// Import libs
import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { Skeleton }          from 'antd';
import _                     from 'lodash';
import { Icon }              from 'helpers';
import { stripTags }         from 'utils/text';
import { getSearchTitle }    from 'utils/search';
import Sentence              from 'helpers/Search/Sentence';
import TextEdit              from 'helpers/TextEdit.jsx';
import { getDataEntity }     from 'store/actions/userView';

import './assets/inline.less';

// TODO: use knowledge !!!
const CLICKABLE_ENTITY_TYPES = ['expert', 'clinicaltrial', 'scidoc', 'project', 'patent'];

/**
 * Display a list row
 *
 */
class Inline extends Component {

    /**
    * Render the loading entity
    *
    */
    renderLoading() {
        const { entity, color } = this.props,
            style               = { color },
            classnames          = ['entity-inline', entity.type, 'is-loading'];

        return (
            <span className={classnames.join(' ')} title={entity.label}
                style={style}
            >
                <Skeleton loading active
                    paragraph={false} avatar
                />
            </span>
        );
    }

    /**
    * Render the entity suffix
    *
    * @return self
    */
    renderSuffix() {
        const { renderSuffix, entity } = this.props,
            renderSuffixIsFn           = renderSuffix && _.isFunction(renderSuffix),
            renderSuffixIsKey          = renderSuffix && _.isString(renderSuffix);

        if (renderSuffixIsFn) {
            return renderSuffix(entity);
        }

        if (renderSuffixIsKey) {
            return entity[renderSuffix] || false;
        }

        return false;
    }


    /**
     * Get the label to render
     */
    renderLabel() {
        // TODO: use bookmark or entity renamed label
        const entity        = this.getEntityToRender(),
            { type, label } = entity,
            emptyString     = 'unavailable at the moment';

        if (type === 'query') {
            return this.renderSearchLabel() || emptyString;
        }

        return stripTags(label || emptyString);
    }


    /**
    * Return the label for search provided as parameters
    *
    * @param object search The search
    *
    * @return void
    */
    renderSearchLabel() {
        const { entity, showBaseLabel } = this.props,
            query                  = getDataEntity(entity),
            { mode }               = query,
            SentenceComponent      = Sentence[mode];

        if (!showBaseLabel && (entity.label || entity.entity?.label)) {
            return entity.label || entity.entity?.label;
        }

        if(!SentenceComponent) {
            return false;
        }

        return (
            <SentenceComponent
                {...query}
                inputs={query.concept}
                lookingFor={false}
                expand={false}
                explain={false}
            />
        );
    }

    /**
     * Get entity to render
     */
    getEntityToRender() {
        const { entity } = this.props,
            isBookmark   = entity.type === 'bookmark',
            isDataEntity = entity.type === 'entity';

        if (isBookmark) {
            const bookmarkedEntity = this.getBookmarkEntity();
            // Fallback when entity of bookmark can be found
            return bookmarkedEntity.id ? bookmarkedEntity : entity.entity;
        }

        if (isDataEntity) {
            return getDataEntity(entity);
        }

        return entity;
    }


    /**
     *  Get bookmark entity
     */
    getBookmarkEntity() {
        const  { entity }  = this.props,
            entityBookmark = entity.entity,
            isQuery        = entityBookmark.type === 'query',
            returnEntity   = isQuery ? entityBookmark.entity?.entity : entityBookmark?.entity,
            label          = entity.label || entityBookmark.label || returnEntity?.label;

        return { ...returnEntity, label };
    }


    /**
    * Render the inline entity
    */
    render() {  // eslint-disable-line
        const {
                color, onClick,
                logoSize, forceLogo, className,
                bindClickOnComponent, labelStyle,
                renderPrefix, editable, registerEditModeCb,
                onTextEditSave, forceEditModeCb, forwardedRef
            }               = this.props,
            entity          = this.getEntityToRender(),
            {
                actorGroupIds, type,
                isSelected, isLoading, clickable,
                partOf, id
            }               = entity,
            isQuery         = type === 'query',
            label           = this.renderLabel(),
            dataEntity      = getDataEntity(entity),
            entityClickable = dataEntity.id && (isQuery || clickable || CLICKABLE_ENTITY_TYPES.indexOf(type) !== -1),
            classnames      = ['entity-inline', type, className, isSelected ? 'selected' : ''],
            displayLogo     = forceLogo || type === 'orgunit',
            style           = { color },
            title           = isQuery ? getSearchTitle(dataEntity) : label;

        if (bindClickOnComponent && entityClickable) {
            style.cursor = 'pointer';
        }

        // The entity is not clickable;
        if (!entityClickable) {
            classnames.push('not-reliable');
        }

        if (partOf) {
            classnames.push('has-part-ofs');
        }

        // Current entity is loading
        if (isLoading) { return this.renderLoading(); }

        return (
            <span
                className={classnames.join(' ')}
                ref={forwardedRef}
                title={title}
                style={style}
                onClick={bindClickOnComponent && entityClickable ? onClick : undefined}
            >
                {renderPrefix && renderPrefix()}
                <span
                    className={`clicker${bindClickOnComponent || !entityClickable ? '' : ' show-link'}`}
                    onClick={!bindClickOnComponent && entityClickable ? onClick : undefined}
                >
                    {displayLogo && (
                        <Icon
                            key={`entity-logo-${id}`}
                            id={type}
                            color="var(--primary-color)"
                            folder="/entities/"
                            className="entity-logo"
                            width={logoSize}
                            discShaped
                            orgunitLogo={type === 'orgunit' ? id : null}
                            borderSize={1}
                            borderColor="#888"
                            title={label}
                            style={{top: '0px'}}
                        />
                    )}
                    {editable && onTextEditSave
                        ? (
                            <span
                                key="entity-label-editable"
                                className="label"
                                style={labelStyle}
                            >
                                <TextEdit
                                    position="absolute"
                                    text={label}
                                    visible="text"
                                    onSave={onTextEditSave}
                                    registerEditModeCb={registerEditModeCb}
                                    forceEditModeCb={forceEditModeCb}
                                    disableClick={editable}
                                />
                            </span>
                        ) : (
                            <span
                                key="entity-label"
                                className="label"
                                style={labelStyle}
                                onClick={entityClickable ? onClick : undefined}
                            >
                                {label}
                            </span>
                        )}
                    {actorGroupIds
                        && _.map(
                            _.isArray(actorGroupIds) ? actorGroupIds : [actorGroupIds],
                            (aId) => (
                                <span key={`group-${aId}`}>
                                    <span className="actorgroup">{aId}</span>
                                    <span className="actorgroupspace"> </span>
                                </span>
                            )
                        )}
                </span>
                {this.renderSuffix()}
            </span>
        );
    }

}

Inline.propTypes = {
    forwardedRef        : PropTypes.any,
    className           : PropTypes.string,
    color               : PropTypes.string,
    entityClassName     : PropTypes.string,
    forceLogo           : PropTypes.bool,
    logoSize            : PropTypes.number,
    onClick             : PropTypes.func,
    bindClickOnComponent: PropTypes.bool,
    renderPrefix        : PropTypes.func,
    renderSuffix        : PropTypes.func,
    labelStyle          : PropTypes.object,
    editable            : PropTypes.bool,
    registerEditModeCb  : PropTypes.func,
    onTextEditSave      : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    forceEditModeCb     : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    showBaseLabel       : PropTypes.bool,
    entity              : PropTypes.shape({
        actorGroupIds: PropTypes.array,
        clickable    : PropTypes.bool,
        id           : PropTypes.string,
        isLoading    : PropTypes.bool,
        isSelected   : PropTypes.bool,
        label        : PropTypes.string,
        partOf       : PropTypes.array,
        entity       : PropTypes.object,
        type         : PropTypes.string,
        userViewState: PropTypes.string,
    })
};

Inline.defaultProps = {
    color               : null,
    logoSize            : 18,
    forceLogo           : false,
    entityClassName     : '',
    renderPrefix        : () => {},
    renderSuffix        : () => {},
    bindClickOnComponent: false,
};

export default Inline;

