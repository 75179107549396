import _                    from 'lodash';
import React, { Component } from 'react';

import PropTypes            from 'prop-types';

import { Element }          from 'helpers';
import Entity               from 'helpers/Entity';

/**
 * Entity module, display entities from store or nav
 *
 */
class EntityModule extends Component {

    /**
    * Initialize the Entity display
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'getMetadata', 'getElement', 'rendererNeedsContext');

        this.state = {
            current       : null,
            elementActions: {}
        };
    }

    /**
    * Triggered once when the component has been mount
    *
    * @return void
    */
    componentDidMount() {
        const {
                emitElementAction,
                registerMetadata
            }        = this.props,
            element  = this.getElement();

        // Emit action to saas-event
        emitElementAction(element);

        registerMetadata(this.getMetadata());
    }

    /**
    * Render the title of the element
    *
    * @return JSX
    */
    getMetadata() {
        const element = this.getElement(),
            { model } = this.props;

        if (this.isAnEntity()) {
            return {
                actions: false,
                title  : model.label
            };
        }

        return {
            actions: false,
            title  : element.label
        };
    }

    /**
    * Check if the module is an entity or an element
    *
    * @return boolean
    */
    isAnEntity() {
        const { module } = this.props;

        return module === 'overview';
    }

    /**
    * Return the main element
    *
    * @return object
    */
    getElement() {
        const { module, elements, model } = this.props,
            analyse   = elements.find(
                (element) => element.target === 'analyse'
                    && element.category === 'Area/Analyse'
                    && element.key === module
            ),
            dataset   = !analyse && elements.find(
                (element) => element.category === 'Area/Dataset' && element.key === module && element.target === model.type
            );

        return analyse || dataset || false;
    }

    /**
    * Returns a boolean that indicates if the item renderer needs context
    *
    * @return boolean
    */
    rendererNeedsContext() {
        const { node, module } = this.props,
            { modules }        = node,
            indexOfModule      = modules.indexOf(module);

        return module.match(/-kpi-/)                                           // Analyse in context
            || module.match(/-in-context/)                                     // Analyse in context
            || (modules[1] && modules[1].match(/-kpi-/) && indexOfModule > 1); // Datasets in context
    }


    /**
    * Get the renderer from Props & Elements
    *
    * @return Component
    */
    renderElement() {
        const {
                model, context, hasBeenActivated,
            }                   = this.props,
            // Renderer selectors
            element             = this.getElement(),
            needsContext        = this.rendererNeedsContext(),
            propsWithoutContext = _.omit(this.props, ['context']);

        if (!hasBeenActivated) {
            return false;
        }

        // If there's a entity
        if (this.isAnEntity()) {
            return (
                <Entity
                    {...this.props}
                    render="modal"
                    key={model.id}
                    entity={model}
                    search={context}
                />
            );
        }

        // If there's an analyse
        return (
            <Element
                key={model.id}
                {...propsWithoutContext}
                context={needsContext ? context : undefined}
                element={element}
            />
        );
    }

    /**
    * Render the entity
    *
    * @return html
    */
    render() {
        return this.renderElement();
    }

}

EntityModule.propTypes = {
    context          : PropTypes.any,
    entities         : PropTypes.any,
    hasBeenActivated : PropTypes.any,
    registerMetadata : PropTypes.func,
    emitElementAction: PropTypes.func,
    model            : PropTypes.shape({
        id   : PropTypes.any,
        label: PropTypes.any,
        type : PropTypes.any,
    }),
    module: PropTypes.string,
    node  : PropTypes.shape({
        modules: PropTypes.array,
    }),
    elements: PropTypes.shape({
        find: PropTypes.func,
    }),
};

/**
* Bind the store to to component
*/
export default EntityModule;
