// Import libs
import React, { Component }      from 'react';
import PropTypes                 from 'prop-types';
import _                         from 'lodash';

import { Icon }                  from 'helpers';
import { hashString, pluralize } from 'utils/text';


// TODO: put icon in knowledge entities
// Import dashboards
export const allEntityType = [
    { type: 'patent',        label: 'invention' },
    { type: 'scidoc',        label: 'scientific work' },
    { type: 'project',       label: 'project' },
    { type: 'clinicaltrial', label: 'clinical study' },
    { type: 'orgunit',       label: 'organization', icon: 'orgunits' },
    { type: 'expert',        label: 'people',        icon: 'people' },
    { type: 'news_link',     label: 'news',          icon: 'news' },
];

import './assets/stats.less';

/**
 * Display a list row
 *
 */
class EntityStats extends Component {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'navigateTo', 'renderEntityLink');
    }

    /**
    * Navigate to dashboard
    *
    * @return void
    */
    navigateTo(e) {
        const { navigateTo }  = this.props,
            { currentTarget } = e,
            { type }          = currentTarget.dataset;

        navigateTo(type);
    }


    /**
     * Format value to render
     *
     * @param {*} statValue
     * @param {bool} isLoading
     *
     * @returns {string || number}
     */
    getValueToRender(statValue, isLoading) {
        const value       = statValue > -1 ? statValue : 0,
            valueToRender = isLoading && value === 0 ? '-' : value;

        return valueToRender;
    }


    /**
     * Render the link of a entity stats
     *
     * @param {object} entityTypeDef
     */
    renderEntityLink(entityTypeDef) {  // eslint-disable-line max-lines-per-function
        const {
                stats, inline, color,
                tableRender, search, close,
                availableDatasets, showUnvailable,
            }               = this.props,
            { label, type } = entityTypeDef,
            value           = stats[type],
            icon            = entityTypeDef.icon || entityTypeDef.type,
            datasetLink     = `${process.env.OVATION_APP}/#/b/${search}/technology-landscape/${type}`,
            key             = `${search}-${hashString(JSON.stringify(entityTypeDef))}`,
            isLoading       = _.isUndefined(value) || !_.includes(availableDatasets, type),
            available       = !isLoading && value > 0,
            classNames      = ['entity-stat', isLoading ? 'is-loading' : '', !available ? 'unavailable' : 'available'],
            valueToRender   = this.getValueToRender(value, isLoading),
            content         = tableRender ? (
                <a key={key}>
                    <span className="entity-stat" style={{ display: inline ? 'inline-block' : 'block' }}
                        onClick={close}
                    >
                        <table cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="stats-icon-padding-left" />
                                <td className="icon-cell">
                                    <a href={datasetLink}>
                                        <Icon
                                            folder="/entities/"
                                            color={color}
                                            id={icon}
                                            height={20}
                                            width={20}
                                        />
                                    </a>
                                </td>
                                <td className="stats-value-padding-left" />
                                <td className="stats-value-padding-right">
                                    <span className="value">
                                        <a href={datasetLink}>
                                            {value}
                                        </a>
                                    </span>
                                </td>
                                <td className="icon-link-cell" />
                                <td className="stats-icon-padding-right" />
                            </tr>
                            <tr>
                                <td colSpan="3" />
                                <td>
                                    <span className="label">
                                        <a href={datasetLink}>
                                            {pluralize(label, value)}
                                        </a>
                                    </span>
                                </td>
                                <td colSpan="2" />
                            </tr>
                            <tr>
                                <td colSpan="6">&nbsp;</td>
                            </tr>
                        </table>
                    </span>
                </a>
            ) : (
                <a
                    key={key}
                    data-type={type}
                    data-qa-key={type}
                    onClick={available ? this.navigateTo : undefined}
                >
                    <span className={classNames.join(' ')} style={{ display: inline ? 'inline-block' : 'block' }}>
                        <Icon
                            folder="/entities/"
                            color={available ? color : '#b6bac4'}
                            id={icon}
                            height={20}
                            width={20}
                        />
                        <span>
                            {`${valueToRender} ${pluralize(label, value)}`}
                        </span>
                    </span>
                </a>
            );

        if (!showUnvailable && _.isUndefined(value)) {
            return null;
        }

        // No data with table
        if (value <= 0 && tableRender) {
            return (<td> </td>);
        }

        // Render with table
        if (tableRender) {
            return (<td>{content}</td>);
        }

        // Standard render
        return content;
    }


    /**
     * Render links
     *
     */
    renderLinks() {
        const { stats, tableRender } = this.props,
            entitiesTypeDef          = _.filter(allEntityType, (entityType) => {
                const { type } = entityType,
                    hasType    =  type && _.keys(stats).includes(type);

                return hasType && stats[type];
            });

        if(!tableRender) {
            return _.map(
                allEntityType,
                this.renderEntityLink
            );
        }

        const items = [];
        for(let entitiesTypeDefcount=0; entitiesTypeDefcount < entitiesTypeDef.length; entitiesTypeDefcount+=3) {
            items.push(
                <React.Fragment key={entitiesTypeDefcount}>
                    <tr className="top">
                        <td colSpan="7" />
                    </tr>
                    <tr>
                        <td className="spaces">&nbsp;</td>
                        {this.renderEntityLink(entitiesTypeDef[entitiesTypeDefcount])}
                        <td className="spaces">&nbsp;</td>
                        {entitiesTypeDefcount+1<entitiesTypeDef.length ? (
                            <>
                                {this.renderEntityLink(entitiesTypeDef[entitiesTypeDefcount+1])}
                            </>
                        ): (<td />)}
                        <td className="spaces">&nbsp;</td>
                        {entitiesTypeDefcount+2<entitiesTypeDef.length ? (
                            <>
                                {this.renderEntityLink(entitiesTypeDef[entitiesTypeDefcount+2])}
                            </>
                        ): (<td />)}
                        <td className="spaces">&nbsp;</td>
                    </tr>
                </React.Fragment>
            );
        }
        return (
            <>
                {items}
            </>
        );
    }


    /**
    * Render the document according to the props.
    *
    * @return JSX
    */
    render() {
        const { tableRender } = this.props;

        // Standard render
        if (!tableRender) {
            return this.renderLinks();
        }

        // Render with table
        return (
            <table className="links">
                {this.renderLinks()}
                <tr className="bottom">
                    <td colSpan="6" />
                </tr>
            </table>
        );
    }

}

EntityStats.propTypes = {
    availableDatasets: PropTypes.array,
    close            : PropTypes.func,
    color            : PropTypes.string,
    inline           : PropTypes.bool,
    navigateTo       : PropTypes.func,
    search           : PropTypes.string,
    showUnvailable   : PropTypes.bool,
    stats            : PropTypes.object,
    tableRender      : PropTypes.bool,
};

EntityStats.defaultProps = {
    availableDatasets: [],
    close            : null,
    inline           : false,
    search           : null,
    showUnvailable   : false,
    stats            : {},
    tableRender      : false,
};

export default EntityStats;

