import React, { Component }         from 'react';
import _                            from 'lodash';
import PropTypes                    from 'prop-types';
import { scaleBand as d3ScaleBand } from 'd3';
import { Icon }                     from 'helpers';
import Animation                    from '../Animation';
import watermarkPng                 from './Bet/watermark.png';

import './Bet/main.less';

const scenario = {
    scenes: {
        general: {
            steps: {
                main: [
                    { attribute: 'size', duration: 200 },
                ]
            }
        }
    }
};

/**
 * The shape graph Component
 *
 */
class Bet extends Component {

    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScales(options) {
        const { nextProps, state }             = options,
            { labelRatio }                     = nextProps,
            { innerWidth, innerHeight, serie } = state,
            startPosition                      = innerWidth * labelRatio,
            items                              = d3ScaleBand()
                .range([
                    0,
                    innerHeight
                ])
                .domain(_.range(serie.length)),
            endPosition                        = innerWidth - items.bandwidth() / 2,
            maxTokens                          = _.reduce(
                serie,
                (accumulator, item) => (accumulator < item.tokens.length ? item.tokens.length : accumulator),
                0
            ),
            tokens                             = d3ScaleBand()
                .range([
                    startPosition,
                    endPosition
                ])
                .domain(_.range(maxTokens) || 1);

        state.scales =  {
            items,
            tokens: tokens.bandwidth() < items.bandwidth()
                ? tokens
                : (val) => startPosition + val * items.bandwidth() * 0.75  // Tokens must be stacked
        };

        return { nextProps, state };
    }

    /**
    * Update plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state }                     = options,
            { labelRatio, innerPadding, filterValues } = nextProps,
            { scales, serie, selectedItem }            = state,
            { innerWidth }                             = state,
            itemHeight                                 = scales.items.bandwidth(),
            tokenSize                                  = itemHeight * (1 - innerPadding),
            labelWidth                                 = innerWidth * labelRatio - itemHeight;

        state.plotData = _.map(serie, (item, itemNum) => ({
            num     : itemNum,
            id      : item.id,
            inFilter: filterValues.includes(item.id),
            label   : {
                text    : item.label,
                position: innerWidth * labelRatio / 2 - (labelWidth + tokenSize / 2) / 2,
                width   : labelWidth,
                opacity : 1,
            },
            line: {
                startPosition: scales.tokens(0),
                endPosition  : scales.tokens(item.tokens.length > 0 ? item.tokens.length - 1 : 0)
            },
            position: scales.items(itemNum) + itemHeight / 2,
            tokens  : _.map(item.tokens, (token, tokenNum) => ({
                id       : token.id,
                label    : token.label,
                type     : token.type,
                clickable: token.clickable,
                position : scales.tokens(tokenNum),
                size     : tokenSize / 1.5
                    * (selectedItem === `${itemNum}-${token.id}` ? 1 + innerPadding * 1.5 : 1)
            }))
        }));

        return { nextProps, state };
    }

    /**
    * Update vertical scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateVerticalScales(options) {
        const { nextProps, state }             = options,
            { innerPadding }                   = nextProps,
            { innerWidth, innerHeight, serie } = state,
            { labelHeight }                    = state,
            startPosition                      = labelHeight * 3,
            endPosition                        = innerHeight,
            tokens                             = d3ScaleBand()
                .range([
                    startPosition,
                    endPosition
                ])
                .domain(_.range(serie[0].tokens.length))
                .paddingInner(innerPadding),
            items                                  = d3ScaleBand()
                .range([
                    tokens.bandwidth(),
                    innerWidth - tokens.bandwidth()
                ])
                .domain(_.range(serie.length))
                .paddingInner(innerPadding);

        state.scales =  { items, tokens };

        return { nextProps, state };
    }

    /**
    * Update plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateVerticalPlotData(options) {
        const { nextProps, state }                 = options,
            { scales, serie, labelHeight }         = state,
            tokenSize                              = scales.tokens.bandwidth(),
            labelWidth                             = scales.items.bandwidth();

        state.plotData = _.map(serie, (item, itemNum) => ({
            num  : itemNum,
            label: {
                text    : item.label,
                position: labelHeight,
                width   : labelWidth,
                opacity : 1,
            },
            line: {
                startPosition: labelHeight * 3,
                endPosition  : scales.tokens(item.tokens.length - 1)
            },
            position: scales.items(itemNum),
            tokens  : _.map(item.tokens, (token, tokenNum) => ({
                id       : token.id,
                label    : token.label,
                type     : token.type,
                clickable: token.clickable,
                position : scales.tokens(tokenNum),
                size     : tokenSize / 1.5
            }))
        }));

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null
        };

        _.bindAll(
            this, 'render', 'renderSvgElements', 'renderTokens', 'entityClick', 'onMouseOverToken', 'onMouseOutToken'
        );

        // Make a uuid
        this.id = _.uniqueId('bet');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - scale (D3Scales object) of the landmark
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, state) {
        const { margin, fontSize, data }      = nextProps,
            { vertical, fontSizeAuto, width } = nextProps,
            // Cascading function to update state
            updateState                       = _.flow([
                vertical ? Bet.updateVerticalScales : Bet.updateScales,       // D3 scale
                vertical ? Bet.updateVerticalPlotData :  Bet.updatePlotData,  // Plot data
            ]),
            dynamicFontSize = fontSizeAuto ?  width / 50 : fontSize,
            { state: newState }               = updateState({
                nextProps,
                state: {
                    ...state,
                    // Size of the chart (without landmark)
                    fontSize   : dynamicFontSize,
                    innerHeight: nextProps.height - 2 * margin,
                    innerWidth : nextProps.width  - 2 * margin,
                    labelHeight: dynamicFontSize * 2,
                    serie      : _.orderBy(_.get(data, 'content', []), ['value', 'label'], ['desc', 'asc']),
                }
            });

        return newState;
    }

    /**
    * On mouse over token
    *
    * @return false
    */
    onMouseOverToken(e) {
        const { interactive } = this.props,
            domNode           = e.currentTarget,
            selectedItem      = _.get(domNode, 'attributes.dataid.value', null);

        if (!interactive) { return false; }

        clearTimeout(this.mouseOverTimeout);
        clearTimeout(this.mouseOutTimeout);

        this.mouseOverTimeout = setTimeout(
            () => this.setState({ selectedItem }),
            150
        );

        return false;
    }

    /**
    * On mouse out item
    *
    * @return false
    */
    onMouseOutToken() {
        const { interactive } = this.props;

        if (!interactive) { return false; }

        clearTimeout(this.mouseOutTimeout);
        clearTimeout(this.mouseOverTimeout);

        this.mouseOutTimeout = setTimeout(
            () => this.setState({ selectedItem: null }),
            150
        );

        return false;
    }

    /**
    * On mouse click
    *
    * @return html
    */
    entityClick(entity, entities) {
        const { openModal, interactive } = this.props;

        if (interactive && entity.clickable) {
            openModal(entity, entities);
        }

        return true;
    }

    /**
    * On item click
    *
    * @return boolean
    */
    onItemClick(item) {
        return () => {
            const { filterCb, filterValues } = this.props;

            // Add filter
            if (filterCb && item.id) {
                filterCb(_.xor([item.id], filterValues));
            }

            return true;
        };
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels(data) {
        const { color, vertical }     = this.props,
            { margin, filterCb,  }    = this.props,
            { labelHeight, fontSize } = this.state;

        return _.map(data, (item) => {
            const { label } = item,
                left    = margin + (vertical ? item.position : label.position),
                top     = margin + (vertical ? label.position : item.position - fontSize);

            return (
                <div
                    className="label-container"
                    key={label.text}
                    style={{
                        left, top, width: label.width + 2, height: labelHeight
                    }}
                >
                    <div
                        onClick={this.onItemClick(item)}
                        className="label"
                        title={label.text}
                        style={{
                            width     : label.width,
                            opacity   : label.opacity,
                            lineHeight: `${fontSize * 2}px`,
                            fontSize  : `${fontSize}px`,
                            fontWeight: item.inFilter ? 'bold' : null,
                            padding   : `0 ${fontSize / 2}px`,
                            textAlign : vertical ? 'center' : 'right',
                            cursor    : _.isFunction(filterCb) && !_.isUndefined(item.id)  ? 'pointer' : null,
                            color,
                        }}
                    >
                        <span>
                            {label.text}
                        </span>
                    </div>
                </div>
            );
        });
    }

    /**
    * Render label lines
    *
    * @return html
    */
    renderLabelsLines(data) {
        const { color, vertical } = this.props,
            { labelHeight }       = this.state;

        return _.map(data, (item) => {
            const x1 = vertical
                    ? item.position + item.label.width / 2
                    : item.label.position + item.label.width,
                y1 = vertical
                    ? item.label.position + labelHeight
                    : item.position,
                x2 = vertical
                    ? x1
                    : item.line.startPosition,
                y2 = vertical
                    ? item.line.startPosition
                    : y1;

            return (
                <line
                    key={item.label.text}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={color}
                    strokeWidth={2}
                />
            );
        });
    }

    /**
    * Render lines
    *
    * @return html
    */
    renderLines(data) {
        const { color, vertical } = this.props;

        return _.map(data, (item) => {
            const x1 = vertical
                    ? item.position + item.label.width / 2
                    : item.line.startPosition,
                y1 = vertical
                    ? item.line.startPosition
                    : item.position,
                x2 = vertical
                    ? x1
                    : item.line.endPosition,
                y2 = vertical
                    ? item.line.endPosition
                    : item.position;

            return (
                <line
                    key={item.label.text}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={color}
                    strokeWidth={2}
                />
            );
        });
    }

    /**
    * Render items
    *
    * @return html
    */
    renderTokens(tokens, progress, params) {
        const { item }   = params,
            { vertical } = this.props;

        return _.map(tokens, (token) => {
            const translate = vertical
                ? `translate(${item.position + item.label.width / 2}, ${token.position})`
                : `translate(${token.position}, ${item.position})`;

            return (
                <g
                    className={`token${token.clickable ? ' clickable' : ''}`}
                    key={token.id}
                    transform={translate}
                    dataid={`${item.num}-${token.id}`}
                    onMouseUp={() => this.entityClick(token, tokens)}
                    onMouseOver={this.onMouseOverToken}
                    onFocus={this.onMouseOverToken}
                    onMouseOut={this.onMouseOutToken}
                    onBlur={this.onMouseOutToken}
                >
                    {this.renderTokensIcon(token)}
                    <Icon
                        SVG
                        discShaped
                        id="orgunit"
                        folder="/entities/"
                        orgunitLogo={token.id}
                        width={token.size}
                        height={token.size}
                        title={token.label}
                    />
                </g>
            );
        });
    }

    /**
    * Render token icon
    *
    * @return html
    */
    renderTokensIcon(token) {
        const { color }  = this.props,
            tokenSize = token.size * 1.85;

        return (
            <Icon
                key="token-icon"
                SVG
                folder="/graph/bet/"
                id="token"
                width={tokenSize}
                height={tokenSize}
                color={color}
                title={token.label}
            />
        );
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvg() {
        const { plotData } = this.state;

        return this.renderSvgElements(plotData);
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvgElements(data) {
        const { margin } = this.props;

        return (
            <g transform={`translate( ${margin} ${margin} )`}>
                {this.renderLabelsLines(data)}
                {this.renderLines(data)}
                {_.map(data, (item) => (
                    <Animation
                        key={item.label.text}
                        data={item.tokens}
                        params={{ item }}
                        render={this.renderTokens}
                        scenario={scenario}
                    />
                ))}
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width,
                height,
                margin,
                skeleton,
                skeletonGradient,
                onlySvg,
                showWatermark,
            }                           = this.props,
            { innerWidth, innerHeight } = this.state,
            { plotData }                = this.state,
            style                       = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) { return (<div className="Bet skeleton" style={style} />); }

        return onlySvg ? this.renderSvg() : (
            <div className="Bet">
                {showWatermark ? (
                    <div
                        className="background"
                        style={{
                            width: innerWidth, height: innerHeight, margin, textAlign: 'left'
                        }}
                    >
                        <img
                            className="watermark"
                            src={watermarkPng}
                            height={innerHeight}
                            alt=""
                        />
                    </div>
                ) : null}
                <svg
                    width={width}
                    height={height}
                >
                    {this.renderSvg()}
                </svg>
                {this.renderLabels(plotData)}
            </div>
        );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
Bet.propTypes = {
    color           : PropTypes.string,
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    filterCb        : PropTypes.func,
    filterValues    : PropTypes.any,
    fontSize        : PropTypes.number,
    height          : PropTypes.number.isRequired,
    interactive     : PropTypes.bool,
    fontSizeAuto    : PropTypes.bool,
    margin          : PropTypes.number,
    onlySvg         : PropTypes.bool,
    openModal       : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    showWatermark   : PropTypes.bool,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    vertical        : PropTypes.bool,
    width           : PropTypes.number.isRequired,
    innerPadding    : PropTypes.number,
    labelRatio      : PropTypes.number,
};

/**
 * Default props value
 */
Bet.defaultProps = {
    margin          : 10,
    fontSize        : 14,
    color           : 'var(--insight-color)',
    skeleton        : false,
    onlySvg         : false,
    skeletonGradient: false,
    fontSizeAuto    : false,
    openModal       : _.noop,
    interactive     : true,
    vertical        : false,
    showWatermark   : true,
    innerPadding    : 0.2,
    labelRatio      : 3 / 8,

};

export default Bet;

