// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Planet graph
*
*/
.Planet svg tspan:nth-child(2n) {
  display: block;
}
.Planet.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.Planet .gapTitle {
  position: absolute;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.Planet .gapText {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.Planet .gapText b {
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Planet/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAGY,cAAA;AAFZ;AAMK;EACG,mDAAA;AAJR;AAJA;EAYQ,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;AALR;AAVA;EAmBQ,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AANR;AAhBA;EAwBY,cAAA;AALZ","sourcesContent":["/**\n* Planet graph\n*\n*/\n\n.Planet {\n    svg {\n        tspan:nth-child(2n) {\n            display: block;\n        }\n    }\n\n     &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n\n    .gapTitle {\n        position: absolute;\n        width: 100%;\n        text-align: center;\n        font-weight: bold;\n    }\n\n    .gapText {\n        position: absolute;\n        width: 100%;\n        text-align: center;\n        padding : 10px;\n        b {\n            color: inherit;\n        }\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
