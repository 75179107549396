// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-popover.insight .item-insight {
  font-size: 14px;
  margin-bottom: 20px;
}
.ant-popover.insight .entity-stat {
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Element/Area/Dashboard/assets/item.less"],"names":[],"mappings":"AACA;EAEQ,eAAA;EACA,mBAAA;AADR;AAFA;EAMQ,iBAAA;AADR","sourcesContent":["\n.ant-popover.insight {\n    .item-insight {\n        font-size    : 14px;\n        margin-bottom: 20px;\n    }\n    .entity-stat {\n        line-height: 20px;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
