import PropTypes              from 'prop-types';
import _                      from 'lodash';
import React, { Component }   from 'react';
import dayjs                  from 'dayjs';
import { DatePicker }         from 'antd';
import { IButton }            from 'helpers';

const monthFormat = 'YYYY/MM',
    customFormat  = 'YYYYMM';

/**
 *  Componant render 2 mont picker to chose a range
 */
class DateRangeCustomMonth extends Component {

    /**
     * Construct
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'disabledDate', 'onChangeMonthPicker');
    }

    /**
    * Get min and max years from DateRange string
    *
    * @param {string} daterangeString
    * @returns array
    */
    getYearsMontFromRangeString(daterangeString) {
        const presetYearExtract = /tfy(\d*)$/.exec(daterangeString),
            customExtract       = /custom_(\d*)_(\d*)$/.exec(daterangeString),
            currentYear         = new Date().getFullYear();

        // Is a year preset range
        if (_.isArray(presetYearExtract) && presetYearExtract.length === 2) {
            return [currentYear - parseInt(presetYearExtract[1]), currentYear];
        }

        // Is a custom range
        if (_.isArray(customExtract) && customExtract.length === 3) {
            return [parseInt(customExtract[1]), parseInt(customExtract[2])];
        }
    }

    /**
    * On apply button  click
    * Triggering the onChange callback
    */
    onApply() {
        const {
                selectedId, onChange,
                filterKey, boundaries, setActiveCustomInput,
            }                = this.props,
            { dateMin }      = boundaries,
            minYear          = dayjs(dateMin).year(),
            isCustom         = _.isString(selectedId) && selectedId.indexOf('custom_') === 0,
            currentYear      = new Date().getFullYear(),
            defaultDateRange = `custom_${minYear}_${currentYear}`;

        setActiveCustomInput(false);
        onChange(filterKey, isCustom ? selectedId : defaultDateRange);
    }

    /**
     * Get DisableDate boolean
     *
     * @param {*} current Date use by DatePicker
     *
     * @returns boolean
     */
    disabledDate(current) {
        const { boundaries }     = this.props,
            { dateMin, dateMax } = boundaries;

        return current
            ? current > dateMax || current < dateMin
            : true;
    }

    /**
    * Get the real custom value
    *
    * @param {array} rangeValueInArray
    * @returns array
    */
    getRealCustomValue(rangeValueInArray) {
        const {
                blockedEndDate, boundaries,
            }               = this.props,
            { dateMin }     = boundaries,
            minYear         = dayjs(dateMin).year(),
            currentYear     = dayjs().year(),
            start           = rangeValueInArray ? rangeValueInArray[0] : minYear,
            end             = rangeValueInArray ? rangeValueInArray[1] : currentYear,
            restrictEndDate = blockedEndDate && dayjs(blockedEndDate).format(monthFormat);

        return [start, restrictEndDate || end];
    }

    /**
     * On change date
     *
     * @param {string} value
     * @param {bool} isStart
     * @param {array} isStart
     */
    onChangeMonthPicker(value, isStart, range) {
        const { setSelectedId } = this.props,
            formattedValue      = value?.format(customFormat),
            min                 = isStart  ? formattedValue : range[0],
            max                 = !isStart ? formattedValue : range[1];

        if (!min || !max) {
            return;
        }

        setSelectedId(`custom_${min}_${max}`);
    }

    /**
     * Render a month picker
     *
     * @param {string} rangeKey
     */
    renderMonthPicker(rangeKey) {
        const {
                blockedEndDate, value
            }                      = this.props,
            { selectedId = value } = this.props,
            isStart                = rangeKey === 'start',
            rangeValueInString     = selectedId && selectedId !== 'tfcustom' ? selectedId : value,
            rangeValueInArray      = this.getYearsMontFromRangeString(rangeValueInString),
            range                  = this.getRealCustomValue(rangeValueInArray),
            defaultValue           = dayjs(range[isStart ? 0 : 1].toString()),
            disabled               = Boolean(!isStart && blockedEndDate);

        return (
            <DatePicker
                value={defaultValue}
                format={monthFormat}
                picker="month"
                disabled={disabled}
                disabledDate={this.disabledDate}
                onChange={newValue => this.onChangeMonthPicker(newValue, isStart, range)}
            />
        );
    }

    /**
     * Render buttons
     *
     * @returns JSX
     */
    renderButtons() {
        const {
                selectedId,
                onReset,
                value
            }          = this.props,
            isDisabled =  !selectedId || selectedId === value;

        return (
            <div className="buttons">
                <IButton
                    label="Reset"
                    fontSize={12}
                    className="active reset"
                    onClick={onReset}
                    disabled={isDisabled}
                />
                <IButton
                    label="Apply"
                    data-qa-key="custom-date-range-button"
                    className="apply" bgColor="#10285D"
                    labelColor="#FFFFFF" hoverBgColor="#58698E"
                    fontSize={12}
                    onClick={() => this.onApply()}
                />
            </div>
        );
    }

    /**
     * Render the componant
     */
    render() {
        return (
            <div className="custom-date-range">
                <div className="date-block-wrapper">
                    <div className="date-block">
                        <h3>
                            Start
                        </h3>
                        {this.renderMonthPicker('start')}

                    </div>
                    <div className="date-block">
                        <h3>
                            End
                        </h3>
                        {this.renderMonthPicker('end')}
                    </div>
                </div>
                {this.renderButtons()}
            </div>
        );
    }

}

DateRangeCustomMonth.propTypes = {
    blockedEndDate: PropTypes.instanceOf(Date),
    boundaries    : PropTypes.object,
    filterKey     : PropTypes.any,
    onChange      : PropTypes.func,
    onReset       : PropTypes.func,
    selectedId    : PropTypes.oneOfType([PropTypes.shape({
        indexOf: PropTypes.func
    }), PropTypes.string]),
    setActiveCustomInput: PropTypes.func,
    setSelectedId       : PropTypes.func,
    value               : PropTypes.any
};

export default DateRangeCustomMonth;

