import React                    from 'react';
import _                        from 'lodash';
import CryptoJS                 from 'crypto-js';
import { getKeysFromCondition } from 'utils/object';

/**
* Base64 URL encoding => There is no = padding at the end, + and / characters are replaced by - and _ respectively.
*
* @param string source
*
* @return string
*/
export const base64url = (source) => {
    let encodedSource;

    // Encode in classical base64
    encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
};

/**
* Match string in array of strings
*
* @params string  string
* @params strings array of strings
*
* @return string
*/
export const renameDuplicate = (string, strings) => {
    const expression = new RegExp(`^${string}[\((\d*)\)]?`, 'gm'),
        founded      = [],
        regexIncNum  = /^(\d*)\)$/gm;

    strings.forEach((str) => {
        const match = str.replace(expression, '');

        if (
            match === ''
            || (match !== str && regexIncNum.exec(match))
        ) {
            founded.push(parseInt(match.substr(0, match.length - 1)) || 0);
        }
    });

    if (founded.length > 0) {
        const max = _.max(founded);
        return `${string}(${max + 1})`;
    }

    return string;
};

/**
* Extract the domain from an url
*
* @return object
*/
export const extractDomain = (url) => {
    const matchs = url?.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/);

    if (!matchs || !matchs.length) {
        return false;
    }

    return {
        url   : matchs[0],
        domain: matchs[1]
    };
};

/**
* Replace SOBJ.CN
*
* @param string url The url to replace
*
* */
export const getSobjImage = (url, size = 250) => {
    const { location }      = window,
        locationAsString    = location.toString(),
        chinaDomainToDetect = 'orbit-innovation.orbit.cn',
        sobjToReplace       = {
            'sobjprd.questel.fr'       : 'sobj.orbit-intelligence.cn',
            'uaigs-sobjprd.orbit.com'  : 'uaigs.orbit-intelligence.cn',
            'uaijpcn-sobjprd.orbit.com': 'uaijpcn.orbit-intelligence.cn',
            'uaikrau-sobjprd.orbit.com': 'uaikrau.orbit-intelligence.cn',
            'uaius-sobjprd.orbit.com'  : 'uaius.orbit-intelligence.cn',
        };

    let urlToReturn = url;

    urlToReturn =  urlToReturn && urlToReturn.replace
        && urlToReturn.replace('&width=250', size ? `&width=${size}` : '')
            .replace('&height=250', '');

    if (locationAsString.match(chinaDomainToDetect)) {
        _.keys(sobjToReplace).forEach((sobjEUHost) => {
            urlToReturn = urlToReturn && urlToReturn.replace
                && urlToReturn.replace(sobjEUHost, sobjToReplace[sobjEUHost]);
        });
        return urlToReturn;
    }

    return urlToReturn;
    // Return `/api/Image?src=${encodeURIComponent(url)}`;
};

/**
* Convert tags to HTML
*
* @return JSX
*/
export const htmlize = (textWithHtml, style, Tag = 'span') =>
    // eslint-disable-next-line
    <Tag dangerouslySetInnerHTML={{ __html: textWithHtml }} style={style} />;

/**
* Multiply a String
*
* @params string $term The term to be multiply
*
* @return string The string
*/
export const multiply = (term, factor) => {
    const value = parseFloat(term, 10),
        unit    = term.replace(value, '');

    return `${value * factor}${unit}`;
};

/**
* Singularize a term
*
* @params string $term The term to be singularized
*
* @return string The string
*/
export const singularize = (term) => {
    if (term.match(/us$/) || term.match(/ws$/) || term.match(/ss$/)) {
        return term;
    }

    return term.replace(/ies$/, 'y')
        .replace(/hes$/, 'h')
        .replace(/s$/, '')
        .replace(/sse$/, 'ss');
};

/**
* Capitalize a text
*
* @params string $term The text to be capitalized
*
* @return string The string
*/
export const capitalize = (text, firstWordOnly = false) => (
    _.isString(text)
        ? (
            firstWordOnly
                ? (text.charAt(0).toUpperCase() + text.slice(1))
                : (text.replace ? text.replace(/\b\w/g, (l) => l.toUpperCase()) : text)
        ) : _.isNumber(text) ? text : ''
);

/**
* Pluralize a term
*
* @params string term  The term to be Pluralized
* @params number value The value tested
*
* @return string The hash
*/
export const pluralize = (term, value = 2) => {
    // Not string => return empty string
    if (!_.isString(term)) { return ''; }

    // Use singular
    if (value <= 1) { return term; }

    // Manage "people" exception
    if (term.toLowerCase().match(/people$/)) {
        return term;
    }

    // Manage %
    if (term.match(/\%$/)) {
        return term;
    }

    // Manage $ and €
    if (term.match(/\$$/) || term.match(/€$/)) {
        return term;
    }

    // Manage ss -> sses
    if (term.match(/ss$/)) {
        return term.replace(/ss$/, 'sses');
    }

    // Manage s -> s
    if (term.match(/us$/) || term.match(/ws$/) || term.match(/es$/) || term.match(/s$/)) {
        return term;
    }

    // Manage y -> ies
    if (term.match(/y$/)) {
        return term.replace(/y$/, 'ies');
    }

    // Manage hs -> hes
    if (term.match(/h$/)) {
        return term.replace(/h$/, 'hes');
    }

    return term === '' ? '' : `${term}s`;
};

/**
* Format number with K, M, B, T
*
* @params number $number The number to be formated
*
* @return string The hash
*/
export const formatNumberWithMagnitude = (
    number,
    precision = 0,
    minimumFractionDigits = false,
    space = false,
    minimumIntegerDigits = 1
) => { // eslint-disable-line max-params
    const min = 1e3;
    // Alter numbers larger than 1k
    if (number >= min) {
        const units  = ['k', 'M', 'B', 'T'],
            order    = Math.floor(Math.log(number) / Math.log(1000)),
            unitname = units[(order - 1)],
            num      = Math.floor(number / (1000 ** order) * (10 ** precision)) / (10 ** precision);

        // Output number remainder + unitname
        return num.toLocaleString(undefined, { minimumFractionDigits, minimumIntegerDigits }) + (space ? ' ' : '') + unitname;
    }

    // Return original number
    const num =  _.isNumber(number) ? Math.floor(number * (10 ** precision)) / (10 ** precision) : number;
    return num?.toLocaleString(undefined, { minimumFractionDigits, minimumIntegerDigits });
};

/**
* Format number with spaces
* 1 000
* 10 000
* 1 000 000
*
* @param {*} number
* @returns {number|string}
*/
export const formatInternationalNumber = number => {
    const numericValue = _.toNumber(number);

    if (numericValue < 1e3) {
        return formatNumberWithMagnitude(numericValue, 2);
    }

    if (_.isFinite(numericValue)) {
        return _.replace(numericValue.toFixed(0), /\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    return 'Invalid number';
};

/**
 * Seek and remove all the html tags in the provided text excepts tags to keep.
 *
 * @param string text        The text to strip the tags from.
 * @param array  tagsToKeep  List of tags to preserve.
 *
 * @return string
 */
export const stripTags = (text, tagsToKeep = []) => {
    const regEx         = /<\/?[^>]+>/gi,
        tagsToKeepRegEx = new RegExp(`<(\/?)(${tagsToKeep.join('|')})(\s.*|\/)?>`, 'i');

    return (text || '').toString().replace(regEx,
        (match) => {
            return tagsToKeep.length > 0 && tagsToKeepRegEx.test(match)
                ? match
                : '';
        });
};

/**
* Extract some GET param from a string (key=value&key=value etc..)
*
* @param string url The url part to seek for parameters
*
* @return object
*/
export const getParams = (url) => {
    const params      = url.split('&').map((val) => val.split('=')),
        indexedParams = {};

    _.each(params, (param) => {
        // eslint-disable-next-line
        indexedParams[param[0]] = param[1];
    });

    return indexedParams;
};

/**
 * Checks if a sentences has a/list word/s
 *
 * @param string sentence
 * @param array words
 * @returns
 */
export const hasWordsInSentence = (words, sentence, caseSensitive) => {
    const escapedWords = words.map(word => `\\b${word}\\b`).join('|'),
        regex          = new RegExp(`${escapedWords}`, caseSensitive ? '' : 'i');

    return regex.test(sentence);
};

/**
 * Return sentence length
 *
 * @param string sentence
 * @returns
 */
export const countWordsInSentence = (sentence = '') => {
    const regex = /\b\w+\b/g,
        match   = sentence.match(regex);

    return match ? match.length : 0;
};

/**
 * Check whether the string is a valid url or not.
 *
 * @param url The url to test
 * @return boolean
 */
export const isUrl = (url) => _.isString(url)
    && url.match(/^(ht|f)tps?:\/\/[a-z0-9-.]+\.[a-z]{2,4}\/?([^\s<>",{}\\|\\^[\]`]+)?$/);

/**
 * Transform New line characters into <br/> tags
 *
 * @param val The text to transform the tags
 * @return string
 */
export const addBr = (val) => {
    const string = `${''}${val}${''}`;
    return string.replace(/\n/g, '<br />');
};

/**
 * Transform <br/> tags into New line characters
 *
 * @param val The text to transform the tags
 * @return string
 */
export const brToNewline = (val) => {
    const string = `${''}${val}${''}`;
    return string.replace(/<br\s?\/?>/gi, '\n');
};

/**
 * Render a price with a currency, useful for the $ case.
 *
 * @param amount The amount to Display
 * @param unit The unit to display
 * @param before If it must be placed before (false by default)
 * @return string
 */
export const renderUnit = (amount, unit, before = false) => (
    before ? `${unit}${amount}` : `${amount}${unit}`
);

/**
 * Render an interval of date,
 *
 * @param startDate The date starting the interval in ISO string format
 * @param endDate The date ending the interval in ISO string formate
 * @return string || null
 */
export const renderDateInterval = (startDate, endDate) => {
    const start = startDate && _.first(startDate.split('-')),
        end = endDate && _.first(endDate.split('-'));

    if (!start && !end) {
        return null;
    }

    if (start === end) {
        return start;
    }

    if (!end) {
        return `${start} - ?`;
    }

    if (!start) {
        return `? - ${end}`;
    }

    return `${start} - ${end}`;
};

/**
 * Return file size in string
 *
 * @return number
 */
export const humanFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

/**
 * Return the current caret position
 *
 * @return number
 */
export const getCaretPosition = () => {
    if (window.getSelection && window.getSelection().getRangeAt) {
        const range     = window.getSelection().getRangeAt(0),
            selectedObj = window.getSelection(),
            childNodes  = selectedObj.anchorNode.parentNode.childNodes; // eslint-disable-line

        let rangeCount = 0;

        for (var i = 0; i < childNodes.length; i++) {                  // eslint-disable-line
            if (childNodes[i] === selectedObj.anchorNode) {
                break;
            }
            if (childNodes[i].outerHTML) {
                rangeCount += childNodes[i].outerHTML.length;
            } else if (childNodes[i].nodeType === 3) {
                rangeCount += childNodes[i].textContent.length;
            }
        }
        return range.startOffset + rangeCount;
    }
    return -1;
};

/**
* Insert text at
*
* @return string
*/
export const splice = (string, idx, rem, str) => { // eslint-disable-line
    return string.slice(0, idx) + str + string.slice(idx + Math.abs(rem));
};

/**
 * Sanitize the filename to export
 *
 * @return string
 */
export const sanitizeFilename = (input, replacement = '_') => {
    if (!input) return '';

    return input.trim()
        .replace(/[(){}\[\]]/g, '')
        .replace('&', 'and')
        .replace(/[^a-z0-9]/gi, replacement);

};

/**
 * Hyphenate the string
 *
 * @return string
 */
export const hyphenate = (input, replacement = '-') => {
    const removeChars = ['(', ')', '{', '}', '[', ']'],
        cleanedInput  = input ? input.split('').filter((char) => !removeChars.includes(char)).join('') : '';

    return cleanedInput.toLowerCase().replace(/[^a-z0-9]/gi, replacement);
};

/**
 * Recursive template
 *
 * @return string
 */
export const fillTemplate = (template, obj) => {
    let filledTemplate = template;
    _.each(obj, (val, key) => {
        const reg = new RegExp(`%${key}%`, 'g');
        filledTemplate = filledTemplate.replace(reg, val);
        if (_.isObject(val)) {
            _.each(val, (subVal, subKey) => {
                const subReg = new RegExp(`%${key}.${subKey}%`, 'g');
                filledTemplate = filledTemplate.replace(subReg, subVal);
            });
        }
    });

    return filledTemplate;
};

/**
 * Hash a string
 *
 * @return string
 */
export const hashString = (s) => (
    _.isString(s)
        ? s.split('').reduce((a, b) => {
            a = ((a < 5) - a) + b.charCodeAt(0); // eslint-disable-line no-param-reassign
            return a && a;
        }, 0)
        : ''
);


/**
 * Transform a label to a data-qa-key or class name
 * @param {string} label
 * @returns
 */
export const str2DomFormat = (label) => {
    if (!_.isString(label)) {
        return '';
    }

    const formattedStr = label.toLowerCase()
        .replace(/[^a-z0-9]/gi, '-')  // Replace all non-alphanumeric characters with a dash
        .replace(/-+/g, '-')      // Replace all occurrences of consecutive '-' with a single '-'
        .replace(/^-+|-+$/g, ''); // Remove the '-' at the beginning and end of the string

    return formattedStr;
};


/**
 * Get string value of a thing
 *
 * @param {all} value
 *
 * @returns
 */
export const toString = (value) => {
    if (_.isString(value)) {
        return value;
    }

    if (_.isArray(value)) {
        return value.map((val) => {
            return toString(val);
        }).join(' ');
    }

    if (_.isObject(value)) {
        return toString(getKeysFromCondition(value));
    }

    if (_.isNumber(value)) {
        return value.toString();
    }

    if (_.isBoolean(value)) {
        return value.toString();
    }

    if (_.isNull(value)) {
        return '';
    }

    if (_.isUndefined(value)) {
        return '';
    }

    if (_.isFunction(value)) {
        return value();
    }

    if (_.isRegExp(value)) {
        return value.toString();
    }

    if (_.isDate(value)) {
        return value.toString();
    }

    return '';
};


/**
 *
 *
 * @param {array} classNames
 * @returns
 */
export const makeStrClassName = (classNames) => {
    return classNames.map((className) => {
        return str2DomFormat(toString(className));
    }).join(' ');
};


/**
 * If the input is a number, return true,
 * otherwise return true if the input is a string of digits.
 *
 * @return boolean
 */
export const isNumeric = (str) => {
    if (_.isNumber(str)) {
        return true;
    }

    return /^\d+$/.test(str);
};

export default {
    singularize,
    capitalize,
    hashString,
    renameDuplicate,
    hasWordsInSentence,
    countWordsInSentence,
    hyphenate,
    addBr,
    brToNewline,
    isNumeric,
    str2DomFormat,
    makeStrClassName,
    formatInternationalNumber,
    formatNumberWithMagnitude,
};

