/**
 * Check if the input text has tags and return the text with highlighted text
 *
 * @returns Component
 */
import React            from 'react';
import PropTypes        from 'prop-types';
import { stripTags }    from 'utils/text';
import { isString }     from 'lodash';

/**
* Render the Highlight
* React Functional Components (Hooks)
*
* @returns JSX
*/
const Highlight = (props) => {  // eslint-disable-line max-lines-per-function
    /**
     * Replace all <Highlight> tag for <mask>
     *
     * @returns HTML
     */
    const replaceHtml = () => {
        const {
                stripTags: mustBeStripped,
                children
            }          = props,
            tagsToKeep = ['highlight'],
            cleanText  = mustBeStripped ? stripTags(children, tagsToKeep) : children;

        return cleanText?.replace(/<highlight>([\s\S]*?)<\/highlight>/gi, '<mark>$1</mark>');
    };


    /**
     * Compose and returns title text if needed
     *
     * @returns string
     */
    const getTitle = () => {
        const { title, children } = props;
        return title === true
            ? stripTags(children, [])
            : (isString(title) && title) || undefined;
    };

    /**
    * Rendering the ShortcutViewItem
    *
    * @returns JSX
    */
    const render = () => {
        const { TagName, children, className, style } = props;
        return isString(children) && (
            <TagName
                className={className}
                title={getTitle()}
                dangerouslySetInnerHTML={{ __html: replaceHtml(children) }}
                style={style}
            />
        );
    };

    // The render call
    return render();
};

Highlight.propTypes = {
    children : PropTypes.string,
    stripTags: PropTypes.bool,
    TagName  : PropTypes.oneOf(['span', 'div', 'p', 'td', 'h2']),
    className: PropTypes.string,
    title    : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    style    : PropTypes.object

};

Highlight.defaultProps = {
    children : '',
    stripTags: false,
    TagName  : 'span'
};

Highlight.displayName = 'Highlight';


export default Highlight;
