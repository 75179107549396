import React, { Component }           from 'react';
import _                              from 'lodash';
import { Skeleton, Popover }          from 'antd';
import PropTypes                      from 'prop-types';
import { LimitChildrenDisplay, Icon } from 'helpers';
import { dataGet }                    from 'utils/api';

import './assets/ipc.less';

const WIPO_CLASSIFICATION_URL = 'https://ipcpub.wipo.int/?notion=scheme';

/**
* Display a list of IPC codes and their descriptions
*/
class Ipc extends Component {

    /**
    * Instantiate component
    */
    constructor(props) {
        super(props);

        this.state = {
            ipcCodesDescriptions: false,
        };
    }

    /**
    * Triggered when the component is ready
    */
    componentDidUpdate() {
        const ipcCodes               = this.getIpcCodes(),
            { ipcCodesDescriptions } = this.state;

        // We have to wait to receive the codes
        if (ipcCodes && ipcCodesDescriptions === false) {
            this.setState({
                ipcCodesDescriptions: {},
            });

            dataGet('/ipc/orbit', {
                data: { code: ipcCodes }
            }).then(response => {
                const { body } = response;

                this.setState({
                    ipcCodesDescriptions: body?.titles || {},
                });
            });
        }
    }

    /**
    * Return entities from props
    *
    * @return array
    */
    getIpcCodes() {
        const { model } = this.props,
            { IC }      = model || {};

        return IC;
    }

    /**
    * Open a new tab to the patent register
    *
    * @param {string} ipc
    */
    goToPatentRegister(ipc) {
        const cleanIpc = _.replace(ipc, /[-\s]/g, '');

        // IPC codes necessarily contain more than 4 characters. Ex: B02C-018/14
        if (cleanIpc.length < 4) {
            return;
        }

        /**
        * Step of building the link to the patent register
        *
        * Example of targeted link: https://ipcpub.wipo.int/?notion=scheme&version=20230101&symbol=A21D0002360000&menulang=en
        *   &lang=en&viewmode=f&fipcpc=no&showdeleted=yes&indexes=no&headings=yes&notes=yes&direction=o2n&initial=A&cwid=none
        *   &tree=yes&searchmode=smart
        *
        * Build with ipc code, example: A21D-002/36
        */
        const date               = new Date(),
            version              = `${date.getFullYear()}0101`,
            prefix               = cleanIpc.substring(0, 4),
            middleOriginal       = cleanIpc.substring(4),
            posSlash             = _.indexOf(middleOriginal, '/'),
            hasContentAfterSlash = posSlash !== -1 && middleOriginal.length > posSlash + 1,
            suffixOriginal       = hasContentAfterSlash ? middleOriginal.substring(posSlash + 1) : '000000',
            middleNew            = posSlash !== 0 ? middleOriginal.substring(0, posSlash) : '0000',
            middleFinal          = _.padStart(middleNew, 4, '0'),
            suffixFinal          = _.padEnd(suffixOriginal, 6, '0'),
            // If no middle and suffix: target at IPC group, otherwise target at ipc
            codeIpc              = (middleFinal + suffixFinal) === '0000000000' ? prefix : prefix + middleFinal + suffixFinal,
            url                  = `${WIPO_CLASSIFICATION_URL}&version=${version}&symbol=${codeIpc}&menulang=en&lang=en`
                + '&viewmode=f&fipcpc=no&showdeleted=yes&indexes=no&headings=yes&notes=yes&direction=o2n&initial=A&cwid=none'
                + '&tree=yes';

        window.open(url, '_blank');
    }

    /**
    * Decorate ipc code with popover
    *
    * @param {string} ipc
    *
    * @returns {JSX}
    */
    decorateWithPopover(ipc) {
        const {
                ipcCodesDescriptions,
            }              = this.state,
            ipcDescription = _.get(ipcCodesDescriptions, ipc),
            content        = (
                <div>
                    <span className="ipc">{ipc}</span>
                    <span className="description">{ipcDescription}</span>
                </div>
            );

        return (
            <Popover content={content}
                overlayClassName="ipc-code-description" placement="right"
            >
                <div className="ipc"
                    onClick={() => this.goToPatentRegister(ipc)}
                >
                    <span data-qa-key={ipc}>{ipc}</span>
                    <Icon id="collection-open" width={12}
                        height={12} color="var(--secondary-color)"
                    />
                </div>
            </Popover>
        );
    }

    /**
    * Render all the references as a list
    *
    * @return {JSX}
    */
    render() {
        const {
                ipcCodesDescriptions,
            }        = this.state,
            ipcCodes = this.getIpcCodes();

        // Prevent IPC Codes render
        if (!ipcCodes) {
            return null;
        }

        return (
            <div className="ipc-codes">
                <div className="category">IPC Codes</div>
                {_.isEmpty(ipcCodesDescriptions) ? (
                    <Skeleton active paragraph={false} />
                ) : (
                    <LimitChildrenDisplay max={5}>
                        {_.map(ipcCodes, ipc => this.decorateWithPopover(ipc))}
                    </LimitChildrenDisplay>
                )}
            </div>
        );
    }

}

Ipc.propTypes = {
    model: PropTypes.shape({
        IC: PropTypes.string,
    }),
};

Ipc.defaultProps = {
    model: false,
};

export default Ipc;
