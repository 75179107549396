import React, { Component }         from 'react';
import _                            from 'lodash';
import { Modal }                    from 'antd';
import PropTypes                    from 'prop-types';

// CSS
import './assets/btt2popin.less';


/**
 * Insight button to popin component
 *
 * @param Component content A single element
 */
class Btt2Popin extends Component {

    /**
    * Initialize btt2popin component
    *
    * @return self
    */
    constructor() {
        super();

        this.state = {
            isOpen: false
        };

        _.bindAll(this, 'onClick', 'onCancel');
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.setState({
            isOpen: false
        });
    }

    /**
     * On click button
     */
    onClick() {
        this.setState({ isOpen: true });
    }


    /**
     * On close popin
     */
    onCancel() {
        this.setState({ isOpen: false });
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                className, button,
                content, title, width
            }          = this.props,
            { isOpen } = this.state;

        return (
            <>
                <div className={`${className}-button`} onClick={this.onClick}>
                    {button}
                </div>
                {isOpen && (
                    <Modal
                        title={title}
                        open={isOpen}
                        className={`btt-2-popin-wrapper ${className}-popin`}
                        width={width}
                        onCancel={this.onCancel}
                        footer={null}
                        confirmLoading
                    >
                        {content}
                    </Modal>
                )}
            </>
        );
    }

}

/**
 * Validate properties for current Component
 */
Btt2Popin.propTypes = {
    button   : PropTypes.element.isRequired,
    content  : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    title    : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    width    : PropTypes.number,
};


export default Btt2Popin;
