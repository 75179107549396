// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailed-list .List {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
}
.detailed-list .List > div {
  display: flex;
  position: relative;
}
.detailed-list .List > div > div {
  padding-bottom: 10px;
}
.list .select {
  padding-left: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Collection/assets/detailedList.less"],"names":[],"mappings":"AAAA;EAEQ,aAAA;EACA,WAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AAAR;AANA;EASY,aAAA;EACA,kBAAA;AAAZ;AAVA;EAcgB,oBAAA;AADhB;AAOA;EAEQ,kBAAA;AANR","sourcesContent":[".detailed-list {\n    .List {\n        display       : flex;\n        width     : 100%;\n        flex-direction: column;\n        gap           : 10px;\n        padding       : 10px 20px;\n\n        > div {\n            display : flex;\n            position: relative;\n\n\n            > div {\n                padding-bottom: 10px;\n            }\n        }\n    }\n}\n\n.list {\n    .select {\n        padding-left: 40px;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
