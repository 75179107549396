// Import libs
import React, { Component }           from 'react';
import _                              from 'lodash';
import { connect }                    from 'react-redux';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';
import { Icon }                       from 'helpers';
import { getUserViewItemFromModel }   from 'store/actions/userView';


/**
 * Display a list row
 *
 */
class Tags extends Component {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);
    }


    /**
     * Render the tag
     *
     * @param {string} tagName
     * @param {int} i
     * @returns
     */
    renderTag(tagName, i) {
        const {
                search, tags, entity, size
            }                = this.props,
            sourceActiveTags = _.get(search, 'settings.tags', false),
            tag              = tags.find(tagObj => tagObj.id === tagName),
            {
                label, icon, color,
                id,

            }           = tag,
            tagsInScope = !sourceActiveTags
                || sourceActiveTags[id] && sourceActiveTags[id].includes(entity.id),
            style                  = {
                color,
                right   : i * 10,
                zIndex  : i,
                height  : size,
                width   : size,
                fontSize: size
            };

        return (
            <Icon
                key={label}
                type={icon}
                theme={tagsInScope ? 'filled' : 'outlined'}
                color={color}
                title={label}
                style={style}
                height={size}
                width={size}
                className="icon"
            />
        );
    }


    /**
    * Render the tags according to the entity.
    *
    * @return JSX
    */
    render() {
        const {
                entity, getUserViewItemFromModel,
            } = this.props,
            streamEntity       = getUserViewItemFromModel(entity, 'bookmark'),
            bookmark           = streamEntity && streamEntity.model,
            { tags: tagsName } = bookmark || {},
            hasTag             = tagsName?.length > 0;

        // No tags ?
        if (!hasTag) {
            return false;
        }

        return (
            <div className="tags">
                {tagsName.map((tagName, i) => this.renderTag(tagName, i))}
            </div>
        );
    }

}

Tags.propTypes = {
    tags                    : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    search                  : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    entity                  : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    size                    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getUserViewItemFromModel: PropTypes.func,
};

Tags.defaultProps = {
    size: '13px'
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
});

export default connect(mapStateToProps, {
    getUserViewItemFromModel
})(Tags);

