import Immutable      from 'immutable';

import * as types     from '../actions/types/resource';
import { initialMap } from '../initialStates';

/**
* Add the response to the stock
*/
const storeResponseToStock = (state, payload) => {
    const mineral = state.get(payload.key);

    if (!mineral) {
        return state;
    }

    return state.set(
        payload.key,
        mineral.set('response', payload.response) // Set the raw data
            .set('uri', payload.uri)              // Keep uri
            .set('isLoaded', true)                // Switch the loaded state to true
            .set('isLoading', false)              // Swith the loading state to false
    );
};

/**
 * Manage elements resource slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const Resource = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        // Create the slot in supply to store minerals
        case types.RESOURCE_LOADING:
            return state.set(action.payload.key, new Immutable.Map({
                ...action.payload.request,
                isLoading: true
            }));

        // Remove the specified key
        case types.RESOURCE_DELETE:
            return state.delete(action.payload);

        // Update the supply with collected data
        case types.RESOURCE_LOADED:
            return storeResponseToStock(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Resource;
