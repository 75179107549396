// Import libs
import React, { PureComponent }                 from 'react';
import PropTypes                            from 'prop-types';

import _                                    from 'lodash';
import { Skeleton }                         from 'antd';

import { getSobjImage }                     from 'utils/text';
import { Flag, Collection, Highlight }      from 'helpers';
import Legals                               from '../utils/Legals';

import './assets/patent.less';

/**
* Display a Patent
*
*/
class Patent extends PureComponent {

    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity } = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the patent image for lists
    *
    * @return JSX
    */
    renderImage() {
        const { entity, allowExport } = this.props;

        return (
            <td className="image" colSpan={allowExport ? 3 : 1}>
                {entity.IMG && _.isArray(entity.IMG) && entity.IMG.length > 0 && (
                    <img src={getSobjImage(entity.IMG[0])} alt="" />
                )}
            </td>
        );
    }

    /**
    * Render the abstract part
    *
    * @return JSX
    */
    renderListAbstract() {
        const { entity } = this.props;

        return (
            <td className="abstract">
                <div className="label">Abstract</div>
                <Highlight
                    className="content content-ellipsis"
                    TagName="div"
                    stripTags
                >
                    {entity.AB}
                </Highlight>
            </td>
        );
    }

    /**
    * Render complementary informations
    *
    * @return JSX
    */
    renderOtherInfos() {
        const { entity } = this.props;

        return (
            <td className="other-info" colSpan="3">
                {entity.ALIVE_IN && _.isArray(entity.ALIVE_IN) && entity.ALIVE_IN.length > 0 && (
                    <div className="alive-in">
                        <span className="label">Alive in</span>
                        <span className="value">
                            {_.map(entity.ALIVE_IN, (country) => (
                                <Flag
                                    key={country}
                                    width={21}
                                    height={16}
                                    iso={country}
                                    borderColor="#dddddd"
                                    borderSize={1}
                                />
                            ))}
                        </span>
                    </div>
                )}
                {entity.TECD && (
                    <div className="domains">
                        <span className="label">Technical domains</span>
                        <span className="value">{entity.TECD.split('<br/>').join(', ')}</span>
                    </div>
                )}
                <Legals opposition={entity.OPPOSITION} litigation={entity.litigation} />
            </td>
        );
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                entity, showExpandedContent, onClick,
                renderActiveStatus, renderModelAction,
                renderOpenableIcon,
            }        = this.props,
            patentId = entity.ID?.replace('_', ' ');

        if (showExpandedContent) {
            return false;
        }

        // Real row display
        return (
            <>
                <td className="ellipsis patent-number" title={patentId}>{patentId}</td>
                <td className="label clickable" onClick={onClick}>
                    <div className="left">
                        <Highlight
                            TagName="div"
                            className="ellipsis"
                            title
                        >
                            {entity.label}
                        </Highlight>
                        {renderOpenableIcon()}
                    </div>
                    {renderActiveStatus()}
                    {renderModelAction()}
                </td>
                <td className="ellipsis owner">
                    {entity.authority && (
                        <Collection
                            entities={entity.authority}
                            type="orgunit"
                            mode="inline"
                            allowExport={false}
                            onClick={onClick}
                        />
                    )}
                </td>
                <Highlight TagName="td" className="application-date">{entity.EAPD}</Highlight>
                <Highlight TagName="td" className="publication-date">{entity.EPD}</Highlight>
            </>
        );
    }

    /**
    * Render loading patent row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the expandRow content for the list
    *
    * @return JSX
    */
    renderExpandedContent() {
        const { showExpandedContent } = this.props;

        if (!showExpandedContent) {
            return false;
        }

        return (
            <>
                {this.renderImage()}
                {this.renderListAbstract()}
                {this.renderOtherInfos()}
            </>
        );
    }

    /**
    * Render the patent row for lists display
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render patent row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Patent.propTypes = {
    allowExport        : PropTypes.any,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    entity             : PropTypes.shape({
        AB        : PropTypes.any,
        ALIVE_IN  : PropTypes.any,
        EPD       : PropTypes.any,
        EAPD      : PropTypes.any,
        ID        : PropTypes.any,
        IMG       : PropTypes.any,
        OPPOSITION: PropTypes.any,
        TECD      : PropTypes.any,
        label     : PropTypes.any,
        authority : PropTypes.any,
        isLoading : PropTypes.any,
        litigation: PropTypes.any
    }).isRequired,
};

Patent.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    renderActiveStatus : () => {},
    renderOpenableIcon : () => {},
};

export default Patent;
