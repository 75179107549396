import PropTypes                          from 'prop-types';
import ImmutablePropTypes                 from 'react-immutable-proptypes';
import { withRouter }                     from 'react-router-dom';
import React, { useState, useEffect }     from 'react';
import { connect }                        from 'react-redux';
import { navigateToSearch }               from 'store/actions/navigation';
import { entityIsInNewsletter }           from 'store/actions/userView';
import { pluralize }                      from 'utils/text';
import { getDeepPropertyValues }          from 'utils/object';
import { Collection }                     from 'helpers';

/**
* Render the bookmarks in tree or flat style
* React Functional Components (Hooks)
*
* @return JSX
*/
function Flat(props) {  // eslint-disable-line max-lines-per-function
    const [firstRender, setFirstRender] = useState(true);

    /**
    * Triggered when the component is ready
    */
    useEffect(() => {
        setFirstRender(false);
    });

    /**
    * Get path of a bookmark
    *
    * @returns String
    */
    const getFolderPath = (entity) => {
        const folders     = getDeepPropertyValues(entity, 'parentFolder', 'parentFolder'),
            foldersLabels = folders.map(folder => folder.label);

        foldersLabels.pop();

        return foldersLabels.reverse().join(' / ');
    };


    /**
     *  Rendering the folder path of the bookmark.
     *
     * @returns JSX
     */
    const renderSuffix = (entity) => {
        const folderPath  = getFolderPath(entity);

        return folderPath && (
            <span key="folder-path" className="folder-path"
                title={folderPath}
            >
                {folderPath}
            </span>
        );
    };


    /**
    *  Rendering the bookmarks in a flat list.
    *
    * @returns JSX
    */
    const render = () => {
        const {
                filters, hideBookmarksCount, bookmarks,
                onClickBookmark,
            }               = props,
            bookmarksCount  = bookmarks.size;

        return !firstRender && (
            <div className="flat-style">
                {bookmarksCount === 0 ? (
                    <p className="empty">
                        No bookmark found
                    </p>
                ) : (
                    <Collection
                        allowExport={false}
                        mode="block"
                        entities={bookmarks.toArray()}
                        logoSize={18}
                        onClick={onClickBookmark}
                        filters={filters}
                        renderSuffix={renderSuffix}
                        useLoading
                    />
                )}
                {!hideBookmarksCount && bookmarksCount > 0 && (
                    <div className="bookmarks-count">
                        {`${bookmarksCount} ${pluralize('bookmark', bookmarksCount)}`}
                    </div>
                )}
            </div>
        );
    };

    // The render call
    return render();
}

Flat.propTypes = {
    bookmarks                : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarkFoldersStreamList: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarkFoldersStreamMap : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    newsletters              : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    tags                     : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filters                  : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    learnKnowledge           : PropTypes.func,
    navigateTo               : PropTypes.func,
    onClick                  : PropTypes.func,
    navigateToSearch         : PropTypes.func,
    entityIsInNewsletter     : PropTypes.func,
    orderBy                  : PropTypes.string,
    textFilter               : PropTypes.string,
    hideBookmarksCount       : PropTypes.bool,
};

Flat.defaultProps = {
    hideBookmarksCount: false,
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarkFoldersStreamMap : state.getIn(['userView', 'bookmark_folder', 'map']),
    bookmarkFoldersStreamList: state.getIn(['userView', 'bookmark_folder', 'list']),
});

export default connect(mapStateToProps, {
    navigateToSearch,
    entityIsInNewsletter,
})(withRouter(Flat));

