import React, { Component }        from 'react';
import _                           from 'lodash';
import PropTypes                   from 'prop-types';
import {
    arc            as d3Arc,
    scaleLinear    as d3ScaleLinear,
    scaleBand      as d3ScaleBand,
    range          as d3Range,
    interpolateRgb as d3InterpolateColor,
}                                  from 'd3';

import Animation                   from '../Animation';
import { Flag }                    from 'helpers';

import { rad2Deg }                 from 'utils/svg';

import './DoubleRadialBar/main.less';

const animationDuration = 400;

// Screenplay for animation
const scenario = {
    scenes: {
        general: {
            steps: {
                main: [
                    { attribute: 'radAngle', duration: animationDuration },
                    { attribute: 'angle', duration: animationDuration },
                ],
            },
            defaults: {
                radAngle: Math.PI,
                angle   : 180,
            },
        },
    }
};

/**
 * The double radial bar graph Component
 *
 */
class DoubleRadialBar extends Component {

    /**
    * Update series (serie1 & serie2 ordered by value)
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateSeries(options) {
        const { nextProps, state } = options,
            { data, ringCount } = nextProps,
            dataSeries = _.get(data, 'content', {}),
            series = [
                !_.isUndefined(dataSeries.serie1) ? dataSeries.serie1.slice(0, ringCount) : d3Range(ringCount),
                !_.isUndefined(dataSeries.serie2) ? dataSeries.serie2.slice(0, ringCount) : d3Range(ringCount),
            ];

        state.series = _.map(series, (serie) => _.orderBy(serie, ['value'], ['desc']));

        return { nextProps, state };
    }

    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScales(options) {
        const { nextProps, state } = options,
            { graphRay, series } = state,
            { ringCount }        = nextProps,
            { PI }               = Math,
            centerDiskRay        = graphRay * 0.15,
            ringScale = d3ScaleBand()
                .range([graphRay - centerDiskRay / 2, centerDiskRay / 2])
                .domain(d3Range(ringCount || series[0].length))
                .paddingInner(0.1),
            bandwidth            = ringScale.bandwidth(),
            circleRadius         = bandwidth / 2,
            step                 = ringScale.step(),
            // Padding          = step * ringScale.paddingInner(),
            // CenterRingOffset = padding + step / 2,
            arcScales = _.map(
                d3Range(ringCount) || series[0],
                (d, serieNum) => {
                    const ringRay            = step * serieNum + centerDiskRay,
                        offsetCircleAngle0 = Math.acos((circleRadius + centerDiskRay / 2) / (ringRay + circleRadius)),
                        offsetCircleAngle1 = Math.acos(circleRadius / (ringRay + circleRadius));
                    return d3ScaleLinear()
                        .range([
                            PI / 2 - offsetCircleAngle0,
                            PI - PI / 2 + offsetCircleAngle1
                        ])
                        .domain([0, 1]);
                }
            );

        state.scales = { ringScale, arcScales, centerDiskRay };

        return { nextProps, state };
    }

    /**
    * Update stats in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateStats(options) {
        const { nextProps, state } = options;

        return { nextProps, state };
    }

    /**
    * Update graph center
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateGraphCenter(options) {
        const { nextProps, state  }     = options,
            { innerWidth, innerHeight } = state,
            { margin }                  = nextProps,
            isWide                      = innerWidth / 2 > innerHeight / 2,
            graphRay                    = isWide
                ? innerHeight / 2 - margin
                : innerWidth / 2  - margin;

        state.graphCenter = {
            x: innerWidth / 2,
            y: innerHeight / 2
        };

        state.graphRay = graphRay;

        return { nextProps, state };
    }

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            {
                color,
                background,
                gradientColors
            } = nextProps;

        state.colors = {
            text: color,
            arc : [
                gradientColors[1],
                gradientColors[0],
            ],
            backgroundArc: background
        };

        state.colors.gradient = d3InterpolateColor(state.colors.arc[0], state.colors.arc[1]);

        return { nextProps, state };
    }

    /**
    * Update plotData in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state }                  = options,
            { colors, scales, series }              = state,
            { ringScale, arcScales, centerDiskRay } = scales,
            ringCount                               = ringScale.domain().length,
            { PI }                                  = Math,
            plotData                                = [],
            step                                    = ringScale.step(),
            bandwidth                               = ringScale.bandwidth();

        _.each(series, (serie, serieIndex) => _.each(serie, (d, index) => {
            const reversedIndex = ringCount - 1 - index,
                arcScale        = arcScales[reversedIndex] || (() => 0),
                radAngle        = PI + arcScale(d.value) * (serieIndex === 0 ? 1 : -1),
                ringRay         = step * reversedIndex + centerDiskRay,
                customId        = `${serieIndex}-${reversedIndex}`,
                backgroundArc   = d3Arc()
                    .cornerRadius(bandwidth)
                    .innerRadius(ringRay)
                    .outerRadius(ringRay + bandwidth)
                    .startAngle(PI)
                    .endAngle(PI + PI * (serieIndex === 0 ? 1 : -1));

            plotData.push({
                id        : `bar-${customId}`,
                key       : `bar-${customId}`,
                background: true,
                color     : colors.backgroundArc,
                path      : backgroundArc(),
                radAngle,
                ringRay,
                bandwidth,
            });

            plotData.push({
                id          : `${serieIndex === 0 ? 'G' : 'D'}-${d.id || customId}`,
                serieIndex,
                country     : d.id,
                circleRadius: bandwidth / 2,
                iconOpacity : 1,
                key         : `background-${customId}`,
                label       : d.label,
                color       : colors.gradient(reversedIndex / ringCount),
                angle       : rad2Deg(radAngle),
                radAngle,
                ringRay,
                bandwidth,
            });
        }));

        state.plotData = plotData;

        state.maxSerie3 = _.maxBy(series.serie3, (obj) => obj.value);

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderDefs', 'render', 'renderGraph');

        this.state = {};

        // Make a uuid
        this.id = _.uniqueId('bar-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - scales (D3Scales object) of the landmark
    *          - stats
    *          - colors
    *          - plotData
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps) {
        const { margin } = nextProps,
            // Cascading function to update state
            updateState = _.flow([
                DoubleRadialBar.updateColors,       // All colors and gradient
                DoubleRadialBar.updateGraphCenter,  // Graph center (make place under graph for top serie3 label)
                DoubleRadialBar.updateSeries,       // Series odered by value asc
                DoubleRadialBar.updateScales,       // D3 scales
                DoubleRadialBar.updatePlotData,     // Location and size of bars
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    // Size of the chart
                    innerWidth : nextProps.width  - 2 * margin,
                    innerHeight: nextProps.height - 2 * margin
                }
            });

        return state;
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const {
                graphCenter,
                graphRay,
                colors,
                scales
            } = this.state,
            bandwidth = scales.ringScale.bandwidth(),
            circleRadius = bandwidth / 2 - 1;

        if (_.isUndefined(graphCenter)) { return null; }

        return (
            <defs>
                <mask id={`${this.id}-circleMask`} x="0"
                    y="0"
                >
                    <circle
                        cx="0"
                        cy="0"
                        r={circleRadius}
                        style={{ stroke: 'none', fill: '#ffffff' }}
                    />
                </mask>
                {/* Gradient of the radial bar */}
                <radialGradient
                    id={`${this.id}-gradient`}
                    gradientUnits="userSpaceOnUse"
                    cx={graphCenter.x}
                    cy={graphCenter.y}
                    r={graphRay}
                >
                    <stop offset="10%" stopColor={colors.arc[0]} />
                    <stop offset="90%" stopColor={colors.arc[1]} />
                </radialGradient>

            </defs>
        );
    }

    /**
    * Render a Arc graph
    *
    * @return html
    */
    renderRankLabels() {
        const { colors, series } = this.state,
            { scales, graphRay }              = this.state,
            { ringScale, centerDiskRay }      = scales,
            step                              = ringScale.step(),
            bandwidth                         = ringScale.bandwidth(),
            rankLabelsWidth                   = centerDiskRay + bandwidth / 4,
            textOffset                        = graphRay - bandwidth / 2;

        return (
            <g className="rankLabels">
                <rect
                    x={-rankLabelsWidth / 2}
                    y="0"
                    width={rankLabelsWidth}
                    height={graphRay}
                    shapeRendering="crispEdges"
                    style={{ fill: '#ffffff' }}
                />
                {_.map(series[0], (serie, index) => (
                    <g
                        key={`rank-${index}`}
                        transform={`translate( ${0}, ${textOffset - index * step} )`}
                    >
                        <text
                            dominantBaseline="middle"
                            textAnchor="end"
                            style={{ fontSize: bandwidth * 0.8, fill: colors.text }}
                        >
                            {(index + 1)}
                        </text>
                        <text
                            dominantBaseline="baseline"
                            textAnchor="start"
                            style={{ fontSize: bandwidth * 0.5, fill: colors.text }}
                        >
                            {index === 0 ? 'st' : (index === 1 ? 'nd' : (index === 2 ? 'rd' : 'th'))}
                        </text>
                    </g>
                ))}
            </g>
        );
    }

    /**
    * Render the svg elements
    *
    * @return html
    */
    renderSvgElements(plotData) {
        const { graphCenter } = this.state,
            { PI }            = Math;

        return (
            <g
                className="plotData"
                transform={`translate(${graphCenter.x},${graphCenter.y})`}

            >
                { plotData.map(data => {
                    const { ringRay, bandwidth, radAngle, key, color, label, path } = data,
                        dataArc  = d3Arc()
                            .innerRadius(ringRay)
                            .outerRadius(ringRay + bandwidth)
                            .startAngle(PI)
                            .endAngle(radAngle);

                    return (
                        <g key={`g-${key}`}>
                            <path
                                key={`path-${key}`}
                                d={path || dataArc()}
                                style={{
                                    fill: color,
                                }}
                            />
                            <title>
                                {label}
                            </title>
                        </g>
                    );
                }) }
                {this.renderRankLabels()}
                {this.renderCircles(plotData)}
            </g>
        );
    }

    /**
    * Render circles
    *
    * @return html
    */
    renderCircles(plotData) {
        const circles = _.filter(plotData, (d) => !_.isUndefined(d.angle));

        return (
            <g
                className="circles"
            >
                {_.map(circles, d => {
                    const translate    = [d.ringRay + d.circleRadius, 0],
                        rotate       = d.angle - 90,
                        unRotate     = -d.angle + 90,
                        itemsOfSerie = circles.filter((c) => c.serieIndex === d.serieIndex);

                    return (
                        <g key={d.id} style={{ opacity: d.iconOpacity }}>
                            <Flag
                                key={`flag-${d.country}-${translate}-${rotate}`}
                                SVG
                                height={
                                    Math.round(d.circleRadius) * 2
                                        + (itemsOfSerie.indexOf(d) === 0 ? 15 : 0) // Add a scale factor for 1st
                                }
                                iso={d.country}
                                discShaped
                                borderSize={2}
                                borderColor="#ffffff"
                                translate={translate}
                                rotateRelative={rotate}
                                rotate={unRotate}
                            />
                        </g>
                    );
                })}
            </g>
        );
    }

    /**
    * Render best serie 3 label
    *
    * @return html
    */
    renderBestSerie3Label() {
        const{ maxSerie3, colors } = this.state,
            { graphRay }           = this.state,
            { width, height }      = this.props,
            fontSize               = (height - graphRay * 2) / 4,
            circleRadius           = Math.round(fontSize * 1.5);

        return _.isUndefined(maxSerie3)
            ? null
            : (
                <div
                    className="best-acceleration"
                    style={{
                        width,
                        top: graphRay * 2 + fontSize,
                    }}
                >
                    <Flag
                        height={circleRadius}
                        iso={maxSerie3.label}
                        discShaped
                        borderSize={2}
                        borderColor={colors.arc[0]}
                    />
                    <span
                        className="text"
                        style={{
                            lineHeight: `${circleRadius}px`,
                            color     : colors.text,
                            fontSize  : `${fontSize}px`
                        }}
                    >
                        Best acceleration last year
                    </span>
                </div>
            );
    }

    /**
    * Render series label
    *
    * @return html
    */
    renderSeriesLabel() {
        const { colors }       = this.state,
            { graphRay }       = this.state,
            {
                seriesLabels, margin, timeFrameLabel
            }                  = this.props,
            fontSize           = graphRay / 17,
            position           = margin + fontSize,
            positionFromCenter = graphRay / 1.6,
            textWidth          = graphRay * 0.75;

        return _.map(seriesLabels, (serieLabel, serieKey) => {
            const { label, small } = serieLabel,
                smallLabel         = timeFrameLabel || small;

            return (
                <div
                    key={`serieLabel-${serieKey}`}
                    className="serie-label"
                    style={{
                        color     : colors.text,
                        width     : textWidth,
                        lineHeight: `${fontSize}px`,
                        fontSize  : `${fontSize}px`,
                        top       : position,
                        marginLeft: serieKey === 'serie1' ? -1 * positionFromCenter - textWidth : positionFromCenter,
                        textAlign : serieKey !== 'serie1' ? 'left' : 'right'
                    }}
                >
                    {label}
                    <span className="small">{_.upperFirst(smallLabel)}</span>
                </div>
            );
        });
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { skeleton, skeletonGradient } = this.props,
            { plotData }                     = this.state,
            style = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) { return (<div className="DoubleRadialBar skeleton" style={style} />); }

        return (
            <Animation
                data={plotData}
                render={this.renderGraph}
                scenario={scenario}
            />
        );
    }

    /**
    * Render the graph
    *
    * @return html
    */
    renderGraph(plotData) {
        const {
            width,
            height,
            margin,
        } = this.props;

        return (
            <div className="DoubleRadialBar">
                <svg
                    width={width}
                    height={height}
                >
                    <g transform={`translate( ${margin} ${margin} )`}>
                        { /* Definitionsi */ }
                        {this.renderDefs()}
                        { /* Graph */ }
                        {this.renderSvgElements(plotData)}
                    </g>
                </svg>
                { /* Labels */ }
                {this.renderSeriesLabel()}
                {this.renderBestSerie3Label()}
            </div>
        );
    }

}

/* eslint-disable react/no-unused-prop-types */

DoubleRadialBar.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    dataParameters  : PropTypes.any,
    color           : PropTypes.string,
    background      : PropTypes.string,
    gradientColors  : PropTypes.arrayOf(PropTypes.string),
    seriesLabels    : PropTypes.oneOfType([PropTypes.object]),
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    margin          : PropTypes.number,
    ringCount       : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    skeleton        : PropTypes.bool,
    timeFrameLabel  : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

DoubleRadialBar.defaultProps = {
    margin          : 10,
    ringCount       : 5,
    color           : 'var(--insight-color)',
    background      : '#CEF3FE',
    gradientColors  : ['#72B0C2', '#AFDCE9'],
    seriesLabels    : [],
    skeletonGradient: false,
    skeleton        : false,
};

export default DoubleRadialBar;

