import React, { Component }               from 'react';
import _                                  from 'lodash';
import PropTypes                          from 'prop-types';
import {
    arc                 as d3Arc,
    pie                 as d3Pie,
    scaleLinear         as d3ScaleLinear,
    scalePoint          as d3ScalePoint,
    sum                 as d3Sum,
    hsl                 as d3Hsl,
}                                         from 'd3';
import { Popover }                        from 'antd';
import { transformNode }                  from 'utils/dom';
import {
    capitalize,
    formatNumberWithMagnitude,
    pluralize,
    formatInternationalNumber,
}                                         from 'utils/text';
import { linkPath, collision }            from 'utils/svg';
import Animation                          from '../Animation';
import NoData                             from '../../NoData'; //eslint-disable-line

import './Pie/main.less';

const animationDuration = 200;

// Screenplay for animation
const scenario = {
    scenes: {
        intro: {
            steps: {
                main: [
                    { attribute: 'label.opacity', duration: 0, value: 0 },
                    { attribute: 'opacity', duration: animationDuration },
                    { attribute: 'startAngle', duration: animationDuration },
                    { attribute: 'endAngle', duration: animationDuration }, // TODO make scene call { scene: 'pie slice resize' }
                ],
                after: [
                    { attribute: 'label.opacity', duration: animationDuration, value: 1 },
                ],
            },
            defaults: {
                'label.opacity': 0,
                opacity        : 0,
                startAngle     : 0,
                endAngle       : 0,
            },
        },
        loading: {
            steps: {
                main: [
                    { attribute: 'label.opacity', duration: animationDuration, value: 0.3 },
                    { attribute: 'opacity', duration: animationDuration, value: 0.3 },
                ]
            }
        },
        general: {
            steps: {
                main: [
                    { attribute: 'opacity', duration: 50 },
                ],
            }
        },
        contentChange: {
            steps: {
                before: [
                    { attribute: 'label.opacity', duration: animationDuration, value: 0 },
                ],
                main: [
                    { attribute: 'label.opacity', duration: 0, value: 0 },
                    {
                        attribute: 'inFilter',
                        duration : 1,
                        atEnd    : [
                            { attribute: 'startAngle', duration: animationDuration },
                            { attribute: 'endAngle', duration: animationDuration },
                        ]
                    }
                ],
                after: [
                    { attribute: 'label.opacity', duration: animationDuration, value: 1 },
                ],
            },
            defaults: {
                'label.opacity': 0,
                inFilter       : false,
                // TODO attribute value can be a formula
                // StartAngle : 'startAngle + (endAngle-startAngle)/2'
                // StartAngle :'reach(startAngle, endAngle)'
                startAngle     : [0, 6.283],  // 6.283 = 2 * Pi
                endAngle       : [0, 6.283],  // 6.283 = 2 * Pi
            },
        }
    },

};

/**
 * The tagCloud graph Component
 *
 */
class Pie extends Component {

    /**
    * Update maxItem
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateMaxItem(options) {
        const { nextProps, state }        = options,
            { regroupOtherOver, maxItem } = nextProps,
            { regroupTiny, content }      = nextProps,
            serie                         = content || [],
            { pieScale }                  = state;

        if ((regroupOtherOver === false && regroupTiny === 0) || serie.length === 0) {
            state.maxItem = maxItem;
            return { nextProps, state };
        }

        let maxItemOver = 0,
            sum         = 0;

        _.each(serie, (item) => {
            sum += item.value;
            if (regroupTiny > 0 && pieScale(item.value) < regroupTiny) { return; }
            if (regroupOtherOver && pieScale(sum) > regroupOtherOver) { return; }
            maxItemOver += 1;
        });

        state.maxItem = maxItem === false || maxItem > maxItemOver ? maxItemOver : maxItem;

        return { nextProps, state };
    }

    /**
    * Update pieData
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePieData(options) {
        const { nextProps, state } = options,
            { content }            = nextProps,
            { maxItem }            = state,
            serie                  = content || [],
            pieData                = [],
            pie                    = d3Pie().value((d) => d.value).sort((d) => d.index),
            otherItem              = {
                id          : '_other_',
                label       : 'Other',
                value       : 0,
                groupedOther: true,
                index       : maxItem
            },
            otherItems             = [];

        _.each(serie, (item, i) => {
            if (maxItem === false || i < maxItem) {
                const label = item.label ? capitalize(item.label.toLowerCase()) : '';

                pieData.push({ ...item, label, id: _.isUndefined(item.id) ? label : item.id});
                return;
            }
            otherItems.push(item);
            otherItem.value += item.value;
        });

        // Manage other label
        if (otherItems.length > 0) {
            otherItem.otherLabels = `(${_.map(otherItems, (item) => item.label).join(', ')})`;

            pieData.push(otherItem);
        }

        state.pieData = pieData;
        state.arcs    = pie(pieData);

        return { nextProps, state };
    }

    /**
    * Update centerOffset
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateCenterOffset(options) {
        const { nextProps, state } = options,
            { domCenterGraph }     = nextProps,
            { pieData, pieScale }  = state;

        let sumValue = 0;

        const { showLabel } = nextProps,
            data            = _.filter(pieData, (d) => !d.groupedOther),
            hasLabelOnRight = _.map(data, (d) => {
                // Calculate sum
                const normalizedSumValue = pieScale(sumValue + d.value / 2); // On 2 (center of the pie part)

                sumValue += d.value;
                return data.length === 1 ? true : normalizedSumValue <= 0.5;
            }),
            pieHasLabelOn   = {
                right: hasLabelOnRight.filter((has)  => has).length > 0,
                left : hasLabelOnRight.filter((has)  => !has).length > 0
            };

        state.pieHasLabelOn = pieHasLabelOn;

        state.centerOffset = domCenterGraph ? 0 : (pieHasLabelOn.right && pieHasLabelOn.left) || !showLabel ? 0
            : (pieHasLabelOn.right ? -0.25 : 0.25);
        return { nextProps, state };
    }

    /**
    * Update maxRadius & pieRadius
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateRadius(options) {
        const { nextProps, state }                    = options,
            { innerWidth, innerHeight, centerOffset } = state,
            { pieSize, horizontalCenter }             = nextProps,
            sizeFromWidth                             = innerWidth < innerHeight,
            baseSize                                  = sizeFromWidth ? innerWidth : innerHeight,
            // Radius * size percent
            calculatedRadius                          = baseSize / 2 * pieSize / 100;

        // The max radius according X centerOffset
        state.maxRadius = innerWidth * (horizontalCenter - Math.abs(centerOffset));

        // The final pie radius
        state.pieRadius = calculatedRadius < state.maxRadius ? calculatedRadius : state.maxRadius;

        return { nextProps, state };
    }

    /**
    * Update plotData
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state }           = options,
            { filterValues, itemUnit }       = nextProps,
            { tooltipPercent, tooltipValue } = nextProps,
            { pieRadius, arcs }              = state,
            { overItemId }                   = state,
            hasSelection                     = (filterValues !== false && (_.isArray(filterValues) && filterValues.length > 0)),
            arc                              = d3Arc()
                .innerRadius(0)
                .outerRadius(pieRadius)
                .cornerRadius(pieRadius / 30)
                .startAngle((d) => d.startAngle)
                .endAngle((d) => d.endAngle),
            total                            = arcs.reduce((accumulator, arc) => accumulator + arc.value, 0);

        state.arc = arc;

        // Data path
        state.plotData = arcs.map((item) => {
            const percent          = Math.round(1000 * item.value / total) / 10,
                selected           = filterValues.includes(item.data.id),
                overed             = overItemId === item.data.id,
                itemUnitPluralized = itemUnit ? ` ${pluralize(itemUnit, item.value)}` : '';

            return {
                id           : item.data.id,
                startAngle   : item.startAngle,
                endAngle     : item.endAngle,
                value        : item.value,
                percent,
                groupedOther : item.data.groupedOther === true,
                inFilter     : selected,
                path         : arc(item),
                overed,
                opacity      : (!hasSelection || selected || overed) ? 1 : 0.3,
                otherLabels  : item.data.otherLabels,
                valueSentence: `<b>${item.data.label} `
                    + `${tooltipPercent ? ` ${percent}% ` : ''} `
                    + `${tooltipValue ? `(${formatNumberWithMagnitude(item.value, 1)}${itemUnitPluralized})` : ''}</b>`
            };
        });

        return { nextProps, state };
    }

    /**
    * Update labels
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateLabels(options) {
        const { nextProps, state }                        = options,
            {
                innerWidth, innerHeight, pieScale,
                pieRadius, pieData,  centerOffset,
            }                                             = state,
            { horizontalCenter }                          = nextProps,
            { rightLabelScale, leftLabelScale }           = Pie.getLabelScales(options, pieData),
            { labelAndLineSpace, linesSpace, labelWidth } = Pie.getLabelWidthAndLineSpace(options),
            pieCenter                                     = innerWidth * (horizontalCenter + centerOffset);

        let sumValue = 0;

        state.linesSpace = linesSpace;

        state.plotData = _.map(pieData, (d, i) => {
            // Calculate sum
            const normalizedSumValue = pieScale(sumValue + d.value / 2),  // On 2 (center of pie part)
                onRight              = pieData.length === 1 ? true : normalizedSumValue <= 0.5,
                angle                = pieScale(sumValue + d.value / 2) * 2 * Math.PI,
                circlePoint          = {
                    x: Math.sin(angle) * pieRadius,
                    y: Math.cos(angle) * pieRadius,
                },
                trueTop              = innerHeight / 2 - circlePoint.y,
                distributedTop       = pieData.length === 1
                    ? rightLabelScale(pieScale(d.value) <= 0.5 ? 0 : 1) // For one value place label on top or bottom
                    : onRight ? rightLabelScale(i) : leftLabelScale(pieData.length - i - 1);

            sumValue += d.value;

            return {
                ...state.plotData[i],
                label: {
                    text     : d.label,
                    opacity  : 1,
                    place    : onRight ? 'right' : 'left',
                    isOnRight: onRight,
                    top      : trueTop + (distributedTop - trueTop) / 2.5,
                    left     : onRight
                        ? pieCenter + pieRadius + linesSpace
                        : pieCenter - pieRadius - linesSpace - labelWidth,
                    width: labelAndLineSpace - linesSpace,
                    value: labelWidth,
                    circlePoint,
                    angle
                }
            };
        });

        return { nextProps, state };
    }

    /**
    * Update labelsLine
    *
    * @params item    object Item
    * @params options object { nextProps, state }
    *
    * @return string
    */
    static getPathLine(item, options) {
        const { nextProps, state }        = options,
            lineMargin                    = 5, // Split line and label
            {
                innerWidth, innerHeight,
                pieRadius, centerOffset
            }                             = state,
            { horizontalCenter, pieSize } = nextProps,
            center                        = { x: innerWidth * (horizontalCenter + centerOffset), y: innerHeight / 2 },
            { label }       = item,
            { circlePoint } = label,
            curveForce      = 1 + (
                1 * Math.abs(circlePoint.y / pieRadius)
                - Math.abs((circlePoint.y * circlePoint.x) / (pieRadius ** 2))
            ) * (100 - pieSize) / 50;

        return linkPath({
            source: {
                x      : center.x + circlePoint.x,
                y      : center.y - circlePoint.y,
                curveTo: {
                    x: center.x + circlePoint.x * curveForce,
                    y: center.y - circlePoint.y * curveForce,
                }
            },
            target: {
                x: label.isOnRight ? label.left - lineMargin : label.left + label.width + lineMargin,
                y: label.top
            }
        });
    }

    /**
    * Update labelsLine
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateLabelsLines(options) {
        const { nextProps, state } = options;

        state.plotData = _.map(state.plotData, (item) => ({
            ...item,
            label: {
                // Add line to label
                ...item.label,
                line: Pie.getPathLine(item, options)
            }
        }));

        return { nextProps, state };
    }

    /**
    * Update firstLabels
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateFirstLabels(options) {
        const { state, nextProps } = options,
            { pieData, maxItem }   = state,
            firstLabelsArray       = _.map(pieData, (d) => d.label).slice(0, maxItem);

        state.firstLabels = firstLabelsArray.join(' & ');

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            overItemId: null,
        };

        this.labelsRef         = React.createRef();
        this.graphRef          = React.createRef();
        this.graphcenteroffset = 0;

        _.bindAll(this, 'render', 'itemClick', 'itemOver', 'renderGraph', 'testLabelsCollision', 'centerGraph');

        // Make a uuid
        this.id = _.uniqueId('pie-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }


    /**
    * Center the graph
    *
    * @return void
    */
    centerGraph() {
        const { domCenterGraph } = this.props;
        if (domCenterGraph && this.graphRef.current && this.labelsRef.current) {
            const {
                    pieHasLabelOn, linesSpace
                }                  = this.state,
                graphEl            = this.graphRef.current,
                parentEl           = graphEl.parentNode,
                labelsEl           = this.labelsRef.current,
                // Get all labels
                leftLabelsEls      = labelsEl.querySelectorAll('.left > span'),
                rightLabelsEls     = labelsEl.querySelectorAll('.right > span'),
                // Pre-process labels width span and parent to get true width (ellipsis)
                leftLabelsWidth    = _.map(leftLabelsEls, (el) => _.min([el.offsetWidth, el.parentNode.offsetWidth])),
                rightLabelsWidth   = _.map(rightLabelsEls, (el) => _.min([el.offsetWidth, el.parentNode.offsetWidth])),
                // Max width labels
                maxLeftLabelWidth  = _.max(leftLabelsWidth) || 0,
                maxRightLabelWidth = _.max(rightLabelsWidth) || 0,
                // Calculate x offset
                offset             = (
                    maxLeftLabelWidth - maxRightLabelWidth
                        - (pieHasLabelOn.left ? 0 : linesSpace)
                        + (pieHasLabelOn.right ? 0 : linesSpace)
                ) / 2;

            transformNode(parentEl, { x: `${offset}px`, y: 0 });
            transformNode(labelsEl, { x: `${offset}px`, y: 0 });

            this.graphcenteroffset = offset;
        }
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
                margin, content, fontSize,
                fontSizeAuto
            }                        = nextProps,
            { lastContent }          = prevState,
            innerWidth               = nextProps.width - 2 * margin,
            innerHeight              = nextProps.height - 2 * margin,
            // Cascading function to update state
            updateState              = _.flow([
                Pie.updateMaxItem,      // MaxItem
                Pie.updatePieData,      // PieData & arcs
                Pie.updateCenterOffset, // CenterOffset
                Pie.updateRadius,       // MaxRadius & pieRadius
                Pie.updatePlotData,     // PlotData
                Pie.updateLabels,       // Labels
                Pie.updateLabelsLines,  // LabelsLines
                Pie.updateFirstLabels,  // FirstLabels
            ]),
            useRadius                = fontSizeAuto && updateState.pieRadius,
            dynamicFontSize          = useRadius ? updateState.pieRadius / 25 : fontSize,
            { state }                = updateState({
                nextProps,
                state: {
                    ...prevState,
                    fontSize     : dynamicFontSize,
                    innerWidth,
                    innerHeight,
                    colors       : Pie.getColors({ nextProps }),
                    pieScale     : d3ScaleLinear().range([0, 1]).domain([0, d3Sum(content || [], (d) => d.value)]),
                    lastContent  : content || lastContent,
                    contentChange: lastContent !== content,
                },
            });

        return state;
    }

    /**
    * Get labels scales
    *
    * @params options object { nextProps, state }
    * @params data    array
    *
    * @return object { nextProps, state}
    */
    static getLabelScales(options, data) {
        const { state }               = options,
            { pieScale, innerHeight } = state,
            rightLabelCount           = Pie.getLabelsOnRightCount(data, pieScale),
            rightLabelScale           = d3ScalePoint()
                .range([0, innerHeight])
                .padding(innerHeight * 0.001)
                .domain(_.range(data.length === 1 ? 2 : rightLabelCount)),
            leftLabelScale                    = d3ScalePoint()
                .range([0, innerHeight])
                .padding(innerHeight * 0.001)
                .domain(_.range(data.length - rightLabelCount));

        return { rightLabelScale, leftLabelScale };
    }

    /**
    * Get labelsWidth and linesSpace
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static getLabelWidthAndLineSpace(options) {
        const { state }                  = options,
            { centerOffset, innerWidth } = state,
            { maxRadius, pieRadius }     = state,
            emptySpace                   = innerWidth - pieRadius * 2 - (centerOffset !== 0 ? maxRadius - pieRadius : 0),
            labelAndLineSpace            = emptySpace / (centerOffset === 0 ? 2 : 1),
            linesSpace                   = labelAndLineSpace / 4,
            labelWidth                   = labelAndLineSpace - linesSpace;

        return { labelAndLineSpace, labelWidth, linesSpace };
    }

    /**
    * GetArcCorners
    *
    * @param options :
    *   arc          object D3 arc
    *   radius       number Distance of corners
    *   cornerRadius number Corner margin
    *   offset       object {x, y}
    *
    * @return object
    */
    static getArcCorners(options) {
        const {
                arc,
                radius,
                cornerRadius,
                offset
            } = options,
            midAngle = arc.startAngle + (arc.endAngle - arc.startAngle) / 2;

        return {
            start: {
                x: offset.x + 1 * cornerRadius * Math.cos(arc.startAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(arc.startAngle),
                y: offset.y + 1 * cornerRadius * Math.sin(arc.startAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(arc.startAngle)
            },
            middle: {
                x: offset.x - 1 * cornerRadius * Math.cos(midAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(midAngle),
                y: offset.y - 1 * cornerRadius * Math.sin(midAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(midAngle)
            },
            end: {
                x: offset.x + -1 * cornerRadius * Math.cos(arc.endAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(arc.endAngle),
                y: offset.y + -1 * cornerRadius * Math.sin(arc.endAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(arc.endAngle)
            }
        };
    }

    /**
    * Get colors
    *
    * @param options object { nextProps, state }
    *
    * @return object
    */
    static getColors(options) { // eslint-disable-line
        const { nextProps }                = options,
            { color, dataColors, flat }    = nextProps,
            { luminanceCenter, luminance } = nextProps,
            dc                             = d3Hsl(color),
            baseColor                      = _.set(_.clone(dc), 'l', luminance).toString(),
            baseOverColor                  = _.set(_.clone(dc), 'l', luminance - 0.05).toString(),
            baseFilterColor                = _.set(_.clone(dc), 'l', luminance - 0.07).toString(),
            otherColor                     = _.set(_.clone(dc), 'l', 0.88).toString(),
            colors                         = {
                gradient: [
                    baseColor,
                    flat ? baseColor : _.set(_.clone(dc), 'l', luminanceCenter).toString(),
                ],
                filter: [
                    flat ? baseFilterColor : _.set(_.clone(dc), 'l', 0.5).toString(),
                    flat ? baseFilterColor : _.set(_.clone(dc), 'l', 0.3).toString(),
                ],
                other: [
                    flat ? otherColor : _.set(_.clone(dc), 'l', 0.78).toString(),
                    otherColor
                ],
                over: [
                    baseOverColor,
                    flat ? baseOverColor : _.set(_.clone(dc), 'l', luminanceCenter - 0.15).toString(),
                ],
                pie: '#10285D',
            };

        _.each(dataColors, (datumColor, dataKey) => {
            const dc            = d3Hsl(datumColor),
                baseColor       = _.set(_.clone(dc), 'l', luminance).toString(),
                baseOverColor   = _.set(_.clone(dc), 'l', luminance - 0.05).toString(),
                baseFilterColor = _.set(_.clone(dc), 'l', luminance - 0.07).toString(),
                otherColor      = _.set(_.clone(dc), 'l', 0.88).toString();

            colors[`${dataKey}gradient`] = [
                baseColor,
                flat ? baseColor : _.set(_.clone(dc), 'l', luminanceCenter).toString(),
            ];

            colors[`${dataKey}filter`] = [
                flat ? baseFilterColor : _.set(_.clone(dc), 'l', 0.5).toString(),
                flat ? baseFilterColor : _.set(_.clone(dc), 'l', 0.3).toString(),
            ];

            colors[`${dataKey}other`] = [
                flat ? otherColor : _.set(_.clone(dc), 'l', 0.78).toString(),
                otherColor
            ];

            colors[`${dataKey}over`] = [
                baseOverColor,
                flat ? baseOverColor : _.set(_.clone(dc), 'l', luminanceCenter - 0.15).toString(),
            ];
        });

        return colors;
    }

    /**
    * Get count of label on right
    *
    * @param options object data
    * @return integer
    */
    static getLabelsOnRightCount(data, pieScale) {
        let sumValue = 0;
        for (let i = 0; i < data.length; i += 1) {
            if (pieScale(sumValue + data[i].value / 2) > 0.5) { // On 2 (center of the pie part)
                return i;
            }
            sumValue += data[i].value;
        }
        return data.length;
    }

    /**
    * Render legend on bottom
    *
    * @return html
    */
    getLegend() {
        const { legend, data } = this.props,
            { stats } = data;

        let filledLegend = legend;
        _.each(stats, (val, key) => {
            const reg = new RegExp(`%${key}%`, 'g');
            filledLegend = filledLegend.replace(reg, val);
            if (_.isObject(val)) {
                _.each(val, (subVal, subKey) => {
                    const subReg = new RegExp(`%${key}.${subKey}%`, 'g');
                    filledLegend = filledLegend.replace(subReg, subVal);
                });
            }
        });

        return filledLegend;
    }

    /**
    * Test labels collision
    */
    testLabelsCollision() {
        if (!this.labelsRef.current) {
            return;
        }

        const labelsNode = this.labelsRef.current.getElementsByClassName('label');
        for (let i = 0; i < labelsNode.length; i += 1) {
            this.modifyHeightAndWhiteSpace(labelsNode[i], true);
        }
        // Test each nodes with others
        for (let i = 0; i < labelsNode.length; i++) {
            const ii= i + 1,
                collide = collision(labelsNode[i], labelsNode[ii]);
            if (collide) {
                this.modifyHeightAndWhiteSpace(labelsNode[i]);
                this.modifyHeightAndWhiteSpace(labelsNode[ii]);
            }
        }
    }

    /**
    * Add ellipsis on the node
    *
    */
    modifyHeightAndWhiteSpace(node, remove = false) {
        const { style } = node;

        node.style.height           = !remove ? style['line-height'] : null; // eslint-disable-line no-param-reassign
        node.style['white-space']   = !remove ? 'nowrap' : null;             // eslint-disable-line no-param-reassign
    }

    /**
    * On item click
    *
    * @return boolean
    */
    itemClick(item) {
        const { filterCb, filterValues } = this.props;

        if (item.groupedOther) {
            return;
        }

        if (filterCb) {
            filterCb(_.xor(filterValues, [item.id]));
        }

        return true;
    }

    /**
    * On item over
    *
    * @return boolean
    */
    itemOver(item = null) {
        const { interactive } = this.props,
            noItem            = _.isNull(item);

        if (!interactive || !noItem && item.groupedOther) {
            return;
        }

        this.mouseOverTimeout = requestAnimationFrame(
            () => this.setState({ overItemId: noItem ? null : item.id })
        );

        return true;
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {  // eslint-disable-line max-lines-per-function
        const { colors, pieRadius } = this.state,
            { dataColors }          = this.props,
            gradientTypes           = dataColors ? _.keys({ ...dataColors, '': null }) : [''];

        return (
            <defs key={`${this.id}-defs`}>
                {gradientTypes.map(key => (
                    <g key={`${this.id}${key}`}>
                        {/* Gradient of the pie */}
                        <radialGradient
                            id={`${this.id}${key}-other`}
                            gradientUnits="userSpaceOnUse"
                            cx="0"
                            cy="0"
                            r={pieRadius}
                        >
                            <stop offset="10%" stopColor={colors[`${key}other`][0]} />
                            <stop offset="90%" stopColor={colors[`${key}other`][1]} />
                        </radialGradient>

                        {/* Gradient of the pie */}
                        <radialGradient
                            id={`${this.id}${key}-gradient`}
                            gradientUnits="userSpaceOnUse"
                            cx="0"
                            cy="0"
                            r={pieRadius}
                        >
                            <stop offset="10%" stopColor={colors[`${key}gradient`][0]} />
                            <stop offset="90%" stopColor={colors[`${key}gradient`][1]} />
                        </radialGradient>

                        {/* Gradient of the pie */}
                        <radialGradient
                            id={`${this.id}${key}-over`}
                            gradientUnits="userSpaceOnUse"
                            cx="0"
                            cy="0"
                            r={pieRadius}
                        >
                            <stop offset="10%" stopColor={colors[`${key}over`][0]} />
                            <stop offset="90%" stopColor={colors[`${key}over`][1]} />
                        </radialGradient>

                        {/* Gradient of the filtered item */}
                        <radialGradient
                            id={`${this.id}${key}-filter`}
                            gradientUnits="userSpaceOnUse"
                            cx="0"
                            cy="0"
                            r={pieRadius}
                        >
                            <stop offset="10%" stopColor={colors[`${key}filter`][0]} />
                            <stop offset="90%" stopColor={colors[`${key}filter`][1]} />
                        </radialGradient>
                    </g>
                ))}
            </defs>
        );
    }

    /**
     * Get item key for react rendering
     *
     * @returns string
     */
    getItemKey(item) {
        return  `${item.id}-${item.value}-${item.label.text}`;
    }


    /**
    * Render pie graph
    *
    * @return html
    */
    renderPie(plotData, progress) {  // eslint-disable-line max-lines-per-function
        const {
                pieSize, legendPlacement, horizontalCenter,
                otherColor, strokeColor, dataColors,
                filterCb, tooltipValue, interactive
            }                                = this.props,
            {
                innerWidth, centerOffset,
                innerHeight, arc,
                overItemId
            }                                = this.state,
            verticalOffset                   = legendPlacement === 'bottom' ? innerHeight * (pieSize - 100) / 100 / 2 : 0,
            fixedCenter                      = innerWidth * (horizontalCenter + centerOffset);

        return (
            <g
                className="pie"
                transform={`translate(${fixedCenter} ${innerHeight / 2 + verticalOffset})`}
            >
                { plotData.map((item) => {
                    const key        = this.getItemKey(item),
                        itemId       = dataColors ? item.id : '',
                        filled       = `url("#${this.id}${itemId}-gradient")`,
                        fillOver     = `url("#${this.id}${itemId}-over")`,
                        fillSelected = `url("#${this.id}${itemId}-filter")`,
                        filledOther  = `url("#${this.id}${itemId}-other")`;

                    return (
                        <g className="pie-slice" key={key}>
                            <Popover
                                key={key}
                                content={(
                                    <div className="tooltip-value">
                                        <div
                                            className="sentence"
                                            dangerouslySetInnerHTML={{ __html: tooltipValue ? item.valueSentence : '' }}
                                        />
                                        <div className="information">
                                            {item.otherLabels}
                                        </div>
                                    </div>
                                )}
                                arrow
                                trigger={interactive ? 'hover' : 'contextMenu'}
                            >
                                <path
                                    key={key}
                                    d={_.isNull(progress) ? item.path : arc(item) /* Direct Calculate path during animation */}
                                    onClick={() => this.itemClick(item)}
                                    onMouseOver={() => this.itemOver(item)}
                                    onMouseOut={() => this.itemOver()}
                                    style={{
                                        stroke       : strokeColor,
                                        strokeOpacity: item.opacity,
                                        strokeWidth  : 1.5,
                                        cursor       : filterCb && !item.groupedOther ? 'pointer' : null,
                                        fillOpacity  : item.opacity,
                                        fill         : overItemId === item.id
                                            ? fillOver
                                            : (item.inFilter ? fillSelected : (item.groupedOther
                                                ? (otherColor === false ? filledOther : otherColor)
                                                : filled)
                                            ),
                                    }}
                                />
                            </Popover>
                        </g>
                    );
                })}
            </g>
        );
    }

    /**
    * Render labels lines
    *
    * @return html
    */
    renderLabelsLines(plotData, progress) {
        const { showOtherLabel } = this.props,
            { colors }           = this.state;

        return _.map(plotData, item => {
            const itemId      = item.id || '',
                value         = item.value,
                colorGradient = colors[`${itemId}gradient`] ? colors[`${itemId}gradient`][1] : colors.gradient[0],
                colorOver     = colors[`${itemId}over`] ? colors[`${itemId}over`][1] : colors.over[0];

            if ((!item.groupedOther || showOtherLabel) && value > 0) {
                return (
                    <path
                        key={`label-${item.id}`}
                        className={`label-line ${item.label.place}`}
                        d={
                            !_.isNull(progress)
                                ? Pie.getPathLine(item, { nextProps: this.props, state: this.state })
                                : item.label.line
                        }
                        style={{
                            strokeOpacity: item.label.opacity,
                            stroke       : item.inFilter || item.overed ? colorOver : colorGradient,
                            fill         : 'none',
                            strokeWidth  : item.inFilter || item.overed ? 3 : 2,
                        }}
                    />
                );
            }

            return null;
        });
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels(plotData) { // eslint-disable-line max-lines-per-function
        const { colors, fontSize }                     = this.state,
            { margin, width }                          = this.props,
            { boldLabel, showOtherLabel, interactive } = this.props,
            { filterCb, tooltipValue, fontSizeAuto }   = this.props,
            { showValue }                              = this.props;

        return (
            <div
                className="labels"
                style={{ margin: `${margin}px`, transform: `translate(${this.graphcenteroffset}px, 0)` }}
                ref={this.labelsRef}
            >
                {_.map(plotData, (item) => {    // eslint-disable-line max-lines-per-function
                    if ((item.groupedOther && !showOtherLabel) || !item.value) {
                        return null;
                    }

                    const { label } = item,
                        value       = showValue && width > 500 ? formatInternationalNumber(item.value) : '';

                    return (
                        <div
                            key={`${label.angle}-${label.text}`}
                            className={`label ${label.place}`}
                            style={{
                                top       : label.top,
                                left      : label.left,
                                right     : label.right,
                                width     : `${label.width}px`,
                                fontWeight: boldLabel || item.inFilter || item.overed ? 'bold' : null,
                                opacity   : label.opacity,
                                color     : colors.pie,
                            }}
                        >
                            <Popover
                                content={(
                                    <div className="tooltip-value">
                                        <div
                                            className="sentence"
                                            dangerouslySetInnerHTML={{ __html: tooltipValue ? item.valueSentence : '' }}
                                        />
                                        <div className="information">
                                            {item.otherLabels}
                                        </div>
                                    </div>
                                )}
                                arrow
                                trigger={interactive ? 'hover' : 'contextMenu'}
                            >
                                <span
                                    onClick={() => this.itemClick(item)}
                                    onMouseOver={() => this.itemOver(item)}
                                    onMouseOut={() => this.itemOver()}
                                    style={{
                                        fontSize    : !fontSizeAuto ? fontSize : fontSize * 0.75,
                                        cursor      : filterCb && !item.groupedOther ? 'pointer' : null,
                                        color       : 'inherit',
                                        overflow    : 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace  : !fontSizeAuto ? 'nowrap' : 'normal',
                                    }}
                                >
                                    {label.text}
                                </span>

                                <span
                                    style={{
                                        fontSize  : !fontSizeAuto ? fontSize : fontSize * 0.75,
                                        cursor    : filterCb && !item.groupedOther ? 'pointer' : null,
                                        color     : 'inherit',
                                        whiteSpace: !fontSizeAuto ? 'nowrap' : 'normal',
                                    }}
                                >
                                    {value}
                                </span>
                            </Popover>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
    * Render legend on bottom
    *
    * @return html
    */
    renderBottomLegend() {
        const {
                innerWidth,
                innerHeight,
                colors,
                fontSize,
            } = this.state,
            {
                margin,
            } = this.props,
            legend = this.getLegend();

        if (legend === false) {
            return '';
        }

        return (
            <div
                className="legend"
                style={{
                    top      : innerHeight - fontSize,
                    left     : margin,
                    width    : innerWidth,
                    position : 'absolute',
                    textAlign: 'center'
                }}
            >
                <span
                    style={{
                        fontSize,
                        color: colors.pie,
                    }}

                >
                    {legend}
                </span>
            </div>
        );
    }

    /**
    * Render legend
    *
    * @return html
    */
    renderLeftLegend() {
        const {
                innerWidth,
                innerHeight,
                pieRadius,
                firstLabels,
                fontSize,
                colors
            } = this.state,
            {
                horizontalCenter,
                legendPadding
            } = this.props,
            legend = this.getLegend();

        if (legend === false) {
            return '';
        }

        return (
            <div
                className="legend"
                style={{
                    top    : innerHeight / 2,
                    left   : 0,
                    width  : innerWidth * horizontalCenter - pieRadius,
                    padding: `${legendPadding}px`
                }}
            >
                <div
                    style={{
                        fontSize: fontSize * 1.3,
                        color   : colors.pie,
                    }}
                >
                    {legend || firstLabels}
                </div>
            </div>
        );
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    render() {
        const { skeleton, skeletonGradient } = this.props,
            { noContent, color }             = this.props,
            { plotData, contentChange }      = this.state,
            style                            = skeleton ? { backgroundImage: skeletonGradient } : null,
            total                            = _.reduce(plotData, (sum, plot) => sum + plot.value, 0);

        if (skeleton) { return (<div className="Pie skeleton" style={style} />); }

        return plotData && total === 0
            ? (<NoData color={color} />)
            : (
                <Animation
                    data={plotData}
                    render={this.renderGraph}
                    scenario={scenario}
                    scene={noContent ? 'loading' : (contentChange ? 'contentChange' : 'general')}
                    atEndCb={this.testLabelsCollision}
                />
            );
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvg(plotData, progress) {
        const {
            width,
            height,
            margin,
            showLabel,
        } = this.props;

        return (
            <svg
                width={width}
                height={height}
                style={{ transform: `translate(${this.graphcenteroffset}px, 0)` }}
            >
                <g transform={`translate( ${margin} ${margin} )`} ref={this.graphRef}>
                    { /* Definitions */ }
                    {this.renderDefs()}
                    { /* Labels lines */ }
                    { /* Pie graph */ }
                    {this.renderPie(plotData, progress)}
                    { showLabel ? this.renderLabelsLines(plotData, progress) : _.noop() }
                </g>
            </svg>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderGraph(plotData, progress) {
        const {
                showLabel,
                legend,
                legendPlacement,
                onlySvg,
            }                    = this.props,
            renderLegendFunction = `render${capitalize(legendPlacement)}Legend`;

        return onlySvg
            ? this.renderSvg(plotData, progress)
            : (
                <div className="Pie">
                    { legend
                        ? !this[renderLegendFunction] ? null : this[renderLegendFunction]()
                        : null}
                    { this.renderSvg(plotData, progress)}
                    { showLabel ? this.renderLabels(plotData, progress) : _.noop() }
                </div>
            );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
Pie.propTypes = {
    boldLabel       : PropTypes.bool,
    color           : PropTypes.string,
    content         : PropTypes.any,
    dataColors      : PropTypes.object,
    domCenterGraph  : PropTypes.bool,
    filterCb        : PropTypes.func,
    filtersValues   : PropTypes.array,
    filterValues    : PropTypes.any,
    flat            : PropTypes.bool,
    fontSize        : PropTypes.number,
    height          : PropTypes.number.isRequired,
    horizontalCenter: PropTypes.number,
    interactive     : PropTypes.bool,
    fontSizeAuto    : PropTypes.bool,
    labelMargin     : PropTypes.number,
    legend          : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    legendPadding   : PropTypes.number,
    legendPlacement : PropTypes.string,
    luminance       : PropTypes.number,
    luminanceCenter : PropTypes.number,
    margin          : PropTypes.number,
    maxItem         : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    noContent       : PropTypes.any,
    onlySvg         : PropTypes.bool,
    otherColor      : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    pieSize         : PropTypes.number,
    regroupOtherOver: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    regroupTiny     : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    showLabel       : PropTypes.bool,
    showOtherLabel  : PropTypes.bool,
    showValue       : PropTypes.bool,
    skeleton        : PropTypes.bool,
    skeletonColor   : PropTypes.string,
    skeletonGradient: PropTypes.string,
    strokeColor     : PropTypes.string,
    tooltipPercent  : PropTypes.bool,
    tooltipValue    : PropTypes.bool,
    width           : PropTypes.number.isRequired,
    data            : PropTypes.shape({
        stats: PropTypes.any
    }),
};

/**
 * Default props value
 */
Pie.defaultProps = {
    margin          : 10,
    pieSize         : 80,
    horizontalCenter: 0.5,
    fontSize        : 12,
    color           : 'var(--insight-color)',
    strokeColor     : '#ffffff',
    boldLabel       : false,
    showLabel       : false,
    otherColor      : false,
    fontSizeAuto    : false,
    labelMargin     : 30,
    tooltipValue    : true,
    tooltipPercent  : true,
    legend          : false,
    regroupOtherOver: false,
    regroupTiny     : 0.02,
    luminanceCenter : 0.52,
    luminance       : 0.59,
    maxItem         : false,
    legendPadding   : 20,
    skeleton        : false,
    skeletonColor   : '',
    skeletonGradient: false,
    onlySvg         : false,
    domCenterGraph  : true,
    flat            : false,
    showOtherLabel  : false,
    showValue       : true,
    filterCb        : null,
    filtersValues   : [],
    legendPlacement : 'left',
    interactive     : true,
};

export default Pie;

