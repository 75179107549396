import React,
{
    useState,
    useEffect,
}                             from 'react';
import { connect }            from 'react-redux';
import PropTypes              from 'prop-types';
import { makeStrClassName }   from 'utils/text';
import ModelAction            from 'helpers/Action/Model';
import { learn }              from 'store/actions/knowledge';
import { Checkbox, Skeleton } from 'antd';

// Import dumb components by entity type
import Orgunit                from './DetailedCard/Orgunit';

// Import display CSS
import './assets/detailedCard.less';

/**
* Create the component collection
* For the moment, only organization lists are affected.
*/
const DETAILED_CARD_COMPONENTS = {
    orgunit: Orgunit,
};

/**
* Display a list row
*/
const DetailedCard = props => { // eslint-disable-line max-lines-per-function
    const [orgClasses, setOrgClasses] = useState([]),
        { learnKnowledge }            = props;

    useEffect(() => {
        learnKnowledge(['org_classes'])
            .then(data => setOrgClasses(data.orgClasses));
    }, []);

    /**
    * Select entity
    *
    * @param {object} e
    *
    * @returns {boolean}
    */
    const onSelectEntity = e => {
        const { entity, onSelectEntity } = props;

        e.stopPropagation();

        onSelectEntity(entity);

        return false;
    };

    /**
    * Render the actions button (...)
    *
    * @returns {JSX}
    */
    const renderModelAction = () => {
        const { entity, onClick } = props;

        return (
            <ModelAction entity={entity} onClick={onClick} />
        );
    };

    /**
    * Render the checkbox to select item to export
    *
    * @return {JSX}
    */
    const renderCheckbox = () => {
        const { allowExport, entity, isSelected } = props,
            { id, isLoading } = entity;

        return allowExport && (id || isLoading) ? (
            <Checkbox
                onClick={onSelectEntity}
                checked={isSelected}
                disabled={isLoading}
            />
        ) : null;
    };

    /**
    * Render a skeleton while entity is loading
    *
    * @returns {JSX}
    */
    const renderSkeleton = () => {
        return (
            <>
                <Skeleton active avatar={{ size: 70 }}
                    paragraph={{ rows: 3, width: ['75%', '50%', '40%'] }}
                />

                <Skeleton active className="skeleton-right"
                    paragraph={{ rows: 4, width: ['85%', '60%', '50%', '50%'] }}
                />
            </>
        );
    };

    /**
    * Render the document according to the props.
    *
    * @return {JSX}
    */
    const render = () => {
        const { entity, onClick }     = props,
            { userViewItem, tags }    = props,
            { compiledTags }          = props,
            { featuredActionsRender } = props,
            { type, isLoading }       = entity,
            DetailedCardComponents    = DETAILED_CARD_COMPONENTS[type],
            classNames                = [
                'detailed-card', type,
                { 'is-loading': isLoading },
            ];

        return (
            <div
                className={makeStrClassName(classNames)}
                onClick={!isLoading ? onSelectEntity : undefined}
            >
                {renderCheckbox()}

                {isLoading ? (
                    renderSkeleton()
                ) : (
                    <DetailedCardComponents
                        entity={entity} onClick={onClick}
                        bookmark={userViewItem?.model} tags={tags}
                        compiledTags={compiledTags}
                        featuredActionsRender={featuredActionsRender}
                        renderModelAction={renderModelAction}
                        orgClasses={orgClasses}
                    />
                )}
            </div>
        );
    };

    return render();
};

DetailedCard.propTypes = {
    userViewItem        : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    compiledTags        : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    entity              : PropTypes.shape({type: PropTypes.any}).isRequired,
    learnKnowledge      : PropTypes.func,
    onClick             : PropTypes.func,
    renderBookmarkAction: PropTypes.func,
};

export default connect(null, {
    learnKnowledge: learn,
})(DetailedCard);
