/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Icon }             from 'helpers';

import './assets/sentence.less';

/**
* Render The "Intelligence" menu
*
*/
class Intelligence extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this);
    }

    /**
    * Render the intelligence search
    *
    * @return JSX
    */
    renderRawSearch() {
        const { label,
                notEllipsed } = this.props,
            className         = `raw-input ${notEllipsed ? '' : 'ellipsis'}`;

        return (
            <div className={className}>
                {label}
            </div>
        );
    }

    /**
    * Render the generated by intelligence
    *
    * @return JSX
    */
    renderIntelligenceStamp() {
        return (
            <div className="intelligence-stamp">
                <table>
                    <tbody>
                        <tr>
                            <td className="generated-by">
                                Generated by
                            </td>
                            <td className="intelligence-icon">
                                <Icon id="orbit-intelligence" width={16}
                                    height={16}
                                />
                            </td>
                            <td>
                                Orbit
                            </td>
                            <td className="intelligence-blue">
                                <span>&nbsp;Intelligence</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
    * Render the human readable Intelligence from inputs
    *
    * @return JSX
    */
    render() {
        const { concept, stamp } = this.props;

        if (!concept) {
            return false;
        }

        return (
            <>
                <div className="sentence intelligence">
                    {this.renderRawSearch()}
                    {!stamp ? this.renderIntelligenceStamp() : null}
                </div>
                {stamp}
            </>
        );
    }

}

Intelligence.propTypes = {
    concept    : PropTypes.any,
    label      : PropTypes.string,
    stamp      : PropTypes.any,
    notEllipsed: PropTypes.bool
};

Intelligence.defaultProps = {
    notEllipsed: false,
};

export default Intelligence;
