/* eslint-disable */

import {
    extent as d3Extent,
    scaleLinear as d3ScaleLinear,
} from 'd3';

const GexfD3 =
    function () {
        var nodes = [];
        var links = [];
        var xExtent = [];
        var yExtent = [];
        var nodeScale = [1, 10];
        var layoutSize = [500, 500];
        var sizeDomain = [];
        var sizeScale, xScale, yScale ;
        var nodeHash = {};
        var linksCoworksDomain= [1,1];

        this.graph = function (gexfParsed) {
            if (!arguments.length) return true;

            var gNodes = gexfParsed._nodes;
            var gLinks = gexfParsed._edges;

            nodes = [];
            links = [];
            nodeHash = {};
            //Create JSON nodes array
            var x = 0;

            gNodes.forEach(function (gNode) {
                var newNode = { id: gNode.key, properties: {} };
                newNode.label = gNode.attributes.label || gNode.key;
                newNode.entityType = gNode.attributes.entityType;
                newNode.rgbColor = gNode.attributes.color || "rgb(122,122,122)";
                newNode.shape = gNode.attributes.shape;
                newNode.originalX = newNode.x;
                newNode.originalY = newNode.y;
                newNode.size = gNode.attributes.coworks;
                    nodeHash[gNode.key] = newNode;
                for (let y in gNode.attributes) {
                    if (!(typeof (gNode.attributes[y]) === "undefined") && !(gNode.attributes[y].toString() == "NaN")) {
                        newNode.properties[y] = gNode.attributes[y];
                    }
                }
                nodes.push(newNode);
                x++;
            });

            //Create JSON links array
            var x = 0;
            gLinks.forEach(function (gLink) {
                var newLink = { id: 'link-' + x, properties: {} };
                newLink.source = nodeHash[gLink.source.key];
                newLink.target = nodeHash[gLink.target.key];
                // A newLink.weight = _.random(0,1) ? 1 : 3;
                // A newLink.weight = gLink.weight;
                newLink.weight = gLink.attributes.coworks;
                //process attributes
                for (let y in gLink.attributes) {
                    newLink.properties[y] = gLink.attributes[y];
                    y++;
                }
                links.push(newLink)

                //domain Calcul
                if(linksCoworksDomain[0]>newLink.properties.coworks) { linksCoworksDomain[0]=newLink.properties.coworks }
                if(linksCoworksDomain[1]<newLink.properties.coworks) { linksCoworksDomain[1]=newLink.properties.coworks }
                x++;
            });

            // Set sizeScale function
            sizeDomain = d3Extent(nodes, function (d) { return parseFloat(d.size) });

            sizeScale = d3ScaleLinear().domain(sizeDomain).range(nodeScale);
            return this;
        }
        this.getNodes = function (incNodes) {
            if (!arguments.length) return nodes;
            nodes = incNodes;
            return this;
        }
        this.getLinks = function (incLinks) {
            if (!arguments.length) return links;
            links = incLinks
            return this;
        }

        this.getNodeScale = function (incScale) {
            if (!arguments.length) return sizeScale;
            nodeScale = incScale;
            sizeScale = d3ScaleLinear().domain(sizeDomain).range(nodeScale);
            return this;
        }

        this.getSizeDomain = function () {
            return sizeDomain;
        }

        this.setSize = function (incSize) {
            if (!arguments.length) return this;

            //Measure
            layoutSize = incSize;
            xExtent = d3Extent(nodes, function (d) { return parseFloat(d.x) })
            yExtent = d3Extent(nodes, function (d) { return parseFloat(d.y) })
            xScale = d3ScaleLinear().domain(xExtent).range([0, layoutSize[0]]);
            yScale = d3ScaleLinear().domain(yExtent).range([layoutSize[1], 0]);
            return this;
        }

        this.linksCoworksDomain = linksCoworksDomain;

        return this;
    }


export default GexfD3;
