import React, { Component }                   from 'react';
import PropTypes                              from 'prop-types';
import _                                      from 'lodash';
import AntdIcon, {
    CloseCircleOutlined,
    QuestionOutlined,
    SolutionOutlined,
    FileTextOutlined,
    ArrowRightOutlined,
    StarFilled,
    CaretLeftOutlined,
    CaretRightOutlined,
    UpSquareOutlined,
    LinkOutlined,
    TagFilled,
    TagOutlined,
    TagsFilled,
    UpOutlined,
    DownOutlined,
    LeftOutlined,
    CaretUpOutlined,
    CopyOutlined,
    CheckOutlined,
    BarChartOutlined,
    PlayCircleFilled,
    OrderedListOutlined,
    PartitionOutlined,
    SearchOutlined,
    ExportOutlined,
    PlusOutlined,
    MinusOutlined,
    FilterOutlined,
    UndoOutlined,
    InfoCircleOutlined
}                                             from '@ant-design/icons';

import { svg as importSVG, png as importPNG } from './Icon/import';
import { dataGet }                            from '../core/utils/api';
import { requestTimeout }                     from 'utils/requestTimeout';
import OvationSvg                             from '../core/utils/OvationSvg';

// Logo loading
const logosStorage = {};
let logoTimeout = null;

const LOGO_CHUNK_LENGTH = 50;

// TODO: refact Icon for use new antd icon import system!
const Renderers = {
    'file-text'   : FileTextOutlined,
    'arrow-right' : ArrowRightOutlined,
    'close-circle': CloseCircleOutlined,
    'caret-up'    : CaretUpOutlined,
    'caret-left'  : CaretLeftOutlined,
    'caret-right' : CaretRightOutlined,
    'up-square'   : UpSquareOutlined,
    'bar-chart'   : BarChartOutlined,
    'play-circle' : PlayCircleFilled,
    'ordered-list': OrderedListOutlined,
    'move-to'     : PartitionOutlined,
    'info-circle' : InfoCircleOutlined,
    unknown       : QuestionOutlined,
    solution      : SolutionOutlined,
    star          : StarFilled,
    link          : LinkOutlined,
    tag           : TagFilled,
    'tag-outlined': TagOutlined,
    tags          : TagsFilled,
    down          : DownOutlined,
    up            : UpOutlined,
    left          : LeftOutlined,
    copy          : CopyOutlined,
    check         : CheckOutlined,
    search        : SearchOutlined,
    export        : ExportOutlined,
    plus          : PlusOutlined,
    minus         : MinusOutlined,
    filter        : FilterOutlined,
    undo          : UndoOutlined,
};
/**
 * Display a icon
 *
 */
class Icon extends Component {

    /**
    * Fetch logos from data-api
    *
    * @return self
    */
    static fetchOrgunitLogos() {
        const logosIds = _.keys(logosStorage).filter(
                logo => !logosStorage[logo].isFetching && _.isNull(logosStorage[logo].data)
            ),
            // Prevent too long urls
            logoChunks = _.chunk(logosIds, LOGO_CHUNK_LENGTH);

        _.each(logosIds, (logoId) => {
            logosStorage[logoId].isFetching = true;
        });

        if (!logosIds.length || !logoChunks.length) {
            return;
        }

        logoChunks.map((slicedLogo) => dataGet('/logos', { data: { ids: slicedLogo } })
            .then(
                ({ body }) => {
                    _.each(slicedLogo, (logoId) => {
                        const logoData = body[logoId];

                        logosStorage[logoId].data       = logoData || false;
                        logosStorage[logoId].isFetching = false;
                        _.each(logosStorage[logoId].callbacks, (cb) => cb());
                    });
                }
            ));
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.iconRef  = React.createRef();
        this.imgRef   = React.createRef();
        this.imageRef = React.createRef();

        this.state = {
            logoData     : false,
            logoIsLoading: false
        };

        _.bindAll(this, 'onLogoFetched');

        // Make a uuid
        this.id = _.uniqueId('icon');
    }

    /**
    * Triggered when the component is mounted
    *
    * @return void
    */
    componentDidMount() {
        this.getOrgunitLogo();
    }

    /**
    * Triggered when the component is mounted
    *
    * @return void
    */
    componentWillUnmount() {
        this.isCancelled = true;
    }

    /**
    * Triggered when the component is update
    *
    * @return void
    */
    componentDidUpdate() {
        const  { width } = this.props,
            { iconSize } = this.state;

        if (!iconSize && _.isString(width) && width.indexOf('%') !== -1) {
            this.setIconSize();
        }
    }

    /**
    * Triggered when the logo has been fetched
    *
    * @return self
    */
    onLogoFetched() {
        const { orgunitLogo } = this.props;

        if (this.isCancelled) {
            return;
        }

        // Logo ready
        this.setState({
            logoData     : logosStorage[orgunitLogo].data,
            logoIsLoading: false
        });
    }

    /**
    * Get image size
    *
    * @return object { width, height}
    */
    getImageSize() {
        const { width, height, ratio } = this.props,
            { iconSize }               = this.state,  // True size from dom
            isPercentSize              = _.isString(width) && width.indexOf('%') !== -1,
            trueWidth                  = isPercentSize && iconSize ? iconSize.width : width,
            trueHeight                 = isPercentSize && iconSize ? iconSize.height : height,
            usedRatio                  = ratio;

        return {
            imageWidth: width !== false
                ? trueWidth
                : (height !== false ? trueHeight * usedRatio : 100),
            imageHeight: height !== false
                ? trueHeight
                : (width !== false ? trueWidth / usedRatio : 100 / usedRatio)
        };
    }

    /**
     * Get icon id
     *
     * @returns string
     */
    getIconId() {
        const {
                id, type,
                theme
            }        = this.props,
            idOrType = id || type,
            suffix   = theme && theme !== 'filled'
                ? `-${theme}`
                : '';

        return !_.isUndefined(idOrType) && idOrType !== false
            ? `${idOrType}${suffix}` : false;
    }

    /**
    * Get icon url
    *
    * @return string
    */
    getIconUrl() {
        const {
                folder,
            }  = this.props,
            id = this.getIconId();

        return id
            ? `${folder}${id}` : false;
    }

    /**
    * Get data from data api
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    getOrgunitLogo() {
        const { orgunitLogo } = this.props,
            { logoIsLoading } = this.state,
            logo              = logosStorage[orgunitLogo],
            logoExists        = !_.isUndefined(logo),
            isNegativeOrgunit = orgunitLogo && orgunitLogo.indexOf &&  orgunitLogo.indexOf('-') > 0;

        if (logoIsLoading || !orgunitLogo || this.unmounted || isNegativeOrgunit) {
            return;
        }

        // Set the loading state
        this.setState({
            logoIsLoading: logoExists && _.isNull(logo.data),
            logoData     : logoExists ? logo.data : false
        });

        if (logoExists && logo.data) {
            this.onLogoFetched();
            return;
        }

        this.addLogoToPromisedList();

        const logoToFetch = _.keys(logosStorage).filter(
            logo => !logosStorage[logo].isFetching && _.isNull(logosStorage[logo].data)
        );

        if (logoToFetch.length < LOGO_CHUNK_LENGTH) {
            logoTimeout && logoTimeout.cancel();
        }

        logoTimeout = requestTimeout(Icon.fetchOrgunitLogos, 100);
    }

    /**
    * Get icon size from Dom
    *
    * @return void
    */
    setIconSize() {
        const { width, height } = this.props,
            widthRatio          = parseInt(width) / 100,
            heightRatio         = parseInt(height) / 100,
            iconNode            = this.iconRef.current,
            htmlWidth           = !_.isNull(iconNode) ? iconNode.parentNode.offsetWidth : 0,
            htmlHeight          = !_.isNull(iconNode) ? iconNode.parentNode.offsetHeight : 0,
            size                = _.min([htmlWidth * widthRatio, htmlHeight * heightRatio]);

        if (htmlWidth !== 0) {
            this.setState({ iconSize: { width: size, height: size } });
        }
    }

    /**
    * Add the logo & the callback to the promised list
    *
    * @return void
    */
    addLogoToPromisedList() {
        const { orgunitLogo } = this.props;

        if (_.isUndefined(logosStorage[orgunitLogo])) {
            logosStorage[orgunitLogo] = {
                id        : orgunitLogo,
                callbacks : [],
                isFetching: false,
                data      : null        // Null => not fetched, False => fetched but no image
            };
        }

        logosStorage[orgunitLogo].callbacks.push(this.onLogoFetched);
    }

    /**
    * Render the icon in SVG
    *
    * @return JSX
    */
    renderSVG() {  // eslint-disable-line  max-lines-per-function
        const { rotateRelative, translate }  = this.props,
            { borderSize, borderColor }      = this.props,
            { discShaped, title, className } = this.props,
            { logoData, logoIsLoading }      = this.state,
            { imageWidth, imageHeight }      = this.getImageSize(),
            iconurl                          = this.getIconUrl(),
            diameter                         = _.min([imageWidth, imageHeight]),
            radius                           = diameter / 2,
            transform                        = (rotateRelative !== false ? `rotate(${rotateRelative}) ` : '')
                + (translate !== false ? `translate(${translate[0]} ${translate[1]})` : ''),
            classNames                       = [className];

        if (logoIsLoading) {
            classNames.push('is-loading');
        }

        return !logoData && !iconurl ? null :  (
            <g
                ref={this.iconRef}
                className={`icon ${classNames.join(' ')}`}
                transform={transform}
                key={iconurl}
            >
                <title>
                    {title}
                </title>
                { discShaped
                    ? (
                        <g>
                            <clipPath id={`nodeCircleMask-${this.id}`}>
                                <circle r={radius - borderSize} style={{ fill: 'red' }} />
                            </clipPath>
                            <circle className="border" r={radius}
                                style={{ fill: borderColor }}
                            />
                            <circle className="white-background" r={radius - borderSize}
                                style={{ fill: '#ffffff' }}
                            />
                        </g>
                    )
                    : (
                        <rect
                            transform={`translate( ${-imageWidth / 2} ${-imageHeight / 2} )`}
                            width={imageWidth}
                            height={imageHeight}
                            style={{ fill: borderColor }}
                        />
                    )}

                {this.renderSVGImage()}

            </g>
        );
    }

    /**
    * Render the icon in SVG
    *
    * @return JSX
    */
    renderSVGImage() {
        const { rotate, discShaped, borderSize } = this.props,
            { logoData }                         = this.state,
            { onFocus, onBlur, onClick }         = this.props,
            { onMouseOver, onMouseOut }          = this.props,
            { imageWidth, imageHeight }          = this.getImageSize(),
            iconurl                              = this.getIconUrl(),
            svgPath                              = importSVG[iconurl],
            pngPath                              = importPNG[iconurl],
            url                                  = logoData
                ? `data:image/${logoData.type};base64, ${logoData.base64}`
                : pngPath;

        return svgPath && !logoData
            ? this.renderTrueSVGIcon('g')
            : (url
                ? (
                    <image
                        ref={this.imageRef}
                        className={_.isNull(logoData) ? 'is-loading' : ''}
                        key={url.substring(0, 20)}
                        transform={rotate !== false ? `rotate(${rotate})` : null}
                        xlinkHref={url}
                        x={-imageWidth / 2 + borderSize}
                        y={-imageHeight / 2 + borderSize}
                        width={imageWidth - borderSize * 2}
                        height={imageHeight - borderSize * 2}
                        onClick={(e) => { _.isNull(onClick) ? null : onClick(); e.preventDefault(); return true; }}
                        onMouseDown={(e) => { e.preventDefault(); return true; }}
                        onMouseUp={(e) => { e.preventDefault(); return true; }}
                        onMouseOver={onMouseOver}
                        onFocus={onFocus}
                        onMouseOut={onMouseOut}
                        onBlur={onBlur}
                        style={{
                            clipPath  : discShaped ? `url(#nodeCircleMask-${this.id})` : null,
                            cursor    : onClick ? 'pointer' : null,
                            background: discShaped ? '#ffffff' : null,
                        }}
                    />
                )
                : null
            );
    }

    /**
    * Render the icon in HTML
    *
    * @return JSX
    */
    renderHTML() {
        const { discShaped, borderSize } = this.props,
            { borderColor, style, id }   = this.props,
            { title, className}          = this.props,
            { rotate, dataQaKey }        = this.props,
            { logoIsLoading }            = this.state,
            { imageWidth, imageHeight }  = this.getImageSize(),
            diameter                     = Math.floor(_.min([imageWidth, imageHeight])),
            radius                       = diameter / 2,
            classNames                   = [className];

        if (logoIsLoading) {
            classNames.push('is-loading');
        }

        return (
            <span
                ref={this.iconRef}
                key={`${id}-${imageHeight}`}
                className={`icon ${classNames.join(' ')}`}
                title={title}
                data-qa-key={dataQaKey}
                style={_.merge({
                    display     : 'inline-block',
                    lineHeight  : 0,
                    /* LineHeight  : `${imageHeight -borderSize * 2}px`, */
                    borderRadius: discShaped ? radius : null,
                    overflow    : discShaped ? 'hidden' : null,
                    width       : discShaped ? diameter : imageWidth,
                    height      : discShaped ? diameter : imageHeight,
                    top         : discShaped ? `${-borderSize * 2}px` : null,
                    border      : discShaped ? `solid ${borderSize}px ${borderColor}` : null,
                    background  : discShaped ? '#ffffff' : null,
                    boxSizing   : 'border-box',
                    transform   : rotate ? `rotate(${rotate}deg)` : null,
                }, style)}

            >
                {this.renderHTMLImage()}
            </span>
        );
    }

    /**
    * Render the HTML Images
    *
    * @return JSX
    */
    renderHTMLImage() {
        const { discShaped, borderSize } = this.props,
            { borderColor, fitOnHeight } = this.props,
            { height, width }            = this.props,
            { noConstraint, onClick }    = this.props,
            { alt, logoData }            = this.state,
            iconurl                      = this.getIconUrl(),
            { imageWidth, imageHeight }  = this.getImageSize(),
            diameter                     = _.min([imageWidth, imageHeight]),
            radius                       = diameter / 2,
            pngPath                      = importPNG[iconurl],
            {
                width: imgWidth,
                height: imgHeight
            }                            = logoData || {},
            imgRatio                     = imgWidth / imgHeight,
            url                          = logoData
                ? `data:image/${logoData.type};base64, ${logoData.base64}`
                : pngPath,
            sizeProperties               = width && height && noConstraint
                ?  { width: '100%',  height: '100%' }
                : !fitOnHeight
                    ? { width: '100%' }
                    : { height: '100%' },
            topOffset                    = !fitOnHeight ? imageHeight / 2 - imageHeight / (2 * imgRatio)  : -radius / 2;

        return !url
            // SVG html tag
            ? this.renderTrueSVGIcon()
            : (
            // Img html tag
                <img
                    ref={this.imgRef}
                    key={`${url}-${imageHeight}`}
                    src={url}
                    alt={alt}
                    {...sizeProperties}
                    onClick={onClick}
                    style={{
                        ...sizeProperties,
                        position     : discShaped ? 'relative' : null,
                        verticalAlign: 'bottom',
                        left         : discShaped && imageWidth > imageHeight ? `${(-radius + borderSize) / 2}px` : null,
                        top          : discShaped && (imageWidth < imageHeight || !fitOnHeight) ? `${topOffset}px` : null,
                        border       : !discShaped ? `solid ${borderSize}px ${borderColor}` : null,
                        cursor       : onClick ? 'pointer' : null
                    }}
                />
            );
    }

    /**
    * Render a tue SVG tag in DOM
    *
    * @return JSX
    */
    renderTrueSVGIcon(wrapper               = 'div') {
        const { borderSize, color, rotate } = this.props,
            { replaceTexts, onClick }       = this.props,
            { imageWidth, imageHeight }     = this.getImageSize(),
            url                             = this.getIconUrl(),
            svgPath                         = importSVG[url];

        return svgPath
            ? (
                <OvationSvg
                    key={`${svgPath}-${imageWidth}-${imageHeight}-${color}`}
                    src={svgPath}
                    rotate={rotate}
                    wrapper={wrapper}
                    color={color}
                    replaceTexts={replaceTexts}
                    width={imageWidth - borderSize * 2}
                    height={imageHeight - borderSize * 2}
                    onClick={onClick}
                    style={{ cursor: onClick ? 'pointer' : null }}
                />
            )
            : null;
    }

    /**
    * Render icon from AntdIcon
    *
    * @return JSX
    */
    renderAntDIcon(type) {
        const {
                style, height, width,
                title, theme
            }                    = this.props,
            { color, dataQaKey } = this.props,
            newStyle             = _.clone(style),
            heightAndSizeAreSet  = (height && width),
            size                 = !heightAndSizeAreSet && (height || width),
            filledProps          = _.pickBy(
                this.props,
                value => !(_.isUndefined(value) || _.isNull(value) || value === false)
            ),
            renderer            = Renderers[type] || Renderers.unknown,
            filteredProps       = _.pick(filledProps, ['className', 'width', 'height', 'onClick']);

        if (!Renderers[type]) {
            console.log('unknown icon:', type, theme);
        }

        // Only one size (height xor width)
        if (size) {
            newStyle.height   = size;
            newStyle.width    = size;
            newStyle.fontSize = size;
        }

        // Both size are set (width and height)
        if (heightAndSizeAreSet) {
            newStyle.height   = height;
            newStyle.width    = width;
            newStyle.fontSize = height;
        }

        if (color) {
            newStyle.color = color;
        }

        return (
            <AntdIcon component={renderer} {...filteredProps}
                style={newStyle} data-qa-key={dataQaKey}
                title={title}
            />
        );
    }

    /**
    * Render the icon
    *
    * @return JSX
    */
    render() {
        const {
                SVG,
                folder
            }  = this.props,
            id = this.getIconId();

        if (!id) {
            return null;
        }

        if (!_.has(importSVG, `${folder}${id}`)
            && !_.has(importPNG, `${folder}${id}`)
        ) {
            return this.renderAntDIcon(id);
        }

        return SVG ? this.renderSVG() : this.renderHTML();
    }

}

Icon.propTypes = {
    id            : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    orgunitLogo   : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    color         : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    translate     : PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)]),
    rotateRelative: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // Rotate relative to the translate
    rotate        : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    width         : PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    height        : PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    theme         : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    borderSize    : PropTypes.number,
    replaceTexts  : PropTypes.array,
    ratio         : PropTypes.number,
    title         : PropTypes.string,
    alt           : PropTypes.string,
    borderColor   : PropTypes.string,
    folder        : PropTypes.string,
    className     : PropTypes.string,
    style         : PropTypes.object,
    fitOnHeight   : PropTypes.bool,
    noConstraint  : PropTypes.bool,
    discShaped    : PropTypes.bool,
    SVG           : PropTypes.bool,
    onClick       : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onMouseOver   : PropTypes.func,
    onMouseOut    : PropTypes.func,
    onFocus       : PropTypes.func,
    onBlur        : PropTypes.func,
    type          : PropTypes.string,
    dataQaKey     : PropTypes.string,
};

Icon.defaultProps = {
    folder        : '/',
    theme         : false,
    width         : false,
    height        : false,
    fitOnHeight   : false,
    noConstraint  : false,
    color         : 'var(--primary-color)',
    replaceTexts  : [],
    discShaped    : false,
    orgunitLogo   : false,
    SVG           : false,
    translate     : false,
    rotateRelative: false,
    rotate        : false,
    title         : '',
    alt           : '',
    borderSize    : 0,
    ratio         : 1,
    borderColor   : 'none',
    className     : '',
    style         : {},
    onClick       : null,
    onMouseOver   : _.noop,
    onMouseOut    : _.noop,
    onFocus       : _.noop,
    onBlur        : _.noop,
};

export default Icon;
