import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import RelatedTo            from './RelatedTo';

/**
* Call RelatedTo JSX
*
*/
class PartOf extends Component  {

    /**
    * Render all the references as a list
    *
    * @return JSX
    */
    render() {
        return (
            <RelatedTo
                {...this.props}
            />
        );
    }

}

PartOf.propTypes = {
    element: PropTypes.shape({}).isRequired,
    width  : PropTypes.number,
    height : PropTypes.number,
    model  : PropTypes.shape({}),
};

PartOf.defaultProps = {
    model: false,
};

/**
 * Bind the store to to component
 */
export default PartOf;
