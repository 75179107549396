// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grouped-list .geomap {
  margin-top: 25px;
  width: 100%;
  height: 200px;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Filters/assets/market.less"],"names":[],"mappings":"AAAA;EAEQ,gBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAAR","sourcesContent":[".grouped-list {\n    .geomap {\n        margin-top: 25px;\n        width     : 100%;\n        height    : 200px;\n        align-self: center;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
