import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import { Skeleton }         from 'antd';

import { learn }            from 'store/actions/knowledge';
import { collect }          from 'store/actions/resource';
import { addItems }         from 'store/actions/userView';
import { emitEvent }        from 'store/actions/sockets';
import {
    getDateString,
    getTimeString,
}                           from 'utils/date';
import {
    capitalize,
    sanitizeFilename,
    pluralize,
}                                 from 'utils/text';
import { deepEqual }              from 'utils/object';
import { post }                   from 'utils/api';
import {
    getExtendedLabels,
    getSource,
    getConfigurationElements,
}                                 from 'utils/elements';

import { NoData, Row, Col, Icon } from 'helpers';

// Import renderers components
import Area                       from './Element/Area';
import Graph                      from './Element/Graph';
import Collection                 from './Element/Collection';

// Create the renderers index
const renderers = {
    area      : Area,
    graph     : Graph,
    collection: Collection,
};

let KNOWLEDGE_POOL = {};


/**
* Manage to display an model from its model and a render
*
*/
class Element extends Component {

    /**
    * Return the element definition plain object
    *
    * @return object
    */
    static getElementStatic(element) {
        const { elements } = KNOWLEDGE_POOL;

        if (!elements) {
            return null;
        }

        // Element isn't an object, it may be a UUID?
        if (!_.isObject(element)) {
            // Handle the simple UUID case
            if (_.isString(element)) {
                const elementToSet = elements.find((obj) => {
                    return obj.id === element;
                });
                if (!elementToSet) {
                    throw new Error(`Element ${element} has not been found.`);
                }
                return elementToSet;
            }
            throw new Error('Elements should be defined by objects.');
        }

        return element.id
            ? element                   // Element is already a plain object
            : elements.find(            // Try to find the element from target
                (obj) => obj.key === element.key && obj.target ===  element.target
            );
    }

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);
        const { element } = this.props;

        _.bindAll(this,
            'addAnalyseListToClipboard', 'addToClipboard', 'getDefaultTimeframeFromAnalyseElement', 'loadNextRange',
            'makeClipboardItem', 'registerCallbacks', 'registerParentCallbacks', 'triggerAddToClipBoard',
            'triggerCallback', 'updateFilters', 'updatePaginationOrder', 'getSize', 'getListResource', 'assignRef',
            'createVirtualClipboard', 'cancelCollector'
        );

        this.ref = React.createRef();

        this.state = {
            ...KNOWLEDGE_POOL,
            // Sub element callback
            callbacks     : {},
            firstRender   : true,
            // Store element data
            data          : null,
            dataIsLoaded  : false,
            // Current parameters (aka: filters
            parameters    : null,
            element       : Element.getElementStatic(element),
            // Current pagination object
            pagination    : false,
            currentFilters: {},
            // Active sort
            activeSortId  : false,
        };

        this.dataIsLoaded = false;

        // Store there the cancellable promise
        this.collector           = null;
        this.filterListCollector = null;
        this.collectFiltersList  = [];
    }


    /**
    * Use this to catch an error
    *
    * @return void
    */
    componentDidCatch(error) {
        const {
                element, context,
                history, model, parameters
            }            = this.props,
            { id, type } = model || {};

        // Fallback to the UI
        this.setState({ hasError: true });

        // Log to the tracer
        post('/bug-report', {
            error: error.toString(),
            info : {
                build   : process.env.BUILD,
                stack   : error.stack,
                message : error.message,
                type    : error.name,
                location: history && history.location,
                element,
                parameters,
                model   : {
                    id,
                    type,
                },
                context,
            }
        });
    }


    /**
     *
     * @returns The return value is a boolean value that tells React component should update.
     */
    shouldComponentUpdate(nextProps, nextState) {
        const { element }         = this.props,
            { firstRender, data } = this.state,
            sameProps             = deepEqual(this.props, nextProps),
            sameState             = deepEqual(this.state, nextState);
        if(
            !firstRender
            && (data === nextState.data)
            && (!element || (sameProps && sameState))
        ) {
            return false;
        }

        this.setState({ firstRender: false });
        return true;
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { callbacks }           = this.state,
            /** Return a empty object */
            defaultCb               = () => {};

        callbacks[action] = cb || defaultCb;

        this.setState({ callbacks });
    }

    /**
    * Extract entities from data
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
                model,
                element,
                parameters:propsParameters
            }  = nextProps,
            {
                data,
                dataIsLoaded,
                parameters:stateParameters,
                model     :stateModel,
            }  = prevState,
            parametersHaveBeenUpdated = propsParameters !== stateParameters,
            hasNoData                 = Element.hasNoData(element, data),
            // Use model compare if pointer are not same or their Ids
            modelHasChanged           = !(stateModel === model || stateModel?.id === model?.id);

        if (!parametersHaveBeenUpdated) {
            return { ...prevState, hasNoData, modelHasChanged };
        }

        return {
            ...prevState,
            model,
            modelHasChanged,
            dataIsLoaded: dataIsLoaded && !parametersHaveBeenUpdated,
            data        : null
        };
    }

    /**
    * Has no data
    *
    * @return Boolean
    */
    static hasNoData(element, data) {
        const noContentData     = !data || !data.content || _.keys(data.content).length === 0,
            noStats             = !data || !data.stats || _.keys(data.stats).length === 0,
            { resource }        = element || {},
            resourceUseMap      = resource && _.isObject(resource.map) && _.keys(_.omit(resource.map, 'stats')).length > 0,
            resourceUseStatsMap = resource && _.isObject(resource.map) && !_.isUndefined(resource.map.stats);

        return resource && ((noContentData && noStats) || (resourceUseMap && noContentData) || (resourceUseStatsMap && noStats));
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge, element, storeElementInstances } = this.props,
            { element:stateElement }                         = this.state,
            knowledgePoolIsFilled                            = _.keys(KNOWLEDGE_POOL).length > 0;

        // Preload knowledge once.
        if (!knowledgePoolIsFilled) {
            learnKnowledge([
                'elements', 'filters', 'resources',
                'entities', 'sorts', 'help-metrics',
                'dynamic-resources', 'timeframes',
            ])
                .then((knowledges) => {
                    KNOWLEDGE_POOL = {
                        ...knowledges
                    };

                    const currentElement = Element.getElementStatic(element);
                    this.setState({
                        ...KNOWLEDGE_POOL,
                        element: currentElement
                    });

                    this.registerParentCallbacks(currentElement);
                });

            return;
        }

        // First of all set the element model
        this.resetPagination();
        this.registerParentCallbacks(stateElement);

        // Send this element to store props
        if (storeElementInstances && stateElement) {
            storeElementInstances(this);
        }
    }

    /**
     * Register some actions/callback to the parent component
     *
     * @param {object} element The current element
     */
    registerParentCallbacks(element) {
        const { registerCallbacks } = this.props;

        if (!registerCallbacks) {
            return;
        }

        registerCallbacks('triggerCallback', this.triggerCallback, element);
        registerCallbacks('cancelCollector', this.cancelCollector, element);
        registerCallbacks('addToClipboard', this.triggerAddToClipBoard, element);
    }

    /**
    * Store an unmounted bool to prevent callbacks on GC'ed components
    *
    * @return self
    */
    componentWillUnmount() {
        // Cancel the promise
        this.cancelCollector();

        this.cancelFilterListCollector();

        // Set the unmount flag
        this.isUnmounted = true;
    }

    /**
    * Cancel promise list collector
    */
    cancelCollector() {
        if (this.collector && this.collector.cancel) {
            this.collector.cancel();
            this.collector = null;
        }
    }

    /**
    * Cancel promise filter list collector
    */
    cancelFilterListCollector() {
        if (this.filterListCollector && this.filterListCollector.cancel) {
            this.filterListCollector.cancel();
            this.filterListCollector = null;
        }
    }


    /**
     * Check parameters in props and state are different
     *
     * @returns boolean
     */
    parametersHaveBeenUpdated() {
        const  { parameters:propsParameters } = this.props,
            { parameters:stateParameters }    = this.state;

        return propsParameters !== stateParameters;
    }

    /**
     * Check if the componant parameters are same
     *
     * @returns boolean
     */
    sameParameters() {
        const { model, sort } = this.props,
            {
                element, sorts,
                modelHasChanged, pagination,
            } = this.state;

        return this.dataIsLoading
            || !element
            || !sorts
            || !element.resource
            || !model
            || (
                !this.parametersHaveBeenUpdated()
                && this.sort === sort
                && this.pagination === pagination
                && !modelHasChanged
            );
    }

    /**
    * Prepare the element, and its data
    *
    * @return void
    */
    componentDidUpdate() {  // eslint-disable-line max-lines-per-function
        const {
                onDataLoaded, parameters, storeElementInstances,
                onFilterListLoaded, sort
            } = this.props,
            {
                element, pagination, dataIsLoaded,
                modelHasChanged,
            } = this.state,
            // May trigger a data update.
            parametersHaveBeenUpdated = this.parametersHaveBeenUpdated();

        // We are facing same parameters
        if (this.sameParameters()) {
            return;
        }

        // Send this element to store props
        if (storeElementInstances) {
            storeElementInstances(this);
        }

        // Reset the pagination if parameters have changed
        if ((parametersHaveBeenUpdated || modelHasChanged || this.sort !== sort
            || this.pagination && this.pagination.order !== pagination.order
        )
            && this.resetPagination()) {
            return;
        }

        // Store pagination for further use
        this.pagination = pagination;
        this.sort = sort;

        if (dataIsLoaded) {
            this.dataIsLoading = true;
            this.setState({
                dataIsLoaded: false,
            });
        }

        // Cancel the promise on filter change
        if (pagination?.updated === false && _.keys(parameters).length > 0 && parametersHaveBeenUpdated) {
            this.cancelCollector();
        }

        this.setState({
            parameters, // Update element live parameters
        });

        // We need to collect new data to update element
        this.collectData(
            (data) => {
                // Prevent callback to be triggered on unmounted elements
                if (this.isUnmounted) {
                    return;
                }

                // Callback
                if (onDataLoaded) {
                    // Pass noData (allElementsHaveBeenDisabled) to upper element
                    // Useful for dataset header section to remove section and no show the NoData component
                    const noData = Element.hasNoData(element, data);
                    requestAnimationFrame(() => { !this.isUnmounted && onDataLoaded(element, data, noData); });
                }

                // Store data
                this.dataIsLoading= false;
                this.setState({
                    data        : this.setPaginationTotal(data), // Update element data
                    dataIsLoaded: true,
                });
            }
        );

        // Update filter list on filter changed
        if (onFilterListLoaded && pagination?.updated === false && parametersHaveBeenUpdated) {
            this.cancelFilterListCollector();

            this.collectFilterList((data) => {
                const filtersList = _.mapValues(
                    data.content,
                    filterValues => _.isArray(filterValues)
                        ? _.map(
                            filterValues,
                            (filterValue) => ({...filterValue, label: capitalize(filterValue.label?.toLowerCase())})
                            // TODO: fix this from data capitalize
                        )
                        : filterValues
                );
                // Callback
                requestAnimationFrame(() => { !this.isUnmounted && onFilterListLoaded(filtersList); });
            });
        }
    }

    /**
     * Get filters key from resources parameters
     *
     * @param {*} parameters
     * @returns
     */
    getFiltersFromParameters(parameters) {
        return _.omitBy(parameters, (value, key) => {
            return key.endsWith('(graph)'); // Remove graph filters
        });
    }


    /**
     * Collect filter list
     */
    collectFilterList(stateCallback) {  // eslint-disable-line max-lines-per-function
        const { collectResource }    = this.props,
            resourceOptions          = this.getResourceOptions(),
            { resource, parameters } = resourceOptions,
            { data }                 = resource;

        // Use only filters in parameters
        resourceOptions.parameters = this.getFiltersFromParameters(parameters);

        resourceOptions.resource.data = {...data, onlyfilterlist: 'true'};
        resourceOptions.resource.map = {};
        // Collect raw minerals from api, then rafine them before call back
        this.filterListCollector = collectResource(resourceOptions)
            .then((mainResults) => {
                const filtersParameters = this.getFiltersFromParameters(resourceOptions.parameters),
                    filtersKeys= _.keys(filtersParameters);

                // Cancel old filters list collectors
                this.collectFiltersList.forEach(promise => promise.cancel());

                // Get filters list for selected filters
                this.collectFiltersList = filtersKeys.map(filterKey => {
                    const parametersWithoutFilter    = _.omit(filtersParameters, filterKey),
                        resourceOptionsForThisFilter = _.cloneDeep(resourceOptions);
                    resourceOptionsForThisFilter.parameters = parametersWithoutFilter;

                    return collectResource(resourceOptionsForThisFilter);
                });

                // No filters => just return main filter list
                if (this.collectFiltersList.length === 0) {
                    stateCallback(mainResults);
                    return;
                }

                // Launch all filters list collectors
                Promise.all(this.collectFiltersList)
                    .then((collectResults) => {
                        const filtersListOfSelectedFilters = {},
                            results                         = _.filter(collectResults, (collectResult) => {
                                const usedFiltersKeys = _.keys(this.getFiltersFromParameters(collectResult.parameters));
                                return usedFiltersKeys.length !== filtersKeys.length;
                            });

                        // Update filters list for selected filters
                        results?.forEach((collectResult) => {
                            if (!collectResult.content) {
                                // Manage no results
                                return;
                            }
                            const usedFiltersKeys = _.keys(this.getFiltersFromParameters(collectResult.parameters)),
                                filterToUpdate    = _.difference(filtersKeys, usedFiltersKeys)[0];

                            filtersListOfSelectedFilters[filterToUpdate] = collectResult.content[filterToUpdate];
                        });

                        // Manage no main results
                        const firstResult = _.first(_.orderBy(results, (result) => _.keys(result.content).length).reverse()),
                            _results      = mainResults?.content
                                ? mainResults
                                : {
                                    ...firstResult,
                                    content: _.mapValues(firstResult.content, () => true)
                                };

                        _results.content = {..._results.content, ...filtersListOfSelectedFilters};

                        // Return the merged filters list
                        stateCallback(_results);
                    });
            });
    }

    /**
    * Set the total of entities accessible
    *
    * @param data object
    *
    * @return data
    */
    setPaginationTotal(data) {
        const { pagination }= data,
            resource= this.getElementResource(),
            { maxTotal }              = resource || {};

        if(!maxTotal) {
            return data;
        }
        if(maxTotal < pagination.total) {
            pagination.total=maxTotal;
        }

        return data;
    }

    /**
    * Trigger a previously registered callback
    *
    * @param string action The action to trigger
    *
    * @return void
    */
    triggerCallback(action, payload) {
        const { callbacks } = this.state;

        // Trigger action on this element
        if (callbacks[action]) {
            return callbacks[action](payload);
        }

        // Trigger sub element
        if (callbacks && callbacks.triggerCallback) {
            return callbacks.triggerCallback(action, payload);
        }
    }

    /**
    * Trigger a AddToClipboard
    *
    * @param string action The action to trigger
    *
    * @return void
    */
    triggerAddToClipBoard(options) {
        const { callbacks }          = this.state,
            { type, limitMaxForced } = options,
            { getEntities }          = callbacks,
            entities                 = (getEntities && getEntities()) || [],
            nbEntities               = entities.length;

        if (type === 'element' || (type === 'list' && (nbEntities > 0 || limitMaxForced))) {
            this.addToClipboard(options);
        }
    }


    /**
    * Get clipboard attributes metadata and properties for type list
    *
    * return Graph Component
    */
    getListClipboardAttributes(options) { // eslint-disable-line max-lines-per-function
        const {
                limitMaxForced, labelPrefix,
                type,
            }                                 = options || {},
            { parameters: elementParameters } = this.props,
            {
                data, callbacks, element, pagination, elements
            }                                 = this.state,
            { uri, parameters }               = data || {},
            extendedLabel                     = (elementParameters && elementParameters.label)
                || getExtendedLabels(elements, element),
            typeDefinition                    = callbacks.getTypeDefinition && callbacks.getTypeDefinition(),
            typeString                        = extendedLabel || (typeDefinition && typeDefinition.label),
            entities                          = (
                callbacks.getEntities && callbacks.getEntities()
            ) || (
                callbacks.triggerCallback && callbacks.triggerCallback('getEntities')
            ),
            selectedEntities                  = callbacks.getSelectedEntities && callbacks.getSelectedEntities(),
            hasSelection                      = selectedEntities && selectedEntities.length > 0,
            selectedCount                     = hasSelection ? selectedEntities.length : null,
            entitiesLength                    = entities && entities.length,
            limitMax                          = limitMaxForced || entitiesLength,
            recordsCount                      = limitMaxForced || selectedCount || entitiesLength,
            isSelected                        = hasSelection && !limitMaxForced,
            listIds                           = isSelected ? _.map(selectedEntities, 'id') : null,
            patentApids                       = isSelected ? _.map(selectedEntities, 'APID') : null,
            label                             = `${labelPrefix || ''}`
                + `${isSelected ? 'Selected ' : ''}`
                + `${pluralize((isSelected ? typeString : _.capitalize(typeString)), recordsCount)}`;

        return entitiesLength ? {
            metadata: {
                filetype: type,
                label,
            },
            properties: {
                uri,
                ...elementParameters,
                parameters: {
                    ...parameters,
                    order: pagination.order
                },
                ids  : listIds,
                ...(type === 'booklet' ? { apid: patentApids } : ''),
                limit: { max: limitMax },
            }
        } : null;
    }

    /**
    * Get clipboard attributes metadata and properties for type graph
    *
    * return Graph Component
    */
    getGraphClipboardAttributes(options) {
        // TODO : Remove width, height references and use child sizes (flex-layout)
        const { parentLabel }                  = this.props,
            { context }                        = this.props,
            { callbacks, element, elements }   = this.state,
            { data }                           = this.state,
            { source, labelPrefix, filetype }  = options,
            { expanded }                       = options,
            { id: idq }                        = context || {},
            { uri }                            = data || {},
            extendedLabel                      = getExtendedLabels(elements, element),
            parameters                         = callbacks.getDataParameters && callbacks.getDataParameters(),
            { width, height }                  = this.getSize(),
            { label }                          = element,
            originalRatio                      = width / height,
            { resource }                       = element || {},
            { data: dataResource, pagination } = resource || {},
            { limit }                          = pagination || {};

        return {
            metadata: {
                label   : `${labelPrefix || ''}${extendedLabel || label || parentLabel}`,
                filetype: filetype || 'image',
                originalRatio,
                source,
            },
            properties: {
                width,
                height,
                parameters: {
                    ...dataResource, // Deconstruct default data from resource first
                    ...parameters,   // Deconstruct data from parameters then. Maybe some data parameters can erase data from resource
                    limit,
                    idq,
                },
                uri,
                expanded,
            }
        };
    }

    /**
     * Add clipboard item for type entity and filetype image
     *
     * @param {object} options
     */
    addEntityImagesToClipboard(options = {}) {
        const { addItems, model }  = this.props,
            { callbacks }          = this.state,
            { filetype, type }     = options || {},
            { activeGroupSection } = options || {},
            selectedEntities       = callbacks.getSelectedEntities && callbacks.getSelectedEntities();

        selectedEntities.forEach(selectedEntity => {
            const clipboardAttributes = {
                metadata: {
                    filetype,
                    type,
                    label: selectedEntity?.label.trim(),
                    url  : `${document.location.hash}/${selectedEntity.id}/overview`,
                },
                properties: {
                    activeGroupSection,
                    render : 'modal',
                    model  : _.pick(selectedEntity, ['id', 'type']),
                    context: model && {
                        id  : model.id,
                        type: model.type,
                    },
                },
            };

            addItems(this.makeClipboardItem(options, clipboardAttributes));
        });
    }

    /**
    * Make clipboard item
    *
    * return clipboard item
    */
    makeClipboardItem(options, clipboardAttributes) { // eslint-disable-line max-lines-per-function
        const {
                context, model,
                filtersByMetrics,
            }                     = this.props,
            { element, elements } = this.state,
            { type, source }      = options || {},
            timestamp             = Date.now(),
            dateString            = getDateString(),
            timeString            = getTimeString(),
            filenameDate          = `${dateString}_${timeString}`,
            filenameBase          = `${clipboardAttributes.metadata.label}`,
            cleanMetadata         = _.pickBy(clipboardAttributes.metadata, _.identity),
            // Common metadata
            metadata              = {
                source  : source || getSource(elements, element),
                filename: sanitizeFilename(`${filenameDate}_${filenameBase}`).toLowerCase(),
                url     : document.location.toString(),
                label   : clipboardAttributes.label?.trim(),
                ...cleanMetadata,
                type,
            },
            // Common properties
            properties            = {
                element: clipboardAttributes.properties.element || element.id,
                context: context && {
                    id    : context.id,
                    type  : context.type,
                    entity: { ..._.pick(context.entity, ['id', 'type']) }
                },
                model: model && {
                    id  : model.id,
                    type: model.type
                },
                filtersByMetrics,
                ...clipboardAttributes.properties,
            };

        // Not adding empty list
        if (type === 'list' && (clipboardAttributes === null)) {
            return null;
        }

        // Return the clipboard item
        return {
            type: 'clipboard_item',
            metadata,
            properties,
            timestamp,
        };
    }


    /**
    * Get the array size: width / height
    */
    getSize() {
        const domElement = this.ref.current;

        return {
            width : domElement?.offsetWidth,
            height: domElement?.offsetHeight
        };
    }


    /**
    * Add collection  to the clipboard
    *
    * return void
    */
    addToClipboard(options) {
        const { addItems, avoidTooltip }                        = this.props,
            { callbacks }                                       = this.state,
            { type, filetype, selfExport }                      = options,
            { addToClipboard: addToClipboardFromElementRender } = callbacks,
            isGraphElement                                      = (type === 'element' || type === 'graph'),
            isEntityImage                                       = type === 'entity' && filetype === 'exportOnePage',
            clipboardAttributes                                 = isGraphElement
                ? this.getGraphClipboardAttributes(options)
                : this.getListClipboardAttributes(options);

        if (avoidTooltip) {
            avoidTooltip(true);
        }

        // Use ElementRenderer addToClipboard function
        if (addToClipboardFromElementRender && !selfExport) {
            addToClipboardFromElementRender(options);
            return;
        }

        if (isEntityImage) {
            this.addEntityImagesToClipboard(options);
            return;
        }

        // Standard add to clipboard
        addItems(this.makeClipboardItem(options, clipboardAttributes));
    }

    /**
    * Create a virtual clipboard item for export raw data
    *
    * @param {object} options
    *
    * @returns {object}
    */
    createVirtualClipboard(options) {
        const clipboardAttributes = this.getGraphClipboardAttributes(options);

        return this.makeClipboardItem(options, clipboardAttributes);
    }

    /**
    * Add list of the analyse to the clipboard (corresponding to the element)
    *
    * @params options
    *
    * @return JSX
    */
    addAnalyseListToClipboard(options, elementsIdsToAdd, analyseKeyToAdd = []) {
        const {
                model, addItems
            }                   = this.props,
            { elements }        = this.state,
            {
                limitMaxForced, source, labelPrefix,
            }                   = options,
            subSectionsElements = elements.filter(el => elementsIdsToAdd.indexOf(el.id) !== -1).toJS(),
            analyseKeys         = subSectionsElements.map(el => el.key).concat(analyseKeyToAdd),
            analyseElements     = elements.filter(
                el => el.category === 'Area/Analyse' && analyseKeys.indexOf(el.key) !== -1
            ).toJS();

        analyseElements.forEach((analyseElement) => {
            const elementListId     = analyseElement.configuration.elements.list,
                elementList         = elements.find((el) => el.id === elementListId),
                path                = this.getListPathFromListElement(elementList),
                parameters          = this.getDefaultTimeframeFromAnalyseElement(analyseElement),
                label               = `${labelPrefix || ''}${analyseElement.label || ''}`,
                clipboardAttributes = {
                    metadata: {
                        filetype: 'list',
                        label,
                        source,
                    },
                    properties: {
                        element: elementList.id,
                        uri    : `/${pluralize(model.type)}/${model.id}/${path}`,
                        parameters,
                        limit  : { max: limitMaxForced },
                    }
                };

            addItems(this.makeClipboardItem(options, clipboardAttributes));
        });
    }

    /**
     * Get path of the list of a analyse element
     *
     * @param object analyseElement
     *
     * @returns string
     */
    getListPathFromListElement(elementList) {
        const { resources } = this.state,
            resource      = resources.find((res) => res.id === elementList.resource.id),
            { path }      = resource;

        return path;
    }

    /**
     * Get default timeframe from analyse element
     *
     * @param object analyseElement
     *
     * @returns object
     */
    getDefaultTimeframeFromAnalyseElement(analyseElement, asCustomString = true) {
        const {
                elements, resources, dynamicResources
            }                           = this.state,
            { configuration, category } = analyseElement,
            { disableDefaultTimeFrame } = configuration,
            // Get deep first element graph in element layout
            elementGraphLayoutId        = configuration.elements.graph;

        if(disableDefaultTimeFrame || !elementGraphLayoutId || category !== 'Area/Analyse') {
            return {};
        }

        const elementGraphLayout          = elements.find(el => el.id === elementGraphLayoutId),
            elementGraphId              = _.flattenDeep(getConfigurationElements(elementGraphLayout.configuration.elements))[0],
            elementGraph                = elements.find(el => el.id === elementGraphId),
            // Use graph resource to get default timeframe selection
            graphResource               = elementGraph && resources.find(res => res.id === elementGraph.resource.id),
            { params, filters }         = graphResource || {},
            {
                defaultSelectionDate,
                rateDuration,
            }                           = params || {},
            timeframeFilterName         = filters && filters.find((filter) => filter.indexOf('timeframe') !== -1),
            dynamicResourcedate         = dynamicResources.find((res) => res.id === defaultSelectionDate),
            end                         = defaultSelectionDate && dynamicResourcedate ? dynamicResourcedate.value : null,
            start                       = defaultSelectionDate && rateDuration ? end - rateDuration : null,
            parameters                  = defaultSelectionDate && start && end && timeframeFilterName
                ? { [timeframeFilterName]: asCustomString
                    ? `custom_${start}_${end}`
                    : { start, end }
                } : {};


        return disableDefaultTimeFrame ? {} : parameters;
    }

    /**
    * Get resource filters
    *
    * @return array
    */
    getResourceFilter() {
        const resource  = this.getElementResource(),
            { filters } = resource || {};

        return filters ?  filters[0] : null; // Use filters one by one
    }

    /**
    * Interpolate vars with filter template
    *
    * @return string
    */
    interpolateValuesAndTemplate(values, template) {
        let interpolatedValue = template;

        _.keys(values).forEach((key) => {
            interpolatedValue = interpolatedValue.replace(`%${key}%`, values[key]);
        });

        return interpolatedValue;
    }

    /**
    * Update filters from graph
    *
    * return Graph Component
    */
    updateFilters(values) {
        const { onUpdateFilters }       = this.props,
            { filters, currentFilters } = this.state,
            filterKey                   = this.getResourceFilter();

        // No filter / No resource??
        if (!filterKey) {
            // Filters should pass thru the current element.
            if (onUpdateFilters) {
                onUpdateFilters(values);
            }
            return;
        }

        const filter    = filters.find((filter) => filter.id === filterKey),
            filterValue = _.isArray(values) ? values : (
                _.isObject(values) ? this.interpolateValuesAndTemplate(values, filter.template) : []
            );

        // Update the local filter
        currentFilters[filterKey] = filterValue;

        this.setState({
            currentFilters
        });

        // Update parent filters
        if (onUpdateFilters) {
            onUpdateFilters(currentFilters, values);
        }
    }

    /**
    * Initialize the state resource from current element
    *
    * @return void
    */
    resetPagination() {
        const {
                element, sorts, pagination:statePagination
            }                 = this.state,
            { sort }          = this.props,
            resource          = this.getElementResource(),
            { pagination }    = resource || {},
            hasLimit          = pagination && pagination.limit,
            { updated }       = statePagination || {},
            sortMustBeUpdated = sort && statePagination && !deepEqual(statePagination.order, sort);

        if (!element || !resource || !sorts || (updated === false && !sortMustBeUpdated)) {
            return false;
        }

        this.setState({
            pagination: {
                updated: false,
                limit  : {
                    range: hasLimit ? pagination.limit.max - (pagination.limit.min || 0) : 25,
                    min  : hasLimit ? pagination.limit.min || 0  : 0,
                    max  : hasLimit ? pagination.limit.max || 25 : 25
                },
                order: sort || statePagination.order || this.getOrder(),
                test : new Date(),
            }
        });

        // Reset Element Collection
        this.triggerCallback('reset');

        return true;
    }

    /**
     * Return resource of the element
     *
     * @returns object
     */
    getElementResource() {
        const { element } = this.state,
            { resource }   = element  || {};

        return resource;
    }

    /**
     * Get order
     *
     * @param {string} id an Id to get order from sort
     *
     * @return object
     */
    getOrder(id = null) {
        const sorts                  = this.getSorts(),
            resource                 = this.getElementResource(),
            activeSort               = sorts.find((sort) => sort.id === id) || sorts[0],
            { by, direction }        = activeSort || {},
            order                    = activeSort && { by, direction },
            { pagination }           = resource,
            { order: resourceOrder } = pagination || {};

        return order || resourceOrder;
    }

    /**
    * Return active sort id from current order
    *
    * @return string
    */
    getActiveSort() {
        const { pagination, sorts } = this.state,
            { sort }                = this.props,
            { by, direction }       = sort || pagination.order || {};

        return sorts.toArray()
            .find((sort) => sort.by === by && sort.direction === direction);
    }

    /**
    * Get the element lowercased main category, mostly used to select renderer.
    *
    * @return string
    */
    getMainCategory() {
        const { element } = this.state;

        return _.first(element.category.split('/')).toLowerCase();
    }

    /**
    * Return resource options
    *
    * @return object
    */
    getResourceOptions() {
        const { model, context, parameters } = this.props,
            { element, pagination }          = this.state,
            resource                         = this.getElementResource(),
            resourceCloned                   = _.cloneDeep(resource),
            newPagination                    = {
                limit: pagination && pagination.limit ? pagination.limit : null
            };

        if (pagination && pagination.order) {
            newPagination.order = pagination.order;
        }

        // Collect raw minerals from api, then rafine them before call back
        return {
            model,
            context,
            parameters,
            element : element.id,
            resource: {
                ...resourceCloned,
                pagination: newPagination
            }
        };
    }

    /**
    * Find the helpMetric for this instance of the class.
    *
    * @return Array
    */
    getHelp() {
        const { helpMetrics, element } = this.state,
            { key }                    = element;

        if (!helpMetrics) {
            return false;
        }

        return helpMetrics.find((obj) => obj.id === `help-${key}`);
    }

    /**
    * Collect data from resource
    *
    * @param func stateCallback Should mainly be the setState fn of the element renderer
    *
    * @return void
    */
    collectData(stateCallback) {
        const { collectResource,
                onBeforeLoad,
                element         } = this.props,
            resourceOptions       = this.getResourceOptions();

        // Callback triggered just before collect
        if (onBeforeLoad) {
            onBeforeLoad(element, resourceOptions);
        }

        // Collect raw minerals from api, then rafine them before call back
        this.collector = collectResource(resourceOptions)
            .then(stateCallback);
    }

    /**
    * Load next range of data
    *
    * @return self
    */
    loadNextRange() {
        const { pagination } = this.state;

        this.setState({
            pagination: {
                ...pagination,
                updated: true,
                limit  : {
                    ...pagination.limit,
                    min: pagination.limit.min + pagination.limit.range,
                    max: pagination.limit.max + pagination.limit.range
                }
            }
        });
    }

    /**
     * Change the active sort
     *
     * @param {string} sortId The sort id to be enabled
     *
     * @return void
     */
    updatePaginationOrder(sortId) {
        const { pagination }            = this.state,
            { onUpdatePaginationOrder } = this.props,
            { sort }                    = this.props,
            order                       = this.getOrder(sortId);

        this.setState({
            data      : null,
            pagination: {
                ...pagination,
                updated: true,
                order  : sort || order,
            },
        });

        if (onUpdatePaginationOrder) {
            onUpdatePaginationOrder(order);
        }
    }

    /**
     * Get sortThresholdReached
     *
     * @return boolean
     */
    isSortThresholdReached() {
        const { data } = this.state,
            { pagination } = data || {};

        return pagination && pagination['dynamic-sort-threshold-reached'] || false;
    }

    /**
     * Obtain element resource definition from the "resources" knowledge
     *
     * @return object
     */
    getResourceKnowledge() {
        const { resources } = this.state,
            resource        = this.getElementResource(),
            resourceId      = resource && resource.id;

        return resources.find((res) => res.id === resourceId) || {};
    }

    /**
    * Obtain the List resource definition that permit to request the data API
    *
    * @return object
    */
    getListResource(forcedElement) {
        const {
                resources, element
            }         = this.state,
            elementId = forcedElement ? forcedElement.resource.id : element.resource.id,
            resource  = resources ? resources.find((obj) => obj.id === elementId) : false;

        return resource || {};
    }

    /**
     * Obtain element models types definition from the "entities" knowledge
     *
     * @return object
     */
    getEntityDefinition() {
        const { entities } = this.state,
            { models }     = this.getResourceKnowledge(),
            isMultiModels  = models && models.length > 1,
            modelType      = models && models.length === 1 && models[0].type;

        // Prevent multi models to return a "false" definition
        if (isMultiModels) {
            return false;
        }

        return entities ? entities.find((entity) => entity.id === modelType) || {} : {};
    }

    /**
    * Get sorts for the current element, the following priorities will be implemented in here:
    *   1) element configuration
    *   2) resource definition
    *   3) entity definition (as the default fallback)
    *
    *  @return array
    */
    getSorts() {
        const { sorts }             = this.state,
            resource                = this.getElementResource(),
            // Obtain each types of sorts ids
            { sorts:elementSorts }  = resource                    || {}, // TODO: get the element sort from state, updated by CB
            { sorts:resourceSorts } = this.getResourceKnowledge() || {},
            { sorts:entitySorts }   = this.getEntityDefinition()  || {},
            // Implement the priority order for "sorts" here:
            sortsIds                = elementSorts || resourceSorts || entitySorts || [],
            enabledSorts            = sorts ? sortsIds.map((id) => sorts.find((sort) => sort.id === id)) : [],
            activeSort              = this.getActiveSort() || false;

        return enabledSorts.map((sort) => ({
            ...sort,
            active: activeSort && sort.id === activeSort.id             // Manage the active state for the sort option
        }));
    }

    /**
    * Get label content
    *
    * return Graph Component
    */
    getLabelContent(label, labelHeight, style) {
        return (
            <div
                className="label"
                style={{
                    textAlign : 'center',
                    fontSize  : labelHeight ? `${labelHeight / 1.5}px` : null,
                    lineHeight: labelHeight ? `${labelHeight}px` : null,
                    ...style
                }}
            >
                {label}
            </div>
        );
    }

    // TODO: Simplify the code to unify Element and Graph function
    /**
    * Add a label to the current graph
    *
    * return JSX
    */
    decorateWithLabel(content, options) {
        const { dataIsLoaded, element }               = this.props,
            { labelHeightApplied }                    = options,
            { data, elementClassName }                = options,
            { label,  labelOnBottom, configuration }  = element,
            { color }                                 = configuration || {},
            contentWithLabel                          = [],
            labelWithData                             = !dataIsLoaded && label && label.indexOf('%total') >= 0
                ? (
                    <Skeleton loading active
                        paragraph={false}
                    />
                )
                : (
                    label ? label.replace('%total', data ? data.total : 0) : null
                ),
            labelContent                              = (
                <Row
                    height={labelHeightApplied}
                    className={`label ${elementClassName}`}
                    key={JSON.stringify(element)}
                >
                    {data ? this.getLabelContent(labelWithData, labelHeightApplied, { color }) : null}
                </Row>
            ),
            labelAddFunction                          = labelOnBottom ? 'push' : 'unshift',
            className                                 = `${(`${element.resource?.id} ${element.category}`).toLowerCase()}`;

        // Push the content inside fragment
        contentWithLabel.push((
            <Row
                key="key"
                className={`${className} ${elementClassName}`}
            >
                {content}
            </Row>
        ));

        // Then, add the label at its perfect place.
        if (label) {
            contentWithLabel[labelAddFunction](labelContent);
        }

        return contentWithLabel;
    }


    /**
     * Render JSX Element in case of issue on retrieving data
     * @return JSX
     */
    renderHaveIssueOnRetrieveListData() {
        const { element } = this.state;

        return (
            <Row
                key={'haveIssueOnRetrieveListData' + element.id}
                className="rel"
            >
                <div className="haveIssueOnRetrieveListData-message">
                    <Icon type="warning" height={24} />
                    <span>
                        Sorry, we encountered an issue and
                        we are unable to retrieve more data.
                    </span>
                </div>
            </Row>
        );
    }

    /**
    * Handle ref
    *
    * @param {*} ref
    */
    assignRef(ref) {
        this.ref = ref;
    }

    /**
    * Render the element from its category
    *
    * @return JSX
    */
    renderElement(haveIssueOnRetrieveListData = false) {
        const { addItems, disableSort } = this.props,
            {
                element, data, elements, entities,
                resources, pagination, dataIsLoaded, filters,
                timeframes,
            }               = this.state,
            sorts           = this.getSorts(),
            mainCategory    = this.getMainCategory(),
            ElementRenderer = renderers[mainCategory];

        if (!ElementRenderer) {
            console.info(mainCategory);
        }

        return (
            <>
                <ElementRenderer
                    {...this.props}
                    key={element.id || element}
                    data={data}
                    forwardedRef={this.ref}
                    assignRef={this.assignRef}
                    element={element}
                    elements={elements}
                    entities={entities}
                    help={this.getHelp()}
                    resources={resources}
                    filters={filters}
                    pagination={pagination}
                    sorts={sorts}
                    setActiveSortId={this.updatePaginationOrder}
                    sortThresholdReached={this.isSortThresholdReached()}
                    dataIsLoaded={dataIsLoaded}
                    loadNextRange={this.loadNextRange}
                    onUpdateFilters={this.updateFilters}
                    registerCallbacks={this.registerCallbacks}
                    addToClipboard={this.addToClipboard}
                    addToClipboardFromStore={addItems}
                    makeClipboardItem={this.makeClipboardItem}
                    createVirtualClipboardItem={this.createVirtualClipboard}
                    addAnalyseListToClipboard={this.addAnalyseListToClipboard}
                    getDefaultTimeframeFromAnalyseElement={this.getDefaultTimeframeFromAnalyseElement}
                    getListResource={this.getListResource}
                    haveIssueOnRetrieveListData={haveIssueOnRetrieveListData}
                    timeframes={timeframes}
                    disableSort={disableSort}
                />
                {haveIssueOnRetrieveListData && this.renderHaveIssueOnRetrieveListData()}
            </>
        );
    }

    /**
    *  Get NoData class
    *
    */
    getNodataClassName() {
        const { element } = this.state;

        if (element.category === 'Area/Dashboard/List') {
            return 'dashboard-list';
        }

        return null;
    }

    /**
    * Render NoData
    *
    * @return Component
    */
    renderNoData() {
        const { element, data, hasError }  = this.state,
            { configuration }              = element,
            { noData, color, hideNoData }  = configuration || {},
            options                        = {
                data,
                configuration,
                elementClassName: this.getNodataClassName(),
            },
            errorOptions                  = hasError
                ? {
                    text    : 'Oops, something went wrong.',
                    icon    : 'error',
                    iconSize: 4,
                }
                : {},
            noDataContent                 = hideNoData ? null : (
                <NoData
                    color={color}
                    {...noData}
                    {...errorOptions}
                />
            );

        return  options.elementClassName
            ? (
                <Col className="no-data-column">
                    {this.decorateWithLabel(noDataContent, options)}
                </Col>
            )
            : noDataContent;
    }

    /**
    * Render the model
    *
    * @return Component
    */
    render() {
        const {
                element, dataIsLoaded,
                hasError, hasNoData, pagination
            }                           = this.state,
            { limit }                   = pagination || {},
            { min }                     = limit || {},
            category                    = element?.category,
            noDataReturned              = dataIsLoaded && hasNoData,
            // Catch 204 issue due to orbit.com instability
            haveIssueOnRetrieveListData = category == 'Collection/List'
                && !_.isUndefined(min) && min !== 0
                && noDataReturned;

        if (!element) {
            return false;
        }

        // Use NoData component
        if (hasError || (noDataReturned && !haveIssueOnRetrieveListData)) {
            return this.renderNoData();
        }

        return this.renderElement(haveIssueOnRetrieveListData);
    }

}

Element.propTypes = {
    learnKnowledge: PropTypes.func.isRequired,
    parameters    : PropTypes.object,
    // Element ID || {from: string, target: string, expand: bool}
    element       : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    context: PropTypes.shape({
        id    : PropTypes.string,
        type  : PropTypes.string,
        entity: PropTypes.object
    }),
    history: PropTypes.object,
    model  : PropTypes.shape({
        id    : PropTypes.string,
        label : PropTypes.string,
        type  : PropTypes.string,
        entity: PropTypes.object,
    }),
    storeElementInstances  : PropTypes.func,
    registerCallbacks      : PropTypes.func,
    onDataLoaded           : PropTypes.func,
    onFilterListLoaded     : PropTypes.func,
    parentLabel            : PropTypes.string,
    filtersByMetrics       : PropTypes.any,
    limitMaxForced         : PropTypes.number,
    addItems               : PropTypes.func,
    avoidTooltip           : PropTypes.func,
    onUpdateFilters        : PropTypes.func,
    onUpdatePaginationOrder: PropTypes.func,
    onBeforeLoad           : PropTypes.func,
    dataIsLoaded           : PropTypes.bool,
    skeleton               : PropTypes.bool,
    collectResource        : PropTypes.func,
    floatingInformation    : PropTypes.bool,
    disableSort            : PropTypes.bool,
    sort                   : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]),
};

Element.defaultProps = {
    parameters : {},
    disableSort: false,
    sort       : false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge : learn,
    collectResource: collect,
    addItems,
    emitEvent,
})(Element);
