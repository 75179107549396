/**
 * Element resources Action creator: create actions to handle resource from elements
 */
import _                  from 'lodash';
import { dataGet }        from 'utils/api';
import { makeCancelable } from 'utils/promise';
import * as types         from './types/resource';
import * as utilText      from '../../core/utils/text';
import { learn }          from './knowledge';

// Store there promises that w'll collect resources
const PROMISE_POOL = {};

// Pagination keys taken
const paginationKeys = [
    'filters',
    'filterslist',
    'orderlist',
    'totalbytype',
    'dynamic-filters-threshold-reached',
    'dynamic-sort-threshold-reached',
];

/**
 * Action library, register actions to obtain member from API
 */
const Actions = {

    /**
    * Action to populate the loading state to the store
    *
    * @param  {object}   key     The resource key
    * @param  {object}   request The request that represent the resource
    *
    * @return {Promise}
    *
    */
    setLoadingState: (key, request) => ({ type: types.RESOURCE_LOADING, payload: { key, request } }),

    /**
    * Action to populate the loading state to the store
    *
    * @param  {object}   key     The resource key
    * @param  {object}   request The request that represent the resource
    *
    * @return {Promise}
    *
    */
    delete: (key) => ({ type: types.RESOURCE_DELETE, payload: key }),

    /**
    * Action to feed the supply with collected mineral
    *
    * @param  {object}   key     The resource key
    * @param  {object}   request The request that represent the resource
    *
    * @return {Promise}
    *
    */
    setLoadedState: (key, response, uri) => ({ type: types.RESOURCE_LOADED, payload: { key, response, uri } }),
};


const formatters = {
    object: (value) => JSON.parse(value),
};

/**
* Format object by keys
*
* @param obj       object The object to re-map keys
* @param formatDef object The keys to change
* @return object
*/
const formatValues = (obj, formatDef) => {
    if (!obj) {
        return false;
    }
    if (!formatDef) {
        return obj;
    }

    return _.mapValues(obj, (value, key) => {
        const formatType = formatDef[key],
            formatFn     = formatters[formatType];

        if (value && formatFn) {
            return formatFn(value);
        }

        return value;
    });
};

/**
* Re-map object keys
*
* @param obj    object The object to re-map keys
* @param mapDef object The keys to change
* @return object
*/
const reMapKeys = (obj, mapDef) => {
    if (!obj) {
        return false;
    }
    if (!mapDef) {
        return obj;
    }

    const returnObj = _.clone(obj);

    _.each(mapDef, (key, keyToMap) => {
        const splitedKeyToMap = keyToMap.split('.'),
            value = splitedKeyToMap.length === 1
                ? obj[splitedKeyToMap[0]]
                : !_.isUndefined(obj[splitedKeyToMap[0]])
                && !_.isUndefined(
                    obj[splitedKeyToMap[0]][splitedKeyToMap[1]],
                )
                    ? obj[splitedKeyToMap[0]][splitedKeyToMap[1]]
                    : null;

        // Multiple map key
        if (_.isArray(key) && !_.isUndefined(value) && !_.isNull(value)) {
            key.forEach((innerKey) => {
                returnObj[innerKey] = value;
            });
            return;
        }

        // Simple key
        if (!_.isUndefined(value) && !_.isNull(value)) {
            returnObj[key] = value;
        }
    });

    return returnObj;
};

/**
* Fill insight whith %stats%
*
* return stats
*/
const extendStatsWithFilledInsight = (stats = { }) => {
    const clonedStats = stats;

    if (!stats || !stats.insight) {
        return stats; // This should stay as a real "no go zone".
    }

    clonedStats.filledInsight = stats.insight;

    _.each(stats, (val, key) => {
        const reg = new RegExp(`%${key}%`, 'g');
        clonedStats.filledInsight = stats.filledInsight.replace(
            reg,
            `<b>${val}</b>`,
        );
    });

    return clonedStats;
};

/**
* Create a uniq resource key to store definitions and datas
*
* @param object resource Element resource definition
*
* @return string
*/
const createRequestKey = (options) => {
    const {
        context,
        model,
        resource,
        parameters = {}
    } = options;

    if (!resource) {
        throw new Error('Element must at least have a resource to fetch.');
    }

    const { id, data } = resource,
        keyElements    = [
            id,
            model   ? `${model.type}_${model.id}` : 'n',
            context ? context.id    : 'n',
            JSON.stringify(parameters),
            JSON.stringify(data),
            JSON.stringify(resource.pagination)
        ];

    return keyElements.join('_');
};

/**
* Return the knowledge definition for the current metric
*
* @return bool|object
*/
const getDataResource = (resource, resources) => {
    // Prevent non loaded Knowledges
    if (!resources || !resource.id) {
        return false;
    }

    return resources.find((obj) => obj.id === resource.id) || false;
};

/**
 * Get filters for this resource
 *
 * @param object FiltersValues Filters values
 * @param object resource      Resource where filters are defined
 *
 * @returns array
 */
export const getResourceFilters = (filtersValues, resource) => {
    const {
            filters, conditionalFilters
        }             = resource,
        { singlenpldoctypes } = conditionalFilters || {},
        allConditionalFilters = singlenpldoctypes ? _.flatten(_.values(singlenpldoctypes)) : [],
        allSubFilters = conditionalFilters
            ? _.flatten(_.keys(filtersValues).map((filterKey) => {
                const rootValue        = filtersValues[filterKey],
                    subFiltersByValues = conditionalFilters[filterKey],
                    subFilters         = subFiltersByValues
                        ? subFiltersByValues[rootValue]
                        : allConditionalFilters; // Use all conditional filters with no rootValue (no singlenpldoctypes given)
                return subFilters;
            })) : [],
        allFilters = (allSubFilters || []).concat(filters || [])
            .filter(filterKey => filterKey?.substr(-7) !== '(graph)'); // Clean existing (graph) filters from knowledge

    return allFilters.concat(allFilters.map(filterKey => `${filterKey}(graph)`)); // Add graph filters
};

/**
* Return an object composed by default params, pagination, and filters (aka: parameters)
*
* @param object config A ressource configuration
*
* @return self
*/
const getDataParameters = (request, resources) => {
    const {
            model,
            context,
            resource,
            parameters,
        }                                      = request,
        { pagination:elementPagination, data } = resource,
        dataResource                           = getDataResource(resource, resources),
        { params }                             = dataResource,
        resourceFilters                        = getResourceFilters(parameters, dataResource),
        filteredParameters                     = _.pick(parameters, resourceFilters),
        // Undefined pagination from metrics
        pagination                             = elementPagination || {};

    // Force pagination
    if (params.length && !pagination.limit) {
        // Default pagination from ressource, and no pagination from metric
        pagination.limit = {
            min: 0,
            max: params.length,
        };
    }

    const dataParameters = {
        ...data,               // Default element 'data' parameters
        ...pagination,         // Pagination from element
        ...filteredParameters, // Parameters injected by props should overwrite default ones
    };

    // Entity in context of a context
    if (model && context && context.id) {
        dataParameters.idq = context.entity && context.entity.id
            ? context.entity.id
            : context.id;
    }

    return dataParameters;
};

/**
* Cancel the request from its resource key
*
* @param string key The resource key to cancel
*
* @return void
*/
const cancelCollect = (key, dispatch) => {
    const resourcePromise = PROMISE_POOL[key];

    if (!resourcePromise || !resourcePromise.cancel) {
        return;
    }

    resourcePromise.cancel();

    _.unset(PROMISE_POOL, key);

    dispatch(Actions.delete(key));
};

/**
* Fetch resource to obtain mineral
*
* @return Promise
*/
const fetchResource = (key, request, cb) => {
    const {
            context, model,
            resources
        }   = request,
        uri = getURI(request);

    if ((!context && !model) || !uri) {
        return;
    }

    // Return results from storage pool if they are already fetched
    /* If (FetcherResultsPool[key]) {
        requests[key].isLoaded  = true;
        this.setState({ requests });
        _.each(requests[key].configs, (config) => {
            this.prepareAndReturnsData(config, FetcherResultsPool[storageKey]);
        });
        return;
    } */

    const data = getDataParameters(request, resources);

    // Request API to obtain metric data
    PROMISE_POOL[key] = dataGet(uri, { data })
        .then((response) => {
            const { status, isCancelled } = response;

            // Remove resolved request from promise pool
            _.unset(PROMISE_POOL, key);

            // Prevent memory leaks
            if (isCancelled) {
                return false;
            }

            // Mineral has been collected
            cb(status.code !== 204 ? response : {}, uri);

            return false;
        });
};

/**
 * Get URI identifier using request model and resource(s)
 *
 * @param {request} request
 * @return path
 */
const getURI = (request) => {
    const {
            model, resource,
            resources
        }            = request,
        { id, type } = model,
        { path }     = getDataResource(resource, resources),
        rootPath     = `/${utilText.pluralize(type)}/${id}`;

    if (!path || !rootPath) {
        return false;
    }

    return `${rootPath}/${path}`;
};

/**
* Create the request from a provided resource
*
* return array
*/
const extractRequest = (options) => {
    const { resource } = options;

    return {
        ...options,
        resource : _.omit(resource, 'map'),
        response : null,
        isLoaded : false,
        isLoading: false
    };
};

/**
* Use the resource MAP object to refine the raw data
*
* @param object options    Resource parameters
* @param object parameters Some additionnal parameters (as filters) to fetch data
* @param object ressources The resources book from knowledge
*
* @return object
*/
const refine = (options, response, ressources, uri) => { /* eslint-disable-line max-lines-per-function, max-params */
    const reservedMapKeys = ['stats', 'statsFormats'],
        { body:data }     = response || {},
        { resource }      = options,
        { series, stats } = data || {},
        { map }           = resource,
        hasMap            = !_.isUndefined(map)
            && !_.isNull(map)
            && Object.keys(map).filter((key) => key !=='statsFormats').length > 0,
        seriesKeys        = hasMap ? Object.keys(map).filter((key) => !reservedMapKeys.includes(key)) : [],
        preserveKeys      = hasMap && seriesKeys.length > 1,
        content           = hasMap ? {} : data,
        dataResource      = getDataResource(resource, ressources);

    // Iterate over series to remap data and populate them to the content object
    seriesKeys.forEach((key) => {
        const serieData      = series ? series[key] : null,
            mapDefinitions   = map[key],
            // Remap data
            remapedData      = serieData && mapDefinitions.fields
                ? serieData.map((obj) => reMapKeys(obj, mapDefinitions.fields)) : serieData,
            // Remap serie key
            serieRemapedKey  = mapDefinitions.to ? mapDefinitions.to : key,
            remappedStats    =  reMapKeys(
                stats ? stats[serieRemapedKey] : {},
                mapDefinitions.stats ? mapDefinitions.stats : null,
            );
        // Remap series content
        if (remapedData) {
            content[serieRemapedKey] = remapedData;
        }

        // Remap series stats
        if (stats && stats[serieRemapedKey] && remappedStats) {
            stats[serieRemapedKey] = remappedStats;
        }

        // Format series stats
        if (mapDefinitions.statsFormats) {
            stats[serieRemapedKey] = formatValues(
                stats ? stats[serieRemapedKey] : {},
                mapDefinitions.statsFormats
            );
        }
    });

    const contentToReturn =  (hasMap && !preserveKeys ? content[seriesKeys[0]] : content) || null,
        totalToReturn     = data
            ? data.length || (contentToReturn ? contentToReturn.length : false) || 0
            : 0,
        onlyEmptyStats    = _.isObject(contentToReturn)
            && !_.isUndefined(contentToReturn.stats)
            && _.keys(contentToReturn).length === 1
            && _.keys(contentToReturn.stats).length === 0;

    return {
        uri,
        parameters: options.parameters,
        entities  : dataResource.source_entities || [],
        content   : onlyEmptyStats ? null : contentToReturn,
        // Extend stats with insight if it exists, then remap keys
        stats     : formatValues(
            reMapKeys(
                extendStatsWithFilledInsight(stats),
                hasMap ? map.stats : null,
            ),
            map?.statsFormats
        ),
        total     : totalToReturn,
        // Refine headers to extract pagination/filters.. etc..
        pagination: extractPaginationFromData(response)
    };
};

/**
* Refine pagination data from raw server answer
*
* @param object data the raw data provided by API
*
* @return object
*/
const extractPaginationFromData = (data) => {
    const { headers }  = data || {},
        pagination = {};

    if (!headers) {
        return null;
    }

    // Extract pagination from headers
    headers.forEach((value, key) => {
        if (!key.match('x-pagination-')) {
            return;
        }

        const splitedKey  = key.split('x-pagination-'),
            paginationKey = splitedKey[1];

        // Integers headers
        if (paginationKey === 'total') {
            pagination[paginationKey] = parseInt(value, 10);
            return;
        }

        // JSON headers
        if (paginationKeys.indexOf(paginationKey) !== -1) {
            pagination[paginationKey] = JSON.parse(value);
            return;
        }

        // Limit
        if (paginationKey === 'limit') {
            const limitArray = value.split(',');
            pagination[paginationKey] = {
                min: limitArray[0],
                max: limitArray[1]
            };
        }
    });

    return pagination;
};

/**
* Fetch data from API and store resource response.
*
* @param object element    An element to fetch resource
* @param object parameters Some additionnal parameters (as filters) to fetch data
*
* @return {Promise}
*/
export const collect = (options) => (dispatch, getState) => {
    const { parameters } = options,
        key              = createRequestKey(options);


    return makeCancelable(
        new Promise((resolve) => {
            /**
            * Return the wanted mineral from stock
            */
            const getMineralFromStock = (type) => getState().get('resource').get(type),
                mineralFromStock      = getMineralFromStock(key),
                learnResources        = learn(['resources']);

            // Ensure to know ressources before collect data from them
            learnResources(dispatch, getState).then(
                ({ resources }) => {
                    // The mineral has been already collected, use the one provided by the stock
                    if (!_.isUndefined(mineralFromStock)) {
                        if (mineralFromStock.get('isLoaded')) {
                            const response = mineralFromStock.get('response'),
                                uri        = mineralFromStock.get('uri');

                            resolve(refine(options, response || null, resources, uri));
                            return;
                        }
                        // Loading state.
                        return;
                    }

                    let request = extractRequest(options);
                    // Set the loading STATE
                    dispatch(Actions.setLoadingState(key, request));

                    // Add parameters and resources in current request
                    request = { ...request, parameters, resources };

                    // Bind a resolve if a promise in pool with same key exists
                    if (!_.isUndefined(PROMISE_POOL[key])) {
                        const { promise } = PROMISE_POOL[key];
                        promise.then(response => {
                            resolve(refine(options, response || null, resources, getURI(request)));
                        });
                        return;
                    }

                    // Set the loading state
                    fetchResource(key, request, (response, uri) => {
                        // Set the loaded STATE
                        dispatch(Actions.setLoadedState(key, response, uri));
                        // Refine the mineral to obtain the data to return
                        resolve(refine(options, response || null, resources, uri));
                    });
                }
            );
        // If cancelled from outside, this should cancel the promise
        }), () => cancelCollect(key, dispatch)
    );
};

// Export default
export default Actions;
