/**
 * Return selected filter labels using filter key and filter selected
 *
 * @returns Component
 */
import React                    from 'react';
import { Skeleton }             from 'antd';
import { connect }              from 'react-redux';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import FilterValues             from 'helpers/Filters/FilterValues';
import { Icon }                 from 'helpers';
import { getResourceFilters }   from 'store/actions/resource';
import { updateMemberSettings } from 'store/actions/auth';
import { emitEvent }            from 'store/actions/sockets';


import './assets/collection-sentence.less';

/**
* Render the Sentence
* React Functional Components (Hooks)
*
* @returns JSX
*/
const CollectionSentence = (props) => {  // eslint-disable-line max-lines-per-function
    /**
    * Get filters from resource and parameters
    *
    * @returns {object}
    */
    const getParameters = () => {
        const {
                resource, filtersDef,
                parameters,
            }               = props,
            resourceFilters = getResourceFilters(parameters, resource);

        return parameters && resourceFilters && filtersDef ? _.pickBy(parameters, (values, filterKey) => {
            const filterDef  = filtersDef.find(knowledge => knowledge.id === filterKey.replace('(graph)', '')),
                filterLabel  = filterDef?.label,
                isToBeRender = !!resourceFilters.find(key => key === filterKey);

            return values?.length && filterLabel && isToBeRender;
        }) : {};
    };

    /**
    * Render All filters values.
    *
    * @returns JSX
    */
    const renderFilterValues = parameters => {
        const {
            filtersDef, dynamicFilters,
            onClickResetFilter, dynamicFiltersPool,
        } = props;

        return parameters && filtersDef ? _.keys(parameters).map((filterKey, index) => {
            const filterDef       = filtersDef.find(knowledge => knowledge.id === filterKey.replace('(graph)', '')),
                filterLabel       = filterDef?.label,
                filterIcon        = filterDef?.icon,
                dynamicFilter     = dynamicFilters && (dynamicFilters[_.camelCase(filterKey)] ||  dynamicFilters[filterKey]),
                dynamicFilterPool = dynamicFiltersPool
                    && (dynamicFiltersPool[_.camelCase(filterKey)] ||  dynamicFiltersPool[filterKey]),
                values            = parameters[filterKey];

            if (!values?.length || !filterLabel) {
                return false;
            }

            return (
                <React.Fragment key={filterKey}>
                    {index > 0 && <span key="logical" className="logical">AND</span>}
                    <FilterValues
                        key="value"
                        filterLabel={filterLabel}
                        filterKey={filterKey}
                        dynamicFilter={dynamicFilter}
                        dynamicFilterPool={dynamicFilterPool}
                        values={values}
                        onRemove={() => onClickResetFilter(filterKey)}
                        icon={filterIcon}
                    />
                </React.Fragment>
            );
        }).filter(mapped => mapped) : [];
    };


    /**
    * Get resource models
    *
    * @return array
    */
    const getResourceModels = () => {
        const { resource } = props,
            { models }     = resource;

        return models;
    };


    /**
     * Render a span with number of model found (use pagination)
     *
     * @param {object} model
     */
    const renderSpanSelectedEntity = (model) => {
        const {
                pagination, basePagination, entitiesDef
            } = props,
            modelEntity            = entitiesDef?.find((obj) => obj.id === model.type),
            models                 = getResourceModels(),
            firstTotalByType       = _.get(basePagination, 'totalbytype', false),
            totalByType            = _.get(firstTotalByType, model.type, false),
            multiModels            = models.length > 1,
            { totalbytype, total } = pagination || {},
            entityTotal            = (multiModels && totalbytype ? totalbytype[modelEntity?.id] : total) || 0,
            labelTotal             = entityTotal > 1 ? modelEntity?.label_plural : modelEntity?.label,
            totalByTypeString      = totalByType && entityTotal < totalByType ? ` on ${totalByType}` : '';

        return modelEntity && (
            <span key={`${modelEntity.id}-${entityTotal}`} className="entity">
                <span className="number">{` ${entityTotal} `}</span>
                <span>{`${labelTotal}${totalByTypeString}`}</span>
                <span className="separator">
                    <span className="default">,</span>
                    <span className="last"> and</span>
                </span>
            </span>
        );
    };


    /**
    * Get filters from search
    *
    * @returns {object}
    */
    const getParametersFromSearch = () => {
        const { searchFilters } = props,
            { filtersDef }      = props;

        return _.pickBy(searchFilters, (value, filterKey) => {
            return filtersDef.find(filter => filter.id === filterKey && filter.isQueryFilter);
        });
    };


    /**
    * Get models type from resource
    *
    * @returns {array}
    */
    const getModelsType = () => {
        const { resource } = props,
            { models }     = resource || {},
            modelsType     = _.map(models, 'type');

        return modelsType;
    };


    /**
    * Trigger when a collection view mode is selected
    *
    * @param {string} mode
    */
    const onSelectViewMode = mode => {
        const {
                memberSettings,
                updateMemberSettings,
                emitEvent,
            }                  = props,
            { list_view_mode } = memberSettings;

        if (mode === list_view_mode) {
            return;
        }

        emitEvent({
            module: 'collection',
            name  : 'change-list-view-mode',
            data  : {
                list_view_mode: mode,
            }
        });

        updateMemberSettings({
            ...memberSettings,
            list_view_mode: mode,
        });
    };


    /**
    * Render list view and table view selectors
    *
    * @returns {JSX}
    */
    const renderViewSelector = () => {
        const { memberSettings }  = props,
            modelsType            = getModelsType(),
            isOrgunit             = _.includes(modelsType, 'orgunit'),
            { list_view_mode }    = memberSettings || {},
            isListMode            = !list_view_mode || list_view_mode === 'List',
            classNameList         = isListMode ? 'selected' : '',
            classNameDetailedList = !isListMode ? 'selected' : '';

        if (!isOrgunit) {
            return null;
        }

        return (
            <div className="selectors">
                <div
                    className={classNameList}
                    onClick={() => onSelectViewMode('List')}
                >
                    List view
                    <Icon id="list-view" height={18} />
                </div>

                <div
                    className={classNameDetailedList}
                    onClick={() => onSelectViewMode('DetailedList')}
                >
                    Detailed list view
                    <Icon id="detailed-list-view" height={16} />
                </div>
            </div>
        );
    };


    /**
    * Render all filters
    *
    * @returns JSX || bool
    */
    const renderFilters = () => {
        const { showResetFilters } = props,
            parameters             = getParameters(),
            parametersFromSearch   = getParametersFromSearch(),
            params                 = {...parametersFromSearch, ...parameters},
            filterMapped           = renderFilterValues(params),
            filtersKeys            = _.keys(filterMapped),
            hasFilters             = filtersKeys.length > 0,
            hasParameters          = !_.isEmpty(parameters);

        return hasFilters && (
            <div key="sentence-filters" className="sentence-filters">
                <span key="label">Filters :</span>
                {filterMapped}
                {showResetFilters && hasParameters && renderResetFilters()}
            </div>
        );
    };


    /**
     *
     */
    const renderResetFilters = () => {
        const { onClickResetFilter } = props;
        return (
            <span
                key="reset"
                className="reset-all-filters"
                onClick={() => onClickResetFilter()}
            >
                Reset filters
            </span>
        );
    };


    /**
     *
     */
    const renderTotal = () => {
        const {
                pagination, basePagination, sentenceSuffix
            }                      = props,
            filterMapped           = getParameters(),
            filtersKeys            = _.keys(filterMapped),
            hasFilters             = filtersKeys.length > 0,
            firstTotal             = _.get(basePagination, 'total', 0),
            models                 = getResourceModels(),
            multiModels            = models.length > 1,
            isLoading              = !pagination,
            { totalbytype, total } = pagination || {},
            modelsToIterate        = multiModels ? models.filter((def) => totalbytype && totalbytype[def.type]) : models;

        return (
            <div className="sentence-total">
                {
                    total > 0
                        ? (
                            <>
                                <span className="entities">{modelsToIterate.map(renderSpanSelectedEntity)}</span>
                                {hasFilters && firstTotal > 0 && total < firstTotal && (
                                    <span>
                                        <span>among</span>
                                        <span className="number">
                                            {` ${firstTotal} `}
                                        </span>
                                    </span>
                                )}
                                {sentenceSuffix}
                            </>
                        )
                        : isLoading && <Skeleton active paragraph={false} />
                }
            </div>
        );
    };

    /**
    * Render the collection sentence
    *
    * @return JSX
    */
    const render = () => {
        return (
            <>
                <div className="sentence-collection">
                    {renderFilters()}
                    {renderTotal()}
                </div>
                {renderViewSelector()}
            </>
        );
    };

    // The render call
    return render();
};

CollectionSentence.propTypes = {
    resource            : PropTypes.object,
    parameters          : PropTypes.object,
    pagination          : PropTypes.object,
    basePagination      : PropTypes.object,
    sentenceSuffix      : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    dynamicFilters      : PropTypes.object,
    showResetFilters    : PropTypes.bool,
    onClickResetFilter  : PropTypes.func,
    dynamicFiltersPool  : PropTypes.object,
    searchFilters       : PropTypes.object,
    memberSettings      : PropTypes.object,
    updateMemberSettings: PropTypes.func.isRequired,
    emitEvent           : PropTypes.func,
};

CollectionSentence.defaultProps = {
    showResetFilters: true
};

/**
* Bind the store to to component
*/
const mapStateToProps = state => {
    const filtersDef   = state.get('knowledge').library.get('filters'),
        entitiesDef    = state.get('knowledge').library.get('entities'),
        memberSettings = state.getIn(['auth', 'member', 'settings']);

    return {
        filtersDef,
        entitiesDef,
        memberSettings,
    };
};

export default connect(mapStateToProps, {
    updateMemberSettings,
    emitEvent,
})(CollectionSentence);
