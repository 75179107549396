/**
 * Entity map actions, perform fetching and storing action
 *
 * @use: import { fetch } from './store/actions/entityMap'
 *       fetch('persons', { from: [Map] });
 *
 */
import _                       from 'lodash';
import React                   from 'react';
import { requestTimeout }      from 'utils/requestTimeout';
import { dataGet }             from 'utils/api';
import { query }               from 'core/utils/Yoomap';
import notification            from 'helpers/notification.js';
import * as types              from './types/yoomap';
import * as SocketTypes        from './types/sockets';

const organizationsToSearch      = {};
const organizationSearchDebounce = {};

/**
* Main method to extract/nurture data from/to the entity map.
*
* @param {string} type The wanted entity type
* @param {mixed}  data The data/options to fetch entities
*
* @return {Void}
*/
export const searchOrganization = (entity, platform) => (dispatch, getState) => {
    const state               = getState(),
        {
            id, org_name, eAddresses
        }                     = entity || {},
        { url }               = platform || {},
        yoomapSlice           = state.get('yoomap'),
        matchedOrgsByPlatform = yoomapSlice?.get('matchedOrgsByPlatform'),
        matchedOrgs           = url && matchedOrgsByPlatform?.get(url),
        e_addresses           = eAddresses && _.isObject(eAddresses) && !_.isArray(eAddresses)
            ? JSON.stringify(eAddresses)
            : '{}',
        variables             = {
            id,
            org_name,
            e_addresses
        };

    // Noway
    if (!id || !url || !org_name) {
        return;
    }

    // Already fetched
    if (matchedOrgs?.get(id)) {
        return;
    }

    manageLoadingState(url, [{id}], dispatch);

    // Push to pool
    if (!organizationsToSearch[url]) {
        organizationsToSearch[url] = {};
    }
    organizationsToSearch[url][id] = variables;

    // Cancel recent call (DEBOUNCING)
    if (organizationSearchDebounce && organizationSearchDebounce[url]) {
        organizationSearchDebounce[url].cancel();
        organizationSearchDebounce[url] = null;
    }

    // Trigger the search for all organizations
    organizationSearchDebounce[url] = requestTimeout(
        () =>  {
            searchAllOrganizations(platform)(dispatch);
        },
        100
    );
};


/**
 * Go to search all organization in the pool
 *
 * @param {object} entity
 * @param {object} platform
 * @param {func}   cb
 *
 * @return {Void}
 */
export const sendOrganization = (entity, platform, cb) => (dispatch, getState) => {
    const { url }     = platform,
        { id }        = entity,
        fieldsPromise = getFieldsForMutation(entity, getState);

    dispatch({
        type   : SocketTypes.WEB_SOCKETS_EMIT_EVENT,
        payload: {
            module: 'yoomap',
            name  : 'send-organization',
            data  : {
                platform: platform.api_url,
            }
        }
    });


    fieldsPromise.then((fields) => {
        manageLoadingState(url, [{id}], dispatch);
        executeSendOrganizationMutation(platform, fields, entity, dispatch, cb);
    });
};


/**
 * Execute mutation to yoomap api
 * @param {object} platform
 * @param {array}  fields
 * @param {object} entity
 * @param {func}   dispatch
 * @param {func}   cb
 *
 * @returns {Void}
 */
const executeSendOrganizationMutation = (platform, fields, entity, dispatch, cb) => { // eslint-disable-line
    query(
        platform,
        {
            queryKey : 'addOrganization',
            variables: { fields },
        }
    ).then((data) => {
        const { insightStartupMutation } = data;

        dispatch({
            type   : types.STORE_SEARCHED_ORGANIZATION,
            payload: {
                url          : platform.url,
                organizations: [{
                    id       : entity.id,
                    id_yoomap: insightStartupMutation && insightStartupMutation.id,
                    url      : insightStartupMutation && insightStartupMutation.url,
                    status   : 'done'
                }]
            }
        });
    }).catch((queryObj) => {
        cb && cb();
        const { response } = queryObj,
            { errors }     = response || {},
            {
                message, description, validation
            }              = (errors && errors[0]) || {};

        // Return the yoomap error in notification
        if (errors) {
            notification({
                type       : 'error',
                message    : validation ? 'Validation error' : message,
                description: validation && _.map(validation, (mess, key) => <div>{key}: {mess}</div>) // eslint-disable-line
                    || description,
            });

            dispatch({
                type   : SocketTypes.WEB_SOCKETS_EMIT_EVENT,
                payload: {
                    module: 'yoomap',
                    name  : 'send-organization-error',
                    data  : {
                        platform: platform.api_url,
                        fields  : _.omit(fields, ['logo']),
                        errors,
                    }
                }
            });

            return;
        }

        this.onHttpError(queryObj, 'send');
    });
};


/**
* Get the logo of the entity
* @param {object} entity
*
* @returns Promise
*/
const getLogo = (entity) => {
    const { id } = entity,
        ids      = [id];

    return new Promise((resolve) => {
        dataGet('/logos', { data: { ids } })
            .then(
                ({ body }) => {
                    _.each(ids, (logoId) => {
                        const logoData = body[logoId];
                        resolve(logoData);
                    });
                }
            );
    });
};

/**
 * Map entity data to get fields array (to send to yoomap mutation)
 *
 * @param {object} entity
 * @param {func}   getState
 *
 * @returns {Promise:array}
 */
const getFieldsForMutation = async (entity, getState) => {
    const state        = getState(),
        knowledge      = state.get('knowledge'),
        { library }    = knowledge,
        orgClasses     = library.get('org-classes'),
        logo           = await getLogo(entity),
        {
            id, org_name, org_aka,
            eAddresses, descriptions,
            pAddress, topics, org_class
        }               = entity,
        {
            presentation,
            'short description': short_description
        }               = descriptions || {},
        { description } = presentation || {},
        {
            description: short_description_text
        }               = short_description || {},
        variables       = {
            source           : 'insight',
            id,
            org_name,
            org_aka          : org_aka && JSON.stringify(org_aka),
            description,
            short_description: short_description_text,
            org_type         : orgClasses.find(definition => definition.id === org_class)?.label,
            e_addresses      : eAddresses && !_.isArray(eAddresses) && JSON.stringify(eAddresses),
            p_address        : pAddress &&  JSON.stringify(pAddress),
            logo             : logo && JSON.stringify(logo),
            topics           : topics && JSON.stringify(topics),
        },
        fields          = _.values(_.mapValues(variables, (value, key) => ({
            uik: key,
            value
        })));

    return fields.filter(filed => !!filed.value);
};


/**
 * Manage loading state for organization to fetch
 *
 * @param {string} url
 * @param {object} variables
 * @param {func}   dispatch
 *
 * @returns {void}
 */
const manageLoadingState = (url, names, dispatch) => {
    const organizationsToFetch = _.map(
        names,
        organization => {
            return {
                id    : organization.id,
                status: 'loading'
            };
        }
    );

    dispatch({
        type   : types.STORE_SEARCHED_ORGANIZATION,
        payload: {
            url,
            organizations: organizationsToFetch
        }
    });
};


/**
 * Go to search all organization in the pool
 * @param {object} platform
 *
 * @returns {void}
 */
const searchAllOrganizations = (platform) => (dispatch) => {  // eslint-disable-line max-lines-per-function
    const { url } = platform,
        names     = _.values(organizationsToSearch[url]),
        variables = { names};

    // Reset the pool
    organizationsToSearch[url] = {};

    manageLoadingState(url, names, dispatch);

    query(
        platform,
        {
            queryKey: 'searchBulkOrganizations',
            variables
        }
    ).then((data) => {
        const { insightStartupSearchMultiple } = data || {},
            organizations = insightStartupSearchMultiple && _.map(
                names,
                organization => {
                    const yoomapOrganization = insightStartupSearchMultiple.find(result => result.id_insight === organization.id);
                    return {
                        id       : organization.id,
                        id_yoomap: yoomapOrganization && yoomapOrganization.id,
                        url      : yoomapOrganization && yoomapOrganization.url,
                        status   : 'done'
                    };
                }
            );

        dispatch({
            type   : types.STORE_SEARCHED_ORGANIZATION,
            payload: {
                url,
                organizations
            }
        });
    }).catch(() => {
        notification({
            type   : 'error',
            message: `Error on fetching information on ${url}`,
        });

        const organizations = _.map(
            names,
            organization => {
                return {
                    id    : organization.id,
                    status: 'error'
                };
            }
        );

        dispatch({
            type   : types.STORE_SEARCHED_ORGANIZATION,
            payload: {
                url,
                organizations
            }
        });
    });
};


// Export default
export default { searchOrganization, sendOrganization };
