import _                              from 'lodash';
import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { getAccessToken, getTicket }  from 'store/actions/auth';
import { fetchPlatformsTokens }       from 'store/actions/auth/platforms';
import YoomapLogin                    from './Login';
import { connect }                    from 'react-redux';

/**
* Manage to send export yoomap
*
*/
class YoomapDecorator extends Component {

    /**
    * Instanciate the modal
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onCancel');
    }

    /**
    * Trigger on close th emodal
    */
    onCancel() {
        const { onClose } = this.props;

        onClose();
    }

    /**
     * Open a popup to login partner
     */
    openYoomapLoginPopup(platform) {
        const { url }    = platform,
            access_token = getAccessToken(),
            ticket       = getTicket(),
            urlEncode    = document.location.protocol + '//' + document.location.hostname,
            urlLogin     = url + '/partner-auth/insight'
                + `?url=${btoa(unescape(encodeURIComponent(urlEncode)))}`
                + (ticket       ? `&userticket=${ticket}`            : '')
                + (access_token ? `&useraccesstoken=${access_token}` : '');

        window.open(urlLogin, 'Login to partner', 'height=500,width=470');
    }


    /**
     * Get Platform from this.props.url
     */
    getPlatformFromUrl() {
        const { url, platforms, fetchPlatformsTokens } = this.props,
            platformsObject                            = platforms.toJS ? platforms.toJS():{},
            platform                                   = platformsObject[url] ? platformsObject[url]: {},
            { token }                                  = platform;

        if (!token) {
            fetchPlatformsTokens();
        }

        return platform;
    }

    /**
    * Render the render Iframe login when the platform is not connected
    *
    * @return Component
    */
    render() {
        const { url,
                onClose,
                children
            }         = this.props,
            platform  = this.getPlatformFromUrl(),
            { token } = platform;

        if(!url) {
            return null;
        }
        if(url.length<1) {
            return null;
        }

        // Open yoomap popin (in new window)
        if (!token) {
            this.openYoomapLoginPopup(platform);
        }

        return token
            ? React.cloneElement(children, { platform, onClose })
            : (
                <YoomapLogin
                    platform={platform}
                    onClose={onClose}
                    // Pass openYoomapLoginPopup to call it on login button click in the YoomapLogin component
                    openYoomapLoginPopupCb={this.openYoomapLoginPopup}
                />
            );
    }

}

YoomapDecorator.propTypes = {
    url                 : PropTypes.string,
    platforms           : PropTypes.object,
    onClose             : PropTypes.func,
    fetchPlatformsTokens: PropTypes.func,
    children            : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

YoomapDecorator.defaultProps = {
    url                 : '',
    platforms           : {},
    children            : [],
    onClose             : () => { },
    fetchPlatformsTokens: () => { },
};


/**
 * Convert state store to props
*/
const mapStateToProps = (state) => {
    const auth = state.get('auth');

    return {
        platforms: auth.get('platforms') ? auth.get('platforms'): {}
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    fetchPlatformsTokens
})(YoomapDecorator);

