import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Tabs, Modal }      from 'antd';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Row, Col }         from 'helpers';
import { learn }            from '../store/actions/knowledge';
import { htmlize, addBr }   from  'utils/text';
import { onParentResize }   from 'utils/dom';
import Icon, {
    StepForwardOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CaretRightOutlined

}                           from '@ant-design/icons';

import './assets/guider.less';

const maxPopin = 1750;

/**
 * Class Guider,
 * Explain to the user what the Metrics are for.
 */
class Guider extends Component {

    /**
    * Initialize the state of the class
    */
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            helpMetrics: false
        };
    }

    /**
    * Learn all the required knowledge
    */
    componentDidMount() {
        const { learnKnowledge, help } = this.props,
            { texts }                  = help || {},
            { didyouknow }             = texts || {};

        this.funfact = didyouknow && _.isArray(didyouknow) && _.sample(didyouknow);

        learnKnowledge(['help-metrics', 'metrics'])
            .then(this.setState.bind(this));
    }

    /**
     *
     */
    componentDidUpdate() {
        this.cleanup = this.ref && onParentResize(this.ref, this.setParentSize);
    }

    /**
     *
     * @param {*} size
     */
    setParentSize= (size) => {
        this.setState(size);
    };

    /**
    * Return the modal width
    *
    * @return number
    */
    getWidth() {
        const { clientWidth } = document.body,
            padding           = this.getPadding();

        return  clientWidth > maxPopin
            ? maxPopin
            : clientWidth - (padding * 2) - 100;
    }

    /**
    * Return the modal width
    *
    * @return number
    */
    getHeight() {
        const {
                clientHeight,
                clientWidth
            }           = document.body,
            popinHeight = (this.getWidth() * clientHeight) / clientWidth;

        return popinHeight > maxPopin
            ? maxPopin
            : popinHeight - 100;
    }

    /**
    * Returns general padding
    *
    * @return number
    */
    getPadding() {
        const  { clientWidth } = document.body;

        return this.full ? 0 : clientWidth / 40;
    }

    /**
    * General onClick to stop propagation
    */
    generalClick(e) {
        e.stopPropagation();
        return false;
    }

    /**
    * Render the text column
    */
    renderRightColumn() {
        const { insight, insightColor } = this.props,
            { hideGraph, label, help }  = this.props,
            { texts }                   = help,
            trueInsightColor            = insightColor || 'glacier';

        if (!texts) {
            return false;
        }

        return (
            <Col ratio="1/2" className="col-right">
                <div className="maintitle">
                    {label}
                </div>
                <div className="interest title">
                    {_.get(texts, 'interest', '')}
                </div>
                <div className="interest description">
                    {htmlize(addBr(_.get(texts, 'read', '')))}
                </div>
                { hideGraph ? null : (
                    <div className={`insight ${trueInsightColor}`}>
                        {_.isString(insight) && htmlize(insight)}
                    </div>
                )}
                {this.renderHow()}
                {texts.services && (
                    <div className="services">
                        <div className="title">Services</div>
                        <div className="description">
                            {this.renderServices()}
                        </div>
                    </div>
                )}
                {texts.consulting && (
                    <div className="further">
                        <div className="title">Want to push further your technological study ?</div>
                        <div className="description">
                            <div className="tabs">{this.renderConsulting()}</div>
                        </div>
                    </div>
                )}
            </Col>
        );
    }

    /**
    * Render "How the graph is built ?" part
    *
    * @return JSX
    */
    renderHow() {
        const { help } = this.props,
            { texts }  = help;

        return (
            <div className="how">
                <div className="title">How is the graph built ?</div>
                <div className="description">
                    <p>{htmlize(addBr(_.get(texts, 'construction', '')))}</p>
                    <p className="more">
                        <Icon component={StepForwardOutlined} style={{ fontSize: 11 }} />
                        <a
                            href={_.get(texts, 'howUrl', 'http://www.questel.com/technology-landscape-metrics/')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            More details
                        </a>
                    </p>
                </div>
            </div>
        );
    }


    /**
    * Render the antD tabs
    *
    * @return JSX
    */
    renderTabs(items) {
        return (
            <Tabs items={items.map((item) => ({
                key     : item.title,
                label   : item.title,
                children: (
                    <div>
                        <p>{item.description}</p>
                        <p className="action">
                            <Icon component={StepForwardOutlined}  style={{ fontSize: 11 }} />
                            <a href={item.href} target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`More on ${item.title}`}
                            </a>
                        </p>
                    </div>
                )})
            )}
            />
        );
    }

    /**
    * Get services content / tabs
    *
    */
    renderServices() {
        const { help } = this.props,
            { texts }  = help,
            services   = _.get(texts, 'services', []);

        if (!services.length) {
            return false;
        }

        return this.renderTabs(services);
    }

    /**
    * Render the tabs for call to action consulting
    *
    * @return JSX
    */
    renderConsulting() {
        const { help }     = this.props,
            { texts }      = help,
            { consulting } = texts;

        if (!consulting.length) {
            return false;
        }

        return this.renderTabs(consulting);
    }

    /**
    * Render the column with graph and analysis
    *
    * @return JSX
    */
    renderLeftColumn() { // eslint-disable-line max-lines-per-function
        const {
                children, hideGraph, member,
                horizontalGraph, help,
            }                 = this.props,
            { texts }         = help,
            email             = member.get('email'),
            academyParams  = `apicode=109835&apikey=OYIVOWWJCVB5D6R7UX&task=login&create=true&groupID=2202&oID=47&email=${email}`,
            graphRatio     = horizontalGraph ? '7/12' : '3/4',
            otherRatio     = hideGraph ? '1' : (
                horizontalGraph ? '5/12' : '1/4'
            );

        return (
            <Col ratio="1/2" className="col-left"
                spacing={20}
            >
                {!hideGraph && (
                    <Row
                        className="builder graph"
                        ratio={graphRatio}
                        width="100%"
                    >
                        {children}
                    </Row>
                )}
                <Row ratio={otherRatio}>
                    <Col>
                        {this.funfact && (
                            <div className="funfact">
                                <div className="texts">
                                    <Icon component={DoubleLeftOutlined} style={{ fontSize: 15, color: '#BBB' }} />
                                    {this.funfact}
                                    <Icon component={DoubleRightOutlined} style={{ fontSize: 15, color: '#BBB' }} />
                                </div>
                            </div>
                        )}
                        <div className="academy">
                            <div className="title">{_.get(texts, 'academy.title')}</div>
                            <div className="description">
                                <Icon component={CaretRightOutlined} />
                                <a
                                    href={`https://academy.questel.com/api.aspx?${academyParams}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {_.get(texts, 'academy.description', '')}
                                </a>
                            </div>
                            <div className="icon" />
                        </div>
                    </Col>
                </Row>
            </Col>
        );
    }

    /**
    * Render the texts of the Guide
    *
    * @return JSX
    */
    render() {
        const { closeModal, help, visible } = this.props,
            { helpMetrics, metrics }        = this.state,
            { texts }                       = help || {},
            width                           = this.getWidth(),
            height                          = this.getHeight();

        if (!helpMetrics || !metrics || !texts) {
            return null;
        }

        return (
            <div onClick={this.generalClick}>
                <span className="help-icon" onClick={this.showModal}>
                    i
                </span>
                <Modal
                    key="guide-modal"
                    className="guide-modal"
                    title={false}
                    footer={null}
                    onCancel={closeModal}
                    width={width}
                    height={height}
                    open={visible}
                    destroyOnClose
                >
                    <Row
                        width={width}
                        height={height}
                        className="guider"
                        onClick={this.generalClick}
                    >
                        {this.renderLeftColumn()}
                        {this.renderRightColumn()}
                    </Row>
                </Modal>
            </div>
        );
    }

}

Guider.propTypes = {
    children       : PropTypes.any,
    closeModal     : PropTypes.func.isRequired,
    help           : PropTypes.object,
    hideGraph      : PropTypes.bool,
    horizontalGraph: PropTypes.bool,
    insight        : PropTypes.string,
    insightColor   : PropTypes.string,
    label          : PropTypes.any,
    learnKnowledge : PropTypes.func.isRequired,
    sourceStats    : PropTypes.shape({}),
    visible        : PropTypes.bool,
    member         : PropTypes.shape({
        get: PropTypes.func,
    }).isRequired,
};

Guider.defaultProps = {
    insight        : '',
    insightColor   : '',
    sourceStats    : {},
    hideGraph      : false,
    visible        : false,
    horizontalGraph: true
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    knowledge: state.get('knowledge'),
    member   : state.getIn(['auth', 'member'])
});

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Guider);

