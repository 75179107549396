// Import libs
import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import { Checkbox, Skeleton }   from 'antd';
import { requestTimeout }       from 'utils/requestTimeout';
import { str2DomFormat }        from 'utils/text';
import { Icon }                 from 'helpers';
import ModelAction              from 'helpers/Action/Model';

// Import dumb components by entity type
import Expert                   from './Row/Expert';
import Webdoc                   from './Row/Webdoc';
import Patent                   from './Row/Patent';
import Orgunit                  from './Row/Orgunit';
import OrgunitNetworkNode       from './Row/OrgunitNetworkNode';
import Mixed                    from './Row/Mixed';
import Project                  from './Row/Project';
import Publication              from './Row/Publication';
import ClinicalTrial            from './Row/ClinicalTrial';
import CorporateActivityEvent   from './Row/CorporateActivityEvent';
import NewsLink                 from './Row/NewsLink';

import {
    entityIsInNewsletter,
}                               from 'store/actions/userView';

import './assets/row.less';

// Create the component collection
const RowsComponents = {
    patent                  : Patent,
    orgunit                 : Orgunit,
    network_orgunit_node    : OrgunitNetworkNode,
    webdoc                  : Webdoc,
    expert                  : Expert,
    network_expert_node     : Expert,
    mixed                   : Mixed,
    project                 : Project,
    scidoc                  : Publication,
    clinicaltrial           : ClinicalTrial,
    corporate_funding       : CorporateActivityEvent,
    corporate_investment    : CorporateActivityEvent,
    corporate_acquisition   : CorporateActivityEvent,
    corporate_activity_event: CorporateActivityEvent,
    news_link               : NewsLink
};

const classNamesByType = {
    network_orgunit_node : 'orgunit',
    network_expert_node  : 'expert',
    clinicaltrial        : 'clinical-trial',
    corporate_investment : 'corporate_activity_event',
    corporate_acquisition: 'corporate_activity_event',
    scidoc               : 'publication',
};

/**
 * Display a list row
 *
 */
class Row extends PureComponent {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(
            this,
            'toggleRow', 'onSelectEntity', 'renderActiveStatus', 'onActionToggleDisplay', 'renderModelAction',
        );

        this.state = {
            firstRender: true,
        };
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { firstRender } = this.state;

        if(firstRender) {
            requestTimeout(() => {
                this.setState({ firstRender: false });
            }, 100);
        }
    }

    /**
    * Select entity
    *
    * return false
    */
    onSelectEntity(e) {
        const { entity, onSelectEntity } = this.props;

        e.stopPropagation();

        onSelectEntity(entity);

        return false;
    }

    /**
    * Manage to send the entity id when the row has been toggle
    *
    * return false
    */
    toggleRow() {
        const { toggleRow, entity } = this.props;

        toggleRow(entity.id);
    }


    /**
     *
     * @param {boolean} menuVisible
     */
    onActionToggleDisplay({menuVisible}) {
        this.setState({ actionMenuVisible: menuVisible });
    }


    /** Render the checkbox to select item to export
    *
    * @return JSX
    */
    renderCheckbox() {
        const { allowExport, entity, isSelected } = this.props,
            { id, isLoading, label } = entity;

        return allowExport && (id || isLoading) ? (
            <td className="select">
                <Checkbox
                    onClick={this.onSelectEntity}
                    checked={isSelected}
                    disabled={isLoading}
                    data-qa-key={`checkbox-${str2DomFormat(label)}`}
                />
            </td>
        ) : null;
    }

    /** Render the arrow-icon to expand row (retract & expand arrow)
    *
    * @return JSX
    */
    renderArrowIcon() {
        const { expandRow, toggleRow,
                entity, isExpandable,
            }          = this.props,
            { type }   = entity,
            expandable = isExpandable(type),
            iconId     = expandRow ? 'retract-arrow' : 'expand-arrow';

        return expandable && (
            <td className="arrow-icon clickable" onClick={this.toggleRow}>
                <Icon id={iconId}
                    width={9}
                    onClick={toggleRow}
                />
            </td>
        );
    }

    /**
    * Render the collection-open icon
    *
    * @return JSX
    */
    renderOpenableIcon() {
        return (
            <div className="openable-btn">
                <Icon id="collection-open" width={12}
                    height={12}
                />
            </div>
        );
    }

    /**
    * The entity is a bookmark
    *
    * @param {object} bookmark
    * @returns string
    */
    isABookmark() {
        const { entity } = this.props,
            { type }     = entity;

        return type === 'bookmark';
    }

    /**
     * Render the actions button (...)
     *
     * @returns JSX
     */
    renderModelAction() {
        const {
            entity, onClick
        } = this.props;

        return (
            <ModelAction
                entity={entity}
                onClick={onClick}
                onActionToggleDisplay={this.onActionToggleDisplay}
            />
        );
    }


    /**
     * Rendering the active status of the entity. (bookmarked, monitored)
     */
    renderActiveStatus() {
        const { featuredActionsRender } = this.props;

        return (
            <div className="active-status">

                {featuredActionsRender.map(renderAction => renderAction())}
            </div>
        );
    }

    /**
    * Render the entity row
    *
    * @return JSX
    */
    renderRow(classNames) { // eslint-disable-line max-lines-per-function
        const {
                entity, entityType, expandRow,
                isSelected, dataQaKey, sourceModel, context,
                columns, compiledTags, onClick, userViewItem
            }               = this.props,
            {
                firstRender,
            }               = this.state,
            bookmark        = userViewItem?.model,
            { type }        = entity,
            trueType        = entityType || type,
            RowComponent    = RowsComponents[trueType];

        if (!RowComponent) {
            throw new Error(`Row component: ${type} does not exists.`);
        }

        // Add expanded-row class
        if (expandRow) {
            classNames.push('expanded-row');
        }

        // Add selected class
        if (isSelected) {
            classNames.push('selected');
        }

        return !_.isEmpty(columns) && (
            <>
                <tr className="entity-row-separator">
                    <td colSpan="50">
                        <div className="line" />
                    </td>
                </tr>
                <tr data-id={entity.id} className={classNames.join(' ')}>
                    {!firstRender ? (
                        <>
                            {this.renderCheckbox()}
                            {this.renderArrowIcon()}
                            <RowComponent
                                key={entity.id}
                                data-qa-key={dataQaKey}
                                entity={entity}
                                columns={columns}
                                bookmark={bookmark}
                                sourceModel={sourceModel}
                                context={context}
                                onClick={onClick}
                                compiledTags={compiledTags}
                                renderActiveStatus={this.renderActiveStatus}
                                renderModelAction={this.renderModelAction}
                                renderOpenableIcon={this.renderOpenableIcon}
                            />
                        </>
                    ) : (
                        <>
                            <td>
                                <Skeleton key="select" loading
                                    active paragraph={false}
                                />
                            </td>
                            {columns.map(column => (
                                <td>
                                    <Skeleton key={column.id} loading
                                        active paragraph={false}
                                    />
                                </td>
                            ))}
                        </>
                    )}
                </tr>
            </>
        );
    }

    /**
    * Render expanded content
    *
    * @return JSX
    */
    renderExpandedContent(classNames) {
        const { entity, entityType } = this.props,
            { type }                 = entity,
            trueType                 = entityType || type,
            RowComponent             = RowsComponents[trueType];

        if (!RowComponent) {
            throw new Error(`Row component: ${type} does not exists.`);
        }

        return (
            <tr data-id={entity.id} className={`${classNames.join(' ')} expanded`}>
                <RowComponent
                    key={entity.id}
                    {...this.props}
                    showExpandedContent
                />
            </tr>
        );
    }


    /**
    * Render the document according to the props.
    *
    * @return JSX
    */
    render() {
        const {
                entity, entityType, expandRow, disabled
            }                     = this.props,
            { actionMenuVisible } = this.state,
            { type, isLoading }   = entity,
            trueType              = entityType || type,
            className             = classNamesByType[trueType] || trueType,
            classNames            = ['row', className];

        // Add skeleton class
        if (isLoading) {
            classNames.push('skeleton');
        }

        if (disabled) {
            classNames.push('disabled');
        }

        if (actionMenuVisible) {
            classNames.push('action-menu-visible');
        }

        return (
            <>

                {this.renderRow(_.clone(classNames))}

                {expandRow ? this.renderExpandedContent(_.clone(classNames)) : null}

            </>
        );
    }

}

Row.propTypes = {
    allowExport          : PropTypes.any,
    columns              : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    sourceModel          : PropTypes.object,
    context              : PropTypes.object,
    userViewItem         : PropTypes.object,
    entityType           : PropTypes.string,
    expandRow            : PropTypes.bool,
    onClick              : PropTypes.func,
    onSelectEntity       : PropTypes.func,
    entityIsInNewsletter : PropTypes.func,
    isSelected           : PropTypes.bool,
    disabled             : PropTypes.bool,
    toggleRow            : PropTypes.func,
    featuredActionsRender: PropTypes.arrayOf(PropTypes.func),
    compiledTags         : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isExpandable         : PropTypes.func.isRequired,
    entity               : PropTypes.shape({
        id       : PropTypes.any,
        isLoading: PropTypes.any,
        type     : PropTypes.any
    }).isRequired,
    dataQaKey: PropTypes.string,
};

Row.defaultProps = {
    expandRow : false,
    entityType: false,
    isSelected: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
    entityIsInNewsletter,
})(Row);

