/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Skeleton }         from 'antd';
import Icon                 from 'helpers/Icon';

import './assets/concept.less';

/**
* Render The "Concept" menu
*
*/
class Concept extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderConcept');
    }

    /**
    * Render Loading
    *
    * @return JSX
    */
    renderLoading(index) {
        return (
            <div key={`skeleton-${index}`} className="concept">
                <Skeleton active paragraph={false} />
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderConcept(concept, index) {
        const { onSelect, selected } = this.props;

        // Loading state7
        if (!concept || concept.isLoading) {
            return this.renderLoading(index);
        }

        return (
            <div
                key={`concept-${index}`}
                className={`suggest concept ${index === selected ? 'selected' : ''}`}
                onClick={() => { onSelect(concept); }}
            >
                <span className="text">{concept}</span>
            </div>
        );
    }

    /**
     * Render error message
     *
     * @param string error
     * @returns
     */
    renderErrorMessage(error) {
        return (
            <div className="error">
                <Icon
                    type="warning"
                    height={20}
                    width={20}
                />
                <span>{ error }</span>
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { suggestions }         = this.props,
            { data: concepts, error } = suggestions || {};

        return (
            <div className="menu concepts">
                <span className="title">
                    <Icon type="edit" height={12} />
                    Concepts
                </span>
                {
                    error
                        ? this.renderErrorMessage(error)
                        : (concepts || []).map(this.renderConcept)

                }
            </div>
        );
    }

}

Concept.propTypes = {
    onSelect   : PropTypes.func.isRequired,
    selected   : PropTypes.any,
    suggestions: PropTypes.object,
};

Concept.defaultProps = {
};

export default Concept;

