import React, { useState, useEffect }   from 'react';
import { connect }                      from 'react-redux';
import PropTypes                        from 'prop-types';
import { Element, CssLoader }           from 'helpers';
import TagsSelector                     from './Dashboard/TagsSelector';
import { requestTimeout }               from 'utils/requestTimeout';



/**
* Render the AreaDashboard
* React Functional Components (Hooks)
*
* @returns JSX
*/
const AreaDashboard = (props) => {  // eslint-disable-line max-lines-per-function
    const {
            element,
            elements,
            listsAreDisplayed
        }    = props,
        type = listsAreDisplayed ? 'list' : 'graph',

        [elementToRender, setElementToRender] = useState(null),
        [firstRender, setFirstRender] = useState(true);


    useEffect(() => {
        const { configuration } = element,
            elementId           = configuration.elements[type],
            filtredElements     = elements.find((element) => element.id === elementId);

        setElementToRender(filtredElements);
    }, [listsAreDisplayed, element]);


    useEffect(() => {
        if (firstRender) {
            requestTimeout(() => {
                setFirstRender(false);
            }, 100);
        }
    }, [firstRender]);


    /**
    * Render the area dashboard
    *
    * @return JSX
    */
    const render = () => {
        const {
                element, model, storeElementInstances,
                onDataLoaded, navigateTo,
                module, state, tags,
            }          = props,
            dashboard  = firstRender || !elementToRender
                ? (
                    <div className="loader">
                        <CssLoader type="ring" size={100}
                            thickness={2} color="#dddddd"
                        />
                    </div>
                )
                :(
                    <Element
                        className={`area-dashboard ${element.key} ${type}`}
                        key={`${element.id}-${model.id}`}
                        model={model}
                        module={module}
                        state={state}
                        element={elementToRender}
                        navigateTo={navigateTo}
                        onDataLoaded={onDataLoaded}
                        ratioKey={type}
                        tags={tags}
                        storeElementInstances={storeElementInstances}
                    />
                );

        // Tags needed
        return (element?.configuration?.required)
            ? (
                <TagsSelector {...props}>
                    {dashboard}
                </TagsSelector>
            )
            : dashboard;
    };

    // The render call
    return render();
};

AreaDashboard.propTypes = {
    forceEmptyRemoval    : PropTypes.bool,
    entity               : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    elements             : PropTypes.object,
    listsAreDisplayed    : PropTypes.any,
    module               : PropTypes.any,
    navigateTo           : PropTypes.any,
    onDataLoaded         : PropTypes.any,
    state                : PropTypes.any,
    tags                 : PropTypes.any,
    storeElementInstances: PropTypes.any,
    model                : PropTypes.shape({
        id: PropTypes.string
    }),
    element: PropTypes.shape({
        id           : PropTypes.string,
        key          : PropTypes.string,
        configuration: PropTypes.shape({
            elements: PropTypes.object,
            hasLists: PropTypes.bool,
            required: PropTypes.any
        }),
    }).isRequired,
};

AreaDashboard.defaultProps = {
    forceEmptyRemoval: false,
    model            : false,
    entity           : false
};

/**
 */
const mapStateToProps = () => {
    return {
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {})(AreaDashboard);

