/**
 * Insight Error module
 *
 * @exports Component
 */

import PropTypes from 'prop-types';
import React,  { Component } from 'react';

import './assets/error.less';

/**
 * Implement the error page for Insight
 *
 */
class Error extends Component {

    /**
    * Render the 404
    *
    * @return html
    */
    render() {
        const { code } = this.props,
            errorMsg = parseInt(code) === 402 ? 'No active licence detected !' : 'Something went wrong.';

        return (
            <div className="error-module">
                <span className="title">{errorMsg}</span>
                <br />

                <span className="baseline">
                    Feel free to contact our helpdesk for more information
                    <br />
                    <a href="https://www.questel.com/contact/" rel="noreferrer"
                        target="_blank"
                    >
                        https://www.questel.com/contact/
                    </a>
                    <br />
                </span>
                <br />
                <table>
                    <tr>
                        <td>
                            <span className="office">Help Desk Europe</span>
                            <br />
                            +8000 783 78 35
                            <br />
                            Email: help@questel.com
                        </td>
                        <td>
                            <span className="office">Help Desk Americas</span>
                            <br />
                            +1 800 456 7248
                        </td>
                        <td>
                            <span className="office">Help Desk Asia</span>
                            <br />
                            +86 400 080 1105
                        </td>
                    </tr>
                </table>

            </div>
        );
    }

}

Error.propTypes = {
    code: PropTypes.any.isRequired
};

export default Error;
