/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                       from 'lodash';
import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import { Popover }             from 'antd';
import { areNodesInCollision } from 'utils/dom';

import './assets/span-overflow.less';

/**
* Render the Smart "SpanOverflow"
*/
class SpanOverflow extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderSpansInMore');

        this.ref = React.createRef();

        this.state = {
            moreLength          : 0,
            collisionChecked    : true,
            previousPropsToWatch: {}
        };
    }

    /**
    * New props?
    *
    *
    */
    static getDerivedStateFromProps(props, state) {
        const {
                propsToWatch, children
            }                        = props,
            { previousPropsToWatch } = state,
            propsNamesToWatch        = _.keys(propsToWatch),
            propsToWatchChanged      = propsNamesToWatch.reduce(
                (cumulative, propName) => {
                    return cumulative || propsToWatch[propName] !== previousPropsToWatch[propName];
                },
                false
            ),
            {
                collisionChecked,
                moreLength,
            }                        = state;

        return {
            previousPropsToWatch: propsToWatch,
            collisionChecked    : propsToWatchChanged ? false : collisionChecked,
            moreLength          : propsToWatchChanged ? (children ? children.length : 0) : moreLength
        };
    }



    /**
    * Manage collisions
    *
    * @return void
    */
    manageCollisions() {
        const {
                children,
            }          = this.props,
            {
                collisionChecked
            }          = this.state,
            container  = this.ref.current;

        if (collisionChecked || !container || !children.length) {
            return;
        }

        this.moreLengthEvaluate();
    }


    /**
     * Evaluate moreLength and store in state
     *
     * @returns void
     */
    moreLengthEvaluate() {
        const {
                collisionNodeSelector,
                containerNodeSelector,
                children
            }                 = this.props,
            { moreLength }    = this.state,
            // Find nodes
            container         = this.ref.current,
            inputNode         = container.closest(containerNodeSelector),
            collisionNode     = inputNode.querySelector(collisionNodeSelector || '.span-overflow-collide-block'),
            // Then manage collides
            collisionDetected = inputNode && collisionNode && areNodesInCollision(container, collisionNode),
            nbChildren        = children && children.length;

        // Show all span
        if (!collisionDetected & moreLength > 0) {
            this.setState({
                moreLength: moreLength - 1
            });
            return;
        }

        // TODO: detect node collide by index to avoid many render (component life cycle) !!!
        // Set number of spans to put in more
        if (collisionDetected) {
            const newMoreLength = moreLength + 1;
            this.setState({
                moreLength      : newMoreLength < nbChildren ? newMoreLength : nbChildren,
                collisionChecked: true,
            });
            return;
        }
    }

    /**
    * On component just render
    *
    * @return self
    */
    componentDidMount() {
        this.manageCollisions();
    }

    /**
    * On component just render
    *
    * @return self
    */
    componentDidUpdate() {
        this.manageCollisions();
    }


    /**
    * Render the More Popover content
    *
    * @return JSX
    */
    renderSpansInMore() {
        const { children, moreContainerClassName } = this.props,
            { moreLength }                         = this.state;

        return (
            <div className={moreContainerClassName}>
                {children.slice(0, moreLength)}
            </div>
        );
    }

    /**
    * Render more button
    *
    * @return JSX
    */
    renderMore() {
        const { showOnClick } = this.props,
            { moreLength }    = this.state;

        if (!moreLength) {
            return false;
        }

        return (
            <Popover
                trigger={showOnClick ? 'click' : 'hover'}
                placement="bottomLeft"
                content={this.renderSpansInMore()}
                overlayClassName="more-custom"
            >
                <span className="span-overflow-more">
                    {`+ ${moreLength} more`}
                </span>
            </Popover>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                children, collisionNodeSelector,
                collisionNodeWidth, className, morePosition
            }              = this.props,
            { moreLength } = this.state,
            style          = {
                width: collisionNodeWidth,
                right: -collisionNodeWidth
            };

        return (
            <div className={`span-overflow ${className}`} ref={this.ref}>
                {morePosition === 'left' && this.renderMore()}
                {children.slice(moreLength, children.length)}
                {
                    // Add a collisionNode if need
                    !collisionNodeSelector && (
                        <span
                            className="span-overflow-collide-block"
                            style={style}
                        />
                    )
                }
                {morePosition === 'right' && this.renderMore()}
            </div>
        );
    }

}

SpanOverflow.propTypes = {
    moreContainerClassName: PropTypes.string,
    propsToWatch          : PropTypes.object,
    collisionNodeSelector : PropTypes.string,
    containerNodeSelector : PropTypes.string,
    className             : PropTypes.string,
    collisionNodeWidth    : PropTypes.number,
    morePosition          : PropTypes.string,
    showOnClick           : PropTypes.bool,
    children              : PropTypes.array,
};

SpanOverflow.defaultProps = {
    collisionNodeWidth: 5,
    className         : '',
    morePosition      : 'right',
    showOnClick       : false,
};

export default SpanOverflow;
