// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*@primary-text-color: var(--primary-color);

.btt-2-popin-wrapper {

    .ant-modal-title{
        font-weight: bold;
    }

    .ant-modal-header, .ant-modal-body {

        color: @primary-text-color;

        * {
            color: inherit;
        }

    }

    .ant-modal-close {
        background-color: #FFFFFF;
        border-radius: 10px;
        top: -10px;
        right: -10px;
        box-shadow: 0 0 4px 0 rgba(97, 97, 97, 0.5);

        .ant-modal-close-x {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 15px;

            i {
                color: #455a64;
            }
        }
    }
}*/
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/btt2popin.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAoCE","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
