// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list .row.project .label {
  margin-right: 5px;
}
.list .row.project .orgunit,
.list .row.project .expert {
  color: var(--insight-color);
}
.list .row.project .orgunit .clicker .icon,
.list .row.project .expert .clicker .icon {
  top: 0px!important;
}
.list .row.project.expanded td {
  white-space: normal;
}
.list .row.project .ant-skeleton {
  width: 80%;
}
.list .row.project .cost-funding {
  margin-bottom: 10px;
  color: var(--insight-color);
}
.list .row.project .cost-funding .cost,
.list .row.project .cost-funding .funding {
  color: var(--insight-color);
  margin-right: 30px;
}
.list .row.project .related-to:hover .actions {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/project.less"],"names":[],"mappings":"AAAA;EAIQ,iBAAA;AAFR;AAFA;;EAUQ,2BAAA;AAJR;AANA;;EAcgB,kBAAA;AAJhB;AASI;EACS,mBAAA;AAPb;AAbA;EAwBQ,UAAA;AARR;AAhBA;EA6BQ,mBAAA;EACA,2BAAA;AAVR;AApBA;;EAiCY,2BAAA;EACA,kBAAA;AATZ;AAaQ;EAEQ,cAAA;AAZhB","sourcesContent":[".list  .row.project  {\n\n\n    .label {\n        margin-right: 5px;\n    }\n\n\n    .orgunit, .expert {\n\n        color: var(--insight-color);\n\n        .clicker {\n            .icon {\n                top     : 0px!important;\n            }\n        }\n    }\n\n    &.expanded {\n        td { white-space: normal; }\n    }\n\n    .ant-skeleton {\n        width: 80%;\n    }\n\n    .cost-funding {\n\n        margin-bottom: 10px;\n        color: var(--insight-color);\n\n        .cost, .funding {\n            color: var(--insight-color);\n            margin-right: 30px;\n        }\n    }\n    .related-to {\n        &:hover {\n            .actions {\n                display: block;\n            }\n        }\n    }\n }\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
