import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    select              as d3Select,
    scaleLinear         as d3ScaleLinear,
    max                 as d3Max,
    min                 as d3Min,
    hsl                 as d3Hsl,
    interpolateRgb      as d3InterpolateColor,
} from 'd3';

import d3Cloud              from 'd3-cloud';
import { capitalize }       from 'utils/text';

import { linkPath }         from 'utils/svg';

import './TagCloud/main.less';

const textPadding = 3;
const wordForceSpacing = 0.4;

/**
 * The tagCloud graph Component
 *
 */
class TagCloud extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            fontReduce: 0
        };

        this.graphNode         = React.createRef();
        this.featuredWordsNode = React.createRef();

        _.bindAll(this, 'render', 'onWordsPlaced');

        // Make a uuid
        this.id = _.uniqueId('tagCloud-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.startGraphProcess();
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) { // eslint-disable-line max-lines-per-function
        const {
                content, margin, color,
                fontSizeMin: fontSizeMinBase, fontSizeMax: fontSizeMaxBase,
                normalized, showFeaturedWords, valueMin, valueMax,
                monoColor, sliceAt, width, fontSizeAuto
            }             = nextProps,
            { lastProps } = prevStates,
            fontSizeMin   = fontSizeAuto ? Math.round(width / 35): fontSizeMinBase,
            fontSizeMax   = fontSizeAuto ? Math.round(width / 19) : fontSizeMaxBase,
            propsChange   = JSON.stringify(lastProps) !== JSON.stringify(nextProps),
            fontReduce    = propsChange ? 0 : prevStates.fontReduce,
            unslicedSerie = content || [],
            serie         = sliceAt && _.isArray(unslicedSerie) ? unslicedSerie.slice(0, sliceAt) : unslicedSerie,
            // Ratio of TagCloud when using featuredWords
            reduceRatio   = showFeaturedWords ? 1 / 2 : 1,
            minDimention  = nextProps.width > nextProps.height ? nextProps.height : nextProps.width,
            innerWidth    = nextProps.width - 2 * margin,
            innerHeight   = nextProps.height - 2 * margin,
            cloudWidth    = (!showFeaturedWords ? nextProps.width : minDimention)  - 2 * margin,
            cloudHeight   = (!showFeaturedWords ? nextProps.height : minDimention) - 2 * margin,
            gradient      = TagCloud.getGradient(nextProps),
            endMinValue   = normalized ? d3Min(serie, (d) => d.value) : valueMin,
            endMaxValue   = normalized ? d3Max(serie, (d) => d.value) : valueMax,
            tagCloudData  = _.map(serie, (d) => ({
                text : d.text,
                color: monoColor ? color : gradient(d.value / endMaxValue),
                value: d.value
            })),
            rangeMin = (1 + wordForceSpacing) * (fontSizeMin - fontSizeMin * fontReduce / 80),
            rangeMax = (1 + wordForceSpacing) * (fontSizeMax - fontSizeMax * fontReduce / 80),
            scale         = d3ScaleLinear()
                // Set range with reduced font
                .range([rangeMin, rangeMax])
                .domain([endMinValue, endMaxValue]),
            cloud         = d3Cloud();


        // Skip generation for invalid container sizes
        if(innerWidth > 0 && innerHeight > 0) {
            cloud.words(tagCloudData)
                .size([cloudWidth, cloudHeight])
                .padding(fontSizeMin / 5)
                // .spiral('rectangular')
                .fontSize((d) => scale(d.value))
                .rotate(0)
                .timeInterval(5000);
        }

        const state = {
            fontReduce,
            // Size of the chart (without landmark )
            innerWidth,
            innerHeight,
            cloudWidth,
            cloudHeight,
            reduceRatio,
            // Data plots
            tagCloudData,
            cloud
        };

        state.lastProps         = nextProps;
        state.featuredWords     = TagCloud.getFeaturedWords({ nextProps, state });
        state.featuredWordsLine = TagCloud.getFeaturedWordsLines({ nextProps, state });

        return state;
    }

    /**
    * Get featured words (show out of the tagcloud)
    *    words and positions
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return array
    */
    static getFeaturedWords(options) {
        const { nextProps, state } = options,
            { showFeaturedWords, data } = nextProps,
            serie       = _.get(data, 'content', []),
            {
                innerWidth,
                innerHeight,
                cloudWidth,
                cloudHeight,
            } = state;

        if (showFeaturedWords === false || serie.length === 0) {
            return [];
        }

        return [
            {
                text : serie[0].text,
                right: innerWidth / 2 + cloudWidth / 8,
                top  : cloudHeight / 4,
            },
            {
                text : serie[1] ? serie[1].text : '',
                right: innerWidth / 2 + cloudWidth / 8,
                top  : innerHeight - cloudHeight / 4,
            },
            {
                text: serie[2] ? serie[2].text : '',
                left: innerWidth / 2 + cloudWidth / 3,
                top : innerHeight / 2 - cloudHeight / 16,
            },
        ];
    }

    /**
    * Get labels line
    *
    * @params nextProps  object New props received
    *
    * @return object
    */
    static getFeaturedWordsLines(options) {
        const { state } = options,
            { featuredWords, innerWidth, innerHeight } = state,
            center = { x: innerWidth / 2, y: innerHeight / 2 },
            links  = _.map(featuredWords, (label) => linkPath({
                source: {
                    x: center.x + (label.left ? (label.left - center.x) / 10 : 0),
                    y: center.y + (label.top  ? (label.top - center.y) / 10 : 0),
                },
                target: {
                    x: (label.left ? label.left : innerWidth - label.right),
                    y: label.right ? label.top : label.top,
                }
            }));

        return links;
    }

    /**
    * Get d3 gradient
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return d3 interpolateRgb
    */
    static getGradient(nextProps) {
        const {
                color,
                gradientColor,
            } = nextProps,
            dc     = d3Hsl(color),
            colors = [
                gradientColor === false ? dc.brighter(1).toString() : gradientColor,
                dc.toString(),
            ];

        return d3InterpolateColor(colors[0], colors[1]);
    }

    /**
    * Triggered when d3 clound finish to place words
    *
    * @return void
    */
    onWordsPlaced(words) {
        const {
            innerWidth,
            innerHeight,
            reduceRatio,
            tagCloudData,
            fontReduce,
        } = this.state;

        if (words.length < tagCloudData.length && fontReduce < 80) {
            this.setState({ fontReduce: fontReduce + 2 });
            return;
        }

        d3Select(this.graphNode.current) // Need dom element when tagcloud starting
            .attr(
                'transform',
                `translate(${innerWidth / 2},${innerHeight / 2})scale(${reduceRatio},${reduceRatio})`
            )
            .selectAll('text')
            .data(words)
            .enter()
            .append('text')
            .style('font-size', (d) => `${d.size / (1 + wordForceSpacing / 2)}px`)
            .style('font-weight', 'bold')
            .style('fill', (d) => d.color)
            .attr('text-anchor', 'middle')
            .attr('transform', (d) => `translate(${[d.x, d.y]})rotate(${d.rotate})`)
            .text((d) => d.text);
    }

    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidUpdate() {
        this.startGraphProcess();
    }

    /**
    * Launch graph process
    *  to drawn graph
    *
    */
    startGraphProcess() {
        const { cloud } = this.state;
        // Clear old words
        d3Select(this.graphNode.current)
            .selectAll('*')
            .remove();

        // Set cloud options
        this.setCloudOptions();

        // Run words placement
        cloud.start();
        this.moveLabels();
    }

    /**
    * Set cloud options
    *
    * @return html
    */
    setCloudOptions() {
        const { cloud } = this.state;

        // Define the tag cloud options
        cloud.on('end', this.onWordsPlaced);
    }

    /**
    * Move labels
    *
    * @return void
    */
    moveLabels() {
        const {
                innerWidth,
                innerHeight
            } = this.state,
            nodes = _.filter(
                !_.isNull(this.featuredWordsNode.current) ? this.featuredWordsNode.current.children : [],
                (node) => node.className === 'label'
            );

        _.each(
            nodes,
            (node) => {
                const { style } = node,
                    cStyle      = window.getComputedStyle(node),
                    {
                        top   : labelTopPx,
                        height: labelHeightPx,
                        left  : labelLeftPx,
                        width : labelWidthPx
                    } = cStyle,
                    labelTop    = parseFloat(labelTopPx),
                    labelLeft   = parseFloat(labelLeftPx),
                    labelHeight = parseFloat(labelHeightPx),
                    labelWidth  = parseFloat(labelWidthPx),
                    halfHeight  = labelHeight / 2,
                    isUp        = labelTop - halfHeight < 0,
                    isDown      = labelTop  + halfHeight > innerHeight,
                    isRight     = labelLeft + labelWidth > innerWidth;

                if (isUp)    { style.top = `${labelTop + halfHeight}px`; }
                if (isDown)  { style.top = `${labelTop - halfHeight}px`; }
                if (isRight) { style.left = `${innerWidth - labelWidth}px`; }
            }
        );
    }

    /**
    * Render featured word
    *   (words put in labels around the tag cloud)
    *
    * @return html
    */
    renderFeaturedWords() {
        const { showFeaturedWords, color, featuredFontSize } = this.props,
            { featuredWords }                                = this.state;

        if (showFeaturedWords === false) {
            return '';
        }

        return (
            <div className="labels" ref={this.featuredWordsNode}>
                {featuredWords.map((d) => (
                    <div
                        key={d.text}
                        className="label"
                        style={{
                            left      : d.left,
                            right     : d.right,
                            top       : d.top,
                            lineHeight: `${(featuredFontSize) * 1.4}px`,
                            textAlign : d.right ? 'right' : 'left'
                        }}
                    >
                        <span
                            style={{
                                fontSize: featuredFontSize,
                                padding : `${textPadding}px`,
                                color,
                            }}
                        >
                            {capitalize(d.text.toLowerCase())}
                        </span>
                    </div>
                ))}
            </div>
        );
    }

    /**
    * Render labels lines
    *
    * @return html
    */
    renderFeaturedWordsLines() {
        const { featuredWordsLine } = this.state,
            { color }               = this.props;

        return _.map(featuredWordsLine, (d) => (
            <path
                key={d}
                d={d}
                style={{
                    stroke     : color,
                    fill       : 'none',
                    strokeWidth: 0.5,
                }}
            />
        ));
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                data,
                width,
                height,
                margin,
                skeleton,
                skeletonGradient
            } = this.props,
            loading = skeleton || !data,
            style = loading ? {
                backgroundImage: skeletonGradient,
            } : null;

        return loading
            ? (<span className="TagCloud skeleton" style={style} />)
            : (
                <div className="TagCloud">
                    <svg
                        width={width}
                        height={height}
                    >
                        <g transform={`translate(${margin} ${margin})`}>
                            <g ref={this.graphNode} />
                        </g>
                        {this.renderFeaturedWordsLines()}
                    </svg>
                    {this.renderFeaturedWords()}
                </div>
            );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
TagCloud.propTypes = {
    color            : PropTypes.string,
    content          : PropTypes.arrayOf(PropTypes.shape()),
    data             : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    featuredFontSize : PropTypes.number,
    fontSizeMax      : PropTypes.number,
    fontSizeMin      : PropTypes.number,
    gradientColor    : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height           : PropTypes.number.isRequired,
    fontSizeAuto     : PropTypes.bool,
    margin           : PropTypes.number,
    monoColor        : PropTypes.bool,
    normalized       : PropTypes.bool,
    showFeaturedWords: PropTypes.bool,
    skeleton         : PropTypes.bool,
    skeletonGradient : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    sliceAt          : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    valueMax         : PropTypes.number,
    valueMin         : PropTypes.number,
    width            : PropTypes.number.isRequired
};

/**
 * Default props value
 */
TagCloud.defaultProps = {
    sliceAt          : false,
    margin           : 0,
    fontSizeMin      : 12,
    fontSizeMax      : 20,
    valueMin         : 0,
    valueMax         : 100,
    color            : 'var(--insight-color)',
    featuredFontSize : 14,
    gradientColor    : false,
    normalized       : false,
    showFeaturedWords: false,
    monoColor        : false,
    skeleton         : false,
    skeletonGradient : false,
    fontSizeAuto     : false,
};

export default TagCloud;

