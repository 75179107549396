/**
 * Keep all actions types listed there
 *
 */

// Triggered when the user view is set
const USER_VIEW_SET                      = 'USER_VIEW_SET';
const USER_VIEW_STORE_OUTER_MODELS       = 'USER_VIEW_STORE_OUTER_MODELS';
const USER_VIEW_UPDATE_MODELS            = 'USER_VIEW_UPDATE_MODELS';
const USER_VIEW_UPDATE_SLICE_STATS       = 'USER_VIEW_UPDATE_SLICE_STATS';
const USER_VIEW_UPDATE_MODEL             = 'USER_VIEW_UPDATE_MODEL';
const USER_VIEW_UPDATE_FROM_DIFF         = 'USER_VIEW_UPDATE_FROM_DIFF';
const USER_VIEW_ADD_NEW_ITEMS            = 'USER_VIEW_ADD_NEW_ITEMS';
const USER_VIEW_REMOVE_ITEMS             = 'USER_VIEW_REMOVE_ITEMS';
const USER_VIEW_UPDATE_ITEMS             = 'USER_VIEW_UPDATE_ITEMS';
const USER_VIEW_REPLACE_ITEMS            = 'USER_VIEW_REPLACE_ITEMS';


export default {
    USER_VIEW_SET,
    USER_VIEW_STORE_OUTER_MODELS,
    USER_VIEW_UPDATE_MODELS,
    USER_VIEW_UPDATE_SLICE_STATS,
    USER_VIEW_UPDATE_MODEL,
    USER_VIEW_UPDATE_FROM_DIFF,
    USER_VIEW_ADD_NEW_ITEMS,
    USER_VIEW_REMOVE_ITEMS,
    USER_VIEW_UPDATE_ITEMS,
    USER_VIEW_REPLACE_ITEMS,
};
