// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Venn graph
*
*/
.Venn {
  position: relative;
}
.Venn .background {
  position: absolute;
  overflow: hidden;
  user-select: none;
  background: #fff;
}
.Venn > svg {
  position: absolute;
}
.Venn .label-container {
  position: absolute;
  text-align: center;
}
.Venn .label-container .area-label {
  font-weight: bold;
}
.Venn .label-container .label,
.Venn .label-container .area-label {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 2px;
}
.Venn .label-container .label > span,
.Venn .label-container .area-label > span {
  position: relative;
  color: inherit;
}
.Venn .label-container .label > span .icon,
.Venn .label-container .area-label > span .icon {
  position: absolute;
  left: -17px;
  top: 0px;
}
.Venn.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Venn/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EACI,kBAAA;AAAJ;AADA;EAGQ,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACR;AACI;EACI,kBAAA;AACR;AAVA;EAcQ,kBAAA;EACA,kBAAA;AADR;AAdA;EAkBY,iBAAA;AADZ;AAjBA;;EAqBY,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAZ;AAzBA;;EA4BgB,kBAAA;EACA,cAAA;AAChB;AA9BA;;EA+BoB,kBAAA;EACA,WAAA;EACA,QAAA;AAGpB;AAGK;EACG,mDAAA;AADR","sourcesContent":["/**\n* Venn graph\n*\n*/\n\n.Venn {\n    position: relative;\n    .background {\n        position: absolute;\n        overflow: hidden;\n        user-select:none;\n        background: #fff;\n    }\n    & > svg {\n        position: absolute;\n\n    }\n\n    .label-container{\n        position: absolute;\n        text-align: center;\n\n        .area-label{\n            font-weight: bold;\n        }\n        .label, .area-label {\n            display: inline-block;\n            overflow: hidden;\n            white-space: nowrap;\n            text-overflow: ellipsis;\n            border-radius: 2px;\n\n            > span {\n                position: relative;\n                color: inherit;\n                .icon {\n                    position: absolute;\n                    left: -17px;\n                    top: 0px;\n                }\n            }\n        }\n    }\n\n     &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
