import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';
import _                              from 'lodash';
import { Skeleton }                   from 'antd';
import { connect }                    from 'react-redux';
import { learn }                      from 'store/actions/knowledge';

import { Row, Col, Highlight }        from 'helpers';

import DocumentHeader                 from './DocumentHeader';

import PatentInfobox                  from './Patent/PatentInfobox';
import Images                         from './Patent/Images';
import TechnicalDomains               from './Patent/TechnicalDomains';

import './Patent/assets/patent.less';

/**
* Display a Patent
*
*/
class Patent extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'toggleClaimsDisplay');

        this.state = {
            showAllClaims: false,
            elements     : [],
        };
    }

    /**
    * On component mount
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['entities', 'elements'])
            .then(this.setState.bind(this));
    }

    /**
    * Get joined labels of a knowledge from ids
    *
    * @return string
    */
    getLabelsOfKnowledge(knowledgeName, ids, separator = ', ') {
        const { [knowledgeName]: values } = this.state;

        return values ? values
            .filter((value) => (ids ? ids.indexOf(value.id) !== -1 : false))
            .map((value) => value.label)
            .join(separator)
            : '';
    }

    /**
    * Toggle the claims display
    */
    toggleClaimsDisplay() {
        const { showAllClaims } = this.state;

        this.setState({ showAllClaims: !showAllClaims });
    }

    /**
    * Render the Concepts panel.
    *
    * @return JSX
    */
    renderConcepts() {
        const { entity } = this.props;

        if (!entity) {
            return (
                <Row className="concepts textual">
                    <Skeleton active />
                </Row>
            );
        }

        const hasConcept = entity !== null && entity.CONCEPTS
            && _.isArray(entity.CONCEPTS) && entity.CONCEPTS.length > 0;

        if (!hasConcept) {
            return null;
        }

        const concepts = entity.CONCEPTS,
            conceptsText = _.map(concepts, (concept, index) => {
                const isLast = index === concepts.length - 1;

                return (
                    <span>
                        {concept}
                        {!isLast && (
                            <span>|</span>
                        )}
                    </span>
                );
            });

        return (
            <Row className="concepts textual" label="Concepts">
                <p>{conceptsText}</p>
            </Row>
        );
    }

    /**
    * Render the Classifications panel.
    *
    * @return JSX
    */
    renderClassifications() {
        const { entity }       = this.props,
            hasClassifications = entity !== null && entity.CLASSIF
                && _.isArray(entity.CLASSIF) && entity.CLASSIF.length;

        if (!entity) {
            return (
                <Row className="rel">
                    <Skeleton active paragraph={{ rows: 3, width: '100%' }} />
                </Row>
            );
        }

        if (!hasClassifications) {
            return null;
        }

        return (
            <Row className="classifications textual rel" label="Classifications">
                <table>
                    {_.map(entity.CLASSIF, (classif) => (
                        <tr>
                            <td>{classif.code}</td>
                            <td>{classif.text}</td>
                        </tr>
                    ))}
                </table>
            </Row>
        );
    }

    /**
    * Render the claims panel.
    *
    * @return JSX
    */
    renderClaims() {
        const { entity } = this.props,
            { showAllClaims } = this.state,
            hasClaims    = entity !== null && entity.ICLM;

        if (!entity) {
            return <Skeleton active paragraph={{ rows: 20 }} />;
        }

        if (!hasClaims) {
            return null;
        }

        const claims = entity[showAllClaims ? 'ECLM' : 'ICLM'];

        return (
            <Row className="claims textual" label="Claims">
                <Highlight>{claims}</Highlight>
                <div className="eclm">
                    <span onClick={this.toggleClaimsDisplay}>
                        {showAllClaims ? 'Hide dependant claims' : 'View dependant claims'}
                    </span>
                </div>
            </Row>
        );
    }

    /**
    * Render the abstract panel.
    *
    * @return JSX
    */
    renderAbstract() {
        const { entity } = this.props;

        if (!entity) {
            return (
                <>
                    <Skeleton active paragraph={{ rows: 10 }} />
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                </>
            );
        }

        const hasObj     = entity.OBJ || false,
            hasDrwBck    = entity.ADB || false;

        const abstractJSXs = [
            <Row className=" abstract rel" label="Abstract"
                key="abstract-text"
            >
                <Highlight>{entity.AB}</Highlight>
            </Row>
        ];

        if (hasObj) {
            abstractJSXs.push(
                <Row className="object-of-invention rel" label="Object of invention"
                    open={false} key="abstract-obj"
                >
                    <Highlight>{entity.OBJ}</Highlight>
                </Row>
            );
        }

        if (hasDrwBck) {
            abstractJSXs.push(
                <Row className="advantages rel" label="Advantages / Previous drawbacks"
                    open={false} key="abstract-adb"
                >
                    <Highlight>{entity.ADB}</Highlight>
                </Row>
            );
        }

        return abstractJSXs;
    }

    /**
    * Render the Description panel.
    *
    * @return JSX
    */
    renderDescription() {
        const { entity } = this.props;

        if (!entity) {
            return (
                <Row className="description textual">
                    <Skeleton active />
                </Row>
            );
        }

        const hasDesc = entity.DESC && _.isString(entity.DESC) && entity.DESC !== '';

        if (!hasDesc) {
            return null;
        }

        return (
            <Row className="description textual" label="Description"
                open={false} key="description"
            >
                <Highlight>{entity.DESC}</Highlight>
            </Row>
        );
    }

    /**
    * Produce the proper display of the document content
    *
    * @return JSX
    */
    render() { // eslint-disable-line  max-lines-per-function
        const {
                entity, entities,
                onClick, registerCallbacks, bookmarks,
            }            = this.props,
            { elements } = this.state;

        return (
            <Col className="document patent sheet rel">
                <DocumentHeader entity={entity} entities={entities}
                    bookmarks={bookmarks}
                />
                <Row className="document-content">
                    <Col ratio="2/10" className="sheet-col">
                        <PatentInfobox
                            entity={entity}
                            key="patent-infobox"
                            onClick={onClick}
                            registerCallbacks={registerCallbacks}
                            elements={elements}
                            className="entity-information"
                        />
                    </Col>
                    <Col ratio="3/10" className="sheet-col">
                        {this.renderAbstract()}
                        {this.renderClaims()}
                    </Col>
                    <Col ratio="5/10" className="sheet-col">
                        <Images entity={entity} />
                        <TechnicalDomains entity={entity} />
                        {this.renderClassifications()}
                        {this.renderConcepts()}
                        {this.renderDescription()}
                    </Col>
                </Row>
            </Col>
        );
    }

}

Patent.propTypes = {
    entity           : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    entities         : PropTypes.any,
    learnKnowledge   : PropTypes.func.isRequired,
    onClick          : PropTypes.any,
    registerCallbacks: PropTypes.any,
    bookmarks        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

Patent.defaultProps = {
    entity: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Patent);

