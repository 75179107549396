/**
 * Display content for shortcut clipboard, download and report.
 *
 * @returns Component
 */

import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Icon, Btt2Popin }  from 'helpers';
import _                    from 'lodash';

import './Layout/assets/flexlayout.less';
/**
*
* @returns JSX
*/
const FlexLayout = props => {  // eslint-disable-line max-lines-per-function
    const {open}            = props,
        [isOpen, setIsOpen] = useState(open);

    /**
     *
     * @param {string|int} ratio
     * @returns {string|false}
     */
    const getPercent = (ratio) => {
        const isValidPercentage = /^\d+(\.\d+)?%$/.test(ratio);

        if (isValidPercentage || !ratio) {
            return ratio;
        }

        const [numerator, denominator] = ratio?.split('/');
        return denominator > 0
            ? `${((numerator / denominator) * 100).toFixed(2)}%`
            : false;
    };


    /**
     *
     * @returns
     */
    const renderWithHeader = () => {
        const { label, children } = props,
            isExpendable = isOpen === true || isOpen === false,
            classNames   = `with-header
                ${isExpendable ? ' expandable' : ''}
                ${isOpen === false ? 'inactive' : 'active'}`;

        return label && (
            <div className={classNames}>
                <div className="header" onClick={() => isExpendable && setIsOpen(!isOpen)}>
                    <div className="label">{label}</div>
                    {
                        isExpendable && (
                            <div className="expand">
                                <Icon
                                    width={10}
                                    type="retract-arrow"
                                />
                            </div>
                        )
                    }
                </div>
                <div className="flex-content">
                    {renderInformation()}
                    {children}
                </div>
            </div>
        );
    };

    /**
     *
     * @returns
     */
    const renderWithoutHeader = () => {
        const { children } = props;

        return (
            <>
                {renderInformation()}
                {children}
            </>
        );
    };


    /**
     *
     */
    const renderInformation = () => {
        const { information } = props;
        if (information) {
            const button = (
                <Icon
                    id="info"
                    height={16}
                    width={16}
                    title={information.title}
                />
            );

            return (
                <Btt2Popin
                    key="information"
                    className="information"
                    button={button}
                    content={information.content}
                    title={information.title}
                    width={information.width}
                />
            );
        }
    };


    /**
     *
     * @returns
     */
    const getStyle = () => {
        const {
            label, width, height,
            mode, ratio, configuration
        } = props;

        // Set properties undefined to avoid inject in the DOM
        if(mode === 'col' || label) {
            return {
                width : getPercent(ratio) || width || undefined,
                height: height || undefined
            };
        }

        if(mode === 'row') {
            return {
                width : width || undefined,
                height: getPercent(ratio) || height || undefined,
            };
        }

        if(mode === 'grid' || mode === 'cell') {
            return _.pick(configuration, ['gridRow', 'gridColumn', 'gridTemplateRows', 'gridTemplateColumns']);
        }
    };

    /**
    *
    *
    * @returns JSX
    */
    const render = () => {
        const {
                TagName, id, forwardedRef, dataid, dataQaKey,
                className, mode, label,
                onMouseEnterCb, onMouseLeaveCb, onClickCb
            }              = props,
            defaultClasses = `ovation-flexlayout ${mode} ${className || ''}`,
            style          = getStyle();

        return (
            <TagName
                id={id || undefined}
                ref={forwardedRef}
                dataid={dataid}
                data-qa-key={dataQaKey}
                className={defaultClasses}
                style={style}
                onClick={onClickCb ? ((e) => onClickCb(e, props)) : undefined}
                onMouseEnter={onMouseEnterCb}
                onMouseLeave={onMouseLeaveCb}
            >
                {
                    label
                        ? renderWithHeader()
                        : renderWithoutHeader()
                }
            </TagName>
        );
    };

    // The render call
    return render();
};

FlexLayout.propTypes = {
    forwardedRef  : PropTypes.any,
    className     : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dataid        : PropTypes.string,
    dataQaKey     : PropTypes.string,
    open          : PropTypes.bool,
    label         : PropTypes.any,
    mode          : PropTypes.string,
    height        : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    width         : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ratio         : PropTypes.any,
    configuration : PropTypes.object,
    onClickCb     : PropTypes.func,
    onMouseEnterCb: PropTypes.func,
    onMouseLeaveCb: PropTypes.func,
    children      : PropTypes.node,
    TagName       : PropTypes.oneOf(['div', 'header', 'footer', 'section', 'nav']),
};

// TODO Clean props
const defaultProps = {
    id           : false,
    open         : null,
    className    : false,
    height       : false,
    width        : false,
    onClickCb    : null,
    dataid       : null,
    configuration: {},
    TagName      : 'div',
};

/**
 *
 * @param {*} props
 * @returns
 */
export const Row = (props) => (
    <FlexLayout  mode="row" {...props} />
);
Row.defaultProps = ({ ...defaultProps, mode: 'row' });

/**
 *
 * @param {*} props
 * @returns
 */
export const Col = (props) => (
    <FlexLayout  mode="col" {...props} />
);
Col.defaultProps = ({ ...defaultProps, mode: 'col' });


/**
 *
 * @param {*} props
 * @returns
 */
export const Grid = (props) => (
    <FlexLayout  mode="grid" {...props} />
);
Grid.defaultProps = ({ ...defaultProps, mode: 'grid' });

/**
 *
 * @param {*} props
 * @returns
 */
export const Cell = (props) => (
    <FlexLayout  mode="cell" {...props} />
);
Cell.defaultProps = ({ ...defaultProps, mode: 'cell' });


export default (Row, Col, Grid, Cell);
