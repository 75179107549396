import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import _                    from 'lodash';
import { Skeleton }         from 'antd';
import {
    Icon, Flag, SpanOverflow,
    Row, Col,
}                         from 'helpers';
import { onParentResize } from 'utils/dom';
import {
    htmlize, addBr, isUrl,
    makeStrClassName,
} from 'utils/text';

import './assets/orgunit.less';

const localizationFields = [
        {
            id   : 'website',
            icon : 'web',
            isUrl: true
        },
        {
            id   : 'twitter',
            icon : 'twitter',
            isUrl: true
        },
        {
            id   : 'linkedin',
            icon : 'linkedin',
            isUrl: true
        }
    ],
    addressMap    = [
        {
            id      : 'address',
            property: 'addrLine'
        },
        {
            id      : 'city',
            property: 'city'
        },
        {
            id      : 'postal-code',
            property: 'postCode'
        },
        {
            id      : 'country',
            property: 'countryName'
        }
    ];

/**
 * Render IdCard entities
 *
 */
class OrgunitInformation extends Component {

    /**
    * Class constructor
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClickToggleDescription');

        this.refBottomOverview = React.createRef();

        this.state = {
            loading            : true,
            moreDescription    : false,
            widthBottomOverview: 0,
        };
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { delay } = this.props;

        this.setState({ moreDescription: _.isFunction(window.isCapture) && window.isCapture() });

        if (delay === 0) {
            this.parentResize();
            return;
        }

        setTimeout(() => {
            this.parentResize();
        }, delay);
    }

    /**
    * Parent Resize
    */
    parentResize() {
        onParentResize(this.refBottomOverview, this.fetchBottomOverviewWidth.bind(this));
        this.setState({ loading: false });
    }

    /**
    * Fetch bottom overview width and store it in state
    */
    fetchBottomOverviewWidth({ width }) {
        if (width > 0) {
            this.setState({ widthBottomOverview: width });
        }
    }


    /**
    * Triggered when view more or less description is clicked
    */
    onClickToggleDescription() {
        const { moreDescription } = this.state;

        this.setState({ moreDescription: !moreDescription });
    }


    /**
     * Render number of employees
     *
     * @returns JSX
     */
    renderNbEmployees() {
        const { entity }     = this.props,
            { descriptions } = entity,
            {
                nbEmployeeMin,
                nbEmployeeMax,
            }                = descriptions || {},
            min              = nbEmployeeMin?.description,
            max              = nbEmployeeMax?.description,
            nbEmployee       = [min, max].filter(value => !_.isUndefined(value)),
            employeesTxt     = nbEmployee.length === 1
                ? (min
                    ? `> ${min} employees`
                    : `< ${max} employees`
                )
                : `${nbEmployee.join(' - ')} employees`;


        return nbEmployee.length > 0 && (
            <div className="nb-employees line">
                <Icon folder="/id-cards/" type="team"
                    height={20}
                />
                <div className="content-container">
                    <span>
                        {employeesTxt}
                    </span>
                </div>
            </div>
        );
    }

    /**
     * Render the revenue range
     *
     * @returns JSX
     */
    renderRevenueRange() {
        const { entity }      = this.props,
            { revenue_range } = entity;

        return revenue_range && (
            <div key="revenueRange" className="revenue-range line">
                <Icon folder="/id-cards/" type="revenue-range"
                    height={20} style={{ marginLeft: '-4px' }}
                />
                <div className="content-container">
                    <span>
                        {revenue_range}
                    </span>
                </div>
            </div>
        );
    }



    /**
    * Render localization
    *
    * @return JSX
    */
    renderAddresses() {
        const { entity, light } = this.props,
            { showAddresses }   = this.props;

        if (light && !showAddresses) {
            return null;
        }

        return (
            <Col key="contentAddresses" className="content addresses">
                {_.map(localizationFields, (field) => {
                    const fieldContent  = _.get(entity, `eAddresses.${field.id}`, false),
                        contentToRender = !isUrl(fieldContent) ? fieldContent : (
                            <a
                                key={`link ${field.id}`}
                                href={fieldContent}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={fieldContent}
                            >
                                {fieldContent}
                            </a>
                        );

                    if (!fieldContent) {
                        return false;
                    }

                    return (
                        <div className={`line ${field.id}`} key={field.id}>
                            <div className="icon-container">
                                <Icon folder="/id-cards/" id={field.icon}
                                    width={12} height={12}
                                />
                            </div>
                            <div className="content-container">
                                {contentToRender}
                            </div>
                        </div>
                    );
                })}
                {this.renderAddressesLines()}
            </Col>
        );
    }

    /**
    * Render localization address
    *
    * @return JSX
    */
    renderAddressesLines() {
        const { entity } = this.props;

        if (!entity || !entity.pAddress) {
            return null;
        }

        const { pAddress } = entity,
            filledProperties = addressMap.filter(map => pAddress[map.property]),
            addressString = filledProperties.map(map => pAddress[map.property]).join(', ');

        return (
            <div className="address line">
                <div className="icon-container">
                    <Icon folder="/id-cards/" id="marker"
                        width={12} height={12}
                    />
                </div>
                <div className="content-container" title={addressString}>
                    {pAddress.iso_code && (
                        <Flag
                            iso={pAddress.iso_code}
                            width={21}
                            height={12}
                            className="flag"
                            borderColor="#dddddd"
                            borderSize={1}
                        />
                    )}
                    {filledProperties.map((map, index) => (
                        <span key={index} className={map.key}>
                            {pAddress[map.property]}
                            {index + 1 < filledProperties.length  && ', '}
                        </span>
                    ))}
                </div>
            </div>
        );
    }

    /**
    * Render the founded date
    *
    * @returns JSX
    */
    renderFoundedDate() {
        const { entity: { funded_date } } = this.props; // !WARNING! Data-Api should return found_date

        return funded_date && (
            <div className="founded-date line">
                <Icon folder="/id-cards/" type="founded-date"
                    height={14}
                />
                <div className="content-container">
                    Founded in {funded_date}
                </div>
            </div>
        );
    }

    /**
    * Render the content as skeletons
    *
    * @return JSX
    */
    renderSkeleton() {
        return (
            <Row>
                <Col className="idcard-left">
                    <Skeleton paragraph={{ rows: 13, width: '100%' }} title={{ width: '100%' }}
                        avatar active
                    />
                </Col>
            </Row>
        );
    }


    /**
    * Render the bottom overview lists.
    *
    * @param {string} field
    * @param {string} title
    *
    * @return {JSX}
    */
    renderBottomOverviewList(field, title) {
        const { entity }            = this.props,
            { widthBottomOverview } = this.state,
            data                    = entity[field] || [];

        return data?.length > 0 && (
            <Row className="bottom-overview-entity rel">
                <h2>{_.capitalize(title)}</h2>
                <SpanOverflow
                    className="bottom-overview-list"
                    moreContainerClassName="more"
                    containerNodeSelector=".bottom-overview-entity"
                    propsToWatch={{ data, width: widthBottomOverview }}
                >
                    {data.map(datum => (
                        <span key={datum} className="category"
                            title={datum}
                        >
                            {datum}
                        </span>
                    ))}
                </SpanOverflow>
            </Row>
        );
    }

    /**
     * Render the organization type
     *
     * @return JSX
     */
    renderOrgClass() {
        const { entity, orgClasses } = this.props,
            {org_class }             = entity,
            orgClassMdl              = orgClasses && orgClasses.find((orgClass) => orgClass.id === org_class);

        if(!orgClassMdl) {
            return false;
        }

        return (
            <div className="type">
                <h2>Type</h2>
                <div>{orgClassMdl.label}</div>
            </div>
        );
    }

    /**
    * Get organization description
    *
    * @returns {string}
    */
    getDescription() {
        const { entity }     = this.props,
            { descriptions } = entity || {},
            {
                presentation,
                'short description': shortDescription,
            }                                        = descriptions || {},
            { description: descriptionPresentation } = presentation || {},
            { description: descriptionShort }        = shortDescription || {},
            description                              = descriptionPresentation || descriptionShort || '';

        return description;
    }

    /**
    * Render the description part
    *
    * @return JSX
    */
    renderDescription() {
        const { light }         = this.props,
            { moreDescription } = this.state,
            currentDescription  = this.refBottomOverview.current,
            currentChild        = currentDescription?.querySelector('.description'),
            currentChildHeight  = _.round(currentChild?.clientHeight / 10) * 10,
            maxHeight           = !light ? currentChildHeight : 'max-content',
            description         = this.getDescription(),
            hasDescription      = !_.isEmpty(description),
            fullDescriptionHtml = hasDescription && htmlize(addBr(description), { maxHeight }, 'div'),
            descriptionHtml     = hasDescription && htmlize(addBr(
                description.length > 360 ? `${description.slice(0, 165)}...` : description
            ), {}, 'div');

        return hasDescription && (
            <div className="description">
                <h2>Presentation</h2>
                {!moreDescription && light ? descriptionHtml : fullDescriptionHtml}
                {light && description.length > 360 && (
                    <span className="read-more" onClick={this.onClickToggleDescription}>
                        {!moreDescription ? 'View more' : 'View less'}
                        <Icon type={moreDescription ? 'up' : 'down'} color="#90A4AE" />
                    </span>
                )}
            </div>
        );
    }

    /**
    * Render the top informations of Overview
    * @return JSX
    */
    renderTopOverview() {
        const addresses  = this.renderAddresses(),
            revenueRange = this.renderRevenueRange(),
            nbEmployees  = this.renderNbEmployees(),
            foundedDate  = this.renderFoundedDate();

        if(!addresses && !revenueRange && !nbEmployees && !foundedDate) {
            return null;
        }

        return (
            <div className="top-overview">
                {addresses}
                {revenueRange}
                {nbEmployees}
                {foundedDate}
            </div>
        );
    }

    /**
    * Render the bottom informations of Overview
    * @return JSX
    */
    renderBottomOverview() {
        const { loading }    = this.state,
            organizationType = this.renderOrgClass(),
            industries       = this.renderBottomOverviewList('crunchbase_categories', 'industries'),
            mainConcept      = this.renderBottomOverviewList('topics', 'mainly works on'),
            description      = this.renderDescription(),
            noContent        = !organizationType && !industries && !mainConcept && !description,
            classNames       = ['bottom-overview', { center: loading }];

        if (noContent) {
            return false;
        }

        return (
            <div className={makeStrClassName(classNames)} ref={this.refBottomOverview}>
                {loading ? (
                    <Skeleton active className="skeleton-right"
                        paragraph={{ rows: 4, width: ['100%', '75%', '65%', '65%'] }}
                    />
                ) : (
                    <>
                        {organizationType}
                        {industries}
                        {mainConcept}
                        {description}
                    </>
                )}
            </div>
        );
    }

    /**
    * Render informations about the entity
    *
    * @return JSX
    */
    render() {
        return (
            <>
                {this.renderTopOverview()}
                {this.renderBottomOverview()}
            </>
        );
    }

}

OrgunitInformation.propTypes = {
    light        : PropTypes.bool,
    delay        : PropTypes.number, // Millisecond
    orgClasses   : ImmutablePropTypes.list,
    showAddresses: PropTypes.bool,
    entity       : PropTypes.shape({
        org_class: PropTypes.number,
        pAddress : PropTypes.shape({
            addrLine   : PropTypes.string,
            city       : PropTypes.string,
            countryName: PropTypes.string,
            iso_code   : PropTypes.string,
            postCode   : PropTypes.string
        }),
        descriptions : PropTypes.object,
        topics       : PropTypes.array,
        revenue_range: PropTypes.string,
        funded_date  : PropTypes.number,
    }),
};

OrgunitInformation.defaultProps = {
    light        : true,
    delay        : 0,
    entity       : null,
    showAddresses: false,
};

export default OrgunitInformation;
