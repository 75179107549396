import React, { PureComponent }     from 'react';
import PropTypes                    from 'prop-types';
import { Skeleton }                 from 'antd';
import _                            from 'lodash';
import { connect }                  from 'react-redux';
import { learn }                    from 'store/actions/knowledge';

import { Collection, Highlight }    from 'helpers';

/**
 * Display a list of projects
 *
 */
class Publication extends PureComponent {


    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['publicationTypes'])
            .then(this.setState.bind(this));
    }


    /**
    * Get the project source label
    *
    * @return string
    */
    getPublicationTypeLabel(publicationTypeId) {
        const { publicationTypes } = this.state;

        if (!publicationTypes) {
            return false;
        }

        const publicationTypeMdl = publicationTypes.find((type) => type.id === publicationTypeId);

        if (!publicationTypeMdl) {
            return false;
        }

        return publicationTypeMdl.label;
    }

    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity } = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                entity, showExpandedContent, renderActiveStatus,
                renderModelAction, onClick, renderOpenableIcon,
            }             = this.props,
            { clickable, label, subtype, date_publication
            }             = entity,
            classNames    = ['label'];

        if (showExpandedContent) {
            return false;
        }

        if (clickable) { classNames.push('clickable'); }

        return (
            <>
                <td className={classNames.join(' ')}
                    onClick={clickable ? onClick : null}
                >
                    <div className="left">
                        <Highlight
                            TagName="div"
                            className="ellipsis"
                            title
                        >
                            {label}
                        </Highlight>
                        {clickable && renderOpenableIcon()}
                    </div>
                    {renderActiveStatus()}
                    {clickable && renderModelAction()}
                </td>
                <td className="ellipsis type">{this.getPublicationTypeLabel(subtype)}</td>
                <td className="publication-year">{date_publication ? date_publication.split('-')[0] : ''}</td>
            </>
        );
    }

    /**
    * Render loading project row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the expandable content
    *
    * @return JSX
    */
    renderExpandedContent() {
        const {
                entity, onClick,
                showExpandedContent, allowExport,
            }                 = this.props,
            abstractIsTooLong = entity.abstract_description && entity.abstract_description.length > 580,
            abstractToRender  = abstractIsTooLong ? `${entity.abstract_description.substr(0, 580)}...`
                : entity.abstract_description,
            authorities       = _.isArray(entity.authority) && entity.authority.slice(0, 10);

        if (!showExpandedContent) {
            return false;
        }

        return (
            <>
                { showExpandedContent ? <td /> : null }
                <td colSpan={allowExport ? 2 : 1} className="abstract">
                    {entity.abstract_description && (
                        <div>
                            <div className="label">Abstract</div>
                            <Highlight className="content" TagName="div">
                                {abstractToRender}
                            </Highlight>
                        </div>
                    )}
                </td>
                <td colSpan={2}>
                    <div className="related-to">
                        {authorities && (
                            <div>
                                <div className="label">Authors</div>
                                <Collection
                                    entitySource={entity}
                                    entities={authorities}
                                    type="expert"
                                    mode="inline"
                                    allowExport={false}
                                    onClick={onClick}
                                />
                            </div>
                        )}
                    </div>
                </td>
            </>
        );
    }

    /**
    * Render the project row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render project row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Publication.propTypes = {
    allowExport        : PropTypes.any,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    learnKnowledge     : PropTypes.func,
    entity             : PropTypes.shape({
        abstract_description: PropTypes.string,
        authority           : PropTypes.array,
        date_publication    : PropTypes.string,
        isLoading           : PropTypes.bool,
        clickable           : PropTypes.bool,
        label               : PropTypes.string,
        subtype             : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
};

Publication.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    publicationTypes   : false,
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Publication);
