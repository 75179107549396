import React, { Component }       from 'react';
import _                          from 'lodash';
import PropTypes                  from 'prop-types';
import { Popover }                from 'antd';
import {
    scaleLinear as d3ScaleLinear,
    scaleBand   as d3ScaleBand,
    max         as d3Max,
    hsl         as d3Hsl,
}                                 from 'd3';
import {
    getElementSize,
}                                 from 'utils/dom';

import {
    formatNumberWithMagnitude,
    pluralize,
    formatInternationalNumber,
}                                 from 'utils/text';
import { roundUpByMagnitude }     from 'utils/number';
import Landmark                   from '../Landmark';
import Animation                  from '../Animation';
import TrendInformation           from '../TrendInformation';
import Flag                       from '../../Flag';
import Icon                       from '../../Icon';
import NoData                     from '../../NoData';
import wClinical                  from './Bar/watermark-clinical.jpg';
import wProject                   from './Bar/watermark-project.jpg';

import './Bar/main.less';

const watermarks = {
    clinical: wClinical,
    project : wProject
};

const animationDuration = 200;

/**
 * The bar graph Component
 *
 */
class Bar extends Component {

    /**
     * Get scenario for animation
     *
     * @param {object} options
     */
    static getScenario(options) {   // eslint-disable-line max-lines-per-function
        const { innerWidth, yOffset } = options;

        return {
            scenes: {
                intro: {
                    steps: {
                        main: [
                            { attribute: 'size', duration: animationDuration },
                            { attribute: 'stackOffset', duration: animationDuration },
                            {
                                attribute: 'opacity',
                                duration : animationDuration,
                                atEnd    : [
                                    { attribute: 'labelOpacity', duration: animationDuration },
                                ]
                            }
                        ],
                    },
                    defaults: {
                        opacity     : 0,
                        labelOpacity: 0,
                        size        : yOffset,
                        stackOffset : 0,
                    },
                },
                loading: {
                    steps: {
                        main: [
                            { attribute: 'opacity', duration: animationDuration, value: 0.3 },
                        ],
                    }
                },
                contentChange: {
                    steps: {
                        before: [
                            { attribute: 'labelOpacity', duration: animationDuration, value: 0 },
                        ],
                        main: [
                            { attribute: 'size', duration: animationDuration },
                            { attribute: 'stackOffset', duration: animationDuration },
                            { attribute: 'bandwidth', duration: animationDuration },
                            { attribute: 'step', duration: animationDuration },
                            { attribute: 'location', duration: animationDuration },
                            {
                                attribute: 'opacity',
                                duration : animationDuration,
                                atEnd    : [
                                    { attribute: 'labelOpacity', duration: animationDuration },
                                ]
                            }
                        ],
                    },
                    defaults: {
                        opacity    : 0,
                        size       : 0,
                        stackOffset: 0,
                        location   : [0, innerWidth],
                        bandwidth  : 0,
                        step       : 0,
                    },
                },
                general: {
                    steps: {
                        main: [
                            { attribute: 'size', duration: animationDuration },
                            { attribute: 'stackOffset', duration: animationDuration },
                            { attribute: 'bandwidth', duration: animationDuration },
                            { attribute: 'step', duration: animationDuration },
                            { attribute: 'location', duration: animationDuration },
                            { attribute: 'opacity', duration: animationDuration },
                        ],
                    },
                    defaults: {
                        opacity    : 0,
                        size       : 0,
                        stackOffset: 0,
                        location   : 0,
                        bandwidth  : 0,
                        step       : 0,
                    },
                }
            },
        };
    }

    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScales(options) {
        const { nextProps, state } = options,
            { horizontal }         = nextProps,
            { landmarkCoords }     = state;

        // Scale needs width and height of landmarkCoords
        if (!landmarkCoords) {
            state.scales = {};
            return { nextProps, state };
        }

        state.scales = horizontal
            ? Bar.getHorizontalScales({ nextProps, state })
            : Bar.getVerticalScales({ nextProps, state });

        return { nextProps, state };
    }

    /**
     * Get colors by classes
     *
     * @param {object} options
     */
    static getColorsByClasses(options) {
        const { nextProps } = options,
            {
                labelBackground, background, color,
                luminance, luminanceBottom, flat,
            }                      = nextProps,
            dc                     = d3Hsl(color),
            colorLuminance         = luminance || dc.l,
            baseColor              = _.set(_.clone(dc), 'l', colorLuminance).toString(),
            baseOverColor          = _.set(_.clone(dc), 'l', colorLuminance + 0.1 - 0.05).toString(),
            baseUnSelectedColor    = _.set(_.clone(dc), 'l', colorLuminance + 0.3).toString(),
            baseSelectedColor      = _.set(_.clone(dc), 'l', colorLuminance - 0.05).toString(),
            landmarkColor          = color,
            colorLuminanceIsDark   = colorLuminance < 0.6,
            innerTextLuminance     = colorLuminanceIsDark ? 0.95 : 0.1,
            boldInnerTextLuminance = colorLuminanceIsDark ? 1 : 0.05;

        return {
            unSelectedbar: [
                baseUnSelectedColor,
                flat ? baseUnSelectedColor : _.set(_.clone(dc), 'l', luminanceBottom - 0.1).toString(),
            ],
            selectedbar: [
                baseSelectedColor,
                flat ? baseSelectedColor : _.set(_.clone(dc), 'l', luminanceBottom - 0.15).toString(),
            ],
            bar: [
                flat ? baseColor : _.set(_.clone(dc), 'l', luminanceBottom).toString(),
                baseColor,
            ],
            overBar: [
                flat ? baseOverColor : _.set(_.clone(dc), 'l', luminanceBottom + 0.1 - 0.05).toString(),
                baseOverColor,
            ],
            borderBar      : color,
            text           : color,
            innerText      : _.set(_.clone(dc), 'l', innerTextLuminance).toString(),
            boldInnerText  : _.set(_.clone(dc), 'l', boldInnerTextLuminance).toString(),
            labelBackground: labelBackground || dc.brighter(1.5).toString(),
            landmark       : landmarkColor,
            background,
        };
    }

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            {
                luminance,
                luminanceBottom, flat,
                stacksColors,
            }               = nextProps,
            colorsByClasses = Bar.getColorsByClasses(options);

        // Add colors for stacks
        _.each(stacksColors, (stackColor, stackKey) => {
            const stackDc                = d3Hsl(stackColor),
                stackBaseColor           = _.set(_.clone(stackDc), 'l', luminance).toString(),
                stackBaseOverColor       = _.set(_.clone(stackDc), 'l', luminance + 0.1 - 0.05).toString(),
                stackBaseUnSelectedColor = _.set(_.clone(stackDc), 'l', luminance + 0.3).toString(),
                stackBaseSelectedColor   = _.set(_.clone(stackDc), 'l', luminance - 0.05).toString();

            colorsByClasses[`${stackKey}unSelectedbar`] = [
                stackBaseUnSelectedColor,
                flat ? stackBaseUnSelectedColor : _.set(_.clone(stackDc), 'l', luminanceBottom - 0.1).toString(),
            ];

            colorsByClasses[`${stackKey}selectedbar`] = [
                stackBaseSelectedColor,
                flat ? stackBaseSelectedColor : _.set(_.clone(stackDc), 'l', luminanceBottom - 0.15).toString(),
            ];

            colorsByClasses[`${stackKey}bar`] = [
                flat ? stackBaseColor : _.set(_.clone(stackDc), 'l', luminanceBottom).toString(),
                stackBaseColor,
            ];

            colorsByClasses[`${stackKey}overBar`] = [
                flat ? stackBaseOverColor : _.set(_.clone(stackDc), 'l', luminanceBottom + 0.1 - 0.05).toString(),
                stackBaseOverColor,
            ];
        });

        state.colors = colorsByClasses;

        return { nextProps, state };
    }

    /**
     * Get projected slope value
     *
     * @param {array} enabledData
     * @param {object} options
     */
    static getProjectedSlope(enabledData, options) {
        const { nextProps, state }  = options,
            { showProjectedValues } = nextProps,
            { projectedBaseLength } = nextProps,
            { stats }               = state,
            lastEnabledData         = stats ? stats.lastEnabledData : false,
            projectedBaseData       = lastEnabledData && showProjectedValues
                ? _.filter(enabledData, (obj) => obj.label > (lastEnabledData - projectedBaseLength))
                : null,
            projectionDiff          = lastEnabledData - projectedBaseLength,
            firstMedianPoint = projectedBaseData
                ? _.get(enabledData.find((obj) => projectionDiff + 1 === obj.label), 'value', 0)
                        + (
                            _.get(
                                enabledData.find(obj => Math.floor(projectionDiff * 3 / 4 + 1) === obj.label
                                ),
                                'value', 0
                            )
                            - _.get(enabledData.find(obj => projectionDiff + 1 === obj.label), 'value', 0)
                        ) / 2
                : null,
            secondMedianPoint = projectedBaseData
                ? _.get(enabledData.find((obj) => lastEnabledData === obj.label), 'value', 0)
                        - (
                            _.get(enabledData.find(obj => lastEnabledData === obj.label), 'value', 0)
                            - _.get(
                                enabledData.find(obj => Math.ceil(projectionDiff / 4) === obj.label
                                ),
                                'value',
                                0
                            )
                        ) / 2
                : null,
            projectedSlope = (secondMedianPoint - firstMedianPoint) / (projectedBaseLength / 2);

        return projectedSlope;
    }

    /**
    * Update dataSerie in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateProjectedData(options) {
        const { nextProps, state } = options,
            { stats }                  = state,
            { projectedMin, content }  = nextProps,
            { showProjectedValues }    = nextProps,
            lastEnabledData            = stats ? stats.lastEnabledData : false,
            dataContent                = _.map(content, (d) => (lastEnabledData ? { ...d, label: parseFloat(d.label) } : d))
                .filter((item) => !_.isNull(item.id) && !_.isUndefined(item.id)),  // No ids null
            enabledData                = lastEnabledData && showProjectedValues
                ? _.filter(dataContent, (obj) => obj.label <= lastEnabledData)
                : dataContent,

            projectedSlope             = Bar.getProjectedSlope(enabledData, options),
            projectedOffset            = lastEnabledData
                ? _.get(enabledData.find((obj) => lastEnabledData === obj.label), 'value', 0)
                : 0,

            projectedData              = showProjectedValues && lastEnabledData
                ? _.times(2, (num) => {
                    const x     = num + lastEnabledData + 1,
                        y       = projectedOffset + projectedSlope * (num + 1),
                        cropedY = _.isNull(projectedMin) ? y : _.max([projectedMin, y]);

                    return {
                        id: x, label: x, value: cropedY, x, y: cropedY, projected: true
                    };
                }) : [];

        if (projectedData.length > 0) {
            // Use realDatum instead projectedData
            _.times(2, (num) => {
                const x              = num + lastEnabledData + 1,
                    realDatum        = dataContent.find((item) => item.id === x),
                    projectedDatum   = projectedData.find((item) => item.x === x),
                    projectedIsUnder = realDatum && projectedDatum && projectedDatum.value < realDatum.value;

                // Use realDatum
                if (projectedIsUnder) {
                    projectedDatum.value = realDatum.value;
                }
            });
        }

        state.projectedData = projectedData;

        state.enabledData   = dataContent;

        return { nextProps, state };
    }

    /**
    * Update dataSerie in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateDataSerie(options) {
        const { nextProps, state } = options,
            {
                enabledData, stats, projectedData
            } = state,
            extendMinX             = _.get(stats, 'extendMinX', false),
            extendMaxX             = _.get(stats, 'extendMaxX', false),
            dataSerie              = projectedData && projectedData.length > 0 ? enabledData.concat(projectedData) : enabledData,
            contentIdsOrder        = dataSerie.reduce(
                (cumul, item) => {
                    if (!cumul.some((d) => d.id === item.id)) {
                        cumul.push(item.id);
                    }
                    return cumul;
                },
                []
            ),
            groupedSerie           = _.groupBy(dataSerie, 'id'),
            flatSerie              = _.map(groupedSerie, (items) => items.reduce((cumul, item) => {
                cumul.id           = item.id;
                cumul.label        = item.label;
                cumul.entity       = item.entity;
                cumul.value        = (cumul.value || 0) + (item.projected ? 0 : item.value);
                return cumul;
            }, {}));

        // Reorder flatSerie by ids from content
        flatSerie.sort((itemA, itemB) => contentIdsOrder.indexOf(itemA.id) - contentIdsOrder.indexOf(itemB.id));

        // Manage hidden data from extendMinX and extendMaxX
        state.flatSerie  = flatSerie.map((item) => {
            item.hidden  = (extendMinX && item.id < extendMinX) || (extendMaxX  && item.id > extendMaxX);
            return item;
        });

        state.groupedSerie  = groupedSerie;

        return { nextProps, state };
    }

    /**
    * Update plotData in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state } = options,
            { horizontal }         = nextProps,
            { scales }             = state,
            { xScale, yScale }     = scales;

        // PlotData need scales
        if (!xScale || !yScale) {
            return { nextProps, state };
        }


        state.plotData = horizontal
            ? Bar.getHorizontalPlotData({ nextProps, state })
            : Bar.getVerticalPlotData({ nextProps, state });

        return { nextProps, state };
    }

    /**
    * Update dynamicStepNumber in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateDynamicStepNumber(options) {
        const { nextProps, state }                     = options,
            { horizontal }                             = nextProps,
            { flatSerie }                              = state,
            { minBarWidth, innerPadding, outerMargin } = nextProps,
            totalWidth                                 = horizontal ? state.innerHeight : state.innerWidth,
            barWidth                                   = totalWidth / (flatSerie.length + (flatSerie.length - 1) * innerPadding),
            realOuterMargin                            = barWidth * (1 + innerPadding) * outerMargin * 2;

        state.dynamicStepNumber = minBarWidth
            ? Math.floor(
                (totalWidth - realOuterMargin) / minBarWidth * (1 - innerPadding)
                // TODO make true formula
            )
            : false;

        return { nextProps, state };
    }

    /**
    * Update selectedRange in state
    *   Selected range in data scale
    *
    * @params options object { nextProps, state }
    **
    * @return object { nextProps, state }
    */
    static updateSelectedRange(options) {
        const { nextProps, state } = options,
            { filterValues }       = nextProps,
            { start, end }         = filterValues,
            { scales, stats }      = state,
            { xScale }             = scales,
            domain                 = xScale?.domain(),
            domainMin              = parseFloat(_.min(domain), 10),
            domainMax              = parseFloat(_.max(domain), 10),
            stepWidth              = xScale?.step ? xScale.step() : 0,
            selectionStart         = start || _.get(stats, 'selectionStart'),
            selectionEnd           = end || _.get(stats, 'selectionEnd'),
            newMin                 = selectionStart >= domainMin ? selectionStart : null,
            newMax                 = selectionEnd  <= domainMax ? selectionEnd :  null;

        if (!selectionStart || !selectionEnd) {
            state.selectedRange = null;
            return { nextProps, state };
        }

        // Set selectedRange
        if (newMin && newMax) {
            state.selectedRange = {
                min: { x: newMin},
                max: { x: newMax}
            };
        }

        // Set selectedPixelRange
        state.selectedPixelRange = {
            min: {
                x: xScale && xScale(_.get(state.selectedRange, 'min.x')) + stepWidth / 2
            },
            max: {
                x: xScale && xScale(_.get(state.selectedRange, 'max.x')) + stepWidth / 2
            }
        };

        return { nextProps, state };
    }


    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderDefs', 'render', 'renderGraph', 'launchModal', 'onItemMouseOver', 'componentDidUpdate',
            'setLandmarkCoords'
        );

        this.state = {
            itemOverflew: false,
            selectedId  : null
        };

        this.containerRef = React.createRef();
        this.graphRef     = React.createRef();

        this.selectedRange = null;

        // Make a uuid
        this.id = _.uniqueId('bar-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Triggered when the component is updated
    *
    * @return void
    */
    componentDidUpdate() {
        this.filterBySelectedRange();
        this.manageInnerLabels();
    }

    /**
     * Call filter cb when selectedRange is set
     *
     */
    filterBySelectedRange() {
        const { filterCb, contentChange }  = this.props,
            { selectedRange, flatSerie } = this.state,
            { min, max }                 = selectedRange || {},
            { x: minX }                  = min || {},
            { x: maxX }                  = max || {},
            hasValidBoundary              = _.isNumber(minX) && _.isNumber(maxX) && !_.isNaN(minX) && !_.isNaN(maxX),
            selectedRangeChanged         = (
                minX !== _.get(this.selectedRange, 'min.x')
                    || maxX !== _.get(this.selectedRange, 'max.x')
            ),
            totalInSelection = hasValidBoundary
                ? flatSerie.filter((d) => d.id >= minX && d.id <= maxX).reduce((cumul, d) => cumul + d.value, 0)
                : 0;

        if (filterCb && selectedRange && hasValidBoundary && (selectedRangeChanged || contentChange) && totalInSelection !== 0) {
            this.selectedRange = selectedRange;
            filterCb({
                start: minX,
                end  : maxX
            }, true);
        }
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - scales (D3Scales object) of the landmark
    *          - stats
    *          - colors
    *          - plotData
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
                height,
                width,
                showLandmark,
                isCapture,
                showTopStats,
                showTrendInformation,
                trendHeight,
                data
            }                    = nextProps,
            { itemOverflew }     = prevState,
            { stats: lastStats } = prevState,
            { stats: newStats }  = data || {},
            stats                = newStats || lastStats,
            // Cascading function to update state
            updateState = _.flow([
                Bar.updateProjectedData,        // ProjectedData
                Bar.updateDataSerie,            // DataSerie
                Bar.updateDynamicStepNumber,    // DynamicStepNumber from minBarWidth
                Bar.updateScales,               // D3 scales
                Bar.updateColors,               // All colors and gradient
                Bar.updateSelectedRange,        // SelectedRange
                Bar.updatePlotData,             // Location and size of bars
            ]),
            trendOffset  = showTrendInformation ? trendHeight : 0,
            { state }    = updateState({
                nextProps,
                state: {
                    itemOverflew,
                    stats,
                    // Size of the landmark
                    innerHeight   : height - trendOffset,
                    innerWidth    : width  - (showTopStats ? width / 4 : 0),
                    hasLandmark   : showLandmark || isCapture,
                    landmarkCoords: prevState.landmarkCoords,
                    trendOffset
                }
            });

        return state;
    }


    /**
     * Get serie and slice it from stepNumber || dynamicStepNumber
     *
     * @param {object} options
     */
    static getSerie(options) {
        const { nextProps, state }          = options,
            { stepNumber, stepSliceOrigin } = nextProps,
            { dynamicStepNumber }           = state,
            { flatSerie }                   = state,
            sliceCount                      = stepNumber || dynamicStepNumber,
            sliceByStart                    = stepSliceOrigin == 'start',
            mustBeSlice                     = sliceCount && sliceCount < flatSerie.length,
            sliceRange                      = mustBeSlice && {
                start: sliceByStart ? 0          : flatSerie.length - sliceCount,
                end  : sliceByStart ? sliceCount : flatSerie.length
            };

        if(!sliceRange) {
            return flatSerie;
        }

        return _.slice(flatSerie, sliceRange.start, sliceRange.end);
    }


    /**
     * Get maxValue from plotData and projectedData
     */
    static getMaxValue(options) {
        const {state}        = options,
            serie            = Bar.getSerie(options),
            maxPlotData      = d3Max(serie, (d) => d.value),
            maxProjectedData = d3Max(state.projectedData, (d) => d.value);

        return _.max([maxPlotData, maxProjectedData]);
    }

    /**
    * Get vertical bar scale
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static getVerticalScales(options) {
        const { nextProps, state } = options,
            {
                innerPadding, outerMargin,
                maxBarWidth, showFlag,
                showLogo, fontSize, showInnerLabels,
            }                     = nextProps,
            { landmarkCoords }    = state,
            { plotSize, offsets } = landmarkCoords || {},
            { width, height }     = plotSize || {},
            { xOffset, yOffset }  = offsets || {},
            serie                 = Bar.getSerie(options),
            maxValue              = Bar.getMaxValue(options),
            max                   = roundUpByMagnitude(maxValue) + 1,
            visibleData           = serie.filter((item) => !item.hidden),
            barWidth              = (width - (1 + innerPadding / 2) * width / (visibleData.length + 1))
                / visibleData.length,
            // Make scale with graph size
            xScale                = plotSize && d3ScaleBand()
                .range([
                    xOffset + barWidth * (1 + innerPadding) * outerMargin / 2,
                    // Use plain width or width based on maxBarWidth
                    xOffset + (maxBarWidth > barWidth || !maxBarWidth
                        ? width - (barWidth * innerPadding)
                        : (maxBarWidth * visibleData.length) - (barWidth * (1 + innerPadding) * outerMargin / 2)
                    )
                ])
                .paddingInner(innerPadding).paddingOuter(outerMargin / 4)
                .domain(_.map(visibleData, 'id')),
            iconOffset            = showFlag || showLogo ? xScale?.bandwidth() : 0,
            labelOffset           = showInnerLabels && !showFlag ? fontSize * 4 : 0,
            yScale                = plotSize && d3ScaleLinear()
                .range([yOffset + height - iconOffset, yOffset + labelOffset])
                .domain([0, max]).nice(),
            realOuterMargin       = barWidth * (1 + innerPadding) * outerMargin;

        return { xScale, yScale, xOffsetAfter: realOuterMargin, iconOffset };
    }

    /**
    * Prepare horizontal bar scale
    *
    * @params options object { nextProps, state }
    *
    * @return self
    */
    static getHorizontalScales(options) {
        const { nextProps, state } = options,
            {
                innerPadding, outerMargin, stepNumber,
                maxBarWidth, showLogo,
            }                     = nextProps,
            { landmarkCoords }    = state,
            serie                 = Bar.getSerie(options),
            maxValue              = Bar.getMaxValue(options),
            max                   = roundUpByMagnitude(maxValue) + 1,
            { plotSize, offsets } = landmarkCoords || {},
            { xOffset, yOffset }  = offsets || {},
            { width, height }     = plotSize || {},
            padding               = innerPadding * (height / serie.length) + height * outerMargin / 2,
            barWidth              = (height  - (1 + innerPadding / 2) * height / (serie.length + 1)) / serie.length,
            // Force x to match number of item (stepNumber)
            yForce                = stepNumber !== false ? serie.length / stepNumber : 1,
            // Make scale with graph size
            yScale                = d3ScaleBand().range([
                yOffset + (maxBarWidth > barWidth || !maxBarWidth
                    ? height - height * yForce - padding
                    : height - ((maxBarWidth * serie.length) - (barWidth * (1 + innerPadding) * outerMargin / 2))
                ),
                yOffset + height - height * outerMargin / 2,
            ]).paddingInner(innerPadding)
                .paddingOuter(innerPadding / 2)
                .domain(_.map(serie, 'id')),
            iconOffset            = showLogo ? yScale?.bandwidth() : 0,
            xScale                = d3ScaleLinear()
                .range([xOffset + iconOffset, xOffset + width])
                .domain([0, max]).nice();


        // Zoom on data
        return { xScale, yScale, iconOffset };
    }


    /**
     * Get sentence string (html formated)
     *
     * @param {object} data
     */
    static getSentenceHtmlString(data, props, plotDatum = {}) {
        const { itemUnit } = props,
            preposition    = props.preposition || 'in',
            {
                projected, typeLabel, type,
                value, id
            }     = data,
            label = _.get(data, 'entity.label', plotDatum.label ||data.label || id);

        return '<b>'
            + `${formatNumberWithMagnitude(value, 2)}`
            + `${projected ? ' projected' : ''} `
            + `${pluralize(typeLabel || itemUnit || type, value)}`
            + '</b> '
            + `${preposition} `
            + `<b>${label}</b>`;
    }

    /**
    * Get plot vertical data
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static getVerticalPlotData(options) {                       // eslint-disable-line
        const { nextProps, state }          = options,
            {  openModal, showInnerLabels } = nextProps,
            { interactive, filterCb }       = nextProps,
            { filterValues, showStack }     = nextProps,
            { scales }                      = state,
            {selectedRange,  stats }        = state,
            { xScale, yScale }              = scales,
            step                            = xScale?.step(),
            bandwidth                       = xScale?.bandwidth(),
            domain                          = xScale?.domain(),
            serie                           = Bar.getSerie(options),
            visibleData                     = serie.filter((item) => !item.hidden),
            maxValue                        = yScale && d3Max(yScale.domain()),
            lastEnabledData                 = stats ? stats.lastEnabledData : false,
            minX                            = _.get(selectedRange, 'min.x'),
            maxX                            = _.get(selectedRange, 'max.x'),
            hasSelection                    = (
                filterValues !== false && (_.isArray(filterValues) && filterValues.length > 0)
            ) || minX,
            plotData                        = _.isArray(visibleData) ? visibleData.map((d, i) => {
                const enabled = lastEnabledData
                        ? parseFloat(d.label, 10) <= lastEnabledData
                        : true,
                    id            = !_.isUndefined(d.id) ? d.id :  _.get(d, 'entity.id') || d.label,
                    selected      = (selectedRange && (parseFloat(id, 10) >= minX && parseFloat(id, 10) <= maxX))
                        || (_.isArray(filterValues) && filterValues.indexOf(d.id) !== -1),
                    label         = _.get(d, 'entity.label', d.label || d.id),
                    valueSentence = Bar.getSentenceHtmlString(d, nextProps);

                return {
                    enabled,
                    valueSentence,
                    label,
                    bandwidth,
                    step,
                    id,
                    clickable  : (interactive && openModal && ((d.entity && d.entity.clickable)) || (filterCb && d.id)),
                    value      : d.value,
                    filterValue: id,
                    location   : xScale && xScale(domain[i]) + step / 2,
                    stackOffset: 0,
                    size       : yScale && maxValue !== 0
                        ? (yScale(d.value) - yScale(maxValue) - (showInnerLabels ? yScale(maxValue) : 0))
                        : 0,
                    entity      : d.entity || false,
                    selected,
                    projected   : d.projected,
                    opacity     : (!hasSelection || selected) ? 1 : 0.3,
                    labelOpacity: 1,
                    lastStack   : true,
                };
            }) : [];

        return showStack ? Bar.getVerticalStackedPlotData(options, plotData) : plotData;
    }

    /**
    * Get plot vertical stacked data
    *
    * @params options  object { nextProps, state }
    * @params plotData object cumuledData
    *
    * @return object
    */
    static getVerticalStackedPlotData(options, plotData) {
        const { state, nextProps }   = options,
            { groupedSerie, scales } = state,
            { landmarkCoords }       = state,
            { offsets }              = landmarkCoords || {},
            { yOffset }              = offsets || {},
            { yScale }               = scales,
            indexStackOffset         = {},
            stackedData              = [],
            maxValue                 = yScale && d3Max(yScale.domain());

        plotData.forEach((plotDatum) => {
            const fullStackData = groupedSerie[plotDatum.id],
                stackValue      = fullStackData.reduce(
                    (cumul, item) => cumul + (item.projected ? 0 : item.value),
                    0
                ),
                values = fullStackData.filter(rawData => rawData.value);


            values.forEach((rawData, index) => {
                const valueSentence  = Bar.getSentenceHtmlString(rawData, nextProps, plotDatum),
                    itemSizeOffset = rawData.projected ? stackValue : 0,
                    size           = rawData.value && yScale && maxValue !== 0
                        ? Math.round(yScale(itemSizeOffset + maxValue - rawData.value))
                        : 0,
                    stackOffset = (indexStackOffset[plotDatum.id] || 0),
                    adjustedSize    = size - yOffset;

                if (size > 0) {
                    stackedData.push({
                        ...plotDatum,
                        id       : plotDatum.id + (rawData.type ? rawData.type : '') + (rawData.projected ? '_p' : ''),
                        type     : rawData.type,
                        value    : rawData.value,
                        projected: rawData.projected,
                        lastStack: index === values.length - 1,
                        stackOffset,
                        size     : adjustedSize,
                        valueSentence,
                    });

                    indexStackOffset[plotDatum.id] = stackOffset + adjustedSize;
                }
            });
        });

        return stackedData;
    }

    /**
    * Get plot horizontal data
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static getHorizontalPlotData(options) {
        const { nextProps, state }               = options,
            { openModal, filterValues }          = nextProps,
            { interactive, filterCb, showStack } = nextProps,
            { stats, selectedRange }             = state,
            hasSelection                         = filterValues !== false
                && (_.isArray(filterValues) && filterValues.length > 0),
            serie                                = Bar.getSerie(options),
            { scales }                           = state,
            { xScale, yScale, iconOffset }       = scales,
            step                                 = yScale?.step(),
            bandwidth                            = yScale?.bandwidth(),
            domain                               = yScale?.domain(),
            lastEnabledData                      = stats ? stats.lastEnabledData : false,
            maxValue                             = d3Max(xScale?.domain()),
            minX                                 = _.get(selectedRange, 'min.x'),
            maxX                                 = _.get(selectedRange, 'max.x'),
            plotData                             = serie.map((d, i) => {
                const id          = !_.isUndefined(d.id) ? d.id :  _.get(d, 'entity.id') || d.label,
                    selected      = (selectedRange && (parseFloat(id, 10) >= minX && parseFloat(id, 10) <= maxX))
                        || (_.isArray(filterValues) && filterValues.indexOf(d.id) !== -1),
                    label         = _.get(d, 'entity.label', d.label || d.id),
                    valueSentence = Bar.getSentenceHtmlString(d, nextProps);

                return {
                    id,
                    clickable  : interactive && openModal && ((d.entity && d.entity.clickable) || (filterCb && d.id)),
                    filterValue: id,
                    value      : d.value,
                    valueSentence,
                    label,
                    location   : yScale && yScale(domain[i]) + step / 2,
                    size       : iconOffset + (xScale && maxValue !== 0
                        ? xScale(d.value) - xScale(0)
                        : 0),
                    entity : d.entity || false,
                    enabled: lastEnabledData
                        ? parseFloat(d.label, 10) <= lastEnabledData
                        : true,
                    opacity     : (!hasSelection || selected) ? 1 : 0.3,
                    labelOpacity: 1,
                    lastStack   : true,
                    selected,
                    projected   : d.projected,
                    bandwidth,
                    step,
                };
            });

        return showStack ? Bar.getHorizontalStackedPlotData(options, plotData) : plotData;
    }

    /**
    * Get plot horizontal stacked data
    *
    * @params options  object { nextProps, state }
    * @params plotData object cumuledData
    *
    * @return object
    */
    static getHorizontalStackedPlotData(options, plotData) {
        const { nextProps, state }   = options,
            { groupedSerie, scales } = state,
            { xScale, iconOffset }   = scales,
            indexStackOffset         = {},
            stackedData              = [],
            maxValue                 = d3Max(xScale.domain());

        plotData.forEach((plotDatum) => {
            const fullStackData = groupedSerie[plotDatum.id],
                stackData       = fullStackData.filter((item) => item.value !== 0);

            stackData.forEach((rawData, index) => {
                const stackOffset = (indexStackOffset[plotDatum.id] || 0),
                    valueSentence = Bar.getSentenceHtmlString(rawData, nextProps, plotDatum),
                    size          = iconOffset + (maxValue !== 0
                        ? Math.round(xScale(rawData.value) - xScale(0))
                        : 0);

                stackedData.push({
                    ...plotDatum,
                    id       : plotDatum.id + (rawData.type ? rawData.type : ''),
                    type     : rawData.type,
                    value    : rawData.value,
                    projected: rawData.projected,
                    stackOffset,
                    size,
                    valueSentence,
                    lastStack: index === stackData.length - 1,
                });

                indexStackOffset[plotDatum.id] = size + stackOffset;
            });
        });

        return stackedData;
    }

    /**
    * On item click
    *
    * @return boolean
    */
    onItemClick(item) {
        return () => {
            const { filterCb, filterValues, filterType } = this.props,
                { openModal }                           = this.props,
                { start, end }                         = filterValues,
                entityId                                = item.entity ? item.entity.id : item.id,
                { filterValue }                         = item;

            // Only open modal
            if (!_.isNull(openModal)) {
                this.launchModal(entityId);
            }

            if (item.groupedOther) {
                return;
            }

            // Add filter
            if (filterCb && filterValue) {
                filterCb(
                    filterType.indexOf('-range') !== -1
                        ? filterValue !== start || filterValue !== end ? { // Specific for timeframe
                            start: filterValue,
                            end  : filterValue
                        } : []
                        : _.xor(filterValues, [filterValue])
                );
            }

            return true;
        };
    }

    /**
    * On item over
    *
    * @return boolean
    */
    onItemMouseOver(itemId = null) {
        return () => {
            const { interactive } = this.props;

            if (!interactive) {
                return;
            }

            this.mouseOverTimeout = requestAnimationFrame(
                () => this.setState({ itemOverflew: itemId }),
            );

            return true;
        };
    }

    /**
    * On item label click
    *
    * @return html
    */
    launchModal(id) {
        const {
                openModal,
                content
            }         = this.props,
            item      = content.find((obj) => _.get(obj, 'entity.id') === id) || {},
            { entity } = item;

        if (entity) {
            const  entities     = content.map((item) => item.entity).filter((item) => item.clickable),
                cleanedEntities = _.uniqBy(entities, (item) => item.id);

            openModal(entity, cleanedEntities);
        }
    }

    /**
    * Manage inner labels (in or out the bar)
    *
    * @return void
    */
    manageInnerLabels() {
        const { showInnerLabels }          = this.props,
            { horizontal, fontSize }       = this.props,
            { innerWidth, colors, scales } = this.state,
            { iconOffset }                 = scales,
            { xOffset }                    = this.getOffsets();

        if (!horizontal || !showInnerLabels || !this.graphRef.current) {
            return;
        }

        const el     = this.graphRef.current,
            parentEl = el.parentNode,
            bars     = el.querySelectorAll('.bars .plotData'),
            labels   = parentEl.parentNode.querySelectorAll('.item-labels .item-label'),
            values   = parentEl.parentNode.querySelectorAll('.item-values .item-value'),
            padding  = fontSize / 2;

        labels.forEach((label, i) => {
            const bar            = bars[i],
                barWidth         = getElementSize(bar).width,
                barContentWidth  = barWidth - padding * 2,
                span             = label.querySelector('span'),
                spanValue        = values[i]?.querySelector('span'),
                labelWidth       = span.offsetWidth,
                valueWidth       = spanValue?.offsetWidth || 0,
                overflow         = barWidth > 0 && (barContentWidth - iconOffset) < labelWidth,
                newLabelWidth    = innerWidth - barContentWidth - padding,
                mustBeMove       = overflow && newLabelWidth > labelWidth;

            label.style.left      = `${xOffset + padding}px`;
            span.style.color      = colors.innerText;

            if (mustBeMove) {
                label.style.left     = `${xOffset + barContentWidth + padding * 2 + valueWidth}px`;
                label.style.padding  = '0 20px';
                label.style.width    = 'auto';
                label.style.maxWidth = `${newLabelWidth}px`;
                span.style.color     =  colors.text;
            }
        });
    }


    /**
     * Get the plot size from landmarkCoords
     *
     * @returns object
     */
    getPlotSize() {
        const { landmarkCoords } = this.state,
            { plotSize }        = landmarkCoords || {};

        return plotSize || {};
    }

    /**
     * Get the offsets from landmarkCoords
     *
     * @returns object
     */
    getOffsets() {
        const { landmarkCoords } = this.state,
            { offsets }          = landmarkCoords || {};

        return offsets || {};
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { colors }                    = this.state,
            { width, height }               = this.getPlotSize(),
            { horizontal, gradientByBlock } = this.props,
            { stacksColors, showStack }     = this.props,
            gradientProps = {
                gradientUnits: gradientByBlock ? null : 'userSpaceOnUse',
                x1           : '0',
                x2           : horizontal ? (gradientByBlock ? '100%' : width) : '0',
                y1           : horizontal ? '0' : (gradientByBlock ? '100%' : height),
                y2           : '0',
            },
            gradientTypes = stacksColors && showStack ? _.keys({ ...stacksColors, '': null }) : [''];
        // TODO Safe color when colors[stackKey] undefined !!
        return (
            <defs key="defs">
                { gradientTypes.map((stackKey) => (
                    <g key={`${this.id}${stackKey}`}>
                        {/* Gradient of the bar */}
                        <linearGradient
                            {...gradientProps}
                            id={`${this.id}${stackKey}-gradient`}
                        >
                            <stop offset="0%" stopColor={colors[`${stackKey}bar`][0]} />
                            <stop offset="100%" stopColor={colors[`${stackKey}bar`][1]}
                                stopOpacity={0.8}
                            />
                        </linearGradient>

                        {/* Gradient of overed the bar */}
                        <linearGradient
                            {...gradientProps}
                            id={`${this.id}${stackKey}-over-gradient`}
                        >
                            <stop offset="0%" stopColor={colors[`${stackKey}overBar`][0]} />
                            <stop offset="100%" stopColor={colors[`${stackKey}overBar`][1]}
                                stopOpacity={0.8}
                            />
                        </linearGradient>

                        {/* Gradient of the selected area */}
                        <linearGradient
                            {...gradientProps}
                            id={`${this.id}${stackKey}-selected-gradient`}
                        >
                            <stop offset="0%" stopColor={colors[`${stackKey}selectedbar`][0]} />
                            <stop offset="100%" stopColor={colors[`${stackKey}selectedbar`][1]} />
                        </linearGradient>
                    </g>
                ))}
            </defs>
        );
    }

    /**
     * Set the size of the plotter container
     *
     * @param {object} size
     */
    setLandmarkCoords(newLandmarkCoords) {
        const { landmarkCoords } = this.state;
        if (JSON.stringify(landmarkCoords) !== JSON.stringify(newLandmarkCoords)) {
            this.setState({ landmarkCoords: newLandmarkCoords });
        }
    }

    /**
    * Render the landmark
    *
    * @return html
    */
    renderLandmark() {
        const {
                horizontal, showInnerLabels,
                xLabel, yLabel, noContent,
                content, margin
            }                                 = this.props,
            {
                innerHeight,
                innerWidth,
                scales,
                colors,
                hasLandmark,
            }                                 = this.state,
            { xScale, yScale, xOffsetAfter }  = scales;

        return (
            <Landmark
                key={JSON.stringify(content)}
                height={innerHeight}
                width={innerWidth}
                xScale={xScale}
                yScale={yScale}
                xOffsetAfter={xOffsetAfter}
                xLabel={xLabel}
                yLabel={yLabel}
                hideXTicks={!horizontal && showInnerLabels}
                hideYTicks={horizontal && showInnerLabels}
                color={colors.landmark}
                noContent={noContent}
                setLandmarkCoordsCb={this.setLandmarkCoords}
                hideLandmark={!hasLandmark}
                margin={margin}
            />
        );
    }


    /**
     *
     */
    renderHorizontalLabel(d, key) {
        const {
                fontSize, fontSizeAuto
            }          = this.props,
            {
                itemOverflew, colors,
                scales,
            }          = this.state,
            itemId     = _.get(d, 'entity.id') || d.id,
            { yScale } = scales,
            bandwidth  = yScale.bandwidth();

        return (
            <div
                className={`item-label ${d.clickable ? 'clickable' : ''}`}
                key={`${key}-${itemId}`}
                style={{
                    top       : `${d.location - bandwidth / 2}px`,
                    left      : `${0}px`,
                    lineHeight: `${bandwidth}px`,
                    padding   : `0 ${fontSize / 2}px`,
                    width     : d.size,
                }}
                onClick={d.clickable ? this.onItemClick(d) : null}
                onMouseOver={this.onItemMouseOver(itemId)}
                onFocus={this.onItemMouseOver(itemId)}
                onMouseOut={this.onItemMouseOver()}
                onBlur={this.onItemMouseOver()}
            >
                <span
                    style={{
                        lineHeight: `${bandwidth}px`,
                        color     : itemOverflew === d.id ? colors.boldInnerText : colors.innerText,
                        fontSize  : fontSize !== false && !fontSizeAuto ? fontSize : bandwidth * 0.3,
                        opacity   : itemOverflew === d.id ? 1 : d.labelOpacity * d.opacity,
                    }}
                >
                    {d.label}
                </span>
            </div>
        );
    }

    /**
     * Get item key for react rendering
     *
     * @returns string
     */
    getItemKey(item) {
        return  `${item.id}-${item.value}-${item.label}-${item.location}`;
    }


    /**
    * Render labels in bar
    *
    * @return html
    */
    renderHorizontalLabels(plotData, svgWidth) {
        const { interactive, noModelSentence } = this.props,
            { showTopStats }                   = this.props,
            { tooltipValue }                   = this.props;

        return (
            <div
                className="item-labels"
                style={{
                    left : showTopStats ? svgWidth / 4 : 0,
                    width: svgWidth
                }}
            >
                { plotData.map((d) => {
                    const key         = this.getItemKey(d);

                    return (
                        <Popover
                            key={key}
                            content={(
                                <div className="tooltip-value">
                                    <div
                                        className="sentence"
                                        dangerouslySetInnerHTML={{ __html: tooltipValue ? d.valueSentence : '' }}
                                    />
                                    <div className="information">
                                        {!d.clickable && noModelSentence ? noModelSentence : ''}
                                    </div>
                                </div>
                            )}
                            placement="left"
                            trigger={interactive ? 'hover' : 'contextMenu'}
                        >
                            {this.renderHorizontalLabel(d, key)}

                        </Popover>
                    );
                }) }
            </div>
        );
    }

    /**
    * Render labels on vertical bar
    *
    * @return html
    */
    renderVerticalLabels(plotData) {
        const { colors}                  = this.state,
            { height }                   = this.getPlotSize(),
            { fontSize, showInnerLabels} = this.props;

        return !showInnerLabels ? null : (
            <div key="vertical" className="item-labels">
                { plotData.map((d) => {
                    const key  = this.getItemKey(d),
                        itemId = _.get(d, 'entity.id') || d.id,
                        label  = _.get(d, 'entity.label', d.label);

                    return (
                        <div
                            key={key}
                            className="item-label"
                            style={{
                                top    : `${height - d.size - fontSize * 3}px`,
                                left   : `${d.location - d.bandwidth / 2}px`,
                                width  : `${d.bandwidth}px`,
                                padding: `0 ${fontSize / 2}px`,
                            }}
                            title={label}
                        >
                            <span
                                className={d.clickable ? 'clickable' : 0}
                                style={{
                                    color   : colors.innerText,
                                    fontSize: `${fontSize}px`,
                                    opacity : d.labelOpacity * d.opacity,
                                }}
                                key={key}
                                dataid={d.entity ? d.entity.id : null}
                                fontSize={fontSize !== false ? fontSize : d.bandwidth * 0.2}
                                onClick={d.clickable ? this.onItemClick(d) : null}
                                onMouseOver={this.onItemMouseOver(itemId)}
                                onFocus={this.onItemMouseOver(itemId)}
                                onMouseOut={this.onItemMouseOver()}
                                onBlur={this.onItemMouseOver()}
                            >
                                { label }
                            </span>
                        </div>
                    );
                }) }
            </div>
        );
    }

    /**
    * Render values on vertical bar
    *
    * @param {array} plotData
    * @param {number} width
    *
    * @returns {JSX}
    */
    renderVerticalValues(plotData, width) {
        const { height }              = this.getPlotSize(),
            { showValue }             = this.props,
            { color, trendHeight }    = this.props,
            { showTrendInformation }  = this.props,
            { margin, showLandmark }  = this.props,
            { width: widthFromProps } = this.props,
            { isCapture }             = this.props,
            { scales }                = this.state,
            { iconOffset }            = scales,
            ratio                     = widthFromProps / window.innerWidth,
            showValues                = showValue && showLandmark && (isCapture || (!isCapture && ratio > 0.7)),
            trendOffset               = showTrendInformation ? trendHeight : 0,
            yOffset                   = trendOffset + margin - iconOffset - (iconOffset > 0 ? 5 : 10),
            fontSize                  = _.floor((ratio * 0.5), 2);

        return !showValues ? null : (
            <div key="vertical-values" className="item-values"
                style={{ width, height: 0 }}
            >
                {plotData.map(d => {
                    const { projected } = d,
                        { enabled }     = d,
                        key             = this.getItemKey(d),
                        show            = _.isUndefined(projected) && enabled;

                    return !show ? null : (
                        <div key={key} className="item-value"
                            style={{
                                top  : `${height - d.size + yOffset}px`,
                                left : `${d.location - d.bandwidth / 2}px`,
                                width: `${d.bandwidth}px`,
                            }}
                        >
                            <span key={key} className={d.clickable ? 'clickable' : 0}
                                style={{
                                    color,
                                    fontSize: `${fontSize}vw`,
                                }}
                            >
                                {formatInternationalNumber(d.value)}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
    * Render values on horizontal bar
    *
    * @param {array} plotData
    * @param {number} width
    *
    * @returns {JSX}
    */
    renderHorizontalValues(plotData, width) {
        const { showValue, fontSize } = this.props,
            { color, showLandmark }   = this.props,
            { showTopStats, margin }  = this.props,
            { width: widthFromProps } = this.props,
            { showInnerLabels, }      = this.props,
            { isCapture }             = this.props,
            ratio                     = !isCapture ? widthFromProps / window.innerWidth : 1,
            // TODO: WARNING: Feature currently disabled for horizontal graphs except for IP Players ...
            showValues                = (showValue === 'force' && showLandmark && ratio > 0.7)
                || (false && showValue && showLandmark && ratio > 0.7),
            offset                    = showInnerLabels ? 20 : 90; // TODO: include iconOffset instead of this ?

        return !showValues ? null : (
            <div key="horizontal-values" className="item-values horizontal"
                style={{
                    width,
                    left: showTopStats ? (width / 4) : 0,
                }}
            >
                {plotData.map(d => {
                    const key = this.getItemKey(d);

                    return (
                        <div key={key} className="item-value"
                            style={{
                                left  : `${d.size + margin + offset}px`,
                                top   : `${d.location - d.bandwidth / 2}px`,
                                height: d.bandwidth,
                            }}
                        >
                            <span key={key} className={d.clickable ? 'clickable' : 0}
                                style={{
                                    color,
                                    fontSize: `${fontSize * ratio}px`,
                                }}
                            >
                                {formatInternationalNumber(d.value)}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
    * Render labels on left and right border of th graph
    *
    * @return html
    */
    renderBorderLabels() {  // eslint-disable-line max-lines-per-function
        const {
                plotData,
                scales,
            } = this.state,
            { xScale, yScale } = scales;

        if (
            plotData.length === 0
            || !xScale
            || !yScale
        ) {
            return null;
        }

        const { fontSize, fontSizeAuto } = this.props,
            {
                colors
            }                  = this.state,
            first              = _.first(plotData),
            last               = _.last(plotData),
            step               = xScale.step(),
            padding            = xScale.paddingInner() * step,
            bandwidth          = xScale.bandwidth(),
            firstTranslate     = xScale(first.label) - padding,
            justOneValue       = plotData.length === 1,
            lastTranslate      = justOneValue
                ? xScale(last.label) + step / 2
                : xScale(last.label) + step + padding;

        return (
            <g
                key="border-labels"
                className="borderLabels"
                transform={`translate( 0 ${yScale(0) + fontSize * 1.5} )`}
            >
                { first.label !== last.label && (
                    <text
                        transform={`translate( ${firstTranslate} 0 )`}
                        style={{ textAnchor: 'start', fill: colors.text }}
                        fontSize={fontSize !== false && !fontSizeAuto ? fontSize : bandwidth * 0.3}
                    >
                        {first.label}
                    </text>
                )}
                <text
                    transform={`translate( ${lastTranslate} 0 )`}
                    style={{ textAnchor: justOneValue ? 'middle' : 'end', fill: colors.text }}
                    fontSize={fontSize !== false && !fontSizeAuto ? fontSize : bandwidth * 0.3}
                >
                    {last.label}
                </text>
            </g>
        );
    }

    /**
    * Get stacksColorKey from item type, and check if is defined in stacksColors
    *
    * return string
    */
    getStackColorKey(type) {
        const { stacksColors } = this.props;

        return type && stacksColors && stacksColors[type] ? type : '';
    }


    /**
     * Get svg path of item
     *
     * @param {object} item
     */
    getVerticalPath(item) {
        const {
                roundCornerFactor,
                showLogo, showFlag,
            }           = this.props,
            { height }  = this.getPlotSize(),
            xMargin     = ((showLogo || showFlag) ? item.bandwidth / 2: 0),
            x           = item.location - item.bandwidth / 2,
            y           = height - item.size - item.stackOffset - xMargin,
            pathWidth   = item.bandwidth,
            pathHeight  = item.size + xMargin,
            r           = item.lastStack
                ? _.min([Math.round(item.bandwidth * roundCornerFactor), item.size])
                : 0;

        let path = `M${x} ${y + r} `;
        path += `Q ${x} ${y} ${x + r} ${y} `;
        path += `H ${x + pathWidth - r} `;
        path += `Q ${x + pathWidth} ${y} ${x + pathWidth} ${y + r} `;
        path += `v ${pathHeight - r} `;
        path += `h ${-pathWidth} `;
        path += `v ${-pathHeight + r}z`;

        return path;
    }


    /**
     * Render svg path
     *
     * @param {object} item
     *
     * @returns jsx
     */
    renderVerticalPath(item) {
        const { filterCb, showProjectedValues }     = this.props,
            { colors, itemOverflew, selectedRange } = this.state,
            stackColorKey                           = this.getStackColorKey(item.type),
            fill                                    = `url("#${this.id}${stackColorKey}-gradient")`,
            fillOver                                = `url("#${this.id}${stackColorKey}-over-gradient")`,
            fillSelected                            = `url("#${this.id}${stackColorKey}-selected-gradient")`,
            {
                id, opacity, enabled,
                projected, selected
            }                                 = item,
            unselected = selectedRange && !selected,
            fillOpacity = unselected
                ? 0.8
                : (itemOverflew === id || !projected ? 1 : opacity * (enabled ? 1 : 0.6)),
            path                              = this.getVerticalPath(item);

        return (
            <path
                d={path}
                onClick={this.onItemClick(item)}
                onMouseOver={this.onItemMouseOver(id)}
                onMouseOut={this.onItemMouseOver()}
                style={{
                    fill: itemOverflew === id
                        ? fillOver
                        : selected ? fillSelected : fill,
                    cursor     : filterCb ? 'pointer' : null,
                    fillOpacity,
                    strokeWidth: enabled
                        ? 0.25
                        : (showProjectedValues ? 0.5 : 0.25),
                    stroke         : showProjectedValues && projected ? colors.borderBar : '#00000040',
                    strokeDasharray: showProjectedValues && projected ? '5,5' : null,
                }}
            />
        );
    }

    /**
    * Render a vertical graph
    *
    * @return html
    */
    renderVerticalGraph(plotData) {
        const {
                showProjectedValues, projectedTitle, interactive,
                tooltipValue, showFlag, unconsolidatedTitle
            }                = this.props,
            { itemOverflew } = this.state,
            { yOffset }      = this.getOffsets();

        return plotData.map((item, index) => {
            const { id, value, enabled }  = item,
                informationUnconsolidated = enabled
                    ? ''
                    : unconsolidatedTitle;

            return !!value && (
                <Popover
                    key={`${id}-${index}`}
                    content={(
                        <div className="tooltip-value">
                            <div
                                className="sentence"
                                dangerouslySetInnerHTML={{ __html: tooltipValue ? item.valueSentence : '' }}
                            />
                            <div className="information">
                                {item.projected && showProjectedValues ? projectedTitle : informationUnconsolidated}
                            </div>
                        </div>
                    )}
                    trigger={interactive ? 'hover' : 'contextMenu'}
                >
                    <g
                        className="plotData"
                        key={this.getItemKey(item)}
                        transform={`translate( 0 ${yOffset})`}
                        style={{
                            strokeWidth: itemOverflew === item.id ? 1 : 0,
                            opacity    : showFlag ? item.opacity : 1
                        }}
                    >
                        {this.renderVerticalPath(item)}
                        {this.renderVerticalFlag(item)}
                    </g>
                </Popover>
            );
        });
    }

    /**
    * Render flag
    *
    * @return html
    */
    renderVerticalFlag(d) {
        const { height }    = this.getPlotSize(),
            { showFlag }  = this.props,
            fill          = `url("#${this.id}-gradient")`;

        return !showFlag ? null : (
            <g>
                <circle
                    cx={d.location}
                    cy={height - d.size - (d.bandwidth / 2)}
                    r={d.bandwidth / 2}
                    style={{ fill }}
                />
                <Flag
                    SVG
                    discShaped
                    options={{disabledTitle: true}}
                    iso={d.id}
                    width={d.step}
                    translate={[d.location, height - d.size - (d.bandwidth / 2)]}
                    borderColor="#ffffff"
                    borderSize={1}
                    onClick={d.clickable ? this.onItemClick(d) : null}
                    onMouseOver={this.onItemMouseOver(d.id)}
                    onMouseOut={this.onItemMouseOver()}
                />
            </g>
        );
    }


    /**
     * Get path for horizontal bar
     *
     * @param {object} item
     */
    getHorizontalPath(item) {
        const {
                roundCornerFactor,
                showLogo, showFlag
            }       = this.props,
            y       = item.location - item.bandwidth / 2,
            x       = 1 + item.stackOffset,
            height  = item.bandwidth,
            width   = item.size - ((showLogo || showFlag)? item.bandwidth / 2 : 0),
            r       = item.lastStack ? _.min([Math.round(item.bandwidth * roundCornerFactor), width]) * 2 : 0;

        let path = `M${x} ${y + r} `;
        path += `M ${x} ${y} `;
        path += `H ${x + width - r} `;
        path += `Q ${x + width} ${y} ${x + width} ${y + r} `;
        path += `v ${height - r * 2} `;
        path += `Q ${x + width} ${y + height} ${x + width - r} ${y + height} `;
        path += `h ${-width + r} `;
        path += `v ${-height}z`;

        return path;
    }


    /**
     * Render path for horizontal bars
     *
     * @param {object} item
     */
    renderHorizontalPath(item) {
        const { filterCb }   = this.props,
            { itemOverflew } = this.state,
            stackColorKey    = this.getStackColorKey(item.type),
            fill             = `url("#${this.id}${stackColorKey}-gradient")`,
            fillOver         = `url("#${this.id}${stackColorKey}-over-gradient")`,
            fillSelected     = `url("#${this.id}${stackColorKey}-selected-gradient")`,
            path             = this.getHorizontalPath(item);

        return (
            <path
                d={path}
                onClick={item.clickable ? this.onItemClick(item) : null}
                onMouseOver={this.onItemMouseOver(item.id)}
                onMouseOut={this.onItemMouseOver()}
                style={{
                    fill: itemOverflew === item.id
                        ? fillOver
                        : item.selected ? fillSelected : fill,
                    cursor     : filterCb ? 'pointer' : null,
                    fillOpacity: itemOverflew === item.id ? 1 : item.opacity * (item.enabled ? 1 : 0.6),
                    strokeWidth: 0.25,
                    stroke     : '#00000040',
                }}
            />
        );
    }

    /**
    * Render a horizontal graph
    *
    * @return html
    */
    renderHorizontalGraph(plotData) {
        const {
                interactive, tooltipValue
            }                = this.props,
            { colors }       = this.state,
            { itemOverflew } = this.state,
            { xOffset }      = this.getOffsets();

        return plotData.map((item) => {
            const itemId = _.get(item, 'entity.id') || item.id;

            return (
                <Popover
                    key={`popover-${item.id}`}
                    content={(
                        <div className="tooltip-value">
                            <div
                                className="sentence"
                                dangerouslySetInnerHTML={{ __html: tooltipValue ? item.valueSentence : '' }}
                            />
                            <div className="information" />
                        </div>
                    )}
                    placement="left"
                    trigger={interactive ? 'hover' : 'contextMenu'}
                >
                    <g
                        className={`plotData ${item.clickable ? 'clickable' : ''}`}
                        key={this.getItemKey(item)}
                        transform={`translate(${xOffset} 0)`}
                        style={{
                            strokeWidth  : itemOverflew === itemId ? 1 : 0,
                            strokeOpacity: 0.2,
                            stroke       : colors.text,
                        }}
                    >
                        {this.renderHorizontalPath(item)}
                        {this.renderHorizontalFlag(item)}
                        {this.renderHorizontalLogo(item)}
                    </g>
                </Popover>
            );
        });
    }

    /**
    * Render horizontal flag
    *
    * @return html
    */
    renderHorizontalFlag(d) {
        const { showFlag } = this.props,
            fill           = `url("#${this.id}-gradient")`;

        return !showFlag ? null : (
            <g style={{ opacity: d.opacity }}>
                <circle
                    cx={d.size - d.bandwidth / 2}
                    cy={d.location}
                    r={d.bandwidth / 2}
                    style={{ fill }}
                />
                <Flag
                    SVG
                    discShaped
                    options={{disabledTitle: true}}
                    iso={d.id}
                    width={d.step}
                    translate={[d.size - (d.bandwidth / 2), d.location]}
                    borderColor="#ffffff"
                    borderSize={1}
                />
            </g>
        );
    }

    /**
    * Render horizontal Logo
    *
    * @return html
    */
    renderHorizontalLogo(d) {
        const { showLogo } = this.props,
            entityId       = _.get(d, 'entity.id'),
            fill           = `url("#${this.id}-over-gradient")`;

        return !showLogo ? null : (
            <g
                onClick={d.clickable ? this.onItemClick(d) : null}
                onMouseOver={this.onItemMouseOver(entityId)}
                onFocus={this.onItemMouseOver(entityId)}
                onMouseOut={this.onItemMouseOver()}
                onBlur={this.onItemMouseOver()}

                style={{ strokeWidth: 0, opacity: d.opacity }}
            >
                <circle
                    cx={d.size - d.bandwidth / 2}
                    cy={d.location}
                    r={d.bandwidth / 2}
                    style={{ fill }}
                />
                <Icon
                    SVG
                    discShaped
                    id="orgunit"
                    folder="/entities/"
                    orgunitLogo={entityId}
                    width={d.bandwidth}
                    ratio={1.25}
                    translate={[d.size - (d.bandwidth / 2), d.location]}
                    borderColor="#ffffff"
                    borderSize={1}
                    title={d.label}
                />

            </g>
        );
    }

    /**
    * Render the top stats
    *
    * @return html
    */
    renderTopStats() {
        const { width, margin }  = this.props,
            {
                innerHeight, colors,
                scales, stats,
            }            = this.state,
            { yScale }   = scales,
            paddingOuter = yScale.paddingOuter() * yScale.step(),
            fontSizeBase = Math.sqrt(innerHeight * width) / 16.5;

        if (_.isUndefined(stats.score)) { return null; }

        return (
            <div
                key="top-stats"
                className="stats"
                style={{
                    width     : width / 4,
                    top       : `${margin + paddingOuter * 2}px`,
                    left      : `${margin / 2}px`,
                    color     : colors.text,
                    fontSize  : `${fontSizeBase}px`,
                    lineHeight: `${fontSizeBase * 1.2}px`
                }}
            >
                <div style={{ fontSize: fontSizeBase * 1.4, lineHeight: `${fontSizeBase * 1.6}px` }}>
                    Top 5
                </div>
                <div>
                    portfolio equals
                </div>
                <div style={{ fontSize: fontSizeBase * 1.6, lineHeight: `${fontSizeBase * 1.8}px` }}>
                    {Math.round(stats.score * 100)}
                    <span style={{ fontSize: fontSizeBase }}>
                        {' %'}
                    </span>
                </div>
                <div>
                    of the top 100 portfolio
                </div>
            </div>
        );
    }

    /**
    * Render the Trend Information
    *
    * @return html
    */
    renderTrendInformation() {
        const {
                data,
                showTrendInformation,
                trendHeight,
                rateFontSize,
                fontSize,
                width,
                margin
            } = this.props,
            {
                selectedRange,
                selectedPixelRange,
                colors,
            } = this.state;

        return showTrendInformation && width && trendHeight && (
            <TrendInformation
                key="trend-information"
                height={trendHeight}
                width={width}
                margin={margin}
                data={data}
                fontSize={fontSize}
                rateFontSize={rateFontSize}
                selectedRange={selectedRange}
                selectedPixelRange={selectedPixelRange}
                color={colors.text}
            />
        );
    }

    /**
    * Render the mthe graph
    *
    * @return html
    */
    renderSvgElements(plotData) {
        const { showBorderLabels, horizontal } = this.props,
            { hasLandmark }                    = this.state;

        return (
            <g
                className="bars"
                key="svg-elements"
            >
                {horizontal ? this.renderHorizontalGraph(plotData) : this.renderVerticalGraph(plotData)}
                {!hasLandmark && !horizontal && showBorderLabels ? this.renderBorderLabels(plotData) : null}
            </g>
        );
    }


    /**
     * Render watermark
     */
    renderBackgroundImage() {
        const { watermark } = this.props,
            {
                trendOffset,
                flatSerie, scales,
            }                     = this.state,
            { xOffset, yOffset }  = this.getOffsets(),
            { height }            = this.getPlotSize(),
            { xScale }            = scales,
            stepWidth             = xScale && !_.isUndefined(xScale.step) ? xScale.step() : false,
            imageWidth            = stepWidth ? flatSerie.length * stepWidth : 0;

        return watermark && watermarks[watermark]
            ? (
                <div
                    key="watermark"
                    className="background"
                    style={{
                        width    : imageWidth,
                        height,
                        textAlign: 'left',
                        top      : `${trendOffset + yOffset}px`,
                        left     : `${xOffset}px`,
                    }}
                >
                    <img
                        className="watermark"
                        src={watermarks[watermark]}
                        height={height}
                        alt=""
                    />
                </div>
            ) : null;
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderGraph(plotData) {   // eslint-disable-line max-lines-per-function
        const {
                width, height, showInnerLabels, showTopStats, horizontal,
                onContextMenu, showLogo,
            }                         = this.props,
            { colors, trendOffset}    = this.state,
            {
                landmarkWidth,
                width: maxLandmarkWidth,
            }                         = this.getPlotSize(),
            maxWidth                  = showLogo ? maxLandmarkWidth : landmarkWidth,
            { xOffset, rightPadding } = this.getOffsets(),
            svgWidth                  = Math.round(
                showTopStats
                    ? width
                    : _.min([width, maxWidth + xOffset + rightPadding])
            ),
            orientationClass          = horizontal ? ' horizontal ' : ' vertical ',
            verticalOffset            = trendOffset,
            horizontalOffset          = (showTopStats ? width / 4 : 0),
            translate                 = `translate( ${horizontalOffset}px, ${verticalOffset}px )`,
            style                     = { height };

        return (
            <div
                className={`Bar ${orientationClass}`}
                ref={this.containerRef}
                style={style}
            >
                {this.renderBackgroundImage()}
                {showTopStats ? this.renderTopStats() : null}
                <svg
                    key="svg"
                    width={svgWidth}
                    height={height}
                    onContextMenu={onContextMenu}
                    style={{ background: colors.background, transform: translate}}
                    ref={this.graphRef}
                >
                    {this.renderLandmark()}
                    {this.renderDefs()}
                    {this.renderSvgElements(plotData)}
                </svg>
                { /* Labels */ }
                {horizontal
                    ? showInnerLabels ? this.renderHorizontalLabels(plotData, svgWidth) : _.noop()
                    : this.renderVerticalLabels(plotData)}
                {horizontal ? this.renderHorizontalValues(plotData, svgWidth) : this.renderVerticalValues(plotData, svgWidth)}
                {this.renderTrendInformation()}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                skeleton, skeletonGradient,
                noContent, contentChange,
                color, horizontal
            }                = this.props,
            {
                plotData,
                innerWidth,
            }                = this.state,
            { yOffset }      = this.getOffsets(),
            orientationClass = horizontal ? ' horizontal ' : ' vertical ',
            skeletonStyle    = { backgroundImage: skeletonGradient };

        // If no data, render the skeleton
        if (skeleton) { return (<div className={`Bar skeleton ${orientationClass}`} style={skeletonStyle} />); }

        // Landmark is need to calculate the plot area (landmarkCoords)
        if (!plotData) {
            return this.renderLandmark();
        }

        return plotData && plotData.length === 0
            ? (<NoData color={color} />)
            : (
                <Animation
                    data={plotData}
                    render={this.renderGraph}
                    scenario={Bar.getScenario({ innerWidth, yOffset })}
                    scene={noContent ? 'loading' : (contentChange ? 'contentChange' : 'general')}
                    didUpdateCb={this.componentDidUpdate}
                />
            );
    }

}

Bar.propTypes = {
    background          : PropTypes.string,
    color               : PropTypes.string,
    content             : PropTypes.arrayOf(PropTypes.object),
    contentChange       : PropTypes.any,
    data                : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    filterCb            : PropTypes.func,
    filterType          : PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
    flat                : PropTypes.bool,
    fontSize            : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    gradientByBlock     : PropTypes.bool,
    height              : PropTypes.number.isRequired,
    horizontal          : PropTypes.bool,
    innerPadding        : PropTypes.number,
    interactive         : PropTypes.bool,
    fontSizeAuto        : PropTypes.bool,
    labelBackground     : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    luminance           : PropTypes.number,
    luminanceBottom     : PropTypes.number,
    margin              : PropTypes.number,
    maxBarWidth         : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    minBarWidth         : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    noContent           : PropTypes.any,
    noModelSentence     : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onContextMenu       : PropTypes.func.isRequired,
    onMouseDown         : PropTypes.func.isRequired,
    onMouseMove         : PropTypes.func.isRequired,
    onMouseUp           : PropTypes.func.isRequired,
    openModal           : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    outerMargin         : PropTypes.number,
    projectedBaseLength : PropTypes.number,
    projectedMin        : PropTypes.number,
    projectedTitle      : PropTypes.string,
    unconsolidatedTitle : PropTypes.string,
    rateFontSize        : PropTypes.number,
    roundCornerFactor   : PropTypes.number,
    showBorderLabels    : PropTypes.bool,
    showFlag            : PropTypes.bool,
    showInnerLabels     : PropTypes.bool,
    showLandmark        : PropTypes.bool,
    showLogo            : PropTypes.bool,
    showProjectedValues : PropTypes.bool,
    showStack           : PropTypes.bool,
    showTopStats        : PropTypes.bool,
    showTrendInformation: PropTypes.bool,
    showValue           : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    skeleton            : PropTypes.bool,
    skeletonGradient    : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    stacksColors        : PropTypes.any,
    stepNumber          : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    title               : PropTypes.string,
    tooltipValue        : PropTypes.bool,
    trendHeight         : PropTypes.number,
    watermark           : PropTypes.string,
    width               : PropTypes.number.isRequired,
    xLabel              : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    yLabel              : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    filterValues        : PropTypes.oneOfType([PropTypes.shape({end: PropTypes.any, start: PropTypes.any}), PropTypes.array]),
    isCapture           : PropTypes.bool,
};

Bar.defaultProps = {
    title               : '',
    margin              : 0,
    fontSize            : 8,
    rateFontSize        : 14,
    color               : 'var(--insight-color)',
    background          : 'none',
    showLandmark        : false,
    showFlag            : false,
    showLogo            : false,
    tooltipValue        : true,
    flat                : false,
    horizontal          : false,
    showTopStats        : false,
    skeleton            : false,
    skeletonGradient    : false,
    xLabel              : false,
    yLabel              : false,
    showBorderLabels    : false,
    showStack           : true,
    showTrendInformation: false,
    trendHeight         : 50,
    luminance           : 0.52,
    luminanceBottom     : 0.69,
    labelBackground     : false,
    innerPadding        : 0.1,
    fontSizeAuto        : false,
    outerMargin         : 0.0,
    stepNumber          : false,
    stepSliceOrigin     : 'start',
    maxBarWidth         : false,
    minBarWidth         : false,
    roundCornerFactor   : 0.05,
    showProjectedValues : false,
    projectedBaseLength : 3,
    projectedMin        : 0,
    projectedTitle      : '',
    unconsolidatedTitle : '',
    openModal           : null,
    interactive         : true,
    noModelSentence     : false,
    showInnerLabels     : false,
    gradientByBlock     : true,
    watermark           : null,
    isCapture           : false,
    showValue           : true,
};

export default Bar;

