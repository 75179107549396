import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import ImmutablePropTypes       from 'react-immutable-proptypes';
import { connect }              from 'react-redux';
import {
    Row, Col,
    Element, Highlight
}                               from 'helpers';
import { learn }                from 'store/actions/knowledge';

import DocumentHeader           from './DocumentHeader';

import Infobox                  from '../utils/Infobox';
import Actors                   from '../utils/Actors';

import './assets/scidoc.less';

/**
 * Display a Publication
 */
class Scidoc extends Component {

    /**
    * Constructor : init an empty state
    */
    constructor(props) {
        super(props);

        this.state = {
            publicationTypes: null,
            elements        : [],
        };
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge([
            'entities', 'publication-types', 'nova-languages',
            'nova-sources', 'domain-tags', 'publishings', 'elements'
        ]).then(this.setState.bind(this));
    }


    /**
    * Get joined labels of a knowledge from ids
    *
    * @return string
    */
    getLabelsOfKnowledge(knowledgeName, ids, separator = ', ') {
        const { [knowledgeName]: values } = this.state,
            idAttibuteName = knowledgeName === 'domainTags' ? 'domainId' : 'id';

        return values ? values
            .filter((value) => (ids ? ids.indexOf(value[idAttibuteName]) !== -1 : false))
            .map((value) => value.label)
            .join(separator)
            : '';
    }


    /**
     * Get journal reference data
     *
     * @returns object
     */
    getJournalReference() {
        const { entity }           = this.props,
            { bibliographic_data } = entity,
            {
                journalName, journalNameAbbrev,
                year, volume, number, publisherName
            }                    = bibliographic_data || {},
            journalReferenceArr  = [
                journalNameAbbrev || journalName,
                publisherName,
                year,
                volume && `vol: ${volume}`,
                number && `number: ${number}`,
            ].filter(value => value),
            titleValues = bibliographic_data && {
                journalName: {
                    label: 'Journal Name:',
                    value: journalName || journalNameAbbrev,
                },
                publisherName: {
                    label: 'Publisher Name:',
                    value: publisherName,
                },
                year: {
                    label: 'Year:',
                    value: year,
                },
                volume: {
                    label: 'Volume:',
                    value: volume,
                },
                number: {
                    label: 'Number:',
                    value: number,
                },
            };

        return {
            label: 'Journal Reference:',
            value: journalReferenceArr.join(', '),
            titleValues
        };
    }


    /**
    * Render the Infobox
    *
    * @return JSX
    */
    renderInfobox() {
        const { entity }         = this.props,
            { publicationTypes } = this.state,
            {
                subtype, pub_publish,
                date_publication, source, lang, url
            }                    = entity,
            journalReference     = this.getJournalReference(),
            elements             = [
                {
                    label: 'Type :',
                    value: `
                        ${this.getLabelsOfKnowledge('publicationTypes', [subtype])}
                        /
                        ${this.getLabelsOfKnowledge('publishings', [pub_publish])}
                    `
                },
                journalReference,
                { label: 'Publication date:', value: (new Date(date_publication).getFullYear()) },
                { label: 'Base:', value: this.getLabelsOfKnowledge('novaSources', [source]) },
                { label: 'Language:', value: this.getLabelsOfKnowledge('novaLanguages', [lang]) },
                { label: 'Full text link:', value: url, isUrl: true }
            ];

        if (!publicationTypes) {
            return null;
        }

        return (
            <Infobox elements={elements} className="entity-information" />
        );
    }

    /**
    * Render the domains.
    *
    * @return JSX
    */
    renderDomains() {
        const { entity } = this.props,
            allDomains = this.getLabelsOfKnowledge('domainTags', entity.pub_domain, ' | ');

        if (!allDomains) {
            return null;
        }

        return (
            <Col label="Domains" className="domains textual">
                {allDomains}
            </Col>
        );
    }

    /**
    * Render the references
    *
    * @return JSX
    */
    renderReferences() {
        const { entity, onClick, registerCallbacks } = this.props,
            {
                elements
            }           = this.state,
            element     = elements.find((el) => el.category === 'Area/Entity/References');

        return element ? (
            <Col className="rel">
                <Element
                    element={element}
                    model={entity}
                    onClick={onClick}
                    registerCallbacks={registerCallbacks}
                />
            </Col>
        ) : null;
    }

    /**
    * Produce the proper display of the document content
    *
    * @return JSX
    */
    render() { // eslint-disable-line  max-lines-per-function
        const {
                entity, onClick,
                registerCallbacks, entities, bookmarks,
            }                        = this.props,
            { abstract_description } = entity || {};

        if (!entity) {
            return null;
        }

        return (
            <Col className="document scidoc sheet">
                <DocumentHeader entity={entity} entities={entities}
                    bookmarks={bookmarks}
                />
                <Row className="document-content">
                    <Col ratio="2/10" className="sheet-col">
                        <Row className="rel">
                            {this.renderInfobox()}
                        </Row>
                        <Row className="other-info">
                            <Actors
                                entity={entity}
                                onClick={onClick}
                                registerCallbacks={registerCallbacks}
                            />
                        </Row>
                    </Col>
                    <Col ratio="5/10" className="sheet-col">
                        <Row>
                            <Col label="Abstract"
                                className={`abstract textual ${!abstract_description ? 'no-abstract' : ''}`}
                            >
                                <Highlight>
                                    {abstract_description || 'Sorry, no abstract available for this document'}
                                </Highlight>
                            </Col>
                        </Row>
                        <Row>
                            {entity.keywords && (
                                <Col label="Keywords" className="keywords textual">
                                    <Highlight>
                                        {entity.keywords}
                                    </Highlight>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col ratio="3/10" className="sheet-col">
                        {this.renderDomains()}
                        {this.renderReferences()}
                    </Col>
                </Row>
            </Col>
        );
    }

}

Scidoc.propTypes ={
    entity           : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    entities         : ImmutablePropTypes.list,
    learnKnowledge   : PropTypes.func.isRequired,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    bookmarks        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

Scidoc.defaultProps = {
    entity: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Scidoc);

