// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.families table {
  width: 63%;
}
.families table tr.dead {
  font-style: oblique;
}
.families table tr.dead td {
  color: #90A4AE;
}
.families table tr td {
  vertical-align: middle;
  color: #455A64;
  padding: 3px 0;
  line-height: 13px;
  font-size: 11px;
}
.families table tr:hover {
  background: #f1f1f1;
}
.families .flag .icon {
  vertical-align: top;
  margin-right: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Element/Area/Entity/assets/family.less"],"names":[],"mappings":"AACI;EAKQ,UAAA;AAJZ;AAQgB;EACI,mBAAA;AANpB;AAKgB;EAES,cAAA;AAJzB;AAPI;EAegB,sBAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;AALpB;AAQgB;EACI,mBAAA;AANpB;AAjBI;EA6BQ,mBAAA;EACA,iBAAA;AATZ","sourcesContent":["\n    .families {\n        @primary-text-color: #455A64;\n        @secondary-text-color: #90A4AE;\n\n        table {\n            width: 63%;\n\n            tr {\n\n                &.dead {\n                    font-style: oblique;\n                    td { color: @secondary-text-color; }\n                }\n\n                td {\n                    vertical-align: middle;\n                    color: @primary-text-color;\n                    padding: 3px 0;\n                    line-height: 13px;\n                    font-size: 11px;\n                }\n\n                &:hover{\n                    background: #f1f1f1;\n                }\n\n            }\n        }\n        .flag .icon {\n            vertical-align: top;\n            margin-right: 3px;\n        }\n    }\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
