/**
 * SEARCH Actions Creator: create actions to fetch search, etc..
 *
 */
import React                      from 'react';
import _, {
    chunk, flatten, isObject, omitBy, keyBy, clone, cloneDeep, last, keys, isArray
}                                 from 'lodash';
import Immutable                  from 'immutable';
import * as types                 from './types/navigation';
import * as typesSocket           from './types/sockets';
import { dataGet, get, dataPost } from 'utils/api';
import { requestTimeout }         from 'utils/requestTimeout';
import * as utilObject            from 'utils/object';
import { isNumeric }              from 'utils/text';
import { learn }                  from './knowledge';
import notification               from 'helpers/notification.js';
import {
    getDeepSubBookmarkFolders, getBookmarkFolder,
    getRootFolder
}                                 from 'store/actions/userView/bookmarksFolders';
import {
    getModelIdentityFromKey,
    modelsAreLoaded
}                                 from 'store/actions/userView';
import {
    getNodePreferences,
    storeNodePreferences,
}                                 from './navigation/nodesPreferences';

let timeoutRefreshQuery      = null;
const WEAKMAP_TAGS_BY_FOLDER = new WeakMap();

/**
* Toggle shortcut
*
* @return void
*/
export const activateShortcut = (shortcutId, options) => (dispatch) => {
    dispatch({ type: types.ACTIVATE_SHORTCUT, payload: { shortcutId, options } });
};

/**
* Refresh the current history list
*
* @return void
*/
export const refreshLastsSearchesList = () => (dispatch, getState) => {
    const state       = getState(),
        member        = state.getIn(['auth', 'member']);

    get(`/member/${member.get('id')}/lasts-searches-ids`,  { data: {
        'limit[min]': 0,
        'limit[max]': 150
    }}).then(({ body }) => {
        const chunks = chunk(body, 150);

        const fetchPromises = chunks.map(
            chunk => dataGet('/query/', { data: { ids: chunk } })
        );

        Promise.all(fetchPromises).then((responses) => {
            const searches = flatten(responses.map(response => response.body));
            dispatch({ type: types.LASTS_SEARCHES_UPDATED, payload: searches });
        });
    });
};

/**
* Prepend the provided search as the top last search
*
* @return void
*/
export const updateLastSearchesWithModel = (search) => (dispatch, getState) => {
    const state              = getState(),
        lastsSearches        = state.getIn(['navigation', 'lasts_searches'])
            || new Immutable.List(),
        newLastSearches = lastsSearches.map(lastsSearch => {
            return lastsSearch.id !== search.id ? lastsSearch : search;
        });

    // Then update store
    dispatch({ type: types.LASTS_SEARCHES_UPDATED, payload: newLastSearches.toJS() });
};

/**
 * Get last search
 *
 * @returns List
 */
export const getUniqueLastSearch = () => (dispatch, getState) => {
    const state              = getState(),
        lastsSearches        = state.getIn(['navigation', 'lasts_searches'])
            || new Immutable.List(),
        uniqueLastSearches =  _.uniqBy(
            lastsSearches.toJS(),
            search => {
                if (!search) {
                    return '';
                }

                return JSON.stringify(search.entity.entity.concept)
                    + JSON.stringify(search.entity.entity.settings.bookmarkFolderForTags)
                    + JSON.stringify(search.entity.entity.settings.dateFilter)
                    + JSON.stringify(search.entity.entity.settings.countryFilter);
            }
        );

    return uniqueLastSearches;
};


/**
* Open a new search from its id
*
* @param string queryId The query id to open
*
* @return void
*/
export const navigateToSearch = (query) => (dispatch, getState) => {
    const state            = getState(),
        currentTree        = state.getIn(['navigation', 'tree']),
        { entity }         = query,
        { entity: search } = entity,
        { id }             = search,
        settings           = state.getIn(['auth', 'member', 'settings']),
        lastUsedDashboard  = settings && settings.search && settings.search.last_used_dashboard
            ? settings.search.last_used_dashboard
            : 'technology-landscape';

    // Tree already exists, we are gonna replace the entire navigation.
    if (currentTree && currentTree.length) {
        dispatch({
            type   : types.INIT,
            payload: [
                {
                    model     : search,
                    collection: null,
                    nodes     : [],
                    modules   : [lastUsedDashboard]
                }
            ]
        });
        return;
    }

    // Navigate from outside of "Browser"
    browse(`/b/${id}/${lastUsedDashboard}`, () => {}, search)(dispatch, getState);
};

/**
* Create a new search from concept/criptic & settings
*
* @return {Promise}
*/
export const query = ({ id, concept, cryptic, settings, mode, cb = null }, navigate = true) => async (dispatch, getState) => {
    const { bookmarkFolderForTags } = settings,
        tagsSettings                = await compileTagsSettings({ folderId: bookmarkFolderForTags })(dispatch, getState),
        search                      = { id, concept, cryptic, settings, mode };

    // Store refreshed search in slice with a timestamp
    if (id) {
        dispatch({
            type   : types.SEARCH_REFRESHED,
            payload: search,
        });
    }

    // Emit socket event
    dispatch({
        type   : typesSocket.WEB_SOCKETS_EMIT_EVENT,
        payload: {
            module: 'search',
            name  : 'new',
            data  : search
        }
    });

    // Perform a new search
    dataPost('/query', {
        mode,
        cryptic,
        concept : mode === 'classic'  ? concept : concept.map(JSON.stringify),
        settings: {
            ...settings,
            ...tagsSettings
        },
        originalid: id,
    }).then(
        (data) => {
            const search = data.status && data.status.code === 200 && data.body
                ? data.body : false;

            if (!search || !search.id) {
                return;
            }

            // Refresh the searchbar
            refreshLastsSearchesList()(dispatch, getState);

            // Trigger a callback
            if (cb) {
                cb(search);
                return;
            }

            // Open new search
            if (navigate) {
                navigateToSearch(search)(dispatch, getState);
            }
        }
    );
};

/**
* Create a submitable parameters array
*
* @return object
*/
const getParametersToSubmit = (query) => {
    const { mode } = query || {};

    if (mode === 'smart') {
        return {
            ...query,
            concept: getConceptsToSubmit(query.concept),
        };
    }

    return query;
};

/**
* Get concepts to submit
*
* return array
*/
const getConceptsToSubmit = (inputs) =>  inputs.map((input) => {
    const {
            type, source, operator, customs, suggestions
        } = input,
        isConcept                                          = type === 'concept';

    return {
        type,
        operator,
        source     : isConcept ? source : source.id,
        customs    : customs.map((custom) => (isConcept ? custom : custom.id)),
        suggestions: suggestions.map((suggestion) => (isConcept ? suggestion : suggestion.id))
    };
});

/**
* Refresh the query with new settings
*
* @return {Promise}
*/
export const refreshQuery = (parametersOrCb = null) => (dispatch, getState) => {
    const state                = getState(),
        tree                   = state.getIn(['navigation', 'tree']),
        parametersSearch       = isObject(parametersOrCb) && parametersOrCb.query,
        { entity }             = parametersSearch || {},
        searchToRefresh        = getParametersToSubmit(entity || getSearch(getState())),
        refreshedSearches      = state.getIn(['navigation', 'refreshedSearches']),
        searchInRefreshedState = refreshedSearches?.get(searchToRefresh.id),
        { modules }            = parametersOrCb || {},
        freshlyRefreshed       = searchInRefreshedState    // Search already refreshed after than 15 seconds old.
            && Date.now() - searchInRefreshedState.timestamp < 15000,
        /** After a new search is performed */
        onRelaunchQuery = (query) => {
            const { entity }            = query,
                { entity: search }      = entity || {},
                { id, originalId }      = search || {},
                newTree                 = _.cloneDeep(tree),
                originalIdAnalyseFilter = `${originalId}/${modules?.join('/')}`,
                path                    = id && `${id}/${modules?.join('/')}`,
                { filters, sorts }      = getNodePreferences(originalIdAnalyseFilter)(dispatch, getState);

            if (path) {
                storeNodePreferences(path, { filters, sorts })(dispatch, getState);
            }

            newTree[0].model = search;

            // Then update uri with the new search
            dispatch({ type: types.INIT, payload: newTree });
        };

    if (freshlyRefreshed) {
        // Cant call the cb => must have a query model not a search model !
        return;
    }


    if (timeoutRefreshQuery) {
        clearTimeout(timeoutRefreshQuery);
    }

    timeoutRefreshQuery = setTimeout(
        // Perform a new search! :)
        () => {
            notification({message: (
                <span> {/* eslint-disable-line react/jsx-filename-extension */}
                    Updating classifications...<br />
                    Now refreshing results.
                </span>
            )});
            query({  ...searchToRefresh, cb: onRelaunchQuery })(dispatch, getState);
        },
        1500
    );
};

/**
* Check if the search is outdated (settings have been modified)
*
* @return bool
*/
export const isSearchMustBeRefreshed = (query) => async (dispatch, getState) => {
    const state                          = getState(),
        search                           = query || getSearch(state),
        { entity, settings }             = search || {},
        { weeks, bookmarkFolderForTags } = settings || {},
        isFromNewsletter                 = !!weeks,
        bookmarksAreLoaded               = modelsAreLoaded('bookmark')(dispatch, getState),
        foldersAreLoaded                 = modelsAreLoaded('bookmark_folder')(dispatch, getState),
        currentSettings                  = await compileTagsSettings({ folderId: bookmarkFolderForTags })(dispatch, getState);

    if(
        isFromNewsletter        // Is from newsletters (with weeks settings)
        || !bookmarksAreLoaded
        || !foldersAreLoaded
    ) {
        return false;
    }

    const searchSettings  = settings
        || (entity && entity.settings)
        || {};

    // No parameters to compare
    if (!search || !currentSettings || !searchSettings) {
        return false;
    }


    const // Get tags of (search and actual) without empty categories
        searchTags        = omitBy(searchSettings.tags || {}, orgunitIds => orgunitIds.length === 0),
        currentTags       = omitBy(currentSettings.tags, orgunitIds => orgunitIds.length === 0),
        isOutdated        = !utilObject.deepEqual(searchTags, currentTags, false);

    return isOutdated;
};


/**
* Get tagged entities from a folder
*
* @return self
*/
export const getTaggedEntities = (options) => (dispatch, getState) => {  // eslint-disable-line max-lines-per-function
    const state   = getState(),
        folderId  = options.folderId || 'undefined';

    try {
        const bookmarksObj  = state && state.getIn(['userView', 'bookmark', 'map']) || {},
            tagged_entities = WEAKMAP_TAGS_BY_FOLDER.get(bookmarksObj) || {},
            taggedEntitiesFromWeak = tagged_entities && tagged_entities[folderId],
            folderUserViewItem = getBookmarkFolder(folderId)(dispatch, getState),
            folderAreLoaded      = modelsAreLoaded('bookmark_folder')(dispatch, getState),
            bookmarkAreLoaded    = modelsAreLoaded('bookmark')(dispatch, getState);

        // Check if tagged entities are being processed
        if (taggedEntitiesFromWeak === 'processing') {
            return new Promise((resolve) => {
                requestTimeout(() => {
                    const recursivePromise = getTaggedEntities(options)(dispatch, getState);
                    recursivePromise.then(resolve);
                }, 100);
            });
        }

        // Check if tagged entities exists in pool and resolve promise
        if(taggedEntitiesFromWeak) {
            return new Promise((resolve) => {
                resolve({tags: tagged_entities[folderId]});
            });
        }

        // Not ready
        if(!folderAreLoaded || !bookmarkAreLoaded) {
            return new Promise((resolve) => {
                resolve({tags: false});
            });
        }

        // Mark processing state
        WEAKMAP_TAGS_BY_FOLDER.set(bookmarksObj, {...tagged_entities, ...{[folderId]: 'processing'}});

        const
            rootFolder           = getRootFolder()(dispatch, getState),
            referenceFolderId    = folderAreLoaded
                ? (
                    folderUserViewItem
                        ? folderUserViewItem.model.id
                        : rootFolder.model.id // Folder is not accessible, get root folder
                )
                : null, // Folders are not loaded
            subFolders         = referenceFolderId && getDeepSubBookmarkFolders(referenceFolderId)(dispatch, getState),
            subFoldersIds      = subFolders && subFolders.map(bmf => bmf.model?.id),
            foldersIds         = referenceFolderId && [referenceFolderId].concat(subFoldersIds),
            foldersKeys        = foldersIds && _.map(foldersIds, id => `member/bookmark_folder/${id}`);

        return new Promise((resolve) => {  // eslint-disable-line max-lines-per-function
            learn(['tags'])(dispatch, getState).then(
                (book) => {
                    const bookmarks         = state.getIn(['userView', 'bookmark', 'map']),
                        { tags: allTags } = book,
                        tagsToReturn      = {};

                    // Add all tags categories
                    allTags.forEach(tag => {
                        tagsToReturn[tag.id] = [];
                    });

                    if (
                        !bookmarks
                        || !bookmarkAreLoaded
                        || !folderAreLoaded
                    ) {
                        return resolve({tags: false});
                    }

                    bookmarks.forEach((itemView)         => {
                        const bookmark                   = itemView.model,
                            parentsKeys                  = _.map(itemView.parent_attachments, parent => parent.key),
                            { disabled_tags_by_folders } = bookmark || {},
                            foldersInScope               =  _.intersection(foldersKeys, parentsKeys);

                        bookmark?.tags?.forEach((tagId) => {
                            if(
                                !bookmark.entity?.entity.id
                                // Bookmark is not in the folder of the query
                                || (foldersKeys && foldersInScope?.length === 0)
                            ) {
                                return;
                            }

                            // If no folders are in scope, we get all tags
                            const tagEnabledInAFolder = !foldersKeys
                                || foldersInScope.reduce(
                                    (isInAFolder, folderKey) => {
                                        const folderIdentity = getModelIdentityFromKey(folderKey);
                                        return isInAFolder
                                            || !disabled_tags_by_folders
                                            || !disabled_tags_by_folders[folderIdentity.id]?.includes(tagId);
                                    },
                                    false
                                );

                            if (tagEnabledInAFolder && bookmark.entity?.entity.id) {
                                tagsToReturn[tagId].push(bookmark);
                            }
                        });
                    });

                    // Merge WeakMap values and resolve promise
                    WEAKMAP_TAGS_BY_FOLDER.set(bookmarksObj, {...tagged_entities, ...{[folderId]: tagsToReturn}});
                    resolve({ tags: tagsToReturn });
                }
            );
        });
    } catch (error) {
        return new Promise((resolve) => {
            resolve({tags: false});
        });
    }
};

/**
 * Create the settings object from current application state
 *
 * @returns
 */
export const compileTagsSettings = (options) => async (dispatch, getState) => {
    const results = await getTaggedEntities(options)(dispatch, getState);
    return new Promise((resolve) => {
        if (!results.tags) {
            resolve(false);
        }
        results.tags = _.mapValues(results.tags, bookmarks => {
            return bookmarks.map(bookmark => bookmark.entity.entity.id);
        });
        resolve(results);
    });
};


/**
* Refresh the current search from its id
*
* @return {Promise}
*/
export const fetchSearch = (UUID) => (dispatch, getState) => dataGet(`/query/${UUID}`)
    .then(
        ({ body }) => {
            // Prepare actions (by design)
            const refreshLastSearches = refreshLastsSearchesList(),
                updateLastSearches    = updateLastSearchesWithModel(body);

            // Force update last search list for each new search trigger
            setTimeout(() => {
                updateLastSearches(dispatch, getState);
            }, 5000);
            setTimeout(() => {
                refreshLastSearches(dispatch, getState);
            }, 1000);

            // Then update then store
            dispatch({ type: types.SEARCH_FETCHED, payload: body });
        },
        () => false // Promise rejection
    );

/**
* Drill down to the searches orgunits collection
*
* @return void
*/
export const openOrgunitsCollection = (collection) => (dispatch, getState) => {
    // From collection
    if (collection) {
        return navigateToModel(collection[0], collection)(dispatch, getState);
    }

    const search       = getSearch(getState()),
        { concept }    = search,
        orgunitsInputs = concept.filter(input => input.type === 'orgunit'),
        orgunits       = flatten(orgunitsInputs.map(input => {
            return [input.source]
                .concat(input.customs)
                .concat(input.suggestions);
        }));

    if (!orgunits.length) {
        return;
    }

    openOrgunitsCollection(orgunits)(dispatch, getState);
};

/**
* Refresh the current search state
*
* @param mixed state The current state
*
* @return {Promise}
*/
export const getSearch = (state) => {
    const tree      = state.getIn(['navigation', 'tree']),
        firstInTree = tree ? tree[0] : false,
        { model }   = firstInTree || {};

    // No search or no search id should interupt the state update process
    if (!model || model.type !== 'query') { return false; }

    return model;
};

/**
* Refresh the current search state
*
* @return {Promise}
*/
export const refreshSearchState = (cb) => (dispatch, getState) => {
    const state = getState(),
        search  = getSearch(state);

    // No search or no search id should interupt the state update process
    if (!search) { return; }

    cb(
        dataGet(`/query/${search.id}/state`)
            .then(
                ({ body }) => dispatch({ type: types.SEARCH_STATE_FETCHED, payload: body }),
                () => false // Promise rejection
            )
    );
};

/**
* Clean the store, there's not anymore a search to display
*
* @return {Promise}
*/
export const forgetSearch = () => (dispatch) => {
    dispatch({
        type   : types.SEARCH_FORGOT,
        payload: null
    });
};

/**
* Return a boolean that indicates if the node is a Model
*
* @param string path The path to test
*
* @return bool
*/
const isAModel = (path) => path.match(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/)
    || path.match(/^~\*[a-z|A-Z|0-9]*$/);

/**
* Return a boolean that indicates if the node is a Model
*
* @param string path The path to test
*
* @return bool
*/
const isAModels = (path) => path.match(/^~\*[a-z|0-9]*\&~\*[a-z|0-9]*$/);

/**
* (Re-)Initialize navigation from pathname
*
* @param string pathname The navigation pathname
* @param func   cb       the callback triggered after browsing
*
* @return void
*/
export const browse = (pathname = '', cb = () => {}, model) => (dispatch) => {
    const splitedLocation = pathname.split('/'),
        flat              = splitedLocation.slice(2, splitedLocation.length),
        modelsIds         = flat.reduce((cumul, nodeString) => {
            nodeString.split('&').forEach(nodeStringSplitted => {
                if (isAModel(nodeStringSplitted)) {
                    cumul.push(nodeStringSplitted);
                }
            });
            return cumul;
        }, []);

    // TODO: rebase this code when model exists
    if(model) {
        const tree  = buildNodesFromArrayAndModels(flat, {[model.id]: model}),
            newTree = addUrlsParams(tree);

        // We aren't in the browser module
        if (!window.location.toString().match(/\#\/b\//)) {
            window.location = `/#${pathname}`;
        }
        dispatch({ type: types.INIT, payload: newTree });
        cb();
        return;
    }


    // Load models from APIS
    modelsIds?.length > 0 && dataGet('/entities', { data: { ids: modelsIds  } })
        .then(
            ({ body }) => {
                const models = keyBy(_.map(body, (model) => model.entity), 'id'),
                    tree     = buildNodesFromArrayAndModels(flat, models),
                    newTree  = addUrlsParams(tree);

                // We aren't in the browser module
                if (!window.location.toString().match(/\#\/b\//)) {
                    window.location = `/#${pathname}`;
                }

                // Then, init the global breadCrumbs
                dispatch({ type: types.INIT, payload: newTree });

                cb();
            }
        );
};


/**
 * Get deepestNode without nodes child
 *
 * @param {array} nodes
 */
const getDeepestNode = (nodes) => {
    const branch              = nodes.length > 0 && last(nodes),
        { nodes: childNodes } = branch || {};

    // Go deep
    if (childNodes.length > 0) {
        return getDeepestNode(childNodes);
    }

    return branch;
};

/**
 * Add urls params to the last module from window.location
 *
 * @param {array} tree
 * @returns
 */
const addUrlsParams = (tree) => {
    const currentUrlParams = getUrlsParams(),
        currentNode        = getDeepestNode(tree),
        {
            modules,
            urlsParams = {}
        }                  = currentNode,
        moduleName         = last(modules);

    // Store urls params by module
    urlsParams[moduleName] = _.mapValues(
        currentUrlParams,
        value => _.isArray(value) ? value.map(v => isNumeric(v) ? parseInt(v) : v) : value
    );

    currentNode.urlsParams = urlsParams;

    return tree;
};


/**
 * Get urls params from window.location
 *
 * @returns
 */
const getUrlsParams = () => {
    const { location }  = window,
        { href }        = location,
        hrefSplit       = href?.split('?'),
        search          = hrefSplit && hrefSplit.length > 0 && hrefSplit[1],
        urlSearchParams = new URLSearchParams(search), // TODO: Use custom url params to have array or not.
        urlParams       = {};

    for (const [key, value] of urlSearchParams) {
        // Convert value with ',' to array
        const isMultiValues = value.startsWith('[') && value.endsWith(']') && !urlParams[key];

        urlParams[key] = isMultiValues ? value.slice(1, -1).split(',') : value;
    }

    return urlParams;
};

/**
* Iterate to create nodes from a string array
*
* @param  array nodes  A string array
* @param  array models Preloaded Models from API
*
* @return array
*/
const buildNodesFromArrayAndModels = (nodes, models) => {
    const rehydratedNodes = [];

    // Rehydrate nodes with models, and create nodes
    nodes.forEach((node) => {
        // One model in node
        if (isAModel(node)) {
            rehydratedNodes.push({
                model     : models[node],
                collection: [],
                nodes     : [],
                modules   : []
            });
            return;
        }

        // Two models in node
        if (isAModels(node)) {
            const modelsIds = node.split('&');
            rehydratedNodes.push({
                model    : models[modelsIds[0]],
                withModel: models[modelsIds[1]],
                nodes    : [],
                modules  : []
            });
            return;
        }

        rehydratedNodes[rehydratedNodes.length - 1].modules.push(node);
    });

    // Inject each nodes into the previous node
    rehydratedNodes.reduce((acc, node) => {
        if (acc) { acc.nodes.push(node); }
        return node;
    });

    return [rehydratedNodes[0]]; // Then return the array only containing the root node
};

/**
* Update breadcrumbs
*
* @param string pathOrModel The path to add/switch
* @param array  collection  The collection witch provide the item
* @param int    from        The node source for the navigation
*
* @return void
*/
export const navigateTo = (pathOrModel, collection, from)  => (dispatch, getState) => {
    const isAModelObject  = isObject(pathOrModel) && pathOrModel.id;

    // The caller provided a model to navigate
    if (isAModelObject) {
        const isEntity = pathOrModel.type && pathOrModel.type === 'entity';
        navigateToModel(isEntity ? pathOrModel.entity : pathOrModel, collection, from)(dispatch, getState);
        return;
    }

    // The caller provided a path as string to update tree
    navigateToModule(pathOrModel, collection, from)(dispatch, getState);
};

/**
* Replace the entire tree
*
* @param array tree The new tree
*
* @return void
*/
export const refreshTree = (tree)  => (dispatch) => {
    dispatch({ type: types.INIT, payload: clone(tree) });
};


/**
 * Get query string from urlsParams of last node
 *
 * @returns string
 */
export const getQueryString = ()  => (dispatch, getState) => {
    const state        = getState(),
        tree           = state.getIn(['navigation', 'tree']),
        currentNode    = getDeepestNode(tree),
        {
            modules,
            urlsParams = {}
        }              = currentNode,
        moduleName     = last(modules),
        moduleParams   = urlsParams[moduleName],
        queryString    = keys(moduleParams).reduce(
            (accumulator, queryParam) => {
                const value     = moduleParams[queryParam],
                    stringValue = isArray(value) ? value.join(`&${queryParam}=`) : value;
                return `${accumulator}&${queryParam}=${stringValue}`;
            },
            ''
        ).replace('&', '?');

    return queryString;
};


/**
* Iterate to push the node in the deepest nodes array
*
* @param array tree The tree object to update
*
* @return array
*/
const pushInTheLastNodes = (tree, node) => {
    const lastNode = tree[tree.length - 1],
        { nodes }  = lastNode;

    // Set the nodes array with a new node.
    if (!nodes.length) {
        tree[tree.length - 1].nodes.push(node);
        return tree;
    }

    tree[tree.length - 1].nodes = pushInTheLastNodes(nodes, node);

    return tree;
};

/**
* Update the navigation tree from a model
*
* @param string model      The model to navigate
* @param array  collection The collection witch provide the item
* @param int    fromNode   A node source for the current navigation
*
* @return void
*/
const navigateToModel = (model, collection, fromNode)  => (dispatch, getState) => {
    const state                    = getState(),
        tree                       = state.getIn(['navigation', 'tree']),
        /**
         * Dispatch the new tree
         *
         * @param {array} tree The navigation tree
         */
        update                     = (tree) => dispatch({ type: types.INIT, payload: clone(tree) }),
        firstNode                  = tree ? tree[0] : null,
        { model:firstNodeModel  }  = firstNode || {},
        newNode                    = {
            model,
            collection,
            nodes  : [],
            modules: ['overview']
        };

    // Case1: There's no current tree?
    if (!firstNodeModel) {
        browse(`/b/${model.id}/overview`,                  // Init browser module
            () => update([newNode])                        // Then, programatically update the tree to inject collection, etc.
        )(dispatch, getState);
        return;
    }

    // Case2: The provided model is a new query
    if (model.type === 'query') {
        update([newNode]);
        refreshSearchState()(dispatch, getState);
        return;
    }

    // Case3: There's a node source for the navigation
    if (!_.isUndefined(fromNode)) {
        const newTree = pushInTheLastNodes(tree, newNode);
        update(newTree);                                   // Push the new node at the end, then dispatch the entire tree
        return;
    }

    // Case4: The first node mode is not a query
    if (firstNodeModel.type !== 'query') {
        tree.push(newNode);                                // Add the new node
        update(tree);                                      // Then dispatch the entire tree
        return;
    }

    // Case5: The first node is a query
    const newRoot = cloneDeep(tree[0]);                  // Clone the search node

    newRoot.disabled = true;                               // Disable the root by default (hide the search)
    newRoot.nodes   = [newNode];                           // Set the new node as child
    newRoot.modules = [tree[0].modules[0]];                // Preset the dashboard

    tree.push(newRoot);                                    // Create a new root for the global tree
    update(tree);                                          // Then dispatch the entire tree
};

/**
* Update the navigation tree from a string
*
* @param string model      The model to navigate
* @param int    from       The item string path The path to add/switch
* @param array  collection The collection witch provide the item
*
* @return void
*/
const navigateToModule = (module) => (dispatch, getState) => {
    const state           = getState(),
        tree            = state.getIn(['navigation', 'tree']),
        switchLast      = module.match(/\.\.\//),
        sanitizedModule = module.replace(/\.\.\//, ''),
        updatedTree     = updateModuleInDeepestNode(tree, sanitizedModule, switchLast);

    // Then, replace the full breadCrumbs
    dispatch({ type: types.INIT, payload: clone(updatedTree) });
};

/**
* Update the navigation tree from a string
*
* @param string model      The model to navigate
* @param int    from       The item string path The path to add/switch
* @param array  collection The collection witch provide the item
*
* @return void
*/
const updateModuleInDeepestNode = (nodes, module, switchLast)  => {
    const lastNode                    = nodes[nodes.length - 1],
        { modules, nodes:childNodes } = lastNode,
        lastNodeHasNodes              = childNodes.length;

    // Just, go, deeper.
    if (lastNodeHasNodes) {
        nodes[nodes.length - 1].nodes = updateModuleInDeepestNode(childNodes, module, switchLast);
        return nodes;
    }

    // Remove the last module
    if (switchLast) {
        modules.pop();
    }

    // Navigate to the new module
    modules.push(module);

    return nodes;
};

/**
* Navigate to the wanted index from the deepest breadcrumb
*
* @param integer node The node
*
* @return void
*/
export const drillTo = () => {

};

/**
* Active the previous entity in the current node collection
*
* @return {Promise}
*/
export const previous = () => (dispatch, getState) => {
    const state               = getState(),
        tree                  = state.getIn(['navigation', 'tree']);

    previousInDeepestNode(tree);

    // Then, replace the full breadCrumbs
    dispatch({ type: types.INIT, payload: clone(tree) });
};

/**
* Replace the model by the next one from collection
*
* @param array nodes       Windows nodes
*
* @return void
*/
const previousInDeepestNode = (nodes) => {
    const lastNode                              = nodes[nodes.length - 1],
        { nodes:childNodes, model, collection } = lastNode,
        lastNodeHasNodes                        = childNodes.length,
        currentEntityIndex                      = collection && collection.findIndex((entity) => entity.id === model.id);

    // Just, go, deeper.
    if (lastNodeHasNodes) {
        nodes[nodes.length - 1].nodes = previousInDeepestNode(childNodes);
        return nodes;
    }

    if (collection.length === 0 || currentEntityIndex === -1 || !collection[currentEntityIndex - 1]) {
        return;
    }

    // Navigate to the new node
    nodes[nodes.length - 1].model = collection[currentEntityIndex - 1];

    return nodes;
};

/**
* Replace the model by the next one from collection
*
* @param array nodes       Windows nodes
*
* @return void
*/
const nextInDeepestNode = (nodes) => {
    const lastNode                              = nodes[nodes.length - 1],
        { nodes:childNodes, model, collection } = lastNode,
        lastNodeHasNodes                        = childNodes.length,
        currentEntityIndex                      = collection && collection?.findIndex(entity => entity.id === model.id);

    // Just, go, deeper.
    if (lastNodeHasNodes) {
        nodes[nodes.length - 1].nodes = nextInDeepestNode(childNodes);
        return nodes;
    }

    if (collection.length === 0 || currentEntityIndex === -1 || !collection[currentEntityIndex + 1]) {
        return;
    }

    // Navigate to the new node
    nodes[nodes.length - 1].model = collection[currentEntityIndex + 1];

    return nodes;
};


/**
* Active the next entity in the current node collection
*
* @return {Promise}
*/
export const next = () => (dispatch, getState) => {
    const state = getState(),
        tree    = state.getIn(['navigation', 'tree']);

    nextInDeepestNode(tree);

    // Then, replace the full breadCrumbs
    dispatch({ type: types.INIT, payload: clone(tree) });
};


// Export default
export default {
    query,
    fetchSearch,
    refreshSearchState,
    forgetSearch,
    previous,
    next,
    activateShortcut,
    updateLastSearchesWithModel,
    isSearchMustBeRefreshed,
    getQueryString,
    getUniqueLastSearch,
};
