/* eslint-disable */
/**
 * Insight Home
 *
 * @return Component
 */
import _                                             from 'lodash';
import React, { Component }                          from 'react';
import { Helmet }                                    from 'react-helmet';
import { connect }                                   from 'react-redux';
import PropTypes                                     from 'prop-types';
import ImmutablePropTypes                            from 'react-immutable-proptypes';
import { List }                                      from 'immutable';
import { Skeleton }                                  from 'antd';
import {
    Icon,
    Collection, IButton,
    Row, Col
}                                                    from 'helpers';

import BookmarksRenderer                             from 'helpers/Renderer/Bookmarks';

import Input                                         from 'helpers/Search/Input';
import { dataGet }                                   from 'utils/api';

import {
    forgetSearch, activateShortcut, navigateTo,
    refreshLastsSearchesList, navigateToSearch,
    getUniqueLastSearch
}                                        from 'store/actions/navigation';

// Assets
import './Home/assets/home.less';

// Empty
import emptyLastSearches from './Home/assets/empty-last-searches.svg';
import emptyNews         from './Home/assets/empty-news.svg';
import emptyProfiles     from './Home/assets/empty-profiles.svg';

/**
 * Home module CC. This should be smart.
 *
 */
class Home extends Component {

    /**
    * Initialize the Home display
    */
    constructor(props) {
        super(props);
        const min = 1,
            max = 7;

        _.bindAll(this, 'renderOnBoarding', 'toggleAdvanced', 'refreshNews', 'onNewsletterButtonClick', 'onSearchClick');

        this.backNumber = Math.floor(Math.random() * (max - min)) + min;

        props.forgetSearch();

        this.state = {
            advanced          : false,
            newsInError       : false,
            news              : null,
            noOrgunitBookmarks: false,
        };
    }

    /**
    * Component did mount
    *
    * @return void
    */
    componentDidMount() {
        const { refreshLastsSearches } = this.props;
        refreshLastsSearches();
    }

    /**
    * On component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { forgetSearch, bookmarksList }   = this.props,
            { bookmarksList:prevBookmarksList } = prevProps,
            { news }                            = this.state,
            orgunitBookmarks                    = this.getOrgunitsBookmarks(bookmarksList),
            prevOrgunitBookmarks                = this.getOrgunitsBookmarks(prevBookmarksList) || [],
            firstRender                         = orgunitBookmarks && orgunitBookmarks.length > 0 && _.isNull(news),
            orgunitBookmarksUpdated             = orgunitBookmarks && orgunitBookmarks.length !== prevOrgunitBookmarks.length,
            noOrgunitBookmarks                  = orgunitBookmarks && orgunitBookmarks.length === 0 && _.isNull(news)

        if (firstRender || orgunitBookmarksUpdated) {
            this.refreshNews();
        }

        if (noOrgunitBookmarks) {
            this.setState({
                news              : [],
                noOrgunitBookmarks: true
            });
        }

        forgetSearch();
    }

    getOrgunitsBookmarks(bookmarksList) {
        const {
                bookmarksStats, bookmarksFolderStats
            }               = this.props,
            modelsAreLoaded = bookmarksStats?.get('modelsAreLoaded')
                && bookmarksFolderStats?.get('modelsAreLoaded');

        if (!bookmarksList || !modelsAreLoaded) {
            return false;
        }

        const models = bookmarksList.toJS().map(streamEntity => {
            return streamEntity.model;
        }).filter(
            model => !!model?.entity
            && model.entity.type === 'entity'
            &&  model.entity.entity.type === 'orgunit'
        );

        return models;
    }


    /**
    * On component did update
    *
    * @return void
    */
    componentWillUnmount() {
        // Stop news fetching
        this.fetchNewsPromise && this.fetchNewsPromise.cancel();
    }


    /**
     * On search click
     *
     * @param {object} entity
     */
    onSearchClick(entity) {
        const { navigateToSearch } = this.props;

        navigateToSearch(entity);
    }


    /**
    * Fetch news from server
    *
    * @return void
    */
    refreshNews() {
        this.setState({
            news: false
        });

        this.fetchNewsPromise = dataGet('/news', {});

        this.fetchNewsPromise.then(
            ({ body, status }) => {
                const { code } = status;

                if (code >= 500) {
                    return this.setState({ newsInError: true });
                }

                if (code === 204) {
                    return this.setState({ news: [] });
                }

                this.setState({ news: body || [] });
            }
        );
    }

    /**
    * Create then return the Onboarding insight array
    *
    * @return self
    */
    getOnBoardingInsights() {
        /* Not used for the knowledge base
        const { member }  = this.props,
            email         = member.get('email'),
            academyParams = `apicode=109835&apikey=OYIVOWWJCVB5D6R7UX&task=login&create=true&groupID=2202&oID=47&email=${email}`;
            */

        return [
            {
                id   : 'elearning',
                label: 'Knowledge base',
                icon : 'study',
                href : 'https://innovation.help.questel.com/en/support/home',
            },
            {
                id   : 'trainingVideo',
                label: 'Scheduled trainings',
                icon : 'movie',
                href : 'https://www.questel.com/training-innosabi-insight/',
            },
        ];
    }

    /**
    * On newsletter button click
    *
    * @param {DocumentEvent} e
    */
    onNewsletterButtonClick() {
        const { activateShortcut } = this.props;

        activateShortcut('newsletters');
    }

    /**
    * Toggle the advanced state
    *
    * @return void
    */
    toggleAdvanced() {
        const { advanced } = this.state;

        this.setState({
            advanced: !advanced
        });
    }


    /**
    * Render the first block of content
    *
    * @return JSX
    */
    renderNewsletters() {  // eslint-disable-line max-lines-per-function
        return (
            <Col
                ratio="1/2"
                label={(
                    <>
                        <Icon
                            type="newsletters"
                            folder="/shortcuts/"
                            color="#ffffff"
                            height={20}
                        />
                        <span className="title" title="Insight Feed">
                            Insight Feed
                        </span>
                    </>
                )}
                className="homepage-content newsletters"
            >
                <div className="newsletters-block">
                    <div className="logo-title">
                        <Icon id="insight" width={105} height={30} />
                        <span> - </span>
                        <span className="competitive-insights">
                            Insight Feed
                        </span>
                    </div>
                    <div className="subtitle">
                        Your weekly or monthly insight feed delivered in your mailbox
                    </div>
                    <Icon className="breaking-news" type="breaking-news" folder="/home/" width={320} height={200} />
                    <div className="slogan">
                        Get
                        <div className="slogan-big"><span>FRESH NEWS</span></div>
                        and
                        <div className="slogan-big">
                            <span>LATEST UPDATES</span>
                        </div>
                    </div>
                    <div className="slogan">
                        about
                        <div className="slogan-big">
                            <span>SEARCHES & COMPANIES</span>
                        </div>
                        that
                        <div className="slogan-big">
                            <span>MATTER FOR YOU</span>
                        </div>
                    </div>
                    <IButton
                        label="Give it a try"
                        fontSize={12}
                        onClick={this.onNewsletterButtonClick}
                    />
                </div>
            </Col>
        );
    }

    /**
    * Render the news blocks
    *
    * @return JSX
    */
    renderOnBoarding() {
        return (
            <Col
                ratio="1/2"
                label={(
                    <>
                        <Icon id="onboarding" height={20} folder="/home/" color="#ffffff" />
                        <span className="title" title="Onboarding">
                            Onboarding
                        </span>
                    </>
                )}
                className="homepage-content news"
            >
                <div className="on-boarding-block">
                    <table>
                        <tbody>
                            <tr>
                                {this.getOnBoardingInsights().map((insight) => (
                                    <td key={insight.id}>
                                        <div className="on-boarding-insight">
                                            <div className="on-boarding-content">
                                                <Icon type={insight.icon} folder="/home/" height={40} color="#FF6400" />
                                                <br />
                                                <div className="label">
                                                    {insight.label}
                                                </div>
                                                <br />
                                                <IButton
                                                    label="Give it a try"
                                                    href={insight.href}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    fontSize={12}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Col>
        );
    }

    /**
    * Render loading
    *
    * @return JSX
    */
    renderLoading() {
        return (
            <div className="is-loading">
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
                <Skeleton active avatar={{ size: 25 }} title={{ width: '100%' }} paragraph={{ rows: 0 }} />
            </div>
        );
    }

    /**
    * Render lasts searches
    *
    * @return JSX
    */
    renderLastSearches() {    // eslint-disable-line  max-lines-per-function
        return (
            <Col
                ratio="2/10"
                label={(
                    <>
                        <Icon folder="/shortcuts/" type="searches" color="#ffffff" height={20} />
                        <span className="title" title="Latest searches">
                            Latest searches
                        </span>
                    </>
                )}
                className="homepage-content last-searches"
            >
                {this.renderLastSearchesContent()}
            </Col>
        );
    }


    /**
     * Render the content of last searches
     *
     * @returns array of JSX
     */
    renderLastSearchesContent() {
        const { activateShortcut, getUniqueLastSearch,
                bookmarksStats, bookmarksFolderStats
            }                = this.props,
            modelsAreLoaded  = bookmarksStats?.get('modelsAreLoaded')
                && bookmarksFolderStats?.get('modelsAreLoaded'),
            uniqLastSearches = getUniqueLastSearch().slice(0, 6);

        return [
            // Loading
            !modelsAreLoaded && this.renderLoading(),
            // Empty
            modelsAreLoaded && uniqLastSearches && !uniqLastSearches.length && (
                <div className="empty">
                    <div className="baseline">
                        Here, find your latest searches
                        <br />
                        <img src={emptyLastSearches} alt="" />
                    </div>
                </div>
            ),
            // Last searches collection
            modelsAreLoaded && uniqLastSearches && uniqLastSearches.length > 0 && (
                <>
                    <div className="last-searches-block">
                        <Collection
                            allowExport={false}
                            mode="block"
                            entities={uniqLastSearches}
                            logoSize={18}
                            onClick={this.onSearchClick}
                        />
                    </div>
                    <div className="footer">
                        <IButton
                            label="Show more"
                            fontSize={12}
                            onClick={() => activateShortcut('searches')}
                        />
                    </div>
                </>
            )
        ]
    }


    /**
    * Render lasts bookmarks
    *
    * @return JSX
    */
    renderLastBookmarks() {
        const {
                activateShortcut, navigateTo,
                bookmarksList, bookmarksStats,
                bookmarksFolderStats,
                bookmarksFolderList
            }                    = this.props,
            streamEntities       = bookmarksList?.toJS(),
            orderedStream        = _.sortBy(streamEntities, ['date_create']).reverse(),
            maxBookmarks         = 6,
            lastStreamEntities   = orderedStream?.slice(0, maxBookmarks),
            bookmarksToRender    = lastStreamEntities && new List(lastStreamEntities),
            modelsAreLoaded      = bookmarksStats?.get('modelsAreLoaded') && bookmarksFolderStats?.get('modelsAreLoaded'),
            bookmarksInMigration = bookmarksFolderList && bookmarksFolderList.size === 0,
            haveBookmarks        = bookmarksToRender && bookmarksToRender.size > 0;


        return (
            <Col
                ratio="2/10"
                label={(
                    <>
                        <Icon
                            folder="/shortcuts/"
                            id="bookmarks"
                            color="#ffffff"
                            height={20}
                        />
                        <span className="title" title="Latest companies">
                            Latest bookmarks
                        </span>
                    </>
                )}
                className="homepage-content last-bookmarks"
                dataQaKey="last-bookmarks"
            >
                {!modelsAreLoaded && this.renderLoading()}
                {modelsAreLoaded && !haveBookmarks && !bookmarksInMigration && (
                    <div className="empty">
                        <div className="baseline">
                            Here, find your latest bookmarks
                            <br />
                            <img src={emptyProfiles} alt="" />
                        </div>
                    </div>
                )}
                {modelsAreLoaded && (haveBookmarks || bookmarksInMigration) && (
                    <>
                        <div className="last-bookmarks-block">
                            <BookmarksRenderer
                                navigateTo={navigateTo}
                                bookmarks={bookmarksToRender}
                                maxBookmarks={maxBookmarks}
                                hideBookmarksCount
                                flat
                            />
                        </div>
                        <div className="footer">
                            <IButton
                                label="Show more"
                                fontSize={12}
                                onClick={() => activateShortcut('bookmarks')}
                            />
                        </div>
                    </>
                )}
            </Col>
        );
    }

    /**
    * Render news
    *
    * @return JSX
    */
    renderNews() {  // eslint-disable-line max-lines-per-function
        const {activateShortcut} = this.props,
            {
                news, newsInError, noOrgunitBookmarks
            } = this.state;

        return (
            <Col className="homepage-content last-news" ratio="6/10"
                label={(
                    <>
                        <Icon type="rss" height={20} folder="/home/" color="#ffffff" />
                        <span className="title" title="Latest news">Latest news</span>
                    </>
                )}
            >
                <div className="last-news-block">
                    {(!news || !_.isArray(news)) && !newsInError && this.renderLoading()}
                    {newsInError && (
                        <div className="empty">
                            <div className="baseline">
                                Oops, news are unavailable for the moment.
                                <br />
                                We&lsquo;re working on a solution to get it back as soon as possible!
                                <br /><img src={emptyNews} alt="" /><br />
                            </div>
                        </div>
                    )}
                    {noOrgunitBookmarks && (
                        <div className="empty">
                            <div className="baseline">
                                <span>
                                    Here, get latest news from companies & organizations of interest
                                </span>
                                <br /><img src={emptyNews} alt="" /><br />
                                <IButton
                                    label="Start follow companies & organizations"
                                    className="open-companies"
                                    icon="play-circle"
                                    iconTheme="filled"
                                    onClick={() => activateShortcut('companies')}
                                    fontSize={16}
                                />
                            </div>
                        </div>
                    )}
                    {!newsInError && news && _.isArray(news) && news.length > 0 && (
                         <Collection
                            type="news_link"
                            allowExport={false}
                            mode="list"
                            entities={news}
                        />
                    )}
                    {!noOrgunitBookmarks && !newsInError && news && _.isArray(news) && news.length === 0 && (
                        <div className="empty">
                            <div className="baseline">
                                Sorry, No news found.
                                <br />
                                <img src={emptyNews} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        );
    }

    /**
    * Render the panels, adjusting if there is no orgunit
    *
    * @return JSX
    */
    renderPanels() {
        return (
            <Row className="panels bottom">
                {this.renderNewsletters()}
                {this.renderOnBoarding()}
            </Row>
        );
    }

    /**
    * Render last searches and news
    *
    * @return JSX
    */
    renderLastSearchAndNews() {
        return (
            <Row className="panels top">
                {this.renderLastSearches()}
                {this.renderLastBookmarks()}
                {this.renderNews()}
            </Row>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <>
                <Helmet>
                    <title>Insight</title>
                </Helmet>
                <Col id="homepage-container">
                    <Row className="searchbar-content rel">
                        <Col className="homepage-title">
                            <div className="baseline">
                                <span className="light-blue">Big picture thinking</span> to come up with<br />
                                <u>ideas</u>, <u>solutions</u> & <u>opportunities</u>
                            </div>
                            <Input placeholder="Type keywords to get comprehensive dashboards" />
                        </Col>
                    </Row>
                    <Row  {...this.props} className={`homepage back${this.backNumber} rel`}>
                        <Col>
                            {this.renderLastSearchAndNews()}
                            {this.renderPanels()}
                        </Col>
                    </Row>
                </Col>
            </>
        );
    }

}

Home.propTypes = {
    activateShortcut    : PropTypes.func,
    companies           : PropTypes.any,
    forgetSearch        : PropTypes.func,
    height              : PropTypes.number,
    lastSearches        : PropTypes.any,
    lastsSearches       : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    navigateTo          : PropTypes.func,
    navigateToSearch    : PropTypes.func,
    refreshLastsSearches: PropTypes.func,
    width               : PropTypes.number,
    bookmarksList       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarksStats      : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    bookmarksFolderStats: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    bookmarksFolderList : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    member              : PropTypes.shape({
        get: PropTypes.func,
    }),
};



Home.defaultProps = {
    lastsSearches: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    search              : state.getIn(['navigation', 'search', 'model']),
    lastsSearches       : state.getIn(['navigation', 'lasts_searches']),
    member              : state.getIn(['auth', 'member']),
    bookmarksList       : state.getIn(['userView', 'bookmark', 'list']),
    bookmarksFolderList : state.getIn(['userView', 'bookmark_folder', 'list']),
    bookmarksStats      : state.getIn(['userView', 'bookmark', 'stats']),
    bookmarksFolderStats: state.getIn(['userView', 'bookmark_folder', 'stats']),
});

/**
 * Bind the store to to component
 */
export default connect(mapStateToProps, {
    navigateTo,
    navigateToSearch,
    forgetSearch,
    activateShortcut,
    refreshLastsSearches: refreshLastsSearchesList,
    getUniqueLastSearch,
})(Home);



