import React, { Component }                     from 'react';
import _                                        from 'lodash';
import PropTypes                                from 'prop-types';
import ImmutablePropTypes                       from 'react-immutable-proptypes';
import { Skeleton }                             from 'antd';
import { Element, Row, Col }                    from 'helpers';
import { dataGet }                              from 'utils/api';
import { makeCancelable, makeCancelableAll }    from 'utils/promise';

import './assets/list.less';

/**
* Draw layouts with elements inside
*
*/
class DashboardList extends Component  {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'goToDataset', 'onDataLoaded');

        this.state = {
            loadedElements: {},
            datasetTotals : null,
        };
        this.cancelableDatasetPromises  = null;
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.fetchDatasetTotals();
    }

    /**
    * Store an unmounted bool to prevent callbaks on GC'ed components
    *
    * @return self
    */
    componentWillUnmount() {
        this.isUnmounted = true;
        this.cancelableDatasetPromises && this.cancelableDatasetPromises.cancel();
    }

    /**
    * When a section element has been loaded
    *
    * @param object element                     The layout which has been loaded
    * @param object data                        Data loaded by the layout
    * @param bool   allElementsHaveBeenDisabled Specify if all elements contained by layout has been disabled
    *
    * @return void  @type {[type]}
    */
    onDataLoaded(element, data) {
        const { loadedElements } = this.state;

        loadedElements[element.id] = data;
        this.setState({ loadedElements });
    }

    /**
    * Fetch dataset totals
    *
    * @return void
    */
    fetchDatasetTotals() {
        const {
                resources, model, getListResource, entities
            }                 = this.props,
            resource          = getListResource(),
            { models }        = resource,
            idq               = model.id,
            entitiesTypes     = _.map(models, 'type'),
            datasetEntities   = entities ? entities.filter((entity) => entitiesTypes.indexOf(entity.id) >= 0).toJS() : [],
            // Fetch dataset list collection to get the big total
            datasetPromises   = datasetEntities.map((entity) => {
                const datasetResourceId = _.isString(entity.dataset_resource)
                        ? entity.dataset_resource
                        : entity.dataset_resource.query,
                    datasetResource = resources.find((res) => res.id === datasetResourceId),
                    apiUri    = datasetResource ? `/queries/${idq}/${datasetResource.path}` : null;

                if (!apiUri) {
                    return null;
                }
                const promise = dataGet(apiUri, {data: { limit: { min: 0, max: 2 }}});
                return makeCancelable(
                    new Promise((resolve) => promise.then(
                        // Use dataget response to resolve Promise.all
                        (response) => {
                            resolve({
                                entity,
                                total: parseInt(response.headers ? response.headers.get('x-pagination-total') : 0, false)
                            });
                        },
                        // Error case
                        (/* { isCancelled } */) => {
                            resolve({ entity, total: false });
                            return false;
                        }
                    )),
                    () => {
                        promise.cancel();
                    }
                );
            });

        this.cancelableDatasetPromises = makeCancelableAll(datasetPromises);
        this.cancelableDatasetPromises.then((datasetTotals) => {
            if (!this.isUnmounted && datasetTotals.length > 0) {
                this.setState({ datasetTotals });
            }
        });
    }

    /**
    * Let's go to the dataset
    *
    * @return void
    */
    goToDataset() {
        const { navigateTo, resources, element } = this.props,
            resourceObj                          = resources.find((r) => r.id === element.resource.id),
            { models }                           = resourceObj || {},
            model                                = models ? models[0].type : null;

        if (model) {
            navigateTo(model);
        }
    }

    /**
    * Render the list Layout
    *
    * @return JSX
    */
    renderLayout() {
        const {
                element, elements, model, navigateTo,
                state, storeElementInstances
            }                 = this.props,
            { configuration } = element,
            elementToRender   = elements.find(element => element.id == configuration.elements);

        return (
            <Row>
                <Element
                    key={elementToRender.id}
                    model={model}
                    state={state}
                    color={configuration.color}
                    element={elementToRender}
                    className={configuration.color}
                    onDataLoaded={this.onDataLoaded}
                    navigateTo={navigateTo}
                    storeElementInstances={storeElementInstances}
                    oneClickOpen
                />
            </Row>
        );
    }

    /**
    * Render links to the datasets
    *
    * {/* totalWithoutFilters ? ` (${totalWithoutFilters} ${entity.label_plural})` : null}
    *
    * @return jsx
    */
    renderDatasetLink() {
        const { datasetTotals } = this.state;

        return (
            <div className="datasetLink">
                <span className="to-dataset">
                    {datasetTotals && (
                        <span>
                            View all:
                            {datasetTotals.map((datasetTotal) => {
                                const { entity, total } = datasetTotal,
                                    { id }              = entity;

                                return total > 0 ? [
                                    <span onClick={this.goToDataset} key={id}
                                        className="model" data-model={id}
                                    >
                                        &nbsp;
                                        {`${total} ${entity.label_plural.toLowerCase()}`}
                                    </span>,
                                    <span key={`separator-${id}`} className="separator">,</span>
                                ] : null;
                            })}
                        </span>
                    )}
                    {!datasetTotals && <Skeleton active paragraph={false} />}
                </span>
            </div>
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { element } = this.props,
            { loadedElements }           = this.state,
            { configuration }            = element,
            classNames                   = [
                'dashboard-list',
                'content-box',
                element.key,
                configuration.color
            ],
            listLoaded = _.keys(loadedElements).length > 0;

        return (
            <Col className={classNames.join(' ')}>
                <div className="header">
                    <h2>{element.label}</h2>
                    { listLoaded ? this.renderDatasetLink() : null}
                </div>
                {this.renderLayout()}
            </Col>
        );
    }

}

DashboardList.propTypes = {
    navigateTo           : PropTypes.func,
    resources            : ImmutablePropTypes.list,
    entities             : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    state                : PropTypes.any,
    storeElementInstances: PropTypes.func,
    getListResource      : PropTypes.func,
    model                : PropTypes.shape({
        id: PropTypes.string,
    }),
    element: PropTypes.shape({
        configuration: PropTypes.shape({
            color   : PropTypes.string,
            elements: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        }),
        id      : PropTypes.string,
        key     : PropTypes.string,
        label   : PropTypes.string,
        resource: PropTypes.shape({
            id: PropTypes.string
        })
    }).isRequired,
    elements: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

DashboardList.defaultProps = {
    model: false
};

export default DashboardList;

