import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    scalePoint          as d3ScalePoint,
    hsl                 as d3Hsl,
} from 'd3';

import { linkPath } from '../../../core/utils/svg';

import './TopCircle/main.less';

import Icon                 from '../../Icon';
import NoData               from '../../NoData';

// Import Sub Component
import Pie                  from './Pie';
import Gauge                from './Gauge';

// Pack Component
const ImportedComponents = {
    Pie,
    Gauge
};

const textPadding = 3;

/**
 * The top circle graph Component
 *
 */
class TopCircle extends Component {

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            { color } = nextProps,
            dc        = d3Hsl(color);

        state.colors    = {
            background: dc.brighter(2.6).toString(),
            subGraph  : dc.brighter(1.5).toString(),
            lines     : dc.brighter(2).toString(),
            circle    : color
        };

        return { nextProps, state };
    }

    /**
    * Update circles in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateCircles(options) {
        const { nextProps, state } = options,
            { data }               = nextProps,
            serie                  = _.get(data, 'content', []),
            { mainCircle }         = state,
            rigthPosition          = { cx: mainCircle.radius, cy: 0 },
            topLeftPosition        = { cx: -mainCircle.radius / 2, cy: -Math.sqrt(3 / 4) * mainCircle.radius },
            topRightPosition       = { cx: mainCircle.radius / 2, cy: -Math.sqrt(3 / 4) * mainCircle.radius },
            bottomLeftPosition     = { cx: -mainCircle.radius / 2, cy: Math.sqrt(3 / 4) * mainCircle.radius };

        state.graphHasData = false;

        state.circles = _.map(serie, (obj, index) => {
            const item = {
                label    : obj.label,
                type     : obj.type,
                id       : obj.id ? obj.id : false,
                component: obj.component ? obj.component : false,
            };
            let position = {};

            if (serie.length === 1) { position = rigthPosition; }
            if (serie.length === 2) { position = index === 0 ? topRightPosition : bottomLeftPosition; }
            if (serie.length === 3) {
                position = index === 0
                    ? topLeftPosition
                    : (index === 1 ? rigthPosition : bottomLeftPosition);
            }

            // Store boolean graphHasData (to display no data)
            state.graphHasData = true;

            return _.merge(item, position);
        });

        // Radius of the little cicrcles
        state.circleRadius = mainCircle.radius * 0.45;

        return { nextProps, state };
    }

    /**
    * Update labels in state
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static updateLabels(options) {
        const { nextProps, state } = options,
            {
                innerWidth,
                innerHeight,
                mainCircle,
                circles
            }           = state,
            { data, horizontalCenter, margin } = nextProps,
            serie                  = _.get(data, 'content', []),
            scale       = d3ScalePoint().range([0, innerHeight]).padding(innerHeight * 0.003).domain(_.range(serie.length)),
            left        = innerWidth * (horizontalCenter + 0.1) + margin,
            labelsWidth = innerWidth - mainCircle.cx;

        state.labels = _.map(circles, (d, i) => ({
            label: d.label,
            x    : d.cx + left + labelsWidth / 8,
            y    : scale(i) + margin
        }));

        return { nextProps, state };
    }

    /**
    * Update labels lines in state
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static updateLabelsLines(options) {
        const { nextProps, state } = options,
            {
                labels,
                mainCircle,
                circleRadius,
                circles,
            }            = state,
            graphRadius  = circleRadius * 1.2,
            { margin }   = nextProps;

        state.labelsLines = _.map(labels, (label, i) => linkPath({
            source: {
                x: mainCircle.cx + circles[i].cx + graphRadius,
                y: mainCircle.cy + circles[i].cy
            },
            target: { x: label.x,  y: label.y - margin }
        }));

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        _.bindAll(this, 'render');

        // Make a uuid
        this.id = _.uniqueId('top-circle-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps) {
        const {
                margin,
                circleSize,
                horizontalCenter
            } = nextProps,
            innerWidth   = nextProps.width - 2 * margin,
            innerHeight  = nextProps.height - 2 * margin,
            // Cascading function to update state
            updateState = _.flow([
                TopCircle.updateColors,      // All colors and gradient
                TopCircle.updateCircles,     // All circles with positions
                TopCircle.updateLabels,      // All labels with positions
                TopCircle.updateLabelsLines, // All labels with positions
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    mainCircle: {
                        cx    : innerWidth * horizontalCenter,
                        cy    : innerHeight / 2,
                        radius: _.min([innerWidth, innerHeight]) / 2 * circleSize
                    },
                    // Size of the chart (without landmark )
                    innerWidth,
                    innerHeight,
                }
            });

        return state;
    }

    /**
    * GetArcCorners
    *
    * @params options :
    *   arc          object D3 arc
    *   radius       number Distance of corners
    *   cornerRadius number Corner margin
    *   offset       object {x, y}
    *
    * @return object
    */
    static getArcCorners(options) {
        const {
                arc,
                radius,
                cornerRadius,
                offset
            } = options,
            midAngle = arc.startAngle + (arc.endAngle - arc.startAngle) / 2;

        return {
            start: {
                x: offset.x + 1 * cornerRadius * Math.cos(arc.startAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(arc.startAngle),
                y: offset.y + 1 * cornerRadius * Math.sin(arc.startAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(arc.startAngle)
            },
            middle: {
                x: offset.x - 1 * cornerRadius * Math.cos(midAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(midAngle),
                y: offset.y - 1 * cornerRadius * Math.sin(midAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(midAngle)
            },
            end: {
                x: offset.x + -1 * cornerRadius * Math.cos(arc.endAngle)
                    + Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.sin(arc.endAngle),
                y: offset.y + -1 * cornerRadius * Math.sin(arc.endAngle)
                    - Math.sqrt(radius * radius - cornerRadius * cornerRadius) * Math.cos(arc.endAngle)
            }
        };
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { circleRadius } = this.state;

        if (_.isUndefined(circleRadius)) { return null; }

        return (
            <defs>
                <mask id={`${this.uuid}-circleMask`} x="0"
                    y="0"
                >
                    <circle
                        cx="0"
                        cy="0"
                        r={circleRadius}
                        style={{ stroke: 'none', fill: '#ffffff' }}
                    />
                </mask>
            </defs>
        );
    }

    /**
    * Render top circle graph
    *
    * @return html
    */
    renderTopCircle() {
        const { colors, mainCircle } = this.state,
            { icon }                 = this.props;

        return (
            <g transform={`translate( ${mainCircle.cx} ${mainCircle.cy} )`}>
                <circle
                    r={mainCircle.radius * 0.85}
                    style={{
                        fill           : 'none',
                        strokeWidth    : 1,
                        stroke         : colors.circle,
                        strokeDasharray: '2,2'
                    }}
                />
                <Icon
                    SVG
                    id={icon}
                    width={mainCircle.radius * 0.8}
                    height={mainCircle.radius * 0.8}
                />

                {this.renderCircles()}
            </g>
        );
    }

    /**
    * Render top circle graph
    *
    * @return html
    */
    renderCircles() {
        const { colors, circles, circleRadius } = this.state;

        return _.map(circles, (circle) => {
            const {
                    label,
                    type,
                    id,
                    cx,
                    cy
                }   = circle,
                key = `${label} ${type} ${cx} ${cy}`;

            return (
                <g key={key} transform={`translate( ${cx} ${cy})`}>
                    <title>
                        {label}
                    </title>
                    <Icon
                        SVG
                        id={type || false}
                        folder="/entities/"
                        width={circleRadius  * 2}
                        height={circleRadius * 2}
                        discShaped
                        borderSize={2}
                        borderColor={colors.subGraph}
                        orgunitLogo={type === 'orgunit' ? id : null}
                    />
                    {this.renderSubComponent(circle)}
                </g>
            );
        });
    }

    /**
    * Render sub component
    *
    * @return html
    */
    renderSubComponent(circle) {
        const { colors, circleRadius } = this.state,
            SubComponent = circle.component ? ImportedComponents[circle.component.name] : false,
            graphRadius  = circleRadius * 1.2,
            subData      = circle.component ? circle.component.data : {},
            subSettings  = circle.component ? circle.component.settings : {};

        return SubComponent
            ? (
                <g transform={`translate( ${-graphRadius} ${-graphRadius} )`}>
                    <SubComponent
                        onlySvg
                        width={graphRadius * 2}
                        height={graphRadius * 2}
                        margin={0}
                        data={subData}
                        color={colors.subGraph}
                        {...subSettings}
                    />
                </g>
            )
            : null;
    }

    /**
    * Render labels lines
    *
    * @return html
    */
    renderLabelsLines() {
        const {
            labelsLines,
            colors
        } = this.state;

        return _.map(labelsLines, (d) => (
            <path
                key={d}
                d={d}
                style={{
                    stroke     : colors.lines,
                    fill       : 'none',
                    strokeWidth: 1.5,
                }}
            />
        ));
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels() {
        const {
                labels,
                colors,
                innerWidth
            } = this.state,
            { fontSize, margin } = this.props;

        return _.map(labels, (d) => (
            <div
                key={`${d.label}-${d.x}-${d.y}`}
                className="label"
                title={d.label}
                style={{
                    left : d.x + margin,
                    top  : d.y,
                    width: `${innerWidth - (d.x + margin * 3)}px`
                }}
            >
                <span
                    style={{
                        fontSize,
                        padding: `${textPadding}px`,
                        color  : colors.circle,
                    }}
                >
                    {d.label}
                </span>
            </div>
        ));
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width,
                height,
                margin,
                color,
                skeleton,
                skeletonColor
            } = this.props,
            { graphHasData  } = this.state,
            dc          = d3Hsl(color);

        // Bright color
        dc.l = 0.7;

        if (skeleton) { return (<div className={`TopCircle skeleton ${skeletonColor}`} />); }

        return !graphHasData
            ? (<NoData color={color} />)
            : (
                <div className="TopCircle">
                    <svg
                        width={width}
                        height={height}
                    >
                        {this.renderDefs()}
                        <g transform={`translate( ${margin} ${margin} )`}>
                            {this.renderLabelsLines()}
                            {this.renderTopCircle()}
                        </g>
                    </svg>
                    {this.renderLabels()}
                </div>
            );
    }

}

/**
 * Props type
 */
TopCircle.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    color           : PropTypes.string, // eslint-disable-line
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    margin          : PropTypes.number,
    circleSize      : PropTypes.number,
    horizontalCenter: PropTypes.number, // eslint-disable-line
    fontSize        : PropTypes.number, // eslint-disable-line
    skeleton        : PropTypes.bool,
    skeletonColor   : PropTypes.string,
    icon            : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

/**
 * Default props value
 */
TopCircle.defaultProps = {
    margin          : 10,
    horizontalCenter: 0.15,
    fontSize        : 16,
    circleSize      : 0.6,
    color           : 'var(--insight-color)',
    skeleton        : false,
    skeletonColor   : '',
    icon            : false,
};

export default TopCircle;

