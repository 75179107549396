/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Select }           from 'antd';

import './assets/operator.less';

const { Option } = Select,
    operatorList = ['AND', 'NOT'];

/**
* Render the Smart "Operator"
*
*/
class Operator extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'update');
    }

    /**
    * Update the operator
    *
    * @return void
    */
    update(operator) {
        const { update, data } = this.props;

        data.operator = operator;

        update(data);
    }

    /**
    * Render operator switcher
    *
    * @return html
    */
    render() {
        const { data, conceptsInputsCount } = this.props,
            filteredList                    = conceptsInputsCount > 1
                ? operatorList : operatorList.filter((operator) => operator !== 'NOT');

        return (
            <div className="operator">
                <Select disabled={data.type === 'orgunit'} defaultValue={data.operator}
                    onChange={this.update} className="button"
                >
                    {filteredList.map((operator) => <Option key={operator} value={operator}>{operator}</Option>)}
                </Select>
            </div>
        );
    }

}

Operator.propTypes = {
    conceptsInputsCount: PropTypes.number,
    update             : PropTypes.func.isRequired,
    data               : PropTypes.shape({
        operator: PropTypes.any,
        type    : PropTypes.string
    }).isRequired,
};

Operator.defaultProps = {
};

export default Operator;
