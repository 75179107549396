/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Icon }             from 'helpers';

import './assets/empty.less';

/**
 * Class Empty
 *
 */
class Empty extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Render the content
    *
    * @return html
    */
    render() {
        const { children, icon,
                textTop, iconHeight
            }         = this.props,
            textStyle = {};

        if (textStyle) {
            textStyle.marginTop = textTop;
        }

        return children && (
            <div className="no-items">
                <Icon className="big-icon" id={icon}
                    folder="/shortcuts/" height={iconHeight}
                    color="#BDBDBD"
                />
                <div className="text" style={textStyle}>
                    {children}
                </div>
            </div>
        );
    }

}

Empty.propTypes = {
    icon      : PropTypes.string.isRequired,
    textTop   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children  : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

Empty.defaultProps = {
    iconHeight: 150,
};

export default Empty;
