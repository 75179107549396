/**
 * Main (root) Reducer, will combine all reducers to one and only.
 *
 * @export rootReducer
 */
import { combineReducers } from 'redux-immutable';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import Immutable from 'immutable';
import _  from 'lodash';

import auth        from './auth';
import entityMap   from './entityMap';
import knowledge   from './knowledge';
import navigation  from './navigation';
import downloads   from './downloads';
import resource    from './resource';
import newsletters from './newsletters';
import sockets     from './sockets';
import userView    from './userView';
import insightBot  from './insightBot';
import yoomap      from './yoomap';

/**
 * Persistance rules
 */
const Transform = createTransform(
    /**
    * Rule to persist immutables
    *
    * @param {mixed} initialStates The state to save to local storage
    */
    (inboundState)  => (inboundState instanceof Immutable.Map ? inboundState.toJS() : inboundState),
    /**
    * Rule to hydrate immutables
    *
    * @param {mixed} outboundState The state to be rehydrated
    */
    (outboundState, key) => {
        if(key === 'library') {
            const immutableKnowledge = _.mapValues(outboundState, (value) => _.isArray(value) ? Immutable.List(value) : value);
            return new Immutable.Map(immutableKnowledge);
        }
        if(key === 'endpoints') {
            return Immutable.List(outboundState);
        }
        return outboundState;
    }
);

// The know persist configuration
const knowledgePersistConfig = {
    storage,
    key       : 'knowledge',
    transforms: [Transform]
};

// Combine reducers to a unique one
const rootReducer = combineReducers({
    auth,
    downloads,
    entityMap,
    navigation,
    resource,
    knowledge: persistReducer(knowledgePersistConfig, knowledge),
    newsletters,
    sockets,
    userView,
    insightBot,
    yoomap,
});

export default rootReducer;
