/**
* Round up a number by his magnitude
*
* @param {*} number
*
* @returns {number}
*/
export const roundUpByMagnitude = number => {
    const magnitude  = Math.floor(Math.log10(Math.abs(number))),
        powerOfTen   = Math.pow(10, magnitude - 1);

    return Math.ceil(number / powerOfTen) * powerOfTen;
};

export default {
    roundUpByMagnitude,
};
