// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Layout base style definitions
 *
 * @return Component
 */
#global-header:hover .quick-access .shortcuts {
  right: 0;
}
#global-header .quick-access .shortcuts {
  display: flex;
  gap: 4px;
}
#global-header .quick-access .shortcuts .hidden-content-view {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/core/Ui/Header/assets/quick-access.less"],"names":[],"mappings":"AAAA;;;;EAIE;AAEF;EAEQ,QAAA;AADR;AAKA;EAGY,aAAA;EACA,QAAA;AALZ;AACA;EAOgB,aAAA;AALhB","sourcesContent":["/**\n * Layout base style definitions\n *\n * @return Component\n */\n\n#global-header:hover {\n    .quick-access .shortcuts {\n        right: 0;\n    }\n}\n\n#global-header {\n    .quick-access {\n        .shortcuts {\n            display: flex;\n            gap    : 4px;\n\n            .hidden-content-view {\n                display: none;\n            }\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
