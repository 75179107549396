// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list .row.mixed .label {
  margin-right: 5px;
}
.list .row.mixed .ant-skeleton {
  width: 80%;
}
.list .row.mixed .related-to:hover .actions {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/mixed.less"],"names":[],"mappings":"AAAA;EAIQ,iBAAA;AAFR;AAFA;EAQQ,UAAA;AAHR;AAOQ;EAEQ,cAAA;AANhB","sourcesContent":[".list .row.mixed {\n\n\n    .label {\n        margin-right: 5px;\n    }\n\n    .ant-skeleton {\n        width: 80%;\n    }\n\n    .related-to {\n        &:hover {\n            .actions {\n                display: block;\n            }\n        }\n    }\n }\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
