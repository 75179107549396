/**
 * Display a report list
 *
 * @return Component
 */

import PropTypes              from 'prop-types';
import ImmutablePropTypes     from 'react-immutable-proptypes';
import _                      from 'lodash';
import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import { openReport,
    deleteReport,
    generateReport }          from 'store/actions/navigation/report';
import { resetDownloadsList } from 'store/actions/downloads';
import { Select }             from 'antd';
import { Icon, notification } from 'helpers';
import ShortcutViewItem       from './ShortcutViewItem';

// Import the stylesheet
import './Reports/assets/main.less';

/**
 * Compare two elements for sort
 *
 * @param { string } key
 * @param {string } order
 */
const compareValues=(key, order = 'asc') => {
    return function innerSort(a, b) {
        if (a===null || b ===null) {
            // Property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key],
            varB   = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        }
        if (varA < varB) {
            comparison = -1;
        }
        return ((order === 'desc') ? (comparison * -1) : comparison);
    };
};

/**
 * Class Reports
 *
 */
class Reports extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(
            this, 'onChangeInput', 'onChangeSelect', 'reportSearch',
            'renderList', 'renderEmptyList',
            'onOpen', 'onGenerate', 'onDelete', 'getActions'
        );

        this.state = {
            search: '',
            select: {key: 'date_create', order: 'desc'}
        };
    }

    /**
     * Input search change
     *
     * @param {*} event
     */
    onChangeInput(event) {
        this.setState({search: event.target.value});
    }

    /**
     * Select order change
     *
     * @param {*} event
     */
    onChangeSelect(value) {
        const select= (value==='date_create') ? {key: 'date_create', order: 'desc'}
            :{key: 'label', order: 'asc'};
        this.setState({select});
    }

    /**
     * Filter the report by search in the label name
     *
     * @param {array} reports
     */
    reportSearch(reports) {
        const { search }= this.state,
            searchLower = search.toLocaleLowerCase();
        if(searchLower.length>0) {
            return reports.filter(report => report.label.toLocaleLowerCase().indexOf(searchLower) >=0);
        }
        return reports;
    }


    /**
     * Trigger when you click on open report
     *
     * @param {object} report
     */
    onOpen(report) {
        const {openReport, close} = this.props,
            reportclone           =_.cloneDeep(report);

        reportclone.isOpen        = true;
        openReport(reportclone);
        close();
    }

    /**
    * Trigger on click on the generate button
    *
    * @param {object} report
    */
    onGenerate(report) {
        const { generateReport } = this.props;

        notification({
            message: 'Once generated, the report will be available in the download manager.'
        });

        generateReport(report.id);
        setTimeout(resetDownloadsList, 1500);
    }

    /**
    * Trigger on click on the generate button
    *
    * @param {object} report
    */
    onDelete(report) {
        const { deleteReport } = this.props;

        deleteReport(report.id);
    }

    /**
     *
     * @param {*} report
     * @returns
     */
    getActions(report) {
        const iconHeight = 16,
            iconWidth    = 14,
            slidesSelect = this.getSlidesSelect(report);

        return {
            open: {
                cb  : this.onOpen,
                icon: <Icon
                    height={iconHeight}
                    id="collection-open"
                    width={iconWidth}
                />,
                label  : 'Open',
                options: report,
            },
            generate: {
                disabled: slidesSelect === 0,
                cb      : this.onGenerate,
                icon    : <Icon
                    height={iconHeight}
                    id="export"
                    width={iconWidth}
                />,
                label  : 'Generate',
                options: report,
            },
            delete: {
                cb  : this.onDelete,
                icon: <Icon
                    height={iconHeight}
                    id="delete"
                    width={iconWidth}
                />,
                label  : 'Delete',
                options: report,
            }
        };
    }

    /**
    * Render report item
    *
    * @param {object} report
    * @returns JSX
    */
    renderElement(report) {
        return (
            <ShortcutViewItem
                key={report.id}
                model={report}
                getActions={this.getActions}
            />
        );
    }

    /**
    * Get number of active slides in a report
    *
    * @param {object} report
    * @returns {integer}
    */
    getSlidesSelect(report) {
        const { slides } = report;
        const groups = slides;

        let slidesSelect = 0;
        groups.forEach(group => {
            group.slides.forEach(slide => {
                if (slide.active !== false) {
                    slidesSelect++;
                }
            });
        });

        return slidesSelect;
    }

    /**
     * Render the report list
     *
     * @return JSX
     */
    renderList() {
        const { reports } = this.props,
            { select }    = this.state,
            reportArray   = reports ? Object.values(reports.toJS()) : [],
            reportSearch  = this.reportSearch(reportArray),
            reportCompare = reportSearch.sort(compareValues(select.key, select.order));

        return (
            <div className="report-list content">
                <div className="items-block-header header">
                    {this.renderHeader(reportCompare)}
                </div>

                <div className="items">
                    <div className="report-content">
                        {
                            reportCompare.map(
                                report => this.renderElement(report)
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Render the header (filter / select / count)
     *
     * @returns html
     */
    renderHeader(reportCompare) {
        const { search, select } = this.state,
            countReport          = reportCompare.length;
        return (
            <div>
                <input
                    className="search"
                    value={search}
                    onChange={this.onChangeInput}
                    placeholder="Search ..."
                />
                <div className="info">
                    <Select
                        className="report-select"
                        value={select.key}
                        onChange={this.onChangeSelect}
                        style={{ width: 'auto' }}
                        popupMatchSelectWidth={false}
                        placement="bottomLeft"
                        bordered={false}
                        suffixIcon={(
                            <Icon
                                width={10}
                                type="expand-arrow"
                            />
                        )}
                        options={[
                            { value: 'date_create',  label: 'Sort by creation date' },
                            { value: 'label',        label: 'Sort by alphabetic order'}
                        ]}
                    />
                    <div className="count">
                        {countReport} report
                        {countReport > 1 && 's'}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Return the view when there's no newsletter
     *
     * @return JSX
     */
    renderEmptyList() {
        return (
            <div className="report-empty">
                <div className="no-items">
                    <div className="icon" />
                    <div className="text">
                        Your report manager is empty
                    </div>
                </div>
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { reports } = this.props;

        if(reports?.size < 1) {
            return this.renderEmptyList();
        }

        return this.renderList();
    }

}

Reports.propTypes = {
    close         : PropTypes.func,
    reports       : ImmutablePropTypes.map,
    openReport    : PropTypes.func,
    deleteReport  : PropTypes.func,
    generateReport: PropTypes.func
};

Reports.defaultProps = {
    active: false,
};

/**
 * Map the state in navigation to props
 *
 * @param {object} state
 */
const mapStateToProps = (state) => {
    const navigation = state.get('navigation');

    return {
        reports: navigation.get('reports')
    };
};

/**
 * Bind the store to to component
 */

export default connect(mapStateToProps, {
    resetDownloadsList,
    openReport,
    deleteReport,
    generateReport
})(Reports);

