/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _            from 'lodash';
import React, {
    useState,
    useRef,
    useEffect,
}                   from 'react';
import PropTypes    from 'prop-types';
import Autocomplete from 'helpers/Search/Autocomplete';
import { Icon }     from 'helpers';
import { Popover }  from 'antd';

import './assets/add-custom.less';

/**
* @return JSX
*/
const AddCustom = ({ data, update }) => { // eslint-disable-line max-lines-per-function
    const [
            autocompleteCallbacks,
            setAutocompleteCallbacks,
        ]   = useState({}),
        ref = useRef();

    useEffect(() => {
        return () => {
            if (popoverIsOpened() && !_.isEmpty(data.customs)) {
                ref?.current?.click();

                setTimeout(() => {
                    if (!popoverIsOpened()) {
                        ref?.current?.click();
                    }
                }, 10);
            }
        };
    }, [data]);

    /**
    * Check if popover is open
    */
    const popoverIsOpened = () => {
        return _.includes(ref?.current?.className, 'ant-popover-open');
    };

    /**
    * On select a new custom tag
    *
    * @return void
    */
    const onSelect = entity => {
        data.customs.push(entity);

        // Update data object
        update(data);

        autocomplete(['reset', 'focus']);
    };

    /**
    * Trigger an action on autocomplete
    *
    * @param string action The callback to trigger
    *
    * @return void
    */
    const autocomplete = action => {
        const actions = _.isArray(action) ? action : [action];

        actions.forEach((actionToDo) => {
            if (autocompleteCallbacks[actionToDo]) {
                autocompleteCallbacks[actionToDo]();
            }
        });
    };

    /**
    * Register callbacks for autocomplete
    *
    * @return self
    */
    const registerAutocompleteCallbacks = (action, cb) => {
        autocompleteCallbacks[action] = cb;

        setAutocompleteCallbacks(autocompleteCallbacks);
    };

    /**
    * Render the tag input provided by the user
    *
    * @return JSX
    */
    const renderButton = () => {
        return (
            <span className="button" onClick={() => autocomplete('focus')}
                ref={ref}
            >
                <Icon type="plus" width={18} />
            </span>
        );
    };

    /**
    * Render the autocomplete
    *
    * @return JSX
    */
    const renderAutocomplete = () => {
        const placeholder = data.type === 'orgunit'
            ? 'Add a new organization'
            : 'Add a new concept';

        return (
            <Autocomplete
                placeholder={placeholder}
                types={[data.type]}
                onSelect={onSelect}
                registerCallbacks={registerAutocompleteCallbacks}
            />
        );
    };

    return (
        <div className="add-custom">
            <Popover content={renderAutocomplete()} trigger="click"
                onOpenChanged={autocomplete('focus')} overlayClassName="popover-add-custom"
                placement="bottomLeft" getPopupContainer={trigger => trigger.parentElement.parentElement.parentElement}
                destroyTooltipOnHide
            >
                {renderButton()}
            </Popover>
        </div>
    );
};


AddCustom.propTypes = {
    update: PropTypes.func.isRequired,
    data  : PropTypes.object.isRequired,
};

export default AddCustom;
