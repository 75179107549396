/**
* Store configuration
*
* @export rootReducer
*/
import { applyMiddleware, compose, createStore } from 'redux';
import thunk                                     from 'redux-thunk';
import { routerMiddleware, connectRouter }       from 'connected-react-router/immutable';
import * as Sentry                               from '@sentry/react';

import rootReducer                               from './reducers/root';
import { initialMap }                            from './initialStates';

/**
* Use the root reducer to configure store then, return it.
*
* @return store
*/
const configureStore = function configureStore(history) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

    return createStore(
        connectRouter(history)(rootReducer),
        initialMap,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                thunk,
            ),
            sentryReduxEnhancer
        ),
    );
};

// Export
export default configureStore;
