import _                                    from 'lodash';
import { notification as antdNotification } from 'antd';

const validType = ['success', 'info', 'warning', 'error'];

import './assets/notifications.less';

/**
 * Manage notifications
 *
 * @param {object} options
 */
const notification = (options) => {
    // No option
    if (!options) {
        return;
    }

    const {
            message,
            description,
            type,
            placement,
            duration,
            key,
            maxCount,
        }                     = options,
        notificationPlacement = placement || 'bottomLeft',
        // Allow duration = 0 (to keep indefinitely the notification)
        notificationDuration  = !_.isUndefined(duration) ? duration : 5,
        notificationType      = type && validType.indexOf(type) !== -1
            ? type
            : 'info';

    // Launch notification
    antdNotification[notificationType]({
        message,
        description,
        key,
        maxCount,
        placement: notificationPlacement,
        duration : notificationDuration,
    });
};

export default notification;
