// Import libs
import React,
{
    memo,
    useRef,
    useState,
    useEffect,
}                                   from 'react';
import { connect }                  from 'react-redux';
import PropTypes                    from 'prop-types';
import _                            from 'lodash';
import Entity                       from '../Entity';
import { Checkbox }                 from 'antd';
import { getUserViewItemFromModel } from 'store/actions/userView';
import VScroll                      from '../../scrollv-react-windows/FixedSizeList';
import { deepEqual }                from 'utils/object';
import { onElementResize }          from 'utils/dom';

// Import display CSS
import './assets/detailedList.less';

/**
* Detailed List: display a detailed list of entities
*/
const DetailedList = props => { // eslint-disable-line max-lines-per-function
    const ref                       = useRef(null),
        [listWidth, setListWidth]   = useState(0),
        [listHeight, setListHeight] = useState(0);

    useEffect(() => {
        /**
        * Set height
        */
        const updateWidth = ({ width }) => {
            setListWidth(width);
        };

        /** Set height
        */
        const updateHeight = ({ height }) => {
            setListHeight(height);
        };

        onElementResize(document.querySelector('#oi-root'), updateHeight);
        onElementResize(ref?.current, updateWidth);
    }, []);

    /**
    * Main render
    *
    * @returns {JSX}
    */
    const render = () => {
        const {
                entities, selectedEntities,
                toggleAllSelection, type,
                entitiesDef, getScrollSelectors
            }             = props,
            noneSelected  = selectedEntities.length === 0,
            allSelected   = !noneSelected && entities.length === selectedEntities.length,
            firstEntity   = entities && entities[0] || {},
            { isLoading } = firstEntity,
            countEntities = entities.length,
            modelEntity   = entitiesDef?.find((obj) => obj.id === type),
            labelTotal    = countEntities > 1 ? modelEntity?.label_plural : modelEntity?.label,
            itemSize      = listWidth > 895 ? 270 : 440,
            height        = _.min([listHeight - 81, countEntities * itemSize]),
            {
                contentSelector, scrollSelector
            }             = getScrollSelectors();

        return (
            <div className={`list loaded ${type}`}>
                <div className="select">
                    <Checkbox onClick={toggleAllSelection}
                        checked={!noneSelected && allSelected}
                        indeterminate={!noneSelected && !allSelected}
                        disabled={isLoading}
                    >
                        Selected {labelTotal}: {selectedEntities.length}
                    </Checkbox>
                </div>

                <div
                    className="detailed-list"
                    ref={ref}
                >
                    <VScroll
                        className="List"
                        height={height}
                        itemCount={countEntities}
                        itemSize={itemSize}
                        itemData={props}
                        scrollSelector={scrollSelector}
                        contentSelector={contentSelector}
                        bottomPadding={50}
                    >
                        {RenderEntity}
                    </VScroll>
                </div>
            </div>
        );
    };

    return render();
};

DetailedList.propTypes = {
    allowExport             : PropTypes.any,
    compiledTags            : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    entities                : PropTypes.arrayOf(PropTypes.object).isRequired,
    getUserViewItemFromModel: PropTypes.func,
    selectedEntities        : PropTypes.any,
    toggleAllSelection      : PropTypes.any,
    type                    : PropTypes.string.isRequired,
};

/**
* Bind the store to to component
*/
const mapStateToProps = state => {
    const entitiesDef = state.get('knowledge').library.get('entities');

    return {
        entitiesDef,
    };
};

/**
* Render the Entity component
*/
const RenderEntity = memo(({ data, style, index }) => {
    const {
            tags, color, model,
            type, onClick, allowExport,
            onSelectEntity, selectedEntities,
            context, disabledEntities, compiledTags, entities,
        }                  = data,
        entity             = entities[index],
        { label, id, url } = entity,
        key                = id || label || url || `skeleton ${index}`,
        disabled           = disabledEntities.includes(entity.id),
        isSelected         = selectedEntities.includes(entity);

    return (
        <div style={style}>
            <Entity
                key={key}
                tags={tags}
                color={color}
                render="detailedCard"
                entity={entity}
                entityType={type}
                onClick={onClick}
                allowExport={allowExport}
                onSelectEntity={onSelectEntity}
                isSelected={isSelected}
                disabled={disabled}
                sourceModel={model}
                context={context}
                compiledTags={compiledTags}
            />
        </div>
    );
}, (oldProps, newProps) => {
    const {
            entities        : oldEntities,
            selectedEntities: oldSelectedEntities,
            compiledTags    : oldCompiledTags,
        } = oldProps.data,
        {
            entities        : newEntities,
            selectedEntities: newSelectedEntities,
            compiledTags    : newCompiledTags,
        }          = newProps.data,
        oldDataEntities = oldEntities[oldProps.index],
        newDataEntities = newEntities[newProps.index];

    return deepEqual(oldDataEntities, newDataEntities)
        && deepEqual(oldProps.style, newProps.style)
        && deepEqual(oldCompiledTags, newCompiledTags)
        && _.xor(oldSelectedEntities, newSelectedEntities).length === 0;
});

RenderEntity.propTypes = {
    data : PropTypes.object,
    style: PropTypes.object,
    index: PropTypes.number,
};

export default connect(mapStateToProps, {
    getUserViewItemFromModel,
})(DetailedList);
