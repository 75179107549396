import _                    from 'lodash';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import { withRouter }       from 'react-router-dom';

import {
    browse,
    refreshTree,
    navigateTo,
    previous as previousAction,
    next     as nextAction,
    getQueryString,
}                from 'store/actions/navigation';
import { learn } from 'store/actions/knowledge';

// Import browser renderer
import Window from './Browser/Window';

// Import actions

/**
* Create breadcrumbs lines to render
*
* @return void
*/
const getTreeNodesToRender = (tree) => {
    const nodesToRender = [];

    // No tree, no nodes.
    if (!tree) {
        return false;
    }

    // Iterate over tree to create nodes
    tree.forEach((firstNode) => {
        const windowNode = [];

        firstNode.modules.forEach((module) => {
            windowNode.push({
                node: firstNode,
                module,
            });
        });

        getTreeNodesToRender(firstNode.nodes).forEach((subNode) => subNode.forEach((wNode) => windowNode.push(wNode)));
        nodesToRender.push(windowNode);
    });

    return nodesToRender;
};

/**
* Entity module, display entities from store or nav
*
*/
class Browser extends Component {

    /**
    * Initialize the Entity display
    */
    constructor(props) {
        const { path, tree } = props;

        super(props);

        _.bindAll(this, 'renderWindow', 'switchTo', 'navigateTo');

        this.state = {
            shouldUpdateFromUrl: true,
            tree               : null,
            current            : null,
            nodesToRender      : null,
            // If the tree exists, use the provided path to avoid
            // Refresh from "componentDidUpdate" vv
            currentPathname    : tree ? path : null,
            elements           : null
        };
    }

    /**
    * Get the nodes to render from the tree
    *
    * @param object nextProps Next properties
    */
    static getNodesToRender(nextProps) {
        const { tree: propsTree } = nextProps,
            nodesToRender         = getTreeNodesToRender(propsTree);

        return nodesToRender;
    }

    /**
    * Derive the props, to get the next state
    *
    * @param object nextProps Next properties
    * @param object prevState Previous state
    *
    * @return object
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { tree:propsTree } = nextProps,
            { tree:stateTree,
                nodesToRender
            }                    = prevState,
            sameTree             = stateTree === propsTree;

        return {
            ...prevState,
            tree               : propsTree,
            nodesToRender      : sameTree ? nodesToRender : Browser.getNodesToRender(nextProps),
            shouldUpdateFromUrl: sameTree
        };
    }

    /**
    * When component is ready, load the orgunit
    */
    componentDidMount() {
        const { learn } = this.props;

        // Learn the Knowledge
        learn(['elements', 'filters', 'entities', 'resources', 'tags'])
            .then(this.setState.bind(this));

        // Check the route the obtain the model
        this.updatePoolFromUrl();
    }

    /**
    * Component update
    */
    componentDidUpdate() {
        const { tree }              = this.props,
            { shouldUpdateFromUrl } = this.state;

        // Update from URL and prevent to update from tree to early
        if (
            shouldUpdateFromUrl && this.updatePoolFromUrl()
            || !tree
        ) {
            return;
        }

        this.updateUrlPathFromTree();
    }

    /**
    * Cut the tree over the specified node & module
    *
    * @param array  tree The tree to cut
    * @param object node The last node definition
    *
    */
    skimNodesFromModule(tree, breadcrumbsElement) {
        const newTree = [];

        const nodeHasBeenFound = false;

        tree.forEach((treeNode) => {
            const itsTheNode = treeNode === breadcrumbsElement.node,
                newModules   = [];

            let moduleHasBeenFound = false;

            if (nodeHasBeenFound) { return; }

            if (!itsTheNode) {
                treeNode.nodes = this.skimNodesFromModule(treeNode.nodes, breadcrumbsElement);
                newTree.push(treeNode);
                return;
            }

            // Reset current nodes values
            treeNode.nodes   = [];

            // Iterate over module to skim unwanted modules
            treeNode.modules.forEach((module) => {
                const itsTheModule = module === breadcrumbsElement.module;
                if (moduleHasBeenFound) { return; }

                if (itsTheModule) {
                    moduleHasBeenFound = true;
                }

                newModules.push(module);
            });

            treeNode.modules = newModules;
            newTree.push(treeNode);
        });

        return newTree;
    }

    /**
    * Update the url with the new actived tab index
    *
    * @param nodes the root nodes
    *
    * @return void
    */
    switchTo(index) {
        const { tree, refreshTree } = this.props;

        // No more tabs to return
        if (index === -1) {
            if (tree.length > 1) {
                tree.pop();
                refreshTree(tree);
                return;
            }

            document.location = '/#/';
            return;
        }

        const { nodesToRender }       = this.state,
            lastBreadcrumbNodes     = _.last(nodesToRender),
            nodeToSwitch            = lastBreadcrumbNodes[index] ? lastBreadcrumbNodes[index] : null,
            newTree                 = this.skimNodesFromModule(tree, nodeToSwitch);

        refreshTree(newTree);
    }

    /**
    * Navigate to tab
    *
    * @param string path       The path to add/switch
    * @param array  collection The collection witch provide the item
    * @param int    from       The item string path The path to add/switch
    *
    * @return void
    */
    navigateTo(path, collection,  from) {
        const { navigateTo } = this.props;

        navigateTo(path, collection, from);

        return false;
    }

    /**
    * Update url from tree
    *
    * return false
    */
    updateUrlPathFromTree() {
        const {
                history, tree, getQueryString
            }                   = this.props,
            { currentPathname } = this.state,
            root                = tree[tree.length - 1],
            {
                modules, nodes, model, withModel
            }             = root,
            modelsPath    = withModel ? '&' + withModel.id : '',
            modulesString = modules.join('/'),
            queryString   = getQueryString();

        if(!model) {
            return;
        }

        // Create the new path from the root
        let newPath = `/b/${model.id}${modelsPath}/${modulesString}`;

        // Do the same for each nodes
        nodes.forEach((node) => {
            newPath += this.getPathFromNode(node);
        });

        // Same path will not be updated
        if (newPath === currentPathname) {
            return;
        }

        // Update history & local state
        history.push(`${newPath}${queryString}`);

        this.setState({
            currentPathname: newPath
        });

        return false;
    }


    /**
     * Get path from a node in tree
     *
     * @returns string
     */
    getPathFromNode(node) {
        const {
                modules,
                model,
                withModel,
                nodes,
            }                 = node,
            nodeModulesString = modules.join('/'),
            // Mutate id with withModel
            id                = `${model.id}${withModel ? '&' + withModel.id : ''}`,
            newPath           = `/${id}/${nodeModulesString}`;

        // Iterate on sub nodes
        if (nodes.length > 0) {
            return newPath + this.getPathFromNode(nodes[0]);
        }

        return newPath;
    }

    /**
    * Update the #@ part in the URL
    *
    * return false
    */
    updatePoolFromUrl() {
        const { location, browse }                   = this.props,
            { currentPathname, shouldUpdateFromUrl } = this.state,
            { pathname }                             = location;

        // Navigation should be done from the store
        if (currentPathname === pathname) {
            return shouldUpdateFromUrl;
        }

        // Replace the trailing "/"
        if (pathname.match(/\/$/)) {
            const path = pathname.replace(/\/$/, '');
            window.location = `/#${path}`;
            return true;
        }

        // Keep the pathname for further usage
        this.setState({
            currentPathname: pathname
        });

        // Init a new navigation session
        requestAnimationFrame(() => browse(pathname));

        return true;
    }

    /**
    * Render a root from navigation tree
    *
    * @type {Object}
    */
    renderWindow(nodes, index) {
        const {
                tree, bookmarksList, next, previous
            }      = this.props,
            {
                elements, entities, resources, filters, tags
            }      = this.state,
            active = index === tree.length - 1;

        return (
            <Window
                key={index}
                entities={entities}
                elements={elements}
                nodes={nodes}
                active={active}
                filters={filters}
                resources={resources}
                bookmarks={bookmarksList}
                nextFromCollection={next}
                previousFromCollection={previous}
                switchTo={this.switchTo}
                navigateTo={this.navigateTo}
                tags={tags}
            />
        );
    }

    /**
    * Render the entity
    *
    * @return html
    */
    render() {
        const { tree } = this.props,
            { elements, entities, resources, nodesToRender } = this.state;

        // Wait for it
        if (!tree || !elements || !entities || !resources || !nodesToRender) {
            return false;
        }

        // Render the navigation tree
        return nodesToRender.map(this.renderWindow);
    }

}

Browser.propTypes = {
    bookmarksList : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    browse        : PropTypes.func,
    learn         : PropTypes.func,
    navigateTo    : PropTypes.func,
    next          : PropTypes.any,
    path          : PropTypes.any,
    previous      : PropTypes.any,
    refreshTree   : PropTypes.func,
    getQueryString: PropTypes.func,
    tree          : PropTypes.array,
    history       : PropTypes.object,
    location      : PropTypes.shape({
        pathname: PropTypes.string,
        search  : PropTypes.string
    }),
};

/**
 * Attach store props
 */
const mapStateToProps = (state) => ({
    tree         : state.get('navigation').get('tree'),
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
});

/**
 * Bind the store to to component
 */
export default connect(mapStateToProps, {
    // Knowledge
    learn,
    // Navigation
    browse,
    refreshTree,
    navigateTo,
    getQueryString,
    previous: previousAction,
    next    : nextAction,
})(withRouter(Browser));
