import _                      from 'lodash';
import React, { useRef }      from 'react';
import PropTypes              from 'prop-types';
import { Icon, notification } from 'helpers';
import { str2DomFormat }      from 'utils/text';

import '../assets/insight-bot.less';

/**
* @return {JSX}
*/
const InsightBotRow = ({ title, children, add, copy }) => { // eslint-disable-line  max-lines-per-function
    const dataRef = useRef();

    /**
    * Copy text to clipboard
    */
    const copyToClipboard = async () => {
        const text = dataRef?.current.innerText;

        try {
            await navigator.clipboard.writeText(text);

            notification({
                message : `${title} copied. Paste anywhere you like!`,
                type    : 'success',
                duration: 2,
            });
        } catch(error) {
            notification({
                message : 'Copy not working !',
                type    : 'error',
                duration: 2,
            });
        }
    };

    const throttleCopyToClipboard = _.throttle(copyToClipboard, 3000, { trailing: false });

    return (
        <div className="insight-bot-row">
            <div className="title">
                {title}
            </div>

            <div className="data" data-qa-key={title}
                ref={dataRef}
            >
                {children}
            </div>

            <div className="action">
                {add && (
                    <Icon id="plus" color="var(--secondary-color)"
                        width={18} onClick={add}
                        dataQaKey={str2DomFormat(`add ${title}`)}
                    />
                )}
                {copy && (
                    <Icon id="copy" color="var(--secondary-color)"
                        width={18} onClick={throttleCopyToClipboard}
                        dataQaKey={str2DomFormat(`copy ${title}`)}
                    />
                )}
            </div>
        </div>
    );
};

InsightBotRow.propTypes = {
    title   : PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    add     : PropTypes.func,
    copy    : PropTypes.bool,
};

InsightBotRow.defaultProps = {
    title: '',
    copy : true,
};

export default InsightBotRow;
