/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                                                from 'lodash';
import React, { Component }                             from 'react';
import { connect }                                      from 'react-redux';
import { withRouter }                                   from 'react-router-dom';
import PropTypes                                        from 'prop-types';
import ImmutablePropTypes                               from 'react-immutable-proptypes';
import { Collection }                                   from 'helpers';
import { refreshLastsSearchesList, navigateToSearch }   from 'store/actions/navigation';


import './assets/searches.less';


/**
 * Class Searches
 *
 */
class Searches extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'refreshAll', 'onSearchClick');

        this.state = {};
    }

    /**
    * When the component has been mount
    *
    * @return void
    */
    componentDidMount() {
        this.refreshHistory();
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate() {
    }


    /**
    * Refresh the all search list
    *
    * @return void
    */
    refreshAll() {
        this.refreshHistory();
    }


    /**
    * Refresh the current history list
    *
    * @return void
    */
    refreshHistory() {
        const { refreshLastsSearches } = this.props;

        refreshLastsSearches && refreshLastsSearches();
    }


    /**
     * On search click
     *
     * @param {object} entity
     */
    onSearchClick(entity) {
        const {
            navigateToSearch,
            close,
        } = this.props;

        navigateToSearch(entity);
        close && close();
    }

    /**
    * Render lasts searches
    *
    * @return JSX
    */
    render() {
        const { lastsSearches } = this.props,
            uniqLastSearches    = lastsSearches && _.uniqBy(
                lastsSearches.toJS(),
                search => {
                    const dataEntity    = search?.entity.entity,
                        { date_create } = search?.entity || {},
                        uniqKey      = dataEntity
                            ? dataEntity.genericQueryId + date_create.substr(0, 10)
                            : '';

                    return uniqKey;
                }
            );

        if (!uniqLastSearches) {
            return null;
        }

        return (
            <div className="content">
                {
                    uniqLastSearches.length > 0
                        ? (
                            <div className="last-searches-block">
                                <Collection
                                    allowExport={false}
                                    mode="block"
                                    entities={uniqLastSearches}
                                    logoSize={18}
                                    onClick={this.onSearchClick}
                                    groupByDate
                                    useLoading
                                />
                            </div>
                        ) : (
                            <div className="empty">
                                No search found in search history
                            </div>
                        )
                }
            </div>
        );
    }

}

Searches.propTypes = {
    close               : PropTypes.func,
    lastsSearches       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    refreshLastsSearches: PropTypes.func,
    navigateToSearch    : PropTypes.func,
};

Searches.defaultProps = {
    lastsSearches: false
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({

    lastsSearches: state.getIn(['navigation', 'lasts_searches']),
});

export default connect(mapStateToProps, {
    navigateToSearch,
    refreshLastsSearches: refreshLastsSearchesList,
})(withRouter(Searches));
