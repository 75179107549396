import React, { PureComponent } from 'react';
import _                        from 'lodash';
import PropTypes                from 'prop-types';
import ImmutablePropTypes       from 'react-immutable-proptypes';
import { connect }              from 'react-redux';
import { Modal, Skeleton }      from 'antd';
import {
    Icon, Action,  Row, Col,
}                               from 'helpers';
import { compileTagsSettings }  from 'store/actions/navigation';
import { str2DomFormat }        from 'utils/text';
import {
    getCustomTimeFrameLabel,
    getDateString,
    getTimeString,
}                               from 'utils/date';
import { post }                 from 'utils/api';
import Graph                    from '../Graph';
import dayjs                    from 'dayjs';

// Import display CSS
import './assets/graph.less';

const WHITE_LIST_EXPORT_RAW_DATA = [
    'Graph/Bar',
    'Graph/DoubleRadialBar',
    'Graph/LineArea',
    'Graph/Pie',
    'Graph/TagCloud',
];

/**
* Draw layouts with elements inside
*
*/
class GraphElement extends PureComponent {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this,
            'onClickExpandedModal',
            'onCloseExpandedModal',
            'registerCallbacks',
            'openExpand',
            'exportRawData',
        );

        this.graphRef = React.createRef();
        this.graphExpandedRef = React.createRef();

        this.state = {
            expanded : false,
            callbacks: {},
        };
    }

    /**
    * Component did update
    */
    componentDidUpdate() {
        const { expanded } = this.state,
            { assignRef }  = this.props;

        if (assignRef) {
            assignRef(expanded ? this.graphExpandedRef : this.graphRef);
        }
        this.updateCompiledTags();
    }

    /**
    * Get actions of the element graph
    *
    * @return html
    */
    getActions(expanded) {
        const {
                disableExpand
            }             = this.props,
            expand        = {
                label: 'Expand',
                icon : <Icon id="expand" folder="/actions/"
                    height={14}
                />,
                cb: this.openExpand,
            },
            exportActions   = this.getExportActions(expanded),
            hasExpandAction = expanded || disableExpand,
            actions         = {
                ...(!hasExpandAction && { expand }),
                ...exportActions,
            };

        return actions;
    }

    /**
    * Get actions for export: add to clipboard, direct raw data
    *
    * @param {boolean} expanded
    *
    * @returns {object}
    */
    getExportActions(expanded) {
        const { addToClipboard } = this.props,
            { element }          = this.props,
            { category }         = element || {},
            { configuration }    = element || {},
            { exportRawData }    = configuration || {},
            { callbacks }        = this.state,
            allowExportRawData   = _.includes(WHITE_LIST_EXPORT_RAW_DATA, category) && exportRawData !== false,
            addToClipboardAction = {
                label: 'Add to export clipboard',
                icon : <Icon
                    id="clipboard-add" folder="/shortcuts/"
                    className="add-to-clipboard"
                    height={18}
                />,
                ...(allowExportRawData && { actions: this.getAddToClipboardSubActions(expanded) }),
                ...(!allowExportRawData && {
                    options: { type: 'element', callbacks, filetype: 'image', expanded },
                    cb     : addToClipboard,
                })
            },
            exportRawDataAction = {
                label: 'Export raw data as .xlsx',
                icon : <Icon
                    id="export-raw-data" folder="/shortcuts/"
                    className="export-raw-data" height={18}
                />,
                options: { type: 'graph', callbacks, filetype: 'list' },
                cb     : this.exportRawData,
            };

        return {
            'add-to-clipboard': addToClipboardAction,
            ...(allowExportRawData && { exportRawDataAction }),
        };
    }

    /**
    * Get sub actions for action add to export clipboard
    *
    * @param {boolean} expanded
    */
    getAddToClipboardSubActions(expanded) {
        const { addToClipboard } = this.props,
            { callbacks }        = this.state;

        return [
            {
                label  : 'Images (.png, .pdf)',
                options: { type: 'element', callbacks, filetype: 'image', expanded },
                cb     : addToClipboard,
            },
            {
                label  : 'Worksheets (.xlsx, .csv)',
                options: { type: 'graph', callbacks, filetype: 'list', expanded },
                cb     : addToClipboard,
            }
        ];
    }

    /**
    * Open expanded metrics in a modal
    *
    * return Graph Component
    */
    openExpand() {
        const { avoidTooltip } = this.props;

        this.setState({
            expanded: true
        });

        if (avoidTooltip) {
            avoidTooltip(true);
        }
    }


    /**
     * Update compiledTags in state
     */
    updateCompiledTags() {
        const {
                model, compileTagsSettings,
                bookmarksStats, foldersStats
            }                     = this.props,
            { compiledTags }      = this.state,
            bookmarkFolderForTags = _.get(model, 'settings.bookmarkFolderForTags', false),
            compiledTagsPromise   = compileTagsSettings({ folderId: bookmarkFolderForTags });

        if(
            !bookmarksStats?.get('modelsAreLoaded')
            || !foldersStats?.get('modelsAreLoaded')
        ) {
            return;
        }

        compiledTagsPromise.then((newCompiledTags) => {
            if (JSON.stringify(newCompiledTags?.tags) === JSON.stringify(compiledTags)) {
                return;
            }

            this.setState({
                compiledTags: newCompiledTags?.tags || false
            });
        });
    }


    /**
    * Return the bookmark from the entity
    *
    * @return object
    */
    getFeaturedIds() {
        const { tags }       = this.props,
            { compiledTags } = this.state;

        this.taggedEntitiesIds = this.taggedEntitiesIds || {};

        if (compiledTags && tags) {
            _.each(tags.toJS(), (tag) => {
                const { label, color } = tag;

                this.taggedEntitiesIds[tag.id] = {
                    ...{ label, color },
                    ids: compiledTags[tag.id],
                };
            });
        }

        return this.taggedEntitiesIds;
    }

    /**
    * Export raw data from graph contextual menu
    *
    * @param {object} options
    */
    exportRawData(options) {
        const {
                createVirtualClipboardItem,
            }             = this.props,
            clipboardItem = createVirtualClipboardItem(options),
            dateString    = getDateString(),
            timeString    = getTimeString(),
            settings      = {
                imageType  : 'png',
                groupList  : 'xlsx',
                booklet    : 'pdf',
                compress   : 'single',
                share      : {},
                showOptions: true
            };

        post('/export', {
            settings,
            label  : `${dateString}_${timeString}_INSI_pack`,
            content: [clipboardItem],
        });
    }

    /**
    * Render Modal of the expandedMetric
    *
    * @return html
    */
    renderExpandedMetric() {
        const {
                element,
                parentLabel,
            }            = this.props,
            { expanded } = this.state,
            modalMarginV = document.documentElement.clientHeight * 0.05,
            w            = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - modalMarginV,
            h            = `calc(100% - ${modalMarginV / 2}px)`,
            labelHeight  = element.label ? 60 : 0;

        if (!expanded) {
            return null;
        }

        return (
            <Modal
                title={null}
                footer={null}
                open
                width={w}
                height={h}
                onCancel={this.onCloseExpandedModal}
                onClick={this.onClickExpandedModal}
                className="expanded-element-graph"
                style={{top: `${modalMarginV / 2}px`}}
            >
                <div onClick={this.onClickExpandedModal}>
                    {this.getLabelContent(parentLabel, labelHeight)}
                    {this.renderGraph(true)}
                </div>
            </Modal>
        );
    }

    /**
    * Stop click propagation
    *
    * return false
    */
    onClickExpandedModal(e) {
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
    * On close modal
    *
    * return false
    */
    onCloseExpandedModal(e) {
        const { avoidTooltip } = this.props;

        this.setState({ expanded: false });

        if (avoidTooltip) {
            avoidTooltip(false);
        }

        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
    * Get the graph type
    *
    * @return string
    */
    getType() {
        const { element } = this.props;

        return _.last(element.category.split('/'));
    }

    /**
    * Get resource filters
    *
    * @return array
    */
    getResourceFilter() {
        const { element } = this.props,
            { resource }  = element  || {},
            { filters }   = resource || {};

        return filters ?  filters[0] : null; // Use filters one by one
    }


    /**
    * Get label content
    *
    * return Graph Component
    */
    getLabelContent(label, labelHeight, style) {
        return (
            <div
                className="label"
                style={{
                    textAlign : 'center',
                    fontSize  : labelHeight ? `${labelHeight / 1.5}px` : null,
                    lineHeight: labelHeight ? `${labelHeight}px` : null,
                    ...style
                }}
            >
                {label}
            </div>
        );
    }

    // TODO: Simplify the code to unify Element and Graph function
    /**
    * Add a label to the current graph
    *
    * return JSX
    */
    decorateWithLabel(content, options) {
        const { dataIsLoaded, element }                 = this.props,
            {  labelHeightApplied, data, expanded }     = options,
            { id, label, labelOnBottom, configuration } = element,
            { color, graphLabelColor }                  = configuration || {},
            contentWithLabel                            = [],
            labelAddFunction                            = labelOnBottom ? 'push' : 'unshift',
            resourceId                                  = element.resource?.id,
            graphLabel                                  = label ?? '',
            className                                   = `layout-graph ${(`${element.resource?.id} ${element.category}`).toLowerCase()}`, // eslint-disable-line max-len
            labelWithData                               = !dataIsLoaded && label && label.indexOf('%total') >= 0
                ? (
                    <Skeleton loading active
                        paragraph={false}
                    />
                )
                : (
                    label ? label.replace('%total', data ? data.total : 0) : null
                ),
            labelContent                             = (
                <Row
                    height={labelHeightApplied}
                    className="label"
                    key={JSON.stringify(element)}
                >
                    {this.getLabelContent(labelWithData, labelHeightApplied, { color: graphLabelColor || color })}
                </Row>
            );

        // Push the content inside fragment
        contentWithLabel.push((
            <Row key={`${id}-label`} className={className}
                dataQaKey={str2DomFormat(`${resourceId} ${graphLabel}`)}
            >
                {content}
            </Row>
        ));

        // Then, add the label at its perfect place.
        if (label) {
            contentWithLabel[labelAddFunction](labelContent);
        }

        return (
            <Col
                key={id} className="element-graph"
                forwardedRef={expanded ? this.graphExpandedRef : this.graphRef}
            >
                {contentWithLabel}
            </Col>
        );
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { registerCallbacks } = this.props,
            { callbacks }           = this.state,
            /** Return empty object */
            defaultCb               = () => {};

        callbacks[action] = cb || defaultCb;

        this.setState({ callbacks });

        // Register callback on Element base componant
        registerCallbacks(action, cb);
    }

    /**
    * Get min and max years from DateRange string
    */
    getDatesFromRangeString(daterangeString) {
        const presetYearExtract = /tfy(\d*)$/.exec(daterangeString),
            customExtract       = /custom_(\d*)_(\d*)$/.exec(daterangeString),
            currentYear         = new Date().getFullYear();

        // Is a year preset range
        if (_.isArray(presetYearExtract) && presetYearExtract.length === 2) {
            return { start: currentYear - parseInt(presetYearExtract[1]), end: currentYear };
        }

        // Is a custom range
        if (_.isArray(customExtract) && customExtract.length === 3) {
            return { start: parseInt(customExtract[1]), end: parseInt(customExtract[2]) };
        }
    }


    /**
     *  Keep selection for first call only
     *
     * @param {object} The Graph data
     *
     * @returns object
     */
    removeSelection(graphData, filterValues) {
        const {
                filtersByMetrics, firstCall, onUpdateFilters,
                dataIsLoaded,
            }                                = this.props,
            autoSelectionIsInDateFilterRange = this.autoSelectionIsInDateFilterRange(graphData, filterValues),
            mustBeRemove                     = dataIsLoaded && firstCall && !autoSelectionIsInDateFilterRange;

        if (mustBeRemove && _.keys(filtersByMetrics).length > 0) {
            // Clean selection on analyse
            onUpdateFilters(null);
        }

        return mustBeRemove && graphData  // Clean selection on without filtersByMetrics (on dashboard)
            ? {
                ...graphData,
                stats: {
                    ...graphData.stats,
                    // Remove selection for the graph
                    selectionStart: null,
                    selectionEnd  : null,
                }
            }
            : graphData;
    }


    /**
    * @param {object} graphData
    *
    * @returns {boolean}
    */
    autoSelectionIsInDateFilterRange(graphData, filterValues) {
        const {
                model, timeframes
            } = this.props,
            { settings }                 = model,
            { dateFilter }               = settings || {},
            { stats }                    = graphData || {},
            {
                selectionStart,
                selectionEnd,
                startYear,
                endYear,
            }                            = stats || {},
            {
                start: filterStart,
                end  : filterEnd,
            }                            = filterValues || {},
            isCustom                     = _.includes(dateFilter, 'custom'),
            selectedTimeframe            = _.find(timeframes?.toArray(), timeframe => timeframe.id === dateFilter),
            duration                     = selectedTimeframe?.duration,
            dateFilterCustom             = dateFilter?.split('_'),
            maxYear                      = isCustom ? dateFilterCustom[2] : dayjs().year(),
            minYear                      = isCustom ? dateFilterCustom[1] : maxYear - duration,
            start                        = selectionStart || startYear || filterStart,
            end                          = selectionEnd || endYear || filterEnd,
            selectionIsInDateFilterRange = start >= minYear && end <= maxYear;

        if (!dateFilter && start && end) {
            return true;
        }

        return selectionIsInDateFilterRange;
    }


    /**
     * Render Graph Helper
     *
     * @param {object} params
     *
     * @returns JSX
     */
    renderGraphHelper( expanded, labelHeightApplied) {  // eslint-disable-line
        const {
                width, height, navigateTo,
                dataIsLoaded, onUpdateFilters, parameters,
                fixedBySelection, filters, filtersByMetrics,
                element, data, model, firstCall,
            }                     = this.props,
            { settings }          = model,
            { dateFilter }        = settings || {},
            timeFrameLabel        = getCustomTimeFrameLabel(dateFilter),
            { callbacks }         = this.state,
            configuration         = this.getConfiguration(expanded),
            filterKey             = this.getResourceFilter(),
            { label }             = element,
            rawData               = expanded && callbacks?.getData ? callbacks.getData() : data,
            filterByMetricsValues = (filterKey && filtersByMetrics && filtersByMetrics[filterKey]) ?? [],
            filterDefinition      = filters.find(filter => filter.id === filterKey),
            filterType            = filterDefinition ? filterDefinition.subtype : null,
            isCustomFilter        = filterType && filterType.indexOf('-range') !== -1,
            filterValues          = isCustomFilter
                ? this.getDatesFromRangeString(filterByMetricsValues)
                : filterByMetricsValues,
            graphData             = this.removeSelection(rawData, filterValues);

        // TODO: filterValues={filterValues} and fix loading for graph....
        return (
            <Graph
                width={width}
                height={(height - labelHeightApplied - (label ? 10 : 0))}
                {...configuration}
                data={graphData}
                type={this.getType()}
                dataIsLoaded={dataIsLoaded}
                filterType={filterType}
                filterValues={rawData ? filterValues : []}
                filterKey={filterKey}
                filterCb={filterKey && !expanded ? onUpdateFilters : null}
                firstCall={firstCall}
                featuredIds={this.getFeaturedIds()}
                openModal={!expanded ? navigateTo : false}
                registerCallbacks={!expanded ? this.registerCallbacks : _.noop}
                dataParameters={parameters}
                fixedBySelection={!_.isUndefined(fixedBySelection) ? fixedBySelection : true}
                timeFrameLabel={timeFrameLabel}
            />
        );
    }

    /**
     * Get graph configuration
     *
     * @param {bool} expanded
     * @returns
     */
    getConfiguration(expanded) {
        const { element }             = this.props,
            { configuration, expand } = element,
            expandConfiguration       = _.get(expand, 'configuration', {});

        if (!expanded) {
            return configuration || {};
        }

        // Merge expand configuration
        return {
            ...(configuration || {}),
            ...(expandConfiguration || {})
        };
    }

    /**
    * Render the graph Helper
    *
    * @return JSX
    */
    renderGraph(expanded = false) {
        const  {
                data, element,
                width, height,
                emitEvent,
            }                  = this.props,
            {
                labelHeight,
                label,
            }                  = element,
            resourceId         = element.resource?.id,
            graphLabel         = label ?? '',
            dataQaKey          = `${str2DomFormat(`${resourceId} ${graphLabel} actions button`)}`,
            configuration      = this.getConfiguration(expanded),
            disableActions     = configuration?.disableActions || false,
            actions            = !disableActions ? this.getActions(expanded) : [],
            size               = {
                width : configuration ? configuration.width : width,
                height: configuration ? configuration.height : height,
            },
            labelHeightApplied = label ? labelHeight || (expanded ? 40 : 20) : 0,
            from               = { uri: data?.uri, category: element?.category, componentName: 'Graph' },
            options            = {
                configuration,
                size,
                labelHeightApplied,
                data,
                expanded,
            };

        return this.decorateWithLabel(
            (
                <>
                    {this.renderGraphHelper(expanded, labelHeightApplied)}
                    <Action
                        from={from}
                        actions={actions}
                        emitEvent={emitEvent}
                        dataQaKey={dataQaKey}
                    />
                    {expanded ? null : this.renderExpandedMetric()}
                </>
            ),
            options
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { expanded } = this.props;

        return this.renderGraph(expanded);
    }

}

GraphElement.propTypes = {
    addToClipboard            : PropTypes.func,
    avoidTooltip              : PropTypes.func,
    bookmarks                 : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    createVirtualClipboardItem: PropTypes.func,
    data                      : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    dataIsLoaded              : PropTypes.bool,
    firstCall                 : PropTypes.bool,
    filters                   : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filtersByMetrics          : PropTypes.any,
    fixedBySelection          : PropTypes.any,
    forcedConfiguration       : PropTypes.object,
    height                    : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    member                    : ImmutablePropTypes.map,
    model                     : PropTypes.object,
    navigateTo                : PropTypes.func,
    onUpdateFilters           : PropTypes.func,
    onUpdatePagination        : PropTypes.func,
    emitEvent                 : PropTypes.func,
    parameters                : PropTypes.any,
    parentLabel               : PropTypes.string,
    registerCallbacks         : PropTypes.func,
    width                     : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    disableExpand             : PropTypes.bool,
    expanded                  : PropTypes.bool,
    size                      : PropTypes.object,
    compileTagsSettings       : PropTypes.func,
    bookmarksStats            : PropTypes.any,
    foldersStats              : PropTypes.any,
    tags                      : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    element                   : PropTypes.shape({
        id           : PropTypes.string,
        category     : PropTypes.string,
        configuration: PropTypes.object,
        expand       : PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        label        : PropTypes.string,
        labelHeight  : PropTypes.any,
        labelOnBottom: PropTypes.any,
        resource     : PropTypes.object,
        type         : PropTypes.any
    }).isRequired,
    timeframes: PropTypes.object,
    assignRef : PropTypes.func,
};

GraphElement.defaultProps = {
    forcedConfiguration: {},
    firstCall          : true,
    data               : {
        content: false,
        stats  : false,
    },
    width   : false,
    height  : false,
    expanded: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = state => ({
    bookmarksStats: state.getIn(['userView', 'bookmark', 'stats']),
    foldersStats  : state.getIn(['userView', 'bookmark_folder', 'stats']),
});

export default connect(mapStateToProps, {
    compileTagsSettings,
})(GraphElement);

