import Immutable            from 'immutable';
import * as types           from '../../actions/types/navigation';
/**
* Manage the search/member slice in the store
*
* @return navigation As a fresh Immutable Map
*/
export const NavigationReport = (navigation, action) => {
    const { type, payload } = action;

    // Switch over actions
    switch (type) {
        // Update the report model
        case types.REPORT_OPEN:
            return openReport(navigation, payload);

        // Save the report model
        case types.REPORT_SAVE:
            return saveReport(navigation, payload);

        // Delete the report model
        case types.REPORT_DELETE:
            return deleteReport(navigation, payload);

        // Get all the report model
        case types.REPORT_FETCH:
            return fetchReport(navigation, payload);

        // Generate the report model
        case types.REPORT_GENERATE:
            return generateReport(navigation, payload);
    }

    // No action found (The sub reducer must return null)
    return null;
};


/**
 * Generate a report from the user
 *
 * @param payload ?
 */
const generateReport = (navigation, payload) => {
    const { report } = payload,
        reports   = navigation.get('reports') || new Immutable.Map(),
        newReports= reports.set(report.id, report);
    return navigation.set('reports', newReports);
};

/**
 * Get all report from the user
 *
 * @param payload  the table of reports
 */
const fetchReport = (navigation, payload) => {
    const reports      = payload  || [],
        reportAsObject = {};

    // Set all reports as a map
    reports.forEach((report) => {
        reportAsObject[report.id] = report;
    });

    return navigation.set('reports', new Immutable.Map(reportAsObject));
};

/**
 * Open report from the id
 *
 * @param payload  the report model
 */
const openReport = (navigation, payload) => {
    const {model} = payload;
    return navigation.set('openedReport', model);
};

/**
 * Save report from the id
 *
 * @param payload  the report model
 *
 */
const saveReport = (navigation, payload) => {
    const report  = payload,
        reports   = navigation.get('reports') || new Immutable.Map(),
        newReports= reports.set(report.id, report);
    return navigation.set('reports', newReports).set('openedReport', Immutable.fromJS(report));
};

/**
 * Delete report from the id
 *
 * @param {object} payload the report model id and true/false if is deleted
 *
 */
const deleteReport =(navigation, payload) => {
    const { isDeleted, key } = payload,
        reports   = navigation.get('reports') || new Immutable.Map(),
        reportsUpdated= (isDeleted===true) ? reports.delete(key) : reports;
    return navigation.set('reports', reportsUpdated);
};
