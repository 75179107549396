/**
 * Main Insight routes definitions
 *
 * @return Component
 */

// Import main modules definitions
import MetricsTester         from '../../modules/MetricsTester';
import ElementManager        from '../../modules/Element';
import Capture               from '../../modules/Capture';
import Newsletter            from '../../modules/Newsletter';
import ApplicationTrigger    from '../../modules/ApplicationTrigger';

import Home                  from '../../modules/Home';
import Browser               from '../../modules/Browser';
import NoMatch               from '../../modules/404';

// Create/Export a route array
export default [

    // Home
    {
        key         : 'home',
        path        : '/',
        enableHeader: true,
        component   : Home,
        exact       : true
    },
    // Main navigators
    {
        key         : 'browser',
        path        : '/b',
        enableHeader: true,
        component   : Browser
    },
    // MetricsTester
    {
        key      : 'metrics-tester',
        path     : '/metrics-tester',
        component: MetricsTester
    },
    // MetricsTester
    {
        key      : 'element-editor',
        path     : '/e',
        component: ElementManager
    },
    // Capture specific element
    {
        key           : 'capture',
        path          : '/c',
        enableHeader  : false,
        enableSidebars: false,
        background    : false,
        component     : Capture
    },
    // Newsletter
    {
        key           : 'newsletter',
        path          : '/n',
        enableHeader  : false,
        enableSidebars: false,
        background    : false,
        component     : Newsletter
    },
    // Application trigger
    {
        key      : 'app-trigger',
        path     : '/r',
        component: ApplicationTrigger
    },
    // Default & 404 route
    {
        key      : '404',
        component: NoMatch
    }

];
