/**
 * Main Insight 404
 *
 * @exports Component
 */

import React,  { Component } from 'react';

/**
 * Implement the 404 page for Insight
 *
 */
class NoMatch extends Component {

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Render the 404
    *
    * @return html
    */
    render() {
        return (
            <div className="404" />
        );
    }

}

export default NoMatch;
