import _ from 'lodash';
import React,
{
    useEffect,
    useRef,
    useState,
}                from 'react';
import PropTypes from 'prop-types';
import { Icon }  from 'helpers';
import {
    makeStrClassName,
    str2DomFormat,
} from 'utils/text';

import './assets/tabs.less';

// Add tab-container class to the direct parent element

/**
* @return JSX
*/
const Tabs = props => { // eslint-disable-line max-lines-per-function
    const { parentRef, sections }    = props,
        { goToTop, loadedSections }  = props,
        { scrollTop, firstSection }  = props,
        { disabledColumnsIds }       = props,
        { onChangeActiveSectionKey } = props,
        { tabSeparator, classNames } = props,
        tabsRef                      = useRef(null),
        withContext                  = sections?.find(section => section?.configuration.useContext),
        goToUpDisplayClass           = [
            'go-to-up',
            { 'hidden': scrollTop === 0 },
            { 'with-context': withContext }
        ],
        [
            activeSectionKey,
            setActiveSectionKey,
        ] = useState(firstSection);

    useEffect(() => {
        setActiveSectionKey(firstSection);
    }, [firstSection]);

    useEffect(() => {
        updateTabIndicator();
    }, [activeSectionKey]);

    /**
    * Get visible sections
    *
    * @returns {array}
    */
    const getVisibleSections = () => {
        return _.filter(sections, section => {
            if (!disabledColumnsIds) {
                return true;
            }

            if (section.configuration?.position === 'left') {
                return false;
            }

            return disabledColumnsIds?.indexOf(section.id) === -1;
        });
    };

    /**
    * On Tab click
    *
    * @returns {void}
    */
    const onTabClick = e => {
        const sectionEl = e.currentTarget,
            sectionKey  = _.get(sectionEl, 'attributes.dataid.value');

        navigateToSection(sectionKey);
    };

    /**
    * Go to section (change the scroll top)
    *
    * @param {string} sectionKey
    *
    * @returns {void}
    */
    const navigateToSection = sectionKey => {
        const tabsEl      = tabsRef?.current, // Use tabs as a start point to navigate DOM
            tabs          = tabsEl?.closest('.tabs'),
            tabsContainer = tabsEl?.closest('.tab-container'),
            targetNode    = tabsContainer?.querySelector(`:not(.tab)[dataid="${sectionKey}"]`),
            top           = targetNode?.offsetTop - tabsEl?.clientHeight - tabs.clientHeight;

        onChangeActiveSectionKey(sectionKey);
        setActiveSectionKey(sectionKey);

        // No section ?
        if (!targetNode) {
            return;
        }

        parentRef?.current?.scrollTo({
            top,
            left    : 0,
            behavior: 'smooth',
        });
    };

    /**
    * Update tab position
    *
    * @return void
    */
    const updateTabIndicator = () => {
        // Prevent dom usage to early
        if (!tabsRef?.current) {
            return;
        }

        const tabsNode = activeSectionKey && tabsRef
                ? tabsRef.current.querySelector(`.tab[dataid="${activeSectionKey}"]`)
                : null,
            tabOffset     = tabsNode ? tabsNode.offsetLeft : 0,
            tabWidth      = tabsNode ? tabsNode.clientWidth : 0,
            indicatorNode = tabsRef?.current.querySelector('.tab-indicator');

        if (!activeSectionKey) { return; }

        indicatorNode.style.left  = `${tabOffset}px`;
        indicatorNode.style.width = `${tabWidth}px`;

        tabsNode?.classList.contains('with-context')
            ? indicatorNode.classList.add('with-context')
            : indicatorNode.classList.remove('with-context');
    };

    const visibleSections = getVisibleSections();

    if (!visibleSections.length) {
        return null;
    }

    return (
        <div ref={tabsRef} className={makeStrClassName([...classNames, 'tabs-row'])}>
            {_.map(visibleSections, (section, index) => {
                const loaded      = loadedSections?.indexOf(section.id) !== -1,
                    active        = activeSectionKey === section.key,
                    tabLabel      = section.configuration?.tabLabel || section.label,
                    position      = section.configuration?.position,
                    useContext    = section.configuration?.useContext,
                    tooltip       = section.configuration?.tooltip,
                    tabClassNames = [
                        'tab',
                        {loaded},
                        {active},
                        {'with-context': !!useContext},
                        {'section-left': position === 'left'}
                    ];

                return (
                    <>
                        {tabSeparator && index > 0 && (
                            <div className="separator" />
                        )}
                        <span
                            key={section.key}
                            className={makeStrClassName(tabClassNames)}
                            datausecontext={section.configuration?.useContext ? 1 : null}
                            dataid={section.key}
                            data-qa-key={str2DomFormat(tabLabel)}
                            onClick={onTabClick}
                            title={tooltip}
                        >
                            {tabLabel}
                        </span>
                    </>
                );
            })}
            <div className="tab-indicator" />
            {goToTop && (
                <div className={makeStrClassName(goToUpDisplayClass)} title="Go to top"
                    onClick={goToTop}
                >
                    <Icon type="caret-up" />
                </div>
            )}
        </div>
    );
};

Tabs.propTypes = {
    classNames              : PropTypes.array,
    disabledColumnsIds      : PropTypes.array,
    firstSection            : PropTypes.string,
    goToTop                 : PropTypes.func,
    loadedSections          : PropTypes.array,
    onChangeActiveSectionKey: PropTypes.func,
    parentRef               : PropTypes.any,
    sections                : PropTypes.array,
    scrollTop               : PropTypes.number,
    tabSeparator            : PropTypes.bool,
};

Tabs.defaultProps = {
    classNames  : [],
    tabSeparator: false,
};

export default Tabs;
