import _           from 'lodash';
import React       from 'react';
import PropTypes   from 'prop-types';
import { Popover } from 'antd';

import './verticalGauge/main.less';

/**
 * The Vertical gauge Component
 */
const SwiftGauge = props => {
    /**
    * Render the main layout
    *
    * @returns JSX
    */
    const render = () => {
        const {
                label, ratio,
                nbSteps,
            }    = props,
            step = 1 / nbSteps;

        return (
            <Popover
                content={(
                    <b>{label}</b>
                )}
                placement="top"
            >
                <div className="vertical-gauge">
                    {_.map(_.range(nbSteps, 0, -1), range => {
                        const level    = range / nbSteps,
                            roundRatio = _.round(ratio + step, 2),
                            lightUp    = roundRatio > level,
                            classNames = [
                                'grade',
                                lightUp ? 'light-up' : '',
                            ];

                        return <div key={range} className={classNames.join(' ')} />;
                    })}
                </div>
            </Popover>
        );
    };

    return render();
};

SwiftGauge.propTypes = {
    label  : PropTypes.string,
    nbSteps: PropTypes.number,
    ratio  : PropTypes.number,
};

SwiftGauge.defaultProps = {
    label  : '',
    nbSteps: 5,
    ratio  : 0,
};

export default SwiftGauge;
