/**
* Main (root) Reducer, will combine all reducers to one and only.
*
* @export rootReducer
*/
import Immutable from 'immutable';

/**
* Create an empty state from  an immutable Map
*
* @return {Map} An empty map
*/
export const initialMap = Immutable.Map();

/**
* Create an empty state from an immutable List
*
* @return {Map} An empty list
*/
export const initialList = Immutable.List();

export default { initialMap, initialList };
