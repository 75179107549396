import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import { connect }              from 'react-redux';
import { Icon }                 from 'helpers';
import { get }                  from 'utils/api';
import { updateMemberSettings } from 'store/actions/auth';

/**
 * Display a news icon to alert user when some features are published
 *
 */
class News extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClick');

        this.state = {
            displayRedDot: false
        };
    }

    /**
     * On component load
     *
     * @return void
     */
    async componentDidMount() {
        const { memberSettings }      = this.props,
            { last_news_access_date } = memberSettings || {},
            resp                      = await get('/features'),
            news                      = resp.body,
            innovation                = news?.find((product) => product.name === 'Insight'),
            { update }                = innovation || {};

        // No last news access or no news, no red dot.
        if (!last_news_access_date
            || !update
            || (new Date(update).getTime() < new Date(last_news_access_date).getTime())
        ) {
            return;
        }

        this.setState({ displayRedDot: true });
    }


    /**
     * On click on
     *
     * @return void
     */
    onClick() {
        const { updateMemberSettings, memberSettings } = this.props,
            url = 'https://www.questel.com/product-release/insight/';

        updateMemberSettings({
            ...memberSettings,
            last_news_access_date: new Date().toISOString().slice(0, 19).replace('T', ' ')
        });

        window.open(url, '_blank');
    }


    /**
     * Render the megaphone
     *
     * @return jsx
     */
    render() {
        const { displayRedDot } = this.state,
            iconType            = displayRedDot ? 'megaphone-red-dot' : 'megaphone';

        return (
            <div className="news" onClick={this.onClick}>
                <Icon height="16" width="16"
                    id={iconType}
                />
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
News.propTypes = {
    memberSettings      : PropTypes.object,
    updateMemberSettings: PropTypes.func.isRequired
};


/**
 */
const mapStateToProps = (state) => {
    const auth = state.get('auth'),
        member = auth.get('member');

    return {
        memberSettings: member.get('settings') || {},
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    updateMemberSettings
})(News);
