// Import libs
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import _                    from 'lodash';

import { connect }          from 'react-redux';
import { learn }            from 'store/actions/knowledge';

import { getDayLabel }      from 'utils/date';

import Entity               from '../Entity';

// Import display CSS
import './assets/block.less';

/**
* Block Block : display a list of entities
*
*/
class Block extends Component {

    /**
    * Prepare the component to be used.
    */
    constructor(props) {
        super(props);

        const { learnKnowledge } = this.props;

        _.bindAll(this, 'renderEntity');

        this.state = {
            expandedRows: [],
            countries   : null,
            orgClasses  : null
        };

        learnKnowledge(['tags', 'entities']).then(this.setState.bind(this));
    }

    /**
     * Extract the authority on entity without type
     *
     * @returns Array of entities.
     */
    getEntities() {
        const { entities } = this.props;

        return _.map(entities, (entity) => (
            !entity?.type && entity?.authority
                ? entity.authority
                : entity
        ));
    }

    /**
     *  It's a recursive function that returns the path of the entity.
     *
     */
    getPathKey(entity) {
        const { parentFolder } = entity;

        return parentFolder
            ? `/${parentFolder.id}-${parentFolder?.label}${this.getPathKey(parentFolder)}`
            : '';
    }


    /**
     * It renders an entity
     *
     * @returns A React component.
     */
    renderEntity(entity, index) {
        const {
                renderSuffix, useLoading,
                forceLogo, color, logoSize,
                entityClassName, onClick,
                disableModal, filters, draggable,
                disabledEntities, renderActionsInline,
                overwriteEntityActions, disableInteractivity
            }             = this.props,
            { isLoading } = entity || { isLoading: true },
            disabled      = disabledEntities.includes(entity.id),
            pathKey       = this.getPathKey(entity),
            key           = !isLoading
                ? `${entity.label} ${entity.id} ${pathKey}}`
                : `skeleton ${index}`;

        return (
            <Entity
                {...this.state}
                key={key}
                color={color}
                forceLogo={forceLogo}
                logoSize={logoSize}
                render="block"
                entity={entity}
                onClick={onClick}
                disableModal={disableModal}
                className={entityClassName}
                renderSuffix={renderSuffix}
                filters={filters}
                draggable={draggable}
                useLoading={useLoading}
                disabled={disabled}
                disableInteractivity={disableInteractivity}
                overwriteActions={overwriteEntityActions}
                renderActionsInline={renderActionsInline}
            />
        );
    }


    /**
    * Given an entity, return the date it was created
    *
    * @returns string
    */
    getEntityDate(entity) {
        return entity.date_create || entity.entity?.date_create;
    }

    /**
    * Render entities grouped by date
    * @returns JSX
    */
    renderGroupedByDate() {
        const entities      = this.getEntities(),
            groupedEntities = _.groupBy(
                entities,
                (entity) => {
                    const date = this.getEntityDate(entity);
                    return date?.substring(0, 10);
                }
            ),
            sortedGroupKey  = _.keys(groupedEntities).sort().reverse();

        return (
            <>
                {sortedGroupKey.map((index) => groupedEntities[index]).map((entitiesByDate, index) => {
                    const dayLabel = getDayLabel(this.getEntityDate(entitiesByDate[0]));
                    return (
                        <div key={`$group-date-${index}`} className="downloads-by-date">
                            <div className="group-date">
                                <span>
                                    {dayLabel}
                                </span>
                            </div>
                            {
                                _.sortBy(entitiesByDate, entity => this.getEntityDate(entity)).reverse()
                                    .map(this.renderEntity)
                            }
                        </div>
                    );
                })}
            </>
        );
    }


    /**
    * Render the list.
    *
    * @return JSX
    */
    render() {
        const {
                className,
                groupByDate,
            } = this.props,
            entities = this.getEntities();

        if (!entities.length) {
            return <div>No data to display</div>;
        }

        const content = groupByDate
            ? this.renderGroupedByDate()
            : _.map(entities, this.renderEntity);

        return (
            <div className={`${className} collection Block`} style={{ display: 'block-block' }}>
                {content}
            </div>
        );
    }

}

Block.propTypes = {
    entities: PropTypes.arrayOf(
        PropTypes.shape({})
    ).isRequired,
    filters               : PropTypes.oneOfType([PropTypes.array, PropTypes.bool, ImmutablePropTypes.list]),
    renderSuffix          : PropTypes.func,
    className             : PropTypes.string,
    entityClassName       : PropTypes.string,
    color                 : PropTypes.any,
    disableModal          : PropTypes.any,
    disabledEntities      : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    renderActionsInline   : PropTypes.bool,
    overwriteEntityActions: PropTypes.any,
    forceLogo             : PropTypes.any,
    learnKnowledge        : PropTypes.func,
    logoSize              : PropTypes.any,
    onClick               : PropTypes.any,
    groupByDate           : PropTypes.bool,
    draggable             : PropTypes.bool,
    useLoading            : PropTypes.bool,
    disableInteractivity  : PropTypes.bool,
};

Block.defaultProps = {
    renderSuffix   : () => {},
    className      : '',
    entityClassName: '',
    groupByDate    : false,
    draggable      : false,
    useLoading     : false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Block);

