/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                              from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect }                    from 'react-redux';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';
import ModelAction                    from 'helpers/Action/Model';
import Autocomplete                   from 'helpers/Search/Autocomplete';

import './assets/companies.less';

/**
 * Class Companies
 *
 */
class Companies extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderActions', 'resetSelectedFilters',
            'toggleTagFilter', 'onClick', 'registerCallbacks', 'reset');

        this.state = {
            lastSelected         : false,
            autocompleteCallbacks: {},
            filters              : {
                tags: [],
            }
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks } = this.props;

        if (registerCallbacks) {
            // Allow the parent to reset autocomplete
            registerCallbacks('reset', this.reset);
        }
    }

    /**
    * Is the provided entity selected ?
    *
    * @return boolean
    */
    isEntitySelected(entity) {
        const { bookmarksList } = this.props,
            orgunits            = bookmarksList.filter(streamEntity => streamEntity.model?.entity.entity.type === 'orgunit');

        return orgunits.find((organisation) => organisation.entity.entity.id === entity.id);
    }


    /**
    * Reset the sidebar
    *
    * @return void
    */
    reset() {
        const { autocompleteCallbacks } = this.state;

        if (autocompleteCallbacks.reset) {
            autocompleteCallbacks.reset();
        }

        this.setState({
            lastSelected: false
        });
    }

    /**
    * Render actions of the orgunit
    *
    */
    renderActions(entity) {
        const { lastSelected } = this.state,
            firstKeyPath       = lastSelected && entity.id === lastSelected.id
                ? ['classify'] : [];

        return (
            <ModelAction entity={entity} onClick={this.onClick}
                firstKeyPath={firstKeyPath}
            />
        );
    }

    /**
    * Open the clicked entity
    *
    * @return void
    */
    onClick(entity, collection) {
        const { navigateTo, close } = this.props,
            orgunitCollection       = collection && collection.map(entity => entity.type !=='orgunit' ? entity.entity : entity);

        if (!navigateTo) {
            return;
        }

        // A navigateTo(entity || bookmark,  orgunitCollection || [entity]);

        navigateTo(entity,  orgunitCollection);

        if (close) {
            close();
        }
    }

    /**
    * Enable / Disable a tag to filter list
    *
    * @param object tag The tag to be toggled
    *
    * @return void
    */
    toggleTagFilter(tag) {
        const { filters } = this.state,
            { tags }      = filters;

        this.setState({
            filters: {
                ...filters,
                tags: _.xor(tags, [tag.id]) // Toggle
            }
        });
    }

    /**
    * Register autocomplete callbacks
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { autocompleteCallbacks } = this.state;

        autocompleteCallbacks[action] = cb;
    }

    /**
    * Reset all tags filters
    *
    * @return void
    */
    resetSelectedFilters() {
        const { filters } = this.state;

        this.setState({
            filters: {
                ...filters,
                tags: []
            }
        });
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
            tags,
            bookmarksList,
            active,
        } = this.props;

        if (!tags || !bookmarksList || !active) {
            return false;
        }

        return (
            <Fragment>
                <div className="content">
                    <div className="searchbar">
                        <Autocomplete
                            max={25}
                            types={['orgunit']}
                            onSelect={this.onClick}
                            placement="after"
                            renderSuffix={this.renderActions}
                            overlayClassName="companies-sidebar-suggestions"
                            placeholder="Enter organization (Facebook, Samsung,...)"
                            registerCallbacks={this.registerCallbacks}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }

}

Companies.propTypes = {
    active           : PropTypes.bool,
    close            : PropTypes.func.isRequired,
    navigateTo       : PropTypes.func.isRequired,
    bookmarksList    : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    tags             : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]).isRequired,
    registerCallbacks: PropTypes.func.isRequired
};

Companies.defaultProps = {
    active       : false,
    bookmarksList: false
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
});

export default connect(mapStateToProps, {
})(Companies);
