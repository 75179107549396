// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shortcut-view.newsletters .period {
  margin-top: 40px;
  text-align: center;
}
.shortcut-view.newsletters .period.from-configure {
  margin-top: 20px;
  text-align: left;
}
.shortcut-view.newsletters .period.from-configure .period-selectors .period-selector {
  display: inline;
  margin-right: 20px;
}
.shortcut-view.newsletters .period .period-selectors {
  display: inline-block;
  text-align: left;
  line-height: 38px;
}
.shortcut-view.newsletters .period .period-selectors .period-selector {
  display: block;
  /*.ant-radio-wrapper {
                .ant-radio {
                    margin: 0 10px 0 10px;

                    .ant-radio-inner:after{
                        transform: scale(1);
                    }

                }
            }*/
}
.shortcut-view.newsletters .period .period-selectors .period-selector label {
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 5px;
}
.shortcut-view.newsletters .period .period-selectors .period-selector .text {
  color: var(--primary-color);
  padding-left: 5px;
}
.settings-section .newsletter-settings .period {
  text-align: left;
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/core/Ui/Header/Shortcut/Views/Newsletters/Newsletter/Steps/assets/period.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,gBAAA;EACA,gBAAA;AAAR;AAFI;EAKQ,eAAA;EACA,kBAAA;AAAZ;AAXA;EAgBQ,qBAAA;EACA,gBAAA;EACA,iBAAA;AAFR;AAhBA;EAqBY,cAAA;EAFV;;;;;;;;;cASY;AACd;AA7BA;EAwBgB,2BAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AAQhB;AApCA;EAgCY,2BAAA;EACA,iBAAA;AAOZ;AAUA;EACI,gBAAA;EACA,aAAA;AARJ","sourcesContent":[".shortcut-view.newsletters .period {\n\n    margin-top: 40px;\n    text-align: center;\n\n    &.from-configure{\n        margin-top: 20px;\n        text-align: left;\n\n        .period-selectors .period-selector {\n            display     : inline;\n            margin-right: 20px;\n        }\n    }\n\n    .period-selectors {\n        display    : inline-block;\n        text-align : left;\n        line-height: 38px;\n\n        .period-selector {\n            display   : block;\n\n            label {\n                color        : var(--primary-color);\n                font-weight  : bold;\n                cursor       : pointer;\n                margin-bottom: 10px;\n                margin-right : 5px;\n            }\n\n            .text {\n            color       : var(--primary-color);\n            padding-left: 5px;\n            }\n\n            /*.ant-radio-wrapper {\n                .ant-radio {\n                    margin: 0 10px 0 10px;\n\n                    .ant-radio-inner:after{\n                        transform: scale(1);\n                    }\n\n                }\n            }*/\n        }\n    }\n}\n\n.settings-section .newsletter-settings .period {\n    text-align: left;\n    margin-top: 0;\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
