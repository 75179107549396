import Immutable                      from 'immutable';
import * as types                     from '../actions/types/navigation';
import { NavigationReport }           from './navigation/report.js';
import { NavigationNodesPreferences } from './navigation/nodesPreferences';
import { initialMap }                 from '../initialStates';

/**
 * Update the navigation with a the search provided by data API
 *
 * @param Map    navigation The store map
 * @param object search     Navigation model
 */
const resetNavigationSearch = (navigation, search) => {
    const searchMap = navigation.get('search') || new Immutable.Map();

    // Update the search model & reset the state
    return navigation.set('search',
        searchMap
            .set('model', new Immutable.Map(search))
            .set('state', null));
};

/**
 * Update the navigation search state (progress, stats, etc..)
 *
 * @param Map    navigation The store map
 * @param object state      Navigation model
 */
const updateNavigationState = (navigation, state) => {
    const searchMap = navigation.get('search') || new Immutable.Map();

    // Empty search map can't handle state
    if (!searchMap) {
        return navigation;
    }

    // Update the search model & reset the state
    return navigation.set('search',
        searchMap.set('state', new Immutable.Map(state)));
};

/**
 * Open/Close shortcut from the id
 *
 * @param Map    navigation The store map
 * @param object shortcutId  The sidebar id to toggle
 */
const activateShortcut = (navigation, payload) => {
    const { shortcutId, options } = payload,
        shortcutActivated         = {
            shortcutId,
            options: options || {}
        };

    // Update the search model & reset the state
    return navigation.set('shortcutActivated', shortcutActivated);
};


/**
 * Store the refreshed search with a timestamp
 *
 * @param Map    navigation The store map
 * @param object shortcutId The refreshed search
 */
const searchRefreshed = (navigation, payload) => {
    const refreshedSearches = navigation.get('refreshedSearches') || new Immutable.Map(),
        { id }              = payload;

    return navigation.set('refreshedSearches', refreshedSearches.set(id, {
        search   : payload,
        timestamp: Date.now()
    }));
};



/**
* Manage the search/member slice in the store
*
* @return navigation As a fresh Immutable Map
*/
const Navigation = (navigation = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        /**
        * Search types:
        */

        // Update the navigation with API synced member data
        case types.SEARCH_FETCHED:
            return resetNavigationSearch(navigation, action.payload);

        // Reset the lasts searches list
        case types.LASTS_SEARCHES_UPDATED:
            return navigation.set(
                'lasts_searches',
                new Immutable.List(
                    action.payload.filter
                    && action.payload.filter(query => !query.entity.entity.settings
                        || !query.entity.entity.settings.weeks)
                )
            );

        // Update the search navigation
        case types.SEARCH_STATE_FETCHED:
            return updateNavigationState(navigation, action.payload);

        // Forget the search.
        case types.SEARCH_FORGOT:
            return navigation.set('tree', null)
                .set('search', null);

        // Store refreshed search
        case types.SEARCH_REFRESHED:
            return searchRefreshed(navigation, action.payload);

            /**
        * Global navigation types
        */

        // Refresh the navigation tree a new navigation tree
        case types.INIT:
            return navigation.set('tree', action.payload);

        // Open/Close active sidebar
        case types.ACTIVATE_SHORTCUT:
            return activateShortcut(navigation, action.payload);

        default:
            break;
    }

    // Sub reducer return navigation slice on founded action
    const navigationReportAction         = NavigationReport(navigation, action),
        navigationNodesPreferencesAction = NavigationNodesPreferences(navigation, action);

    // Return the modified slice (Just one action return navigation slice)
    return navigationReportAction
        || navigationNodesPreferencesAction
        || navigation;
};

export default Navigation;
