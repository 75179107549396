import React                           from 'react';
import PropTypes                       from 'prop-types';
import { Popover }                     from 'antd';
import _                               from 'lodash';
import { isUrl, makeStrClassName }     from 'utils/text';
import { Icon }                        from 'helpers';



import './assets/infobox.less';

/**
 * Infobox class
 *
 * Use it to render the key:value format of any document.
 */
const Infobox = (props) => {    // eslint-disable-line max-lines-per-function
    /**
     * Render a element
     *
     * @param {object} el
     * @param {integer} index
     *
     * @returns JSX
     */
    const renderElement = (el) => {
        if (!el.value) {
            return null;
        }

        const  {
                titleValues, label, value
            } = el,
            valueHtml = el.isUrl || isUrl(value)
                ? (
                    <a
                        style={{ width: `${width - 110}px` }}
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {el.value}
                    </a>
                )
                : <span style={{ width: `${width - 110}px` }}>{value}</span>,
            className = makeStrClassName(['line', {'has-title': !!titleValues}]);

        return (
            <div key={`element-${value}`} className={className}>
                <div className="category">
                    {label}
                    {titleValues && titleDecorate(el)}
                </div>
                <div title={value} className="value">{valueHtml}</div>
            </div>
        );
    };

    /**
     * Render the title content
     *
     * @param {object} el
     * @returns JSX
     */
    const renderTitleContent = (el) => {
        const { titleValues } = el,
            rows              = _.values(titleValues).map(subElement => {
                const { label, value } = subElement;
                return value && (
                    <tr key={subElement.label}>
                        <td className="info-label">{label}</td>
                        <td className="info-value">{value}</td>
                    </tr>
                );
            });

        return (
            <table>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    };

    /**
     * Decorate the content with a popover
     *
     * @param {object} el
     * @param {JSX} content
     *
     * @returns JSX
     */
    const titleDecorate = (el) => {
        return (
            <Popover
                destroyTooltipOnHide
                placement="top"
                title={el.label}
                content={renderTitleContent(el)}
                getPopupContainer={triggerNode => triggerNode}
                overlayClassName="element-details"
            >
                <span className="info-tooltip-icon">
                    <Icon
                        id="info"
                        height={20}
                    />
                </span>
            </Popover>
        );
    };

    const {
            elements, width, className,
            label,
        } = props,
        elementsJSX = _.map(elements, (el) => {
            const content = renderElement(el);

            return content;
        });

    return (
        <div className="heading">
            {label && (
                <h3>
                    <span className="anchor" />
                    <span className="text">
                        {label}
                    </span>
                </h3>
            )}
            <div className={`info-box ${className}`}>
                {elementsJSX}
            </div>
        </div>
    );
};

Infobox.propTypes = {
    elements : PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    className: PropTypes.string,
    label    : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    width    : PropTypes.number,
};

Infobox.defaultProps = {
    label    : 'Overview',
    isUrl    : false,
    className: '',
};

export default Infobox;
