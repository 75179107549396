// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection.Block {
  display: block !important;
}
.collection.Block .separator {
  display: none;
}
.collection.Block .entity-block .entity-inline.is-loading {
  position: relative;
  padding: 0;
}
.collection.Block .entity-block .entity-inline.is-loading .ant-skeleton-avatar {
  width: 25px;
  height: 25px;
}
.collection.Block .group-date {
  height: 32px;
  font-size: 8px;
  text-align: center;
  position: relative;
}
.collection.Block .group-date > span {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #BFBFBF;
  position: relative;
  top: 11px;
  padding: 0 5px;
}
.collection.Block .group-date::before {
  content: '';
  height: 1px;
  background-color: #BFBFBF;
  display: block;
  top: 16px;
  width: 100%;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Collection/assets/block.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAFA;EAKQ,aAAA;AAAR;AALA;EAWY,kBAAA;EACA,UAAA;AAHZ;AATA;EAegB,WAAA;EACA,YAAA;AAHhB;AAbA;EAsBQ,YAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AANR;AAnBA;EA4BY,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;AANZ;AASQ;EACI,WAAA;EACA,WAAA;EACA,yBAAA;EACA,cAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AAPZ","sourcesContent":[".collection.Block {\n\n    display: block !important;\n\n    .separator {\n        display: none;\n    }\n\n    .entity-block {\n\n        .entity-inline.is-loading {\n            position: relative;\n            padding : 0;\n\n            .ant-skeleton-avatar {\n                width : 25px;\n                height: 25px;\n            }\n        }\n    }\n\n    .group-date {\n        height     : 32px;\n        font-size  : 8px;\n        text-align : center;\n        position   : relative;\n\n        > span {\n            text-transform  : uppercase;\n            background-color: #ffffff;\n            color           : #BFBFBF;\n            position        : relative;\n            top             : 11px;\n            padding         : 0 5px;\n        }\n\n        &::before {\n            content         : '';\n            height          : 1px;\n            background-color: #BFBFBF;\n            display         : block;\n            top             : 16px;\n            width           : 100%;\n            position        : absolute;\n        }\n    }\n\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
