// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Map graph
*
*/
.GeoMap.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.GeoMap .countries.mouseEnabled .country path {
  transition: opacity ease-out 100ms, strokeWidth ease-out 100ms;
}
.GeoMap .countries.mouseEnabled .country path:hover:not(.disabled) {
  stroke-width: 1.5;
  opacity: 0.8;
  cursor: pointer;
}
.GeoMap .countries.mouseEnabled .country path.disabled {
  cursor: not-allowed;
  transition: none;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/GeoMap/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAMG;EACI,mDAAA;AAJR;AAQQ;EACI,8DAAA;AANZ;AAQY;EACI,iBAAA;EACA,YAAA;EACA,eAAA;AANhB;AASY;EACI,mBAAA;EACA,gBAAA;AAPhB","sourcesContent":["/**\n* Map graph\n*\n*/\n\n.GeoMap {\n    svg {\n    }\n\n    &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n\n    .countries {\n        &.mouseEnabled .country path {\n            transition: opacity ease-out 100ms, strokeWidth ease-out 100ms;\n\n            &:hover:not(.disabled) {\n                stroke-width: 1.5;\n                opacity     : 0.8;\n                cursor      : pointer;\n            }\n\n            &.disabled {\n                cursor    : not-allowed;\n                transition: none;\n            }\n        }\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
