// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list.webdoc > thead th[title="Type"] {
  text-align: center;
}
.list.webdoc .row.webdoc .webdoc-title {
  margin-right: 5px;
  display: table-cell;
}
.list.webdoc .row.webdoc td:not(.webdoc-title) a {
  color: var(--insight-color);
}
.list.webdoc .row.webdoc .webdoc-type {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/webdoc.less"],"names":[],"mappings":"AAEQ;EACI,kBAAA;AADZ;AAFA;EASY,iBAAA;EACA,mBAAA;AAJZ;AANA;EAcY,2BAAA;AALZ;AATA;EAkBY,kBAAA;AANZ","sourcesContent":[".list.webdoc {\n    > thead th {\n        &[title=\"Type\"] {\n            text-align: center;\n        }\n    }\n    .row.webdoc  {\n        .webdoc-title {\n            margin-right: 5px;\n            margin-right: 5px;\n            display: table-cell;\n        }\n\n        td:not(.webdoc-title) a {\n            color: var(--insight-color);\n        }\n\n        .webdoc-type {\n            text-align: center;\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
