import _                    from 'lodash';
import Immutable            from 'immutable';
import * as types           from '../../actions/types/auth';
import { initialMap }       from '../../initialStates';

/**
 * Fetch the platform list
 *
 * @param Map    auth     The store map
 * @param object state    service model
 */
const fetchPlatforms = (auth, state) => {
    // Invalid platformlist => set a empty platforms map
    const platformsList       = state  || [],
        serviceAsObject = {};
    if(typeof (platformsList.forEach) !== 'function') {
        return auth.set('platforms', new Immutable.Map(serviceAsObject));
    }
    // Set all platforms as a map
    platformsList.forEach((service) => {
        serviceAsObject[service.url] = service;
    });

    return auth.set('platforms', new Immutable.Map(serviceAsObject));
};

/**
 * Get all token for all platforms
 *
 * @param Map    navigation The store map
 * @param object state      Navigation model
 */
const fetchPlatformsTokens = (auth, state) => {
    const tokensList       = state  || [],
        platformsList   = auth.get('platforms') || new Immutable.Map(),
        platformsObject = platformsList.toJS(),
        platformsArray   = Object.values(platformsObject),
        tokenListMap  = (typeof (tokensList.map)==='function'
            ? tokensList.map((token) => token.platform_url+token.token) : []),
        platformsArrayMap = (typeof (platformsArray.map)==='function'
            ? platformsArray.map((service) => service.url+service.token) : []),
        tokenToUpdate= _.difference(tokenListMap, platformsArrayMap);

    if(tokenToUpdate.length === 0) {
        return auth;
    }
    // Set all token in the right service
    tokensList.forEach((token) => {
        const { platform_url }= token,
            serviceFind       = platformsArray.find((service) => service.url===platform_url),
            { url }           = serviceFind || {};

        if(!serviceFind) {
            return;
        }

        platformsObject[url].token=token.token;
        platformsObject[url].token_key=token.token_key;
        platformsObject[url].platform_url=platform_url;
        platformsObject[url].api_url=token.api_url;
        platformsObject[url].id=token.id;
    });

    return auth.set('platforms', new Immutable.Map(platformsObject));
};

/**
 * Delete the right token
 */
const deleteTokensPlatforms = (auth, state) => {
    const { tokenId, isDelete}  = state  || {},
        platformsList  = auth.get('platforms') || new Immutable.Map(),
        platformsObject= platformsList.toJS(),
        platformsArray = Object.values(platformsObject),
        platform       = platformsArray.find((platform) => platform.id===tokenId);

    if(!platform || isDelete===false) {
        return auth;
    }

    platformsObject[platform.url].token=null;
    platformsObject[platform.url].id='';

    return auth.set('platforms', new Immutable.Map(platformsObject));
};


/**
* Manage the platforms slice in the store
*
* @return platforms As a fresh Immutable Map
*/
export const AuthPlatforms = (auth = initialMap, action) => {
    // Switch over actions
    switch (action.type) {
        /**
        * Search types:
        */

        // Fetch the platforms platforms
        case types.PLATFORMS_FETCH:
            return fetchPlatforms(auth, action.payload);
        case types.PLATFORMS_TOKEN:
            return fetchPlatformsTokens(auth, action.payload);
        case types.PLATFORMS_DELETE_TOKEN:
            return deleteTokensPlatforms(auth, action.payload);
        default:
            break;
    }

    return auth;
};
