/**
 * Newsletter
 *
 * @exports Component
 */

import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import PropTypes              from 'prop-types';
import _                      from 'lodash';
import{
    formatNumberWithMagnitude,
    isUrl,
    pluralize,
}                             from 'utils/text';
import {
    getTopicsForSearch, getTopicsForOrgunit
}                                   from 'utils/elements';
import{ learn }                     from 'store/actions/knowledge';
import{ Icon, CssLoader }           from 'helpers';
import{ getEntitiesModelsPromise }  from 'utils/api';
import Sentence                     from 'helpers/Search/Sentence';
import DashboardBar                 from 'helpers/DashboardBar';


import './Newsletter/assets/main.less';
import competitiveInsightsImg       from './Newsletter/assets/my-competitive-insights.png';
import topicInsightsImg             from './Newsletter/assets/my-topic-insights.png';


/**
 * Newsletter for Insight
 *
 */
class Newsletter extends Component {

    /**
    * Initialize news module
    *
    */
    constructor(props) {
        super(props);

        const validData   = _.isArray(props.data),
            entitiesData  = validData && props.data.filter((datum) => !!datum.entity),
            featuredImage = validData && props.data.find((datum) => datum.key === 'featuredImage');

        _.bindAll(this, 'onLinkClick', 'putFeaturedImageSizeInState');

        this.featuredImageRef = React.createRef();

        this.state = {
            entitiesModels: null,
            data          : entitiesData || [],
            featuredImage
        };
    }

    /**
    * Triggered when the component is ready
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['entities', 'elements', 'resources']).then(this.setState.bind(this));
    }

    /**
     * Component dis update
     */
    componentDidUpdate() {
        // Fetch entities models for entities block
        this.fetchEntitiesModels();
    }


    /**
     * Put featured image size in state (to center featured image)
     *
     */
    putFeaturedImageSizeInState() {
        const {
                featuredImage,
                featuredImageSize
            }                 = this.state,
            { current }       = this.featuredImageRef,
            { width, height } = current?.getBoundingClientRect() || {},
            imageRatio        = width / height,
            minRatio          = 1.6,
            imageBockWidth    = this.getFeaturedImageBlockWidth(),
            maxHeight         = imageBockWidth / minRatio;

        if (
            !featuredImage       // No image to center
            || featuredImageSize    // The featuredImageSize already set
            || !width
            || !height
        ) {
            return;
        }

        this.setState({
            featuredImageSize: {
                width: height > maxHeight
                    ? (imageRatio < minRatio ? width * height / maxHeight : imageBockWidth)
                    : width,
                height: height > maxHeight
                    ? (imageRatio < minRatio ? maxHeight : imageBockWidth / imageRatio)
                    : height
            }
        });
    }

    /**
    * Fetch entities and store into entitiesModels in state
    *
    * @returns void
    */
    async fetchEntitiesModels() {
        const { data, entitiesModels } = this.state,
            modelIds                   = data && data.map((data) => data.entity);

        // Already fetched or can't fetch
        if (entitiesModels || !modelIds || modelIds.length === 0 || this.entitiesModelsFetched) {
            return;
        }

        const models = await getEntitiesModelsPromise(modelIds);

        this.entitiesModelsFetched = true;

        // Store models into state component
        this.setState({
            entitiesModels: models.entities,
            queriesModels : models.queriesModels
        });
    }


    /**
    * On link click
    */
    onLinkClick() {
        const { fromCapture, close } = this.props;

        if (!fromCapture) {
            close();
        }
    }

    /**
     * Get newsletter
     *
     * @return {object} newsletter
     */
    getNewsletter() {
        const { newsletter, newsletterContent }   = this.props,
            { newsletter: newsletterFromContent } = newsletterContent || {};

        return newsletter || newsletterFromContent;
    }

    /**
    * Get entity model from state
    *
    * @param {string} entityId
    */
    getEntity(entityId) {
        const { entitiesModels } = this.state,
            model                = entitiesModels
                && entitiesModels.find
                && entitiesModels.find(model => model.entity.id === entityId);

        return model && model.entity;
    }

    /**
     * The newsletter follow searches
     *
     * @return {boolean}
     */
    isSearchNewsletter() {
        const { key } = this.getNewsletter();

        return key === 'topic-insights';
    }

    /**
    * Extract topics from knowledge (orgunit sections)
    *
    * @return array
    */
    getSectionsFromKnowledge() {
        if (!this.sectionsFromKnowledge && this.getNewsletter()) {
            this.sectionsFromKnowledge = this.getSectionsFromElements();
        }

        return this.sectionsFromKnowledge;
    }


    /**
    * Extract topics from knowledge (search sections)
    *
    * @return array
    */
    getSectionsFromElements() {
        const { elements, resources } = this.state;

        if (!elements || !resources) {
            return null;
        }

        return this.isSearchNewsletter() ? getTopicsForSearch(elements) : getTopicsForOrgunit(elements, resources);
    }


    /**
     * Finde section difinition by key ( iterate by '.' in key)
     *
     * @param {string} sectionKey
     */
    findSectionDefinition(sectionKey, sectionsDefinition = this.getSectionsFromKnowledge()) {
        const splitedKey      = sectionKey.split('.'),
            isDepthKey        = splitedKey.length > 1,
            definition        = sectionsDefinition.find(s => s.id === (isDepthKey ? splitedKey[0] : sectionKey)),
            dummyFoShift      = splitedKey.shift(), // eslint-disable-line no-unused-vars
            sectionDefinition = sectionsDefinition && (
                isDepthKey && definition ?  this.findSectionDefinition(splitedKey.join('.'), definition.subSections) : definition
            );

        return sectionDefinition || {};
    }

    /**
    * Get analyse key from section key
    *
    * @param {string} sectionKey
    *
    * @returns string
    */
    getSectionAnalyseKey(sectionKey, subsectionModelType) {
        if (this.isSearchNewsletter()) {
            return null;
        }

        const sectionDefinition = this.findSectionDefinition(sectionKey);

        if (!sectionDefinition || !sectionDefinition.subSections) {
            return;
        }

        const subSection = sectionDefinition.subSections.find(subSection => subSection.modelType === subsectionModelType)
            || sectionDefinition.subSections[0];

        return subSection.key;
    }

    /**
    * Get url of the entity
    *
    * @param {string} entityId
    *
    * @returns string
    */
    getEntityUrl(entityId) {
        return  `${process.env.OVATION_APP}/#/b/${entityId}/${this.isSearchNewsletter() ? '' : 'overview'}`;
    }


    /**
     * Render filterUpdateWeek query param based on period and ranges
     *
     * @return {string} ?filterUpdateWeek=...
     */
    getFilterUpdatePeriod() {
        const { newsletterContent } = this.props,
            { period, ranges }      = newsletterContent;

        if (!period || !ranges) {
            return;
        }

        if (period === 'month') {
            return `?filterUpdateWeek=[${ranges.join(',')}]`;
        }

        return `?filterUpdateWeek=${ranges.toString()}`;
    }

    /**
    * Get url of the section
    *
    * @param {string}  analyseKey
    * @param {string}  entityId
    * @param {boolean} isItem (optional, default false)
    *
    * @returns string
    */
    getAnalyseUrl(analyseKey, entityId, isItem = false) {
        const entityUrl        = this.getEntityUrl(entityId),
            filterUpdatePeriod = this.getFilterUpdatePeriod(),
            analyseUrl         = `${entityUrl}/${analyseKey}`;

        // If filterUpdatePeriod is set, add it to the analyse url.
        if (filterUpdatePeriod && !isItem) {
            return analyseUrl.concat(filterUpdatePeriod);
        }

        return analyseUrl;
    }

    /**
    * Get all entities definitions of the analyse
    *
    * @param {string} analyseKey
    *
    * @returns array
    */
    getEntitiesDefinitions(analyseKey) {
        if (!analyseKey) { return []; }
        const {
                elements, resources, entities
            }              = this.state,
            analyseElement = elements.find(element => element.category === 'Area/Analyse' && element.key === analyseKey),
            elementListId  = analyseElement.configuration.elements.list,
            elementList    = elements.find(element => element.id === elementListId),
            resourceId     = elementList.resource.id,
            resource       = resources.find(res => res.id === resourceId),
            { models }     = resource;

        return models.map(modelType => entities.find(entity => entity.id === modelType.type));
    }

    /**
     *
     * @param {*} sections
     */
    renderSections(sections) {
        return (
            <tr>
                <td colSpan="2" className="sections table-center-expand">
                    {sections.map((section) => this.renderEntitySection(section))}
                </td>
                <td />
            </tr>
        );
    }

    /**
    * Render block of an entity with all sections
    *
    * @param {array} sections The data to fill html
    *
    * @returns JSX
    */
    renderEntitySections(sections, path = '') {  // eslint-disable-line max-lines-per-function
        const sectionsFilled     = sections.filter(section => /* A section.total && */ section.key.indexOf(path) === 0),
            depth                = path ? path.split('.').length : 0,
            sectionDefinition    = this.findSectionDefinition(path),
            { configuration }    = sectionDefinition,
            { color }            = configuration || {},
            entityId             = sections[0].entity,
            sectionsBysubKey     = _.groupBy(sectionsFilled, section => {
                const splitedKey = section.key.split('.');

                return splitedKey.length > 1 + depth ? splitedKey[depth] : null;
            }),
            sectionsKeys         = _.keys(sectionsBysubKey),
            isSearchNewsletter   = this.isSearchNewsletter(),
            isSimpleSection      = sectionsKeys.length === 0
                || (sectionsKeys.length === 1 && sectionsKeys.includes('null')),
            sectionToRender      = isSimpleSection
                ? _.sortBy(sections, section => {
                    const { index }     = this.findSectionDefinition(section.key);
                    return index;
                })
                : _.sortBy(sectionsKeys.map(
                    sectionKey => ({
                        key     : sectionKey,
                        sections: sectionsBysubKey[sectionKey]
                    })
                ), sect => {
                    // Order by section index
                    const sectionPath =`${path}${path ? '.' :''}${sect.key}`,
                        { index }     = this.findSectionDefinition(sectionPath);

                    return index;
                }),
            sectionsNotFilled    = sections.filter((section) => section.total === 0),
            allSectionAreEmpty   = sectionsNotFilled.length === sections.length;

        return (
            <table className={`entity-sections ${color || ''}`}>
                <tbody>
                    {(isSearchNewsletter || !allSectionAreEmpty) && (
                        isSimpleSection
                            ? this.renderSections(sectionToRender)
                            : sectionToRender.map(sectionGroup => {
                                const sectionKey = path ? `${path}.${sectionGroup.key}` : sectionGroup.key;
                                return (
                                    <tr key={sectionGroup.key}>
                                        <td className={`group-depth-${depth} section-group`}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {this.renderSectionTitle({
                                                                sectionKey, entityId, isSectionGroup: true
                                                            })}
                                                            {this.renderEntitySections(
                                                                sectionGroup.sections,
                                                                sectionKey
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                );
                            })
                    )}
                    {false && allSectionAreEmpty && (
                        <tr className="table-horizontal-line">
                            <td  className="table-vertical-line" />
                            <td  className="table-center-expand" />
                            <td  className="table-vertical-line" />
                        </tr>
                    )}
                    {isSimpleSection && sectionsNotFilled.length > 0 && (
                        <>
                            <tr>
                                <td className="margin-top" />
                            </tr>
                            <tr className="sections-not-used">
                                <td className="table-center-expand">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" className="padding-top" />
                                            </tr>
                                            <tr>
                                                <td className="padding" />
                                                <td className="nothing-about">
                                                    Nothing about :
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="padding" />
                                                <td className="label">
                                                    { sectionsNotFilled.map((section) => {
                                                        const sectionKey= section.key,
                                                            sectionDefinition= this.findSectionDefinition(sectionKey),
                                                            label= sectionDefinition ?  sectionDefinition.label : null;

                                                        return label;
                                                    }
                                                    ).join(', ')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="padding-bottom" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td />
                            </tr>
                        </>
                    )}
                </tbody>
                {(depth==2 || isSearchNewsletter === false) && (
                    <tfoot>
                        <tr className="more-spacing" colSpan="3"><td>&nbsp;</td></tr>
                        <tr className="table-horizontal-line">
                            <td  className="table-center-expand" colSpan="3"
                                rowSpan="0"
                            >
                                &nbsp;
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        );
    }


    /**
     * Get query for search id
     *
     * @param {string} searchId
     */
    getQueryFromSearchId(searchId) {
        if (!searchId) {
            return null;
        }
        const { queriesModels } = this.state;

        return queriesModels.find(query => query.entity.entity.id === searchId);
    }


    /**
     * Get state of the search
     *
     * @param {object} search
     */
    getStateOfSearch(search) {
        const { newsletterContent } = this.props,
            { metadata }            = newsletterContent,
            { states }              = metadata || {},
            stateOfSearch           = states && states[search.id];

        return _.isObject(stateOfSearch) && !_.isArray(stateOfSearch) && stateOfSearch;
    }


    /**
     *
     */
    renderEntityBlock(sections) {  // eslint-disable-line max-lines-per-function
        const{ appRender, close } = this.props,
            { entitiesModels }    = this.state,
            entityId              = sections[0].entity,
            entity                = this.getEntity(entityId),
            isSearchNewsletter    = this.isSearchNewsletter(),
            { label, type }       = entity || {},
            sectionsDefinition    = this.getSectionsFromKnowledge(),
            entityUrl             = this.getEntityUrl(entityId),
            entitySearch          = this.isSearchNewsletter() && entitiesModels
                ? entitiesModels.find(entity => entity.entity.id === entityId)
                : null,
            SentenceComponant     = entitySearch ? Sentence[entitySearch.entity.mode] : null,
            searchState           = entitySearch && this.getStateOfSearch(entitySearch.entity),
            query                 = this.getQueryFromSearchId(_.get(entitySearch, 'entity.originalId')),
            entityLabel           = query && query.label !==''
                ? query.label
                : (entitySearch
                    ? (
                        <SentenceComponant
                            {...entitySearch.entity}
                            inputs={entitySearch.entity.concept}
                            lookingFor={false}
                            expand={false}
                            explain={false}
                            notEllipsed
                        />
                    ) : label
                );

        return entity && sectionsDefinition && (
            <>

                <div className="newsletter-entity-block">
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="table-center-expand">
                                    <table className="entity-header">
                                        <tbody>
                                            {isSearchNewsletter && (
                                                <>
                                                    <tr className="table-horizontal-line">
                                                        <td colSpan={(appRender && type) === 'orgunit'? '4' : '3'}
                                                            className="table-center-expand red"
                                                        />
                                                    </tr>
                                                    <tr className="padding">
                                                        <td colSpan={(appRender && type) === 'orgunit'? '4' : '3'}
                                                            className="table-center-expand"
                                                        />
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                <td className={type === 'orgunit' ? 'padding-none' : 'padding-left'} />
                                                {appRender && type === 'orgunit' && (
                                                    <td className="entity-icon">
                                                        <a onClick={this.onLinkClick} href={entityUrl}>
                                                            <Icon
                                                                id="orgunit"
                                                                folder="/entities/"
                                                                orgunitLogo={entityId}
                                                                width={28}
                                                                height={28}
                                                                borderSize={2}
                                                                borderColor="#dddddd"
                                                                discShaped
                                                            />
                                                        </a>
                                                    </td>
                                                )}
                                                <td className="entity-label">
                                                    { type === 'orgunit'
                                                        ? (
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <a
                                                                                onClick={this.onLinkClick}
                                                                                href={entityUrl}
                                                                            >
                                                                                {entityLabel}
                                                                            </a>
                                                                        </td>
                                                                        <td className="icon-link-cell">
                                                                            <a
                                                                                onClick={this.onLinkClick}
                                                                                href={entityUrl}
                                                                            >
                                                                                <Icon
                                                                                    id="link"
                                                                                    height={15}
                                                                                    width={15}
                                                                                />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        ) : (
                                                            <span>
                                                                {entityLabel}
                                                            </span>
                                                        )}
                                                </td>
                                                <td className="entity-tags" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            { isSearchNewsletter && entitySearch && searchState && (
                                <tr>
                                    <td className="dashboard-bar-cell">
                                        <DashboardBar
                                            state={searchState}
                                            key={entitySearch.id}
                                            search={entitySearch.entity}
                                            tableRender
                                            close={close}
                                        />
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td>
                                    {this.renderEntitySections(sections)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="newsletter-entity-block-separtor">
                    &nbsp;
                </div>
            </>
        );
    }

    /**
    * Render a section of the entity
    *
    * @param {object} section The data to fill html
    *
    * @returns JSX
    */
    renderEntitySection(section) {  // eslint-disable-line max-lines-per-function
        const {
                data, entity: entityId, total, totalByType
            }                   = section,
            isSearchNewsletter  = this.isSearchNewsletter(),
            showMoreLink        = data.length < total,
            sectionDefinition   = this.findSectionDefinition(section.key),
            isNewsletterable    = !!sectionDefinition.id,
            analyseKey          = this.getSectionAnalyseKey(section.key),
            sectionHref         = isSearchNewsletter
                ? this.getSectionUrl(entityId, section.key)
                : this.getAnalyseUrl(analyseKey, entityId),
            entitiesDefinitions = this.getEntitiesDefinitions(analyseKey),
            entitiesLabels      = entitiesDefinitions.map((entityDefinition) => entityDefinition.label_plural),
            subsections         = _.keys(totalByType),
            hasSubSections      = subsections.length > 0,
            isEmptySection      = total === 0;

        return isNewsletterable && !isEmptySection && (
            <table className="section">
                <tbody>
                    {!isSearchNewsletter && (
                        <tr>
                            <td className="padding">&nbsp;</td>
                        </tr>
                    )}
                    <tr className="header">
                        <td>
                            {this.renderSectionTitle({sectionKey: section.key, entityId})}
                        </td>
                    </tr>
                    {!isSearchNewsletter && (
                        <tr>
                            <td className="padding-big">&nbsp;</td>
                        </tr>
                    )}
                    <tr className="section-content">
                        <td>
                            {hasSubSections
                                ? subsections.map(
                                    subsectionModelType => this.renderContentSection(data, subsectionModelType, section, entityId)
                                )
                                : data.map((item) => this.renderItem(section, item, entityId))}
                        </td>
                    </tr>
                    {(!isSearchNewsletter && !hasSubSections && showMoreLink && entitiesLabels) && (
                        <>
                            <tr className="more">
                                <td>
                                    <a onClick={this.onLinkClick} href={sectionHref}>
                                        <table cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr className="table-horizontal-line">
                                                    <td className="table-vertical-line" />
                                                    <td className="table-center-expand" />
                                                    <td className="table-vertical-line" />
                                                </tr>
                                                <tr className="title">
                                                    <td className="table-vertical-line" />
                                                    <td className="table-center-expand label" height="30"
                                                        valign="center"
                                                    >
                                                        <a onClick={this.onLinkClick} href={sectionHref}>
                                                            {`+ ${total} ${_.toUpper(entitiesLabels[0])} AVAILABLE`}
                                                        </a>
                                                    </td>
                                                    <td  className="table-vertical-line" />
                                                </tr>
                                                <tr className="table-horizontal-line">
                                                    <td className="table-vertical-line" />
                                                    <td className="table-center-expand" />
                                                    <td className="table-vertical-line" />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </a>
                                </td>
                            </tr>
                            <tr className="more-spacing"><td>&nbsp;</td></tr>
                            <tr className="table-horizontal-line">
                                <td className="table-vertical-line" />
                            </tr>
                        </>
                    )}
                    {(!isSearchNewsletter && (!hasSubSections && !showMoreLink)) && (
                        <>
                            <tr className="table-horizontal-line">
                                <td  className="table-center-expand">
                                    &nbsp;
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        );
    }

    /**
     * Render a table box form with i a string in center
     *
     * @param {string} text
     * @returns
     */
    renderBoxText(text, sectionHref) {
        return (
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr className="table-horizontal-line">
                        <td className="table-vertical-line" />
                        <td className="table-center-expand" />
                        <td className="table-vertical-line" />
                    </tr>
                    <tr className="title">
                        <td className="table-vertical-line" />
                        <td className="table-center-expand label">
                            <a onClick={this.onLinkClick} href={sectionHref}>
                                {text}
                            </a>
                        </td>
                        <td  className="table-vertical-line" />
                    </tr>
                    <tr className="table-horizontal-line">
                        <td className="table-vertical-line" />
                        <td className="table-center-expand" />
                        <td className="table-vertical-line" />
                    </tr>
                </tbody>
            </table>
        );
    }


    /**
    * Render subsection
    *
    * @param {string} subsectionModelType
    * @param {object} section
    * @param {string} entityId
    *
    * @returns JSX
    */
    renderContentSection(data, subsectionModelType, section, entityId) {  // eslint-disable-line max-params
        const items               = data.filter((datum) => datum.type === subsectionModelType),
            // Get analyse key of subsection
            analyseKey          = this.getSectionAnalyseKey(section.key, subsectionModelType),
            // Get entities definition of the analyse
            entitiesDefinitions = this.getEntitiesDefinitions(analyseKey),
            // Get model label of the analyse
            entitiesLabels      = entitiesDefinitions.map((entityDefinition) => entityDefinition.label_plural),
            // Get url of analyse
            sectionHref         = this.getAnalyseUrl(analyseKey, entityId),
            { totalByType }     = section,
            hasItems            = items.length > 0,
            total               = totalByType[subsectionModelType],
            showMoreLink        = items.length < total,
            isSearchNewsletter  = this.isSearchNewsletter();

        return (isSearchNewsletter || hasItems) && (
            <table className="subsection" cellPadding="0"
                cellSpacing="0"
            >
                <tbody>
                    <tr className="header">
                        <td>
                            {this.renderContentSectionTitle(analyseKey, entityId)}
                        </td>
                    </tr>
                    <tr className="section-content">
                        <td>
                            {items.map((item) => this.renderItem(section, item, entityId))}
                        </td>
                    </tr>
                    { showMoreLink
                        ? (
                            <>
                                <tr className="more-spacing"><td>&nbsp;</td></tr>
                                <tr className="more">
                                    <td height="30" valign="middle">
                                        <a onClick={this.onLinkClick} href={sectionHref}>
                                            {this.renderBoxText(
                                                `+ ${total} ${_.toUpper(entitiesLabels[0])} AVAILABLE`,
                                                sectionHref
                                            )}
                                        </a>
                                    </td>
                                </tr>
                                <tr className="more-spacing"><td>&nbsp;</td></tr>
                            </>
                        ) : (<tr />
                        )}
                </tbody>
            </table>
        );
    }


    /**
     * Get url of the section
     *
     * @param {string} entityId
     * @param {string} sectionKey
     */
    getSectionUrl(entityId, sectionKey) {
        // Make full sectionKey
        const sectionDefinition = this.findSectionDefinition(sectionKey),
            sectionsKeys        = sectionKey.split('.').reduce((carry, key) => {
                const computeKey = carry.length === 0 ? key : `${_.last(carry)}.${key}`;
                carry.push(computeKey);
                return carry;
            }, []),
            sectionsDefinition = sectionsKeys.map(sectionKey => this.findSectionDefinition(sectionKey)),
            // Make path with elements categories
            categoryWithPath   = ['Area/Dashboard', 'Area/Dashboard/Item'],

            sectionPath = sectionsDefinition.reduce((carry, definition) => {
                if (categoryWithPath.indexOf(definition.category) === -1) {
                    return carry;
                }
                return carry.length === 0 ? definition.id : `${carry}/${definition.id}`;
            }, '');

        return categoryWithPath.indexOf(sectionDefinition.category) !== -1
            ? this.getEntityUrl(entityId) + sectionPath
            : null;
    }

    /**
    * Render section title (title with icon)
    *
    * @param {string} sectionKey
    * @param {string} entityId
    *
    * @returns JSX
    */
    renderSectionTitle(params) {  // eslint-disable-line max-lines-per-function
        const {
                sectionKey, entityId, isSectionGroup, sectionIsEmpty
            } = params,
            sectionDefinition = this.findSectionDefinition(sectionKey),
            // Disable sectionHref for orgunit must have an anchor on idcard section
            sectionHref       = !sectionIsEmpty && this.isSearchNewsletter()
                ? this.getSectionUrl(entityId, sectionKey)
                : null,
            { configuration } = sectionDefinition,
            { color, icon }   = configuration || {};

        return (
            <table cellPadding="0" cellSpacing="0"
                className={isSectionGroup ? 'section-group-title' : 'section-title'}
            >
                <tbody>
                    <tr>
                        { icon && (
                            <td className="section-icon">
                                <Icon id={icon} folder="/id-cards/"
                                    width={18} color="#d14936"
                                />
                            </td>
                        )}
                        <td className={`label ${color || 'base'}`}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="label">
                                            {sectionHref ? (
                                                <a onClick={this.onLinkClick} href={sectionHref}>
                                                    {_.startCase(_.toLower(sectionDefinition.label))}
                                                </a>
                                            ) : (
                                                <table cellPadding="0" cellSpacing="0"
                                                    className="section-label"
                                                >
                                                    <tbody>
                                                        <tr className="first-row"><td colSpan="3" /></tr>
                                                        <tr>
                                                            <td className="padding">&nbsp;</td>
                                                            <td>{_.toUpper(sectionDefinition.label)}</td>
                                                            <td className="padding">&nbsp;</td>
                                                        </tr>
                                                        <tr className="last-row"><td colSpan="3" /></tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </td>
                                        {sectionHref && (
                                            <td className="link">
                                                <a onClick={this.onLinkClick} href={sectionHref}>
                                                    <Icon
                                                        id="link"
                                                        width={25} height={25}
                                                    />
                                                </a>
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="margin-bottom" />
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    /**
    * Render section title (title with icon)
    *
    * @param {string} analyseKey
    * @param {string} entityId
    *
    * @returns JSX
    */
    renderContentSectionTitle(analyseKey, entityId) {
        const { elements }   = this.state,
            sectionHref    = this.getAnalyseUrl(analyseKey, entityId),
            analyseElement = elements.find((element) => element.category === 'Area/Analyse' && element.key === analyseKey),
            { label }      = analyseElement || {};

        if (!analyseElement) {
            console.log('No analyseElement found, analyseKey : ', analyseKey);
            return null;
        }

        return (
            <div className="subsection-title">
                <table>
                    <tbody>
                        <tr>
                            <td className="label">
                                <a onClick={this.onLinkClick} href={sectionHref}>
                                    {label}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className="padding-bottom" />
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * Get news meta
     */
    getNewsMeta(item, sectionUrl) {
        const { arguments:arg } = item,
            newsObj           = arg.find((a) => a.type === 'news'),
            authorObj         = arg.find((a) => a.type === 'author'),
            domainRegex       = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/im,
            domainFounded     = newsObj && domainRegex.exec(newsObj.id),
            domain            = domainFounded && domainFounded[1],
            author            = authorObj && authorObj.label,
            { arguments:args }     = item,
            { id:replaceEntityId } = args[0] || {},
            // Search url in argument id
            replacementIsUrl       = isUrl(replaceEntityId),
            href                   = replacementIsUrl
                // Simple url
                ? replaceEntityId
                // Link to entity in section
                :  `${sectionUrl}/${replaceEntityId}/overview`;

        return { domain, author, href };
    }

    /**
     * Replace word by html element using arguments of the item
     *
     * @param {string} word    Word of sentence (replace if is %s or %entity)
     * @param {object} options
     */
    replaceItemPatern(word, options) {  // eslint-disable-line max-lines-per-function
        const { appRender }                           = this.props,
            { sectionUrl, entityHref, entityId, item} = options,
            { arguments:args }                        = item,
            entity                                    = this.getEntity(entityId),
            { label: entityLabel }                    = entity,
            argumentRegex                             = /(.*)%s(.*)/i,
            argumentPaternFound                       = argumentRegex.exec(word),
            entityRegex                               = /(.*)%entity(.*)/i,
            entityPaternFound                         = entityRegex.exec(word),
            // Get argument attibutes
            { id: replaceEntityId, label, type }      = args[options.argumentIndex] || {},
            // Search url in argument id
            replacementIsUrl                          = isUrl(replaceEntityId);

        // Is a %s patern
        if (argumentPaternFound) {
            const elementType = type === 'amount' ? 'span' : 'a',
                href          = replacementIsUrl
                    // Simple url
                    ? replaceEntityId
                    // Link to entity in section
                    :  `${sectionUrl}/${replaceEntityId}/overview`;

            options.argumentIndex++;           // Next %s  BAD pratice
            // Create a html element
            return React.createElement(
                // Tag type
                elementType,
                // Props
                {
                    href,
                    target : replacementIsUrl ? '_blank' : null,
                    onClick: this.onLinkClick,
                },
                // Children
                [type === 'orgunit' && appRender
                        && (
                            <span className="orgunit-logo">
                                <Icon
                                    id="orgunit"
                                    folder="/entities/"
                                    orgunitLogo={replaceEntityId}
                                    width={18}
                                    height={18}
                                    borderSize={1}
                                    borderColor="#dddddd"
                                    discShaped
                                />
                            &nbsp;
                            </span>
                        ),
                argumentPaternFound[1],  /* Pre residual string */
                type === 'amount' ? formatNumberWithMagnitude(label) : label,
                argumentPaternFound[2],  /* Post residual string */
                ]
            );
        }

        // Is a %entity patern
        if (entityPaternFound) {
            return (
                <a onClick={this.onLinkClick} href={entityHref}>
                    { entity.type === 'orgunit' && appRender
                        && (
                            <span className="orgunit-logo">
                                <Icon
                                    id="orgunit"
                                    folder="/entities/"
                                    orgunitLogo={entityId}
                                    width={18}
                                    height={18}
                                    borderSize={1}
                                    borderColor="#dddddd"
                                    discShaped
                                />
                            &nbsp;
                            </span>
                        )}
                    {entityPaternFound[1]  /* Pre residual string */}
                    {entityLabel}
                    {entityPaternFound[2]  /* Post residual string */}
                </a>
            );
        }

        return (
            <span>
                {word}
            </span>
        );
    }

    /**
    * Render section item line
    *
    * @param {array} section
    * @param {array} item
    * @param {string} entityId
    *
    * @returns JSX
    */
    renderItem(section, item, entityId) {   // eslint-disable-line max-lines-per-function
        const {
                label: itemLabelPatern,
                date
            }                 = item,
            analyseKey        = this.getSectionAnalyseKey(section.key),
            sectionUrl        = this.isSearchNewsletter()
                ? this.getSectionUrl(entityId, section.key)
                : this.getAnalyseUrl(analyseKey, entityId, !!item),
            entityHref        = this.getEntityUrl(entityId),
            // Cut sentence by space
            cuttedSentence    = itemLabelPatern.split(' '),
            options           = {
                argumentIndex: 0,
                sectionUrl,
                entityHref,
                entityId,
                item
            },
            // Get news meta (domain / author)
            newsMeta          = this.getNewsMeta(item, sectionUrl),
            // Map word of sentence find patern (%s or %entity)
            cuttedReplaced    = cuttedSentence.map(word => this.replaceItemPatern(word, options)),
            fullDateObj       = new Date(date),
            formatedDate      = fullDateObj.toLocaleString('en-US', {
                weekday: 'long', year: 'numeric', month: 'long', day: '2-digit'
            }),
            dateSpan          = formatedDate !== 'Invalid Date' ? <td className="date">{formatedDate}</td> : null,
            isSearchNewsletter= this.isSearchNewsletter();
        return (
            <table className={'item' + (section.key === 'news' ? (' news') : '')} cellPadding="0"
                cellSpacing="0"
            >
                <tbody>
                    { !isSearchNewsletter && section.key === 'news' && (
                        <tr>
                            {section.key === 'news' ? dateSpan : <td />}
                            <td className="icon-link-cell" valign="middle">
                                {section.key === 'news' && newsMeta.href && (
                                    <a href={newsMeta.href}>
                                        <Icon
                                            id="link"
                                            height={10}
                                            width={10}
                                        />
                                    </a>
                                )}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className={'label' + (section.key === 'news' ? (' news') : '')}>
                            {cuttedReplaced.map(el => [el, ' '])}
                        </td>
                        <td />
                    </tr>
                    <tr className="meta">
                        <td>
                            {newsMeta.domain && (
                                <span className="domain">
                                    {newsMeta.domain}
                                </span>
                            )}
                            {newsMeta.author && (
                                <span className="author">
                                    &nbsp;-
                                    {' '}
                                    {newsMeta.author}
                                </span>
                            )}
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td className={isSearchNewsletter ? 'padding-small' : 'padding-big'} colSpan="2">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    /**
     * Get featured image block width
     *
     * @returns integer
     */
    getFeaturedImageBlockWidth() {
        const { fromCapture } = this.props;

        return fromCapture ? 568 : 608;
    }

    /**
    * Render news picture at top
    *
    *  @returns JSX
    */
    renderNewsPicture() {  // eslint-disable-line max-lines-per-function
        const {
                featuredImage,
                featuredImageSize,
            }                    = this.state,
            { base64, src, url } = featuredImage || {},
            width                = this.getFeaturedImageBlockWidth(),
            imageHeight          = featuredImageSize?.height || null,
            imageWidth           = featuredImageSize?.width || null,
            height               = imageHeight;

        return featuredImage && (
            <div className="news-picture-block">
                <table cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr className="table-horizontal-line">
                            <td className="table-center-expand red" />
                        </tr>
                        <tr>
                            <td>
                                <a onClick={this.onLinkClick} href={url}
                                    target="_blank" rel="noreferrer"
                                >
                                    <svg width={width} height={height}
                                        xmlns="https://www.w3.org/2000/svg"
                                        xmlnsXlink="https://www.w3.org/1999/xlink" zoomAndPan="magnify"
                                    >
                                        <g>
                                            <rect x="0" y="0"
                                                width={width} height={height}
                                                fill="#F7F7F7"
                                            />
                                            <image
                                                ref={this.featuredImageRef}
                                                xlinkHref={base64 || src}
                                                x={imageWidth ? (width - imageWidth) / 2 : null}
                                                y="0"
                                                width={imageWidth}
                                                height={imageHeight}
                                                onLoad={this.putFeaturedImageSizeInState}
                                            />
                                            <rect x="0" y="0"
                                                width="75" height="20"
                                                fill="#D14936"
                                            />
                                            <text x="4" y="12"
                                                fontFamily="helvetica"
                                                fontWeight="bold" fontSize="10"
                                                fill="#ffffff"
                                            >
                                                WHAT&rsquo;S NEW
                                            </text>
                                        </g>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
            </div>
        );
    }

    /**
     * Get period like WEEKLY or MONTHLY
     */
    getPeriodString() {
        const { newsletterContent } = this.props,
            { period, tag }         = newsletterContent || {},
            periodTag               = tag[4] === 'w' ? 'week' : 'month', // "OlD" newsletter period method.
            periodStr               = (period ?? periodTag) + 'ly';

        return periodStr;
    }

    /**
    * The render when all entity are empty sections
    *
    * @returns JSX
    */
    renderAllEntitiesAreEmpty() {   // eslint-disable-line  max-lines-per-function
        const { appRender }    = this.props,
            { period }         = this.getNewsletter(),
            sectionsByEntity   = this.getSectionsByEntity(),
            entitiesIds        = _.keys(sectionsByEntity),
            entities           = entitiesIds.map(entityId => this.getEntity(entityId)),
            periodString       = period && period.toUpperCase && period.toUpperCase(),
            isSearchNewsletter = this.isSearchNewsletter();

        return (
            <div className="all-entities-are-empty newsletter-entity-block">
                It looks like it has been
                <br />
                <span className="big">
                    PRETTY QUIET THESE DAYS
                </span>
                <br />
                {isSearchNewsletter ? pluralize('Search', entities.length) : 'Companies & organizations'} you’re tracking have
                <br />
                <span className="big">
                    NO UPDATE TO SHARE THIS {periodString}.
                </span>
                <div className="entities">
                    <table>
                        <tbody>
                            {entities.map(entity => {
                                const { id, label, type } = entity || {},
                                    entityUrl             = id && this.getEntityUrl(id),
                                    isASearch             = type === 'query',
                                    SentenceComponant     = isASearch ? Sentence[entity.mode] : null,
                                    query                 = this.getQueryFromSearchId(_.get(entity, 'originalId')),
                                    entityLabel           = query && query.label !==''
                                        ? query.label
                                        : (isASearch
                                            ? (
                                                <SentenceComponant
                                                    {...entity}
                                                    inputs={entity.concept}
                                                    lookingFor={false}
                                                    expand={false}
                                                    explain={false}
                                                    notEllipsed
                                                />
                                            ) : label
                                        );


                                return id && (
                                    <tr>
                                        <td className="entity-cell">
                                            <a key={id} onClick={this.onLinkClick}
                                                href={entityUrl}
                                            >
                                                { appRender && type === 'orgunit' && (
                                                    <span className="orgunit-logo">
                                                        <Icon
                                                            id="orgunit"
                                                            folder="/entities/"
                                                            orgunitLogo={id}
                                                            width={18}
                                                            height={18}
                                                            borderSize={1}
                                                            borderColor="#dddddd"
                                                            discShaped
                                                        />
                                                    &nbsp;
                                                    </span>
                                                )}
                                                <span className="entity-label">
                                                    {entityLabel}
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }


    /**
     * Render the button to show more on
     *
     * @returns JSX
     */
    renderMoreToShow() {
        const { newsletterContent } = this.props,
            { tag }                 = newsletterContent,
            { id, key }             = this.getNewsletter();

        return (
            <div className="more-to-show">
                <table width="100%" cellSpacing="0"
                    cellPadding="0"
                >
                    <tbody>
                        <tr>
                            <td>
                                <a
                                    href={`${process.env.OVATION_APP}/#/r/newsletter/${key}/${id}/${tag}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <table className="button" cellSpacing="0"
                                        cellPadding="0"
                                    >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <a
                                                        href={`${process.env.OVATION_APP}/#/r/newsletter/${key}/${id}/${tag}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {/* To remove purple color visited */}
                                                        <strong style={{ fontWeight: 'normal' }}>
                                                            View all updates
                                                        </strong>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * Get sections grouped by entity and ordered by definition (like on orgunit idcard)
     *
     * @returns object
     */
    getSectionsByEntity() {
        const { data }         = this.state,
            sectionsDefinition = this.getSectionsFromKnowledge(),
            sectionsKeys       = sectionsDefinition ? sectionsDefinition.map((section) => section.id) : [],
            sectionsByEntity   = data ? _.groupBy(data, (datum) => datum.entity) : {},
            orderedBySection   = _.mapValues(
                sectionsByEntity,
                (sections) => _.sortBy(
                    sections, (section) => sectionsKeys.indexOf(section.key)
                )
            );

        return orderedBySection;
    }

    /**
     * Get entities ids ordered by section count
     *
     * @returns array
     */
    getorderedEntitieIds(sectionsByEntity) {
        const entitySectionCount = _.mapValues(
            sectionsByEntity,
            (sectionEntity, entityId)  => ({
                count: -sectionEntity.filter((section) => section.total > 0).length,
                entityId
            })
        );

        return _.sortBy(entitySectionCount, ['count']).map(entityCount => entityCount.entityId);
    }

    /**
     * Get formated date of newsletter
     *
     * @return string
     */
    getFormatedDate() {
        const { newsletterContent } = this.props,
            { date_create }         = newsletterContent,
            fullDateObj             = date_create ? new Date(date_create) : new Date();

        return fullDateObj.toLocaleString('en-US', { weekday: 'long', month: 'long', day: '2-digit' });
    }


    /**
    * Render the newsletter
    *
    * @returns JSX
    */
    render() {  // eslint-disable-line max-lines-per-function
        const {
                entityMax,
                newsletterContent,
                fromCapture, openSettings
            }                   = this.props,
            {
                data, entitiesModels, featuredImage,
                featuredImageSize
            }                   = this.state,
            { id, key, label }  = this.getNewsletter(),
            { tag }             = newsletterContent || {},
            sectionsByEntity    = this.getSectionsByEntity(),
            entitiesIds         = this.getorderedEntitieIds(sectionsByEntity) || [],
            entitiesToShow      = fromCapture && entityMax ? entitiesIds.slice(0, entityMax) : entitiesIds,
            allEntitiesAreEmpty = data && !data.find((section) => section.total > 0),
            moreEntitiesToShow  = entitiesToShow.length < entitiesIds.length,
            hereHref            = fromCapture
                ? `${process.env.OVATION_APP}/#/r/newsletter/${key}/${id}/${tag}/settings`
                : null,
            classNames          = ['newsletter-module'],
            isSearchNewsletter  = this.isSearchNewsletter(),
            period              = this.getPeriodString(),
            loader              = (
                <div className="loader">
                    <CssLoader type="ripple" size={40}
                        thickness={2} color="#dddddd"
                    />
                </div>
            ),
            isLoaded = entitiesModels && (!featuredImage  || featuredImageSize),
            date = this.getFormatedDate();

        if (!data && !this.getNewsletter()) {
            return loader;
        }

        if (fromCapture) {
            classNames.push('from-capture');
        }

        return (
            <table align="center" width="100%"
                bgcolor="#F7F7F7" cellPadding="0"
                cellSpacing="0"
                className={'email-center inline-html newsletter'
                    + (isSearchNewsletter ? '-search': '-company')
                    + (isLoaded ? ' loaded' : '')}
            >
                <tbody>
                    <tr className="page-padding">
                        <td />
                    </tr>
                    <tr>
                        <td align="center">
                            <table bgcolor="#FFFFFF" cellPadding="0"
                                cellSpacing="0" className={classNames.join(' ')}
                            >
                                <tr className="top">
                                    <td className="table-vertical-line" />
                                    <td>
                                        <img
                                            src={isSearchNewsletter ? topicInsightsImg : competitiveInsightsImg}
                                            alt={`Insight - ${label}`}
                                            height="61"
                                            width={isSearchNewsletter ? 263 : 285}
                                        />
                                    </td>
                                    <td className="table-vertical-line" />
                                </tr>
                                { period && (
                                    <tr className="info">
                                        <td className="table-vertical-line" />
                                        <td>
                                            {`Your ${period} insight feed - ${date.toLowerCase()}`}
                                        </td>
                                        <td className="table-vertical-line" />
                                    </tr>
                                )}
                                <tr className="intro">
                                    <td className="table-vertical-line" />
                                    <td>
                                        {!entitiesModels && loader}
                                    </td>
                                    <td className="table-vertical-line" />
                                </tr>
                                <tr className="entities-block">
                                    <td className="table-vertical-line" />
                                    <td>
                                        {this.renderNewsPicture()}
                                        {
                                            allEntitiesAreEmpty
                                                ? this.renderAllEntitiesAreEmpty()
                                                : entitiesToShow.map(
                                                    entityId => this.renderEntityBlock(sectionsByEntity[entityId])
                                                )
                                        }
                                        {moreEntitiesToShow && this.renderMoreToShow()}
                                    </td>
                                    <td className="table-vertical-line" />
                                </tr>
                                <tr className="footer">
                                    <td className="table-vertical-line" />
                                    <td>
                                        <div>
                                            <span>Update your email preferences or unsubscribe&nbsp;</span>
                                            <a onClick={!fromCapture && openSettings} href={hereHref}>here</a>
                                        </div>
                                    </td>
                                    <td className="table-vertical-line" />
                                </tr>
                                <tr className="footer-padding">
                                    <td className="table-vertical-line" />
                                    <td />
                                    <td className="table-vertical-line" />
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr className="page-padding">
                        <td />
                    </tr>
                </tbody>
            </table>
        );
    }

}

/**
* Validate properties for current Component
*/
Newsletter.propTypes ={
    appRender        : PropTypes.bool,
    data             : PropTypes.arrayOf(PropTypes.object),
    entityMax        : PropTypes.number,
    fromCapture      : PropTypes.bool,
    close            : PropTypes.func,
    learnKnowledge   : PropTypes.func,
    openSettings     : PropTypes.any,
    newsletter       : PropTypes.any, //! TODO
    newsletterContent: PropTypes.shape({
        metadata   : PropTypes.object,
        date_create: PropTypes.string,
        tag        : PropTypes.string,
        period     : PropTypes.string,
        ranges     : PropTypes.arrayOf(PropTypes.string),
    })
};

Newsletter.defaultProps = {
    date       : '-',
    entityMax  : 3,
    appRender  : false,
    fromCapture: false,
    tag        : '',
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Newsletter);
