// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions.filters-entities {
  position: initial;
  top: auto;
  left: auto;
}
.ant-dropdown.filters-entities-menu .icon-container .icon-entities-type {
  top: -1px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Filters/assets/entities.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,SAAA;EACA,UAAA;AACJ;AAEA;EAEQ,SAAA;EACA,kBAAA;AADR","sourcesContent":[".actions.filters-entities {\n    position: initial;\n    top     : auto;\n    left    : auto;\n}\n\n.ant-dropdown.filters-entities-menu {\n    .icon-container .icon-entities-type{\n        top     : -1px;\n        position: relative;\n    }\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
