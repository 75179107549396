/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Icon }             from 'helpers';

import './assets/sentence.less';

/**
* Render The "Express" menu
*
*/
class Express extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this);
    }

    /**
    * Render the express search
    *
    * @return JSX
    */
    renderRawSearch() {
        const { label,
                notEllipsed } = this.props,
            className         = `raw-input ${notEllipsed ? '' : 'ellipsis'}`;

        return (
            <div className={className}>
                {label}
            </div>
        );
    }

    /**
    * Render the generated by express
    *
    * @return JSX
    */
    renderExpressStamp() {
        return (
            <div className="express-stamp">
                <table>
                    <tbody>
                        <tr>
                            <td className="generated-by">
                                Generated by
                            </td>
                            <td className="express-icon">
                                <Icon id="orbit-express"
                                    width={16} height={16}
                                />
                            </td>
                            <td>
                                Orbit
                            </td>
                            <td className="express-blue">
                                <span>&nbsp;Express</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    /**
    * Render the human readable Express from inputs
    *
    * @return JSX
    */
    render() {
        const { concept, stamp } = this.props;

        if (!concept) {
            return false;
        }

        return (
            <>
                <div className="sentence express">
                    {this.renderRawSearch()}
                    {!stamp ? this.renderExpressStamp() : null}
                </div>
                {stamp}
            </>
        );
    }

}

Express.propTypes = {
    concept    : PropTypes.any,
    label      : PropTypes.string,
    stamp      : PropTypes.any,
    notEllipsed: PropTypes.bool,
};

Express.defaultProps = {
    notEllipsed: false,
};

export default Express;
