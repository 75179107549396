import React, { PureComponent }          from 'react';
import { connect }                   from 'react-redux';
import PropTypes                     from 'prop-types';

import _                             from 'lodash';
import { Skeleton, Tooltip }         from 'antd';

import { learn }                     from 'store/actions/knowledge';
import {
    htmlize, stripTags, isUrl,
    str2DomFormat
}                                    from 'utils/text';
import { Icon, Flag }                from 'helpers';
import SwiftGauge                    from '../../Graph/types/SwiftGauge';

import './assets/orgunit.less';

/**
* Render Orgunit entities
*
*/
class Orgunit extends PureComponent {

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['entities', 'orgClasses', 'countries', 'tags'])
            .then(this.setState.bind(this));
    }

    /**
    * Get the name of a country given its ISO2 code
    *
    * @return string
    */
    getCountryName(isoCode) {
        const { countries } = this.state;

        if (!countries) {
            return null;
        }

        const countryMdl = countries.find((country) => country.id === isoCode);

        if (!countryMdl) {
            return 'Unknown';
        }

        return countryMdl.label;
    }

    /**
    * Returns whether the entity is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Get the name of a country given its ISO2 code
    *
    * @return string
    */
    renderOrgClass(orgClassId) {
        const { orgClasses } = this.state;

        if (!orgClasses) {
            return false;
        }

        const orgClassMdl = orgClasses.find((orgClass) => orgClass.id === orgClassId);

        if (!orgClassMdl) {
            return false;
        }

        return orgClassMdl.label;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                showExpandedContent,
                columns,
            }                 = this.props,
            swiftGaugeColumns = ['scidoc', 'patent', 'clinicaltrial', 'project'];

        /**
        * TODO: Do not forget to add columns
        * Scientific work, Invention, Clinical study, Project for gauges
        * in new DB table model_column
        */

        if (showExpandedContent) {
            return null;
        }

        // Real row display
        return (
            _.map(columns, column => {
                const renderFuncName = `render${_.upperFirst(_.camelCase(column.id))}`;

                if (this[renderFuncName]) {
                    return this[renderFuncName]();
                }

                if (_.includes(swiftGaugeColumns, column.id)) {
                    return this.renderSwiftGauge(column.id);
                }

                return null;
            })
        );
    }

    /**
    * Render Name
    *
    * @return JSX
    */
    renderOrgunitName() {
        const {
                entity, renderActiveStatus,
                renderModelAction, onClick, renderOpenableIcon,
            }                        = this.props,
            { clickable, label, id } = entity,
            classNames               = ['label'];

        if (clickable) { classNames.push('clickable'); }

        return (
            <td key="name" className={classNames.join(' ')}
                onClick={clickable ? onClick : null}
            >
                <div className="left">
                    <Icon
                        id="orgunit"
                        className="logo"
                        folder="/entities/"
                        width={18}
                        height={18}
                        discShaped
                        orgunitLogo={id}
                        borderSize={1}
                        borderColor="#888"
                        style={{top: '0px'}}
                    />
                    <span className="ellipsis name" title={label}>{label}</span>
                    {clickable && renderOpenableIcon()}
                </div>
                {renderActiveStatus()}
                {clickable && renderModelAction()}
            </td>
        );
    }


    /**
    * Render Aka
    *
    * @return JSX
    */
    renderOrgunitAka() {
        const { entity } = this.props;

        return (
            <td key="aka" className="ellipsis">
                {entity.org_aka}
            </td>
        );
    }

    /**
    * Render Aka
    *
    * @return JSX
    */
    renderAkaContent() {
        const { entity } = this.props;

        if (!entity.org_aka) { return null; }

        return (
            <>
                <div className="label">A K A</div>
                <div className="content content-ellipsis" style={{ marginBottom: 10}}>
                    {entity.org_aka}
                </div>
            </>
        );
    }

    /**
     * Render swift gauge by document type: patent, scidoc, project, clinicaltrial
     *
     * @param {string} type
     *
     * @returns JSX
     */
    renderSwiftGauge(type) {
        const { entity } = this.props,
            { scores }   = entity,
            score        = scores[type],
            entityType   = this.getEntity(type),
            { label }    = entityType || {};

        return (
            <td key={type}>
                <SwiftGauge ratio={score} label={_.upperFirst(label)} />
            </td>
        );
    }

    /**
     * Get entities by type
     *
     * @param {string} type
     *
     * @returns {object}
     */
    getEntity(type) {
        const { entities } = this.state,
            entitiesArray  = entities?.toArray() || [],
            entity         = entitiesArray.find(entity => entity.id === type);

        return entity;
    }


    /**
    * Render tags for current bookmark
    *
    * @param object entity
    *
    * @return JSX
    */
    renderOrgunitClassification() {
        const {
                bookmark,
                entity, compiledTags
            }                     = this.props,
            { tags }              = this.state,
            { id }                = entity,
            currentTags           = tags?.filter(tag => bookmark && bookmark.tags.includes(tag.id));

        if (!bookmark || !compiledTags || !currentTags) {
            return <td key="tags" />;
        }

        return (
            <td key="tags" className="tags">
                {currentTags.map(tag => {
                    const size      = 18,
                        tagsInScope = !compiledTags || compiledTags[tag.id] && compiledTags[tag.id].includes(id),
                        title       = tag.label + (!tagsInScope ? ' (not in the specified folder)' : ''),
                        style       = {
                            color   : tag.color,
                            height  : size,
                            width   : size,
                            fontSize: 20
                        };

                    return (
                        <Icon
                            key={tag.id}
                            dataQaKey={str2DomFormat(`${entity.label} ${title}`)}
                            type={tag.icon}
                            color={tag.color}
                            theme={tagsInScope ? 'filled' : 'outlined'}
                            style={style}
                            height={size}
                            width={size}
                            title={title}
                            className="icon"
                        />
                    );
                })}
            </td>
        );
    }

    /**
    * Render Type
    *
    * @return JSX
    */
    renderOrgunitType() {
        const { entity } = this.props;

        return (
            <td key="type" className="ellipsis type">
                {this.renderOrgClass(entity.org_class)}
            </td>
        );
    }

    /**
    * Render Acronym
    *
    * @return JSX
    */
    renderOrgunitAcronym() {
        const { entity } = this.props;

        return (
            <td  key="acronym" className="ellipsis acronym">{entity.org_acronym}</td>
        );
    }

    /**
    * Render Country
    *
    * @return JSX
    */
    renderOrgunitCountry() {
        const { entity } = this.props;

        return (
            <td key="country" className="ellipsis country">
                {entity.country && (
                    <>
                        <Flag iso={entity.country} height={17}
                            className="flag" borderColor="#dddddd"
                            borderSize={1}
                        />
                        <span className="country-label">{this.getCountryName(entity.country)}</span>
                    </>
                )}
            </td>
        );
    }

    /**
    * Render concepts / topics
    *
    * @return JSX
    */
    renderTopics() {
        const { entity } = this.props,
            topics       = entity.topics || false,
            joinTopics   = topics ? topics.join(', ') : false;

        if (!topics) {
            return false;
        }

        return (
            <div className="topics">
                <span className="label">
                    Mainly works on
                </span>
                <span className="content">
                    <Tooltip
                        key={joinTopics}
                        mouseEnterDelay={2}
                        placement="left"
                        title={htmlize(joinTopics)}
                    >
                        {joinTopics}
                    </Tooltip>
                </span>
            </div>
        );
    }

    /**
    * Render the description
    *
    * @return JSX
    */
    renderDescription() {
        const { entity } = this.props,
            description  = _.get(entity, 'descriptions.presentation.description', false);

        if (!description) {
            return false;
        }

        return (
            <>
                <div className="label">Presentation</div>
                <div className="content content-ellipsis">
                    {stripTags(description)}
                </div>
            </>
        );
    }

    /**
    * Render the expanded content for the list
    *
    * @return JSX
    */
    renderExpandedContent() { // eslint-disable-line max-lines-per-function
        const {
                entity,
                showExpandedContent
            }      = this.props,
            webUrl = _.get(entity, 'eAddresses.website', false);

        if (!showExpandedContent) { return false; }

        return (
            <>
                <td />
                <td className="logo" colSpan="2">
                    <Icon
                        id="orgunit"
                        folder="/entities/"
                        width={100}
                        height={100}
                        discShaped
                        orgunitLogo={entity.id}
                        borderSize={2}
                        borderColor="#888"
                    />
                </td>
                <td className="abstract" colSpan="100">
                    <div className="expanded-text-container">
                        <div className="text">
                            {this.renderAkaContent()}
                            {this.renderDescription()}
                        </div>
                        <div className="text">
                            {webUrl && isUrl(webUrl) && (
                                <div className="web">
                                    <span className="label">Web</span>
                                    <span className="content">
                                        <a href={webUrl} target="_blank"
                                            rel="noopener noreferrer"
                                        >{webUrl}
                                        </a>
                                    </span>
                                </div>
                            )}
                            {this.renderTopics()}
                        </div>
                    </div>
                </td>
            </>
        );
    }

    /**
    * Render loading patent row
    *
    * @return JSX
    */
    renderLoading() {
        const {
                showExpandedContent,
                columns,
            }               = this.props,
            numberOfColumns = columns.length + (showExpandedContent ? 2 : 0);

        if (!this.isLoading()) {
            return false;
        }

        return (
            _.map(_.range(numberOfColumns), index => {
                return (
                    <td key={index}>
                        <Skeleton loading active
                            paragraph={false}
                        />
                    </td>
                );
            })
        );
    }

    /**
    * Render the patent row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render patent row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Orgunit.propTypes = {
    bookmark: PropTypes.oneOfType([
        PropTypes.shape({tags: PropTypes.array}),
        PropTypes.bool
    ]),
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    entity : PropTypes.shape({
        clickable  : PropTypes.bool,
        country    : PropTypes.any,
        entity     : PropTypes.object,
        id         : PropTypes.string,
        isLoading  : PropTypes.bool,
        label      : PropTypes.string,
        org_acronym: PropTypes.any,
        org_aka    : PropTypes.any,
        org_class  : PropTypes.any,
        scores     : PropTypes.object,
        topics     : PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
        type       : PropTypes.string,
    }).isRequired,
    compiledTags       : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    learnKnowledge     : PropTypes.func.isRequired,
    onClick            : PropTypes.func,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    showExpandedContent: PropTypes.bool,
};

Orgunit.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Orgunit);

