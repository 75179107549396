import _                         from 'lodash';
import React, { Component }      from 'react';
import PropTypes                 from 'prop-types';
import Select                    from 'helpers/Select';
import notification              from 'helpers/notification.js';
import { query }                 from 'core/utils/Yoomap';
import { Modal }                 from 'antd';
import { connect }               from 'react-redux';
import { deleteTokensPlatforms } from 'store/actions/auth/platforms.js';
import { emitEvent }             from 'store/actions/sockets';

// CSS
import './assets/Export/main.less';

const selectsConfiguration = [
    {
        key              : 'modules',
        label            : 'Module',
        range            : 0,
        orderFields      : ['label'],
        orderByPagination: false,
        render           : {
            isHideBeforeEmpty    : false,
            isDisabledBeforeEmpty: false,
            showErrorEmpty       : true
        },
        requestVar: {
            isForPartner       : true,
            datafieldTypes     : ['file'],
            datafieldIsMultiple: true
        }
    },
    {
        key              : 'data',
        label            : 'Data',
        range            : 10,
        orderFields      : ['label'],
        orderByPagination: true,
        render           : {
            isHideBeforeEmpty    : false,
            isDisabledBeforeEmpty: true,
            showErrorEmpty       : true
        },
        requestVar: {
            limit   : 0,
            offset  : 0,
            datatype: '',
            search  : { value: '' },
            order   : {
                columns: [
                    {
                        column   : 'title',
                        direction: 'asc'
                    }
                ]
            }
        }
    },
    {
        key              : 'fields',
        label            : 'Field',
        range            : 0,
        orderFields      : ['label'],
        orderByPagination: false,
        render           : {
            isHideBeforeEmpty    : true,
            isDisabledBeforeEmpty: true,
            showErrorEmpty       : true
        },
        requestVar: {
            datatype           : '',
            isForPartner       : true,
            datafieldTypes     : ['file'],
            datafieldIsMultiple: true
        }
    }
];

/**
* Manage to send export yoomap
*
*/
class YoomapExport extends Component  {

    /**
    * Instanciate the modal
    */
    constructor(props) {
        super(props);

        this.state = {
            modules: {
                data    : null,
                selected: null
            },
            data: {
                data    : null,
                selected: null
            },
            fields: {
                data    : null,
                selected: null
            },
            sended: false
        };

        this.refModules = React.createRef();
        this.refData = React.createRef();
        this.refFields = React.createRef();

        _.bindAll(this, 'fetch', 'setModules', 'onError', 'onChangeModules', 'onChangeData', 'setData', 'updateSelect',
            'updateModules', 'updateData', 'updateFields',
            'onChangeFields', 'setFields', 'send', 'renderSelect', 'getSelectSearch');
    }


    /**
     * Call on the first mount on the component
     */
    componentDidMount() {
        const modulesIndex = selectsConfiguration.findIndex(select => select.key=== 'modules'),
            { requestVar } =  selectsConfiguration[modulesIndex];

        this.fetch('modules', requestVar, null);
    }

    /**
    * Fetch the data list
    *
    */
    fetch(queryKey, variables, loadedCb) {
        const { platform }= this.props,
            { api_url }   = platform;

        if(!api_url) {
            return;
        }

        query(platform, {
            queryKey,
            variables }).then((data) => {
            this['set'+ _.capitalize(queryKey)](data);
            loadedCb && loadedCb(data);
        }).catch((error) => {
            this.onError(error);
            loadedCb && loadedCb();
        });
    }


    /**
     *  Call on error occured
     * @param {object} error
     */
    onError(error) {
        console.log('error', error);
        const {
                deleteTokensPlatforms,
                platform
            }            = this.props,
            { response } = error,
            { status }   = response || 0;

        if (status === 401 || status === 403) {
            deleteTokensPlatforms(platform.id);
            return;
        }

        notification({
            message    : 'Oops !',
            description: `We encountered an issue, please try again in a few minutes ( Error ${status} )`,
            type       : 'error',
            duration   : 20
        });
    }

    /**
    * Set the module list
    *
    */
    setModules(data) {
        const modulesData= data.datatypes || null,
            { modules }  = this.state;

        if(modules.data === null) {
            modules.data = [];
        }

        (modulesData === null ? modules.data = modulesData : modules.data.push(...modulesData));

        this.setState({
            modules,
        });
    }

    /**
     * Trigger on select modules changed
     */
    onChangeModules(value) {
        const { modules } = this.state;
        modules.selected  = value;

        this.setState({
            modules,
            data  : {data: null, selected: null},
            fields: {data: null, selected: null},
        }, () => {
            const { current } = this.refData || {},
                { reset }     = current || {};

            if (reset) {
                reset();
            }
        });
    }

    /**
     * Get the search form the right select
     *
     * @param {string} selectKey
     *
     * @returns string
     */
    getSelectSearch(selectKey) {
        const refSelect   = this['ref'+ _.capitalize(selectKey)],
            { current }   = refSelect || {},
            { getSearch } =  current || { };

        if (!getSearch) {
            return '';
        }

        return getSearch();
    }

    /**
    * Render the select div
    *
    * @return html
    */
    renderSelect(selectKey) {                                    // eslint-disable-line max-lines-per-function
        const selectIndex = selectsConfiguration.findIndex(select => select.key=== selectKey);

        // Selects configuration not found
        if(selectIndex < 0) {
            return;
        }

        const { [selectKey]: selectState } = this.state,
            selectConfiguration            = selectsConfiguration[selectIndex],
            selectBefore                   = selectIndex > 0
                ? selectsConfiguration[selectIndex-1]
                : null,
            { key: selectBeforeKey }       = selectBefore || {},
            selectStateBefore              = selectBefore && this.state[selectBeforeKey],   // eslint-disable-line
            {
                range, orderFields,
                orderByPagination, label,
                render
            }                              = selectConfiguration,
            search                         = this.getSelectSearch(selectKey),
            {
                isHideBeforeEmpty,
                isDisabledBeforeEmpty,
                showErrorEmpty
            }                              = render || {},
            noData                         = (
                selectState.data
                && selectState.data.length === 0
                && search.length === 0
            ),
            selectedBefore                  = selectStateBefore ? selectStateBefore.selected : null,
            disabled                        = selectedBefore === null || noData;


        if(selectedBefore === null && isHideBeforeEmpty) {
            return;
        }

        return(
            <div className={selectKey}>
                <div className="label">  {label} </div>
                <Select
                    ref={this['ref'+ _.capitalize(selectKey)]}
                    placeholder="-"
                    options={
                        selectState.data
                            ? selectState.data.map(element => ({
                                key  : element.id,
                                value: element,
                                label: element.title,
                            }))
                            : []
                    }
                    onChange={this['onChange'+ _.capitalize(selectKey)]}
                    updateData={this['update'+_.capitalize(selectKey)]}
                    disabled={disabled && isDisabledBeforeEmpty===true}
                    range={range}
                    orderFields={orderFields}
                    orderByPagination={orderByPagination}
                />
                <div className={'error'+ ((noData && showErrorEmpty===true) ? '' : ' hide')}>
                    <div className="logo" />
                    <div>
                        Oops! There is a configuration error please contact your Insight project manager or support.
                    </div>
                </div>
            </div>
        );
    }

    /**
    * Update the select modules
    */
    updateModules() {
        // I this.updateSelect('modules', pagination, onLoadCb);
    }

    /**
    * Update the select data
    */
    updateData(pagination, onLoadCb) {
        this.updateSelect('data', pagination, onLoadCb);
    }

    /**
    * Update the select fields
    */
    updateFields(pagination, onLoadCb) {
        this.updateSelect('fields', pagination, onLoadCb);
    }

    /**
     * Update the select
     * @param {object} pagination
     * @param {function} onLoadCb
     */
    updateSelect(selectKey, pagination, onLoadCb) {
        const selectIndex = selectsConfiguration.findIndex(select => select.key=== selectKey);

        if(selectIndex<0) {
            return;
        }

        const select                     = selectsConfiguration[selectIndex],
            requestVar                   = _.cloneDeep(select.requestVar),
            { [selectKey] : selectState} = this.state,
            search                       = this.getSelectSearch(selectKey);

        if(requestVar) {
            if(typeof requestVar.limit !== 'undefined') {
                requestVar.limit = pagination.range;
                requestVar.offset = (pagination.limit.min/pagination.range)+1;
            }

            if(typeof requestVar.datatype !== 'undefined') {
                const { modules } = this.state,
                    { selected }  = modules,
                    datatype      = selected ? selected.uik : '';

                requestVar.datatype = datatype;
            }

            if(requestVar.order) {
                requestVar.order = {
                    columns: [
                        {
                            column   : 'title',
                            direction: pagination.order
                        }
                    ]
                };
            }

            if(requestVar.search) {
                delete requestVar.search;
                if(search && search.length>0) {
                    requestVar.search = { value: search };
                }
            }
        }

        if(pagination.limit.min === 0) {
            selectState.data = null;
        }

        this.setState({
            [selectKey]: selectState
        }, () => {
            this.fetch(selectKey, (requestVar || {}), onLoadCb);
        });
    }

    /**
    * Set the data list
    *
    */
    setData(data) {
        const datas            = data.data ? data.data.data : null,
            { data: dataState} = this.state;

        if(dataState.data === null) {
            dataState.data = [];
        }
        (datas === null ? dataState.data=datas : dataState.data.push(...datas));

        this.setState({
            data: dataState
        });
    }


    /**
     * Trigger on select data changed
     */
    onChangeData(value) {
        const { data } = this.state;
        data.selected = value;

        this.setState({
            data,
            fields: {data: null, selected: null},
        }, () => {
            const { current }= this.refFields ? this.refFields: {},
                { reset }    = current || {};

            if(!reset) {
                return;
            }

            reset();
        });
    }


    /**
    * Set the fields list
    *
    */
    setFields(data) {
        const{ exportedExtentions } = this.props,
            { modules, fields }     = this.state,
            clonedFields            = _.cloneDeep(fields),
            { selected }            = modules,
            datatypeModule          = selected !== null ? selected.uik : '',
            datatypes               = data.datatypes ? data.datatypes : [],
            datatype                = datatypes.length>0 ? datatypes[0]: {};

        // ????
        if(datatypeModule !== datatype.uik) {
            this.setState({
                fields: {...clonedFields, data: null, selected: null}
            });
            return;
        }

        // Data must be array
        if(clonedFields.data === null) {
            clonedFields.data = [];
        }

        // Add fields from data (datatype)
        clonedFields.data = (datatype.fields === null)
            ? datatype.fields
            : clonedFields.data.concat(datatype.fields);

        // Filter fields with exported extentions
        clonedFields.data = clonedFields.data.filter(field => {
            const { settings }                              = field || {},
                settingsObj                                 = settings && JSON.parse(settings),
                { allowedExtensions: allowedExtensionsObj } = settingsObj || {};

            return _.difference(exportedExtentions, _.keys(allowedExtensionsObj)).length === 0;
        });

        this.setState({ fields: clonedFields });
    }



    /**
     * Trigger on select fields changed
     */
    onChangeFields(value) {
        const { fields } = this.state;

        fields.selected = value;

        this.setState({
            fields
        });
    }

    /**
     * Send the yoomap settings
     */
    send(e) {
        const { emitEvent }           = this.props,
            { modules, data, fields } = this.state,
            idModules                  = modules.selected.id,
            idData                     = data.selected.id,
            idFields                   = fields.selected.id,
            { launchExport, platform } = this.props,
            { platform_url }           = platform,
            settingsAdd                = {
                hideFiles: true,
                shares   : [
                    {   target    : 'yoomap',
                        parameters: {
                            module: idModules,
                            data  : idData,
                            field : idFields,
                            platform_url }
                    }]};

        emitEvent({
            module: 'yoomap',
            name  : 'send-export',
            data  : {
                platform: platform_url,
            }
        });

        launchExport(e, settingsAdd);

        this.setState({
            sended: true
        });
    }


    /**
    * Render the data div
    *
    * @return html
    */
    renderBotton() {
        const { fields }                       = this.state;

        return(
            <div className="botton">
                <div
                    className={'send'+ (fields.selected !== null ? '' : ' disabled')}
                    onClick={this.send}
                >
                    Send
                </div>
            </div>
        );
    }


    /**
    * Render the body
    *
    * @return Component
    */
    renderBody() {
        const { sended } = this.state;

        if(sended) {
            return (
                <div className="sended">
                    <div className="icon-send" />
                    <div className="titre">
                        Thank you
                    </div>
                    <div className="comment">
                        We are sending your files
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="body">
                    <div className="intro">
                        Please select below on which data you wish to send your content.
                        It will be added automatically in the associated file field.
                    </div>

                    {selectsConfiguration.map(selectConfiguration => this.renderSelect(selectConfiguration.key))}
                </div>
                {this.renderBotton()}
            </>
        );
    }

    /**
    * Render the yoomap modal export
    *
    * @return Component
    */
    render() {
        const { platform, onClose } = this.props,
            { platform_url }        = platform;

        return (
            <Modal
                title="Send to my other innosabi app"
                className="yoomapExport"
                style={{top: 60, minHeight: 346}}
                width={470}
                open={!(platform_url.length<1)}
                onCancel={onClose}
                footer={null}
                maskClosable={false}
            >
                {this.renderBody()}
            </Modal>
        );
    }

}

YoomapExport.propTypes = {
    platform             : PropTypes.object,
    deleteTokensPlatforms: PropTypes.func,
    onClose              : PropTypes.func,
    emitEvent            : PropTypes.func,
    launchExport         : PropTypes.func,
    exportedExtentions   : PropTypes.array,
};

YoomapExport.defaultProps = {
    platform             : {},
    deleteTokensPlatforms: () => {},
    onClose              : () => {},
    launchExport         : () => {},
    exportedExtentions   : [],
};



/**
 * Convert state store to props
*/
const mapStateToProps = (state) => {
    const auth = state.get('auth');

    return {
        platforms: auth.get('platforms') ? auth.get('platforms'): {}
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    deleteTokensPlatforms,
    emitEvent,
})(YoomapExport);
/**
 * Bind Dispatcher to the component props
 */

