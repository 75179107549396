import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';
import { Icon }             from 'helpers';

/**
 * Render networks nodes
 *
 */
class Nodes extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    /**
     * Check if the component must render
     */
    shouldComponentUpdate(nextProps/* A , nextState */) {
        const { nodes, borderSize, forceRender } = this.props,
            /**
             * Make key function
             */
            keyFn          = (cumul, node) => cumul + node.id + node.x + node.y,
            key            = nodes?.reduce(keyFn, ''),
            nextKey        = nextProps.nodes?.reduce(keyFn, '');

        return forceRender && nodes && (
            key !== nextKey
            || borderSize !== nextProps.borderSize
        );
    }


    /**
     * Render a icon contaner group
     *
     * @return JSX
     */
    renderIconContainer(options) {
        const {
                nodeOut, nodeOver,
                borderSize, borderColor,
                noPaint, scaleFactor
            }  = this.props,
            { sizeScaled, d } = options,
            { properties }    = d;

        return (
            <g
                className="icon-container"
                dataid={d.id}
                transform={`scale(${sizeScaled * scaleFactor/ 100})`}
                onMouseOver={nodeOver}
                onFocus={nodeOver}
                onMouseOut={nodeOut}
                onBlur={nodeOut}
            >
                { noPaint
                    ? (
                        <circle className="node-no-paint" r={50 + borderSize}
                            opacity="0"
                        />
                    ) : (
                        <Icon
                            SVG
                            discShaped
                            id={properties.entityType}
                            folder="/entities/"
                            orgunitLogo={properties.entityType === 'orgunit' ? d.id : null}
                            width={100}
                            height={100}
                            borderColor={borderColor}
                            borderSize={borderSize}
                        />
                    )}
            </g>
        );
    }


    /**
     * The Render
     */
    render() {
        const {
                nodes, getSizeScale,
                borderSize,
            }         = this.props,
            sizeScale = getSizeScale();

        return (
            _.map(nodes, (d) => {
                const sizeScaleCalc = sizeScale(d.size) + borderSize / 2,
                    sizeScaled      = d.size > 0 ? sizeScaleCalc : 0,
                    transform       = !_.isUndefined(d.x) ? `translate(${d.x},${d.y})` : null;

                return (
                    <g
                        key={`${d.id}${d.size}${d.cluster}`}
                        dataid={d.id}
                        className="node"
                        transform={transform}
                    >
                        {this.renderIconContainer({ sizeScaled, d })}
                    </g>
                );
            })
        );
    }

}

/**
 * Props type
 */
Nodes.propTypes = {
    nodes       : PropTypes.array.isRequired,
    nodeOver    : PropTypes.func,
    nodeOut     : PropTypes.func,
    borderColor : PropTypes.string,
    borderSize  : PropTypes.number,
    scaleFactor : PropTypes.number,
    noPaint     : PropTypes.bool,
    forceRender : PropTypes.bool,
    getSizeScale: PropTypes.func.isRequired,
};

/**
 * Default props value
 */
Nodes.defaultProps = {
    borderSize : 0.5,
    scaleFactor: 1,
    nodeOver   : _.noop,
    nodeOut    : null,
};

export default Nodes;
