// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-elements {
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.25s ease, opacity 0.5s ease, margin-top 0.25s ease;
}
.hidden-elements.show {
  opacity: 1;
}
.i-button.show-more .icon {
  transition: 0.3s ease;
}
.i-button.show-more.more .icon {
  transform: rotate(-180deg);
}
.i-button.show-more.less .icon {
  transform: rotate(0deg);
}
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/limit-children-display.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,2EAAA;AACJ;AACI;EACI,UAAA;AACR;AAII;EAEQ,qBAAA;AAHZ;AAKQ;EACI,0BAAA;AAHZ;AAKQ;EACI,uBAAA;AAHZ","sourcesContent":[".hidden-elements {\n    overflow: hidden;\n    opacity: 0;\n    transition: max-height 0.25s ease, opacity 0.5s ease, margin-top 0.25s ease;\n\n    &.show {\n        opacity: 1;\n    }\n}\n\n.i-button {\n    &.show-more {\n        .icon {\n            transition: 0.3s ease;\n        }\n        &.more .icon {\n            transform: rotate(-180deg);\n        }\n        &.less .icon {\n            transform: rotate(0deg);\n        }\n    }\n}\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
