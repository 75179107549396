// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legals {
  font-size: 9px;
}
.legals div {
  display: inline-block;
  width: 50%;
  text-align: center;
}
.legals div.inactive {
  opacity: 0.33;
}
.legals div .img-container {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-shadow: 0 0 5px 2px #607d8b;
  border-radius: 16px;
  margin-right: 7px;
}
.legals div .img-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/utils/assets/legals.less"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAFA;EAIQ,qBAAA;EACA,UAAA;EACA,kBAAA;AACR;AACQ;EACI,aAAA;AACZ;AAVA;EAaY,WAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;EACA,kBAAA;EAEA,+BAAA;EACA,mBAAA;EACA,iBAAA;AADZ;AApBA;EAwBgB,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AADhB","sourcesContent":[".legals {\n    font-size: 9px;\n\n    div {\n        display: inline-block;\n        width: 50%;\n        text-align: center;\n\n        &.inactive {\n            opacity: 0.33;\n        }\n\n        .img-container {\n            width: 16px;\n            height: 16px;\n            display: inline-block;\n            vertical-align: middle;\n            position: relative;\n\n            box-shadow: 0 0 5px 2px #607d8b;\n            border-radius: 16px;\n            margin-right: 7px;\n\n            img {\n                position: absolute;\n                top: 50%;\n                left: 50%;\n                transform: translate(-50%, -50%);\n            }\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
