// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yoomap-login {
  position: relative;
}
.yoomap-login .platform-url {
  color: var(--primary-color);
}
.yoomap-login .authentication-waiting {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.yoomap-login .authentication-waiting .loader-ring {
  display: inline-block;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Yoomap/assets/Login/main.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;AAAJ;AAFA;EAKQ,2BAAA;AAAR;AALA;EASQ,kBAAA;EACA,WAAA;EACA,YAAA;AADR;AAVA;EAcY,qBAAA;EACA,kBAAA;AADZ","sourcesContent":[".yoomap-login {\n\n    position: relative;\n\n    .platform-url {\n        color     : var(--primary-color);\n    }\n\n    .authentication-waiting {\n        position: absolute;\n        right   : 20px;\n        bottom  : 20px;\n\n        .loader-ring {\n            display: inline-block;\n            margin-right: 10px;\n        }\n    }\n\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
