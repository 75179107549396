import React, { Component }   from 'react';
import _                      from 'lodash';
import PropTypes              from 'prop-types';
import { notification }       from 'helpers';
import { Button, Popconfirm } from 'antd';
import { del }                from 'utils/api';
import stepsViewsByType       from './Steps';

/**
 * Use this component to configure a newsletter after its creation
 *
 */
class Configure extends Component {

    /**
     * Construct the configure view
     *
     * @param {object} props Some properties
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'unsubscribe', 'save', 'validate', 'cancel');
    }


    /**
     * Save current settings
     *
     * @return void
     */
    save() {
        const { save, newsletter } = this.props;

        if (!this.validate()) {
            return;
        }

        save(newsletter);

        this.cancel();

        notification({
            type   : 'info',
            message: `"${newsletter.label}" has been updated.`,
        });
    }


    /**
     * Validate newsletter content before saving
     *
     * @return boolean
     */
    validate() {
        const { newsletter }            = this.props,
            { entities, settings, key } = newsletter,
            { sections }                = settings,
            isSearchNewLetter           = key === 'topic-insights';

        // Validate entities
        if (entities.length === 0) {
            notification({
                type   : 'error',
                message: isSearchNewLetter
                    ? 'Sorry, You must have at least one query in your Insight Feed.'
                    : 'Sorry, you must have at least one company or organization in your Insight Feed.',
            });
            return false;
        }

        // Validate sections
        if (sections.length === 0) {
            notification({
                type   : 'error',
                message: 'Please select at least one topic',
            });
            return false;
        }

        return true;
    }

    /**
     * Delete the newsletter from the API
     *
     *
     */
    unsubscribe() {
        const { newsletter } = this.props;

        del(`/newsletter/${newsletter.id}`)
            .then(() => {
                this.cancel();
                notification({
                    type   : 'info',
                    message: `"${newsletter.label}" has been deleted`,
                });
            });
    }


    /**
     * Close the settings of current newsletter
     *
     *
     */
    cancel() {
        const { newsletter, closeSettings } = this.props;

        closeSettings(newsletter);
    }


    /**
     * Render newsletters footer
     *
     * @returns JSX
     */
    renderNewsletterFooter() {
        return (
            <div className="buttons-footer">
                <div className="unsubscribe-button">
                    <Popconfirm
                        overlayClassName="unsubscribe-confirm"
                        title="You are about to delete the insight feed. Are you sure?"
                        onConfirm={this.unsubscribe}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            Unsubscribe
                        </Button>
                    </Popconfirm>
                </div>
                <div className="cancel-setting-button" onClick={this.cancel}>
                    <Button
                        onClick={this.closeSettings}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="close-setting-button" onClick={this.save}>
                    <Button
                        type="primary"
                        onClick={this.closeSettings}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }


    /**
    * Render settings when the newsletter has already been created
    *
    * @return html
    */
    renderSettings() { // eslint-disable-line max-lines-per-function
        const {
                newsletter, update,
                close,
            } = this.props,
            { key }    = newsletter,
            stepsViews = stepsViewsByType[key];

        return (
            <div className="newsletter-setting">
                <h3>
                    Settings
                </h3>
                {stepsViews.map((Component, index) => {
                    const { definition } = Component;

                    return (
                        <div key={definition.label} className="settings-section">
                            <div className="step">
                                <span>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="step-label">
                                {definition.label}
                            </div>
                            <div className="steps-line" />
                            <Component
                                key={key}
                                newsletter={newsletter}
                                canBeEmpty
                                update={update}
                                fromConfigure
                                close={close}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <>
                <div className="content">
                    {this.renderSettings()}
                </div>
                {this.renderNewsletterFooter()}
            </>
        );
    }

}

Configure.propTypes = {
    newsletter   : PropTypes.object,
    closeSettings: PropTypes.func,
    save         : PropTypes.func,
    close        : PropTypes.func,
    update       : PropTypes.any
};

Configure.defaultProps = {
    newsletter: false,
};

export default Configure;

