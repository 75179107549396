import _                      from 'lodash';
import Immutable              from 'immutable';
import types                  from '../actions/types/userView';
import { initialMap }         from '../initialStates';
import { makeSentryHeaders }  from 'core/utils/api';

/**
 * Manage the auth/member slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const UserView = (userView = initialMap, action) => { // eslint-disable-line  max-lines-per-function
    /**
     * Store outer models
     *
     * @param {Immutable.Map} userView
     * @param {object} payload
     * @returns
     */
    const storeOuterModels = (userView, models) => {
        const types = _.uniq(_.map(models, 'type')),
            slices  = types.reduce(
                (cumul, type) => {
                    cumul[type] = userView.get(type)?.get('outerModels') || new Immutable.Map();
                    return cumul;
                },
                {}
            );

        models.forEach(model => {
            slices[model.type] = slices[model.type].set(
                model.id,
                { ...model, isOuter: true }
            );
        });

        _.forIn(
            slices,
            (slice, type) => {
                userView = userView.set(
                    type,
                    userView.get(type)?.set('outerModels', slice)
                );
            }
        );

        return userView;
    };


    /**
    * Set the userView slice
    *
    * @param {Immutable.Map} userView A userView slice
    * @param {object}        model    The source model of the entire userView slice
    *
    * @returns Immutable.Map : The new userView slice
    */
    const setUserViewSlice = (userView, payload) => {
        // Save models to re-populateModels
        const { entities, span } = payload,
            allModels            = [],
            allStats             = {};
        _.forIn(
            userView.toJS(),
            (userViewSlice, type) => {
                const models = _.values(userViewSlice.map).reduce((accumulator, streamEntity) => {
                    if (streamEntity.model) {
                        accumulator.push(streamEntity.model);
                    }
                    return accumulator;
                }, []);

                // Clean the userViewState
                models.forEach(model => delete model.userViewState);

                allModels.push(models);
                allStats[type] = userViewSlice.stats;
            }
        );

        // Make the userView slice as a deep Immutable
        const streamEntities = {};
        _.forIn(
            entities,
            (userViewSlice, type) => {
                const stats = allStats[type] || {};
                stats.sentrySpan = makeSentryHeaders({}, span);
                userView = userView.set(
                    type,
                    new Immutable.Map({
                        list : new Immutable.List(userViewSlice),
                        map  : new Immutable.Map(_.keyBy(userViewSlice, obj => obj.key)),
                        stats: new Immutable.Map(stats),
                    })
                );
                userViewSlice.forEach(streamEntity => {
                    streamEntities[streamEntity.key] = streamEntity;
                });
            }
        );

        // Index all stream entities
        userView =  userView.set('streamEntities',  new Immutable.Map(streamEntities));

        return populateModels(userView, _.flatten(allModels), { resetModelState: true });
    };


    /**
     * Set news userView Immutable from new maps and lists
     *
     */
    const setUserViewImmutable = (userView, {newMaps, newLists, newStats}) => {
        _.forIn(
            newMaps,
            (streamEntities, type) => {
                userView = userView.set(
                    type,
                    new Immutable.Map({
                        list : newLists[type],
                        map  : newMaps[type],
                        stats: newStats[type],
                    })
                );
            }
        );

        return userView;
    };

    /**
     * Update the stats of a slice with specified values
     *
     * @param {Immutable.Map} userView The userView slice
     * @param {array}         models   The models to populate
     *
     *  @returns Immutable.Map : The new userView slice
     */
    const setSliceStats = (userView, options) => {
        const { modelType, values } = options,
            slice                   = userView.get(modelType) || new Immutable.Map(),
            sliceStats              = slice.get('stats')?.toJS() || {},
            stats                   = { ...sliceStats, ...values },
            newUserView             = userView.set(
                modelType,
                new Immutable.Map({
                    list : slice.get('list') || new Immutable.List(),
                    map  : slice.get('map') || new Immutable.Map(),
                    stats: new Immutable.Map(stats),
                })
            );

        return newUserView;
    };


    /**
    * Populate a userView with models
    *
    * @param {Immutable.Map} userView The userView slice
    * @param {array}         models   The models to populate
    *
    * @returns Immutable.Map : The new userView slice
    */
    const populateModels = (userView, models, { cb, resetModelState = false } = {}) => {
        const newMaps  = {};
        const newLists = {};
        const newStats = {};
        const updatedTypes = {};

        models.forEach(model => {
            const { type } = model,
                slice      = userView.get(type);

            // Slice type not exist => can't update model of a stream entity
            if (!slice) {
                return userView;
            }

            // Fill slices
            if (!newMaps[type]) {
                newMaps[type]  = slice.get('map');
                newLists[type] = slice.get('list');
                newStats[type] = slice.get('stats');
            }

            const streamEntitiesToPopulate = newLists[type].filter(streamEntity => {
                return streamEntity.entity_id === model.id;
            });

            if (streamEntitiesToPopulate) {
                streamEntitiesToPopulate.forEach(streamEntityToPopulate => {
                    streamEntityToPopulate.model = resetModelState || !streamEntityToPopulate.model?.userViewState
                        ? model
                        : { ...model, userViewState: streamEntityToPopulate.model.userViewState  };
                    newMaps[type] = newMaps[type].set(streamEntityToPopulate.key, streamEntityToPopulate);
                    updatedTypes[type] = true;
                });
            }
        });

        // Updating the lists that need
        for (const modelType in updatedTypes) {
            newLists[modelType] = newMaps[modelType].toList();
        }

        const newUserView = setUserViewImmutable(userView, {newMaps, newLists, newStats});

        if (cb) {
            cb();
        }

        return newUserView;
    };


    /**
    * Update bookmark model entity into bookmarks slice from a entity
    *
    * @param {Immutable.Map} userView A userView slice
    * @param {object}        entity   The entity to update
    *
    * @returns Immutable.Map : The new userView slice
    */
    const updateBookmarksEntity = (userView, entity) => {
        const newMaps      = {};
        const newLists     = {};
        const newStats     = {};
        const updatedTypes = {};

        const type = 'bookmark',
            slice  = userView.get(type);

        // Fill slices
        if (!newMaps[type]) {
            newMaps[type]  = slice.get('map');
            newLists[type] = slice.get('list');
            newStats[type] = slice.get('stats');
        }

        const streamEntitiesToUpdate = newLists[type].filter(streamEntity => {
            return streamEntity?.model?.entity?.id === entity.id;
        });

        if (streamEntitiesToUpdate.size > 0) {
            streamEntitiesToUpdate.forEach(
                streamEntityToUpdate => {
                    streamEntityToUpdate.model.entity = entity;
                    newMaps[type] = newMaps[type].set(streamEntityToUpdate.key, streamEntityToUpdate);
                    updatedTypes[type] = true;
                }
            );
        }

        // Updating the lists that need
        for (const modelType in updatedTypes) {
            newLists[modelType] = newMaps[modelType].toList();
        }

        return _.keys(updatedTypes.length) > 0
            ? setUserViewImmutable(userView, {newMaps, newLists, newStats})
            : userView;
    };


    /**
    * Remove a item into user view (useful to remove a temporary item like a new bookmark folder)
    *
    * @param {Immutable.Map} userView      A userView slice
    * @param {object}        userViewItems The Array of userView items to be remove
    */
    const removeItems = (userView, userViewItems) => {
        const newUserView = userViewItems.reduce((accumulator, userViewItem) => {
            const {
                    key,
                    entity_type,
                }           = userViewItem,
                slice       = accumulator.get(entity_type),
                sliceMap    = slice.get('map'),
                sliceStats  = slice.get('stats'),
                newMap      = sliceMap.delete(key);

            return accumulator.set(
                entity_type,
                new Immutable.Map({
                    list : newMap.toList(),
                    map  : newMap,
                    stats: sliceStats,
                })
            );
        }, userView);

        return newUserView;
    };


    // TODO: Refact addNewItems and updateItems

    /**
    * Add model into the userView slice (slice of the type of the model) with added userViewState
    *
    * @param {Immutable.Map} userView A userView slice
    * @param {object}        payload  The model to be add
    *
    */
    const addNewItems = (userView, payload) => {  // eslint-disable-line max-lines-per-function
        const {
                items, userViewState, modelsDefinitions
            }                   = payload,
            state               =  userViewState ?? 'added',
            tomorrow            = new Date(Date.now() + 86400000).toISOString(),
            itemsToUpdateByType = items && _.groupBy(items, item => item.model?.type || item.entity_type) || {};

        let newUserView  = userView;

        for (const type in itemsToUpdateByType) {
            const slice         = newUserView.get(type),
                sliceMap        = slice.get('map'),
                sliceStats      = slice.get('stats'),
                modelDefinition = modelsDefinitions.find(def => {
                    return def.id === type;
                }),
                { service }     = modelDefinition;

            let newMap = sliceMap;

            itemsToUpdateByType[type].forEach((item) => {
                const {
                        model, parent_attachments
                    }                    = item,
                    newItemId            = getNewItemId(item),
                    newItemKey           = `${service}/${type}/${newItemId}`,
                    newParentAttachments = parent_attachments?.map(parent_attachment => ({
                        ...parent_attachment, date_create: parent_attachment.date_create || tomorrow
                    })),
                    newModel = model && {
                        ...model,
                        id           : newItemId,
                        userViewState: state
                    };

                newMap = newMap.set(
                    newItemKey,
                    {
                        key               : newItemKey,
                        entity_type       : type,
                        date_create       : new Date(Date.now()).toISOString(),
                        entity_id         : newItemId,
                        model             : newModel,
                        parent_attachments: newParentAttachments
                    }
                );
            });

            newUserView = newUserView.set(
                type,
                new Immutable.Map({
                    list : newMap.toList(),
                    map  : newMap,
                    stats: sliceStats,
                })
            );
        }

        return newUserView;
    };

    /**
     * > Replace the items in the user view with the new items
     * @param userView - the current userView object
     * @param payload - the payload of the action
     * @returns The updatedUserView is being returned.
     */
    const replaceItems = (userView, payload) => {
        const { items, modelsDefinitions }     = payload,
            cleanedUserView = removeItems(userView, items.map(item => item.source)),
            updatedUserView = addNewItems(
                cleanedUserView,
                {
                    items        : items.map(item => item.target),
                    userViewState: 'saved',
                    modelsDefinitions,
                }
            );

        return updatedUserView;
    };

    /**
     * Update a userView slice with a new model for all children
     * @param {Immutable.Map} userView      A userView slice
     * @param {string}        userViewState State of the userView
     * @param {string}        parentKey     The parent key
     */
    const updateUserViewStateChildren = (userView, userViewState, parentKey) => {
        if (!userViewState) {
            return userView;
        }

        let newUserView = userView;

        // Search children in all slice
        _.forIn(
            newUserView.toJS(),
            (userViewSlice, type) => {
                const { list } = userViewSlice,
                    children   = list?.filter(
                        item => item?.parent_attachments?.map(
                            attachment => attachment?.key
                        ).includes(parentKey)
                    ),
                    slice      = newUserView.get(type),
                    sliceMap   = slice.get('map'),
                    sliceStats = slice.get('stats'),
                    // Update sliceMap with children model to set a parent userViewState attribute
                    newMap     = children?.reduce((previousMap, item) => {
                        const newItem = _.cloneDeep(item);
                        if (newItem.model) {
                            newItem.model.userViewState = `parent-${userViewState}`;
                        }

                        return previousMap.set(item.key, newItem);
                    }, sliceMap);

                if (!newMap || !children?.length) {
                    return;
                }

                // Update sliceMap
                newUserView = newUserView.set(
                    type,
                    new Immutable.Map({
                        list : newMap.toList(),
                        map  : newMap,
                        stats: sliceStats,
                    })
                );

                // Recursive iteration on children
                children.forEach(child => {
                    newUserView = updateUserViewStateChildren(newUserView, userViewState, child.key);
                });
            }
        );

        return newUserView;
    };

    /**
     * It returns the model's id if it exists, otherwise it returns the entity_id if it exists, otherwise
     * it returns a random UUID
     *
     * @param item - The item that is being added to the list.
     * @returns The id of the item.
    */
    const getNewItemId = (item) => {
        const { model, entity_id } = item;

        return model?.id ?? entity_id ?? crypto.randomUUID();
    };


    // TODO: Refact addNewItems and updateItems !!!

    /**
    * Update an items list into the userView slice (slice of the type of the model)
    * Useful to update a moved bookmark,folder,downloads,clipboard-items... before
    * the updated user view received by webSocket
    *
    * @param {Immutable.Map} userView A userView slice
    * @param {object}        payload  The model to be add
    *
    */
    const updateItems = (userView, payload) => {
        const  {
                items,
                userViewState
            } = payload,
            tomorrow            = new Date(Date.now() + 86400000).toISOString(),
            itemsToUpdateByType = items && _.groupBy(items, item => item.entity_type) || {};

        let newUserView  = userView;

        for (const type in itemsToUpdateByType) {
            const slice    = newUserView.get(type),
                sliceMap   = slice.get('map'),
                sliceStats = slice.get('stats');

            let newMap = sliceMap;

            itemsToUpdateByType[type].forEach((item) => { // eslint-disable-line  no-loop-func
                const { key, model, parent_attachments } = item,
                    newParentAttachments = parent_attachments?.map(parent_attachment => ({
                        ...parent_attachment, date_create: parent_attachment.date_create || tomorrow
                    })),
                    newItemId            = getNewItemId(item),
                    newModel             = !model?.id || !key
                        ? {
                            id: newItemId,
                        }
                        : _.clone(model);

                if (!_.isUndefined(userViewState)) {
                    newUserView = updateUserViewStateChildren(newUserView, userViewState, key);
                    newMap = newUserView.get(type).get('map');
                    newModel.userViewState = userViewState;
                }

                newMap = newMap.set(key, {
                    ...item,
                    model             : newModel,
                    parent_attachments: newParentAttachments
                });

                newUserView = newUserView.set(
                    type,
                    new Immutable.Map({
                        list : newMap.toList(),
                        map  : newMap,
                        stats: sliceStats,
                    })
                );
            });
        }

        return newUserView;
    };


    /**
     * It takes a userView and a payload, and returns a new userView with the items updated according to
     * the payload
     *
     * @param userView - the current state of the user's view
     * @param diff - an array of variations
     *
     * @returns The userView is being returned.
     */
    const updateItemsFromDiff = (userView, diff) => {
        const newUserView = diff.reduce((updatedUserView, variation) => {
            const { item, action } = variation,
                { entity_type }    = item,
                slice              = userView.get && userView.get(entity_type),
                sliceMap           = slice?.get('map');

            if (!sliceMap) {
                window.location.reload();
            }

            if (action === 'remove') {
                return removeItems(updatedUserView, [item]);
            }

            if (action === 'add') {
                // And reset the userViewState
                return updateItems(updatedUserView, { items: [item], userViewState: null });
            }

            return updatedUserView;
        }, userView);

        return newUserView;
    };


    // Switch over actions
    switch (action.type) {
        // User view is set
        case types.USER_VIEW_SET:
            return setUserViewSlice(userView, action.payload);

        case types.USER_VIEW_STORE_OUTER_MODELS:
            return storeOuterModels(userView, action.payload);

        // TODO: Use two states: userViewState and modelState
        case types.USER_VIEW_UPDATE_MODELS:
            return populateModels(userView, action.payload, {cb: action.cb, resetModelState: true});

        case types.USER_VIEW_UPDATE_MODEL:

            return action.payload.type === 'query'
                ? updateBookmarksEntity(userView, action.payload)
                : populateModels(userView, [action.payload]);

        case types.USER_VIEW_ADD_NEW_ITEMS:
            return addNewItems(userView, action.payload);

        case types.USER_VIEW_REMOVE_ITEMS:
            return removeItems(userView, action.payload);

        case types.USER_VIEW_UPDATE_SLICE_STATS:
            return setSliceStats(userView, action.payload);

        case types.USER_VIEW_UPDATE_ITEMS:
            return updateItems(userView, action.payload);

        case types.USER_VIEW_REPLACE_ITEMS:
            return replaceItems(userView, action.payload);

        case types.USER_VIEW_UPDATE_FROM_DIFF:
            return updateItemsFromDiff(userView, action.payload);

        default:
            break;
    }

    return userView;
};

export default UserView;
