// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-module {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  margin-top: -179px;
  background: #fff;
  box-shadow: 1px 1px 17px #555;
  border-radius: 10px;
  padding: 20px;
  line-height: 31px;
}
.error-module .title {
  background-size: 300px;
  padding-top: 80px;
  display: block;
  font-size: 20px;
  color: var(--primary-color);
  text-align: center;
}
.error-module .baseline {
  text-align: center;
  display: block;
}
.error-module .office {
  font-size: 15px;
  color: var(--primary-color);
}
.error-module table {
  margin: 0 10px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/assets/error.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,6BAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;AAAJ;AAZA;EAeQ,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;AAAR;AApBA;EAwBQ,kBAAA;EACA,cAAA;AADR;AAxBA;EA6BQ,eAAA;EACA,2BAAA;AAFR;AA5BA;EAkCQ,cAAA;EACA,WAAA;AAHR","sourcesContent":[".error-module {\n\n    position     : fixed;\n    top          : 50%;\n    left         : 50%;\n    width        : 600px;\n    margin-left  : -300px;\n    margin-top   : -179px;\n    background   : #fff;\n    box-shadow   : 1px 1px 17px #555;\n    border-radius: 10px;\n    padding      : 20px;\n    line-height  : 31px;\n\n    .title {\n        background-size: 300px;\n        padding-top: 80px;\n        display   : block;\n        font-size : 20px;\n        color     : var(--primary-color);\n        text-align: center;\n    }\n\n    .baseline {\n        text-align: center;\n        display: block;\n    }\n\n    .office {\n        font-size: 15px;\n        color    : var(--primary-color);\n    }\n\n    table {\n        margin: 0 10px;\n        width: 100%;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
