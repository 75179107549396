import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import { Collection }       from 'helpers';

import { deepEqual }        from 'utils/object';

/**
* Draw collections of entities
*
*/
class CollectionElement extends Component  {


    /**
    *
    * @returns The return value is a boolean value that tells React component should update.
    */
    shouldComponentUpdate(nextProps, nextState) {
        const sameProps = deepEqual(this.props, nextProps),
            sameState   = deepEqual(this.state, nextState);

        return !sameProps || !sameState;
    }

    /**
    * Extract entities from data
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { data, pagination, dataIsLoaded,
                haveIssueOnRetrieveListData }   = nextProps,
            { entitiesList,
                content: stateContent }         = prevState,
            { content,
                pagination:responsePagination } = data || {},
            sliceMin                            = pagination && pagination.limit ? pagination.limit.min : 0;

        if (stateContent === content) {
            return prevState;
        }

        if (haveIssueOnRetrieveListData) {
            return {
                ...prevState,
                dataIsLoaded,
                entitiesList: entitiesList.filter(entity => !entity.isLoading),
            };
        }

        // The Reset case
        if (_.isNull(content) && entitiesList.length > 0 && pagination.limit.min === 0) {
            return {
                ...prevState,
                dataIsLoaded,
                entitiesList: [],
            };
        }

        if (!content || !content.forEach) {
            return {
                ...prevState,
                content,
            };
        }

        content.forEach((value, index) => entitiesList.splice(sliceMin + index, 1, value));
        return {
            ...prevState,
            dataIsLoaded,
            content,
            entitiesList: Array.from(entitiesList).splice(0, responsePagination.total)
        };
    }

    /**
    * Construct metrics
    *
    */
    constructor(props) {
        const { registerActions } = props;

        super(props);

        _.bindAll(this,
            'loadNextRange', 'reset', 'addARangeEntitiesToLoad', 'getLoadingEntities',
            'registerCallbacks',
        );

        if (registerActions) {
            registerActions({
                loadNextRange: this.loadNextRange,
                reset        : this.reset
            });
        }

        this.state = {
            entitiesList: [],
            content     : [],
            callbacks   : {},
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['filters', 'entities'])
            .then(this.setState.bind(this));

        this.addARangeEntitiesToLoad();
    }

    /**
    * Component did update
    *
    * @return self
    */
    componentDidUpdate() {
        const { data }   = this.props,
            { content }  = data || {},
            { entitiesList } = this.state;

        // Content null = no data.
        if (!entitiesList.length && !_.isNull(content)) {
            this.addARangeEntitiesToLoad();
        }
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        this.isUnmounted = true;
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { registerCallbacks } = this.props,
            { callbacks }           = this.state,
            /** Return empty object */
            defaultCb               = () => {};

        callbacks[action] = cb || defaultCb;

        this.setState({ callbacks });

        // Register callback on Element base componant
        registerCallbacks(action, cb);
    }

    /**
    * Clean the collection
    *
    * @return void
    */
    reset() {
        const { callbacks } = this.state;

        callbacks.resetSelectedEntities && callbacks.resetSelectedEntities();

        !this.isUnmounted && this.setState({
            entitiesList: []
        });
    }

    /**
    * Add 'n' (range) entities to load
    *
    * return void
    */
    addARangeEntitiesToLoad() {
        const { pagination } = this.props,
            { limit }        = pagination || {},
            { entitiesList } = this.state;

        if (!pagination) {
            return;
        }

        this.setState({
            entitiesList: entitiesList.concat(this.getLoadingEntities(limit.range)),
        });
    }

    /**
    * Get dataset models types
    *
    * @return string
    */
    getModelType() {
        const { getListResource } = this.props,
            resource              = getListResource(),
            { models }            = resource;

        if (models.length > 1) {
            return 'mixed';
        }

        return models[0].type;
    }

    /**
    * Get resource models
    *
    * @return array
    */
    getResourceModels() {
        const { getListResource } = this.props,
            resource              = getListResource(),
            { models }            = resource;

        return models;
    }

    /**
    * Return placeholders entities during their load
    *
    * @param number length entities counts
    *
    * @return array
    */
    getLoadingEntities(length) {
        const type = this.getModelType();

        return _.times(length, () => ({
            type,
            isLoading: true
        }));
    }

    /**
    * Load the next range of items
    *
    * @return self
    */
    loadNextRange(cb) {
        const {
                loadNextRange, dataIsLoaded
            }                    = this.props,
            { data, pagination } = this.props,
            { entitiesList }     = this.state,
            {
                content, pagination:responsePagination,
            }                    = data || {},
            totalHasBeenReached  = (_.isNull(content) && entitiesList.length > 0 && pagination.limit.min === 0)
                || (!content || !content.forEach)
                || !responsePagination
                || pagination.limit.max >= responsePagination.total;

        if (!loadNextRange || !dataIsLoaded || totalHasBeenReached) {
            cb && cb(totalHasBeenReached, dataIsLoaded);
            return;
        }

        this.addARangeEntitiesToLoad();
        cb && cb(null, dataIsLoaded);

        loadNextRange();
    }

    /**
    * Get the graph type
    *
    * @return string
    */
    getType() {
        const { element } = this.props;

        return _.last(element.category.split('/'));
    }

    /**
    * Make the renderSuffix function from template in stats[renderSuffixKey]
    *
    * @param string renderSuffixKey
    *
    * @return function
    */
    getRenderSuffixFn(suffixTemplateKey) {
        const { data } = this.props,
            { stats }  = data || {};

        if (!stats || !stats[suffixTemplateKey]) {
            return () => '';
        }

        return () => this.renderCollectionSuffix(stats[suffixTemplateKey], stats);
    }

    /**
    * Render Collection suffix from template
    *
    * @param definition metrics definition
    *
    * @return boolean
    */
    renderCollectionSuffix(template, stats) {
        let returnString = template;

        _.each(stats, (val, key) => {
            const reg = new RegExp(`%${key}%`, 'g');
            returnString = returnString.replace(
                reg,
                `<b>${val}</b>`,
            );
        });

        return (
            <span
                className="suffix"
                dangerouslySetInnerHTML={{ __html: returnString }}
            />
        );
    }

    /**
    * Render the graph Helper
    *
    * @return JSX
    */
    renderCollection() { // eslint-disable-line max-lines-per-function
        const  {
                data,
                context,
                forwardedRef,
                model,
                element,
                navigateTo,
                oneClickOpen,
                parentLabel,
                addToClipboard,
                sorts,
                setActiveSortId,
                sortThresholdReached,
                disableSort,
                getScrollSelectors,
                createVirtualClipboardItem,
            }                   = this.props,
            { entitiesList }    = this.state,
            { configuration }   = element,
            { renderSuffix  }   = configuration || {},
            renderSuffixFn      = this.getRenderSuffixFn(renderSuffix),
            { pagination }      = data || {},
            { total }           = pagination || {},
            type                = this.getModelType(),
            mode                = this.getType().toLowerCase();

        return (
            <Collection
                key={`collection-${element.id}`}
                forwardedRef={forwardedRef}
                onClick={navigateTo}
                model={model}
                context={context}
                type={type}
                mode={mode}
                entities={entitiesList}
                total={total}
                parentLabel={parentLabel}
                oneClickOpen={oneClickOpen}
                {...configuration}
                renderSuffix={renderSuffixFn}
                element={element.category === 'Collection/Inline' ? element : null}
                addToClipboard={addToClipboard}
                registerCallbacks={this.registerCallbacks}
                sorts={sorts}
                setActiveSortId={setActiveSortId}
                sortThresholdReached={sortThresholdReached}
                resourceModels={this.getResourceModels()}
                disableSort={disableSort}
                getScrollSelectors={getScrollSelectors}
                createVirtualClipboardItem={createVirtualClipboardItem}
            />
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        return this.renderCollection();
    }

}

CollectionElement.propTypes = {
    createVirtualClipboardItem: PropTypes.func,
    element                   : PropTypes.shape({
        id           : PropTypes.string,
        category     : PropTypes.string,
        configuration: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        resource     : PropTypes.shape({
            id         : PropTypes.string,
            fromElement: PropTypes.string
        })
    }).isRequired,
    forwardedRef               : PropTypes.any,
    data                       : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    addToClipboard             : PropTypes.func,
    context                    : PropTypes.any,
    dataIsLoaded               : PropTypes.bool,
    learnKnowledge             : PropTypes.func,
    loadNextRange              : PropTypes.func,
    model                      : PropTypes.object,
    navigateTo                 : PropTypes.func,
    oneClickOpen               : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    pagination                 : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    parentLabel                : PropTypes.string,
    registerActions            : PropTypes.func,
    registerCallbacks          : PropTypes.func,
    getListResource            : PropTypes.func,
    resources                  : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    setActiveSortId            : PropTypes.func,
    sortThresholdReached       : PropTypes.bool,
    sorts                      : PropTypes.array,
    haveIssueOnRetrieveListData: PropTypes.bool,
    basePagination             : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    dynamicFilters             : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    sentenceSuffix             : PropTypes.any,
    disableSort                : PropTypes.bool,
    getScrollSelectors         : PropTypes.func,
};

CollectionElement.defaultProps = {
    data       : false,
    disableSort: false,
};

export default CollectionElement;

