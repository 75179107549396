/**
 * Main Insight edit search
 *
 * @return Component
 */
import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import './assets/edit.less';

// Import Input renderer
import Input from './Input';

/**
* Edit a query
*
*
*/
class Edit extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this);

        this.state = {};
    }

    /**
    * Launch side effects
    *
    * @return boolean
    */
    componentDidUpdate() {
        return false;
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
            search,
            registerCallbacks,
            onSearch,
            onUpdateInputs,
            mustHaveOneConcept
        } = this.props;

        return (
            <div className="edit-search">
                <Input
                    submit={false}
                    search={search}
                    switcher={false}
                    onSearch={onSearch}
                    registerCallbacks={registerCallbacks}
                    onUpdateInputs={onUpdateInputs}
                    mustHaveOneConcept={mustHaveOneConcept}
                />
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
Edit.propTypes = {
    registerCallbacks : PropTypes.func,
    onUpdateInputs    : PropTypes.func,
    mustHaveOneConcept: PropTypes.bool,
    onSearch          : PropTypes.func,
    search            : PropTypes.shape(),
};

Edit.defaultProps = {
    registerCallbacks: () => {},
    onUpdateInputs   : () => {},
    onSearch         : _.noop,
};

export default Edit;
