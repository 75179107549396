import React, {
    useMemo, useEffect,
    useState, useRef
}                                   from 'react';
import _                            from 'lodash';
import PropTypes                    from 'prop-types';
import ImmutablePropTypes           from 'react-immutable-proptypes';
import { updateBookmarkFolder }     from 'store/actions/userView/bookmarksFolders';
import { connect }                  from 'react-redux';
import { Icon, CssLoader, Action }  from 'helpers';
import ModelAction                  from '../Action/Model';
import Inline                       from './Inline';
import { emitEvent }                from 'store/actions/sockets';
import { updateBookmark }           from 'store/actions/userView/bookmarks';
import {
    getUserViewItemFromModel,
    getDataEntity,
    entityIsInNewsletter,
    getModelsOfType,
}                                   from 'store/actions/userView';
import FilterValues                 from 'helpers/Filters/FilterValues';
import {
    capitalize,
    str2DomFormat,
    makeStrClassName
}                                   from 'utils/text';
import './assets/block.less';

/**
* Render the block entity render type
* React Functional Components (Hooks)
*
* @return JSX
*/
const Block = (props) => {  // eslint-disable-line max-lines-per-function
    const {
            disableInteractivity, entity,
            onClick, overwriteActions, renderActionsInline,
            height, newslettersList, filters, bookmarksList,
            tags,
        }                   = props,
        {
            disabled_tags_by_folders,
        }                   = entity,
        newsletterKeyByType = {
            orgunit: 'competitive-insights',
            query  : 'topic-insights',
        };

    /**
     * Making data-* props from additionalData
     *
     * @returns Object
     */
    const getTreeDataInfo = () => {
        const { additionalData } = props;
        return _.mapKeys(additionalData, (value, key) => {
            return `data-${key}`;
        });
    };

    /**
     * Checks if block entity is bookmarked
     *
     * @returns
     */
    const getBookmark = () => {
        const { getUserViewItemFromModel } = props;

        return entity && getUserViewItemFromModel(entity, 'bookmark');
    };

    /**
    * Saved text for TextEdit #[src/helpers/TextEdit.jsx] with store update
    * @param string Edited text value
    *
    * @returns void
    */
    const onTextEditSave = (text) => {
        const {
                entity, getUserViewItemFromModel,
                updateBookmarkFolder, forceEditModeCb
            }            = props,
            streamEntity = getUserViewItemFromModel(entity, 'bookmark_folder');

        // At create bookmark folder (name the fake model)
        if (forceEditModeCb) {
            return;
        }

        streamEntity && updateBookmarkFolder(streamEntity, { label: text });
    };

    /**
     * Gets dataEntity from userView
     * @returns
     */
    const getUserviewDataEntity = () => {
        return getDataEntity(entity);
    };

    /** */
    const handleMouseEnter = () => { setIsHovered(true); };

    /**
     * Orgunit or search is in insight newsletter
     *
     * @param {object} entity
     *
     * @returns boolean
     */
    const getIsInNewsletter = () => {
        const {
                entityIsInNewsletter, entity,
                getModelsOfType,
            }                      = props,
            { type }               = entity,
            { type:dataEntityType} = dataEntity,
            newsletters            = getModelsOfType('newsletter'),
            newsletterKey          = newsletterKeyByType[dataEntityType],
            newsletter             = newsletters && newsletterKey
                && newsletters.find(newsletter => newsletter.key === newsletterKey);

        return newsletter && entityIsInNewsletter(
            type === 'bookmark' ? entity.entity : entity
        );
    };

    const ref   = useRef(null),
        [prefix, setPrefix]             = useState([]),
        [actions, setActions]           = useState([]),
        [suffix, setSuffix]             = useState([]),
        [isHovered, setIsHovered]       = useState(false),
        [editModeCb, setEditModeCb]     = useState(null),
        treeDataInfo                    = useMemo(getTreeDataInfo, [entity]),
        dataEntity                      = useMemo(getUserviewDataEntity, [entity]),
        isInNewsletter                  = useMemo(getIsInNewsletter, [entity, newslettersList]),
        bookmark                        = useMemo(getBookmark, [entity, bookmarksList]);

    useEffect(() => {
        setPrefix(renderPrefix());
    }, [entity, bookmark, bookmarksList]);

    useEffect(() => {
        setSuffix(renderSuffix());
    }, [tags, dataEntity, isInNewsletter, bookmark, filters]);

    // Build actions in first hover event
    useEffect(() => {
        isHovered && setActions(renderActions);
    }, [isHovered]);

    /**
     * Render prefix elements
     * @returns
     */
    const renderPrefix = () => {
        return [
            renderHolder(),
            renderIconType()
        ];
    };

    /**
     * Render actions
     * @returns
     */
    const renderActions = () => {
        return [
            renderAddFolder(),
            !disableInteractivity && renderModelActions(),
        ];
    };

    /**
     * Render suffix
     * @returns
     */
    const renderSuffix = () => {
        const isBookmark = entity?.type     == 'bookmark',
            isQuery      = dataEntity?.type == 'query';

        return [
            tags && renderTags(),
            renderMonitoredIcon(),
            !isBookmark && renderBookmarkedIcon(),
            renderSearchModeIcon(),
            isQuery && renderSearchFilters()
        ];
    };


    /**
     * Update disabled tags on the parent folder
     *
     * @param {array} newTags
     */
    const updateDisabledTags = (newTags) => {
        const {
                updateBookmark, getUserViewItemFromModel, emitEvent
            }                = props,
            userViewItem     = entity && getUserViewItemFromModel(entity, 'bookmark'),
            { parentFolder } = entity || {},
            {id: parent_id } = parentFolder || {};

        emitEvent({
            module: 'bookmark',
            name  : 'update-disabled-tags',
            data  : {
                disabled_tags: newTags,
                model        : entity.entity.entity,
                folder       : parentFolder
            },
            attributesFilter: ['model.id', 'model.type', 'disabled_tags', 'folder.id']
        });

        updateBookmark(
            userViewItem,
            { disabled_tags_by_folders:
                {
                    ...disabled_tags_by_folders || {},
                    ...{[parent_id]: newTags}
                }
            }
        );
    };

    /**
     * Render tags
     */
    const renderTags = () => { // eslint-disable-line  max-lines-per-function
        const { parentFolder }    = entity || {},
            { model }             = bookmark || {},
            {
                disabled_tags_by_folders,
                tags: modelTags
            }                     = model || {},
            {id: parent_id }      = parentFolder || {},
            tagSize               = height - 1,
            entityTags            = tags.filter((tag) => (modelTags || []).indexOf(tag.id) !== -1);

        return (
            <span key="tags" className="tags">
                {(entityTags || []).map((tag) => {
                    const disabledTags = disabled_tags_by_folders && disabled_tags_by_folders[parent_id] || [],
                        disabledTag    = disabledTags.includes(tag.id),
                        newTags        = _.xor(disabledTags, [tag.id]),
                        size           = tagSize,
                        style          = {
                            color   : tag.color,
                            right   : 0,
                            height  : size,
                            width   : size,
                            fontSize: tagSize
                        };

                    return (
                        <span
                            key={tag.label}
                            title={tag.label}
                            className="tag clickable"
                            onClick={() => updateDisabledTags(newTags)}
                        >
                            <Icon
                                type={!disabledTag ? tag.icon : `${tag.icon}-outlined`}
                                className="icon"
                                color={tag.color}
                                title={tag.label}
                                theme="filled"
                                style={style}
                                height={size}
                                width={size}
                            />
                        </span>
                    );
                })}
            </span>
        );
    };

    /**
    * Render the monitored icon
    *
    * @return JSX
    */
    const renderMonitoredIcon = () => {
        return isInNewsletter && (
            <div key="monitored" className="entity-monitored">
                <Icon id="monitored" width={height}
                    title="is monitored"
                />
            </div>
        );
    };

    /**
    * Render the icon of the entity
    *
    * @return JSX
    */
    const renderBookmarkedIcon = () => {
        return bookmark && (
            <div key="bookmarked" className="entity-bookmarked">
                <Icon id="bookmarked" color="#ffb400"
                    width={height} title="is bookmarked"
                />
            </div>
        );
    };

    /**
     * Render search mode icon
     */
    const renderSearchModeIcon = () => {
        const { type, mode } = dataEntity,
            searchMode       = (type === 'query' && mode),
            mustShowMode     = searchMode === 'express' || searchMode === 'intelligence';

        return mustShowMode && (
            <div
                key="search"
                className={`entity-is-${searchMode}-search`}
            >
                <Icon
                    id={`orbit-${searchMode}`}
                    width={height}
                    title={`generated by orbit ${searchMode}`}
                />
            </div>
        );
    };

    /**
    * Render icon with value if search is filtered
    *
    * @returns JSX
    */
    const renderSearchFilters = () => {
        const { settings } = dataEntity;
        return filters && _.map(settings, (value, filterKey) => {
            const filterDef = filters.find(filter => filter.id === filterKey && filter.isQueryFilter),
                // TODO: use the knowledge ? but is ont a filter! :/
                isBookmarkFolderForTags = filterKey === 'bookmarkFolderForTags';

            return (filterDef || isBookmarkFolderForTags) && (
                <FilterValues className="last-search query-filter" key={filterKey}
                    filterKey={filterKey} icon={filterDef?.icon || 'folder'}
                    values={value}
                />
            );
        });
    };

    /**
    * Render the add folder action button
    *
    * @return JSX
    */
    const renderAddFolder = () => {
        const { addFolder }        = props,
            { type, ancestorsIds } = entity,
            isFolder               = type === 'bookmark_folder',
            folderDepth            = ancestorsIds ? ancestorsIds.length + 1 : 0,
            tooDeep                = folderDepth > 5,
            btnClassNames          = ['action-add-folder-btn', {'too-deep': tooDeep}],
            title                  = tooDeep ? 'Sorry, you can use 5 levels maximum' : 'Add a subfolder';

        return isFolder && (
            <div
                key="add-folder"
                className="action-add-folder"
                onClick={() => addFolder(entity)}
            >
                <div className={makeStrClassName(btnClassNames)} title={title}>
                    <Icon
                        id="plus"
                        height={8}
                        color={tooDeep ? '#ccc' : '#13275C'}
                        title={title}
                    />
                </div>
            </div>
        );
    };

    /**
    * Render the actions menu
    *
    * @return JSX
    */
    const renderModelActions = () => {
        if(!_.isEmpty(overwriteActions)) {
            return (
                <Action
                    key="overwrite-actions"
                    entityId={entity.id}
                    actions={overwriteActions}
                    renderInline={renderActionsInline}
                    dataQaKey="overwrite-actions-button"
                />
            );
        }

        return (
            <ModelAction
                key="actions"
                entity={bookmark?.model || entity}
                onClick={onClick}
                containerRef={ref?.current}
                editModeCb={editModeCb}
            />
        );
    };

    /**
     * Prevent default event
     *
     * @param {event} e
     * @returns false
    */
    const onClickHolder = (e) => {
        e.preventDefault();
        e.stopPropagation();

        return false;
    };

    /**
     *  Rendering a holder to hang drag and drop
     */
    const renderHolder = () => {
        const {
                draggable, entity
            }                 = props,
            { id, type }      = entity,
            label             = entity.label || entity.entity?.label  || entity.entity?.entity?.label,
            { model }         = bookmark || {},
            { userViewState } = model || {},
            disabled          = userViewState || entity.userViewState || false, // A entity.userViewState for folder
            dataQaKey         = str2DomFormat(`holder of ${label}`);

        return draggable && (
            <span
                key="holder"
                className={`holder${disabled ? ' disabled' : ''}`}
                onClick={onClickHolder}
                draggable={draggable && !disabled}
                data-id={id}
                data-type={type}
                data-qa-key={dataQaKey}
                title={disabled ? 'This entity is updating, wait to drag' : 'Click to drag'}
                {...treeDataInfo}
            >
                {
                    disabled ? (
                        <CssLoader type="ring" size={10}
                            thickness={2} color="#dddddd"
                            title="This entity is updating, wait to drag"
                        />
                    ): (
                        <Icon id="holder" height={10}
                            title="Click to drag"
                            color="#bbbbbb"
                        />
                    )
                }
            </span>
        );
    };

    /**
     * Render icon type
     */
    const renderIconType = () => {
        const { entities, noIcon } = props,
            { type }               = dataEntity || {},
            entityDefinition       = entities?.filter(ent => ent.id === type)?.first(),
            typeLabel              = type === 'query' ? 'Search': capitalize(entityDefinition?.label).toString();

        return !noIcon && type && (
            <div key="icon" className="entity-type">
                <Icon
                    key={type}
                    id={type}
                    folder="/entities/"
                    width={height}
                    title={typeLabel}
                />
            </div>
        );
    };

    /**
     * Set edit mode callback
     */
    const registerEditModeCb = (editModeCb) => {
        setEditModeCb(() => editModeCb);
    };

    /**
     * Render Inline component
     */
    const renderInlineEntity = () => {
        const { renderSuffix, forceEditModeCb } = props,
            { type }              = entity,
            isFolder              = type === 'bookmark_folder';

        return (
            <div className="inline-wrapper">
                {renderSuffix && renderSuffix(entity)}
                <Inline
                    entity={bookmark?.model || entity}
                    bindClickOnComponent={!disableInteractivity}
                    editable={isFolder}
                    registerEditModeCb={registerEditModeCb}
                    onTextEditSave={isFolder && onTextEditSave}
                    forceEditModeCb={forceEditModeCb}
                    onClick={onClick}
                />
            </div>
        );
    };


    /**
    * The render function
    * @returns JSX
    */
    const render = () => {
        const {
                className,
                disabled,
                noIcon,
                draggable,
                disableInteractivity
            } = props,
            { id, type }    = entity,
            classNames      = [
                'entity-block',
                entity.type,
                className,
                {
                    disabled,
                    'no-icon'              : noIcon,
                    'with-holder'          : draggable,
                    'bad-entity'           : !dataEntity?.id,
                    'disable-interactivity': disableInteractivity,
                }
            ];

        return (
            <div
                ref={ref}
                data-id={id}
                data-type={type}
                className={makeStrClassName(classNames)}
                onMouseEnter={handleMouseEnter}
                {...treeDataInfo}
            >
                {prefix}
                {renderInlineEntity()}
                <div key="active-status" className="active-status">
                    {suffix}
                    {actions}
                </div>

            </div>
        );
    };

    // The render call
    return render();
};

Block.propTypes = {
    entity                  : PropTypes.object,
    newslettersList         : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    tags                    : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filters                 : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onClick                 : PropTypes.func,
    renderSuffix            : PropTypes.func,
    getUserViewItemFromModel: PropTypes.func,
    entityIsInNewsletter    : PropTypes.func,
    getModelsOfType         : PropTypes.func,
    entities                : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    storeState              : PropTypes.object,
    noIcon                  : PropTypes.bool,
    className               : PropTypes.string,
    draggable               : PropTypes.bool,
    additionalData          : PropTypes.object,
    parentFolder            : PropTypes.object,
    addFolder               : PropTypes.func,
    updateBookmarkFolder    : PropTypes.func.isRequired,
    updateBookmark          : PropTypes.func.isRequired,
    disableInteractivity    : PropTypes.bool,
    overwriteActions        : PropTypes.object,
    renderActionsInline     : PropTypes.bool,
    useLoading              : PropTypes.bool,
    disabled                : PropTypes.bool,
    forceEditModeCb         : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    context                 : PropTypes.object,
    height                  : PropTypes.number
};

Block.defaultProps = {
    additionalData: {},
    height        : 15,
    loading       : true,
    useLoading    : false,
    disabled      : false,
    addFolder     : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const  newslettersList = state.getIn(['userView', 'newsletter', 'list']),
        bookmarksList      = state.getIn(['userView', 'bookmark', 'list']);

    return { newslettersList, bookmarksList };
};

export default connect(mapStateToProps, {
    getUserViewItemFromModel,
    entityIsInNewsletter,
    getModelsOfType,
    updateBookmark,
    updateBookmarkFolder,
    emitEvent
})(Block);
