// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project.sheet .info-box.other-data {
  padding-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Modal/assets/project.less"],"names":[],"mappings":"AACA;EAGQ,oBAAA;AAFR","sourcesContent":["\n.project.sheet {\n   \n    .info-box.other-data {\n        padding-bottom: 20px;\n    }   \n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
