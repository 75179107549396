// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list .row.orgunit .name {
  position: relative;
}
.list .row.orgunit:hover .actions {
  display: inline-block;
}
.list .row.orgunit .abstract .content {
  line-height: 12px;
  max-height: 60px;
}
.list .row.orgunit .icon {
  vertical-align: text-top;
  position: relative;
}
.list .row.orgunit .country-label {
  position: relative;
  top: 1px;
  left: 5px;
}
.list .row.orgunit .other-info > div {
  text-overflow: ellipsis;
  overflow: hidden;
}
.list .row.orgunit .other-info .open-btn {
  right: 20px;
}
.list .row.orgunit.expanded td {
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/orgunitNetworkNode.less"],"names":[],"mappings":"AAAA;EAMQ,kBAAA;AAJR;AAOI;EAEQ,qBAAA;AANZ;AALA;EAgBQ,iBAAA;EACA,gBAAA;AARR;AATA;EAqBQ,wBAAA;EACA,kBAAA;AATR;AAbA;EA2BQ,kBAAA;EACA,QAAA;EACA,SAAA;AAXR;AAlBA;EAkCY,uBAAA;EACA,gBAAA;AAbZ;AAtBA;EAsCY,WAAA;AAbZ;AAiBI;EAEQ,mBAAA;AAhBZ","sourcesContent":[".list  .row.orgunit {\n\n    @abstract-line-height: 12px;\n    @abstract-max-lines: 5;\n\n    .name {\n        position: relative;\n    }\n\n    &:hover {\n        .actions {\n            display: inline-block;\n        }\n    }\n\n    .abstract .content {\n        line-height: @abstract-line-height;\n        max-height: @abstract-line-height * @abstract-max-lines;\n    }\n\n    .icon{\n        vertical-align: text-top;\n        position      : relative;\n\n    }\n\n    .country-label {\n        position: relative;\n        top: 1px;\n        left: 5px;\n    }\n\n    .other-info {\n        > div {\n            text-overflow: ellipsis;\n            overflow: hidden;\n        }\n        .open-btn {\n            right: 20px;\n        }\n    }\n\n    &.expanded {\n        td {\n            white-space: normal;\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
