/**
 * Keep all actions types listed there
 *
 */

// Triggered when some entities are coming from the API
export const NEW_ENTITIES_ACQUIRED = 'NEW_ENTITIES_ACQUIRED';

// Update the store with the entity provided as payload
export const UPDATE_ENTITY = 'UPDATE_ENTITY';

// Update the store with a list of entities
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';

// Remove an entity from the store
export const REMOVE_ENTITY = 'DELETE_ENTITY';

export default {};
