import Immutable         from 'immutable';
import * as types        from '../actions/types/auth';
import { initialMap }    from '../initialStates';
import { AuthPlatforms } from './auth/platforms';

/**
 * Manage the auth/member slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const Auth = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the state with member
        case types.MEMBER_UPDATED:
            return state.set('member', action.payload);

        // Update the state with API synced member data
        case types.MEMBER_FETCHED:
            return state.set('member', new Immutable.Map(action.payload));

        // Update the state with API synced member data
        case types.MEMBER_DISCONNECTED:
            return state.set('member', false);

        default:
            break;
    }

    return AuthPlatforms(state, action);
};

export default Auth;
