import React, { Component }                                from 'react';
import _                                                   from 'lodash';
import PropTypes                                           from 'prop-types';
import { Icon, Newsletter as NewsletterHelper, CssLoader } from 'helpers';
import { Select }                                          from 'antd';
import { dataGet }                                         from 'utils/api';

const { Option } = Select;

// Import the header stylesheet
import './assets/view.less';

/**
 * View current newsletter
 */
class View extends Component {

    /**
     * Construct
     */
    constructor(props) {
        super(props);

        this.state = {
            newsletterContents: null,
            data              : null,
            contentId         : null,

        };

        _.bindAll(this, 'openSettings', 'onChangeContentSelector');

        this.loadLastContentId();
    }

    /**
     * Componant did update
     */
    componentDidUpdate() {
        // Fetch data of the newsletter
        this.loadDataFromContentID();
    }


    /**
     * Load the last content of the newsletter
     */
    loadLastContentId() {
        const { newsletter } = this.props,
            { id }           = newsletter;

        dataGet(`/newsletter/${id}/contents`).then((results) => {
            const { body }         = results || {},
                newsletterContents = _.sortBy(body || [], ['date_create']).reverse().slice(0, 10),
                newsletterContent  = newsletterContents && newsletterContents[0] || {},
                { id }             = newsletterContent;

            this.setState({
                newsletterContents,
                contentId: id,
            });
        });
    }

    /**
     * Load data from the newsletter content
     */
    loadDataFromContentID() {
        const { data }        = this.state,
            newsletterContent = this.getNewsletterContent(),
            { id }            = newsletterContent || {};

        if (id && !data) {
            dataGet(`/newsletter-content/${id}/content`).then(
                results => this.setState({ data: results && results.body })
            );
        }
    }

    /**
    * Open the settings for the current newsletter
    *
    * @return void
    */
    openSettings() {
        const { openSettings, newsletter } = this.props;

        openSettings(newsletter.key);
    }


    /**
    * Render settings icon
    *
    * @return html
    */
    renderSettingsIcon() {
        return (
            <div className="setting-button">
                <Icon
                    id="cog"
                    title="Settings"
                    height={12}
                    color="#13285c"
                    onClick={this.openSettings}
                />
            </div>
        );
    }


    /**
     * Get the current newsletter content using selectedTag
     *
     * @returns object
     */
    getNewsletterContent() {
        const {
                newsletterContents,
                contentId,
            }                 = this.state,
            newsletterContent = newsletterContents
                && newsletterContents.find(content => content.id === contentId);

        return newsletterContent;
    }


    /**
     * On change context selector
     *
     * @param {change event} e
     */
    onChangeContentSelector(contentId) {
        this.setState({ contentId, data: null });
    }


    /**
    * Render Tag selctor
    *
    * @return html
    */
    renderContentTagSelector() {
        const { newsletterContents } = this.state,
            newsletterContent        = this.getNewsletterContent();

        return newsletterContent && (
            <div>
                <Select
                    value={newsletterContent && newsletterContent.id}
                    onChange={this.onChangeContentSelector}
                    className="content-selector"
                    popupClassName="content-options"
                >
                    {newsletterContents && newsletterContents.map(content => {
                        const { id, date_create } = content,
                            formatedDate          = new Date(date_create).toLocaleString('en-US', {
                                year: 'numeric', month: 'long', day: '2-digit'
                            });
                        return (<Option key={id} value={id}>{formatedDate}</Option>);
                    })}
                </Select>
                <div className="selector-separator" />
            </div>
        );
    }


    /**
     * Render the news isn't already ready
     *
     * @return JSX
     */
    renderEmtpy() {
        return (
            <div className="content no-content">
                <div className="background">
                    <Icon id="innovation-newsletter" width={250}
                        height={360} color="#f5f5f5"
                    />
                </div>
                <div className="message">
                    <div className="big-message">
                        Your Insight Feed will be available soon
                        <br />
                        No worries, we are on it.
                    </div>
                    <div className="small-message">
                        Keep an eye on your mailbox.
                    </div>
                </div>
            </div>
        );
    }


    /**
     * Render newsletter content
     *
     * @returns html
     */
    renderNewsletterContent() {
        const { close }       = this.props,
            { data }          = this.state,
            newsletterContent = this.getNewsletterContent();

        if (!data) {
            return (
                <CssLoader type="ripple" size={100}
                    thickness={2} color="#dddddd"
                />
            );
        }

        return data && newsletterContent && (
            <NewsletterHelper
                appRender
                key={newsletterContent.id}
                data={data}
                newsImage={null}
                entityMax={null}
                close={close}
                openSettings={this.openSettings}
                newsletterContent={newsletterContent}
            />
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { contentId, newsletterContents } = this.state,
            isEmpty                             = !contentId && newsletterContents;

        return (
            <div className="newsletter-view content">
                { isEmpty
                    ? this.renderEmtpy()
                    : this.renderNewsletterContent()}
                {this.renderSettingsIcon()}
                {this.renderContentTagSelector()}
            </div>
        );
    }

}

View.propTypes = {
    newsletter  : PropTypes.object,
    openSettings: PropTypes.func,
    close       : PropTypes.func,
};

View.defaultProps = {
    newsletter: false,
};

export default View;
