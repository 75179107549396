import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import View       from './Newsletter/View';
import Create     from './Newsletter/Create';
import Configure  from './Newsletter/Configure';

/**
 * The main newsletter router
 *
 * @return JSX
 */
class Newsletter extends Component {

    /**
     * Implement logic between newsletter views
     *
     * @return JSX
     */
    getComponentToRender() {
        const { newsletter, displaySettings } = this.props;

        // Create a new newsletter
        if (!newsletter.id || newsletter.date_delete) {
            return Create;
        }

        // Configure the open one
        if (displaySettings) {
            return Configure;
        }

        // Just display it.
        return View;
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { newsletter } = this.props,
            Component        = this.getComponentToRender();

        return <Component {...this.props} key={newsletter.id} />;
    }

}

Newsletter.propTypes = {
    newsletterDefinition: PropTypes.object,
    newsletter          : PropTypes.object,
    displaySettings     : PropTypes.bool
};

Newsletter.defaultProps = {
    newsletterDefinition: {},
    newsletter          : false,
};

export default Newsletter;

