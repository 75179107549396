/**
 * Display the list of dashboards, allowing the user to navigate.
 *
 * @return Component
 */

import _                                    from 'lodash';
import React, { Component }                 from 'react';
import { connect }                          from 'react-redux';
import { emitEvent }                        from 'store/actions/sockets';
import PropTypes                            from 'prop-types';
import { Tooltip, Dropdown }                from 'antd';
import { Icon, Row }                        from 'helpers';
import { updateMemberSettings }             from 'store/actions/auth';
import {
    toggleEntityInNewsletter, entityIsInNewsletter
}                                           from 'store/actions/userView';

import './assets/switcher.less';

/**
* Switcher class
*
*/
class Switcher extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this,
            'getCurrentDashboard', 'onDashboardSwitch', 'onToggleLists',
            'toggleQueryToNewsletter', 'renderDropdownOptions'
        );

        this.state = {};
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {

    }

    /**
    * On dashboard switch
    *
    * @return void
    */
    onDashboardSwitch(dashboardType) {
        const {
            navigateTo,
            updateMemberSettingsCb,
            search,
            emitEvent,
        } = this.props;

        updateMemberSettingsCb({
            search: { last_used_dashboard: dashboardType }
        });

        emitEvent({
            module: 'dashboard',
            name  : 'open',
            data  : {
                model: search,
                key  : dashboardType
            },
            attributesFilter: ['model.id', 'model.type', 'key']
        });

        navigateTo(`../${dashboardType}`);
    }



    /**
     * Send an emitEvent and then calls a callback if needed
     *
     * @param string label action label
     * @param function callback optional callback function
     *
     * @return function
     */
    emitEvent(label, callback) {
        const {
            search,
            dashboard,
            emitEvent
        }  = this.props;

        return () => {
            emitEvent({
                module: 'switcher',
                name  : label,
                data  : {
                    model        : search,
                    ComponentName: 'Switcher',
                    labels       : [label],
                    dashboard,
                },
                attributesFilter: ['model.id', 'model.type', 'dashboard.key', 'dashboard.label', 'componentName', 'labels']
            });

            callback && callback();
        };
    }


    /**
    * On toggleLists
    *
    * @return void
    */
    onToggleLists() {
        const {
                dashboard,
                listsAreDisplayed,
                updateMemberSettingsCb,
            }     = this.props,
            label = listsAreDisplayed ? 'Display charts only' : ' Display charts and lists';

        updateMemberSettingsCb({
            search: _.set({}, `dashboard_settings.${dashboard.key}.lists_are_displayed`, !listsAreDisplayed)
        });

        this.emitEvent(label)();
    }

    /**
    * Return the current dashboard definition
    *
    * @return object
    */
    getCurrentDashboard() {
        const { dashboards } = this.props;

        return dashboards.find((dashboard) => dashboard.key === dashboard);
    }


    /**
     * Orgunit or search is in insight newsletter
     *
     * @param {object} entity
     *
     * @returns boolean
     */
    isInNewsletter() {
        const {
            search, newsletter, entityIsInNewsletter
        } = this.props;

        return newsletter && entityIsInNewsletter(search);
    }

    /**
     * Is a insight feed search
     */
    isInsightFeedSearch() {
        const { search } = this.props,
            { settings } = search || {},
            { weeks }    = settings || {};

        return weeks && weeks.length > 0;
    }


    /**
    * Add/remove search to newletter (topic insight)
    *
    * @param {object} entity
    */
    toggleQueryToNewsletter() {
        const {
                search,
                toggleEntityInNewsletter
            }              = this.props,
            isInNewsletter = this.isInNewsletter(),
            label          = isInNewsletter ? 'Remove from Insight Feed' : 'Monitor search';

        toggleEntityInNewsletter(search, 'topic-insights');

        this.emitEvent(label)();

        return;
    }

    /**
     * Render newsletter button
     */
    renderSearchToNewsletter() {
        const isInNewsletter    = this.isInNewsletter(),
            isInsightFeedSearch = this.isInsightFeedSearch(),
            classNames          = ['newsletter-action'],
            title               = isInNewsletter ? 'Monitored search' : 'Monitor this search';

        if (isInNewsletter) {
            classNames.push('is-in-newsletter');
        }

        return !isInsightFeedSearch && (
            <Tooltip
                title={title}
                placement="bottom"
            >
                <div
                    className={classNames.join(' ')}
                    onClick={isInNewsletter ? null : this.toggleQueryToNewsletter}
                >
                    <Icon
                        key={isInNewsletter}
                        folder="/shortcuts/"
                        id="newsletters"
                        color={isInNewsletter ? '#ffffff' : 'var(--primary-color)'}
                        height={13}
                    />
                </div>
            </Tooltip>
        );
    }


    /**
     * Render insight feed action menu item
     *
     * @return html
     */
    renderInsightFeedAction() {
        const isInsightFeedSearch = this.isInsightFeedSearch(),
            isInNewsletter        = this.isInNewsletter(),
            icon                  = (
                <Icon
                    folder="/shortcuts/"
                    id="newsletters"
                    height={18}
                />
            );

        return !isInsightFeedSearch && ({
            key    : 'toggle-in-newsletter',
            label  : isInNewsletter ? 'Remove from Insight Feed' : 'Monitor search',
            icon,
            onClick: this.toggleQueryToNewsletter,
        });
    }

    /**
     * Render clipboard action menu item
     *
     * @return html
     */
    renderClipboardAction() {
        const { exportDashboard } = this.props,
            label                 = 'Add to export clipboard',
            icon                  = (
                <Icon
                    key={exportDashboard}
                    id="clipboard-add"
                    folder="/shortcuts/"
                    height={18}
                    className="add-to-clipboard"
                    color={exportDashboard ? 'var(--primary-color)' : '#ddd'}
                />
            ),
            tooltipedLabel        = (
                <Tooltip title={!exportDashboard ? 'Please wait until all items are loaded' : null} placement="bottom">
                    {label}
                </Tooltip>
            );


        return {
            icon,
            label        : tooltipedLabel,
            key          : 'chart-and-lists-clipboard',
            disabled     : !exportDashboard,
            'data-qa-key': label,
            onClick      : this.emitEvent(label, exportDashboard)
        };
    }


    /**
    * Render menu item for generate report
    *
    * @return html
    */
    renderReportCreateMenu =() => {
        const {
                exportReport,
                dataIsLoaded
            }     = this.props,
            label = 'Generate one click report',
            icon  = (
                <Icon
                    key={exportReport}
                    id="reports"
                    folder="/shortcuts/"
                    height={18}
                    color={dataIsLoaded===true ? '#13275C' : '#ddd'}
                />
            ),
            tooltipedLabel = (
                <Tooltip title={!dataIsLoaded ? 'Please wait until all items are loaded' : null} placement="bottom">
                    <span className="report">
                        {label}
                    </span>
                </Tooltip>
            );

        return {
            icon,
            label        : tooltipedLabel,
            key          : 'Report',
            disabled     : !dataIsLoaded,
            'data-qa-key': label,
            onClick      : this.emitEvent(label, exportReport)
        };
    }

    /**
    * Render menu item for charts and lists options
    *
    * @return html
    */
    renderChartsAndListsMenu = () => {
        const { listsAreDisplayed, dashboard } = this.props,
            displayListSwitch = dashboard.configuration.hasLists,
            // eslint-disable-next-line
            checkIcon         = <Icon type="check" color="inherit" height={14} />,
            emptyIcon         = <Icon type="empty" color="transparent" />;


        return displayListSwitch ? [
            {
                key     : 'charts',
                label   : 'Display charts only',
                icon    : !listsAreDisplayed ? checkIcon : emptyIcon,
                disabled: !listsAreDisplayed,
                onClick : this.onToggleLists
            },
            {
                key     : 'chart-and-lists',
                label   : 'Display charts and lists',
                icon    : listsAreDisplayed  ? checkIcon : emptyIcon,
                disabled: listsAreDisplayed,
                onClick : this.onToggleLists
            },
            { key: 'divider-1', type: 'divider' }
        ] : [];
    }


    /**
    * Render dropdown with separators
    *
    * @return html
    */
    renderDropdownOptions() {
        return [
            ...this.renderChartsAndListsMenu(),
            ...[
                this.renderInsightFeedAction(),
                { key: 'divider-2', type: 'divider' },
                this.renderReportCreateMenu(),
                { key: 'divider-3', type: 'divider' },
                this.renderClipboardAction()
            ]
        ] || [];
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
            dashboards,
            dashboard,
        } = this.props;

        return (
            <Row className="switcher-bar" TagName="nav">
                <Row className="switcher">
                    {dashboards.map((switchDashboard) => {
                        const { key, label } = switchDashboard;
                        return (
                            <Row
                                onClickCb={() => this.onDashboardSwitch(key)}
                                className={`link ${key === dashboard.key ? 'active' : ''}`}
                                key={key}
                                datadashboardid={key}
                            >
                                {label}
                            </Row>
                        );
                    })}
                </Row>
                <Row className="left">
                    {this.renderSearchToNewsletter()}
                    <Dropdown className="options actions-list" overlayClassName="dashboard-options actions-list"
                        menu={{ items: this.renderDropdownOptions() }} trigger={['click']}
                        destroyPopupOnHide
                    >
                        <span>
                            <Icon type="options" height={15} />
                        </span>
                    </Dropdown>
                </Row>
            </Row>
        );
    }

}

/**
* Validate properties for current Component
*/
Switcher.propTypes = {
    dashboard               : PropTypes.object.isRequired,
    listsAreDisplayed       : PropTypes.bool.isRequired,
    updateMemberSettingsCb  : PropTypes.func.isRequired,
    navigateTo              : PropTypes.func.isRequired,
    exportDashboard         : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    toggleEntityInNewsletter: PropTypes.func,
    entityIsInNewsletter    : PropTypes.func,
    emitEvent               : PropTypes.func,
    search                  : PropTypes.object,
    newsletter              : PropTypes.object,
    dataIsLoaded            : PropTypes.bool.isRequired,
    exportReport            : PropTypes.func.isRequired,
    dashboards              : PropTypes.arrayOf(
        PropTypes.shape({
            type     : PropTypes.string.isRequired,
            label    : PropTypes.string.isRequired,
            component: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.bool
            ])
        }).isRequired
    ).isRequired,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    newslettersStream: state.getIn(['userView', 'newsletter', 'list']),
    entitiesStream   : state.getIn(['userView', 'entity', 'list']),
});


/**
 * Bind the store to to component
 */
export default connect(mapStateToProps, {
    updateMemberSettingsCb: updateMemberSettings,
    toggleEntityInNewsletter,
    entityIsInNewsletter,
    emitEvent,
})(Switcher);
