import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import _                    from 'lodash';

const svgSourcePool = {};

/**
 * Display a SVG using ReactSVG
 * And remove double TAG wrapper !!!
 *
 */
class OvationSvg extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(
            this, 'loadingSourceFromPool'
        );

        this.ovationSvgNodeRef = React.createRef();

        this.state = {
            svgSource: null
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { src } = this.props;

        if (svgSourcePool[src]) {
            this.loadingSourceFromPool();
            return;
        }

        svgSourcePool[src] = 'is-loading';
        fetch(src).then(
            (response) => {
                response.text().then((svgSource) => {
                    svgSourcePool[src] = svgSource;
                    this.loadingSourceFromPool();
                });
            }
        );
    }

    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidUpdate() {
        this.alterSvg();
    }

    /**
    * Triggered when the component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        this.isCancelled = true;
    }

    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    loadingSourceFromPool() {
        const { src, color, replaceTexts } = this.props;

        let svgSource                      = svgSourcePool[src];

        if (this.isCancelled) {
            return;
        }

        if (svgSourcePool[src] === 'is-loading') {
            setTimeout(this.loadingSourceFromPool, 50);
            return;
        }

        // Replace color
        if (color) {
            svgSource = svgSource.replace(/#000000/g, color);
        }

        // Replace text paterns
        replaceTexts.forEach((replaceDef) => svgSource = svgSource.replace(new RegExp(replaceDef.patern, 'g'), replaceDef.value));

        this.setState({ svgSource });
    }

    /**
    * Alter svg  size - color
    *
    * @return svgnode
    */
    alterSvg() {
        const { width, height } = this.props,
            ovationSvgNode      = this.ovationSvgNodeRef.current,
            svgnode             = ovationSvgNode && ovationSvgNode.children && ovationSvgNode.children.length
                ? ovationSvgNode.children[0] : null;

        if (svgnode) {
            svgnode.setAttribute('width', width);
            svgnode.setAttribute('height', height);
            return;
        }

        // IE
        const children = Array.from(ovationSvgNode.childNodes);

        children.forEach((child) => {
            if (child.tagName === 'svg') {
                child.setAttribute('width', width);
                child.setAttribute('height', height);
            }
        });
    }

    /**
    * Alter svg node
    *    - Change color
    *    - Remove tag for dom-to-img compatibility
    *
    * @return svgnode
    */
    cleanSvg(svg) {
        const metadataNode = svg.querySelector('metadata'),
            namedviewNode  = svg.querySelector('namedview'); // Sodipodi:namedview

        // Remove tag for dom-to-img compatibility
        if (metadataNode) { metadataNode.parentNode.removeChild(metadataNode); }
        if (namedviewNode) { namedviewNode.parentNode.removeChild(namedviewNode); }

        return svg;
    }

    /**
    * Render the icon
    *
    * @return JSX
    */
    render() {
        const { width, height, style } = this.props,
            { wrapper, onClick }       = this.props,
            { svgSource }              = this.state,
            coloredSvgSource           = svgSource;

        return wrapper === 'g' ? (
            <g
                className="OvationSvg"
                transform={`translate( ${-width / 2} ${-height / 2} )`}
                ref={this.ovationSvgNodeRef}
                dangerouslySetInnerHTML={{ __html: coloredSvgSource }}
                onClick={(e) => { _.isNull(onClick) ? null : onClick(); e.preventDefault(); return true; }}
                style={style}
            />
        )
            : (
                <div
                    className="OvationSvg"
                    ref={this.ovationSvgNodeRef}
                    dangerouslySetInnerHTML={{ __html: coloredSvgSource }}
                    onClick={onClick || (() => {})}
                    style={style}
                />
            );
    }

}

OvationSvg.propTypes = {
    src         : PropTypes.string.isRequired,
    width       : PropTypes.number.isRequired,
    height      : PropTypes.number.isRequired,
    wrapper     : PropTypes.string,
    color       : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    replaceTexts: PropTypes.array,
    onClick     : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    style       : PropTypes.object,
};

OvationSvg.defaultProps = {
    wrapper     : 'div',
    color       : false,
    replaceTexts: [],
    onClick     : null,
    style       : {},
};

export default OvationSvg;
