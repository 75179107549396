import _                 from 'lodash';
import * as types        from '../actions/types/sockets';
import { initialMap }    from '../initialStates';
import CryptoJS          from 'crypto-js';


/**
 * Get data filtered by attributesFilter
 *
 * @param {object|array} data
 * @param {array of string} attributesFilter
 * @returns
 */
const filterDataFromAttributesFilter = (data, attributesFilter) => {
    if (!attributesFilter) {
        return data;
    }

    if (_.isArray(data)) {
        return data.map(d => _.pick(d, attributesFilter));
    }

    if (_.isObject(data)) {
        return _.pick(data, attributesFilter);
    }

    return data;
};

/**
 *
 * @param {Imutable map} state  The global redux state
 * @param {object}       param1 Params to emit for socket.io
 */
const emitEvent = (state, payload) => {
    const socket        = state.get('socket'),
        encryptedTicket = localStorage.getItem('OI'),
        ticket          = encryptedTicket
            ? CryptoJS.AES.decrypt(encryptedTicket, process.env.OVATION_SECRET).toString(CryptoJS.enc.Utf8)
            : false,
        {
            module, name,
            data, attributesFilter
        }               = payload,
        dataToSend      = filterDataFromAttributesFilter(data, attributesFilter);

    socket?.emit('user-action', { name, module, ticket, data: dataToSend });

    return state;
};


/**
 * Manage the auth/member slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const Sockets = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        // Sockets are initialized
        case types.WEB_SOCKETS_INITIALIZED:
            console.log('WEB_SOCKETS_INITIALIZED');
            return state.set('socket', action.payload);

        case types.WEB_SOCKETS_EMIT_EVENT:
            return emitEvent(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Sockets;
