/**
* Ovation AutoComplete : Use it to complete some entity or some concept
*
* @use import AutoComplete then render <AutoComplete />
*/
import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    Tooltip,
    Dropdown,
    Popover
}                           from 'antd';
import { SearchOutlined }   from '@ant-design/icons';
import { dataGet }          from 'utils/api';
import  Menus               from './Autocomplete/Menus';
import {
    hasWordsInSentence,
    countWordsInSentence,
}                                    from 'utils/text';

import './assets/autocomplete.less';

/**
* Insight generic loader
*
* @param Component content A single element
*/
class Autocomplete extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render', 'renderInput', 'performAutocomplete', 'cancelAutocomplete',
            'onSelect', 'decorateWithSuggestions', 'getEndpoints', 'hasValue', 'renderSuggestionsMenu',
            'handleTextareaKeyup', 'handleTextareaKeypress', 'handleBodyClick', 'handleTextareaFocus',
            'onSubmit', 'reset', 'focus', 'manageNavigation', 'onKeyDown', 'blur');

        // The minimum length for a string to perform an autocomplete
        this.minLength = 1;

        // Assign the editor to the autocomplete
        this.textareaRef = React.createRef();
        this.dropdownRef = React.createRef();

        // Where to store XHR promises
        this.endpointPromises = [];

        // Prepare the state to manage data/autocomplete
        this.state = {
            focused        : false,
            value          : '',
            suggestions    : [],
            sentenceContent: '',
            selected       : null
        };

        this.debouncedPerformAutocomplete = _.debounce(
            this.performAutocomplete,
            800
        );
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks } = this.props;

        // Ensure autocomplete binding to the DOM
        this.manageBinding();

        // Allow the parent to reset autocomplete
        registerCallbacks('reset', this.reset);
        registerCallbacks('focus', this.focus);
        registerCallbacks('has-value', this.hasValue);
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
        const { isResetting } = this.state;

        if (isResetting) {
            this.resetValueAndSuggestions();
        }

        this.manageBinding();
    }

    /**
    * Umount autocomplete
    *
    * return void
    */
    componentWillUnmount() {
        if (this.textareaRef.current) {
            this.textareaRef.current.removeEventListener('keyup', this.handleTextareaKeyup);
            this.textareaRef.current.removeEventListener('keypress', this.handleTextareaKeypress);
            this.textareaRef.current.removeEventListener('focus', this.handleTextareaFocus);
        }

        document.body.removeEventListener('click', this.handleBodyClick);
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        return prevState;
    }

    /**
    * Has some value
    *
    * @return bool
    */
    hasValue() {
        const input = this.textareaRef.current;

        return input && input.value.length > 0;
    }

    /**
    * Add a fresh data to the selected data array
    *
    * @param string input The searched input
    *
    * @return false
    */
    onSelect(input) {
        const { onSelect } = this.props;

        this.debouncedPerformAutocomplete.cancel();
        this.cancelAutocomplete();

        if (onSelect) {
            return onSelect(input);
        }

        return false;
    }

    /**
    * Triggered when the user clicks on the ?
    *
    * @return false
    */
    onSubmit() {
        return false;
    }

    /**
    * Return orderedSuggestions array
    *
    * @return array
    */
    getOrderedSuggestions() {
        const { types }        = this.props,
            { suggestions }    = this.state,
            isMixedType        = types.length > 1,
            orderedSuggestions = [];

        // Only one type send data as suggestions
        if (!isMixedType) {
            const singleSuggestion = suggestions.find(
                (suggestion) => types[0] === suggestion.id
            );

            return singleSuggestion;
        }

        // Reorder suggestion for mixed types from types.forEach
        types.forEach((type) => {
            const dataForType = suggestions.find((suggestion) => type === suggestion.id);

            orderedSuggestions.push(dataForType || {
                id  : type,
                data: []
            });
        });

        return orderedSuggestions;
    }

    /**
    * Return a select'able' data source array
    *
    * @return JSX
    */
    renderSuggestionsMenu() {
        const {
                types, renderSuffix, placement
            }                  = this.props,
            { selected }       = this.state,
            isMixedType        = types.length > 1,
            MenuComponent      = isMixedType ? Menus.mixed : Menus[types[0]],
            orderedSuggestions = this.getOrderedSuggestions(),
            isAfterPlacement   = placement === 'after',
            className          = isAfterPlacement
                ? 'autocomplete-dropdown autocomplete-after'
                : 'autocomplete-dropdown';

        return (
            <div className={className} ref={this.dropdownRef}>
                <MenuComponent
                    suggestions={orderedSuggestions}
                    selected={!selected || types.length > 1 ? selected : selected.row}
                    onSelect={this.onSelect}
                    renderSuffix={renderSuffix}
                />
            </div>
        );
    }

    /**
    * Force dom binding
    *
    * return void
    */
    manageBinding() {
        // Handle click on body
        document.removeEventListener('click', this.handleBodyClick);
        document.addEventListener('click',    this.handleBodyClick);

        const textareaNode = this.textareaRef.current;

        // Textarea binding
        if (textareaNode) {
            textareaNode.removeEventListener('keyup',    this.handleTextareaKeyup);
            textareaNode.addEventListener('keyup',       this.handleTextareaKeyup);
            textareaNode.removeEventListener('keypress', this.handleTextareaKeypress);
            textareaNode.addEventListener('keypress',    this.handleTextareaKeypress);
            textareaNode.removeEventListener('focus',    this.handleTextareaFocus);
            textareaNode.addEventListener('focus',       this.handleTextareaFocus);
        }
    }

    /**
    * Handle the click on the body
    *
    * @return void
    */
    handleBodyClick(e) {
        const { placement } = this.props,
            { suggestions } = this.state,
            { target }      = e;

        // No handleClick for the after placement
        if (placement === 'after') {
            return;
        }

        // Reset the suggestions in case of click on the body
        if (
            (
                !(target.closest('.ant-modal-wrap')
                || target.closest('.autocomplete-dropdown'))
            )
            && suggestions.length
        ) {
            this.setState({
                suggestions: [],
            });
        }
    }

    /**
    * Handle the keypress on textarea
    *
    * @return
    */
    handleTextareaKeypress(e) {
        // Send the request !
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }

        return true;
    }


    /**
    * Handle the focus on textarea
    *
    * @return
    */
    handleTextareaFocus() {
        const textarea = this.textareaRef.current,
            { value }  = textarea;

        if (!value || value.trim().length === 0) {
            return;
        }

        this.debouncedPerformAutocomplete(value);
    }

    /**
    * Handle the keypress on textarea
    *
    * @return
    */
    handleTextareaKeyup(e) {
        const { onSubmit } = this.props,
            {
                suggestions, selected,
            }              = this.state,
            textarea       = this.textareaRef.current,
            { value }      = textarea,
            enterKeyCode   = e.keyCode === 13;

        // Add the selected input
        if (enterKeyCode && selected) {
            e.preventDefault();
            e.stopPropagation();
            const suggestionObj = suggestions.find((suggestion) => suggestion.id === selected.type);

            if (suggestionObj) {
                this.onSelect(suggestionObj.data[selected.row]);
                return false;
            }

            onSubmit();

            return false;
        }

        // Manage navigation
        if (
            this.previousValue === value
            || suggestions.length && this.manageNavigation(e.keyCode)
        ) {
            return true;
        }

        this.previousValue = value;

        // Now we can press enter to add custom concept but don't restart perform autocomplete in this case
        if (!enterKeyCode) {
            this.debouncedPerformAutocomplete(value);
        }

        return true;
    }


    /**
    * Manage navigation with keyboard thru suggestions
    *
    * @return bool
    */
    manageNavigation(keyCode) {
        const { selected } = this.state;

        // Manage down
        if (keyCode === 40 && this.down(keyCode)) {
            return true;
        }

        // No selected suggestion, so we can't navigate into them
        if (!selected) {
            return false;
        }

        return (keyCode === 38 && this.up(keyCode)) // UP
            || (
                [37, 39].indexOf(keyCode) !== -1
                    && this.leftOrRight(keyCode)     // LEFT & RIGHT
            );
    }

    /**
    * Manage DOWN
    *
    * @param integer keyCode The pressed Key
    *
    * @return bool
    */
    down() {
        const { types }                  = this.props,
            { selected, suggestions }    = this.state,
            currentSelected              = selected || {
                type: types[0],
                row : 0
            },
            currentSuggestion            = suggestions.find(
                (suggestion) => suggestion.id === currentSelected.type
            ),
            dataLength                   = currentSuggestion.data.length;

        // No selected, select the first suggestion
        if (!selected) {
            this.setState({ selected: currentSelected });
            return true;
        }

        // Otherwise, next row from the same list
        this.setState({
            selected: {
                ...selected,
                row: selected.row < dataLength - 1
                    ? selected.row + 1
                    : dataLength - 1
            }
        });

        return true;
    }

    /**
    * Manage UP
    *
    * @param integer keyCode The pressed Key
    *
    * @return bool
    */
    up() {
        const { selected } = this.state;

        // Up on first row should remove selected
        if (selected.row === 0) {
            this.setState({ selected: null });
            return true;
        }

        // Otherwise go up.
        this.setState({
            selected: {
                ...selected,
                row: selected.row - 1
            }
        });

        return true;
    }

    /**
    * Manage left or right key press from keycode
    *
    * @param integer keyCode The pressed Key
    *
    * @return bool
    */
    leftOrRight(keyCode) {
        // Left & Right are not handled for only one type.
        const { types }         = this.props,
            { selected }        = this.state,
            dropdownNode        = this.dropdownRef.current,
            mixedMenuNode       = dropdownNode.querySelector('.mixed-menu'),
            menusGroups         = Array.from(mixedMenuNode.childNodes),
            currentTypeIndex    = types.findIndex((type) => type === selected.type),
            nextIndex           = keyCode === 39 ? currentTypeIndex + 1 : currentTypeIndex - 1,
            selectedElement     = dropdownNode.querySelector('.selected'),
            selectedElementRect = selectedElement.getBoundingClientRect();

        // There's no next menu.
        if (!menusGroups[nextIndex]) {
            return true;
        }

        const nextSuggests = menusGroups[nextIndex].querySelectorAll('.suggest');

        let selectedRow = null;

        // Look for the next selected node element
        nextSuggests.forEach((suggest, index) => {
            const suggestRect = suggest.getBoundingClientRect(),
                thisIsIt      = selectedElementRect.y >= suggestRect.y
                    && selectedElementRect.y < suggestRect.y + suggestRect.height;

            if (thisIsIt) {
                selectedRow = index;
            }
        });

        this.setState({
            selected: {
                type: types[nextIndex],
                row : !_.isNull(selectedRow) ? selectedRow : nextSuggests.length - 1
            }
        });

        return true;
    }

    /**
    * Create promise to perform autocomplete
    *
    * @return array
    */
    getEndpoints(value) {
        const { types }                     = this.props,
            endpoints                       = [
                {
                    id           : 'concept',
                    max          : 8,
                    validation   : this.validateConceptString(value),
                    beforeResolve: (data) => {
                        const dataToReturn  = data || [];

                        dataToReturn.push(value.replace(/[^0-9a-zA-Z- .]/gi, '')); // Replace ", then inject concept

                        return dataToReturn;
                    }
                },
                {
                    id : 'orgunit',
                    max: 10,
                }
            ],
            filteredEndpoints = endpoints.filter(
                (endpoint) => types.indexOf(endpoint.id) !== -1
            );

        for (const key in filteredEndpoints) {
            const { id, validation } = filteredEndpoints[key],
                { valid, message }   = validation || {};

            // If error create a rejected promise
            filteredEndpoints[key].promise = (valid === false)
                ? new Promise((resolve, reject) => { reject(new Error(message)); })
                : dataGet(`/${id}s`, { data: { q: value } });
        }

        this.endpointPromises = filteredEndpoints;

        return filteredEndpoints;
    }

    /**
    * Create an array with loading states
    *
    * @return []
    */
    getLoadersForEndpoints(endpoints) {
        const { max } = this.props;

        return endpoints.map((endpoint) => ({
            id  : endpoint.id,
            data: _.times(max || endpoint.max, ({ isLoading: true }))
        }));
    }

    /**
    * Cancel every previous performed autocomplete
    *
    * @return void
    */
    cancelAutocomplete() {
        // Cancel all promises if it's needed
        if (!this.endpointPromises.length) {
            return;
        }

        this.endpointPromises.forEach(endpoint => {
            endpoint?.promise?.cancel && endpoint.promise.cancel();
        });

        this.endpointPromises = [];
    }

    /**
     * Validate concept value
     *
     * @param string concept string
     *
     * @return object
     */
    validateConceptString(value) {
        const maxWords    = 5,
            excludedTerms = ['AND', 'OR'],
            inputValue    = _.trimStart(value);

        if(hasWordsInSentence(excludedTerms, inputValue, false)) {
            return {
                message: `Concepts cannot include the terms ${excludedTerms.join(' or ')}`,
                valid  : false
            };
        }
        if(countWordsInSentence(inputValue) > 5) {
            return {
                message: `Concepts must not have more than ${maxWords} words`,
                valid  : false
            };
        }

        return {valid: true, message: ''};
    }


    /**
    * Manage to search data for autocomplete
    *
    * @param string input The searched input
    *
    * @return false
    */
    performAutocomplete(value) {
        const { max }  = this.props,
            element    = this.textareaRef.current;

        // Prevent undefined element case
        if (!element) { return false; }

        const inputValue    = _.trimStart(value),
            uniqId          = new Date().getTime(),
            hasEnoughChars  = inputValue.length >= this.minLength;

        this.cancelAutocomplete();

        // Prevent XHR when there's no luck to obtain suggests
        if (!hasEnoughChars || !element || _.isEmpty(element.value)) {
            _.isEmpty(element.value) && this.setState(
                {suggestions: [], selected: null}
            );
            return false;
        }

        // Clear a previous timeout to prevent multi post
        this.suggestId = uniqId;

        const endpoints = this.getEndpoints(value),
            freshSuggestions = [];

        // Set suggestions loaders, reset selected endpoint
        this.setState({
            suggestions: this.getLoadersForEndpoints(endpoints),
            selected   : null,
        });

        endpoints.forEach((endpoint) => endpoint
            // Resolved promise
            .promise.then(
                (promiseResults) => {
                    const onBeforeResolve = endpoint.beforeResolve
                        ? endpoint.beforeResolve : (data) => data;

                    // Prevent late renders
                    if (this.suggestId !== uniqId) {
                        return;
                    }

                    const { body } = promiseResults,
                        bodyToSlice = body || [],
                        data        = onBeforeResolve(bodyToSlice.slice(0, max || endpoint.max));

                    freshSuggestions.push({
                        id: endpoint.id,
                        data
                    });
                    this.setState({suggestions: freshSuggestions});
                }
            // Add error message from rejected promise
            ).catch(error => {
                freshSuggestions.push({
                    id   : endpoint.id,
                    data : [],
                    error: error.message
                });
                this.setState({suggestions: freshSuggestions});
            })
        );

        return false;
    }

    /**
    * Decorate with dropdown
    *
    * @return JSX
    */
    decorateWithDropdown(input) {
        const { placement, overlayClassName } = this.props,
            { suggestions }                   = this.state;

        if (['left', 'leftTop', 'right', 'rightTop'].indexOf(placement) !== -1) {
            return false;
        }

        return (
            <Dropdown
                overlayClassName={overlayClassName}
                overlayStyle={{ zIndex: 1000 }}
                dropdownRender={this.renderSuggestionsMenu}
                open={!!suggestions.length}
            >
                {input}
            </Dropdown>
        );
    }

    /**
    * Decorate with Popover
    *
    * @return JSX
    */
    decorateWithPopover(input) {
        const { placement, overlayClassName } = this.props,
            { suggestions }                   = this.state;

        return (
            <Popover
                overlayClassName={overlayClassName}
                placement={placement}
                content={this.renderSuggestionsMenu()}
                title={false}
                open={!!suggestions.length}
            >
                {input}
            </Popover>
        );
    }

    /**
     * Render suggestion directly after the input
     *
     * @returns
     */
    decorateAfter(input) {
        const { placement } = this.props;

        return placement === 'after' && (
            <>
                {input}
                {this.renderSuggestionsMenu()}
            </>
        );
    }

    /**
    * Add the dropdown if needed
    *
    * @return JSX
    */
    decorateWithSuggestions(input) {
        return this.decorateAfter(input)
            || this.decorateWithDropdown(input)
            || this.decorateWithPopover(input);
    }


    /**
    *
    * Should I display placeholder
    *
    * @return bool
    */
    hidePlaceholder() {
        const { sentenceContent } = this.state;

        return sentenceContent && ((_.isString(sentenceContent) && sentenceContent.length)
                ||  (sentenceContent.nodeType === 3 && sentenceContent.length)
                ||  (sentenceContent.innerText && sentenceContent.innerText.length));
    }

    /**
    * Reset
    */
    reset() {
        const { isResetting } = this.state;

        if (isResetting) {
            return;
        }

        this.setState({ isResetting: true });
    }

    /**
    * Reset the autocomplete
    *
    * @return void
    */
    resetValueAndSuggestions() {
        const textareaEl = this.textareaRef.current;

        if (!textareaEl) {
            return;
        }

        this.setState({
            value      : '',
            suggestions: [],
            isResetting: false,
        });

        textareaEl.value = '';
    }

    /**
    * Focus the autocomplete
    *
    * @return void
    */
    focus() {
        const textareaEl = this.textareaRef.current;

        textareaEl?.focus();

        this.setState({ focused: true });
    }

    /**
    * Blur the autocomplete
    *
    * @return void
    */
    blur() {
        const textareaEl = this.textareaRef.current;

        textareaEl.blur();

        this.setState({ focused: false });
    }

    /**
    * Push class names for the current autocomplete
    *
    * @return string
    */
    getGlobalClassNames() {
        const { focused } = this.state,
            classNames    = ['autocomplete'];

        if (focused) { classNames.push('is-focused'); }

        return classNames;
    }

    /**
    * On key down
    *
    * @param {*} e
    */
    onKeyDown(e) {
        const { types }    = this.props,
            { autoFocus }  = this.props,
            { value }      = this.textareaRef.current,
            hasConceptType = _.includes(types, 'concept'),
            enterKeyCode   = e.keyCode === 13, // KeyCode 13 = Enter
            { valid }      = this.validateConceptString(value),
            isValid        = valid !== false,
            canPressEnter  = isValid && hasConceptType && enterKeyCode && !_.isEmpty(value);

        // Trigger onSelect custom concept if we press enter
        if (canPressEnter) {
            this.onSelect(value);

            if (!autoFocus) {
                this.setState({ focused: false });
            }
        }
    }

    /**
    * Render the input in the autocomplete form
    *
    * @return html
    */
    renderInput() {
        const { sentenceContent }   = this.state,
            { placeholder }         = this.props,
            { disabled, autoFocus } = this.props,
            placeholderToRender     = !this.hidePlaceholder()
                ? placeholder || 'nanomaterials, biotechnology, synthesis...' : false;

        return (
            <textarea
                ref={this.textareaRef}
                className="search"
                placeholder={placeholderToRender}
                value={sentenceContent || undefined}
                onChange={_.noop}
                onKeyDown={this.onKeyDown}
                onFocus={this.focus}
                onBlur={this.blur}
                disabled={disabled}
                autoFocus={autoFocus}
            />
        );
    }

    /**
    * Render the search icon
    *
    * @return JSX
    */
    renderIcon() {
        const { value } = this.props;

        return (
            <Tooltip
                open={!_.isUndefined(this.input) && this.input.length > 0 && !value}
                title="Go!"
            >
                <SearchOutlined onClick={this.onSubmit} />
            </Tooltip>
        );
    }

    /**
    * Render input suffix (parser, help?, etc..)
    *
    * @return JSX
    */
    renderInputSuffix() {
        const { renderInputSuffix } = this.props;

        return renderInputSuffix ? renderInputSuffix() : false;
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { label } = this.props,
            classNames  = this.getGlobalClassNames();

        return (
            <div className={classNames.join(' ')}>
                {label}
                {this.decorateWithSuggestions(
                    <div>
                        {this.renderInput()}
                        {this.renderInputSuffix()}
                    </div>
                )}
            </div>
        );
    }

}

Autocomplete.propTypes = {
    autoFocus        : PropTypes.bool,
    label            : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    max              : PropTypes.number,
    onSelect         : PropTypes.func,
    onSubmit         : PropTypes.func,
    overlayClassName : PropTypes.string,
    placeholder      : PropTypes.string,
    placement        : PropTypes.string,
    registerCallbacks: PropTypes.func.isRequired,
    renderInputSuffix: PropTypes.func.isRequired,
    renderSuffix     : PropTypes.any,
    value            : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
    types            : PropTypes.arrayOf(PropTypes.string),
    disabled         : PropTypes.bool,
};

// Specifies the default values for props:
Autocomplete.defaultProps = {
    autoFocus        : false,
    overlayClassName : '',
    placement        : 'bottom',
    value            : false,
    registerCallbacks: () => {},
    renderInputSuffix: () => {},
    onSubmit         : () => {},
    label            : false,
    disabled         : false,
};

export default Autocomplete;

