// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-graph {
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}
.element-graph > div > .actions {
  display: none;
}
.element-graph:hover > div > .actions {
  display: block;
}
.element-graph .content {
  flex-grow: 1;
}
.element-graph .Graph {
  width: 100%;
}
.expanded-element-graph .ant-modal-content {
  height: 100%;
}
.expanded-element-graph .ant-modal-body {
  display: flex;
  height: 100%;
  width: 100%;
}
.expanded-element-graph .ant-modal-body > div {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.expanded-element-graph .ant-modal-body > div > .label {
  flex: none;
  color: #aaa !important;
  font-size: 30px !important;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.expanded-element-graph .element-graph {
  display: flex;
  flex-grow: 1;
}
.expanded-element-graph .element-graph .layout-graph,
.expanded-element-graph .element-graph .Graph {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.expanded-element-graph .ant-modal {
  top: 2.5%;
}
.expanded-element-graph .row.List {
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Element/assets/graph.less"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAEI;EACI,aAAA;AAAR;AAGI;EACI,cAAA;AADR;AATA;EAaQ,YAAA;AADR;AAZA;EAiBQ,WAAA;AAFR;AAOA;EAEQ,YAAA;AANR;AAIA;EAKQ,aAAA;EACA,YAAA;EACA,WAAA;AANR;AADA;EAUY,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AANZ;AAPA;EAegB,UAAA;EACA,sBAAA;EACA,0BAAA;EACA,6BAAA;EACA,iBAAA;AALhB;AAdA;EA0BQ,aAAA;EACA,YAAA;AATR;AAlBA;;EA6BY,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAPZ;AAzBA;EAqCQ,SAAA;AATR;AA5BA;EAyCQ,cAAA;AAVR","sourcesContent":["\n.element-graph {\n    max-width : 100%;\n    height    : 100%;\n    overflow  : hidden;\n\n    &> div > .actions {\n        display: none;\n    }\n\n    &:hover > div > .actions {\n        display: block;\n    }\n    .content {\n        flex-grow: 1;\n    }\n\n    .Graph {\n        width : 100%;\n    }\n}\n\n\n.expanded-element-graph {\n    .ant-modal-content {\n        height: 100%;\n    }\n    .ant-modal-body {\n        display: flex;\n        height : 100%;\n        width  : 100%;\n\n        > div {\n            position      : relative;\n            display       : flex;\n            flex-direction: column;\n            width         : 100%;\n            > .label {\n                flex         : none;\n                color        : #aaa !important;\n                font-size    : 30px !important;\n                border-bottom: 1px solid #ddd;\n                font-weight  : bold;\n\n            }\n        }\n    }\n\n    .element-graph {\n        display  : flex;\n        flex-grow: 1;\n        .layout-graph, .Graph {\n            position  : relative;\n            max-width : 100%;\n            max-height: 100%;\n            flex-grow : 1;\n        }\n    }\n\n    .ant-modal{\n        top: 2.5%;\n    }\n\n    .row.List {\n        overflow: auto;\n\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
