// Import libs
import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import ImmutablePropTypes           from 'react-immutable-proptypes';
import _                            from 'lodash';
import { Helmet }                   from 'react-helmet';

import ModelAction                  from 'helpers/Action/Model';

import { connect }                  from 'react-redux';
import { addItems }                 from 'store/actions/userView';

import { dataGet }                  from 'utils/api';
import {
    getDateString, getTimeString,
}                                   from 'utils/date';
import {
    stripTags, makeStrClassName,
    sanitizeFilename, getSobjImage,
}                                   from 'utils/text';

// Import dumb components by entity type
import Patent                       from './Modal/Patent';
import IdCard                       from './Modal/IdCard';
import Project                      from './Modal/Project';
import Scidoc                       from './Modal/Scidoc';
import Clinical                     from './Modal/Clinical';

import './assets/modal.less';

// Create the component collection
const ModalsComponents = {
    // Sheets
    patent               : Patent,
    project              : Project,
    scidoc               : Scidoc,
    clinicaltrial        : Clinical,
    // ID cards
    orgunit              : IdCard,
    expert               : IdCard,
    orgunit_collaboration: IdCard,
};

// CONSTANTS
const ENTITYWITHIDCARDS = ['orgunit', 'expert', 'orgunit_collaboration'];

/**
 * Display a list row
 *
 */
class Modal extends Component {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this,
            'expandIdCard', 'onClick', 'addItemsToClipboard',
            'registerCallbacks', 'makeClipboardItem'
        );

        this.ref   = React.createRef();

        this.state = {
            completeEntity  : null,
            idCardIsExpanded: false,
            callbacks       : {},
        };
    }

    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidMount() {
        this.loadCompleteEntity();
    }

    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidUpdate() {
        const { completeEntity }         = this.state,
            { entity, registerMetadata } = this.props,
            isIdCard                     = this.isIdCard();

        // Reload entity from props;
        if (completeEntity && completeEntity.entity?.id !== entity.id) {
            this.loadCompleteEntity();
        }

        if (!isIdCard) {
            registerMetadata({ title: stripTags(entity.label), actions: this.renderModalActions() });
        }
    }

    /**
    * Store an unmounted bool to prevent callbacks on GC'ed components
    *
    * @return self
    */
    componentWillUnmount() {
        this.dataGetPromise.cancel();
    }

    /**
    * Render actions buttons
    *
    * @return JSX
    */
    renderModalActions() {
        const {
            entity,
            registerMetadata
        } = this.props;

        return (
            <ModelAction
                entity={entity}
                addToClipboard={this.addItemsToClipboard || false}
                registerMetadata={registerMetadata}
            />
        );
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { callbacks } = this.state,
            /** Return empty object */
            defaultCb       = () => {};

        if (!callbacks[action]) {
            callbacks[action] = [];
        }
        callbacks[action].push(cb || defaultCb);

        this.setState({ callbacks });
    }

    /**
    * Toggle the id card expand state
    *
    * @return void
    */
    expandIdCard() {
        const { idCardIsExpanded } = this.state;

        this.setState({
            idCardIsExpanded: !idCardIsExpanded
        });
    }

    /**
    * Toggle the modal Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    onClick(entity, entities) {
        const { navigateTo } = this.props;

        if (navigateTo) {
            navigateTo(entity, entities);
        }
    }

    /**
    * Load the complete entity for displaying the sheet.
    */
    loadCompleteEntity() {
        const { entity, search } = this.props,
            trueUri              = '/entities',
            searchData           = search && {idq: search.id},
            data                 = {
                ...searchData,
                ids: [entity.id],
            };

        this.setState({ completeEntity: null });

        this.dataGetPromise = dataGet(trueUri, {data});

        this.dataGetPromise.then(({ status, body }) => {
            // Documents not ready
            if (status.code === 206) {
                this.setState({ completeEntity: null });
                return;
            }

            this.setState({ completeEntity: body[0] });
        });
    }

    /**
    * Returns a boolean that indicate if the modals is for an ID CARD
    *
    * @return boolean
    */
    isIdCard() {
        const { entity } = this.props,
            { type }     = entity;

        return ENTITYWITHIDCARDS.indexOf(type) !== -1;
    }

    /**
    * Add all items entity to the clipboard
    *
    * return Graph Component
    */
    addItemsToClipboard() {
        const { entity, entities, addItems } = this.props,
            { callbacks }                    = this.state,
            { IMG }                          = entity,
            entityDefinition                 = entities.find((e) => e.id === entity.type),
            typeLabel                        = entityDefinition ? entityDefinition.label : null,
            labelPrefix                      = `${stripTags(entity.label).substring(0, 50)} `,
            itemsOptions                     = [{}]; // First item with no options (the model)

        // Add patent images to clipboard
        if (IMG && IMG.length > 0) {
            IMG.forEach((href, imageNum) => itemsOptions.push({
                href,
                imageNum,
            }));
        }

        // Add model and images to clipboard
        addItems(itemsOptions.map((options) => this.makeClipboardItem(options)));

        // Trigger addToClipboard for authority and related documents
        if (callbacks.addToClipboard) {
            const options = { type: 'list', source: _.capitalize(typeLabel), labelPrefix };
            callbacks.addToClipboard.forEach((cb) => cb(options));
        }
    }

    /**
    * Add entity item to the clipboard
    *
    * return Graph Component
    */
    makeClipboardItem(options = {}) {
        const {
                entity,
                entities
            }                             = this.props,
            { href, imageNum }            = options || {},
            entityLabel                   = stripTags(entity.label),
            filenameLabel                 = entityLabel.substring(0, 50),
            entityDefinition              = entities.find((e) => e.id === entity.type),
            typeLabel                     = entityDefinition ? _.capitalize(entityDefinition.label) : null,
            timestamp                     = Date.now(),
            date                          = new Date(),
            filenameDate                  = this.getFileNameDate(date),
            metadata                      = {
                filename: sanitizeFilename(`${filenameDate}_${filenameLabel}`).toLowerCase(),
                filetype: 'list',
                type    : 'entity',
                label   : `${filenameLabel} informations`,
                fullname: entityLabel,
                source  : `${_.capitalize(typeLabel)} profile`,
                url     : document.location.toString(),
            },
            properties = this.getProperties(options);

        if (href) {
            metadata.filetype = 'image';
            metadata.type     = 'link';
            metadata.mimetype = 'image/png';
            metadata.label    = 'Invention image';
            metadata.filename += `_${imageNum + 1}`;
        }

        return {
            type: 'clipboard_item',
            timestamp,
            metadata,
            properties,
        };
    }


    /**
     * It returns a string that contains the current date and time in the format `YYYY_MM_DD_HH_MM_SS`
     * @returns A string with the current date and time in the format:
     *     YYYY_MM_DD_HH_MM_SS
     */
    getFileNameDate() {
        const dateString = getDateString(),
            timeString   = getTimeString();

        return  `${dateString}_${timeString}`;
    }


    /**
     * It returns an object with the entity and context properties
     */
    getProperties(options) {
        const {
                entity, context,
            }          = this.props,
            { href }   = options || {},
            properties = {
                model  : _.pick(entity, ['id', 'type']),
                context: context && {
                    id  : context.id,
                    type: context.type,
                },
            };

        if (href) {
            properties.href = getSobjImage(href, false);
        }

        return properties;
    }

    /**
    * Render the document according to the props.
    *
    * @return JSX
    */
    render() {
        const {
                entity, addItems,
                registerMetadata, entities,
            }                    = this.props,
            { completeEntity }   = this.state,
            { idCardIsExpanded } = this.state,
            { type }             = entity,
            renderedEntity       = completeEntity?.entity || entity,
            ModalComponent       = ModalsComponents[type],
            classNames           = [
                'entity-modal',
                type,
            ];

        if (!ModalComponent) {
            throw new Error(`Modal component: ${type} does not exists.`);
        }

        return (
            <div className={makeStrClassName(classNames)} ref={this.ref}>
                <Helmet>
                    <title>{stripTags(entity.label)}</title>
                </Helmet>
                <ModalComponent
                    {...this.props}
                    modalRef={this.ref}
                    key={renderedEntity.id}
                    type={renderedEntity.type}
                    entity={renderedEntity}
                    expanded={idCardIsExpanded}
                    expandIdCard={this.expandIdCard}
                    onClick={this.onClick}
                    registerCallbacks={this.registerCallbacks}
                    makeClipboardItem={this.makeClipboardItem}
                    addToClipboard={addItems}
                    registerMetadata={registerMetadata}
                    entities={entities}
                />
            </div>
        );
    }

}

Modal.propTypes = {
    addItems         : PropTypes.func,
    entities         : ImmutablePropTypes.list,
    navigateTo       : PropTypes.func,
    registerCallbacks: PropTypes.func,
    registerMetadata : PropTypes.func,
    search           : PropTypes.object,
    context          : PropTypes.shape({
        id  : PropTypes.string,
        type: PropTypes.string,
    }),
    entity: PropTypes.shape({
        id       : PropTypes.string,
        type     : PropTypes.string,
        org_class: PropTypes.number,
        IMG      : PropTypes.array,
        label    : PropTypes.oneOfType([
            PropTypes.shape({substring: PropTypes.func}),
            PropTypes.string
        ]),
    }).isRequired,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
    addItems,
})(Modal);
