// Import libs
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

import Entity               from '../Entity';

/**
* Inline Inline : display a list of entities
*
*/
class Inline extends Component {

    /**
    * Prepare the component to be used.
    */
    constructor(props) {
        super(props);

        this.state = {
            expandedRows: [],
            countries   : null,
            orgClasses  : null
        };
    }

    /**
    * Render the list.
    *
    * @return JSX
    */
    render() {
        const {
            entities, renderSuffix,
            forceLogo, useSeparator, color, logoSize,
            className, entityClassName, onClick,
            disableModal, bindClickOnComponent, forwardedRef, disabledEntities
        } = this.props;

        if (!entities.length) {
            return <div className="no-data-display">No data to display</div>;
        }

        const actualEntities = _.map(entities, (entity) => (!entity.type && entity.authority ? entity.authority : entity)),
            content = _.map(actualEntities, (entity, index) => {
                const { isLoading } = entity,
                    key             = !isLoading ? `${entity.label} ${entity.id}̀` : `skeleton ${index}`,
                    isNotLast       = index !== entities.length - 1,
                    disabled        = disabledEntities.includes(entity.id);

                return (
                    <React.Fragment key={key}>
                        <Entity
                            {...this.state}
                            forwardedRef={forwardedRef}
                            key={key}
                            color={color}
                            forceLogo={forceLogo}
                            logoSize={logoSize}
                            render="inline"
                            entity={entity}
                            onClick={onClick}
                            bindClickOnComponent={bindClickOnComponent}
                            disableModal={disableModal}
                            disabled={disabled}
                            className={entityClassName}
                            renderSuffix={renderSuffix}
                        />
                        {isNotLast && useSeparator && <span key={`separator-${index}`} className="separator">, </span>}
                    </React.Fragment>
                );
            });

        return (
            <div className={`${className} collection Inline`} style={{ display: 'inline-block' }}>
                {content}
            </div>
        );
    }

}

Inline.propTypes = {
    entities: PropTypes.arrayOf(
        PropTypes.shape({})
    ).isRequired,
    forwardedRef        : PropTypes.any,
    renderSuffix        : PropTypes.func,
    useSeparator        : PropTypes.bool,
    className           : PropTypes.string,
    entityClassName     : PropTypes.string,
    color               : PropTypes.any,
    disableModal        : PropTypes.any,
    disabledEntities    : PropTypes.any,
    forceLogo           : PropTypes.any,
    logoSize            : PropTypes.any,
    onClick             : PropTypes.any,
    bindClickOnComponent: PropTypes.bool,
};

Inline.defaultProps = {
    renderSuffix        : () => {},
    bindClickOnComponent: false,
    useSeparator        : true,
    className           : '',
    entityClassName     : ''
};

/**
 * Bind the store to to component
 */
export default Inline;
