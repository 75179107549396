/**
* Promise utils
*/

/**
* Wrap a promise to add the cancel possibility
*
* @params         Promise $promise The promise to cancelabilize
*
* @ideaProvidedBy https://github.com/facebook/react/issues/5465#issuecomment-157888325
*
* @return Object The promise object
*/
export const makeCancelable = (promiseToWrap, cancelCb) => {
    let isCancelled = false;
    let isEnded     = false;

    /**
    * Wrap fn
    */
    const promise = new Promise((resolve, reject) => {
        promiseToWrap.then(
            (val)   => {
                isEnded = true;
                return resolve(val);
            }, //eslint-disable-line
            (error) => isCancelled ? reject() : reject(error)  //eslint-disable-line
        );
    });

    /**
    * Cancel FN
    */
    const cancelFn = () => {
            isCancelled = true;
            if (!isEnded) { cancelCb(isCancelled); }
        },
        finallyFn = () => {
            promise.finally();
            return {
                promise,
                cancel: cancelFn
            };
        },
        catchFn = (error) => {
            promise.catch(error);
            return {
                promise,
                cancel : cancelFn,
                finally: finallyFn
            };
        },
        thenFn  = (resolve, reject) => {
            promise.then(resolve, reject);
            return {
                promise,
                cancel : cancelFn,
                catch  : catchFn,
                finally: finallyFn
            };
        };

    return {
        /**
        * Return the wrapped promise
        */
        promise,
        /**
        * Implement the cancel function
        */
        cancel : cancelFn,
        /**
        * Re-route to promise then
        */
        then   : thenFn,
        /**
        * Re-route to promise catch
        */
        catch  : catchFn,
        /**
        * Re-route to promise finally
        */
        finally: finallyFn
    };
};

/**
 * Wrap a promise.all to add the cancel possibility
 *
 * @params Promises $promise The promises to cancelabilize
 *
 * @return Object The promise object
 */
export const makeCancelableAll = (promisesToWrap) => {
    return makeCancelable(
        Promise.all(promisesToWrap),
        () => promisesToWrap.forEach(promise => {
            promise.cancel();
        })
    );
};

export default { makeCancelable, makeCancelableAll };
