/**
 * Display a user based menu
 *
 * @return Component
 */

import React, { Component, Fragment }   from 'react';
import _                                from 'lodash';
import PropTypes                        from 'prop-types';
import ImmutablePropTypes               from 'react-immutable-proptypes';
import { connect }                      from 'react-redux';
import { Divider, Avatar, Popover }     from 'antd';
import { LogoutOutlined }               from '@ant-design/icons';
import { bindActionCreators }           from 'redux';
import { logoutMember }                 from '../../../store/actions/auth';
import OIcon                            from '../../../helpers/Icon';

import './assets/user-menu.less';

/**
 * Class UserMenu
 * Allow the user some global actions and help on the app.
 */
class UserMenu extends Component {

    /**
    * Create the items list
    *
    * @return array
    */
    getItems() { // eslint-disable-line  max-lines-per-function
        const {
                member, yoomapUrl
            }             = this.props,
            email         = member.get('email'),
            academyParams = `apicode=109835&apikey=OYIVOWWJCVB5D6R7UX&task=login&create=true&groupID=2202&oID=47&email=${email}`,
            items         = [
                {
                    label: 'Data Scope',
                    url  : 'https://www.questel.com/data-scope/',
                    icon : 'data-scope'
                },
                {
                    label: 'Questel Academy',
                    url  : `https://academy.questel.com/api.aspx?${academyParams}`,
                    icon : 'questel-academy'
                },
                {
                    label: 'Product Support',
                    url  : 'https://www.questel.com/contact/',
                    icon : 'product-support'
                },
                {
                    label: 'Innovation Services',
                    url  : 'https://www.questel.com/innovation-services/operational-services/',
                    icon : 'innovation-services'
                },
                {
                    label: 'Consulting',
                    url  : 'https://www.questel.com/innovation-consulting/',
                    icon : 'consulting'
                },
                {
                    id     : 'consentManagement',
                    label  : 'Consent management',
                    target : '_self',
                    url    : '#',
                    onClick: window.displayCMP,
                    icon   : 'consent-management'
                }
            ];

        if (yoomapUrl) {
            items.push(
                {
                    label: 'Startup',
                    url  : yoomapUrl,
                    icon : 'orbit-startup'
                }
            );
        }

        return items;
    }


    /**
    * Render an icon in the style required by the component.
    *
    * @return JSX
    */
    renderIcon(iconId) {
        return (
            <OIcon
                folder="/user-menu/"
                id={iconId}
                width={34}
                height={34}
                SVG={false}
            />
        );
    }

    /**
    * Render the items of the Menu
    *
    * @return JSX
    */
    renderMenuContent() {
        const { member, logout } = this.props,
            items                = this.getItems(),
            firstname            = member.get('firstname') ? member.get('firstname') : '',
            lastname             = member.get('lastname') ? member.get('lastname') : '',
            label                = member.get('label') ? member.get('label') : '',
            email                = member.get('email') ? member.get('email') : '',
            userNamesAreEmpty    = firstname.length === 0 && lastname.length === 0;

        return (
            <div className={`ovation-user-menu ${userNamesAreEmpty ? 'no-names' : ''}`}>
                <header>
                    <Avatar size={50}>
                        {firstname.charAt(0)}
                        {lastname.charAt(0)}
                    </Avatar>
                    <div className="informations">
                        <span className="label">{label}</span>
                        <br />
                        <span className="email">{email}</span>
                    </div>
                </header>
                {_.map(items, (item) => (
                    <Fragment key={item.label}>
                        <Divider />
                        <div id={item.id || false} className="item">
                            <a
                                href={item.url}
                                onClick={item.onClick || undefined}
                                target={item.target || '_blank'}
                                rel="noopener noreferrer"
                            >{item.label}
                            </a>
                            <div className="icon">{this.renderIcon(item.icon)}</div>
                        </div>
                    </Fragment>
                ))}
                <Divider />
                <div className="ui-actions">
                    <LogoutOutlined onClick={logout} />
                </div>
            </div>
        );
    }

    /**
    * Render the UserMenu
    *
    * @return JSX
    */
    render() {
        return (
            <Popover
                destroyTooltipOnHide
                placement="bottomRight"
                content={this.renderMenuContent()}
                trigger="click"
                getPopupContainer={trigger => trigger.parentElement.parentElement}
                overlayClassName="user-menu"
            >
                <div className="user">
                    <OIcon id="account"
                        height="16" width="16"
                    />
                </div>
            </Popover>
        );
    }

}

/**
 * Validate properties for current Component
 */
UserMenu.propTypes = {
    member: PropTypes.PropTypes.oneOfType([
        ImmutablePropTypes.map,
        PropTypes.bool
    ]),
    logout   : PropTypes.func.isRequired,
    yoomapUrl: PropTypes.string,
};

UserMenu.defaultProps = {
    member: false,
};

/**
 * Bind the store the props
 */
const mapStateToProps = (state) => {
    const auth = state.get('auth');
    return {
        member: auth.get('member')
    };
};

/**
 * Bind Dispatcher to the component props
 */
const mapDispatchToProps = (dispatch) => ({
    logout: bindActionCreators(logoutMember, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
