import _ from 'lodash';


/**
 *
 * @param {object} model
 * @param {string} property
 * @param {string} digDeepProperty
 *
 * @returns array
 */
export const getDeepPropertyValues = (model, property, digDeepProperty) => {
    const value     = _.get(model, property),
        values      = value ? [value] : [],
        subProperty = _.get(model, digDeepProperty);

    if (subProperty) {
        return values.concat(getDeepPropertyValues(subProperty, property, digDeepProperty));
    }

    return values;
};


/**
* Create a curated function to compare deeply model property
*
* @param {object} firstModel           The first model to compare
* @param {string} firstProperty        The property to compare
* @param {string} firstDigDeepProperty The property to dig into firstModel
*
* @returns function
*/
export const curriedCompareProperty = (firstModel, firstProperty, firstDigDeepProperty) => {
    const firstValues       = getDeepPropertyValues(firstModel, firstProperty, firstDigDeepProperty),
        /**
         * The curated function to compare the value
         *     Go deep in secondModel (iterate with secondDigDeepProperty property)
         *
         * @param {object} secondModel           The second model to compare
         * @param {string} secondProperty        The property to compare
         * @param {string} secondDigDeepProperty The property to dig into secondModel
         *
         * @returns boolean
         */
        deepCompareProperty = (secondModel, secondProperty, secondDigDeepProperty) => {
            const secondValues = getDeepPropertyValues(secondModel, secondProperty, secondDigDeepProperty);

            return _.intersection(firstValues, secondValues).length > 0
                || firstValues.length === 0 && secondValues.length === 0;
        };

    // Return the curried function
    return deepCompareProperty;
};


/**
* Deep comparison of objects
*
* @return JSX
*/
export const deepEqual = (obj1, obj2, takeCareOfOrder = true) => {
    // Same object
    if (obj1 === obj2) { // It's just the same object. No need to compare.
        return true;
    }

    // All are null
    if (_.isNull(obj1) && _.isNull(obj2)) {
        return true;
    }

    // Just on is null
    if (_.isNull(obj1) || _.isNull(obj2)) {
        return false;
    }

    // All are undefined
    if (_.isUndefined(obj1) && _.isUndefined(obj2)) {
        return true;
    }

    // Just on is undefined
    if (_.isUndefined(obj1) || _.isUndefined(obj2)) {
        return false;
    }

    if (isPrimitive(obj1) && isPrimitive(obj2)) { // Compare primitives
        return obj1 === obj2;
    }

    // Fast check object keys count7
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }

    // Compare objects with same number of keys
    for (const key in obj1) {
        if (!(key in obj2)) {
            return false;
        }
        if (!takeCareOfOrder && _.isArray(obj1) && _.isArray(obj2) && obj2.indexOf(obj1[key]) !== -1) {
            continue;
        }
        if (!deepEqual(obj1[key], obj2[key], takeCareOfOrder)) {
            return false;
        }
    }

    return true;
};

/**
 * Deep merge of two objects
 *
 * @return Object
 */
export const deepMerge = (obj1, obj2) => {
    return _.mergeWith(obj1, obj2,
        (objValue, srcValue) => {
            if(_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
        }
    );
};


/**
 * Check if value is primitive
 *
 * @return bool
 */
export const isPrimitive = (obj) => (obj !== Object(obj));

/**
 *
 * @param {object}   obj    The object to filter elements
 * @param {Function} keepFn The function to filter or not ,must return boolean (true keep the element) and the params : key, value
 *
 * @returns new object without filtered elements
 */
export const objectFilter = (obj, keepFn) => _.keys(obj).reduce((returnObj, key) => {
    if (keepFn(key, obj[key])) { returnObj[key] = obj[key]; }
    return returnObj;
}, {});

/**
 * Get leaves of tree
 *
 * @param {array || object} node                The tree or branches
 * @param {string}          branchAttributeName Attribute name for dig deep
 *
 * @returns {array || object}
 */
export const getTreeLeaves = (node, branchAttributeName, path) => {
    // Nothing to do
    if (!node) {
        return null;
    }

    // Branches list
    if (_.isArray(node)) {
        return _.flatten(node.map(branch => getTreeLeaves(branch, branchAttributeName, path)));
    }

    // Branch have ramifications
    if (node[branchAttributeName]) {
        return getTreeLeaves(node[branchAttributeName], branchAttributeName, path ? `${path}.${node.id}` : node.id);
    }

    // Return a deep branch
    return { path: path ? `${path}.${node.id}` : node.id, node };
};


/**
 * Get keys using value to check condition
 * @param {*} object
 * @returns
 */
export const getKeysFromCondition = (object) => _.keys(
    _.omitBy(object, (val) => {
        const condition = _.isFunction(val) ? val() : val;
        return !condition;
    })
);


/**
 * Get node of tree found py path
 *
 * @param {array}  nodes
 * @param {string} branchAttributeName
 * @param {string} topicPath
 */
export const getTreeNode = (nodes, branchAttributeName, topicPath) => {
    const pathArray = topicPath.split('.'),
        nodeId      = pathArray.shift(),
        node        = nodes.find(t => t.id === nodeId);

    if (pathArray.length > 0 && node[branchAttributeName]) {
        return getTreeNode(node[branchAttributeName], branchAttributeName, pathArray.join('.'),);
    }

    return node;
};

export default {
    deepEqual,
    deepMerge,
    objectFilter,
    getTreeLeaves,
    getTreeNode,
    curriedCompareProperty,
    getDeepPropertyValues,
    getKeysFromCondition
};

