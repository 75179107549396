// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Override body element
*/
body.capture-element {
  display: block;
  background: transparent;
}
body.capture-element .elements-definition textarea {
  width: 100%;
  height: 200px;
}
body.capture-element .capture-viewer {
  top: 200px;
  position: absolute;
}
body.capture-element .capture-viewer .Collection .list tr.entity-row-separator .line {
  margin-left: 0;
}
body.capture-element .capture-viewer .Collection .list th.select,
body.capture-element .capture-viewer .Collection .list td.select {
  display: none;
}
body.capture-element .capture-viewer .idcard.sheet .entity-sections {
  margin-top: 20px;
}
body.capture-element .row:not(.label),
body.capture-element .content,
body.capture-element .element-graph,
body.capture-element .layout-graph {
  flex-grow: 1;
}
body.capture-element:after {
  display: none;
}
body.capture-element:before {
  display: none;
}
body.capture-element .is-fixed {
  display: none;
}
body.capture-element #global-header {
  display: none;
}
body.capture-element .sidebar {
  display: none !important;
}
body.capture-element .ovation-flexlayout > .help {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Capture/assets/capture.less"],"names":[],"mappings":"AAAA;;CAEC;AAGD;EAEI,cAAA;EACA,uBAAA;AAFJ;AADA;EASY,WAAA;EACA,aAAA;AALZ;AALA;EAeQ,UAAA;EACA,kBAAA;AAPR;AATA;EAqBoB,cAAA;AATpB;AAZA;;EA0BgB,aAAA;AAVhB;AAhBA;EA+BY,gBAAA;AAZZ;AAnBA;;;;EAoCQ,YAAA;AAXR;AAcI;EACI,aAAA;AAZR;AAeI;EACI,aAAA;AAbR;AA/BA;EAgDQ,aAAA;AAdR;AAlCA;EAoDQ,aAAA;AAfR;AArCA;EAwDQ,wBAAA;AAhBR;AAxCA;EA2DQ,aAAA;AAhBR","sourcesContent":["\n/**\n* Override body element\n*/\n\nbody.capture-element {\n\n    display   : block;\n    background: transparent;\n\n    @elements-definition-height: 200px;\n\n    .elements-definition {\n        textarea {\n            width: 100%;\n            height: @elements-definition-height;\n        }\n    }\n\n    .capture-viewer {\n        top: @elements-definition-height;\n        position: absolute;\n\n        .Collection .list {\n            tr.entity-row-separator {\n                .line {\n                    margin-left: 0;\n                }\n            }\n\n            th.select, td.select {\n                display: none;\n            }\n        }\n\n        .idcard.sheet .entity-sections {\n            margin-top: 20px;\n        }\n    }\n\n    .row:not(.label), .content, .element-graph, .layout-graph {\n        flex-grow: 1;\n    }\n\n    &:after {\n        display: none;\n    }\n\n    &:before {\n        display: none;\n    }\n\n    .is-fixed {\n        display: none;\n    }\n\n    #global-header {\n        display: none;\n    }\n\n    .sidebar {\n        display: none !important;\n    }\n    .ovation-flexlayout >.help {\n        display: none;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
