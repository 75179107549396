import React, { Component }          from 'react';
import _                             from 'lodash';
import PropTypes                     from 'prop-types';
import {
    hsl              as d3Hsl,
    interpolateRgb   as d3InterpolateColor,
    selectAll        as d3SelectAll,
    scaleLinear      as D3ScaleLinear,
    drag             as d3Drag,
}                                    from 'd3';

import {
    Slider, Button, AutoComplete, Input,
}                                    from 'antd';

import Graphology                    from 'graphology';
import GexfParser                    from 'graphology-gexf';
import GexfD3                        from '../GexfD3';

import { transformToString }         from 'utils/dom';

import { CssLoader, Icon }           from 'helpers';

import NetworkBase                   from './Network/NetworkBase';

import ForceWorker                   from './Network/force.worker.js';

import DatGui, { DatNumber, DatButton, DatPresets } from 'react-dat-gui';

// Import('../../../core/datGuiIndex.css');

import './Network/main.less';

const random = Math.random; //eslint-disable-line


const presets = [
    {'little link': {
        linkStrength   : 0.9,
        linkLength     : 25,
        repulsion      : 20,
        clusterDistance: 75,
    }},
    {'long link': {
        linkStrength   : 0.9,
        linkLength     : 200,
        repulsion      : 20,
        clusterDistance: 100,
    }},

    /* A
    {'little elastic': {
        linkStrength   : 0.2,
        linkLength     : 20,
        repulsion      : 20,
        clusterDistance: 100,
    }},
    {'normal link': {
        linkStrength   : 0.3,
        linkLength     : 120,
        repulsion      : 20,
        clusterDistance: 100,
    }},
    {'long link': {
        linkStrength   : 0.3,
        linkLength     : 250,
        repulsion      : 20,
        clusterDistance: 100,
    }},
    {'repulsion long link': {
        linkStrength   : 0.3,
        linkLength     : 250,
        repulsion      : 100,
        clusterDistance: 100,
    }},
    {'extra long link': {
        linkStrength   : 0.3,
        linkLength     : 400,
        repulsion      : 20,
        clusterDistance: 100,
    }},
    */
];


/**
 * The Network graph Component
 *
 */
class Network extends Component {

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            { color } = nextProps,
            dc        = d3Hsl(color);

        state.colors    = {
            rainbow: [
                dc.toString(),
                dc.brighter(1.5).toString(),
            ],
        };

        state.colors.gradient = d3InterpolateColor(state.colors.rainbow[1], state.colors.rainbow[0]);

        return { nextProps, state };
    }


    /**
    * Update sameData
    *
    * @params options object { nextprops, state }
    *
    * @return object { nextprops, state}
    */
    static updateSameData(options) {
        const { nextProps, state } = options,
            { data }               = nextProps,
            { oldGexf }            = state,
            stats                  = _.get(data, 'stats', []);

        state.sameData = _.isUndefined(stats.gexf) || oldGexf === stats.gexf;

        return { nextProps, state };
    }


    /**
    * Update gexfData
    *
    * @params options object { nextprops, state }
    *
    * @return object { nextprops, state}
    */
    static updateGexfData(options) {
        const { nextProps, state } = options,
            { data }               = nextProps,
            { gexfData, sameData } = state,
            stats                  = _.get(data, 'stats', []);

        state.gexfData = sameData
            ? gexfData
            : (stats?.gexf ? GexfParser.parse(Graphology, stats.gexf) : false);

        return { nextProps, state };
    }


    /**
    * Update gd3 object
    *
    * @params options object { nextprops, state }
    *
    * @return object { nextprops, state}
    */
    static updateGd3(options) {
        const { nextProps, state } = options,
            { gexfData, sameData } = state;

        // The state.gD3 is already set
        if (sameData && (state.gD3 || !state.gexfData)) {
            return { nextProps, state };
        }

        // Store gD3
        state.gD3 = new GexfD3();
        state.mustStartSimulation = true;

        if (gexfData) {
            state.gD3.setSize([1000, 1000]).graph(gexfData);
        }

        return { nextProps, state };
    }


    /**
    * Update gd3 object
    *
    * @params options object { nextprops, state }
    *
    * @return object { nextprops, state}
    */
    static updateNodeAndLinksByIds(options) {
        const { nextProps, state } = options,
            { plotData }           = state,
            { nodes, links }       = plotData || {};

        // Store nodes by ids
        state.nodesByIds = nodes ? _.keyBy(nodes, (o) => o.id) : {};

        // Store links by ids
        state.linksByIds = links ? _.keyBy(links, (o) => o.id) : {};

        return { nextProps, state };
    }

    /**
    * Update startingAlpha
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateStartingAlpha(options) {
        const { nextProps, state } = options,
            { startingAlpha }      = nextProps,
            { nodesByIds }         = state,
            numberOfNodes          = _.keys(nodesByIds).length;

        if (numberOfNodes === 0) {
            state.startingAlpha = startingAlpha;
            return { nextProps, state };
        }

        state.startingAlpha = 0.5;
        state.endingAlpha   = 0.05;

        return { nextProps, state };
    }

    /**
    * Update modelsData
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateModelData(options) {
        const { nextProps, state } = options,
            { data }               = nextProps,
            { content }            = data    || {},
            serie                  = content || [];

        state.modelsData = serie;

        return { nextProps, state };
    }

    /**
    * Update zoom and pan from mouse move in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePan(options) {
        const { nextProps, state }   = options,
            { previousMouseDragged } = state,
            { disableZoom }          = nextProps,
            { mouseDragged }         = nextProps;

        if (disableZoom || !mouseDragged) {
            state.previousMouseDragged = null;
            return { nextProps, state };
        }

        if (mouseDragged === previousMouseDragged) {
            return { nextProps, state };
        }
        state.previousMouseDragged = mouseDragged;

        if (!previousMouseDragged) {
            return { nextProps, state };
        }

        const offsetX = mouseDragged.end.x - previousMouseDragged.end.x,
            offsetY = mouseDragged.end.y - previousMouseDragged.end.y;

        state.translate.x += offsetX;
        state.translate.y += offsetY;

        state.translateTo.x = state.translate.x;
        state.translateTo.y = state.translate.y;

        return { nextProps, state };
    }

    /**
    * Update plotData in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state } = options,
            { dataIsLoaded }       = nextProps,
            {
                gD3, sameData, plotData,
            }                               = state,
            { getLinks, getNodes } = gD3 || {},
            newNodes                        = getNodes ? getNodes() : [],
            newLinks                        = getLinks ? getLinks() : [];

        if ((sameData && !!plotData) || !dataIsLoaded) {
            return { nextProps, state };
        }

        state.plotData = {
            links: newLinks,
            nodes: newNodes,
        };

        return { nextProps, state };
    }


    /**
    * Update translate in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateTranslate(options) {
        const { nextProps, state } = options,
            {
                translate, translateTo, autoCenter, instantCenter
            }                      = state,
            xDiff                  = (translateTo.x - translate.x),
            yDiff                  = (translateTo.y - translate.y);

        if (instantCenter || autoCenter && xDiff !== 0  && xDiff !== 0) {
            state.translate.x = instantCenter || xDiff < 0.2
                ? translateTo.x
                : translate.x + xDiff / 3;

            state.translate.y = instantCenter || yDiff < 0.2
                ? translateTo.y
                : translate.y + yDiff / 3;
        }

        return { nextProps, state };
    }


    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScale(options) {
        const { nextProps, state } = options,
            {
                scale, scaleTo, autoCenter, instantCenter,
            }                      = state,
            diff                   = (scaleTo - scale);

        if (instantCenter || autoCenter && diff !== 0) {
            state.scale = instantCenter || diff <= 0.02
                ? scaleTo
                : scale + diff / 3;
        }

        // Use instantCenter just one time
        if (instantCenter) {
            state.instantCenter = false;
        }


        return { nextProps, state };
    }


    /**
    * Update zoomFinished in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateZoomFinished(options) {
        const { nextProps, state } = options,
            { zoomFinished }       = state;

        // Use zoomFinished just one time
        if (zoomFinished) {
            state.zoomFinished = false;
        }

        return { nextProps, state };
    }


    /**
    * Get highlights elements ( nodes / links / selectedNodes)
    *
    * @return object
    */
    static updateHighlights(options) {
        const { nextProps, state } = options,
            {
                plotData, overedNodeId, selectedNodesIds
            }                      = state,
            { links }              = plotData || {},
            highlightedNodesIds    = overedNodeId ? selectedNodesIds.concat(overedNodeId) : selectedNodesIds,
            highlightedNodes       = [],
            highlightedLinks       = [],
            normalLinks            = [];

        _.each(links, link => {
            if (_.intersection(highlightedNodesIds, [link.source.id, link.target.id]).length > 0) {
                highlightedLinks.push(link);
            } else {
                normalLinks.push(link);
            }
        });

        // Add nodes from highlighted links
        _.each(highlightedLinks, (link) => {
            if (overedNodeId !== link.source.id) {
                highlightedNodes.push(link.source);
            }
            if (overedNodeId !== link.target.id) {
                highlightedNodes.push(link.target);
            }
        });

        state.highlightedNodes = _.uniq(highlightedNodes);
        state.highlightedLinks    = highlightedLinks;
        state.normalLinks         = normalLinks;

        return { nextProps, state };
    }


    /**
     * Control the renders
     */
    static updateRenderControl(options) {
        const { nextProps, state }         = options,
            { instantCenter, zoomFinished} = state;

        state.forceRenderLinks = instantCenter || zoomFinished;

        return { nextProps, state };
    }


    /**
     * Get Center of nodes
     */
    static getNodesCenter(plotData) {
        const graphLimit = {
            minX: null,
            maxX: null,
            minY: null,
            maxY: null,
        };

        // Detect graph limit border
        plotData?.nodes.forEach(prevNode => {
            if (!prevNode.disabled) {
                graphLimit.minX = !_.isNull(graphLimit.minX) && graphLimit.minX < prevNode.x ? graphLimit.minX :  prevNode.x;
                graphLimit.maxX = !_.isNull(graphLimit.maxX) && graphLimit.maxX > prevNode.x ? graphLimit.maxX :  prevNode.x;
                graphLimit.minY = !_.isNull(graphLimit.minY) && graphLimit.minY < prevNode.y ? graphLimit.minY :  prevNode.y;
                graphLimit.maxY = !_.isNull(graphLimit.maxY) && graphLimit.maxY > prevNode.y ? graphLimit.maxY :  prevNode.y;
            }
        });

        return {
            xCenter: (graphLimit.minX + graphLimit.maxX) / 2,
            yCenter: (graphLimit.minY + graphLimit.maxY) / 2
        };
    }


    /**
     * Create links inter clusters
     *
     * @param {array}  centerNodes Array of the biggest node of each cluster
     *  @param {array} nodes       Nodes of the network
     *
     * @returns Array of cluster links
     */
    static getClustersLinks(centerNodes, nodes = []) {
        const clustersLinks   = [],
            clusterNodesCount = {},
            clusterLinksCount = {},
            nbClusters        = centerNodes.length,
            lessClusterBoost  = 10 / nbClusters;

        nodes.forEach(node => {
            const { cluster } = node;

            clusterNodesCount[cluster] = !_.isUndefined(clusterNodesCount[cluster]) ? clusterNodesCount[cluster] + 1 : 1;
            clusterLinksCount[cluster] = !_.isUndefined(clusterNodesCount[cluster])
                ? clusterNodesCount[cluster] + node.linksCount
                : node.linksCount;
        });

        centerNodes.forEach((node, index) => {
            centerNodes.forEach((node2, index2) => {
                if (index !== index2) {
                    const clustersNodesCount = clusterNodesCount[node.cluster] + clusterNodesCount[node2.cluster],
                        clustersLinksCount   = clusterLinksCount[node.cluster] + clusterLinksCount[node2.cluster],
                        LinkNodeRatio        = clustersLinksCount / (1.3 * clustersNodesCount);

                    clustersLinks.push({
                        source: node,
                        target: node2,
                        weight: (clustersNodesCount * LinkNodeRatio)**0.61   // Weight by clusters properties
                        + 5                                                  // Min weight
                        - lessClusterBoost                                   // More compact little nb clusters
                    });
                }
            });
        });

        return clustersLinks;
    }


    /**
    * Update oldGd3 in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateOldGexf(options) {
        const { nextProps, state } = options,
            { data }               = nextProps,
            stats                  = _.get(data, 'stats', []);

        state.oldGexf = stats.gexf;

        return { nextProps, state };
    }


    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {  // eslint-disable-line max-lines-per-function
        super(props);
        const { startingAlpha } = this.props;

        this.state = {
            startingAlpha,
            scale               : 1,
            scaleTo             : 1,
            autoCenter          : true,
            instantCenter       : false,
            translate           : { x: 0, y: 0 },
            translateTo         : { x: 0, y: 0 },
            previousMouseDragged: null,
            guiSetting          : {
                linkStrength   : 0.9,
                linkLength     : 100,
                repulsion      : 20,
                clusterDistance: 100,
            },
            selectedNodesIds        : [],
            filterNodesBySelectedIds: [],
            useForceWorker          : true,
            zoomFinished            : false,
            showShiftZoomHelp       : false,
        };

        this.networkRef = React.createRef();
        this.svgRef     = React.createRef();
        this.ref        = React.createRef();

        _.bindAll(this,
            'render', 'nodeOver', 'nodeOut', 'nodeClick', 'nodeTitleClick', 'networkClickUp', 'onCloseModal',
            'onSliderMove', 'centerGraph', 'zoomIn', 'zoomOut', 'onWheel', 'useInstantCenter', 'networkMouseOver',
            'sizeScale', 'saveGuiSetting', 'filterSelectedNodes', 'filterSelectedLinks', 'resetFilterSelectedNodes',
            'dragstarted', 'dragged', 'dragended', 'networkClickDown', 'networkClickUp',
            'renderSvg', 'componentDidUpdate', 'onRenderBase',
            'linkValueClick', 'linkValueOver', 'linkValueOut', 'onSearchNodeSelect', 'onSearchNodeChange',
        );

        // Make a uuid
        this.id = _.uniqueId('Network-chart');
    }

    /**
     * Set react state with onchange gui settings
     */
    saveGuiSetting(value) {
        this.setState({ guiSetting: value });
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.currentScale = 0;

        this.startForceSimulation();
    }


    /**
    * Triggered when the component is unmounting
    *
    * @return void
    */
    componentWillUnmount() {
        this.terminateForceWorker();
    }


    /**
    * Termuinate the forceWorker
    *
    * @return void
    */
    terminateForceWorker() {
        if (this.forceWorker) {
            this.forceWorker.terminate();
        }
    }


    /**
    * Get state for props and previous state
    *
    * Calculate                        :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) {
        const {
                margin, data,
            }            = nextProps,
            innerWidth   = nextProps.width  - 2 * margin,
            innerHeight  = nextProps.height - 2 * margin,
            stats        = _.get(data, 'stats', []),
            // Cascading function to update state
            updateState = _.flow([
                Network.updateRenderControl,
                Network.updateColors,        // All colors and gradient
                Network.updateModelData,     // Update modelsData

                Network.updateSameData,
                Network.updateGexfData,
                Network.updateGd3,           // Gd3 object
                Network.updatePlotData,      // Location and size of bars

                Network.updateNodeAndLinksByIds,
                Network.updateStartingAlpha, // StartingAlpha can be change on low nuber of nodes
                Network.updatePan,           // Pan from Mouse
                Network.updateOldGexf,
                Network.updateTranslate,
                Network.updateScale,
                Network.updateZoomFinished,
                Network.updateHighlights,
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    ...prevStates,
                    innerWidth,
                    innerHeight,
                    gexf     : stats?.gexf,
                    sizeRange: [5, 15],
                }
            });

        return state;
    }


    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidUpdate() {
        const { sameData, gexf }    = this.state;

        this.startForceSimulation();
        this.manageTransform();

        if (!sameData && !!gexf) {
            requestAnimationFrame(this.centerGraph);
        }

        // Add onWheel event listener (passive mode for chrome windows)
        if (this.svgRef.current && !this.svgRef.current.onWheelListen) {
            this.svgRef.current.addEventListener('wheel', this.onWheel, { passive: false });
            this.svgRef.current.onWheelListen = true;
        }
    }

    /**
    * Callback triggered when the user clicks to close the modal
    *
    * return false
    */
    onCloseModal(e) {
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
    * On slider change value
    *
    * @return self
    */
    onSliderMove(value) {
        const scale = this.computeZoomFromSlider(value);

        this.zoomScale(scale);
    }

    /**
    * On mouse wheel
    *
    * @return
    */
    onWheel(e) {
        const { disableZoom } = this.props,
            { shiftKey }       = e;

        if (disableZoom) {
            return false;
        }

        if (!shiftKey) {
            this.setState({ showShiftZoomHelp: true });
            clearTimeout(this.showCtrlZoomHelpTimeout);
            this.showCtrlZoomHelpTimeout = setTimeout(() => this.setState({ showShiftZoomHelp: false }), 3000);
            return false;
        }

        e.preventDefault();
        e.stopPropagation();

        const { deltaY, deltaX } = e;

        // Prevent /0 (Chrome trackpad issue)
        if (!Math.abs(deltaY) && !Math.abs(deltaX)) {
            return false;
        }

        const { scale } = this.state,
            { zoomMax } = this.props,
            delta       = deltaY || deltaX,  // Use deltaX (for Safari shift-click move horizontaly)
            newScale    = scale * (1 - (delta / Math.abs(delta)) * 0.1),
            center      = {
                x: e.layerX,
                y: e.layerY,
            },
            unityScale    = this.computeUnityScale(newScale),
            newScaleState = zoomMax ** unityScale;

        this.setState({ showShiftZoomHelp: false });

        this.zoomScale(newScaleState, center);

        return false;
    }


    /**
    * On slider change value
    *
    * @return self
    */
    zoomIn(e) {
        e.preventDefault();  // Prevent focus on button (onMouseDown)
        const { scale }  = this.state,
            { zoomMax }  = this.props,
            newScale     = scale * 1.1,
            unityScale   = this.computeUnityScale(newScale),
            newScaleSate = zoomMax ** unityScale;

        this.zoomScale(newScaleSate);
    }

    /**
    * On slider change value
    *
    * @return self
    */
    zoomOut(e) {
        e.preventDefault(); // Prevent focus on button (onMouseDown)
        const { scale }  = this.state,
            { zoomMax }  = this.props,
            newScale     = scale / 1.1,
            unityScale   = this.computeUnityScale(newScale),
            newScaleSate = zoomMax ** unityScale;

        this.zoomScale(newScaleSate);
    }


    /**
     * Set nodes ids to filter graph and filter by nodes
     *
     * @param {MousEvent} e
     */
    filterSelectedNodes(e) {
        e.preventDefault(); // Prevent focus on button (onMouseDown)

        const { selectedNodesIds } = this.state;

        this.setState({
            filterNodesBySelectedIds: selectedNodesIds,
            filterOnlySelectedLinks : false,
            selectedNodesIds        : [],
            plotData                : null, // Force to get plotData from GD3
            mustStartSimulation     : true
        });
    }


    /**
     * Set nodes ids to filter graph and filter by links
     *
     * @param {MousEvent} e
     */
    filterSelectedLinks(e) {
        e.preventDefault(); // Prevent focus on button (onMouseDown)

        const { selectedNodesIds } = this.state;

        this.setState({
            filterNodesBySelectedIds: selectedNodesIds,
            filterOnlySelectedLinks : true,
            selectedNodesIds        : [],
            plotData                : null, // Force to get plotData from GD3
            mustStartSimulation     : true
        });
    }


    /**
     * Set nodes ids to filter graph
     *
     * @param {MousEvent} e
     */
    resetFilterSelectedNodes(e) {
        e.preventDefault(); // Prevent focus on button (onMouseDown)

        this.setState({
            filterNodesBySelectedIds: [],
            mustStartSimulation     : true,
            plotData                : null, // Force to get plotData from GD3
        });
    }


    /**
    * Calculate zoom from slider
    *
    * @return self
    */
    computeZoomFromSlider(value) {
        const { zoomMax } = this.props,
            power = value / 100;
        return zoomMax ** power;
    }

    /**
    * Calculate slider value from zoom
    *
    * @return self
    */
    computeUnityScale(value) {
        const { zoomMax } = this.props,
            powerInv = Math.log(value) / Math.log(zoomMax);

        if (powerInv < 0) { return 0; }
        if (powerInv > 1) { return 1; }

        return powerInv;
    }


    /**
    * Manage network transform (pan and zoom)
    *
    * @return self
    */
    manageTransform() {
        const { translate, scale } = this.state,
            transform              = { ...translate, scale },
            { current }            = this.networkRef;

        current?.setAttribute('transform', transformToString(transform));
    }


    /**
     * Get simulation options
     *
     * @return object
     */
    getSimulationOptions() {
        const {
                guiSetting, gD3,
                startingAlpha, endingAlpha,
            }                 = this.state,
            {
                linkStrength, // A linkLength, clusterDistance
            }                 = guiSetting,
            sizeScale         = gD3.getNodeScale(),
            sizeScaleDomain   = sizeScale?.domain(),
            sizeScaleRange    = sizeScale?.range(),
            alphaMedian       = (startingAlpha + endingAlpha) * 0.5,
            alphaQuart        = (startingAlpha + endingAlpha) * 0.7,
            repulsion         = 14,
            weightRange       = [0.7, 1.5];

        return {
            linkStrength,
            repulsion,
            weightRange,
            startingAlpha,
            endingAlpha,
            sizeScaleDomain,
            sizeScaleRange,
            alphaMedian,
            alphaQuart,
        };
    }


    /**
     * Initialize a work to compute force
     */
    initializeForceWorker() {  // eslint-disable-line max-lines-per-function
        const svgDom = this.svgRef.current,
            graphDom = this.ref.current;

        // Put is-loading on Graph (For capture waiting)
        graphDom.classList.add('is-loading');

        if (svgDom) {
            svgDom.style.display = 'none';
        }

        this.terminateForceWorker();

        this.forceWorker = new ForceWorker();

        this.forceWorker.onmessage = messageEvent => {
            const { data, type: messageEventType } = messageEvent,
                { type, nodes, links, clustersLinks, progress }  = data,
                progressDom                        = graphDom && graphDom.querySelector('.worker-progress');

            // Manage progress
            if (messageEventType === 'message' && type === 'progress' && progressDom) {
                progressDom.style.width = `${100 * progress}%`;
            }

            // Manage ending message of the worker
            if (messageEventType === 'message' && type === 'end') {
                // Remove is-loading on Graph
                graphDom.classList.remove('is-loading');

                if (svgDom) {
                    svgDom.style.display = 'block';
                }

                if (progressDom) {
                    progressDom.style.width = '0%';
                }

                this.setState(
                    {
                        plotData:
                            {
                                links,
                                nodes,
                                clustersLinks,
                            },
                        mustStartSimulation: false,
                        simulationStarted  : false,
                        selectedSearchNode : null
                    },
                    () => {
                        this.centerGraph({
                            instantCenter: true,
                        });
                    }
                );
            }
        };
    }


    static updateCluster = (node, clusterId, nodes, links, nodeIdToUpdated) => {  // eslint-disable-line max-params
        const linksToUpdate = links.filter(link => link.source.id === node.id || link.target.id === node.id);
        linksToUpdate.forEach(link => {
            const nodeSource = nodes.find(f => {
                return f.id === link.source.id;
            });

            if (!nodeIdToUpdated.includes(nodeSource.id)) {
                nodeSource.cluster = clusterId;
                nodeIdToUpdated.push(nodeSource.id);
                Network.updateCluster(nodeSource, clusterId, nodes, links, nodeIdToUpdated);
            }

            const nodeTarget = nodes.find(f => {
                return f.id === link.target.id;
            });

            if (!nodeIdToUpdated.includes(nodeTarget.id)) {
                nodeTarget.cluster = clusterId;
                nodeIdToUpdated.push(nodeTarget.id);
                Network.updateCluster(nodeTarget, clusterId, nodes, links, nodeIdToUpdated);
            }
        });
    }

    /**
     * Get filterd nodes by filterNodesBySelectedIds
     *
     * @param {array} globalLinks
     * @returns array
     */
    getFilteredNodes(globalLinks) {
        const { filterNodesBySelectedIds } = this.state,
            filteredNodes                  = {};

        globalLinks.forEach(link => {
            // No filterNodesBySelectedIds => extract all nodes
            if (filterNodesBySelectedIds.length === 0) {
                // Add links nodes to filteredNodes
                if (!filteredNodes[link.source.id]) { filteredNodes[link.source.id] = (link.source); }
                if (!filteredNodes[link.target.id]) { filteredNodes[link.target.id] = (link.target); }
                return;
            }

            if (filterNodesBySelectedIds.includes(link.source.id) || filterNodesBySelectedIds.includes(link.target.id)) {
                // Add links nodes to filteredNodes
                if (!filteredNodes[link.source.id]) { filteredNodes[link.source.id] = (link.source); }
                if (!filteredNodes[link.target.id]) { filteredNodes[link.target.id] = (link.target); }
            }
        });

        // Remove duplicate nodes
        return _.values(filteredNodes);
    }


    /**
     *
     * @param {array} globalLinks
     * @param {array} filteredNodesIds
     *
     * @returns object
     */
    getDataFromFilteredNodes(globalLinks, filteredNodesIds, onlySelectedLinks = false) {
        const filteredNodesLinksCount    = {},
            filteredLinks                = [],
            { filterNodesBySelectedIds } = this.state;

        let filteredMaxWeight = 0;

        // Get links of filteredNodes
        globalLinks.forEach((link, index)  => {
            const mustAddlink = onlySelectedLinks && filterNodesBySelectedIds.length > 0
                ?  (filterNodesBySelectedIds.includes(link.source.id)
                || filterNodesBySelectedIds.includes(link.target.id))
                :  (filteredNodesIds.includes(link.source.id)
                && filteredNodesIds.includes(link.target.id));

            if (mustAddlink) {
                // Attach a temp clusterId
                link.source.cluster = index;
                link.target.cluster = index;

                // Update filteredNodesLinksCount
                filteredNodesLinksCount[link.source.id] = _.isUndefined(filteredNodesLinksCount[link.source.id])
                    ? 1
                    : filteredNodesLinksCount[link.source.id] + 1;
                filteredNodesLinksCount[link.target.id] = _.isUndefined(filteredNodesLinksCount[link.target.id])
                    ? 1
                    : filteredNodesLinksCount[link.target.id] + 1;

                // Update filteredMaxWeight
                if (filteredMaxWeight < link.weight) {
                    filteredMaxWeight = link.weight;
                }

                filteredLinks.push(link);
            }
        });

        return { filteredLinks, filteredMaxWeight, filteredNodesLinksCount };
    }


    /**
    * Get force forceWorker option
    *
    * @return object
    */
    getForceWorkerOptions() {
        const{
                gD3, filterOnlySelectedLinks
            } = this.state,
            // Clone deep links (filterNodesBySelectedIds can modify data !)
            globalLinks         = gD3 && gD3.getLinks ? _.cloneDeep(gD3.getLinks()) : [],
            simulationOptions   = this.getSimulationOptions(),
            clustersCenterNodes = [],
            filteredNodes       = this.getFilteredNodes(globalLinks),
            filteredNodesIds    = filteredNodes.map(node => node.id),
            {
                filteredLinks, filteredMaxWeight, filteredNodesLinksCount
            } = this.getDataFromFilteredNodes(globalLinks, filteredNodesIds, filterOnlySelectedLinks),
            nodeIdToUpdated     = [];

        // Update cluster of filteredNodes and add linksCount to nodes
        filteredNodes.forEach(node => {
            const { id }   = node,
                linksCount = filteredNodesLinksCount[id];

            node.linksCount = linksCount;
            Network.updateCluster(node, node.cluster, filteredNodes, filteredLinks, nodeIdToUpdated);
        });

        // Index cluster id
        const clustersIds = _.uniq(filteredNodes.map(n => n.cluster));

        // Set clustersCenterNodes to get filtered clustersLinks
        filteredNodes.forEach(node => {
            const clusterId = clustersIds.indexOf(node.cluster);

            // Update cluster id by index of clustersIds
            node.cluster =  clusterId;

            if (
                !clustersCenterNodes[clusterId]   // No center node for this cluster
                || node.linksCount > filteredNodesLinksCount[clustersCenterNodes[clusterId].id]
                // Use filteredNodesLinksCount to get the actual links count (filteredNodesIds)
            ) {
                clustersCenterNodes[clusterId] = node;
            }
        });

        return {
            nodes        : filteredNodes,
            links        : filteredLinks,
            clustersLinks: Network.getClustersLinks(clustersCenterNodes, filteredNodes),
            nbCluster    : clustersCenterNodes.length,
            maxWeight    : filteredMaxWeight,
            simulationOptions
        };
    }


    /**
    * Start force simulation
    *
    * @return void
    */
    startForceSimulation() {
        const { mustStartSimulation, useForceWorker } = this.state;

        // Simulation force in a worker
        if (useForceWorker && mustStartSimulation) {
            this.initializeForceWorker();

            this.forceWorker.postMessage({
                action : 'createSimulation',
                options: this.getForceWorkerOptions()
            });

            this.setState({ mustStartSimulation: false, simulationStarted: true });
        }
    }


    /**
     * Called when NetworkBase is rendered
    */
    onRenderBase() {
        d3SelectAll(`#${this.id} g.network g.nodes`)
            .selectAll('g.node')
            .call(d3Drag()
                .on('start', this.dragstarted)
                .on('drag', this.dragged)
                .on('end', this.dragended)
                .filter(this.dragFilter)
                .container(this.networkRef?.current)
            );
    }


    /**
     * Set autoCenter to true
     */
    useInstantCenter(e) {
        e.preventDefault(); // Prevent focus on button (onMouseDown)

        const { currentTarget, button } = e,
            { classList  }               = currentTarget;

        if (button !== 0) {
            return;
        }

        this.centerGraph({
            instantCenter       : true,
            doNotUseSearchedNode: true,
            fitOnSelection      : classList.contains('fit-selection'),
        });
    }

    /**
    * The center graph animation
    *
    * @return void
    */
    centerGraph(options) {  // eslint-disable-line max-lines-per-function
        const { zoomMax }     = this.props,
            {
                gD3, autoCenter, selectedNodesIds, nodesByIds, nodeDraggedId, selectedSearchNode
            }                 = this.state,
            {
                instantCenter,
                fitOnSelection,
                doNotUseSearchedNode
            } = options || {},
            selectedNodes     = selectedNodesIds.map(nodeId => nodesByIds[nodeId]).filter(node => !!node),
            useSearchedNodes  = !doNotUseSearchedNode && selectedSearchNode;

        if (nodeDraggedId) {
            return;
        }

        if (useSearchedNodes) {
            selectedNodes.push(selectedSearchNode);
        }

        if (!gD3 || !gD3.getNodeScale || !gD3.getNodeScale()) {
            return;
        }

        const { width, height } = this.props,
            networkRef        = this.networkRef.current,
            zoomSelectorClass = useSearchedNodes
                ? '.searched-node'
                : (
                    fitOnSelection && selectedNodes.length
                        ? '.highlight-nodes'
                        : '.clusters-hulls'
                ),
            zoomedElement     = networkRef && networkRef.querySelector(zoomSelectorClass),
            bbox              = zoomedElement && zoomedElement.getBBox(),
            sbbox             = bbox && bbox.width !== 0 ? bbox : {
                x: -1000, y: -1000, width: 1, height: 1
            },
            forcedZoom        = useSearchedNodes && zoomMax / 2,
            zoomMarginFactor  = selectedNodes.length ? 0.9 : 1,
            xRatio            = forcedZoom || zoomMarginFactor * width / sbbox.width,
            yRatio            = forcedZoom || zoomMarginFactor * height / sbbox.height,
            ratio             = _.min([xRatio, yRatio, zoomMax]);

        if (autoCenter || instantCenter) {
            // Can't get size of the network
            if (!bbox) {
                return;
            }

            // Center view on the network
            this.zoomScaleTo(ratio, null, instantCenter);
            this.zoomTranslateTo({
                x: width / 2 - ratio * (sbbox.width / 2 + sbbox.x),
                y: height / 2 - ratio * (sbbox.height / 2 + sbbox.y),
            });
        }
    }

    /**
    * Zoom to scale factor
    *
    * @return void
    */
    zoomScaleTo(newScale, center, instantCenter) {
        const { scale, translate }      = this.state,
            { innerWidth, innerHeight } = this.state,
            factorScale                 = newScale / scale,
            offsetX                     = (1 - factorScale) * (center ? center.x : innerWidth / 2),
            offsetY                     = (1 - factorScale) * (center ? center.y : innerHeight / 2);

        this.setState({
            scaleTo      : newScale,
            instantCenter: !!instantCenter,
            translateTo  : {
                x: translate.x * factorScale + offsetX,
                y: translate.y * factorScale + offsetY,
            }
        });
    }


    /**
    * Zoom to scale factor
    *
    * @return void
    */
    zoomScale(newScale, center) {
        const { scale, translate } = this.state,
            { innerWidth, innerHeight } = this.state,
            factorScale            = newScale / scale,
            offsetX                = (1 - factorScale) * (center ? center.x : innerWidth / 2),
            offsetY                = (1 - factorScale) * (center ? center.y : innerHeight / 2),
            newTranslate           = {
                x: translate.x * factorScale + offsetX,
                y: translate.y * factorScale + offsetY,
            };

        this.setState({
            scale       : newScale,
            translate   : newTranslate,
            scaleTo     : newScale,
            translateTo : newTranslate,
            zoomFinished: false,
        });

        // Wait user stop zoomming
        clearTimeout(this.hideNodeTimeout);
        this.hideNodeTimeout = setTimeout(() => {
            this.setState({ zoomFinished: true });
        }, 500);
    }


    /**
    * Zoom to scale factor
    *
    * @return void
    */
    zoomTranslateTo(translateTo) {
        this.setState({ translateTo });
    }


    /**
    * Event occurs when the user push mouse button down
    *
    * @returns
    */
    networkClickDown() {
        this.svgRef.current.querySelector('g.network-background').classList.add('grabbed');

        return true;
    }


    /**
    * Event occurs when the user click on the background
    *
    * @param {object} e
    * @returns
    */
    networkClickUp(e) {
        e.preventDefault();

        const { selectedNodesIds, previousMouseDragged } = this.state;

        this.svgRef.current.querySelector('g.network-background').classList.remove('grabbed');

        if(selectedNodesIds.length === 0 || previousMouseDragged) {
            // Clear in search autocomplete
            this.setState({ selectedSearchNode: null });
            return true;
        }

        this.setState({ selectedNodesIds: [], selectedSearchNode: null });

        return true;
    }


    /**
     * On mouse over network background
     */
    networkMouseOver() {
        const { overedNodeId, nodeDraggedId } = this.state;

        // Remove overedNodeId
        if (overedNodeId && !nodeDraggedId) {
            this.setState({ overedNodeId: null });
        }
    }


    /**
    * On mouse click
    *
    * @return html
    */
    nodeTitleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { openModal }          = this.props,
            { previousMouseDragged } = this.state,
            { modelsData }           = this.state,
            el                       = e.currentTarget,
            nodeId                   = el.attributes.dataid.nodeValue,
            entity                   = _.find(modelsData, (model) => model.id === nodeId);

        if (!previousMouseDragged && entity && openModal) {
            openModal(entity, modelsData);
        }

        return false;
    }


    /**
     * On link value mouse over
     */
    linkValueOver(e) {
        const {
                linksByIds, nodeDraggedId
            } = this.state,
            el               = e.currentTarget,
            targetElementId  = el.attributes.dataid.nodeValue,
            linkValueOvered = targetElementId && linksByIds[targetElementId];

        if (!nodeDraggedId) {
            this.setState({
                linkValueOvered,
                overedNodeId: null
            });
        }
    }


    /**
     * On link value mouse out
     */
    linkValueOut(e) {
        const { buttons }     = e,
            { nodeDraggedId } = this.state;

        if (buttons > 0 || nodeDraggedId) {
            return;
        }

        this.setState({ linkValueOvered: null });
    }


    /**
     * On link value click
     */
    linkValueClick(e) {
        const el            = e.currentTarget,
            { buttons }     = e,
            targetElementId = el.attributes.dataid.nodeValue,
            { openModal }   = this.props,
            {
                nodeDraggedId, linksByIds, previousMouseDragged,
                modelsData
            }               = this.state,
            link            = linksByIds[targetElementId],
            model           = link && _.find(modelsData, (entity) => entity.id === link.source.id),
            withModel       = link && _.find(modelsData, (entity) => entity.id === link.target.id),
            relationId      = model && withModel && `${model.id}&${withModel.id}`,
            relationLabel   = `${model.label} & ${withModel.label}`;

        if (nodeDraggedId || buttons > 0) {
            return;
        }

        if (!previousMouseDragged && openModal && relationId) {
            const collaborationModel = {
                id   : relationId,
                type : 'orgunit_collaboration',
                label: relationLabel,
                model,
                withModel,
            };

            openModal(collaborationModel);
        }

        e.preventDefault();
        e.stopPropagation();

        return false;
    }


    /**
    * On mouse click
    *
    * @return html
    */
    nodeClick(event, nodeId) {
        const { nodeDraggedId, nodesByIds, plotData } = this.state;

        if (nodeDraggedId) {
            return true;
        }

        const { selectedNodesIds } = this.state,
            selectedNodeId         = nodeId,
            nodeAlreadySelectIndex = selectedNodesIds.indexOf(selectedNodeId),
            { sourceEvent }        = event,
            { shiftKey }           = sourceEvent;

        // Shit click to select cluster
        if (shiftKey) {
            const { cluster }   = nodesByIds[selectedNodeId],
                { nodes }       = plotData,
                clusterNodesIds = nodes.filter(node => node.cluster === cluster).map(node => node.id);

            this.setState({
                selectedNodesIds: nodeAlreadySelectIndex < 0
                    ? _.union(selectedNodesIds, clusterNodesIds)
                    : _.difference(selectedNodesIds, clusterNodesIds)
            });
            return true;
        }

        if (nodeAlreadySelectIndex < 0) {
            selectedNodesIds.push(selectedNodeId);
        }
        if (nodeAlreadySelectIndex >= 0) {
            selectedNodesIds.splice(nodeAlreadySelectIndex, 1);
        }

        this.setState({ selectedNodesIds, selectedSearchNode: null });

        return true;
    }

    /**
    * On mouseover node
    *
    * @return html
    */
    nodeOver(e) {
        const el           = e.currentTarget,
            { buttons }    = e,
            overedNodeId = el.attributes.dataid.nodeValue;

        if (buttons > 0) {
            return;
        }

        this.setState({
            overedNodeId,
            linkValueOvered: null
        });
    }


    /**
    * On mouseout node
    *
    * @return html
    */
    nodeOut(e) {
        const { buttons } = e,
            { nodeDraggedId } = this.state;

        if (buttons > 0 || nodeDraggedId) {
            return;
        }

        this.setState({
            overedNodeId: null,
        });
    }

    /**
     * Mangage drag node filter
     */
    dragFilter(event) {
        const { srcElement } = event || {},
            { tagName }      = srcElement || {};

        // Text node can't be drag
        return srcElement && tagName !== 'text';
    }

    /**
    * Manage drag node
    *
    * @return html
    */
    dragstarted(event) {
        const { srcElement } = event?.sourceEvent,
            element          = srcElement && srcElement.closest(".icon-container"),
            { nodesByIds }   = this.state,
             nodeId          = element && element?.attributes?.dataid?.nodeValue,
            d                = nodeId && nodesByIds[nodeId];

        if (!d) { return; }

        this.setState({
            nodeId,
            autoCenter: false
        });

        d.fx = d.x;
        d.fy = d.y;
    }

    /**
    * Manage drag node
    *
    * @return html
    */
    dragged(event) {
        const { nodesByIds, nodeId } = this.state,
            d                        = nodeId && nodesByIds[nodeId];

        if (!d) { return; }

        this.moveNodeIntoTheDom(d);

        this.setState({
            nodeDraggedId: nodeId,
            overedNodeId : nodeId, // Force overedNodeId
        });

        d.fx = event.x;
        d.fy = event.y;

        d.x = d.fx;
        d.y = d.fy;
    }

    /**
    * Manage drag node
    *
    * @return html
    */
    dragended(event) {
        const {
            nodesByIds,
            nodeId,
            nodeDraggedId
        }      = this.state,
            d  = nodeId && nodesByIds[nodeId];

        this.setState({
            nodeDraggedId: false,
            nodeId       : false,
        });

        if (!d) { return; }

        this.moveNodeIntoTheDom(d);

        if (!nodeDraggedId) {
            this.nodeClick(event, nodeId);
        }

        d.fx = null;
        d.fy = null;
    }

    /**
     * Move node into the html dom
     */
    moveNodeIntoTheDom(node) {
        const {
                highlightedLinks, linksByIds
            }                    = this.state,
            nodesSelection       = d3SelectAll(`svg#${this.id} g.nodes g.node[dataid='${node.id}']`),
            links                = highlightedLinks.filter(link => link.source.id === node.id || link.target.id === node.id),
            linksIds             = links.map(link => link.id),
            allLinksSelection    = d3SelectAll(`svg#${this.id} g.links line.link`),
            linksSelection       = allLinksSelection.filter(
                (d, index, domLinks) => linksIds.includes(domLinks[index].attributes.dataid.value)
            );


        // Move nodes
        nodesSelection.attr('transform', `translate(${node.x},${node.y})`);

        // Move Links
        linksSelection.each((d, index, domElements) => {
            const domElement = domElements[index],
                linkId       = domElement.attributes.dataid.value,
                data         = linksByIds[linkId];

            domElement.setAttribute('x1', data.source.x);
            domElement.setAttribute('y1', data.source.y);
            domElement.setAttribute('x2', data.target.x);
            domElement.setAttribute('y2', data.target.y);
        });
    }


    /**
    * Return sized value from Gd3
    *
    * @return html
    */
    sizeScale(value) {
        const { gD3, sizeRange } = this.state,
            sizeDomain        = gD3.sizeDomain(),
            sizeScale = D3ScaleLinear().domain(sizeDomain).range(sizeRange);

        return sizeScale(value);
    }

    /**
    * Render the zoom control
    *
    * @return html
    */
    renderZoomControl() { // eslint-disable-line  max-lines-per-function
        const { isCapture }             = this.props,
            { scale, selectedNodesIds } = this.state,
            marks                       = {
                0  : '',
                100: '',
            };

        return isCapture ? null : (
            <div className="zoom-control">
                {selectedNodesIds.length > 0 && (
                    <div className="btts">
                        <Button onMouseDown={this.useInstantCenter} title="Fit on selection"
                            className="fit-selection"
                        >
                            <Icon type="target" width={16}
                                color="var(--secondary-color)"
                            />
                        </Button>
                    </div>
                )}
                <div className="btts">
                    <Button onMouseDown={this.useInstantCenter} title="Fit on all nodes"
                        className="fit-all"
                    >
                        <Icon type="fit" width={10} />
                    </Button>
                </div>
                <div className="btts">
                    <Button onMouseDown={this.zoomIn}  icon={<Icon type="plus" width={12} />}
                        title="Zoom in" className="zoom-in"
                    />
                    <div className="zoom-btts-separator" />
                    <Button onMouseDown={this.zoomOut} icon={<Icon type="minus" width={12} />}
                        title="Zoom out" className="zoom-out"
                    />
                </div>
                <div className="slider">
                    <Slider
                        vertical
                        defaultValue={50}
                        value={Math.round(100 * this.computeUnityScale(scale))}
                        marks={marks}
                        min={0}
                        max={100}
                        tooltip={{formatter: null}}
                        onChange={this.onSliderMove}
                    />
                </div>

            </div>
        );
    }

    /**
    * Render the svg
    *
    * @return html
    */
    renderSvg() { // eslint-disable-line
        const {
                width, height,
                disableZoom, onMouseOut,
                onMouseMove, onMouseDown, onMouseUp,
                linkStyle, linkWidth, nodeColor
            }       = this.props,
            {
                gD3, plotData, gexf, nodesByIds, linksByIds,
                sameData, sizeRange,
                scale, selectedNodesIds, overedNodeId, highlightedNodes,
                highlightedLinks, normalLinks, linkValueOvered,
                forceRenderLinks, nodeDraggedId, selectedSearchNode
            } = this.state,
            zoomProps = !disableZoom ? {
                onMouseMove,
                onMouseDown,
                onMouseUp,
                onMouseOut,
                onBlur: onMouseOut,
            } : null;

        return (
            <svg
                {... zoomProps}
                id={this.id}
                width={width}
                height={height}
                ref={this.svgRef}
            >
                <g className="network-background" onMouseMove={this.networkMouseOver}>
                    <rect
                        fill="white"
                        x="0"
                        y="0"
                        width={width}
                        height={height}
                        onMouseDown={this.networkClickDown}
                        onMouseUp={this.networkClickUp}
                    />
                </g>
                <NetworkBase
                    gexf={gexf}
                    gD3={gD3}
                    plotData={plotData}
                    sameData={sameData}
                    nodesByIds={nodesByIds}
                    linksByIds={linksByIds}
                    selectedNodesIds={selectedNodesIds}
                    overedNode={overedNodeId && nodesByIds[overedNodeId]}
                    highlightedNodes={highlightedNodes}
                    highlightedLinks={highlightedLinks}
                    normalLinks={normalLinks}
                    onRenderBase={this.onRenderBase}
                    networkId={this.id}
                    zoomScale={scale}
                    gD3SizeScale={this.sizeScale}
                    sizeRange={sizeRange}
                    forwardedRef={this.networkRef}
                    linkStyle={linkStyle}
                    linkWidth={linkWidth}
                    nodeColor={nodeColor}
                    nodeOver={this.nodeOver}
                    nodeOut={this.nodeOut}
                    linkValueOver={this.linkValueOver}
                    linkValueOut={this.linkValueOut}
                    linkValueClick={this.linkValueClick}
                    linkValueOvered={linkValueOvered}
                    nodeTitleClick={this.nodeTitleClick}
                    onMouseDown={this.networkClickDown}
                    onMouseUp={this.networkClickUp}
                    onMouseMove={this.networkMouseOver}
                    forceRenderLinks={forceRenderLinks}
                    nodeDraggedId={nodeDraggedId}
                    selectedSearchNode={selectedSearchNode}

                />
            </svg>
        );
    }


    /**
     * Render DatGui
     */
    renderDatGui() {
        const { guiSetting } = this.state;

        return (
            <DatGui data={guiSetting} onUpdate={this.saveGuiSetting}>
                <DatPresets
                    label="Presets"
                    options={presets}
                    onUpdate={this.saveGuiSetting}
                />
                {/* A<DatNumber path="linkStrength" label="linkStrength" min={0} max={1} step={0.01} /> */}
                <DatNumber path="linkLength" label="linkLength"
                    min={1} max={300}
                    step={1}
                />
                {/* A <DatNumber path="repulsion" label="repulsion" min={0} max={100} step={1} /> */}
                <DatNumber path="clusterDistance" label="clusterDistance"
                    min={0} max={200}
                    step={1}
                />
                <DatButton label="Punch" onClick={() => this.relaunchAlpha(1)} />
                <DatButton label="Animate" onClick={this.relaunchAlpha} />
                <DatButton label="Stop animate" onClick={this.stopAlpha} />
            </DatGui>
        );
    }


    /**
     * On change in search node autocomplete
     *
     * @param {string} id
     */
    onSearchNodeChange(id) {
        if (!id) {
            // Manage clear button
            this.setState({ selectedSearchNode: null });
        }
    }

    /**
     * On select a node in autocomplete
     *
     * @param {string} searchString
     */
    onSearchNodeSelect(selectedSearchNodeId) {
        const { nodesByIds }   = this.state,
            selectedSearchNode = nodesByIds[selectedSearchNodeId];

        this.setState(
            { selectedSearchNode },
            () => {
                this.centerGraph({
                    instantCenter: true,
                });
            }
        );
    }


    /**
     * Render the search node input
     *
     * @returns JSX
     */
    renderSearchInput() {
        const {
                noContent, disableSearch, isCapture
            } = this.props,
            {
                plotData, simulationStarted,
                modelsData, selectedSearchNode,
            } = this.state,
            options = plotData?.nodes?.map(data => {
                const model = modelsData.find(m => m.id === data.id),
                    { org_acronym, org_aka, label } = model || {},
                    text    = `${label}${org_acronym ? ' - ' + org_acronym: ''}${org_aka ? ` (${org_aka})` : ''}`;
                if (!model) {
                    console.log('node not found in models',  data.id, modelsData);
                }
                return model && { value: data.id, label: text};
            }).filter(option => !!option), // Remove empty option (model not in modelsData)
            isOrgunitModels = modelsData && modelsData[0]?.type === 'orgunit',
            placeholder     = isOrgunitModels
                ? 'Search organization (Facebook, Samsung...)'
                : 'Search expert',
            autocompleteProps = {};

        if (isCapture || disableSearch || noContent || !plotData || plotData.nodes.length === 0 || simulationStarted) {
            return null;
        }

        // To not block typing)
        // Inject value only with selectedSearchNode
        // And set Key AutoComplete
        if (selectedSearchNode) {
            autocompleteProps.value = selectedSearchNode.label;
        }

        return (
            <div className="search-node">
                <AutoComplete
                    key={selectedSearchNode?.id}
                    {...autocompleteProps}
                    popupClassName="autocomplete-nodes-list"
                    options={options && _.sortBy(options, option => option.label.trim())}
                    filterOption={(inputValue, option) => (
                        option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    )}
                    onSelect={this.onSearchNodeSelect}
                    onChange={this.onSearchNodeChange}
                    notFoundContent="No matching result found"
                >
                    <Input.Search placeholder={placeholder} allowClear />
                </AutoComplete>
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {  // eslint-disable-line max-lines-per-function
        const {
                width,
                height,
                skeletonGradient,
                disableZoom,
                noContent,
            }          = this.props,
            {
                plotData, gexf, filterNodesBySelectedIds,
                simulationStarted, selectedNodesIds, showShiftZoomHelp
            }          = this.state,
            style      = !gexf ? {
                backgroundImage: skeletonGradient,
            }              : null,
            classNames = ['Network'];

        return !plotData
            ? (<span className="Network skeleton " style={style} />)
            : (
                <div
                    ref={this.ref}
                    className={classNames.join(' ')}
                    style={{
                        width : `${width}px`,
                        height: `${height}px`,
                    }}
                >
                    {(noContent || simulationStarted)
                        && (
                            <CssLoader type="ring" size={100}
                                thickness={2} color="#dddddd"
                            />
                        )}
                    {!noContent && filterNodesBySelectedIds.length > 0 && plotData && plotData.nodes.length === 0 && (
                        <div className="filter-selected-nodes-no-data">
                            <div>
                                No data found with filtered nodes
                                <br />
                                <br />
                                <Button
                                    onMouseDown={this.resetFilterSelectedNodes}
                                    icon={<Icon type="undo" width={12} />}
                                >
                                    Reset and show all
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className="worker-progress" />
                    {!disableZoom && height >= 250 ? this.renderZoomControl() : null}
                    {!disableZoom && (
                        <div className={`shift-zoom-help ${showShiftZoomHelp ? 'showed' : 'hidden'}`}>
                            <div>
                                You can zoom in on the graph using SHIFT + Scroll wheel
                            </div>
                        </div>
                    )}
                    {this.renderSearchInput()}
                    {this.renderSvg()}
                    {/* A selectedNodesIds.length > 0 && (
                        <Button
                            className="filter-nodes-btt"
                            onMouseDown={this.filterSelectedNodes}
                            icon="filter"
                        >
                            Filter selected nodes (network of selected nodes)
                        </Button>
                    ) */}
                    {selectedNodesIds.length > 0 && (
                        <Button
                            className="filter-links-btt"
                            onMouseDown={this.filterSelectedLinks}
                            icon={<Icon type="filter" width={12} />}
                        >
                            Filter selected nodes
                        </Button>
                    )}
                    {filterNodesBySelectedIds.length > 0 && plotData && plotData.nodes.length > 0 && (
                        <Button
                            className="filter-reset-nodes-btt"
                            onMouseDown={this.resetFilterSelectedNodes}
                            icon={<Icon type="undo" width={12} />}
                        >
                            Reset and show all
                        </Button>
                    )}
                    {/* A this.renderDatGui() */}
                </div>
            );
    }

}

/**
 * Props type
 */
Network.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    dataIsLoaded    : PropTypes.bool,
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    margin          : PropTypes.number,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    linkStyle       : PropTypes.string,
    startingAlpha   : PropTypes.number,
    linkWidth       : PropTypes.number,
    zoomMax         : PropTypes.number,
    disableZoom     : PropTypes.bool,
    disableSearch   : PropTypes.bool,
    nodeColor       : PropTypes.string,
    onMouseMove     : PropTypes.func.isRequired,
    onMouseDown     : PropTypes.func.isRequired,
    onMouseUp       : PropTypes.func.isRequired,
    onMouseOut      : PropTypes.func.isRequired,
    openModal       : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    mouseDragged    : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    noContent       : PropTypes.bool,
    isCapture       : PropTypes.bool,
};

/**
 * Default props value
 */
Network.defaultProps = {
    dataIsLoaded    : false,
    margin          : 0,
    skeleton        : false,
    skeletonGradient: false,
    linkStyle       : 'line',
    linkWidth       : 0.25,
    zoomMax         : 15,
    startingAlpha   : 0.3,
    disableZoom     : false,
    disableSearch   : false,
    isCapture       : false,
    nodeColor       : '#555',
    openModal       : null,
};

export default Network;

