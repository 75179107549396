// Import libs
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';
import { Checkbox }         from 'antd';
import Icon                 from 'helpers/Icon';

/**
* Display entity list header
*
*/
class Header extends Component {

    /**
    * Instanciate the Header
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'toggleAllSelection');

        this.state = {};
    }

    /**
    * Select entity
    *
    * return false
    */
    toggleAllSelection(e) {
        const { toggleAllSelection } = this.props;

        e.stopPropagation();

        toggleAllSelection();

        return false;
    }

    /**
     * Render the given icon
     *
     * @param {object} icon
     *
     * @returns JSX
     */
    renderIcon(icon) {
        const { id, folder, title } = icon;

        return (
            <Icon id={id}
                folder={folder} height={14}
                title={title} width={14}
            />
        );
    }

    /**
    * Render the document according to the props.
    *
    * @return JSX
    */
    render() {
        const {
                allowExport, selectedEntities,
                entities, isExpandable,
                columns,
            }                       = this.props,
            noneSelected            = selectedEntities.length === 0,
            allSelected             = !noneSelected && entities.length === selectedEntities.length,
            firstEntity             = entities && entities[0] || {},
            { id, isLoading, type } = firstEntity,
            expandable              = isExpandable(type);

        // Prevent to early render
        if (_.isEmpty(columns)) {
            return (
                <tr>
                    <th> </th>
                </tr>
            );
        }

        return (
            <tr>
                {allowExport && (id || isLoading) ? (
                    <th className="select" key="select"
                        title="Select all"
                    >
                        <Checkbox onClick={this.toggleAllSelection}
                            checked={!noneSelected && allSelected}
                            indeterminate={!noneSelected && !allSelected}
                            disabled={isLoading}
                        />
                    </th>
                ) : null}

                {expandable ? (
                    <th className="icon-header" style={{width: '25px'}}> </th>
                ) : null}

                {_.map(columns,
                    column => {
                        const { label, style, icon } = column;

                        return (
                            <th style={style} key={label}
                                title={label}
                            >
                                {icon ? this.renderIcon(icon) : _.upperCase(label)}
                            </th>
                        );
                    })}
            </tr>
        );
    }

}

/**
* PropTypes
*/
Header.propTypes = {
    columns           : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    allowExport       : PropTypes.bool,
    toggleAllSelection: PropTypes.func,
    entities          : PropTypes.arrayOf(PropTypes.shape()),
    selectedEntities  : PropTypes.arrayOf(PropTypes.shape()),
    isExpandable      : PropTypes.func.isRequired,
};

Header.defaultProps = {
    allowExport       : false,
    toggleAllSelection: () => {},
    entities          : [],
    selectedEntities  : [],
};

export default Header;
