import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { hsl as d3Hsl }     from 'd3';
import { Icon }             from 'helpers';
import {
    pluralize,
    formatNumberWithMagnitude,
    renderUnit,
}                           from '../../../core/utils/text';

/**
 * The one-stat graph Component
 *
 */
class OneStat extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        // Make a uuid
        this.id = _.uniqueId('one-stat-chart');
    }

    /**
    * Compute the value to display
    *
    * @return JSX
    */
    getValueToDisplay() {
        const { data } = this.props,
            value      = !data || !data.stats ? '' :  data.stats.value;

        // Do we have an int ?
        if (/^\+?(0|[1-9]\d*)$/.test(value)) {
            const formattedValue = formatNumberWithMagnitude(value, 1),
                currency         = _.get(data, 'stats.currency', false),
                isDollar         = currency === 'USD';

            // Append currency symbol
            if (currency) {
                return renderUnit(formattedValue, isDollar ? '$' : '€', true);
            }

            return formattedValue;
        }

        return value;
    }

    /**
    * Render the value
    *
    * @return html
    */
    renderValue() {
        const {
                width, fixedWidth, margin,
                skeleton, fontSize, height,
                fontSizeAuto
            }             = this.props,
            displayValue  = this.getValueToDisplay(),
            size          = _.min([width, height]),
            fontFactor    = size === width ? 10 : 6,
            valueFontSize = fontSizeAuto ?  Math.round(size / fontFactor) : fontSize * 1.3;

        return (
            <div
                style={{
                    color       : '#fff',
                    fontSize    : valueFontSize,
                    lineHeight  : `${valueFontSize * 1.4}px`,
                    whiteSpace  : 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth    : `${width - margin * 2}px`,
                    width       : fixedWidth,
                    overflow    : 'hidden'
                }}
                title={displayValue}
            >
                {skeleton ? '****' : displayValue}
            </div>
        );
    }

    /**
    * Render the text
    *
    * @return html
    */
    renderText() {
        const {
                data,
                text,
                skeleton,
                fontSize,
                icon,
                width,
                height,
                fontSizeAuto
            }            = this.props,
            iconParams   = _.cloneDeep(icon),
            value        = !data || !data.stats ? '' :  data.stats.value,
            size         = _.min([width, height]),
            fontFactor   = size === width ? 12 : 6,
            textFontSize = fontSizeAuto ? Math.round(size / fontFactor) : fontSize;

        if (fontSizeAuto && iconParams) {
            iconParams.width  = Math.round(size / fontFactor);
            iconParams.height = Math.round(size / fontFactor);
        }

        const iconContent  = iconParams ? <Icon {...iconParams} /> : null;
        return (
            <div
                style={{
                    color         : '#fff',
                    fontSize      : textFontSize,
                    display       : 'flex',
                    justifyContent: 'center',
                }}
            >
                {iconContent || (skeleton ? '****' : pluralize(text, value))}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                height, fontSize, color,
                luminance, skeleton, noStats,
                skeletonGradient, valueOnBottom,
                width, fontSizeAuto
            }           = this.props,
            dc          = d3Hsl(color),
            baseColor   = _.set(_.clone(dc), 'l', luminance).toString(),
            brightColor = _.set(_.clone(dc), 'l', luminance + 0.04).toString(),
            gradient    = `linear-gradient(33deg, ${brightColor} 10%, ${baseColor} 50%, ${brightColor} 90%)`,
            size        = _.min([width, height]),
            fontFactor  = size === width ? 15 : 7,
            padding     = fontSizeAuto ? Math.round(size / fontFactor) : fontSize * 0.8;

        return (
            <div
                className="OneStat"
                style={{
                    height,
                    width,
                    position      : skeleton || noStats ? 'relative' : null,
                    display       : 'flex',
                    justifyContent: 'center',
                    alignItems    : 'center',
                }}
            >
                <div
                    className={skeleton || noStats ? 'skeleton' : null}
                    style={{
                        background     : !skeleton ? color : null,
                        backgroundImage: skeleton || noStats ? skeletonGradient : gradient,
                        padding        : `${padding}px`,
                        textAlign      : 'center',
                        borderRadius   : fontSizeAuto ? Math.round(size / (fontFactor * 8)) : '5px',
                        maxWidth       : '100%',
                    }}
                >
                    {valueOnBottom ? this.renderText()  : this.renderValue() }
                    {valueOnBottom ? this.renderValue() : this.renderText() }
                </div>
            </div>
        );
    }

}

OneStat.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    color           : PropTypes.string, // eslint-disable-line
    text            : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // eslint-disable-line
    valueOnBottom   : PropTypes.bool,
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    margin          : PropTypes.number,
    luminance       : PropTypes.number,
    fixedWidth      : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    fontSize        : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.string,
    icon            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    noStats         : PropTypes.bool,
    fontSizeAuto    : PropTypes.bool,
};

OneStat.defaultProps = {
    margin          : 10,
    fontSize        : false,
    color           : 'var(--insight-color)',
    skeleton        : false,
    skeletonGradient: false,
    fixedWidth      : false,
    text            : '',
    luminance       : 0.52,
    valueOnBottom   : false,
    icon            : false,
    fontSizeAuto    : false,
};

export default OneStat;

