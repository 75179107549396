import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import Element              from '../../Element';
import { Row, Col }         from 'helpers';

/**
* Draw layouts with elements inside
*
*/
class AreaLayout extends Component  {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onDataLoaded', 'onBeforeLoad', 'registerCallbacks', 'triggerCallback');

        this.state = {
            callbacks        : {},
            // Store ids to load, and those disabled
            elementsToLoad   : [],
            disabledElements : [],
            // Store loaded data by elements
            loadedElements   : {},
            elementsCallbacks: {}
        };

        if (props.registerCallbacks) {
            props.registerCallbacks('triggerCallback', this.triggerCallback);
        }

        // Keep a unique empty object
        this.emptyObject = {};
    }

    /**
    * Component did update
    *
    * @return void
    */
    componentDidUpdate() {
        const { onDataLoaded, onBeforeLoad, element } = this.props,
            {
                loadedElements, disabledElements, elementsToLoad,
                loadingHasStarted, onBeforeLoadHasBeenTriggerd
            } = this.state,
            allElementsHaveBeenDisabled = elementsToLoad.length === disabledElements.length;

        // All elements are disabled
        if (onDataLoaded
            && elementsToLoad.length
            && elementsToLoad.length === _.keys(loadedElements).length
        ) {
            onDataLoaded(element, loadedElements, allElementsHaveBeenDisabled);
        }

        // Trigger once the before load callback
        if (onBeforeLoad && loadingHasStarted && !onBeforeLoadHasBeenTriggerd) {
            // TODO: fix onBeforeLoadHasBeenTriggerd != onBeforeLoadHasBeenTriggered
            onBeforeLoad(element);
            this.onBeforeLoadHasBeenTriggered = true;
        }
    }

    /**
    * Delegate the call to the layout elements
    *
    * @param string action The action to delegate
    *
    * @return void
    */
    triggerCallback(action, payload) {
        const { callbacks } = this.state;

        _.each(callbacks, (elementsCallbacks) => {
            elementsCallbacks.triggerCallback(action, payload);
        });
    }

    /**
    * Register callback from layout elements
    *
    * @return self
    */
    registerCallbacks(action, cb, element) {
        const { callbacks }  = this.state;
        if (!element || callbacks[element.id]) {
            return;
        }

        if (!callbacks[element.id]) {
            callbacks[element.id] = {};
        }

        callbacks[element.id][action] = cb;

        this.setState({ callbacks });
    }

    /**
    * Triggered when a data has been loaded
    *
    * @param object  element                     The loaded element
    * @param object  data                        The data
    * @param boolean allElementsHaveBeenDisabled
    *
    * @return
    */
    onDataLoaded(element, data, allElementsHaveBeenDisabled) {
        const {
                loadedElements
            }                = this.state,
            { category }     = element,
            { total, stats } = data;

        // It's a element with resource already loaded before
        if (loadedElements[element.id] && loadedElements[element.id].uri) { // Uri => element with ressource
            loadedElements[element.id] = { ...data, isLoading: false };
        }

        // It's a element never been loaded
        if (_.isUndefined(loadedElements[element.id])) {
            loadedElements[element.id] = {
                ...data,
                // Get isLoading info from child elements
                isLoading: _.values(data).reduce(
                    (cumul, d) => cumul || (!d || _.isUndefined(d.isLoading) ? false : d.isLoading),
                    false
                )
            };
        }

        this.setState({ loadedElements });

        if ((category === 'Area/Layout' && !allElementsHaveBeenDisabled)                 // Layout cases
            || (
                total > 0 || !_.isNull(stats) && _.keys(stats).length                    // Other sub-elements
            )
        ) {
            return;
        }

        // Disable element
        this.disableElement(element);
    }

    /**
    * Add the provided element to the excluded list
    *
    * @param object element The element definition to disable
    *
    * @return void
    */
    disableElement(element) {
        const { disabledElements } = this.state,
            isDisabled             = disabledElements.indexOf(element.id) !== -1;

        if (isDisabled) {
            return;
        }

        disabledElements.push(element.id);
        this.setState({
            disabledElements: _.clone(disabledElements)
        });
    }

    /**
    * Triggered when a data has been loaded
    *
    * @return
    */
    onBeforeLoad(element) {
        const { elementsToLoad, loadedElements } = this.state;

        // Manage to re-put isLoading on a element already loaded before
        if (loadedElements[element.id]) {
            // Put isloading to true
            loadedElements[element.id].isLoading = true;

            this.setState({
                loadedElements
            });
        }

        // Manage a element who is never be loaded
        if (elementsToLoad.indexOf(element) !== -1) {
            return;
        }

        // Put isloading to true
        element.isLoading = true;

        elementsToLoad.push(element);

        this.setState({
            loadingHasStarted: true,
            elementsToLoad
        });
    }

    /**
     * Render Element componant
     */
    renderElement(options) {
        const {
                model, element, elements:elementsKnowledge,
                parameters,  onUpdateFilters,  navigateTo,
                parentLabel, color, fixedBySelection,
                state, tags, bookmarks, registerActions,
                storeElementInstances, filtersByMetrics, avoidTooltip,
                context, disableExpand, firstCall,
                onUpdatePaginationOrder, sort, disableSort,
            } = this.props,
            { configuration, elements, properties } = options,
            configIsUUID                            = _.isString(configuration),
            elementToRenderId                       = configIsUUID ? configuration : elements,
            elementToRender                         = elementsKnowledge.find((el) => el.id === elementToRenderId);

        return (
            <Element
                key={elementToRenderId}
                element={elementToRender}
                model={model}
                context={context}
                {...properties}
                state={state}
                color={color}
                parameters={parameters}
                navigateTo={navigateTo}
                onUpdateFilters={onUpdateFilters}
                onUpdatePaginationOrder={onUpdatePaginationOrder}
                onBeforeLoad={this.onBeforeLoad}
                onDataLoaded={this.onDataLoaded}
                registerCallbacks={this.registerCallbacks}
                registerActions={registerActions}
                avoidTooltip={avoidTooltip}
                parentLabel={element.label ? `${parentLabel} / ${element.label}` : parentLabel}
                tags={tags}
                bookmarks={bookmarks}
                storeElementInstances={storeElementInstances}
                // TODO: Make elements options to pass to elements children !!
                //  (filtersByMetrics, fixedBySelection, firstCall ,disableExpand....)
                filtersByMetrics={filtersByMetrics}
                fixedBySelection={fixedBySelection}
                firstCall={firstCall}
                disableExpand={disableExpand}
                sort={sort}
                disableSort={disableSort}
            />
        );
    }

    /**
    * Render the element from its category
    *
    * @param object configuration The AreaLayout configuration object
    * @param isCol  isCol         Layout type, the default is col.
    *
    * @return JSX
    */
    renderLayoutsFromConfiguration(configuration, isCol = true, ratio = undefined) {
        const {
                ratioKey, elements: allElements,
            }                    = this.props,
            { disabledElements } = this.state,
            Layout               = isCol ? Col : Row,
            configIsArray        = _.isArray(configuration),
            configIsUUID         = _.isString(configuration),
            isSingleElement      = configIsUUID
                || (configuration.elements && !_.isArray(configuration.elements)),
            elements = configIsArray
                || configIsUUID ? configuration : configuration.elements;

        // No layouts, here we have to append a single element
        if (isSingleElement) {
            const properties    = configuration.elements ? _.omit(configuration, 'elements') : this.emptyObject;

            properties.ratio = properties.ratio || ratio;

            return this.renderElement({ configuration, elements, properties });
        }

        // Create the Layout from configuration
        return _.map(elements, (element) => {
            const subElement                 = allElements.find((el) => el.id === element),
                { configuration:subConfig }  = subElement || {},
                { ratio:subElementRatio }    = subConfig  || {},
                ratioIsObject                = _.isObject(subElementRatio),
                effectiveRatio               = ratioIsObject
                    ? (subElementRatio[ratioKey] ? subElementRatio[ratioKey] : null)
                    : subElementRatio,
                layoutRatio                  = element.ratio || effectiveRatio || `1/${elements.length}`;

            if (disabledElements.indexOf(element.id) !== -1) {
                return false;
            }

            return (
                <Layout key={`layout-${element}`} ratio={layoutRatio}>
                    {this.renderLayoutsFromConfiguration(element.elements || element, !isCol)}
                </Layout>
            );
        });
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { element }      = this.props,
            { configuration }  = element;

        return (
            <Row
                key={element.id}
                {...this.props}
            >
                {this.renderLayoutsFromConfiguration(configuration)}
            </Row>
        );
    }

}

AreaLayout.propTypes = {
    avoidTooltip           : PropTypes.any,
    bookmarks              : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    color                  : PropTypes.any,
    context                : PropTypes.any,
    elements               : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filtersByMetrics       : PropTypes.any,
    fixedBySelection       : PropTypes.any,
    firstCall              : PropTypes.bool,
    forceEmptyRemoval      : PropTypes.bool,
    model                  : PropTypes.object,
    navigateTo             : PropTypes.func,
    onBeforeLoad           : PropTypes.func,
    onDataLoaded           : PropTypes.func,
    onUpdateFilters        : PropTypes.func,
    onUpdatePaginationOrder: PropTypes.func,
    parameters             : PropTypes.any,
    parentLabel            : PropTypes.any,
    ratioKey               : PropTypes.any,
    registerCallbacks      : PropTypes.func,
    registerActions        : PropTypes.func,
    search                 : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    state                  : PropTypes.any,
    storeElementInstances  : PropTypes.any,
    tags                   : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    disableExpand          : PropTypes.bool,
    disableSort            : PropTypes.bool,
    sort                   : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    element                : PropTypes.shape({
        configuration: PropTypes.shape({
            padding: PropTypes.any,
            spacing: PropTypes.any
        }),
        elements: PropTypes.any,
        id      : PropTypes.any,
        label   : PropTypes.any,
        ratio   : PropTypes.any
    }).isRequired,
};

AreaLayout.defaultProps = {
    forceEmptyRemoval: false,
    search           : false,
    model            : false,
    disableSort      : false,
};

export default AreaLayout;

