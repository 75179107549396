import React, { Component }    from 'react';
import _                       from 'lodash';
import PropTypes               from 'prop-types';
import {
    scaleLinear     as d3ScaleLinear,
    line            as d3Line,
    curveMonotoneX  as d3CurveMonotoneX,
    curveStepAfter  as d3CurveStepAfter,
    curveStepBefore as d3CurveStepBefore,
    curveLinear     as d3CurveLinear,
    curveCatmullRom as d3CurveCatmullRom,
    curveCardinal   as d3CurveCardinal,
    curveBasis      as d3CurveBasis,
    area            as d3Area,
    max             as d3Max,
    min             as d3Min,
    hsl             as d3Hsl,
}                              from 'd3';
import { Popover }             from 'antd';
import {
    pluralize,
    formatNumberWithMagnitude,
    formatInternationalNumber,
}                              from 'utils/text';
import { NoData }              from 'helpers';
import Landmark                from '../Landmark';
import Animation               from '../Animation';
import TrendInformation        from '../TrendInformation';
import { getDomColor }         from 'utils/dom';

import './LineArea/main.less';

const interpolationCurves = {
    curveX       : d3CurveMonotoneX,
    stepAfter    : d3CurveStepAfter,
    stepBefore   : d3CurveStepBefore,
    curveLinear  : d3CurveLinear,
    curveCat     : d3CurveCatmullRom,
    curveCardinal: d3CurveCardinal,
    curveBasis   : d3CurveBasis,
};

const handlerWidth = 20;

const animationDuration = 200;

/**
 * The graph line area Component
 *
 */
class LineArea extends Component {

    /**
    *  Screenplay for animation
    *
    * @return object
    */
    static getScenario(options) {   // eslint-disable-line max-lines-per-function
        const { innerWidth, height } = options;

        return {
            scenes: {
                intro: {
                    steps: {
                        main: [
                            { attribute: 'y', duration: animationDuration },
                            // { attribute: 'opacity', duration: animationDuration },
                        ],
                    },
                    defaults: {
                        // Opacity: 0,
                        y: height,
                    },
                },
                loading: {
                    steps: {
                        main: [
                            { attribute: 'opacity', duration: animationDuration, value: 0.3 },
                        ]
                    }
                },
                contentChange: {
                    steps: {
                        before: [
                            { attribute: 'y', duration: animationDuration, value: height },
                            { attribute: 'opacity', duration: animationDuration, value: 0 },
                        ],
                        main: [
                            {
                                attribute: 'x',
                                duration : animationDuration,
                                atEnd    : [
                                    { attribute: 'y', duration: animationDuration },
                                    { attribute: 'value', duration: animationDuration },
                                    { attribute: 'opacity', duration: animationDuration },
                                ]
                            },
                        ],
                    },
                    defaults: {
                        opacity: 0,
                        x      : [0, innerWidth],
                        y      : 0,
                        value  : 0,
                    },
                },
                general: {
                    steps: {
                        main: [
                            { attribute: 'y', duration: animationDuration },
                            { attribute: 'opacity', duration: animationDuration },
                        ],
                    },
                    defaults: {
                        opacity: 0,
                        y      : 0,
                    },
                }
            },
        };
    }

    /**
    * Update serie in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    static updateSerieAndSumData(options) {
        const { nextProps, state }           = options,
            { isCumulative, content, stats } = nextProps,
            {
                extendMinX, extendMaxX,
                sliceMinX,
            }                                = stats,
            sumData                          = [],
            rawDataContent                   = content || [],
            // Slice with data from stats
            dataContent                      = rawDataContent.filter((d) => (sliceMinX ? parseFloat(d.x) >= sliceMinX : true));

        let serie = _.clone(dataContent).map((item) => ({ ...item, x: parseFloat(item.x) })); // Force X to float !!!

        // Manage inject extendMinX
        if (extendMinX && !_.find(serie, (item) => item.x === extendMinX)) {
            serie.push({ x: extendMinX, y: 0, falseData: true });
        }

        // Manage hidden data from extendMinX and extendMaxX
        if (extendMinX || extendMaxX) {
            serie = serie.map((item) => {
                item.hidden = (extendMinX && item.x < extendMinX) || (extendMaxX && item.x > extendMaxX);
                return item;
            });
        }

        // Manage inject extendMinX
        if (extendMaxX && !_.find(serie, (item) => item.x === extendMaxX)) {
            serie.push({ x: extendMaxX, y: 0, falseData: true });
        }

        state.forceShowDots = false;
        // Extend for unique point
        if (serie.length === 1) {
            state.forceShowDots = true;
            const xx = serie[0].x;
            serie.push({
                id: xx - 1, x: xx - 1.0, y: 0, falseData: true
            });
            serie.push({
                id: xx + 1, x: xx + 1.0, y: 0, falseData: true
            });
        }

        serie = _.orderBy(serie, ['x']);

        // Set sumData : using reduce to set cumulative array
        serie.reduce(
            (a, b, i) => {
                sumData[i] = {x: b.x, y: a.y + b.y, falseData: b.falseData, value: b.value};
                return sumData[i];
            },
            { x: (serie[0] ? serie[0].x : 0) - 1, y: 0 }
        );

        // Make flat serie from multiple data on same x (stacked linearea)
        state.groupedSerieByX     = _.groupBy(isCumulative ? sumData : serie, 'x');
        state.groupedSerieByType  = _.groupBy(isCumulative ? sumData : serie, 'type');

        state.serie     = serie;

        state.sumData = sumData;

        return { nextProps, state };
    }

    /**
    * Update projectedDataByType
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    static updateProjectedDataByType(options) { // eslint-disable-line  max-lines-per-function
        const {
                nextProps,
                state,
            }                                            = options,
            { showProjectedValues, projectedBaseLength } = nextProps,
            { stats, projectedMin, content }             = nextProps,
            { groupedSerieByType }                       = state,
            { extendMinX, extendMaxX, lastEnabledData }  = stats,
            closestOptions                               = { min: extendMinX, max: extendMaxX };

        // Init projectedDataByType
        state.projectedDataByType = {};

        // Loop on serie grouped by type
        _.forIn(groupedSerieByType, (serie, serieType) => { // eslint-disable-line  max-lines-per-function
            const enabledData = lastEnabledData
                    ? _.filter(serie, (obj) => obj.x <= lastEnabledData)
                    : serie,
                projectedBaseData = lastEnabledData && showProjectedValues
                    ? _.filter(enabledData, (obj) => obj.x > (lastEnabledData - projectedBaseLength))
                    : null,
                // Base point to projected data
                firstBasePoint = projectedBaseData
                    ? LineArea.getClosestDataFromX(
                        Math.floor(lastEnabledData - projectedBaseLength + 1),
                        enabledData,
                        closestOptions
                    )
                    : null,
                secondBasePoint = projectedBaseData
                    ? enabledData.find((obj) => lastEnabledData === obj.x)
                    : null,
                firstMedianPoint = projectedBaseData
                    ? LineArea.getClosestDataFromX(
                        Math.floor(lastEnabledData - projectedBaseLength * 3 / 4 + 1),
                        enabledData,
                        closestOptions
                    )
                    : null,
                secondMedianPoint = projectedBaseData
                    ? LineArea.getClosestDataFromX(
                        Math.ceil(lastEnabledData - projectedBaseLength / 4),
                        enabledData,
                        closestOptions
                    )
                    : null,
                // First median Y to calculate protected scope
                firstMedianY = projectedBaseData
                    ? _.get(firstBasePoint, 'y', 0)
                        + (
                            _.get(firstMedianPoint, 'y', 0)
                            - _.get(firstBasePoint, 'y', 0)
                        ) / 2
                    : null,
                // Second median Y to calculate protected scope
                secondMedianY = projectedBaseData
                    ? _.get(secondBasePoint, 'y', 0)
                        - (
                            _.get(secondBasePoint, 'y', 0)
                            - _.get(secondMedianPoint, 'y', 0)
                        ) / 2
                    : null,
                // Prepare linear function params ( y = projectedSlope * x + projectedYOffset )
                projectedSlope = (secondMedianY - firstMedianY) / (projectedBaseLength / 2),
                projectedYOffset = lastEnabledData
                    ? _.get(enabledData.find((obj) => lastEnabledData === obj.x), 'y', 0)
                    : 0;

            // Store projected points
            state.projectedDataByType[serieType] = showProjectedValues
                ? _.times(1 + state.maxX - lastEnabledData, (num) => {
                    const x     = num + lastEnabledData,
                        y       = projectedYOffset + projectedSlope * (num),
                        cropedY = _.isNull(projectedMin) ? y : _.max([projectedMin, y]);

                    return { x, y: cropedY, type: serieType, typeLabel: serie[0].typeLabel };
                }) : [];
        });

        // Use realData instead projectedData
        _.mapValues(
            state.projectedDataByType,
            (dataByType, type) => {
                _.times(1 + state.maxX - lastEnabledData, (num) => {
                    const x              = num + lastEnabledData,
                        realData         = content.find(
                            (item) => (
                                item.type === type || (_.isUndefined(item.type) && type === 'undefined')
                            ) && item.x === x
                        ),
                        projectedData    = dataByType.find((item) => item.x === x),
                        projectedIsUnder = realData && projectedData && projectedData.y < realData.y;
                    // Use realData
                    if (projectedIsUnder) {
                        projectedData.y = realData.y;
                    }
                });
            }
        );

        // TODO: Better correction (must use projectedIsUnder information)
        // Correction with intervalues
        _.mapValues(
            state.projectedDataByType,
            dataByType => {
                const first = _.first(dataByType),
                    last    = _.last(dataByType);

                if (dataByType.length > 1) {
                    const dataToPass = dataByType.filter((item) => item.x !== first.x),
                        maxValue     = dataToPass.reduce((cumul, item) => _.max([cumul, item.y]), 0);

                    if (maxValue - last.y > 0) {
                        last.y = maxValue;
                    }
                }
            }
        );

        return { nextProps, state };
    }

    /**
    * Update flatSerie
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    static updateFlatSerie(options) {
        const {
            nextProps,
            state,
        } = options;

        // Make a cumul by X
        state.flatSerie = _.map(
            state.groupedSerieByX,
            items => items.reduce((cumul, item) => {
                cumul.x = item.x;
                cumul.y = (cumul.y || 0) + item.y;
                return cumul;
            }, {})
        );

        state.minX    = d3Min(state.flatSerie, (d) => d.x);
        state.maxX    = d3Max(state.flatSerie, (d) => d.x);

        return { nextProps, state };
    }

    /**
     * Get a plot data from a item
     * @returns An object
     */
    static getPointData(options, item, { projected = true, hidden = false}) {
        const { state }       = options,
            { scales } = state,
            {
                xScale,
                yScale
            }                 = scales || {};

        return {
            _x       : item.x,
            _y       : item.y,
            value    : item.y,
            type     : item.type,
            typeLabel: item.typeLabel,
            id       : `p-${item.type}-${item.x}`,
            x        : xScale && xScale(item.x),
            y        : yScale && yScale(item.y),
            opacity  : 1,
            projected,
            hidden,
        };
    }

    /**
    * Update plotData in state
    *   Sgb path of curves areas
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    static updatePlotData(options) { // eslint-disable-line  max-lines-per-function
        const { nextProps, state }          = options,
            { isCumulative, interpolation } = nextProps,
            { itemUnit, stats }             = nextProps,
            { preposition }                 = nextProps,
            { serie, projectedDataByType }  = state,
            { groupedSerieByType }          = state,
            { lastEnabledData }             = stats,
            cleanSerie                      = _.filter(serie, (item) => item.falseData !== true),
            {
                scales,
                sumData,
            } = state,
            {
                xScale,
                yScale
            } = scales || {},
            yRange      = yScale?.range() || [],
            rawPlotData = isCumulative ? sumData : cleanSerie,
            pointData   = _.map(rawPlotData, (d) => {
                const dd = _.clone(d);
                return {
                    ...dd,
                    id           : dd.x,
                    _x           : dd.x,
                    _y           : dd.y,
                    x            : xScale && xScale(d.x),
                    y            : yScale && yScale(d.y),
                    opacity      : 1,
                    projected    : false,
                    hidden       : dd.hidden,
                    enabled      : lastEnabledData ? d.x <= lastEnabledData : true,
                    valueSentence: `<b>${formatNumberWithMagnitude(d.y, 1)}`
                        + `${d.projected ? ' projected' : ''} `
                        + `${pluralize(d.typeLabel || itemUnit, d.y)}</b> `
                        + `${preposition} <b>${d.label || d.x}</b>`,
                };
            });

        // Add projection point on each serie type
        _.forIn(groupedSerieByType, (serie, serieType) => {
            const lastProjected = _.last(projectedDataByType[serieType]);

            projectedDataByType[serieType].forEach((datum) => {
                const isLastProjected = datum === lastProjected;
                pointData.push(LineArea.getPointData(options, datum, { projected: true, hidden: !isLastProjected }));
            });
        });

        // Define the line
        const valueCurve = d3Line()
            .x((d) => d?.x)
            .y((d) => d?.y);

        // Define the area
        const valueAreaCurve = d3Area()
            .x((d) => d?.x)
            .y0(yRange[1])
            .y1((d) => d?.y);

        valueCurve.curve(interpolationCurves[interpolation]);     // Curve generator
        valueAreaCurve.curve(interpolationCurves[interpolation]); // Curve area generator

        state.pointData      = pointData;
        state.valueCurve     = valueCurve;
        state.valueAreaCurve = valueAreaCurve;

        return { nextProps, state };
    }

    /**
    * Get plot vertical stacked data
    *
    * @params options  object { nextProps, state }
    * @params plotData object cumuledData
    *
    * @return object
    */
    static updateStackedPlotData(options) {
        const { nextProps, state }    = options,
            { showStack, itemUnit }   = nextProps,
            { scales }                = state,
            { flatSerie, pointData }  = state,
            { yScale }                = scales || {},
            { preposition }           = nextProps,
            indexStackOffset          = {},
            indexProjectedStackOffset = {},
            stackedData               = [],
            maxValue                  = d3Max(flatSerie, (d) => d.y),
            // Make groupedPointData with all data (normal and projected)
            groupedPointData             = _.groupBy(pointData, '_x');

        // Not showStack or no type in data
        if (!showStack || _.get(_.first(groupedPointData), 'type', false)) {
            return { nextProps, state };
        }

        flatSerie.forEach((plotDatum) => {
            const stackData = groupedPointData[plotDatum.x];
            if (stackData) {
                stackData.forEach((rawData) => {
                    const index     = rawData.projected ? indexProjectedStackOffset : indexStackOffset,
                        stackOffset = (index[plotDatum.x] || 0),
                        valueSentence = `<b>${formatNumberWithMagnitude(rawData._y, 1)}`
                            + `${rawData.projected ? ' projected' : ''} `
                            + `${pluralize(rawData.typeLabel || rawData.type || itemUnit, rawData._y)}</b> `
                            + `${preposition} <b>${rawData.label || plotDatum.x}</b>`,
                        size          = maxValue !== 0 && yScale
                            ? Math.round(yScale(rawData._y + stackOffset))
                            : 0;
                    stackedData.push({
                        ...rawData,
                        y: size,
                        valueSentence,
                    });

                    index[plotDatum.x] = stackOffset + rawData._y;
                });
            }
        });

        state.pointData = stackedData;

        return { nextProps, state };
    }

    /**
    * Update selectedRange in state
    *   Selected range in data scale
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state }
    */
    static updateSelectedRange(options) {
        const { nextProps, state } = options,
            {
                selectedRange, scales,
                selectedPixelRange
            }                      = state,
            { xScale }             = scales || {},
            minPixel               = xScale && xScale(_.get(selectedRange, 'min.x')),
            maxPixel               = xScale && xScale(_.get(selectedRange, 'max.x')),
            pixelIsNotSnaped       = selectedPixelRange?.min.x !== minPixel || selectedPixelRange?.max.x !== maxPixel;

        // Get default selectedRange
        state.selectedRange = pixelIsNotSnaped
            ? selectedRange
            : LineArea.getSelectedRangeFromDefault({ nextProps, state });



        return { nextProps, state };
    }

    /**
    * Update selectedRange in state
    *
    * Get selected range in data values from selected pixel range
    *     And Trigger onSelectionChange
    *
    * @params options object { nextProps, state }
    *
    *
    * @return object selectedRange
    */
    static updateSelectedRangeFromPixelRange(options) {
        const { nextProps, state } = options,
            {
                mouseDragged,
                onSelectionChange,
                canChangeSelect,
                isCumulative,
                content,
                stats,
            }                      = nextProps,
            {
                selectedPixelRange,
                selectedRange,
                sumData,
                scales,
            }                      = state,
            workingData            = isCumulative ? sumData : (content || []),
            {
                extendMinX,
                extendMaxX,
            }                      = stats,
            closestOptions         = {
                min: extendMinX,
                max: extendMaxX
            };

        if (!canChangeSelect || mouseDragged === false) {
            return { nextProps, state };
        }

        // On mouse dragged
        // Find closest data for selected pixel range
        const closestPointMin   = LineArea.getClosestDataFromX(
                scales.xScale.invert(selectedPixelRange.min.x),
                workingData,
                closestOptions
            ),
            closestPointMax     = LineArea.getClosestDataFromX(
                scales.xScale.invert(selectedPixelRange.max.x),
                workingData,
                closestOptions
            );

        // Trigger on selected range change
        if (selectedRange.min !==  closestPointMin || selectedRange.max !== closestPointMax) {
            onSelectionChange({ min: closestPointMin, max: closestPointMax });
        }

        // Set selectedRange from closest Point (in data scale)
        state.selectedRange = {
            min: closestPointMin,
            max: closestPointMax,
        };

        return { nextProps, state };
    }

    /**
    * Update selectedRange in state
    *
    * Get selected range in data values from selected pixel range
    *
    * @params options object { nextProps, state }
    *
    *
    * @return object selectedRange
    */
    static updateSelectedRangeFromPixelRangeFromExtend(options) { // eslint-disable-line  max-lines-per-function
        const { nextProps, state } = options,
            {
                isCumulative,
                stats,
                content,
            }                      = nextProps,
            {
                selectedRange,
                sumData,
                minX,
                maxX,
            }                      = state,
            {
                extendMinX,
                extendMaxX
            } = stats,
            workingData         = isCumulative ? sumData : (content || []),
            closestOptions      = {
                min: _.min([minX, extendMinX]),
                max: _.max([maxX, extendMaxX])
            },
            closestPointMin     = selectedRange.min
                ? LineArea.getClosestDataFromX(selectedRange.min.x, workingData, closestOptions)
                : false,
            closestPointMax     = selectedRange.max
                ? LineArea.getClosestDataFromX(selectedRange.max.x, workingData, closestOptions)
                : false,
            trueClosestPointMin = selectedRange.min
                ? LineArea.getClosestDataFromX(selectedRange.min.x, workingData)
                : false,
            trueClosestPointMax = selectedRange.max
                ? LineArea.getClosestDataFromX(selectedRange.max.x, workingData)
                : false,
            pointsAreOutter     = (
                (extendMinX && trueClosestPointMin?.x < extendMinX)
            ) && (
                (extendMaxX && extendMaxX < trueClosestPointMax?.x)
            ) && selectedRange.min;

        if (pointsAreOutter) {
            const newSelectedRange = _.cloneDeep(selectedRange);

            newSelectedRange.min = null;
            newSelectedRange.max = null;

            state.selectedRange = newSelectedRange;
            return { nextProps, state };
        }

        const newSelectedRange = { min: closestPointMin, max: closestPointMax };
        state.selectedRange = newSelectedRange;

        return { nextProps, state };
    }

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state }  = options,
            { color, stacksColors } = nextProps,
            realColor               = getDomColor(color),
            dc                      = d3Hsl(realColor),
            luminance               = 0.75, // TODO make props and plug on basic colors !!
            luminanceBottom         = 0.89,
            flat                    = false,
            colors                  = {
                text          : realColor,
                curve         : realColor,
                area          : [dc.brighter(2.9).hex(), dc.brighter(1.9).hex()],
                selectedArea  : [dc.brighter(2.5).hex(), dc.brighter(1.5).hex()],
                rangeDot      : realColor,
                rangeDotCenter: '#ffffff',
                rangeBorder   : realColor,
                landmark      : realColor
            };

        // Add colors for stacks
        _.each(stacksColors, (stackColor, stackKey) => {
            const stackDc                = d3Hsl(stackColor),
                stackBaseUnSelectedColor = _.set(_.clone(stackDc), 'l', luminance + 0.3).toString(),
                stackBaseSelectedColor   = _.set(_.clone(stackDc), 'l', luminance - 0.05).toString();

            colors[`${stackKey}curve`] = stackColor;

            colors[`${stackKey}area`] = [
                stackBaseUnSelectedColor,
                flat ? stackBaseUnSelectedColor : _.set(_.clone(stackDc), 'l', luminanceBottom - 0.1).toString(),
            ];

            colors[`${stackKey}selectedArea`] = [
                stackBaseSelectedColor,
                flat ? stackBaseSelectedColor : _.set(_.clone(stackDc), 'l', luminanceBottom - 0.5).toString(),
            ];
        });

        state.colors = colors;
        return { nextProps, state };
    }

    /**
    * Update actualProps
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateActualProps(options) {
        const { nextProps, state }     = options,
            { content, width, height } = nextProps;

        state.actualProps = { content, width, height };

        return { nextProps, state };
    }

    /**
    * Update stats in state
    *
    *    Stats informations to be displayed in popin
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateStats(options) {
        const { nextProps, state }           = options,
            { isCumulative }                 = nextProps,
            { sumData, selectedRange, maxX } = state,
            { flatSerie }                    = state,
            serieMin = selectedRange.min
                ? flatSerie.find((item) => item.x === selectedRange.min.x)
                : false,
            serieMax = selectedRange.max
                ? flatSerie.find((item) => item.x === selectedRange.max.x)
                : false;

        if (selectedRange === false || !serieMin || !serieMax) {
            state.stats = {
                growingRate           : false,
                selectedTotal         : false,
                selectedOnTotalPercent: false,
                selected              : false,
            };

            return { nextProps, state };
        }

        // Regroup data and sumData for boundary of selection
        const selected = {
                min: {
                    data: serieMin,
                    sum : sumData.find((item) => item.x === selectedRange.min.x)
                },
                max: {
                    data: serieMax,
                    sum : sumData.find((item) => item.x === selectedRange.max.x)
                }
            },
            // Bigger sumData item
            maxSum          = sumData.find((item) => item.x === maxX),
            // Growing rate percent from selection (start - end)
            nbX             = selected.max.data.x - selected.min.data.x,
            growingRate     = isCumulative
                ? (selected.min.sum.y !== 0
                    ? Math.round(1000 * (Math.pow(selected.max.sum.y / selected.min.sum.y, 1 / nbX) - 1)) / 10
                    : null)
                : (selected.min.data.y !== 0
                    ? Math.round(1000 * (Math.pow(selected.max.data.y / selected.min.data.y, 1 / nbX) - 1)) / 10
                    : null
                ),
            selectedTotal          = selected.min.data.y + selected.max.sum.y - selected.min.sum.y,
            selectedOnTotalPercent = Math.round(1000 * selectedTotal / maxSum.y) / 10;

        state.stats = {
            growingRate,
            selectedTotal,
            selectedOnTotalPercent,
            selected
        };

        return { nextProps, state };
    }

    /**
    * Update selected pixel range from mouse move in state
    *
    * Get selected pixel range from state
    *   Or using mouse dragged informations
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateSelectedPixelRangeFromMouse(options) {
        const { nextProps, state }  = options,
            { mouseDragged, width } = nextProps,
            // Minimum and maximum value for pixel range
            pixelBoundaries = {
                min: 0,
                max: width
            },
            isRangeBorder = mouseDragged.start.target.className.baseVal === 'range_border',
            borderClicked   = isRangeBorder ? mouseDragged.start.target.attributes.dataid.value : null;

        // Set pixelRange on background
        let pixelRange = [mouseDragged.start.x, mouseDragged.end.x];
        // Invert start & end
        if (!isRangeBorder && mouseDragged.start.x > mouseDragged.end.x) {
            pixelRange = [mouseDragged.end.x, mouseDragged.start.x];
        }

        // Limit selection in boundaries
        pixelRange[0] = pixelRange[0] < pixelBoundaries.min ? pixelBoundaries.min : pixelRange[0];
        pixelRange[1] = pixelRange[1] > pixelBoundaries.max ? pixelBoundaries.max : pixelRange[1];

        // Limit when move one border
        if (borderClicked === 'start') {
            pixelRange[1] = pixelRange[1] < pixelBoundaries.min ? pixelBoundaries.min : pixelRange[1];
            pixelRange[1] = pixelRange[1] > state.selectedPixelRange.max.x
                ? state.selectedPixelRange.max.x
                : pixelRange[1];
        }
        if (borderClicked === 'end') {
            pixelRange[1] = pixelRange[1] < state.selectedPixelRange.min.x
                ? state.selectedPixelRange.min.x
                : pixelRange[1];
        }

        // Set selectedPixelRange from mouse informations
        state.selectedPixelRange = {
            min: {
                x: isRangeBorder
                    ? (borderClicked === 'start' ? pixelRange[1] : state.selectedPixelRange.min.x)
                    : pixelRange[0]
            },
            max: {
                x: isRangeBorder ? (borderClicked === 'end' ? pixelRange[1] : state.selectedPixelRange.max.x) : pixelRange[1]
            }
        };

        state.isUserSelection = true;
        return { nextProps, state };
    }

    /**
    * Update selected pixel range in state
    *
    * Get selected pixel range from state
    *   Or using mouse dragged informations
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateSelectedPixelRange(options) {
        const { nextProps, state }            = options,
            { mouseDragged, canChangeSelect } = nextProps,
            { selectedRange, scales }         = state,
            { xScale }                        = scales || {};

        // On mouse drag
        if (canChangeSelect && mouseDragged !== false) {
            return LineArea.updateSelectedPixelRangeFromMouse(options);
        }

        // Not on mouse drag
        // Snap selectedPixelRange to closest data point
        state.selectedPixelRange = {
            min: {
                x: xScale && xScale(_.get(selectedRange, 'min.x'))
            },
            max: {
                x: xScale && xScale(_.get(selectedRange, 'max.x'))
            }
        };

        return { nextProps, state };
    }

    /**
    * Update scales in state
    *
    *   Store xScale and yScale in state.scales
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScales(options) {      // eslint-disable-line max-lines-per-function
        const { nextProps, state }               = options,
            {
                stats, isCumulative,
            }                                    = nextProps,
            {
                landmarkCoords, sumData,
                projectedDataByType, flatSerie,
            }                                   = state,
            { plotSize, offsets }               = landmarkCoords || {},
            { xOffset, yOffset }                = offsets || {},
            { width, height }                   = plotSize || {},
            dataToScale                         = isCumulative ? sumData : flatSerie,
            visibleData                         = dataToScale.filter((item) => !item.hidden),
            { lastEnabledData }                 = stats,
            lastEnabledDataObj                  = dataToScale.find((item) => item.x === lastEnabledData),
            lastEnabledDataIndex                = dataToScale.indexOf(lastEnabledDataObj),
            preLastEnabledData                  = lastEnabledDataIndex !== -1 ? dataToScale[lastEnabledDataIndex - 1] : null,
            projectedCurveOverflow              = preLastEnabledData && lastEnabledDataObj.y > preLastEnabledData.y
                ? (lastEnabledDataObj.y - preLastEnabledData.y) / 2
                : 0,
            // Make scale with graph size
            xScale                               = d3ScaleLinear().range([
                xOffset,
                xOffset + width,
            ]),
            yScale                               = d3ScaleLinear().range([
                yOffset,
                yOffset + height,
            ]),
            // Make flat projectedDataByType (all type)  cumul for stacked
            flatProjectedData                    = _.values(projectedDataByType).reduce(
                (accumulator, projectedValues) => {
                    projectedValues.forEach((projectedValue) => {
                        const yCumul = accumulator[projectedValue.x] || 0;
                        accumulator[projectedValue.x] = yCumul + projectedValue.y;
                    });
                    return accumulator;
                },
                {}
            ),
            maxVisibleData   = d3Max(visibleData, (d) => d.y),
            maxProjectedData = d3Max(_.values(flatProjectedData), (y) => (y || 0) + projectedCurveOverflow),
            maxData          = maxVisibleData === 0 && maxProjectedData === 0 ? 1 : _.max([maxVisibleData, maxProjectedData]);
            /**
            * "1" is a default value in case 'maxVisibleData' and 'maxProjectedData' are both equal to 0.
            * This ensures that there is always a default max value for the domain
            * And prevents the value 0 from being positioned at the center of the graph.
            */

        if (!landmarkCoords) {
            state.scales = {};
            return { nextProps, state };
        }

        // Zoom on data
        xScale.domain([d3Min(visibleData, (d) => d.x), d3Max(visibleData, (d) => d.x)]);
        yScale.domain([maxData, 0]).nice();
        if (plotSize) {
            state.scales = { xScale, yScale };
        }

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderDefs', 'render', 'renderDataPlot', 'setLandmarkCoords');

        this.graphRef  = React.createRef();

        this.state = {
            selectedRange: [],
            forceShowDots: false
        };

        this.selectedRange = null;

        // Make a uuid
        this.id = _.uniqueId('LineArea-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.updateOnMouseElement();
    }

    /**
    * Triggered when the component is updated
    *
    * @return void
    */
    componentDidUpdate() {
        const { filterCb, mouseDragged } = this.props,
            { dataIsLoaded }             = this.props,
            { selectedRange }            = this.state;

        this.updateOnMouseElement();

        // Update filter on selection change
        if (!mouseDragged && !_.isArray(selectedRange) && selectedRange !== false && selectedRange.min && selectedRange.max && (
            !this.selectedRange || (
                selectedRange.min.x !== this.selectedRange.min.x
                    || selectedRange.max.x !== this.selectedRange.max.x
            )
        )) {
            this.selectedRange = selectedRange;

            if (filterCb && dataIsLoaded) {
                filterCb({
                    start: this.selectedRange.min.x,
                    end  : this.selectedRange.max.x
                });
            }
        }
    }

    /**
    * Trigger setOnMouseElement to graph
    *
    * @return void
    */
    updateOnMouseElement() {
        const { setOnMouseElement } = this.props;

        setOnMouseElement(this.graphRef.current);
    }

    /**
    * Get selected range
    *     - from props (select)               // Default
    *
    * @params options object { nextProps, state }
    *
    * @return object { min, max }
    */
    static getSelectedRangeFromDefault(options) {
        const { nextProps, state }        = options,
            { select, filterValues }      = nextProps,
            { stats }                     = nextProps,
            {
                minX,
                maxX,
                sumData,
            }                             = state,
            { start, end }                = filterValues,
            {
                selectionStart, selectionEnd
            }                             = stats,
            // Get range from props (select) (x: min,max,last)
            minXDefaultRangeFromProps     = select !== false && !_.isUndefined(select.x)
                ? (!_.isUndefined(select.x.min)
                    ? select.x.min
                    : !_.isUndefined(select.x.last)
                        ? maxX - select.x.last
                        : null
                ) : null,
            // Get range from data (stats.last)
            minXDefaultRangeFromData      = start || selectionStart,
            maxXDefaultRangeFromData      = end || selectionEnd,
            // Set default max range from props
            maxXDefaultRangeFromProps     = select !== false
                && !_.isUndefined(select.x)
                && !_.isUndefined(select.x.max)
                ? select.x.max
                : null,
            // Set default min range
            minXDefaultRange              = !_.isNull(minXDefaultRangeFromProps)
                ? minXDefaultRangeFromProps
                : minXDefaultRangeFromData,
            maxXDefaultRange              = !_.isNull(maxXDefaultRangeFromProps)
                ? maxXDefaultRangeFromProps
                : maxXDefaultRangeFromData,
            // Set Points
            { extendMinX, extendMaxX }    = stats,
            closestOptions                = { min: _.min([minX, extendMinX]), max: _.max([maxX, extendMaxX]) },
            defaultPointMin               = LineArea.getClosestDataFromX(minXDefaultRange, sumData, closestOptions),
            defaultPointMax               = LineArea.getClosestDataFromX(maxXDefaultRange, sumData, closestOptions);

        return { min: defaultPointMin, max: defaultPointMax };
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - cumulative data
    *          - colors and gradient
    *          - scales (D3Scales object) of the landmark
    *          - plots data string (svg path)
    *          - selected ranges (in data and pixel scales)
    *          - stats
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) { // eslint-disable-line  max-lines-per-function
        const {
                content,
                showSelectTooltip,
                margin,
                tooltipHeight,
                stats,
                contentChange,
                showTrendInformation,
                trendHeight,
            } = nextProps,
            {
                landmarkCoords,
                selectedRange
            }                      = prevState,
            { plotSize }           = landmarkCoords || {},
            { height }             = plotSize || {},
            trendInformationOffset = showTrendInformation ? trendHeight / 3 : 0,
            serie       = content || [],
            // TODO react into a function ?
            marginTop   =  (showSelectTooltip ? tooltipHeight : 0),
            // Size of the chart (without landmark )
            innerHeight = nextProps.height - (2 * margin) - marginTop - trendInformationOffset,
            innerWidth  = nextProps.width  - 2 * margin,
            scenario    = height && LineArea.getScenario({ innerWidth, height });

        // No data
        if (!serie.length) {
            return {
                ...prevState,
                innerHeight,
                innerWidth,
                scenario,
            };
        }

        if (contentChange && stats && stats.disableSelection && !prevState.isUserSelection) {
            prevState.selectedRange = { min: false, max: false };
        }

        // Cascading function to update state
        const updateState = _.flow([
                LineArea.updateSerieAndSumData,             // Data serie and sumData
                LineArea.updateProjectedDataByType,         // Projected data by type
                LineArea.updateFlatSerie,                   // Flat serie (all type)
                LineArea.updateColors,                      // All colors and gradient
                LineArea.updateScales,                      // D3 scales
                LineArea.updatePlotData,                    // Plot data of linearea
                LineArea.updateStackedPlotData,             // Plot data of stacked linearea
                LineArea.updateSelectedRange,               // Selected range from prevState.selectedRange/from props.select
                LineArea.updateSelectedPixelRange,          // Selected pixel range from dragged mouse or selected range
                LineArea.updateSelectedRangeFromPixelRange, // Selected range on dragged mouse from pixel range
                LineArea.updateSelectedRangeFromPixelRangeFromExtend, // Selected range
                LineArea.updateStats,                       // Stats for labels
                LineArea.updateActualProps,                 // Actual Props
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    ...prevState,
                    selectedRange,
                    landmarkCoords,
                    marginTop,
                    trendInformationOffset,
                    innerHeight,
                    innerWidth,
                    scenario,
                }
            });

        return state;
    }

    /**
    * Get closest data set from a value on x
    *
    * @params x         number Searched x
    * @params dataSerie object Data contains x attribute
    *
    * @return object
    */
    static getClosestDataFromX(x, dataSerie, options = {}) {
        let closest = null;

        if (!_.isNumber(x)) {
            return null;
        }

        const { min, max } = options;

        _.each(dataSerie, (item) => {
            if (
                (!min || min <= item.x) && (!max || item.x <= max)
                && (_.isNull(closest) || Math.abs(x - closest.x) > Math.abs(item.x - x))
            ) {
                closest = item;
            }
        });

        return closest;
    }


    /**
     * Get the plot size from landmarkCoords
     *
     * @returns object
     */
    getPlotSize() {
        const { landmarkCoords } = this.state,
            { plotSize }        = landmarkCoords || {};

        return plotSize || {};
    }

    /**
     * Get the offsets from landmarkCoords
     *
     * @returns object
     */
    getOffsets() {
        const { landmarkCoords } = this.state,
            { offsets }          = landmarkCoords || {};

        return offsets || {};
    }


    /**
    * On item click
    *
    * @return boolean
    */
    onItemClick(item) {
        return () => {
            const { filterCb }                = this.props,
                { minX, maxX, selectedRange } = this.state,
                clickOnRange                  = !_.isUndefined(item.min),
                start                         = clickOnRange ? minX : item._x,
                end                           = clickOnRange ? maxX : item._x,
                values                        = {
                    start,
                    end
                },
                sameSelection                 = selectedRange && selectedRange.min.x === start && selectedRange.max.x === end;

            // Manage filter
            if (filterCb && (item._x || item.min) && !sameSelection) {
                // User click on range: remove the selection
                if (clickOnRange) {
                    this.setState({
                        selectedRange: false
                    });
                    filterCb(values);
                    return;
                }

                // User click on a specific line point
                this.setState({
                    selectedRange: {
                        min: { x: start },
                        max: { x: end }
                    }
                });

                filterCb(values);
            }

            return true;
        };
    }

    /**
    * Render tooltip content
    *
    * @return html
    */
    renderTooltipContent() {
        const { itemUnit }                = this.props,
            { selectedRange, maxX, stats } = this.state;

        return !selectedRange || (_.isArray(selectedRange) && selectedRange.length === 0) ? null : (
            selectedRange.min.x !== selectedRange.max.x
                ? (
                    <div className="stats-block">
                        {selectedRange.max.x >= maxX
                            ? (
                                <div>
                                    <span>since</span>
                                    <span className="date">
                                        {selectedRange.min.x}
                                    </span>
                                </div>
                            )
                            : (
                                <div>
                                    <span>from</span>
                                    <span className="date">
                                        {selectedRange.min.x}
                                    </span>
                                    <span>to</span>
                                    <span className="date">
                                        {selectedRange.max.x}
                                    </span>
                                </div>
                            )}
                        <div className="separator" />
                        <div className="patented">
                            <span className="value">{stats.selectedTotal}</span>
                            <span>{`new ${itemUnit}`}</span>
                        </div>
                        <div className="separator" />
                        {this.renderGrowingRate()}
                    </div>
                ) : ''
        );
    }

    /**
    * Render growingRate in tooltip
    *
    * @return html
    */
    renderGrowingRate() {
        const { stats } = this.state;

        return _.isNumber(stats.growingRate)
            ? (
                <div className="growing-rate">
                    <span className="percent">{`${stats.growingRate}%`}</span>
                    <span>growth rate</span>
                </div>
            ) : '';
    }

    /**
    * Render selected range tooltip
    *
    * @return html
    */
    renderSelectTooltip() {
        const {
                selectedPixelRange,
            }                     = this.state,
            { tooltipHeight }     = this.props,
            minX  = !selectedPixelRange ? 0 : selectedPixelRange.min.x || 0,
            maxX  = !selectedPixelRange ? 0 : selectedPixelRange.max.x || 0,
            { showSelectTooltip } = this.props,
            content               = this.renderTooltipContent(),
            position = {
                top   : tooltipHeight + 10,
                left  : minX,
                width : maxX - minX,
                height: 0,
            };

        if (!showSelectTooltip) {
            return null;
        }
        // TODO: make unique id for linearea-tooltip-container

        return (
            <div>
                <div id="linearea-tooltip-container" style={{ height: tooltipHeight }} />
                <Popover
                    overlayClassName="lineAreaPopover"
                    overlayStyle={{top: 1}}
                    overlayInnerStyle={{padding: '5px 10px', marginLeft: 10, marginRight: 40}}
                    getPopupContainer={() => document.getElementById('linearea-tooltip-container')}
                    content={content}
                    open={!!content}
                    arrow={false}
                    zIndex={2}
                >
                    <div
                        className="tooltip-anchor"
                        style={position}
                    />
                </Popover>
            </div>
        );
    }

    /**
    * Render ranges lines
    *
    * @params range object
    *
    * @return html
    */
    renderSelectedRangesLines(range) {
        const {
                canChangeSelect
            }                     = this.props,
            {
                colors,
            }                     = this.state,
            { height }            = this.getPlotSize(),
            { yOffset }           = this.getOffsets();

        if (!canChangeSelect) {
            return null;
        }

        return (
            <g key={`rangeLine${range.value.x}`}>
                <line
                    vectorEffect="non-scaling-stroke"
                    shapeRendering="crispEdges"
                    x1={range.value.x}
                    x2={range.value.x}
                    y1={yOffset}
                    y2={height + yOffset}
                    stroke={colors.rangeBorder}
                    strokeDasharray="5,5"
                />
                <line
                    className="range_border"
                    strokeWidth={handlerWidth}
                    x1={range.value.x}
                    x2={range.value.x}
                    y1={yOffset}
                    y2={height + yOffset}
                    stroke={colors.rangeBorder}
                    dataid={range.id}
                    strokeDasharray="5,5"
                />
            </g>
        );
    }

    /**
    * Render ranges dots
    *
    * @params range object
    *
    * @return html
    */
    renderSelectedRangesDots(range) {
        const {
                mouseDragged, canChangeSelect
            }           = this.props,
            {
                colors,
            }           = this.state,
            { yOffset } = this.getOffsets();

        if (!canChangeSelect || mouseDragged !== false) {
            return null;
        }

        return (
            <g key={`rangeDot${range.value.x}`}>
                <circle
                    className="dot_shadow"
                    style={{ fill: `url(#${this.id}-dot-shadow-gradient)` }}
                    r="12"
                    cx={range.value.x}
                    cy={yOffset}
                    stroke="none"
                    strokeWidth="1.5"
                />
                <circle
                    className="dot"
                    r="2.5"
                    cx={range.value.x}
                    cy={yOffset}
                    fill={colors.rangeDotCenter}
                    stroke={colors.rangeDot}
                    strokeWidth="1.5"
                />
            </g>
        );
    }

    /**
    * Render selected ranges
    *
    * @return html
    */
    renderSelectedRanges() {
        const { selectedPixelRange } = this.state,
            min = !selectedPixelRange ? { x: 0, y: 0 } : selectedPixelRange.min,
            max = !selectedPixelRange ? { x: 0, y: 0 } :  selectedPixelRange.max,
            ranges                   = min.x !== max.x
                ? [{ id: 'start', value: min }, { id: 'end', value: max }]
                : [{ id: 'start', value: min }];

        return (
            <g>
                {ranges.map(range => range?.value?.x && (
                    <g key={`range-${range.id}`}>

                        {/* Render selected ranges lines */}
                        {this.renderSelectedRangesLines(range)}

                        {/* Render selected ranges dots */}
                        {this.renderSelectedRangesDots(range)}
                    </g>
                ))}
            </g>
        );
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() { // eslint-disable-line  max-lines-per-function
        const { selectedPixelRange, colors } = this.state,
            { scales }                       = this.state,
            { width, height }                = this.getPlotSize(),
            { selectedColor, stacksColors }  = this.props,
            { showStack, gradientByBlock }   = this.props,
            { data }                         = this.props,
            { stats }                        = data || {},
            { lastEnabledData }              = stats || {},
            { xScale, yScale }               = scales || {},
            yRange                           = yScale?.range() || [0, 0],
            minX                             = !selectedPixelRange ? 0 : selectedPixelRange.min.x || 0,
            maxX                             = !selectedPixelRange ? 0 : selectedPixelRange.max.x || 0,

            gradientTypes                    = stacksColors && showStack ? _.keys({ ...stacksColors, '': null }) : [''],
            gradientProps = {
                gradientUnits: gradientByBlock ? null : 'userSpaceOnUse',
                x1           : 0,
                x2           : 0,
                y1           : gradientByBlock ? '90%' : height,
                y2           : gradientByBlock ? '10%' : 0,
            };

        return !colors ? null : (
            <defs>
                { gradientTypes.map(stackKey => ( // eslint-disable-line  max-lines-per-function
                    <g key={`${this.id}${stackKey}`}>
                        {/* Mask of the selected area */}
                        <clipPath id={`${this.id}${stackKey}-cut-off`}>
                            <rect
                                x={minX}
                                y={yRange[0]}
                                width={maxX - minX}
                                height={yRange[1] - yRange[0]}
                            />
                        </clipPath>
                        {/* Mask of the curve */}
                        <clipPath id={`${this.id}${stackKey}-cut-curve`}>
                            <rect
                                x={xScale && xScale(lastEnabledData)}
                                y={yRange[0]}
                                width={width}
                                height={yRange[1] - yRange[0]}
                            />
                        </clipPath>

                        {/* Gradient of the area */}
                        <linearGradient {...gradientProps} id={`${this.id}${stackKey}-gradient`}>
                            <stop offset="0%" stopColor={colors[`${stackKey}area`][0]} />
                            <stop offset="100%" stopColor={colors[`${stackKey}area`][1]} />
                        </linearGradient>

                        {/* Gradient of the area disabled */}
                        <linearGradient {...gradientProps} id={`${this.id}${stackKey}-disabled`}>
                            <stop
                                offset="0%"
                                stopColor={
                                    (c => { const dc = d3Hsl(c); dc.s = 0; return dc.hex(); })(colors[`${stackKey}area`][0])
                                }
                            />
                            <stop
                                offset="100%"
                                stopColor={
                                    (c => { const dc = d3Hsl(c); dc.s = 0; return dc.hex(); })(colors[`${stackKey}area`][1])
                                }
                            />
                        </linearGradient>

                        {/* Gradient of the selected area */}
                        <linearGradient
                            {...gradientProps}
                            id={`${this.id}${stackKey}-select-gradient`}
                        >
                            <stop offset="0%" stopColor={selectedColor || colors[`${stackKey}selectedArea`][0]} />
                            <stop offset="100%" stopColor={selectedColor || colors[`${stackKey}selectedArea`][1]} />
                        </linearGradient>

                        {/* Gradient of the dot shadow */}
                        <radialGradient id={`${this.id}${stackKey}-dot-shadow-gradient`}>
                            <stop offset="0%" stopColor={selectedColor || colors[`${stackKey}selectedArea`][0]}
                                stopOpacity="1"
                            />
                            <stop
                                offset="100%"
                                stopColor={selectedColor || colors[`${stackKey}selectedArea`][0]}
                                stopOpacity="0"
                            />
                        </radialGradient>
                    </g>
                ))}
            </defs>
        );
    }

    /**
     * Set the size of the plotter container
     *
     * @param {object} size
     */
    setLandmarkCoords(newLandmarkCoords) {
        const { landmarkCoords } = this.state;
        if (JSON.stringify(landmarkCoords) !== JSON.stringify(newLandmarkCoords)) {
            this.setState({ landmarkCoords: newLandmarkCoords });
        }
    }

    /**
    * Render the landmark
    *
    * @return html
    */
    renderLandmark() {
        const {
                xLabel, fontSize,
                yLabel, noContent, content,
                showLandmark, isCapture
            }                  = this.props,
            {
                innerHeight,
                innerWidth,
                scales,
                selectedRange,
                colors,
            }                  = this.state,
            { xScale, yScale } = scales || {},
            mustRenderLandmark = showLandmark || isCapture;

        return (
            <Landmark
                key={JSON.stringify(content)}
                height={innerHeight}
                width={innerWidth}
                xScale={xScale}
                yScale={yScale}
                xLabel={xLabel}
                yLabel={yLabel}
                selectedRange={selectedRange}
                color={colors.landmark}
                noContent={noContent}
                setLandmarkCoordsCb={this.setLandmarkCoords}
                fontSize={fontSize}
                hideLandmark={!mustRenderLandmark}
            />
        );
    }

    /**
    * Render the minimun and maximum value of X
    *
    * @return html
    */
    renderMinMaxX() {
        const {
                innerHeight, innerWidth,
                colors, minX, maxX
            } = this.state,
            {
                fontSize,
            } = this.props,
            y = innerHeight - fontSize;

        return (
            <g>
                <text
                    transform={`translate( 0 ${y})`}
                    fontSize={fontSize}
                    dominantBaseline="middle"
                    style={{ fill: colors.text }}
                >
                    {minX}
                </text>
                <text
                    transform={`translate( ${innerWidth} ${y})`}
                    fontSize={fontSize}
                    dominantBaseline="middle"
                    textAnchor="end"
                    style={{ fill: colors.text }}
                >
                    {maxX}
                </text>

            </g>
        );
    }


    /**
    * Get the projected data curve
    *
    * @param {object} groupedProjectedData
    * @param {array}  typeData
    * @param {string} typeKey
    *
    * @returns {object}
    */
    getProjectedCurve(groupedProjectedData, typeData, typeKey) {
        // No project data
        if (!groupedProjectedData.true) {
            return {};
        }

        const { valueCurve } = this.state,
            enabledData      = typeData.filter(data => data.enabled),
            projectedData    = (groupedProjectedData.true || []).filter((item) => item.type === typeKey),
            lastEnabled      = enabledData.slice(0, 3).reverse(),
            data             = lastEnabled ? lastEnabled.concat(projectedData) : projectedData,
            projectedLine    = valueCurve(data);

        return {
            projectedLine,
            enabledData,
            projectedData,
        };
    }


    /**
    * Render the data plot
    *
    * @return html
    */
    renderDataPlot(pointData, progress) { //eslint-disable-line
        const { projectedTitle, showDots }            = this.props,
            { colors, forceShowDots, selectedRange }  = this.state,
            { valueCurve, valueAreaCurve, scales }    = this.state,
            { yScale }                                = scales,
            hasSelection                              = selectedRange?.min && selectedRange?.max,
            pointDataOrdered                          = pointData.sort((a, b) => a.x - b.x).filter(item => !item.hidden),
            groupedProjectedData                      = _.groupBy(pointDataOrdered, (d) => d.projected),
            truePointDataOrdered                      = _.filter(pointDataOrdered, item => item.falseData !== true),
            enabledData                               = (groupedProjectedData.false || []).filter((item) => !item.hidden) || [],
            fillDisabled                              = `url("#${this.id}-disabled")`,
            // Reverse to start loop by the right of the graph
            enabledDataByType                         = _.groupBy(enabledData.reverse(), 'type');

        return (
            <g
                className="dataPlot"
                key={JSON.stringify(pointData)}
                style={{ opacity: pointData[0].opacity }}
            >

                {/* Render Projected Areas */}
                {_.map(enabledDataByType, (typeData, typeKey) => { // eslint-disable-line  max-lines-per-function
                    const stackClass      = typeKey === 'undefined' ? '' : typeKey,
                        {
                            projectedLine,
                            enabledData,
                            projectedData,
                        }                 = this.getProjectedCurve(groupedProjectedData, typeData, typeKey),
                        projectedAreaData = projectedLine
                            ? `${projectedLine} V ${yScale(0)} H ${_.first(projectedData)?.x}
                                V ${yScale(0)} H ${_.first(enabledData)?.x}`
                            : '';

                    return (
                        <g key={`${this.id}${stackClass}-p`}>
                            <g className="projectedArea">
                                <path
                                    d={projectedAreaData}
                                    style={{ fill: fillDisabled }}
                                    clipPath={`url(#${this.id}-cut-curve)`}
                                />
                                <title>
                                    {projectedTitle}
                                </title>
                            </g>
                        </g>
                    );
                })};

                {/* Render true data areas + lines */}
                {_.map(enabledDataByType, (typeData, typeKey) => {
                    const stackClass = typeKey === 'undefined' ? '' : typeKey,
                        // _.groupBy create string undefined...
                        fill         = `url("#${this.id}${stackClass}-gradient")`,
                        fillSelect   = `url("#${this.id}${stackClass}-select-gradient")`;

                    return (
                        <g key={`${this.id}${stackClass}`}>
                            <path d={valueAreaCurve(typeData)} opacity="0.4"
                                style={{ fill }}
                            />
                            { /* TODO select with color not opacity ! */ }
                            <path
                                d={valueAreaCurve(typeData)}
                                style={{
                                    fill   : fillSelect,
                                    opacity: 0.4,
                                }}
                                clipPath={hasSelection ? `url(#${this.id}-cut-off)` : undefined}
                            />

                            <path
                                d={valueCurve(typeData)}
                                strokeWidth="1"
                                stroke={colors[`${stackClass}curve`]}
                                strokeOpacity={0.4}
                                fill="none"
                            />
                            <path
                                d={valueCurve(typeData)}
                                strokeWidth="2"
                                stroke={colors[`${stackClass}curve`]}
                                strokeOpacity={0.4}
                                fill="none"
                                clipPath={`url(#${this.id}-cut-off)`}
                            />

                        </g>
                    );
                })}

                {/* Render Projected lines */}
                {_.map(enabledDataByType, (typeData, typeKey) => {
                    const stackClass      = typeKey === 'undefined' ? '' : typeKey,
                        { projectedLine } = this.getProjectedCurve(groupedProjectedData, typeData, typeKey);

                    return (
                        <g key={`${this.id}${stackClass}-p`}>
                            <path
                                d={projectedLine}
                                strokeWidth="0.5"
                                strokeDasharray="5,5"
                                stroke={colors.curve}
                                fill="none"
                                clipPath={`url(#${this.id}-cut-curve)`}
                            />
                        </g>
                    );
                })};

                {/* Render selected range over lines and under data points */}
                {this.renderSelectedRanges()}

                {/* Render circle data point */}
                {!showDots && !forceShowDots ? null : _.map(
                    truePointDataOrdered,
                    (d, i) => this.renderDataPoint(d, i)
                )}
            </g>
        );
    }

    /**
    * Render values
    *
    * @param {array} pointDataOrdered
    *
    * @returns {JSX}
    */
    renderDataPointValue(pointDataOrdered) {
        const { color, showLandmark }        = this.props,
            { showValue, showDots }          = this.props,
            { tooltipHeight, fontSize }      = this.props,
            { showSelectTooltip, isCapture } = this.props,
            { isCumulative, margin, width }  = this.props,
            { sumData }                      = this.state,
            lastPointData                    = _.last(pointDataOrdered),
            ratio                            = !isCapture ? width / window.innerWidth : 0.9,
            showValues                       = showValue && showDots && showLandmark && ratio > 0.70,
            yOffset                          = showSelectTooltip ? tooltipHeight : 0,
            el                               = this.graphRef.current,
            parentEl                         = el?.parentNode,
            itemValues                       = parentEl?.parentNode.querySelectorAll('.item-values .item-value'),
            lastItemValue                    = _.last(itemValues);

        return !showValues ? null : (
            <div key="point-values" className="item-values">
                {pointDataOrdered.map((pointData, index) => {
                    const lastPointOffset      = lastPointData === pointData ? lastItemValue?.offsetWidth : 0,
                        { projected, enabled } = pointData,
                        { falseData }          = pointData,
                        value                  = isCumulative ? sumData[index].y : pointData.value,
                        formatedValue          = formatInternationalNumber(value);

                    return projected || !enabled || falseData ? null : (
                        <div key={`point-value-${pointData.id}`} className="item-value"
                            style={{
                                top : pointData.y + yOffset + margin - 25,
                                left: pointData.x - lastPointOffset + margin,
                            }}
                        >
                            <span
                                style={{
                                    color,
                                    fontSize: `${fontSize * ratio}px`,
                                }}
                            >
                                {formatedValue}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Render Dot circle od a datum
     */
    renderCircle(d) {
        const { filterCb }           = this.props,
            {colors, selectedRange } = this.state,
            clickable                = filterCb && !d.projected && d.value > 0,
            type                     = d.type === 'undefined' ? '' : d.type,
            color                    = colors[`${type || ''}selectedArea`][0],
            fillColor                = selectedRange.min && selectedRange.min.x <= d._x && selectedRange.max.x >= d._x
                ? colors[`${type || ''}curve`]
                : colors[`${type || ''}area`][1],
            fillOpacity              = d.projected ? 0.4 : (d.enabled ? 0.8 : 0.5);

        return (
            <circle
                r={5}
                stroke={d.projected ? fillColor : color}
                strokeWidth={d.projected ? 2 : 1}
                strokeOpacity={0.8}
                fill={d.projected ? color : fillColor}
                fillOpacity={fillOpacity}
                onClick={clickable ? this.onItemClick(d) : null}
                style={{ cursor: clickable? 'pointer' : null }}
            />
        );
    }

    /**
    * Render the Trend Information
    *
    * @return html
    */
    renderDataPoint(d, i) {
        const {
                projectedTitle, showStack, unconsolidatedTitle
            }                   = this.props,
            informationUnconsolidated = d.enabled
                ? ''
                : unconsolidatedTitle;

        return (
            <g
                key={`point-${d.id}-${i}`}
                className="dot-value"
                transform={`translate(${d.x} ${d.y})`}
            >
                {d.value > 0 || !showStack ? (
                    <Popover
                        content={(
                            <div className="tooltip-value">
                                <div
                                    className="sentence"
                                    dangerouslySetInnerHTML={{ __html: d.valueSentence }}
                                />
                                <div className="information">
                                    {d.projected ? projectedTitle : informationUnconsolidated}
                                </div>
                            </div>
                        )}
                        placement="top"
                        trigger="hover"
                    >
                        {this.renderCircle(d)}
                    </Popover>
                ) : null}
            </g>
        );
    }

    /**
    * Render the Trend Information
    *
    * @return html
    */
    renderTrendInformation() {
        const {
                data,
                xLabel,
                showTrendInformation,
                trendHeight,
                fontSize,
                rateFontSize,
            } = this.props,
            {
                selectedRange,
                selectedPixelRange,
                innerWidth,
            } = this.state;

        return showTrendInformation && (
            <TrendInformation
                data={data}
                height={trendHeight}
                width={innerWidth}
                xLabel={xLabel}
                fontSize={fontSize}
                rateFontSize={rateFontSize}
                selectedRange={selectedRange}
                selectedPixelRange={selectedPixelRange}
            />
        );
    }



    /**
    *  Rendering a skeleton for the chart.
    *
    * @return html
    */
    renderSkeleton() {
        const {
                showSelectTooltip, skeletonGradient
            }                       = this.props,
            showSelectTooltipClass  = showSelectTooltip ? 'select-tooltip' : '',
            loadClass               = `LineArea skeleton ${showSelectTooltipClass}`,
            style                   = { backgroundImage: skeletonGradient };

        return (
            <div className={loadClass} style={style} />
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() { // eslint-disable-line max-lines-per-function
        const {
                showLandmark,
                skeleton, onMouseMove,
                onMouseDown, onMouseUp,
                margin, canChangeSelect, dataKeysChange, noContent,
                color, isCapture,
            }                       = this.props,
            {
                pointData, scenario, innerHeight, innerWidth,
                trendInformationOffset
            } = this.state,
            showGraph               = pointData ? pointData.some((item) => !item.projected && !item.hidden) : false,
            mustRenderLandmark      = showLandmark || isCapture,
            style                   = {margin, height: innerHeight},
            translate              = `translate( ${0}, ${trendInformationOffset} )`;

        if (skeleton) {
            return this.renderSkeleton();
        }

        // A transform={`translate( 0 ${yTranslate} )`}
        return showGraph ? (
            <div className={`${canChangeSelect ? 'interactive ' : ''}LineArea loaded`} style={style}>
                {this.renderSelectTooltip()}
                <svg
                    ref={this.graphRef}
                    width={innerWidth}
                    height={innerHeight}
                    onMouseMove={onMouseMove}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    transform={translate}
                >
                    {this.renderLandmark()}
                    <g>
                        {this.renderDefs()}
                        <Animation
                            data={pointData} render={this.renderDataPlot}
                            scenario={scenario} scene={noContent ? 'loading' : (dataKeysChange ? 'contentChange' : 'general')}
                        />
                        {!mustRenderLandmark && this.renderMinMaxX()}
                    </g>
                </svg>
                {this.renderTrendInformation()}

                {/* Render data point value */}
                {this.renderDataPointValue(pointData)}
            </div>
        ) : (
            <NoData
                color={color}
            />
        );
    }

}

/**
 * Props type
 */
LineArea.propTypes = {
    canChangeSelect     : PropTypes.bool,
    color               : PropTypes.string,
    content             : PropTypes.any,
    contentChange       : PropTypes.any,
    dataIsLoaded        : PropTypes.bool,
    dataKeysChange      : PropTypes.bool,
    isCumulative        : PropTypes.bool,
    data                : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    filterCb            : PropTypes.func,
    filterValues        : PropTypes.any,
    fontSize            : PropTypes.number,
    gradientByBlock     : PropTypes.bool,
    height              : PropTypes.number.isRequired,
    interpolation       : PropTypes.string,
    itemUnit            : PropTypes.string,
    margin              : PropTypes.number,
    mouseDragged        : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    noContent           : PropTypes.any,
    onMouseDown         : PropTypes.func.isRequired,
    onMouseMove         : PropTypes.func.isRequired,
    onMouseUp           : PropTypes.func.isRequired,
    onSelectionChange   : PropTypes.func,
    preposition         : PropTypes.string,
    projectedBaseLength : PropTypes.number,
    projectedMin        : PropTypes.number,
    projectedTitle      : PropTypes.string,
    unconsolidatedTitle : PropTypes.string,
    rateFontSize        : PropTypes.number,
    select              : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    selectedColor       : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    setOnMouseElement   : PropTypes.func.isRequired,
    showDots            : PropTypes.bool,
    showLandmark        : PropTypes.bool,
    showProjectedValues : PropTypes.bool,
    showSelectTooltip   : PropTypes.bool,
    showStack           : PropTypes.bool,
    showTrendInformation: PropTypes.bool,
    showValue           : PropTypes.bool,
    skeleton            : PropTypes.bool,
    skeletonGradient    : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    stacksColors        : PropTypes.any,
    stats               : PropTypes.shape(),
    trendHeight         : PropTypes.number,
    tooltipHeight       : PropTypes.number,
    width               : PropTypes.number.isRequired,
    xLabel              : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    yLabel              : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isCapture           : PropTypes.bool,
};

/**
 * Default props value
 */
LineArea.defaultProps = {
    onSelectionChange   : _.noop,
    showSelectTooltip   : false,
    showTrendInformation: false,
    tooltipHeight       : 50,
    trendHeight         : 150,
    showLandmark        : false,
    showStack           : false, // Fix multiple lines!! with no stack
    canChangeSelect     : false,
    skeleton            : false,
    itemUnit            : '',
    xLabel              : false,
    yLabel              : false,
    fontSize            : 10,
    rateFontSize        : 14,
    margin              : 0,
    color               : 'var(--insight-color)',
    selectedColor       : false,
    skeletonGradient    : false,
    select              : false,
    showProjectedValues : false,
    projectedBaseLength : 3,
    projectedMin        : 0,
    projectedTitle      : '',
    unconsolidatedTitle : '',
    isCumulative        : false,
    showDots            : false,
    preposition         : 'in',
    gradientByBlock     : true,
    interpolation       : 'curveX',
    dataIsLoaded        : false,
    isCapture           : false,
    showValue           : true,
    // Interpolation       : 'stepAfter',
    // Interpolation       : 'curveLinear',
    // Interpolation       : 'curveCat',
    // Interpolation       : 'curveCardinal',
};

export default LineArea;

