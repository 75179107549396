import React                          from 'react';
import { connect }                    from 'react-redux';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';

import { Icon, Highlight }            from 'helpers';
import { capitalize }                 from 'utils/text';

import { getUserViewItemFromModel }   from 'store/actions/userView';

// Import CSS
import './assets/document.less';

/**
* Render the header content of the card
*
* @return JSX
*/
const DocumentHeader = (props) => {
    const {
            entity, entities, getUserViewItemFromModel
        }                = props,
        { type }         = entity,
        streamEntity   = getUserViewItemFromModel(entity, 'bookmark'),
        entityDefinition = entities.find((model) => model.id === entity.type) || {};

    return (
        <div className="header">
            <div className="heading">
                <div className="heading-left" />
                <div className="logo">
                    <Icon
                        className="document-logo"
                        id={type}
                        folder="/entities/"
                        height={81}
                        borderSize={17}
                        borderColor="#ffffff"
                        discShaped
                    />
                    {streamEntity && (
                        <Icon
                            className="bookmark"
                            type="bookmark"
                            width={22}
                            color="#FFB400"
                            discShaped
                        />
                    )}
                </div>
                <div className="content">
                    <Highlight TagName="h2" title>
                        {entity.label}
                    </Highlight>

                    <div className="document-type">
                        {entityDefinition ? capitalize(entityDefinition.label) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

DocumentHeader.propTypes = {
    entities: ImmutablePropTypes.list,
    entity  : PropTypes.shape({
        id   : PropTypes.string,
        label: PropTypes.string,
        type : PropTypes.string
    }),
    getUserViewItemFromModel: PropTypes.func,
};

DocumentHeader.defaultProps = {
    entity: null
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
});

export default connect(mapStateToProps, {
    getUserViewItemFromModel
})(DocumentHeader);
