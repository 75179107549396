import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import _                     from 'lodash';
import { connect }           from 'react-redux';
import { Skeleton }          from 'antd';
import { learn }             from 'store/actions/knowledge';
import { Element, Row, Col } from 'helpers';
import { pluralize }         from 'utils/text';

import './assets/actors.less';

/**
 * Actors class
 *
 * Display all actors of a document according to the type of the document
 */
class Actors extends Component {

    /**
    * Constructor : Initialize the state
    */
    constructor(props) {
        super(props);

        this.state = {
            elements      : null,
            expertDocRoles: null,
            orgDocRoles   : null
        };

        this.sections = null;
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['elements', 'org-doc-roles', 'expert-doc-roles'])
            .then(this.setState.bind(this));
    }

    /**
    * Parse all the authorities of persons combining with the entity's orgunits in one array.
    *
    * @return array
    */
    getAllOrgunits() {
        const { entity }  = this.props,
            { authority } = entity,
            allOrgunits   = _.filter(authority, { type: 'orgunit' }),
            allPersons    = _.filter(authority, { type: 'expert' });

        _.each(allPersons, (person) => {
            if (!person.authority) { return; }

            _.each(person.authority, (orgunit) => {
                allOrgunits.push(orgunit);
            });
        });

        return _.uniqBy(allOrgunits, 'id');
    }

    /**
    * Parse the autorities of the entities, filtering only the persons.
    *
    * @return array
    */
    getAllPersons() {
        const { entity } = this.props,
            { authority } = entity;

        return _.filter(authority, { type: 'expert' });
    }

    /**
    * Find all the sections to render.
    *
    * @return Array
    */
    getSectionsToRender() {
        if (this.sections) {
            return this.sections;
        }

        const { entity }                    = this.props,
            { authority }                   = entity,
            { expertDocRoles, orgDocRoles } = this.state;

        this.sections = {};

        // Find all sections ids
        const orgunitSectionsIds = _.uniq(_.chain(this.getAllOrgunits()).map('role').value());
        const expertSectionsIds  = _.uniq(_.chain(authority).filter({ type: 'expert' }).map('role').value());

        // Deduce the content
        this.sections.expert  = _.filter(expertDocRoles.toArray(), (role) => (expertSectionsIds.indexOf(role.id) > -1));
        this.sections.orgunit = _.filter(orgDocRoles.toArray(), (role) => (orgunitSectionsIds.indexOf(role.id) > -1));

        // Manage un-named affiliations
        if (orgunitSectionsIds.indexOf(null) !== -1) {
            this.sections.orgunit.push({ id: null, label: 'Affiliations' });
        }

        return this.sections;
    }

    /**
    * Render a section with orgunits
    *
    * @return JSX
    */
    renderTypedSection(section, type) {
        const {
                entity, onClick, registerCallbacks
            } = this.props,
            {
                elements
            } = this.state,
            filters               = { role: section.id, type },
            { label }             = section,
            sectionLabel          = pluralize(label),
            element               = elements.find((el) => el.category === 'Area/Entity/Authority');

        return (
            <Row key={section.id || type} label={sectionLabel}
                className={`${type === 'orgunit' ? 'affiliations' : 'authors'} entities`}
            >
                <Element
                    element={element}
                    model={entity}
                    parameters={{
                        uri          : `/${entity.type}s/${entity.id}`,
                        label        : sectionLabel,
                        attributePath: section.id ? 'authority' : 'expertsAffiliations',
                        filters
                    }}
                    onClick={onClick}
                    registerCallbacks={registerCallbacks}
                />
            </Row>
        );
    }

    /**
    * Render the sections according to the document type.
    *
    * @return JSX
    */
    renderSections() {
        const allSections = this.getSectionsToRender();

        return _.map(allSections, (sections, key) => {
            return (
                <Col key={key}>
                    {
                        _.map(
                            sections,
                            (section) => this.renderTypedSection(section, key === 'orgunit' ? 'orgunit' : 'expert'))
                    }
                </Col>
            );
        });
    }

    /**
    * Render the actors according to the document type
    *
    * @return JSX
    */
    render() {
        const { expertDocRoles, orgDocRoles } = this.state;

        return (
            <Col {...this.props}  className="actors">
                {
                    !expertDocRoles || !orgDocRoles
                        ? <Skeleton active />
                        : this.renderSections()
                }
            </Col>
        );
    }

}

Actors.propTypes = {
    className: PropTypes.string,
    entity   : PropTypes.shape({
        authority: PropTypes.arrayOf(PropTypes.shape({})),
        id       : PropTypes.string,
        type     : PropTypes.string
    }).isRequired,
    learnKnowledge   : PropTypes.func,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
};

Actors.defaultProps = {
    className: 'default',
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Actors);

