/** Lazy load for infinite scroll
 *
 * @return Component
*/
import React,
{
    Component,
    createContext,
}                    from 'react';
import _             from 'lodash';
import PropTypes     from 'prop-types';
import { CssLoader } from 'helpers';


/**
 * Class LazyLoad
 *
 */
class LazyLoad extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = { loading: false, dataFinished: false };

        _.bindAll(this, 'updateLazyLoading', 'lazyLoadListData', 'nextCbFinished', 'onChildrenMount');

        this.ref = React.createRef();
    }

    /**
     * Get node with the scroller
     */
    getScrollNode() {
        const { getScrollSelectors } = this.props,
            { scrollSelector }       = getScrollSelectors();

        return scrollSelector ? document.querySelector(scrollSelector) : window;
    }

    /**
    *  Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const scrollNode =  this.getScrollNode();

        // Make sure that the user is not in the middle of the page at startup
        scrollNode?.scrollTo(0, 0);
        scrollNode?.addEventListener('scroll', this.updateLazyLoading);
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        const scrollNode =  this.getScrollNode();
        scrollNode && scrollNode.removeEventListener('scroll', this.updateLazyLoading);
    }


    /**
    * Triggered when the component is updated
    *
    * @return void
    */
    componentDidUpdate() {
        this.updateLazyLoading();
    }


    /**
    * Triggered when the user scroll the window.scrollTo
    *
    * @return void
    */
    updateLazyLoading() {
        clearTimeout(this.scrollTimeout);

        // Prevent multi-trigger
        this.scrollTimeout = setTimeout(() => {
            this.lazyLoadListData();
        }, 200);
    }

    /**
     * Loading finished
     */
    nextCbFinished(dataFinished, dataIsLoaded) {
        this.setState({
            dataFinished,
            dataIsLoaded,
            loading: false,
        });
    }

    /**
    * Check if lazy load is needed, then trigger the next items loading
    *
    * @return void
    */
    lazyLoadListData() {
        const {
                loadNextCb, getScrollSelectors,
                pixelDetect, pagination
            }                          = this.props,
            {
                contentSelector, scrollSelector
            }                          = getScrollSelectors(),
            { dataFinished }           = this.state,
            scrollElement = scrollSelector
                ? this.ref?.current?.querySelector(scrollSelector) || document.querySelector(scrollSelector)
                : window,
            { scrollY, scrollTop, innerHeight, clientHeight }  = scrollElement || {},
            verticalScroll             = scrollY || scrollTop || 0,
            scrollElementHeight        = innerHeight || clientHeight,
            domElement                 = this.ref?.current?.closest(contentSelector)
                || this.ref?.current?.querySelector(contentSelector),
            elementHeight              = domElement?.scrollHeight || domElement?.clientHeight; // Chrome hack.

        if (
            domElement
            && elementHeight > 0
            && scrollElementHeight  // No zero scroll height
            && verticalScroll + scrollElementHeight > elementHeight - pixelDetect
            && loadNextCb
            && (!dataFinished || (
                pagination && pagination.total !== 0 && pagination.limit?.max < pagination.total
            ))
        ) {
            this.setState({ loading: true, dataIsLoaded: false});
            requestAnimationFrame(() => setTimeout(() => requestAnimationFrame(() => loadNextCb(this.nextCbFinished)), 100));
        }
    }

    /**
    * On children Mount
    */
    onChildrenMount() {
        const scrollNode =  this.getScrollNode();

        scrollNode?.removeEventListener('scroll', this.updateLazyLoading);
        scrollNode?.addEventListener('scroll', this.updateLazyLoading);

        this.updateLazyLoading();
    }

    /**
     * Render
     */
    render() {
        const { children } = this.props,
            {
                loading,
                dataIsLoaded,
                dataFinished
            }               = this.state;

        return (
            <div ref={this.ref} className="lazy-load">
                <LazyloadContext.Provider value={{ onComponentMount: this.onChildrenMount }}>
                    {children}
                </LazyloadContext.Provider>

                <div className="lazy-loader">
                    {!dataFinished && (loading || !dataIsLoaded) && (
                        <CssLoader type="ring" size={16}
                            thickness={1} color="#808080A0"
                        />
                    )}
                </div>
            </div>
        );
    }

}


LazyLoad.propTypes = {
    getScrollSelectors: PropTypes.func,
    loadNextCb        : PropTypes.func,
    pixelDetect       : PropTypes.number,
    pagination        : PropTypes.object,
    children          : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),

};

LazyLoad.defaultProps = {
    pixelDetect: 300,
    loadNextCb : _.noop,
    pagination : {},
};

export const LazyloadContext = createContext({
    onComponentMount: () => {}
});

export default LazyLoad;
