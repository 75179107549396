import React, { Component }          from 'react';
import PropTypes                     from 'prop-types';
import _                             from 'lodash';
import { getSobjImage }              from 'utils/text';
import { Carousel, Skeleton, Modal } from 'antd';
import {
    Icon, CssLoader,
    IButton, Highlight, Row
}                                    from 'helpers';
import './assets/images.less';

/**
* Display patent carousel from patent entity
*
* @return JSX
*/
class Images extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onImageClick', 'onClose', 'previousImage', 'nextImage',
            'imageLoaded', 'rotateRight', 'rotateLeft', 'handleKeyPress'
        );

        this.state = {
            imageOpened  : null,
            imageLoaded  : false,
            rotations    : {},
            lastRotations: {},
        };

        this.carouselRef       = React.createRef();
        this.imageCenterRef = React.createRef();
    }

    /**
     * Component did update
     *
     * @param {object} prevProps
     * @param {object} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const { imageOpened } = prevState;

        // Force focus to have OnKeyDown event always listening
        if (!_.isNull(imageOpened) && this.imageCenterRef.current) {
            this.imageCenterRef.current.focus();
        }
    }


    /**
     * On key pressed
     *
     * @param {event} e
     */
    handleKeyPress(event) {
        const { key, nativeEvent } = event;

        // Do not scroll the page on up / down
        nativeEvent.stopPropagation();
        nativeEvent.preventDefault();

        if (key === 'ArrowLeft') {
            this.previousImage();
        }

        if (key === 'ArrowRight') {
            this.nextImage();
        }

        if (key === 'ArrowUp') {
            this.rotateRight();
        }

        if (key === 'ArrowDown') {
            this.rotateLeft();
        }
    }

    /**
     * On image click
     *
     * @param {*} e
     */
    onImageClick(e) {
        const { currentTarget } = e,
            { dataset }         = currentTarget.parentNode,
            { index }           = dataset ||{};

        index && this.setState({ imageOpened: parseInt(index) });
    }


    /**
     * Pass to the previous image on the modal
     */
    previousImage() {
        const { imageOpened, rotations } = this.state;

        if (imageOpened > 0) {
            this.setState({
                imageLoaded  : false,
                imageOpened  : imageOpened - 1,
                lastRotations: rotations,
            });
        }
    }


    /**
     * Pass to the next image on the modal
     */
    nextImage() {
        const { entity }               = this.props,
            { IMG }                    = entity,
            amount                     = IMG && IMG.length,
            { imageOpened, rotations } = this.state;

        if (imageOpened < amount - 1) {
            this.setState({
                imageLoaded  : false,
                imageOpened  : imageOpened + 1,
                lastRotations: rotations,
            });
        }
    }

    /**
     *
     * Image loaded
     */
    imageLoaded() {
        this.setState({ imageLoaded: true });
    }


    /**
     * On modal close
     */
    onClose() {
        const { imageOpened } = this.state;

        this.carouselRef.current.goTo(imageOpened);

        this.setState({ imageOpened: null });
    }


    /**
     * On rotate right click
     */
    rotateRight() {
        const { rotations } = this.state;

        this.setState({ lastRotations: rotations, rotations: this.getLimitedAngle(90) });
    }


    /**
     * On rotate left click
     */
    rotateLeft() {
        const { rotations } = this.state;

        this.setState({ lastRotations: rotations, rotations: this.getLimitedAngle(-90) });
    }


    /**
     * Get new angle inner 0 - 360
     *
     * @param {integer} angleOffset
     * @returns
     */
    getLimitedAngle(angleOffset) {
        const { imageOpened, rotations } = this.state,
            angle                        = rotations[imageOpened] || 0,
            newAngle                     = angle + angleOffset,
            newRotation                  = _.cloneDeep(rotations);

        newRotation[imageOpened] = newAngle;

        return newRotation;
    }


    /**
     * Render action buttons
     *
     * @returns JSX
     */
    renderActionsButtons() {
        return (
            <div className="actions-buttons">
                <IButton
                    icon="rotate-right"
                    labelColor="var(--primary-color)"
                    bgColor="transparent"
                    fontSize={20}
                    onClick={this.rotateRight}
                />
                <IButton
                    icon="rotate-left"
                    labelColor="var(--primary-color)"
                    bgColor="transparent"
                    fontSize={20}
                    onClick={this.rotateLeft}
                />
            </div>
        );
    }


    /**
     * Render containers and image of the modal
     *
     * @returns JSX
     */
    renderModalImage() {
        const { entity }   = this.props,
            {
                imageLoaded, rotations, lastRotations,
                imageOpened,
            }              =  this.state,
            {
                innerHeight, innerWidth
            }              = window,
            { IMG }        = entity,
            modalImageUrl  = !_.isNull(imageOpened) && IMG[imageOpened],
            imgStyle       = {
                '--last-angle': `${lastRotations[imageOpened] || 0}deg`,
                '--new-angle' : `${rotations[imageOpened] || 0}deg`,
            },
            angle          = rotations[imageOpened] % 360,
            imageAngle     = angle < 0
                ? angle + 360
                : angle >= 360 ? angle - 360 : angle,
            imgClass       = !_.isUndefined(imageAngle) ? `rotated${imageAngle || 0}` : '',
            isQuartRotated = imageAngle === 90 || imageAngle === 270;

        // Fix very large image (big difference width/height)
        imgStyle[isQuartRotated ? 'maxHeight' : 'maxWidth'] = innerWidth - 200;

        return modalImageUrl && (
            <div className="image-center" tabIndex="0"
                onKeyDown={this.handleKeyPress} ref={this.imageCenterRef}
            >
                <div className="image-container-2">
                    <img
                        className={`${imgClass} ${imageLoaded && ' loaded'}`}
                        data-index={imageOpened}
                        src={getSobjImage(modalImageUrl, false)}
                        height={!isQuartRotated ? innerHeight - 325 : null}
                        width={!isQuartRotated ? null :innerHeight - 325}
                        onLoad={this.imageLoaded}
                        style={imgStyle}
                    />
                </div>
            </div>
        );
    }


    /**
     * Render modal title
     *
     * @returns JSX
     */
    renderTitle() {
        const { entity } = this.props,
            { label }    = entity;
        return <Highlight>{label}</Highlight>;
    }


    /**
     * Render modal
     *
     * @returns JSX
     */
    renderModal() {  // eslint-disable-line  max-lines-per-function
        const { entity }                = this.props,
            { imageLoaded }             = this.state,
            { IMG }                     = entity,
            amount                      = IMG && IMG.length,
            {
                imageOpened,
            }                           = this.state,
            { innerHeight, innerWidth } = window,
            loader                      = (
                <div className="loader">
                    <CssLoader type="ripple" size={100}
                        thickness={2} color="#dddddd"
                    />
                </div>
            );

        return (
            <Modal
                title={this.renderTitle()}
                open={!_.isNull(imageOpened)}
                className="image-patent-popin"
                width={innerWidth - 200}
                onCancel={this.onClose}
                bodyStyle={{ height: innerHeight - 300, position: 'relative' }}
                footer={null}
                confirmLoading
            >
                {imageOpened > 0 && (
                    <div className="button-left">
                        <Icon
                            type="caret-left"
                            height={100}
                            onClick={this.previousImage}
                            color="#dddddd"
                        />
                    </div>
                )}
                {!imageLoaded && loader}
                {this.renderModalImage()}
                {imageOpened < amount - 1 && (
                    <div className="button-right">
                        <Icon
                            type="caret-right"
                            height={100}
                            onClick={this.nextImage}
                            color="#dddddd"
                        />
                    </div>
                )}
                {this.renderActionsButtons()}
            </Modal>
        );
    }


    /**
     * Render
     *
     * @returns JSX
     */
    render() {
        const { entity }  = this.props,
            { rotations } = this.state,
            { IMG, id }   = entity,
            amount        = IMG && IMG.length;

        if (!entity) {
            return (
                <Row className="images" label="Images"
                    key="images"
                >
                    <Skeleton active title={{ width: '100px' }}
                        paragraph={{ rows: 1, width: '100%' }}
                    />
                </Row>
            );
        }

        if (!IMG) {
            return false;
        }

        return (
            <Row className="images" label={`Images (${amount})`}
                key="images"
            >
                <Carousel
                    ref={this.carouselRef}
                    key={id}
                >
                    {_.map(IMG, (img, index) => {
                        const angle =  `${rotations[index] || 0}deg`,
                            style   = {
                                transform: `rotate(${angle})`
                            };

                        return (
                            <div key={`IMG-${index}`} data-index={index}>
                                <img
                                    src={getSobjImage(img, 500)}
                                    onClick={this.onImageClick}
                                    style={style}
                                />
                            </div>
                        );
                    })}
                </Carousel>
                {this.renderModal()}
            </Row>
        );
    }

}


Images.propTypes = {
    entity: PropTypes.shape({
        id   : PropTypes.string,
        label: PropTypes.string,
        IMG  : PropTypes.array,
    })
};


Images.defaultProps = {
    entity: null
};

export default Images;
