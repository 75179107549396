/**
 * Newsletters Actions Creator: create actions to store entities
 *
 */
import types                   from './types/newsletters';


/**
* Open the provided settings for the provided newsletter
*
* @return {Promise}
*/
export const openSettings = (newsletterKey, newsletterId) => (dispatch) => dispatch({
    type   : types.NEWSLETTER_UPDATE_STATE,
    payload: {
        openSettings: newsletterKey,
        newsletter  : newsletterId
    }
});

/**
* Open the provided settings for the provided newsletter
*
* @return {Promise}
*/
export const closeSettings = (newsletter) => (dispatch) => dispatch({
    type   : types.NEWSLETTER_UPDATE_STATE,
    payload: {
        closeSettings: newsletter,
    }
});


// Export default
export default {
    openSettings, closeSettings,
};
