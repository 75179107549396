import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import _                      from 'lodash';
import { Modal, Skeleton }    from 'antd';
import { connect }            from 'react-redux';
import { learn }              from 'store/actions/knowledge';
import Legals                 from '../../utils/Legals';
import { Element, Highlight } from 'helpers';

/**
 * Infobox of Document
 */
class PatentInfobox extends Component {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'handleKeywordsModal');

        this.state = {
            elements         : [],
            openKeywordsModal: false,
        };
    }

    /**
    * On component mount
    */
    componentDidMount() {
        const {  learnKnowledge } = this.props;

        learnKnowledge(['elements'])
            .then(this.setState.bind(this));
    }

    /**
    * Display the keywords modal
    */
    handleKeywordsModal() {
        const { openKeywordsModal } = this.state;

        this.setState({ openKeywordsModal: !openKeywordsModal });
    }

    /**
    * Render the keywords list.
    *
    * @return {JSX}
    */
    renderKeywords() {
        const { entity }          = this.props,
            { openKeywordsModal } = this.state,
            { KEYW }              = entity || {},
            keyw                  = KEYW?.replace(/\([^)]*\)/g, ''),
            keywords              = keyw && _.split(keyw, '<br/>'),
            words                 = _.join(_.map(keywords, _.capitalize), ', ');

        return keywords?.length > 0 && (
            <div className="keywords">
                <div class="category">Keywords</div>

                <div>
                    <Highlight>
                        {words}
                    </Highlight>

                    <div
                        className="see-all-keywords"
                        onClick={this.handleKeywordsModal}
                    >
                        See all
                    </div>
                </div>

                <Modal
                    className="keywords insight-modal"
                    title="Keywords"
                    footer={null}
                    open={openKeywordsModal}
                    onCancel={this.handleKeywordsModal}
                    width={860}
                    bodyStyle={{ maxHeight: '75vh', fontSize: 13, textAlign: 'justify' }}
                >
                    <Highlight className="capitalize">
                        {words}
                    </Highlight>
                </Modal>
            </div>
        );
    }

    /**
    * Render the Family panel
    *
    * @return JSX
    */
    renderFamilies() {
        const {
                entity, onClick, registerCallbacks,
                elements
            }       = this.props,
            element = elements.find((el) => el.category === 'Area/Entity/Family');

        return element ? (
            <Element
                element={element}
                model={entity}
                parameters={{
                    label        : 'Familly patents',
                    uri          : `/${entity.type}s/${entity.id}`,
                    entityType   : 'patent-application',
                    attributePath: 'FAMILY',
                }}
                onClick={onClick}
                registerCallbacks={registerCallbacks}
            />
        ) : null;
    }

    /**
    * Render the IPC Codes
    *
    * @return JSX
    */
    renderIpc() {
        const {
                entity, onClick, registerCallbacks,
                elements
            }       = this.props,
            element = elements.find((el) => el.category === 'Area/Entity/Ipc');

        return element ? (
            <Element
                element={element}
                model={entity}
                onClick={onClick}
                registerCallbacks={registerCallbacks}
            />
        ) : null;
    }


    /**
    * Render the component with skeletons
    *
    * @return JSX
    */
    renderIsLoading() {
        const { entity } = this.props;

        if (entity) {
            return false;
        }

        return (
            <div className="infobox is-loading">
                <div className="number">
                    <Skeleton active  paragraph={false} />
                </div>
                <div className="assignees">
                    <Skeleton active title={false}
                        paragraph={{ rows: 3, width: '100%' }}
                    />
                </div>
                <div className="impact">
                    <Skeleton active paragraph={false} />
                </div>
                <div className="families">
                    <Skeleton active paragraph={false} />
                </div>
            </div>
        );
    }

    /**
    * Render authorities
    *
    * @return JSX
    */
    renderAssignees() {
        const {
                entity, onClick, registerCallbacks
            }            = this.props,
            { elements } = this.state,
            filters      = { type: 'orgunit' },
            element      = elements.find((el) => el.category === 'Area/Entity/Authority'),
            orgunits     = entity && entity.authority ? entity.authority.filter((e) => e.type === 'orgunit') : null;

        if (!orgunits || orgunits.length === 0) {
            return null;
        }

        return element ? (
            <div className="line assignees">
                <div className="category">Assignees:</div>
                <div className="entity-authority">
                    <Element
                        element={element}
                        model={entity}
                        parameters={{
                            uri              : `/${entity.type}s/${entity.id}`,
                            label            : 'Assignees',
                            attributePath    : 'authority',
                            noExpertAuthority: true,
                            filters
                        }}
                        onClick={onClick}
                        registerCallbacks={registerCallbacks}
                    />
                </div>
            </div>
        ) : null;
    }

    /**
    * Render the Inventors
    *
    * @return JSX
    */
    renderInventors() {
        const {
                entity, onClick, registerCallbacks
            } = this.props,
            { elements }          = this.state,
            filters               = { type: 'expert' },
            element               = elements.find((el) => el.category === 'Area/Entity/Authority'),
            experts               = entity && entity.authority ? entity.authority.filter((e) => e.type === 'expert') : null;

        if (!experts || experts.length === 0) {
            return null;
        }

        return element ? (
            <div className="line assignees inventors">
                <div className="category">Inventors:</div>
                <div className="entity-authority">
                    <Element
                        element={element}
                        model={entity}
                        parameters={{
                            uri              : `/${entity.type}s/${entity.id}`,
                            label            : 'Inventors',
                            attributePath    : 'authority',
                            noExpertAuthority: true,
                            filters
                        }}
                        onClick={onClick}
                        registerCallbacks={registerCallbacks}
                    />
                </div>
            </div>
        ) : null;
    }

    /**
    * Render the Component
    *
    * @return html
    */
    render() {
        const { entity, className } = this.props;

        if (!entity) {
            return this.renderIsLoading();
        }

        return (
            <div className="heading">
                <h3>
                    <span className="anchor" />
                    <span className="text">Overview</span>
                </h3>
                <div className="patent-id">
                    {entity.ID}
                </div>
                <div className={`info-box ${className}`}>
                    {this.renderAssignees()}
                    <div className="line application-date">
                        <div className="category">Application date:</div>
                        <span title={entity.EAPD} className="value">{entity.EAPD}</span>
                    </div>
                    <div className="line publication-date">
                        <div className="category">Publication date:</div>
                        <span title={entity.EPD} className="value">{entity.EPD}</span>
                    </div>
                    {this.renderInventors()}
                    <div className="impact">
                        <Legals litigation={entity.LITIGATION} opposition={entity.OPPOSITION} />
                    </div>
                    {this.renderKeywords()}
                    {this.renderFamilies()}
                    {this.renderIpc()}
                </div>
            </div>
        );
    }

}


PatentInfobox.propTypes = {
    learnKnowledge   : PropTypes.func,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    className        : PropTypes.string,
    elements         : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    entity           : PropTypes.shape({
        EAPD      : PropTypes.any,
        EPD       : PropTypes.any,
        ID        : PropTypes.any,
        KEYW      : PropTypes.any,
        LITIGATION: PropTypes.any,
        OPPOSITION: PropTypes.any,
        authority : PropTypes.array,
        id        : PropTypes.any,
        type      : PropTypes.any
    }),
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    entity        : false,
    className     : '',
})(PatentInfobox);
