import React        from 'react';
import PropTypes    from 'prop-types';

// CSS
import './assets/loader.less';

/**
 * Insight generic loader
 *
 * @param Component content A single element
 */
const Loader = (props) => {
    const { label } = props;

    return (
        <div className="oi-loader">
            {label
                && <div className="label">{label}</div>}
        </div>
    );
};

// Validate properties for current Component
Loader.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
};

// Declare default properties
Loader.defaultProps = {
    label: false,
};

export default Loader;
