/**
* Ovation Action, enable action on elements
*
* @use import Action then render <Action />
*/
import React, { Component }                           from 'react';
import { connect }                                    from 'react-redux';
import _                                              from 'lodash';
import PropTypes                                      from 'prop-types';
import { Dropdown, Tooltip, Checkbox }                from 'antd';
import OIcon                                          from './Icon';
import { CssLoader }                                  from 'helpers';
import { isNumeric, makeStrClassName }                from 'utils/text';
import { emitEvent }                                  from 'store/actions/sockets';

// Import the autocomplete stylesheet
import './Action/assets/action.less';

/**
* Insight action manager
*
* @param Component content A single element
*/
class Action extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            menuVisible: false,
            openKeys   : []
        };

        _.bindAll(this, 'render', 'renderButton', 'getMenuProps', 'renderAction',
            'onOpenChange', 'onVisibleChange', 'actionClick', 'close');
    }

    /**
     * Componant just mounted
     *
     */
    componentDidMount() {
        const { firstKeyPath } = this.props;

        if (firstKeyPath.length) {
            this.setState({
                openKeys   : firstKeyPath,
                menuVisible: true
            });
        }
    }

    /**
    * Umount autocomplete
    */
    componentWillUnmount() {
        // Unbind For sure
        this.close();
    }

    /**
     * Component did update
     */
    componentDidUpdate() {
        const { onActionToggleDisplay } = this.props,
            { menuVisible }             = this.state;

        onActionToggleDisplay && onActionToggleDisplay({ menuVisible });
    }


    /**
    * Close the action menu
    *
    * @return void
    */
    close() {
        this.setState({
            menuVisible: false,
            openKeys   : []
        });
    }

    /**
    * Return the action definition from its path
    *
    * @param array keyPath The key path of the action
    *
    * @return object
    */
    getActionFromKeyPath(keyPath, actionsFromParam) {
        const defaultActions  = this.getActions(),
            actions    = actionsFromParam || defaultActions,
            currentPos = keyPath.length - 1,
            currentKey = keyPath[currentPos];

        if (!actions[currentKey]) {
            return;
        }

        const currentAction = actions[currentKey],
            nextKeyPath     = keyPath.length > 1 ? _.clone(keyPath).slice(0, currentPos) : null;

        return nextKeyPath ? this.getActionFromKeyPath(nextKeyPath, currentAction.actions)
            : currentAction;
    }


    /**
    *
    * Disable bubling on click
    *
    * @return true
    */
    disableBubbling(e) {
        const { domEvent } = e,
            event = domEvent || e;

        // Prevent bubbling
        event.stopPropagation();
        event.nativeEvent?.stopImmediatePropagation();
    }


    /**
    *
    * On actions click
    *
    * @return true
    */
    actionClick(e) {
        const { from, emitEvent, entityId } = this.props,
            { domEvent }          = e,
            event                 = domEvent || e;

        // Prevent bubbling
        event.stopPropagation();
        event.nativeEvent?.stopImmediatePropagation();

        const { currentTarget } = event,
            currentHasDataId    = currentTarget.dataset.id,  // Manage action / sub-actions
            actionElement       = currentHasDataId
                ? currentTarget
                : currentTarget.closest('.ovation-action-menu'),
            {id, path}          = actionElement.dataset,
            keys                = id.split('::'),
            labels              = path.split('::'),
            action              = this.getActionFromKeyPath(keys.reverse()),
            isSelectable        = !_.isUndefined(action.selected),
            firstKeyAction      = _.last(keys);

        // Send event information
        emitEvent && emitEvent({
            module: 'action',
            name  : isNumeric(firstKeyAction) ? labels[0] : firstKeyAction,
            data  : {
                ...from,
                labels,
            },
            attributesFilter: ['model.id', 'model.type', 'componentName', 'uri', 'category', 'labels']
        });

        // Trigger the action
        if (!action.disabled && action.cb) {
            action.cb(action.options || entityId);
        }

        // If the item is not selectable, close the menu
        if (!isSelectable && !action.openAfter) {
            this.close();
            return;
        }

        this.setState({
            openKeys: action.openAfter || keys
        });

        return false;
    }


    /**
    *
    * On onVisibleChange
    *
    * @return true
    */
    onVisibleChange(visible) {
        this.setState({ menuVisible: visible, openKeys: [] });
    }

    /**
    * Update opened SubMenu
    *
    * @return JSX
    */
    onOpenChange(openKeys) {
        this.setState({ openKeys });
    }

    /**
    * Render the label for the current action
    *
    * @return JSX
    */
    renderActionLabel(action) {
        const  {
                label, title, href,
                target, selected, indeterminate
            }           = action,
            selectable  = !_.isUndefined(action.selected);

        return (
            <Tooltip title={title}>
                <a href={href} target={target}>
                    <span>{label}</span>
                    {selectable && (
                        <Checkbox
                            checked={!indeterminate && selected}
                            indeterminate={indeterminate}
                        />
                    )}
                </a>
            </Tooltip>
        );
    }


    /**
     * Render the icon for the current action
     *
     * @return JSX
     */
    reunderActionIcon(action) {
        const { icon, isLoading } = action;

        return isLoading
            ?  (
                <CssLoader type="ring" size={14}
                    thickness={1} color="#9b9b9b"
                />
            )
            :  icon;
    }

    /**
    * Render an action for the current menu
    *
    * @return object with MenuProps
    */
    renderAction(action, parentKey = false, parentPath = false) {  // eslint-disable-line max-lines-per-function
        const {
                key, label, actions,
                selected, disabled, href,
                cb, isLoading, preserveIconColor
            }             = action,
            selectable    = !_.isUndefined(selected),
            hasSubActions = actions && _.keys(actions).length > 0,
            isADivider    = _.keys(action).length === 2,    // Only have key and rank
            noParentKey   = parentKey === false,
            id            = noParentKey ? key   : `${parentKey}::${key}`,
            path          = noParentKey ? label : `${parentPath}::${label}`,
            classNames    = [
                'action',
                'ovation-action-menu',
                selectable              ? 'selectable'    : '',
                selectable && selected  ? 'selected'      : '',
                disabled                ? 'disabled'      : '',
                cb                      ? 'has-cb'        : '',
                href                    ? 'has-href'      : '',
                !preserveIconColor      ? ' force-color ' : '',
                hasSubActions           ? 'has-sub'       : '',
            ];

        if (isADivider) {
            return {key: `divider-${id}`, type: 'divider'};
        }

        const props = {
            'data-id'    : id,
            'data-qa-key': id,
            'data-path'  : path,
            key          : hasSubActions? `sub-${id}` : id,
            className    : makeStrClassName(classNames),
            label        : this.renderActionLabel(action),
            icon         : this.reunderActionIcon(action),
            disabled     : isLoading,
            children     : hasSubActions
                ? _.map(this.orderActions(actions), subAction => this.renderAction(subAction, id, path))
                : null,
            selected,
        };

        // Add actions in SubMenu and Menu elements
        hasSubActions
            ? props.onTitleClick   = cb ? this.actionClick : this.disableBubbling
            : props.onClickCapture = this.actionClick;

        return props;
    }


    /**
     * Get actions from props or getAction callback
     *
     * @returns array
     */
    getActions() {
        const { actions, getActions } = this.props,
            { menuVisible }           = this.state;

        return actions
            || menuVisible && getActions();
    }

    /**
     * Transform object to ordered array
     *
     * @param {object} actions
     */
    orderActions(actions) {
        const actionsArray = _.values(_.mapValues(actions, (value, key) => {
            value.key  = key;
            value.rank = value.rank || 0;
            return value;
        }));

        return _.sortBy(actionsArray, 'rank');
    }

    /**
     * Hide actions button if no actions active
     *
     * @param {array} actions
     *
     * @returns {boolean}
     */
    mustHideActionsButton(actions) {
        // Active action has at least a callback or a submenu (other actions) and not disabled
        const activeActions = actions.filter(
            action => !action.disabled
                && (
                    !_.isUndefined(action.cb)
                    || action?.actions?.length > 0
                )
        );

        return _.isEmpty(activeActions);
    }

    /** Iterative function to check if all actions are ready
    *
    * @param {array}
    *
    * @return {boolean}
    */
    isAllActionsAreReady(actions) {
        const actionsArray       = _.isArray(actions) ? actions : _.values(actions),
            isAllActionsAreReady = !actionsArray || (actionsArray.every && actionsArray?.every(action => {
                const callBackIsReady  = action.cb,
                    notAnAction        = _.isUndefined(action.cb)
                        && _.isUndefined(action.actions)
                        && _.isUndefined(action.isLoading),
                    subActionsAreReady = _.isUndefined(action.cb)
                        && action.actions
                        && this.isAllActionsAreReady(action.actions),
                    actionIsDisabled   = action.disabled;
                return callBackIsReady || notAnAction || subActionsAreReady || actionIsDisabled;
            }));

        return isAllActionsAreReady;
    }

    /**
    * Render the items of the Menu
    *
    * @return JSX
    */
    getMenuProps(providedActions) {
        const { getActions }      = this.props,
            actions               = this.getActions(),
            { openKeys}           = this.state,
            actionsToRender       = providedActions || actions,
            orderActions          = this.orderActions(actionsToRender),
            mustHideActionsButton = this.mustHideActionsButton(orderActions),
            className             = `ovation-action-menu${this.isAllActionsAreReady(orderActions) ? ' actions-are-ready' : ''}`;

        if (!getActions && (
            !actionsToRender
            || !_.keys(actionsToRender).length
            || (_.isArray(actionsToRender) && !actionsToRender.length)
            || mustHideActionsButton
        )) {
            return false;
        }

        return {
            openKeys,
            className,
            onOpenChange: this.onOpenChange,
            items       : _.map(orderActions, action => this.renderAction(action))
        };
    }

    /**
    * Render the clicker
    *
    * @return JSX
    */
    renderButton() {
        const { children, dataQaKey } = this.props;

        return children
            ? (
                <div className="children-action-btn" data-qa-key={dataQaKey}>
                    {children}
                </div>
            ) : (
                <div className="action-btn" data-qa-key={dataQaKey}>
                    <OIcon
                        id="options"
                        height={14}
                        SVG={false}
                    />
                </div>
            );
    }


    /**
     * Render dropdown menu
     *
     * @return html
     */
    renderDropdown(menuProps, customIcon) {
        const {
                overlayClassName,
                showMenuOnClick,
                getActions,
                clickAction
            }               = this.props,
            { menuVisible } = this.state,
            hasItems        = getActions || menuProps?.items?.length > 0;

        // Manage single action
        if (!hasItems && clickAction) {
            return customIcon || this.renderButton();
        }

        return (
            menuProps && hasItems ? (
                <Dropdown
                    className="actions-list"
                    overlayClassName={overlayClassName}
                    menu={menuProps}
                    open={menuVisible}
                    onOpenChange={this.onVisibleChange}
                    trigger={showMenuOnClick ? ['click'] : ['hover']}
                    destroyPopupOnHide
                >
                    {customIcon || this.renderButton()}
                </Dropdown>
            ) : false
        );
    }


    /**
     * Render inline
     *
     * @return html
     */
    renderInline(menuProps) {
        const { items }   = menuProps;

        return items && items.map(action => {
            const {
                    label, title, icon,
                    children, className, actionCb,
                    ...otherProps
                }            = action,
                subMenuProps = this.getMenuProps(action.children);
            return (
                <div
                    title={label} className={`render-inline ${className}`}
                    onClick={actionCb || this.disableBubbling}
                    {...otherProps}
                >
                    {this.renderDropdown(subMenuProps, icon)}
                </div>
            );
        });
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                className,
                renderInline,
                actionCb,
                forceRender
            }               = this.props,
            { menuVisible } = this.state,
            menuProps       = this.getMenuProps(),
            classNames      = [
                'actions',
                className,
                {'is-open': menuVisible || forceRender},
                {inline: renderInline}
            ];

        return (
            <div className={makeStrClassName(classNames)} onClick={actionCb || this.disableBubbling}>
                {
                    renderInline
                        ? this.renderInline(menuProps)
                        : this.renderDropdown(menuProps)
                }
            </div>
        );
    }

}

Action.propTypes = {
    actions: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool
    ]),
    entityId             : PropTypes.string,
    getActions           : PropTypes.func,
    onActionToggleDisplay: PropTypes.func,
    emitEvent            : PropTypes.func,
    className            : PropTypes.string,
    overlayClassName     : PropTypes.string,
    dataQaKey            : PropTypes.string,
    firstKeyPath         : PropTypes.arrayOf(PropTypes.string),
    clickAction          : PropTypes.arrayOf(PropTypes.string),
    actionCb             : PropTypes.func,
    children             : PropTypes.object,
    from                 : PropTypes.object,
    showMenuOnClick      : PropTypes.bool,
    renderInline         : PropTypes.bool,
};

// Specifies the default values for props:
Action.defaultProps = {
    className       : '',
    overlayClassName: null,
    firstKeyPath    : [],
    children        : null,
    showMenuOnClick : false,
    renderInline    : false
};



/**
 * Bind Dispatcher to the component props
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    emitEvent
})(Action);
