import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                           from 'lodash';

import {
    polygonHull      as D3PolygonHull,
    line             as D3Line,
    curveBasisClosed as D3CurveBasisClosed,
}                                    from 'd3';

import {
    hullPoints
}                                  from 'utils/svg';


/**
 * Render networks nodes
 *
 */
class Hulls extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * The Render
     */
    render() {
        const {
                plotData, onMouseDown, onMouseUp,
                highlightedNodes, onMouseMove
            }                        = this.props,
            { nodes }                = plotData,
            line                     = D3Line().curve(D3CurveBasisClosed),
            highlightedClustersIds   = _.uniq(highlightedNodes.map(node => node.cluster)),
            nodeByCluster            = _.groupBy(nodes, node => node.cluster);
        
        return (
            <g
                className="clusters-hulls"
                onMouseMove={onMouseMove}
            >
                { _.map(nodeByCluster, (clusterNodes, clusterId) => {
                    const points     = clusterNodes.map(node => ({
                            x: node.x,
                            y: node.y
                        })),
                        path = line(D3PolygonHull(hullPoints(points, 20)));
                    
                    return clusterId >= 0 && (
                        <path
                            key={clusterId}
                            onMouseDown={onMouseDown}
                            onMouseUp={onMouseUp}
                            className="cluster-hull"
                            visibility={highlightedClustersIds.includes(parseInt(clusterId)) ? 'visible' : 'hidden'}
                            dataid={clusterId}
                            d={path}
                            fill="#eeeeee"
                        />
                    );
                })}
            </g>
        );
    }

}

/**
 * Props type
 */
Hulls.propTypes = {
    plotData        : PropTypes.shape().isRequired,
    onMouseDown     : PropTypes.func.isRequired,
    onMouseUp       : PropTypes.func.isRequired,
    onMouseMove     : PropTypes.func.isRequired,
    highlightedNodes: PropTypes.array.isRequired,
};

/**
 * Default props value
 */
Hulls.defaultProps = {
};

export default Hulls;
