// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* RaceLine graph
*
*/
.Bet {
  position: relative;
}
.Bet .background {
  position: absolute;
  overflow: hidden;
  user-select: none;
  background: #fff;
}
.Bet > svg {
  position: absolute;
}
.Bet > svg .token.clickable:Hover {
  cursor: pointer;
}
.Bet .label-container {
  position: absolute;
}
.Bet .label-container .label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Bet .label-container .label > span {
  color: inherit;
}
.Bet.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Bet/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EACI,kBAAA;AAAJ;AADA;EAGQ,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACR;AACI;EACI,kBAAA;AACR;AAEY;EACI,eAAA;AAAhB;AAbA;EAmBQ,kBAAA;AAHR;AAhBA;EAsBY,gBAAA;EACA,mBAAA;EACA,uBAAA;AAHZ;AArBA;EA0BgB,cAAA;AAFhB;AAOI;EACI,mDAAA;AALR","sourcesContent":["/**\n* RaceLine graph\n*\n*/\n\n.Bet {\n    position: relative;\n    .background {\n        position: absolute;\n        overflow: hidden;\n        user-select:none;\n        background: #fff;\n    }\n    & > svg {\n        position: absolute;\n\n        .token{\n            &.clickable:Hover{\n                cursor: pointer;\n            }\n        }\n    }\n\n    .label-container {\n        position: absolute;\n\n        .label {\n            overflow: hidden;\n            white-space: nowrap;\n            text-overflow: ellipsis;\n            > span {\n                color: inherit;\n            }\n        }\n    }\n\n    &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
