import _                      from 'lodash';
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { Checkbox, Skeleton } from 'antd';
import { Icon  }              from 'helpers';

/**
 * List filter
 */
class CompanyTag extends Component {

    /**
    * Constructor
    *
    */
    constructor(props) {
        super(props);

        _.bindAll(this,
            'renderTag',
        );
    }

    /**
     * Render a tag
     *
     * @param {object} option
     */
    renderTag(option) {
        const { selectedIds, onChange } = this.props,
            { id, label, icon, color }  = option;

        return (
            <Checkbox
                key={id}
                checked={selectedIds.indexOf(id) !== -1}
                onChange={(e) => onChange(id, e)}
                className="tags"
            >
                <Icon
                    type={icon}
                    color={color}
                    height={15}
                    theme="filled"
                    style={{ color }}
                />
                <span title={label}>
                    {label}
                </span>
            </Checkbox>
        );
    }

    /**
    * Render the filter List
    *
    * @return JSX
    */
    render() {
        const { values }  = this.props,
            orderedValues = values && _.orderBy(values, (value) => {
                const { settings } = value;
                return settings.order;
            });

        if (!orderedValues) {
            return (
                <div className="filter-list">
                    <Skeleton title={false} paragraph={{ rows: 5, width: '100%' }}
                        active
                    />
                </div>
            );
        }

        return _.map(orderedValues, this.renderTag);
    }

}

CompanyTag.propTypes = {
    onChange   : PropTypes.func.isRequired,
    values     : PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.arrayOf(PropTypes.string),
};

export default CompanyTag;
