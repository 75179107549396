/* eslint-disable */
/**
 * Insight Metrics tester
 *
 * @return Component
 */

import React, { Component }           from 'react';
import { connect }                    from 'react-redux';
import _                              from 'lodash';
import { Graph, Metrics }             from 'helpers';
import { fetchSearch }                from 'store/actions/navigation';

import './MetricsTester/main.less';

const data = {
        content: [
            { x: 1992, y: 0 },
            { x: 1993, y: 200 },
            { x: 1994, y: 200 },
            { x: 1995, y: 300 },
            { x: 1996, y: 300 },
            { x: 1997, y: 0 },
            { x: 1998, y: 0 },
            { x: 1999, y: 500 },
            { x: 2000, y: 500 },
            { x: 2001, y: 1000 },
            { x: 2002, y: 200 },
            { x: 2003, y: 200 },
            { x: 2004, y: 100 },
            { x: 2005, y: 100 },
            { x: 2006, y: 100 },
            { x: 2007, y: 100 },
            { x: 2008, y: 200 },
        ],
        stats: {
            rate       : 5.7,
            rateMeaning: 'steady',
            last       : 3
        }
    },
    dataLine2 = {
        content: [
            { x: 2008, y: 2000 },
            { x: 2009, y: 2000 },
            { x: 2010, y: 2000 },
            { x: 2011, y: 1000 },
            { x: 2012, y: 3000 },
            { x: 2014, y: 600 },
            { x: 2017, y: 1500 },
            { x: 2018, y: 500 },
            { x: 2019, y: 500 },
        ],
        stats: {
            /*
            ExtendMinX: 2009,
            extendMaxX: 2020,
            */
            lastEnabledData: 2017,
        }
    },
    dataBar1 = {
        content: [
            { label: 1992, value: 980 },
            { label: 1993, value: 200 },
            { label: 1994, value: 200 },
            { label: 1995, value: 300 },
            { label: 1996, value: 300 },
            { label: 1997, value: 500 },
            { label: 1998, value: 1200 },
            { label: 1999, value: 500 },
            { label: 2000, value: 500 },
            { label: 2001, value: 1000 },
            { label: 2002, value: 200 },
            { label: 2003, value: 200 },
            { label: 2004, value: 100 },
            { label: 2005, value: 100 },
            { label: 2006, value: 290 },
            { label: 2007, value: 200 },
            { label: 2008, value: 140 },
        ],
        stats: {
            lastEnabledData: 2008
        }
    },
    dataBar2 = {
        content: [
            { label: 'Ocean’s King Lighting Science & Technology', value: 4 },
            { label: 'Central South University', value: 4 },
            { label: 'Zeijang University', value: 3 },
            { label: 'Semiconductor Energy Laboratory', value: 2 },
            { label: 'Tsinghua University', value: 1 },
        ],
        stats: {
        }
    },
    dataBar3 = {
        content: [
            { label: 'Lighting Science', value: 204 },
            { label: 'South University', value: 172 },
            { label: 'Zeijang University', value: 150 },
            { label: 'Energy Laboratory', value: 97 },
            { label: 'Tsinghua University', value: 91 },
        ],
        stats: {
        }
    },
    dataBar4 = {
        content: [
            { label: 'Lighting Science', value: 204 },
            { label: 'South University', value: 172 },
        ],
        stats: {
        }
    },
    dataGauge = {
        serie: [
        ],
        stats: {
            label: 'Consolidated',
            step : 4,
        }
    },
    dataGauge2 = {
        serie: [
        ],
        stats: {
            label: '60%',
            score: 0.6
        }
    },
    dataPlanet1 = {
        serie: [
            { label: 'Technology "reference"', value: 241 },
            { label: 'Graphene battery', value: 200, mine: true },
            { label: 'Condimentum risus lectus', value: 76 },
            { label: 'Erat vestibulum', value: 67 },
        ],
    },
    dataPlanet2 = {
        serie: [
            { label: 'Technology "reference"', value: 121 },
            { label: 'Graphene battery', value: 87, mine: true },
        ],
    },
    dataPlanet3 = {
        serie: [
            { label: 'Graphene battery', value: 187, mine: true },
            { label: 'Technology "reference"', value: 121 },
        ],
    },
    dataMap1    = {
        serie: [
            { id: 'CN', value: 1 },
            { id: 'KR', value: 1 },
            /* { id: 'FR', value: 1 },
            { id: 'JP', value: 1 },
            { id: 'US', value: 1 } */
        ],
    },
    dataMap2    = {
        serie: [
            { id: 'CH', value: 60 },
            { id: 'SW', value: 70 },
            { id: 'FR', value: 80 },
            { id: 'BR', value: 90 },
            { id: 'RU', value: 50 },
            { id: 'US', value: 100 },
            { id: 'NO', value: 40 },
            { id: 'AU', value: 30 },
            { id: 'DE', value: 20 },
            { id: 'SP', value: 10 },
        ],
    },
    dataTagCloud = {
        serie: [
            'candidate gene',
            'placement sureface',
            'time factor',
            'possible mechanism',
            'even number',
            'simple structure',
            'starting position',
            'previously sold',
            'move',
            'performance evaluation',
            'hierachical structure',
            'draw',
            'pixel',
            'data',
            'separately',
            'expensive',
            'pixels',
            'masks',
            'detection',
            'larger',
            'whole',
            'comparing',
            'box',
            'large',
            'think',
            'version',
            'single',
            'tree',
            'Cloud',
            'Generator',
            'Works',
            'positioning',
            'overlap',
            'available',
            'GitHub',
            'open',
            'source',
            'license',
            'd3cloud',
            'Note',
            'code',
            'converting',
            'text',
            'rendering',
            'final',
            'output',
            'requires',
            'additional',
            'development',
            'quite',
            'slow',
            'hundred',
            'run',
            'asynchronously',
            'configurable',
            'size',
            'makes',
            'animate',
        ]
    },
    dataPie = {
        serie: [
            { label: 'Other 1', value: 100 },
            { label: 'Other 2', value: 100 },
            { label: 'Other 3', value: 100 },
            { label: 'Other 4', value: 100 },
            { label: 'Other 5', value: 100 },
            { label: 'Other 6', value: 100 },
            { label: 'Other 7', value: 100 },
            { label: 'Other 8', value: 100 },
            { label: 'Other 9', value: 100 },
            { label: 'Other 10', value: 100 },
            { label: 'Other 11', value: 100 },
            { label: 'Other 12', value: 100 },
            { label: 'Other 13', value: 100 },
        ],
    },
    dataPie2 = {
        serie: [
            { label: 'Alive families', value: 5098 },
            { label: 'Other', value: 288, other: true },
        ],
    },
    dataPie3 = {
        serie: [
            { label: 'TOP 10 players own 40% of the invention', value: 1800 },
            { label: 'Other', value: 1000, other: true },
        ],
    },
    dataPie4 = {
        serie: [
            { label: 'Chocolate', value: 100 },
            { label: 'Food', value: 100 },
            { label: 'Choouf', value: 100 },
            { label: 'Other 4', value: 1000 },
        ],
    },

    dataDoubleRadial = {
        series: {
            serie2: [
                {
                    label: 'CN',
                    value: 0.84545
                },
                {
                    label: 'JP',
                    value: 0.5687022900763359
                },
                {
                    label: 'US',
                    value: 0.42366412213740456
                },
                {
                    label: 'KR',
                    value: 0.23282442748091603
                },
                {
                    label: 'IN',
                    value: 0.17938931297709923
                }
            ],
            seriei111: [
                {
                    label: 'CN',
                    value: 0
                },
                {
                    label: 'JP',
                    value: 0
                },
                {
                    label: 'US',
                    value: 0
                },
                {
                    label: 'KR',
                    value: 0
                },
                {
                    label: 'IN',
                    value: 0
                }
            ],
            serie1: [
                {
                    label: 'ES',
                    value: 0
                },
                {
                    label: 'US',
                    value: 0
                },
                {
                    label: 'KR',
                    value: 1
                },
                {
                    label: 'IN',
                    value: 1
                },
                {
                    label: 'CH',
                    value: 1
                },
            ],
            serie1112: [
                {
                    label: 'ES',
                    value: 1
                },
                {
                    label: 'US',
                    value: 0.75
                },
                {
                    label: 'KR',
                    value: 0.5
                },
                {
                    label: 'IN',
                    value: 0.25
                },
                {
                    label: 'FR',
                    value: 0
                },
            ],
            serie3: [
                {
                    label: 'CH',
                    value: 0.5687022900763359
                },
            ]

        },
        stats: {
            nbDocs     : 4318,
            information: 'The IP activity is mainly located in pays1 but '
                + 'paysacceleration shows an observable acceleration last year.'
        }
    },
    dataTopCircle1 = {
        serie: [
            { label: 'Lucas Marques de Arruda', type: 'expert' },
            { label: 'Adam Johanson', type: 'expert' },
            { label: 'Vignesh Shivan Prabu', type: 'expert' },
        ],
    },
    dataShape1 = {
        stats: {
            label: 'incremental'
        }
    },
    dataShape2 = {
        stats: {
            label: 'disruptive'
        }
    },
    dataTopCircle2 = {
        serie: [
            { label: 'Massachusetts Institute', type: 'academic', logo: 'https://dev1.env.expernova.info/coutries-96x64/FR.png' },
            { label: 'Paris Dauphine', type: 'academic' },
            { label: 'Harvard University', type: 'academic', logo: 'https://dev1.env.expernova.info/coutries-96x64/US.png' },
        ],
    },
    dataFoamtree1 = {
        serie: [
            {
                label : 'Group 1',
                groups: [
                    {
                        label: 'Group 1.1'
                    },
                    {
                        label: 'Group 1.2'
                    },
                    {
                        label: 'Group 1.3'
                    }
                ]
            },
            {
                label : 'Group 2',
                groups: [
                    {
                        label: 'Group 2.1'
                    },
                    {
                        label: 'Group 2.2'
                    }
                ]
            },
            {
                label: 'Group 3'
            }
        ]
    },
    dataTopCircle3 = {
        serie: [
            {
                label    : 'both Industrial & Academic collaboration type',
                component: {
                    name: 'Pie',
                    data: {
                        serie: [
                            { label: 'First', value: 3000 },
                            { label: 'Other', value: 2000, other: true },
                            { label: 'Other', value: 2000, other: true },
                        ],
                    },
                }
            },
            {
                label    : 'low collaboration level',
                component: {
                    name: 'Gauge',
                    data: {
                        serie: [],
                        stats: { step: 1 },
                    },
                    settings: {  steps: 3, fontSize: 0 }
                }
            },
            {
                label    : 'mostly worlwide collaboration reach',
                component: {
                    name: 'Pie',
                    data: {
                        serie: [
                            { label: 'First', value: 3000 },
                            { label: 'Other', value: 2000, other: true },
                            { label: 'Other', value: 2000, other: true },
                        ],
                    },
                }
            },
        ],
    };

/**
 * MetricsTester Component
 *
 */
class MetricsTester extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        _.bindAll(this);
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { fetchSearch } = this.props;

        fetchSearch('c05dc8a9-e9cd-11e9-a7f5-02420a040021');

        fetch('/dataTest/4.gexf').then(
            (response) => {
                response.text().then((xml) => {
                    this.setState({
                        dataXml: xml
                    });
                });
            }
        );
        fetch('/dataTest/therace.json').then(
            (response) => {
                response.text().then((json) => {
                    this.setState({
                        dataTheRace: JSON.parse(json)
                    });
                });
            }
        );

        this.fetchData('bet');
        this.fetchData('bet2');

        this.fetchData('venn', 0);
        this.fetchData('venn', '0-inv');
        this.fetchData('venn', '0-s');
        this.fetchData('venn', 1);
        this.fetchData('venn', '1-s');
        this.fetchData('venn', 2);
        this.fetchData('venn', '2-s');
        this.fetchData('venn', 3);
        this.fetchData('venn', '3-s');
        this.fetchData('venn', 4);
        this.fetchData('venn', '4-s');
        this.fetchData('venn', 5);
        this.fetchData('venn', '5-s');
        this.fetchData('venn', 6);
        this.fetchData('venn', '6-s');
        this.fetchData('barstack', 0);
        this.fetchData('linestack', 0);
        this.fetchData('linestack', 1);
    }

    /* Fetch Venn data
    *
    * @return void
    */
    fetchData(prefix, num = '') {
        fetch(`/dataTest/${prefix}${num}.json`).then(
            (response) => {
                response.text().then((json) => {
                    const newState = {};
                    newState[`data${_.capitalize(prefix)}${num}`] = JSON.parse(json);
                    this.setState(newState);
                });
            }
        ); 0;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBar1() {
        return (
            <Graph
                type="Bar"
                itemLabel="inventions"
                data={dataBar2}
                dataIsLoaded
                width={300}
                height={150}
                innerPadding={0.3}
                outerMargin={0.0}
                color="#8d2983"
                horizontal
                showGradient0
                showInnerLabel
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBar2() {
        return (
            <Graph
                type="Bar"
                title="Invention filing over the last 20 years"
                itemLabel="inventions"
                data={dataBar1}
                dataIsLoaded
                width={600}
                height={200}
                innerPadding={0.25}
                outerMargin={0}
                color="#607d8b"
                background="#eceff1"
                showBorderLabels
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBar3() {
        return (
            <Graph
                type="Bar"
                title="Invention filing over the last 20 years"
                itemUnit="invention"
                data={dataBar1}
                dataIsLoaded
                width={800}
                height={200}
                margin={40}
                innerPadding={0.2}
                outerMargin={0}
                maxBarWidth={30}
                color="var(--insight-color)"
                showLandmark
                showGradient
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBar4() {
        return (
            <Graph
                type="Bar"
                data={dataBar3}
                dataIsLoaded
                width={250}
                height={100}
                margin={20}
                innerPadding={0.1}
                outerMargin={0}
                color="var(--insight-color)"
                stepNumber={3}
                showLandmark
                showLabelOnBar
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBar5() {
        return (
            <Graph
                type="Bar"
                title="Invention filing over the last 20 years"
                itemLabel="inventions"
                data={dataBar1}
                dataIsLoaded
                width={600}
                height={300}
                margin={40}
                fontSize={9}
                innerPadding={0.1}
                outerMargin={0}
                color="#607d8b"
                borderLabels={false}
                showLandmark
                showInnerLabel
                showProjectedValues
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBarStack0() {
        const { dataBarstack0 } = this.state;

        return (
            <Graph
                type="Bar"
                title="Invention filing over the last 20 years"
                itemUnit="document"
                data={dataBarstack0}
                dataIsLoaded
                width={1000}
                height={500}
                margin={50}
                fontSize={9}
                innerPadding={0.1}
                outerMargin={0}
                color="#607d8b"
                stacksColors={{
                    scidoc       : '#8d2983',
                    project      : '#463f92',
                    clinicaltrial: '#2F6D84',
                    patent       : '#f6b247',
                }}
                showLandmark

            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderLine1() {
        return (
            <Graph
                type="LineArea"
                itemLabel="inventions"
                data={data}
                dataIsLoaded
                width={1000}
                height={400}
                showSelectTooltip
                showLandmark
                canChangeSelect
                select={{ x: { last: 5 } }}
                xLabel="years"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderLine2() {
        return (
            <Graph
                type="LineArea"
                data={data}
                dataIsLoaded
                width={135 * 2}
                height={75 * 2}
                showTrendInformation
                xLabel="years"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderLine3() {
        return (
            <Graph
                type="LineArea"
                data={dataLine2}
                cumulative
                showDots
                showLandmark
                dataIsLoaded
                margin={20}
                width={300 * 2}
                height={200 * 2}
                xLabel="years"
                interpolation="stepAfter"
                itemLabel="dollar"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderLine4() {
        return (
            <Graph
                type="LineArea"
                data={dataLine2}
                showDots
                showLandmark
                dataIsLoaded
                margin={20}
                width={300 * 2}
                height={200 * 2}
                xLabel="years"
                itemLabel="dollar"
                showProjectedValues
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderStackedLine1() {
        const { dataLinestack1 } = this.state;

        return (
            <Graph
                type="LineArea"
                itemUnit="document"
                data={dataLinestack1}
                dataIsLoaded
                width={1000}
                height={400}
                showLandmark
                showDots
                canChangeSelect
                select={{ x: { last: 5 } }}
                xLabel="years"
                yLabel="documents"
                dataIsLoaded
                stacksColors={{
                    scidoc       : '#8d2983',
                    project      : '#463f92',
                    clinicaltrial: '#2F6D84',
                    patent       : '#f6b247',
                }}

            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge1() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                straight
                thickness={7}
                data={dataGauge}
                steps={5}
                width={110}
                height={67}
                innerPadding={0.10}
                outerMargin={0.10}
                color="var(--insight-color)"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge2() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge}
                steps={5}
                width={200}
                height={100}
                innerPadding={0.08}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge2Bis() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge}
                steps={5}
                width={200}
                height={100}
                margin={0}
                arcSize={50}
                innerPadding={0.08}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge3() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge}
                steps={5}
                width={100}
                height={200}
                innerPadding={0.08}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge4() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge2}
                ranges={[
                    {
                        value: 0,
                        label: 'Nascent',
                        text : 'Analysing portefolios distribution, the IP market is considered as nascent'
                    },
                    {
                        value: 0.15,
                        label: 'Emerging',
                        text : 'Analysing portefolios distribution, the IP market is considered as emerging'
                    },
                    {
                        value: 0.33,
                        label: 'Moderate',
                        text : 'Analysing portefolios distribution, the IP market is considered as moderate'
                    },
                    {
                        value: 0.65,
                        label: 'Mature',
                        text : 'Analysing portefolios distribution, the IP market is considered as mature'
                    },
                    {
                        value: 0.87,
                        Label: 'Consolidated',
                        text : 'Analysing portefolios distribution, the IP market is considered as consolidated'
                    }
                ]}
                width={200}
                height={200}
                roundedStep
                angleStart={0}
                innerPadding={0.08}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge5() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                straight
                data={dataGauge}
                steps={4}
                width={110 * 1}
                height={67 * 1}
                innerPadding={0.4065}
                overflow={0.24}
                shape="puzzle"
                color="var(--insight-color)"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge6() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge2}
                width={100}
                height={200}
                angleStart={0}
                angleEnd={Math.PI * 2}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge7() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                innerRadius={0.8}
                data={dataGauge2}
                steps={18}
                innerPadding={0}
                emptyStepSize={1}
                width={100}
                height={100}
                angleStart={0}
                angleEnd={Math.PI * 2}
                color="#13275c"
                gradientColor="#6983ac"
                fontSize={9}
                noLabel
                needleSize={0}
                icon="kpi/buzz"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge8() {
        return (
            <Graph
                dataIsLoaded
                type="Gauge"
                thickness={1}
                data={dataGauge2}
                steps={60}
                emptyStepSize={2}
                width={100}
                height={200}
                angleStart={-Math.PI / 2}
                angleEnd={Math.PI / 2}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGauge9() {
        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                thickness={1}
                data={dataGauge}
                steps={5}
                width={200}
                height={100}
                innerPadding={0.08}
                outerPadding={0.04}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={9}
                angleEnd={6.28318}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPlanet1() {
        return (
            <Graph
                type="Planet"
                data={dataPlanet1}
                width={1000}
                height={500}
                margin={40}
                innerPadding={0.02}
                outerMargin={0.10}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={16}
                showLandmark
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPlanet2() {
        return (
            <Graph
                type="Planet"
                data={dataPlanet2}
                width={360}
                height={180}
                margin={0}
                innerPadding={-0.10}
                outerMargin={0.0}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={16}
                showGap
                labelAnchorOffset={10}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPlanet3() {
        return (
            <Graph
                type="Planet"
                data={dataPlanet3}
                width={360}
                height={180}
                margin={0}
                innerPadding={-0.10}
                outerMargin={0.0}
                color="var(--insight-color)"
                gradientColor="#cef3fd"
                fontSize={16}
                showGap
                labelAnchorOffset={10}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderMap1() {
        return (
            <Graph
                type="Map"
                data={dataMap1}
                width={300}
                height={160}
                color="#7576B0"
                gradientColor="#ce0000"
                fontSize={16}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderMap2() {
        return (
            <Graph
                type="Map"
                data={dataMap2}
                width={600}
                height={320}
                color="#7576B0"
                gradientColor="#ce0000"
                fontSize={16}
                noLabel
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderTagCloud1() {
        const tempdata = {
            serie: _.map(dataTagCloud.serie, (d) => {
                const value = Math.random() * 50 + 50;
                return {
                    text: d,
                    value
                };
            })
        };

        return (
            <Graph
                type="TagCloud"
                data={tempdata}
                dataIsLoaded
                width={800}
                height={400}
                color="#7576B0"
                fontSizeMin={9}
                fontSizeMax={30}
                normalized
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderTagCloud2() {
        const tempdata = {
            serie: _.orderBy(
                _.map(dataTagCloud.serie, (d) => {
                    const value = Math.random() * 95 + 5;
                    return {
                        text: d,
                        value
                    };
                }),
                ['value'],
                ['desc']
            )
        };

        return (
            <Graph
                type="TagCloud"
                data={tempdata}
                dataIsLoaded
                width={310}
                height={130}
                color="#bf3e4a"
                fontSizeMin={3}
                fontSizeMax={5}
                showFeaturedWords
                featuredBackground="#f7e1e3"
                normalized
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPie1() {
        return (
            <Graph
                type="Pie"
                pieSize={40}
                horizontalCenter={0.5}
                data={dataPie}
                dataIsLoaded
                width={600}
                height={320}
                color="#cc5965"
                fontSize={14}
                showLabel
                regroupOtherOver={0.9}
                maxItem={11}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPie2() {
        return (
            <Graph
                type="Pie"
                pieSize={20}
                horizontalCenter={0.65}
                data={dataPie}
                dataIsLoaded
                width={350}
                height={170}
                color="#cc5965"
                fontSize={10}
                showLabel
                regroupOtherOver={0.5}
                maxItem={3}
                legend="Graphene battery researchs are mainly focusing on"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPie3() {
        return (
            <Graph
                type="Pie"
                pieSize={100}
                data={dataPie2}
                dataIsLoaded
                width={70}
                height={70}
                color="#597c89"
                strokeColor="none"
                otherColor="#CCD7DA"
                fontSize={10}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPie4() {
        return (
            <Graph
                type="Pie"
                pieSize={50}
                horizontalCenter={0.3}
                data={dataPie3}
                dataIsLoaded
                width={500}
                height={500}
                color="#597c89"
                strokeColor="none"
                otherColor="#CCD7DA"
                fontSize={16}
                showLabel
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderPie5() {
        return (
            <Graph
                type="Pie"
                pieSize={50}
                data={dataPie4}
                dataIsLoaded
                width={1000}
                height={800}
                color="#597c89"
                strokeColor="none"
                otherColor="#CCD7DA"
                regroupOtherOver={0.5}
                fontSize={18}
                showLabel
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderDoubleRadialBar() {
        return (
            <Graph
                type="DoubleRadialBar"
                dataIsLoaded
                data={dataDoubleRadial}
                width={400}
                height={400}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderTopCircle1() {
        return (
            <Graph
                type="TopCircle"
                data={dataTopCircle1}
                dataIsLoaded
                width={240 * 4}
                height={130 * 4}
                icon="academics"
                color="#8d2983"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderTopCircle2() {
        return (
            <Graph
                type="TopCircle"
                data={dataTopCircle2}
                dataIsLoaded
                width={240 * 1}
                height={130 * 1}
                icon="academics"
                color="#8d2983"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderTopCircle3() {
        return (
            <Graph
                type="TopCircle"
                data={dataTopCircle3}
                dataIsLoaded
                width={240 * 1}
                height={130 * 1}
                color="#8d2983"
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderFoamtree() {
        return (
            <Graph
                type="Foamtree"
                data={dataFoamtree1}
                width={240 * 1}
                height={130 * 1}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderNetwork() {
        const { dataXml } = this.state;

        return !_.isUndefined(dataXml)
            ? (
                <Graph
                    type="Network"
                    dataIsLoaded
                    data={{ stats: { gexf: dataXml } }}
                    width={1200 * 1}
                    height={900 * 1}
                    startingAlpha={0}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderRaceLine1() {
        const { dataTheRace } = this.state;

        return !_.isUndefined(dataTheRace)
            ? (
                <Graph
                    type="RaceLine"
                    dataIsLoaded
                    data={{ content: dataTheRace }}
                    width={750 * 1}
                    height={300 * 1}
                    featuredIds={[
                        '~*kfzp6stu3wdsna2wjkmw4zi7ze000000',
                        '~*wta7id4v63wqwtix2g55y72uiy000000',
                        '~*bavmd6g3it7gabe2sj45mugqsq000000',
                    ]}

                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderRaceLine2() {
        const { dataTheRace } = this.state;

        return !_.isUndefined(dataTheRace)
            ? (
                <Graph
                    type="RaceLine"
                    dataIsLoaded
                    data={{ content: dataTheRace }}
                    width={750 * 1}
                    height={300 * 1}
                    featuredIds={[
                        '~*kfzp6stu3wdsna2wjkmw4zi7ze000000',
                        '~*wta7id4v63wqwtix2g55y72uiy000000',
                        '~*cavretqj75zupukycvhh2xqd4e000000',
                        '~*4ddbrhwra7uu2y5rwl46i76bna000000',
                        '~*bavmd6g3it7gabe2sj45mugqsq000000',
                    ]}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderRaceLine3() {
        const { dataTheRace } = this.state;

        return !_.isUndefined(dataTheRace)
            ? (
                <Graph
                    type="RaceLine"
                    dataIsLoaded
                    data={{ content: dataTheRace }}
                    featuredIds={[
                        '~*kfzp6stu3wdsna2wjkmw4zi7ze000000',
                        '~*wta7id4v63wqwtix2g55y72uiy000000',
                    ]}
                    width={1370 * 1}
                    height={520 * 1}
                    fontSize={14}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderVenn(num, color) {
        const dataVenn = this.state[`dataVenn${num}`];

        return !_.isUndefined(dataVenn)
            ? (
                <Graph
                    type="Venn"
                    dataIsLoaded
                    data={{ stats: { leftLabel: 'Rebel Aliance', rightLabel: 'Competitors' }, content: dataVenn }}
                    width={1370 * 1}
                    height={520 * 1}
                    fontSize={20}
                    color={color}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderLittleVenn(num, color) {
        const dataVenn = this.state[`dataVenn${num}`];

        return !_.isUndefined(dataVenn)
            ? (
                <Graph
                    type="Venn"
                    dataIsLoaded
                    data={{ stats: { leftLabel: 'Rebel Aliance', rightLabel: 'Competitors' }, content: dataVenn }}
                    width={411 * 1}
                    height={188 * 1}
                    color={color}
                    fontSize={13}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBet1() {
        const { dataBet } = this.state;

        return !_.isUndefined(dataBet)
            ? (
                <Graph
                    type="Bet"
                    dataIsLoaded
                    data={{ content: dataBet }}
                    width={1370 * 1}
                    height={520 * 1}
                    fontSize={16}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderBet2() {
        const { dataBet2 } = this.state;

        return !_.isUndefined(dataBet2)
            ? (
                <Graph
                    type="Bet"
                    dataIsLoaded
                    data={{ content: dataBet2 }}
                    width={411 * 1}
                    height={188 * 1}
                    labelRatio={1 / 2}
                />
            )
            : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderShape1() {
        return (
            <Graph
                type="Shape"
                data={dataShape1}
                dataIsLoaded
                width={240 * 1}
                height={130 * 1}
                margin={10}
                fontSize={20}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderShape2() {
        return (
            <Graph
                type="Shape"
                data={dataShape2}
                dataIsLoaded
                width={240 * 1}
                height={130 * 1}
                margin={10}
                fontSize={20}
            />
        );
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderOneStat() {
        return (
            <Graph
                type="OneStat"
                data={{ stats: { value: 666 } }}
                dataIsLoaded
                text="diable"
                width={240 * 1}
                height={130 * 1}
                margin={10}
                fontSize={20}
            />
        );
    }

    /**
    * Render a metric
    *
    * @return html
    */
    renderBestWhy() {
        const { search } = this.props;

        return search ? (
            <Metrics
                metricsFrom="dashboard"
                metricsKey="_test-inline-suffix"
                search={search.get('model')}
                width={411 * 1}
                height={188 * 1}
            />
        ) : null;
    }

    /**
    * Render a graph
    *
    * @return html
    */
    renderGrid1() {
        const dataGrid = _.times(250, (i) => ({
                id   : _.uniqueId('data-grid'),
                label: `label-${i}`,
                type : 'orgunit'
            })),
            { width, height } = this.props, // eslint-disable-line
            tempdata = {
                serie: _.map(dataTagCloud.serie, (d) => {
                    const value = Math.random() * 50 + 50;
                    return {
                        text: d,
                        value
                    };
                })
            };

        return (
            <Graph
                type="Grid"
                data={dataGrid}
                width={width}
                height={height}
                minItemWidth={200}
                minItemHeight={200}
                maxGrowFactor={0.4}
                itemPadding={10}
                itemComponent={(itemProps) => (
                    <Graph
                        margin={0}
                        type="TagCloud"
                        data={tempdata}
                        color="#7576B0"
                        fontSizeMin={9}
                        fontSizeMax={30}
                        normalized
                        width={itemProps.width}
                        height={itemProps.height}
                    />
                )}
            />
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {   // eslint-disable-line
        return (
            <div className="metrics-tester" style={{ top: 50, position: 'relative', padding: '50px' }}>
                {/*
                {this.renderPlanet1()}
                {this.renderPlanet2()}
                {this.renderPlanet3()}

                {this.renderFoamtree()}
                {this.renderFoamtree()}
                {this.renderShape1()}
                {this.renderShape2()}
                {this.renderGrid1()}

                {this.renderMap1()}
                {this.renderMap2()}

                <Icon id="KPI/buzz" />
                <svg width={100} height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Icon id="KPI/buzz" SVG />
                    </g>
                </svg>

                <svg width={100} height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="KR" SVG discShaped />
                    </g>
                </svg>
                <svg width="100" height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="KR" SVG discShaped borderSize={2} borderColor="green" />
                    </g>
                </svg>
                <svg width="100" height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="KR" SVG discShaped borderSize={10} borderColor="red" />
                    </g>
                </svg>
                <svg width={100} height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="KR" SVG />
                    </g>
                </svg>
                <svg width={100} height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="KR" SVG borderSize={2} borderColor="green" />
                    </g>
                </svg>
                <svg width={100} height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="CH" SVG borderSize={2} borderColor="green" />
                    </g>
                </svg>
                <svg width="100" height={100} style={{ background: '#ccc' }}>
                    <g transform="translate(50 50)">
                        <Flag iso="CH" SVG discShaped borderSize={2} borderColor="green" />
                    </g>
                </svg>
                <div style={{ background: '#ddd' }}>
                    <Flag iso="US" height={33} />
                    <Flag iso="CN" />
                    <Flag iso="JP" />
                    <Flag iso="JP" discShaped />
                    <Flag iso="JP" discShaped borderSize={2} borderColor="green" />
                    <Flag iso="JP" discShaped borderSize={10} borderColor="red" />
                    <Flag iso="US" borderSize={2} borderColor="green" />
                    <Flag iso="CH" borderSize={2} borderColor="green" />
                    <Flag iso="CH" discShaped borderSize={2} borderColor="green" />
                </div>

                {this.renderDoubleRadialBar()}

                {this.renderOneStat()}

                {this.renderPie1()}
                {this.renderPie2()}
                {this.renderPie3()}
                {this.renderPie4()}
                {this.renderPie5()}

                {this.renderTagCloud2()}
                {this.renderTagCloud1()}

                {this.renderTagCloud2()}
                {this.renderTagCloud1()}

                {this.renderGauge1()}
                {this.renderGauge2()}
                {this.renderGauge2Bis()}
                {this.renderGauge3()}
                {this.renderGauge4()}
                {this.renderGauge5()}
                {this.renderGauge6()}
                {this.renderGauge7()}
                {this.renderGauge8()}
                {this.renderGauge9()}

                {this.renderTopCircle2()}
                {this.renderTopCircle3()}
                {this.renderTopCircle1()}

                {this.renderNetwork()}

                {this.renderBestWhy()}

                {this.renderRaceLine1()}

                {this.renderBet1()}
                {this.renderBet2()}

                {this.renderRaceLine3()}
                {this.renderRaceLine2()}

                {this.renderLittleVenn('0-s')}
                {this.renderLittleVenn('1-s')}
                {this.renderLittleVenn('2-s')}
                {this.renderLittleVenn('3-s')}
                {this.renderLittleVenn('4-s')}
                {this.renderLittleVenn('5-s')}
                {this.renderLittleVenn('6-s')}

                {this.renderVenn('0-inv')}
                {this.renderVenn(0)}
                {this.renderVenn(1)}
                {this.renderVenn(2)}
                {this.renderVenn(3)}
                {this.renderVenn(4)}
                {this.renderVenn(5)}
                {this.renderVenn(6)}

                {this.renderVenn('0b','#393a62')}
                {this.renderVenn(1, '#393a62')}
                {this.renderVenn(2, '#8d2983')}
                {this.renderVenn(3 ,'#cc5965')}
                {this.renderVenn(4, '#cc5965')}

                {this.renderBar1()}
                {this.renderBar2()}
                {this.renderBar3()}
                {this.renderBar4()}
                {this.renderBar5()}

                {this.renderLine2()}
                {this.renderLine3()}
                {this.renderLine4()}

                <div style={{ width: '1500px', overflow: 'scroll' }}>
                    <div style={{ width: '3000px' }}>
                        {this.renderLine1()}
                    </div>
                </div>
                { */}

                {this.renderBarStack0()}
                {this.renderStackedLine1()}

            </div>
        );
    }

}

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    search: state.getIn(['navigation', 'search']),
});

export default connect(mapStateToProps, {
    fetchSearch,
})(MetricsTester);
