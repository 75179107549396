import Immutable      from 'immutable';
import * as types     from '../actions/types/knowledge';

/**
* Reset the storage state with a new version
*
* @return Immutable.Map
*/
const resetVersion = (state, version) => {
    // Create a fresh knowledge library with the new version
    const cleanState = {
        version,
        library  : new Immutable.Map(),
        endpoints: new Immutable.List(),
    };

    return cleanState;
};

/**
* Reset the storage state with a new version
*
* @return Immutable.Map
*/
const storeNewKnowledge = (state, parameters) => ({
    ...state,
    library: state.library.set(parameters.type, new Immutable.List(parameters.list))
});

/**
* Reset the storage state with a new version
*
* @return Immutable.Map
*/
const storeNewKnowledgeEndpoints = (state, parameters) => ({
    ...state,
    endpoints: new Immutable.List(parameters.list)
});

/**
* Manage an immutable identity map for all models returned by the API
*
* @return state As a fresh Immutable Map
*/
const Knowledge = (state = {}, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the state with API synced member data
        case types.RESET_VERSION:
            return resetVersion(state, action.payload);

        // Update the state with API synced member data
        case types.NEW_KNOWLEDGE_ACQUIRED:
            return storeNewKnowledge(state, action.payload);

        case types.NEW_KNOWLEDGE_ENDPOINTS_ACQUIRED:
            return storeNewKnowledgeEndpoints(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Knowledge;
