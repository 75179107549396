// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-model {
  cursor: pointer;
  /* * { color: #DDD; transition: color 200ms; } */
}
.action-model.is-bookmarked {
  cursor: pointer;
}
.action-model.is-bookmarked * {
  color: #CA9A00 !important;
}
.action-model.is-bookmarked:hover {
  cursor: pointer;
}
.action-model.is-bookmarked:hover * {
  color: #CA9A00 !important;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Action/assets/model.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACF,gDAAgD;AAClD;AAEI;EACI,eAAA;AAAR;AADI;EAEQ,yBAAA;AAEZ;AAAQ;EACI,eAAA;AAEZ;AAHQ;EAEQ,yBAAA;AAIhB","sourcesContent":[".action-model {\n    cursor: pointer;\n\n    /* * { color: #DDD; transition: color 200ms; } */\n\n    &.is-bookmarked {\n        cursor: pointer;\n        * { color: #CA9A00 !important; }\n\n        &:hover {\n            cursor: pointer;\n            * { color: #CA9A00 !important; }\n        }\n\n    }\n\n\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
