import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

import { Icon, Action }     from 'helpers';

/**
* Render the header content of the card
*
* @return JSX
*/
class CollaborationHeader extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render');

        this.state = {
            callbacks: {}
        };
    }

    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidUpdate() {
        const { entity, registerMetadata } = this.props,
            metadata                       = {
                title  : entity.label,
                actions: this.renderCollaborationActions()
            };

        // Nothing to render => slow down thr process...

        clearTimeout(this.registerMetadataTiOut);

        this.registerMetadataTiOut = setTimeout(
            () => registerMetadata(metadata),
            500
        );
    }

    /**
    * Render collaboration action
    *
    *  @return JSX
    */
    renderCollaborationActions() {
        const { addToClipboard } = this.props,
            actions = {
                addToClipboard: {
                    icon: <Icon
                        type="clipboard-add"
                        className="add-to-clipboard"
                        height={18}
                        folder="/shortcuts/"
                    />,
                    cb   : addToClipboard,
                    label: 'Add to export clipboard'
                }
            };

        return (
            <div className="action-model">
                <Action
                    {...this.props}
                    actions={actions}
                />
            </div>
        );
    }

    /**
    * Render collaboration header
    *
    */
    render() {
        return null;
    }

}

CollaborationHeader.propTypes = {
    addToClipboard  : PropTypes.func,
    registerMetadata: PropTypes.func,
    entity          : PropTypes.shape({
        label: PropTypes.string
    }),
};

CollaborationHeader.defaultProps = {
    entity: null
};

export default CollaborationHeader;
