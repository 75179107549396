import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Modal, Skeleton, Alert } from 'antd';
import _                          from 'lodash';
import { connect }                from 'react-redux';
import { learn }                  from 'store/actions/knowledge';
import { htmlize }                from 'utils/text';
import { Icon }                   from 'helpers';

import './assets/helpsearchsyntax.less';

/**
 * Class HelpGeneric
 * Display the help popin using the key from the knowledge help-generics
 * Provides a clickable element.
 */
class HelpGeneric extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'showModal', 'hideModal');

        this.state = {
            modalVisible: false,
            helpGenerics: null
        };
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        // Learn all needed Knowledges, then fill the state with collections
        learnKnowledge('help-generics')
            .then(this.setState.bind(this));
    }

    /**
    * Display the modal
    */
    showModal() {
        this.setState({ modalVisible: true });
        throw new Error('bad click! 1');
    }

    /**
    * Hide the modal
    */
    hideModal() {
        this.setState({ modalVisible: false });
    }

    /**
    * Render the content of the popin
    *
    * @return JSX
    */
    renderModal() {     // eslint-disable-line  max-lines-per-function
        const { helpGenerics } = this.state,
            { id }             = this.props,
            iconInfo           = (
                <Icon type="infoBubbleHome" folder="/home/"
                    width={18}
                />
            );

        if (!helpGenerics) {
            return <Skeleton paragraph={{ rows: 10 }} active />;
        }

        const textData = _.find(helpGenerics.toArray(), { id }) || null;

        if (!textData) {
            return `Sorry, we could not find help for ${id}.`;
        }

        return (
            <div className="help-search-syntax">
                <div className="container">
                    {_.map(textData.sections, (section, index) => {
                        const id    = `${section.title}-${index}`,
                            content = section.title ? [<h2 key={`h2-${id}`}>{section.title}</h2>] : [];

                        // TODO: type info in knowledge
                        if (index === 0) {
                            content.push(
                                <Alert banner icon={iconInfo}
                                    key="alert-info" message={section.content}
                                    rootClassName="insight-alert-info" type="info"
                                />
                            );

                            return content;
                        }

                        if (section.type === 'table') {
                            content.push(
                                <table key={`table-${id}`} className="section">
                                    <tbody>
                                        {_.map(section.content, (row, i) => (
                                            <tr key={`row-${id}-${i}`}>
                                                {_.map(row, (cell, ind) => (
                                                    <td key={`cell-${id}-${i}-${ind}`}>
                                                        {cell}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            );
                            return content;
                        }

                        if (section.type === 'ul') {
                            content.push(
                                <ul key={`ul-${id}`} className="section">
                                    {_.map(section.content, (item, i) => (
                                        <li key={`ul-${id}-${i}`}>{htmlize(item)}</li>
                                    ))}
                                </ul>
                            );
                            return content;
                        }

                        content.push(
                            <div className="section" key={`section-${id}`}>
                                {_.map(section.content, (par) => (<p key={`p-${id}-${par}`}>{htmlize(par)}</p>))}
                            </div>
                        );

                        return content;
                    })}
                </div>
            </div>
        );
    }

    /**
    * Render the button to trigger the popin
    *
    * @return JSX
    */
    render() {
        const { modalVisible } = this.state;

        return (
            <>
                <Icon type="infoBubbleHome" folder="/home/"
                    width={20} onClick={this.showModal}
                />
                <Modal
                    title="Search help"
                    footer={null}
                    open={modalVisible}
                    onCancel={this.hideModal}
                    className="help-search-syntax-popin insight-modal"
                    width={860}
                    bodyStyle={{height: 500}}
                >
                    {this.renderModal()}
                </Modal>
            </>
        );
    }

}

HelpGeneric.propTypes = {
    learnKnowledge: PropTypes.func.isRequired,
    id            : PropTypes.string.isRequired
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(HelpGeneric);

