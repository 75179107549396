import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import './assets/legals.less';

import HammerPNG           from './assets/hammer.png';
import HandPNG             from './assets/hand.png';

/**
 * Display the status of litigation and opposition.
 *
 */
class Legals extends Component {

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Render the legal status
    *
    * @return JSX
    */
    render() {
        const { litigation, opposition } = this.props;

        return (
            <div className="legals">
                <div className={litigation ? '' : 'inactive'}>
                    <div className="img-container">
                        <img src={HammerPNG} alt="" />
                    </div>
                    {litigation ? 'litigation' : 'no litigation'}
                </div>
                <div className={opposition ? '' : 'inactive'}>
                    <div className="img-container">
                        <img src={HandPNG} alt="" />
                    </div>
                    {opposition ? 'opposition' : 'no opposition'}
                </div>
            </div>
        );
    }

}

Legals.propTypes = {
    litigation: PropTypes.bool,
    opposition: PropTypes.bool
};

Legals.defaultProps = {
    litigation: false,
    opposition: false
};

export default Legals;
