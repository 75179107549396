/**
 * Keep all actions types listed there
 *
 */

// Triggered when the member has been fetched
export const MEMBER_UPDATED        = 'MEMBER_UPDATED';
export const MEMBER_FETCHED        = 'MEMBER_FETCHED';
export const MEMBER_DISCONNECTED   = 'MEMBER_DISCONNECTED';

// Manage token:
export const TOKEN_FETCHED         = 'TOKEN_FETCHED';
export const OBTAIN_TOKEN          = 'OBTAIN_TOKEN';
export const LOGGED_IN             = 'LOGGED_IN';
export const LOGIN_SUCCESS         = 'LOGIN_SUCCESS';

// Manage Platforms:
export const PLATFORMS_FETCH        = 'PLATFORMS_FETCH';
export const PLATFORMS_TOKEN        = 'PLATFORMS_TOKEN';
export const PLATFORMS_DELETE_TOKEN = 'PLATFORMS_DELETE_TOKEN';

