/**
 * Actions for Analyses filters
 *
 */
import * as types from '../types/navigation';

/**
* Store filters of analyse indexed by analyseId
*
* @return {Promise}
*/
export const storeNodePreferences = (nodeId, preferences) => (dispatch) => {
    dispatch({ type: types.STORE_NODE_PREFERENCES, payload: { nodeId, preferences } });
};

/**
 * Get node preferences from id
 *
 * @param {string} id
 *
 * @returns {object}
 */
export const getNodePreferences = id => (dispatch, getState) => {
    const navigation     = getState().get('navigation'),
        nodesPreferences = navigation?.getIn(['nodesPreferences', id]);

    return nodesPreferences || {};
};

// Export default
export default {
    storeNodePreferences,
    getNodePreferences,
};
