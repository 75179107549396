/**
 * Main Insight autocomplete orgunit
 *
 * @return Component
 */
import _                             from 'lodash';
import React, { Component }          from 'react';
import { connect }                   from 'react-redux';
import PropTypes                     from 'prop-types';
import { Skeleton }                  from 'antd';

import {
    Collection,
    Flag,
    Icon,
}                                    from 'helpers';

import { learn }                     from 'store/actions/knowledge';
import { getUserViewItemFromModel } from 'store/actions/userView';

import './assets/orgunit.less';

/**
* The header CC for Insight, this should be smart.
*
*
*/
class Orgunit extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        _.bindAll(this, 'renderSuffix', 'onSelectEntity');
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['tags']).then(this.setState.bind(this));
    }


    /**
    * Render part ofs
    *
    * @return self
    */
    renderPartOfs(entity) {
        const { onSelect } = this.props,
            { partOf }     = entity;

        if (!partOf) {
            return false;
        }

        return (
            <span className="part-ofs" onClick={() => false}>
                <Collection
                    mode="inline"
                    allowExport={false}
                    className="part-ofs"
                    onClick={onSelect}
                    entities={entity.partOf}
                />
            </span>
        );
    }

    /**
    * Render City & Country
    *
    * @return self
    */
    renderCityCountry(entity) {
        return (
            <span key={`city-country-${entity.id}`} className="city-country"
                onClick={() => false}
            >
                <Flag iso={entity.country} width={15}
                    height={10}
                />
                {entity.org_city}
                {entity.isHQ && (
                    <span className="hq"> - HQ</span>
                )}
            </span>
        );
    }

    /**
    * Render entities suffixes
    *
    * @return JSX
    */
    renderSuffix(entity) {
        const { renderSuffix } = this.props;
        return [
            this.renderBookmarkedIcon(entity),
            (
                <span key="tags-part-ofs" className="inline">
                    { this.renderTags(entity) }
                    { this.renderPartOfs(entity) }
                </span>
            ),
            this.renderCityCountry(entity),
            renderSuffix(entity),

        ];
    }



    /**
    * Render tags for current bookmark
    *
    * @param object entity
    *
    * @return JSX
    */
    renderTags(entity) {
        const entityTags = this.getEntityTags(entity),
            size         = 12;

        return entityTags && entityTags?.size > 0 && (
            <span className="tags">
                {entityTags.map((tag) => {
                    const style  = {
                        color   : tag.color,
                        right   : 0,
                        height  : size,
                        width   : size,
                        fontSize: size
                    };

                    return (
                        <Icon
                            key={tag.label}
                            type={tag.icon}
                            color={tag.color}
                            theme="filled"
                            style={style}
                            height={size}
                            width={size}
                            className="icon"
                        />
                    );
                })}
            </span>
        );
    }


    /**
     * Get current tags of entity
     *
     * @returns array
     */
    getEntityTags(entity) {
        const {
                getUserViewItemFromModel,
            }            = this.props,
            streamEntity = getUserViewItemFromModel(entity, 'bookmark'),
            bookmark     = streamEntity && streamEntity.model,
            { tags }     = this.state;

        return bookmark && tags
            ? tags.filter((tag) => bookmark?.tags.indexOf(tag.id) !== -1)
            : [];
    }


    /**
    * Render the icon of the entity
    *
    * @return JSX
    */
    renderBookmarkedIcon(entity) {
        const {
                getUserViewItemFromModel
            }            = this.props,
            typeSize     = 15,
            streamEntity = getUserViewItemFromModel(entity, 'bookmark');

        return streamEntity && (
            <div key={`entity-bookmarked-${entity.id}`} className="entity-bookmarked"
                style={{ width: typeSize+10, height: typeSize+10 }}
            >
                <Icon
                    id="bookmarked"
                    color="#ffb400"
                    width={typeSize}
                    title="is bookmarked"
                    style={{ top: 5, left: 5 }}
                />
            </div>
        );
    }



    /**
    * Render the loading state
    *
    * @return JSX
    */
    renderLoading() {
        const { suggestions }      = this.props,
            { data:organizations } = suggestions || {};

        return (
            <div className="menu orgunits">
                <span className="title">Organizations</span>
                {organizations.slice(0, 5).map((organization, index) => (
                    <div className="is-loading" key={index}>
                        <Skeleton paragraph={{ row: 1 }} title={false}
                            avatar
                        />
                    </div>
                ))}
            </div>
        );
    }

    /**
     * On select entity
     *
     * @param {object}
     */
    onSelectEntity(entity) {
        const { onSelect } = this.props;

        onSelect(entity);

        // We have to re-render in some case. Ex: re-render suffix with checkbox
        this.forceUpdate();
    }

    /**
    * Render orgunits collections
    *
    * @return JSX
    */
    render() {
        const { selected, suggestions } = this.props,
            { data:organizations }      = suggestions || {};

        if (!organizations || !organizations.length) {
            return false;
        }

        // Loading state
        if (!organizations[0] || organizations[0].isLoading) {
            return this.renderLoading();
        }

        // Collection
        return (
            <div className="menu orgunits">
                <span className="title">
                    <Icon folder="/shortcuts/" height={14}
                        type="companies"
                    />
                    Organizations
                </span>
                <Collection
                    mode="inline"
                    key="orgunits"
                    allowExport={false}
                    className="orgunits"
                    entityClassName="suggest"
                    onClick={this.onSelectEntity}
                    bindClickOnComponent
                    logoSize={40}
                    entities={
                        organizations.map((orgunit, index) => {
                            orgunit.isSelected = index === selected;
                            return orgunit;
                        })
                    }
                    renderSuffix={this.renderSuffix}
                />
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
Orgunit.propTypes = {
    onSelect                : PropTypes.func,
    renderSuffix            : PropTypes.func,
    selected                : PropTypes.string,
    suggestions             : PropTypes.object,
    learnKnowledge          : PropTypes.func,
    getUserViewItemFromModel: PropTypes.func,
};

Orgunit.defaultProps = {
    renderSuffix: () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    getUserViewItemFromModel,
    learnKnowledge: learn
})(Orgunit);

