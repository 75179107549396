// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Main Insight no data
*
*/
.dashboard-collection .content-list > .actions {
  display: none;
}
.dashboard-collection:hover .content-list > .actions {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/resource-collection.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAGQ,aAAA;AAFR;AAKI;EAGQ,cAAA;AALZ","sourcesContent":["/**\n* Main Insight no data\n*\n*/\n\n.dashboard-collection  {\n\n    .content-list > .actions{\n        display: none;\n    }\n\n    &:hover{\n\n        .content-list > .actions{\n            display: block;\n        }\n\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
