import _                                      from 'lodash';
import React, { Component }                   from 'react';
import PropTypes                              from 'prop-types';
import { connect }                            from 'react-redux';
import { learn }                              from '../../store/actions/knowledge';

import './assets/textInfo.less';

/**
 * TextInfo filter
 */
class TextInfo extends Component {

    /**
    * Constructor, init the state
    */
    constructor(props) {
        const { value, options, filterKey } = props;

        super(props);

        this.state = {
            key        : options && options.knowledge ? options.knowledge : filterKey,
            selectedIds: value || []
        };
    }

    /**
    * When component is ready, learn knowledge to display values.
    */
    componentDidMount() {
        const { learnKnowledge, filterKey, items } = this.props,
            { key } = this.state;

        if (_.isArray(items)) {
            this.setState({ [_.camelCase(filterKey)]: items });
            return;
        }

        // If (key !== filterKey) {
        learnKnowledge(key)
            .then(this.setState.bind(this));
        // }
    }

    /**
    * Render the filter List
    *
    * @return JSX
    */
    render() {
        const { filterContent }= this.props;
        return (
            <div className="textInfo">
                {filterContent}
            </div>
        );
    }

}

TextInfo.propTypes = {
    filterKey    : PropTypes.string.isRequired,
    filterContent: PropTypes.string,
    items        : PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.shape({}))]
    ),
    learnKnowledge: PropTypes.func.isRequired,
    value         : PropTypes.arrayOf(PropTypes.string).isRequired,
    options       : PropTypes.shape({
        knowledge: PropTypes.any
    }),
};

TextInfo.defaultProps = {
    items  : null,
    options: null,
};

/**
 * Bind the store to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(TextInfo);

