import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Icon }             from 'helpers';

import './assets/text-input.less';

/**
*  Component to use input text with
*  - clear button
*  - onTextChange callback
*
*/
class TextInput extends Component {

    state = { }

    /**
    *  This is the constructor for the component. It is called when the component is created.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'handleInputKeyup', 'clearValue');

        this.inputRef =  React.createRef();
    }


    /**
    *  It is called when the component is created.
    */
    componentDidMount() {
        this.manageBinding();
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
        this.manageBinding();
    }


    /**
    * Force dom binding
    *
    * return void
    */
    manageBinding() {
        const inputNode = this.inputRef.current;

        // Input binding
        if (inputNode) {
            inputNode.removeEventListener('keyup',    this.handleInputKeyup);
            inputNode.addEventListener('keyup',       this.handleInputKeyup);
        }
    }

    /**
    * Handle the keypress on input
    *
    * @return
    */
    handleInputKeyup(e) {
        const input = this.inputRef.current,
            { value }  = input;

        // Clear the value on escape press
        if (e.keyCode === 27) {
            e.stopPropagation();

            this.clearValue();

            return false;
        }

        this.onTextChange(value);

        return true;
    }


    /**
     * Clear the value of the input
     */
    clearValue() {
        const input = this.inputRef.current;

        this.onTextChange(false);
        input.value = '';
    }


    /**
     * The function takes in a value and passes it to the onTextChange function in the parent component
     */
    onTextChange(value) {
        const { onTextChange } = this.props;

        this.setState({
            value,
        });

        onTextChange(value);
    }

    /**
    * Returning the HTML for the component.
    *
    * @returns JSX
    */
    render() {
        const {
                placeholder,
            }         = this.props,
            { value } = this.state;

        return (
            <div className="text-input-filter">
                <input
                    ref={this.inputRef}
                    className="search"
                    placeholder={placeholder}
                    onChange={_.noop}
                />
                <Icon id="magnify" className="magnify"
                    height={16} color="#e0e0e0"
                />
                {value && (
                    <Icon
                        id="close"
                        className="close"
                        height={10}
                        color="#e0e0e0"
                        onClick={this.clearValue}
                        dataQaKey="clear the text filter"
                    />
                )}
            </div>
        );
    }

}

TextInput.propTypes = {
    onTextChange: PropTypes.func.isRequired,
    placeholder : PropTypes.string,
};

TextInput.defaultProps = {
    placeholder: '',
};

export default TextInput;

