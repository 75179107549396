import React, { PureComponent } from 'react';
import _                        from 'lodash';
import PropTypes                from 'prop-types';
import { hsl as d3Hsl }         from 'd3';

import LineArea                 from './Graph/types/LineArea';
import TagCloud                 from './Graph/types/TagCloud';
import Bar                      from './Graph/types/Bar';
import DoubleRadialBar          from './Graph/types/DoubleRadialBar';
import Gauge                    from './Graph/types/Gauge';
import Planet                   from './Graph/types/Planet';
import GeoMap                   from './Graph/types/GeoMap';
import Pie                      from './Graph/types/Pie';
import TopCircle                from './Graph/types/TopCircle';
import MultiGraph               from './Graph/types/MultiGraph';
import Foamtree                 from './Graph/types/Foamtree';
import Shape                    from './Graph/types/Shape';
import Network                  from './Graph/types/Network';
import OneStat                  from './Graph/types/OneStat';
import RaceLine                 from './Graph/types/RaceLine';
import Bet                      from './Graph/types/Bet';
import Venn                     from './Graph/types/Venn';

import {
    onParentResize,
    getDomColor
}                               from 'utils/dom';
import { deepEqual }            from 'utils/object';

import './Graph/assets/main.less';

const graphTypesComponent = {
    LineArea,
    TagCloud,
    Bar,
    DoubleRadialBar,
    Gauge,
    Planet,
    GeoMap,
    Pie,
    TopCircle,
    Foamtree,
    Shape,
    Network,
    OneStat,
    MultiGraph,
    RaceLine,
    Bet,
    Venn,
};

/**
 * General graph component
 *
 */
class Graph extends PureComponent {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'handleClick', 'filterCb', 'resetSelectedAttributes', 'updateSelectedValues',
            'handleMouseMove', 'handleMouseOut', 'setOnMouseElement', 'getData', 'getSelectedValues', 'getDataParameters');

        this.graphRef = React.createRef();

        this.state = {
            clicked          : false,
            mouseDragged     : false,
            parentWidth      : 0,
            parentHeight     : 0,
            selectedValues   : [],
            sumSelectedValues: -1,
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks } = this.props;

        this.cleanup = this.graphRef && onParentResize(this.graphRef, this.setParentSize);

        registerCallbacks('resetGraph', this.resetSelectedAttributes);
        registerCallbacks('getData', this.getData);
        registerCallbacks('getSelectedValues', this.getSelectedValues);
        registerCallbacks('getDataParameters', this.getDataParameters);
    }

    /**
    * Triggered when the component did update
    *
    * @return void
    */
    componentDidUpdate() {
        const { sumSelectedValues, selectedValues } = this.state,
            { dataIsLoaded, filterCb, firstCall }   = this.props;

        // Triggered on first call only when we have a selection whose sum of values is equal to 0 (no document)
        if (
            firstCall
            && dataIsLoaded
            && !_.isEmpty(selectedValues)
            && sumSelectedValues === 0
            && filterCb
        ) {
            filterCb([]);
            this.resetSelectedAttributes();
        }
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        const { registerCallbacks } = this.props;

        this.cleanup && typeof this.cleanup === 'function' && this.cleanup();

        // Prevent memory leaks
        registerCallbacks('resetGraph');
        registerCallbacks('getData');
        registerCallbacks('getSelectedValues');
        registerCallbacks('getDataParameters');
    }


    /**
     * Set parent width/height in state
     *
     * @param {*} size
     */
    setParentSize = (size) => {
        const { width, height } = size;
        this.setState({
            parentWidth : width,
            parentHeight: height
        });
    };

    /**
    * Return current data
    *
    * @return void
    */
    getData() {
        const { content, stats } = this.state;

        return { content, stats };
    }

    /**
    * Return current selection
    *
    * @return void
    */
    getSelectedValues() {
        const { selectedValues } = this.state;

        return selectedValues;
    }

    /**
    * Return data xhr paramters
    *
    * @return void
    */
    getDataParameters() {
        const { dataParameters } = this.state;

        return dataParameters;
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) { // eslint-disable-line  max-lines-per-function
        const {
                data,
                fixedBySelection,
                filterValues,
                dataIsLoaded,
                dataParameters: newDataParameters
            } = nextProps,
            // A fixedBySelection = false,
            {
                content       : lastContent,
                stats         : lastStats,
                dataParameters: lastDataParameters,
                selectedValues: lastSelectedValues,
                waitForNewData
            } = prevState,
            { content: newContent, stats: newStats } = data || {},
            contentIsFixedBySelection = fixedBySelection
                ? (_.isObject(lastSelectedValues) ? _.keys(lastSelectedValues)?.length > 0 : lastSelectedValues?.length > 0)
                    || (filterValues && filterValues.length > 0)
                : false,
            useLastContent        = !newContent || (contentIsFixedBySelection && lastContent),
            useLastStats          = !newStats || (contentIsFixedBySelection && lastStats),
            useLastDataParameters = contentIsFixedBySelection && lastDataParameters,
            content               = useLastContent        ? lastContent        : newContent,
            stats                 = useLastStats          ? lastStats          : newStats,
            dataParameters        = useLastDataParameters ? lastDataParameters : newDataParameters,
            contentChange         = !deepEqual(lastContent, content),
            dataKeysChange        = !deepEqual(_.keys(lastContent), _.keys(content)),
            noContents            = _.isUndefined(newContent) && _.isUndefined(lastContent),
            noContent             = !dataIsLoaded && !(fixedBySelection && contentIsFixedBySelection),
            noStats               = _.isUndefined(newStats)   && _.isUndefined(lastStats),
            hasSelectedValues     = !_.isEmpty(_.values(lastSelectedValues)),
            selectedValuesChange  = !deepEqual(lastSelectedValues, filterValues),
            selectedValues        = ((waitForNewData && !contentChange) || hasSelectedValues) && !selectedValuesChange
                ? lastSelectedValues
                : filterValues,
            { start, end }        = selectedValues || {},
            isRangeSelection      = start && end,
            selectedData          = _.filter(content, item => (
                isRangeSelection
                    ? ((item.id ?? item.x) >= start && (item.id ?? item.x) <= end)
                    : _.isArray(selectedValues) && selectedValues.includes(item.id)
            )),
            sumSelectedValues     = _.reduce(selectedData, (sum, selectedDatum) => sum + selectedDatum.value, 0);

        return {
            stats,
            content,
            selectedValues,
            noContent,
            noStats,
            noContents,
            contentChange,
            dataKeysChange,
            dataParameters,
            waitForNewData: waitForNewData ? !contentChange : false,
            sumSelectedValues,
        };
    }

    /**
    * Update selected values in the graph element
    *
    * @param array newValues New selected set
    *
    * @return void
    */
    updateSelectedValues(values) {
        this.setState({
            selectedValues: values
        });
    }

    /**
    * Triggered when filter has been edited from the graph
    *
    * @return void
    */
    filterCb(values) {
        const { filterCb }     = this.props,
            { selectedValues } = this.state,
            content            = this.getContentForComponent(),
            dataIds            = _.flatten(_.values(content) || []).map(value => value.id),
            cleanedValues      = _.isArray(values) ? _.intersection(values, dataIds) : values;

        // Remove values not inside data content

        if (JSON.stringify(selectedValues) === JSON.stringify(cleanedValues)) {
            return;
        }

        // Callback to update parent component
        if (filterCb) {
            filterCb(cleanedValues);
        }

        // Set the state with new filters
        this.updateSelectedValues(cleanedValues);
    }

    /**
    * Reset previously applied filters
    *
    * @return void
    */
    resetSelectedAttributes(options) {
        const { filterKey }       = this.props,
            { filter, values=[] } = options || {},
            rootFilterKey         = filter && filter.substr(0, filter.length - 7);  // Remove '(graph)'

        if (!filter || rootFilterKey === filterKey) {
            this.setState({
                waitForNewData: true,
                selectedValues: values,
            });
        }
    }

    /**
    * Get Skeleton Colors
    *
    * @return object
    */
    getSkeletonGradient() {
        const { color, skeletonColor } = this.props,
            realColor                  = getDomColor(skeletonColor || color),
            dc1                        = d3Hsl(realColor),
            dc2                        = d3Hsl(realColor);

        dc2.l = dc1.l + 0.2;

        if (!skeletonColor) {
            dc1.l = 0.84;
            dc2.l = 0.92;
        }

        return {
            skeletonGradient: `linear-gradient(90deg, ${dc1.toString()} 25%, ${dc2.toString()}  37%, ${dc1.toString()} 63%)`
        };
    }

    /**
    * Put mouse buttons informations in state
    *
    * @return boolean
    */
    handleClick(e) {
        const bttNum      = e.nativeEvent.which;
        const clickStates = { clicked: false, contextClicked: false };

        if (e.type === 'contextmenu') {
            return true;
        }

        if (e.type === 'mousedown') {
            clickStates.clicked        = bttNum === 1;
            clickStates.contextClicked = bttNum === 3;
        }

        if (e.type === 'mouseup' && bttNum === 1) {
            clickStates.clicked = false;
        }

        this.setState(clickStates);

        this.setState({ mouseDragged: false });

        return true;
    }

    /**
    * Set state on mouse out
    * Remove mouse click an drag informations
    *
    * @return void
    */
    handleMouseOut(e) {
        const clickStates                 = { clicked: false, contextClicked: false },
            { target, originalTarget }    = e.nativeEvent,
            { relatedTarget, srcElement } = e.nativeEvent;

        if (
            target === originalTarget
            && originalTarget === srcElement
            && !this.graphRef.current.contains(relatedTarget)
        ) {
            this.setState(clickStates);
            this.setState({ mouseDragged: false });
        }

        return true;
    }

    /**
    * Put onmouse element in state
    *
    * @return void
    */
    setOnMouseElement(el) {
        this.setState({ onMouseElement: el });
    }

    /**
    * Put mouse move informations in state
    *
    * @return void
    */
    handleMouseMove(e) {
        const {
                clicked,
                mouseDragged,
                onMouseElement
            } = this.state,
            { target } = e.nativeEvent,
            elementBounding = onMouseElement ? onMouseElement.getBoundingClientRect() : {},
            x = e.nativeEvent.clientX - (elementBounding.x || 0),
            y = e.nativeEvent.clientY - (elementBounding.y || 0);

        this.setState({
            // Set mouse coordonates on mouse click pressed (on drag)
            mouseDragged: clicked ? {
                start: mouseDragged === false ? {
                    x,
                    y,
                    target,
                }
                    : mouseDragged.start,
                end: {
                    x,
                    y,
                },
            }
                : mouseDragged
        });
    }


    /**
     * Get isCapture bit
     *
     * @returns boolean
     */
    getIsCapture() {
        const { current } = this.graphRef,
            captureViewer = current && current.closest('.capture-viewer');

        return !!captureViewer;
    }


    /**
     * Get content with inner empty value added
     *
     * @returns array
     */
    getContentForComponent() {
        const { fillEmptyContent } = this.props,
            { content }            = this.state,
            newContent             = [];

        if (!fillEmptyContent) {
            return content;
        }

        const ids = content?.map(item => item.id),
            min = _.min(ids),
            max = _.max(ids),
            templateItem = _.sample(content);

        for (let id = min; id <= max; id++) {
            const itemToPush = content.find(item => item.id === id);

            if (itemToPush) {
                newContent.push(itemToPush);
            } else {
                newContent.push({
                    ...templateItem,
                    id,
                    label: id.toString(),
                    value: 0
                });
            }
        }

        return newContent;
    }


    /**
    * Render the graph
    *
    * @return html
    */
    render() {
        const {
                type, dataIsLoaded, style, filterCb,
                height, width
            }          = this.props,
            { mouseDragged, selectedValues, dataKeysChange } = this.state,
            {
                noContent, noContents, parentWidth, parentHeight, contentChange, stats
            }               = this.state,
            GraphType       = graphTypesComponent[type],
            className      = `Graph${!dataIsLoaded ? ' is-loading' : ''}`,
            mustBeRendered  = (parentWidth || width) > 0 && (parentHeight || height) > 0,
            canFilter       = filterCb && (dataIsLoaded || selectedValues?.length > 0);

        if (_.isUndefined(GraphType)) { throw new Error(`Graph not found : '${type}'`); }
        return GraphType ? (
            <div
                className={className}
                ref={this.graphRef}
                style={{...style, height: parentHeight}}
            >  {
                    mustBeRendered && (
                        <GraphType
                            {...this.props}
                            width={parentWidth || width}
                            height={parentHeight || height}
                            {...this.getSkeletonGradient()}
                            isCapture={this.getIsCapture()}
                            filterCb={canFilter ? this.filterCb : null}
                            filterValues={selectedValues || []}
                            skeleton={!dataIsLoaded && noContents}
                            content={this.getContentForComponent()}
                            stats={stats}
                            noContent={noContent}
                            contentChange={contentChange}
                            dataKeysChange={dataKeysChange}
                            onMouseMove={this.handleMouseMove}
                            onMouseOut={this.handleMouseOut}
                            onMouseDown={this.handleClick}
                            onMouseUp={this.handleClick}
                            onContextMenu={this.handleClick}
                            setOnMouseElement={this.setOnMouseElement}
                            mouseDragged={mouseDragged}
                        />
                    )
                }
            </div>
        ) : null;
    }

}

Graph.propTypes = {
    color            : PropTypes.string,
    content          : PropTypes.bool,
    dataIsLoaded     : PropTypes.bool,
    expanded         : PropTypes.any,
    filterCb         : PropTypes.func,
    filterValues     : PropTypes.any,
    fillEmptyContent : PropTypes.bool,
    firstCall        : PropTypes.bool,
    fixedBySelection : PropTypes.bool,
    gradientColor    : PropTypes.string,
    registerCallbacks: PropTypes.func,
    skeletonColor    : PropTypes.string,
    filterKey        : PropTypes.string,
    stats            : PropTypes.bool,
    style            : PropTypes.object,
    type             : PropTypes.string.isRequired,
    width            : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    height           : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    dataParameters   : PropTypes.any,
    data             : PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object, PropTypes.bool]),
        stats  : PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.array]),
    }),
};

Graph.defaultProps = {
    filterCb: null,
    color   : 'var(--insight-color)',
    data    : {
        content: false,
        stats  : false,
    },
    dataIsLoaded     : false,
    fillEmptyContent : false,
    firstCall        : true,
    fixedBySelection : true,
    width            : 0,
    height           : 0,
    style            : {},
    registerCallbacks: _.noop,
};

export default Graph;

