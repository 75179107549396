/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                                         from 'lodash';
import React, { Component, Fragment }            from 'react';
import PropTypes                                 from 'prop-types';
import ImmutablePropTypes                        from 'react-immutable-proptypes';
import { connect }                               from 'react-redux';
import { pluralize }                             from 'utils/text';
import EntitiesFilters                           from 'helpers/Filters/Entities';
import TextInput                                 from 'helpers/Filters/TextInput';
import BookmarksRenderer                         from 'helpers/Renderer/Bookmarks';
import Empty                                     from './Empty';
import { Icon }                                  from 'helpers';

import './assets/bookmarks.less';

/**
 * Class Bookmarks
 *
 */
class Bookmarks extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClick', 'onUpdateFilters', 'onTextInputChange', 'onDrag');

        this.state = {
            filters      : {},
            textFilter   : false,
            alreadyShowed: false,
            dragStatus   : null
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.testAlreadyShowed();
    }


    /**
     * Component dis update
     */
    componentDidUpdate() {
        this.testAlreadyShowed();
    }


    /**
     * It's a callback function that will be called when the user change the text input.
     */
    onTextInputChange = _.debounce((value) => {
        this.setState({ textFilter: value });
    }, 500);

    /**
     * Set alreadyShowed if the component have a active props in the past
     */
    testAlreadyShowed() {
        const { active } = this.props,
            { alreadyShowed } = this.state;

        if (!alreadyShowed && active) {
            this.setState({ alreadyShowed: true });
        }
    }


    /**
    *  It's a callback function that will be called when the user change the filters.
    */
    onUpdateFilters = _.debounce((newFilters) => {
        const filters = {};

        newFilters.forEach(filter => {
            const { id, type } = filter,
                filterType     = pluralize(type),
                filtersByType  = filters[filterType] || [];

            filtersByType.push(id);
            filters[pluralize(type)] = filtersByType;
        });

        this.setState({ filters });
    }, 300);


    /**
     * On entity click
     */
    onClick(entity, collection) {
        const {
                navigateTo, navigateToSearch, close
            }        = this.props,
            { type } = entity,
            isQuery  = type === 'query';

        if (isQuery) {
            navigateToSearch && navigateToSearch(entity);
        }

        if (!isQuery) {
            navigateTo && navigateTo(entity, collection);
        }

        close && close();
    }


    /**
     * Set dragStatus state
     * @param {object} dragStatus
     *
     * @return void
     */
    onDrag(dragStatus) {
        this.setState({ dragStatus });
    }

    /**
     * Render drag and drop message when there are no action
     *
     * @return void
     */
    renderDefaultMessage() {
        return (
            <div className="drop-message">
                <Icon
                    type="info-bubble"
                    className="icon-info-bubble"
                    height={14}
                    width={14}
                />
                <div className="message">You can drag your bookmark using </div>
                <Icon
                    type="holder"
                    className="icon-holder"
                    height={10}
                    width={10}
                    color="#8696B3"
                />
                <div className="message">icon</div>
            </div>
        );
    }

    /**
     * Render drag and drop message
     *
     * @return void
     */
    renderDropMessage() {
        const {
                dragStatus, textFilter, filters,
            }                 = this.state,
            { message, type } = dragStatus || {},
            isWarning         = type === 'warning',
            iconType          = isWarning ? 'warning' : 'info-bubble',
            className         = isWarning ? 'icon' : 'icon-info-bubble',
            hasFilters        = !_.isEmpty(textFilter) || !_.isEmpty(filters);

        if (hasFilters) {
            return;
        }

        if (_.isUndefined(message)) {
            return this.renderDefaultMessage();
        }

        return (
            <div className={`drop-message ${type}`}>
                <Icon
                    type={iconType}
                    className={className}
                    height={14}
                    width={14}
                />
                <div className="message">{message}</div>
            </div>
        );
    }

    /**
    * Render bookmarks collection
    *
    * @return JSX
    */
    renderBookmarks() {
        const {
                tags,
            } = this.props,
            {
                filters, alreadyShowed, textFilter
            } = this.state;

        return alreadyShowed && filters && (
            <BookmarksRenderer
                onClick={this.onClick}
                tags={tags}
                filters={filters}
                textFilter={textFilter}
                orderBy="label"
                onDrag={this.onDrag}
            />
        );
    }


    /**
     * Render Empty Jsx component
     *
     * @returns JSX
     */
    renderEmptyContent() {
        return (
            <div className="content empty empty-bookmarks">
                <Empty icon="folder" iconHeight={100}>
                    <div>
                        Your bookmark folder is empty<br />
                        Use &#171; Add folder &#187; button to create<br />
                        your first folder
                    </div>
                </Empty>
            </div>
        );
    }


    /**
     * Render Empty Jsx component
     *
     * @returns JSX
     */
    renderFilterAndSearch() {
        return (
            <div className="bookmark-filters">
                <EntitiesFilters
                    onUpdateFilters={this.onUpdateFilters}
                />
                <TextInput
                    onTextChange={this.onTextInputChange}
                    placeholder="Search in my bookmarks"
                />
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                tags,
                folderList
            }                    = this.props,
            haveRootFolder       = folderList.size > 0,
            haveOneFolderAtLeast = folderList.size > 1;

        if (!tags) {
            return false;
        }

        return (
            <Fragment>
                {haveOneFolderAtLeast && this.renderFilterAndSearch()}
                {haveOneFolderAtLeast && this.renderDropMessage()}
                {haveRootFolder && !haveOneFolderAtLeast && this.renderEmptyContent()}
                <div className="content">
                    {this.renderBookmarks()}
                </div>
            </Fragment>
        );
    }

}

Bookmarks.propTypes = {
    active          : PropTypes.bool,
    close           : PropTypes.func.isRequired,
    navigateTo      : PropTypes.func.isRequired,
    navigateToSearch: PropTypes.func.isRequired,
    folderList      : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    tags            : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]).isRequired,
};

Bookmarks.defaultProps = {
    active    : false,
    folderList: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    folderList: state.getIn(['userView', 'bookmark_folder', 'list']),
});

/**
 * Bind the store to to component
 */
export default connect(mapStateToProps, {})(Bookmarks);

