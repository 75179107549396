// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-edit-view .ant-modal-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}
.search-edit-view .ant-modal-footer .informations {
  display: flex;
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/assets/view.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;AACJ;AALA;EAOQ,aAAA;EACA,YAAA;AACR","sourcesContent":[".search-edit-view .ant-modal-footer {\n    position       : relative;\n    display        : flex;\n    align-items    : center;\n    justify-content: end;\n\n    .informations {\n        display  : flex;\n        flex-grow: 1;\n    }\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
