// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input .smart .inputs .input .operator .button {
  color: #FFF;
  background: var(--insight-light-gray);
  border-radius: 4px;
  cursor: pointer;
}
.search-input .smart .inputs .input .operator .button .ant-select-selector {
  display: flex;
  align-items: center;
  height: 36px;
  box-shadow: none;
}
.search-input .smart .inputs .input .operator .button .ant-select-selection {
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/Inputs/Smart/Input/assets/operator.less"],"names":[],"mappings":"AAEA;EAEQ,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,eAAA;AAFR;AAHA;EAQY,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAFZ;AATA;EAeY,uBAAA;AAHZ","sourcesContent":["@insight-light-gray: var(--insight-light-gray);\n\n.search-input .smart .inputs .input .operator {\n   .button {\n        color        : #FFF;\n        background   : @insight-light-gray;\n        border-radius: 4px;\n        cursor       : pointer;\n\n        .ant-select-selector {\n            display    : flex;\n            align-items: center;\n            height     : 36px;\n            box-shadow : none;\n        }\n\n        .ant-select-selection {\n            background: transparent;\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
