import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import ImmutablePropTypes       from 'react-immutable-proptypes';
import { connect }              from 'react-redux';
import _                        from 'lodash';
import { Icon, IButton }        from 'helpers';
import { Checkbox, Modal }      from 'antd';
import { dataGet }              from 'utils/api';
import notification             from 'helpers/notification';
import Edit                     from 'helpers/Search/Edit';
import Sentence                 from 'helpers/Search/Sentence';

import {
    query as performSearch,
    activateShortcut,
    getUniqueLastSearch
}                               from 'store/actions/navigation';

import { fetchUserViewModels, entityIsInNewsletter }  from 'store/actions/userView';

import './assets/searches.less';

/**
 * Choose some searches step
 *
 */
class Searches extends Component {

    /**
     * Construct
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderSwitchFromSelectionBtn', 'toggleEntity', 'toggleEntities', 'renderSuggestionSuffix',
            'renderSelectedEntities', 'addNewsSearch', 'onCancelAddSearch', 'onCreateSearch',
            'registerEditSearchCallbacks', 'editSearch', 'onUpdateInputs'
        );

        this.searchFetched = [];

        this.state = {
            // Store there models for entities ids
            editSearchCallbacks: {},
            queries            : [],
            inputsState        : null,
        };
    }

    /**
    *
    */
    componentDidMount() {
        this.updateQueries();
    }


    /**
    *
    */
    componentDidUpdate() {
        this.updateQueries();

        const { fetchUserViewModels } = this.props,
            entities                  = this.getEntities(),
            entitiesToFetch           = entities && entities.filter(se => !se.model)
                .toJS()
                .map(se => se.key);

        fetchUserViewModels({
            modelType        : 'entity',
            userViewItemsKeys: entitiesToFetch,
        });
    }


    /**
     * Get entities of the newsletter
     *
     * @returns array
     */
    getEntities() {
        const {
                newsletter,
                newslettersList,
                entitiesList,
            }               = this.props,
            { id }          = newsletter,
            streamEntity    = newslettersList?.find(streamEntity => streamEntity.entity_id === id),
            streamEntityId  = streamEntity?.key,
            entities        = streamEntityId  && entitiesList.filter(
                se => _.map(se.parent_attachments, 'key').includes(streamEntityId)
            );

        return entities;
    }


    /**
    * Register edit search callback
    *
    * @param string action The action to Register
    * @param func   cb     The callback to trigger
    *
    * @return JSX
    */
    registerEditSearchCallbacks(action, cb) {
        const { editSearchCallbacks } = this.state;

        editSearchCallbacks[action] = cb;

        this.setState({
            editSearchCallbacks
        });
    }

    /**
    *  Trigger when inputs are updated
    *
    * @param inputsState {object} The states of the input
    *
    * @return State
    */
    onUpdateInputs(inputsState) {
        this.setState({ inputsState });
    }

    /**
     * Update queries in state
     */
    updateQueries() {
        const { newsletter }           = this.props,
            { entities }               = newsletter || {},
            { queries }                = this.state,
            queriesFromLastAndBookmark = this.getQueriesFromLastSearchesAndBookmarks(),
            queriesArray               = queriesFromLastAndBookmark.toJS ? queriesFromLastAndBookmark.toJS() : [],
            queriesToAdd               = queriesArray.filter(
                query => !queries.find(search => search.id === query.id)
            ),
            allQueries                 = queries.concat(queriesToAdd),
            entitiesInSearch           = entities.filter(
                entityId => allQueries.find(search => search.entity.entity.id === entityId)
            ),
            entitiesToFetch            = _.difference(entities, entitiesInSearch.concat(this.searchFetched)),
            shouldFetch                = entitiesInSearch.length !== entities.length;

        if (queriesToAdd.length > 0) {
            this.setState({ queries: allQueries});
        }

        if(!shouldFetch) {
            return;
        }

        this.searchFetched.concat(entitiesToFetch);

        entitiesToFetch.length > 0 && dataGet('/entities', { data: { ids: entitiesToFetch }})
            .then(
                response => {
                    const { body }  = response,
                        entitiesIds = [];

                    body.forEach(model => {
                        entitiesIds.push(model.id);
                    });

                    dataGet('/queries', { data: { entities: entitiesIds }})
                        .then(
                            response => {
                                const { body }   = response;

                                this.setState({
                                    queries: queries.concat(body)
                                });
                            }
                        );
                }
            );
    }


    /**
    *
    * @param {object} entity
    */
    toggleEntity(query) {
        const { update, newsletter } = this.props,
            { entities }             = newsletter,
            { entity }               = query,
            { entity: search }       = entity,
            { id }                   = search;

        update({
            ...newsletter,
            entities: _.xor(entities, [id])
        });
    }


    /**
     * Toggle multiple entities
     *
     * @param {array} entities
     */
    toggleEntities(entitiesToToggle) {
        const { update, newsletter } = this.props,
            { entities }             = newsletter;

        update({
            ...newsletter,
            entities: _.xor(entities, entitiesToToggle.map(query => query.entity.entity.id))
        });
    }


    /**
     * Open modal to add news search
     */
    addNewsSearch() {
        const { close } = this.props;

        // Put true to create new search
        this.setState({ searchIdModal: true });

        // Close the shortcut view
        close();
    }


    /**
     * Edit search
     *
     * @param {ClickEvent} e
     */
    editSearch(e) {
        const { close }       = this.props,
            { currentTarget } = e,
            entityNode        = currentTarget.closest('.entity'),
            { dataset }       = entityNode,
            { id }            = dataset || {};

        this.setState({ searchIdModal: id });

        // Close the shortcut view
        close();
    }


    /**
     * Close modal to add/edit news search
     */
    onCancelAddSearch() {
        const { activateShortcut } = this.props;

        this.setState({ searchIdModal: false });

        // Restore newsletters shortcut view
        activateShortcut('newsletters');
    }


    /**
    * Add a search from modal
    */
    onCreateSearch() {
        const { performSearch, activateShortcut, entityIsInNewsletter }  = this.props,
            { editSearchCallbacks, searchIdModal, queries, inputsState } = this.state,
            { searchCanBeLaunched }                                      = inputsState,
            parameters                                                   = editSearchCallbacks.submit();

        if (!searchCanBeLaunched) {
            return;
        }

        // Perform Search
        performSearch(
            {
                ...parameters,
                cb: newSearch => {
                    const entitiesToToggle = [],
                        isInNewsletter     = entityIsInNewsletter(newSearch);

                    if (isInNewsletter) {
                        notification({
                            type   : 'error',
                            message: 'Sorry, this query is already in your Insight Feed.'
                        });

                        // Disable modal
                        this.setState({ searchIdModal: false });

                        // Restore newsletters shortcut view
                        activateShortcut('newsletters');

                        return;
                    }

                    // Remove edited search
                    if (_.isString(searchIdModal)) {
                        const search     = queries.find(search => search.entity.entity.id === searchIdModal);
                        entitiesToToggle.push(search);
                    }

                    // Add created search
                    entitiesToToggle.push(newSearch);
                    this.toggleEntities(entitiesToToggle);

                    // Disable modal
                    this.setState({ searchIdModal: false });

                    // Restore newsletters shortcut view
                    activateShortcut('newsletters');
                },
            },
            false
        );
    }


    /**
    * Get bookmarhed searches
    *
    * @return array
    */
    getBookmarkedQueries() {
        const { bookmarksList } = this.props,
            bookmarkEntities           = bookmarksList && bookmarksList
                .map(streamEntity => streamEntity.model?.entity)
                .filter(entity => entity && entity.type === 'query');

        return bookmarkEntities ? bookmarkEntities.toJS(): [];
    }


    /**
    * Render Autocompleter's suggestions suffix
    *
    * @return JSX
    */
    renderSuggestionSuffix(entity) {
        const label = !this.isEntitySelected(entity) ? 'Add search' : 'Remove search';

        return [this.renderSwitchFromSelectionBtn(entity, label)];
    }


    /**
    * Is the provided entity selected ?
    *
    * @return boolean
    */
    isEntitySelected(entity) {
        const { settings } = this.props;

        return _.isArray(settings) && settings.find(search => search.id === entity.id);
    }


    /**
    * Render a popover orgunit
    *
    */
    renderSwitchFromSelectionBtn(entity) {
        const { newsletter } = this.props,
            { entities }     = newsletter;

        /**
         * Switch entity from selection, then kill bubling.
         */
        const switchFromSelection = (e) => {
            e.stopPropagation();
            e.cancelBubble = true;

            this.toggleEntity(entity);
        };

        return (
            <button className="check-it">
                <Checkbox
                    checked={_.isArray(entities) && entities.includes(entity.id)}
                    onClick={switchFromSelection}
                />
            </button>
        );
    }


    /**
    * Return the label for search provided as parameters
    *
    * @param object search The search
    *
    * @return void
    */
    getLabel(query) {
        const { entity, label } = query,
            { entity: search }  = entity,
            { settings, mode }  = search,
            { expand }          = settings || {},
            SentenceComponent   = Sentence[mode],
            hasLabel            = label !== '';

        return hasLabel
            ? <span>{label}</span>
            : (
                SentenceComponent
                    ? (
                        <SentenceComponent {...search} expand={expand}
                            inputs={search.concept} lookingFor={false}
                        />
                    )
                    : 'Error to get search'
            );
    }

    /**
     * Get all selected searches models
     */
    getQueriesFromLastSearchesAndBookmarks() {
        const {lastsSearches, newslettersSearches } = this.props,
            searchesFromBookmarks = lastsSearches
                ? lastsSearches.concat(this.getBookmarkedQueries())
                : this.getBookmarkedQueries();

        return newslettersSearches
            ? newslettersSearches.concat(searchesFromBookmarks)
            : searchesFromBookmarks;
    }


    /**
     * Get search of the newsletter
     */
    getSelectedEntities() {
        const { newsletter } = this.props,
            { entities }     = newsletter,
            { queries }      = this.state,
            // Index search by entitiId
            selectedQueries  = queries.length && _.zipObject(
                entities,
                entities.map(
                    entityId => queries.find(search => search.entity.entity.id === entityId)
                ).filter(search => !!search)
            );

        return selectedQueries;
    }

    /**
    * Render the autocompleted searches
    *
    * @return html
    */
    renderSelectedEntities() {
        const { newsletter } = this.props,
            { entities, id } = newsletter,
            selectedQueries  = this.getSelectedEntities(),
            title            = id ? 'Is added to your Insight Feed' : 'Will be Added to your Insight Feed';

        return (
            <div className="newsletter-entities">
                <span className="add-search" onClick={this.addNewsSearch}>
                    <Icon id="plus" width={10}
                        height={10}
                    />
                    &nbsp;
                    Add a new search
                </span>
                <div className="searches-block-title">{title}</div>
                {entities.map(id => this.renderQuery(selectedQueries[id], true))}
                {entities.length === 0 && (
                    <span className="must-add">You must add an search</span>
                )}
            </div>
        );
    }


    /**
    * Getting the selected queries and returning the unique genericQueryIds.
    *
    */
    getSelectedGenericQueryIds() {
        const selectedQueries = this.getSelectedEntities();

        return _.uniq(_.values(selectedQueries).map(search => search?.entity?.entity.genericQueryId));
    }


    /**
    * Render the autocompleted searches
    *
    * @return html
    */
    renderLastSearches() {
        const {
                lastsSearches, getUniqueLastSearch
            }               = this.props,
            genericQueryIds = this.getSelectedGenericQueryIds();

        if (!lastsSearches || !lastsSearches.toJS) { return false; }

        const uniqLastSearches = getUniqueLastSearch(),
            searches           = uniqLastSearches.filter(search => search
                // Uniq genericQueryId
                && !genericQueryIds.includes(_.get(search, 'entity.entity.genericQueryId'))
                // Remove searches already selected
                && !search.entity.entity.settings?.dateFilter
                && !search.entity.entity.settings?.countryFilter
                && !search.entity.entity.settings?.bookmarkFolderForTags
            ).slice(0, 3);

        return searches.length > 0 && (
            <div className="bookmarked-entities">
                <div className="searches-block-title">
                    Your last searches
                </div>
                {searches.map(search => this.renderQuery(search))}
            </div>
        );
    }


    /**
    * Render the bookmarked searches
    *
    * @return html
    */
    renderBookmarkedSearches() {
        const queries       = this.getBookmarkedQueries(),
            genericQueryIds = this.getSelectedGenericQueryIds(),
            queriesToRender = queries.filter(search => search
                // Remove searches already selected
                && !genericQueryIds.includes(_.get(search, 'entity.entity.genericQueryId'))
                // Remove date filtered searches
                && !search.entity.entity.settings?.dateFilter
                // Remove country filtered searches
                && !search.entity.entity.settings?.countryFilter
            );

        // SORT !? sortedSearches = _.sortBy(searches, search => (search.label || search.entity.label || "Z").toLowerCase());

        return queriesToRender.length > 0 && (
            <div className="bookmarked-entities">
                <div className="searches-block-title">
                    Suggestions from your company profiles bookmarks
                </div>
                {queriesToRender.map(query => this.renderQuery(query))}
            </div>
        );
    }


    /**
    * Render the a query line
    *
    * @return html
    */
    renderQuery(query, noCheckbox = false) {    // eslint-disable-line  max-lines-per-function
        // No way to render an undefined entity
        if(!query || !query.entity) {
            return false;
        }

        const { entity }       = query,
            { entity: search } = entity,
            { mode }           = search,
            isEditable         = mode !== 'express' && mode !== 'intelligence',
            label              = this.getLabel(query);

        const { newsletter } = this.props,
            { entities }     = newsletter,
            /**
             * Onclick
             */
            onClick          = () => {
                if(query) {
                    this.toggleEntity(query);
                }
            };

        return (
            <div className="entity" data-id={query.entity.entity.id}
                key={query.entity.entity.id}
            >
                {!noCheckbox && (
                    <Checkbox
                        checked={_.isArray(entities) && entities.includes(search.id)}
                        onClick={onClick}
                    />
                )}
                <Icon
                    type="search"
                    folder="/entities/"
                    key={query.id}
                    width={16}
                    height={16}
                    discShaped
                    borderSize={1}
                    borderColor="#888"
                />
                <div
                    className={`label${!noCheckbox ? ' clickable' : ''}`}
                    onClick={!noCheckbox && onClick}
                >
                    {label}
                </div>
                {noCheckbox && isEditable && (
                    <div className="edit-entity" onClick={this.editSearch}>
                        <Icon id="edit" width={18}
                            height={18} color="#aaaaaa"
                            title="Edit"
                        />
                    </div>
                )}
                {noCheckbox && (
                    <div className="remove-entity" onClick={onClick}>
                        <Icon id="close" height={16}
                            color="#aaaaaa" title="Remove"
                        />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Render i-buttons from helpers
     */
    renderButtons() {
        const { inputsState }       = this.state,
            { searchCanBeLaunched } = inputsState || {};

        return (
            <div className="actions-btn">
                <div className="content">
                    <IButton
                        label="Cancel"
                        labelColor="#10285D"
                        bgColor="#FFF"
                        hoverLabelColor="#FFF"
                        hoverBgColor="#10285D"
                        onClick={this.onCancelAddSearch}
                    />
                    <IButton
                        className="ok-button"
                        label="Ok"
                        labelColor="#FFF"
                        bgColor="#10285D"
                        hoverLabelColor="#FFF"
                        hoverBgColor="#233b69"
                        onClick={this.onCreateSearch}
                        disabled={!searchCanBeLaunched}
                        tooltip={searchCanBeLaunched ? '' : 'You must add at least one concept'}
                    />
                </div>
            </div>
        );
    }

    /**
     * Render news search modal
     */
    renderAddNewSearchModal() {
        const { searchIdModal, queries } = this.state,
            searchToEdit                 = queries.find(search => search.entity.entity.id === searchIdModal);

        return searchIdModal
            ? (
                <Modal
                    className="search-add-view"
                    title={searchToEdit ? 'Edit search' : 'Add search'}
                    onCancel={this.onCancelAddSearch}
                    width={1300}
                    footer={this.renderButtons()}
                    open
                >
                    <Edit
                        search={searchToEdit && searchToEdit.entity.entity}
                        registerCallbacks={this.registerEditSearchCallbacks}
                        onUpdateInputs={this.onUpdateInputs}
                        mustHaveOneConcept
                    />
                </Modal>
            )
            : null;
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <div className="searches setting">
                {this.renderSelectedEntities()}
                {this.renderLastSearches()}
                {this.renderBookmarkedSearches()}
                {this.renderAddNewSearchModal()}
            </div>
        );
    }

}

Searches.propTypes = {
    bookmarksList       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    lastsSearches       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newslettersList     : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    entitiesList        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    settings            : PropTypes.array,
    update              : PropTypes.func,
    performSearch       : PropTypes.func,
    activateShortcut    : PropTypes.func,
    close               : PropTypes.func,
    fetchUserViewModels : PropTypes.func,
    entityIsInNewsletter: PropTypes.func,
    getUniqueLastSearch : PropTypes.func,
    newslettersSearches : PropTypes.bool,
    newsletter          : PropTypes.shape({
        id      : PropTypes.string,
        entities: PropTypes.arrayOf(PropTypes.object)
    }),
};

Searches.definition =  {
    key                : 'entities',
    label              : 'Searches',
    question           : 'What do you want to track?',
    view               : 'Searches',
    newslettersSearches: false,
};


/**
 * Bind the store to to component
 */
const mapStateToProps = state => ({
    lastsSearches      : state.getIn(['navigation', 'lasts_searches']),
    newslettersSearches: state.getIn(['navigation', 'newsletters_searches']),
    bookmarksList      : state.getIn(['userView', 'bookmark', 'list']),
    newslettersList    : state.getIn(['userView', 'newsletter', 'list']),
    entitiesList       : state.getIn(['userView', 'entity', 'list']),

});

export default connect(mapStateToProps, {
    performSearch, activateShortcut, fetchUserViewModels, entityIsInNewsletter, getUniqueLastSearch
})(Searches);
