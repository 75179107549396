// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actors .entities .collection {
  display: block;
}
.actors .entities .collection .entity-inline {
  display: block;
  margin-bottom: 3px;
}
.actors .entities .collection .entity-inline:not(.not-reliable) {
  color: #455A64 !important;
}
.actors .entities .collection .entity-inline .actorgroup {
  font-size: 9px;
  background-color: #455A64;
  color: #cfd8dc;
  margin-left: 3px;
  border-radius: 4px;
  padding: 4px 4px 2px 4px;
}
.actors .entities .collection .entity-inline .actorgroupspace {
  font-size: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/utils/assets/actors.less"],"names":[],"mappings":"AAAA;EAGY,cAAA;AADZ;AAFA;EAMgB,cAAA;EACA,kBAAA;AADhB;AAGgB;EACI,yBAAA;AADpB;AATA;EAcoB,cAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,wBAAA;AAFpB;AAjBA;EAsBoB,cAAA;AAFpB","sourcesContent":[".actors {\n    .entities {\n        .collection {\n            display: block;\n\n            .entity-inline {\n                display: block;\n                margin-bottom: 3px;\n\n                &:not(.not-reliable) {\n                    color: #455A64 !important;;\n                }\n\n                .actorgroup {\n                    font-size: 9px;\n                    background-color: #455A64;\n                    color: #cfd8dc;\n                    margin-left: 3px;\n                    border-radius: 4px;\n                    padding: 4px 4px 2px 4px;\n                }\n                .actorgroupspace {\n                    font-size: 0px;\n                }\n            }\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
