import _                from 'lodash';
import types            from '../actions/types/newsletters';

const defaultState = {
    state: {
        newsletter    : false,
        openedSettings: [],
        searches      : false
    }
};

/**
* Refresh the application display for newsletter
*
* @return object with Immutable.List
*/
const updateState = (state, params) => {
    const oldNewslettersState = state.state,
        newslettersState      = { ...oldNewslettersState },
        {
            newsletter, openSettings, closeSettings
        } = params;

    if (newsletter) {
        newslettersState.newsletter = newsletter;
    }

    if (openSettings && newslettersState.openedSettings.indexOf(openSettings) === -1) {
        newslettersState.openedSettings.push(openSettings);
    }

    if (closeSettings && newslettersState.openedSettings.indexOf(closeSettings.key) !== -1) {
        newslettersState.openedSettings = _.xor(oldNewslettersState.openedSettings, [closeSettings.key]);
    }

    return {
        ...state,
        state: {
            ...newslettersState
        }
    };
};


/**
* Manage an immutable identity map for all models returned by the API
*
* @return state As a fresh Immutable Map
*/
const Newsletters = (state = defaultState, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the application with a newsletter state
        case types.NEWSLETTER_UPDATE_STATE:
            return updateState(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Newsletters;
