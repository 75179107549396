import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    geoMercator          as D3GeoProjection,
    geoPath              as D3GeoPath,
    select               as d3Select,
    hsl                  as d3Hsl,
    max                  as d3Max,
    interpolateRgb       as d3InterpolateColor,
} from 'd3';

import { feature }          from 'topojson-client';
import { getDomColor }      from 'utils/dom';

import './GeoMap/main.less';

import worldData             from './GeoMap/world-countries.json';
import countriesInformations from './GeoMap/countries-informations.json';

/**
 * Miller raw projection.
 *
 * @params number lambda
 * @params number phi
 *
 * returns array
 */
const millerRaw = (lambda, phi) => [
    lambda,
    1.25 * Math.log(Math.tan(Math.pi / 4 + 0.4 * phi))
];

millerRaw.invert = (x, y) => [
    x,
    2.5 * Math.atan(Math.exp(0.8 * y)) - 0.625 * Math.pi
];

/**
 * The map graph Component
 *
 */
class GeoMap extends Component {

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            {
                color,
                background,
                gradientColor,
            }         = nextProps,
            realColor = getDomColor(color),
            dc        = d3Hsl(realColor);

        // Make D3 range projection
        state.colors = {
            text: realColor,
            background,
            map : [
                gradientColor === false ? dc.brighter(1).toString() : gradientColor,
                dc.toString(),
            ],
        };

        state.colors.gradient = d3InterpolateColor(state.colors.map[0], state.colors.map[1]);

        return { nextProps, state };
    }

    /**
    * Update projection in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateProjection(options) {
        const { nextProps, state }      = options,
            { innerWidth, innerHeight } = state,
            ratio                       = innerWidth / innerHeight,
            basedOnHeight               = ratio > 1.46,
            scaleRatio                  = basedOnHeight
                ? 115 * innerHeight / 500
                : 115 * innerWidth / 730; // 500 * 1.46

        state.projection = D3GeoProjection()
            .scale(scaleRatio)
            .rotate([-11, 0])  // Uncut russia
            .translate([innerWidth / 2, innerHeight * 2 / 3]);

        return { nextProps, state };
    }

    /**
    * Update GeoMap Data in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateGeoMapData(options) {
        const { nextProps, state } = options,
            { colors, projection } = state,
            { worldFeatures }      = state,
            { data, monoColor }    = nextProps,
            { restrictedFilters }  = nextProps,
            serie                  = _.get(data, 'content', []),
            dataCountry            = _.keyBy(serie, 'id'),
            maxValue               = d3Max(serie, (d) => d.value);

        state.mapData = worldFeatures.filter((d) => d.id !== 10)
            .map((d) => {
                const path       = D3GeoPath().projection(projection),
                    isoCode      = !_.isNull(d.properties['Alpha-2']) ? d.properties['Alpha-2'] : d.id,
                    name         = !_.isNull(d.properties.name) ? d.properties.name : d.id,
                    stringPath   = path(d),
                    value        = !_.isUndefined(dataCountry[isoCode]) ? dataCountry[isoCode].value : 0,
                    informations = _.find(countriesInformations, { id: isoCode }),
                    center       = !_.isUndefined(informations) ? path.centroid(informations.center) : path.centroid(d),
                    visible      = isoCode !== 'AQ',
                    disabled     = restrictedFilters ? _.includes(restrictedFilters, isoCode) : true;

                return {
                    id   : isoCode,
                    value,
                    center,
                    path : stringPath,
                    label: name,
                    visible,
                    disabled,
                    color: value === 0
                        ? 'rgba(16, 40, 93, 0.5)'
                        : (monoColor ? colors.map[1] : colors.gradient(value / maxValue)),
                };
            });

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.labelsRef = React.createRef();

        _.bindAll(this, 'renderDefs', 'render', 'onCountryClick');

        this.state = {
            // SVG path string for line
            mapData      : [],
            labelsAnchors: {}
        };

        // Make a uuid
        this.id = _.uniqueId('map-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.resizeLabel();
    }

    /**
    * Get state for props and previous states
    *
    * Calculate :
    *          - data
    *          - projection (D3Geo projection)
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous states
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) {
        const {
                margin,
            } = nextProps,
            // Cascading function to update state
            updateState = _.flow([
                GeoMap.updateColors,       // Colors
                GeoMap.updateProjection,   // Projection
                GeoMap.updateGeoMapData,   // GeoMap Data
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    // Size of the chart (without landmark )
                    innerHeight  : nextProps.height - 2 * margin,
                    innerWidth   : nextProps.width  - 2 * margin,
                    worldFeatures: feature(worldData, worldData.objects.countries1).features,
                    labelsAnchors: prevStates.labelsAnchors
                }
            });

        return state;
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
        this.resizeLabel();
    }

    /**
    *
    * On contry click
    *
    * @return void
    */
    onCountryClick(countryId) {
        const { countryClick } = this.props;

        if (countryClick) {
            countryClick(countryId);
        }
    }

    /**
    * Resize labels
    *
    * @return html
    */
    resizeLabel() {
        const { textPadding } = this.props,
            { labelsAnchors } = this.state;

        if (!_.isNull(this.labelsRef.current)) {
            const textLabelNodes = d3Select(this.labelsRef.current).selectAll('.text-label').nodes();
            // Resize rect from text width
            _.each(textLabelNodes, (node) => {
                const rectNode = node.querySelector('rect'),
                    textNode = node.querySelector('text'),
                    textSize = textNode.getBBox();

                rectNode.setAttribute('width', textSize.width + 2 * textPadding);
            });

            // TODO !!!!
            // Manage colision
            let hasAColide = false;
            _.each(textLabelNodes, (node, index) => {
                let isColide = false;
                _.each(textLabelNodes, (node2, index2) => {
                    if (index >= index2) { return; }
                    const BCR1   = node.getBoundingClientRect(),
                        BCR2     = node2.getBoundingClientRect();

                    isColide = isColide
                            || (BCR1.left <= BCR2.right && BCR1.left >= BCR2.left
                                && BCR1.top <= BCR2.bottom && BCR1.top >= BCR2.top)
                            || (BCR1.left <= BCR2.right && BCR1.left >= BCR2.left
                                && BCR1.bottom <= BCR2.bottom && BCR1.bottom >= BCR2.top)
                            || (BCR1.right <= BCR2.right && BCR1.right >= BCR2.left
                                && BCR1.top <= BCR2.bottom && BCR1.top >= BCR2.top)
                            || (BCR1.right <= BCR2.right && BCR1.right >= BCR2.left
                                && BCR1.bottom <= BCR2.bottom && BCR1.bottom >= BCR2.top);
                });

                if (isColide) {
                    labelsAnchors[node.attributes.dataId.value] = { x: Math.random() * 100 - 50, y: Math.random() * 150 - 50 };
                    hasAColide = true;
                }
            });

            if (hasAColide) {
                this.setState({ labelsAnchors });
            }
        }
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { colors }    = this.state;

        return (
            <defs>

                {/* Gradient of the map */}
                <radialGradient
                    id={`${this.id}-filled`}
                    cx="50%"
                    cy="50%"
                    r="100%"
                >
                    <stop offset="15%" stopColor={colors.map[0]} />
                    <stop offset="85%" stopColor={colors.map[1]} />
                </radialGradient>

                {/* Linear gradient for gap lines */}
                <linearGradient
                    id={`${this.id}-linear`}
                    x1={0}
                    x2="100%"
                    y1={0}
                    y2={0}
                >
                    <stop offset="0%" stopColor={colors.map[0]} />
                    <stop offset="100%" stopColor={colors.map[1]} />
                </linearGradient>
            </defs>
        );
    }

    /**
    * Render graph
    *
    * @return html
    */
    renderGraph() {
        const { mapData }    = this.state,
            { countryClick } = this.props;

        return (
            <g className={`countries${countryClick ? ' mouseEnabled' : null}`}>
                {
                    mapData.map((d) => (
                        <g className="country" key={`${d.id}-${d.label}`}>
                            {d.visible
                                ? (
                                    <g
                                        onMouseDown={() => this.onCountryClick(d.id)}
                                    >
                                        <path
                                            d={d.path}
                                            className={`country ${!d.disabled ? 'disabled' : ''}`}
                                            stroke="#FFFFFF"
                                            strokeWidth={0.5}
                                            fill={d.disabled ? d.color : 'rgba(204,204,204,0.25)'}
                                            title={d.label}
                                        />
                                        <title>
                                            {d.label}
                                        </title>
                                    </g>
                                )
                                : null}
                        </g>
                    ))
                }
            </g>
        );
    }

    /**
    * Render graph
    *
    * @return html
    */
    renderLabel() {
        const { mapData, colors }  = this.state;

        return (
            <g ref={this.labelsRef}>
                {
                    mapData.map((d) => (d.value === 0 ? '' : (
                        <g
                            className="label"
                            key={`${d.id}-${d.label}`}
                            transform={`translate(${d.center[0]} ${d.center[1]})`}
                        >
                            <circle
                                r="1.5"
                                stroke={colors.background}
                                strokeWidth="1"
                                fill="none"
                            />
                            {this.renderLabelRect(d)}
                            {this.renderLabelText(d)}
                        </g>
                    )))
                }
            </g>
        );
    }

    /**
    * Render the label rectangle
    *
    * @return html
    */
    renderLabelRect(d) {
        const { labelsAnchors, colors } = this.state,
            labelAnchor                 = labelsAnchors[d.id] || { x: 24, y: 12 };

        return (
            <line
                x1={1}
                y1={1}
                x2={labelAnchor.x}
                y2={labelAnchor.y}
                stroke={colors.background}
                strokeWidth="1"
            />
        );
    }

    /** Render the label rectangle
    *
    * @return html
    */
    renderLabelText(d) {
        const { labelsAnchors, colors } = this.state,
            { textPadding }             = this.props,
            labelAnchor                 = labelsAnchors[d.id] || { x: 24, y: 12 };

        return (
            <g transform={`translate( ${labelAnchor.x} ${labelAnchor.y} )`} className="text-label"
                dataId={d.id}
            >
                <rect
                    transform="translate( 0 -7 )"
                    width="25"
                    height="12"
                    fill={colors.background}
                />
                <text
                    transform={`translate( ${textPadding} 0 )`}
                    fontSize="9"
                    style={{ textAnchor: 'start', fill: colors.text, position: 'absolute' }}
                    dominantBaseline="middle"
                >
                    {d.label}
                </text>
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width,
                height,
                margin,
                noLabel,
                skeleton,
                skeletonGradient,
                onMouseMove,
                onMouseDown,
                onMouseUp,
                onContextMenu
            } = this.props,
            style = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) {
            return (
                <div className="GeoMap skeleton" style={style} />
            );
        }

        return (
            <div className="GeoMap">
                <svg
                    width={width}
                    height={height}
                    onMouseMove={onMouseMove}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    onContextMenu={onContextMenu}
                >
                    <g transform={`translate( ${margin} ${margin} )`}>
                        { /* Definitions */ }
                        {this.renderDefs()}
                        { /* GeoMap graph */ }
                        { this.renderGraph()}
                        { /* Labels */ }
                        {noLabel ? _.noop() : this.renderLabel()}
                    </g>
                </svg>
            </div>
        );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
GeoMap.propTypes = {
    data             : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    color            : PropTypes.string, // eslint-disable-line
    gradientColor    : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // eslint-disable-line
    background       : PropTypes.string, // eslint-disable-line
    width            : PropTypes.number.isRequired,
    height           : PropTypes.number.isRequired,
    margin           : PropTypes.number,
    textPadding      : PropTypes.number,
    fontSize         : PropTypes.number,  // eslint-disable-line
    noLabel          : PropTypes.bool,
    monoColor        : PropTypes.bool, // eslint-disable-line
    skeleton         : PropTypes.bool,
    skeletonGradient : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    mouseEnabled     : PropTypes.bool,
    onMouseMove      : PropTypes.func.isRequired,
    onMouseDown      : PropTypes.func.isRequired,
    onMouseUp        : PropTypes.func.isRequired,
    onContextMenu    : PropTypes.func.isRequired,
    countryClick     : PropTypes.func,
    restrictedFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

/**
 * Default props value
 */
GeoMap.defaultProps = {
    margin          : 0,
    fontSize        : 12,
    textPadding     : 3,
    color           : 'var(--primary-color)',
    background      : '#E6E6F1',
    gradientColor   : false,
    noLabel         : false,
    monoColor       : false,
    skeleton        : false,
    skeletonGradient: false,
    mouseEnabled    : false,
    countryClick    : null,
};

export default GeoMap;

