// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-search {
  padding: 15px;
  background: var(--insight-lightest-gray);
  border-radius: 6px;
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.edit-search .search-input .smart .search-form {
  width: 100%;
}
.edit-search .autocomplete textarea {
  padding-top: 8px !important;
}
.edit-search .collision-custom {
  margin-left: 75% !important;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/assets/edit.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,wCAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAJA;EASQ,WAAA;AAFR;AAPA;EAcY,2BAAA;AAJZ;AAVA;EAmBQ,2BAAA;AANR","sourcesContent":["@primary-color        : var(--primary-color);\n@insight-lightest-gray: var(--insight-lightest-gray);\n\n.edit-search {\n    padding      : 15px;\n    background   : @insight-lightest-gray;\n    border-radius: 6px;\n    max-height   : 65vh;\n    overflow-x   : hidden;\n    overflow-y   : auto;\n\n    .search-input .smart .search-form {\n        width: 100%;\n    }\n\n    .autocomplete {\n        textarea {\n            padding-top: 8px !important;\n        }\n    }\n\n    .collision-custom {\n        margin-left: 75% !important;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
