/**
 * Return selected filter tags using filter key and filter selected
 *
 * @returns Component
 */
import React, {useState, useEffect } from 'react';
import { Popover }                   from 'antd';
import PropTypes                     from 'prop-types';
import { connect }                   from 'react-redux';
import { learn }                     from 'store/actions/knowledge';
import { getTaggedEntities }         from 'store/actions/navigation';  // TODO:  MOVE TO BOOKMARK FOLDER STORE
import { fetchOuterModels }          from 'store/actions/userView';
import { getCustomTimeFrameLabel }   from 'utils/date';
import { str2DomFormat }             from 'utils/text';
import { Icon }                      from 'helpers';
import _                             from 'lodash';

import './assets/filter-values.less';


/**
* Render the FilterValues tags
* React Functional Components (Hooks)
*
* @returns JSX
*/
const FilterValues                            = (props) => {  // eslint-disable-line max-lines-per-function
    const {
            filterLabel,
            filterKey,
            values,
            bookmarksFoldersList,
            getTaggedEntitiesFromUserView,
            learnKnowledge,
            dynamicFilter,
            bookmarksFoldersAreLoaded,
            fetchOuterModels,
            bookmarksFoldersOuter
        }                                   = props,
        [filters, setFilters]               = useState([]),
        [countries, setCountries]           = useState([]),
        [continents, setContinents]         = useState([]),
        [timeFrames, setTimeFrames]         = useState([]),
        [filtersDefs, setFiltersDefs]       = useState([]),
        [tags, setTags]                     = useState([]),
        [taggedEntities, setTaggedEntities] = useState([]);

    // Learn general knowledge
    learnKnowledge(['filters', 'countries', 'continents', 'timeframes'])
        .then((slice) => {
            setFilters(slice.filters);
            setCountries(slice.countries);
            setContinents(slice.continents);
            setTimeFrames(slice.timeframes);
        });

    useEffect(() => {
        updateTaggedEntities();
    }, [values, filterKey, bookmarksFoldersList, filters]);

    useEffect(() => {
        updateFiltersDefs();
    }, [values, dynamicFilter, filters]);

    useEffect(() => {
        setFilterValuesTags();
    }, [
        values, dynamicFilter, taggedEntities, filters, countries, continents, filtersDefs,
        bookmarksFoldersOuter, bookmarksFoldersAreLoaded
    ]);



    /**
     * Return the group string if all elements are select or a list of elements strings
     *
     * @param {List} elements
     * @param {(List|Boolean)} groups
     *
     * @return string
     */
    const getElementsOrGroups = (elements = [], groups = [], groupBy = 'groupId') => {
        const elementsArray = elements.toArray && elements?.toArray() || [],
            groupsArray     = groups.toArray   && groups?.toArray()   || [];

        // Return elementArray if elements or groups don't exists
        if(elementsArray.length === 0 || groupsArray.length === 0) {
            const orderBy = elementsArray[0]?.order ? 'order' : 'label';
            return _.orderBy(elementsArray, orderBy);
        }

        // Otherwise recover groups and elements (in this order)
        const { values }    = props,
            countGroupsById = _.mapValues(_.groupBy(elementsArray, groupBy), (groupItems) => groupItems.length),
            groupedSelected = _.groupBy(elementsArray.filter(el => values.includes(el.id)), groupBy),
            groupItems      = [],
            elementItems    = [];

        Object.keys(groupedSelected).forEach(groupId => {
            const items = groupedSelected[groupId];
            if(countGroupsById[groupId] === items.length) {
                groupItems.push({
                    id    : groupId,
                    label : groupsArray.find(el => el.id == groupId)?.label,
                    type  : 'grouped',
                    count : countGroupsById[groupId],
                    labels: getLimitedArray(items)
                });
                return;
            }

            // Returns selected items
            const elementItemsFromGroup = addType(groupedSelected[groupId]);
            elementItemsFromGroup.forEach(elementItem => {
                elementItems.push(elementItem);
            });
        });

        return [..._.orderBy(groupItems, 'label'), ..._.orderBy(elementItems, 'label')];
    };


    /**
     * Returns a tag group for custom/exceptional subtypes
     *
     * @param {string} subtype
     * @returns Custom string or false
     */
    const getSubtypeCustomTags = (subtype) => {
        if(subtype == 'market') {
            return getElementsOrGroups(countries, continents, 'continent');
        }

        if(['year-range', 'month-range'].includes(subtype)) {
            const timeFramesArray = timeFrames || [];

            return addType([{id: values, label: getCustomTimeFrameLabel(values, timeFramesArray)}]);
        }

        if(subtype == 'int-range') {
            return addType([{id: values, label: _.uniq(values.split('_').slice(1, 3)).join(' - ')}]);
        }

        return false;
    };


    /**
     * Inject type in all array object
     *
     * @param {*} array  Array objects options
     * @param {string} type default item
     *
     * @return
     */
    const addType = (values, type='item') => {
        const { dynamicFilterPool } = props,
            array = _.isArray(values) ? values : [values];

        return array && array.map(item => {
            if (item.type === 'grouped') {
                return item;
            }

            const itemId     =  item.id || item,
                itemFromPool = !_.isArray(itemId) && (dynamicFilterPool?.find(poolItem => poolItem.id === itemId)),
                {
                    label: itemLabel
                }     = itemFromPool || {},
                id    = itemId,
                label = item.label || itemLabel || item;

            return {id, label, item: item.type || type};
        });
    };


    /**
     * Get filters definition and his groups definitions
     */
    const updateFiltersDefs = () => {
        const knowledgeFilterKeys          = filterKey.replace('(graph)', ''),
            filterDefinition               = filters.find((knowledge) => [knowledgeFilterKeys].includes(knowledge.id)),
            { options }                    = filterDefinition || {},
            { knowledge: optionKnowledge } = options || {},
            knowledge                      = optionKnowledge || knowledgeFilterKeys;

        learnKnowledge([knowledge, `${knowledge}-groups`]).then((a) => {
            return setFiltersDefs(a);
        });
    };


    /**
     * Return a custom string or an array with all options.
     *
     * @return array
     */
    const getFilterValuesOptions = () => {
        const { dynamicFilter }            = props,
            knowledgeFilterKeys            = filterKey.replace('(graph)', ''),
            filterDefinition               = filters.find((knowledge) => [knowledgeFilterKeys].includes(knowledge.id)),
            { subtype, options }           = filterDefinition || {},
            { knowledge: optionKnowledge } = options || {},
            knowledge                      = optionKnowledge || knowledgeFilterKeys,
            subTypeOptions                 = subtype && getSubtypeCustomTags(subtype),
            elements                       = filtersDefs[_.camelCase(knowledge)],
            groups                         = filtersDefs[_.camelCase(`${knowledge}-groups`)],
            knowledgeFilterDefinitions     = getElementsOrGroups(elements, groups);

        if (filterKey === 'bookmarkFolderForTags') {
            return getBookmarkFolderForTagsValues();
        }

        // Is a custom/exceptional subtype exists
        if(subTypeOptions) {
            return subTypeOptions;
        }

        // If filter use knowledge and knowledge group (if exists)
        if(knowledgeFilterDefinitions) {
            return knowledgeFilterDefinitions;
        }


        // If data is from a dynamic filter
        if(_.isArray(dynamicFilter) && !_.isEmpty(dynamicFilter)) {
            return addType(dynamicFilter);
        }

        return [];
    };


    /**
     * Use effect to set taggedEntities
     */
    const updateTaggedEntities = async () => {
        const folder       = bookmarksFoldersList?.find(userViewItem => values === userViewItem?.model?.id),
            { model }      = folder || {},
            { id }         = model || {};

        if (id) {
            const taggedEntities = await getTaggedEntitiesFromUserView({folderId: id});
            setTaggedEntities(taggedEntities || []);
        }
    };

    /**
     * Get bookmark folders tags information
     *
     * @returns array
     */
    const getBookmarkFolderForTagsValues = () => {
        const folder       = bookmarksFoldersList?.find(userViewItem => values === userViewItem?.model?.id),
            modelFromOuter = bookmarksFoldersOuter?.get(values),
            { model }      = folder || {},
            { label }      = model || modelFromOuter || {},
            folderLabel    = `${label}${modelFromOuter ? ' (deleted)' : ''}`;

        if (bookmarksFoldersAreLoaded && !folder) {
            fetchOuterModels('bookmark_folder', [values]);
        }

        return taggedEntities
            ? addType([{ id: values, label: folderLabel, /* A type: 'grouped' , */ labels: getTaggedEntitiesLabels() }])
            : [];
    };

    /**
     * Get tagged entities labels
     */
    const getTaggedEntitiesLabels = () => {
        return [];

        console.log('🚀 ~  taggedEntities:', taggedEntities); // eslint-disable-line no-unreachable
        return [{ id: 'aaaa', label: 'aaaaa'}, { id: 'bbbb', label: 'bbbbbb'}];
    };


    // TODO: make a component
    /**
     * Returns a subset of the given array limited to a max number of elements
     *
     * @param {array} array              The array to be filtered and limited.
     * @param {(array|bool)} selectedIds Array of selected ids or false
     * @param {object} options           Options: {max: max elements}
     * @returns array
     */
    const getLimitedArray = (filteredOptions, options = {max: 25}) => {
        const { max }   = options,
            subsetArray = _.slice(filteredOptions, 0, max) || [],
            totalCount  = filteredOptions.length,
            othersCount = totalCount - max;

        if(othersCount > 0) {
            subsetArray.push({label: `(+${othersCount} more)`, type: 'more'});
        }

        return subsetArray;
    };


    /**
     * Set filter values tags
     */
    const setFilterValuesTags = () => {
        const allFilterOptions = getFilterValuesOptions(),
            options            = _.isEmpty(allFilterOptions) ? addType(values) : allFilterOptions,
            filteredOptions    = options.filter(obj => values.includes(obj.id) || obj.type == 'grouped'),
            tags               = getLimitedArray(filteredOptions);

        setTags(tags);
    };


    /**
     * Render tooltips tags
     *
     * @param {*} label
     */
    const renderTags = (tags) => {
        const { inlineLabel } = props;

        const tagsElements = tags.map((tag, index) => {
            const {
                    id, label, labels,
                    type, count
                } = tag || {},
                labelString = _.isString(label) && label?.replace(/_/g, ' '),
                tagsElement = labelString && (
                    <span
                        key={`${id}-${index}`}
                        className={`selected-item ${type}`}
                    >
                        { labelString }
                        { count ? ` (${count})` : '' }
                    </span>
                );

            return labels
                ? (
                    <Popover
                        key={`popover-${id}`}
                        overlayClassName="filter-sub-labels"
                        placement="topLeft"
                        content={labels && renderTags(labels)}
                    >
                        {tagsElement}
                    </Popover>
                ) : tagsElement;
        });

        return _.reduce(tagsElements, (tagsElement, value, key) => {
            tagsElement.push(value);

            if (value !== _.last(tagsElements)) {
                tagsElement.push(
                    <span key={`or-${key}`} className="logical">
                        {inlineLabel ? ' or ' : 'OR'}
                    </span>
                );
            }

            return tagsElement;
        }, []);
    };


    /**
    * Check if filter is a query filter
    *
    * @returns {bool}
    */
    const isQueryFilter = () => {
        const filterDefinition = filters.find(filter => [filterKey].includes(filter.id));

        return filterDefinition?.isQueryFilter;
    };


    /**
     * Render icons
     *
     * @returns JSX
     */
    const renderIcon = () => {
        const { icon }     = props,
            { filterKey }  = props,
            modelFromOuter = bookmarksFoldersOuter?.get(values),
            isGraphFilter  = filterKey.substr(-7) == '(graph)',
            iconType       = icon === 'folder'
                ? `${modelFromOuter ? 'deleted-' : ''}folder`
                : icon;

        return (
            <React.Fragment key="icon">
                {icon && (
                    <Icon id={iconType} height={filterLabel ? 12 : 16}
                        color={filterLabel ? '#008200' : '#10285D'}
                    />
                )}

                {isGraphFilter && (
                    <Icon color="#c66f00" type="bar-chart"
                        width={10} height={14}
                    />
                )}
            </React.Fragment>
        );
    };


    /**
     * Render label
     *
     * @returns JSX || bool
     */
    const renderLabel = (tagsEl) => {
        const { inlineLabel } = props;

        return filterLabel && (
            <span key="label">
                {filterLabel} {inlineLabel && tagsEl}
            </span>
        );
    };


    /**
     * Render remove action button
     *
     * @returns JSX || bool
     */
    const renderRemoveAction = () => {
        const { filterKey, onRemove } = props;

        return onRemove && !isQueryFilter() && (
            <Icon key="close"
                color="#c66f00" type="close"
                width={10} height={14}
                onClick={() => onRemove(filterKey)}
            />
        );
    };


    /**
    * Rendering the FilterValues
    *
    * @returns JSX
    */
    const render = () => {
        const {
                className, inlineLabel, onVisibilityTooltipChange
            }          = props,
            classNames = ['sentence-filter'],
            tagsEl     = renderTags(tags);

        if (className) {
            classNames.push(className);
        }

        if (isQueryFilter() && !inlineLabel) {
            classNames.push('search-filter');
        }

        return (
            <Popover
                content={tags.length > 0 && tagsEl}
                color="rgba(0, 0, 0, 0.85)"
                onOpenChange={onVisibilityTooltipChange}
                overlayClassName={`filter-values ${inlineLabel ? 'hidden' : ''}`}
            >
                <span className={classNames.join(' ')} data-qa-key={str2DomFormat(filterLabel)}
                    style={{color: '#ffffff'}}
                >
                    {renderIcon()}
                    {renderLabel(tagsEl)}
                    {renderRemoveAction()}
                </span>
            </Popover>
        );
    };


    // The render call
    return render();
};


FilterValues.propTypes = {
    filterLabel  : PropTypes.string,
    filterKey    : PropTypes.string.isRequired,
    className    : PropTypes.string,
    values       : PropTypes.any,
    icon         : PropTypes.any,
    dynamicFilter: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    onRemove     : PropTypes.func,
    inlineLabel  : PropTypes.bool,
};


FilterValues.defaultProps = {
    className    : '',
    filterKey    : '',
    values       : '',
    dynamicFilter: false,
    inlineLabel  : false,
};


FilterValues.displayName = 'FilterValues';


/**
* Bind the store to to component
*/
const mapStateToProps               = state => {
    const filters                   = state.get('knowledge').library.get('filters');
    const bookmarksFoldersList      = state.getIn(['userView', 'bookmark_folder', 'list']);
    const bookmarksFoldersOuter     = state.getIn(['userView', 'bookmark_folder', 'outerModels']);
    const bookmarksFoldersAreLoaded = state.getIn(['userView', 'bookmark_folder', 'stats', 'modelsAreLoaded']);

    return { filters, bookmarksFoldersList, bookmarksFoldersAreLoaded, bookmarksFoldersOuter };
};


export default connect(mapStateToProps, {
    learnKnowledge               : learn,
    getTaggedEntitiesFromUserView: getTaggedEntities,
    fetchOuterModels
})(FilterValues);
