/**
 * Navigation actions
 *
 */

// Actions about search navigation
export const SEARCH_REFRESHED             = 'SEARCH_REFRESHED';
export const SEARCH_FETCHED               = 'SEARCH_FETCHED';
export const SEARCH_STATE_FETCHED         = 'SEARCH_STATE_FETCHED';
export const SEARCH_FORGOT                = 'SEARCH_FORGOT';
export const LASTS_SEARCHES_UPDATED       = 'LASTS_SEARCHES_UPDATED';

// Actions about navigation tree
export const INIT                         = 'INIT';
export const UPDATE_CURRENT_INDEX         = 'UPDATE_CURRENT_INDEX';

export const UPDATE_CURRENT_ENTITY        = 'UPDATE_CURRENT_ENTITY';
export const CREATE_POOL                  = 'CREATE_POOL';
export const ADD_TO_POOL                  = 'ADD_TO_POOL';
export const REMOVE_LAST_FROM_POOL        = 'REMOVE_LAST_FROM_POOL';
export const SWITCH_LAST_ACTIVE_IN_POOL   = 'SWITCH_LAST_ACTIVE_IN_POOL';

// Actions for Report
export const REPORT_OPEN                  = 'REPORT_OPEN';
export const REPORT_FETCH                 = 'REPORT_FETCH';
export const REPORT_DELETE                = 'REPORT_DELETE';
export const REPORT_SAVE                  = 'REPORT_SAVE';
export const REPORT_GENERATE              = 'REPORT_GENERATE';

// Actions for nodesPreferences
export const STORE_NODE_PREFERENCES       = 'STORE_NODE_PREFERENCES';

// Actions controlling shortcuts
export const ACTIVATE_SHORTCUT            = 'ACTIVATE_SHORTCUT';
