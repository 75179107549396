import React, { Component } from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import _                    from 'lodash';
import Autocomplete         from 'helpers/Search/Autocomplete';
import { Icon }             from 'helpers';
import { dataGet }          from 'utils/api';
import { Checkbox }         from 'antd';

import './assets/organizations.less';

/**
 * Choose some organisations step
 *
 */
class Organizations extends Component {

    /**
     * Construct
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'toggleEntity', 'renderSuggestionSuffix',
            'renderSelectedEntities', 'sortEntities', 'switchFromSelection',
        );

        this.state = {
            // Store there models for entities ids
            selectedEntities: {}
        };
    }

    /**
    *
    */
    componentDidMount() {
        this.updateSelectedEntities();
    }

    /**
    *
    */
    componentDidUpdate() {
        this.updateSelectedEntities();
    }


    /**
     * Refresh selected entities with models provided by API
     *
     * @param {} entity
     */
    updateSelectedEntities() {
        const { selectedEntities } = this.state,
            {
                newsletter, update,
            }                      = this.props,
            { entities }           = newsletter,
            shouldUpdate           = _.difference(entities, _.keys(selectedEntities)).length > 0;

        if (!shouldUpdate) {
            return;
        }

        if (!entities.length) {
            update({
                ...newsletter,
                entities
            });

            this.setState({
                selectedEntities: {}
            });
        }

        this.updateSelectedEntitiesFromEntitiesMap();
    }

    /**
    * If the entitiesMap has changed, update the selectedEntities with the new data
    * @returns return Void
    */
    updateSelectedEntitiesFromEntitiesMap() {
        const {
                entitiesMap, newsletter
            }                    = this.props,
            { selectedEntities } = this.state,
            { entities }         = newsletter,
            entitiesByDataId     = entitiesMap
                ? _.keyBy(entitiesMap.toJS(), item => {
                    return item.model?.entity?.id;
                })
                : {},
            entitiesFromUserView = _.pick(entitiesByDataId, entities),
            newSelectedEntities = _.mapValues(
                entitiesFromUserView,
                item => item.model.entity
            ),
            goodUpdate = _.xor(_.keys(selectedEntities), _.keys(newSelectedEntities)).length !== 0;

        // Use entites from user view
        if (goodUpdate) {
            this.setState({
                selectedEntities: newSelectedEntities
            });
        }

        // TODO: Get only not enities not founded in userview
        // Get newSelectedEntities from api
        if (entitiesFromUserView.length !== entities?.length && entities?.length > 0) {
            dataGet('/entities', { data: { ids: entities } })
                .then(
                    response => {
                        const { body }          = response,
                            newSelectedEntities = {};

                        body.forEach(model => {
                            const { entity } = model;
                            newSelectedEntities[entity.id] = entity;
                        });

                        this.setState({
                            selectedEntities: newSelectedEntities
                        });
                    }
                );
        }
    }


    /**
    *
    * @param {object} entity
    */
    toggleEntity(entity) {
        const { update, newsletter } = this.props,
            { selectedEntities }     = this.state,
            { entities }             = newsletter;

        if (!entities.includes(entity.id)) {
            selectedEntities[entity.id] = entity;

            this.setState({
                selectedEntities
            });
        }

        update({
            ...newsletter,
            entities: _.xor(entities, [entity.id])
        });
    }


    /**
    * Get bookmarhed organizations
    *
    * @return array
    */
    getBookmarkedOrganizations() {
        const { bookmarksList } = this.props,
            bookmarkEntities    = bookmarksList && bookmarksList
                .map(streamEntity => streamEntity.model?.entity?.entity)
                .filter(entity => entity && entity.type === 'orgunit');

        return bookmarkEntities ? bookmarkEntities.toJS(): [];
    }

    /**
    * Render Autocompleter's suggestions suffix
    *
    * @param {object} entity
    *
    * @returns JSX
    */
    renderSuggestionSuffix(entity) {
        const { newsletter } = this.props,
            { entities }     = newsletter;

        return (
            <button className="check-it">
                <Checkbox
                    checked={_.isArray(entities) && entities.includes(entity.id)}
                />
            </button>
        );
    }

    /**
    * Switch entity from selection, then kill bubling.
    */
    switchFromSelection = (entity) => {
        this.toggleEntity(entity);
    };


    /**
    * Render the autocomplete
    *
    * @return html
    */
    renderAutocomplete() {
        return (
            <div className="autocomplete">
                <Autocomplete
                    max={25}
                    types={['orgunit']}
                    onSelect={this.switchFromSelection}
                    placement="rightTop"
                    renderSuffix={this.renderSuggestionSuffix}
                    overlayClassName="organizations-suggestions"
                    placeholder="Add organization or company you want to track"
                />
                <Icon id="magnify" height={16}
                    color="#e0e0e0"
                />
            </div>
        );
    }

    /**
     * Sort entities
     *
     * @param {object || string} entityA
     * @param {object || string} entityB
     *
     * @return integer Direction of sort
     */
    sortEntities(entityA, entityB) {
        const { selectedEntities } = this.state,
            labelA               = _.isString(entityA) && selectedEntities[entityA]
                ? selectedEntities[entityA].label
                : entityA.label,
            labelB               = _.isString(entityB) && selectedEntities[entityB]
                ? selectedEntities[entityB].label
                : entityB.label;

        if (labelA < labelB) {
            return -1;
        }

        if (labelA > labelB) {
            return 1;
        }

        return 0;
    }


    /**
    * Render the autocompleted organizations
    *
    * @return html
    */
    renderSelectedEntities() {
        const { newsletter }     = this.props,
            { entities }         = newsletter,
            { selectedEntities } = this.state;

        // Not any selected entity at this time.
        if (!selectedEntities || !_.keys(selectedEntities).length) {
            return false;
        }

        entities.sort(this.sortEntities);

        return (
            <div className="newsletter-entities">
                <div className="organizations-block-title">Is added to your Insight Feed</div>
                {entities.map(id => this.renderOrganization(selectedEntities[id], true))}
                {entities.length === 0 && (
                    <span className="must-add">You must add an organization</span>
                )}
            </div>
        );
    }


    /**
    * Render the autocompleted organizations
    *
    * @return html
    */
    renderBookmarkedOrganizations() {
        const organizations = this.getBookmarkedOrganizations();

        organizations.sort(this.sortEntities);

        return organizations.length > 0 && (
            <div className="bookmarked-entities">
                <div className="organizations-block-title">
                    Suggestions from your company profiles bookmarks
                </div>
                {organizations.map(organization => this.renderOrganization(organization))}
            </div>
        );
    }


    /**
    * Render the autocompleted organizations
    *
    * @return html
    */
    renderOrganization(entity, noCheckbox = false) {
        // No way to render an undefined entity
        if (!entity) {
            return false;
        }

        const { newsletter } = this.props,
            { entities }     = newsletter,
            /**
             * Trigger the click action
             */
            onClick          = () => {
                if (entity) {
                    this.toggleEntity(entity);
                }
            };

        return (
            <div
                key={entity.id}
                data-id={entity.id}
                className="entity"
            >
                {!noCheckbox && (
                    <Checkbox
                        checked={_.isArray(entities) && entities.includes(entity.id)}
                        onClick={onClick}
                    />
                )}
                <Icon
                    id="orgunit"
                    folder="/entities/"
                    key={entity.id}
                    width={18}
                    height={18}
                    discShaped
                    orgunitLogo={entity.id}
                    borderSize={1}
                    borderColor="#888"
                />
                <span
                    className={`label${!noCheckbox ? ' clickable' : ''}`}
                    onClick={!noCheckbox ? onClick : undefined}
                >
                    {entity.label}
                </span>
                {noCheckbox && (
                    <div className="remove-entity" onClick={onClick}>
                        <Icon id="close" height={16}
                            color="#aaaaaa"
                        />
                    </div>
                )}
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <div className="organizations setting">
                {this.renderAutocomplete()}
                {this.renderSelectedEntities()}
                {this.renderBookmarkedOrganizations()}
            </div>
        );
    }

}

Organizations.propTypes = {
    bookmarksList: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    entitiesMap  : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    newsletter   : PropTypes.object,
    update       : PropTypes.func
};

Organizations.definition =  {
    key     : 'entities',
    label   : 'Companies & Organizations',
    question: 'Who do you want to track?',
    view    : 'Organizations',
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList : state.getIn(['userView', 'bookmark', 'list']),
    entitiesMap   : state.getIn(['userView', 'entity', 'map']),
    newslettersMap: state.getIn(['userView', 'newsletter', 'map']),
});

export default connect(mapStateToProps, {
})(Organizations);

