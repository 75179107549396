/**
 * Insight element module
 *
 * @return Component
 */
import React, { Component } from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import Element                        from '../helpers/Element';
import { learn }                      from '../store/actions/knowledge';
import { dataGet }                    from '../core/utils/api';

import './Element/assets/editor.less';

/**
 * Element module.
 *
 */
class ElementManager extends Component {

    /**
    * When component is ready, load the orgunit
    */
    componentDidMount() {
        const { path } = this.props,
            splitedPath = path.split('/');

        if (splitedPath.length < 3) {
            console.info('A source model is needed.');
            return;
        }

        const modelArray = [splitedPath[3]];

        if (splitedPath[4]) {
            modelArray.push(splitedPath[4]);
        }

        dataGet('/entities', {
            data: { ids: modelArray }
        }).then((response) => {
            const { body } = response;

            this.setState({
                model  : body[0],
                element: splitedPath[2],
                context: body[1] || null
            });
        });
    }

    /**
    * When component has updated
    */
    componentDidUpdate() {
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { model, element, context } = this.state || {};

        if (!model || !element) {
            return false;
        }

        return (
            <div className="element-editor">
                <div className="element">
                    <Element
                        model={model.entity}
                        element={element}
                        context={context}
                        height={400}
                        width={800}
                    />
                </div>
            </div>
        );
    }

}

ElementManager.propTypes = {
    path: PropTypes.shape({
        split: PropTypes.func,
    })
};


/**
 * Bind the store to to component
 */
export default connect(() => {}, {
    learnKnowledge: learn,
})(ElementManager);
