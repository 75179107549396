/**
 * Keep all actions types listed there
 *
 */

// Triggered when the web socket has been initialized
export const WEB_SOCKETS_INITIALIZED = 'WEB_SOCKETS_INITIALIZED';
export const WEB_SOCKETS_EMIT_EVENT  = 'WEB_SOCKETS_EMIT_EVENT';

export default {
    WEB_SOCKETS_INITIALIZED,
    WEB_SOCKETS_EMIT_EVENT,
};
