import React, { Component }              from 'react';
import PropTypes                         from 'prop-types';
import ImmutablePropTypes                from 'react-immutable-proptypes';
import _                                 from 'lodash';
import { connect }                       from 'react-redux';
import {
    Row, Col,
    Element, Highlight
}                                        from 'helpers';
import { learn }                         from 'store/actions/knowledge';
import { renderDateInterval }            from 'utils/text';

import DocumentHeader                    from './DocumentHeader';

import Infobox                           from '../utils/Infobox';
import Actors                            from '../utils/Actors';

import './assets/clinical.less';

/**
 * Display a Clinical trial
 *
 */
class Clinical extends Component {

    /**
    * Constructor : init an empty state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'setActorsExportDataCb', 'setReferenceExportDataCb');

        this.state = {
            clinicalInterventionTypes: null,
            elements                 : [],
        };
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge([
            'clinical-phases', 'clinical-status', 'nova-languages',
            'clinical-types', 'clinical-purposes', 'nova-sources',
            'clinical-intervention-types', 'entities', 'elements',
        ])
            .then(this.setState.bind(this));
    }

    /**
    * Get joined labels of a knowledge from ids
    *
    * @return string
    */
    getLabelsOfKnowledge(knowledgeName, ids, separator = ', ') {
        const { [knowledgeName]: values } = this.state;

        return values ? values
            .filter((value) => (ids ? ids.indexOf(value.id) !== -1 : false))
            .map((value) => value.label)
            .join(separator)
            : '';
    }


    /**
    * Get interventions export data
    *
    * @return JSX
    */
    getInterventionsExportData() {
        const { clinicalInterventionTypes } = this.state,
            { entity }                      = this.props,
            rows                            = [];

        if (!clinicalInterventionTypes) {
            return null;
        }

        _.each(clinicalInterventionTypes.toArray(), (interventionType) => {
            const key = `intervention_detail_${interventionType.id}`;

            if (!entity[key]) {
                return true;
            }

            rows.push(`${this.getLabelsOfKnowledge('clinicalInterventionTypes', [interventionType.id])}\
                ( ${entity[key].join(' | ')} )`);

            return true;
        });

        return rows.join('\n');
    }

    /**
    * Plug Actors ExportData call back
    *
    * @return html
    */
    setActorsExportDataCb(getActorsExportData) {
        this.getActorsExportData = getActorsExportData;
    }

    /**
    * Plug get References ExportData call back
    *
    * @return html
    */
    setReferenceExportDataCb(getReferenceExportData) {
        this.getReferenceExportData = getReferenceExportData;
    }

    /**
    * Render the Infobox
    *
    * @return JSX
    */
    renderInfobox() {
        const { entity } = this.props,
            { clinicalInterventionTypes } = this.state;

        if (!entity || !clinicalInterventionTypes) {
            return null;
        }

        const elements = [
            { label: 'Phase :', value: this.getLabelsOfKnowledge('clinicalPhases', entity.study_phase) },
            { label: 'Dates :', value: renderDateInterval(entity.start, entity.end) },
            { label: 'Status :', value: this.getLabelsOfKnowledge('clinicalStatus', [entity.study_status]) },
            { label: 'Base :', value: this.getLabelsOfKnowledge('novaSources', [entity.source]) },
            { label: 'Language :', value: this.getLabelsOfKnowledge('novaLanguages', [entity.lang]) },
            { label: 'Link :', value: entity.url, isUrl: true }
        ];

        return (
            <Infobox elements={elements} className="entity-information" />
        );
    }

    /**
    * Render the descriptions according to the project type
    *
    * @return JSX
    */
    renderDescriptions() {
        const { entity } = this.props;

        return [
            entity.abstract_description && (
                <Row label="Abstract" className="textual rel">
                    <Highlight stripTags>
                        {entity.abstract_description}
                    </Highlight>
                </Row>
            ),
            entity.content_en && (
                <Row label="Description" className="textual rel">
                    <Highlight stripTags>
                        {entity.content_en}
                    </Highlight>

                </Row>
            ),
            entity.keywords && (
                <Row label="Keywords" className="keywords textual rel">
                    <Highlight>
                        {entity.keywords}
                    </Highlight>
                </Row>
            )
        ];
    }

    /**
    * Render the study design Infobox
    *
    * @return JSX
    */
    renderStudyDesign() {
        const { entity } = this.props,
            elements = [
                { label: 'Type :', value: this.getLabelsOfKnowledge('clinicalTypes', [entity.subtype]) },
                { label: 'Purpose :', value: this.getLabelsOfKnowledge('clinicalPurposes', [entity.study_purpose]) },
                { label: 'Masking :', value: entity.study_masking }
            ];

        return (
            <Row label="Study Design">
                <Infobox elements={elements} label={false} />
            </Row>
        );
    }

    /**
    * Render interventions of the clinical
    *
    * @return JSX
    */
    renderInterventions() {
        const { clinicalInterventionTypes } = this.state,
            { entity }                      = this.props,
            rows                            = [];

        if (!clinicalInterventionTypes) {
            return null;
        }

        _.each(clinicalInterventionTypes.toArray(), (interventionType) => {
            const key = `intervention_detail_${interventionType.id}`;

            if (!entity[key]) {
                return true;
            }

            rows.push(
                <div key={key}>
                    {`${this.getLabelsOfKnowledge('clinicalInterventionTypes', [interventionType.id])}\
                        ( ${entity[key].join(' | ')} )`}
                </div>
            );

            return true;
        });

        if (rows.length === 0) {
            return null;
        }

        return (
            <Row label="Interventions" className="textual">
                {rows}
            </Row>
        );
    }

    /**
    * Produce the proper display of the document content
    *
    * @return JSX
    */
    render() { // eslint-disable-line  max-lines-per-function
        const {
                entity, entities,
                onClick, registerCallbacks, bookmarks
            }           = this.props,
            {
                elements
            }           = this.state,
            element     = elements.find((el) => el.category === 'Area/Entity/References');

        if (!entity || elements.length === 0) {
            return null;
        }

        return (
            <Col className="document clinicaltrial sheet">
                <DocumentHeader entity={entity} entities={entities}
                    bookmarks={bookmarks}
                />
                <Row className="document-content">
                    <Col ratio="2/10" alignement="center"
                        className="sheet-col"
                    >
                        <Row>
                            {this.renderInfobox()}
                        </Row>
                        <Row className="textual other-info">
                            <Actors
                                entity={entity}
                                onClick={onClick}
                                registerCallbacks={registerCallbacks}
                            />
                        </Row>
                    </Col>
                    <Col ratio="5/10" className="sheet-col">
                        {this.renderDescriptions()}
                    </Col>
                    <Col ratio="3/10" className="sheet-col other-data">
                        {this.renderStudyDesign()}
                        <Row label="Conditions" className="textual">
                            {entity.study_condition}
                        </Row>
                        {this.renderInterventions()}
                        <Row>
                            <Element
                                element={element}
                                model={entity}
                                onClick={onClick}
                                registerCallbacks={registerCallbacks}
                            />
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }

}

Clinical.propTypes = {
    entity           : PropTypes.oneOfType([PropTypes.shape({}).isRequired, PropTypes.bool]),
    learnKnowledge   : PropTypes.func.isRequired,
    entities         : PropTypes.arrayOf(PropTypes.object),
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    bookmarks        : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
};

Clinical.defaultProps = {
    entity: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Clinical);

