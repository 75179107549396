/**
 * Display newsletter: should be used by capture
 *
 * @exports Component
 */

import PropTypes                                       from 'prop-types';
import React,  { Component }                           from 'react';
import { connect }                                     from 'react-redux';
import ImmutablePropTypes                              from 'react-immutable-proptypes';
import { Newsletter as  NewsletterHelper }             from 'helpers';
// A import fakeData                                   from './Newsletter/fake-data.json';
// A import fakeOrgunitData                                 from './Newsletter/fake-orgunit-data.json';
import fakeOrgunitData                                 from './Newsletter/fake-orgunit-data-nike.json';
import fakesearchData                                  from './Newsletter/fake-search-data.json';

const testing = [
        {
            newsletterId: 'b223393b-7acb-4384-adb6-706bc93c1acd',
            tag         : fakeOrgunitData.tag,
            content     : fakeOrgunitData.content,
            period      : fakeOrgunitData.period,
            ranges      : fakeOrgunitData.ranges,
        },
        {
            newsletterId: '3c7352dd-0d99-4dc2-9732-b0ba6f105e7e',
            content     : fakesearchData.content,
            metadata    : fakesearchData.metadata,
        }
    ],
    testingIndex = 0;


/**
 * Newsletter module for Insight
 *
 */
class Newsletter extends Component {

    /**
    * Initialize news module
    *
    */
    constructor(props) {
        super(props);

        this.state = {
            content      : null,
            featuredImage: null
        };

        window.animationIsDisabled = () => true;
        window.isCapture           = () => true;
    }

    /**
    * Triggered when the component is ready
    */
    async componentDidMount() {
        const captures                          = window.getCaptureData ? await window.getCaptureData() : null,
            { params }                          = captures ? captures[0] : {},
            {
                data, featuredImage, newsletter,
                tag, metadata, period, ranges } = params || {};

        this.setState({
            newsletterId: newsletter || testing[testingIndex].newsletterId,
            data        : data || testing[testingIndex].content,
            metadata    : metadata || testing[testingIndex].metadata,
            period      : period || testing[testingIndex].period,
            ranges      : ranges || testing[testingIndex].ranges,
            tag         : tag || testing[testingIndex].tag,
            featuredImage
        });
    }


    /**
     * Get the current newsletter content using selectedTag
     *
     * @returns object
     */
    getNewsletterContent() {
        const { metadata, tag, ranges, period } = this.state,
            newsletterContent = { metadata, tag, ranges, period };

        return newsletterContent;
    }

    /**
    * Render the newsletter
    *
    * @returns JSX
    */
    render() {
        const { newsletterStream } = this.props,
            { data, newsletterId } = this.state,
            newsletterContent      = this.getNewsletterContent(),
            streamEntity           = newsletterStream?.find(se => se.model?.id === newsletterId),
            newsletter             = streamEntity?.model;


        if (!data || !newsletter || !newsletterContent) {
            return false;
        }

        return data && (
            <NewsletterHelper
                fromCapture
                data={data}
                newsletterContent={newsletterContent}
                newsletter={newsletter}
            />
        );
    }

}

Newsletter.propTypes = {
    newsletterStream: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    newsletterStream: state.getIn(['userView', 'newsletter', 'list'])
});

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {})(Newsletter);

