import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { Popover }          from 'antd';
import {
    arc              as d3Arc,
    scaleLinear      as d3ScaleLinear,
    scaleBand        as d3ScaleBand,
    range            as d3Range,
    hsl              as d3Hsl,
    interpolateRgb   as d3InterpolateColor,
} from 'd3';

import { stripTags, htmlize } from '../../../core/utils/text';
import { rad2Deg }   from '../../../core/utils/svg';

import './Gauge/main.less';

// Import SVG shapes
import puzzle  from './Gauge/shapes/puzzle.svg';

import Icon                 from '../../Icon';

// Pack shapes
const importedShapes = {
    puzzle,
};

/**
 * The gauge graph Component
 *
 */
class Gauge extends Component {

    /**
    * Update rangeScale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object {nextProps, state}
    */
    static updateRangeScale(options) {
        const { nextProps, state }     = options,
            { straight, innerPadding } = nextProps,
            { outerPadding }           = nextProps,
            { nbStep }                 = state,
            range = straight
                ? Gauge.getStraightRange({ nextProps, state })
                : Gauge.getArcRange({ nextProps, state });

        state.rangeScale = d3ScaleBand().range(range);

        state.rangeScale
            .paddingInner(innerPadding)
            .paddingOuter(outerPadding)
            .domain(d3Range(nbStep));

        return { nextProps, state };
    }

    /**
    * Update linear in state
    *
    * @params options object { nextProps, state }
    *
    * @return object {nextProps, state}
    */
    static updateLinearScale(options) {
        const { nextProps, state } = options,
            { straight }           = nextProps,
            range                  = straight
                ? Gauge.getStraightRange({ nextProps, state })
                : Gauge.getArcRange({ nextProps, state });

        state.linearScale = d3ScaleLinear()
            .range(range)
            .domain([0, 1]);

        return { nextProps, state };
    }

    /**
     * Update Ranges in state
     *
     * @params options object { nextProps, state }
     * @returns
     */
    static updateRanges(options) {
        const { nextProps, state } = options,
            { ranges, data }       = nextProps,
            stats                  = _.get(data, 'stats', {}),
            { ranges:dataRanges }  = stats;

        state.ranges = ranges || dataRanges || false;

        return { nextProps, state };
    }

    /**
    * Update nbStep in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateNbStep(options) {
        const { nextProps, state } = options,
            { steps }  = nextProps,
            { ranges } = state;

        state.nbStep = steps !== false ? steps : ranges.length;
        return { nextProps, state };
    }

    /**
    * Update step in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateStep(options) {
        const { nextProps, state } = options,
            { data, steps}         = nextProps,
            { ranges }             = state,
            { score, step }        = _.get(data, 'stats', {}),
            /**
            * Get slice index in ranges definitions
            *
            * @params number Score value (0 to 1)
            *
            * @return string
            */
            rangesScale = (val) => {
                let istep = 0;
                _.each(ranges, (slice, index) => {
                    if (val < slice.value) {
                        return;
                    }
                    istep = index;
                });
                return istep;
            };

        state.step = !_.isUndefined(step)
            ? step - 1
            : steps !== false
                ? (Math.ceil(score * steps) - 1)
                : rangesScale(score);

        return { nextProps, state };
    }

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state }  = options,
            {
                color,
                showGradient,
                gradientColor,
                background,
                gaugeBackground
            } = nextProps,
            dc     = d3Hsl(color), // Make D3 range scale
            colors = {
                background,
                text           : color,
                gaugeBackground: gaugeBackground || 'transparent',
                gauge          : [
                    gradientColor || dc.brighter(showGradient ? 2.5 : 0).toString(),
                    dc.toString()
                ],
            };

        // Store colors (used in dataPlot functions)
        colors.gradient = d3InterpolateColor(colors.gauge[0], colors.gauge[1]);
        state.colors = colors;

        return { nextProps, state };
    }

    /**
    * Update dataPlot in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state } = options,
            { straight } = nextProps;

        // Store plot data
        state.dataPlot = straight
            ? Gauge.getStraightPlotData({ nextProps, state })
            : Gauge.getArcPlotData({ nextProps, state });

        return { nextProps, state };
    }

    /**
    * Update needleAngle in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateNeedleAngle(options) {
        const { nextProps, state }            = options,
            { straight, data, angleStart }    = nextProps,
            { rangeScale, linearScale, step } = state,
            { nbStep }                        = state,
            stats                             = _.get(data, 'stats', {}),
            stepLength = rangeScale.step(),
            bandwidth  = rangeScale.bandwidth();

        state.needleAngle = straight
            ? 0
            : rad2Deg(
                nbStep
                    ? stepLength * (step) + bandwidth / 2 + angleStart + Math.PI / 2
                    : linearScale(stats.score) + Math.PI / 2
            );

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderDefs', 'render');

        this.state = {};

        // Make a uuid
        this.id = _.uniqueId('gauge-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - scales (D3Scales object)
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps) {
        const {
                margin,
                height,
                width,
                straight,
                fontSize,
                noLabel,
                doubleHeight  // TODO Calculate size (ray) using angle for gauge in arc
            } = nextProps,
            size             = _.min([
                width,
                height * (doubleHeight ? 1 : 2) - fontSize * 2
            ]) - 2,
            horizontalSizing = size >= width - 2,
            innerWidth       = (straight ? width  : size) - 2 * margin,
            innerHeight      = (straight ? height : size) - 2 * margin,
            // Cascading function to update state
            updateState = _.flow([
                Gauge.updateRanges,       // Ranges
                Gauge.updateNbStep,       // Number of steps
                Gauge.updateRangeScale,   // D3 range scale
                Gauge.updateLinearScale,  // D3 linear scale
                Gauge.updateStep,         // Current Step
                Gauge.updateColors,       // Colors and gradient
                Gauge.updatePlotData,     // Location and svg path
                Gauge.updateNeedleAngle   // Needle angle
            ]),
            { state } = updateState({
                nextProps,
                state: {
                    // Size of the chart (without landmark )
                    innerWidth,
                    innerHeight,
                    graphOffset: {
                        x: (horizontalSizing ? innerWidth / 2 : width / 2 - margin) + 1,
                        y: (horizontalSizing ? innerHeight / 2 : innerHeight / 2) + 1 - (doubleHeight || noLabel ? 0 : fontSize),
                    }
                },
            });

        return state;
    }

    /**
    * Get straight range
    *
    * @params options object { nextProps, state }
    *
    * @return array
    */
    static getStraightRange(options) {
        const { nextProps, state } = options,
            { outerMargin }        = nextProps,
            margin                 = state.innerWidth * outerMargin / 2;

        return [margin, state.innerWidth - margin];
    }

    /**
    * Get arc range
    *
    * @params options object { nextProps, state }
    *
    * @return array
    */
    static getArcRange(options) {
        const { nextProps }          = options,
            { angleStart, angleEnd } = nextProps;

        return [angleStart, angleEnd];
    }

    /**
    * Get arc plot data
    *
    * @params options object     { nextProps, state }
    * @params options rangeScale D3 range scale
    *
    * @return object
    */
    static getArcPlotData(options) {
        const { state } = options,
            { nbStep }  = state;

        return nbStep
            ? Gauge.getRangedArcPlotData(options)
            : Gauge.getLinearArcPlotData(options);
    }

    /**
    * Get ranged arc plot data
    *
    * @params options object     { nextProps, state }
    * @params options rangeScale D3 range scale
    *
    * @return object
    */
    static getRangedArcPlotData(options) {
        const { nextProps, state }                 = options,
            {
                innerWidth, colors, rangeScale,
                nbStep, step, ranges
            }                                       = state,
            { innerRadius, emptyStepSize }          = nextProps,
            { roundedStep, arcSize, emptyColor }    = nextProps,
            stepLength            = rangeScale.step(),
            domain                = rangeScale.domain(),
            r                     = innerWidth / 2 * arcSize,
            ringWidth             = r * (1 - innerRadius),
            ringMiddle            = r - ringWidth / 2,
            rangedArc             = d3Arc()
                .cornerRadius(roundedStep ? ringWidth / 4 : 0)
                .innerRadius(r * innerRadius)
                .outerRadius(r)
                .startAngle((i) => rangeScale(domain[i]))
                .endAngle((i)   => rangeScale(domain[i]) + stepLength - nextProps.innerPadding * stepLength),
            dataPlot              =  domain.map((d, i) => {
                const filled = i <= step;

                if (!filled && emptyStepSize) {
                    rangedArc.innerRadius(ringMiddle - emptyStepSize / 2)
                        .outerRadius(ringMiddle + emptyStepSize / 2);
                }

                return {
                    index : i,
                    label : ranges ? ranges[i].label : null,
                    path  : rangedArc(i),
                    color : filled ? colors.gradient(i / nbStep) : emptyColor || colors.gradient(0.25),
                    filled: filled ? colors.gradient(i / nbStep) : emptyColor || 'transparent'
                };
            });

        return dataPlot;
    }

    /**
    * Get linear arc plot data
    *
    * @params options object     { nextProps, state }
    * @params options rangeScale D3 range scale
    *
    * @return object
    */
    static getLinearArcPlotData(options) {
        const { nextProps, state }  = options,
            { innerWidth, colors  } = state,
            { linearScale }         = state,
            { innerRadius, data }   = nextProps,
            stats                   = _.get(data, 'stats', {}),
            r                       = innerWidth / 2,
            linearArc               = d3Arc()
                .cornerRadius(r * (1 - innerRadius))
                .innerRadius(r * innerRadius)
                .outerRadius(r)
                .startAngle(() => linearScale(0))
                .endAngle((i)   => linearScale(i)),
            linearWidth  = r * (1 - innerRadius),
            linearCenter = r - linearWidth / 2,
            linearBackgroundArc = d3Arc()
                .cornerRadius(linearWidth)
                .innerRadius(linearCenter - 0.5)
                .outerRadius(linearCenter + 0.5)
                .startAngle(linearScale(0))
                .endAngle(linearScale(1)),
            dataPlot = [
                {
                    index : 1,
                    path  : linearBackgroundArc(),
                    filled: true,
                    color : colors.gradient(1)
                },
                {
                    index : 0,
                    label : stats.label,
                    path  : linearArc(stats.score),
                    filled: true,
                    color : colors.gradient(stats.score)
                },
            ];

        return dataPlot;
    }

    /**
    * Get plot arc data
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static getStraightPlotData(options) {
        const { nextProps, state } = options,
            { rangeScale, step }   = state,
            domain                 = rangeScale.domain(),
            dataPlot               = domain.map((d, i) => ({
                index   : i,
                location: rangeScale(domain[i]),
                size    : nextProps.thickness,
                filled  : i <= step
            }));

        return dataPlot;
    }


    /**
     * Return the step label text
     *
     * @return string
     */
    getLabelText(step) {
        const {
                showText, data
            }                     = this.props,
            stats                 = _.get(data, 'stats', {}),
            { ranges }            = this.state,
            { label }             = stats,
            stepText              = showText ? ranges[step].text : false,
            stepLabel             = ranges !== false
                ? ranges[step].label
                : !_.isUndefined(label) ? label : null;

        return showText && stepText
            ? `<b>${stripTags(stepLabel)}</b>: ${stripTags(stepText)}`
            :  stripTags(stepLabel);
    }


    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { colors, innerWidth } = this.state;

        return (
            <defs>
                <mask id={`${this.id}-mask`}>
                    {this.renderPaths()}
                </mask>
                {/* Gradient of the gauge */}
                <linearGradient
                    id={`${this.id}-filled`}
                    gradientUnits="userSpaceOnUse"
                    x1="0"
                    x2={innerWidth / 2}
                    y1="0"
                    y2="0"
                >
                    <stop offset="0%" stopColor={colors.gauge[0]} />
                    <stop offset="100%" stopColor={colors.gauge[1]} />
                </linearGradient>

            </defs>
        );
    }

    /**
    * Render label of the graph
    *
    * @return html
    */
    renderLabel() {
        const {
                fontSize, noLabel,
                width, boldLabel
            }                = this.props,
            { step, colors } = this.state,
            stepLabel        = this.getLabelText(step),
            labelTopPosition = this.getLabelTopPosition();

        if (_.isNull(stepLabel) || noLabel) { return null; }

        return (
            <div
                className="label"
                key="label"
                style={{
                    top : labelTopPosition,
                    left: 0,
                    width
                }}
            >
                {htmlize(stepLabel, {
                    color     : colors.text,
                    fontSize  : `${fontSize}px`,
                    fontWeight: boldLabel ? 'bold' : null
                })}
            </div>
        );
    }

    /**
     * Get the label top position
     *
     * @return string
     */
    getLabelTopPosition() {
        const {
                straight, fontSize,
                thickness, overflow,
                labelOnBottom, height,
                margin
            }                        = this.props,
            { dataPlot, rangeScale } = this.state,
            bandwidth                = rangeScale.bandwidth() * (1 + overflow * (dataPlot.length - 1));

        if(straight) {
            return labelOnBottom
                ? ((height + margin) / 2 + fontSize)
                : (overflow ? bandwidth : thickness);
        }

        return labelOnBottom
            ? (height - margin - fontSize * 1.25)
            : (fontSize * 1.25);
    }

    /**
    * Render icon of the graph
    *
    * @return html
    */
    renderIcon() {
        const { innerWidth, colors } = this.state,
            { icon, skeleton }       = this.props,
            radius                   = innerWidth / 3;

        return icon === false ? null : (
            <Icon id={icon} width={radius}
                height={radius} color={skeleton ? '#000' : colors.text}
                SVG
            />
        );
    }

    /**
     * Render icon of the popover

     * @returns html
     */
    renderWithPopover(d) {
        const label = this.getLabelText(d.index);

        return (
            <Popover
                key={`popover-${this.id}-path-${d.index}`}
                content={(
                    <div className="tooltip-value">
                        <div
                            className="sentence"
                            dangerouslySetInnerHTML={{ __html: label }}
                        />
                    </div>
                )}
                arrow
                trigger="hover"
            >
                { this.renderStraightRect(d) }
            </Popover>
        );
    }

    /**
    * Render a Arc graph
    *
    * @return html
    */
    renderArcGraph() {
        const {
                dataPlot,
                colors,
                graphOffset
            }           = this.state,
            {
                stepBorderSize,
            }           = this.props,
            that        = this;

        return (
            <g
                className="dataPlot"
                transform={`translate(${graphOffset.x},${graphOffset.y})`}

            >
                { dataPlot.map((d) => (
                    <g key={`${that.id}-path-${d.index}`}>
                        <path
                            d={d.path}
                            style={{
                                stroke     : stepBorderSize > 0 && d.filled ? d.color : colors.text,
                                fill       : d.filled,
                                strokeWidth: stepBorderSize,
                            }}
                        />
                        { d.label ? (
                            <title>
                                {htmlize(d.label)}
                            </title>
                        ) : null}
                    </g>
                )) }
                { /* Icon */ }
                { this.renderIcon() }
                { /* Needle */ }
                { this.renderNeedle() }
            </g>
        );
    }

    /**
    * Render a needle
    *
    * @return html
    */
    renderNeedle() {
        const {
                innerWidth, needleAngle, colors,
                dataPlot, rangeScale, step, ranges
            }            = this.state,
            {
                innerRadius, needleSize, arcSize,
                straight, fontSize, thickness
            }            = this.props,

            needleHeight = needleSize / 3,
            graphHeight  = innerWidth / 2,
            label        = ranges[step]?.label,
            count        = dataPlot.filter((data) => data.filled === true).length,
            rotate       = `rotate(${straight ? -90 : needleAngle})`,
            needleX      = straight ? (count - 0.5) * rangeScale.step() : 0,
            needleY      = straight
                ? thickness + (needleHeight * 4)
                : -graphHeight * innerRadius * arcSize + needleSize + needleHeight,
            translate    = `translate(${needleY}, ${needleX})`;

        return (
            [
                straight && label && (
                    <text
                        key="label"
                        x={(count - 0.5) * rangeScale.step()}
                        y={-needleY - needleHeight * 2}
                        dominantBaseline="middle"
                        textAnchor="middle"
                        style={{
                            fontSize  : `${fontSize}px`,
                            fill      : colors.text,
                            fontWeight: 'bold'
                        }}
                    >
                        { label }
                    </text>
                ),
                <g key="needle" className="needle">
                    <polygon
                        points={`0,${needleHeight} -${needleSize},0 0,-${needleHeight}`}
                        transform={`${rotate}${translate}`}
                        style={{
                            fill: colors.text,
                        }}
                    />
                </g>
            ]
        );
    }

    /**
    * Render a Straight graph
    *
    * @return html
    */
    renderStraightGraph() {
        const { dataPlot, rangeScale, colors } = this.state,
            { innerWidth, innerHeight }        = this.state,
            { fontSize, overflow }             = this.props,
            { thickness, gaugeBorderSize }     = this.props,
            graphTop = overflow
                ? innerHeight / 2 - rangeScale.bandwidth() / 2
                : innerHeight / 2 + (fontSize / 1.25),
            filled = `url("#${this.id}-filled")`;

        return (
            <g className="dataPlot" transform={`translate( 0 ${graphTop} )`}>
                <rect
                    y={-thickness}
                    width={innerWidth}
                    height={thickness}
                    fill={colors.gaugeBackground}
                    stroke={gaugeBorderSize > 0 ? filled : 0}
                    strokeWidth={gaugeBorderSize}
                />
                { dataPlot.map((d) => this.renderWithPopover(d)) }
                { this.renderNeedle() }
            </g>
        );
    }

    /**
    * Render a Straight graph
    *
    * @return html
    */
    renderStraightRect(d) {
        const {
                shape, overflow, innerPadding,
                stepBorderSize, skeleton
            }           = this.props,
            {
                dataPlot, rangeScale,
                colors, nbStep
            }           = this.state,
            bandwidth   = rangeScale.bandwidth() * (1 + overflow * (dataPlot.length - 1)),
            filled      = colors.gradient(d.index / nbStep),
            fillColor   = skeleton || filled,
            strokeColor = innerPadding !== 0 ? filled : null;

        return (shape !== false
            ? (
                <image
                    key={`shape-${d.index}`}
                    x={d.location - overflow * rangeScale.bandwidth() * d.index}
                    y={-d.size * (1 + overflow * (dataPlot.length - 1))}
                    width={bandwidth}
                    height={bandwidth}
                    xlinkHref={importedShapes[shape]}
                    style={{ opacity: (dataPlot.length - d.index) / dataPlot.length }}
                />
            )
            : (
                <rect
                    key={`rect-${d.index}`}
                    title={d.label}
                    x={d.location}
                    y={-d.size}
                    width={bandwidth}
                    height={d.size}
                    shapeRendering="crispEdges"
                    style={{
                        fill           : fillColor,
                        stroke         : strokeColor,
                        strokeWidth    : stepBorderSize,
                        strokeAlignment: 'inner'
                    }}
                />
            )
        );
    }

    /**
    * Render the svg
    *
    * @return html
    */
    renderSvg() {
        const { height } = this.props,
            { colors }   = this.state;

        return [(
            <svg
                width="100%"
                height={height}
                style={{ background: colors.background }}
                key="svg"
            >
                {this.renderGraph()}
            </svg>
        ),
        // Labels
        this.renderLabel()
        ];
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderGraph() {
        const { skeleton } = this.props;

        return (
            <g>
                { /* Definitions */ }
                {this.renderDefs()}
                { /* Gauge graph */ }
                {!skeleton ? this.renderPaths() : null  }
            </g>
        );
    }

    /**
    * Render the paths
    *
    * @return html
    */
    renderPaths() {
        const { straight, margin } = this.props;
        return (
            <g transform={`translate( ${margin} ${margin} )`}>
                {straight ? this.renderStraightGraph() : this.renderArcGraph()}
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                skeleton,
                skeletonGradient,
                data,
                onlySvg,
            }     = this.props,
            stats = _.get(data, 'stats', false),
            style = skeleton || !stats ? {
                // MaskImage      : `url(#${this.id}-mask)`,
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton || !stats) { return (<div className="Gauge skeleton" style={style} />); }

        return onlySvg
            ? this.renderSvg()
            : (
                <div className={`Gauge ${skeleton ? 'skeleton' : ''}`} style={style}>
                    {this.renderSvg()}
                </div>
            );
    }

}

/* eslint-disable react/no-unused-prop-types */
Gauge.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    color           : PropTypes.string, // eslint-disable-line
    gradientColor   : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // eslint-disable-line
    background      : PropTypes.string, // eslint-disable-line
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    steps           : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),  // eslint-disable-line
    ranges          : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]), // eslint-disable-line
    margin          : PropTypes.number,
    fontSize        : PropTypes.number,
    innerPadding    : PropTypes.number, // eslint-disable-line
    outerPadding    : PropTypes.number, // eslint-disable-line
    innerRadius     : PropTypes.number, // eslint-disable-line
    outerMargin     : PropTypes.number, // eslint-disable-line
    overflow        : PropTypes.number, // eslint-disable-line
    thickness       : PropTypes.number, // eslint-disable-line
    angleStart      : PropTypes.number, // eslint-disable-line
    angleEnd        : PropTypes.number, // eslint-disable-line
    arcSize         : PropTypes.number, // eslint-disable-line
    needleSize      : PropTypes.number, // eslint-disable-line
    gaugeBorderSize : PropTypes.number, // eslint-disable-line
    stepBorderSize  : PropTypes.number, // eslint-disable-line
    gaugeBackground : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    showGradient    : PropTypes.bool, // eslint-disable-line
    roundedStep     : PropTypes.bool, // eslint-disable-line
    straight        : PropTypes.bool,
    skeleton        : PropTypes.bool,
    onlySvg         : PropTypes.bool,
    noLabel         : PropTypes.bool,
    labelOnBottom   : PropTypes.bool,
    doubleHeight    : PropTypes.bool,
    boldLabel       : PropTypes.bool,
    showText        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    shape           : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    icon            : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    emptyStepSize   : PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    emptyColor      : PropTypes.string,
    onMouseMove     : PropTypes.func,
    onMouseDown     : PropTypes.func,
    onMouseUp       : PropTypes.func,
    onContextMenu   : PropTypes.func,
};

Gauge.defaultProps = {
    margin          : 0,
    fontSize        : 12,
    color           : 'var(--insight-color)',
    gradientColor   : false,
    steps           : false,
    ranges          : false,
    background      : 'none',
    angleStart      : -Math.PI / 2,
    angleEnd        : Math.PI / 2,
    arcSize         : 1,
    gaugeBorderSize : 0,
    gaugeBackground : false,
    stepBorderSize  : 1,
    showGradient    : false,
    straight        : false,
    innerPadding    : 0.10,
    outerPadding    : 0,
    innerRadius     : 0.66,
    outerMargin     : 0.0,
    overflow        : 0.0,
    thickness       : 6,
    needleSize      : 8,
    skeleton        : false,
    skeletonGradient: false,
    onlySvg         : false,
    shape           : false,
    emptyStepSize   : false,
    emptyColor      : null,
    roundedStep     : false,
    noLabel         : false,
    labelOnBottom   : true,
    icon            : false,
    boldLabel       : false,
    showText        : false,
    doubleHeight    : true,
    onMouseMove     : _.noop,
    onMouseDown     : _.noop,
    onMouseUp       : _.noop,
    onContextMenu   : _.noop,
};

export default Gauge;

