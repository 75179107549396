import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { Popover }          from 'antd';
import { Collection }       from 'helpers';
import { onParentResize }   from 'utils/dom';

/**
* Draw Id cards authority
*
*/
class Authority extends Component {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        this.refRelatedTo = React.createRef();

        _.bindAll(this, 'registerCallbacks', 'getEntities');

        this.state = {
            relatedToWidth: 0,
        };
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        this.computeActorsNumbering();

        onParentResize(this.refRelatedTo, this.fetchRelatedToWidth.bind(this));
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb, element) {
        const { registerCallbacks } = this.props;

        if (action === 'getEntities') {
            return registerCallbacks(action, this.getEntities, element);
        }

        registerCallbacks(action, cb, element);
    }


    /**
    * Fetch related to width and store it in state
    */
    fetchRelatedToWidth({ width }) {
        if (width > 0) {
            this.setState({ relatedToWidth: width });
        }
    }


    /**
    * Get entities of authority
    *
    * @return array
    */
    getEntities() {
        const { model, parameters }        = this.props,
            { numberedAuthority }          = this.state,
            { filters, noExpertAuthority } = parameters,
            { authority }                  = model,
            dataToDisplay                  = noExpertAuthority
                ? _.filter(authority, filters)
                : _.filter(numberedAuthority, filters);

        return dataToDisplay;
    }

    /**
    * Parse all the authorities of persons combining with the entity's orgunits in one array.
    *
    * @return array
    */
    getAllOrgunits() {
        const { model } = this.props,
            { authority } = model,
            allOrgunits = _.filter(authority, { type: 'orgunit' }),
            allPersons = _.filter(authority, { type: 'expert' });

        _.each(allPersons, (person) => {
            if (!person.authority) { return; }

            _.each(person.authority, (orgunit) => {
                allOrgunits.push(orgunit);
            });
        });

        return _.uniqBy(allOrgunits, 'id');
    }

    /**
    * Parse the autorities of the entities, filtering only the persons.
    *
    * @return array
    */
    getAllPersons() {
        const { model } = this.props,
            { authority } = model;

        return _.filter(authority, { type: 'expert' });
    }

    /**
    * Parse all the entities within the current entity, providing a clean
    * orgunit to persons data structure.
    *
    * @return object
    */
    getAssociations() {
        const allOrgunits = this.getAllOrgunits(),
            allPersons = this.getAllPersons(),
            associations = {};

        _.each(allOrgunits, (orgunit) => {
            if (!_.has(associations, orgunit.id)) {
                associations[orgunit.id] = {
                    groupId: 0,
                    persons: []
                };
            }
        });

        _.each(allPersons, (person) => {
            if (!person.authority) { return; }

            _.each(person.authority, (orgunit) => {
                associations[orgunit.id].persons.push(person.id);
            });
        });

        let groupCounter = 0;

        const cleanedAssociations = _.chain(associations)
            .pickBy((assoc) => assoc.persons.length > 0)
            .mapValues((assoc) => {
                groupCounter += 1;

                return { ...assoc, groupId: groupCounter };
            })
            .value();

        return cleanedAssociations;
    }

    /**
    * Compute all the actors and matches the orgunits with persons
    *
    * @return self
    */
    computeActorsNumbering() {
        const associations = this.getAssociations(),
            allOrgunits    = this.getAllOrgunits(),
            allPersons     = this.getAllPersons();

        let numberedAuthority = [];

        numberedAuthority = numberedAuthority.concat(_.map(allOrgunits, (sourceOrgunit) => {
            const orgunit = { ...sourceOrgunit };

            if (_.has(associations, orgunit.id)) {
                if (!_.isArray(orgunit.actorGroupIds)) {
                    orgunit.actorGroupIds = [];
                }

                orgunit.actorGroupIds.push(associations[orgunit.id].groupId);
            }

            return orgunit;
        }));

        numberedAuthority = numberedAuthority.concat(_.map(allPersons, (sourcePerson) => {
            const person = { ...sourcePerson };

            _.each(associations, (association) => {
                if (association.persons.indexOf(person.id) === -1) {
                    return true;
                }
                if (!_.isArray(person.actorGroupIds)) { person.actorGroupIds = []; }

                person.actorGroupIds.push(association.groupId);
                return true;
            });

            return person;
        }));

        this.setState({ numberedAuthority });

        return this;
    }

    /**
    * Render authority
    *
    * @return Component
    */
    renderAuthority() { // eslint-disable-line  max-lines-per-function
        const {
                model, onClick,
                parameters, showMore,
            }                  = this.props,
            { relatedToWidth } = this.state,
            { filters }        = parameters,
            { type }           = filters,
            { authority }      = model,
            dataToDisplay      = this.getEntities(),
            nbToShow           = !showMore ? dataToDisplay.length : Math.floor(relatedToWidth / 200),
            slicedAuthority    = dataToDisplay.slice(0, nbToShow),
            more               = showMore && dataToDisplay.length > nbToShow,
            countMore          = dataToDisplay.length - nbToShow;

        return (
            <div className="authority-container" ref={this.refRelatedTo}>
                <Collection
                    entitySource={model}
                    entities={slicedAuthority}
                    onClick={onClick}
                    mode="inline"
                    allowExport={false}
                    type={type}
                    useSeparator={false}
                    forceLogo
                    registerCallbacks={this.registerCallbacks}
                />
                {more && (
                    <Popover
                        overlayClassName="related-to"
                        title="Mainly works on:"
                        content={(
                            <Collection entities={authority} type="orgunit"
                                mode="inline" allowExport={false}
                                onClick={onClick}
                            />
                        )}
                    >
                        <button className="more">
                            {`${countMore} more`}
                        </button>
                    </Popover>
                )}
            </div>
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        return (
            <>
                {this.renderAuthority()}
            </>
        );
    }

}

Authority.propTypes = {
    element          : PropTypes.shape({}).isRequired,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    showMore         : PropTypes.bool,
    model            : PropTypes.shape({
        authority: PropTypes.array,
    }),
    parameters: PropTypes.shape({
        filters: PropTypes.shape({
            type: PropTypes.string
        }),
        noExpertAuthority: PropTypes.any
    }),
};

Authority.defaultProps = {
    model: false,
};

export default Authority;

