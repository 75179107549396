import _                                          from 'lodash';
import PropTypes                                  from 'prop-types';
import ImmutablePropTypes                         from 'react-immutable-proptypes';
import { withRouter }                             from 'react-router-dom';
import React, { useState, useEffect, useRef }     from 'react';
import { connect }                                from 'react-redux';
import Immutable                                  from 'immutable';
import { navigateToSearch }                       from 'store/actions/navigation';
import { entityIsInNewsletter, modelsAreLoaded }  from 'store/actions/userView';
import {
    createNewBookmarkFolder,
    getRootFolder,
    getFolderBranch,
    getBookmarksOfBranches,
}                                                 from 'store/actions/userView/bookmarksFolders';
import { getBookmarkLabel }                       from 'store/actions/userView/bookmarks';
import { CssLoader }                              from 'helpers';
import Flat                                       from './Bookmarks/Flat';
import Tree                                       from './Bookmarks/Tree';
import { requestTimeout }                         from 'utils/requestTimeout';

import './Bookmarks/assets/main.less';

/**
* Render the bookmarks in tree or flat style
* React Functional Components (Hooks)
*
* @return JSX
*/
function BookmarksRenderer(props) {  // eslint-disable-line max-lines-per-function
    const [firstRender, setFirstRender] = useState(true),
        contentRef                      = useRef(null);

    /**
    * Triggered when the component is ready
    */
    useEffect(() => {
        setFirstRender(false);
    });


    /**
    * Open the clicked entity
    *
    * @return void
    */
    const onClickBookmark = (bookmark, collection) => {
        const {
                navigateTo, navigateToSearch, onClick
            }                      = props,
            { entity }             = bookmark,
            { entity: webEntity }  = entity,
            entityCollection       = collection && collection.map(entity => entity.entity),
            isQuery                = entity.type === 'query',
            collectionWithoutQuery = entityCollection?.filter(ent => ent && ent.type !== 'query'),
            collectionWebEntities  = collectionWithoutQuery?.length > 1 && collectionWithoutQuery.map(entity => entity.entity);

        // Open entity with collection navigation
        if (onClick && !isQuery) {
            onClick(webEntity,  collectionWebEntities);
            return;
        }

        // Open search with onclick (TODO: usefull ? WHY not just with navigateToSearch)
        if (onClick && isQuery) {
            onClick(entity);
            return;
        }

        // Open search with navigateToSearch
        if (isQuery && navigateToSearch) {
            navigateToSearch(entity);
            return;
        }

        // Open entity with collection navigation and navigateTo
        if (navigateTo) {
            navigateTo(webEntity,  collectionWebEntities);
        }
    };


    /**
     * Get filtered bookmarks
     *
     * @returns Immutable.map
     */
    const getBookmarks = (foldersTree) => { // TODO: put this in the bookmarks
        const { maxBookmarks } = props,
            flatTree           = getBookmarksOfBranches(foldersTree),
            filteredBookmarks  = getFilteredBookmarks(flatTree),
            orderedBookmarks   = getOrderedBookmarks(filteredBookmarks);

        return new Immutable.List(
            maxBookmarks
                ? orderedBookmarks.slice(0, maxBookmarks)
                : orderedBookmarks
        );
    };


    /**
     * Filter bookmarks using the filters props
     *
     * @param {array} bookmarks
     * @returns array
     */
    const getFilteredBookmarks = (bookmarks) => {
        const {
                filters, entityIsInNewsletter, textFilter
            } = props,
            {
                tags, queries, entities, monitors
            } = filters || {};

        return bookmarks.filter(bookmark => {
            // Filtering the bookmarks based on the filters.
            const label = getBookmarkLabel(bookmark),
                emptyFilters = !tags && !queries && !entities &&!monitors,
                tagsFound  = tags
                    && tags.length > 0
                    && _.intersection(tags, bookmark.tags).length > 0,
                queriesFound = queries
                    && queries.length > 0
                    && queries.includes(bookmark.entity.type),
                entitiesFound = entities
                    && entities.length > 0
                    && entities.includes(bookmark.entity.entity?.type),
                monitoredFound  = monitors && entityIsInNewsletter(bookmark.entity),
                labelMatch      = textFilter && label?.indexOf(textFilter.toLowerCase()) !== -1,
                textCondition   = !textFilter || labelMatch,
                filterCondition = emptyFilters || tagsFound || queriesFound || entitiesFound || monitoredFound;

            return textCondition && filterCondition;
        });
    };


    /**
     * Order bookmarks using the orderBy (by default date_create desc)
     *
     * @param {array} bookmarks
     * @returns array
     */
    const getOrderedBookmarks = (bookmarks) => {
        const {orderBy} = props;

        if (orderBy === 'label') {
            return _.orderBy(
                bookmarks,
                [bookmark => getBookmarkLabel(bookmark), 'date_create'],
                ['asc', 'desc']
            );
        }
        return _.orderBy(bookmarks, 'date_create', 'desc');
    };


    /**
     * Add a new folder
     */
    const addFolder = (folder) => {
        const {
                createNewBookmarkFolder,
                getRootFolder
            }          = props,
            { id }     = folder,
            rootFolder = getRootFolder(),
            contentEl  = contentRef?.current.firstChild,
            options    = {
                parent_bookmark_folder: rootFolder?.model?.id
            };

        if (id) {
            options.parent_bookmark_folder = id;
        }

        contentEl?.scrollTo({
            top     : contentEl?.scrollHeight,
            behavior: 'smooth',
        });

        requestTimeout(() => createNewBookmarkFolder(options), 300);
    };


    /**
    * Rendering the button to add a folder.
    *
    * @returns JSX
    */
    const renderAddFolderButton = () => {
        const {
                folderId,
                modelsAreLoaded,
                getRootFolder
            }                 = props,
            isFlatMode        = isFlatModeRender(),
            bookmarksAreReady = modelsAreLoaded('bookmark'),
            foldersAreReady   = modelsAreLoaded('bookmark_folder'),
            rootFolder        = getRootFolder(),
            ready             = bookmarksAreReady && foldersAreReady && rootFolder;

        return !isFlatMode && !folderId && ready && (
            <div key="addFolderButton" className="add-folder-button"
                onClick={addFolder}
            >
                + Add folder
            </div>
        );
    };

    /**
     * Get boolean to know if we render flat bookmarks (not tree)
     */
    const isFlatModeRender = () => {
        const { flat, filters, textFilter } = props;

        return flat || !!textFilter || _.keys(filters).length > 0;
    };

    /**
    * It's rendering the bookmarks in a tree with folders or flat mode
    *
    * @returns JSX
    */
    const renderContent = () => {
        const {
                tags, onSelect, draggable, hideBookmarksCount, onDrag,
                getFolderBranch, folderId, expandAll
            }           = props,
            // Used to render a bookmark flat version in homepage bookmarks and filtered bookmarks
            isFlatMode  = isFlatModeRender(),
            foldersTree = getFolderBranch({ paramKey: folderId });

        return foldersTree && [
            isFlatMode && (
                <div key="contentFlat">
                    <Flat
                        onClickBookmark={onClickBookmark}
                        bookmarks={isFlatMode ? getBookmarks(foldersTree) : new Immutable.List()}
                        hideBookmarksCount={hideBookmarksCount}
                    />
                </div>
            ),
            !isFlatMode && (
                <div key="contentTree" ref={contentRef}>
                    <Tree
                        tags={tags}
                        onClickItem={onClickBookmark}
                        onSelect={onSelect}
                        draggable={draggable}
                        foldersTree={foldersTree}
                        rootFolderId={folderId}
                        addFolder={addFolder}
                        onDrag={onDrag}
                        blockNode
                        expandAll={expandAll}
                    />
                </div>
            )
        ];
    };


    /**
     * Rendering Migration in progress state
     */
    const renderMigrationInProgress = () => {
        const  loader                      = (
            <div className="loader">
                <CssLoader type="ripple" size={50}
                    thickness={2} color="#dddddd"
                />
            </div>
        );
        return (
            <div className="bookmark-migration">
                {loader}    Migration in progress
            </div>

        );
    };
    /**
    * Rendering the bookmarks in a tree with folders or flat mode
    *
    * @returns JSX
    */
    const render = () => {
        const { bookmarkFolders } = props;

        // No root folder
        if (bookmarkFolders && bookmarkFolders.size === 0) {
            return renderMigrationInProgress();
        }

        return !firstRender && (
            <div className="bookmarks-renderer">
                {renderContent()}
                {renderAddFolderButton()}
            </div>
        );
    };

    // The render call
    return render();
}

BookmarksRenderer.propTypes = {
    bookmarks           : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarkFolders     : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newsletters         : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    tags                : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filters             : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    textFilter          : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    learnKnowledge      : PropTypes.func,
    navigateTo          : PropTypes.func,
    onClick             : PropTypes.func,
    onSelect            : PropTypes.func,
    navigateToSearch    : PropTypes.func,
    entityIsInNewsletter: PropTypes.func,
    orderBy             : PropTypes.string,
    hideBookmarksCount  : PropTypes.bool,
    flat                : PropTypes.bool,
    draggable           : PropTypes.bool,
    expandAll           : PropTypes.bool,
    maxBookmarks        : PropTypes.number,
    onDrag              : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

BookmarksRenderer.defaultProps = {
    hideBookmarksCount: false,
    flat              : false,
    textFilter        : false,
    expandAll         : false,
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarkFolders: state.getIn(['userView', 'bookmark_folder', 'list']),
});

export default connect(mapStateToProps, {
    navigateToSearch,
    entityIsInNewsletter,
    createNewBookmarkFolder,
    getRootFolder,
    getFolderBranch,
    modelsAreLoaded,
})(withRouter(BookmarksRenderer));

