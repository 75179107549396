/**
 * QuickAccess layout
 *
 * @return Component
 */

import React, { PureComponent }   from 'react';
import _                      from 'lodash';
import Shortcut               from './Shortcut';

// Import the header stylesheet
import './assets/quick-access.less';

const shortcuts = [
    {
        id   : 'newsletters',
        title: 'Insight Feed',
    },
    {
        id   : 'bookmarks',
        title: 'Bookmarks',
    },
    {
        id   : 'searches',
        title: 'Search history',
    },
    {
        id   : 'companies',
        title: 'Company profiles',
    },
    {
        id   : 'clipboard',
        title: 'Export clipboard',
        // Progress: Math.random(),
    },
    {
        id   : 'downloads',
        title: 'Downloads',
        // Loading : true,
        // Progress: Math.random(),
    },
    {
        id   : 'reports',
        title: 'Reports',
    },
];


/**
* Quick access allow to access to header buttons
*
* @return void
*/
class QuickAccess extends PureComponent {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'registerCallbacks');

        this.state = {
            callbacks: {},
        };
    }

    /**
    * Register callback from ShortcutView
    *
    * @return self
    */
    registerCallbacks(action, cb, key) {
        const { callbacks } = this.state;

        if (!callbacks[action]) {
            callbacks[action] = {};
        }

        callbacks[action][key] = cb;

        this.setState({ callbacks });
    }

    /**
    * Check if a shortcut is active
    *
    * @return bool
    */
    isOneActive() {
        const { callbacks } = this.state,
            { getActives } = callbacks;

        return _.values(getActives).reduce((accumulator, getActive) => accumulator || getActive(), false);
    }

    /**
    * Render the shortcuts
    *
    * @return html
    */
    renderShortcuts() {
        return (
            <div className="shortcuts">
                {shortcuts.map((shortcut) => (
                    <Shortcut
                        {...this.props}
                        key={shortcut.id}
                        id={shortcut.id}
                        title={shortcut.title}
                        progress={shortcut.progress}
                        registerCallbacks={this.registerCallbacks}
                    />
                ))}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <div className="quick-access">
                {this.renderShortcuts()}
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
QuickAccess.propTypes = {
};

export default QuickAccess;
