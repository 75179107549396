/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                              from 'lodash';
import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { Skeleton }                   from 'antd';
import { Icon, Entity, SpanOverflow } from 'helpers';
import Operator                       from './Input/Operator';
import AddSuggestion                  from './Input/AddSuggestion';
import InsightBot                     from './Input/InsightBot';
import { onParentResize }             from 'utils/dom';

import './assets/input.less';

/**
* Render the Smart "Input"
*/
class Input extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'isAConcept', 'renderCustom', 'renderOperator', 'remove',
            'onClickOnInput', 'up', 'down', 'removeTag', 'addTags',
        );

        this.state = {
            widthInput: 0,
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.parentResize();
    }

    /**
    * Parent Resize
    */
    parentResize() {
        const { inputRef } = this.props;

        onParentResize(inputRef, this.fetchInputWidth.bind(this));
        this.setState({ loading: false });
    }

    /**
    * Fetch bottom overview width and store it in state
    */
    fetchInputWidth({ width }) {
        if (width > 0) {
            this.setState({ widthInput: width });
        }
    }

    /**
    * Render Loading
    *
    * @return JSX
    */
    renderLoading() {
        return (
            <div className="concept">
                <Skeleton active paragraph={false} />
            </div>
        );
    }

    /**
    * Delete
    *
    * @return void
    */
    remove() {
        const { remove, data } = this.props;

        remove(data);
    }

    /**
    * GO up
    *
    * @return void
    */
    up() {
        const { up, data } = this.props;

        up(data);
    }

    /**
    * GO down
    *
    * @return void
    */
    down() {
        const { down, data } = this.props;

        down(data);
    }

    /**
    * Source is a concept ?
    *
    * return bool
    */
    isAConcept() {
        const { data } = this.props;

        return data.type === 'concept';
    }

    /**
    * On click on input
    *
    * @return void
    */
    onClickOnInput() {
        const {
            openAddCustom, toggleAddCustom, data,
            openAddSuggestion, toggleAddSuggestion,
        } = this.props;

        if (!openAddCustom) {
            toggleAddCustom(data);
        }

        if (!openAddSuggestion) {
            toggleAddSuggestion(data);
        }
    }

    /**
    * Render operator
    *
    * @return JSX
    */
    renderOperator() {
        return <Operator {...this.props} />;
    }

    /**
    * Render add suggestion
    *
    * @return JSX
    */
    renderAddSuggestion() {
        const { toggleAddSuggestion } = this.props,
            { openAddSuggestion }     = this.props;

        return this.isAConcept() && (
            <AddSuggestion
                {...this.props}
                active={openAddSuggestion}
                toggle={toggleAddSuggestion}
            />
        );
    }

    /**
    * Remove tag
    *
    * @param mixed entity The tag to remove
    *
    * @return void
    */
    removeTag(entity) {
        const { data, update } = this.props;

        data.customs = data.customs.filter((custom) => custom !== entity);

        update(data);
    }

    /**
    * Add tags to custom suggestions
    *
    * @param {array} tags
    *
    * @returns function
    */
    addTags(tags) {
        const { data, update } = this.props;

        for(const tag of tags) {
            if (!_.includes(data.customs, tag)) {
                data.customs.push(tag);
            }
        }

        // Update data object
        update(data);
    }

    /**
    * Render each custom input
    *
    * @return self
    */
    renderCustom(entity) {
        const isAConcept = this.isAConcept(),
            key          = isAConcept ? entity : entity.id;

        return (
            <span className="tag custom" key={key}>
                {isAConcept ? entity : <Entity render="inline" entity={entity} />}
                <Icon className="delete" color="var(--secondary-color)"
                    height={12} onClick={() => this.removeTag(entity)}
                    type="close"
                />
            </span>
        );
    }

    /**
    * Render the input list from state
    *
    * return JSX
    */
    renderCustoms() {
        const { data, openAddCustom } = this.props,
            { widthInput }            = this.state,
            { customs }               = data;

        return customs.length > 0 && (
            <>
                <SpanOverflow
                    propsToWatch={{ customs, openAddCustom, width: widthInput }}
                    moreContainerClassName="custom-more-inputs"
                    collisionNodeSelector=".collision-custom"
                    containerNodeSelector=".input"
                    morePosition="right"
                    showOnClick
                >
                    {customs.map(this.renderCustom)}
                </SpanOverflow>
                <span className="collision-custom" />
            </>
        );
    }

    /**
    * Render Insight Bot button
    *
    * @returns JSX
    */
    renderInsightBot() {
        const { data }          = this.props,
            { source, customs } = data || {};

        return this.isAConcept() && (
            <InsightBot addTags={this.addTags} source={source}
                tags={customs}
            />
        );
    }

    /**
    * Render the tag input provided by the user
    *
    * @return JSX
    */
    renderSource() {
        const { data } = this.props;

        return (
            <span className="tag source">
                {this.isAConcept() ? data.source : <Entity render="inline" entity={data.source} />}
            </span>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <>
                {this.renderOperator()}
                {this.renderInsightBot()}
                {this.renderSource()}
                {this.renderAddSuggestion()}
                {this.renderCustoms()}
            </>
        );
    }

}

Input.propTypes = {
    down               : PropTypes.func.isRequired,
    openAddCustom      : PropTypes.any,
    openAddSuggestion  : PropTypes.any,
    inputRef           : PropTypes.any,
    remove             : PropTypes.func.isRequired,
    toggleAddCustom    : PropTypes.func,
    toggleAddSuggestion: PropTypes.func,
    up                 : PropTypes.func.isRequired,
    update             : PropTypes.func.isRequired,
    data               : PropTypes.shape({
        source : PropTypes.any,
        customs: PropTypes.array,
        type   : PropTypes.string
    }).isRequired,
};

export default Input;
