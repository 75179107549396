import React, { PureComponent } from 'react';
import PropTypes                from 'prop-types';
import { Skeleton }             from 'antd';
import { Highlight }            from 'helpers';
import { learn }                from 'store/actions/knowledge';
import { connect }              from 'react-redux';

/**
 * Display a list of clinicals
 *
 */
class ClinicalTrialRow extends PureComponent {

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['clinicalTypes', 'clinicalPhases'])
            .then(this.setState.bind(this));
    }

    /**
    * Get the clinical type label
    *
    * @param string subtype The clinical type
    *
    * @return string
    */
    getSubtypeLabel(clinicalTypeId) {
        const { clinicalTypes } = this.state;

        if (!clinicalTypes) {
            return false;
        }

        const clinicalTypeMdl = clinicalTypes.find((clinicalType) => clinicalType.id === clinicalTypeId);

        if (!clinicalTypeMdl) {
            return false;
        }

        return clinicalTypeMdl.label;
    }

    /**
    *
    * Get the clinical status label
    *
    * @param string subtype The clinical type
    *
    * @return string
    */
    getClinicalPhasesLabel(clinicalPhaseId) {
        const { clinicalPhases } = this.state;

        if (!clinicalPhases) {
            return false;
        }

        const clinicalPhaseMdl = clinicalPhases.find((status) => status.id === clinicalPhaseId);

        if (!clinicalPhaseMdl) {
            return false;
        }

        return clinicalPhaseMdl.label;
    }

    /**
    * Returns whether the entity is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity } = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render clinical trial years
    *
    * @return JSX
    */
    renderDates() {
        const { entity } = this.props,
            start = entity.start ? entity.start.split('-')[0] : null,
            end   = entity.end   ? entity.end.split('-')[0]   : null;

        if (!end) {
            return start;
        }

        return `${start} - ${end}`;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                entity, showExpandedContent,
                renderActiveStatus, renderModelAction,
                onClick, renderOpenableIcon,
            }                          = this.props,
            { study_phase, clickable } = entity,
            studyPhase                 = study_phase ? this.getClinicalPhasesLabel(study_phase[0]) : null,
            classNames                 = ['label'],
            subtype                    = this.getSubtypeLabel(entity.subtype);

        if (showExpandedContent) {
            return false;
        }

        if (clickable) { classNames.push('clickable'); }

        return (
            <>
                <td className="ellipsis phase" title={studyPhase}>{studyPhase}</td>
                <td className={classNames.join(' ')}
                    onClick={clickable ? onClick : null}
                >
                    <div className="left">
                        <Highlight
                            TagName="div"
                            className="ellipsis"
                            stripTags
                            title
                        >
                            {entity.title}
                        </Highlight>
                        {clickable && renderOpenableIcon()}
                    </div>
                    {renderActiveStatus()}
                    {clickable && renderModelAction()}

                </td>
                <td className="ellipsis type" title={subtype}>{subtype}</td>
                <td className="date">{this.renderDates()}</td>
            </>
        );
    }

    /**
    * Render loading clinical row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the expandable content
    *
    * @return JSX
    */
    renderExpandedContent() {
        const {
            entity, showExpandedContent,
            allowExport
        } = this.props;

        // Prevent early or non wanted expanded row
        if (!showExpandedContent) {
            return false;
        }

        const abstractIsTooLong = entity.abstract_description && entity.abstract_description.length > 580,
            abstractToRender    = abstractIsTooLong
                ? `${entity.abstract_description.substr(0, 580)}...`
                : entity.abstract_description;

        return (
            <>
                <td colSpan={allowExport ? 4 : 2} className="abstract">
                    <div className="label">Abstract</div>
                    <Highlight
                        TagName="div"
                        className="content"
                        stripTags
                    >
                        {abstractToRender}
                    </Highlight>
                </td>
                <td colSpan={2} className="other-info">
                    {entity.keywords && entity.keywords.length > 0 && (
                        <div className="ellipsis">
                            <span>Keywords : </span>
                            <Highlight>
                                {entity.keywords.join(' , ')}
                            </Highlight>

                        </div>
                    )}
                </td>
            </>
        );
    }

    /**
    * Render the clinical row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render clinical row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

ClinicalTrialRow.propTypes = {
    allowExport        : PropTypes.bool,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    learnKnowledge     : PropTypes.func,
    entity             : PropTypes.shape({
        abstract_description: PropTypes.string,
        authority           : PropTypes.array,
        end                 : PropTypes.string,
        isLoading           : PropTypes.bool,
        clickable           : PropTypes.bool,
        keywords            : PropTypes.array,
        start               : PropTypes.string,
        study_phase         : PropTypes.any,
        subtype             : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title               : PropTypes.string
    }).isRequired,
};

ClinicalTrialRow.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    clinicalTypes      : false,
    clinicalPhases     : false,
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};


/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(ClinicalTrialRow);

