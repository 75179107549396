/**
 * Main Insight trigger landing page (homepage)
 *
 * @return Component
 */
import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import { openSettings }               from 'store/actions/newsletters';
import { activateShortcut }           from 'store/actions/navigation';
import Home                           from './Home';

/**
 * Element module.
 *
 */
class ApplicationTrigger extends Component {

    /**
    * Initialize the login form
    *
    */
    constructor(props) {
        super(props);
    }


    /**
    * When component is ready, load the orgunit
    */
    async componentDidMount() {
        const { activateShortcut, openSettings, path } = this.props,
            splitedPath                                = path.split('/'),
            selectedNewsletterKey                      = splitedPath[3],
            newsletterId                               = splitedPath[4],
            settings                                   = splitedPath[6];

        if (settings === 'settings') {
            openSettings(selectedNewsletterKey, newsletterId);
        }

        activateShortcut('newsletters', {key: selectedNewsletterKey});
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return <Home {...this.props} />;
    }

}

ApplicationTrigger.propTypes = {
    activateShortcut: PropTypes.func,
    openSettings    : PropTypes.func,
    path            : PropTypes.shape({
        split: PropTypes.func
    })
};


export default connect(() => {}, {
    activateShortcut,
    openSettings
})(ApplicationTrigger);

