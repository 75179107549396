import _                    from 'lodash';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import { Popover }          from 'antd';
import React, { Component } from 'react';
import { Icon }             from 'helpers';

import Clipboard            from './Views/Clipboard';
import Downloads            from './Views/Downloads';
import Searches             from './Views/Searches';
import Companies            from './Views/Companies';
import Newsletters          from './Views/Newsletters';
import Reports              from './Views/Reports';
import Bookmarks            from './Views/Bookmarks';

// Import the header stylesheet
import './assets/shortcut-view.less';


/**
 * Display a ShortcutView
 *
 * @return React componant
 */
const Components = {
    clipboard  : Clipboard,
    downloads  : Downloads,
    companies  : Companies,
    searches   : Searches,
    newsletters: Newsletters,
    reports    : Reports,
    bookmarks  : Bookmarks,
};

/**
 * Class ShortcutView
 *
 */
class ShortcutView extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            hasBeenActivatedOnce: false
        };

        this.contentParentRef = React.createRef();

        _.bindAll(this, 'close');
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }


    /**
    * Triggered when the component is unmounting
    *
    * @return void
    */
    componentWillUnmount() {
    }


    /**
    * The getDerivedStateFromProps function is called after the component receives new props.
    * used to update the state of the component
    *
    * @returns The state object.
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { active }         = nextProps,
            {hasBeenActivatedOnce: prevHasBeenActivatedOnce } = prevState,
            hasBeenActivatedOnce = active || prevHasBeenActivatedOnce;
        // The hasBeenActivatedOnce variable allows to render the ViewContent only once
        // Inner or outer the popover
        return {
            ...prevState,
            hasBeenActivatedOnce
        };
    }


    /**
    *  Called after the component receives new props.
    *
    */
    componentDidUpdate() {
        const contentParentNode = this.contentParentRef?.current,
            contentNode         =  contentParentNode
                && _.find(contentParentNode.childNodes, childNode => childNode.className === 'content');

        // Stop mouse wheel propagation on content node
        if (contentNode) {
            const {scrollHeight} = contentNode;

            contentNode.onwheel = function(e) { // eslint-disable-line space-before-function-paren
                const height = contentNode.offsetHeight;

                if((this.scrollTop === (scrollHeight - height) && e.deltaY > 0) || (this.scrollTop === 0 && e.deltaY < 0)) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.returnValue = false;
                    return false;
                }
            };
        }
    }


    /**
    * Render the view content
    *
    * @return JSX
    */
    renderViewContent() {
        const { id, ids } = this.props,
            ViewContents  = new Map(),
            contentIds    = id ? [id] : ids;

        contentIds.forEach((idd) => ViewContents.set(idd, Components[idd]));

        return (
            <div key={id || JSON.stringify(ids)} ref={this.contentParentRef}>
                {contentIds.map((idd) => {
                    const ViewContent = ViewContents.get(idd);

                    return ViewContent ? (
                        <ViewContent
                            key={`viewContent-${idd}`}
                            {...this.props}
                            viewId={idd}
                            close={this.close}
                        />
                    ) : null;
                })}
            </div>
        );
    }


    /**
    * Desactivate
    *
    * @return void
    */
    close() {
        const { toggleActive } = this.props;

        toggleActive(false);
    }


    /**
    * Render the view
    *
    * @return JSX
    */
    headerDecorate(contentView) {
        const { title } = this.props;

        return contentView ? (
            <>
                <div className="header">
                    <div className="title">
                        {title}
                    </div>
                    <div className="close" data-qa-key="close"
                        onClick={this.close}
                    >
                        <Icon type="close" height={14} />
                    </div>
                </div>
                {contentView}
            </>
        ) : null;
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                id, ids, active, toggleActive, disabled,
                renderButtons
            }           = this.props,
            contentView = active && this.renderViewContent(),
            className   = `shortcut-view ${id || ids[0]}`;

        return (
            <Popover
                trigger="click"
                open={active && !!id}
                placement="bottomRight"
                onOpenChange={toggleActive}
                content={this.headerDecorate(contentView)}
                overlayClassName={className}
                getPopupContainer={trigger => trigger.parentElement.parentElement.parentElement}
            >
                <div className="shortcut-item" onClick={disabled ? null : toggleActive}>
                    {renderButtons()}
                </div>
            </Popover>
        );
    }

}

ShortcutView.propTypes = {
    title           : PropTypes.string,
    disabled        : PropTypes.bool,
    active          : PropTypes.bool,
    id              : PropTypes.string,
    bookmarks       : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    tags            : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    ids             : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    downloads       : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    navigateTo      : PropTypes.func,
    navigateToSearch: PropTypes.func,
    renderButtons   : PropTypes.func,
    toggleActive    : PropTypes.func
};

ShortcutView.defaultProps = {
    id               : false,
    ids              : false,
    active           : false,
    toggleActive     : _.noop,
    registerCallbacks: _.noop,
    navigateTo       : _.noop,
    navigateToSearch : _.noop,
    downloads        : false,
};

export default ShortcutView;
