// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sentence.express {
  display: flex;
  align-items: center;
}
.sentence.express .raw-input.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: currentColor;
}
.sentence.express .express-stamp {
  font-weight: bold;
  font-size: 10px;
}
.sentence.express .express-stamp .icon {
  position: relative;
  vertical-align: initial;
  top: 2px;
}
.sentence.express .express-stamp > table {
  white-space: nowrap;
  width: auto !important;
}
.sentence.express .express-stamp > table tr {
  height: auto;
}
.sentence.express .express-stamp > table tr td {
  padding: 0;
  vertical-align: middle;
}
.sentence.express .express-stamp > table tr td.express-icon {
  padding-top: 3px;
  padding-right: 1px;
}
.sentence.express .express-stamp > table tr td.generated-by {
  padding-right: 2px;
}
.sentence.express .express-stamp .express-blue {
  color: #5a95aa !important;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/Inputs/Express/assets/sentence.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;AAAJ;AAHA;EAMQ,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AATA;EAcQ,iBAAA;EACA,eAAA;AAFR;AAbA;EAkBY,kBAAA;EACA,uBAAA;EACA,QAAA;AAFZ;AAlBA;EAwBY,mBAAA;EACA,sBAAA;AAHZ;AAtBA;EA2BgB,YAAA;AAFhB;AAzBA;EA6BoB,UAAA;EACA,sBAAA;AADpB;AAGoB;EACI,gBAAA;EACA,kBAAA;AADxB;AAIoB;EACI,kBAAA;AAFxB;AApCA;EA8CY,yBAAA;AAPZ","sourcesContent":[".sentence.express {\n\n    display    : flex;\n    align-items: center;\n\n    .raw-input.ellipsis {\n        overflow     : hidden;\n        white-space  : nowrap;\n        text-overflow: ellipsis;\n        color        : currentColor;\n    }\n\n\n    .express-stamp {\n        font-weight: bold;\n        font-size  : 10px;\n\n        .icon {\n            position      : relative;\n            vertical-align: initial;\n            top           : 2px;\n        }\n\n        > table {\n            white-space: nowrap;\n            width      : auto !important;\n            tr {\n                height: auto;\n                td {\n                    padding       : 0;\n                    vertical-align: middle;\n\n                    &.express-icon {\n                        padding-top  : 3px;\n                        padding-right: 1px;\n                    }\n\n                    &.generated-by {\n                        padding-right: 2px;\n                    }\n                }\n            }\n        }\n\n\n        .express-blue {\n            color: #5a95aa !important;\n        }\n\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
