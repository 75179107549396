import React, { useEffect }         from 'react';
import PropTypes                    from 'prop-types';
import Entity                       from '../Entity';
import { onParentResize }           from 'utils/dom';
import { rgb as d3Hsl }             from 'd3';
import _                            from 'lodash';

import './Grid/assets/main.less';

/**
* Render the Grid
* React Functional Components (Hooks)
*
* @returns JSX
*/
const Grid = (props) => { // eslint-disable-line max-lines-per-function
    const {
            entities, color, model, type,
            onClick, disableModal, openedModalId,
            forwardedRef, isCapture,
        }              = props,
        primaryColor   = d3Hsl('#10285D'),
        secondaryColor = d3Hsl('#f8f8f8'),
        borderColor    = d3Hsl('#e5e5e5');

    // A primaryColor.l   = 0.1;
    secondaryColor.l = 0.96;


    /**
    * Sets width / height
    */
    const hideGridElements = () => {
        document.querySelectorAll('.grid-element').forEach(
            element => {
                element.style.display = 'block';
                element.style.display = (element.offsetHeight === 0)
                    ? 'none'
                    : 'block';
            });
    };

    useEffect(() => {
        const resizeObserver = onParentResize(forwardedRef, hideGridElements);
        return resizeObserver;
    }, []);
    useEffect(hideGridElements, [entities]);

    return (
        <div
            ref={forwardedRef}
            className="grid-component"
            style={{
                '--primary-color'  : primaryColor,
                '--secondary-color': secondaryColor,
                '--border-color'   : borderColor
            }}
        >
            <div className="grid-content">
                {entities.map((entity, index) => {
                    const key = entity.id || 'entity';
                    return (
                        <div key={`${key}-${index}`} className="grid-element">
                            <Entity
                                sourceModel={model}
                                type={type}
                                key={`${entity.id || _.uniqueId('gridItem')}-gridItem`}
                                render="card"
                                onClick={onClick}
                                entity={entity}
                                color={color}
                                disableModal={disableModal}
                                modalIsOpen={openedModalId === entity.id}
                                isCapture={isCapture}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Grid.propTypes = {
    forwardedRef : PropTypes.any,
    entities     : PropTypes.array,
    color        : PropTypes.string,
    model        : PropTypes.object,
    type         : PropTypes.string.isRequired,
    onClick      : PropTypes.any,
    disableModal : PropTypes.bool,
    isCapture    : PropTypes.bool,
    openedModalId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Grid.defaultProps = {
    color        : '#e1e1e1',
    disableModal : false,
    openedModalId: false,
    isCapture    : false,
};

Grid.displayName = 'Grid';

export default Grid;

