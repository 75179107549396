import _                          from 'lodash';
import Immutable                  from 'immutable';
import { learn }                  from '../knowledge';
import * as types                 from '../types/navigation';
import { get, post, patch, del }  from '../../../core/utils/api';
import { addBr, stripTags }       from '../../../core/utils/text';

/**
* Open Report open with element
*
* @return void
*/
export const openReport = (model, cb= () => {}) => async (dispatch, getState) => {
    const modelObj   = model.creating ?  await createReport(model, getState): model;
    modelObj.loading = true;
    const modelImmutable= Immutable.fromJS(modelObj);
    dispatch({ type: types.REPORT_OPEN, payload: { model: modelImmutable } });
    cb();
};


/**
* Create a new report from dahboard element
*
* @return immutable report
*/
export const createReport =(model, getState) => { // eslint-disable-line max-lines-per-function
    return new Promise((resolve) => {            // eslint-disable-line max-lines-per-function
        learn(['help-metrics'])(null, getState).then(
            (book) => { // eslint-disable-line max-lines-per-function
                const {helpMetrics} = book,
                    helpMetricsObj  = helpMetrics.toJS(),
                    slides          = model.slides.map(element => {
                        const { slides } = element,
                            slidesModel  = slides.map(slide => {
                                const slideHelp                = helpMetricsObj.find(el => el.metric === slide.key),
                                    {
                                        originalRatio, insight,
                                        width, height,
                                    } = slide,
                                    insightText                = stripTags(insight.filledInsight).replace(
                                        ' Want to add competitors to your analysis ? Use the side bar on the right.', ''),
                                    isEmptyInsight             = _.keys(insight).length === 0,
                                    parameters                 = slideHelp ? {
                                        hideGraph          : false,
                                        hideInsight        : false,
                                        interestTitle      : stripTags(slideHelp.texts.interest),
                                        interestDescription: addBr(slideHelp.texts.read),
                                        howBuildTitle      : 'How is the graph built ?',
                                        howBuildDescription: addBr(slideHelp.texts.construction),
                                        insight            : insightText
                                    }: {
                                        hideGraph          : false,
                                        hideInsight        : false,
                                        interestTitle      : '',
                                        interestDescription: '',
                                        howBuildTitle      : 'How is the graph built ?',
                                        howBuildDescription: '',
                                        insight            : '' },
                                    active    = !isEmptyInsight,
                                    { label } = slide;

                                return {
                                    id     : slide.id,
                                    element: slide.id,
                                    type   : 'element',
                                    label,
                                    parameters,
                                    originalRatio,
                                    width,
                                    height,
                                    active,
                                };
                            });
                        // Slide group
                        return { id: element.id, label: element.label, element: element.id, slides: slidesModel };
                    });
                // Add title slide
                slides.unshift(
                    {
                        id    : 'title1',
                        label : 'Title',
                        slides: [{id: 'slide1', label: '', parameters: {}, type: 'title', active: true }]
                    }
                );

                resolve({search: null, isOpen: true, label: 'New report #1', isVisible: true, slides });
            }
        );
    });
};

/**
* Save or create a report
*
* @return {promise}
*/
export const saveReport = (model, cb) => (dispatch) => {
    if(model.id) {
        patch(`/reports/${model.id}`, model)
            .then(({ body }) => {
                cb(body);
                dispatch({ type: types.REPORT_SAVE, payload: body });
            });
        return;
    }
    post('/reports', model).then(
        response => {
            const { body }  = response;
            cb(body);
            dispatch({ type: types.REPORT_SAVE, payload: body });
        });
};

/**
* Get all the reports of the user
*
* @return {promise}
*/
export const fetchReport = () => (dispatch) => {
    get('/reports')
        .then(({ body }) => {
            dispatch({ type: types.REPORT_FETCH, payload: body });
        });
};

/**
* Delete the report
*
* @return {promise}
*/
export const deleteReport = (id) => (dispatch) => {
    del('/reports/'+id).then(
        response => {
            const { body }  = response,
                playload    = {isDeleted: body, key: id};
            dispatch({ type: types.REPORT_DELETE, payload: playload });
        });
};

/**
* Generate the report
*
* @return {promise}
*/
export const generateReport = (modelId) => (dispatch) => {
    post('/report-builds', {report_id: modelId}).then(
        response => {
            const { body }  = response;
            dispatch({ type: types.REPORT_GENERATE, payload: body });
        }
    );
};



export default {
    deleteReport,
    saveReport,
    generateReport,
    openReport,
    fetchReport
};
