/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import Menus from '.';

/**
 * Class Mixed
 *
 */
class Mixed extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderMenuForType');
    }

    /**
    * Render the menu for a specific type
    *
    * @return JSX
    */
    renderMenuForType(suggestions) {
        const { onSelect, selected, renderSuffix } = this.props,
            { id }                                 = suggestions || {},
            MenuRenderer                           = Menus[id];

        return (
            <div key={id} className="menu-group">
                <MenuRenderer
                    suggestions={suggestions}
                    selected={selected && selected.type === id ? selected.row : null}
                    onSelect={onSelect}
                    renderSuffix={renderSuffix}
                />
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { suggestions } = this.props;

        return (
            <div className="mixed-menu">
                {suggestions.map(this.renderMenuForType)}
            </div>
        );
    }

}

Mixed.propTypes = {
    onSelect    : PropTypes.func.isRequired,
    renderSuffix: PropTypes.func,
    selected    : PropTypes.shape({
        row : PropTypes.any,
        type: PropTypes.any
    }),
    suggestions: PropTypes.array,
};

Mixed.defaultProps = {
};

export default Mixed;

