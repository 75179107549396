import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    scaleBand           as d3ScaleBand,
    hsl                 as d3Hsl,
} from 'd3';

import './MultiGraph/main.less';

import NoData               from '../../NoData';

// Import Sub Component
import Pie                  from './Pie';
import Gauge                from './Gauge';
import Shape                from './Shape';

import wNetwork             from './MultiGraph/watermark-network.png';

const watermarks = {
    network: wNetwork,
};

// Pack Component
const ImportedComponents = {
    Pie,
    Gauge,
    Shape
};

/**
 * The multi graph Component
 *
 */
class MultiGraph extends Component {

    /**
    * Update colors in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateColors(options) {
        const { nextProps, state } = options,
            { color } = nextProps,
            dc        = d3Hsl(color);

        state.colors = {
            subGraph: dc.brighter(1.5).toString(),
            lines   : dc.brighter(2).toString(),
            circle  : color
        };

        return { nextProps, state };
    }

    /**
    * Update circles in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateCircles(options) {
        const { nextProps, state } = options,
            { data, fontSize }     = nextProps,
            { radiusFactor }       = nextProps,
            { components }         = nextProps,
            { scale, innerHeight } = state,
            { series }             = state,
            { stats }              = data || { stats: {} },
            textHeight             = fontSize * 1.5 * 2,
            maxRadius              = scale.bandwidth() * radiusFactor / 2,
            minRadius              = (innerHeight - textHeight) / 2,
            radius                 = _.min([minRadius, maxRadius]),
            verticalOffset         = (innerHeight - radius * 2 - textHeight) / 1.5;

        // Store boolean graphHasData (to display no data)
        state.graphHasData = false;

        state.circles = _.map(components, (componentDef, index) => {
            const dataKey = componentDef.data,
                data      = {
                    content: series[dataKey] || {},
                    stats  : stats[dataKey] || {}
                };

            state.graphHasData = true;

            return {
                label     : data.stats.label || false,
                id        : dataKey || false,
                component : componentDef.component || false,
                properties: componentDef.properties,
                cx        : scale(index) + radius,
                cy        : radius + (verticalOffset || 1),
                radius,
                data,
            };
        });

        state.verticalOffset = verticalOffset;

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        _.bindAll(this, 'render');

        // Make a uuid
        this.id = _.uniqueId('top-circle-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps) {
        const {
                margin, data, components,
            }           = nextProps,
            series      = _.get(data, 'content', _.times(3, () => ({ // Default series for dynamic skeleton
                id       : _.uniqueId('circle'),
                component: _.uniqueId('component')
            }))),
            innerWidth  = nextProps.width - 2 * margin,
            innerHeight = nextProps.height - 2 * margin,
            // Cascading function to update state
            updateState = _.flow([
                MultiGraph.updateColors,      // All colors and gradient
                MultiGraph.updateCircles,     // All circles with positions
            ]),
            { state }   = updateState({
                nextProps,
                state: {
                    // Size of the chart (without landmark )
                    innerWidth,
                    innerHeight,
                    series,
                    scale: d3ScaleBand().range([0, innerWidth]).padding(0.15).domain(_.range(components.length)),
                }
            });

        return state;
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
    }

    /**
    * Render top circle graph
    *
    * @return html
    */
    renderMultiGraph() {
        const { colors } = this.state,
            { circles }  = this.state,
            { skeleton } = this.props;

        return _.map(circles, (circle, index) => (
            <g
                key={`${circle.component}-${circle.id}`}
                transform={`translate( ${circle.cx} ${circle.cy} )`}
            >
                <circle
                    r={circle.radius}
                    style={{ fill: '#fff' }}
                />
                {this.renderSubComponent(circle)}
                <circle
                    r={circle.radius}
                    style={{
                        fill       : skeleton ? '#fff' : `url(#${this.id}-disk)`,
                        strokeWidth: skeleton ? 0 : 1,
                        stroke     : colors.circle,
                    }}
                />
                {circles[index + 1] ? (
                    <line
                        x1={circle.radius}
                        x2={circles[[index + 1]].cx - circle.cx - circle.radius}
                        y1={0}
                        y2={0}
                        style={{ stroke: colors.circle }}
                    />
                ) : null}
            </g>
        ));
    }

    /**
    * Render sub component
    *
    * @return html
    */
    renderSubComponent(circle) {
        const { colors } = this.state,
            SubComponent = circle.component ? ImportedComponents[circle.component] : false,
            subData      = circle.data ? circle.data : {},
            subSettings  = circle.properties ? circle.properties : {};

        return SubComponent
            ? (
                <g key={circle.id} transform={`translate( ${-circle.radius} ${-circle.radius} )`}>
                    <SubComponent
                        key={circle.id}
                        onlySvg
                        width={circle.radius * 2}
                        height={circle.radius * 2}
                        margin={0}
                        data={subData}
                        color={colors.subGraph}
                        {...subSettings}
                    />
                </g>
            )
            : null;
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels(marginLeft) {
        const { circles, colors } = this.state,
            { fontSize, margin }  = this.props,
            lineLenght            = circles[1].cx - circles[0].cx - circles[0].radius * 2;

        return _.map(circles, (d, index) => (
            <div
                key={`${JSON.stringify(d.component)}-${index}`}
                className="label"
                title={d.label.toString()}
                style={{
                    left   : marginLeft + d.cx - d.radius - lineLenght / 2,
                    top    : (margin * 1.5) + d.cy + d.radius,
                    width  : `${d.radius * 2 + lineLenght}px`,
                    padding: `0 ${fontSize / 2}px`,
                }}
            >
                <span
                    style={{
                        fontSize,
                        lineHeight: `${fontSize}px`,
                        color     : colors.circle,
                    }}
                    dangerouslySetInnerHTML={{ __html: d.label ? d.label.replace('& ', '&amp;&nbsp;') : '' }}
                />
            </div>
        ));
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { skeleton } = this.props,
            { circles }    = this.state;

        return (
            <defs>
                <mask id={`${this.id}-mask`}>
                    { skeleton ? this.renderMultiGraph() : null }
                </mask>
                {/* Gradient of disk */}
                <radialGradient
                    id={`${this.id}-disk`}
                    gradientUnits="userSpaceOnUse"
                    cx="0"
                    cy="0"
                    r={circles[0].radius}
                >
                    <stop offset="33%" stopColor="#fff"
                        stopOpacity="0.2"
                    />
                    <stop offset="66%" stopColor="#fff"
                        stopOpacity="0.4"
                    />
                </radialGradient>

            </defs>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() { // eslint-disable-line max-lines-per-function
        const {
                width, height, margin,
                color, skeleton, skeletonGradient,
                watermark,
            }          = this.props,
            {
                graphHasData, scale, circles,
                innerWidth, innerHeight,
            }          = this.state,
            lastCircle = _.last(circles),
            graphWidth = lastCircle ? lastCircle.cx + lastCircle.radius : 0,
            marginLeft = (width - graphWidth) / 2 - (scale.padding() * scale.step() / 2),
            transform  = `translate( ${marginLeft} ${margin} )`,
            style      = skeleton ? {
                maskImage      : `url(#${this.id}-mask)`,
                backgroundImage: skeletonGradient,
                top            : margin,
                marginLeft,
            } : null;

        return !graphHasData
            ? (<NoData color={color} />)
            : (
                <div className={`MultiGraph ${skeleton ? 'skeleton' : ''}`} style={style}>
                    {!skeleton && watermark && watermarks[watermark] ? (
                        <div
                            className="background"
                            style={{
                                width: innerWidth, height: innerHeight, textAlign: 'left', top: '0px'
                            }}
                        >
                            <img
                                className="watermark"
                                src={watermarks[watermark]}
                                alt=""
                            />
                        </div>
                    ) : null}
                    <svg
                        width={width}
                        height={height}
                    >
                        <g transform={transform}>
                            { /* Definitions */ }
                            {this.renderDefs()}
                            { !skeleton ? this.renderMultiGraph() : null}
                        </g>
                    </svg>
                    {this.renderLabels(marginLeft)}
                </div>
            );
    }

}

/**
 * Props type
 */
MultiGraph.propTypes = {
    color           : PropTypes.string,
    components      : PropTypes.arrayOf(PropTypes.object),
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fontSize        : PropTypes.number,
    height          : PropTypes.number.isRequired,
    horizontalCenter: PropTypes.any,
    margin          : PropTypes.number,
    radiusFactor    : PropTypes.number,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.any,
    watermark       : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    width           : PropTypes.number.isRequired,
};

/**
 * Default props value
 */
MultiGraph.defaultProps = {
    margin      : 0,
    fontSize    : 14,
    color       : 'var(--insight-color)',
    skeleton    : false,
    watermark   : null,
    radiusFactor: 1,
};

export default MultiGraph;

