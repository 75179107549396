// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./little-skeleton.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./why-skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Bar graph
*
*/
.Gauge .label {
  position: absolute;
  display: flex;
  text-align: center;
  align-items: top;
  justify-content: center;
  padding: 0 5%;
}
.Gauge .label .span {
  display: inline-block;
  vertical-align: middle;
}
.Gauge b {
  color: inherit;
}
.Gauge.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.kpi-graph {
  height: 50px;
}
.kpi-graph svg {
  display: flex;
}
.kpi-graph svg > g {
  align-self: center;
}
.kpi-graph .Graph {
  height: 50px;
  width: 50px;
}
.kpi-graph .Gauge.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Gauge/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAEQ,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;AADR;AANA;EASY,qBAAA;EACA,sBAAA;AAAZ;AAVA;EAeQ,cAAA;AAFR;AAKI;EACI,mDAAA;AAHR;AAOA;EACI,YAAA;AALJ;AAIA;EAIQ,aAAA;AALR;AACA;EAOQ,kBAAA;AALR;AAFA;EAWQ,YAAA;EACA,WAAA;AANR;AANA;EAeQ,mDAAA;AANR","sourcesContent":["/**\n* Bar graph\n*\n*/\n\n.Gauge {\n    .label {\n        position: absolute;\n        display: flex;\n        text-align: center;\n        align-items: top;\n        justify-content: center;\n        padding: 0 5%;\n        .span {\n            display: inline-block;\n            vertical-align: middle;\n        }\n    }\n\n    b {\n        color:inherit;\n    }\n\n    &.skeleton {\n        mask-image:  url('./little-skeleton.svg');\n    }\n}\n\n.kpi-graph {\n    height: 50px;\n\n    svg {\n        display: flex;\n    }\n    svg > g {\n        align-self: center;\n    }\n\n    .Graph {\n        height: 50px;\n        width : 50px;\n    }\n    .Gauge.skeleton {\n        mask-image:  url('./why-skeleton.svg');\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
