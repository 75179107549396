/**
 * Display the browser render "Window"
 *
 * @return Component
 */

import PropTypes                          from 'prop-types';
import React, { Component }               from 'react';
import _                                  from 'lodash';
import { Icon, Col, Row }                 from 'helpers';
import { htmlize, stripTags }             from 'utils/text';
import { deepEqual }                      from 'utils/object';

import './Window/assets/main.less';

// Load accordion items
import Item from './Window/Item';


/**
* The Window renderer
*
*/
class Window extends Component {

    /**
    * Initialize the Entity display
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'registerMetadata', 'renderBreadcrumbs', 'switchTo', 'close', 'goBackToPreviousWindow');

        this.state = {
            itemsMetadata: []
        };
    }

    /**
    * Switch to specific node from its index
    *
    * @param int index The node index to show
    *
    * @return void
    */
    switchTo(e) {
        const { target } = e,
            { switchTo }  = this.props,
            nodeEl       = target.parentNode.classList.contains('label')
                ? target.parentNode : target;

        switchTo(nodeEl.dataset.index);
    }

    /**
    * Return to homepage
    *
    * @return void
    */
    goBackToHome() {
        document.location = '/#/';
    }

    /**
    * Cut the branch and return to the previous windows
    *
    * @return void
    */
    goBackToPreviousWindow() {
        const { switchTo } = this.props;

        switchTo(-1);
    }

    /**
    * Close the node from its index
    *
    * @return void
    */
    close(index) {
        const { switchTo, nodes } = this.props,
            firstNodeIsDisabled   = nodes[0].node.disabled;

        switchTo(
            firstNodeIsDisabled && index === 0 ? -1 // Go back to the other window
                : index - 1                         // Go back to the previous tab
        );
    }

    /**
    * Render home icon
    *
    * @return JSX
    */
    renderHomeIcon(separator) {
        return (
            <span className="node home" onClick={this.goBackToHome}>
                <span className="label">
                    Home
                </span>
                <span className="separator">{separator}</span>
            </span>
        );
    }


    /**
     * This is a dashboard
     *
     * @returns boolean
    */
    isDashboard() {
        const { nodes } = this.props;

        return nodes.length === 1
            && nodes[0].module !== 'overview';
    }

    /**
     * Get the title from the itemMetadata
     *
     * @param {object} itemMetadata
     *
     * @returns {string|false} title OR false
     */
    getTitle(itemMetadata) {
        const { title: itemTitle,
                urlsParams = {}
            }                    = itemMetadata,
            { filterUpdateWeek } = urlsParams || {},
            feedPeriodTitle      = filterUpdateWeek && this.feedPeriodTitle(itemTitle, filterUpdateWeek),
            title                = feedPeriodTitle || itemTitle;

        if (!title) {
            return false;
        }

        return title;
    }

    /**
     * Title with period
     * @param {string} title
     * @param {string || Array} filterUpdateWeek
     *
     * @returns string
     */
    feedPeriodTitle(title, filterUpdateWeek) {
        const isWeek         = filterUpdateWeek && (typeof filterUpdateWeek === 'string'),
            isMonth          = _.isArray(filterUpdateWeek) && filterUpdateWeek?.length > 1,
            newsletterPeriod = (
                isWeek
                    ? 'Weekly' : isMonth
                        ? 'Monthly' : null
            ),
            feedPeriodTitle  = newsletterPeriod ? `${title} (${newsletterPeriod} Feed)` : title;

        return feedPeriodTitle;
    }

    /**
    * Render the breadcrumb part from the item index
    *
    * @param int index The item index
    *
    * @return JSX
    */
    renderBreadcrumbs(itemIndex) { // eslint-disable-line  max-lines-per-function
        const { nodes }       = this.props,
            { itemsMetadata } = this.state,
            toManyNodes       = nodes.length > 4,
            displayHome       = !nodes[0].node.disabled,
            separator         = <Icon width={9} type="retract-arrow" />;

        return this.isDashboard() ? null : (
            <Col className="breadcrumbs">
                <Row>
                    {displayHome && this.renderHomeIcon(separator)}
                    {!displayHome && (
                        <span className="node back" onClick={this.goBackToPreviousWindow}>
                            <Icon type="up-square" theme="filled" />
                        </span>
                    )}
                    {nodes.map((item, index) => {
                        const itemMetadata       = itemsMetadata[index],
                            title                = itemMetadata && this.getTitle(itemMetadata),
                            key                  = item.module + item.node?.model?.id + index,
                            isLast               = index === itemIndex;

                        if (item.node.disabled) { return false; }

                        if (toManyNodes) {
                            if (index === 1) {
                                return (
                                    <span key={`separator-${key}`} className="separator"> ... {separator}</span>
                                );
                            }
                            if (index < itemIndex - 2) {
                                return false;
                            }
                        }

                        if (index > itemIndex || !title) { return false; }

                        return (
                            <span key={key} className="node">
                                <span
                                    title={stripTags(title)}
                                    className={`label ${isLast ? 'last' : ''}`}
                                    data-index={index}
                                    onClick={this.switchTo}
                                >
                                    {htmlize(title)}
                                </span>
                                {!isLast && (<span className="separator">{separator}</span>)}
                            </span>
                        );
                    })}
                </Row>
            </Col>
        );
    }

    /**
    * Register metadata for current view
    *
    * @param metadata object metadata with {title, actions}
    *
    * @return void
    */
    registerMetadata(index, metadata) {
        const { nodes }                     = this.props,
            { itemsMetadata }               = this.state,
            currentMetadata                 = itemsMetadata[index],
            currentNode                     = nodes[index],
            { module: currentModule, node } = currentNode,
            { urlsParams: nodesUrlsParams } = node || {},
            // Get the current node urlsParams (url query params from navigation).
            currentModuleUrlsParams         = (nodesUrlsParams && nodesUrlsParams[currentModule]) || null,
            { actions: currentMetadataActions,
                title: currentMetadataTitle
            }                               = currentMetadata || {},
            { actions, title }              = metadata;

        // Prevent doing loops again & again.
        if (
            currentMetadataActions === actions
            && currentMetadataTitle === title
            // Check if actions props changed
            && (!actions || deepEqual(actions.props, _.clone(currentMetadata.actions.props)))
        ) {
            return;
        }

        itemsMetadata[index] = { ...metadata, urlsParams: currentModuleUrlsParams };

        this.setState({
            itemsMetadata
        });
    }

    /**
    * Render the entity modal
    *
    * @param object entity The entity to render
    *
    * @return JSX
    */
    renderNode(definition, index) {
        const {
                nodes, active, elements, filters, entities, resources, tags,
                navigateTo, bookmarks, previousFromCollection, nextFromCollection
            }                 = this.props,
            { node }          = definition,
            { itemsMetadata } = this.state,
            metadata          = itemsMetadata[index],
            firstNode         = nodes[0],
            firstNodeModel    = _.get(firstNode, 'node.model'),
            isLastNode        = index === nodes.length - 1,
            isActive          = active && isLastNode,
            // Query nodes doesn't have query context.
            context           = (firstNodeModel?.type === 'query' && node.model.type !== 'query')
                ? firstNodeModel
                : null;

        return (
            <Item
                key={index}
                {...definition}
                nodes={nodes}
                index={index}
                last={isLastNode}
                active={isActive}
                metadata={metadata || false}
                context={context}
                elements={elements}
                entities={entities}
                filters={filters}
                resources={resources}
                bookmarks={bookmarks}
                tags={tags}
                navigateTo={navigateTo}
                close={this.close}
                nextFromCollection={nextFromCollection}
                previousFromCollection={previousFromCollection}
                renderBreadcrumbs={this.renderBreadcrumbs}
                registerMetadata={this.registerMetadata}
            />
        );
    }

    /**
    * Render the search
    *
    * @return self
    */
    render() {
        const { nodes } = this.props;

        return (
            <div
                id="browser-accordion"
                className="sticky"
            >
                {nodes.map(this.renderNode.bind(this))}
            </div>
        );
    }

}

Window.propTypes = {
    active                : PropTypes.any,
    bookmarks             : PropTypes.any,
    elements              : PropTypes.any,
    entities              : PropTypes.any,
    filters               : PropTypes.any,
    navigateTo            : PropTypes.any,
    nextFromCollection    : PropTypes.any,
    previousFromCollection: PropTypes.any,
    resources             : PropTypes.any,
    switchTo              : PropTypes.func,
    tags                  : PropTypes.any,
    width                 : PropTypes.number,
    nodes                 : PropTypes.array,
};

export default Window;
