import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Skeleton }         from 'antd';

import _                    from 'lodash';

import { extractDomain }    from 'utils/text';

import { formatShortDate }  from 'utils/date';

import Icon                 from '../../Icon';

import './assets/news_link.less';

/**
 * Display a list of news_link
 *
 */
class NewsLink extends Component {

    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const { entity }            = this.props,
            { url, title,
                author, posted_on } = entity,
            extractedDomain         = url ? _.get(extractDomain(url), 'domain', '') : null,
            authorText              = author ? ` - ${author}` : null,
            formattedDate           =  formatShortDate(new Date(posted_on)),
            poweredText             = this.renderPoweredText(),
            className               = ['news_link-row'];

        if (poweredText) {
            className.push('powered-by');
        }

        return (
            <td colSpan="50" className={className.join(' ')}>
                <div className=" news_link-image">
                    {this.renderOrgIcon()}
                </div>
                <div className="news-content">
                    <a href={url} target="_blank"
                        rel="noreferrer" title={title}
                    >
                        <div className="news-title">{title}</div>
                        <Icon type="link" color="#CDCDCE" />
                    </a>
                    <div
                        className="news-extra "
                        title={`${extractedDomain} - ${formattedDate}`}
                    >
                        {extractedDomain}
                        {authorText}
                        {` | ${formattedDate}`}
                    </div>
                </div>
                {poweredText}
            </td>
        );
    }

    /**
    * Render orgaunit icon
    *
    * @return JSX
    */
    renderOrgIcon() {
        const { entity }        = this.props,
            { authority }       = entity,
            auth                = _.isArray(authority) ? authority[0] : authority,
            { type, label, id } = auth || {};

        return (
            <Icon
                id={type ?? 'orgunit'}
                folder="/entities/"
                className="entity-logo"
                width={18}
                discShaped
                orgunitLogo={type === 'orgunit' ? id : null}
                borderSize={1}
                borderColor="#C6C6C6"
                title={label}
            />
        );
    }

    /**
     * Render the powered text if exists
     */
    renderPoweredText() {
        const { entity } = this.props,
            url          = _.get(entity, 'eAddresses.crunchbase', 'https://www.crunchbase.com');

        return entity.provider === 'Crunchbase' ? (
            <div className="source">
                <a
                    href={url}
                    className="powered-by"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Powered by CrunchBase
                </a>
            </div>
        ) : false;
    }

    /**
    * Render loading project row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <td colSpan="50">
                <Skeleton active avatar={{ size: 0 }}
                    title={{ width: '100%' }} paragraph={false}
                />
            </td>
        );
    }

    /**
    * Render the project row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render project row
        return this.renderContent();
    }

}

NewsLink.propTypes = {
    entity: PropTypes.shape({
        author   : PropTypes.any,
        authority: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isLoading: PropTypes.any,
        posted_on: PropTypes.any,
        title    : PropTypes.any,
        url      : PropTypes.any,
        provider : PropTypes.any
    }).isRequired,
};

NewsLink.defaultProps = {
    expandRow: false,
};

export default NewsLink;

