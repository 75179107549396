import _                                from 'lodash';
import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import {
    Checkbox,
    Radio, Skeleton
}                                       from 'antd';
import { Icon, LimitChildrenDisplay }   from 'helpers';
import { connect }                      from 'react-redux';
import { learn }                        from '../../store/actions/knowledge';
import {
    makeStrClassName,
    str2DomFormat,
}                                       from 'utils/text';


/**
 * GroupedList filter
 */
class GroupedList extends Component {

    static IS_DEBOUNCED = true;

    /**
    * Constructor, init the state
    */
    constructor(props) {
        super(props);

        const { value, filterKey, options } = this.props;

        this.state = {
            selectedIds: value || [],
            activeGroup: null
        };

        this.knowledgeKey = options && options.knowledge ? options.knowledge : filterKey;
    }

    /**
    * When component is ready, learn knowledge to display values.
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge([this.knowledgeKey, `${this.knowledgeKey}-groups`])
            .then(this.setState.bind(this));
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { value }     = this.props,
            { selectedIds } = this.state;

        if(_.isEmpty(value) && prevProps.value.length > 0) {
            this.setState({ selectedIds: [] });
        }

        // Put selected values from props
        if(!_.isEmpty(value) && _.isEmpty(selectedIds)) {
            this.setState({ selectedIds: value });
        }
    }

    /**
    * When user checks an item in the filter
    */
    onClickOnCheckbox(check, e) {
        const { options }   = this.props,
            { selectedIds } = this.state;

        let newSelection = [...selectedIds];

        // Append the new value
        if (e.target.checked) {
            newSelection.push(check);
        }

        // Remove the old value
        if (!e.target.checked) {
            newSelection = selectedIds.filter((selectedId) => selectedId !== check);
        }

        // Manage exclusion of other groups, if required.
        if (options && options.excludingGroup) {
            const allItems     = this.getAllItems(),
                currentGroupId = _.find(allItems, { id: check }).groupId,
                allGroupIds    = _.map(_.filter(allItems, { groupId: currentGroupId }), 'id');

            newSelection = _.intersection(allGroupIds, newSelection);
        }

        this.updateState(newSelection);
    }

    /**
    * When user checks an entire group of value
    */
    onClickOnGroupCheckbox(groupId, e) {
        if (groupId === null) {
            this.updateState([]);
            return;
        }

        const { options }   = this.props,
            { selectedIds } = this.state,
            groups          = this.getGroupsToDisplay(),
            allItemIds      = _.map(groups[groupId].children, 'id');

        let newSelection = [...selectedIds];

        // Append the new values
        if (e.target.checked) {
            newSelection = newSelection.concat(allItemIds);
        }

        // Remove all the values
        if (!e.target.checked) {
            newSelection = selectedIds.filter((selectedId) => allItemIds.indexOf(selectedId) === -1);
        }

        // Manage exclusion of other groups, if required.
        if (options && options.excludingGroup) {
            const allItems     = this.getAllItems(),
                allGroupIds    = _.map(_.filter(allItems, { groupId }), 'id');

            newSelection = _.intersection(allGroupIds, newSelection);
        }

        // Make sure we do not have duplicates
        this.updateState(_.uniq(newSelection));
    }

    /**
    * When user expands/retract a group.
    *
    * @return JSX
    */
    onClickonGroupCaret(group) {
        const { activeGroup } = this.state;

        if (group === activeGroup) {
            this.setState({ activeGroup: null });
            return;
        }

        this.setState({ activeGroup: group });
    }

    /**
    * Get all items that will be displayed.
    *
    * @return Array
    */
    getAllItems() {
        const { items }     = this.props,
            itemsDefinition = _.get(this.state, _.camelCase(this.knowledgeKey)),
            itemsById       = _.keyBy(items, 'id');

        return itemsDefinition
            ? itemsDefinition.toArray().map((item) => ({
                ...item,
                value: itemsById ? itemsById[item.id]?.value || 0 : null   // Inject facet count
            }))
            : null;
    }

    /**
    * Pack all groups and items in an object to display.
    *
    * @return object
    */
    getGroupsToDisplay() {
        const { options }     = this.props,
            knowledgeGroupKey = _.camelCase(`${this.knowledgeKey}-groups`),
            {
                [knowledgeGroupKey]: groupsFromKnowledge
            }                 = this.state,
            { multiValued }   = options;

        // If we have no groupsFromKnowledge, we cannot continue.
        if (!groupsFromKnowledge) {
            return null;
        }

        const allItems = this.getAllItems(),
            allGroups  = groupsFromKnowledge.toArray(),
            output     = {};

        _.each(_.orderBy(allGroups, 'order'), (group) => {
            if (group.displayable === 0) {
                return;
            }
            const sum =  _.sumBy(_.filter(allItems, { groupId: group.id }), 'value');

            output[group.id] = {
                id      : group.id,
                label   : group.label,
                children: _.orderBy(_.filter(allItems, { groupId: group.id }), 'order'),
                value   : multiValued ? sum > 0 : sum

            };
        });

        return output;
    }

    /**
    * Update the state, triggering the events upper.
    */
    updateState(selectedIds) {
        const { filterKey, onChange } = this.props;

        this.setState({ selectedIds });
        onChange(filterKey, selectedIds);
    }

    /**
    * Render a single group, managing the display state of the input.
    *
    * @return JSX
    */
    renderGroup(group) {
        const { activeGroup }   = this.state,
            isActive            = activeGroup === group.id,
            className           = isActive ? 'active' : 'inactive';

        return (
            <div className="group-filter" key={`group-${group.id}`}>
                <div className="group-label">
                    {group.id !== null && (
                        <span className={`expand ${className}`}>
                            <Icon
                                width={9}
                                color="#ccc"
                                type="retract-arrow"
                                onClick={() => { this.onClickonGroupCaret(group.id); }}
                                data-qa-key={`caret-${str2DomFormat(group.label)}`}
                            />
                        </span>
                    )}
                    {this.renderGroupInput(group)}
                </div>
                {isActive && this.renderGroupItems(group)}
            </div>
        );
    }

    /**
    * Render the group input, choosing Checkbox or Radio according to props.
    *
    * @return JSX
    */
    renderGroupInput(group) {
        const {
                options,
                isFiltersLoading,
                items
            }                       = this.props,
            { selectedIds }         = this.state,
            { id, value, children } = group,
            checked                 = id !== null
                && children.length === _.intersection(selectedIds, _.map(children, 'id')).length,
            indeterminate           = !checked && _.intersection(selectedIds, _.map(children, 'id')).length > 0,
            emptyString             = isFiltersLoading || !items ? '' : '-',
            count                    = _.isBoolean(value)
                ? (value ? '✓' : emptyString)  // Just say if the group have facets
                : (value > 0                   // Render the facet count (sum of sub items)
                    ? value
                    : emptyString
                );

        if (options && options.excludingGroup) {
            return (
                <Radio
                    key={`label-${group.id}`}
                    checked={(selectedIds.length === 0 && group.id === null) || (checked || indeterminate)}
                    onChange={(e) => this.onClickOnGroupCheckbox(group.id, e)}
                    className={group.id === null ? 'is-not-a-group' : ''}
                >
                    <span className="filter-label" title={group.label}>{group.label}</span>
                    <span className="filter-count" data-qa-key={group.label}>
                        {count}
                    </span>
                </Radio>
            );
        }

        return (
            <Checkbox
                key={`label-${group.id}`}
                checked={checked}
                indeterminate={indeterminate}
                onChange={(e) => this.onClickOnGroupCheckbox(group.id, e)}
            >
                <span className="filter-label" title={group.label}>
                    {group.label}
                </span>
                <span className="filter-count" data-qa-key={group.label}>
                    {count}
                </span>
            </Checkbox>
        );
    }

    /**
    * Render content of a group.
    *
    * @return JSX
    */
    renderGroupItems(group) {
        if (group.children.length === 0) {
            return null;
        }

        const {
                items, isFiltersLoading
            }               = this.props,
            { selectedIds } = this.state,
            groupItems      = _.sortBy(group.children, 'label'),
            emptyString     = isFiltersLoading || !items ? '' : '-';

        return (
            <div className="group-checkboxes">
                {_.map(groupItems, (child) => (
                    <Checkbox
                        key={`child-${child.id}`}
                        checked={selectedIds.indexOf(child.id) !== -1}
                        onChange={(e) => this.onClickOnCheckbox(child.id, e)}
                    >
                        <span className="filter-label" title={child.label}>
                            {child.label}
                        </span>
                        <span className="filter-count">
                            {child.value > 0 ? child.value : emptyString }
                        </span>
                    </Checkbox>
                ))}
            </div>
        );
    }

    /**
    * Render the skeleton of the component.
    *
    * @return JSX
    */
    renderSkeleton() {
        return (
            <div className="group-filter">
                {_.times(5, (index) => (
                    <Skeleton key={index} paragraph={null}
                        active
                    />
                ))}
            </div>
        );
    }

    /**
    * Render the filter List
    *
    * @return JSX
    */
    render() {
        const {
                isFiltersLoading, options,
                filterKey, filterLabel,
            }            = this.props,
            {
                multiValued, excludingGroup
            }            = options || {},
            groupsJSX    = [],
            classNames   = ['grouped-list', filterKey, {'is-loading': isFiltersLoading}],
            groups       = this.getGroupsToDisplay(),
            sortedGroups = groups && _.sortBy(groups, 'label'),
            className    = makeStrClassName(classNames),
            sum          = _.sumBy(sortedGroups, 'value'),
            count        = !multiValued
                ? sum
                : sum > 0;

        if (!groups) {
            return this.renderSkeleton();
        }

        // Append extra "none" value
        if (excludingGroup) {
            groupsJSX.push(this.renderGroup({ id: null, label: 'All', children: [], value: count }));
        }

        return (
            <div className={className} data-qa-key={str2DomFormat(filterLabel)}>
                <LimitChildrenDisplay className="filter-elements" tolerance={1}>
                    {groupsJSX.concat(_.map(sortedGroups, (group) => this.renderGroup(group)))}
                </LimitChildrenDisplay>
            </div>
        );
    }

}


GroupedList.propTypes = {
    filterKey  : PropTypes.string.isRequired,
    filterLabel: PropTypes.string,
    items      : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
    value      : PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    ).isRequired,
    onChange      : PropTypes.func.isRequired,
    learnKnowledge: PropTypes.func.isRequired,
    options       : PropTypes.shape({
        excludingGroup: PropTypes.any,
        knowledge     : PropTypes.any,
        multiValued   : PropTypes.bool,
    }),
    isFiltersLoading: PropTypes.bool,
};


GroupedList.defaultProps = {
    options: null
};


/**
 * Bind the store to the component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(GroupedList);

