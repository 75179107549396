import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';

import Icon                 from '../../Icon';
import { capitalize }       from '../../../core/utils/text';

import './Shape/main.less';

// Pack shapes
const iconShapes = {
    'Incremental Process': 'incremental',
    'Disruptive Process' : 'disruptive',
    collabLevel1         : 'littleNetwork',
    collabLevel2         : 'mediumNetwork',
    collabLevel3         : 'bigNetwork',
    collabType0          : 'industrial',
    collabType1          : 'academic',
    collabType2          : 'academicAndIndustrial',
    collabScope0         : 'domestic',
    collabScope1         : 'worldwide',
    'Local Market'       : 'localStrategy',
    'Main Markets'       : 'mainStrategy',
    'Global Market'      : 'globalStrategy',
};

/**
 * The shape graph Component
 *
 */
class Shape extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};

        _.bindAll(this, 'render');

        this.labelRef = React.createRef();

        // Make a uuid
        this.id = _.uniqueId('shape-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.fitLabel();
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
        this.fitLabel();
    }

    /**
    * Fit label in window
    *
    * @return void
    */
    fitLabel() {
        const  { width, margin } = this.props,
            textElement          = this.labelRef.current;

        const bbox                 = textElement ? textElement.getBBox() : {},
            textWidth            = bbox.width,
            fontResizeRatio      = (width - (margin * 2)) / textWidth,
            textMustBeResize     = fontResizeRatio && fontResizeRatio < 1,
            textFontSize         = _.get(textElement, 'style.font-size'),
            newFontSize          = textFontSize ? parseInt(textFontSize) * fontResizeRatio : null;

        if (textMustBeResize && newFontSize) {
            textElement.style['font-size'] = `${newFontSize}px`;
        }
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvg() {
        const {
                width, height, margin,
                fontSize, color, data,
                onlySvg, shapeRootKey, labelOnTop,
                labelPrefix,
            }           = this.props,
            innerWidth  = width - margin * 2,
            labelheight = onlySvg ? 0 : (fontSize + (labelPrefix ? fontSize * 1.3 : 0)),
            innerHeight = height - margin * 2 - labelheight,
            stats       = _.get(data, 'stats', {}),
            shape       = iconShapes[`${shapeRootKey}${capitalize(stats.shapeKey)}`];

        return shape ? (
            <g transform={`translate( ${margin} ${margin} )`}>
                <Icon
                    width={innerWidth}
                    height={innerHeight}
                    translate={[innerWidth / 2, innerHeight / 2 + (labelOnTop ? labelheight : 0)]}
                    id={shape}
                    title={capitalize(stats.label)}
                    color={color}
                    folder="/graph/shape/"
                    SVG
                />
                {!onlySvg ? (
                    <text
                        ref={this.labelRef}
                        transform={
                            `translate( ${innerWidth / 2}`
                            + ` ${(labelOnTop ? 0 : innerHeight) + ((labelOnTop ? 1 : -1) * fontSize)} )`
                        }
                        dominantBaseline="baseline"
                        textAnchor="middle"
                        fill={color}
                        style={{ fontSize: `${fontSize}px` }}
                    >
                        <tspan>
                            {labelPrefix}
                        </tspan>
                        <tspan className="label" x={0}
                            y={fontSize + margin}
                        >
                            {capitalize(stats.label)}
                        </tspan>
                    </text>
                ) : null}

            </g>
        ) : null;
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width,
                height,
                skeleton,
                skeletonGradient,
                noStats,
                onlySvg,
            } = this.props,
            style = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton || noStats) { return (<div className="Shape skeleton" style={style} />); }

        return onlySvg ? this.renderSvg() : (
            <div className="Shape">
                <svg
                    width={width}
                    height={height}
                >
                    {this.renderSvg()}
                </svg>
            </div>
        );
    }

}

/**
 * Props type
 */
Shape.propTypes = {
    color           : PropTypes.string,
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fontSize        : PropTypes.number,
    height          : PropTypes.number.isRequired,
    labelOnTop      : PropTypes.bool,
    labelPrefix     : PropTypes.string,
    margin          : PropTypes.number,
    noStats         : PropTypes.any,
    onlySvg         : PropTypes.bool,
    shapeRootKey    : PropTypes.string,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    width           : PropTypes.number.isRequired
};

/**
 * Default props value
 */
Shape.defaultProps = {
    margin          : 0,
    fontSize        : 9,
    color           : '#317387',
    skeleton        : false,
    shapeRootKey    : '',
    onlySvg         : false,
    labelOnTop      : false,
    skeletonGradient: false
};

export default Shape;

