import React, { Component }          from 'react';
import { connect }                   from 'react-redux';
import PropTypes                     from 'prop-types';
import ImmutablePropTypes            from 'react-immutable-proptypes';
import _                             from 'lodash';
import { Skeleton }                  from 'antd';
import {
    Icon,
    Flag,
    Element,
    IButton,
}                                    from 'helpers';
import ModelAction                   from 'helpers/Action/Model';
import Tags                          from '../../../Tags';
import {
    getUserViewItemFromModel,
    getModelsOfType,
    toggleEntityInNewsletter,
}                                    from 'store/actions/userView';
import { bookmarkIsInScopeOfSearch } from 'store/actions/userView/bookmarks';
import { activateShortcut }          from 'store/actions/navigation';
import { openSettings }              from 'store/actions/newsletters';
import YoomapSendOrganization        from 'helpers/Yoomap/SendOrganization';
import { isUrl, makeStrClassName }   from 'utils/text';


const addressFields      = ['addrLine', 'city', 'countryName'];
const socialNetworkLinks = [
    {
        id   : 'website',
        icon : 'id-cards/web',
        isUrl: true
    },
    {
        id   : 'twitter',
        icon : 'id-cards/twitter',
        isUrl: true
    },
    {
        id   : 'linkedin',
        icon : 'id-cards/linkedin',
        isUrl: true
    }
];

/**
* Render the header content of the card
*
* @return JSX
*/
class OrgunitHeader extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onNewsletterButtonClick');

        this.state = {
            bookmarkJustAdded: false
        };
    }


    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidUpdate() {
        const { entity, registerMetadata } = this.props;

        registerMetadata && registerMetadata({ title: entity.label, actions: this.renderIdcardActions() });
    }


    /**
    * Render actions buttons
    *
    * @return JSX
    */
    renderIdcardActions() {
        const {
            entity, addToClipboard,
            registerMetadata,
            activeGroupSection,
        } = this.props;

        return (
            <ModelAction
                entity={entity}
                addToClipboard={addToClipboard || false}
                registerMetadata={registerMetadata}
                activeGroupSection={activeGroupSection}
            />
        );
    }


    /**
     * Entity has part of
     *
     * @returns boolean
     */
    hasPartOf() {
        const { entity } = this.props;

        return entity.partOf && entity.partOf.length > 0;
    }


    /**
     * Entity has acquired by
     *
     * @returns boolean
     */
    acquiredBy() {
        const { entity } = this.props;

        return entity.acquiredBy && entity.acquiredBy.length > 0;
    }


    /**
     * Render Part of
     */
    renderPartOf() {
        const {
                entity, elements, registerCallbacks,
                onClick
            } = this.props,
            partOfElement  = elements.find(el => el.category === 'Area/Entity/PartOf');

        return this.hasPartOf() && (
            <div className="part-ofs">
                <Icon
                    id="part-of"
                    width={18}
                    height={18}
                    className="part-of-icon"
                    title="Part of"
                />
                <Element
                    element={partOfElement.id}
                    model={entity}
                    registerCallbacks={registerCallbacks}
                    parameters={{
                        uri          : `/${entity.type}s/${entity.id}`,
                        label        : 'Part of',
                        attributePath: 'partOf',
                    }}
                    mode="inline"
                    allowExport={false}
                    onClick={onClick}
                />
            </div>
        );
    }


    /**
     * Render AcquiredBy
     */
    renderAcquiredBy() {
        const {
                entity, elements, registerCallbacks,
                onClick
            } = this.props,
            acquiredByElement = elements.find((el) => el.category === 'Area/Entity/AcquiredBy');

        return this.hasAcquiredBy() && (
            <div className="acquired-by">
                <Icon
                    title="Acquired by"
                    id="acquired-by"
                    width={18}
                    height={18}
                    className="acquired-by-icon"
                />
                <Element
                    element={acquiredByElement.id}
                    model={entity}
                    registerCallbacks={registerCallbacks}
                    parameters={{
                        uri          : `/${entity.type}s/${entity.id}`,
                        label        : 'Acquired by',
                        attributePath: 'acquiredBy',
                    }}
                    mode="inline"
                    allowExport={false}
                    onClick={onClick}
                />
            </div>
        );
    }



    /**
     * Render skeleton
     */
    renderSkeleton() {
        return (
            <div className="heading">
                <div className="heading-left" />
                <h2>
                    <Skeleton active paragraph={false} />
                </h2>
                <div className="logo"><Skeleton active title={false}
                    paragraph={false} avatar
                />
                </div>
                <div className="location">
                    <Skeleton active paragraph={false} />
                </div>
            </div>
        );
    }

    /**
     * This entity has acquiredBy organization
     *
     * @returns boolean
     */
    hasAcquiredBy() {
        const { entity } = this.props;

        return entity.acquiredBy && entity.acquiredBy.length > 0;
    }


    /**
    * Entity is in a newsletter
    *
    * @returns boolean
    */
    isInNewsletters() {
        const {
                entity, getModelsOfType,
            }           = this.props,
            newsletters = getModelsOfType('newsletter'),
            entities    = newsletters.reduce(
                (cumul, newsletter) => cumul.concat(_.get(newsletter, 'entities', [])),
                []
            );

        return !!entities.find((id) => id === entity.id);
    }


    /**
    * Trigger on click on newsletter button
    *
    * @param {DocumentEvent} e
    */
    onNewsletterButtonClick(e) {
        const {
                activateShortcut, entity, getModelsOfType,
                openSettings, toggleEntityInNewsletter
            }                 = this.props,
            newsletters       = getModelsOfType('newsletter'),
            { currentTarget } = e,
            { dataset }       = currentTarget,
            { showsettings }  = dataset || {},
            isInNewsletters   = this.isInNewsletters(),
            newsletter        = newsletters.find(newsL => newsL.key === 'competitive-insights') || {},
            { key }           = newsletter;

        if (key && showsettings) {
            openSettings(key);
            return activateShortcut('newsletters', { key });
        }

        if (key && isInNewsletters) {
            return activateShortcut('newsletters', { key });
        }

        toggleEntityInNewsletter(entity, 'competitive-insights');
    }


    /**
    * Render the newsletters links
    *
    * @returns html
    */
    renderNewslettersLink() {
        const { entity }    = this.props,
            { type }        = entity || {},
            isOrganization  = type === 'orgunit',
            isInNewsletters = this.isInNewsletters();

        return isOrganization && (
            <div className="newsletters-links">
                <div className="newsletters-links-button">
                    <IButton
                        icon="id-cards/email"
                        iconSize={14}
                        iconColor="var(--primary-color)"
                        label={isInNewsletters ? 'View' : 'Monitor'}
                        onClick={this.onNewsletterButtonClick}
                        hoverBgColor="var(--primary-color)"
                        hoverLabelColor="#ffffff"
                        fontSize={12}
                    />
                </div>
            </div>
        );
    }


    /**
    * Render social network button
    *
    * @returns {JSX}
    */
    renderSocialNetwork() {
        const { entity } = this.props;

        return _.map(socialNetworkLinks, link => {
            const socialNetwork = _.get(entity, `eAddresses.${link.id}`, false),
                validUrl        = isUrl(socialNetwork),
                iconColor       = validUrl ? 'var(--primary-color)' : 'var(--insight-gray)',
                href            = validUrl ? socialNetwork : '',
                disabled        = !validUrl;

            if (!socialNetwork) {
                return false;
            }

            return (
                <IButton
                    icon={link.icon}
                    iconSize={12}
                    iconColor={iconColor}
                    hoverBgColor="var(--primary-color)"
                    hoverLabelColor="#ffffff"
                    href={href}
                    disabled={disabled}
                    key={`link ${link.id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    tooltip={socialNetwork}
                    tooltipArrow={false}
                    tooltipOffset={[0, 5]}
                    tooltipTheme="light"
                    className={link.id}
                />
            );
        });
    }


    /**
    * Render tags for the current entity
    *
    * @return JSX
    */
    renderTags() {
        const {
            entity, tags, search
        } = this.props;

        return (
            <Tags
                search={search}
                entity={entity}
                tags={tags}
                size={16}
            />
        );
    }


    /**
     * Render orgunit logo
     *
     * @returns JSX
     */
    renderOrgunitLogo() {
        const {
                entity, getUserViewItemFromModel, search,
                bookmarkIsInScopeOfSearch,
            }             = this.props,
            userViewItem              = getUserViewItemFromModel(entity, 'bookmark'),
            bookmark                  = userViewItem && userViewItem.model,
            { settings }              = search || {},
            { bookmarkFolderForTags } = settings || {},
            isInScope                 = bookmarkIsInScopeOfSearch(userViewItem, bookmarkFolderForTags);

        return (
            <div className="logo">
                <Icon
                    className="orgunit-logo"
                    id={entity.type}
                    folder="/entities/"
                    orgunitLogo={entity.id}
                    width={81}
                    height={81}
                    borderSize={15}
                    borderColor="#ffffff"
                    discShaped
                />
                {bookmark && (
                    <Icon
                        className="bookmark"
                        type="bookmark"
                        theme={isInScope ? 'filled' : 'outlined'}
                        width={22}
                        color="#FFB400"
                        discShaped
                    />
                )}
            </div>
        );
    }


    /**
     * Render content
     *
     * @returns JSX
     */
    renderContent() {
        const { entity } = this.props,
            partOf       = this.renderPartOf(),
            acquiredBy   = this.acquiredBy(),
            fullAddress  = [];

        _.each(addressFields, field => {
            if (_.has(entity, `pAddress.${field}`) && entity.pAddress[field].trim() !== '') {
                fullAddress.push(entity.pAddress[field]);
            }
        });

        return (
            <div className="content">
                <h2>
                    <span>
                        <a target="_blank" rel="noopener noreferrer">
                            {entity.label}
                        </a>
                    </span>
                    {this.renderTags()}
                </h2>

                <div className="location">
                    <Flag iso={_.get(entity, 'pAddress.iso_code')} height={13}
                        className="flag"
                    />
                    {fullAddress.join(', ')}
                    {entity.isHQ && (
                        <span className="hq"> - HQ</span>
                    )}
                </div>

                {(partOf || acquiredBy) && (
                    <div className="relations">
                        {this.renderPartOf()}
                        {this.renderAcquiredBy()}
                    </div>
                )}

                <div className="links">
                    {this.renderSocialNetwork()}
                </div>
            </div>
        );
    }


    /**
    * Render the header
    *
    * @return JSX
    */
    render() {
        const { entity } = this.props,
            classNames   = [
                'heading',
                {
                    'has-part-of'   : this.hasPartOf(),
                    'is-acquired-by': this.hasAcquiredBy(),
                },
            ];

        if (!entity) {
            return this.renderSkeleton();
        }

        return (
            <div className={makeStrClassName(classNames)}>
                <div className="heading-left" />
                {this.renderOrgunitLogo()}
                {this.renderContent()}
                <div className="action-buttons">
                    <YoomapSendOrganization entity={entity} />
                    {/* Newsletters subscribe or view */}
                    {this.renderNewslettersLink()}
                </div>
            </div>
        );
    }

}

/* eslint-disable react/no-unused-prop-types */
OrgunitHeader.propTypes = {
    activeGroupSection       : PropTypes.object,
    activateShortcut         : PropTypes.func,
    addToClipboard           : PropTypes.func,
    search                   : PropTypes.object,
    elements                 : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    onClick                  : PropTypes.any,
    openSettings             : PropTypes.func,
    registerCallbacks        : PropTypes.func,
    registerMetadata         : PropTypes.func,
    getUserViewItemFromModel : PropTypes.func,
    bookmarkIsInScopeOfSearch: PropTypes.func,
    getModelsOfType          : PropTypes.func,
    entity                   : PropTypes.shape({
        acquiredBy: PropTypes.array,
        id        : PropTypes.string,
        isHQ      : PropTypes.bool,
        label     : PropTypes.string,
        pAddress  : PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        partOf    : PropTypes.array,
        type      : PropTypes.string
    }),
    tags                    : PropTypes.any,
    toggleEntityInNewsletter: PropTypes.func,
    newslettersStream       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

OrgunitHeader.defaultProps = {
    entity: null,
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList    : state.getIn(['userView', 'bookmark', 'list']),
    newslettersStream: state.getIn(['userView', 'newsletter', 'list']),
});

export default connect(mapStateToProps, {
    activateShortcut,
    bookmarkIsInScopeOfSearch,
    getModelsOfType,
    getUserViewItemFromModel,
    openSettings,
    toggleEntityInNewsletter,
})(OrgunitHeader);
