/**
 * Display content for shortcut clipboard, download and report.
 *
 * @returns Component
 */

import _                 from 'lodash';
import React, {
    useRef, useMemo,
    useState, useEffect,
}                        from 'react';
import { connect }       from 'react-redux';
import PropTypes         from 'prop-types';
import { Checkbox }      from 'antd';
import { Icon }          from 'helpers';
import { humanFileSize } from 'utils/text';
import { learn }         from 'store/actions/knowledge';
import { emitEvent }     from 'store/actions/sockets';
import Action            from 'helpers/Action';

/**
* Add each value of an array
*
* @param {int} accumulator
* @param {int} currentValue
*/
const add = (accumulator, currentValue) => accumulator + currentValue;

/**
* Render the shortcutViewItem
* React Functional Components (Hooks)
*
* @returns JSX
*/
const ShortcutViewItem = React.memo(props => {  // eslint-disable-line max-lines-per-function
    const {
            model, learnKnowledge, lastSeenDate,
        }                         = props,
        { id, userViewState }     = model,
        iconHeight                = 16,
        limitMenuDropdown         = 2,
        downloadLinkRef           = useRef(null),
        [mimetypes, setMimetypes] = useState([]),
        { date_ready, in_error }  = model,
        isLoading                 = !date_ready && !in_error ? 'loading' : '',
        isSeen                    = !lastSeenDate || new Date(date_ready) > new Date(lastSeenDate);

    const [loading, error, clickable, notSeen]  = useMemo(() => {
        if (model.type === 'file') {
            return [
                isLoading === 'loading',
                in_error,
                !in_error && !isLoading,
                isSeen && !isLoading
            ];
        }

        return [
            userViewState === 'loading',
        ];
    }, [isLoading, in_error, isSeen, userViewState]);

    useEffect(() => {
        learnKnowledge(['mimetypes'])
            .then(data => setMimetypes(data.mimetypes));
    }, []);

    /**
    * Invoke onToggle function with id param
    *
    * @param {object} e
    */
    const onClickCheckbox = e => {
        const {onToggle} = props;

        if (!onToggle) {
            return null;
        }

        onToggle(id);

        e.stopPropagation();
        e.preventDefault();
    };

    /**
    * Render action
    *
    * @param {object} action
    * @returns {(JSX|null)}
    */
    const renderAction = action => {
        if(!action.cb) {
            return null;
        }

        return (
            <div
                key={`${id}-${action.key}`}
                className={action.className}
                data-id={id}
                onClick={(e) => {
                    action.cb(model);
                    e.stopPropagation();
                }}
            >
                <Icon
                    folder={action.folder}
                    height={iconHeight}
                    id={action.key}
                />
            </div>
        );
    };

    /**
    * Simulate click on download link
    */
    const onClickDownloadLink = () => {
        downloadLinkRef.current.click();
    };

    /**
    * Render download link
    *
    * @param {object} props
    * @returns JSX
    */
    const renderDownloadLink = props => {
        const  { model } = props,
            { date_ready , id } = model,
            { filename, extention } = getDownloadFilenameAndExtension();

        return (
            <a
                className="download-link"
                data-download-id={id}
                download={`${filename}.${extention}`}
                href={date_ready && `public-api/files/${id}/file`}
                ref={downloadLinkRef}
            >
                Download
            </a>
        );
    };

    /**
    * Rendering the thumb
    *
    * @param {JSX} icon
    * @returns JSX
    */
    const renderThumb = icon => {
        return (
            <div
                key="thumb"
                className="thumb"
                onClick={onClickDownloadLink}
            >
                {icon}
            </div>
        );
    };

    /**
    * Rendering actions
    *
    * @returns JSX
    */
    const renderActions = () => {
        const {
                model, actions,
                getActions, emitEvent
            }               = props,
            actionsToRender = actions || getActions(model),
            actionsArray    = _.values(actionsToRender),
            actionsCount    = actionsArray.length,
            { displayName } = ShortcutViewItem,
            from            = { model, componentName: displayName},
            { label }       = model;

        // No actions
        if(!actionsToRender) {
            return null;
        }

        // Render actions with icons
        if (actionsCount <= limitMenuDropdown) {
            return actionsArray?.map(action => renderAction(action));
        }

        // Render actions menus
        return (
            <Action
                from={from}
                actions={actionsToRender}
                emitEvent={emitEvent}
                dataQaKey={`${label}-actions-button`}
            />
        );
    };

    /**
    * Rendering the main content
    *
    * @param {string} title
    * @param {JSX} subtitle
    * @param {string} secondaryTitle
    * @returns JSX
    */
    const renderInfo = (title, subtitle, secondaryTitle = '') => {
        return (
            <div
                key="info"
                className="info"
                onClick={onClickDownloadLink}
            >
                <div className="title">
                    <div className="primary-title">
                        {title}
                    </div>

                    {secondaryTitle && (
                        <div className="secondary-title">
                            {secondaryTitle}
                        </div>
                    )}
                </div>

                <div className="subtitle">
                    {subtitle}
                </div>
            </div>
        );
    };

    /**
    * Rendering the model corresponding to type
    *
    * @returns JSX
    */
    const renderModel = () => {
        const { type } = model;

        switch (type) {
            case 'clipboard_item':
                return renderClipboard();
            case 'file':
                return renderDownload();
            case 'report':
                return renderReport();
        }
    };

    /**
    * Rendering downloads
    *
    * @returns JSX
    */
    const renderDownload = () => {
        const { filename, extention } = getDownloadFilenameAndExtension(),
            icon                      = renderDownloadIcon(),
            title                     = `${filename}.${extention}`,
            subtitle                  = renderDownloadSubtitle();

        return (
            <>
                {icon && renderThumb(icon)}
                {renderInfo(title, subtitle)}
                {renderActions()}
            </>
        );
    };

    /**
    * Rendering clipboards
    *
    * @returns JSX
    */
    const renderClipboard = () => {
        const { metadata } = model,
            { filename }   = metadata,
            extention      = getClipboardFileExtention(model),
            icon           = renderIcon(extention),
            title          = `${filename}.${extention}`,
            subtitle       = renderClipboardSubtitle();

        return (
            <>
                {icon && renderThumb(icon)}
                {renderInfo(title, subtitle)}
                {renderActions()}
            </>
        );
    };

    /**
    * Rendering reports
    *
    * @returns JSX
    */
    const renderReport = () => {
        const { label }    = model,
            icon           = renderIcon('reports', 'shortcuts'),
            subtitle       = renderReportSubtitle(),
            secondaryTitle = `${renderReportSlidesCount()} slides`;

        return (
            <>
                {icon && renderThumb(icon)}
                {renderInfo(label, subtitle, secondaryTitle)}
                {renderActions()}
            </>
        );
    };

    /**
    * GetMimetype
    *
    * @returns object
    */
    const getMimetype = () => {
        const mimetype = model?.metadata?.mimetype || 'application/octet-stream';

        return mimetypes && mimetypes.find(mimetypeDefinition => mimetypeDefinition.id === mimetype);
    };

    /**
    *
    * @returns JSX
    */
    const renderDownloadIcon = () => {
        const mimetype           = getMimetype(),
            { extention, label } = mimetype || {},
            { date_ready }       = model,
            color                = date_ready ? undefined : '#888';

        return extention && label && (
            <Icon
                id={extention}
                folder="/file-type/"
                height={32}
                color={color}
                title={label}
            />
        );
    };

    /**
    * Render icon
    *
    * @param {string} id
    * @param {string} folder
    * @returns JSX
    */
    const renderIcon = (id, folder = 'file-type') => {
        return (
            <Icon
                id={id}
                folder={`/${folder}/`}
                height={33}
                width={27}
            />
        );
    };

    // TODO: put getExportSettings() in src\store\actions\auth.js
    /**
    * Get settings from props or defaultSettings
    *
    * @returns object
    */
    const getExportSettings = () => {
        const {
                member, clipboardDefaultSettings
            }              = props,
            memberSettings = member.get('settings');

        return !_.isNull(memberSettings) && memberSettings.export  ? memberSettings.export : clipboardDefaultSettings;
    };

    /**
    * Get extention file of a item
    *
    * @returns string
    */
    const getClipboardFileExtention = () => {
        const settings                 = getExportSettings(),
            { groupList, imageType }   = settings,
            { metadata }               = model,
            { filetype }               = metadata;

        if (filetype === 'booklet' || filetype === 'exportOnePage') {
            return 'pdf';
        }

        return filetype === 'image'
            ? imageType === 'png' ? 'png' : 'pdf'
            : (
                groupList === 'csv' ? 'csv' : 'xlsx'
            );
    };

    /**
    *
    * @returns object
    */
    const getDownloadFilenameAndExtension = () => {
        const { metadata }  = model,
            { filename }    = metadata || {},
            mimetype        = getMimetype(),
            { extention }   = mimetype || {};

        return { filename, extention };
    };

    /**
    * Render the main content of report
    *
    * @returns JSX
    */
    const renderReportSubtitle = () => {
        const {
                date_create, date_update, date_last_generate,
            }                  = model,
            date_update_text   = (date_update || 'Never'),
            date_generate_text = (date_last_generate || 'Never');

        return(
            <div className="bottom">
                <div className="left">
                    Created&nbsp;:&nbsp;
                    <span className={date_create ? '' : 'empty'}>
                        {date_create}
                    </span>
                </div>
                <div className="center">
                    Modified&nbsp;:&nbsp;
                    <span className={date_update ? '' : 'empty'}>
                        {date_update_text}
                    </span>
                </div>
                <div className="right">
                    Generated&nbsp;:&nbsp;
                    <span className={date_last_generate ? '' : 'empty'}>
                        {date_generate_text}
                    </span>
                </div>
            </div>
        );
    };

    /**
    * Number of reports slides
    *
    * @returns integer
    */
    const renderReportSlidesCount = () => {
        const { slides } = model,
            groups       = slides,
            slidesCount  = groups.map(group => { return group.slides.length; }).reduce(add);

        return slidesCount;
    };

    /**
    * Get time of the download
    *
    * @returns string
    */
    const getDownloadTime = () => {
        const { date_create } = model,
            dateCreate        = new Date(date_create),
            isValidDate       = !_.isNaN(dateCreate.getTime()),
            timeOptions       = { hour: 'numeric', minute: 'numeric' },
            time              = isValidDate ? new Intl.DateTimeFormat('en-AU', timeOptions).format(dateCreate) : null;

        return time;
    };

    /**
    * Get update date of the download
    *
    * @returns string
    */
    const getDownloadUpdateDate = () => {
        const { date_update } = model,
            dateUpdate        = date_update && new Date(date_update),
            isValidDate       = dateUpdate && !_.isNaN(dateUpdate.getTime()),
            timeOptions       = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
            time              = isValidDate ? new Intl.DateTimeFormat('en-US', timeOptions).format(dateUpdate) : null;

        return time;
    };

    /**
    * Render the main content of a download
    *
    * @returns JSX
    */
    const renderDownloadSubtitle = () => {
        const { date_ready }        = model,
            { filesize, in_error}   = model,
            { extention, filename } = getDownloadFilenameAndExtension(),
            time                    = getDownloadTime(),
            dateUpdate              = getDownloadUpdateDate(),
            isLoading               = !date_ready && !in_error ? 'loading' : '',
            relaunchAt              = `${time}${dateUpdate ? ` - relaunch at ${dateUpdate}` : ''}`,
            fSize                   = `${(filesize && isLoading !== 'loading') ? `${humanFileSize(filesize)}` : ''}`;

        return filename && extention && (
            <>
                {in_error && (<div className="error">An error occurred</div>)}
                {!in_error && time && (
                    <div className="time">
                        {relaunchAt}
                        {fSize && ' - '}
                        <span className="file-size">{fSize}</span>
                    </div>
                )}
            </>
        );
    };

    /**
    * Render icon according to status
    *
    * returns JSX|null
    */
    const renderStatusIcon = () => {
        if (!notSeen && !error) {
            return null;
        }

        if (error) {
            return (
                <Icon
                    className="icon-status"
                    color="#FF2C55"
                    height={20}
                    type="bubble-warning"
                />
            );
        }

        return (
            <Icon
                className="icon-status"
                color="var(--secondary-color)"
                height={20}
                type="bubble-check"
            />
        );
    };

    /**
    * Render the main content of clipboard
    *
    * @return JSX
    */
    const renderClipboardSubtitle = () => {
        const { metadata }    = model,
            { label, source } = metadata || {};

        return (
            <>
                <Icon
                    type="file-text"
                    height={10}
                />

                <span>{label}</span>
                {(
                    source && (
                        <>
                            <Icon
                                type="arrow-right"
                                height={10}
                            />
                            <span>{source}</span>
                        </>
                    )
                )}
            </>
        );
    };

    /**
    * Rendering the ShortcutViewItem
    *
    * @returns JSX
    */
    const render = () => {
        const { checked, onToggle } = props;

        return (
            <div key={id} className={`item ${loading ? 'loading' : ''} ${notSeen ? 'notSeen' : ''}`}>
                <div
                    className={`container ${error ? 'in-error' : ''} ${clickable ? 'clickable' : ''}`}
                    onClick={!loading ? onClickCheckbox : undefined}
                >
                    {onToggle && (
                        <div className="select" onClick={!loading ? onClickCheckbox : undefined}>
                            <Checkbox checked={checked} disabled={loading}
                                name={id}
                            />
                        </div>
                    )}

                    {renderModel()}
                    {renderStatusIcon()}
                </div>

                {renderDownloadLink(props)}
            </div>
        );
    };

    // The render call
    return render();
});

/* eslint-disable react/no-unused-prop-types */
ShortcutViewItem.propTypes = {
    actions                 : PropTypes.object, // Same actions for all elements
    getActions              : PropTypes.func, // Custom actions for each element using this cb
    checked                 : PropTypes.bool,
    clipboardDefaultSettings: PropTypes.object,
    isSeenDownload          : PropTypes.func,
    learnKnowledge          : PropTypes.func,
    model                   : PropTypes.object.isRequired,
    onToggle                : PropTypes.func,
};

ShortcutViewItem.defaultProps = {
    checked: false,
    status : '',
};

ShortcutViewItem.displayName = 'ShortcutViewItem';

/**
* Bind the store to to component
*/
const mapStateToProps = state => {
    const member       = state.getIn(['auth', 'member']),
        memberSettings = member.get('settings') || {},
        lastSeenDate   = memberSettings.file ? memberSettings.file.last_seen_date : null;

    return {
        member,
        lastSeenDate,
    };
};

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    emitEvent
})(ShortcutViewItem);
