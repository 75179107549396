import React, { Component }   from 'react';
import List from './List';

/**
 * UniqueChoice filter (unique value in list)
 */
class UniqueChoice extends Component {

    /**
     * Render the componant
     */
    render() {
        return (
            <List
                {...this.props}
                singleValue
            />
        );
    }

}

export default UniqueChoice;

