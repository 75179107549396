import _                    from 'lodash';
import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import { Alert, Modal }     from 'antd';
import { Icon, CssLoader }  from 'helpers';
import { makeStrClassName } from 'utils/text';
import InsightBotRow        from './InsightBot/InsightBotRow';
import { connect }          from 'react-redux';
import { fetchAssistant }   from 'store/actions/insightBot';

import './assets/insight-bot.less';

/**
* @return JSX
*/
const InsightBot = React.memo(({ // eslint-disable-line max-lines-per-function
    addTags,
    fetchAssistant,
    lexicons,
    source,
    tags,
}) => {
    const { data, status }              = lexicons?.get(source) || {},
        [showModal, setShowModal]       = useState(false),
        [selectedTags, setSelectedTags] = useState([]),
        assistantIsLoading              = status === 'loading',
        assistantIsLoaded               = status === 'loaded',
        functionMappings                = {
            synonyms              : synonyms => renderSynonyms(synonyms),
            definition            : definition => renderDefinitions(definition),
            key_technical_concepts: concepts => renderKeyTechnicalConcepts(concepts),
            benefits              : benefits => renderBenefits(benefits),
            challenges            : challenges => renderChallenges(challenges),
            production            : production => renderProduction(production),
            applications          : applications => renderApplications(applications),
        };

    /**
    * Close modal
    */
    const closeModal = () => {
        setSelectedTags([]);
        setShowModal(false);
    };

    /**
    * Handle tags
    *
    * @param {string} tag
    */
    const handleTags = tag => {
        if (!_.includes(selectedTags, tag)) {
            setSelectedTags([
                ...selectedTags,
                tag,
            ]);

            return;
        }

        const newTags = _.clone(selectedTags);
        _.pull(newTags, tag);

        setSelectedTags(newTags);
    };

    /**
    * Launch insight bot or open bot modal
    */
    const manageBot = () => {
        if (!assistantIsLoaded && !assistantIsLoading) {
            fetchAssistant(source);

            return;
        }

        setShowModal(!_.isEmpty(data));
    };

    /**
    * Render button
    *
    * @returns {JSX}
    */
    const renderButton = () => {
        const iconColor = !_.isEmpty(data) ? 'var(--insight-orange)' : 'var(--insight-gray)',
            classNames  = [
                'insight-bot-button',
                {
                    ready  : assistantIsLoaded && !_.isEmpty(data),
                    loading: assistantIsLoading,
                },
            ];

        return (
            <div className={makeStrClassName(classNames)} onClick={manageBot}>
                {assistantIsLoading ? (
                    <CssLoader type="ring" size={18}
                        thickness={1} color="var(--insight-gray)"
                    />
                ) : (
                    <Icon type="ai" width={18}
                        color={iconColor}
                    />
                )}
            </div>
        );
    };

    /**
    * Render modal title
    *
    * @returns {JSX}
    */
    const renderModalTitle = (
        <div className="title">
            <Icon type="ai" width={18}
                color="var(--primary-color)"
            />
            InsightBot
        </div>
    );

    /**
    * Prefix value with a letter: a), b), c), ...
    * For copy to clipboard functionality
    *
    * @param {number} pos
    *
    * @returns {JSX}
    */
    const prefixListItemWithLetter = pos => {
        const letter = String.fromCharCode('a'.charCodeAt(0) + pos);

        return `${letter}) `;
    };

    /**
    * Render an item of a list
    *
    * @param {string} item
    * @param {number} pos
    *
    * @returns {JSX}
    */
    const renderListItem = (item, pos) => (
        <li key={pos}>
            {prefixListItemWithLetter(pos)}
            {item}
        </li>
    );

    /**
    * Render synonyms
    *
    * @param {array} synonyms
    *
    * @returns {JSX}
    */
    const renderSynonyms = synonyms => {
        const tagsToAdd = _.isEmpty(selectedTags) ? synonyms : selectedTags;

        return (
            <InsightBotRow add={() => addTags(tagsToAdd)} copy={false}
                title="Synonyms" key="synonyms"
            >
                {_.map(synonyms, (synonym, index) => {
                    const hasComma      = index < synonyms.length - 1,
                        alreadySelected = _.includes(tags, synonym),
                        selected        = _.includes(selectedTags, synonym),
                        classNames      = [
                            'synonym',
                            {selected},
                            {'already-selected': alreadySelected},
                        ];

                    return (
                        <span className={makeStrClassName(classNames)} key={synonym}
                            onClick={!alreadySelected ? () => handleTags(synonym) : undefined}
                        >
                            {`${synonym}${hasComma ? ', ' : ''}`}
                        </span>
                    );
                })}
            </InsightBotRow>
        );
    };

    /**
    * Render definition
    *
    * @param {string} definition
    *
    * @returns {JSX}
    */
    const renderDefinitions = definition => (
        <InsightBotRow key="description" title="Description">
            {definition}
        </InsightBotRow>
    );

    /**
    * Render key definitions
    *
    * @param {array} keyTechnicalConcepts
    *
    * @returns {JSX}
    */
    const renderKeyTechnicalConcepts = concepts => (
        <InsightBotRow key="key-definitions" title="Key Definitions">
            <ol className="lower-alpha">
                {_.map(
                    concepts,
                    (concept, index) => renderListItem(`${concept?.concept}: ${concept?.definition}`, index)
                )}
            </ol>
        </InsightBotRow>
    );

    /**
    * Render benefits
    *
    * @param {array} benefits
    *
    * @returns {JSX}
    */
    const renderBenefits = benefits => (
        <InsightBotRow key="benefits" title="Benefits">
            <ol className="lower-alpha">
                {_.map(benefits, (benefit, index) => renderListItem(benefit, index))}
            </ol>
        </InsightBotRow>
    );

    /**
    * Render challenges
    *
    * @param {array} challenges
    *
    * @returns {JSX}
    */
    const renderChallenges = challenges => (
        <InsightBotRow key="technical-challenges" title="Technical Challenges">
            <ol className="lower-alpha">
                {_.map(challenges, (challenge, index) => renderListItem(challenge, index))}
            </ol>
        </InsightBotRow>
    );

    /**
    * Render production
    *
    * @param {string} production
    *
    * @returns {JSX}
    */
    const renderProduction = production => (
        <InsightBotRow key="production-means" title="Production Means">
            {production}
        </InsightBotRow>
    );

    /**
    * Render applications
    *
    * @param {array} applications
    *
    * @returns {JSX}
    */
    const renderApplications = applications => (
        <InsightBotRow key="applications" title="Applications">
            <ol className="lower-alpha">
                {_.map(applications, (application, index) => renderListItem(application, index))}
            </ol>
        </InsightBotRow>
    );

    /**
    * Render Modal body
    *
    * @returns {JSX}
    */
    const renderBody = () => {
        const alertMessage = 'This feature is using OpenAI/ChatGPT. '
            + 'The request is submitted by our Questel servers. '
            + 'No personal information is transmitted to third parties during this process.',
            icon = (
                <Icon type="infoBubbleHome" folder="/home/"
                    width={18}
                />
            );

        return (
            <div className="body-container">
                <Alert banner icon={icon}
                    key="alert-info" message={alertMessage}
                    rootClassName="insight-alert-info" type="info"
                />

                {
                    _.map(data, (assistantDatum, key) => {
                        if (!functionMappings[key]) {
                            return null;
                        }

                        return functionMappings[key](assistantDatum);
                    })
                }
            </div>
        );
    };

    return (
        <>
            {renderButton()}
            <Modal
                styles={{height: 500}}
                footer={null}
                onCancel={closeModal} open={showModal}
                title={renderModalTitle} width={860}
                className="insight-modal insight-bot"
            >
                {renderBody()}
            </Modal>
        </>
    );
});

InsightBot.propTypes = {
    addTags       : PropTypes.func.isRequired,
    fetchAssistant: PropTypes.func,
    lexicons      : ImmutablePropTypes.map,
    source        : PropTypes.string.isRequired,
    tags          : PropTypes.array,
};

InsightBot.defaultProps = {
    addTags: () => {},
    source : '',
};

/**
*
*/
const mapStateToProps = state => {
    const lexicons = state.getIn(['insightBot', 'lexicons']);

    return {
        lexicons,
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    fetchAssistant,
})(InsightBot);
