import _                      from 'lodash';
import { GraphQLClient, gql } from 'graphql-request';

const queries = {
    modules: gql`query fetchModules(
            $uiks: [String]
            $isForPartner: Boolean
            $datafieldTypes: [String]
            $datafieldIsMultiple: Boolean
        ) {
            datatypes(
                uiks: $uiks
                isForPartner: $isForPartner
                datafieldTypes: $datafieldTypes
                datafieldIsMultiple: $datafieldIsMultiple
                ) {
                id
                uik
                title
            }
        }`,
    data: gql`query fetchData(
            $datatype: [String]
            $limit: Int
            $offset: Int
            $search: SearchInput
            $order: OrderInput
          ) {
            data (
              datatype: $datatype
              limit: $limit
              offset: $offset
              search: $search
              order: $order
            ) {
              data {
                id
                title
                image
                description
              }
            }
          }`,
    fields: gql`query fetchFields(
            $datatype: [String]
            $isForPartner: Boolean
            $datafieldTypes: [String]
            $datafieldIsMultiple: Boolean
        ) {
        datatypes(
            uiks: $datatype
            isForPartner: $isForPartner
            datafieldTypes: $datafieldTypes
            datafieldIsMultiple: $datafieldIsMultiple
            ) {
          uik
          title
          fields {
            id
            title
            settings
          }
        }
      }`,
    searchOrganization: gql`query insightStartupSearch($org_name: String, $e_addresses: String) {
        insightStartupSearch(org_name: $org_name, e_addresses: $e_addresses) {
            id
            url
        }
    }`,
    searchBulkOrganizations: gql`query insightStartupSearchMultiple($names: [InsightInput]) {
        insightStartupSearchMultiple(names: $names) {
            id
            url
            id_insight
        }
    }`,
    addOrganization: gql`mutation insightStartupMutation($fields: [FieldInput]) {
        insightStartupMutation(fields: $fields) {
                id
                url
            }
        }
    `,
};


const mocks = {
    sendValidationError: {
        errors: [
            {
                message   : 'validation',
                file      : '/var/www/html/local/services/unicore/vendor/rebing/graphql-laravel/src/Support/Field.php',
                line      : 144,
                validation: {
                    org_name: [
                        'Le champ org name est obligatoire quand e addresses n\'est pas présent.'
                    ],
                    e_addresses: [
                        'Le champ e addresses est obligatoire quand org name n\'est pas présent.'
                    ]
                }
            }
        ],
        data: {
            insightStartupSearch: null
        }
    },
    sendError: {
        errors: [{
            message    : 'Yoomap got a error',
            description: 'The description of the yoomap error',
        }]
    },
    searchSuccess: {
        data: {
            id : '779477',
            url: 'https://qa.yoomap.pub/modal/view/779477',
        }
    },
    searchError: {
        errors: [
            {
                message   : 'validation',
                file      : '/var/www/html/local/services/unicore/vendor/rebing/graphql-laravel/src/Support/Field.php',
                line      : 144,
                validation: {
                    org_name: [
                        'Le champ org name est obligatoire quand e addresses n\'est pas présent.'
                    ],
                    e_addresses: [
                        'Le champ e addresses est obligatoire quand org name n\'est pas présent.'
                    ]
                }
            }
        ],
        data: {
            insightStartupSearch: null
        }
    },
    searchOrganizationsSuccess: {
        data: {
            insightStartupSearchMultiple: [
                {
                    id        : '806405',
                    url       : 'http://startup.localhost/modal/view/755047?title=usdep-health',
                    id_insight: '~*ygkqx6ufo4qsar2tbhbna3nzsm000000'
                },
                {
                    id        : '806405',
                    url       : 'http://startup.localhost/modal/view/755047?title=putra',
                    id_insight: '~*sp5sd6ddsr4irhn3zf4tjdtjnm000000'
                },
                {
                    id        : '757642',
                    url       : 'http://startup.localhost/modal/view/757642?title=Basf',
                    id_insight: '~*gupgccsa2l7xsj3qf6xhxwolwe000000'
                }
            ]
        }
    }
};


/**
 * Catch query to generate mock
 *
 * @param {string} queryKey
 * @returns
 */
const mockResponse = (api_url, queryKey) => {
    if (queryKey === 'searchOrganization' && api_url === 'https://api.yoomap.pub/graphql/secret') {
        return; // Disable mock
        return mocks.searchError; // eslint-disable-line  no-unreachable
        return []; // eslint-disable-line  no-unreachable
    }

    if (queryKey === 'searchBulkOrganizations' && api_url === 'https://api.yoomap.pub/graphql/secret') {
        return; // Disable mock
        return mocks.searchOrganizationsSuccess.data; // eslint-disable-line  no-unreachable
    }

    if (queryKey === 'addOrganization' && api_url === 'https://api.yoomap.pub/graphql/secret') {
        return; // Disable mock
        return mocks.sendValidationError; // eslint-disable-line  no-unreachable
    }
};

/**
 * Execute the query
 *
 * @param {object} platform
 * @param {object} options
 */
export const query = (platform, options) => {
    const { api_url, token, token_key } = platform,
        { queryKey, variables }         = options,
        graphQLClient                   = new GraphQLClient(api_url, {
            headers: {
                authorization: 'Bearer ' + token,
                hash         : token_key
            },
        });

    return new Promise((resolve, reject) => {
        // Manage Mocks
        const mockReturn = mockResponse(api_url, queryKey, resolve);
        if (!_.isUndefined(mockReturn)) {
            mockReturn.errors
                ? reject(mockReturn)
                : resolve(mockReturn);
            return;
        }

        // Launch GraphQL request
        graphQLClient.request(queries[queryKey], variables).then((data) => {
            resolve(data);
        }).catch((error) => {
            const errorStringify = JSON.stringify(error, undefined, 2),
                errorJson        = errorStringify ? JSON.parse(errorStringify) : {};

            reject(errorJson);
        });
    });
};

