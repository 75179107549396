import _ from 'lodash';

/**
 * Get link path (curved line)
 *
 * @param d object { source : {x, y}, target: {x, y} }
 *
 * @return string The string path
 */
export const linkPath = (d) => {
    const curveSourceX = _.get(d, 'source.curveTo.x', false) || (d.source.x + d.target.x) / 2,
        curveSourceY = _.get(d, 'source.curveTo.y', false) || d.source.y,
        curveTargetX = _.get(d, 'target.curveTo.x', false) || (d.source.x + d.target.x) / 2,
        curveTargetY = _.get(d, 'target.curveTo.y', false) || d.target.y,
        moveTo = `M${d.source.x},${d.source.y}`,
        cSource = `C${curveSourceX},${curveSourceY}`,
        cTarget = `${curveTargetX},${curveTargetY}`,
        target = `${d.target.x},${d.target.y}`;
    return `${moveTo} ${cSource} ${cTarget} ${target}`;
};

/**
 * Get link path (curved line)
 *
 * @param d object { source : {x, y}, target: {x, y} }
 *
 * @return string The string path
 */
export const linePath = (d) => {
    const moveTo = `M${d.source.x},${d.source.y}`,
        target = `${d.target.x},${d.target.y}`;
    return `${moveTo} ${target}`;
};

/**
 * Get dom element postion
 */
const getPosition = (el) => {
    if (el.style?.top && el.style?.left && el.style?.width &&  el.offsetHeight) {
        return {
            ...el.style,
            height: el.offsetHeight
        };
    }

    return getBoundingClientRectPosition(el);
};

/**
 * Get position from getBoundingClientRect
 *
*/
const getBoundingClientRectPosition = (el) => {
    const rect = el.getBoundingClientRect();

    return {
        top   : rect.y,
        left  : rect.x,
        width : rect.width + 2,
        height: rect.height + 2,
    };
};


/**
 * Test collision inner two absolute domelement
 *   Must have top, left style attributes
 *
 * @param domNode div1
 * @param domNode div2
 *
 * @return bool
 */
export const collision = (div1, div2) => {
    if (!div1 || !div2) { return false; }

    const { top: top1, left: left1, width: width1, height: height1 } = getPosition(div1),
        { top: top2, left: left2, width: width2, height: height2 } = getPosition(div2),
        x1 = parseInt(left1, 10),
        y1 = parseInt(top1, 10),
        h1 = height1,
        w1 = parseInt(width1, 10),
        x2 = parseInt(left2, 10),
        y2 = parseInt(top2, 10),
        h2 = height2,
        w2 = parseInt(width2, 10);

    if ((y1 + h1) > y2
        && y1 < (y2 + h2)
        && (x1 + w1) > x2
        && x1 < (x2 + w2)
    ) {
        return true;
    }

    return false;
};


/**
 *   Make ortho rotation
 *   @param {object}   a Point A
 *   @param {object}   b Point B
 *   @returns Array   The rotated points
 */
const orthoRotate = (a, b) => {
    const ax = (a.x + a.y + b.x - b.y) / 2,
        ay = (-a.x + a.y + b.x + b.y) / 2,
        bx = (a.x - a.y + b.x + b.y) / 2,
        by = (a.x + a.y - b.x + b.y) / 2;
    return {
        a: {
            x: ax,
            y: ay
        },
        b: {
            x: bx,
            y: by
        }
    };
};

/**
 *   Get distance of two point
 *   @param {object}   a Point A
 *   @param {object}   b Point B
 *   @returns {string} The path
 */
export const dist = (a, b) => Math.sqrt(
    ((a.x - b.x) ** 2)
    + ((a.y - b.y) ** 2)
);

/**
 *   Get center two point
 *   @param {object}   a Point A
 *   @param {object}   b Point B
 *   @returns {string} The path
 */
export const center = (a, b) => ({
    x: a.x + (b.x - a.x) / 2,
    y: a.y + (b.y - a.y) / 2
});

/**
 *  Get svg path using 3 points
 *   @param {object}   a The point A
 *   @param {object}   c The point C
 *   @param {object}   b The point B
 *   @returns {string} The path
 */
export const arcPath3Point = (a, b, c) => {
    const A = dist(b, c),
        B = dist(c, a),
        C = dist(a, b),
        angle = Math.acos((A * A + B * B - C * C) / (2 * A * B)),
        // Calc radius of circle
        K = A * B * Math.sin(angle) / 2,
        r = Math.round(1000 * A * B * C / 4 / K) / 1000,
        // Large arc flag
        laf = +(Math.PI / 2 > angle),
        // Sweep flag
        saf = +((b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) < 0);

    return ['M', [a.x, a.y], 'A', r, r, 0, laf, saf, [b.x, b.y]].join(' ');
};

/**
 *  Get svg path using 3 points
 *   @param {object}   a     The point A
 *   @param {object}   c     The point C
 *   @param {float}    ratio The ratio
 *   @returns {string} The path
 */
export const arcPath2Point = (d) => {
    const {
            source, target, ratio
        }             = d,
        rotatedPoints = orthoRotate(source, target),
        c             = center(rotatedPoints.a, rotatedPoints.b),
        newPoints     = {
            a: {
                x: c.x + (rotatedPoints.a.x - c.x) * ratio,
                y: c.y + (rotatedPoints.a.y - c.y) * ratio
            },
            b: {
                x: c.x + (rotatedPoints.b.x - c.x) * ratio,
                y: c.y + (rotatedPoints.b.y - c.y) * ratio
            }
        };

    return arcPath3Point(source, target, newPoints.b);
};

/**
 * Get hull points from points
 *
 * @param {*} points
 * @returns
 */
export const hullPoints = (points, padding = 2.5) => {
    let pointArr = [];

    points.forEach(d => {
        const pad = (d.size || 0) + padding;
        pointArr = pointArr.concat([
            [d.x - pad, d.y - pad],
            [d.x - pad, d.y + pad],
            [d.x + pad, d.y - pad],
            [d.x + pad, d.y + pad]
        ]);
    });

    return pointArr;
};

/**
*  Radiant to degree
*   @param {float} rad The radiant
*   @returns {float} The value in degree
*/
export const rad2Deg = (rad) => rad * 180 / Math.PI;

export default {
    linkPath,
    rad2Deg,
    dist,
    arcPath3Point,
    arcPath2Point,
    hullPoints,
    collision,
    linePath,
};
