/**
 * Keep all actions types listed there
 *
 */

// REFRESH the entire list
const NEWSLETTERS_REFRESH_LIST = 'NEWSLETTERS_REFRESH_LIST';

// UPDATE the application state
const NEWSLETTER_UPDATE_STATE = 'NEWSLETTER_UPDATE_STATE';

// Edit newsletter
const NEWSLETTERS_UPDATE       = 'NEWSLETTERS_UPDATE';

// Remove newsletter
const NEWSLETTERS_REMOVE       = 'NEWSLETTERS_REMOVE';

export default {
    NEWSLETTERS_REFRESH_LIST,
    NEWSLETTERS_UPDATE,
    NEWSLETTERS_REMOVE,
    NEWSLETTER_UPDATE_STATE,
};
