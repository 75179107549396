// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landmark g {
  transition: opacity 0.2s linear;
}
.capture-viewer .landmark g {
  transition: none;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/assets/landmark.less"],"names":[],"mappings":"AAAA;EAEQ,+BAAA;AAAR;AAIA;EAIY,gBAAA;AALZ","sourcesContent":[".landmark {\n    g {\n        transition: opacity 0.2s linear;\n    }\n}\n\n.capture-viewer {\n\n    .landmark {\n        g {\n            transition: none;\n        }\n    }\n\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
