/**
 * Main Insight search view
 *
 * @return Component
 */
import PropTypes from 'prop-types';
import _                    from 'lodash';
import React, { Component } from 'react';

import './assets/view.less';

/**
* View a query
*
*
*/
class View extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this);

        this.state = {};
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { search,
                sentence } = this.props,
            { label }      = search;

        return (
            <div className="edit-search">
                {sentence || label}
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
View.propTypes = {
    sentence: PropTypes.any,
    search  : PropTypes.shape({
        label: PropTypes.string
    })
};

View.defaultProps = {

};

export default View;

