import _                           from 'lodash';
import React, { Component }        from 'react';
import PropTypes                   from 'prop-types';
import {Radio }                    from 'antd';
import { connect }                 from 'react-redux';
import { learn }                   from '../../store/actions/knowledge';
import DateRangeCustomYear         from './DateRangeCustomYear';
import DateRangeCustomMonth        from './DateRangeCustomMonth';
import {
    makeStrClassName,
    str2DomFormat,
}                                  from 'utils/text';
import dayjs                       from 'dayjs';
import { getCustomTimeFrameLabel } from 'utils/date';
import { requestTimeout }          from 'utils/requestTimeout';
import { IButton }                 from 'helpers';

/**
 * Publication Date filter
 *
 */
class DateRange extends Component {

    /**
    * Construct the component
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClickOnBtn', 'setSelectedId', 'setActiveCustomInput', 'resetDateRangeCustom');

        this.state = {
            timeframes       : null,
            activeCustomInput: false,
        };
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        this.learnKnowledge();
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { value }     = this.props,
            { selectedId } = this.state;

        if(value != prevProps.value && selectedId !== value) {
            this.setState({ selectedId: value });
        }
    }


    /**
    * Event when the user clicks a button
    */
    onClickOnBtn(selectedId) {
        const { activeCustomInput } = this.state;

        this.setState({ selectedId });

        if (this.isACustomRange(selectedId)) {
            return;
        }

        if (activeCustomInput) {
            this.setActiveCustomInput(false);
        }

        this.setSelectedId(selectedId);
    }

    /**
    * Update the state with the provided id
    */
    setSelectedId(selectedId) {
        // Prevent reloading when filters haven't changed.
        const { onChange, filterKey, value } = this.props;
        if (value === selectedId) {
            return;
        }

        this.setState({ selectedId });

        // Update filter on preset range (not custom)
        if (_.isString(selectedId) && selectedId.indexOf('custom_') === -1) {
            requestTimeout(
                () => onChange(filterKey, selectedId === 'tfall' ? null: selectedId),
                100
            );
        }
    }

    /**
     * Change state of activeCustomInput
     *
     * @param {boolean} value
     */
    setActiveCustomInput(value) {
        this.setState({ activeCustomInput: value });
    }

    /**
    * Determine if the provided id is a custom range.
    *
    * @return boolean
    */
    isACustomRange(id) {
        const { timeframes } = this.state,
            actualFrames     = timeframes
                .filter((timeframe) => timeframe.id !== 'tfcustom')
                .map((timeframe) => timeframe.id)
                .toArray();

        return id && actualFrames.indexOf(id) === -1;
    }

    /**
    * Learn all the knowledge required to render the content.
    */
    learnKnowledge() {
        const { learnKnowledge } = this.props;

        learnKnowledge('timeframes').then(this.setState.bind(this));
    }

    /**
    * Get min and max date if search is filter
    *
    * @returns {object}
    */
    getBoundaries() {
        const { restrictedFilters } = this.props,
            duration                = this.getDuration(restrictedFilters),
            dateMax                 = dayjs().endOf('day'),
            maxYear                 = dateMax.year(),
            minYear                 = duration > 0 ? maxYear - duration : 1980,
            dateMin                 = dayjs(`${minYear}-01-01`).format('YYYY-MM-DD');

        return {
            dateMax,
            dateMin,
        };
    }

    /**
     * Reset custom date ranges
     */
    resetDateRangeCustom() {
        this.setState({ selectedId: undefined });
        this.getBoundaries();
    }

    /**
    * Render the custom
    *
    * @return JSX
    */
    renderDateRangeCustom() {
        const {
                value, onChange, filterKey,
                filterType, blockedEndDate, minRange,
            }                     = this.props,
            { selectedId }        = this.state,
            { activeCustomInput } = this.state,
            boundaries            = this.getBoundaries(),
            CustomComponant       = filterType === 'YearRange' ? DateRangeCustomYear : DateRangeCustomMonth;

        return activeCustomInput && (
            <CustomComponant
                value={value}
                selectedId={selectedId}
                setSelectedId={this.setSelectedId}
                onChange={onChange}
                onReset={this.resetDateRangeCustom}
                filterKey={filterKey}
                blockedEndDate={blockedEndDate}
                minRange={minRange}
                boundaries={boundaries}
                setActiveCustomInput={this.setActiveCustomInput}
            />
        );
    }

    /**
     * Get enabled timeframes compared to filter value
     *
     * @param {array} timeframes
     *
     * @returns {array}
     */
    getEnabledTimeframes(timeframes) {
        const {
                omitTimeframes,
                restrictedFilters,
            }                   = this.props,
            alwaysEnabled       = restrictedFilters === 'tfall' || !restrictedFilters,
            availableTimeframes = _.filter(
                timeframes,
                timeframe => !omitTimeframes.includes(timeframe.id),
            );

        if (alwaysEnabled) {
            return availableTimeframes.map(timeframe => ({ ...timeframe, enabled: true }));
        }

        const filterDuration = this.getDuration(restrictedFilters),
            enabledTimeframes  = availableTimeframes.map(timeframe => {
                const { duration } = timeframe,
                    tfall          = timeframe.id === 'tfall',
                    enabled        = timeframe.id === 'tfcustom'
                        || (duration <= filterDuration) // Tfall duration === -1
                        || (filterDuration < 0 && tfall);

                return { ...timeframe, enabled };
            });

        return enabledTimeframes;
    }

    /**
     * Get duration
     *
     * @param {string} dateFilter
     *
     * @returns {number}
     */
    getDuration(dateFilter) {
        const { timeframes }  = this.state,
            selectedTimeframe = timeframes.find(timeframe => timeframe.id === dateFilter);

        if (dateFilter && !this.isACustomRange(dateFilter)) {
            return selectedTimeframe.duration || -1;
        }

        const customDateFilter = _.split(dateFilter, '_'),
            customDuration     = customDateFilter[2] - customDateFilter[1];

        return customDuration;
    }

    /**
     * Get current selected timeframe
     */
    getCurrentSelection() {
        const { value }    = this.props,
            { selectedId } = this.state;

        if (selectedId) {
            return selectedId;
        }

        if (value) {
            return value;
        }

        return 'tfall';
    }

    /**
     * Render the button to show the custom form
     *
     * @returns JSX
     */
    renderButtonCustom() {
        const {
                activeCustomInput,
            }              = this.state,
            { filterType } = this.props,
            classNames     = ['custom', {'is-active': activeCustomInput}],
            label          = filterType === 'YearRange'
                ? 'Custom Year'
                : 'Custom Date';

        return (
            <IButton
                className={makeStrClassName(classNames)}
                fontSize={12}
                label={label}
                hoverBgColor="#f8f8f8"
                hoverLabelColor="#10285D"
                icon="actions/filter"
                iconColor="#B7B7B7"
                onClick={() => this.setActiveCustomInput(!activeCustomInput)}
            />
        );
    }

    /**
    * Render the Filters
    *
    * @return JSX
    */
    render() {
        const { timeframes }     = this.state,
            { items }            = this.props,
            { filterLabel }      = this.props,
            { isFiltersLoading } = this.props,
            classNames           = ['filter-range', {'is-loading': isFiltersLoading}, filterLabel];

        if (!timeframes) {
            return null;
        }

        const sortedTimeframes       = _.orderBy(timeframes.toArray(), 'order'),
            enabledTimeframes        = this.getEnabledTimeframes(sortedTimeframes),
            currentSelection         = this.getCurrentSelection(),
            currentSelectionIsCustom = _.isString(currentSelection) && currentSelection.indexOf('custom') >= 0;

        return (
            <div className={makeStrClassName(classNames)} data-qa-key={str2DomFormat(filterLabel)}>
                {enabledTimeframes.map(value => {
                    const { enabled } = value,
                        classes       = ['value', {disabled: !enabled}],
                        item          = _.find(items, item => item.id === value.id),
                        count         = !items ? '' : item?.value || '-',
                        showCustom    = currentSelectionIsCustom && value.id === 'tfcustom',
                        showOptions   = value.id !== 'tfcustom' || showCustom,
                        selected      = value.id === currentSelection
                            || _.isNull(currentSelection) && value.id === 'tfall'
                            || showCustom;

                    return showOptions && (
                        <Radio
                            key={`date-range-value-${value.id}`}
                            data-qa-key={value.id}
                            className={makeStrClassName(classes)}
                            checked={selected}
                            disabled={!enabled}
                            onChange={() => enabled ? this.onClickOnBtn(value.id) : undefined}
                        >
                            <span className="filter-label">
                                {value.label} {showCustom ? `(${getCustomTimeFrameLabel(currentSelection)})` : ''}
                            </span>
                            <span>{count}</span>
                        </Radio>
                    );
                })}
                {this.renderButtonCustom()}
                {this.renderDateRangeCustom()}
            </div>
        );
    }

}

DateRange.propTypes = {
    blockedEndDate   : PropTypes.instanceOf(Date),
    restrictedFilters: PropTypes.string,
    filterKey        : PropTypes.string.isRequired,
    filterType       : PropTypes.string,
    filterLabel      : PropTypes.string,
    learnKnowledge   : PropTypes.func.isRequired,
    minRange         : PropTypes.number,
    omitTimeframes   : PropTypes.arrayOf(PropTypes.string),
    onChange         : PropTypes.func.isRequired,
    value            : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    items            : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
    isFiltersLoading : PropTypes.bool,
};

DateRange.defaultProps = {
    omitTimeframes: [],
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(DateRange);
