/**
 * Get title for the search
 *
 * @returns string
 */
export const getSearchTitle = (search, prefix = 'Insights on ')  => {
    if (!search) {
        return '';
    }
    const { mode, concept } = search;

    if (mode !== 'smart') {
        return prefix + search.label;
    }

    // Create the concept content array
    const conceptsContents = concept
        .sort((concept) => (concept.operator === 'AND' ? 0 : 1))
        .map((conceptInput) => {
            const { customs, source } = conceptInput,
                hasCustomContent      = customs.length > 0,
                isNot                 = conceptInput.operator === 'NOT',
                customContents        = hasCustomContent
                    ? customs.join(', ')
                    : false,
                customString = customContents && ` (${isNot ? 'nor' : 'or'} ${customContents})`;

            return {
                concept: {
                    source,
                    isNot
                },
                customString
            };
        }).filter(conceptContent => !conceptContent.concept.source?.type);

    // Concat concepts
    let firstNot = true;
    const searchTitle = conceptsContents.reduce(
        (prev, curr, index) => {
            const { concept, customString } = curr,
                { isNot, source }           = concept,
                value =                     prev
                    + (index === 0 ? '' : (!isNot
                        ? (index !== conceptsContents.length - 1 ? ', ' : ' and also ')
                        : (firstNot ? ' but not mentioning ' : ' nor ')))
                    + source
                    + (customString || '');

            if (isNot && firstNot) {
                firstNot = false;
            }

            return value;
        },
        ''
    );

    return prefix + searchTitle;
};

export default { getSearchTitle };
