import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _                    from 'lodash';
import { Radio }            from 'antd';
import { capitalize }       from 'utils/text';

import './assets/period.less';

const periods = [
    {
        key  : 'week',
        label: 'Weekly Insight Feed',
        text : 'is good for me'
    },
    {
        key  : 'month',
        label: 'Monthly Insight Feed',
        text : 'is ok'
    },
];

/**
 * What's the proper perdiod to receive mails? step' 
 * 
 */
class Period extends Component {

    /**
     * Construct
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onChange');

        this.state = { };
    }


    /**
    * OnChange
    * 
    * @param {DomEvent} event 
    * 
    * @return void
    */
    onChange(event) {
        const { update, newsletter } = this.props,
            { target }               = event,
            { value }                = target,
            { dataset }              = target,
            { value: valueFromData } = dataset || {};

        update({
            ...newsletter,
            period: value || valueFromData
        });
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { newsletter, fromConfigure } = this.props,
            { period  }                     = newsletter,
            periodToIterate                 = period || 'week',
            classNames                      = ['period', 'setting'];

        if (fromConfigure) {
            classNames.push('from-configure');
        }

        return (
            <div className={classNames.join(' ')}>
                <div className="period-selectors">
                    {periods.map(period => (
                        <div key={period.key} className="period-selector">
                            <Radio
                                name="period"
                                value={period.key}
                                data-value={period.key}
                                checked={periodToIterate === period.key}
                                onChange={this.onChange}
                            />
                            <label data-value={period.key} onClick={this.onChange}>
                                {!fromConfigure ? period.label : capitalize(period.key)}
                            </label>
                            { !fromConfigure
                                && (
                                    <span className="text">
                                        {period.text}
                                    </span>
                                )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    
}

Period.propTypes = {
    fromConfigure: PropTypes.bool,
    newsletter   : PropTypes.object,
    update       : PropTypes.func
};
 
Period.definition = {
    key     : 'period',
    label   : 'Period',
    question: 'Are you on a weekly or monthly basis?',
    view    : 'Period',
};

export default Period;

