// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insight-stamp {
  font-weight: bold;
  font-size: 10px;
}
.insight-stamp .icon {
  margin: 0 5px 0;
  top: 2px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/assets/insight-search.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;AACJ;AAHA;EAKQ,eAAA;EACA,QAAA;EACA,kBAAA;AACR","sourcesContent":[".insight-stamp {\n    font-weight: bold;\n    font-size  : 10px;\n\n    .icon {\n        margin  : 0 5px 0;\n        top     : 2px;\n        position: relative;\n    }\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
