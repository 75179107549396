// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Double Radial Bar graph
*
*/
.DoubleRadialBar.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.DoubleRadialBar .small {
  color: inherit;
  font-size: 0.8em;
  margin-top: 5px;
}
.DoubleRadialBar .serie-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
}
.DoubleRadialBar .serie-label > b {
  color: inherit;
}
.DoubleRadialBar .best-acceleration {
  position: absolute;
  text-align: center;
}
.DoubleRadialBar .best-acceleration > span.text {
  vertical-align: middle;
  padding-left: 5px;
}
.DoubleRadialBar .best-acceleration > div.icon {
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/DoubleRadialBar/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAOG;EACI,mDAAA;AALR;AADA;EASQ,cAAA;EACA,gBAAA;EACA,eAAA;AALR;AANA;EAeQ,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AANR;AAZA;EAqBY,cAAA;AANZ;AAfA;EAyBQ,kBAAA;EACA,kBAAA;AAPR;AAnBA;EA6BY,sBAAA;EACA,iBAAA;AAPZ;AAvBA;EAiCY,sBAAA;AAPZ","sourcesContent":["/**\n* Double Radial Bar graph\n*\n*/\n\n.DoubleRadialBar {\n\n    svg {\n    }\n\n    &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n    .small {\n        color: inherit;\n        font-size : 0.8em;\n        margin-top: 5px;\n    }\n\n    .serie-label {\n        position      : absolute;\n        display       : flex;\n        flex-direction: column;\n        left          : 50%;\n\n        > b{\n            color: inherit;\n        }\n    }\n    .best-acceleration{\n        position  : absolute;\n        text-align: center;\n\n        > span.text {\n            vertical-align: middle;\n            padding-left  : 5px;\n        }\n        > div.icon {\n            vertical-align: middle;\n        }\n    }\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
