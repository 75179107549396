/**
 * Keep all actions types listed there
 *
 */

// Edit download
const DOWNLOADS_RESET_LIST     = 'DOWNLOADS_RESET_LIST';
const DOWNLOADS_NEXT_LIST      = 'DOWNLOADS_NEXT_LIST';
const DOWNLOADS_REMOVE         = 'DOWNLOADS_REMOVE';
const DOWNLOAD_UPDATE          = 'DOWNLOAD_UPDATE';
const DOWNLOADS_UPDATE_THUMBS  = 'DOWNLOADS_UPDATE_THUMBS';

export default {
    DOWNLOADS_RESET_LIST,
    DOWNLOADS_NEXT_LIST,
    DOWNLOADS_REMOVE,
    DOWNLOAD_UPDATE,
    DOWNLOADS_UPDATE_THUMBS,
};
