import React, { Component }          from 'react';
import PropTypes                     from 'prop-types';
import _                             from 'lodash';
import { Skeleton }                  from 'antd';
import { formatNumberWithMagnitude } from 'utils/text';
import { formatShortDate }           from 'utils/date';
import Collection                    from '../../Collection';

import './assets/corporate_activity_event.less';

/**
* Render CorporateActivityEvent entities
*
*/
class CorporateActivityEvent extends Component {

    /**
    * Returns whether the entity is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render loading patent row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the date for the current event
    *
    */
    renderDate() {
        const { entity } = this.props;

        return formatShortDate(new Date(entity.evtDate));
    }

    /**
    * Render the event type
    *
    */
    renderType() {
        const { entity } = this.props;

        return _.startCase(entity.evtType);
    }

    /**
    * Render the event Authorities
    *
    */
    renderAuthorities() {
        const { entity, onClick } = this.props;

        if (!entity.authority.length) {
            return false;
        }

        return (
            <Collection
                entitySource={entity}
                entities={entity.authority}
                type="orgunit"
                mode="inline"
                allowExport={false}
                onClick={onClick}
            />
        );
    }

    /**
    * Render the event Authorities
    *
    */
    renderAmount() {
        const { entity } = this.props;

        if (!entity.amount) {
            return false;
        }

        return `$${formatNumberWithMagnitude(entity.amount)}`;
    }

    /**
    * Render the patent row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        return (
            <>
                <td className="date">{this.renderDate()}</td>
                <td className="event">{this.renderType()}</td>
                <td className="authorities">{this.renderAuthorities()}</td>
                <td className="amount">{this.renderAmount()}</td>
            </>
        );
    }

}

CorporateActivityEvent.propTypes = {
    entity: PropTypes.shape({
        amount   : PropTypes.any,
        authority: PropTypes.array,
        evtDate  : PropTypes.any,
        evtType  : PropTypes.any,
        isLoading: PropTypes.bool,
    }),
    onClick: PropTypes.func
};

export default CorporateActivityEvent;

