// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
*/
.metrics-tester {
  background: #ffffff;
}
.metrics-tester .metric {
  height: auto !important;
}
.metrics-tester .Graph,
.metrics-tester .metric > div {
  box-sizing: content-box;
  box-shadow: 10px 5px 5px #888;
  border: solid 1px #aaa;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/MetricsTester/main.less"],"names":[],"mappings":"AAAA;CACC;AACD;EACI,mBAAA;AACJ;AAFA;EAGQ,uBAAA;AAER;AALA;;EAMQ,uBAAA;EACA,6BAAA;EACA,sBAAA;EACA,YAAA;AAGR","sourcesContent":["/**\n*/\n.metrics-tester  {\n    background: #ffffff;\n    .metric {\n        height: auto !important;\n    }\n    .Graph, .metric > div {\n        box-sizing: content-box;\n        box-shadow: 10px 5px 5px #888;\n        border: solid 1px #aaa;\n        margin: 10px;\n    }\n    svg {\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
