import _                            from 'lodash';
import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { fetchPlatformsTokens }     from 'store/actions/auth/platforms';
import { getAccessToken }          from 'store/actions/auth';
import { connect }                  from 'react-redux';
import { Modal, Button }            from 'antd';
import { CssLoader }                from 'helpers';

// CSS
import './assets/Login/main.less';
/**
* Manage to send export yoomap
*
*/
class YoomapLogin extends Component  {

    /**
    * Instanciate the modal
    */
    constructor(props) {
        super(props);

        this.state = {};

        this.tokenCallInterval=null;

        _.bindAll(this, 'tokenTest', 'openYoomapLoginPopup');
    }

    /**
     * Call when the component mount
     *
     * @returns void
     */
    componentDidMount() {
        this.getTicket();
    }

    /**
    * Call for get the ticket
    */
    getTicket() {
        const ticket= getAccessToken();

        this.setState({
            ticket
        }, () => {
            this.tokenTest();
        });
    }


    /**
     * Call when the component will unmount
     *
     * @returns void
     */
    componentWillUnmount() {
        clearInterval(this.tokenCallInterval);
    }

    /**
    * Call for test the token
    */
    tokenTest() {
        const { platform, fetchPlatformsTokens } = this.props,
            { url, token }                       = platform;

        if(this.tokenCallInterval) {
            clearInterval(this.tokenCallInterval);
        }
        if(token || !url) {
            return;
        }

        fetchPlatformsTokens();

        this.tokenCallInterval = setInterval(() => {
            fetchPlatformsTokens();
        }, 10000);
    }


    /**
     * Open Yoomap popup
     *
     */
    openYoomapLoginPopup() {
        const { platform, openYoomapLoginPopupCb } = this.props;

        openYoomapLoginPopupCb(platform);
    }

    /**
    * Render the report
    *
    * @return Component
    */
    render() {
        const { platform,
                onClose,
            }              = this.props,
            { url, token } = platform;

        return (
            <Modal
                title="Send to my other innosabi app"
                className="yoomap-login"
                style={{top: 60}}
                width={600}
                open={!!((url  && !token))}
                onCancel={onClose}
                footer={null}
                maskClosable={false}
            >
                <p>
                    <span>
                        To access this service you need to login on&nbsp;
                    </span>
                    <a href={url} target="blank"
                        className="platform-url"
                    >
                        {url}
                    </a>
                </p>
                <div className="authentication-waiting">
                    <CssLoader type="ring" size={12}
                        thickness={2} color="#dddddd"
                    />
                    Waiting for authentication.
                </div>
                <br />
                <Button type="primary"
                    onClick={this.openYoomapLoginPopup}
                >
                    Login
                </Button>
            </Modal>
        );
    }

}

YoomapLogin.propTypes = {
    platform              : PropTypes.object,
    fetchPlatformsTokens  : PropTypes.func,
    onClose               : PropTypes.func,
    openYoomapLoginPopupCb: PropTypes.func,
};

YoomapLogin.defaultProps = {
    platform            : {},
    fetchPlatformsTokens: () => { },
    onClose             : () => {}
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(null, {
    fetchPlatformsTokens
})(YoomapLogin);

