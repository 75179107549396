// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.references .more {
  cursor: pointer;
  width: 100%;
  text-align: right;
  display: block;
}
.references .line {
  margin-top: 5px;
  margin-bottom: 10px;
}
.references .line:last-child {
  margin-bottom: 5px;
}
.references .line .label .collection {
  width: 100%;
}
.references .line .label .collection .entity-inline,
.references .line .label .collection .entity-inline span {
  width: 100%;
  line-height: 13px;
  display: inline-block;
}
.references .line .label .collection .label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.references .line .type,
.references .line .year {
  display: inline-block;
  color: #888;
}
.references .line .year {
  position: absolute;
  right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Element/Area/Entity/assets/references.less"],"names":[],"mappings":"AAAA;EAEQ,eAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;AAAR;AALA;EASQ,eAAA;EACA,mBAAA;AADR;AAGQ;EACI,kBAAA;AADZ;AAZA;EAkBY,WAAA;AAHZ;AAfA;;EAqBgB,WAAA;EACA,iBAAA;EACA,qBAAA;AAFhB;AArBA;EA2BgB,qBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHhB;AA3BA;;EAoCY,qBAAA;EACA,WAAA;AALZ;AAhCA;EAyCY,kBAAA;EACA,UAAA;AANZ","sourcesContent":[".references {\n    .more {\n        cursor: pointer;\n        width: 100%;\n        text-align: right;\n        display: block;\n    }\n\n    .line {\n        margin-top: 5px;\n        margin-bottom: 10px;\n\n        &:last-child {\n            margin-bottom: 5px;\n        }\n\n        .label .collection {\n\n            width: 100%;\n\n            .entity-inline, .entity-inline span {\n                width: 100%;\n                line-height: 13px;\n                display: inline-block;\n            }\n\n            .label {\n                display: inline-block;\n                white-space: nowrap;\n                overflow: hidden;\n                text-overflow: ellipsis;\n            }\n\n        }\n\n        .type, .year {\n            display: inline-block;\n            color: #888;\n        }\n\n        .year {\n            position: absolute;\n            right: 0px;\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
