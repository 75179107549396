import PropTypes                from 'prop-types';
import React, { Component }     from 'react';
import _                        from 'lodash';
import { Skeleton, Slider }     from 'antd';
import { IButton }              from 'helpers';

/**
 *  Component render 2 int picker to chose a range
 */
class IntRange extends Component {

    /**
     * Contruct
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.state = {
            rangeStart: 0,
            rangeEnd  : 0
        };

        _.bindAll(this, 'onReset', 'getRangeFromString',
            'changeRangeStart', 'changeRangeEnd', 'rangeInit', 'keyDownRange');
    }

    /**
     * Call whe hte component mount
     * @returns
     */
    componentDidMount() {
        this.rangeInit();
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { value, items }       = this.props,
            { rangeStart, rangeEnd } = this.state;

        if (!rangeStart && !rangeEnd || items !== prevProps.items || value !== prevProps.value) {
            this.rangeInit();
        }
    }

    /**
     * Init the range value
     * @returns
     */
    rangeInit() {
        const {
            rangeStart,
            rangeEnd
        } = this.state;

        const { value, items }     = this.props,
            { selectedId = value } = this.props,
            start                  = items ? items[0].value : 0,
            end                    = items ? items[1].value : 0,
            rangeValue             = selectedId && selectedId !== 'tfcustom' ? selectedId : value,
            extractValue           = rangeValue ? this.getRangeFromString(selectedId) : [start, end],
            realCustomValue        = extractValue
                ? [extractValue[0], extractValue[1]]
                : [start, end];

        if(rangeStart !== 0 && rangeEnd !== 0 && value) {
            return;
        }

        if(rangeStart === realCustomValue[0] &&  rangeEnd === realCustomValue[1]) {
            return;
        }
        this.setState({
            rangeStart: realCustomValue[0] || start,
            rangeEnd  : realCustomValue[1] || end
        });
    }

    /**
    * Get min and max range
    */
    getRangeFromString(rangeString) {
        const customExtract = /custom_(\d*)_(\d*)$/.exec(rangeString);

        // Is a custom range
        if (_.isArray(customExtract) && customExtract.length === 3) {
            return [parseInt(customExtract[1]), parseInt(customExtract[2])];
        }
    }

    /**
    * On apply button  click
    * Triggering the onChange callback
    */
    onApply() {
        const { onChange, filterKey } = this.props,
            { rangeStart, rangeEnd }  = this.state,
            defaultRange              = `custom_${rangeStart}_${rangeEnd}`;

        onChange(filterKey, defaultRange);
    }


    /**
    * On Reset button  click
    * Triggering the onChange callback
    */
    onReset() {
        const { onChange, filterKey, items } = this.props;

        this.setState({
            selectedId: null,
            rangeStart: items[0].value,
            rangeEnd  : items[1].value,
        });

        onChange(filterKey, '');
    }

    /**
    * On click on enter
    * Triggering the onKeyDown callback
    */
    keyDownRange(e) {
        // Apply on press enter
        if (e.keyCode === 13) {
            this.onApply();
        }

        return true;
    }

    /**
     * Call when the input value changed
     *
     * @param {Object} e
    */
    changeRangeStart(e) {
        const { rangeEnd } = this.state,
            { value }      = e.target,
            newValue       = value < rangeEnd ? value : rangeEnd;

        this.setState({ rangeStart: newValue });
    }


    /**
     * Call when the input value changed
     *
     * @param {Object} e
    */
    changeRangeEnd(e) {
        const { rangeStart } = this.state,
            { value }      = e.target,
            newValue       = value > rangeStart ? value : rangeStart;

        this.setState({ rangeEnd: newValue });
    }


    /**
     * Render skeleton
     */
    renderSkeleton() {
        return (
            <div className="filter-range skeleton">
                <div className="custom-range">
                    <Skeleton className="ant-slider"
                        paragraph={null} active
                    />
                    <div className="buttons">
                        <Skeleton.Button block active />
                        <Skeleton.Button block active />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Render compoment buttons
     */
    renderButtons(resetDisabled = false) {
        return (
            <div className="buttons">
                <IButton
                    label="Reset"
                    fontSize={12}
                    className="active reset"
                    onClick={() => this.onReset()}
                    disabled={resetDisabled}
                />
                <IButton
                    label="Apply"
                    fontSize={12}
                    className="active apply"
                    bgColor="#10285D"
                    labelColor="#FFFFFF" hoverBgColor="#58698E"
                    onClick={() => this.onApply()}
                />
            </div>
        );
    }

    // TODO: Make one component IntRange <---> DateRange
    /**
     * Render the Component
     */
    render() { // eslint-disable-line  max-lines-per-function
        const { items }  = this.props,
            {
                rangeStart,
                rangeEnd
            }             = this.state,
            start         = items ? items[0].value : 0,
            end           = items ? items[1].value : 0,
            rangeLength   = end - start,
            range         = rangeEnd - rangeStart,
            textPosition  = Math.round(100 * (rangeStart - start + range / 2) / rangeLength),
            slideDisabled = start===end,
            resetDisabled = (rangeStart == start && rangeEnd == end);

        if (start===0) {
            return this.renderSkeleton();
        }

        return (
            <div className="filter-range">
                <div className="custom-range">
                    <Slider
                        key={JSON.stringify([start, end])}
                        range
                        min={start}
                        max={end}
                        value={[rangeStart, rangeEnd]}
                        disabled={slideDisabled}
                        onChange={(newValue) => {
                            const [min, max] = newValue;
                            this.setState({
                                rangeStart: min,
                                rangeEnd  : max
                            });
                        }}
                    />
                    <div className="range-container">
                        <span className="range" style={{ left: `${textPosition}%` }}>
                            <span className="range-content">
                                <input
                                    className="rangeStart"
                                    type="number"
                                    style={{width: (end.toString().length)*9 + 'px'}}
                                    onKeyDown={this.keyDownRange}
                                    onChange={this.changeRangeStart}
                                    value={rangeStart}
                                />
                                &nbsp;-&nbsp;
                                <input
                                    className="rangeEnd"
                                    type="number"
                                    style={{width: (end.toString().length)*9 + 'px'}}
                                    onKeyDown={this.keyDownRange}
                                    onChange={this.changeRangeEnd}
                                    value={rangeEnd}
                                />
                            </span>
                        </span>
                    </div>
                    {
                        !slideDisabled
                            ? this.renderButtons(resetDisabled)
                            : (
                                <div className="range-text">
                                    Max and min data are the same: range can’t be used.
                                </div>
                            )
                    }
                </div>
            </div>
        );
    }

}

IntRange.propTypes = {
    filterKey : PropTypes.string,
    onChange  : PropTypes.func,
    selectedId: PropTypes.shape({
        indexOf: PropTypes.func
    }),
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default IntRange;
