import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';
import { Radio }            from 'antd';
import Graph                from '../Graph';
import CustomCountriesModal from './CustomCountriesModal';
import { learn }            from '../../store/actions/knowledge';
import { IButton }          from 'helpers';
import { makeStrClassName } from 'utils/text';
import { requestTimeout }   from 'utils/requestTimeout';

import './assets/market.less';

/**
 * Market filter
 */
class Market extends Component {

    /**
    * Constructor, setting up the state.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClickOnButton', 'onCloseCustomModal');

        this.state = {
            // Modal for custom countries
            modalIsOpen         : false,
            // Store current selected ids here
            selectedIds         : [],
            // Store knowledge there
            countries           : null,
            continents          : null,
            markets             : null,
            internationalOffices: null,
            // Pre-compile some other knowledges
            marketsEntitiesIds  : null,
        };
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        const { value }       = this.props,
            { learnKnowledge } = this.props;

        this.setState({
            selectedIds: value || []
        });

        // Learn all needed Knowledges, then fill the state with collections
        learnKnowledge(['countries', 'continents', 'markets', 'international-offices'])
            .then((bookcase) => this.populateKnowledgesToState(bookcase));
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { value }     = this.props,
            { selectedIds } = this.state;

        if(_.isEmpty(value) && prevProps.value.length > 0) {
            this.setState({ selectedIds: [] });
        }

        // Put selected values from props
        if(!_.isEmpty(value) && _.isEmpty(selectedIds)) {
            this.setState({ selectedIds: value });
        }
    }

    /**
    * Event when the user clicks a button.
    *
    * @return void
    */
    onClickOnButton(id) {
        const { marketsEntitiesIds } = this.state;
        if (id === 'custom') {
            return this.openModal();
        }
        this.updateValue(marketsEntitiesIds[id]);
    }

    /**
     * Open Custom modal
     */
    openModal() {
        this.setState({ modalIsOpen: true });
    }

    /**
    * Event when close custom countries modal
    *
    * @return void
    */
    onCloseCustomModal(options) {
        const { selectedIds } = options || {};

        this.setState({ modalIsOpen: false });

        if (!_.isUndefined(selectedIds)) {
            this.updateValue(selectedIds);
        }
    }


    /**
    * Get market id from countries id list (selected or restricted)
    */
    getMarketFromCountriesId(countriesId) {
        const {
            markets,
            countries,
            marketsEntitiesIds,
            internationalOffices,
        } = this.state;

        // We depends on markets & countries here
        if (!markets || !countries || !internationalOffices) {
            return false;
        }

        return _.findKey(marketsEntitiesIds, ids => {
            const countriesInCommon =  _.intersection(countriesId, ids).length,
                defaultCase         = countriesInCommon === ids.length && countriesInCommon === countriesId?.length;

            return defaultCase;
        });
    }

    /**
    * Extract selected ids from markets state then create a worldmap serie
    *
    * @return array
    */
    getSelectedIdsForWorldmap(selectedMarket) {
        const {
                countries,
                selectedIds
            }               = this.state,
            allCountriesIds = countries.map((country) => country.id).toArray();

        return _.map(
            selectedMarket === 'world' ? allCountriesIds : selectedIds,
            (country) => ({ id: country, value: '1' })
        );
    }

    /**
    * Extract/Compile then add knowledges to the current state
    *
    * @return void
    */
    populateKnowledgesToState(bookcase) {
        const nextState = {
                ...bookcase,
                marketsEntitiesIds: {},
            },
            { markets }    = bookcase,
            marketsAsArray = markets.toArray();

        _.each(marketsAsArray, ({ id: marketId }) => {
            const entitiesIds = [];
            if (marketId === 'custom') { return; }
            // Populate next state with countries ids & offices ids
            nextState.marketsEntitiesIds[marketId] = entitiesIds.concat(
                bookcase.countries.filter(
                    ({ market:countryMarkets }) => countryMarkets && _.includes(countryMarkets, marketId)
                ).map(({ id }) => id).toArray()
            )
                .concat(
                    bookcase.internationalOffices.filter(
                        ({ market:officeMarkets }) => officeMarkets && _.includes(officeMarkets, marketId)
                    ).map(({ id }) => id).toArray()
                );
        });

        this.setState(nextState);
    }

    /**
    * Event when the user clicks a button.
    */
    updateValue(selectedIds) {
        const { onChange, filterKey } = this.props;

        this.setState({
            selectedIds
        });

        requestTimeout(
            () =>  onChange(filterKey, selectedIds),
            100
        );

        return false;
    }

    /**
     * Determine if market is disabled
     *
     * @param {string} id
     *
     * @returns
     */
    marketIsDisabled(id) {
        const { restrictedFilters } = this.props,
            authorizedMarket        = this.getMarketFromCountriesId(restrictedFilters) || 'custom';

        if (id === 'custom' || !restrictedFilters) {
            return false;
        }

        return authorizedMarket ? id !== authorizedMarket : false;
    }

    /**
    * Render custom countries selector modal
    *
    * @return JSX
    */
    renderCustomModal() {
        const { selectedIds }            = this.state,
            { restrictedFilters, items } = this.props;

        return (
            <CustomCountriesModal
                onCloseModal={this.onCloseCustomModal} selectedIds={selectedIds}
                restrictedFilters={restrictedFilters} items={items}
            />
        );
    }

    /**
    * Render the GeoMap
    *
    * @return JSX
    */
    renderGeoMap() {
        const { selectedIds } = this.state,
            { colors }        = this.props,
            selectedMarket    = this.getMarketFromCountriesId(selectedIds) || 'custom';

        return (
            <div className="geomap">
                <Graph
                    type="GeoMap"
                    dataIsLoaded
                    data={{
                        content: this.getSelectedIdsForWorldmap(selectedMarket)
                    }}
                    width={260}
                    height={150}
                    color={colors.active}
                    fontSize={16}
                    noLabel
                />
            </div>
        );
    }

    /**
    * Render the Worldmap filter
    *
    * @return JSX
    */
    render() {
        const {
                markets, modalIsOpen,
                selectedIds,
            }              = this.state,
            {
                showGeoMap,
                isFiltersLoading
            }              = this.props,
            selectedMarket = this.getMarketFromCountriesId(selectedIds) || 'custom',
            classNames     = ['grouped-list', 'filter-market', {'is-loading': isFiltersLoading}],
            className      = makeStrClassName(classNames);

        // We depends on markets & countries to obtain the selected market
        if (!markets || !selectedMarket) {
            return false;
        }

        return (
            <div className={className}>
                {markets.sortBy((m) => m.order)
                    .map(({ label, id }) =>  {
                        const checked = id === selectedMarket,
                            show      = id !== 'custom' || checked;

                        return show && (
                            <div key={`market-group-${id}`} className="group-filter">
                                <div className="group-label">
                                    <Radio
                                        key={`market-filter-${id}`}  className="value"
                                        value={id} checked={checked}
                                        onChange={() => this.onClickOnButton(id)}
                                        disabled={this.marketIsDisabled(id)}
                                    >
                                        <span className="filter-label" title={label}>
                                            {label}
                                        </span>
                                    </Radio>
                                </div>
                            </div>
                        );
                    })}
                <IButton
                    label="Custom Market"
                    fontSize={12}
                    icon="actions/filter" iconColor="#B7B7B7"
                    hoverBgColor="#f8f8f8" hoverLabelColor="#10285D"
                    onClick={() => this.openModal()}
                />
                {showGeoMap  && this.renderGeoMap()}
                {modalIsOpen && this.renderCustomModal()}
            </div>
        );
    }

}

Market.propTypes = {
    filterKey        : PropTypes.string.isRequired,
    learnKnowledge   : PropTypes.func.isRequired,
    onChange         : PropTypes.func,
    isFiltersLoading : PropTypes.bool,
    showGeoMap       : PropTypes.bool,
    items            : PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    restrictedFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    value            : PropTypes.arrayOf(PropTypes.string).isRequired,
    colors           : PropTypes.shape({
        active: PropTypes.string,
    }),
};

Market.defaultProps = {
    onChange  : () => {},
    showGeoMap: true,
    colors    : {
        active: 'var(--primary-color)',
    }
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Market);
