/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                             from 'lodash';
import React, { Component }          from 'react';
import PropTypes                     from 'prop-types';
import { ToggleChildren, Icon }      from 'helpers';
import {
    Checkbox,
    Popover,
    Switch,
}                                    from 'antd';
import { connect }                   from 'react-redux';

import { dataPost }                  from 'utils/api';
import { htmlize, makeStrClassName } from 'utils/text';
import { updateMemberSettings }      from 'store/actions/auth';

import ModelAction                   from 'helpers/Action/Model';
import Autocomplete                  from '../Autocomplete';
import Input                         from './Smart/Input';
import Sentence                      from './Smart/Sentence';
import AddCustom                     from './Smart/Input/AddCustom';
import SmartFilter                   from './Smart/Filters';

// A import BookmarksRenderer                   from 'helpers/Renderer/Bookmarks';

import './assets/smart.less';

/**
* Render The "Smart" menu
*/
class Smart extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        _.bindAll(this, 'onSelectFromAutocomplete', 'companiesInputsCount', 'renderAutocompleteSuffix', 'hasValue',
            'addEntityToInputs', 'removeInput', 'toggleSyntaxic', 'getParametersToSubmit', 'renderActions',
            'registerAutocompleteCallbacks', 'toggleExpand', 'autocomplete', 'onSubmit', 'getConceptsToSubmit',
            'upInput', 'downInput', 'updateInput', 'toggleAddCustom', 'toggleAddSuggestion', 'onUpdateInputs',
            'onChangeFilter', 'onCancel'
        );

        this.state = {
            inputs               : [],
            autocompleteCallbacks: {},
            enabledAddCustom     : null,
            enabledAddSuggestion : null,
            searchCanBeLaunched  : false,
            syntaxic             : {
                enabled    : false,
                value      : false,
                mustBeFetch: true,
            },
            filters: {},
        };

        this.ref = React.createRef();
    }

    /**
    * Component did mont
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks } = this.props;

        registerCallbacks('submit', this.getParametersToSubmit);
        registerCallbacks('has-value', this.hasValue);
    }

    /**
    * Side effects
    *
    * @return void
    */
    componentDidUpdate() {
        this.refreshSyntaxic();
    }

    /**
    * Create the state from properties
    *
    * @return object
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { searchSettings } = nextProps,
            { boost_is_enabled } = searchSettings,
            entity               = nextProps.search,
            isNewSearch          = !entity,
            {
                expand,
                dateFilter,
                countryFilter,
                bookmarkFolderForTags,
            }                    = entity?.settings || {},
            searchHasExpand      = !_.isUndefined(expand);

        if (!prevState.edited && nextProps.value.length > 0 && prevState.inputs.length === 0) {
            prevState.inputs = nextProps.value.map((input) => {
                input.id = _.uniqueId('input');
                return input;
            }) || [];
        }

        // Manage expand (boost) search
        prevState.expand = prevState.edited
            ? prevState.expand
            : searchHasExpand
                ? expand
                : isNewSearch
                    ? (_.isUndefined(boost_is_enabled) ? true : boost_is_enabled)
                    : undefined;

        if (!isNewSearch && !prevState.edited) {
            prevState.filters = {
                ...(!_.isEmpty(dateFilter) ? { dateFilter } : {}),
                ...(!_.isEmpty(countryFilter) ? { countryFilter } : {}),
                ...(!_.isEmpty(bookmarkFolderForTags) ? { bookmarkFolderForTags } : {}),
            };
        }

        return prevState;
    }

    /**
    * Check if the smart search have some value
    *
    * @return bool
    */
    hasValue() {
        const { inputs } = this.state;

        return inputs.length > 0 || this.autocomplete('has-value');
    }

    /**
    * Search for orgunits in inputs, then return them
    *
    * @return
    */
    getOrgunitsFromInputs() {
        const { inputs }   = this.state,
            orgunitsInputs = inputs.filter((input) => input.type === 'orgunit');

        return orgunitsInputs.reduce((orgunits, input) => orgunits.concat([input.source], input.customs), []);
    }

    /**
    * On submit smart search
    *
    * @return JSX
    */
    onSubmit() {
        const { onSubmit, openOrgunitsCollection } = this.props,
            { inputs }                             = this.state,
            conceptsInputs                         = inputs.filter((input) => input.type === 'concept'),
            orgunitsInputs                         = inputs.filter((input) => input.type === 'orgunit');

        if (!conceptsInputs.length && orgunitsInputs.length) {
            openOrgunitsCollection(this.getOrgunitsFromInputs());
            return;
        }

        if (onSubmit) {
            onSubmit(this.getParametersToSubmit());
        }
    }

    /**
    * On cancel smart search
    */
    onCancel() {
        this.setState({ inputs: [] });
    }

    /**
    * Create a submitable parameters array
    *
    * @return object
    */
    getParametersToSubmit() {
        const {
                expand, filters,
            }          = this.state,
            parameters = {
                concept : this.getConceptsToSubmit(),
                mode    : 'smart',
                settings: {
                    expand,
                    ...filters,
                }
            };

        return parameters;
    }

    /**
    * Get concepts to submit
    *
    * return array
    */
    getConceptsToSubmit() {
        const { inputs } = this.state;

        return inputs.map((input) => {
            const {
                    type, source, operator, customs, suggestions
                } = input,
                isConcept = type === 'concept';

            return {
                type,
                operator,
                source     : isConcept ? source : source.id,
                customs    : customs.map((custom) => (isConcept ? custom : custom.id)),
                suggestions: suggestions.map((suggestion) => (isConcept ? suggestion : suggestion.id))
            };
        });
    }

    /**
    * On select and entity (concept, orgunit) from autocomplete
    *
    * @param object|string The new entity to add as input
    *
    * @return void
    */
    onSelectFromAutocomplete(entity) {
        // Add the entity to the inputs
        this.addEntityToInputs(entity);

        // Set the syntaxic value to false
        this.resetSyntaxic();

        // Reset the autocomplete
        this.autocomplete('reset');
    }

    /**
    * Reset the syntaxic value
    *
    * @return void
    */
    resetSyntaxic() {
        const { syntaxic } = this.state;

        this.setState({
            syntaxic: {
                ...syntaxic,
                mustBeFetch: true,
            }
        });
    }

    /**
    * Trigger an action on autocomplete
    *
    * @param string action The callback to trigger
    *
    * @return void
    */
    autocomplete(action) {
        const { autocompleteCallbacks, inputs } = this.state,
            componentKey                        = inputs.length > 1 ? 'bottom' : 'top',
            componentCallBack                   = autocompleteCallbacks && autocompleteCallbacks[componentKey];

        if (componentCallBack && componentCallBack[action]) {
            return componentCallBack[action]();
        }

        return null;
    }

    /**
    * Register the callback to allow reset the autocomplete
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerAutocompleteCallbacks(componentKey) {
        return (action, cb) => {
            const { autocompleteCallbacks } = this.state,
                /** Return empty object */
                defaultCb = () => {};

            if (!autocompleteCallbacks[componentKey]) {
                autocompleteCallbacks[componentKey] = {};
            }

            autocompleteCallbacks[componentKey][action] = cb || defaultCb;

            this.setState({ autocompleteCallbacks });
        };
    }

    /**
    * Create a new input object, then add the entity inside
    *
    * @param object|string The new entity to add as input
    *
    * @return void
    */
    addEntityToInputs(entity) {
        const { inputs } = this.state,
            newInput     = {
                id         : _.uniqueId('input'),
                type       : entity.type || 'concept',
                source     : entity,
                operator   : 'AND',
                suggestions: [],
                customs    : []
            };

        inputs.push(newInput);

        this.setState({
            edited: true,
            inputs
        });

        this.onUpdateInputs();
    }

    /**
     * Is search can be launched ?
     *
     * @return bool
     */
    searchCanBeLaunched() {
        const { mustHaveOneConcept } = this.props,
            containsAtLeastAConcept  = this.containsAtLeastAConcept(),
            companiesInputsCount     = this.companiesInputsCount(),
            isNotEmpty               = containsAtLeastAConcept || companiesInputsCount > 0;

        if (mustHaveOneConcept) {
            return containsAtLeastAConcept;
        }

        return isNotEmpty;
    }

    /**
     * Trigger when inputs are updated
     *
     * @return void
     */
    onUpdateInputs() {
        const { onUpdateInputs } = this.props,
            { inputs }           = this.state;

        onUpdateInputs({
            inputs,
            searchCanBeLaunched: this.searchCanBeLaunched()
        });

        // Refresh syntaxic
        this.resetSyntaxic();
    }

    /**
    * Toggle add custom input
    *
    * @return void
    */
    toggleAddCustom(data) {
        this.setState({ enabledAddCustom: data });
    }

    /**
    * Toggle add suggestion
    *
    * @return void
    */
    toggleAddSuggestion(newData) {
        this.setState({ enabledAddSuggestion: newData });
    }

    /**
    * Update input in inputs array
    *
    * @param object The updated data
    *
    * @return void
    */
    updateInput(freshData) {
        const { inputs } = this.state,
            inputIndex = inputs.findIndex((input) => input.id === freshData.id);

        if (_.isUndefined(inputIndex) || inputIndex === -1) {
            return;
        }

        inputs[inputIndex] = freshData;

        // Force new object to rerender.
        this.setState({
            edited: true,
            inputs: _.clone(inputs)
        });

        this.onUpdateInputs();
    }

    /**
    * Remove an input from the input array
    *
    * @param object The input to remove
    *
    * @return void
    */
    removeInput(inputToDelete) {
        const { inputs } = this.state,
            newInputs    = inputs.filter((input) => input.id !== inputToDelete.id);

        // Force new object to rerender.
        this.setState({
            edited: true,
            inputs: newInputs
        }, () => this.onUpdateInputs()); // Trigger on callback to be sure the state is updated
    }

    /**
    * Up the input in the list
    *
    * @param object The input to remove
    *
    * @return void
    */
    upInput(inputToUp) {
        const { inputs } = this.state,
            inputIndex   = inputs.findIndex((input) => input.id === inputToUp.id),
            dataToSwitch = inputs[inputIndex - 1];

        inputs[inputIndex]   = dataToSwitch;
        inputs[inputIndex - 1] = inputToUp;

        // Force new object to rerender.
        this.setState({ inputs });
    }

    /**
    * Is there some companies ?
    *
    * @return bool
    */
    companiesInputsCount() {
        const { inputs }    = this.state,
            companiesInputs = inputs.filter((input) => input.type === 'orgunit');

        return companiesInputs.length;
    }

    /**
    * Is there some concepts ?
    *
    * @return bool
    */
    conceptsInputsCount() {
        const { inputs }   = this.state,
            conceptsInputs = inputs.filter((input) => input.type === 'concept');

        return conceptsInputs.length;
    }

    /**
    * Down the input in the list
    *
    * @param object The input to remove
    *
    * @return void
    */
    downInput(inputToDown) {
        const { inputs } = this.state,
            inputIndex   = inputs.findIndex((input) => input.id === inputToDown.id),
            dataToSwitch = inputs[inputIndex + 1];

        inputs[inputIndex]   = dataToSwitch;
        inputs[inputIndex + 1] = inputToDown;

        // Force new object to rerender.
        this.setState({ inputs });
    }

    /**
    * Render operator
    *
    * @return JSX
    */
    renderInputActions(input) {
        return (
            <div className="actions" onClick={() => this.removeInput(input)}>
                <Icon type="delete" className="delete"
                    height={14}
                />
            </div>
        );
    }

    /**
    * Render operator
    *
    * @return JSX
    */
    renderAddCustom(input) {
        const data = input.type === 'orgunit'
            ? _.cloneDeep(input)
            : input;

        return (
            <AddCustom
                data={data}
                update={this.updateInput}
            />
        );
    }

    /**
    * Render the input list from state
    *
    * return JSX
    */
    renderInputs() {
        const {
                inputs, enabledAddCustom,
                enabledAddSuggestion,
            }                    = this.state,
            conceptsInputsCount  = this.conceptsInputsCount(),
            companiesInputsCount = this.companiesInputsCount();

        return (
            <ToggleChildren title="Input" open
                suffix={this.renderBoostCheckbox()}
            >
                <div className="inputs">
                    {inputs.map(input => {
                        const openAddCustom = enabledAddCustom ? enabledAddCustom.id === input.id : false,
                            classNames      = [
                                'input',
                                { 'add-custom-deployed': openAddCustom },
                            ];

                        return (
                            <div className="input-container">
                                <div className={makeStrClassName(classNames)} ref={this.inputRef}>
                                    <Input
                                        key={JSON.stringify(_.omit(input, 'id'))}
                                        data={_.cloneDeep(input)}
                                        up={this.upInput}
                                        down={this.downInput}
                                        update={this.updateInput}
                                        remove={this.removeInput}
                                        openAddCustom={enabledAddCustom ? enabledAddCustom.id === input.id : false}
                                        openAddSuggestion={enabledAddSuggestion ? enabledAddSuggestion.id === input.id : false}
                                        toggleAddCustom={this.toggleAddCustom}
                                        toggleAddSuggestion={this.toggleAddSuggestion}
                                        conceptsInputsCount={conceptsInputsCount}
                                        companiesInputsCount={companiesInputsCount}
                                        inputRef={this.inputRef}
                                    />
                                    {this.renderAddCustom(input)}
                                </div>
                                {this.renderInputActions(input)}
                            </div>
                        );
                    })}
                    {this.renderConceptAutocomplete()}
                </div>
            </ToggleChildren>
        );
    }

    /**
    * Render the autocomplete suffix
    *
    * @return JSX
    */
    renderAutocompleteSuffix() {
        const { switcher } = this.props;

        return (
            <>
                {switcher}
            </>
        );
    }

    /**
    * Render actions of the orgunit
    *
    */
    renderActions(entity) {
        return (
            <ModelAction key={`model-action-${entity.id}`} entity={entity} />
        );
    }

    /**
    * Render the autocomplete
    *
    * @return JSX
    */
    renderAutocomplete() {
        const {
                mustHaveOneConcept
            }           = this.props,
            basicPh     = 'Enter concept (biomarker, autonomous vehicle,...) or organization (Facebook, Samsung,...)',
            monitorPh   = basicPh.concat('. ', 'Organization must be added with at least one concept.'),
            placeholder = mustHaveOneConcept ? monitorPh : basicPh,
            disabled    = this.containsAtLeastAConcept() || this.companiesInputsCount() > 0;

        return (
            <Autocomplete
                types={['concept', 'orgunit']}
                onSubmit={this.onSubmit}
                onSelect={this.onSelectFromAutocomplete}
                renderInputSuffix={this.renderAutocompleteSuffix}
                renderSuffix={this.renderActions}
                registerCallbacks={this.registerAutocompleteCallbacks('top')}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    }

    /**
    * Render the concept autocomplete
    *
    * @return JSX
    */
    renderConceptAutocomplete() {
        return (
            <Autocomplete
                types={['concept', 'orgunit']}
                onSubmit={this.onSubmit}
                onSelect={this.onSelectFromAutocomplete}
                registerCallbacks={this.registerAutocompleteCallbacks('bottom')}
                placeholder="Add a new concept"
                autoFocus
            />
        );
    }

    /**
    * Render the submit button
    *
    * @return JSX
    */
    renderSubmit() {
        const { onSubmit } = this.props,
            { inputs }     = this.state;

        if (!inputs.length || !onSubmit) {
            return false;
        }

        return (
            <div className="search-actions">
                <div className="cancel" onClick={this.onCancel}>
                    Cancel my search
                </div>

                <div className="submit" onClick={this.onSubmit}>
                    {this.containsAtLeastAConcept()
                        ? 'Launch my search'
                        : `${this.companiesInputsCount() > 1
                            ? 'View profiles!' : 'View profile!'}`}
                </div>
            </div>
        );
    }

    /**
     * Toggle expand
     *
     * @return self
     */
    toggleExpand() {
        const { updateMemberSettingsCb, searchSettings } = this.props,
            { expand }                                   = this.state;

        updateMemberSettingsCb({
            search: {
                ...searchSettings,
                boost_is_enabled: !expand
            }
        });

        this.setState({ edited: true, expand: !expand });
        this.resetSyntaxic();
    }

    /**
    * Render boost my search checkbox
    *
    * @return JSX
    */
    renderBoostCheckbox() {
        const { expand } = this.state,
            text         = 'Boost my search',
            explain      = (
                <>
                    Including variation of concepts
                    <br />
                    (conjugated form, word family...)
                    <br />
                    and extending search to extra fields
                </>
            );

        return (
            <div className="checkboxes boost">
                <div className="container">
                    <Popover
                        content={explain}
                        placement="right"
                    >
                        <Checkbox
                            checked={expand}
                            onChange={this.toggleExpand}
                        >
                            {text}
                        </Checkbox>
                    </Popover>
                </div>
            </div>
        );
    }

    /**
    * Contains at least a concept input
    *
    * @return bool
    */
    containsAtLeastAConcept() {
        return this.conceptsInputsCount() > 0;
    }

    /**
    * Trigger a request that refresh the syntaxic label for the current query
    *
    * @return void
    */
    refreshSyntaxic() {
        if (!this.containsAtLeastAConcept()) {
            return false;
        }

        const parameters = this.getParametersToSubmit(),
            { concept }  = parameters,
            { syntaxic } = this.state,
            {
                enabled,
                mustBeFetch
            }            = syntaxic;

        if (mustBeFetch) {
            this.setState({
                syntaxic: {
                    ...syntaxic,
                    mustBeFetch: false
                }
            });
            dataPost('/query-labels', {
                ...parameters,
                concept : concept.map(JSON.stringify),
                settings: JSON.stringify(parameters.settings)
            })
                .then(
                    ({ body }) => {
                        this.setState({
                            syntaxic: {
                                enabled,
                                value: _.get(body, 'label', '')
                            }
                        });
                    }
                );
        }
    }

    /**
    * Enabled / Disabled syntaxic search
    *
    * @return void
    */
    toggleSyntaxic() {
        const { syntaxic } = this.state,
            { enabled }    = syntaxic;

        this.setState({
            syntaxic: {
                ...syntaxic,
                enabled: !enabled
            }
        });
    }

    /**
    * Do some replace in the syntaxic search then htmlize
    *
    * @return self
    */
    parseSyntaxic(value) {
        if (!_.isString(value)) {
            return '';
        }

        const replacedString = value.replace(/<bb>/gi, '<b>')
            .replace(/<\/bb>/gi, '</b>');

        return htmlize(replacedString);
    }

    /**
    * Render the human readable sentence from inputs
    *
    * @return JSX
    */
    renderSentence() {
        const {
                inputs, expand,
                filters, syntaxic
            }                  = this.state,
            { enabled, value } = syntaxic,
            parseValue         = this.parseSyntaxic(value),
            classNames         = ['show-syntaxic', {enabled}];


        if (!inputs.length) {
            return false;
        }

        return (
            <ToggleChildren title="Resume">
                <div className="search-sentence">
                    {this.containsAtLeastAConcept() && (
                        <div className={makeStrClassName(classNames)}>
                            <Switch checked={enabled} onChange={this.toggleSyntaxic}
                                size="small" className="ant-switch-extra-small"
                            />
                            <span onClick={this.toggleSyntaxic}>Show syntaxic search</span>
                        </div>
                    )}

                    {enabled && parseValue ? (
                        <div className="smart-syntaxic">
                            {parseValue}
                        </div>
                    ) : (
                        <Sentence inputs={inputs} expand={expand}
                            filters={filters}
                        />
                    )}
                </div>
            </ToggleChildren>
        );
    }

    /**
    * Filter change
    *
    * @param {string} filterKey
    * @param {string} value
    */
    onChangeFilter(filterKey, value) {
        const { filters } = this.state,
            filterToAdd   = { ...filters, [filterKey]: value };

        this.setState({ edited: true, filters: filterToAdd });
    }

    /**
    * Render inputs search
    *
    * @returns JSX
    */
    renderInputsSearch() {
        const { inputs } = this.state,
            hasInputs    = inputs.length > 0;

        return (
            <>
                {!hasInputs && this.renderAutocomplete()}
                {hasInputs > 0 && (
                    <div className="search-inputs">
                        {this.renderInputs()}
                        {this.renderFilters()}
                        {this.renderSentence()}
                        {this.renderSubmit()}
                    </div>
                )}
            </>
        );
    }


    /**
     *
     * @param {*} filterKey
     * @returns
     */
    renderFilter(filterKey) {
        const { filters }  = this.state,
            filterValue    = filters[filterKey];

        if (!filterKey || this.conceptsInputsCount() === 0) {
            return null;
        }

        return (
            <SmartFilter
                className="wrapper-filter"
                filterKey={filterKey}
                filterValue={filterValue}
                onChangeFilter={this.onChangeFilter}
            />
        );
    }

    /**
    * Render filters
    *
    * @returns JSX
    */
    renderFilters() {
        const { filters }    = this.state,
            atLeastOneFilter = !_.isEmpty(filters);

        return this.containsAtLeastAConcept() && (
            <ToggleChildren title="Filters" open={atLeastOneFilter}>
                <div className="filters-container">
                    {this.renderFilter('dateFilter')}
                    {this.renderFilter('countryFilter')}
                    {this.renderFilter('bookmarkFolderForTags')}
                </div>
            </ToggleChildren>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <div className="smart" ref={this.ref}>
                <div className="search-form">
                    {this.renderInputsSearch()}
                </div>
            </div>
        );
    }

}

Smart.propTypes = {
    onSubmit              : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    openOrgunitsCollection: PropTypes.func,
    registerCallbacks     : PropTypes.func,
    switcher              : PropTypes.any,
    search                : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    value                 : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    updateMemberSettingsCb: PropTypes.func,
    onUpdateInputs        : PropTypes.func,
    mustHaveOneConcept    : PropTypes.bool,
    searchSettings        : PropTypes.shape({
        boost_is_enabled: PropTypes.bool,
    }),
};

Smart.defaultProps = {
    registerCallbacks: () => {},
    onUpdateInputs   : () => {},
};

/**
 */
const mapStateToProps  = (state) => {
    const auth                = state.get('auth'),
        member                = auth.get('member'),
        settings              = member.get('settings') || {},
        searchSettings        = settings.search || {};


    return { searchSettings };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    updateMemberSettingsCb: updateMemberSettings,
})(Smart);
