import _               from 'lodash';

/**
* Test if two nodes are in collision
*
* @param node node1 The first node
* @param node node2 The second node
*
* @return bool
*/
export const areNodesInCollision = (node1, node2) => {
    const rect1 = node1.getBoundingClientRect(),
        rect2   = node2.getBoundingClientRect();

    return rect1.x < rect2.x + rect2.width
        && rect1.x + rect1.width > rect2.x
        && rect1.y < rect2.y + rect2.height
        && rect1.height + rect1.y > rect2.y;
};

/**
* Test if two nodes are in collision
*
* @param node node1 The first node
* @param node node2 The second node
*
* @return bool
*/
export const getAbsoluteRectBoundingClient = (node) => {
    const parentOffsets = node.tagName !== 'BODY' && node.parentNode
            ? getAbsoluteRectBoundingClient(node.parentNode) : null,
        nodeOffset      = node.getBoundingClientRect();

    // Return node offset
    if (!parentOffsets) {
        return nodeOffset;
    }

    return {
        x     : parentOffsets.x + nodeOffset.x,
        y     : parentOffsets.y + nodeOffset.y,
        width : nodeOffset.width,
        height: nodeOffset.height
    };
};

/**
 * Test if the element is overflowing
 * @return string
 */
export const elementIsOverflowing = (el, options = {}) => {
    const parentEl    = options.parentElement || el.parentNode,
        elementBottom = el.offsetTop + el.clientHeight,
        offsetHeight  = options.offsetHeight || 0;

    return elementBottom > (parentEl.clientHeight - offsetHeight);
};

/**
 * Extract some GET param from a translate string ("rotate(45) skewX(20) translate(20,30) scale(1)")
 *
 * @param domNode node The svg node with transform attribute
 *
 * @return object
 */
export const getTranslation = (node) => {
    if (!_.get(node, 'attributes.transform.value')) { return [0, 0]; }
    /* Consolidate the SVGTransformList containing all transformations
       To a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
       its SVGMatrix. */
    const { matrix } = node.transform.baseVal.consolidate();

    // TODO use DOMMatrix when all browser implement it.

    // As per definition values e and f are the ones for the translation.
    return [matrix.e, matrix.f];
};

/**
 * Make string from translation object {x, y ,scale, rotate}
 *
 * @param object
 *
 * @return string
 */
export const transformToString = (translation, unit = '') => {
    const translationArray      = [],
        {
            x,
            y,
            scale,
            rotate
        } = translation;

    if (!_.isUndefined(x) && !_.isUndefined(y)) { translationArray.push(`translate(${x}${unit},${y}${unit})`); }
    if (scale) { translationArray.push(`scale(${scale})`); }
    if (rotate) { translationArray.push(`rotate(${rotate})`); }

    return translationArray.join(' ');
};

/**
 * Translate domNode from translation object {x, y ,scale, rotate}
 *
 * @param object
 *
 * @return string
 */
export const transformNode = (el, translation, useAtribute = false) => {
    if (!el) { return; }

    if (useAtribute) {
        _.set(
            el,
            'attributes.transform.value',
            transformToString(translation)
        );
        return;
    }

    el.style.transform = transformToString(translation, 'px'); // eslint-disable-line no-param-reassign
};

/**
 * Get element size
 * @return void
 */
export const getElementSize = (el) => {
    const svgElements = ['g', 'text', 'rect'];

    return el && svgElements.includes(el.tagName)
        ? el.getBBox()
        : {
            width: el
                ? el.clientWidth
                : 0,
            height: el
                ? el.clientHeight
                : 0
        };
};

/**
 * Get element global offsetTop
 * @return void
 */
export const getGlobalOffset = (element) => {
    let top = 0,
        left = 0;
    if (element) {
        do {
            top += element.offsetTop  || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);
    }

    return {
        top,
        left
    };
};

/**
 * Find a class belonging to one of the element's parent
 *
 * @param {Node} element
 * @param {string} className
 *
 * @returns {Node|null}
 */
export const findParentClass = (element, className) => {
    if (!element) {
        return null;
    }

    let parent = element.parentNode;

    while (parent) {
        if (parent.classList && parent.classList.contains(className)) {
            return parent;
        }

        parent = parent.parentNode;
    }

    return null;
};

export default {
    elementIsOverflowing,
    getElementSize,
    getTranslation,
    getGlobalOffset,
    transformToString,
    transformNode,
    findParentClass,
};

/**
 * Make a callback with element width/height and returns a function for unobserve
 * @param {*} elementRef Element reference
 * @param {*} callback  Callback for resize
 * @returns Unobserve function
 */
export const onElementResize = (elementRef, callback) => {
    if (!elementRef) {
        return () => {};
    }
    const element = elementRef,
        observer = new ResizeObserver(() => {
            const width = element?.offsetWidth,
                height  = element?.offsetHeight;

            if (width > 0 && height > 0) {
                callback({width, height});
            }
        });

    callback && observer.observe(element, callback);

    return () => {
        observer.unobserve(element);
    };
};

/**
 * Make a callback with parent width/height and returns a function for unobserve
 * @param {*} elementRef Element reference
 * @param {*} callback  Callback for resize
 * @returns Unobserve function
 */
export const onParentResize = (elementRef, callback) => {
    const parent = elementRef?.current?.parentNode;

    return onElementResize(parent, callback);
};


/**
 *
 * @param {*} color
 */
export const getDomColor = (color) => {
    const matches = color.match(/--([a-zA-Z0-9-]+)/);
    if (matches && matches.length > 1) {
        return getComputedStyle(document.documentElement).getPropertyValue(`--${matches[1]}`);
    }
    return color;
};
