/**
* Render Classic search
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { makeStrClassName } from 'utils/text';

import './assets/classic.less';

/**
* Render The "Classic" menu
*
*/
class Classic extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'emitChange', 'keypress', 'injectValue',
            'getParametersToSubmit', 'onSubmit', 'hasValue', 'focus', 'blur');

        this.contentEditableRef = React.createRef();

        this.state = {
            value        : '',
            first        : true,
            edited       : false,
            placeholdered: true,
            focused      : false,
        };
    }

    /**
    * Component did mont
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks } = this.props;

        registerCallbacks('submit', this.getParametersToSubmit);
        registerCallbacks('has-value', this.hasValue);

        this.injectValue();
    }

    /**
    * Side effects
    *
    * @return void
    */
    componentDidUpdate() {
        this.injectValue();
    }

    /**
    * Inject value if needed
    *
    * @return void
    */
    injectValue() {
        const { value, edited, first } = this.state,
            input                      = this.contentEditableRef.current;

        if (!edited && value && first && input) {
            input.innerText = value;
            this.setState({
                first        : false,
                placeholdered: value.length === 0
            });
        }
    }

    /**
    * Create the state from properties
    *
    * @return object
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { value } = nextProps,
            { edited }  = prevState;

        if (!edited && value) {
            const [firstValue] = value;

            prevState.value = firstValue;
        }

        return prevState;
    }

    /**
    * On submit classic search
    *
    * @return JSX
    */
    onSubmit() {
        const { onSubmit } = this.props;

        if (onSubmit) {
            onSubmit(this.getParametersToSubmit());
        }
    }

    /**
    * Is there some content in the contenteditable
    *
    * @return bool
    */
    hasValue() {
        const { value } = this.state;

        return value && value.length > 0;
    }

    /**
    * Create a submitable parameters array
    *
    * @return object
    */
    getParametersToSubmit() {
        const { value } = this.state;

        return {
            concept : [value],
            mode    : 'classic',
            settings: {}
        };
    }

    /**
    * Render the switcher
    *
    * @return JSX
    */
    renderSwitcher() {
        const { switcher } = this.props;

        if (!switcher) {
            return false;
        }

        return switcher;
    }

    /**
    * Render the submit button
    *
    * @return JSX
    */
    renderSubmit() {
        const { onSubmit } = this.props;

        if (!onSubmit) {
            return false;
        }

        return (
            <div className="submit" onClick={this.onSubmit}>
                Launch my search
            </div>
        );
    }

    /**
    * On key keypress
    *
    * @return void
    */
    keypress(e) {
        const { type } = e,
            code       = type === 'keyup' ? e.keyCode : false;

        if (code === 13) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /**
    * On change
    *
    * @return void
    */
    emitChange(e) {
        const editable = this.contentEditableRef.current;

        if (!editable) {
            return;
        }

        const { type } = e,
            code   = type === 'keyup' ? e.keyCode : false;

        if (code === 13) {
            this.setState({ focused: false });

            e.stopPropagation();
            e.preventDefault();

            this.onSubmit();

            return false;
        }

        this.setState({
            edited       : true,
            value        : editable.textContent,
            placeholdered: editable.textContent.length === 0,
            canSubmit    : editable.textContent.length > 2
        });
    }

    /**
    * Focus on content editable
    */
    focus() {
        this.setState({ focused: true });
    }

    /**
    * Blur on content editable
    */
    blur() {
        this.setState({ focused: false });
    }

    /**
    * Render the classic input
    *
    * @return JSX
    */
    renderInput() {
        const { placeholdered } = this.state,
            { focused }         = this.state,
            classNames          = ['input', {'is-focused': focused}];

        return (
            <div className={makeStrClassName(classNames)}>
                {placeholdered && (
                    <span className="placeholder">
                        Enter keywords and operators...
                    </span>
                )}
                <div
                    ref={this.contentEditableRef}
                    className="editable"
                    contentEditable="true"
                    onKeyUp={this.emitChange}
                    onKeyPress={this.keypress}
                    onBlur={this.blur}
                    onFocus={this.focus}
                />
                {this.renderSwitcher()}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { canSubmit } = this.state;

        return (
            <div className="classic">
                {this.renderInput()}
                {canSubmit && this.renderSubmit()}
            </div>
        );
    }

}

Classic.propTypes = {
    onSubmit         : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
    registerCallbacks: PropTypes.func,
    switcher         : PropTypes.any,
    value            : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
};

Classic.defaultProps = {
    registerCallbacks: () => {}
};

export default Classic;

