// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Graph Tren Information
*
*/
.trend-information {
  position: absolute;
}
.trend-information div,
.trend-information span {
  color: inherit;
}
.trend-information .rate {
  text-align: left;
  display: inline-block;
}
.trend-information svg.trend-lines {
  position: absolute;
}
.trend-information svg.trend-lines line {
  shape-rendering: crispedges;
}
.trend-information svg.trend-lines line.slope {
  shape-rendering: geometricprecision;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/assets/trendInformation.less"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACI,kBAAA;AACJ;AAFA;;EAGQ,cAAA;AAGR;AANA;EAMQ,gBAAA;EACA,qBAAA;AAGR;AAVA;EAUQ,kBAAA;AAGR;AAbA;EAYY,2BAAA;AAIZ;AAHY;EACI,mCAAA;AAKhB","sourcesContent":["/**\n* Graph Tren Information\n*\n*/\n.trend-information{\n    position: absolute;\n    div, span {\n        color: inherit;\n    }\n    .rate{\n        text-align: left;\n        display: inline-block;\n    }\n    svg.trend-lines{\n        position: absolute;\n        line {\n            shape-rendering: crispedges;\n            &.slope{\n                shape-rendering: geometricprecision;\n            }\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
