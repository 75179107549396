import React, { useState,  useRef }     from 'react';
import PropTypes                        from 'prop-types';
import { IButton }                      from 'helpers';
import { makeStrClassName }             from 'utils/text';

import './assets/limit-children-display.less';


/**
 * Show a subset of elements (based on maxVisibleElements) and a button to display all
 * React Functional elements (Hooks)

 * @return JSX
 */
const LimitChildrenDisplay = ({children, max, tolerance, className}) => { // eslint-disable-line  max-lines-per-function
    const [showAll, setShowAll] = useState(false),
        hiddenElementsRef    = useRef(null),
        toleredElements      = tolerance + max,
        maxVisibleElements   = toleredElements >= children.length ? toleredElements : max,
        visibleElements      = children.slice(0, maxVisibleElements),
        hiddenElements       = children.slice(maxVisibleElements, children.length),
        hasHiddenElements    = hiddenElements.length > 0,
        maxHeight            = hiddenElementsRef?.current?.scrollHeight || undefined,
        hiddenElementsHeight = showAll ? maxHeight : 0;

    /**
     * Toogle between show and hide states
     */
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    /**
     * Render the button more/less
     * @returns JSX
     */
    const renderMoreLessButton = () => {
        const count   = hiddenElements.length,
            className = showAll ? 'less' : 'more',
            label     = showAll ? 'Show less' : `Show more (+${count})`;
        return (
            <IButton
                label={label}
                icon="retract-arrow"
                className={`show-more ${className}`}
                onClick={toggleShowAll}
                fontSize={12}
                iconSize={10}
                iconColor="#B7B7B7"
                reverse
            />
        );
    };

    return visibleElements && (
        <>
            <div
                className={makeStrClassName(['visible-elements', className])}
            >
                {visibleElements}
            </div>
            { hasHiddenElements && (
                <div
                    ref={hiddenElementsRef} style={{maxHeight: hiddenElementsHeight}}
                    className={makeStrClassName(['hidden-elements', className, showAll ? 'show' : 'hidden'])}
                >
                    {hiddenElements}
                </div>
            )}
            {hasHiddenElements && renderMoreLessButton()}
        </>
    );
};

LimitChildrenDisplay.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    children : PropTypes.array,
    max      : PropTypes.number,
    tolerance: PropTypes.number,
};

LimitChildrenDisplay.defaultProps = {
    className: [],
    children : [],
    max      : 10,
    tolerance: 0,
};

export default LimitChildrenDisplay;
