import _                      from 'lodash';
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import ImmutablePropTypes     from 'react-immutable-proptypes';
import { Modal, Tooltip }     from 'antd';
import { Element, CssLoader,
    notification, Icon }      from 'helpers';
import TextEdit               from 'helpers/TextEdit.jsx';
import { htmlize }            from 'utils/text';
import { getSearchTitle }     from 'utils/search';
import { connect }            from 'react-redux';
import { resetDownloadsList } from 'store/actions/downloads';
import {
    saveReport,
    generateReport }          from 'store/actions/navigation/report';

// CSS
import './Report/assets/main.less';
/**
* Manage to edit a report
*
*/
class Report extends Component  {

    /**
    * Instanciate the modal
    */
    constructor(props) {
        super(props);

        this.state = {
            model     : {},
            modelStore: null
        };

        _.bindAll(this, 'onCancel', 'viewToogle', 'slideActive', 'slideSelect', 'hideElement', 'saveText',
            'update', 'onGenerate', 'renderGroup', 'renderSlideEdit', 'renderEditInsight', 'renderSlides',
            'renderSlideDesc');
    }

    /**
    * After each render
    *
    * @return void
    */
    componentDidUpdate() {
        const { model }=this.state;
        if(model.loading) {
            model.loading=false;
            setTimeout(() => this.setState({model}), 300);
        }
    }

    /**
     * Trigger before render
     *
     * @param {Object} props
     * @param {Object} state
     */
    static getDerivedStateFromProps(props, state) {
        const { openedReport, search }= props,
            { modelStore } = state,
            updateReport = (modelStore !== openedReport),
            model =   updateReport
                && openedReport
                && typeof openedReport.toJS === 'function'  /* Test is immutable object */
                && openedReport.toJS();

        if (!updateReport || model === false) {
            return null;
        }

        // Add search to report model
        if (model.search === null) {
            model.search= search;
        }

        // Fill empty title with search label
        if (model.slides) {
            model.slides.forEach((group) => {
                const titleSlide = group.slides.find(slide => slide.type === 'title'),
                    { label }    = titleSlide || {};

                if (titleSlide && !label) {
                    titleSlide.label = getSearchTitle(search);
                }
            });
        }

        return {
            ...state,
            modelStore: openedReport,
            model,
        };
    }


    /**
    * Trigger onCancel
    *
    * @return void
    */
    onCancel() {
        const { model } = this.state;
        model.isOpen= false;
        this.update(model);
    }

    /**
    * Trigger when you change the view to display
    *
    * @param {bool} display
    *
    * @return void
    */
    viewToogle(display) {
        const { model } =this.state;
        if(display!=='liste') {
            display='gallery';
        }
        model.display= display;
        this.update(model);
    }

    /**
    * Trigger when to active hide a slide
    *
    * @param {int} groupIndex
    * @param {int} slideIndex
    *
    * @return void
    */
    slideActive(groupIndex, slideIndex) {
        const { model }   = this.state;

        // Toggle active
        model.slides[groupIndex].slides[slideIndex].active = !model.slides[groupIndex].slides[slideIndex].active;

        this.update(model);
    }

    /**
    * Trigger when to select a slide
    *
    * @param {string} elementType
    * @param {int} groupIndex
    * @param {int} slideIndex
    *
    * @return void
    */
    slideSelect(groupIndex, slideIndex) {
        const { model } = this.state,
            { display }= model;
        // In list view not change on slide click only on the button
        // I if(elementType==="slide" && model.display!=="liste"){
        // I    return;
        // I}
        this.viewToogle('liste');
        model.elementSelect=[groupIndex, slideIndex];
        this.update(model);
        if(display!=='liste') {
            setTimeout(() => {
                const slide= document.getElementsByClassName('element select')[0];
                slide.scrollIntoView();
            }, 100);
        }
    }

    /**
    * Trigger when click to hide an element ( graph insight)
    *
    * @param {string} location
    *
    * @return void
    */
    hideElement(location) {
        const { model }      = this.state,
            elementSelect    = model.elementSelect ? model.elementSelect : [0, 0],
            element          = model.slides[elementSelect[0]].slides[elementSelect[1]],
            {parameters} = element;
        switch(location) {
            case 'hideGraph':
                parameters.hideGraph= !parameters.hideGraph;
                break;
            case 'hideInsight':
                parameters.hideInsight= !parameters.hideInsight;
                break;
            default:
                alert('hide location unknow :'+location);
                break;
        }
        this.update(model);
    }

    /**
    * Trigger when click to change a text
    *
    * @param {string} location
    * @param {string} text
    *
    * @return void
    */
    saveText(location, text) {
        const { model }   = this.state,
            { search }    = model,
            elementSelect = model.elementSelect ? model.elementSelect : [0, 0],
            element       = model.slides[elementSelect[0]].slides[elementSelect[1]];

        switch(location) {
            case 'reportName':
                model.label=text;
                break;
            case 'slideTitle':
                element.label=text || getSearchTitle(search);
                break;
            case 'interestTitle':
                element.parameters.interestTitle=text;
                break;
            case 'interestDescription':
                element.parameters.interestDescription=text;
                break;
            case 'howBuildTitle':
                element.parameters.howBuildTitle=text;
                break;
            case 'howBuildDescription':
                element.parameters.howBuildDescription=text;
                break;
            default:
                alert('text location unknow :'+location);
                break;
        }
        this.update(model);
    }

    /**
     * Update the model
     *
     * @param  slides contains the report object
     */
    update(slides) {
        this.setState({ Model: slides});
    }

    /**
     * Save the model
     *
     */
    onSave=() => {
        const { saveReport } = this.props,
            { model }        = this.state;
        model.isOpen=false;
        saveReport(model, this.Saved);
    }

    /**
     * After Save the model
     *
     */
    Saved=() => {
        notification({message: 'your report has been saved.'});
    }

    /**
     * Save a report before Generate one
     *
     * @param  slides contains the report object
     */
    onGenerate() {
        const { model }    = this.state,
            { saveReport } = this.props;
        notification({
            message: 'Once generated, the report will be available in the download manager.'});
        saveReport(model, this.generating);
    }

    /**
     * Generate the report model
     *
     */
    generating=(model) => {
        const { generateReport, resetDownloadsList } = this.props;
        generateReport(model.id);

        setTimeout(resetDownloadsList, 1500);
    }

    /**
    * Render for group of slides
    *
    * @return HTML
    */
    renderGroup() {
        const { model }   = this.state,
            elementsGroups= model.slides ? model.slides: [];

        return(
            <>
                {
                    elementsGroups.map((group, groupIndex) => {
                        return (
                            <div
                                key={'report-group'+groupIndex}
                                className="group"
                            >
                                <div
                                    className="label"
                                >
                                    {group.label}
                                </div>
                                <div
                                    className="elements"
                                >
                                    { this.renderSlides(group.slides, groupIndex)}
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    }

    /**
    * Render the slide edit area title
    *
    * @return html
    */
    renderSlideEditTitle() {
        const {model}          =this.state,
            elementSelect      = model.elementSelect? model.elementSelect: [0, 0],
            elementsGroups     = model.slides ? model.slides : null,
            element            = elementsGroups ? elementsGroups[elementSelect[0]].slides[elementSelect[1]]: {};
        return(
            <>
                <div className="logo">
                    <Icon
                        key="insight"
                        id="insight"
                        folder="/"
                        width={127} height={30}
                        alt="Insight"
                    />
                </div>
                <div className="title">
                    <TextEdit
                        text={element.label}
                        onSave={(text) => this.saveText('slideTitle', text)}
                        tooltip="Click to edit chart title"
                        type={(element.type==='element'?'input':'textarea')}
                        maxLength={(element.type==='title'?500: 200)}
                    />
                </div>
            </>
        );
    }

    /**
    * Render the slide edit area
    *
    * @return html
    */
    renderSlideEdit() {
        const {model}          =this.state,
            elementSelect      = model.elementSelect? model.elementSelect: [0, 0],
            elementsGroups     = model.slides ? model.slides : null,
            element            = elementsGroups ? elementsGroups[elementSelect[0]].slides[elementSelect[1]]: {},
            parameters         = element.parameters? element.parameters : {},
            interestTitle      = parameters.interestTitle? parameters.interestTitle: '',
            interestDescription= parameters.interestDescription? parameters.interestDescription: '';
        return(
            <div className={'edit-slide'+ (element.originalRatio<=(16/9)?' portrait': ' landscape')
                + (element.type==='title'?' title': '')}
            >
                {this.renderSlideEditTitle()}
                {element.type==='element' && (
                    <div className="edit-element">
                        {this.renderSlideEditGraph()}
                        <div className="graph-infos">
                            {this.renderEditInsight()}
                            <div className="desc">
                                <div className="title">
                                    <TextEdit
                                        text={interestTitle}
                                        onSave={(text) => this.saveText('interestTitle', text)}
                                        maxLength={200}
                                    />
                                </div>
                                <div className="desc">
                                    <TextEdit
                                        text={interestDescription}
                                        onSave={(text) => this.saveText('interestDescription', text)}
                                        type="textarea"
                                        maxLength={700}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    /**
    * Render the graph area for the slide
    *
    * @return html
    */
    renderSlideEditGraph() {
        const {model}      =this.state,
            {search} = model,
            elementSelect  = model.elementSelect? model.elementSelect: [0, 0],
            elementsGroups = model.slides ? model.slides : null,
            element        = elementsGroups ? elementsGroups[elementSelect[0]].slides[elementSelect[1]]: {},
            parameters     = element.parameters? element.parameters : {},
            {originalRatio}= element,
            portrait       = originalRatio<=(16/9),
            elementWidth   = portrait? 920 : 1870,
            elementHeight  = portrait? 944 : 600,
            editRatio      = elementWidth/elementHeight,
            width          = (originalRatio>editRatio ? elementWidth: elementHeight*originalRatio),
            height         = (originalRatio>editRatio ? elementWidth/originalRatio : elementHeight);
        return (
            <div className={'graph capture-viewer' + (parameters.hideGraph? ' hide' :'')}>
                <div className="hide" onClick={() => this.hideElement('hideGraph')}>
                    {parameters.hideGraph?'show chart' : 'hide chart'}
                </div>
                <div className="element">
                    <div
                        className="resize"
                        style={{height, width}}
                    >
                        <Element
                            key={element.id}
                            element={element.id}
                            model={search}
                            width={width}
                            height={height}
                            hideRowLabel
                        />
                    </div>
                </div>
            </div>
        );
    }


    /**
    * Render insight et desc element of a slide
    *
    * @return html
    */
    renderEditInsight() {
        const {model}          = this.state,
            elementSelect      = model.elementSelect? model.elementSelect: [0, 0],
            elementsGroups     = model.slides ? model.slides: null,
            element            = elementsGroups ? elementsGroups[elementSelect[0]].slides[elementSelect[1]]:{},
            parameters         = element.parameters? element.parameters : {},
            howBuildTitle      = parameters.howBuildTitle? parameters.howBuildTitle: '',
            howBuildDescription= parameters.howBuildDescription? parameters.howBuildDescription: '',
            insight            = parameters.insight? parameters.insight: '';
        return(
            <div className="insight">
                <div className="hide" onClick={() => this.hideElement('hideInsight')}>
                    {parameters.hideInsight?'show insight' : 'hide insight'}
                </div>
                <div className="info">
                    <div
                        className={'title'+ (parameters.hideInsight? ' hide' :'')}
                    >
                        {insight}
                    </div>
                    <div className="desc" style={{display: 'none'}}>
                        <div className="title">
                            <TextEdit
                                text={howBuildTitle}
                                onSave={(text) => this.saveText('howBuildTitle', text)}
                            />
                        </div>
                        <div className="desc">
                            <TextEdit
                                text={howBuildDescription}
                                onSave={(text) => this.saveText('howBuildDescription', text)}
                                type="textarea"
                                maxLength={700}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    /**
    * Render for one group slide
    *
    * @return html
    */
    renderSlides(group, groupIndex) {       // eslint-disable-line max-lines-per-function
        const {model}    = this.state,
            {search} = model,
            elementSelect= model.elementSelect? model.elementSelect:[0, 0];
        return(
            <>
                {group.map((element, elementIndex) => {
                    const parameters  = element.parameters ? element.parameters : {},
                        {
                            originalRatio, type, active
                        }             = element,
                        elementWidth  = 892,
                        elementHeight = 544,
                        editRatio     = elementWidth / elementHeight,
                        width         = (originalRatio > editRatio ? elementWidth : elementHeight * originalRatio),
                        height        = (originalRatio > editRatio ? elementWidth/originalRatio : elementHeight);

                    return(
                        <div
                            key={'report-element'+elementIndex}
                            className={'element'+ (active===false? ' hide': '')
                                +((elementSelect[0]===groupIndex && elementSelect[1]===elementIndex)? ' select': '')
                                + ((type==='title')? ' title':'')}
                        >
                            <div
                                className="info"
                                onClick={() => this.slideSelect(groupIndex, elementIndex)}
                            >
                                <div className="title"> {htmlize(element.label)} </div>
                                {
                                    type==='element'
                                    && (
                                        <div className="graph capture-viewer">
                                            <div className="resize" style={{height, width}}>
                                                <Element
                                                    element={element.id}
                                                    model={search}
                                                    width={width}
                                                    height={height}
                                                    hideRowLabel
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                {type==='element' && this.renderSlideDesc(parameters)}
                            </div>
                            {this.renderSlideAction(groupIndex, elementIndex, active)}
                        </div>
                    );
                })}
            </>
        );
    }

    /**
     * Render the slide action element
     *
     * @param {int} groupIndex
     * @param {int} elementIndex
     */
    renderSlideAction(groupIndex, elementIndex, active) {
        return(
            <>
                <Tooltip placement="bottom" title={active?'Click to deselect the slide': 'Click to select the slide'}>
                    <div
                        className="select"
                        onClick={() => this.slideActive(groupIndex, elementIndex)}
                    >
                        <Icon
                            key="reportSlideVisible"
                            id="reportSlideVisible"
                            folder="/report/"
                            height={25}
                        />
                    </div>
                </Tooltip>
                <Tooltip placement="bottom" title="Click to edit the slide">
                    <div
                        className="edit"
                        onClick={() => this.slideSelect(groupIndex, elementIndex)}
                    />
                </Tooltip>
            </>
        );
    }

    /**
    * Render for one slide description
    *
    * @param {object} parameters
    *
    * @return html
    */
    renderSlideDesc(parameters) {
        const interestTitle    = parameters.interestTitle? parameters.interestTitle: '',
            interestDescription= parameters.interestDescription? parameters.interestDescription: '';
        return (
            <div className="desc">
                <div className="title">
                    {interestTitle}
                </div>
                <div className="infos">
                    <p>
                        {htmlize(interestDescription)}
                    </p>
                </div>
            </div>
        );
    }

    /**
     * Render Report body
     *
     * @return html
     */
    renderBody() {
        const {model}= this.state,
            display  = (model.display==='liste')?'liste':'gallery';
        if(model.loading===true) {
            return (
                <div className="loading">
                    <div>
                        <CssLoader color="#000000" type="ripple"
                            size={50}
                        />
                        <div className="loading-text">
                            report loading ...
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <div className={'body '+ display}>
                {this.reportBodyHeader()}
                {this.reportBodyCenter()}
                {this.reportBodyFooter()}
            </div>
        );
    }

    /**
    * Render Report body header
    *
    * @return html
    */
    reportBodyHeader() {
        const { model }      = this.state,
            {label} = model,
            slidesGroups     = model.slides ? model.slides: [];
        let slidesCount=0,
            slidesSlect=0;
        slidesGroups.forEach(groups => {
            groups.slides.forEach(slide => {
                slidesCount++;
                if(slide.active!==false) {
                    slidesSlect++;
                }
            });
        });
        return(
            <div className="header">
                <div className="title">
                    <TextEdit
                        position="absolute"
                        text={label}
                        tooltip="Click to edit report title"
                        onSave={(text) => this.saveText('reportName', text)}
                    />
                </div>
                <div className="infos">
                    <div className="slide">
                        {slidesSlect} slide
                        {slidesSlect>1?'s':''} selected on {slidesCount}
                    </div>
                    <div className="modeIcons" onClick={() => this.viewToogle('gallery')} />
                    <div className="modeList" onClick={() => this.viewToogle('liste')} />
                </div>
            </div>
        );
    }

    /**
    * Render Report body center
    *
    * @return html
    */
    reportBodyCenter() {
        return(
            <div className="center">
                <div className="slides-list">
                    {this.renderGroup()}
                </div>
                {this.renderSlideEdit()}
            </div>
        );
    }

    /**
    * Render Report body footer
    *
    * @return html
    */
    reportBodyFooter() {
        const { model }      = this.state,
            slidesGroups     = model.slides ? model.slides: [];
        let slidesSlect=0;
        slidesGroups.forEach(groups => {
            groups.slides.forEach(slide => {
                if(slide.active!==false) {
                    slidesSlect++;
                }
            });
        });
        return(
            <div className="footer">
                <div
                    className="cancel"
                    tabIndex="1"
                    onClick={this.onCancel}
                >
                    Cancel
                </div>
                <div className="save"
                    onClick={this.onSave}
                    tabIndex="2"
                >
                    Save
                </div>
                <Tooltip placement="top" title={slidesSlect>0? '' : '0 slide selected'}>
                    <div
                        className={'generate'+ (slidesSlect>0? '' : ' disabled')}
                        tabIndex="3"
                        onClick={slidesSlect>0 && this.onGenerate}
                    >
                        Generate
                    </div>
                </Tooltip>
            </div>
        );
    }


    /**
    * Render the report
    *
    * @return Component
    */
    render() {
        const { model }= this.state,
            openModal  = !!((model.isOpen===true || model.loading===true));

        if(openModal===false) {
            return null;
        }
        return (
            <Modal
                title="One click report"
                className="report"
                style={{top: 60}}
                open={openModal}
                onCancel={this.onCancel}
                footer={null}
                maskClosable={false}
            >
                {this.renderBody()}
            </Modal>
        );
    }

}

Report.propTypes = {
    search            : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    openedReport      : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    saveReport        : PropTypes.func,
    generateReport    : PropTypes.func,
    resetDownloadsList: PropTypes.func,
};

Report.defaultProps = {
    searchSentence: '',
    openedReport  : false
};


/**
 */
const mapStateToProps = (state) => {
    const navigation = state.get('navigation');
    return {
        openedReport: navigation.get('openedReport'),
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    resetDownloadsList,
    generateReport,
    saveReport
})(Report);

