// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-countries-modal .ant-modal-content {
  padding: 0;
}
.custom-countries-modal .ant-modal-header {
  padding: 15px 25px;
  margin-bottom: 0;
  border-bottom: 1px solid #e8e8e8;
}
.custom-countries-modal .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
}
.custom-countries-modal .selector {
  overflow: auto;
  padding: 20px;
  border-right: 1px solid #e8e8e8;
}
.custom-countries-modal .selector * {
  line-height: 1em;
}
.custom-countries-modal .map {
  margin: auto;
}
.custom-countries-modal .buttons {
  border-top: 1px solid #e8e8e8;
  padding: 16px 20px;
}
.custom-countries-modal .buttons.row {
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Filters/assets/customcountries.less"],"names":[],"mappings":"AAGA;EAGQ,UAAA;AAJR;AACA;EAOQ,kBAAA;EACA,gBAAA;EACA,gCAAA;AALR;AAJA;EAaQ,eAAA;EACA,gBAAA;EACA,2BAAA;AANR;AATA;EAmBQ,cAAA;EACA,aAAA;EACA,+BAAA;AAPR;AAdA;EAuBY,gBAAA;AANZ;AAjBA;EA4BQ,YAAA;AARR;AApBA;EAgCQ,6BAAA;EACA,kBAAA;AATR;AAWQ;EACI,2BAAA;AATZ","sourcesContent":["@button-color     : var(--primary-color);\n@button-over-color: var(--primary-light-color);\n\n.custom-countries-modal {\n\n    .ant-modal-content {\n        padding: 0;\n    }\n\n    .ant-modal-header {\n        padding      : 15px 25px;\n        margin-bottom: 0;\n        border-bottom: 1px solid #e8e8e8;\n    }\n\n    .ant-modal-title{\n        font-size    : 16px;\n        font-weight  : 500;\n        color        : var(--primary-color);\n    }\n\n    .selector {\n        overflow    : auto;\n        padding     : 20px;\n        border-right: 1px solid #e8e8e8;\n        * {\n            line-height: 1em;\n        }\n    }\n\n    .map {\n        margin: auto;\n    }\n\n    .buttons {\n        border-top: 1px solid #e8e8e8;\n        padding   : 16px 20px;\n\n        &.row {\n            flex-direction: row-reverse;\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
