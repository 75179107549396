// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 Update popin
**/
.ant-popover.shortcut-view.searches .sentence {
  width: 540px;
}
.ant-popover.shortcut-view.searches .content .empty {
  color: var(--insight-gray);
  font-size: 12px;
  margin: 10px;
}
.edit-search-bookmark .name,
.edit-search-bookmark .concepts {
  width: 100%;
}
.edit-search-bookmark .name span,
.edit-search-bookmark .concepts span {
  width: 100px;
  display: inline-block;
  vertical-align: top;
  padding: 10px 0;
}
.edit-search-bookmark .name textarea,
.edit-search-bookmark .concepts textarea,
.edit-search-bookmark .name input,
.edit-search-bookmark .concepts input {
  padding: 10px;
  border: 1px solid #CCC;
  width: 350px;
}
.edit-search-bookmark .name textarea,
.edit-search-bookmark .concepts textarea {
  height: 210px;
}
.edit-search-bookmark .ant-btn-primary span {
  color: #FFF;
}
.shortcut-view.searches {
  width: 33%;
}
`, "",{"version":3,"sources":["webpack://./src/core/Ui/Header/Shortcut/Views/assets/searches.less"],"names":[],"mappings":"AAAA;;EAEE;AAGF;EAEQ,YAAA;AAFR;AAAA;EAQY,0BAAA;EACA,eAAA;EACA,YAAA;AALZ;AAUA;;EAGQ,WAAA;AATR;AAMA;;EAMY,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;AARZ;AADA;;;;EAaY,aAAA;EACA,sBAAA;EACA,YAAA;AANZ;AATA;;EAmBY,aAAA;AANZ;AAbA;EAyBY,WAAA;AATZ;AAcC;EACG,UAAA;AAZJ","sourcesContent":["@insight-gray: var(--insight-gray);\n\n/**\n Update popin\n**/\n.ant-popover.shortcut-view.searches {\n    .sentence {\n        width: 540px;\n    }\n\n    .content {\n\n        .empty {\n            color    : @insight-gray;\n            font-size: 12px;\n            margin   : 10px;\n        }\n    }\n}\n\n.edit-search-bookmark {\n\n    .name, .concepts {\n        width: 100%;\n\n        span {\n            width         : 100px;\n            display       : inline-block;\n            vertical-align: top;\n            padding       : 10px 0;\n        }\n\n        textarea, input {\n            padding: 10px;\n            border : 1px solid #CCC;\n            width  : 350px;\n        }\n\n        textarea {\n            height: 210px;\n        }\n    }\n\n    .ant-btn-primary {\n        span {\n            color: #FFF;\n        }\n    }\n}\n\n .shortcut-view.searches {\n    width: 33%;\n }\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
