/**
 * Display the dashboard of the App
 *
 * @return Component
 */
import _                                  from 'lodash';
import React, { PureComponent }           from 'react';
import PropTypes                          from 'prop-types';
import ImmutablePropTypes                 from 'react-immutable-proptypes';

import { connect }                        from 'react-redux';
import { Helmet }                         from 'react-helmet';
import { withRouter }                     from 'react-router-dom';
import { Skeleton }                       from 'antd';
import { dataGet }                        from 'utils/api';
import {
    getSubElementsFilterByCategory,
    getDashboardsElements
}                                         from 'utils/elements';
import { Element }                        from 'helpers';

import { learn }                          from 'store/actions/knowledge';
import { getModelsOfType }                from 'store/actions/userView';
import {
    isSearchMustBeRefreshed,
    refreshQuery,
    openOrgunitsCollection
}                                         from 'store/actions/navigation';
import { openReport}                      from 'store/actions/navigation/report';
import Switcher                           from './Dashboard/Switcher';
import DashboardBar                       from 'helpers/DashboardBar';

import './Dashboard/assets/dashboard.less';

let KNOWLEDGE_POOL = {};

/**
* Dashboard class
*
*/
class Dashboard extends PureComponent {

    /**
    * Dashboard constructor
    *
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'refreshSearchState', 'manageOutdatedSearches', 'makeClipboardItem', 'renderDashboardElement',
            'getMetadata', 'getCurrentDashboardElement', 'storeElementInstances', 'getMetricTooltipContent',
            'registerShowBarCb', 'registerHideBarCb', 'exportDashboard', 'exportReport', 'onDataLoaded');

        this.state = {
            searchState      : null,
            dataIsLoaded     : false,
            elementsInstances: {},
            model            : null,
            element          : null,
            ...KNOWLEDGE_POOL
        };

        this.showBarCb             = _.noop;
        this.hideBarCb             = _.noop;

        this.dashboardElement      = {};

        this.tooltipsContent       = {};
        this.sourceEntities        = {};
        this.metricUseGeneralStats = {};
        this.elementsData          = {};

        this.searchTimeout         = null;

        // Store there the cancellable promise
        this.collector             = null;

        this.dashboardRef          = React.createRef();
    }

    /**
    * Get state for props and previous state
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) {
        const { element:previousElement, model:previousModel,
                dashboards:previousDashboards, metadata,
                newslettersStream: previousNewsletters
            } = prevStates,
            {
                module,  elements, model,
                newslettersStream
            } = nextProps,
            dashboards                   = previousDashboards || getDashboardsElements(elements),
            element                      = dashboards.find((el) => el.key === module),
            modelHasChanged              = previousModel !== model;

        return {
            model,
            modelHasChanged,
            element,
            dashboards,
            newslettersStream,
            // Reset metadata when the context has been changed.
            metadata:
                (!previousElement || !previousModel || (previousElement.key !== element.key) || (previousModel.id !== model.id)
                    || previousNewsletters !== newslettersStream

                )
                    ? false : metadata
        };
    }

    /**
    * Get topic insight newsletter
    *
    * @returns object
    */
    getTopicInsight() {
        const { getModelsOfType } = this.props,
            newsletters           = getModelsOfType('newsletter');

        return newsletters && newsletters.find(newsletter => newsletter.key === 'topic-insights');
    }

    /**
    * Create the title object
    *
    * @return object
    */
    getMetadata() {
        const { model, navigateTo }                         = this.props,
            {
                metadata, dashboards, element, dataIsLoaded
            }          = this.state,
            newsletter = this.getTopicInsight();

        // Set metadata to the state if it's needed
        if (!metadata) {
            this.setState({
                metadata: {
                    title  : element.label,
                    actions: (
                        <Switcher
                            search={model}
                            dashboard={element}
                            dashboards={dashboards}
                            listsAreDisplayed={this.getListsAreDisplayed()}
                            newsletter={newsletter}
                            navigateTo={navigateTo}
                            exportDashboard={dataIsLoaded && this.exportDashboard}
                            exportReport={this.exportReport}
                            dataIsLoaded={dataIsLoaded}
                        />
                    )
                }
            });
        }

        return metadata;
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        // Fetch some entities to display
        learnKnowledge(['resources', 'entities', 'help-metrics'])
            .then((knowledges) => {
                KNOWLEDGE_POOL = knowledges;
                this.setState({
                    ...KNOWLEDGE_POOL
                });
            });

        this.refreshSearchState();
    }

    /**
    * Launch side effects
    *
    * @return boolean
    */
    componentDidUpdate() {
        const { module, registerMetadata,  } = this.props,
            { searchState, modelHasChanged } = this.state,
            generalStats                     = searchState?.stats || { },
            dashboardsStats                  = searchState?.statsByType || { },
            dashboardStats                   = dashboardsStats && dashboardsStats[module] || { },
            // Get cumulative progress of all dashboards
            dashboardIsProgressing           = dashboardStats.progress
                ? dashboardStats.progress < 100
                : true,
            generalIsProgressing             = !generalStats || generalStats.progress < 100,
            metadata                         = this.getMetadata();

        if (this.isUnmounted) {
            return;
        }

        this.manageOutdatedSearches();

        // Update metadata if they are properly set
        if (registerMetadata && metadata) {
            registerMetadata(metadata);
        }

        // Model changed update the state of search one time..
        if (modelHasChanged) {
            this.refreshSearchState();
        }

        // Update search state from api
        if (generalIsProgressing || dashboardIsProgressing) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => this.refreshSearchState(), 2000);
            return false;
        }

        return false;
    }

    /**
    * Render outdated query if it's needed
    *
    * @return JSX|false
    */
    async manageOutdatedSearches() {
        const {
                isSearchMustBeRefreshed, refreshQuery, active,
                model,
            }          = this.props,
            isOutdated = await isSearchMustBeRefreshed(model);

        if (!isOutdated || !active) {
            return false;
        }

        refreshQuery();
    }

    /**
    * On data loaded
    *
    * @param object  element                     The loaded element
    * @param object  data                        The data
    * @param boolean allElementsHaveBeenDisabled
    *
    */
    onDataLoaded() {
        this.setState({
            dataIsLoaded: true,
            metadata    : false
        });
    }

    /**
    * Refresh the current search state
    *
    * @return {Promise}
    */
    refreshSearchState() {
        const { model } = this.props;

        this.collector = dataGet(`/query/${model.id}/state`)
            .then(
                ({ body }) => {
                    !this.isUnmounted && this.setState({
                        searchState: body,
                        metadata   : false
                    });
                },
                () => false // Promise rejection
            );
    }

    /**
    * Triggered when the component is unmounted
    *
    * @return void
    */
    componentWillUnmount() {
        // Clear all setTimeout
        clearTimeout(this.fetchSearchStateTimeout);

        this.isUnmounted = true;

        if(this.collector && this.collector.cancel) {
            this.collector.cancel();
            this.collector = null;
        }

        return false;
    }

    /**
    * Return the metric tooltip as soon as it's has previously been loaded
    *
    * @return string
    */
    getMetricTooltipContent(className) {
        const elementKey = _.find(className.split(' '),
            (classname) => classname.match(/metric/)).replace(/^metric-/, '');

        // Make sure the false value is properly returned
        if (this.tooltipsContent[elementKey] === false) {
            return false;
        }

        return this.tooltipsContent[elementKey]
            || (<Skeleton active paragraph={{ rows: 1, width: 200 }}  />);
    }

    /**
    * Check if the list view is enabled
    *
    * @return void
    */
    getListsAreDisplayed() {
        const { module, member }        = this.props,
            settings                    = member.get('settings'),
            isDataScreening             = module === 'data-screening';

        return _.get(
            settings,
            `search.dashboard_settings.${module}.lists_are_displayed`,
            isDataScreening  // DataScreening show list by default
        );
    }

    /**
    * Get current dashboard Element definition
    *
    * @return object
    */
    getCurrentDashboardElement() {
        const { module, elements } = this.props,
            element                = elements.find(obj => obj.key === module && obj.category === 'Area/Dashboard');

        return element;
    }

    /**
    * Add element to the clipboard
    *
    * return Graph Component
    */
    makeClipboardItem(elementId) {
        const { elementsInstances, element } = this.state,
            elementInstance                  = elementsInstances[elementId],
            source                           = `${element.label} dashboard`;

        // Call  elementInstance addToClipboard function
        if (elementInstance && elementInstance.addToClipboard) {
            // Add element image
            elementInstance.addToClipboard({ type: 'element', source });

            // Add element list
            elementInstance.addToClipboard({
                type          : 'list',
                limitMaxForced: 1000,
                source,
            });
        }
    }

    /**
    * Get all group of elements
    *
    * @return array
    */
    getAllGroupElements() {
        const { elements }                  = this.props,
            { element }                     = this.state,
            { configuration }               = element,
            { elements: dashboardElements } = configuration,
            { graph: dashboardElementId }   = dashboardElements,
            dashboardElement                = elements.find(el => el.id === dashboardElementId),
            dashboarGroupElements           = getSubElementsFilterByCategory(
                elements,
                'Area/Dashboard/Group',
                dashboardElement
            );

        return dashboarGroupElements;
    }

    /**
    * Get all exportable elements
    *
    * @return array
    */
    getItemGroupExportableElements(GroupElement) {
        const { elements }                      = this.props,
            dashboardElement                    = elements.find(el => el.id === GroupElement.id),
            dashboarItemElements                = getSubElementsFilterByCategory(
                elements,
                'Area/Dashboard/Item',
                dashboardElement
            );

        return dashboarItemElements;
    }

    /**
    * Get all exportable elements
    *
    * @return array
    */
    getAllExportableElements() {
        const { elements }                  = this.props,
            { element }                     = this.state,
            { configuration }               = element,
            { elements: dashboardElements } = configuration,
            { graph: dashboardElementId }   = dashboardElements,
            dashboardElement                = elements.find(el => el.id === dashboardElementId),
            dashboarItemElements            = getSubElementsFilterByCategory(
                elements,
                'Area/Dashboard/Item',
                dashboardElement
            );

        return dashboarItemElements;
    }

    /**
    * Get all exportable list element ids
    *
    * @return array
    */
    getExportableElementIds() {
        const dashboarItemElements  = this.getAllExportableElements(),
            dashboarItemElementsIds = dashboarItemElements.reduce((cumul, el) => cumul.concat(el.id), []);

        return dashboarItemElementsIds;
    }

    /**
    * Export dashboard elements
    *
    * @return array
    */
    exportDashboard() {
        const  exportableElements = this.getExportableElementIds();
        exportableElements.forEach(this.makeClipboardItem);
    }

    /**
    * Get the the size of an element id
    *
    * @param {string} elementid
    *
    * @return array
    */
    getElementInfo(elementId) {
        const { elementsInstances } = this.state,
            elementInstance         = elementsInstances[elementId],
            size                    = elementInstance ? elementInstance.getSize(): [1, 1],
            insight                 = elementInstance ? elementInstance.triggerCallback('getStatsForInsights'): {};
        return {size, insight };
    }

    /**
    * Export report elements
    *
    * @return array
    */
    exportReport() {
        const { openReport }     = this.props,
            dashboarGroupElements= this.getAllGroupElements(),
            slides= dashboarGroupElements.map(element => {
                const slides   = this.getItemGroupExportableElements(element),
                    slidesModel=slides.map(slide => {
                        const { size, insight } = this.getElementInfo(slide.id),
                            { width, height }   = size,
                            originalRatio       = width / height,
                            {label} = slide;
                        return {key: slide.key, id: slide.id, label, originalRatio, width, height, insight};
                    });
                return { id: element.id, label: element.label, slides: slidesModel };
            });
        openReport({ slides, creating: true });
    }

    /**
    * Register the showbar call back
    *
    * @param cb function The showBarCb
    *
    * @return void
    */
    registerShowBarCb(cb) {
        if (_.isFunction(cb)) {
            this.showBarCb = cb;
        }
    }

    /**
    * Register the hide bar call back
    *
    * @param cb function The hideBarCb
    *
    * @return void
    */
    registerHideBarCb(cb) {
        if (_.isFunction(cb)) {
            this.hideBarCb = cb;
        }
    }

    /**
    * Store Element Props
    *
    * @return void
    */
    storeElementInstances(elementInstance) {
        const { elementsInstances } = this.state,
            { state }               = elementInstance,
            { element }             = state,
            { id }                  = element;

        elementsInstances[id] = elementInstance;

        this.setState({ elementsInstances });
    }

    /**
    * Render meta title
    *
    * @return boolean
    */
    renderMeta() {
        const { model }       = this.props,
            { element }       = this.state,
            title             = !model || !element ? 'Loading...' : [element.label];

        return (
            <Helmet>
                <title>{title}</title>
            </Helmet>
        );
    }


    /**
    * Render a dashboard element
    *
    * @return JSX
    */
    renderDashboardElement() {
        const {
                model, module,
                history,
                navigateTo, tags
            }                        = this.props,
            { element, searchState } = this.state;


        return tags && (
            <Element
                model={model}
                state={searchState}
                element={element}
                history={history}
                tags={tags}
                module={module}
                key={`${module}_${model.id}`}
                listsAreDisplayed={this.getListsAreDisplayed()}
                navigateTo={navigateTo}
                storeElementInstances={this.storeElementInstances}
                onDataLoaded={this.onDataLoaded}
            />
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {  //eslint-disable-line
        const {
                model,
                elements, navigateTo
            }                          = this.props,
            { resources, searchState } = this.state,
            { dataIsLoaded }           = this.state,
            listsAreDisplayed          = this.getListsAreDisplayed();

        // Wait for it !
        if (!elements || !resources) {
            return false;
        }

        return (
            <div
                className="dashboard-wrapper"
                key={`${model.id}-${listsAreDisplayed ? 'list' : 'graph'}`}
                ref={this.dashboardRef}
            >
                {this.renderMeta()}
                <DashboardBar
                    state={searchState}
                    key={model.id}
                    search={model}
                    navigateTo={navigateTo}
                    dataIsLoaded={dataIsLoaded}
                />
                {this.renderDashboardElement()}
            </div>
        );
    }

}

Dashboard.propTypes = {
    active                 : PropTypes.any,
    openReport             : PropTypes.func,
    isSearchMustBeRefreshed: PropTypes.func,
    learnKnowledge         : PropTypes.func,
    module                 : PropTypes.string,
    navigateTo             : PropTypes.any,
    refreshQuery           : PropTypes.func,
    registerMetadata       : PropTypes.func,
    tags                   : PropTypes.any,
    elements               : PropTypes.shape({
        filter: PropTypes.func,
        find  : PropTypes.func
    }),
    history: PropTypes.object,
    model  : PropTypes.shape({
        concept: PropTypes.any,
        label  : PropTypes.string,
        mode   : PropTypes.string,
        id     : PropTypes.any
    }),
    member: PropTypes.shape({
        get: PropTypes.func
    }),
    getModelsOfType  : PropTypes.func,
    newslettersStream: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

Dashboard.defaultProps = {
    member: null,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const member    = state.getIn(['auth', 'member']);

    return {
        knowledge        : state.getIn('knowledge'),
        newslettersStream: state.getIn(['userView', 'newsletter', 'list']),
        folderAreLoaded  : state.getIn(['userView', 'bookmark_folder', 'stats', 'modelsAreLoaded']),
        member
    };
};

export default connect(mapStateToProps, {
    openReport,
    isSearchMustBeRefreshed,
    refreshQuery,
    learnKnowledge: learn,
    openOrgunitsCollection,
    getModelsOfType
})(withRouter(Dashboard));

