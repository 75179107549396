/**
 * Sockets Actions Creator: create actions for the current member
 *
 */
import _                                from 'lodash';
import * as Sentry                      from '@sentry/react';
import * as types                       from './types/sockets';
import { io }                           from 'socket.io-client';

import {
    getAuthorizationHeaders, logoutMember
}                                       from 'store/actions/auth';
import { updateLastSearchesWithModel }  from 'store/actions/navigation';
import {
    setUserView, updateUserViewModels
}                                       from 'store/actions/userView';
import { updateDownload }               from 'store/actions/downloads';

import {makeSentryHeaders }             from '../../core/utils/api';



/**
 * Init the socket core function
 *
 * @return void
 */
const initSocketsCore = (cb) => (dispatch, getState) => {
    const authPromise = getAuthorizationHeaders(),
        member        = getState().get('auth').get('member'),
        userId        = member.get('id');

    if (!userId) {
        console.warn('No member founded to start socket handshake');
        return;
    }

    authPromise.then(
        auth => {
            addTrace(
                {
                    op  : 'app.socket.connected',
                    name: 'Socket: connect'
                },
                (span) => {
                    const sentryHeaders = makeSentryHeaders({}, span);
                    const socket = io('/user', {
                        allowEIO3   : true,
                        path        : '/ws-api/socket.io/',
                        reconnection: false,
                        transports  : ['polling', 'websocket'],   // Use WebSocket classic upgrade
                        extraHeaders: {
                            Authorization: auth.Authorization,
                            ...sentryHeaders
                        },
                        query: {
                            jwt: auth.Authorization.replace('Bearer ', ''),
                            ...sentryHeaders
                        }
                    });

                    console.log('- WebSockets initialized');

                    cb(socket);

                    dispatch({ type: types.WEB_SOCKETS_INITIALIZED, payload: socket });
                }
            );
        }
    );
};


/**
 * Reconnect socket IO
 */
const reConnect = (dispatch, getState) => {
    setTimeout(() => {
        initSockets()(dispatch, getState);
    }, 5000);
};

/**
 * Init the socket
 *
 * @returns void
 */
export const initSockets = () => (dispatch, getState) => { // eslint-disable-line max-lines-per-function
    initSocketsCore(socket => {  // eslint-disable-line max-lines-per-function
        socket.on('connect', () => {
            console.log('Socket connected');
        });

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
            reConnect(dispatch, getState);
        });

        socket.on('auth_error', (error) => {
            console.log(`Authentication error: ${error.code} - ${error.message}`);
            logoutMember()(dispatch, getState);
        });

        socket.io.on('close', () => {
            console.log('Socket closed');
        });

        socket.on('connect_error', (e) => {
            console.log('Socket connection error', e, JSON.stringify(e));
            reConnect(dispatch, getState);
        });

        socket.on('api.user-view.touched', (payload) => {
            const { headers } = payload;
            addTrace(
                {
                    headers,
                    op  : 'app.socket.userview.touched',
                    name: 'Socket: input message: User view.touched',
                },
                (span) => {
                    setUserView(payload, span)(dispatch, getState);
                }
            );
        });

        socket.on('api.models.updated', (payload) => {
            const { models, headers } = payload;

            addTrace(
                {
                    headers,
                    op  : 'app.socket.models.updated',
                    name: 'Socket: input message: Models updated'

                },
                () => {
                    updateUserViewModels(models)(dispatch, getState);

                    // TODO: move this code block into updateUserViewModels
                    _.forEach(models, (model) => {
                        if (model.type === 'query') {
                            updateLastSearchesWithModel(model)(dispatch, getState);
                        }
                        if (model.type === 'file') {
                            updateDownload(model)(dispatch, getState);
                        }
                    });
                }
            );
        });

        socket.on('api.model.created', (payload) => {
            const { model, headers } = payload;

            addTrace(
                {
                    headers,
                    op  : 'app.socket.model.created',
                    name: 'Socket: input message: Model created'

                },
                () => {
                    if (model.type === 'file') {
                        updateDownload(model)(dispatch, getState);
                    }
                }
            );
        });
    })(dispatch, getState);
};


/**
 * Adds a trace to the Sentry distributed tracing system.
 *
 * @param {Object} headers - The headers from the request, containing the Sentry trace and baggage information.
 * @param {string} op - The operation name for the Sentry span.
 * @param {string} name - The name for the Sentry span.
 * @param {function} callback - The callback function to be executed within the Sentry span.
 */
const addTrace = (options, callback) => {
    const { headers, op, name } = options,
        {
            'sentry-trace': sentryTrace,
            baggage

        } = headers || {};

    //  Manage sentry distributed Tracing
    Sentry.continueTrace({ sentryTrace, baggage }, () => {
        Sentry.startSpan(
            {
                op,
                name,
                forceTransaction: true
            },
            (span) => {
                try {
                    callback(span);
                } catch (e) {
                    Sentry.captureException(e);
                }
            }
        );
    });
};

/**
 * Takes an event name and a payload and emits the event to event-concentrator
 *
 * @return void
 */
export const emitEvent = (payload) => (dispatch) => {
    dispatch({ type: types.WEB_SOCKETS_EMIT_EVENT, payload });
};


// Export default
export default { initSockets, emitEvent };
