import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import PropTypes                from 'prop-types';
import _                        from 'lodash';

// Import renderers components
import { Icon }                      from 'helpers';
import ModelAction                   from 'helpers/Action/Model';
import Row                           from 'helpers/Entity/Row';
import Modal                         from 'helpers/Entity/Modal';
import Card                          from 'helpers/Entity/Card';
import Inline                        from 'helpers/Entity/Inline';
import Block                         from 'helpers/Entity/Block';
import DetailedCard                  from 'helpers/Entity/DetailedCard';
import YoomapSendOrganization        from 'helpers/Yoomap/SendOrganization';
import {
    getUserViewItemFromModel,
    getDataEntity
}                                    from 'store/actions/userView';
import { bookmarkIsInScopeOfSearch } from 'store/actions/userView/bookmarks';

const RENDERERS = {
    row         : Row,
    modal       : Modal,
    card        : Card,
    inline      : Inline,
    block       : Block,
    detailedCard: DetailedCard,
};

/**
* Manage to display an entity from its model and a render
*
*/
class Entity extends PureComponent {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClick', 'renderBookmarkAction', 'renderYoomapAction', 'renderBookmarkedIcon', 'renderSendYoomapIcon');

        this.state = {
            isInScope   : false,
            userViewItem: false,
        };
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.updateUserViewItem();
    }


    /**
    * Component Did Update
    */
    componentDidUpdate(prevProps, prevState) {
        this.updateUserViewItem(prevProps, prevState);
    }


    /**
    * Component Did Update
    */
    updateUserViewItem(prevProps, prevState = {}) {
        const {
                entity,
                sourceModel,
                getUserViewItemFromModel,
                bookmarkIsInScopeOfSearch
            }                                 = this.props,
            { userViewItem: prevUserViewItem} = prevState,
            { isLoading }                     = entity,
            userViewItem                      = !isLoading && getUserViewItemFromModel(entity, 'bookmark');

        if(!isLoading && userViewItem !== prevUserViewItem) {
            const { settings }            = sourceModel || {},
                { bookmarkFolderForTags } = settings || {},
                isInScope                 = bookmarkIsInScopeOfSearch(userViewItem, bookmarkFolderForTags);

            this.setState({userViewItem, isInScope});
        }
    }

    /**
    * Callback triggered when the user clicks on a entity
    *
    * return false
    */
    onClick(model, collection) {
        const { entity, onClick, disableModal } = this.props,
            isEvent  = !_.isUndefined(model?.stopPropagation);

        // Don't open entity
        if (!onClick || disableModal) {
            return true;
        }

        // Stop bubbling
        if (isEvent) {
            model.stopPropagation();
        }

        // From collection
        if (collection) {
            onClick(model, collection);
            return false;
        }

        // Specify model
        if (!isEvent && model) {
            onClick(model);
            return false;
        }


        onClick(entity);

        return false;
    }

    /**
    * The entity is a bookmark
    *
    * @param {object} bookmark
    *
    * @returns {boolean}
    */
    isABookmark() {
        const { entity } = this.props,
            { type }     = entity;

        return type === 'bookmark';
    }


    /**
     * Render the star icon
     */
    renderBookmarkedIcon() {
        const { entity }                = this.props,
            { isInScope, userViewItem } = this.state,
            typeSize                    = 16,
            isBookmarked                = !!userViewItem,
            dataEntity                  = getDataEntity(entity),
            { label }                   = dataEntity || {},
            title                       = (isBookmarked ? `${label} is bookmarked` : '')
                + (isBookmarked && !isInScope ? ' but not in the specified folder' : '');

        return (
            <Icon
                id="bookmarked"
                theme={isInScope || !isBookmarked ? 'filled' : 'outlined'}
                color={isBookmarked ? '#ffb400' : '#ccc'}
                width={typeSize}
                title={title}
                className={`${!isBookmarked ? 'hide' : ''}`}
            />
        );
    }

    /**
    * Render the icon of the entity
    *
    * @return JSX
    */
    renderBookmarkAction() {
        const { entity }     = this.props,
            { userViewItem } = this.state,
            isBookmarked     = !!userViewItem;

        return !this.isABookmark() && (
            <div className={`entity-bookmark ${isBookmarked ? 'bookmarked' : ''}`}>
                <ModelAction
                    key={entity.id}
                    dataQaKey="star"
                    onlyActions={[]}
                    clickAction={['add_bookmark']}
                    entity={entity}
                    forceRender
                    childrenRender={this.renderBookmarkedIcon}
                />
            </div>
        );
    }


    /**
     * Render the send to yoomap icon
     */
    renderSendYoomapIcon() {
        const { entity } = this.props;

        return (
            <YoomapSendOrganization
                key="send" entity={entity}
                renderIconMode
            />
        );
    }

    /**
    * Render the icon of the entity
    *
    * @return JSX
    */
    renderYoomapAction() {
        const { entity, platforms } = this.props,
            hasPlatforms            = platforms && platforms.size > 0;

        return hasPlatforms && (
            <div className="entity-yoomap">
                <ModelAction
                    key={entity.id}
                    dataQaKey="yoomap-startup"
                    onlyActions={[]}
                    clickAction={['add_yoomap']}
                    entity={entity}
                    forceRender
                    childrenRender={this.renderSendYoomapIcon}
                />
            </div>
        );
    }

    /**
    * Render the entity
    *
    * @return Component
    */
    render() {
        const {
                render, entity
            }               = this.props,
            {
                isInScope,
                userViewItem,
            }               = this.state,
            EntityComponent = RENDERERS[render],
            featuredActions = [this.renderBookmarkAction],
            { type }        = entity,
            isOrgunit       = type === 'orgunit';

        if (!EntityComponent) {
            throw new Error(`Entity render: ${render} does not exists.`);
        }

        if (isOrgunit) {
            featuredActions.push(this.renderYoomapAction);
        }

        return (
            <EntityComponent
                {...this.props}
                onClick={this.onClick}
                isInScope={isInScope}
                userViewItem={userViewItem}
                featuredActionsRender={featuredActions}
            />
        );
    }

}

Entity.propTypes = {
    // Renderers
    render: PropTypes.oneOf([
        'row',
        'modal',
        'card',
        'inline',
        'block',
        'detailedCard',
    ]).isRequired,
    bookmarkIsInScopeOfSearch: PropTypes.func,
    disableModal             : PropTypes.any,
    entity                   : PropTypes.object,
    platforms                : PropTypes.object,
    getUserViewItemFromModel : PropTypes.func,
    onClick                  : PropTypes.func,
    sourceModel              : PropTypes.object,
};

Entity.defaultProps = {
    onClick: null
};


/**
 * Bind the store to to component
 */
const mapStateToProps = state => ({
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
    platforms    : state.getIn(['auth', 'platforms']),
});

export default connect(mapStateToProps, {
    getUserViewItemFromModel,
    bookmarkIsInScopeOfSearch,
})(Entity);
