import Immutable     from 'immutable';
import types         from '../actions/types/downloads';
import { deepEqual } from 'utils/object';

/**
* Reset the whole downloads list
*
* @return Immutable.Map
*/
const resetList = (state, downloads) => {
    const map = state.map || new Immutable.Map(),
        downloadsAsObject = map.toJS();

    let mapUpdated = false;

    if (!downloads) {
        return state;
    }

    // Set all downloads as a map
    downloads.forEach && downloads.forEach((download) => {
        if (!deepEqual(downloadsAsObject[download.id], download)) {
            downloadsAsObject[download.id] = download;
            mapUpdated = true;
        }
    });

    return {
        ...state,
        map: mapUpdated ? new Immutable.Map(downloadsAsObject) : map,
    };
};

/**
* Reset the whole downloads list
*
* @return Immutable.Map
*/
const nextDownloadsList = (state, downloads) => {
    const map = state.map || new Immutable.Map(),
        downloadsAsObject = map.toJS();

    if (!downloads) {
        return state;
    }

    // Set all downloads as a map
    downloads.forEach && downloads.forEach((download) => {
        downloadsAsObject[download.id] = download;
    });

    return {
        ...state,
        map: new Immutable.Map(downloadsAsObject),
    };
};


/**
* Remove an download to the local storage
*
* @return Immutable.Map
*/
const removeDownload = (state, download) => {
    const map = state.map || new Immutable.Map();

    return { ...state, map: map.delete(download.id) };
};


/**
* Update an download to the local storage
*
* @return Immutable.Map
*/
const updateDownload = (state, download) => {
    const map = state.map || new Immutable.Map();

    return { ...state, map: map.set(download.id, download) };
};


/**
* Remobe an download to the local storage
*
* @return Immutable.Map
*/
const updateThumbs = (state, thumb) => {
    const stateThumbs                = state.thumbs || new Immutable.Map(),
        existingThumb                = stateThumbs.get(thumb.id),
        { downloaded: isDownloaded } = existingThumb || {},
        { downloaded, downloading }  = thumb,
        newThumb                     = { downloaded, downloading },
        newThumbs                    = stateThumbs.set(thumb.id, newThumb);

    return { ...state, thumbs: existingThumb && isDownloaded ? stateThumbs : newThumbs };
};

/**
* Manage an immutable identity map for all models returned by the API
*
* @return state As a fresh Immutable Map
*/
const Downloads = (state = {}, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the state with API synced member data
        case types.DOWNLOADS_RESET_LIST:
            return resetList(state, action.payload);

        // Update the state with API synced member data
        case types.DOWNLOADS_REMOVE:
            return removeDownload(state, action.payload);

        case types.DOWNLOADS_UPDATE_THUMBS:
            return updateThumbs(state, action.payload);

        case types.DOWNLOADS_NEXT_LIST:
            return nextDownloadsList(state, action.payload);

        case types.DOWNLOAD_UPDATE:
            return updateDownload(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Downloads;
