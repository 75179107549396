// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-editor {
  position: fixed;
  top: 60px;
  left: 40px;
  right: 40px;
  bottom: 10px;
  background: #FFF;
}
.element-editor .element {
  padding: 10px;
  text-align: center;
  position: absolute;
  left: 10%;
  right: 10px;
  top: 0px;
  bottom: 0px;
  background: #DDD;
}
.element-editor .element > div {
  border: 1px solid #666;
  box-shadow: 0 0 4px #333;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Element/assets/editor.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAPA;EAUQ,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,gBAAA;AAAR;AAEQ;EACI,sBAAA;EACA,wBAAA;AAAZ","sourcesContent":[".element-editor {\n    position  : fixed;\n    top       : 60px;\n    left      : 40px;\n    right     : 40px;\n    bottom    : 10px;\n    background: #FFF;\n\n    .element {\n\n        padding   : 10px;\n        text-align: center;\n        position  : absolute;\n        left      : 10%;\n        right     : 10px;\n        top       : 0px;\n        bottom    : 0px;\n        background: #DDD;\n\n        & > div {\n            border: 1px solid #666;\n            box-shadow: 0 0 4px #333;\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
