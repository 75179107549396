import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import Icon                 from 'helpers/Icon';
import { makeStrClassName } from 'utils/text';

import './assets/toggle-children.less';


/**
* @return JSX
*/
const ToggleChildren = ({ children, open, suffix, title }) => { // eslint-disable-line  max-lines-per-function
    const [show, setShow] = useState(open),
        classNames        = ['children', {show}];

    /**
    * Toggle between show and hide children
    */
    const toggleShow = () => {
        setShow(!show);
    };

    return (
        <div className="toggle-children">
            <div className="title">
                <div className="left-part" onClick={toggleShow}>
                    <Icon type="right-arrow" height={16}
                        className={show ? 'open' : ''}
                    />
                    {title}
                </div>
                {show && suffix}
            </div>

            <div className={makeStrClassName(classNames)}>
                {children}
            </div>
        </div>
    );
};

ToggleChildren.propTypes = {
    children: PropTypes.object,
    open    : PropTypes.bool,
    suffix  : PropTypes.object,
    title   : PropTypes.string.isRequired,

};

ToggleChildren.defaultProps = {
    children: {},
    open    : false,
};

export default ToggleChildren;
