// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list .row.expert.expanded-row .authorities {
  white-space: normal;
  padding-top: 4px;
}
.list .row.expert.expanded {
  height: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/expert.less"],"names":[],"mappings":"AAGI;EAEQ,mBAAA;EACA,gBAAA;AAHZ;AASI;EACI,YAAA;AAPR","sourcesContent":[".list .row.expert {\n\n\n    &.expanded-row {\n        .authorities {\n            white-space: normal;\n            padding-top: 4px;\n            // line-height: 20px;\n            // height     : 100%;\n        }\n    }\n\n    &.expanded {\n        height: 48px;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
