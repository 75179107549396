import Immutable      from 'immutable';
import _              from 'lodash';
import * as types     from '../actions/types/entityMap';
import { initialMap } from '../initialStates';

/**
 * Update the current slice with the new entity
 *
 * @return {Immutable.Map}
 */
const updateEntity = (state, entity) =>  {
    // Convert a JSON object as Map
    const entityAsMap    = entity instanceof Immutable.Map ? entity : new Immutable.Map(entity),
        entityType       = entityAsMap.get('public_type'),
        entityTypeExists = !_.isUndefined(state.get(entityType)),
        newState         = entityTypeExists ? state : state.set(entityType, new Immutable.Map()),
        finalState       = newState.setIn([entityAsMap.get('public_type'), entityAsMap.get('id')], entityAsMap);

    // Store the new immutable object in the slice
    return finalState;
};

/**
 * Update the current slice with the new entity
 *
 * @return {Immutable.Map}
 */
const updateEntities = (state, entities) => {
    let finalState = state;
    _.each(entities, (entity) => {
        finalState = updateEntity(finalState, entity);
    });

    return finalState;
};

/**
 * Update the current slice with the new entity
 *
 * @param {Immutable.Map} state  The entity map slice
 * @param {Immutable.Map} entity An entity to remove from entity map
 *
 * @return {Immutable.Map}
 */
const removeEntity = (state, entity) => state.delete(entity.get('id'));

/**
 * Manage an immutable identity map for all models returned by the API
 *
 * @return state As a fresh Immutable Map
 */
const EntityMap = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the state with API synced member data
        case types.UPDATE_ENTITY:
            return updateEntity(state, action.payload);

        // Update the state with API some entities
        case types.UPDATE_ENTITIES:
            return updateEntities(state, action.payload);

        // Remove the entity from the current state
        case types.REMOVE_ENTITY:
            return removeEntity(state, action.payload);

        default:
            break;
    }

    return state;
};

export default EntityMap;
