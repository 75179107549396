// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Main Insight no data
*
*/
.noData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.noData .noDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.noData .noDataContent .text {
  line-height: 20px;
  font-size: 11px;
  text-align: center;
}
.haveIssueOnRetrieveListData-message {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px auto;
}
.haveIssueOnRetrieveListData-message .anticon-warning {
  color: red;
  margin-right: 5px;
}
.haveIssueOnRetrieveListData-message span {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/no-data.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAJA;EAOQ,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AAAR;AAVA;EAaY,iBAAA;EACA,eAAA;EACA,kBAAA;AAAZ;AAKA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,gBAAA;AAHJ;AADA;EAOQ,UAAA;EACA,iBAAA;AAHR;AALA;EAYQ,2BAAA;AAJR","sourcesContent":["/**\n* Main Insight no data\n*\n*/\n\n.noData {\n    width      : 100%;\n    height     : 100%;\n    display    : flex;\n    align-items: center;\n\n    .noDataContent {\n        display       : flex;\n        flex-direction: column;\n        align-items   : center;\n        margin        : auto;\n\n        .text {\n            line-height: 20px;\n            font-size  : 11px;\n            text-align : center;\n        }\n    }\n}\n\n.haveIssueOnRetrieveListData-message {\n    display    : flex;\n    align-items: center;\n    gap        : 5px;\n    margin     : 5px auto;\n\n    .anticon-warning {\n        color       : red;\n        margin-right: 5px;\n    }\n\n    span {\n        color: var(--primary-color);\n    }\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
