import _            from 'lodash';
import React        from 'react';
import Smart        from './Inputs/Smart/Sentence';
import Express      from './Inputs/Express/Sentence';
import Intelligence from './Inputs/Intelligence/Sentence';

export default {
    smart       : Smart,
    express     : Express,
    intelligence: Intelligence,
    classic     : (props => (
        <div className="sentence">
            <span>
                {_.capitalize(props.label)}
            </span>
        </div>
    )),
};

