import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import Icon                 from '../Icon';
import { capitalize }  from '../../core/utils/text';

import './assets/trendInformation.less';

/**
 * The bar graph Component
 *
 */
class TrendInformation extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - fontFactor to scale too big fontSize
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps) {
        const { width, rateFontSize, fontSize } = nextProps,
            fontFactor                          = width > 380 ? 1 : (0.30 * (width - 250) / (380 - 250)) + 0.6,
            state = {
                rateFontSizeResized: rateFontSize * fontFactor,
                fontSizeResized    : fontSize * fontFactor,
            };

        return state;
    }

    /**
    * Render the trend informations lines
    *
    * @return html
    */
    renderTrendInformationLines() {
        const { color, selectedPixelRange } = this.props,
            { rateFontSizeResized }         = this.state,
            top                             = Math.round(rateFontSizeResized * 1.5) || 0,
            min                             = !selectedPixelRange ? { x: 0, y: 0 } : selectedPixelRange.min,
            max                             = !selectedPixelRange ? { x: 0, y: 0 } : selectedPixelRange.max,
            dropOffset                      = Math.round((max.x - min.x) / 2) || 0,
            width                           = (min.x + dropOffset) || 0,
            height                          = (top + dropOffset) || 0;

        return width && height && (
            <svg className="trend-lines" style={{ width, height }}>
                <line
                    x1={0}
                    x2={min.x}
                    y1={top}
                    y2={top}
                    strokeWidth={1}
                    strokeDasharray="2"
                    style={{
                        stroke: color,
                    }}
                />
                <line
                    className="slope"
                    x1={min.x}
                    x2={width}
                    y1={top}
                    y2={height}
                    strokeWidth={1}
                    strokeDasharray="2"
                    style={{
                        stroke: color,
                    }}
                />
            </svg>
        );
    }

    /**
    * Render arrow
    *
    * @return html
    */
    renderArrow() {
        const { data, color }       = this.props,
            { rateFontSizeResized } = this.state,
            meaningsRotate          = { up: -45, steady: 0, down: 45 };

        return (
            <Icon
                id="graph/trendArrow"
                title={data.stats.rateLabel}
                alt={data.stats.rateLabel}
                width={rateFontSizeResized * 0.75}
                height={rateFontSizeResized * 0.75}
                rotate={meaningsRotate[data.stats.rateMeaning]}
                color={color}
                style={{
                    margin: ` 0 ${rateFontSizeResized / 5}px `,
                }}
            />
        );
    }

    /**
    * Render informations
    *
    * @return html
    */
    renderRateInformation() {
        const { data }              = this.props,
            { decimalNumber }       = this.props,
            { rateFontSizeResized } = this.state;

        return (
            <div className="rate-information" style={{ lineHeight: `${Math.round(rateFontSizeResized * 1.5)}px` }}>
                <span style={{ fontSize: `${rateFontSizeResized}px`, fontWeight: 'bold' }}>
                    {capitalize(data.stats.trendLabel, true)}
                </span>
                {
                    data.stats.separator ? (
                        <span style={{ fontSize: `${rateFontSizeResized}px`, margin: `${rateFontSizeResized / 3}px` }}>
                            {data.stats.separator}
                        </span>
                    ) : null
                }
                {
                    data.stats.separator ? (
                        <span style={{ fontSize: `${rateFontSizeResized}px`, fontWeight: 'bold' }}>
                            {data.stats.rateLabel}
                        </span>
                    ) : null
                }
                {this.renderArrow()}
                {_.isNumber(data.stats.rate) ? (
                    <span style={{ fontSize: `${rateFontSizeResized}px` }}>
                        {`${data.stats.rate.toFixed(decimalNumber)} %`}
                    </span>
                ) : null }
            </div>

        );
    }

    /**
    * Render the trend informations
    *
    * @return html
    */
    render() {
        const {
                selectedRange, color,
                margin, data,
            }                        = this.props,
            { stats }                = data,
            { disableSelection }     = stats || {},
            {
                rateFontSizeResized,
                fontSizeResized,
            }                        = this.state,
            minX                     = _.get(selectedRange, 'min.x'),
            maxX                     = _.get(selectedRange, 'max.x'),
            last                     = maxX - minX;

        return (
            last !== 0 && !_.isNaN(last) && !disableSelection
            && _.isNumber(minX) && _.isNumber(maxX)
        ) && (
            <div
                className="trend-information"
                style={{
                    color,
                    top       : `${margin}px`,
                    left      : `${margin}px`,
                    lineHeight: `${rateFontSizeResized}px`
                }}
            >
                <div className="rate">
                    {this.renderTrendInformationLines()}
                    {this.renderRateInformation()}
                    <div
                        className="rate-explain"
                        style={{
                            fontSize : `${fontSizeResized}px`,
                            marginTop: '4px'
                        }}
                    >
                        {`between ${minX} and ${maxX}`}
                    </div>
                </div>
            </div>
        );
    }

}

TrendInformation.propTypes = {
    data              : PropTypes.shape().isRequired,
    width             : PropTypes.number.isRequired,
    selectedRange     : PropTypes.shape({ min: PropTypes.object, max: PropTypes.object }).isRequired,
    selectedPixelRange: PropTypes.shape({ min: PropTypes.object, max: PropTypes.object }).isRequired,
    margin            : PropTypes.number,
    fontSize          : PropTypes.number,
    rateFontSize      : PropTypes.number,
    decimalNumber     : PropTypes.number,
    color             : PropTypes.string,
};

TrendInformation.defaultProps = {
    fontSize     : 9,
    rateFontSize : 14,
    margin       : 20,
    color        : 'var(--insight-color)',
    decimalNumber: 0,
};

export default TrendInformation;

