import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Skeleton }         from 'antd';

import { Row }              from 'helpers';

/**
 * Technical domains of a patent
 */
class TechnicalDomains extends Component {

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Render the Component
    *
    */
    render() {
        const { entity } = this.props;

        if (entity === null || !entity.TECD) {
            return (
                <div className="technical-domains textual">
                    <Skeleton active />
                </div>
            );
        }

        const explodedTECD = entity.TECD.split('<br/>');

        return (
            <Row className="technical-domains textual" label="Technical Domains"
                key="images"
            >
                <p>{explodedTECD.join(', ')}</p>
            </Row>
        );
    }

}

TechnicalDomains.propTypes = {
    entity: PropTypes.shape({
        TECD: PropTypes.string
    })
};

TechnicalDomains.defaultProps = {
    entity: null
};

export default TechnicalDomains;
