// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-component {
  margin: 0 auto;
  background-color: transparent;
  overflow: hidden;
  height: 100%;
}
.grid-content {
  display: grid;
  padding: 10px;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-template-rows: repeat(auto-fill, 200px);
  grid-gap: 10px;
  grid-auto-rows: 0;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}
.grid-content .grid-element {
  height: 100%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Collection/Grid/assets/main.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,6BAAA;EACA,gBAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,aAAA;EACA,YAAA;EACA,+CAAA;EACA,4CAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,6BAAA;AAAJ;AAVE;EAaM,YAAA;EACA,gBAAA;AAAR","sourcesContent":[".grid-component {\n    margin          : 0 auto;\n    background-color: transparent;\n    overflow        : hidden;\n    height          : 100%;\n  }\n\n  .grid-content {\n    display              : grid;\n    padding              : 10px;\n    height               : 100%;\n    grid-template-columns: repeat(auto-fill, 140px);\n    grid-template-rows   : repeat(auto-fill, 200px);\n    grid-gap             : 10px;\n    grid-auto-rows       : 0;\n    align-content        : center;\n    align-items          : center;\n    justify-content      : space-evenly;\n\n    .grid-element {\n        height  : 100%;\n        overflow: hidden;\n    }\n  }\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
