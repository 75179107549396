/**
 * Downloads Actions Creator: create actions to store entities
 *
 */
import types                     from './types/downloads';
import {
    del,
    get,
    patch,
} from '../../core/utils/api';
import notification              from 'helpers/notification.js';
import { debounce }              from 'lodash';

let gettingDownloads  = false,
    itemsToDeletePool = [];

const type       = 'file';
const imagesBlob = {};

/**
* Refresh the whole download list
*
* @return {Promise}
*/
export const resetDownloadsList = () => (dispatch, getState) => {
    if (gettingDownloads) {
        return;
    }
    gettingDownloads = true;

    get('/files', { data: {hidden: false} }).then(({ body }) => {
        dispatch({
            type   : types.DOWNLOADS_RESET_LIST,
            payload: body
        });

        gettingDownloads = false;
    });

    // Get in_error files
    const state        = getState(),
        downloads      = state.get('downloads').map,
        filesInLoading = downloads && downloads.filter(download =>  !download.date_ready && !download.in_error);

    if (filesInLoading && filesInLoading.size > 0) {
        filesInLoading.forEach(file => {
            get(`/files/${file.id}`).then((response) => {
                const { body } = response;
                dispatch({ type: types.DOWNLOAD_UPDATE, payload: body });
            });
        });
    }
};

/**
* Refresh the whole download list
*
* @return {Promise}
*/
export const nextDownloadsList = (cb) => (dispatch, getState) => {
    if (gettingDownloads) {
        return;
    }
    gettingDownloads = true;

    const state   = getState(),
        downloads = state.get('downloads').map;

    get(
        '/files',
        { data: {
            hidden      : false,
            'limit[min]': downloads.size,
            'limit[max]': downloads.size + 25
        } }
    ).then(({ body }) => {
        dispatch({
            type   : types.DOWNLOADS_RESET_LIST,
            payload: body
        });
        gettingDownloads = false;
        cb && cb(body && body.length === 0, true);
    });
};

// TODO: remove or refact
/**
* Update thumbs
*
* @return {Promise}
*/
export const updateDownloadsThumbs = () => (dispatch, getState) => {
    const state   = getState(),
        downloads = state.get('downloads').map,
        { thumbs } = state.get('downloads');

    if (!downloads) { return; }

    let thumbToFetchIndex = 0;

    downloads.forEach((download) => {
        const thumb = thumbs ? thumbs.get(download.id) : null,
            { metadata, date_ready } = download;


        if (date_ready &&  metadata.mimetype === 'image/png' && !thumb) {
            dispatch({
                type   : types.DOWNLOADS_UPDATE_THUMBS,
                payload: { id: download.id, downloading: true },
            });

            setTimeout(
                () => {
                    get(`/files/${download.id}/file`, {}).then((response) => {
                        imagesBlob[download.id] = response.body;

                        dispatch({
                            type   : types.DOWNLOADS_UPDATE_THUMBS,
                            payload: { id: download.id, downloading: false, downloaded: true }
                        });
                    });
                },
                100 * thumbToFetchIndex
            );
            thumbToFetchIndex++;
        }
    });
};



/**
* Relaunch download thumbs
*
* @return {Promise}
*/
export const relaunchDownload = (download) => (dispatch) => {
    const { metadata, id } = download,
        { factory_id }     = metadata;

    get(`/export/${factory_id}`, {}).then((response) => {
        const { body } = response,
            { label }  = body;

        notification({
            message : `Export '${label}' relaunched.`,
            duration: 20
        });

        patch(`/files/${id}`, {
            relaunch: true,
        }).then((response) => {
            const { body } = response;
            dispatch({ type: types.DOWNLOAD_UPDATE, payload: body });
        });
    });
};

/**
* Relaunch download thumbs
*
* @return {Promise}
*/
export const updateDownload = (download) => (dispatch) => {
    dispatch({ type: types.DOWNLOAD_UPDATE, payload: download });
};

/**
 * Get image blob for static imageBlob
 */
export const getImageBlobById = (id) => () => {
    return imagesBlob[id];
};

/**
* Remove downloads
*
* @return {Promise}
*/
export const removeDownload = (model) => (dispatch, getState) => {
    const state          = getState(),
        downloads        = state.get('downloads').map,
        downloadToDelete = downloads.find((download) => download.id === model.id);

    del(`/files/${downloadToDelete.id}`)
        .then(() => dispatch({ type: types.DOWNLOADS_REMOVE, payload: model }));
};

/**
* Remove items from downloads
*
* @return {Promise}
*/
export const removeDownloads = models => dispatch => {
    models.forEach(item => {
        dispatch({
            type   : types.DOWNLOADS_REMOVE,
            payload: { ...item, date_delete: new Date() }
        });

        itemsToDeletePool.push({ id: item.id, type});
    });

    bulkDeleteFromPool();
};

// TODO refact downloadUserView
/**
* Send bulk delete
*
* @return debounced function
*/
const bulkDeleteFromPool = debounce(() => {
    const models = itemsToDeletePool;
    del('/bulk', { body: { models }});
    itemsToDeletePool = [];
}, 1000);

// Export default
export default {
    resetDownloadsList,
    updateDownloadsThumbs,
    nextDownloadsList,
    getImageBlobById,
    relaunchDownload,
    updateDownload,
};
