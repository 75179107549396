// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./little-skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Pie graph
*
*/
.Pie {
  position: relative;
}
.Pie > svg {
  position: absolute;
  transition: transform 200ms ease-in;
}
.Pie > svg g.pie path title tspan:nth-child(2n) {
  display: block;
}
.Pie .labels {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 200ms ease-in;
}
.Pie .label {
  text-align: center;
  word-wrap: initial;
}
.Pie .label.right {
  text-align: left;
}
.Pie .label.left {
  text-align: right;
}
.Pie .label > span {
  display: flex;
  flex-direction: column;
}
.Pie .label > span span {
  line-height: normal;
}
.Pie .label,
.Pie .legend {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.Pie .legend div {
  line-height: 1.15;
  text-align: right;
}
.Pie.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Pie/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAEI,kBAAA;AADJ;AADA;EAKQ,kBAAA;EACA,mCAAA;AADR;AALA;EAQY,cAAA;AAAZ;AARA;EAaQ,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mCAAA;AAFR;AAhBA;EAsBQ,kBAAA;EACA,kBAAA;AAHR;AAKQ;EAAU,gBAAA;AAFlB;AAGQ;EAAU,iBAAA;AAAlB;AA1BA;EA6BY,aAAA;EACA,sBAAA;AAAZ;AA9BA;EAiCgB,mBAAA;AAAhB;AAjCA;;EAuCQ,kBAAA;EACA,QAAA;EACA,6BAAA;AAFR;AAvCA;EA6CQ,iBAAA;EACA,iBAAA;AAHR;AAMI;EACI,mDAAA;AAJR","sourcesContent":["/**\n* Pie graph\n*\n*/\n\n.Pie {\n\n    position: relative;\n\n    > svg {\n        position: absolute;\n        transition :transform  200ms ease-in;\n        g.pie path title tspan:nth-child(2n) {\n            display: block;\n        }\n    }\n\n    .labels {\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        transition :transform  200ms ease-in;\n    }\n\n    .label {\n        text-align: center;\n        word-wrap: initial;\n\n        &.right { text-align: left }\n        &.left  { text-align: right }\n\n        > span {\n            display       : flex;\n            flex-direction: column;\n\n            span {\n                line-height: normal;\n            }\n        }\n    }\n\n    .label, .legend {\n        position: absolute;\n        top: 50%;\n        transform: translate( 0, -50%);\n    }\n\n    .legend div {\n        line-height: 1.15;\n        text-align: right;\n    }\n\n    &.skeleton {\n        mask-image:  url('./little-skeleton.svg');\n    }\n\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
