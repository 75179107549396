/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import { Entity }           from 'helpers';
import { learn }            from 'store/actions/knowledge';
import FilterValues         from 'helpers/Filters/FilterValues';

import './assets/sentence.less';

/**
* Render The "Sentence" menu
*
*/
class Sentence extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);
        this.state = {
            id: _.uniqueId()
        };

        this.ref= React.createRef();
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        this.learnKnowledge();
    }

    /**
    * Learn all the knowledge required to render the content.
    */
    learnKnowledge() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['timeframes', 'filters']).then(this.setState.bind(this));
    }

    /**
    * Render the beginning of the sentence
    *
    * @return JSX
    */
    renderBegin() {
        const { lookingFor } = this.props;

        return lookingFor ? "I'm looking for" : '';
    }

    /**
    * Is there some companies ?
    *
    * @return bool
    */
    companiesInputsCount() {
        const { inputs }    = this.props,
            companiesInputs = inputs.filter((input) => input.type === 'orgunit');

        return companiesInputs.length;
    }

    /**
    * Is there some companies ?
    *
    * @return bool
    */
    conceptsInputsCount() {
        const { inputs }    = this.props,
            conceptsInputs = inputs.filter((input) => input.type === 'concept');

        return conceptsInputs.length;
    }

    /**
    * Render the  part of the sentence
    *
    * @return JSX
    */
    renderCompanies() {
        const { inputs }    = this.props,
            companiesInputs = inputs.filter((input) => input.type === 'orgunit');

        if (!companiesInputs.length) {
            return false;
        }

        // Create the company content array
        const companiesContents = companiesInputs.map((companyInput) => {
            const { customs, source } = companyInput,
                hasCustomContent      = customs.length > 0,
                customContents        = hasCustomContent
                    ? customs.map((custom) => custom && (
                        <span key={custom.id} className="custom">
                            <Entity render="inline" entity={custom} />
                        </span>
                    )).reduce((prev, curr) => [prev, ', ', curr])
                    : false,
                customContentString = ['(or ', customContents, ')'];

            return source && (
                <span key={source.id} className="orgunit">
                    <span className="source">
                        <Entity render="inline" entity={source} />
                    </span>
                    &apos;s profile
                    {hasCustomContent && (
                        <span className="customs">
                            {customContentString}
                        </span>
                    )}
                </span>
            );
        });

        return (
            <span className="orgunits">
                {companiesContents.reduce((prev, curr) => [prev, ', ', curr])}
            </span>

        );
    }

    /**
    * Render the concept part of the sentence
    *
    * @return JSX
    */
    renderConcepts() {  // eslint-disable-line max-lines-per-function
        const { inputs, explain } = this.props,
            conceptsInputs       = inputs.filter((input) => input.type === 'concept');

        if (!conceptsInputs.length) {
            return false;
        }

        // Create the concept content array
        const conceptsContents = conceptsInputs
                .sort((concept) => (concept.operator === 'AND' ? 0 : 1))
                .map((conceptInput) => {
                    const { customs, source } = conceptInput,
                        hasCustomContent      = customs.length > 0,
                        isNot                 = conceptInput.operator === 'NOT',
                        customContents        = hasCustomContent
                            ? customs.map((custom) => (
                                <span key={custom} className="custom">
                                    {custom}
                                </span>
                            )).reduce((prev, curr) => [prev, ', ', curr])
                            : false,
                        customContentString = [`(${isNot ? 'nor' : 'or'} `, customContents, ')'];

                    return (
                        <span key={source} className={`concept ${isNot ? ' not ' : ''}`}>
                            <span className="source">
                                {source}
                            </span>
                            {hasCustomContent && (
                                <span className="customs">
                                    {customContentString}
                                </span>
                            )}
                        </span>
                    );
                }),
            separator = this.companiesInputsCount() ? ' and ' : ' ';

        let firstNot = true;

        return (
            <span className="concepts">
                {separator}
                {' '}
                insights on&nbsp;
                {conceptsContents.reduce((prev, curr, index) => {
                    const isNot   = curr.props.className && curr.props.className.match(/ not /),
                        value     = [
                            prev,
                            !isNot
                                ? (index !== conceptsContents.length - 1 ? ', ' : ' and also ')
                                : (firstNot ? ' but not mentioning ' : ' nor '),
                            curr
                        ];

                    if (isNot && firstNot) {
                        firstNot = false;
                    }

                    return value;
                })}
                {explain && (
                    <span className="expand">
                        (both singular and plural forms are automatically included).
                    </span>
                )}
            </span>

        );
    }

    /**
    * Render the "expand" part of the sentence
    *
    * @return JSX
    */
    renderExpand() {
        const { expand } = this.props;

        if (!expand || !this.conceptsInputsCount()) {
            return false;
        }

        return (
            <span className="expand">
                I also want to
                <b>boost my search</b>
                as much as possible by including variation of concepts (conjugated form, word family...)
                and extending search to extra fields.
            </span>
        );
    }


    /**
    * Render the "folder limit tags" part of the sentence
    *
    * @return JSX
    */
    renderFolderLimitTag() {
        const { filters }             = this.props,
            { bookmarkFolderForTags } = filters || {};

        if (!bookmarkFolderForTags) {
            return false;
        }

        return (
            <span className="filters">
                <FilterValues className="query-filter"
                    key="bookmarkFolderForTags"
                    filterLabel="and using only the organizations classified within the folder:"
                    filterKey="bookmarkFolderForTags"
                    values={bookmarkFolderForTags} inlineLabel
                />
                <span>
                    to create the Competitive Landscape
                </span>
                <i>(Beta version)</i>
            </span>
        );
    }


    /**
    * Get filter definition from knowledge by key
    *
    * @param {string} filterKey
    *
    * @returns {object}
    */
    getFilterDefinition(filterKey) {
        const { filters }    = this.state,
            filterDefinition = filters?.toArray().find(filterDef => filterDef.id === filterKey);

        return filterDefinition;
    }


    /**
    * Render the value of date filter
    *
    * @returns JSX
    */
    getQueryFilters() {
        const { filters } = this.props;

        return _.map(filters, (values, filterKey) => {
            const filterDef = this.getFilterDefinition(filterKey),
                { label }   = filterDef || {};

            if (!filterDef || !values?.length || !label) {
                return;
            }

            return (
                <React.Fragment key={filterKey}>
                    <FilterValues className="query-filter" key={filterKey}
                        filterLabel={`${label}: `} filterKey={filterKey}
                        values={values} inlineLabel
                    />
                    <i>(Beta version)</i>
                </React.Fragment>
            );
        }).filter(mapped => mapped); // To avoid undefined values
    }


    /**
    * Render the "filters" part of the sentence
    *
    * @return {bool || JSX}
    */
    renderFilters() {
        const queryFilters = this.getQueryFilters();

        return queryFilters.length > 0 && (
            <span className="filters">
                <b>&nbsp;Filtered by:</b>
                {queryFilters}
            </span>
        );
    }

    /**
    * Render the human readable sentence from inputs
    *
    * @return JSX
    */
    render() {
        const {
                inputs, lookingFor,
                stamp,
            }          = this.props,
            { source } = inputs && inputs[0] || {},
            dataQaKey  = `insights on ${source}`;

        if (!inputs.length) {
            return false;
        }

        return (
            <>
                <span ref={this.ref}
                    className={`sentence ${!lookingFor ? 'no-looking-for' : ''}`}
                    data-qa-key={dataQaKey}
                >
                    {this.renderBegin()}
                    {this.renderCompanies()}
                    {this.renderConcepts()}
                    {this.renderExpand()}
                    {this.renderFolderLimitTag()}
                    {this.renderFilters()}
                </span>
                {stamp}
            </>
        );
    }

}

Sentence.propTypes = {
    expand        : PropTypes.bool,
    explain       : PropTypes.bool,
    filters       : PropTypes.object,
    inputs        : PropTypes.arrayOf(PropTypes.object).isRequired,
    learnKnowledge: PropTypes.func.isRequired,
    lookingFor    : PropTypes.bool,
    stamp         : PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

Sentence.defaultProps = {
    expand    : true,
    explain   : true,
    lookingFor: true
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Sentence);
