import React,
{
    useRef,
    useEffect,
    useState
}                           from 'react';
import PropTypes            from 'prop-types';
import Icon                 from './Icon';
import { onParentResize }   from 'utils/dom';

// CSS
import './assets/no-data.less';

/**
 * Innovation no data
 *
 * @param Component content A single element
 */
const NoData = (props) => { // eslint-disable-line  max-lines-per-function
    const ref = useRef(null);
    const [parentSize, setParentSize] = useState({ parentWidth: 0, parentHeight: 0 }),
        { parentHeight } = parentSize,
        {
            color,
            text,
            icon,
            iconSize,
        } = props,
        iconHeight = iconSize * parentHeight;

    useEffect(() => {
        /**
        * Sets width / height
        */
        const calculateSize = ({ width, height }) => {
            setParentSize({
                parentWidth : Math.min(width, 400),
                parentHeight: Math.min(height, 400),
            });
        };

        const resizeObserver = onParentResize(ref, calculateSize);

        return resizeObserver;
    }, []);


    return (
        <div
            ref={ref}
            className="noData"
            style={{opacity: 0.7}}
        >
            <div
                className="noDataContent"
            >
                <Icon
                    id={icon || 'hand-holding-up-a-magnifier'}
                    height={iconHeight / 6}
                    color={color}
                />
                <div
                    className="text"
                    style={{ color }}
                >
                    {text || 'We did not find any activity'}
                </div>
            </div>
        </div>
    );
};

/**
 * Props type
 */
NoData.propTypes = {
    text    : PropTypes.string,
    icon    : PropTypes.string,
    color   : PropTypes.string,
    iconSize: PropTypes.number
};

/**
 * Default props value
 */
NoData.defaultProps = {
    color   : 'var(--insight-color)',
    iconSize: 1
};

export default NoData;

