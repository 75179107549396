import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    scaleBand   as d3ScaleBand,
    hsl         as d3Hsl,
}                           from 'd3';

import { Icon }             from 'helpers';

import Animation            from '../Animation';

import watermarkPng         from './Venn/watermark.png';

import './Venn/main.less';

const seriesKeys = ['left', 'center', 'right'];

const animationDuration = 200;

// Screenplay for animation
const scenario = {
    scenes: {
        intro: {
            steps: {
                main: [
                    {
                        attribute: 'opacity',
                        duration : animationDuration,
                        atEnd    : [
                            { attribute: 'labelsOpacity', duration: animationDuration },
                        ]
                    },
                ],
            },
            defaults: {
                labelsOpacity: 0,
                opacity      : 0,
            },
        },
        general: {
            steps: {
                main: [
                    { attribute: 'opacity', duration: animationDuration, value: 0 },
                    { attribute: 'labelsOpacity', duration: animationDuration, value: 0 }
                ],
                after: [
                    { attribute: 'opacity', duration: animationDuration, value: 1 },
                    { attribute: 'labelsOpacity', duration: animationDuration, value: 1 }
                ],

            },
            defaults: {
            },
        },
        loading: {
            steps: {
                main: [
                    { attribute: 'opacity', duration: animationDuration, value: 0.3 },
                    { attribute: 'labelsOpacity', duration: animationDuration, value: 0.3 },
                ]
            }
        },
    },
};

/**
 * The shape graph Component
 *
 */
class Venn extends Component {

    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScales(options) {
        const { nextProps, state } = options;

        state.scales = _.map(seriesKeys, (serieKey, index) => Venn.getSerieScale(options, index));

        return { nextProps, state };
    }

    /**
    * Get serie scale
    *
    * @params options object { nextProps, state }
    *
    * @return object d3Scale
    */
    static getSerieScale(options, serieIndex) {
        const { nextProps, state } = options,
            { innerPadding }       = nextProps,
            { series }             = state,
            { labelHeight }        = state,
            serie                  = series[serieIndex],
            nbItem                 = serie.length;

        return d3ScaleBand()
            .range([
                0,
                nbItem * labelHeight
                    + (nbItem - 1) * labelHeight * innerPadding
            ])
            .domain(_.range(serie.length))
            .paddingInner(innerPadding);
    }

    /**
    * Update colors
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotDataColors(options) {
        const { nextProps, state } = options,
            { color }              = nextProps,
            leftLabelColor         = d3Hsl(color),
            leftBoxColor           = d3Hsl(color),
            leftBorderColor        = d3Hsl(color),
            centerLabelColor       = d3Hsl(color),
            centerBoxColor         = d3Hsl(color),
            centerBorderColor      = d3Hsl(color),
            rightLabelColor        = d3Hsl(color),
            rightBoxColor          = d3Hsl(color),
            rightBorderColor       = d3Hsl(color);

        // Set mid luminence
        leftLabelColor.l   = 0.3;
        centerLabelColor.l = 0.3;
        rightLabelColor.l  = 0.3;

        // Box luminecence
        leftBoxColor.l     = 0.75;
        centerBoxColor.l   = 0.65;
        rightBoxColor.l    = 0.75;

        // Border luminecence
        leftBorderColor.l   = 0.5;
        centerBorderColor.l = 0.52;
        rightBorderColor.l  = 0.5;

        // Change right tint
        rightLabelColor.h   += 30;
        rightBoxColor.h     += 30;
        rightBorderColor.h  += 30;
        centerBoxColor.h    += 15;
        centerBorderColor.h += 15;

        state.plotData.colors = {
            labels: {
                left  : leftLabelColor.toString(),
                center: centerLabelColor.toString(),
                right : rightLabelColor.toString(),
            },
            boxes: {
                left  : leftBoxColor.toString(),
                center: centerBoxColor.toString(),
                right : rightBoxColor.toString(),
            },
            gradients: {
                left  : [leftBoxColor, leftBorderColor.toString()],
                center: [centerBoxColor.toString(), centerBorderColor.toString()],
                right : [rightBorderColor.toString(), rightBoxColor]
            }
        };

        return { nextProps, state };
    }

    /**
    * Update plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) { // eslint-disable-line  max-lines-per-function
        const { nextProps, state }                                = options,
            { filterValues }                                      = nextProps,
            { scales, series, boxesLabels }                       = state,
            { innerWidth, innerHeight }                           = state,
            areasIsEmpty                                          = _.map(series, (serie) => serie.length === 0),
            { 0: leftIsEmpty, 1: centerIsEmpty, 2: rightIsEmpty } = areasIsEmpty,
            onlyCenter                                            = leftIsEmpty && !centerIsEmpty && rightIsEmpty,
            filledAreasCount                                      = !onlyCenter
                ? _.reduce(series, (total, serie) => total + (serie.length === 0 ? 0 : 1), 0)
                : 3,
            maxLabelsWidth                                        = Math.round(innerWidth / filledAreasCount),
            // Get the max height of the boxes
            maxHeight                                             = _.max(_.map(scales, (scale) => scale.range()[1]));

        state.plotData = {
            areas: _.mapValues(series, (serie, serieIndex) => {
                const scale             = scales[serieIndex],
                    serieKey            = seriesKeys[serieIndex],
                    labelsHeight        = maxHeight,
                    labelsOffset        = !onlyCenter && serieKey !== 'left'
                        ? -maxLabelsWidth * (
                            serieKey === 'center'
                                ? (leftIsEmpty ? 1 : 0)
                                : (leftIsEmpty || rightIsEmpty ? 1 : 0) + (centerIsEmpty ? 1 : 0)
                        ) : 0,
                    labelsPosition      = serieIndex * maxLabelsWidth + labelsOffset,
                    labelsTop           = innerHeight / 2 - labelsHeight / 2,
                    labelVerticalOffset = (labelsHeight - scale.range()[1]) / 2,
                    labelsWidth         = maxLabelsWidth;

                return {
                    id           : serieKey,
                    serieKey,
                    label        : boxesLabels[serieKey],
                    labelsHeight,
                    labelsWidth,
                    labelsPosition,
                    labelsTop,
                    opacity      : 1,
                    labelsOpacity: 1,
                    labels       : _.map(serie, (item, itemNum) => ({
                        id      : item.id,
                        inFilter: filterValues.includes(item.id),
                        num     : itemNum,
                        text    : item.label,
                        icon    : item.icon,
                        position: scale(itemNum) + labelVerticalOffset
                    })),

                };
            }),
            areasIsEmpty,
            filledAreasCount
        };

        return { nextProps, state };
    }

    /** Update boxes positions in plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateBoxesPositions(options) {
        const { nextProps, state }    = options,
            { plotData, labelHeight } = state,
            { areas }                 = plotData;

        state.plotData.areas = _.map(areas, area => {
            const { labelsPosition, labelsWidth } = area,
                { labelsTop, labelsHeight }       = area,
                labelsMargin                      = labelHeight / 2,
                boxLeft                           = labelsPosition,
                boxRight                          = labelsPosition + labelsWidth,
                boxTop                            = labelsTop - labelsMargin,
                boxBottom                         = labelsTop + labelsHeight,
                boxWidth                          = boxRight - boxLeft,
                boxHeight                         = boxBottom - boxTop + labelsMargin;

            // The boxPosition :
            area.boxPosition = {
                left  : boxLeft,
                top   : boxTop,
                width : boxWidth,
                height: boxHeight,
            };

            return area;
        });

        return { nextProps, state };
    }

    /** Update boxes positions in plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateOvalsPositions(options) {
        const { nextProps, state }      = options,
            { plotData }                = state,
            { innerWidth, innerHeight } = state,
            { areas, areasIsEmpty }     = plotData,
            { 1: centerIsEmpty }        = areasIsEmpty;

        state.plotData.areas = _.map(areas, (area, areaIndex) => {
            const { labelsWidth, serieKey } = area,
                otherArea                   = _.find(areas, (area) => area.serieKey !== 'center' && area.serieKey !== serieKey),
                otherIsEmpty                = otherArea.labels.length === 0,
                isEmpty                     = areasIsEmpty[areaIndex],
                widthGrow                   = !centerIsEmpty ? (otherIsEmpty && isEmpty ? labelsWidth * 3 : labelsWidth) : 0,
                boxWidth                    = (isEmpty ? 0 : labelsWidth) + widthGrow,
                boxLeft                     = serieKey === 'left' ? 0 : innerWidth - boxWidth,
                boxTop                      = 10,
                boxHeight                   = innerHeight - 20;

            // The boxPosition :
            area.ovalPosition = {
                left  : boxLeft,
                top   : boxTop,
                width : boxWidth,
                height: boxHeight,
            };

            return area;
        });

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null
        };

        _.bindAll(
            this, 'render', 'renderGraph'
        );

        this.graphRef = React.createRef();

        // Make a uuid
        this.id = _.uniqueId('bet');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
        const { fontSize }  = this.props,
            { labelHeight } = this.state,
            graphNode       = this.graphRef.current,
            containersNodes = graphNode ? graphNode.querySelectorAll('g.label-container') : [];

        _.each(containersNodes, (containerNode) => {
            const labelNode    = containerNode.querySelector('.label'),
                iconNode       = containerNode.parentNode.querySelector('g.icon'),
                containerSize  = containerNode.getBBox(),
                containerWidth = containerSize.width,
                labelWidth     = labelNode.offsetWidth,
                xOffset        = (containerWidth - labelWidth + fontSize * 1.62) / 2;

            // Move icon in label container
            if (iconNode) {
                iconNode.setAttribute('transform', `translate(${xOffset} ${labelHeight / 2})`);
            }
        });
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - scale (D3Scales object) of the landmark
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { margin, data, fontSize }  = nextProps,
            { boxesLabels, fontSizeAuto } = nextProps,
            { content, width }            = nextProps,
            { lastContent }               = prevState,
            newContent                    = content || lastContent,
            series                        = newContent || [],
            // Cascading function to update state
            updateState                   = _.flow([
                Venn.updateScales,          // D3 scale
                Venn.updatePlotData,        // Plot data labels positions
                Venn.updatePlotDataColors,  // Plot data colors
                Venn.updateBoxesPositions,  // Plot data boxes
                Venn.updateOvalsPositions,  // Plot data ovals
            ]),
            dynamicFontSize               = fontSizeAuto ? width / 56 : fontSize,
            { state: newState }           = updateState({
                nextProps,
                state: {
                    ...prevState,
                    // Size of the chart (without landmark)
                    fontSize   : dynamicFontSize,
                    innerHeight: nextProps.height - 2 * margin,
                    innerWidth : nextProps.width  - 2 * margin,
                    labelHeight: Math.round(dynamicFontSize * 1.7),
                    series     : _.map(seriesKeys, (serieKey) => series[serieKey] || []),
                    boxesLabels: {
                        left  : _.get(data, 'stats.leftLabel') || boxesLabels.left,
                        center: _.get(data, 'stats.centerLabel') || boxesLabels.center,
                        right : _.get(data, 'stats.rightLabel') || boxesLabels.right,
                    }
                }
            });

        return newState;
    }

    /**
    * On item click
    *
    * @return boolean
    */
    onItemClick(item) {
        return () => {
            const { filterCb, filterValues }  = this.props;

            // Add filter
            if (filterCb && item.id) {
                filterCb(_.xor([item.id], filterValues));
            }

            return true;
        };
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { plotData }    = this.state,
            { colors, areas } = plotData,
            leftandrightareas = _.filter(areas, (area) => area.serieKey !== 'center');

        return (
            <defs>
                {_.map(leftandrightareas, (area) => (
                    <linearGradient
                        id={`${this.id}-gradient-${area.serieKey}`}
                        key={area.serieKey}
                        gradientUnits="userSpaceOnUse"
                        x1="0"
                        x2={area.ovalPosition.width}
                        y1="0"
                        y2="0"
                    >
                        <stop offset="0%" stopColor={colors.gradients[area.serieKey][0]} />
                        <stop offset="100%" stopColor={colors.gradients[area.serieKey][1]} />
                    </linearGradient>
                ))}
                <radialGradient
                    id={`${this.id}-gradient-center`}
                    cx="50%"
                    cy="50%"
                    r="100%"
                >
                    <stop offset="15%" stopColor={colors.gradients.center[0]} />
                    <stop offset="85%" stopColor={colors.gradients.center[1]} />
                </radialGradient>
            </defs>
        );
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels(areas) {
        const { margin, filterCb }              = this.props,
            { labelHeight, plotData, fontSize } = this.state,
            { colors }                          = plotData;

        return _.values(_.mapValues(areas, (area) => _.map(area.labels, (label) => {
            const { serieKey } = area,
                color          = colors.labels[serieKey],
                labelContract  = -area.labelsWidth / 20,
                left           = area.labelsPosition + margin,
                top            = area.labelsTop + label.position + margin,
                leftPadding    = label.icon ? fontSize * 1.5 : fontSize / 2;

            return (
                <div
                    className="label-container"
                    key={`label-${serieKey}-${label.text}`}
                    style={{
                        left, top, width: area.labelsWidth + labelContract, height: labelHeight + 2
                    }}
                >
                    <div
                        className="label"
                        onClick={this.onItemClick(label)}
                        title={label.text}
                        style={{
                            maxWidth       : area.labelsWidth - fontSize * 2 + labelContract,
                            lineHeight     : `${fontSize * 1.5}px`,
                            fontSize       : `${fontSize}px`,
                            fontWeight     : label.inFilter ? 'bold' : null,
                            opacity        : area.labelsOpacity,
                            padding        : `${Math.round(fontSize / 8)}px ${fontSize / 2}px 0 ${leftPadding}px`,
                            backgroundColor: 'rgba(255, 255, 255, 0.65)',
                            cursor         : _.isFunction(filterCb) && !_.isUndefined(label.id)  ? 'pointer' : null,
                            color
                        }}
                    >
                        <span>
                            {this.renderIconLabel(label, color)}
                            {label.text}
                        </span>
                    </div>
                </div>
            );
        })));
    }

    /**
    * Render icon label
    *
    * @return html
    */
    renderIconLabel(label, color) {
        const { iconFolder } = this.props,
            { fontSize }     = this.state,
            { icon }         = label;

        return icon ? (
            <Icon
                folder={`/${iconFolder}/`}
                id={icon}
                height={fontSize}
                width={fontSize}
                title={icon}
                color={color}
            />
        ) : null;
    }

    /**
    * Render ovals
    *
    * @return html
    */
    renderOvals(areas) {
        return (
            <>
                {this.renderOval(areas, 'left')}
                {this.renderOval(areas, 'right')}
            </>
        );
    }

    /**
    * Render leftOval
    *
    * @return html
    */
    renderOval(areas, serieKey) {
        const area            = _.find(areas, (area) => area.serieKey === serieKey),
            { ovalPosition }  = area,
            { left, top }     = ovalPosition,
            { width, height } = ovalPosition,
            fill              = `url("#${this.id}-gradient-${serieKey}")`,
            path              = [],
            ovalOffset        = -10;

        if (serieKey === 'left') {
            path.push(`M${left - ovalOffset} ${height / 2}`);
            path.push(`Q${left - ovalOffset} 0, ${width / 2} ${0}`);
            path.push(`Q${width} 0, ${width} ${height / 2}`);
            path.push(`Q${width} ${height}, ${width / 2} ${height}`);
            path.push(`Q${left - ovalOffset} ${height}, ${left - ovalOffset} ${height / 2}`);
        }

        if (serieKey === 'right') {
            path.push(`M${width + ovalOffset} ${height / 2}`);
            path.push(`Q${width + ovalOffset} 0, ${width / 2} ${0}`);
            path.push(`Q0 0, 0 ${height / 2}`);
            path.push(`Q0 ${height}, ${width / 2} ${height}`);
            path.push(`Q${width + ovalOffset} ${height}, ${width + ovalOffset} ${height / 2}`);
        }

        return (
            <g className="area" transform={`translate( ${left} ${top} )`}
                key={serieKey}
            >
                <path
                    d={path.join(' ')}
                    style={{ fill, fillOpacity: 0.7 * area.opacity }}
                />
            </g>
        );
    }

    /**
    * Render area label
    *
    * @return html
    */
    renderBoxLabel(area, color) {
        const { margin }              = this.props,
            { labelHeight, fontSize } = this.state,
            { ovalPosition, label }   = area,
            { serieKey }              = area,
            { width }                 = ovalPosition,
            left                      = area.labelsPosition + margin,
            top                       = margin + (serieKey === 'center' ? area.labelsHeight + labelHeight : 0);

        return area.label && width > 0 ? (
            <div
                className="label-container"
                style={{
                    left, top, width: width / 2, height: labelHeight
                }}
                key={`${serieKey}-${label}`}
            >
                <div
                    className="area-label"
                    title={label}
                    style={{
                        width     : area.labelsWidth,
                        lineHeight: `${labelHeight}px`,
                        fontSize  : `${fontSize}px`,
                        padding   : `0 ${fontSize / 2}px`,
                        textAlign : serieKey,
                        color,
                    }}
                >
                    <span>
                        {label}
                    </span>
                </div>
            </div>
        ) : null;
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderGraph(areas) {
        const {
                width,
                height,
                margin,
                showWatermark,
            }                           = this.props,
            { innerWidth, innerHeight } = this.state,
            { plotData }                = this.state,
            { colors }                  = plotData;

        return (
            <div className="Venn" ref={this.graphRef}>
                {showWatermark ? (
                    <div
                        className="background"
                        style={{ width: innerWidth, height: innerHeight, margin }}
                    >
                        <img
                            className="watermark"
                            src={watermarkPng}
                            height={innerHeight}
                            alt=""
                        />
                    </div>
                ) : null}
                <svg
                    width={width}
                    height={height}
                >
                    {this.renderSvgElements(areas)}
                </svg>
                {this.renderLabels(areas)}
                {_.values(_.mapValues(areas, (area) => this.renderBoxLabel(area, colors.labels[area.serieKey])))}
            </div>
        );
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvgElements(areas) {
        const { margin } = this.props;

        return (
            <g transform={`translate( ${margin} ${margin} )`}>
                {this.renderDefs()}
                {this.renderOvals(areas)}
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                skeleton,
                skeletonGradient,
                noContent,
            }                           = this.props,
            { plotData }                = this.state,
            style                       = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) { return (<div className="Venn skeleton" style={style} />); }

        return (
            <Animation
                data={plotData.areas}
                render={this.renderGraph}
                scenario={scenario}
                scene={noContent ? 'loading' : 'general'}
            />
        );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
Venn.propTypes = {
    center          : PropTypes.string,
    color           : PropTypes.string,
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    filterCb        : PropTypes.func,
    filterValues    : PropTypes.any,
    fontSize        : PropTypes.number,
    height          : PropTypes.number.isRequired,
    iconFolder      : PropTypes.string,
    innerPadding    : PropTypes.number,
    left            : PropTypes.string,
    margin          : PropTypes.number,
    noContent       : PropTypes.any,
    right           : PropTypes.string,
    showWatermark   : PropTypes.bool,
    skeleton        : PropTypes.bool,
    fontSizeAuto    : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    width           : PropTypes.number.isRequired,
    content         : PropTypes.any,
    boxesLabels     : PropTypes.shape({
        left  : PropTypes.string,
        center: PropTypes.string,
        right : PropTypes.string
    }),
};

/**
 * Default props value
 */
Venn.defaultProps = {
    margin          : 0,
    fontSize        : 14,
    color           : 'var(--insight-color)',
    skeleton        : false,
    skeletonGradient: false,
    innerPadding    : 0.2,
    showWatermark   : false,
    fontSizeAuto    : false,
    iconFolder      : 'technical-domains',
    boxesLabels     : {
        left  : '',
        center: '',
        right : '',
    }
};

export default Venn;
