import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import Icon                 from './Icon';
import { learn }            from '../store/actions/knowledge';

/**
* Render the flag
*
* @return JSX
*/
class Flag extends Component {

    /**
    * Class constructor
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['countries'])
            .then(this.setState.bind(this));
    }

    /**
    * Render the flag
    *
    * @return JSX
    */
    render() {
        const {
                iso,
                className,
                options
            }                = this.props,
            { countries }    = this.state,
            { disabledTitle} = options,
            ratio            = 96 / 64,
            folder           = '/countries-96x64/',
            countryLabel     = !_.isUndefined(countries) && countries.find((country) => country.id === iso)?.label || iso,
            title            = disabledTitle ? undefined : countryLabel;

        return (
            <Icon
                {...this.props}
                id={iso}
                title={title}
                alt={iso}
                ratio={ratio}
                folder={folder}
                fitOnHeight
                className={className}
            />
        );
    }

}

Flag.propTypes = {
    className     : PropTypes.string,
    iso           : PropTypes.string,
    learnKnowledge: PropTypes.func,
    options       : PropTypes.shape({
        disabledTitle: PropTypes.bool,
    })
};

Flag.defaultProps = {
    options: {
        disabledTitle: false
    }
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Flag);

