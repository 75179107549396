import React, { Component }               from 'react';
import _                                  from 'lodash';
import { Switch, Tooltip }                from 'antd';
import { connect }                        from 'react-redux';
import PropTypes                          from 'prop-types';
import ImmutablePropTypes                 from 'react-immutable-proptypes';

import { getTreeLeaves, getTreeNode }     from 'utils/object';
import {
    getTopicsForSearch, getTopicsForOrgunit
}                                         from 'utils/elements';

import { learn }                          from 'store/actions/knowledge';
import { getModelsOfType }                from 'store/actions/userView';
import { Icon, CssLoader }                from 'helpers';

import './assets/topics-of-interest.less';

/**
 * Select some topic to add them in the newsletter
 *
 */
class TopicsOfInterest extends Component {

    /**
     * Construct'
     */
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        _.bindAll(this, 'onChangeSwitch', 'toggleAccordeon');

        this.state = {
            enlargedTopics: [],
            topics        : [],
        };
    }


    /**
    * Triggered when the component is ready
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['entities', 'elements', 'resources']).then(this.setState.bind(this));
    }

    /**
    * Triggered when the component is updated
    */
    componentDidUpdate() {
        this.updateTopics();
        this.setDefault();
    }


    /**
     * Update topics in state
     *
     * @returns Void
     */
    updateTopics() {
        const { bookmarksList} = this.props,
            {
                topics, elements, previousBookmarks,
            } = this.state;

        if ((!elements || topics.length > 0) && bookmarksList === previousBookmarks) {
            return true;
        }

        const topicsElement = this.getTopics(),
            leaves          = getTreeLeaves(topicsElement, 'subSections'),
            subTopicsIds    = leaves?.map(leave => leave.path);

        this.setState({
            topics           : topicsElement,
            subTopicsIds,
            previousBookmarks: bookmarksList,
        });
    }


    /**
     * Call update newsletter
     *
     * @param {object} newsletter
     */
    updateNewletter(newsletter) {
        const { update }          = this.props,
            { settings }          = newsletter,
            { sections }          = settings,
            competitiveIsDisabled = this.competitiveIsDisabled(),
            filteredSections      = sections.filter(
                path => !competitiveIsDisabled || path.indexOf('competitive-landscape') === -1
            );

        update({
            ...newsletter,
            settings: {
                ...settings,
                // Add all sections
                sections: filteredSections
            }
        });
    }


    /**
    * Set all section checked by default
    */
    setDefault() {
        const { newsletter } = this.props,
            { subTopicsIds } = this.state,
            { settings }     = newsletter,
            { sections }     = settings || {};

        // Sections not set
        if (subTopicsIds && subTopicsIds.length > 0 && !sections) {
            this.updateNewletter({
                ...newsletter,
                settings: {
                    ...settings,
                    // Add all sections
                    sections: subTopicsIds
                }
            });
        }
    }


    /**
     * The competitive landscape is disable when user do not have tags (on bookmarks)
     */
    competitiveIsDisabled() {
        const {
                newsletter, getModelsOfType
            }                = this.props,
            bookmarks        = getModelsOfType('bookmark'),
            { key }          = newsletter,
            bookmarkWithTags =  bookmarks?.filter && bookmarks.filter(bookmark => bookmark?.tags?.length > 0);

        return key === 'topic-insights' && (
            !bookmarkWithTags || bookmarkWithTags.length === 0
        );
    }

    /**
     * Extract topics from knowledge (competitive or topic)
     */
    getTopics() {
        const { newsletter } = this.props,
            { elements }     = this.state,
            { key }          = newsletter,
            topics           = key === 'topic-insights'
                ? getTopicsForSearch(elements, this.competitiveIsDisabled())
                : this.getTopicsForOrgunit();

        return topics;
    }


    /**
     * Get topics for orgunits newsletter
     */
    getTopicsForOrgunit() {
        const { elements, resources } = this.state,
            topics                    = getTopicsForOrgunit(elements, resources);

        return topics.map(topic => _.omit(topic, 'subSections')); // No subsection choice for orgunit
    }

    /**
    * On change switch
    *  using settingKey because ant-switch do not send enventClick
    *
    * @param {string} settingKey
    */
    onChangeSwitch(topicPath) {
        const { newsletter }         = this.props,
            { settings }             = newsletter,
            { topics, subTopicsIds } = this.state,
            topic                    = getTreeNode(topics, 'subSections', topicPath);

        if (!topic.subSections) {
            const node               = this.ref.current,
                checkboxesNode       = node.querySelectorAll('.ant-switch'),
                selectedNodeSections = _.filter(checkboxesNode, checkboxNode => checkboxNode.getAttribute('aria-checked') === 'true'), // eslint-disable-line max-len
                selectedSections     = _.map(selectedNodeSections, checkboxNode => checkboxNode.dataset.id)
                    .filter(sectionId => subTopicsIds.indexOf(sectionId) !== -1);

            this.updateNewletter({
                ...newsletter,
                settings: {
                    ...settings,
                    sections: _.xor(selectedSections, [topicPath])
                }
            });
            return;
        }

        this.changeBranchSwitch(topicPath);
    }


    /**
     * Change a branch switch
     *
     * @param {string} key
     */
    changeBranchSwitch(topicPath) {
        const { newsletter }      = this.props,
            { subTopicsIds }      = this.state,
            { settings }          = newsletter,
            { sections }          = settings || {},
            selectedSections      = sections || [],
            subsectionsIds        = subTopicsIds.filter(subSectionId => subSectionId.indexOf(topicPath) === 0),
            hasSubsectionSelected = this.topicIsEnabled(topicPath);

        this.updateNewletter({
            ...newsletter,
            settings: {
                ...(settings || {}),
                sections: hasSubsectionSelected
                    ? _.difference(selectedSections, subsectionsIds)
                    : _.union(selectedSections, subsectionsIds)
            }
        });
    }


    /**
    * Expand or reduce subsections
    *
    * @param {clickEvent} e
    */
    toggleAccordeon(e) {
        const { enlargedTopics } = this.state,
            { currentTarget }   = e,
            { dataset }         = currentTarget,
            { id }              = dataset;

        this.setState({ enlargedTopics: _.xor(enlargedTopics, [id]) });
    }


    /**
     * The topic si enabled
     *
     * @param {object} topic
     */
    topicIsEnabled(topicPath) {
        const { newsletter }  = this.props,
            { settings}       = newsletter,
            { sections }      = settings || {},
            selectedSections  = sections || [];

        for (const index in selectedSections) {
            const selectedSection = selectedSections[index];
            if (selectedSection.indexOf(topicPath) === 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Render topic and subtopics
     *
     * @param {array} topics
     */
    renderTopics(topics, path) {    // eslint-disable-line max-lines-per-function
        const { enlargedTopics } = this.state,
            classNameArray       = ['topics'];

        if (enlargedTopics.indexOf(path) === -1 && path) {
            classNameArray.push('reduced');
        }

        if (!path) {
            classNameArray.push('root');
        }

        return (
            <div className={classNameArray.join(' ')}>
                {topics && topics.map(topic => {
                    const topicPath       =  path ? `${path}.${topic.id}` : topic.id,
                        isReduced         = enlargedTopics.indexOf(topicPath) === -1,
                        topicIsDisabled   = topic.disabled,
                        tooltipText       = 'To process Competitive Landscape insight, Innosabi Insight needs some information '
                        + 'about your organization and your competitors',
                        { configuration } = topic,
                        isChecked         = this.topicIsEnabled(topicPath) && !topicIsDisabled,
                        active            = isChecked && !topic.hideChildren,
                        dataQaKey         = `${active ? 'active' : 'inactive'}-${!path ? 'main' : 'sub'}-topic`;

                    return (
                        <div key={topic.label} className="topic">
                            <Tooltip
                                placement="bottomLeft"
                                title={topicIsDisabled ? tooltipText : null}
                            >
                                <Switch
                                    size="small"
                                    checked={isChecked}
                                    data-id={topicPath}
                                    onClick={!topicIsDisabled ? () => this.onChangeSwitch(topicPath) : null}
                                    data-qa-key={dataQaKey}
                                />
                                <span className="label" onClick={!topicIsDisabled ? () => this.onChangeSwitch(topicPath) : null}>
                                    {topic.label}
                                </span>
                                <span className="text">
                                    {configuration?.description}
                                </span>
                                {topic.subSections && (
                                    <div className={topic.hideChildren ? 'hidden-sub-sections' : ''}>
                                        <div className={`toggle-accordeon${isReduced ? ' reduced' : ''}`}>
                                            <span onClick={!topicIsDisabled && this.toggleAccordeon} data-id={topicPath}
                                                data-qa-key={`more-${topicPath}`}
                                            >
                                                <Icon type={isReduced ? 'down' : 'left'} />
                                                &nbsp;
                                                {isReduced ? 'more' : 'less'}
                                            </span>
                                        </div>
                                        {this.renderTopics(topic.subSections, topicPath)}
                                    </div>
                                )}
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { topics } = this.state;

        return (
            <div className="topics-of-interest setting" ref={this.ref}>
                {
                    topics.length > 0
                        ? this.renderTopics(topics)
                        : (
                            <CssLoader type="ripple" size={50}
                                thickness={2} color="#dddddd"
                            />
                        )
                }
            </div>
        );
    }

}


TopicsOfInterest.definition =  {
    key     : 'sections',
    label   : 'Topics Of Interest',
    question: 'What are you interested in?',
    view    : 'TopicsOfInterest',
};


/**
* Validate properties for current Component
*/
TopicsOfInterest.propTypes = {
    learnKnowledge : PropTypes.func,
    newsletter     : PropTypes.object,
    bookmarksList  : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    update         : PropTypes.func,
    getModelsOfType: PropTypes.func
};

TopicsOfInterest.defaultProps = {
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList: state.getIn(['userView', 'bookmark', 'list']),
});

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    learnKnowledge: learn,
    getModelsOfType,
})(TopicsOfInterest);

