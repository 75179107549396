import PropTypes                    from 'prop-types';
import React, { Component }         from 'react';
import _                            from 'lodash';
import dayjs                        from  'dayjs';
import { IButton }                  from 'helpers';
import { Slider }                   from 'antd';
import { getYearsFromCustomString } from 'utils/date';

/**
 *  Componant render 2 year picker to chose a range
 */
class DateRangeCustomYear extends Component {

    /**
     * Construct
     *
     * @param {object} props
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onAfterChange');
    }

    /**
    * On apply button  click
    * Triggering the onChange callback
    */
    onApply() {
        const {
                selectedId, onChange,
                filterKey, boundaries, setActiveCustomInput,
            }                = this.props,
            { dateMin }      = boundaries,
            minYear          = dayjs(dateMin).year(),
            isCustom         = _.isString(selectedId) && selectedId.indexOf('custom_') === 0,
            currentYear      = new Date().getFullYear(),
            defaultDateRange = `custom_${minYear}_${currentYear}`;

        setActiveCustomInput(false);
        onChange(filterKey, isCustom ? selectedId : defaultDateRange, true);
    }

    /**
    * Get min and max when you have a blocked end date
    *
    * @param {number} newValue
    * @returns string
    */
    getConstrainCustomString(newValue) {
        const {
                blockedEndDate, minRange
            }              = this.props,
            blockedEndYear = blockedEndDate.getFullYear(),
            gap            = blockedEndYear - newValue,
            minValue       = gap < minRange ? (newValue - minRange + gap) : newValue;

        return `custom_${minValue}_${blockedEndYear}`;
    }

    /**
    * Handle slider onAfterChange
    *
    * @param {number|number[]} newValue
    */
    onAfterChange(newValue) {
        const {
                setSelectedId, blockedEndDate,
            }            = this.props,
            [min, max]   = _.isArray(newValue) ? newValue : [],
            customString = blockedEndDate ? this.getConstrainCustomString(newValue) : `custom_${min}_${max}`;

        setSelectedId(customString);
    }

    /**
    * Get year range from extract value
    *
    * @returns array
    */
    getYearRange() {
        const {
                blockedEndDate, value,
                boundaries, selectedId  = value,
            }            = this.props,
            { dateMin }  = boundaries,
            minYear      = dayjs(dateMin).year(),
            currentYear  = blockedEndDate ? blockedEndDate.getFullYear() : new Date().getFullYear(),
            rangeValue   = selectedId && selectedId !== 'tfcustom' ? selectedId : value,
            extractValue = getYearsFromCustomString(rangeValue),
            yearRange    = extractValue
                ? [extractValue[0], extractValue[1]]
                : [minYear, currentYear];

        return yearRange;
    }

    /**
     * Render buttons
     *
     * @returns JSX
     */
    renderButtons() {
        const {
                selectedId,
                onReset,
                value
            }          = this.props,
            isDisabled =  !selectedId || selectedId === value;

        return (
            <div className="buttons">
                <IButton
                    label="Reset"
                    fontSize={12}
                    className="active reset"
                    onClick={onReset}
                    disabled={isDisabled}
                />
                <IButton
                    label="Apply"
                    data-qa-key="custom-date-range-button"
                    className="apply" bgColor="#10285D"
                    labelColor="#FFFFFF" hoverBgColor="#58698E"
                    fontSize={12}
                    onClick={() => this.onApply()}
                />
            </div>
        );
    }


    // TODO: Make one component IntRange <---> DateRange
    /**
     * Render the componant
     */
    render() {
        const {
                blockedEndDate, boundaries,
            }            = this.props,
            { dateMin }  = boundaries,
            minYear      = dayjs(dateMin).year(),
            currentYear  = blockedEndDate ? blockedEndDate.getFullYear() : new Date().getFullYear(),
            yearRange    = this.getYearRange(),
            rangeLength  = yearRange[1] - yearRange[0],
            rangeText    = rangeLength === 0 ? yearRange[0] : `${yearRange[0]}-${yearRange[1]}`,
            defaultValue = blockedEndDate ? yearRange[0] : yearRange,
            className    = `custom-range${blockedEndDate ? ' blocked-end' : ''}`;

        return (
            <div data-qa-key="custom-date-range" className={className}>
                <Slider
                    defaultValue={defaultValue}
                    key={JSON.stringify(yearRange)}
                    max={currentYear}
                    min={minYear}
                    onAfterChange={this.onAfterChange}
                    range={blockedEndDate ? false : {draggableTrack: true}}
                />
                <div className="range-container">
                    <span className="range">
                        <span className="range-content">
                            {rangeText}
                        </span>
                    </span>
                </div>
                {this.renderButtons()}
            </div>
        );
    }

}

DateRangeCustomYear.propTypes = {
    blockedEndDate: PropTypes.instanceOf(Date),
    boundaries    : PropTypes.object,
    filterKey     : PropTypes.string,
    minRange      : PropTypes.number,
    onChange      : PropTypes.func,
    onReset       : PropTypes.func,
    selectedId    : PropTypes.oneOfType([PropTypes.shape({
        indexOf: PropTypes.func
    }), PropTypes.string]),
    setActiveCustomInput: PropTypes.func,
    setSelectedId       : PropTypes.func,
    value               : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DateRangeCustomYear;

