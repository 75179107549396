/**
 * Insight web application bootstrap file
 *
 * @use: npm start
 */

import React                              from 'react';
import ReactDOM                           from 'react-dom';
import { Provider }                       from 'react-redux';
import { createBrowserHistory }           from 'history';
import { persistStore }                   from 'redux-persist';
import { PersistGate }                    from 'redux-persist/integration/react';

import configureStore        from 'store/configureStore';
import Loader                from 'helpers/loader';
import Ui                    from './core/Ui';


// Import polyfill libs
// import 'whatwg-fetch';

// Unregister service workers if they exists
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.update();
        registration.unregister();
    });
}

// Create core constants
const history = createBrowserHistory();
const store = configureStore(history);

const persistor = persistStore(store);
const { userAgent } = window.navigator;
global.isIE = /MSIE|Trident|Edge/.test(userAgent);

// Polyfill matches for IE11
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

/**
* ClosestFn
* Polyfill closest for IE11
*/
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) { // eslint-disable-line space-before-function-paren
        let el = this;
        if (!document.documentElement.contains(el)) { return null; }
        do {
            if (el.matches(s)) { return el; }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// Missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * Render the Entire APP to the DOM
 *
 */
const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate
                loading={<Loader label="" />}
                persistor={persistor}
            >
                <Ui history={history} />
            </PersistGate>
        </Provider>,
        document.getElementById('oi-root'),
    );
};

// Ensure orbit is loaded
global.ORBITLOADED = true;

if (!String.prototype.startsWith) {
    // eslint-disable-next-line
    String.prototype.startsWith = (searchString, position) => this.substr(position || 0, searchString.length) === searchString;
}

/**
 * Initialize the APP UI.
 */
render();
