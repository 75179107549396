import React, { Component }              from 'react';
import { connect }                       from 'react-redux';
import _                                 from 'lodash';
import PropTypes                         from 'prop-types';
import { Skeleton }                      from 'antd';
import { learn }                         from 'store/actions/knowledge';
import { Row, Collection }               from 'helpers';
import { pluralize, renderDateInterval } from 'utils/text';

import './assets/references.less';

const INITIALAMOUNT = 3;

/**
* Draw Id cards reference
*
*/
class References extends Component  {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        this.state = {
            showAll: false,
        };
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        const { registerCallbacks, learnKnowledge } = this.props;

        _.bindAll(this, 'showAllDocuments', 'getEntities');

        learnKnowledge(['document-relation-types', 'publication-types', 'publishings', 'clinical-types', 'clinical-phases'])
            .then(this.setState.bind(this));

        // Register callbacks
        registerCallbacks && registerCallbacks('getEntities', this.getEntities);
    }

    /**
    * Return entities from props
    *
    * @return array
    */
    getEntities() {
        const { data }  = this.props,
            { content } = data || {};

        return content;
    }

    /**
    * Convert ids of knowledge into a string
    *
    * @return string
    */
    getLabelsOfKnowledge(knowledge, ids, separator = ', ') {
        const { [knowledge]: values } = this.state;

        if (!values || !ids || ids.length === 0) {
            return null;
        }

        const displayValues = values.filter((value) => ids.indexOf(value.id) !== -1);

        if (displayValues.length === 0) {
            return false;
        }

        return _.map(displayValues.toArray(), 'label').join(separator);
    }

    /**
    * Get the type of the document
    *
    * @return JSX
    */
    getTypeContent(doc) {
        const rows = [];

        if (doc.type === 'clinicaltrial') {
            rows.push(this.getLabelsOfKnowledge('clinicalTypes', [doc.subtype]));
            rows.push(this.getLabelsOfKnowledge('clinicalPhases', doc.study_phase));
        }

        if (doc.type === 'scidoc') {
            rows.push(this.getLabelsOfKnowledge('publicationTypes', [doc.subtype]));
            rows.push(this.getLabelsOfKnowledge('publishings', [doc.pub_publish]));
        }

        return rows.length > 0 ? rows.join(' / ') : null;
    }

    /**
    * Get the year of a document
    *
    * @return string
    */
    getYear(doc) {
        if (doc.type === 'clinicaltrial') {
            return renderDateInterval(doc.start, doc.end);
        }

        if (doc.type === 'scidoc') {
            return doc.date_publication && _.first(doc.date_publication.split('-'));
        }

        return null;
    }

    /**
    * Render reference
    *
    * @return Component
    */
    renderReferences() {
        return (
            <div>
                References element..
            </div>
        );
    }

    /**
    * Enable all documents to display.
    */
    showAllDocuments() {
        this.setState({ showAll: true });
    }

    /**
    * Render skeleton
    *
    * @return JSX
    */
    renderSkeleton() {
        return (
            <Row className="references textual is-loading" {...this.props}>
                <Skeleton title={false} active />
                <Skeleton title={false} active />
                <Skeleton title={false} active />
            </Row>
        );
    }

    /**
    * Render all the references as a list
    *
    * @return JSX
    */
    render() {
        const {
                entity, width,
                onClick, element
            }           = this.props,
            { showAll } = this.state,
            documents   = this.getEntities(),
            { label }   = element;

        if (!documents) {
            return this.renderSkeleton();
        }

        if (documents.length === 0) {
            return null;
        }

        const moreAmount = documents.length - INITIALAMOUNT;

        return (
            <Row className="references textual" {...this.props}
                label={label}
            >
                {_.map(showAll ? documents : documents.slice(0, INITIALAMOUNT), (doc) => (
                    <div className="line">
                        <div className="label" style={{ width: `${width}px` }}>
                            <Collection
                                width={width}
                                entitySource={entity}
                                entities={[doc]}
                                allowExport={false}
                                mode="inline"
                                onClick={onClick}
                                forceIcon
                            />
                        </div>
                        <div className="type">{this.getTypeContent(doc)}</div>
                        <div className="year">{this.getYear(doc)}</div>
                    </div>

                ))}
                {moreAmount > 0 && !showAll && (
                    <span className="more" onClick={this.showAllDocuments}>
                        {`View ${moreAmount} more ${pluralize('reference', moreAmount)}`}
                    </span>
                )}
            </Row>
        );
    }

}

References.propTypes = {
    data             : PropTypes.object,
    entity           : PropTypes.any,
    forceEmptyRemoval: PropTypes.bool,
    learnKnowledge   : PropTypes.func,
    model            : PropTypes.shape({}),
    onClick          : PropTypes.any,
    parameters       : PropTypes.any,
    registerCallbacks: PropTypes.func,
    width            : PropTypes.number,
    element          : PropTypes.shape({
        label: PropTypes.any
    }).isRequired,
};

References.defaultProps = {
    forceEmptyRemoval: false,
    model            : false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(References);

