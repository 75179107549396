/**
 * Keep all actions types listed there
 *
 */

// Triggered when we need to reset knowledge storage
export const RESET_VERSION          = 'RESET_VERSION';

// Triggered when some entities are coming from the API
export const NEW_KNOWLEDGE_ACQUIRED = 'NEW_KNOWLEDGE_ACQUIRED';

export const NEW_KNOWLEDGE_ENDPOINTS_ACQUIRED = 'NEW_KNOWLEDGE_ENDPOINTS_ACQUIRED';

export default {};
