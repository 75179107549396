import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import { Element, Row, Col, Grid }    from 'helpers';
import './assets/group.less';

/**
* Draw layouts with elements inside
*
*/
class DashboardGroup extends Component  {

    /** The Construct */
    constructor(props) {
        super(props);

        this.state = {
            childLines: false,
        };

        this.ref = React.createRef();
    }

    /**
    *
    */
    componentDidMount() {
        this.createLines();
    }


    /**
     *
     */
    createLines() {
        requestAnimationFrame(() => {
            const {
                    offsetHeight: parentHeight,
                    offsetWidth : parentWidth,
                    children    = []
                }          = this.ref?.current || {},
                childLines = [];

            for (const child of children) {
                const {
                    offsetHeight: height,
                    offsetWidth : width,
                    offsetLeft  : left,
                    offsetTop   : top
                } = child;

                if (width <= parentWidth / 2) {
                    const position    = left > parentWidth / 2 ? 'right' : 'left',
                        middle        = top + (height / 2),
                        topPercentage = (middle / parentHeight) * 100,
                        key           = child.dataset.qaKey;

                    childLines.push((
                        <div
                            key={`line-${key}`}
                            className={`line ${position} ${key}`}
                            style={{top: `${topPercentage}%`}}
                        />
                    ));
                }
            }

            this.setState({childLines});
        });
    }

    /**
    * Render the group Layout
    *
    * @return JSX
    */
    renderLayout(elementItem, color) {
        const {
            model, navigateTo,
            state, tags, bookmarks,
            storeElementInstances, onDataLoaded, onBeforeLoad
        } = this.props;

        return (
            <Element
                key={elementItem}
                model={model}
                module={module}
                state={state}
                element={elementItem}
                color={color}
                navigateTo={navigateTo}
                tags={tags}
                bookmarks={bookmarks}
                storeElementInstances={storeElementInstances}
                onDataLoaded={onDataLoaded}
                onBeforeLoad={onBeforeLoad}
            />
        );
    }

    /**
     * Render Layouts
     *
     * @returns
     */
    renderLayouts() {
        const { element }       = this.props,
            { configuration }   = element,
            { elements, color } = configuration;

        return elements instanceof Array
            ? elements.map((elementItem) => this.renderLayout(elementItem, color))
            : this.renderLayout(elements, color);
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { element }                = this.props,
            { childLines }               = this.state,
            { configuration }            = element,
            classNames                   = [
                'dashboard-group',
                'content-box',
                element.key,
                configuration.color
            ];

        return (
            <Col configuration={configuration} className={classNames.join(' ')}>
                <Row className="title">
                    <span>{element.label}</span>
                </Row>
                <Grid
                    forwardedRef={this.ref}
                    className="content"
                    configuration={configuration}
                >
                    {this.renderLayouts()}
                </Grid>
                <div className="lines">
                    {childLines}
                </div>
            </Col>
        );
    }

}

DashboardGroup.propTypes = {
    bookmarks            : PropTypes.any,
    model                : PropTypes.shape({}),
    navigateTo           : PropTypes.any,
    onBeforeLoad         : PropTypes.any,
    onDataLoaded         : PropTypes.any,
    state                : PropTypes.any,
    storeElementInstances: PropTypes.any,
    tags                 : PropTypes.any,
    element              : PropTypes.shape({
        configuration: PropTypes.shape({
            color   : PropTypes.any,
            elements: PropTypes.any
        }),
        id   : PropTypes.any,
        key  : PropTypes.any,
        label: PropTypes.any
    }).isRequired,
};

DashboardGroup.defaultProps = {
    model: false
};

export default DashboardGroup;

