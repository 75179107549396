import _    from 'lodash';

/**
* Get configuration elements
*
* @return array
*/
export const getConfigurationElements = (elements) => {
    if (_.isArray(elements)) {
        // Multiple elements
        return elements.map(element  => getConfigurationElements(element));
    }

    if (_.isObject(elements) && elements.elements) {
        // Multiple elements
        return getConfigurationElements(elements.elements);
    }

    if (_.isObject(elements)) {
        return getConfigurationElements(_.values(elements));
    }

    // Just a string
    return elements;
};


/**
* Get Sub Elements Ids
*
* @param element element
*
* @return array
*/
export const getSubElementsIds = (element) => {
    const { configuration } = element,
        { elements }        = configuration || {};

    if (!elements) {
        return [];
    }

    // Is dashboard elements
    if (elements.graph) {
        return [elements.graph];
    }

    // Is string
    if (_.isString(elements)) {
        return [elements];
    }

    // Is array
    return elements.reduce((returnArray, subElements) => returnArray.concat(_.flattenDeep(getConfigurationElements(
        _.isString(subElements) ? [subElements] : subElements
    ))), []);
};

/**
* Get area parent arealayout
*
* @param element
*
* @return string
*/
export const getParentElement = (elements, element, category) => {
    const parentElement= elements.find(
        el => _.flattenDeep([getConfigurationElements(_.get(el, 'configuration.elements'))])
            .includes(element.id)
    );

    return parentElement
        ? parentElement.category === category ? parentElement : getParentElement(elements, parentElement, category)
        : null;
};

/**
* Get Graph Label
*
* @param element
*
* @return string
*/
export const getExtendedLabels = (elements, element) => {
    const areaList     = getParentElement(elements, element, 'Area/Dashboard/List'),
        dashboardItem  = getParentElement(elements, element, 'Area/Dashboard/Item'),
        dashboardGroup = getParentElement(elements, element, 'Area/Dashboard/Group');

    let labels         = [];

    if (element.label) { labels.push(element.label); }
    if (areaList && areaList.label) { labels.push(areaList.label); }
    if (dashboardItem && dashboardItem.label) { labels.push(dashboardItem.label); }
    if (dashboardGroup && dashboardGroup.label) { labels.push(dashboardGroup.label); }

    labels = _.uniq(labels);

    return labels.length > 0
        ? `${labels.shift()}${labels.length ? ' (' : ''}${labels.join(' ')}${labels.length ? ')' : ''}`
        : null;
};


/**
* Get element source
*
* return Graph Component
*/
export const getSource = (elements, element) => {
    const rootElements = [
            { type: 'Dashboard', category: 'Area/Dashboard' },
            { type: 'Analyse', category: 'Area/Analyse' },
            { type: 'Dataset', category: 'Area/Dataset' },
        ],
        elementsFounded     = rootElements.map(el => getParentElement(elements, element, el.category)).filter(el => el !== null),
        rootElement         = elementsFounded.length > 0 ? elementsFounded[0] : null,
        { label, category } = rootElement || {},
        sourceDef           = rootElements.find(el => el.category === category),
        sourceType          = sourceDef ? sourceDef.type : null;

    return rootElement ? `${_.capitalize(sourceType)} (${label})` : null;
};



/**
* Get sub elements filter by category
*
* @param element element
*
* @return array
*/
export const getSubElementsFilterByCategory = (elements, category, element) => {
    if (!elements || !element || !category) {
        return [];
    }

    const subElementsIds      = getSubElementsIds(element),
        subElements           = _.orderBy(
            elements.filter(el => subElementsIds.includes(el.id)).toJS(),
            el => subElementsIds.indexOf(el.id) // Reorder element
        ),
        exportableElements    = subElements.filter(el => {
            const categories = _.isArray(category) ? category : [category];
            for (let i = 0; i < categories.length; i++) {
                if (el.category.indexOf(categories[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }),
        subExportableElements = _.flatten(_.map(subElements, el => getSubElementsFilterByCategory(elements, category, el)));

    // Reorder Dashboard/Item in Area/Layout element
    if (
        category === 'Area/Dashboard/Item'
        && exportableElements.length
        && element.category == 'Area/Layout'
    ) {
        // Add to sub layout elements the 'col' and 'row' types
        const layoutSubElementsWithType = getLayoutSubElementsWithType(element.configuration.elements),
            // Order elements by row, col (reading direction)
            sortedLayoutElements        = sortLayoutElementsByReadingDirection(layoutSubElementsWithType),
            // Get elements in a flat way
            flatElements                = sortedLayoutElements && flatLayoutElements(sortedLayoutElements),
            // Get full elements object
            newExportableElements       = flatElements && flatElements.map(
                el                      => exportableElements.find(expEl => el.elements === expEl.id)
            ).filter(el => !!el);

        // Elements have been sorted => use it
        if (newExportableElements) {
            return newExportableElements.concat(subExportableElements);
        }
    }

    return exportableElements.concat(subExportableElements);
};

/**
 * Flat layout elements
 * @param {array} layoutElements
 *
 * @returns array
 */
const flatLayoutElements = (layoutElements) => {
    let  flatElements = [];

    layoutElements.forEach((layoutElement => {
        if (_.isArray(layoutElement.elements)) {
            // Concat sub elements
            flatElements = flatElements.concat(flatLayoutElements(layoutElement.elements));
        }

        if (_.isString(layoutElement.elements)) {
            // Push a single element
            flatElements.push(layoutElement);
        }
    }));

    return flatElements;
};

/**
 * Add layoutType ('col' or 'row') to sub layout elements
 *
 * @param {array || object || string} layoutElements The layout elements
 * @param {string}        layoutType The layout type ('coll' or 'row')
 *
 * @return array || null
 */
const getLayoutSubElementsWithType = (layoutElements, layoutType = 'col') => {
    // Just the elements id
    if (_.isString(layoutElements)) {
        return layoutElements;
    }

    // Got sub elements (non layout elements) => go iterate
    if (layoutElements.elements) {
        return getLayoutSubElementsWithType(layoutElements.elements, 'col');
    }

    // Invert col / row type and iterate
    if (_.isArray(layoutElements)) {
        return layoutElements.map(
            el => ({ layoutType, elements: getLayoutSubElementsWithType(el, layoutType === 'row' ? 'col' : 'row')})
        );
    }

    // Nothing detected
    return null;
};

/**
 * Sort layout elements by reading direction and
 * @param {array} layoutElementsWithType
 *
 * @return array || null
 */
const sortLayoutElementsByReadingDirection = (layoutElementsWithType) => {
    if (layoutElementsWithType && layoutElementsWithType[0].layoutType === 'col') {
        let ordered     = [];
        const colCount  =  layoutElementsWithType.length,
            // Get max row count for all columns
            rowCount  =  layoutElementsWithType.reduce(
                (cumul, el) => {
                    const count = _.isArray(el.elements) ? el.elements.length : 1;
                    return cumul > count ? cumul : count;
                },
                0
            );

        // Go for reading direction
        for (let row = 0; row < rowCount; row++) {
            for (let col = 0; col < colCount; col++) {
                if (layoutElementsWithType[col]
                    && layoutElementsWithType[col].elements
                    && layoutElementsWithType[col].elements[row]
                ) {
                    const element = layoutElementsWithType[col].elements[row];
                    // Sub layouts => must be iterated
                    if (_.isArray(element.elements)) { // eslint-disable-line max-depth
                        // Merge sub elements
                        ordered = ordered.concat(sortLayoutElementsByReadingDirection(element.elements));
                        continue;
                    }

                    ordered.push(element);
                }
            }
        }

        return ordered;
    }

    return null;
};


/**
 * Get dashboards elements ordered by rank
 *
 * @param {ImmutableList} elements
 */
export const getDashboardsElements = (elements) => {
    const dashboardsElements = _.orderBy(
        elements.filter(
            (el) => el.category === 'Area/Dashboard'
        ).toJS(),
        el => el.configuration.rank
    ).map((el, index) => {
        el.index = index;
        return el;
    });

    return dashboardsElements;
};

/**
* Extract topics from knowledge (search sections)
*
* @return array
*/
export const getTopicsForSearch = (elements, disableCompetitive) => {
    const dashboardElements = elements && getDashboardsElements(elements) || [],
        options = {
            elements,
            elementCategory: 'Area/Dashboard/Group',
            disableCompetitive
        },
        sections          = dashboardElements.map((dashboardElement, index) => ({
            id           : dashboardElement.key,
            index,
            label        : dashboardElement.label,
            category     : dashboardElement.category,
            configuration: dashboardElement.configuration,
            disabled     : disableCompetitive && dashboardElement.key.indexOf('competitive-landscape') !== -1,
            explanation  : '',
            subSections  : getDashboardSubSection({ ...options, dashboardElement }),
        }));

    return sections;
};

/**
* Returns section definition of idcard
*
* @return array
*/
export const getIdcardSections = ({ type, context, elements }, category) => {
    if (!elements) {
        return;
    }

    const sections = elements.filter((element) => {
        const { configuration } = element,
            { useContext }      = configuration || {};

        return element.target === `${type}-id-card`
        && element.category === category
        && (!useContext || context);  // Manage section with useContext in configuration
    }).toArray();

    return _.sortBy(sections, section => section.configuration.order);
};

/**
* Extract topics from knowledge (orgunit sections)
*
* @return array
*/
export const getTopicsForOrgunit = (elements, resources) => {
    const sections = getIdcardSections({type: 'orgunit', elements}, 'Area/Section')
        .filter(section => !_.includes(section.key, 'section-about')) // TODO: find a better solution ?
            .map(section => {
                const subSections = getSubElementsFilterByCategory(
                    elements,
                    'Area/Layout',
                    section
                ).filter(subSection => subSection.target === 'id-card')
                    .map(subSection => {
                        const analyse   = elements.find(element => element.category === 'Area/Analyse' && element.key === subSection.key),
                            { list }    = analyse?.configuration?.elements || {},
                            listElement = elements.find(element => element.id === list),
                            resourceId  = listElement?.resource?.id,
                            resource    = resources?.find(res => res.id === resourceId),
                            { models }  = resource || {},
                            modelType   = models && models[0] && models[0].type;

                        return {
                            ...subSection,
                            modelType,
                        };
                    });

                return {
                    ...section,
                    subSections,
                    elementId: section.id,
                    id       : section.key.replace('section-', ''),
                };
            });

    return sections || null;
};


/**
 * Get subsection for a dashboard layout
 *
 * @param {object} layoutElement
 */
export const getDashboardSubSection = (options) => {
    const {
            elements, dashboardElement,
            elementCategory, disableCompetitive
        } = options,
        dashboardItemsElements = getSubElementsFilterByCategory(
            elements,
            elementCategory,
            dashboardElement

        ),
        elementsFiltered = elementCategory === 'Area/Dashboard/Item'
            ? dashboardItemsElements.filter(el => el.configuration.newsletterable)
            : dashboardItemsElements;

    return elementsFiltered.map((el, index) => {
        const subSections = elementCategory === 'Area/Dashboard/Group'
                ? getDashboardSubSection({
                    elements,
                    dashboardElement: el,
                    elementCategory : 'Area/Dashboard/Item',
                    disableCompetitive
                })
                : null,
            section = {
                id           : el.key,
                index,
                label        : el.label,
                category     : el.category,
                configuration: el.configuration,
                disabled     : disableCompetitive && dashboardElement.key.indexOf('competitive-landscape') !== -1,
                explanation  : '',
                hideChildren : elementCategory === 'Area/Dashboard/Group',
            };

        if (subSections && subSections.length > 0) {
            section.subSections = subSections;
        }

        return section;
    });
};

