/**
 * Auth Actions Creator: create actions for the current member
 *
 */
import _                from 'lodash';
import CryptoJS         from 'crypto-js';
import * as types       from './types/auth';
import * as ajax        from '../../core/utils/api';
import * as utilText    from '../../core/utils/text';
import { getTimeStamp } from '../../core/utils/date';

/**
 * Fetch the Member from the API
 *
 * @return {Promise} With the member object
 */
const fetchMember    = () => ajax.get('/member', {byPassWaitingAccessToken: true});

/**
 * Trigger a UPDATE to the API to update member
 *
 * @param  {Object} parameters to send to the Ovation API
 *
 * @return {Promise} ....
 */
const updateMember   = (parameters) => ajax.patch('/member', parameters);

/**
 * Fetch ticket from api with open id code
 * @param {*} parameters
 * @returns
 */
const httpFetchAccessToken = (parameters) => ajax.get('/auth', {...parameters, apiService: 'public-api', noAuthHeaders: true });


/**
 * Get SSO login URL
 *
 * @returns string
 */
const getSSOLoginUrl = () => {    // eslint-disable-line  no-unused-vars
    const locationString = document.location.toString(),
        isCnUrl          = locationString.match(process.env.OVATION_APP_CN),
        appUrl           = isCnUrl ? process.env.OVATION_APP_CN : process.env.OVATION_APP,
        ssoUrl           = process.env.SSO_HOST,
        stateBeforeLogin = localStorage.getItem('stateBeforeLogin');

    return `https://${ssoUrl}/authorize?response_type=code&client_id=orbit-insight&state=${stateBeforeLogin}&scope=openid+rights+profile+email+yoomap+account+offline_access+services&redirect_uri=${encodeURIComponent(appUrl)}`; // eslint-disable-line
};

/**
 * Manage a option to activate OIDC
 *
 * @returns boolean
 */
const OIDCisActive = () => {
    return !!localStorage.getItem('OIDC_ACTIVE');
};


/**
 * Redirect the user to the Orbit login page
 * or get access_token with refresh_token
 *
 * @return {string}
 *
 * @return boolean
 */
export const startLoginProcess = async () => {
    // Getting of access token is in progress
    if (actionIsInProcess('isGettingAccessToken')) {
        return;
    }

    if (OIDCisActive()) {
        localStorage.setItem('isLoginToOIDC', getTimeStamp());
    }

    const timestamp    = new Date().getTime(),
        appName        = 'Insight',
        locationString = document.location.toString(),
        isCnUrl        = locationString.match(process.env.OVATION_APP_CN),
        orbitUrl       = isCnUrl ? process.env.ORBIT_API_URL_CN : process.env.ORBIT_API_URL,
        state          = getParamFromUrl('state'),
        openIdCode     = getParamFromUrl('code');

    cleanTokensInLocalStorage();

    if (!state && !openIdCode) {
        localStorage.setItem('locationBeforeLogin', locationString);
        localStorage.setItem('stateBeforeLogin', crypto.randomUUID());
    }

    document.location = OIDCisActive()
        ? getSSOLoginUrl()
        : `https://${orbitUrl}/?delegateFor=${encodeURIComponent(document.location)}&appName=${appName}&timestamp=${timestamp}`; // eslint-disable-line max-len
};


/**
 * Clear tokens in localStorage
 */
const cleanTokensInLocalStorage = () => {
    const openIdCode  = getParamFromUrl('code');

    localStorage.removeItem('OI');
    localStorage.removeItem('OIDC');
    localStorage.removeItem('OIDC_REFRESH');
    localStorage.removeItem('OIDC_EXPIRE');
    localStorage.removeItem('OIDC_TOKEN_TYPE');
    localStorage.removeItem('OIDC_SESSION');
    localStorage.removeItem('OIDC_TIME');

    // Clean stored old uri
    if (!openIdCode) {
        localStorage.removeItem('locationBeforeLogin');
    }
};

/**
 * Store action time in localStorage
 *
 * @param {boolean} state
 */
const setActionTime = (action) => {
    localStorage.setItem(action, getTimeStamp());
};


/**
 * Get actionIsInProcess (is a TLL bit)
 *
 * @returns boolean
 */
const actionIsInProcess = (action) => {
    const storedDate = localStorage.getItem(action),
        timePassed   = storedDate
            ? getTimeStamp() - storedDate
            : null;

    return !_.isNull(timePassed) ? timePassed  < 20 : false;
};

/**
 * Redirect the user to the Orbit logout page
 *
 */
const navigateToLogoutPage = () => {
    const timestamp    = new Date().getTime(),
        appName        = 'Insight',
        id_token       = getSessionToken(),
        locationString = document.location.toString(),
        isCnUrl        = locationString.match(process.env.OVATION_APP_CN),
        ssoUrl         = process.env.SSO_HOST;

    cleanTokensInLocalStorage();

    // eslint-disable-next-line
    document.location = OIDCisActive()
        ? `https://${ssoUrl}/endsession?state=${crypto.randomUUID()}&id_token_hint=${id_token}&post_logout_redirect_uri=${isCnUrl ? process.env.OVATION_APP_CN : process.env.OVATION_APP}` // eslint-disable-line
        : `https://${process.env.ORBIT_API_URL}/logout?delegateFor=${encodeURIComponent(document.location)}&appName=${appName}&nocache=${timestamp};` // eslint-disable-line

    return true;
};

/**
* Redirect the user to the Orbit login page
*
* @param int code The answered status code from API
*
* @return boolean
*/
export const navigateToErrorPage = (code) => {
    // eslint-disable-next-line
    document.location = `/#/ae/${code}` ;

    return true;
};

/**
 * Trigger a DELETE to the API to revoke member authorization
 *
 * @return {Promise} With the member object
 */
const delMember = () => ajax.del('/auth').then(({ body }) => {
    navigateToLogoutPage(body);
});

/**
 * Action library, register actions to obtain member from API
 */
const Actions = {
    // The action token
    getToken: () => ({ type: types.OBTAIN_TOKEN })
};

/**
 * Trigger a setting update
 *
 * @return {Promise} With the member object
 */
export const updateMemberSettings = (newSettings) => (dispatch, getState) => {
    const member  = getState().get('auth').get('member'),
        settings  = member.get('settings') || {};

    // Update member settings in BD
    updateMember({ settings: newSettings });

    return dispatch({ type: types.MEMBER_UPDATED, payload: member.set('settings', _.merge(_.clone(settings), newSettings)) });
};


/**
* Get export user settings
*
* @return object
*/
export const getExportSettings = () => (dispatch, getState) => {
    const member       = getState().get('auth').get('member'),
        memberSettings = member.get('settings') || {};

    return !_.isNull(memberSettings) && memberSettings.export  ? memberSettings.export : {};
};



/**
* Get defaultPlatform from member settings
*
* @returns string
*/
export const getDefaultSharePlatform = () => (dispatch, getState) => {
    const settings          = getExportSettings()(dispatch, getState),
        share               = settings.share || {},
        { defaultPlatform } = share;

    return defaultPlatform;
};

/**
* Boolean to indicate whether we must use a custom authorizaton or not
*
* @return Boolean
*/
const useCustomAuthorizationMethod = () => _.isFunction(window.getAuthorization);


/**
 * Make the Authorization header
 *
 * @param {object} data
 * @returns
 */
const getOvationAuthorization = (data) => {
    const header          = { alg: 'HS256', typ: 'JWT' },
        stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header)),
        encodedHeader     = utilText.base64url(stringifiedHeader),
        stringifiedData   = CryptoJS.enc.Utf8.parse(JSON.stringify({ iss: process.env.OVATION_JWT_KEY, ...data})),
        encodedData       = utilText.base64url(stringifiedData),
        token             = `${encodedHeader}.${encodedData}`,
        signature         = utilText.base64url(CryptoJS.HmacSHA256(token, process.env.OVATION_JWT_SECRET));

    return `Bearer ${token}.${signature}`;
};

/**
 * Fetch access token from code or refresh_token
 */
const fetchAccessToken = ({ openIdCode, refresh_token, resolve }) => {
    const data        = {};

    if (refresh_token) {
        data.refresh_token = refresh_token;
    }

    if (openIdCode) {
        data.code = openIdCode;
    }

    httpFetchAccessToken({data}).then((response) => {
        const {status, body} = response;

        if (status.code !== 200) {
            setTimeout(() => {
                resolve({});
                // Clean isGettingAccessToken to be ready to restart the login process
                localStorage.removeItem('isGettingAccessToken');
            }, status.code === 401 ? 0 : 5000);
            return;
        }

        setAccessToken(body);

        resolve({
            Authorization: getOvationAuthorization({
                access_token: body.access_token,
                token_type  : body.token_type,
                expires_in  : body.expires_in
            })
        });
    });
};

/**
* Get authorization
*
* @return Promise(Obj)
*/
export const getAuthorizationHeaders = async (options) => {  // eslint-disable-line max-lines-per-function
    const { byPassWaitingAccessToken } = options || {};

    if (!useCustomAuthorizationMethod()) {
        return new Promise((resolve) => { // eslint-disable-line max-lines-per-function
            const ticket         = getTicket(),
                access_token     = getAccessToken(),
                refresh_token    = getRefreshToken(),
                token_time       = getTokenTime(),
                token_expire     = getTokenExpire(),
                token_type       = getTokenType(),
                openIdCode       = getParamFromUrl('code'),
                state            = getParamFromUrl('state'),
                stateBeforeLogin = localStorage.getItem('stateBeforeLogin'),
                timePassed       = token_time && (getTimeStamp() - token_time),
                mustRefresh      = refresh_token && timePassed && timePassed > _.random(~~(token_expire * 0.8), token_expire);
                // The token duration is 3600s

            // Retrying later to getAuthorizationHeaders
            if (
                !byPassWaitingAccessToken
                && (actionIsInProcess('isGettingAccessToken') || actionIsInProcess('isLoginToOIDC'))
            ) {
                setTimeout(() => {
                    getAuthorizationHeaders(options).then(resolve);
                }, 1000);

                return;
            }

            // Return the authorization header when an access_token is already set
            if (access_token && !mustRefresh) {
                resolve({
                    Authorization: getOvationAuthorization({ access_token, token_type, token_expire })
                });

                // Redirect when an other instance as set access_token while a login process
                // To clean the url
                if (openIdCode) {
                    resolve({});
                    navigateToBeforeLogin();
                }

                return;
            }

            // Bad OIDC parameters => redirect to stored page
            if (
                openIdCode && stateBeforeLogin !== state  // Bad state
                || !openIdCode && state        // Only a state
            ) {
                resolve({});
                navigateToBeforeLogin();
            }

            // Good OIDC parameters or time to use refresh_token
            if (
                (openIdCode && stateBeforeLogin === state)
                || mustRefresh && refresh_token
            ) {
                setActionTime('isGettingAccessToken', true);

                // Let kong take the time to manage current requests
                setTimeout(
                    () => {
                        fetchAccessToken({ openIdCode, refresh_token, resolve});
                    },
                    refresh_token ? 2000 : 0
                );

                return;
            }

            // Force the user to have a ticket to send a request.
            if (!ticket) {
                startLoginProcess();
                return;
            }

            resolve({
                Authorization: getOvationAuthorization({ ticket })
            });
        });
    }

    // Use custom authorization method
    return await window.getAuthorization();
};


/**
* Get ticket value from local storage
*
* @return string
*/
export const getTicket = () =>  {
    const encryptedTicket = localStorage.getItem('OI');

    return encryptedTicket ? CryptoJS.AES.decrypt(encryptedTicket, process.env.OVATION_SECRET).toString(CryptoJS.enc.Utf8)
        : false;
};

// TODO: encrypt OIDC values

/**
* Get access_token value from local storage
*
* @return string
*/
export const getAccessToken = () =>  {
    return localStorage.getItem('OIDC');
};

/**
* Get access_token value from local storage
*
* @return string
*/
export const getRefreshToken = () =>  {
    return localStorage.getItem('OIDC_REFRESH');
};

/**
* Get token time value from local storage
*
* @return string
*/
export const getTokenTime = () =>  {
    return parseInt(localStorage.getItem('OIDC_TIME'));
};


/**
* Get token expire value from local storage
*
* @return string
*/
export const getTokenExpire = () =>  {
    return parseInt(localStorage.getItem('OIDC_EXPIRE'));
};


/**
* Get token expire value from local storage
*
* @return string
*/
export const getTokenType = () =>  {
    return localStorage.getItem('OIDC_TOKEN_TYPE');
};


/**
* Get access_token value from local storage
*
* @return string
*/
export const getSessionToken = () =>  {
    return localStorage.getItem('OIDC_SESSION');
};

/**
* Store ticket to local storage
*
* @param string ticket The ticket to crypt & store
*
* @return void
*/
const setTicket = (ticket) => {
    localStorage.setItem('OI', CryptoJS.AES.encrypt(ticket, process.env.OVATION_SECRET));
};


/**
 * Navigate to url before the login process
 */
const navigateToBeforeLogin= () => {
    document.location = localStorage.getItem('locationBeforeLogin') || '/#/';
};

/**
* Store ticket to session
*
* @param string ticket The ticket to crypt & store
*
* @return void
*/
const setAccessToken = (body) => {
    const { access_token, id_token, refresh_token, expires_in, token_type } = body || {};

    localStorage.setItem('OIDC', access_token);
    localStorage.setItem('OIDC_SESSION', id_token);
    localStorage.setItem('OIDC_REFRESH', refresh_token);
    localStorage.setItem('OIDC_EXPIRE', expires_in);
    localStorage.setItem('OIDC_TOKEN_TYPE', token_type);
    setActionTime('OIDC_TIME');
    localStorage.removeItem('isLoginToOIDC');
    localStorage.removeItem('isGettingAccessToken');
};


/**
 * Extract the ticket from URL parameters
 *
 * @return string||bool
 */
const getParamFromUrl = (paramName) => {
    const ticketRegexp   = new RegExp(`${paramName}=([\\w-]*)`),
        locationAsString = window.location.toString(),
        ticketMatches    = locationAsString.match(ticketRegexp);

    return ticketMatches && ticketMatches.length === 2 ? ticketMatches[1] : false;
};


/**
* Obtain ticket from url params or from the local storage
*
* @return self
*/
const handleTicketSession = () => {
    // A custom authorizaton methods has been injected
    if (useCustomAuthorizationMethod()) {
        return;
    }

    // Use the ticket workflow
    const ticket = getParamFromUrl('ticket');

    // There's a ticket provided by url
    if (!_.isUndefined(ticket) && ticket !== false) {
        // Store an encrypted ticket in the session storage
        setTicket(ticket);
    }

    // There's no ticket stored in session, re-log the user
    if (!getTicket() && !getAccessToken() && !getParamFromUrl('code') && !actionIsInProcess('isGettingAccessToken')) {
        startLoginProcess();
    }
};

/**
* Refresh the current member data, sync it with the API's one
*
* @return {Promise}
*/
export const initMember = () => (dispatch) => {
    const ticket = getParamFromUrl('ticket');

    // Auth Error page
    if (window.location.hash.match(/^\#\/ae\/4[01]\d+$/)) {
        return;
    }

    // Store the ticket in the session storage.
    handleTicketSession();

    fetchMember().then((response) => {
        const { body, status } = response;

        if (status.code !== 200) {
            return dispatch({ type: types.MEMBER_FETCHED, payload: { guest: true } });
        }

        // Member is authentified?
        if (!body.guest) {
            const urlParamsRegExp = new RegExp(/\?([^=]*=[^=/]*&?)*/),
                locationAsString  = window.location.toString();

            // If the url has some parameters
            if (ticket) {
                const locationWithoutTicket = locationAsString.replace(urlParamsRegExp, '');

                // Replace the ticket&shard part without reload
                window.history.replaceState({}, document.title, locationWithoutTicket);
            }

            return dispatch({ type: types.MEMBER_FETCHED, payload: body });
        }
    });
};

/**
* Log out the current member
*
* @return {Promise}
*/
export const logoutMember = () => () => delMember();

// Export default
export default Actions;
