import * as types     from '../actions/types/insightBot';
import { initialMap } from '../initialStates';
import Immutable      from 'immutable';

/**
 * Add Lexicon
 *
 * @param {*} state
 * @param {*} payload
 *
 * @returns state
 */
const addLexicon = (state, payload) => {
    const lexicons = state.get('lexicons') || new Immutable.Map();

    return state.set('lexicons', lexicons.set(payload.key, payload));
};

/**
 * Manage the InsightBot slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const InsightBot = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {
        // Update the state with lexicons
        case types.INSIGHT_BOT_ADD_LEXICON:
            return addLexicon(state, action.payload);

        default:
            break;
    }

    return state;
};

export default InsightBot;
