// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-dropdown .concepts .concept {
  border-top: 1px solid #DEDEDE;
}
.autocomplete-dropdown .concepts .concept:first-child {
  border-top: none;
}
.autocomplete-dropdown .concepts .concept .ant-skeleton-title {
  height: 23px;
  margin: 6px 0 3px 0;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Search/Autocomplete/Menus/assets/concept.less"],"names":[],"mappings":"AAAA;EAGQ,6BAAA;AADR;AAGQ;EACI,gBAAA;AADZ;AALA;EAUY,YAAA;EACA,mBAAA;AAFZ","sourcesContent":[".autocomplete-dropdown .concepts {\n\n    .concept {\n        border-top: 1px solid #DEDEDE;\n\n        &:first-child {\n            border-top: none;\n        }\n\n        .ant-skeleton-title {\n            height: 23px;\n            margin: 6px 0 3px 0;\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
