import React, { Component }           from 'react';
import { connect }                    from 'react-redux';
import _                              from 'lodash';
import { Skeleton }                   from 'antd';
import PropTypes                      from 'prop-types';
import { learn }                      from 'store/actions/knowledge';
import { Flag }                       from 'helpers';

import PDFIcon                        from './assets/pdffulltext.png';

import './assets/family.less';

/**
* Draw Id cards reference
*
*/
class Family extends Component  {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        const { registerCallbacks, learnKnowledge } = this.props;

        _.bindAll(this, 'getEntities');

        learnKnowledge('entities')
            .then(this.setState.bind(this));

        // Register callbacks
        registerCallbacks && registerCallbacks('getEntities', this.getEntities);
    }

    /**
    * Return entities from props
    *
    * @return array
    */
    getEntities() {
        const { model } = this.props,
            { FAMILY }   = model;

        return FAMILY;
    }

    /**
    * Render all the references as a list
    *
    * @return JSX
    */
    render() {
        const { model } = this.props,
            hasFamilies  = model && model.FAMILY
                && _.isArray(model.FAMILY) && model.FAMILY.length > 0;

        if (!model) {
            return (
                <div className="families">
                    <Skeleton active paragraph={{ rows: 10, width: '100%' }} />
                </div>
            );
        }

        // Prevent family render
        if (!hasFamilies) {
            return (
                <div className="families">
                    <div className="category">Family</div>
                    <Skeleton active paragraph={false} />
                </div>
            );
        }

        return (
            <div className="families">
                <div className="category">Family</div>
                <table>
                    <tbody>
                        {_.map(model.FAMILY, (family, index) => {
                            const liClass = family.ACT_STATE === 'DEAD' ? 'dead' : '';

                            return (
                                <tr className={liClass} key={`family-${index}`}>
                                    <td className="flag"><Flag height={15} width={25}
                                        iso={family.CC}
                                    />
                                    </td>
                                    <td className="date">{family.DATE}</td>
                                    <td className="status">{_.capitalize(family.ACT_STATUS)}</td>
                                    <td className="fulltext">
                                        <a href={family.PDF_LINK} target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={PDFIcon} alt="Get full text content" />
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

}

Family.propTypes ={
    element       : PropTypes.shape({}).isRequired,
    learnKnowledge: PropTypes.func,
    model         : PropTypes.shape({
        FAMILY: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    }),
    registerCallbacks: PropTypes.func,
};

Family.defaultProps = {
    model: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Family);

