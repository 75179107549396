// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./little-skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Bar graph
*
*/
.Shape svg text tspan.label {
  font-weight: bold;
}
.Shape.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/Shape/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAGY,iBAAA;AAFZ;AAMI;EACI,mDAAA;AAJR","sourcesContent":["/**\n* Bar graph\n*\n*/\n\n.Shape {\n    svg {\n        text tspan.label {\n            font-weight: bold;\n        }\n    }\n\n    &.skeleton {\n        mask-image:  url('./little-skeleton.svg');\n    }\n\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
