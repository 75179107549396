import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    scaleLinear as d3ScaleLinear,
    hsl         as d3Hsl,
    max         as d3Max,
}                           from 'd3';

import {
    formatNumberWithMagnitude,
    pluralize,
    capitalize,
    fillTemplate,
}                           from 'utils/text';
import { linkPath }         from 'utils/svg';
import Landmark             from '../Landmark';

import './Planet/main.less';

/**
 * The planet graph Component
 *
 */
class Planet extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderDefs', 'render');

        this.state = {};

        // Make a uuid
        this.id = _.uniqueId('planet-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - scales (D3Scales object)
    *          - graph data
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps/* , prevStates */) {
        const {
                margin,
                color,
                showGap,
                content,
            }           = nextProps,
            dc          = d3Hsl(color),
            serie       = showGap ? _.orderBy(content, ['other'], ['desc']) : _.orderBy(content, ['value'], ['desc']),
            colors      = {
                text      : color,
                line      : dc.brighter(2.5).toString(),
                landmark  : dc.brighter(1).toString(),
                minePlanet: [
                    dc.brighter(2.5).toString(),
                    dc.toString(),
                    dc.brighter(1.5).toString(),
                ],
                planet: [
                    dc.brighter(3.5).toString(),
                    dc.brighter(2.5).toString(),
                    dc.brighter(3.0).toString(),
                ],
            },
            // Cascading function to update state
            updateState         = _.flow([
                Planet.updateScale,          // D3 scale
                Planet.updatePercentGap,     // PercentGap
                Planet.updatePlotData,       // Plot data labels positions
            ]),
            { state: newState } = updateState({
                nextProps,
                state: {
                    serie,
                    // Store the max value
                    maxValue   : d3Max(serie, (d) => d.value),
                    // Size of the chart (without landmark )
                    innerHeight: nextProps.height - 2 * margin,
                    innerWidth : nextProps.width  - 2 * margin - (showGap ? 0.3 * nextProps.width : 0),
                    colors
                }
            });

        return newState;
    }

    /**
    * Prepare scale
    *
    * @params options object { nextProps, state }
    *
    * @return self
    */
    static updateScale(options) {
        const { nextProps, state } = options,
            {
                innerPadding,
                outerMargin,
                margin,
                showGap,
            }                      = nextProps,
            {
                innerWidth,
                innerHeight,
                serie,
                maxValue
            }                      = state,
            // A valueSum               = serie.reduce((total, item) => total + item.value, 0),
            radiusSum              = serie.reduce((total, item) => total + Planet.getRadiusFromValue(item.value), 0),
            paddingSum             = (serie.length) * innerPadding * innerWidth,
            marginHSum             = (2 * outerMargin * innerWidth) + (2 * margin),
            marginVSum             = (2 * outerMargin * innerHeight) + (2 * margin),
            maxCircleHRadius       = (innerWidth - paddingSum - marginHSum) / 2,
            maxCircleVRadius       = (innerHeight - marginVSum) / 2,
            scaleBasedOnWidth      = !showGap || maxCircleHRadius < maxCircleVRadius,
            maxRadius              = scaleBasedOnWidth ? maxCircleHRadius : maxCircleVRadius;

        state.scaleBasedOnWidth  = scaleBasedOnWidth;
        state.scale              = d3ScaleLinear()
            .range([0, maxRadius])
            .domain([0, showGap ? 100 : radiusSum]);

        function customInterpolator(/* A min, max */) { // eslint-disable-line require-jsdoc
            return (a) => {
                const r = Planet.getRadiusFromValue(a) * maxRadius;
                return r;
            };
        }

        // A state.landmarkScale       = d3ScalePow().exponent(2)
        state.landmarkScale = d3ScaleLinear()
            .domain([0, maxValue])
            // A .range([0, maxRadius])
            .interpolate(customInterpolator);

        state.maxRadius = maxRadius;

        return { nextProps, state };
    }

    /**
    * Given a value, return the radius of a circle with that area.
    * @returns The radius of a circle with the given area.
    */
    static getRadiusFromValue(value) {
        return Math.sqrt(value / Math.PI);
    }

    /**
    * Get plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static updatePlotData(options) {
        const { nextProps, state } = options,
            {
                outerMargin,
                innerPadding,
                showGap,
                itemUnit
            }                     = nextProps,
            { innerWidth, scale } = state,
            { gapType, serie }    = state,
            plotData              = [],
            radiusByGap           = {
                smaller: [20, 60],
                similar: [40, 40],
                bigger : [60, 20],
            };

        // Location of actual planet
        let lastLoc = outerMargin * innerWidth + (showGap ? innerWidth / 4 : 0);

        _.each(serie, (d, i) => {
            const radiusValue = Planet.getRadiusFromValue(d.value),
                radius        = scale(showGap ? radiusByGap[gapType][i] : radiusValue),
                value         = formatNumberWithMagnitude(d.value, 1, false, true),
                unit          = `${itemUnit === '$' ? '' : ' '}${pluralize(itemUnit)}`;

            lastLoc += radius;
            plotData.push({
                location: lastLoc,
                size    : radius,
                value   : d.value,
                label   : d.label,
                odd     : i % 2 === 0,
                mine    : !d.other,
                labelPos: !showGap
                    ? {
                        // Label on top
                        x: radius / Math.sqrt(1.5),
                        y: radius / Math.sqrt(3),

                    }
                    : {
                        // Label on bottom
                        x: radius / Math.sqrt(7),
                        y: radius / Math.sqrt(1.2),

                    },
                formatedLabel: `${value}${unit}`,
            });
            lastLoc += radius + (innerPadding * innerWidth) + (showGap ? innerWidth / 5 : 0);
        });

        state.plotData = plotData;

        return { nextProps, state };
    }

    /**
    * Updater percent gap
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static updatePercentGap(options) {
        const { nextProps, state } = options,
            { serie }              = state,
            data0                  = _.get(serie, 0, {}),
            data1                  = _.get(serie, 1, {}),
            isMineFirst            = data0.other !== true || false,
            data0Radius            = Planet.getRadiusFromValue(data0.value),
            data1Radius            = Planet.getRadiusFromValue(data1.value),
            gap                    = data0Radius - data1Radius || 0,
            percentGap             = Math.round(gap / (isMineFirst ? data1Radius : data0Radius) * 100);

        state.percentGap = percentGap;
        state.gapType    = percentGap < -5 || percentGap > 5 ? (percentGap < 0 ? 'smaller' : 'bigger') : 'similar';

        return { nextProps, state };
    }

    /**
    * Render the svg defs
    *
    * @return html
    */
    renderDefs() {
        const { colors } = this.state;

        return (
            <defs>

                {/* Gradient of the planet */}
                <radialGradient
                    id={`${this.id}-filled`}
                    cx="50%"
                    cy="50%"
                    r="100%"
                >
                    <stop offset="15%" stopColor={colors.planet[0]} />
                    <stop offset="85%" stopColor={colors.planet[1]} />
                </radialGradient>

                {/* Gradient of the mine planet */}
                <radialGradient
                    id={`${this.id}-mine-filled`}
                    cx="50%"
                    cy="50%"
                    r="100%"
                >
                    <stop offset="15%" stopColor={colors.minePlanet[0]} />
                    <stop offset="85%" stopColor={colors.minePlanet[1]} />
                </radialGradient>

                {/* Linear gradient for gap lines */}
                <linearGradient
                    id={`${this.id}-linear`}
                    x1={0}
                    x2="100%"
                    y1={0}
                    y2={0}
                >
                    <stop offset="0%" stopColor={colors.planet[0]} />
                    <stop offset="100%" stopColor={colors.planet[1]} />
                </linearGradient>
            </defs>
        );
    }

    /**
    * Render value labels
    *   (label in planet center)
    *
    * @return html
    */
    renderValuesLabels() {
        const {
                plotData,
                innerHeight,
                colors,
            } = this.state,
            {
                fontSize,
                showGap,
            } = this.props,
            labelTop = innerHeight / 2,
            labelFontSize = fontSize * (showGap ? 1.2 : 1);

        return (
            <g className="value-labels">
                { plotData.map(d => {
                    const labelInCenter = d.size * 2 > labelFontSize * 5,
                        labelTopOffset = labelInCenter ? 0 : (d.size + labelFontSize / 4) * (showGap ? 1.7 : 1);

                    return !showGap || d.mine ? (
                        <g transform={`translate( ${d.location} ${labelTop + labelTopOffset} )`} key={d.label}>
                            <text
                                style={{
                                    textAnchor      : 'middle',
                                    fill            : colors.text,
                                    stroke          : 'none',
                                    dominantBaseline: labelInCenter ? 'middle' : (showGap ? 'baseline' : 'hanging'),
                                    fontWeight      : showGap ? 'bold' : null,
                                }}

                                fontSize={labelFontSize}
                            >
                                {d.formatedLabel}
                            </text>
                        </g>
                    ) : null;
                }) }

            </g>
        );
    }

    /**
    * Render gap label of the graph
    *
    * @return html
    */
    renderGap() {
        const { plotData } = this.state;

        return plotData.length === 0 ? null : (
            <g className="gap-labels">
                (
                {this.renderGapLine()}
                {this.renderGapLine(true)}
                )
            </g>
        );
    }

    /**
    * Render gap line
    *
    * @return html
    */
    renderGapLine(downline = false) {
        const { colors }              = this.state,
            { innerHeight, plotData } = this.state,
            data0                     = _.get(plotData, 0, { }),
            data1                     = _.get(plotData, 1, { });

        return (
            <path
                d={linkPath({
                    source: {
                        x: data0.location,
                        y: innerHeight / 2 - data0.size * (downline ? -1 : 1),
                    },
                    target: {
                        x: data1.location,
                        y: innerHeight / 2 - data1.size * (downline ? -1 : 1),
                    }
                })}
                style={{
                    stroke: colors.line,
                    fill  : 'none',
                }}
                strokeWidth={2}
                strokeDasharray="8"
            />
        );
    }

    /**
    * Render gap title
    *
    * @return html
    */
    renderGapTitle() {
        const { showGap, gapTitle, stats } = this.props,
            { fontSize }                   = this.props,
            { colors }                     = this.state,
            { innerHeight, plotData }      = this.state,
            data0                          = _.get(plotData, 0, {}),
            data1                          = _.get(plotData, 1, {}),
            maxGap                         = _.max([data0.size, data1.size]),
            top                            = (innerHeight / 2 - maxGap) / 4;

        return  showGap && gapTitle ? (
            <div className="gapTitle" style={{ color: colors.text, fontSize: fontSize * 1.3, top }}>
                {capitalize(fillTemplate(gapTitle, stats))}
            </div>
        ) : null;
    }

    /**
    * Render gap texts indicator
    *
    * @return html
    */
    renderGapTextsIndicator() {
        const { stats, fontSize, gapText } = this.props,
            { colors, plotData }           = this.state,
            { innerHeight }                 = this.state,
            data0                          = _.get(plotData, 0, {}),
            data1                          = _.get(plotData, 1, {}),
            maxGap                         = _.max([data0.size, data1.size]),
            bottom                         = (innerHeight - maxGap * 2) / 8 - fontSize,
            gapLabelKey                    = stats ? stats.difference : null,
            template                       = gapText && gapLabelKey ? _.get(gapText, gapLabelKey, null) : null;

        return template ? (
            <div
                className="gapText"
                style={{ color: colors.text, bottom }}
                dangerouslySetInnerHTML={{ __html: fillTemplate(template, stats) }}
            />
        ) : null;
    }

    /**
    * Render label of the graph
    *
    * @return html
    */
    renderLabel() {
        const {
                plotData,
                innerHeight,
                colors
            } = this.state,
            {
                showGap,
                fontSize,
                labelOffset,
                textPadding,
            } = this.props,
            labelTop        = innerHeight / 2,
            labelFontSize   = fontSize * 0.8,
            textPaddingCalc = labelFontSize + textPadding,
            options         = {
                colors,
                labelOffset,
                showGap,
                fontSize,
                labelTop,
                labelFontSize,
                textPaddingCalc
            };

        return showGap ? null : (
            <g className="labels">
                { plotData.map(d => Planet.renderLabelText(d, options)) }
            </g>
        );
    }

    /**
    * Render text of label
    *
    * @return html
    */
    static renderLabelText(d, options) {
        const {
                labelTop,
                labelFontSize,
                colors,
                showGap,
                labelOffset,
                textPaddingCalc
            } = options,
            labelTranslateX = d.location + d.labelPos.x,
            labelTranslateY = labelTop + d.labelPos.y * (showGap ? 1 : -1) + (d.mine ? 5 : 0),
            txtTranslate    = `translate(${textPaddingCalc + labelOffset} 0)`,
            txtRotate       = showGap ? '' : 'rotate(-45)';

        return (
            <g transform={`translate( ${labelTranslateX} ${labelTranslateY} )`} key={d.label}>
                <text
                    transform={`${txtRotate} ${txtTranslate}`}
                    style={{
                        textAnchor      : 'start',
                        fill            : colors.text,
                        fontWeight      : d.mine ? 'bold' : null,
                        stroke          : 'none',
                        dominantBaseline: 'middle'
                    }}
                    fontSize={labelFontSize * (d.mine ? 1.1 : 1)}
                >
                    {capitalize(d.label)}
                </text>
            </g>
        );
    }


    /**
     * Render Landmark label in bottom/right corner
     *
     * @return html
     */
    renderLandmarkLabel() {
        const { itemUnit, yLabel }      = this.props,
            { innerWidth, innerHeight } = this.state,
            label                       = yLabel || itemUnit;
        return (
            <g transform={`translate( ${innerWidth} ${innerHeight} )`}>
                <text style={{textAnchor: 'end'}}>
                    {label}
                </text>
            </g>
        );
    }

    /**
    * Render the landmark
    *
    * @return html
    */
    renderLandmark() {
        const {
                innerHeight,
                innerWidth,
                landmarkScale,
                colors,
                maxValue,
                maxRadius
            } = this.state,
            {
                itemUnit, yLabel, noContent,
            } = this.props;

        return (
            <Landmark
                height={maxRadius * 2}
                width={innerWidth}
                yScale={landmarkScale}
                yLabel={yLabel || itemUnit}
                ticksAlter={{
                    inverseYTick: true,
                    minY        : 0,
                    offsetY     : innerHeight / 2,
                    maxValue,
                }}
                color={colors.landmark}
                noContent={noContent}
            />
        );
    }

    /**
    * Render graph
    *
    * @return html
    */
    renderGraph() {
        const {
                plotData,
                innerHeight,
            } = this.state,
            filled      = `url("#${this.id}-filled")`,
            mineFilled  = `url("#${this.id}-mine-filled")`;

        return (
            <g className="dataPlot">
                {_.orderBy(plotData, ['mine']).map((d) => (  // Mine planet in front
                    <g key={d.label}>
                        <circle
                            cx={d.location}
                            cy={innerHeight / 2}
                            r={d.size}
                            key={d.label}
                            title={d.label}
                            style={{
                                // Stroke: d.mine ? colors.minePlanet[1] : colors.planet[0],
                                fill: d.mine ? mineFilled : filled,
                            }}
                        />
                        <title>
                            {`${d.label} : ${d.formatedLabel}`}
                        </title>
                    </g>
                )) }
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width, height, margin,
                showGap, skeleton,
                skeletonGradient,
                showLandmark
            }                                       = this.props,
            style                                   = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null,
            { colors, scaleBasedOnWidth, plotData } = this.state,
            { innerWidth }                          = this.state,
            data0                                   = _.get(plotData, 0, {}),
            data1                                   = _.get(plotData, 1, {}),
            horizontalCenter                        = scaleBasedOnWidth
                ? 0
                : (innerWidth - data1.location + data1.size / 2) / 2 - (data1.size - data0.size) / 4;

        if (skeleton) {
            return (
                <div className="Planet skeleton" style={style} />
            );
        }

        return (
            <div className="Planet">
                {this.renderGapTitle()}
                {this.renderGapTextsIndicator()}
                <svg
                    width={width}
                    height={height}
                    style={{ background: colors.background }}
                >
                    <g transform={`translate( ${(margin * 1.5) + horizontalCenter} ${margin} )`}>
                        { /* Definitions */ }
                        {this.renderDefs()}
                        { /* Landmark */ }
                        { showLandmark ? this.renderLandmarkLabel() : _.noop() }
                        { /* Gap informations */ }
                        {showGap ? this.renderGap() : null}
                        { /* Planet graph */ }
                        { this.renderGraph()}
                        { /* Labels */ }
                        { this.renderLabel() }
                        { this.renderValuesLabels() }
                    </g>
                </svg>
            </div>
        );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
Planet.propTypes ={
    color           : PropTypes.string,
    content         : PropTypes.any,
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fontSize        : PropTypes.number,
    gapText         : PropTypes.any,
    gapTitle        : PropTypes.any,
    height          : PropTypes.number.isRequired,
    innerPadding    : PropTypes.number,
    itemUnit        : PropTypes.string,
    labelOffset     : PropTypes.number,
    margin          : PropTypes.number,
    noContent       : PropTypes.any,
    outerMargin     : PropTypes.number,
    showGap         : PropTypes.bool,
    showLandmark    : PropTypes.bool,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    textPadding     : PropTypes.number,
    width           : PropTypes.number.isRequired,
    yLabel          : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    stats           : PropTypes.shape({
        difference: PropTypes.any
    }),
};

/**
 * Default props value
 */
Planet.defaultProps = {
    margin          : 0,
    fontSize        : 12,
    color           : 'var(--insight-color)',
    innerPadding    : 0.10,
    outerMargin     : 0.0,
    labelOffset     : 0,
    textPadding     : 5,
    showLandmark    : false,
    showGap         : false,
    skeleton        : false,
    itemUnit        : '',
    yLabel          : false,
    skeletonGradient: false,
};

export default Planet;

