// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grouped-list {
  gap: 6px;
}
.grouped-list .group-filter {
  display: flex;
  flex-direction: column;
}
.grouped-list .group-filter .group-checkboxes {
  display: flex;
  flex-direction: column;
  padding: 6px 0 0 3.7em;
  gap: 6px;
}
.grouped-list .group-filter .expand {
  display: flex;
  justify-content: center;
  width: 16px;
}
.grouped-list .group-filter .expand .icon {
  transition: 0.3s ease;
}
.grouped-list .group-filter .expand.active .icon {
  transform: rotate(180deg);
}
.grouped-list .group-filter .expand.inactive .icon {
  transform: rotate(90deg);
}
.grouped-list .group-filter .group-label {
  display: flex;
  flex: 1;
  gap: 6px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Filters/assets/groupedlist.less"],"names":[],"mappings":"AAGA;EACI,QAAA;AAFJ;AACA;EAGQ,aAAA;EACA,sBAAA;AADR;AAHA;EAOY,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,QAAA;AADZ;AATA;EAcY,aAAA;EACA,uBAAA;EACA,WAAA;AAFZ;AAdA;EAkBgB,qBAAA;AADhB;AAGY;EAAmB,yBAAA;AAA/B;AACY;EAAmB,wBAAA;AAE/B;AAvBA;EAyBY,aAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;AACZ","sourcesContent":["@text-color   : var(--primary-color);\n@checked-color: var(--primary-color);\n\n.grouped-list {\n    gap: 6px;\n    .group-filter {\n        display       : flex;\n        flex-direction: column;\n\n        .group-checkboxes {\n            display       : flex;\n            flex-direction: column;\n            padding       : 6px 0 0 3.7em;\n            gap           : 6px;\n        }\n\n        .expand {\n            display        : flex;\n            justify-content: center;\n            width          : 16px;\n            .icon {\n                transition: 0.3s ease;\n            }\n            &.active .icon   { transform: rotate(180deg) }\n            &.inactive .icon { transform: rotate(90deg)  }\n        }\n\n        .group-label  {\n            display    : flex;\n            flex       : 1;\n            gap        : 6px;\n            align-items: center;\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
