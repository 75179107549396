import React, { PureComponent }  from 'react';
import PropTypes                 from 'prop-types';
import { Skeleton }              from 'antd';
import { connect }               from 'react-redux';
import { learn }                 from 'store/actions/knowledge';

import _                         from 'lodash';

import { Collection, Highlight } from 'helpers';
import {
    formatNumberWithMagnitude,
    renderUnit,
    renderDateInterval,
}                                from 'utils/text';

import './assets/project.less';

/**
 * Display a list of projects
 *
 */
class Project extends PureComponent {

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['projectTypes'])
            .then(this.setState.bind(this));
    }

    /**
    * Get the project type label
    *
    * @param string subtype The project type
    *
    * @return string
    */
    getSubtypeLabel(projectTypeId) {
        const { projectTypes } = this.state;

        if (!projectTypes) {
            return false;
        }

        const projectTypeMdl = projectTypes.find((projectType) => projectType.id === projectTypeId);

        if (!projectTypeMdl) {
            return false;
        }

        return projectTypeMdl.label;
    }

    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity } = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                entity, showExpandedContent, renderActiveStatus,
                renderModelAction, onClick, renderOpenableIcon,
            }            = this.props,
            { start, end, title, label, clickable, project_acronym,
                subtype, total_cost, total_funding, currencySymbol,
            }            = entity,
            classNames   = ['label'],
            dates        = renderDateInterval(start, end),
            projectTitle = title || label,
            projectType  = this.getSubtypeLabel(subtype);

        if (showExpandedContent) {
            return false;
        }

        if (clickable) { classNames.push('clickable'); }

        return (
            <>
                <td className={classNames.join(' ')}
                    onClick={clickable ? onClick : null}
                >
                    <div className="left">
                        <Highlight
                            TagName="div" className="ellipsis"
                            title
                            stripTags
                        >
                            {projectTitle}
                        </Highlight>
                        {clickable && renderOpenableIcon()}
                    </div>
                    {renderActiveStatus()}
                    {clickable && renderModelAction()}
                </td>
                <td className="ellipsis aka" title={project_acronym}>{project_acronym}</td>
                <td className="ellipsis type" title={projectType}>{projectType}</td>
                <td className="cost">
                    {total_cost && renderUnit(formatNumberWithMagnitude(total_cost, 2), currencySymbol, true)}
                </td>
                <td className="funding">
                    {total_funding && renderUnit(formatNumberWithMagnitude(total_funding, 2), currencySymbol, true)}
                </td>
                <td className="date" title={dates}>{dates}</td>
            </>
        );
    }

    /**
    * Render loading project row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the expandable content
    *
    * @return JSX
    */
    renderExpandedContent() {
        const {
            entity, onClick,
            showExpandedContent, allowExport
        } = this.props;

        if (!showExpandedContent) {
            return false;
        }

        const authorities = _.isArray(entity.authority) && entity.authority.slice(0, 10);

        return (
            <>
                <td  colSpan={allowExport ? 4 : 2} className="abstract">
                    <div className="label">Abstract</div>
                    <Highlight
                        className="content content-ellipsis"
                        TagName="div"
                        stripTags
                    >
                        {entity.abstract_description || ''}
                    </Highlight>
                </td>
                <td colSpan={4} className="other-info">
                    <div className="related-to">
                        <span className="label">Related to</span>
                        {authorities && (
                            <Collection
                                entitySource={entity}
                                entities={authorities}
                                type="orgunit"
                                mode="inline"
                                allowExport={false}
                                onClick={onClick}
                            />
                        )}
                    </div>
                </td>
            </>
        );
    }

    /**
    * Render the project row for lists display
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render project row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Project.propTypes = {
    allowExport        : PropTypes.any,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    learnKnowledge     : PropTypes.func,
    entity             : PropTypes.shape({
        abstract_description: PropTypes.string,
        authority           : PropTypes.array,
        currencySymbol      : PropTypes.string,
        end                 : PropTypes.any,
        isLoading           : PropTypes.any,
        label               : PropTypes.string,
        project_acronym     : PropTypes.any,
        start               : PropTypes.any,
        clickable           : PropTypes.bool,
        subtype             : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title               : PropTypes.string,
        total_cost          : PropTypes.number,
        total_funding       : PropTypes.number
    }),
};

Project.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(Project);

