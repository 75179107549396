import _                          from 'lodash';
import React, { Component }       from 'react';
import { connect }                from 'react-redux';
import PropTypes                  from 'prop-types';
import { capitalize }             from 'utils/text';
import { learn }                  from 'store/actions/knowledge';
import { emitEvent }              from 'store/actions/sockets';
import { Action, Icon, IButton }  from 'helpers';

import './assets/entities.less';

/**
 * Show filter for all entities
 *  - entity type
 *  - tags
 *  - monitored
 */
class EntitiesFilters extends Component {


    /**
    * Class constructor
    */
    constructor(props) {
        super(props);

        this.state = {
            enabledFilters: []
        };

        _.bindAll(this, 'toggleFilter', 'onToggleDisplayFilters');
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['tags', 'entities']).then(this.setState.bind(this));
    }


    /**
    * It toggles the filter.
    */
    toggleFilter(filter) {
        const { onUpdateFilters } = this.props,
            { enabledFilters }    = this.state,
            allFilters            = this.getAllFilters(),
            newEnabledFilters     = _.xor(enabledFilters, [filter.id]),
            filters               = allFilters.filter(filter => newEnabledFilters.includes(filter.id));

        this.setState({ enabledFilters: newEnabledFilters });

        onUpdateFilters(filters);
    }

    /**
     * It returns all filters action
     * @returns An array of actions.
     */
    getActions() {
        const tagsActions        = this.getTagsActions(),
            monitoredActions     = this.getMonitoredActions(),
            entitiesTypesActions = this.getEntitiesTypesActions();

        return {...entitiesTypesActions, separator1: {}, ...monitoredActions, separator2: {}, ...tagsActions};
    }


    /**
     * Get all possible filters
     */
    getAllFilters() {
        const { tags }      = this.state,
            entitiesFilters = this.getEntitiesFilters(),
            monitorFilter   = this.getMonitorFilter();

        return [...entitiesFilters, monitorFilter, ...tags?.toJS()];
    }

    /**
     *  Counting the number of filters that are enabled.
     */
    getFilterCount() {
        const { enabledFilters } = this.state;

        return enabledFilters.length;
    }

    /**
    * Classify actions
    *
    * @return self
    */
    getTagsActions() {
        const{
                tags,
                enabledFilters,
            }       = this.state,
            actions = {};

        if (!tags) {
            return {};
        }

        tags.forEach((tag) => {
            const icon = (
                <Icon
                    type={tag.icon}
                    theme="filled"
                    style={{ color: tag.color }}
                    className="icon"
                    height={13}
                    color={tag.color}
                />
            );

            actions[tag.id] = {
                icon,
                preserveIconColor: true,
                cb               : () => this.toggleFilter(tag),
                label            : tag.label,
                selected         : enabledFilters.includes(tag.id),
            };
        });

        return actions;
    }

    /**
     * Get monitor filter definition
     *
     * @returns object
     */
    getMonitorFilter() {
        return { id: 'monitored', type: 'monitor' };
    }

    /**
     * Get monitored actions
     *
     * @returns object
     */
    getMonitoredActions() {
        const { enabledFilters } = this.state,
            icon                 = (
                <Icon
                    type="monitored"
                    height={14}
                />
            );


        return   {
            monitored: {
                icon,
                label   : 'Monitored',
                cb      : () => this.toggleFilter(this.getMonitorFilter()),
                selected: enabledFilters.includes('monitored'),
            }
        };
    }

    /**
    * It returns an array of entities definitions to filter
    *
    * @return object
    */
    getEntitiesFilters() {
        const { entities } = this.state,
            enabledType = [
                'orgunit',
                { id: 'query', label: 'search', type: 'query' },
                'patent',
                'scidoc',
                'clinicaltrial',
                'project',
            ],
            entitiesDefinitions = entities && enabledType.map(
                type => {
                    return _.isObject(type)
                        ? type
                        : entities.find(definition => definition.id === type);
                }
            );

        return entitiesDefinitions || [];
    }

    /**
    * Get entities type actions
    *
    * @return self
    */
    getEntitiesTypesActions() {
        const{
                enabledFilters,
            }               = this.state,
            actions         = {},
            entitiesFilters = this.getEntitiesFilters();

        entitiesFilters.forEach((entityDef) => {
            const icon = (
                <Icon
                    folder="/entities/"
                    type={entityDef.id}
                    className="icon-entities-type"
                    height={16}
                />
            );

            if (!entityDef) {
                return;
            }

            actions[entityDef.id] = {
                icon,
                cb      : () => this.toggleFilter(entityDef),
                label   : capitalize(entityDef.label),
                selected: enabledFilters.includes(entityDef.id),
            };
        });

        return actions;
    }


    /**
     * This function is called on visibility change of the filters menu
     */
    onToggleDisplayFilters(status) {
        const { menuIsVisible } = this.state,
            { menuVisible }     = status;

        if (menuIsVisible === menuVisible) {
            return;
        }

        this.setState({ menuIsVisible: menuVisible });
    }


    /**
     * Render the componant
     */
    render() {
        const { menuIsVisible } = this.state,
            actions             = this.getActions();

        return (
            <Action
                className="filters-entities"
                overlayClassName="filters-entities-menu"
                visible={menuIsVisible}
                actions={actions}
                onActionToggleDisplay={this.onToggleDisplayFilters}
                showMenuOnClick
                emitEvent={emitEvent}
            >
                <IButton
                    label="Filter"
                    fontSize={12}
                    icon="actions/filter"
                    count={this.getFilterCount()}
                    active={menuIsVisible}
                />
            </Action>
        );
    }

}

EntitiesFilters.propTypes = {
    learnKnowledge : PropTypes.func,
    onUpdateFilters: PropTypes.func,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    emitEvent,
})(EntitiesFilters);

