// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list > tbody {
  border-top: none;
}
.list td,
.list th {
  padding: 2px 5px;
}
.row.corporate_activity_event td {
  line-height: 11px;
  vertical-align: middle;
}
.row.corporate_activity_event td .entity-inline {
  line-height: 31px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/Row/assets/corporate_activity_event.less"],"names":[],"mappings":"AAGI;EACI,gBAAA;AAFR;AADA;;EAOQ,gBAAA;AAFR;AAMA;EAGQ,iBAAA;EACA,sBAAA;AANR;AAEA;EAOY,iBAAA;AANZ","sourcesContent":["\n.list {\n\n    & > tbody {\n        border-top: none;\n    }\n\n    td, th {\n        padding       : 2px 5px;\n    }\n}\n\n.row.corporate_activity_event {\n\n    td {\n        line-height   : 11px;\n        vertical-align: middle;\n\n        .entity-inline {\n            line-height: 31px;\n        }\n    }\n\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
