import React, {
    useEffect,
    useState
}                           from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { learn }            from 'store/actions/knowledge';
import { Icon, IButton }    from 'helpers';
import { Popover }          from 'antd';

import DateRange            from './DateRange';
import UniqueChoice         from './UniqueChoice';
import List                 from './List';
import Market               from './Market';
import GroupedList          from './GroupedList';
import TextInfo             from './TextInfo';
import IntRange             from './IntRange';

import { makeStrClassName } from 'utils/text';

// Associate filters by type
const filterTypes = {
    YearRange : DateRange,
    MonthRange: DateRange,
    List,
    UniqueChoice,
    Market,
    GroupedList,
    TextInfo,
    IntRange
};

/**
* Render FilterBlock
* React Functional Components (Hooks)
*
* @return JSX
*/
const FilterBlock = (props) => {  // eslint-disable-line max-lines-per-function
    const {
            filterKey, value, onChange,
            debounceTime, dynamicFilters, learnKnowledge,
            options: propOptions,
        }                 = props,
        options           = { ...FilterBlock.defaultProps.options, ...propOptions},
        debouncedOnChange = _.debounce((filter, values) => onChange(filter, values), debounceTime),

        [filterDefinition, setFiltersDefinition]        = useState([]),
        [isEmpty, setIsEmpty]                           = useState(false),
        [filterType, setFilterType]                     = useState(null),
        [dynamicItems, setDynamicItems]                 = useState(null),
        [dynamicFiltersLabels, setDynamicFiltersLabels] = useState({});

    // Learn filters knowledge
    useEffect(() => {
        learnKnowledge(['filters']).then((slice) => {
            const filters      = slice?.filters || {},
                filter         = filters.find((filter) => filter.id === filterKey) || {},
                filterCamelKey = _.upperFirst(_.camelCase(filter?.subtype));

            setFiltersDefinition(filter);
            setFilterType(filterCamelKey);
        });
    }, []);

    // Sets dynamic filters and visibility
    useEffect(() => {
        const filterValues = getFilterValues(),
            dynamicItems   = getDynamicItems(filterValues);

        setDynamicItems(dynamicItems);
        if (
            filterDefinition?.isDynamic
            && dynamicFilters
            && (dynamicItems === null || dynamicItems?.length === 0)
        ) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
    }, [filterDefinition, dynamicFilters, value]);

    // Keep selected labels
    useEffect(() => {
        const updatedLabels = { ...dynamicFiltersLabels };
        _.each(dynamicFilters, (filters, filterKey) => {
            _.each(filters, (filter) => {
                updatedLabels[filterKey] = {
                    ...updatedLabels[filterKey],
                    [filter.id]: filter.label || filter.id,
                };
            });
        });

        setDynamicFiltersLabels(updatedLabels);
    }, [dynamicFilters]);


    // Cancel debounce on dismount component
    useEffect(() => {
        return () => { debouncedOnChange.cancel(); };
    }, []);


    /**
     * Debounced handle change
     *
     * @param {*} debouncedValue
     */
    const handleChange = (filter, values) => {
        filterTypes[filterType]?.IS_DEBOUNCED
            ? debouncedOnChange(filter, values)
            : onChange(filter, values);
    };


    /**
     * Render header section (title & help)
     */
    const renderHeader = () => {
        const { helpInfo, customLabel } = props,
            { label }                   = filterDefinition,
            { title, content }          = helpInfo || {},
            hasHelpInfo                 = title || content;

        return(
            <div key={`title-${filterKey}`} className="filter-header">
                <span className="filter-title">
                    {customLabel || label}
                </span>
                {
                    hasHelpInfo && (
                        <Popover
                            title={title}
                            content={content}
                            placement="top"
                        >
                            <span className="info-icon">
                                <Icon id="info-solid" height={14}
                                    color="var(--secondary-color)"
                                />
                            </span>
                        </Popover>
                    )
                }
            </div>
        );
    };


    /**
     * Get default filter values
     *
     * @returns array
     */
    const getFilterValues = () => {
        const {customDateRange} = props;

        return  value
            || filterDefinition.default
            || (filterKey === 'timeframe' ? customDateRange : false); // Set custom Date Range for timeframe
    };


    /**
     * Get dynamic items
     *
     * @returns array
     */
    const getDynamicItems = (filterValues) => {
        // Set custom Date Range for timeframe
        const dynamicItems = dynamicFilters && _.isArray(dynamicFilters[filterKey])
            ? dynamicFilters[filterKey]
            : _.keys(dynamicFilters).length > 0 ? null : false;

        // Re-put lost dynamicItems (not returned in filters)
        _.each(filterValues, (valueId) => {
            if (dynamicItems && !_.find(dynamicItems, (item) => item.id === valueId)) {
                dynamicItems.push({ id: valueId, label: _.get(dynamicFiltersLabels, `${filterKey}.${valueId}`) });
            }
        });

        return dynamicItems;
    };

    /**
     * Render bottom section
     */
    const renderBottom = () => {
        const { showDelete } = options;

        return showDelete && (
            <div className="filter-bottom">
                { showDelete && renderDeleteButton()}
            </div>
        );
    };

    /**
     * Render Delete button
     *
     * @returns JSX
     */
    const renderDeleteButton = () => {
        return (
            <IButton label="Delete filter" onClick={() => handleChange(filterKey, null)}
                className="delete"
                icon="delete"
                fontSize={12}
            />
        );
    };

    /**
     *  Render the filter content
     *
     * @returns JSX
     */
    const renderFilterContent = () => {
        const { resource, isFiltersLoading, restrictedFilters, filterConfig } = props;
        const {
                label,
                options  : filterOptions,
                content  : filterContent
            }               = filterDefinition,
            Filter          = filterTypes[filterType],
            { multiValued } = filterOptions || {},
            filterValues    = getFilterValues();

        if (!Filter) {
            console.log(`No filter "${filterType}" component found (${filterKey}) `);
            return;
        }

        if(!isFiltersLoading && isEmpty) {
            return <div className="empty">No data to display</div>;
        }

        return (
            <Filter
                key={`content-${filterKey}`}
                filterKey={filterKey}
                onChange={handleChange}
                filterType={filterType}
                value={filterValues}
                items={dynamicItems}
                filterContent={filterContent}
                filterLabel={label}
                options={filterOptions}
                restrictedFilters={!multiValued && restrictedFilters[filterKey]}
                resource={resource}
                isFiltersLoading={isFiltersLoading}
                {...filterConfig}
            />
        );
    };

    /**
     *  Render header content and delete buttons
     *
     * @returns JSX
     */
    const render = () => {
        // Is custom filter (with children) or filter exists
        const { children, className }  = props,
            { showTitle, showOnEmpty } = options,
            classNames                 = ['filter', className],
            mustBeRendered             = (!isEmpty || showOnEmpty);

        return mustBeRendered && (
            <div key={`filter-${filterKey}`} className={makeStrClassName(classNames)}>
                {showTitle && renderHeader()}
                <div className="filter-content">
                    {children || (filterType && renderFilterContent())}
                </div>
                { renderBottom() }
            </div>
        );
    };

    // The render call
    return render();
};

FilterBlock.propTypes = {
    filterKey        : PropTypes.string.isRequired,
    value            : PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
    onChange         : PropTypes.func,
    helpInfo         : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    dynamicFilters   : PropTypes.object,
    restrictedFilters: PropTypes.object,
    filterConfig     : PropTypes.object,
    className        : PropTypes.string,
    customLabel      : PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
    isFiltersLoading : PropTypes.bool,
    resource         : PropTypes.shape({
        filters: PropTypes.any
    }),
    options: PropTypes.shape({
        showTitle  : PropTypes.bool,
        showOnEmpty: PropTypes.bool,
        showDelete : PropTypes.bool,
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

FilterBlock.defaultProps = {
    debounceTime     : 750,
    // TODO: rename filterConfig prop or refact?
    filterConfig     : {},
    restrictedFilters: {},
    customLabel      : false,
    options          : {
        showTitle  : true,
        showOnEmpty: false,
        showDelete : false,
    },
};

/**
* Bind the store to to component
*/
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(FilterBlock);
