import { Component } from 'react';
import _             from 'lodash';
import PropTypes     from 'prop-types';
import {
    interpolate             as d3Interpolate,
    easeLinear,
    easeElasticIn,
    easeElasticInOut,
    easeElasticOut,
    easeCubicIn,
    easeCubicInOut,
    easeCubicOut,
    easePolyIn,
    easePolyInOut,
    easePolyOut,
    easeSinIn,
    easeSinInOut,
    easeSinOut,
    easeCircleIn,
    easeCircleInOut,
    easeCircleOut,
    easeBackIn,
    easeBackInOut,
    easeBackOut,
    easeBounceIn,
    easeBounceInOut,
    easeBounceOut,
    easeQuadIn,
    easeQuadInOut,
    easeQuadOut,
    easeExpIn,
    easeExpInOut,
    easeExpOut,
}                           from 'd3';

import { capitalize }        from 'utils/text';

const eases = {
    easeLinear,
    easeElasticIn,
    easeElasticInOut,
    easeElasticOut,
    easeCubicIn,
    easeCubicInOut,
    easeCubicOut,
    easePolyIn,
    easePolyInOut,
    easePolyOut,
    easeSinIn,
    easeSinInOut,
    easeSinOut,
    easeCircleIn,
    easeCircleInOut,
    easeCircleOut,
    easeBackIn,
    easeBackInOut,
    easeBackOut,
    easeBounceIn,
    easeBounceInOut,
    easeBounceOut,
    easeQuadIn,
    easeQuadInOut,
    easeQuadOut,
    easeExpIn,
    easeExpInOut,
    easeExpOut,
};

const stepsToPlay =  ['before', 'main', 'after'];

/**
 * Animation Component for graph
 *
 */
class Animation extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            dataToRender : null,
            startTime    : null,
            stepTime     : null,
            totalProgress: null,
            sceneToPlay  : null,
            scenarioVars : {},
            scenesQueue  : [],
            stepIndex    : 0
        };

        _.bindAll(this, 'render', 'process', 'getTweenedData');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { scenario } = this.props,
            { scenes } = scenario;

        if (scenes) {
            this.start();
        }
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        // Cancel the animation if it exists to prevent memory leaks
        if (this.rafID) {
            cancelAnimationFrame(this.rafID);
        }
    }


    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
        const { data, didUpdateCb, scene } = this.props,
            { scenario }                   = this.props,
            { scenes }                     = scenario,
            { initialData, startTime  }    = this.state,
            { sceneToPlay, scenesQueue }   = this.state;

        if (!scenes) {
            didUpdateCb();
            return;
        }

        // Add scene to queue
        if (
            (sceneToPlay !== scene && sceneToPlay !== 'intro')                // New scene
            || JSON.stringify(data) !== JSON.stringify(initialData)         // New Props to process
            || (scene === 'loading' && scenesQueue.indexOf('loading') === -1) // Must be add
        ) {
            this.addSceneToQueue();
        }

        // Animation frame process
        if (startTime) {
            cancelAnimationFrame(this.rafID);
            this.rafID = requestAnimationFrame(this.process);
        }

        // Run animation
        if (_.isNull(startTime) && scenesQueue.length > 0) {
            this.start();
        }

        didUpdateCb();
    }

    /**
    * Update dataToRender
    *
    * @return void
    */
    getTweenedData(transitions = [], options) {
        const {
            item, dataIndex, offsetTime, elapsedTime, interpolatePool
        } = options;

        // Isolate a specific item to work with.
        let workingItem = _.clone(item);
        // Iterate over transitions to compute data for current item
        transitions.forEach((transition, transitionIndex) => {
            const {
                    attribute, duration, atEnd,
                    easing, easeWay
                }                                     = transition,
                easingPostfix                         = capitalize(easeWay || 'out'),
                easingName                            = capitalize(`${easing}${easingPostfix}` || 'linear'),
                ease                                  = eases[`ease${easingName}`] || eases.easeLinear,
                { startValue, endValue, interpolate } = interpolatePool[transitionIndex],
                progress                              = (elapsedTime - offsetTime) / duration;   // Compute attribute progress

            // Set the interpolated attribute value
            if (startValue !== endValue) {
                _.set(workingItem, attribute, progress < 1 ? interpolate(ease(progress, 0.5)) : endValue);
            }

            // At end of scene, get tweenedData of next scene
            if (atEnd && progress >= 1) {
                workingItem = _.merge(workingItem, this.getTweenedData(
                    atEnd,
                    {
                        item           : workingItem,
                        dataIndex,
                        offsetTime     : offsetTime + duration,
                        elapsedTime,
                        interpolatePool: interpolatePool[transitionIndex].atEnd
                    }
                ));
            }
        });

        return workingItem;
    }

    /**
     * Get tweededData
     *
     * @param {object} options
     */
    getAllTweenedData(options) {
        const { reverseItems }                       = this.props,
            { scenarioVars }                         = this.state,
            {
                animationIsEnded, elapsedStepTime,
                step, reverseStep,
            }                                        = options,
            { timelines }                            = scenarioVars,
            { regular, reverse }                     = timelines,
            { interpolatePools }                     = regular,
            reverseInterpolatePools                  = reverse.interpolatePools,
            { startData, endData, name, transitions} = step,
            tweenedData                              = animationIsEnded ? endData // Animation is ended
                : _.map(startData, (item, dataIndex) => {
                    // Make interpolatePool reverse or not
                    const isReverse     = reverseItems.includes(item.id),
                        interpolatePool = isReverse ? reverseInterpolatePools[name] : interpolatePools[name];

                    return this.getTweenedData(             // Compute new data to render
                        isReverse ? reverseStep.transitions : transitions,
                        {
                            item,
                            dataIndex,
                            elapsedTime    : elapsedStepTime,
                            offsetTime     : 0,
                            interpolatePool: interpolatePool[dataIndex]
                        }
                    );
                });

        return tweenedData;
    }

    /**
    * Processing dataToRender
    *
    * @return void
    */
    process() {
        const { atEndCb } = this.props,
            {
                scenarioVars,
                startTime,
                stepTime,
                stepIndex,
                scenesQueue,
            }                       = this.state,
            {
                timelines, globalEndTime
            }                       = scenarioVars,
            { regular, reverse }    = timelines,
            animationIsDisabled     = window.animationIsDisabled && window.animationIsDisabled(),
            step                    = regular.timeline[stepIndex],
            reverseStep             = reverse.timeline[stepIndex],
            dateNow                 = Date.now(),
            elapsedTime             = dateNow - startTime,
            totalProgress           = elapsedTime / globalEndTime,
            elapsedStepTime         = dateNow - stepTime,
            stepProgress            = elapsedStepTime / _.max([step.duration, reverseStep.duration]),
            animationIsEnded        = animationIsDisabled ? true : stepProgress >= 1,
            isLastStep              = regular.timeline.length === stepIndex + 1,
            sceneIsEnded            = animationIsEnded && isLastStep,
            // Proceed data
            tweenedData  = this.getAllTweenedData({ animationIsEnded, elapsedStepTime, step, reverseStep });

        // Remove the scene from queue
        if (sceneIsEnded && scenesQueue.length > 0) {
            scenesQueue.shift();
        }

        // Set New dataToRender
        this.setState({
            scenesQueue,
            // Reset startTime on animationIsEnded
            startTime: animationIsEnded
                // Restart startTime on next step
                ? (isLastStep ? null : dateNow)
                : startTime,
            stepTime     : animationIsEnded ? dateNow : stepTime,
            dataToRender : _.merge(_.cloneDeep(step.endData), tweenedData),
            totalProgress: sceneIsEnded ? null : totalProgress,
            // Pass to next step
            stepIndex    : animationIsEnded
                ? (isLastStep ? stepIndex : stepIndex + 1)
                : stepIndex
        });

        // Launch endCallback
        if (animationIsEnded && isLastStep) {
            atEndCb();
        }
    }

    /**
    * Get all steps interpolatePool
    *
    * @return array
    */
    getInterpolatePools(timeline) {
        return _.reduce(
            timeline,
            (interpolatePools, step) => {
                // Add step interpolation in interpolatePools
                interpolatePools[step.name] = _.map(
                    step.startData,
                    (item, dataIndex) => this.getInterpolatePool(step.transitions, dataIndex, step)
                );

                return interpolatePools;
            },
            {}
        );
    }

    /**
    * Find closest value in array
    *
    * @return array
    */
    closest(values, goal) {
        return values.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));
    }

    /**
    * Pre-Process interpolations
    *
    * @return array
    */
    getInterpolatePool(transitions = [], dataIndex, step) {
        return transitions.map(({ attribute, value, atEnd }) => {
            const { matchItemsById } = this.props,
                {
                    startData, endData, defaults
                }            = step,  // TODO place out of map
                item         = startData[dataIndex],
                startValue   = _.get(item, attribute),
                useId        = matchItemsById && !_.isUndefined(item.id),
                // Find data using item.id
                foundedItem  = useId ? endData.find((d) => d.id === item.id) : null,
                indexToTween = foundedItem ? endData.indexOf(foundedItem) : dataIndex,
                defaultDef   = _.get(defaults, attribute, 0), // DefaultValue
                defaultValue = _.isArray(defaultDef) ? this.closest(defaultDef, startValue) : defaultDef,
                endValue     = _.isUndefined(value)
                    ? (
                        useId && _.isUndefined(foundedItem)  // Using defaultValue for unfound target item
                            ? defaultValue
                            : _.get(endData[indexToTween], attribute, defaultValue)
                    )
                    : value;  // Forced Value

            return {
                endValue,
                startValue,
                interpolate: d3Interpolate(startValue, endValue),
                atEnd      : atEnd ? this.getInterpolatePool(atEnd, dataIndex, step) : null
            };
        });
    }

    /**
    * Get deep duration of attributes animation definition
    *
    * @param attributeAnimation object A attribute animation definition
    *
    * @return
    */
    getDeepDuration(transitions) {
        return transitions.length > 0 ? _.max(
            _.map(
                transitions,
                (attributeAnimation) => {
                    const { atEnd } = attributeAnimation;
                    return _.get(attributeAnimation, 'duration', 200) + (atEnd ? this.getDeepDuration(atEnd) : 0);
                }
            )
        ) : 0;
    }

    /**
    * Get data with forced values from transitions
    *
    */
    getDataWithForcedValue(inputData, transitions) {
        const forcedValues = this.getForcedValues(transitions),
            data = _.cloneDeep(inputData);

        // Overide values of data
        if (_.keys(forcedValues).length > 0) {
            data.forEach((datum) => _.forIn(
                forcedValues,
                (forcedValue, attribute) => _.set(datum, attribute, forcedValue.value)
            ));
        }

        return data;
    }

    /**
    * Get forced values from transitions
    *
    */
    getForcedValues(transitions, time = 0) {
        const childrenTransitions = transitions.filter((transition) => !_.isUndefined(transition.atEnd)),
            // Filter the forced values
            forcedTransitions     = transitions.filter((transition) => !_.isUndefined(transition.value)),
            // Build forcedValues object
            returnForcedValues    = forcedTransitions.reduce(
                (forcedValues, transition) => {
                    forcedValues[transition.attribute] = {
                        value: transition.value,
                        time : time + transition.duration
                    };
                    return forcedValues;
                }, {}
            ),
            // Get forced values from atEnd
            childrenForcedValues = _.map(
                childrenTransitions,
                transition => this.getForcedValues(transition.atEnd, transition.duration + time)
            );

        // Overide deep forced values
        childrenForcedValues.forEach((forcedValues) => {
            _.forIn(forcedValues, (forcedValue, attribute) => {
                const localTime = _.get(returnForcedValues, attribute, 0);
                // Overide value of a later transition
                if (localTime < forcedValue.time) {
                    returnForcedValues[attribute] = forcedValue;
                }
            });
        });

        return returnForcedValues;
    }

    /**
    * Get timeline
    *
    */
    getTimeline(globalEndData, sceneToPlay, isReverse = false) {
        const {
                scenario,
            }                   = this.props,
            {
                dataToRender,
            }                   = this.state,
            scene               = scenario.scenes[sceneToPlay],
            { steps, defaults } = scene,
            // Global start data with additional empty value
            globalStartData     = this.fillMissingData(dataToRender || [], globalEndData, defaults),
            reversePosfix       = isReverse ? 'Reverse' : '',
            beforeData          = this.getDataWithForcedValue(globalStartData, steps[`before${reversePosfix}`] || []),
            mainData            = this.getDataWithForcedValue(globalEndData, steps[`main${reversePosfix}`] || []),
            afterData           = this.getDataWithForcedValue(mainData, steps[`after${reversePosfix}`] || []),
            // Store start data by step
            stepStartData       = {
                before: globalStartData,
                main  : beforeData,
                after : mainData,
            },
            // Store end data by step
            stepEndData         = {
                before: beforeData,
                main  : mainData,
                after : afterData,
            };

        return stepsToPlay.reduce(
            (line, rootStepName) => {
                const startTime        = _.get(_.last(line), 'duration', 0),
                    stepName           = `${rootStepName}${reversePosfix}`,
                    transitions        = steps[stepName] || [],
                    duration           = this.getDeepDuration(transitions),
                    endTime            = startTime + duration,
                    startData          = stepStartData[rootStepName],
                    endData            = stepEndData[rootStepName];

                line.push({
                    name: rootStepName, transitions, duration, startTime, endTime, startData, endData, defaults
                });
                return line;
            },
            []
        );
    }

    /**
    * Fill startData missing data from endData
    */
    fillMissingData(startData, endData, defaults = []) {
        const { matchItemsById } = this.props;

        if (!endData) { return startData; }

        // Search in endData
        endData.forEach((item, index) => {
            const useId     = matchItemsById && !_.isUndefined(item.id),
                mustAddData =  useId
                    ? !startData.find((d) => d.id === item.id)
                    : index >= startData.length,
                itemToPush  = _.cloneDeep(endData[useId ? index : 0]);

            // Add default item
            if (mustAddData) {
                // Set each defaultValue attributes
                _.forEach(
                    defaults,
                    (defaultDef, attribute) => _.set(
                        itemToPush,
                        attribute,
                        _.isArray(defaultDef) ? this.closest(defaultDef, _.get(itemToPush, attribute, 0)) : defaultDef
                    )
                );
                startData.push(itemToPush);
            }
        });

        return startData;
    }

    /**
    * Add scene to queue
    *
    * @return void
    */
    addSceneToQueue() {
        const { scene }   = this.props,
            { scenesQueue } = this.state,
            sceneIndex    = scenesQueue.indexOf(scene);

        // Add new scene in queue
        if (sceneIndex === -1) {
            scenesQueue.push(scene);
            this.setState({ scenesQueue });
        }

        // Restart the active scene
        if (sceneIndex === 0) {
            this.start();
        }
    }

    /**
    * Start Animation
    *
    * @return void
    */
    start() {
        const {
                data, scenario, scene
            }               = this.props,
            {
                dataToRender, scenesQueue
            }               = this.state,
            endData         = _.cloneDeep(_.isArray(data) || _.isUndefined(data) ? data : [data]), // Protect data by cloning it
            hasIntro        = _.get(scenario, 'scenes.intro', false),
            sceneToPlay     = !dataToRender && hasIntro
                ? 'intro'
                : (scenesQueue.length > 0 ? scenesQueue[0] : scene),
            timeline        = this.getTimeline(endData, sceneToPlay),
            reverseTimeline = this.getTimeline(endData, sceneToPlay, true),
            scenarioVars    = {
                timelines: {
                    regular: {
                        timeline,
                        interpolatePools: this.getInterpolatePools(timeline)
                    },
                    reverse: {
                        timeline        : reverseTimeline,
                        interpolatePools: this.getInterpolatePools(reverseTimeline)
                    }
                },
                globalEndTime: _.max([_.last(timeline).endTime, _.last(reverseTimeline).endTime])
            };

        this.setState({
            sceneToPlay,
            startTime    : Date.now(),         // Set the initial timeStamp
            stepTime     : Date.now(),         // Set the initial timeStamp
            initialData  : data,               // Object used to prevent start loop
            totalProgress: 0,
            stepIndex    : 0,
            scenarioVars
        });
    }

    /**
    * Render Animation frame
    *
    * @return html
    */
    render() {
        const { render, params } = this.props,
            {
                dataToRender,
                totalProgress,
                initialData
            }                    = this.state,
            initialDataAreArray  = _.isArray(initialData);

        // No processed data, return.
        if (!dataToRender) {
            return false;
        }

        return render(
            initialDataAreArray
                ? dataToRender
                : (dataToRender.length > 0 ? dataToRender[0] : null),
            totalProgress,
            params
        );
    }

}

/**
 * Props type
 */
Animation.propTypes = {
    data          : PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]).isRequired,
    render        : PropTypes.func.isRequired,
    scenario      : PropTypes.shape(),
    scene         : PropTypes.string,
    reverseItems  : PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),  // Ids to reverse animation
    params        : PropTypes.shape(),
    matchItemsById: PropTypes.bool,     // Match items by id
    didUpdateCb   : PropTypes.func,
    atEndCb       : PropTypes.func,
};

/**
 * Default props value
 */
Animation.defaultProps = {
    scenario      : {},
    scene         : 'general',
    reverseItems  : [],
    params        : {},
    matchItemsById: true,
    didUpdateCb   : _.noop,
    atEndCb       : _.noop,
};

export default Animation;
