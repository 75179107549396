import _                              from 'lodash';
import types                          from '../types/userView';
import { getDeepPropertyValues }      from 'utils/object';
import { post, del, patch }           from 'utils/api';
import {
    removeItemFromModel,
    getUserViewItemFromModel,
    replaceItemFromModel,
}                                     from '../userView';
import { learn }                      from '../knowledge';
import { getBookmarkLabel }           from 'store/actions/userView/bookmarks';

/**
* Get root folder user view item
*
* @return {Promise}
*/
export const getRootFolder = () => (dispatch, getState) => {
    const state       = getState(),
        userViewItems = state.get('userView').get('bookmark_folder')?.get('list'),
        rootFolder    = userViewItems?.find(item => item.parent_attachments.length === 0 && item?.model?.label === 'root');

    return rootFolder;
};


/**
* Get all sub folder user view item
*
* @return {Promise}
*/
export const getDeepSubBookmarkFolders = (folderId) => (dispatch, getState) => {
    const folder      = getBookmarkFolder(folderId)(dispatch, getState),
        branch        = folder && getFolderBranch({
            paramKey     : folder.key,
            ancestors_ids: folder.parent_attachments.map(
                item => {
                    const splittedKey = item.key.split('/');
                    return splittedKey[2];
                }
            ),
        })(dispatch, getState);

    return folder && getFoldersOfBranches(branch);
};


/**
* Get a folder user view item
*
* @return {Promise}
*/
export const getBookmarkFolder = (folderId) => (dispatch, getState) => {
    const state       = getState(),
        userViewItems = state?.get('userView')?.get('bookmark_folder')?.get('list');

    return folderId && userViewItems?.find(item => item.entity_id === folderId);
};


/**
 * Takes all folder in branches and returns deep all sub folders()
 *
 * @param   {array} array of bookmark folders
 *
 * @returns {array}
 */
const getFoldersOfBranches = (branches) => {
    let folders = [];

    branches.forEach(elementTree => {
        const { children, model } = elementTree,
            { type }              = model;

        if (type === 'bookmark_folder') {
            folders.push(elementTree);
            folders = folders.concat(getFoldersOfBranches(children));
        }
    });
    return folders;
};


/**
 * Takes all bookmarks in branches
 *
 * @param   {array} array of bookmark
 *
 * @returns {array}
 */
export const getBookmarksOfBranches = (branches) => {
    let bookmarks = [];

    branches.forEach(elementTree => {
        const { children, model } = elementTree,
            { type }              = model;

        if (type === 'bookmark') {
            bookmarks.push(model);
        }

        if (type === 'bookmark_folder') {
            bookmarks = bookmarks.concat(getBookmarksOfBranches(children));
        }
    });
    return bookmarks;
};


/**
*  It's getting user view item folder by key
*
* @returns array
*/
const getParentFolderByKey = (key, bookmarkFolders) => {
    const itemsFolder  = bookmarkFolders.find(
            item => item.key == key
        ),
        { parent_attachments } = itemsFolder || [],
        parent_keys            = _.map(parent_attachments, 'key') || [],
        folder                 = _.cloneDeep(itemsFolder?.model);

    if (folder && parent_keys.length > 0) {
        folder.parentFolder = getParentFolderByKey(parent_keys[0], bookmarkFolders);
    }

    return folder;
};

/**
 * Sort items by type/state
 *
 * @returns integer
 */
const sortItemsByType = (item, rootFolder) => {
    const { model, parent_attachments } = item,
        { type, userViewState }         = model || {},
        isNewFolder                     = userViewState === 'added' && type === 'bookmark_folder',
        rootFolderId                    = rootFolder?.model?.id,
        parent_keys                     = parent_attachments.map(item => item.key),
        inRootFolder                    = parent_keys && parent_keys[0] === `member/bookmark_folder/${rootFolderId}`;

    // Only for new folders
    if (isNewFolder) {
        // If is located in root folder, it is displayed at bottom, otherwise at top
        return inRootFolder ? 10 : -10;
    }
    // Display folders first and then bookmarks
    return type === 'bookmark_folder' ? 0 : 1;
};


/**
* Get path of a bookmark
*
* @returns String
*/
const getFolderPathIds = (entity) => {
    const folders   = getDeepPropertyValues(entity, 'parentFolder', 'parentFolder'),
        foldersPath = folders.map(folder => folder.id),
        path        = foldersPath.join('/');

    return path;
};

/**
* It's a recursive function that returns the children of a folder.
*
* @returns array
*/
export const getFolderBranch = (options) => (dispatch, getState) => {
    const state         = getState(),
        bookmarkFolders = state.get('userView')?.get('bookmark_folder')?.get('list');

    if (!bookmarkFolders) { return null; }

    const { paramKey, ancestors_ids, onlyFolders, path } = options,
        key             = !paramKey ||paramKey.startsWith('member/bookmark_folder/')
            ? paramKey
            : `member/bookmark_folder/${paramKey}`,
        rootFolder      = getRootFolder()(dispatch, getState),
        bookmarks       = state.get('userView')?.get('bookmark')?.get('list'),
        bookmarksArray  = bookmarks ? bookmarks.toJS() : [],
        isRoot          = !key,
        items           = bookmarkFolders.toJS()
            .concat(onlyFolders ? [] : bookmarksArray)   // Adding bookmarks to folder
            .filter(
                item => {
                    const {
                            parent_attachments
                        }           = item,
                        parent_keys = parent_attachments && _.map(parent_attachments, 'key'),
                        inRoot      = parent_keys?.length === 0;

                    // Return root folders
                    if (isRoot && inRoot) { return true; }

                    // Return children folder of key
                    return parent_keys
                        && parent_keys.length > 0
                        && parent_keys.includes(key);
                }
            ),
        folder            = bookmarkFolders.find(st => st.key === key),
        ancestorsId       = folder ? [folder.model?.id] : [],
        itemSortedByLabel = _.sortBy(items, item => getBookmarkLabel(item.model)),
        // Call sortItems function
        itemSortedByType  = _.sortBy(itemSortedByLabel, (item) => sortItemsByType(item, rootFolder)),
        parentFolder      = getParentFolderByKey(key, bookmarkFolders),
        newAncestorsIds   = ancestors_ids ? ancestors_ids.concat(ancestorsId) : [],
        itemsToMute       = paramKey && !ancestors_ids && folder
            ? [folder]
            : itemSortedByType,
        itemsInBranch     = itemsToMute.map(
            item => getBookmarkMutedModel({item, key, parentFolder, newAncestorsIds, onlyFolders, path})(dispatch, getState)
        );

    // Root folder model is not ready
    if (!paramKey && isRoot && itemsToMute.length > 0 && !itemsToMute[0].model) {
        return null;
    }
    return itemsInBranch;
};


/**
 * Add attributes to model
 *
 * @returns
 */
const getBookmarkMutedModel = (options) => (dispatch, getState) => {
    const {
            item, key, parentFolder,
            newAncestorsIds, onlyFolders, path,
        }                                      = options,
        { key: id, model, parent_attachments } = item,
        { label }                              = model || {},
        newPath                                = path ? [...path, label] : [],
        mutedModel                             = _.cloneDeep(model) || ({ ...item, isLoading: true });

    mutedModel.ancestorsIds        = newAncestorsIds;
    mutedModel.parentFolder        = parentFolder;
    mutedModel.parent_attachments  = parent_attachments;

    if(parent_attachments && parent_attachments.length > 0) {
        mutedModel.date_create = parent_attachments && parent_attachments.find(
            attach => attach.key === `member/bookmark_folder/${parentFolder?.id}`
        )?.date_create;
    }

    mutedModel.key = key
        ? `${mutedModel.id}//${key}//${getFolderPathIds(mutedModel)}`
        : mutedModel.id;  // For root,

    return {
        model   : mutedModel,
        title   : label || 'loading',
        key     : `${item.key}-${newPath}`,
        value   : mutedModel?.id,
        fullPath: newPath.join(' / '),
        children: getFolderBranch({
            paramKey: id, ancestors_ids: newAncestorsIds, onlyFolders, path: newPath
        })(dispatch, getState),
    };
};


/**
* Add new temporary folder it into userView slice
*
* @return {Promise}
*/
export const createNewBookmarkFolder = (options) => (dispatch, getState) => {
    const state                = getState(),
        streamEntities         = state.get('userView').get('bookmark_folder').get('map'),
        {
            parent_bookmark_folder,
        }                      = options,
        parentStreamEntity     = streamEntities.find(streamEntity => streamEntity.entity_id === parent_bookmark_folder),
        newFolder              = {
            label      : '-',
            description: null,
            type       : 'bookmark_folder',
            parent_bookmark_folder
        };

    /* Dispatching an action to the reducer. */
    learn(['models'])(dispatch, getState).then(({ models: modelsDefinitions }) => {
        const payload = {
            items: [{
                model             : newFolder,
                parent_attachments: [{
                    key: parentStreamEntity.key
                }]
            }],
            modelsDefinitions
        };
        dispatch({ type: types.USER_VIEW_ADD_NEW_ITEMS, payload });
    });
};

/**
* Add bookmark to API & store it into userView slice
*
* @return {Promise}
*/
export const saveNewBookmarkFolder = (model, cb) => (dispatch, getState) => {
    const {
            label, description, parent_bookmark_folder
        }       = model,
        item    = getUserViewItemFromModel(model, 'bookmark_folder')(dispatch, getState),
        saveItem = {...item, model };

    dispatch({ type: types.USER_VIEW_UPDATE_ITEMS, payload: { items: [saveItem], userViewState: 'saved' } });

    post('/bookmark-folder', {  // eslint-disable-line no-unreachable
        label,
        description,
        parent_bookmark_folder
    }).then(({ body, status }) => {
        const { code } = status;

        // Remove item on api error
        if (code !== 201) {
            removeItemFromModel(model)(dispatch, getState);
            return;
        }

        // Re update to have true Id
        const newItem = {
            ...saveItem,
            model: {
                ...body,
                userViewState: 'saved',
            }
        };

        replaceItemFromModel(model, newItem)(dispatch, getState);

        if (cb) {
            cb(body);
        }

        // After the API finish the update of the userView,
        // The updated userView is sended by sockets
    });
};



/**
* Remove a bookmark from api & remove it into userView slice
*
* @return {Promise}
*/
export const removeBookmarkFolder = (item) => (dispatch, getState) => {
    const state = getState(),
        streamEntities   = state.get('userView').get('bookmark_folder').get('map'),
        bookmarksFolders = streamEntities.map(item => item.model).filter(model => !!model),
        folderToDelete = bookmarksFolders.find((folder) => folder.id === item.model.id);

    dispatch({ type: types.USER_VIEW_UPDATE_ITEMS, payload: { items: [item], userViewState: 'deleted' } });
    del(`/bookmark-folder/${folderToDelete.id}`);

    // After the API finish the update of the userView,
    // The updated userView is sended by sockets
};



/**
* Store a new model to the store
*
* @return {promise}
*/
export const updateBookmarkFolder = (item, content, cb) => () => {
    const { id } = item.model || item;  // Get id from bookmarks or stream entity

    id && patch(`/bookmark-folder/${id}`, content)
        .then(({ body }) => {
            if (cb) {
                cb(body);
            }
        });
};

/**
* Move a bookmark to a folder
*
* @return {promise}
*/
export const moveBookmarkFolder = (payload, content, cb) => (dispatch) => {
    const { model }         = payload,
        { id }              = model,  // Get id from bookmarks
        move                = _.pick(content, ['to']),
        { to }              = move,
        params              = { move },
        item                = _.cloneDeep(payload),
        newParentAttachment = {
            key        : `member/bookmark_folder/${to}`,
            date_create: new Date(Date.now() + 86400000).toISOString() // Tomorrow
        };

    if (!id || !to) {
        return;
    }

    // Move in redux
    item.parent_attachments = [newParentAttachment];
    item.model.bookmark_folders = [to];
    dispatch({ type: types.USER_VIEW_UPDATE_ITEMS, payload: { items: [item], userViewState: 'moved' } });

    // Move in API
    id && patch(`/bookmark-folders/${id}`, params)
        .then(({ body }) => {
            if (cb) {
                cb(body);
            }
        });
};



export default {
    createNewBookmarkFolder,
    removeBookmarkFolder,
    updateBookmarkFolder,
    moveBookmarkFolder,
    saveNewBookmarkFolder,
    getRootFolder,
    getDeepSubBookmarkFolders,
    getFolderBranch,
    getBookmarksOfBranches,
};
