/**
* Render the mixed suggestions menus
*
* @return Component
*/

import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Popover }          from 'antd';
import { Icon, CssLoader }  from 'helpers';
import { dataGet }          from 'utils/api';
import { makeStrClassName } from 'utils/text';

import './assets/add-suggestion.less';

/**
* Render the Smart Input "AddSuggestion" process
*
*/
class AddSuggestion extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'toggleActiveState', 'onSelect', 'moreSuggestions');

        this.state = {
            active           : false,
            suggestions      : null,
            suggestionsLoaded: false,
        };

        this.fetchSuggestions();
    }

    /**
     * Cancelling the promise that is fetching the suggestions.
     */
    componentWillUnmount() {
        this.fetchingSuggestionPromise?.cancel();
    }

    /**
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { data, suggestionsStep } = nextProps;

        prevState.suggestions     = data && data.suggestions
            ? _.difference(data.suggestions, data.customs).slice(0, _.get(data, 'showedSuggestions', suggestionsStep))
            : [];

        return prevState;
    }

    /**
    * Source is a concept ?
    *
    * return bool
    */
    isAConcept() {
        const { data } = this.props;

        return data.type === 'concept';
    }

    /**
    * Fetch suggestions
    *
    * @param {object} options Some parameters to configure the XHR
    *
    */
    fetchSuggestions() {
        const  { data } = this.props,
            { source }  = data,
            uri         = '/concepts',
            options     = {
                data: {
                    as   : `"${source}"`,
                    limit: {
                        max: 10
                    }
                }
            };

        // Not a concept
        if (!this.isAConcept()) {
            return;
        }

        // Fetch suggestions
        this.fetchingSuggestionPromise = dataGet(uri, options)
            .then((fetchedData) => {
                const { body, status } = fetchedData;

                if (status.code !== 200 && status.code !== 204) {
                    return;
                }

                this.storeSuggestions(body);
            });
    }

    /**
    * On select a new suggestion tag
    *
    * @return void
    */
    storeSuggestions(suggestions) {
        const { data, update, suggestionsStep } = this.props;

        // Show the first step
        data.showedSuggestions = suggestionsStep * 2; // No More

        // Store suggestions
        data.suggestions       = suggestions || [];

        this.setState({ suggestionsLoaded: true });

        // Update data object
        update(data);
    }

    /**
    * On select a new suggestion tag
    *
    * @return void
    */
    onSelect(entity) {
        return () => {
            const { data, update } = this.props;

            data.customs.push(entity);

            // Update data object
            update(data);
        };
    }

    /**
    * More suggestions
    *
    * @return void
    */
    moreSuggestions() {
        const { suggestionsStep, update, data } = this.props;

        // Update showedSuggestions
        data.showedSuggestions += suggestionsStep;

        // Update data object
        update(data);
    }

    /**
    * Enable the autocomplete
    *
    * @return void
    */
    toggleActiveState() {
        const { toggle, data, active } = this.props;

        toggle(!active ? data : null);
    }

    /**
    * Render the suggestion
    *
    * @return JSX
    */
    renderSuggestion(suggestion) {
        return (
            <div
                key={suggestion}
                className="suggestion"
                onClick={this.onSelect(suggestion)}
            >
                <Icon className="add" type="plus"
                    height={18} color="var(--secondary-color)"
                />
                <span>{suggestion}</span>
            </div>
        );
    }

    /**
    * Render the suggestions
    *
    * @return JSX
    */
    renderSuggestions() {
        const { data, suggestionsStep } = this.props,
            { suggestions }             = this.state,
            suggestions1                = suggestions ? suggestions.slice(0, suggestionsStep) : [],
            suggestions2                = suggestions ? suggestions.slice(suggestionsStep, suggestionsStep * 2) : [],
            showMore                    = data && data.suggestions
                && data.showedSuggestions && data.showedSuggestions < data.suggestions.length;

        return (
            <div className="suggestions">
                <div className="suggestions-block">
                    {suggestions1.map((suggestion) => this.renderSuggestion(suggestion))}
                </div>
                <div className="suggestions-block">
                    {suggestions2.map((suggestion) => this.renderSuggestion(suggestion))}
                </div>
                {showMore
                    ? (
                        <div className="more">
                            <span className="button" onClick={this.moreSuggestions}>
                                More
                            </span>
                        </div>
                    )
                    : null}
            </div>
        );
    }

    /**
    * Render the tag input provided by the user
    *
    * @return JSX
    */
    renderButton() {
        const { suggestions }     = this.state,
            { suggestionsLoaded } = this.state;

        return (
            <>
                <span>
                    Add suggestions
                </span>
                {suggestionsLoaded ? (
                    <div className="count">
                        <div>
                            {suggestions.length}
                        </div>
                    </div>
                ) : (
                    <CssLoader color="var(--secondary-color)" size={14}
                        thickness={2}
                    />
                )}
            </>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { active }          = this.props,
            { suggestions }       = this.state,
            { suggestionsLoaded } = this.state,
            classNames            = [
                'add-suggestion',
                active,
                { empty: (!suggestions || suggestions.length === 0) && suggestionsLoaded },
            ];

        return (
            <>
                <div className={makeStrClassName(classNames)}>
                    <Popover
                        trigger="click"
                        overlayClassName="pop-suggestions"
                        content={this.renderSuggestions()}
                        open={active && suggestions && suggestions.length > 0}
                        placement="bottomLeft"
                        onOpenChange={this.toggleActiveState}
                    >
                        {this.renderButton()}
                    </Popover>
                </div>
                <span className="separator" />
            </>
        );
    }

}

AddSuggestion.propTypes = {
    active         : PropTypes.bool,
    suggestionsStep: PropTypes.number,
    toggle         : PropTypes.func.isRequired,
    update         : PropTypes.func.isRequired,
    data           : PropTypes.shape({
        customs          : PropTypes.array,
        showedSuggestions: PropTypes.any,
        source           : PropTypes.any,
        suggestions      : PropTypes.array,
        type             : PropTypes.string
    }).isRequired,
};

AddSuggestion.defaultProps = {
    suggestionsStep: 10,
};

export default AddSuggestion;
