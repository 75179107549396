// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsletter-view {
  position: relative;
  background: #F7F7F7;
  text-align: center;
}
.newsletter-view .setting-button {
  position: absolute;
  top: 9px;
  width: 100%;
  text-align: right;
  padding-right: 25px;
}
.newsletter-view .content-selector {
  position: absolute;
  top: 5px;
  right: 42px;
  width: 170px;
}
.newsletter-view .selector-separator {
  position: absolute;
  top: 12px;
  right: 30px;
  border-right: solid 1px #dddddd;
  width: 10px;
  height: 20px;
}
.newsletter-view .newsletter-setting {
  text-align: left;
}
.newsletter-view .loader-ripple {
  top: 200px;
}
.newsletter-view .inline-html .loader-ripple {
  top: 15px;
}
.content-options .ant-select-dropdown-menu-item {
  text-align: left;
}
.shortcut-view.newsletters .create-newsletter .searches .add-search {
  top: 234px;
  right: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/core/Ui/Header/Shortcut/Views/Newsletters/Newsletter/assets/view.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EA2BA,kBAAA;AAzBJ;AAJA;EAMQ,kBAAA;EACA,QAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;AACR;AAXA;EAcQ,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AAAR;AAjBA;EAqBQ,kBAAA;EACA,SAAA;EACA,WAAA;EACA,+BAAA;EACA,WAAA;EACA,YAAA;AADR;AAzBA;EAgCQ,gBAAA;AAJR;AA5BA;EAoCQ,UAAA;AALR;AA/BA;EAwCQ,SAAA;AANR;AAYA;EAEQ,gBAAA;AAXR;AAeA;EACI,UAAA;EACA,WAAA;AAbJ","sourcesContent":[".newsletter-view {\n    position: relative;\n    background: #F7F7F7;\n\n\n    .setting-button {\n        position     : absolute;\n        top          : 9px;\n        width        : 100%;\n        text-align   : right;\n        padding-right: 25px;\n    }\n\n    .content-selector {\n        position     : absolute;\n        top          : 5px;\n        right        : 42px;\n        width        : 170px;\n    }\n\n    .selector-separator {\n        position    : absolute;\n        top         : 12px;\n        right       : 30px;\n        border-right: solid 1px #dddddd;\n        width       : 10px;\n        height      : 20px;\n    }\n\n    text-align: center;\n\n    .newsletter-setting{\n        text-align: left;\n    }\n\n    .loader-ripple{\n        top: 200px;\n    }\n\n    .inline-html .loader-ripple{\n        top: 15px;\n    }\n\n\n}\n\n.content-options {\n    .ant-select-dropdown-menu-item{\n        text-align: left;\n    }\n}\n\n.shortcut-view.newsletters .create-newsletter .searches .add-search {\n    top: 234px;\n    right: 25px;\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
