import React        from 'react';
import PropTypes    from 'prop-types';

// CSS
import './assets/css-loader.less';

/**
 * Insight css loader
 * loading.io/css/
 *
 * @param Component content A single element
 */
const CssLoader = (props) => {  // eslint-disable-line max-lines-per-function
    const {
            type, size, thickness,
            color, title
        } = props,
        classNames = ['css-loader'];

    if (type === 'ring') {
        const style = {
                width : size,
                height: size,
            },
            clip        = `rect(${size / 2}px, ${size}px, ${size}px, 0)`,
            beforeStyle = {
                ...style,
                border   : '${thickness}px solid transparent',
                borderTop: `${thickness} solid ${color}`,
                clip,
            },
            mainStyle = {
                ...style,
                clip,
            },
            afterStyle = {
                ...style,
                clip,
                border: `${thickness}px solid ${color}`,
            };

        classNames.push('loader-ring center');

        return (
            <div
                className={classNames.join(' ')}
                style={style}
                title={title}
            >
                <span className="main" style={mainStyle}>
                    <span className="before" style={beforeStyle} />
                    <span className="after" style={afterStyle} />
                </span>
            </div>
        );
    }

    if (type === 'ripple') {
        const subDivStyle = {
                border: `${thickness}px solid ${color}`,
            },
            style = { width: size, height: size };

        classNames.push('loader-ripple');

        return (
            <div
                className={classNames.join('  ')}
                style={style}
                title={title}
            >
                <div style={subDivStyle} />
                <div style={subDivStyle} />
            </div>
        );
    }

    return null;
};

// Validate properties for current Component
CssLoader.propTypes = {
    type     : PropTypes.string,
    size     : PropTypes.number,
    thickness: PropTypes.number,
    color    : PropTypes.string,
    title    : PropTypes.string,
};

// Declare default properties
CssLoader.defaultProps = {
    type     : 'ring',
    size     : 24,
    thickness: 2,
    color    : '#ffffff',
    title    : null,
};

export default CssLoader;
