import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    scaleLinear as d3ScaleLinear,
}                           from 'd3';

import Animation            from '../Animation';
import { Icon }             from 'helpers';

import './RaceLine/main.less';
import watermarkPng              from './RaceLine/watermark.png';

const animationDuration = 200;

const scenario = {
    scenes: {
        intro: {
            steps: {
                main: [
                    { attribute: 'itemSize', duration: animationDuration },
                    { attribute: 'x', duration: animationDuration },
                    { attribute: 'opacity', duration: animationDuration, value: 1 },
                ],
                mainReverse: [
                    { attribute: 'itemSize', duration: animationDuration },
                    { attribute: 'x', duration: animationDuration },
                    { attribute: 'opacity', duration: animationDuration, value: 1 },
                ],
            },
            defaults: {
                opacity : 0,
                x       : 0,
                itemSize: 0,
            },
        },
        loading: {
            steps: {
                main: [
                    { attribute: 'opacity', duration: animationDuration, value: 0.3 },
                ]
            }
        },
        contentChange: {
            steps: {
                main: [
                    { attribute: 'opacity', duration: 0, value: 1 },
                    { attribute: 'x', duration: animationDuration },
                    { attribute: 'itemSize', duration: animationDuration },
                ],
                mainReverse: [
                    { attribute: 'opacity', duration: 0, value: 1 },
                    { attribute: 'x', duration: animationDuration },
                    { attribute: 'itemSize', duration: animationDuration },
                ],
            },
            defaults: {
                x       : 0,
                itemSize: 0,
            },
        },
        general: {
            steps: {
                main: [
                    {
                        attribute: 'label.offset',
                        duration : animationDuration / 2,
                        atEnd    : [{ attribute: 'label.opacity', duration: animationDuration }]
                    },
                    { attribute: 'itemSize', duration: animationDuration },
                    { attribute: 'label.weight', duration: 0 },
                ],
                mainReverse: [
                    { attribute: 'label.opacity', duration: animationDuration },
                    { attribute: 'label.offset', duration: animationDuration / 2 },
                    { attribute: 'itemSize', duration: animationDuration },
                    { attribute: 'label.weight', duration: 0 },
                ],
                defaults: {
                    'label.offset' : 0,
                    itemSize       : 0,
                    'label.opacity': 0,
                },
            }
        }
    }
};

/**
 * The shape graph Component
 *
 */
class RaceLine extends Component {

    /**
    * Update itemSize
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateItemSize(options) {
        const { nextProps, state } = options,
            { itemRatio }          = nextProps,
            { innerHeight }        = state;

        state.itemSize = itemRatio * innerHeight;

        return { nextProps, state };
    }

    /**
    * Update scale in state
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateScale(options) {
        const { nextProps, state }          = options,
            { featuredScale }               = nextProps,
            { innerWidth, serie, itemSize } = state,
            freizeMargin                    = 1 * itemSize,
            serieMin                        = _.minBy(serie, (d) => d.value),
            serieMax                        = _.maxBy(serie, (d) => d.value);

        state.scale = d3ScaleLinear()
            .range([
                freizeMargin,
                innerWidth - freizeMargin - itemSize / featuredScale
            ])
            .domain([
                serieMin ? serieMin.value : 0,
                serieMax ? serieMax.value : 0,
            ]);

        return { nextProps, state };
    }

    /**
    * Update plot data
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updatePlotData(options) {
        const { nextProps, state }         = options,
            { featuredIds, featuredScale } = nextProps,
            { scale, serie, itemSize }     = state,
            { selectedItem }               = state,
            previousItem                   = {
                x         : 0,
                isFeatured: false
            };

        state.plotData             = _.map(serie, (item, i) => {
            const x                = scale(item.value || 0),
                isLast             = i === serie.length - 1,
                entity             = item.authority,
                orgunitId          = _.get(entity, 'id'),
                myCompanyIds       = _.get(featuredIds, 'my-company.ids', []),
                isFeatured         = myCompanyIds.includes(orgunitId),
                isSelectedItem     = orgunitId === selectedItem,
                // Categories of the item
                featuredColors = _.reduce(featuredIds, (colors, featured) => {
                    if (_.get(featured, 'ids', []).includes(orgunitId)) {
                        colors.push(featured.color);
                    }
                    return colors;
                }, []),
                itemColor          = _.first(featuredColors),
                // Minimum space inner item
                minSpace           = (itemSize * 1 / 3)
                    * (previousItem.isFeatured ? featuredScale : 1)
                    + (i !== 0 && !previousItem.isFeatured && (isFeatured || isLast) ? itemSize / 2 : 0);

            // X must be separted by demi itemSize at least
            previousItem.x = (previousItem.x !== 0 && x < previousItem.x + minSpace)
                ? previousItem.x + minSpace
                : x;

            previousItem.isFeatured = isFeatured;
            previousItem.isSelectedItem = isSelectedItem;

            return {
                ...previousItem,
                num     : i,
                id      : orgunitId,
                label   : { text: item.label, weight: 400 },
                itemSize: itemSize * (isSelectedItem ? 1.05 : 1) * (isFeatured || isLast ? featuredScale : 1),
                color   : itemColor,
                opacity : 1,
                isLast,
                entity,
            };
        });

        return { nextProps, state };
    }

    /**
    * Shift Item By End (Good place for the winner)
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static shiftItemByEnd(options) {
        const { nextProps, state }        = options,
            { featuredScale }             = nextProps,
            { plotData, itemSize, scale } = state,
            { serie }                     = state,
            serieMax                      = _.maxBy(serie, (d) => d.value),
            maxX                          = serieMax ? scale(serieMax.value) : 0;

        let continueShift = true,
            previousItem = {
                x         : maxX,
                isFeatured: false,
                isLast    : true,
            };

        state.plotData = _.map(plotData.reverse(), (item, i) => {
            const minSpace = (itemSize * 1 / 3)
                    * (previousItem.isFeatured ? featuredScale : 1)
                    + (i === 0 && !previousItem.isFeatured && (item.isFeatured) ? itemSize / 2 : 0)
                    + (previousItem.isLast ? itemSize / 2 : 0),
                mustShift = item.x > previousItem.x - minSpace;

            // Break the shift
            if (continueShift && i !== 0 && !mustShift) { continueShift = false; }

            previousItem = {
                ...item,
                x: (i !== 0 && mustShift)
                    ? (continueShift ? previousItem.x - minSpace : item.x)
                    : i === 0 ? maxX : item.x
            };

            return previousItem;
        }).reverse();

        return { nextProps, state };
    }

    /**
    * Update label offset in plotData
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateLabelOffset(options) {
        const { nextProps, state }                    = options,
            { fontSize, labelPosition, linePosition } = nextProps,
            { heightCut }                             = nextProps,
            { plotData, innerHeight, itemSize }       = state,
            { selectedItem }                          = state,
            nbFeatured                                = _.filter(plotData, (d) => d.isFeatured).length,
            topLabelOffset                            = (heightCut - labelPosition - linePosition) * innerHeight / heightCut,
            verticalCut                               = (1 / heightCut) * innerHeight,
            verticalSpacing                           = (
                verticalCut * (labelPosition - linePosition)
                - itemSize / 3
            ) / nbFeatured
            - itemSize / 28; // Winner icon;

        let lastOffsetIndex = 0;

        state.plotData = _.map(state.plotData, (plotDatum) => {
            const { isSelectedItem } = plotDatum;

            // Last item
            if (plotDatum.isLast) {
                _.set(plotDatum, 'label.offset', topLabelOffset);
                _.set(plotDatum, 'label.opacity',  selectedItem && !isSelectedItem ? 0 : 1);
                _.set(plotDatum, 'label.weight', 800);
                return plotDatum;
            }

            // Other items
            if (!plotDatum.isFeatured) {
                _.set(plotDatum, 'label.offset', isSelectedItem ? topLabelOffset + (itemSize / 2) + fontSize * 1.5 : 0);
                _.set(plotDatum, 'label.opacity', isSelectedItem ? 1 : 0);
                _.set(plotDatum, 'label.weight', 800);
                return plotDatum;
            }

            // Featured items
            _.set(plotDatum, 'label.offset', -lastOffsetIndex * verticalSpacing);
            _.set(plotDatum, 'label.opacity', 1);
            _.set(plotDatum, 'label.weight', isSelectedItem ? 800 : 400);

            lastOffsetIndex += 1;

            return plotDatum;
        });

        return { nextProps, state };
    }

    /**
    * Update label width in plotData
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    static updateLabelWidth(options) {
        const { nextProps, state } = options,
            { innerWidth }         = state;

        state.plotData = _.map(state.plotData, (plotDatum) => {
            // Last Item
            if (plotDatum.isLast) {
                _.set(plotDatum, 'label.width', plotDatum.x);
                return plotDatum;
            }

            // Not featured
            if (!plotDatum.isFeatured) {
                _.set(plotDatum, 'label.width', innerWidth);
                return plotDatum;
            }

            // Only featured
            _.set(plotDatum, 'label.width', innerWidth - plotDatum.x);


            return plotDatum;
        });

        return { nextProps, state };
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null
        };

        _.bindAll(
            this, 'render', 'renderGraph', 'entityClick',
            'onMouseOverItem', 'onMouseOutItem'
        );

        // Make a uuid
        this.id = _.uniqueId('race-line');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /**
    * Triggered when the component can be modified (run side effect)
    *
    * @return void
    */
    componentDidUpdate() {
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - size of the chart only
    *          - scale (D3Scales object) of the landmark
    *
    * @params nextProps  object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { margin }    = nextProps,
            { content }     = nextProps,
            { lastContent } = prevState,
            newContent      = content || lastContent,
            // Cascading function to update state
            updateState        = _.flow([
                RaceLine.updateItemSize,             // Update itemSize
                RaceLine.updateScale,                // D3 scale
                RaceLine.updatePlotData,             // Plot data
                RaceLine.shiftItemByEnd,             // Shift item to Have the winner at the good place
                RaceLine.updateLabelOffset,          // Update label offset in plotData
                RaceLine.updateLabelWidth,           // Update label width in plotData
            ]),
            { state: newState }       = updateState({
                nextProps,
                state: {
                    ...prevState,
                    // Size of the chart (without landmark)
                    innerHeight  : nextProps.height - 2 * margin,
                    innerWidth   : nextProps.width  - 2 * margin,
                    serie        : _.orderBy(newContent || [], ['value'], ['asc']),
                    lastContent  : newContent,
                    contentChange: lastContent !== content,
                }
            });

        return newState;
    }

    /**
    * On mouse over item
    *
    * @return false
    */
    onMouseOverItem(e) {
        const { interactive } = this.props,
            itemNode          = e.currentTarget.closest('g.item'),
            selectedItem      = _.get(itemNode, 'attributes.dataid.value', null);

        if (!interactive) { return false; }

        clearTimeout(this.mouseOutTimeout);
        clearTimeout(this.mouseOverTimeout);

        this.mouseOverTimeout = setTimeout(
            () => this.setState({ selectedItem }),
            50
        );

        return false;
    }

    /**
    * On mouse out item
    *
    * @return false
    */
    onMouseOutItem() {
        const { interactive } = this.props;

        if (!interactive) { return false; }

        clearTimeout(this.mouseOutTimeout);
        clearTimeout(this.mouseOverTimeout);

        this.mouseOutTimeout = setTimeout(
            () => this.setState({ selectedItem: null }),
            50
        );

        return false;
    }

    /**
    * On mouse click
    *
    * @return boolean
    */
    entityClick(entity, data) {
        const { openModal, interactive } = this.props;

        if (interactive && entity.clickable && openModal) {
            openModal(entity, data.map((item) => item.entity));
        }

        return true;
    }

    /**
    * Render the horizon line
    *
    * @return html
    */
    renderHorizonLine() {
        const { scale, innerHeight  }          = this.state,
            { linePosition, heightCut, color } = this.props,
            range                              = scale.range(),
            y                                  = linePosition * (1 / heightCut) * innerHeight;

        return (
            <line
                key="horizon-line"
                x1={0}
                y1={y}
                x2={range[1]}
                y2={y}
                stroke={color}
                strokeWidth={2}
                strokeOpacity={0.75}
                strokeDasharray="2 5"
            />
        );
    }

    /**
    * Render items
    *
    * @return html
    */
    renderItems(data) {
        const { linePosition, heightCut, interactive } = this.props,
            { openModal }                              = this.props,
            { innerHeight }                            = this.state,
            y                                          = linePosition * (1 / heightCut) * innerHeight;

        return _.map(data, (item) => (
            <g
                className={`item${_.get(item.entity, 'clickable') && openModal ? ' clickable' : ''}`}
                key={`${item.id}-${item.isLast}-${item.isFeatured}`}
                transform={`translate(${item.x}, ${y})`}
                dataid={item.id}
                onMouseUp={() => this.entityClick(item.entity, data)}
                style={{ opacity: item.opacity }}
            >
                <Icon
                    SVG
                    discShaped
                    id="orgunit"
                    folder="/entities/"
                    title={!interactive ? item.label.text : ''}
                    orgunitLogo={item.id}
                    width={item.itemSize}
                    height={item.itemSize}
                    borderColor={item.color}
                    borderSize={item.isFeatured ? 4 : 2}
                    onMouseOver={this.onMouseOverItem}
                    onFocus={this.onMouseOverItem}
                    onMouseOut={this.onMouseOutItem}
                    onBlur={this.onMouseOutItem}
                />
                {item.isLast ? this.renderWinnerIcon(item) : null}
                {item.isFeatured ? this.renderFeatureIcon(item) : null}
            </g>
        ));
    }

    /**
    * Render featured icon
    *
    * @return html
    */
    renderFeatureIcon(item) {
        const { itemSize } = item,
            iconWidth      = itemSize / 4,
            y              = item.isLast
                ? itemSize  / 2 * 1.5
                : -iconWidth  * 2 - iconWidth / 1.5;

        return (
            <Icon
                SVG
                id="medal"
                width={iconWidth}
                height={iconWidth}
                translate={[0, y]}
                color={item.color}
            />
        );
    }

    /**
    * Render winner icon
    *
    * @return html
    */
    renderWinnerIcon(item) {
        const { itemSize } = item,
            iconWidth      = itemSize;

        return (
            <Icon
                SVG
                folder="/graph/race-line/"
                id="winner"
                width={iconWidth}
                height={iconWidth}
                translate={[0, iconWidth / 2]}
                color={item.color}
            />
        );
    }

    /**
    * Render labels lines
    *
    * @return html
    */
    renderLabelsLines(data) {
        const { linePosition, labelPosition } = this.props,
            { heightCut, fontSize }           = this.props,
            { innerHeight }                   = this.state,
            featuredFontSize                  = fontSize * 1.2,
            y1Base                            = linePosition * (1 / heightCut) * innerHeight,
            y2Base                            = labelPosition * (1 / heightCut) * innerHeight
                - featuredFontSize;

        return _.map(data, (d) => {
            const visible = d.isFeatured || d.isLast || d.isSelectedItem || true,
                y1 = y1Base + (d.itemSize / 2) * (
                    d.isLast ? -1 : (d.isFeatured ? 1 : -1)
                ),
                y2 = d.isLast
                    ? y1Base + d.label.offset + 1.5 * fontSize
                    : (!d.isFeatured && !d.isLast
                        ? y1 + d.label.offset
                        : y2Base + d.label.offset
                    );

            if (!visible) { return null; }

            return (
                <line
                    key={d.label.text}
                    x1={d.x}
                    y1={y1}
                    x2={d.x}
                    y2={y2}
                    stroke={d.color}
                    strokeWidth={2}
                    opacity={d.opacity * (d.isLast ? d.label.opacity : 1)}
                />
            );
        });
    }

    /**
    * Render labels
    *
    * @return html
    */
    renderLabels(data) {
        const { linePosition, labelPosition } = this.props,
            { heightCut, fontSize, margin }   = this.props,
            { innerHeight, itemSize }         = this.state,
            featuredFontSize                  = fontSize * 1.2,
            y1Base                            = linePosition * (1 / heightCut) * innerHeight,
            y2Base                            = labelPosition * (1 /  heightCut) * innerHeight
                - featuredFontSize,
            lastY                             = (heightCut - labelPosition)
                * (1 / heightCut) * innerHeight;

        return _.map(data, (d) => {
            const labelFontSize = d.isLast ? featuredFontSize : fontSize,
                left = margin + (d.isLast ? d.x - d.label.width + labelFontSize : d.x - labelFontSize),
                top  = margin + (d.isLast
                    ? lastY
                    : (!d.isFeatured && !d.isLast
                        ? y1Base + d.label.offset - itemSize / 2 - fontSize * 1.5
                        : y2Base + d.label.offset
                    ));
            //  If ?? d.isFeatured || d.isLast ?
            return (
                <div
                    className="label-container"
                    key={d.label.text}
                    style={{
                        left, top, width: d.label.width, height: labelFontSize * 2
                    }}
                >
                    <div
                        xmlns="http://www.w3.org/1999/xhtml"
                        className={`label${d.isLast ? ' winner' : ''}`}
                        title={d.label.text}
                        style={{
                            borderColor: d.color,
                            opacity    : d.label.opacity * d.opacity,
                            lineHeight : `${labelFontSize * 1.5}px`,
                            fontSize   : `${labelFontSize}px`,
                            fontWeight : d.label.weight,
                            color      : d.color,
                        }}
                    >
                        {d.label.text}
                    </div>
                </div>
            );
        });
    }

    /**
    * Render the main svg
    *
    * @return html
    */
    renderSvgElements(plotData) {
        const { margin } = this.props;

        return (
            <g transform={`translate( ${margin} ${margin} )`}>
                {this.renderHorizonLine()}
                {this.renderLabelsLines(plotData)}
                {this.renderItems(plotData)}
            </g>
        );
    }

    /**
    * Render the graph
    *
    * @return html
    */
    renderGraph(plotData) {
        const {
                width,
                height,
                margin,
                showWatermark,
            } = this.props,
            { innerWidth, innerHeight } = this.state;

        return (
            <div className="RaceLine">
                {showWatermark ? (
                    <div
                        className="background"
                        style={{ width: innerWidth, height: innerHeight, margin }}
                    >
                        <img
                            className="watermark"
                            src={watermarkPng}
                            height={innerHeight}
                            alt=""
                        />
                    </div>
                ) : null}
                <svg
                    width={width}
                    height={height}
                >
                    {this.renderSvgElements(plotData)}
                </svg>
                {this.renderLabels(plotData)}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                skeleton,
                skeletonGradient,
                noContent
            }                           = this.props,
            {
                plotData,
                selectedItem,
                contentChange,
            }  = this.state,
            style                       = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) { return (<div className="RaceLine skeleton" style={style} />); }

        return (
            <Animation
                data={plotData}
                render={this.renderGraph}
                scenario={scenario}
                scene={noContent ? 'loading' : (contentChange ? 'contentChange' : 'general')}
                revertedItems={_.map(_.filter(plotData, (d) => d.id !== selectedItem), (d) => d.id)}
            />
        );
    }

}

/**
 * Props type
 */
/* eslint-disable react/no-unused-prop-types */
RaceLine.propTypes = {
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    color           : PropTypes.string,                                                        // eslint-disable-line
    width           : PropTypes.number.isRequired,
    height          : PropTypes.number.isRequired,
    margin          : PropTypes.number,
    fontSize        : PropTypes.number,                                                        // eslint-disable-line
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    openModal       : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    interactive     : PropTypes.bool,
    itemRatio       : PropTypes.number,
    linePosition    : PropTypes.number,
    labelPosition   : PropTypes.number,
    heightCut       : PropTypes.number,
    featuredIds     : PropTypes.shape(),
    featuredColor   : PropTypes.string,
    featuredScale   : PropTypes.number,
    showWatermark   : PropTypes.bool,
    content         : PropTypes.any,
    noContent       : PropTypes.bool,

};

/**
 * Default props value
 */
RaceLine.defaultProps = {
    margin          : 10,
    fontSize        : 12,
    color           : 'var(--insight-color)',
    skeleton        : false,
    skeletonGradient: false,
    openModal       : false,
    interactive     : true,
    itemRatio       : 1 / 5,
    linePosition    : 4,
    labelPosition   : 9,
    heightCut       : 10,
    featuredIds     : {},
    featuredColor   : 'rgb(89, 126, 170)',
    featuredScale   : 1.7,
    showWatermark   : true,
};

export default RaceLine;

