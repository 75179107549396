import TopicsOfInterest from './TopicsOfInterest';
import Period           from './Period';
import Organizations    from './Organizations';
import Searches         from './Searches';

export default {
    'competitive-insights':
        [
            TopicsOfInterest, Period, Organizations
        ],
    'topic-insights':
        [
            TopicsOfInterest, Period, Searches
        ],
};
