import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                           from 'lodash';

import {
    linkPath,
    arcPath2Point,
}                                  from 'utils/svg';

/**
 * Render networks nodes
 *
 */
class Links extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    /**
     * Check if the componant must render
     */
    shouldComponentUpdate(nextProps/* A , nextState */) {
        const { forceRender } = nextProps;

        return !!forceRender;
    }


    /**
    * Render the links
    *
    * @return html
    */
    renderLinksCurvedLines() {
        const {
                plotData, onMouseDown, onMouseUp, zoomScale,
            }           = this.props,
            { links }   = plotData,
            linearScale =  this.getStrokeScale();

        return _.map(links, (d) => (
            <path
                className="link"
                visibility="visible"
                key={d.id}
                dataid={d.id}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                d={linkPath({
                    source: {
                        x: d.source.x,
                        y: d.source.y
                    },
                    target: {
                        x: d.target.x,
                        y: d.target.y
                    }
                })}
                style={{
                    stroke     : '#DADADA',
                    fill       : 'none',
                    strokeWidth: `${linearScale(d.properties.coworks) / zoomScale}px`,
                }}
            />
        ));
    }


    /**
    * Render the links
    *
    * @return html
    */
    renderLinksArcs() {
        const {
                plotData, onMouseDown, onMouseUp, zoomScale,
            }           = this.props,
            { links }   = plotData,
            linearScale =  this.getStrokeScale();

        return _.map(links, (d) => {
            const path = arcPath2Point({
                    source: {
                        x: d.source.x,
                        y: d.source.y
                    },
                    target: {
                        x: d.target.x,
                        y: d.target.y
                    },
                    ratio: 0.4
                }),
                { properties } = d,
                { coworks }    = properties;

            return (
                <path
                    className="link"
                    visibility="visible"
                    key={d.id}
                    id={d.id}
                    dataid={d.id}
                    d={path}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    style={{
                        stroke     : '#DADADA',
                        fill       : 'none',
                        strokeWidth: `${linearScale(coworks) / zoomScale}px`,
                        // A strokeWidth: '0.25px',
                        // A strokeWidth: `${d.weight * 0.25}px`,
                    }}
                />
            );
        });
    }


    /**
     * Get stroke scale
     */
    getStrokeScale() {
        const { getLinksScaleLinear } = this.props;

        return getLinksScaleLinear().range([0.75, 3]);
    }

    /**
    * Render the links
    *
    * @return html
    */
    renderLinkLines() {
        const {
                onMouseDown, onMouseUp,
                zoomScale,
                links, linkColor
            }           = this.props,
            linearScale =  this.getStrokeScale();

        return _.map(links, (d) => {
            const{ properties, weight } = d,
                { coworks }    = properties || {};

            return (
                <line
                    className="link"
                    key={d.id}
                    dataid={d.id}
                    x1={d.source.x}
                    y1={d.source.y}
                    x2={d.target.x}
                    y2={d.target.y}
                    visibility="visible"
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    style={{
                        stroke     : linkColor,
                        fill       : 'none',
                        strokeWidth: `${linearScale(coworks || weight) / zoomScale}px`,
                    }}
                />
            );
        });
    }

    /**
     * The Render
     */
    render() {
        const {
            linkStyle
        } = this.props;

        return (
            <g className="links">
                {linkStyle === 'arc'
                    ? this.renderLinksArcs()
                    : this.renderLinkLines()}
            </g>
        );
    }

}

/**
 * Props type
 */
Links.propTypes = {
    plotData           : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]).isRequired,
    links              : PropTypes.array,
    onMouseDown        : PropTypes.func.isRequired,
    onMouseUp          : PropTypes.func.isRequired,
    getLinksScaleLinear: PropTypes.func.isRequired,
    linkStyle          : PropTypes.string,
    linkColor          : PropTypes.string,
    zoomScale          : PropTypes.number,
    forceRender        : PropTypes.bool,
};

/**
 * Default props value
 */
Links.defaultProps = {
    linkStyle: 'arc',
    plotData : false,
};

export default Links;
