import React, { PureComponent }         from 'react';
import { connect }                      from 'react-redux';
import PropTypes                        from 'prop-types';
import ImmutablePropTypes               from 'react-immutable-proptypes';
import _                                from 'lodash';
import { Collection, Icon, IButton }    from 'helpers';
import { addBookmark }                  from 'store/actions/userView/bookmarks';
import { getBookmarkFolder }            from 'store/actions/userView/bookmarksFolders';
import { getUserViewItemFromModel }     from 'store/actions/userView';
import Autocomplete                     from 'helpers/Search/Autocomplete';
import BookmarksRenderer                from 'helpers/Renderer/Bookmarks';

import './assets/tags-selector.less';

/**
 * Select organisation and tag them!
 *
 */
class TagsSelector extends PureComponent {

    /** The Construct */
    constructor(props) {
        super(props);

        _.bindAll(this, 'addEntityToTag', 'registerCallback',
            'getOrganizationsIdsFromTags', 'getTagContents', 'nextStep', 'isNextLocked');

        this.state = {
            entitiesToAdd       : {},
            // Currently saving bookmarks
            saving              : false,
            // Use this to skip company form
            skipCompany         : false,
            // Store there the callback to disable autocomplete
            autocompleteActions : {},
            // Select a parent bookmark folder
            parentBookmarkFolder: false
        };

        this.ref = React.createRef();
    }


    /**
     * Get folderId in search settings
     *
     * @returns string
     */
    getFolderIdFromSearch() {
        const { model, getBookmarkFolder } = this.props,
            { settings }                   = model || {},
            { bookmarkFolderForTags }      = settings || {},
            folderUserViewItem             = bookmarkFolderForTags && getBookmarkFolder(bookmarkFolderForTags),
            folderId                       = folderUserViewItem && folderUserViewItem.model.id;

        return folderId;
    }

    /**
    * Return a list containing bookmarks tagged with 'tag'
    *
    * @param string tag The tag we are looking for
    *
    * @return array
    */
    getOrganizationsIdsFromTags(tag) {
        const { model }     = this.props;

        return _.get(model, `settings.tags.${tag}`, []);
    }

    /**
    * Returns content for speicific tag
    *
    * @param object tag The tag to obtain contents
    *
    * @return object
    */
    getTagContents(tag) {
        const contents = {
            'my-company': {
                header: (
                    <span className="label">
                        <span className="top">
                            <Icon type={tag.icon} style={{ color: tag.color }}
                                theme="filled" color={tag.color}
                                height={20}
                            />
                            Identify your organization
                        </span>
                        <br />
                        <span className="sub">
                            If you don&apos;t want to disclose your organization or if your don&apos;t find it
                            <br />
                            {' '}
                            you can skip this step by clicking on the skip button.
                        </span>
                    </span>
                ),
                nextLabel: 'Save'
            },
            competitor: {
                header: (
                    <span className="label">
                        <span className="top">
                            <Icon type={tag.icon} style={{ color: tag.color }}
                                color={tag.color}
                                theme="filled"
                            />
                            Identify your known competitors
                        </span>
                    </span>
                ),
                footer: (
                    <span>
                        Want to identify more competitors for your analysis? You can use
                        the Company profiles on the top right corner.
                    </span>
                ),
                nextLabel: 'Access dashboard'
            }
        };
        return contents[tag.id];
    }

    /**
    * Add an entity to the tag pool
    *
    * @param object entity The entity to add
    * @param string tag    The tag ID
    */
    addEntityToTag(entity, tag) {
        const {
                entitiesToAdd,
                autocompleteActions
            }            = this.state,
            alreadyExist = !!_.find(entitiesToAdd[tag], {id: entity?.id});



        // Create the pool to store entities
        if (!entitiesToAdd[tag]) {
            entitiesToAdd[tag] = [];
        }

        // Add the entity only if not added before
        if(!alreadyExist) {
            entitiesToAdd[tag].push(entity);
        }

        // Add the entity to the selected ones
        this.setState({
            entitiesToAdd: { ...entitiesToAdd }
        });

        // Close the autocomplete box
        if (autocompleteActions.reset) {
            autocompleteActions.reset();
        }

        return false;
    }

    /**
    * Reset the input form
    */
    registerCallback(action, cb) {
        const { autocompleteActions } = this.state;

        autocompleteActions[action] = cb;

        this.setState({
            autocompleteActions
        });
    }

    /**
    * Get current tag to add
    *
    * @return string
    */
    getCurrentTagIdToAdd() {
        const { skipCompany }    = this.state,
            myCompanyBookmarks   = this.getOrganizationsIdsFromTags('my-company'),
            competitorsBookmarks = this.getOrganizationsIdsFromTags('competitor');

        // The main exit rule: at least 1 concurrent do not display the component. min 1 concurrent
        if (competitorsBookmarks.length > 0) {
            return false;
        }

        // Only display competitors rule
        if (myCompanyBookmarks.length > 0 || skipCompany) {
            return 'competitor';
        }

        // The last case is to select its company
        return 'my-company';
    }

    /**
     * Get current tag
     */
    getCurrentTag() {
        const { tags }   = this.props,
            currentTagId = this.getCurrentTagIdToAdd();

        return tags.find((tagModel) => tagModel.id === currentTagId);
    }

    /**
    * Next is locked ?
    *
    * @param object tag Current tag
    *
    * @return bool
    */
    isNextLocked(tag) {
        const {
                entitiesToAdd,
                parentBookmarkFolder
            }                  = this.state,
            currentEntities    = entitiesToAdd[tag.id] || [],
            myCompanyBookmarks = this.getOrganizationsIdsFromTags('my-company');

        // Validate the next or add error state.
        if (tag.id === 'competitor') {
            const competitorsLength = currentEntities.length;
            if (competitorsLength === 0 || (
                myCompanyBookmarks.length > 0 && competitorsLength < 1
            )) {
                return true;
            }
        }

        // Validate if has a parent folder selected
        if (currentEntities.length === 0 || !parentBookmarkFolder) {
            return true;
        }

        return false;
    }

    /**
    * Save bookmark & goto next step
    *
    * @param object tag The current tag to save bookmark
    *
    * @return void
    */
    nextStep(tag, isSkipAction = false) {
        const {
                addBookmark,
                getUserViewItemFromModel
            }                 = this.props,
            { entitiesToAdd } = this.state,
            currentEntities   = entitiesToAdd[tag.id] || [];

        // Validate the next or add error state.
        if (tag.id === 'competitor' && this.isNextLocked(tag)) {
            this.setState({ error: 'Competitive Landscape needs competitors to be performed.' });
            return;
        }

        // Skip my company?
        if (tag.id === 'my-company' && isSkipAction) {
            this.setState({ skipCompany: true, parentBookmarkFolder: false });
            this.ref.current.focus();
            return;
        }

        // Add the saving state
        this.setState({ saving: true });

        // Iterate overs entities to add bookmarks & tags
        currentEntities.forEach((entity) => {
            const {
                    parentBookmarkFolder
                }            = this.state,
                streamEntity = getUserViewItemFromModel(entity, 'bookmark'),
                existingTags = streamEntity?.model.tags ?? [],
                tagsToAdd    = [tag.id],
                tags         = [...existingTags, ...tagsToAdd];

            if(!parentBookmarkFolder) {
                return false;
            }

            return addBookmark(
                entity,
                { tags, parent_bookmark_folder: parentBookmarkFolder },
                () => {
                    this.setState({ saving: false, parentBookmarkFolder: false });
                    this.ref.current?.focus();
                }
            );
        });
    }

    /**
     * Store the parentBookmarkFolder key in the state
     *
     * @param {object} elementTree
     */
    onSelectFolder(elementTree) {
        const parentBookmarkFolder = elementTree?.model.id;
        this.setState({parentBookmarkFolder});
    }


    /**
     * Get current entities
     */
    getCurrentEntities() {
        const { entitiesToAdd } = this.state,
            currentTagId        = this.getCurrentTagIdToAdd();

        return entitiesToAdd[currentTagId] || [];
    }


    /**
     * Render selected entities
     */
    renderSelectedEntities() {
        const currentEntities = this.getCurrentEntities();
        return (
            <div className="selected">
                <div className="entities">
                    {currentEntities.length > 0 && (
                        <Collection
                            key={currentEntities.length}
                            entities={currentEntities}
                            mode="inline"
                        />
                    )}
                    {currentEntities.length === 0 && (
                        <span />
                    )}
                </div>
                <div className="several">
                    You can identify several organizations
                </div>
            </div>
        );
    }


    /**
     * Render baseline
     */
    renderBaseline() {
        return (
            <div className="baseline">
                To run Competitive Landscape dashboard, Insight needs some information
                {' '}
                <br />
                {' '}
                about your organization and your competitors
                {' '}
                <br />
                {' '}
                <br />
                (Don&apos;t worry, you only have to do it once)
            </div>
        );
    }

    /**
     *  Render Bookmark Folder selector
     */
    renderBookmarkFolder() {
        const { tags }         = this.props,
            tag                = this.getCurrentTag(),
            folderIdFromSearch = this.getFolderIdFromSearch();

        return (
            <div className="bookmark-container">
                <span>Select a folder to bookmark these organizations</span>
                <BookmarksRenderer
                    key={tag.id}
                    tags={tags}
                    onSelect={(elementTree) => this.onSelectFolder(elementTree)}
                    draggable={false}
                    folderId={folderIdFromSearch}
                    expandAll
                />
            </div>
        );
    }

    /**
     * Render button
     */
    renderButtons(isMyCompanyStep = false) {
        const { saving } = this.state,
            tag          = this.getCurrentTag(),
            locked       = this.isNextLocked(tag),
            tagContents  = this.getTagContents(tag),
            disabled     = saving || locked,
            label        = !saving ? tagContents.nextLabel : 'Saving...';

        return (
            <div className="actions-btn">
                <div className="content">
                    <IButton
                        label={label}
                        onClick={() => this.nextStep(tag)}
                        disabled={disabled}
                    />
                    {isMyCompanyStep && (
                        <IButton
                            label="Skip"
                            onClick={() => this.nextStep(tag, true)}
                        />
                    )}
                </div>
            </div>
        );
    }

    /**
    * Render the selectors for tags
    *
    * @return JSX
    */
    renderTagsSelectors() {  // eslint-disable-line  max-lines-per-function
        const {
                tags, foldersAreLoaded
            }               = this.props,
            currentTagId    = this.getCurrentTagIdToAdd(),
            isMyCompanyStep = currentTagId === 'my-company',
            currentEntities = this.getCurrentEntities();

        // No needs to add some bookmark/tag
        if (
            !foldersAreLoaded
            || !currentTagId
            || !tags

        ) {
            return false;
        }

        const tag       = this.getCurrentTag(),
            tagContents = this.getTagContents(tag);

        // Render the selector
        return (
            <div className="organisations-selector">
                <div className="wrapper">
                    {this.renderBaseline()}
                    <div className="identify" ref={this.ref}>
                        <Autocomplete
                            tags={tags}
                            types={['orgunit']}
                            label={tagContents.header}
                            selection={currentEntities}
                            placeholder="Enter organizations to add bookmarks.."
                            onSelect={(entity) => this.addEntityToTag(entity, currentTagId)}
                            registerCallbacks={this.registerCallback}
                        />
                        {this.renderSelectedEntities()}
                        {this.renderBookmarkFolder()}
                        <div className="footer">{tagContents.footer}</div>
                        {this.renderButtons(isMyCompanyStep)}
                    </div>
                </div>
            </div>
        );
    }

    /**
    * Rendering the Dashboard
    *
    * @return JSX
    */
    render() {
        const {
                children,
            } = this.props,
            tagSelector      = this.renderTagsSelectors();

        return tagSelector || children;
    }

}

TagsSelector.propTypes ={
    addBookmark             : PropTypes.func.isRequired,
    bookmarksList           : ImmutablePropTypes.list.isRequired,   // eslint-disable-line react/no-unused-prop-types
    children                : PropTypes.any,
    model                   : PropTypes.object,
    tags                    : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]).isRequired,
    getUserViewItemFromModel: PropTypes.func,
    getBookmarkFolder       : PropTypes.func,
    foldersAreLoaded        : PropTypes.bool,
};

TagsSelector.defaultProps = {};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarksList   : state.getIn(['userView', 'bookmark', 'list']),
    foldersAreLoaded: state.getIn(['userView', 'bookmark_folder', 'stats', 'modelsAreLoaded']),
});

export default connect(mapStateToProps, {
    addBookmark,
    getUserViewItemFromModel,
    getBookmarkFolder,
})(TagsSelector);

