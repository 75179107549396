import React, { Component }    from 'react';
import _                       from 'lodash';
import PropTypes               from 'prop-types';

import {
    formatNumberWithMagnitude,
    capitalize,
}                              from 'utils/text';
import { collision }           from 'utils/svg';
import { requestTimeout }      from 'utils/requestTimeout';

import './assets/landmark.less';


/**
 * The graph line area Component
 *
 */
class Landmark extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);
        const { color } = props;

        this.state = {
            lastXTickWidth      : null,
            marginRightStabilize: true,
        };

        this.color = {
            text: color,
            axes: color,
            grid: 'var(--insight-gray)',
        };

        this.ref = React.createRef();

        _.bindAll(this, 'render', 'componentDidUpdate', 'checkXTicksOverlapping');
    }

    /**
    * Ticks filters (using ticksAlter props)
    *
    * @params array  ticks Ticks to be filtered
    * @params string axe   Axe ('x' or 'y')
    *
    * @return array
    */
    static getFilteredTicks(options, ticks, axe) {
        const { ticksAlter } = options,
            min = axe === 'y'
                ? (!_.isUndefined(ticksAlter.minY) ? ticksAlter.minY : null)
                : (!_.isUndefined(ticksAlter.minX) ? ticksAlter.minX : null),
            max = axe === 'y'
                ? (!_.isUndefined(ticksAlter.maxY) ? ticksAlter.maxY : null)
                : (!_.isUndefined(ticksAlter.maxX) ? ticksAlter.maxX : null),
            filteredTicks = _.filter(ticks, (tickValue) => {
                let removed = false;
                removed = removed || (!_.isNull(max) && tickValue > max);        // Mask ticks over  MAX
                removed = removed || (!_.isNull(min) && tickValue < min);        // Mask ticks under MIN
                removed = removed || (tickValue - Math.floor(tickValue) !== 0);  // Mask tick with float
                return !removed;
            });

        return filteredTicks;
    }

    /**
    * Compute graph data
    *
    * @params nextProps  object New props received
    * @params prevStates object Previous states
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevStates) {
        const {
                width, height,
                xScale, yScale,
                margin,
            }                  = nextProps,
            { lastXTickWidth } = prevStates,
            {
                axeLabelFontSize,
                xLabelHeight, xTicksLabelHeight,
                yLabelWidth, yTicksLabelWidth,

            }                  = Landmark.getLandmarkSizes(nextProps),
            bottomPadding      = xTicksLabelHeight * 2 + xLabelHeight + margin,
            rightPadding       = lastXTickWidth / 2 + margin,
            xOffset            = yLabelWidth + yTicksLabelWidth + margin,
            yOffset            = xTicksLabelHeight + margin,
            containerWidth     = width - xOffset - rightPadding,
            containerHeight    = height - yOffset - bottomPadding,
            rangeWidth         = xScale && (xScale.range()[1] - xScale.range()[0]),
            rangeHeight        = yScale && (Math.abs(yScale.range()[1] - yScale.range()[0])),
            plotData           = Landmark.getPlotData(
                nextProps,
                {
                    prevStates,
                    containerWidth,
                    containerHeight
                }
            );

        return {
            plotData,
            rangeWidth,
            rangeHeight,
            containerWidth : ~~containerWidth,
            containerHeight: ~~containerHeight,
            rightPadding   : ~~rightPadding,
            xOffset,
            yOffset,
            axeLabelFontSize,
            xTicksLabelHeight,
            yTicksLabelWidth,
            xLabelHeight,
            yLabelWidth,
        };
    }


    /**
     *  Get plotData
     * @param {object} options
     *
     * @returns object
     */
    static getPlotData(nextProps, options) {
        const {
                containerWidth, containerHeight
            }                  = options,
            {
                selectedRange,
                xScale, yScale, ticksAlter,
                ticksWidth, ticksHeight, noContent,
            }                  = nextProps,
            // Count nb X units (max - min)
            nbXUnits           = !_.isNull(xScale) ? Math.round(xScale.domain()[1] - xScale.domain()[0]) : null,
            // Calculate number of x ticks
            nbXTicks           = Math.round(containerWidth / ticksWidth),
            stepWidth          = !_.isNull(xScale) && !_.isUndefined(xScale.step) ? xScale.step() : false,
            stepHeight         = !_.isNull(yScale) && !_.isUndefined(yScale.step) ? yScale.step() : false,
            // Generate the xTicks from scale
            xTicks             = !_.isNull(xScale)
                ? (stepWidth !== false ? xScale.domain() : xScale.ticks(_.min([nbXUnits, nbXTicks])))
                : null,
            // Calculate number of y ticks
            nbYTicks           = Math.round(containerHeight / ticksHeight),
            // Generate the yTicks from scale
            yTicks             = !_.isNull(yScale)
                ? (stepHeight !== false
                    ? yScale.domain()
                    : Landmark.getFilteredTicks(
                        { ticksAlter, scale: yScale },
                        !_.isNull(yScale) ? yScale.ticks(nbYTicks) : null, 'y'
                    )
                ) : null;

        return {
            opacity    : noContent ? 0.3 : 1,
            xPixelTicks: xTicks ? xTicks.map(
                (tick) => ({
                    value   : tick,
                    position: xScale(tick),
                    selected: _.get(selectedRange, 'min.x', false)
                        && selectedRange.min.x <= tick && tick <= selectedRange.max.x
                })
            ) : [],
            yPixelTicks: yTicks ? yTicks.map(
                (tick) => ({
                    value   : tick,
                    position: yScale(tick),
                })
            ) : [],
            selectedPixelRange: xScale ? {
                min: { x: xScale(_.get(selectedRange, 'min.x')) },
                max: { x: xScale(_.get(selectedRange, 'max.x')) },
            } : null,
            stepWidth,
            stepHeight,
        };
    }

    /**
     * Get sizes calculated from fontsize
     *
     * @param {object} nextProps
     * @returns
     */
    static getLandmarkSizes(nextProps) {
        const {
                fontSize, xLabel, yLabel,
                hideLandmark,
                hideXTicks, hideYTicks
            }                 = nextProps,
            axeLabelFontSize  = ~~(fontSize * 1.7),
            xTicksLabelHeight = hideXTicks ? 0 : ~~(fontSize * 1.7),
            xLabelHeight      = xLabel ? ~~(axeLabelFontSize * 1.5) : 0,
            yTicksLabelWidth  = hideYTicks ? ~~(fontSize * 1) : ~~(fontSize * 4.5),
            yLabelWidth       = yLabel ? ~~(axeLabelFontSize * 2.4) : 0;

        if (hideLandmark) {
            return {
                axeLabelFontSize : 0,
                xTicksLabelHeight: 0,
                yTicksLabelWidth : 0,
                xLabelHeight     : 0,
                yLabelWidth      : 0,
            };
        }

        return {
            axeLabelFontSize,
            xTicksLabelHeight,
            yTicksLabelWidth,
            xLabelHeight,
            yLabelWidth,
        };
    }



    /**
    * Component did update
    */
    componentDidMount() {
        this.updateLandmarkCoords();
    }


    /**
    * Component did update
    */
    componentDidUpdate() {
        this.updateLandmarkCoords();
    }

    /**
     * Update landmark coords
     */
    updateLandmarkCoords() {
        const { setLandmarkCoordsCb, xScale }  = this.props,
            {
                containerWidth, containerHeight,
                xOffset, yOffset, rightPadding
            } = this.state;

        requestAnimationFrame(this.checkXTicksOverlapping);

        setLandmarkCoordsCb && setLandmarkCoordsCb({
            plotSize: {
                width        : containerWidth,
                height       : containerHeight,
                landmarkWidth: this.getLandmarkWidth()
            },
            offsets: {
                xOffset,
                yOffset,
                rightPadding,
            }
        });

        xScale && this.storeLastXTickWidth();
    }


    /**
     * Store lastXTickWidth in state
     */
    storeLastXTickWidth() {
        const {
                hideLandmark,
                hideXTicks,
            }              = this.props,
            {
                lastXTickWidth: lastXTickWidthFromState,
                marginRightStabilize,
            }              = this.state,
            { current }    = this.ref,
            xTicksEls      = current?.querySelectorAll('.xticks .tick text'),
            lastXTickWidth = xTicksEls && Math.round(_.last(xTicksEls).getBoundingClientRect().width);

        if (hideLandmark || hideXTicks) {
            return;
        }

        if (lastXTickWidth !== lastXTickWidthFromState && marginRightStabilize) {
            this.setState({ lastXTickWidth, marginRightStabilize: false });

            // Use marginRightStabilize to know when the xScale is stabilized
            this.marginStabilizeTimeout && this.marginStabilizeTimeout.cancel();
            this.marginStabilizeTimeout = requestTimeout(() => {
                this.setState({ marginRightStabilize: true });
            }, 100);
        }
    }

    /**
     * Check x ticks overlapping
     */
    checkXTicksOverlapping() {
        const { current } = this.ref,
            xticksEls     = current?.querySelectorAll('.xticks .tick text');

        let tickOverlapStep = false,
            overlapStep     = 0,
            nextStep        = false;

        if (xticksEls) {
            do {
                overlapStep += 1;
                nextStep     = false;

                for (const index in xticksEls) {
                    // Elements to detect collision
                    const xticksEl1 = xticksEls[index],
                        xticksEl2   = xticksEls[parseInt(index) + overlapStep];

                    // Second element doest exist
                    if (!xticksEl2) {
                        break;
                    }

                    // Check collision
                    const col = collision(xticksEl1, xticksEl2);
                    if (col) {
                        // Store the tickOverlapStep
                        tickOverlapStep = overlapStep + 1;

                        // Go to next overlapStep
                        nextStep        = true;
                        break;
                    }
                }
            } while (nextStep);

            // Apply visibility on x ticks text elements
            xticksEls.forEach((xticksEl, index) => xticksEl.setAttribute(
                'visibility',
                !tickOverlapStep || parseInt(index)%tickOverlapStep === 0 ? 'visible' : 'hidden'
            ));
        }
    }

    /**
    * Render axes
    *
    * @return void
    */
    renderAxes() {
        const {
                containerWidth,
                containerHeight,
                plotData
            } = this.state,
            {
                xPixelTicks,
            } = plotData,
            hideX         = _.isNull(_.get(xPixelTicks, 0, null)),
            landmarkWidth = this.getLandmarkWidth();

        return (
            <g className="axes">
                {hideX ? '' : ( // X axis line
                    <line
                        className="axis"
                        x1={0}
                        x2={_.min([landmarkWidth, containerWidth])}
                        y1={containerHeight}
                        y2={containerHeight}
                        stroke={this.color.axes}
                    />
                )}

                {hideX ? '' : ( // Y axis line
                    <line
                        className="axis"
                        x1={0}
                        x2={0}
                        y1={containerHeight}
                        y2={0}
                        stroke={this.color.axes}
                    />
                )}
            </g>
        );
    }

    /**
    * Render axes labels
    *
    * @return void
    */
    renderAxesLabels() {
        const {
                xLabel, yLabel,
                height: innerHeight,
                margin,
            }         = this.props,
            {
                containerWidth: width,
                containerHeight: height,
                xLabelHeight,
                yLabelWidth,
                axeLabelFontSize,
                xOffset,
                plotData,
            } = this.state,
            {
                xPixelTicks,
                // A yPixelTicks,
                stepWidth,
                // A stepHeight,
            }         = plotData,
            maxWidth  = stepWidth !== false ? _.min([width, xPixelTicks.length * stepWidth]) : width,
            maxHeight = height; // A stepHeight !== false ? _.min([height, yPixelTicks.length * stepHeight]) : height;

        return (
            <g className="axeSLabels">
                <text
                    x={xOffset + maxWidth / 2}
                    y={innerHeight - xLabelHeight - margin}
                    dominantBaseline="hanging"
                    style={{ textAnchor: 'middle', fill: this.color.text, fontSize: axeLabelFontSize }}
                >
                    {capitalize(xLabel)}
                </text>
                <text
                    transform="rotate(-90)"
                    x={-maxHeight / 2 - margin}
                    y={margin + yLabelWidth / 2}
                    dominantBaseline="baseline"
                    style={{ textAnchor: 'middle', fill: this.color.text, fontSize: axeLabelFontSize  }}
                >
                    {capitalize(yLabel)}
                </text>
            </g>
        );
    }

    /**
    * Render X ticks
    *
    * @return void
    */
    renderXTicks() {
        const { color }                 = this,
            { fontSize }                = this.props,
            {
                containerHeight: height,
                xTicksLabelHeight,
                plotData
            }                           = this.state,
            { xPixelTicks, stepWidth }  = plotData;

        if (_.isNull(_.get(xPixelTicks, 0, null))) {
            return '';
        }

        return (
            <g className="xticks" transform={`translate( ${0} ${0} )`}>
                { xPixelTicks.map((tick) => (
                    <g
                        key={`tiksX${tick.position}`}
                        className="tick"
                        transform={`translate( ${tick.position} ${height} )`}
                        style={{ position: 'absolute' }}
                    >
                        <title>
                            {tick.value}
                        </title>
                        <text
                            x={0}
                            y={xTicksLabelHeight}
                            fontSize={fontSize}
                            transform={`translate( ${stepWidth !== false ? stepWidth / 2 : 0} ${0} )`}
                            fontWeight={
                                tick.selected
                                    ? 'Bold'
                                    : 'Normal'
                            }
                            style={{ textAnchor: 'middle', fill: color.text }}
                        >
                            {tick.value}
                        </text>
                        {this.renderYGridLines()}
                    </g>
                ))}
            </g>
        );
    }

    /**
    * Render Y grid lines
    *
    * @return void
    */
    renderYGridLines() {
        const {
                containerHeight: height,
                plotData
            } = this.state,
            {
                stepWidth,
                stepHeight,
                yPixelTicks,

            } = plotData,
            maxHeight = stepHeight !== false ? _.min([height, yPixelTicks.length * stepHeight]) : height;

        return (
            <g>
                <line
                    x1={0}
                    x2={0}
                    y1={-maxHeight}
                    y2={0}
                    stroke={this.color.grid}
                    strokeDasharray="2"
                    vectorEffect="non-scaling-stroke"
                />
                {stepWidth === false
                    ? ''
                    : (
                        <line
                            x1={stepWidth}
                            x2={stepWidth}
                            y1={-maxHeight}
                            y2={0}
                            stroke={this.color.grid}
                            strokeDasharray="2"
                            vectorEffect="non-scaling-stroke"
                        />
                    )}
            </g>
        );
    }

    /**
    * Render Y ticks
    *
    * @return void
    */
    renderYTicks() {
        const {
                ticksAlter
            } = this.props,
            {
                plotData
            } = this.state,
            {
                yPixelTicks
            } = plotData,
            offset       = !_.isUndefined(ticksAlter.offsetY) ? ticksAlter.offsetY : 0,
            inverseYTick = !_.isUndefined(ticksAlter.inverseYTick) && ticksAlter.inverseYTick,
            absolute     = false,
            options      = {
                offset,
                absolute,
                inverseYTick,
            };

        if (_.isNull(_.get(yPixelTicks, 0, null))) {
            return null;
        }

        return (
            <g className="yticks">
                <g className="ticks">
                    { yPixelTicks.map((tick) => this.renderYTick(tick, options)) }
                </g>
            </g>
        );
    }

    /**
     * Get max with of the plot area
     *
     * @returns
     */
    getLandmarkWidth() {
        const {
                rangeWidth,
                containerWidth,
                plotData,
            } = this.state,
            {
                stepWidth,
                xPixelTicks,
            }  = plotData;

        return stepWidth !== false
            ? _.min([containerWidth, xPixelTicks.length * stepWidth])
            : rangeWidth;
    }

    /**
    * Render Y tick
    *
    * @return void
    */
    renderYTick(tick, options) {
        const {
                offset,
                inverseYTick,
                absolute,
            }                           = options,
            {
                plotData
            }                          = this.state,
            inverseFactor              = inverseYTick ? -1 : 1,
            { fontSize, xOffsetAfter } = this.props,
            { stepHeight }             = plotData,
            landmarkWidth              = this.getLandmarkWidth(),
            formattedValue             = formatNumberWithMagnitude(absolute ? Math.abs(tick.value) : tick.value, 1);

        return (
            <g
                className="tick"
                key={`tiksY${tick.position}`}
                transform={`translate( ${0} ${tick.position * inverseFactor + offset} )`}
                style={{ position: 'absolute' }}
            >
                <title>
                    {formattedValue}
                </title>
                <text
                    x="-10"
                    y={0}
                    transform={`translate( 0 ${stepHeight !== false ? stepHeight / 2 : 0} )`}
                    fontSize={fontSize}
                    style={{ textAnchor: 'end', fill: this.color.text, position: 'absolute' }}
                    dominantBaseline="middle"
                >
                    {formattedValue}
                </text>
                <line
                    x1={landmarkWidth + xOffsetAfter}
                    x2="0"
                    y1={0}
                    y2={0}
                    stroke={this.color.grid}
                    strokeDasharray="2"
                    vectorEffect="non-scaling-stroke"
                />
            </g>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                hideXTicks, hideYTicks,
                width, height, hideLandmark,
            }       = this.props,
            {
                xOffset, yOffset,
                marginRightStabilize,
                plotData
            }       = this.state,
            {
                opacity: dataOpacity
            }       = plotData,
            key     = `${JSON.stringify(plotData)}${width}${height}`,
            opacity = marginRightStabilize ? dataOpacity : 0;

        return !hideLandmark && (
            <svg
                className="landmark"
                ref={this.ref}
                key={key} style={{ userSelect: 'none' }}
                /** SVG on capture won't get translate */
            >
                <g transform={`translate( ${xOffset} ${0} )`} style={{ opacity }}>
                    {!hideYTicks ? this.renderYTicks() : null}
                </g>
                <g transform={`translate( ${0} ${yOffset} )`} style={{ opacity }}>
                    {!hideXTicks ? this.renderXTicks() : null}
                </g>
                <g transform={`translate( ${xOffset} ${yOffset} )`}>
                    {this.renderAxes()}
                </g>
                <g>
                    {this.renderAxesLabels()}
                </g>
            </svg>
        );
    }

}

Landmark.propTypes = {
    color              : PropTypes.string,
    fontSize           : PropTypes.number,
    margin             : PropTypes.number,
    height             : PropTypes.number.isRequired,
    hideXTicks         : PropTypes.bool,
    hideYTicks         : PropTypes.bool,
    ticksAlter         : PropTypes.shape(),
    noContent          : PropTypes.bool,         // eslint-disable-line react/no-unused-prop-types
    ticksHeight        : PropTypes.number,       // eslint-disable-line react/no-unused-prop-types
    ticksWidth         : PropTypes.number,       // eslint-disable-line react/no-unused-prop-types
    selectedRange      : PropTypes.shape(),      // eslint-disable-line react/no-unused-prop-types
    width              : PropTypes.number.isRequired,
    xLabel             : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    xOffsetAfter       : PropTypes.number,
    xScale             : PropTypes.func,
    yLabel             : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    yScale             : PropTypes.func,
    setLandmarkCoordsCb: PropTypes.func,
    hideLandmark       : PropTypes.bool,
};

Landmark.defaultProps = {
    color              : 'var(--insight-color)',
    xScale             : null,
    yScale             : null,
    xOffsetAfter       : 0,
    margin             : 0,
    ticksWidth         : 50,
    ticksHeight        : 30,
    xLabel             : false,
    yLabel             : false,
    hideXTicks         : false,
    fontSize           : 9,
    noContent          : true,
    ticksAlter         : {},
    setLandmarkCoordsCb: null,
    hideLandmark       : false,
};

export default Landmark;
