// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./little-skeleton.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./skeleton-top-collab.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Top circle graph
*
*/
.TopCircle .label {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.TopCircle.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.TopCircle .label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.metric-top-collaboration .TopCircle.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/TopCircle/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAIQ,kBAAA;EACA,QAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,0BAAA;AAHR;AAMI;EACI,mDAAA;AAJR;AAVA;EAkBQ,uBAAA;EACA,gBAAA;EACA,mBAAA;AALR;AAUA;EACI,mDAAA;AARJ","sourcesContent":["/**\n* Top circle graph\n*\n*/\n\n.TopCircle {\n    svg {\n    }\n    .label{\n        position: absolute;\n        top: 50%;\n        transform: translate( 0, -50%);\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        text-transform: capitalize;\n    }\n\n    &.skeleton {\n        mask-image:  url('./little-skeleton.svg');\n    }\n\n    .label {\n        text-overflow: ellipsis;\n        overflow: hidden;\n        white-space: nowrap;\n    }\n}\n\n// By metrics\n.metric-top-collaboration .TopCircle.skeleton {\n    mask-image:  url('./skeleton-top-collab.svg');\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
