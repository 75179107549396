// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ipc-codes {
  display: flex;
  flex-direction: column;
}
.ipc-codes .ipc {
  display: flex;
  gap: 8px;
  align-items: center;
  width: max-content;
}
.ipc-codes .ipc span {
  color: var(--secondary-color) !important;
}
.ipc-codes .ipc .icon {
  visibility: hidden;
}
.ipc-codes .ipc:hover {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.ipc-codes .ipc:hover .icon {
  visibility: visible;
}
.ipc-codes .show-more {
  margin-top: 2px;
}
.ipc-code-description .ant-popover-inner {
  padding: 0;
}
.ipc-code-description .ant-popover-inner .ant-popover-inner-content div {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
.ipc-code-description .ant-popover-inner .ant-popover-inner-content div span {
  padding: 10px 15px;
}
.ipc-code-description .ant-popover-inner .ant-popover-inner-content div .ipc {
  font-weight: 900;
  border-bottom: 1px solid #E1E1E1;
}
.ipc-code-description .ant-popover-inner .ant-popover-inner-content div .description {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Element/Area/Entity/assets/ipc.less"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;AAFA;EAKQ,aAAA;EACA,QAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AARA;EAWY,wCAAA;AAAZ;AAXA;EAeY,kBAAA;AADZ;AAIQ;EACI,iBAAA;EACA,eAAA;EACA,0BAAA;AAFZ;AADQ;EAMQ,mBAAA;AAFhB;AAtBA;EA8BQ,eAAA;AALR;AASA;EAEQ,UAAA;AARR;AAMA;EAKY,aAAA;EACA,sBAAA;EACA,gBAAA;AARZ;AACA;EAUgB,kBAAA;AARhB;AAFA;EAcgB,gBAAA;EACA,gCAAA;AAThB;AANA;EAmBgB,eAAA;AAVhB","sourcesContent":["\n.ipc-codes {\n    display       : flex;\n    flex-direction: column;\n\n    .ipc {\n        display    : flex;\n        gap        : 8px;\n        align-items: center;\n        width      : max-content;\n\n        span {\n            color: var(--secondary-color) !important;\n        }\n\n        .icon {\n            visibility: hidden;\n        }\n\n        &:hover {\n            font-weight    : bold;\n            cursor         : pointer;\n            text-decoration: underline;\n\n            .icon {\n                visibility: visible;\n            }\n        }\n    }\n\n    .show-more {\n        margin-top: 2px;\n    }\n}\n\n.ipc-code-description {\n    .ant-popover-inner {\n        padding: 0;\n\n        .ant-popover-inner-content div {\n            display       : flex;\n            flex-direction: column;\n            max-width     : 600px;\n\n            span {\n                padding: 10px 15px;\n            }\n\n            .ipc {\n                font-weight  : 900;\n                border-bottom: 1px solid #E1E1E1;\n            }\n\n            .description {\n                font-size: 12px;\n            }\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
