import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                           from 'lodash';

/**
 * Render networks nodes
 *
 */
class LinksValues extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Stop click propagation
     */
    stopPropagation(e) {
        e.preventDefault();
        e.stopPropagation();
    }


    /**
     * Render the svg group of link value
     *
     * @return JSX
     */
    renderLinkValue(d, r, pos) {
        const  {
                onMouseUp, onMouseOver, onMouseOut,
                linkValueOvered
            }                 = this.props,
            { coworks }       = d.properties,
            isOrgunitLink     = d.source?.properties?.entityType === 'orgunit',
            isLinkValueOvered = linkValueOvered && linkValueOvered.id === d.id,
            rayonFactor       = isLinkValueOvered && isOrgunitLink ? 1.5 : 1,
            transform         = !_.isUndefined(pos.x) ? `translate( ${pos.x} ${pos.y})` : null,
            className         = `linkValue${isOrgunitLink ? '' : ' disabled'}`;

        return (
            <g
                key={d.id}
                dataid={d.id}
                className={className}
                onMouseDown={this.stopPropagation}
                onMouseUp={isOrgunitLink ? onMouseUp : this.stopPropagation}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onBlur={onMouseOut}
                transform={transform}
                opacity={!linkValueOvered || isLinkValueOvered ? 1 : 0.1}
            >
                <circle cx="0" cy="0"
                    r={r * rayonFactor} fill="var(--secondary-color)"
                />
                <text
                    fontSize={r * rayonFactor}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{fill: '#ffffff'}}
                >
                    {coworks}
                </text>
            </g>
        );
    }


    /**
     * The Render
     */
    render() {
        const {
                selectedNodesIds, zoomScale,
                getSizeScale, getLinksScaleLinear, highlightedLinks,
            }           = this.props,
            sizeScale   = getSizeScale(),
            linearScale = getLinksScaleLinear().range([8, 15]);

        return (
            <g className="links-values">
                { _.map(highlightedLinks, (d) => {
                    const {
                            properties, source, target
                        } = d,
                        { coworks }      = properties,
                        r                = linearScale(coworks) / zoomScale,
                        sourceSelected     = selectedNodesIds.includes(source.id),
                        targetSelected     = selectedNodesIds.includes(target.id),
                        origin             = sourceSelected ? target : source,
                        destination        = sourceSelected ? source : target,
                        // Angle of the link
                        angle = Math.atan2(destination.x - origin.x, destination.y - origin.y),
                        // The coordinate offset of intersection of origin node and the link
                        originRadius       = sizeScale(origin.size) / 2,
                        originBorder       = {
                            x: Math.sin(angle) * (originRadius + r * 3),
                            y: Math.cos(angle) * (originRadius + r * 3),
                        },
                        // The coordinate offset of intersection of destination node and the link
                        destinationRadius  = sizeScale(destination.size) / 2,
                        destinationBorder  = {
                            x: -Math.sin(angle) * (destinationRadius + r * 3),
                            y: -Math.cos(angle) * (destinationRadius + r * 3),
                        },
                        lineLength = Math.sqrt((destination.x - origin.x)**2 + (destination.y - origin.y)**2)
                            - originRadius - destinationRadius,
                        pos                = sourceSelected && targetSelected || lineLength < r * 6
                            // Center of the link (line is too small or the two node are selected)
                            ? {
                                x: (origin.x + originBorder.x + destination.x + destinationBorder.x) / 2,
                                y: (origin.y + originBorder.y + destination.y + destinationBorder.y) / 2
                            }
                            // At the border of the origin node
                            : {
                                x: origin.x + originBorder.x,
                                y: origin.y + originBorder.y,
                            };

                    return (sourceSelected || targetSelected) && this.renderLinkValue(d, r, pos);
                })}
            </g>
        );
    }

}

/**
 * Props type
 */
LinksValues.propTypes = {
    zoomScale          : PropTypes.number.isRequired,
    onMouseUp          : PropTypes.func.isRequired,
    onMouseOver        : PropTypes.func.isRequired,
    onMouseOut         : PropTypes.func.isRequired,
    getSizeScale       : PropTypes.func.isRequired,
    selectedNodesIds   : PropTypes.array.isRequired,
    highlightedLinks   : PropTypes.array.isRequired,
    getLinksScaleLinear: PropTypes.func.isRequired,
    linkValueOvered    : PropTypes.shape(),
};

/**
 * Default props value
 */
LinksValues.defaultProps = {
};

export default LinksValues;
