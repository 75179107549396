import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                           from 'lodash';

import {
    scaleLinear      as D3ScaleLinear,
}                                    from 'd3';

/**
 * Render networks nodes
 *
 */
class NodesLabels extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Get FontSize scale
     *
     * @return function
     */
    getFontScale() {
        const { gD3 }  = this.props,
            sizeDomain = gD3.getSizeDomain();

        return D3ScaleLinear().domain(sizeDomain).range([12, 20]);
    }

    /**
     * Stop click propagation
     */
    stopPropagation(e) {
        e.preventDefault();
        e.stopPropagation();
    }


    /**
     * The Render
     */
    render() {
        const {
                zoomScale, nodeTitleClick,
                getSizeScale, nodes,
            }                = this.props,
            sizeScale        = getSizeScale(),
            fontScale        = this.getFontScale();

        return nodes.length && (
            <g className="nodes-labels">
                { _.map(nodes, (d) => {
                    const fontsizeScaleCalc = fontScale(d.size) / zoomScale,
                        fontsizeScaled      = d.size > 0 ? fontsizeScaleCalc : 0,
                        sizeScaleCalc       = sizeScale(d.size),
                        sizeScaled          = d.size > 0 ? sizeScaleCalc : 0,
                        transform           = !_.isUndefined(d.x) ? `translate(${d.x},${d.y})` : null;

                    return (
                        <g
                            key={`${d.id}${d.size}${d.cluster}`}
                            dataid={d.id}
                            className="node-label"
                            transform={transform}
                            style={{ cursor: nodeTitleClick ? 'pointer' : null, pointerEvents: 'bounding-box' }}
                            onMouseDown={this.stopPropagation}
                            onMouseUp={nodeTitleClick}
                        >
                            <text
                                visibility="visible"
                                dataid={d.id}
                                y={sizeScaled / 2 + 12 / zoomScale}
                                fontSize={fontsizeScaled}
                                dominantBaseline="hanging"
                                style={{ textAnchor: 'middle',
                                    fill      : 'var(--primary-color)',
                                    fontWeight: 'bold',
                                    fontFamily: 'Helvetica' }}
                            >
                                {(d.label)}
                            </text>
                        </g>
                    );
                })}
            </g>
        );
    }

}

/**
 * Props type
 */
NodesLabels.propTypes = {
    gD3           : PropTypes.shape().isRequired,
    zoomScale     : PropTypes.number.isRequired,
    nodeTitleClick: PropTypes.func,
    getSizeScale  : PropTypes.func.isRequired,
    nodes         : PropTypes.array.isRequired,
};

/**
 * Default props value
 */
NodesLabels.defaultProps = {
    nodeTitleClick: null,
};

export default NodesLabels;
