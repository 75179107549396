import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { Icon, Graph, CssLoader } from 'helpers';

/**
 * Buttons layout
 *
 * @return React componant
 */
class Buttons extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            prevState: {},
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
    }

    /** Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderUntouched() {
        const { nbUntouched } = this.props;

        if (!nbUntouched) { return null; }

        return (
            <div className="untouched">
                <span>
                    {nbUntouched}
                </span>
            </div>
        );
    }

    /**
    * Render the loader
    *
    * @return JSX
    */
    renderLoader() {
        const { loading } = this.props;
        if (!loading) { return null; }

        return (
            <CssLoader type="ring" size={36}
                thickness={1} color="var(--primary-light-color)"
            />
        );
    }

    /**
    * Render the progress
    *
    * @return JSX
    */
    renderProgress() {
        const { progress } = this.props;

        if (progress >= 1) { return null; }

        return (
            <Graph
                type="Gauge"
                dataIsLoaded
                innerRadius={0.9}
                data={{ stats: { score: progress } }}
                steps={12}
                innerPadding={0}
                angleStart={0}
                angleEnd={Math.PI * 2}
                color="#10285D"
                stepBorderSize={0}
                emptyColor="#bdbdbd"
                needleSize={0}
                fontSize={0}
                height={38}
                width={38}
                noLabel
            />
        );
    }

    /**
    * Render the progress
    *
    * @return JSX
    */
    renderIcons() {
        const { id, ids, title } = this.props;

        if (id) {
            return this.renderIcon({
                id, title, size: 20, margin: 8
            });
        }

        // Packed
        return ids.map((id) => this.renderIcon({ id, size: 9, margin: 4 }));
    }

    /**
    * Render the progress
    *
    * @return JSX
    */
    renderIcon(def) {
        const { disabled } = this.props,
            {
                id, title, size,
                margin,
            } = def;

        return (
            <Icon
                key={`${id}-${disabled}`}
                folder="/shortcuts/"
                id={id}
                title={title}
                color={disabled ? '#ccc' : 'var(--primary-color)'}
                height={size}
                style={{
                    marginLeft: `${margin}px`,
                    marginTop : `${margin}px`,
                }}
            />
        );
    }

    /**
    * Render the button
    *
    * @return html
    */
    render() {
        const {
                id, ids, title,
                active, calling,
            } = this.props,
            multipleIcons        = !id && ids && ids.length > 1,
            lineHeight           = 16 / (multipleIcons ? 2 : 1),
            padding              = multipleIcons ? 3 : 0,
            className            = ['shortcut', active ? ' active' : '', calling ? 'calling' : ''],
            style                = { lineHeight: `${lineHeight}px`, paddingTop: padding, paddingLeft: padding };

        return (
            <div
                title={title}
                className={className.join(' ')}
                style={style}
            >
                {this.renderProgress()}
                {this.renderLoader()}
                {this.renderIcons()}
                {this.renderUntouched()}
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
Buttons.propTypes = {
    title      : PropTypes.string.isRequired,
    ids        : PropTypes.arrayOf(PropTypes.string),
    id         : PropTypes.string,
    progress   : PropTypes.number,
    nbUntouched: PropTypes.number,
    active     : PropTypes.bool,
    calling    : PropTypes.bool,
    loading    : PropTypes.bool,
    disabled   : PropTypes.bool,
};

Buttons.defaultProps = {
    progress   : 1,
    active     : false,
    calling    : false,
    nbUntouched: false,
    loading    : false,
    disabled   : false,
};

export default Buttons;
