import React, { PureComponent }      from 'react';
import PropTypes                 from 'prop-types';
import { Skeleton }              from 'antd';

import _                         from 'lodash';

import {
    renderDateInterval, htmlize
}                                from 'utils/text';

import { connect }               from 'react-redux';
import { learn }                 from '../../../store/actions/knowledge';
import { Collection, Highlight } from 'helpers';

import './assets/mixed.less';

/**
 * Display a list of projects
 *
 */
class Mixed extends PureComponent {

    /**
    * Constructor : init an empty state
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge([
            'entities'
        ]).then(this.setState.bind(this));
    }


    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity } = this.props,
            { isLoading } = entity;

        return isLoading;
    }


    /**
    * Render publication date
    *
    * @return JSX
    */
    renderMixedPublicationDate() {
        const { entity } = this.props;

        if (entity.type === 'scidoc') {
            return (
                <td key="publication-date">
                    {entity.date_publication.split('-')[0]}
                </td>
            );
        }

        if (entity.type === 'patent') {
            return (
                <td key="publication-date">
                    {htmlize(entity.EPD)}
                </td>
            );
        }

        if (entity.type === 'clinicaltrial') {
            const start = entity.start ? entity.start.split('-')[0] : null,
                end     = entity.end   ? entity.end.split('-')[0]   : null;

            return (
                <td key="publication-date">
                    {!end ? start : `${start} - ${end}`}
                </td>
            );
        }

        if (entity.type === 'project') {
            return (
                <td key="publication-date">
                    {renderDateInterval(entity.start, entity.end)}
                </td>
            );
        }

        return <td key="publication-date">-</td>;
    }


    /**
    * Render application date
    *
    * @return JSX
    */
    renderMixedApplicationDate() {
        const { entity } = this.props;

        if (entity.type === 'patent') {
            return (
                <td key="application-date">
                    {htmlize(entity.EAPD)}
                </td>
            );
        }

        return <td key="application-date">-</td>;
    }


    /**
    * Render type
    *
    * @returns JSX
    */
    renderMixedType() {
        const { entity }     = this.props,
            { entities }     = this.state,
            entityDefinition = entities ? entities.find((ent) => ent.id === entity.type) : false;

        return (
            <td key="type">
                {entityDefinition ? entityDefinition.label : ''}
            </td>
        );
    }

    /**
    * Render title
    *
    * @returns JSX
    */
    renderMixedTitle() {
        const {
                entity, renderActiveStatus,
                renderModelAction, onClick, renderOpenableIcon,
            }          = this.props,
            {
                clickable: clickableEntity
            }          = entity,
            classNames = ['label'],
            title      = entity.title || entity.label,
            isPatent   = entity.type === 'patent',
            clickable  = clickableEntity || isPatent;

        if (clickable) { classNames.push('clickable'); }

        return (
            <td className={classNames.join(' ')} key="title"
                onClick={clickable ? onClick : null}
            >
                <div className="left">
                    <Highlight
                        TagName="div"
                        className="ellipsis clickable"
                        stripTags
                        title
                    >
                        {title}
                    </Highlight>
                    {clickable && renderOpenableIcon()}
                </div>

                {renderActiveStatus()}
                {(clickable || isPatent) && renderModelAction()}
            </td>
        );
    }


    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const { showExpandedContent, columns } = this.props;

        if (showExpandedContent) {
            return false;
        }

        return (
            <>
                {_.map(columns, column => {
                    const renderFuncName = `render${_.upperFirst(_.camelCase(column.id))}`;

                    if (this[renderFuncName]) {
                        return this[renderFuncName]();
                    }

                    return null;
                })}
            </>
        );
    }


    /**
    * Render related to authorities
    *
    * @returns JSX
    */
    renderRelatedTo() {
        const { entity, onClick } = this.props,
            authorities           = _.isArray(entity.authority) && entity.authority.slice(0, 10);

        return (
            <div className="related-to">
                {authorities && (
                    <div>
                        <div className="label">Authors</div>
                        <Collection
                            entitySource={entity}
                            entities={authorities}
                            type="expert"
                            mode="inline"
                            allowExport={false}
                            onClick={onClick}
                        />
                    </div>
                )}
            </div>
        );
    }


    /**
    * Render loading project row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td className="ellipsis"><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the expandable content
    *
    * @return JSX
    */
    renderExpandedContent() {
        const {
                entity, showExpandedContent
            }                 = this.props,
            abstractIsTooLong = entity.abstract_description && entity.abstract_description.length > 580,
            abstractToRender  = abstractIsTooLong ? `${entity.abstract_description.substr(0, 580)}...`
                : entity.abstract_description;

        if (!showExpandedContent) {
            return false;
        }

        const authorities = _.isArray(entity.authority) && entity.authority.slice(0, 10);

        return (
            <>
                <td colSpan={2} />
                <td colSpan={4}>
                    <div className="abstract">
                        {entity.abstract_description && (
                            <div>
                                <div className="label">Abstract</div>
                                <Highlight
                                    TagName="div"
                                    className="content"
                                    stripTags
                                >
                                    {abstractToRender}
                                </Highlight>
                                {authorities ? <br /> : null}
                            </div>
                        )}
                    </div>
                    {this.renderRelatedTo()}
                </td>
            </>
        );
    }

    /**
    * Render the project row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render project row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Mixed.propTypes = {
    columns            : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    learnKnowledge     : PropTypes.func.isRequired,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    entity             : PropTypes.shape({
        EPD                 : PropTypes.string,
        EAPD                : PropTypes.string,
        abstract_description: PropTypes.string,
        authority           : PropTypes.array,
        date_publication    : PropTypes.string,
        end                 : PropTypes.string,
        isLoading           : PropTypes.bool,
        clickable           : PropTypes.bool,
        label               : PropTypes.string,
        start               : PropTypes.string,
        title               : PropTypes.string,
        type                : PropTypes.string
    }).isRequired,
};

Mixed.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Mixed);

