import React, {useMemo}                 from 'react';
import { connect }                      from 'react-redux';
import PropTypes                        from 'prop-types';
import ImmutablePropTypes               from 'react-immutable-proptypes';

import ModelAction                      from 'helpers/Action/Model';
import { Icon, Flag, Collection }       from 'helpers';
import { Skeleton }                     from 'antd';
import { capitalize, makeStrClassName } from 'utils/text';
import {
    getUserViewItemFromModel,
}                                       from 'store/actions/userView';
import { bookmarkIsInScopeOfSearch }    from 'store/actions/userView/bookmarks';

import './assets/card.less';

/**
* Render the Card
* React Functional Components (Hooks)
*
* @returns JSX
*/
const Card = (props) => { // eslint-disable-line max-lines-per-function
    const {
            entity, sourceModel, onClick, getUserViewItemFromModel, disableModal
        } = props,
        {
            id, isLoading, type, label,
            country, org_city, authority
        }            = entity,
        userViewItem = getUserViewItemFromModel(entity, 'bookmark');
    /**
     *
     */
    const entityBookmarkStatus = () => {
        const { settings }                = sourceModel || {},
            { bookmarkFolderForTags }     = settings || {},
            { bookmarkIsInScopeOfSearch } = props,
            isBookmarked                  = !!userViewItem,
            isInScope                     = isBookmarked && bookmarkIsInScopeOfSearch(userViewItem, bookmarkFolderForTags);

        return {
            isBookmarked: !!userViewItem,
            isInScope
        };
    };

    const { isBookmarked, isInScope } = useMemo(entityBookmarkStatus, [userViewItem]);

    /**
     * Render action for orgunuit card type
     */
    const renderActions = () => {
        return entity.id && entity.type === 'orgunit' && (
            <ModelAction entity={entity} />
        );
    };

    /**
     * Render card icons (logo / bookmark icon)
     */
    const renderIcons = () => {
        return(
            <>
                <Icon
                    id="bookmarked"
                    className="icon-bookmarked"
                    theme={isInScope ? 'filled' : 'outlined'}
                    color="var(--bookmarked-color)"
                    width={18}
                    title="is bookmarked"
                />
                <Icon
                    id={type}
                    folder="/entities/"
                    className="entity-logo"
                    orgunitLogo={id}
                    discShaped
                    borderSize={1}
                    borderColor="var(--border-color)"
                    height={65}
                />
                <div className="country">
                    <Flag
                        iso={country}
                        height={14}
                    />
                </div>
            </>
        );
    };


    /**
     *
     * @returns
     */
    const renderAffiliations = () => {
        const hasAuthorities = (authority || []).length > 0,
            limit            = 2;

        return hasAuthorities && (
            <div className="affiliations">
                <Collection
                    onClick={onClick}
                    mode="inline"
                    entities={authority.slice(0, limit) || []}
                    allowExport={false}
                />
            </div>
        );
    };

    /**
     * Render default card content
     *
     * @returns
     */
    const renderDefaultContent = () => {
        return (
            <>
                { label && <p className="label" title={label}><span>{label}</span></p> }
                { org_city && <span className="city">{org_city}</span> }
            </>
        );
    };

    /**
     * Render expert card content
     *
     * @returns
     */
    const renderExpertContent = () => {
        const {
                person_firstname,
                person_lastname
            }           = entity,
            firstName   = capitalize((person_firstname || '').toLowerCase()),
            lastName    = capitalize((person_lastname || '').toLowerCase());
        return (
            <>
                { label && (
                    <p className="label" title={label}>
                        <span>{firstName} {lastName}</span>
                    </p>
                )}
                { renderAffiliations() }
            </>
        );
    };

    /**
    * Render the card
    *
    * @return JSX
    */
    const render = () => {
        const classNames = [
            'card',
            type,
            {hoverable: !disableModal},
            {clickable: !!onClick},
            {bookmarked: isBookmarked},
            {'in-scope': isInScope},
            `${isLoading ? 'loading': 'loaded'}`,
        ];

        return (
            <div className={makeStrClassName(classNames)} onClick={onClick}>
                <div className="top">
                    {renderActions()}
                    {renderIcons()}
                </div>
                <div className="bottom">
                    {
                        isLoading
                            ? <Skeleton title={false} active />
                            : type == 'expert' ? renderExpertContent() : renderDefaultContent()
                    }
                </div>
            </div>
        );
    };

    // Call render
    return render();
};

Card.propTypes = {
    entity         : PropTypes.object,
    onClick        : PropTypes.func,
    sourceModel    : PropTypes.object,
    disableModal   : PropTypes.bool,
    bookmarks      : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarkFolders: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

Card.displayName = 'Card';

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const bookmarks     = state.getIn(['userView', 'bookmark', 'list']),
        bookmarkFolders = state.getIn(['userView', 'bookmark_folder', 'list']);

    return { bookmarkFolders, bookmarks };
};


export default connect(mapStateToProps, {
    getUserViewItemFromModel,
    bookmarkIsInScopeOfSearch,
})(Card);
