import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { Icon, CssLoader }  from 'helpers';
import { Tooltip }          from 'antd';
import { makeStrClassName } from 'utils/text';

// CSS
import './assets/i-button.less';

/**
 * Insight generic loader
 *
 * @param Component content A single element
 */
class IButton extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render');

        this.state = {
        };
    }

    /**
     * Get style variable for css
     *
     * @returns object
     */
    getStyleVar(variables) {
        const varToMap = _.omitBy(variables, _.isNull);

        return _.mapKeys(varToMap, (value, key) => `--i-button-${_.kebabCase(key)}`);
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    renderCount() {
        const { count } = this.props;

        if (!count) { return null; }

        return (
            <div className="count">
                <span>
                    {count}
                </span>
            </div>
        );
    }

    /**
     * Render the component
     *
     * @returns JSX
     */
    render() {    // eslint-disable-line max-lines-per-function
        const {
                label,
                className,
                onClick,
                onMouseEnter,
                onMouseLeave,
                labelColor,
                bgColor,
                iconColor,
                iconSize,
                icon,
                href,
                target,
                rel,
                disabled,
                loading,
                hoverLabelColor,
                hoverBgColor,
                tooltip,
                tooltipOffset,
                tooltipArrow,
                tooltipTheme,
                fontSize,
                iconTheme,
                reverse
            }             = this.props,
            { active }    = this.props,
            classNames    = [
                'i-button',
                className,
                icon && ' has-icon',
                label && ' has-label',
                disabled && 'disabled',
                loading && 'loading',
                active && ' is-active',
                reverse && 'reverse'
            ],
            computedClass     = classNames.filter(name => !!name).join(' '),
            overlayClassNames = ['i-button--tooltip', tooltipTheme],
            style             = this.getStyleVar({
                labelColor     : disabled ? '#b7b7b7' : labelColor,
                bgColor        : disabled ? '#f8f8f8' : bgColor,
                hoverLabelColor: hoverLabelColor || labelColor,
                hoverBgColor   : hoverBgColor || bgColor,
                fontSize       : `${fontSize}px`,
                iconColor
            }),
            buttonIcon   = loading
                ? (
                    <CssLoader type="ring" size={fontSize}
                        thickness={1} color={labelColor || '#ddd'}
                    />
                )
                : icon && (
                    <Icon key={icon} type={icon}
                        height={iconSize || fontSize}
                        theme={iconTheme}
                    />
                );

        // TODO: manage Icon with antD type

        return (
            <Tooltip
                overlayClassName={makeStrClassName(overlayClassNames)}
                placement="bottom" // ? If placement is changed, change align offset properties.
                title={tooltip}
                align={{offset: tooltipOffset}}
                arrow={tooltipArrow}
            >
                <div
                    className={computedClass}
                    onClick={(!disabled && !loading && onClick) || undefined}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={style}
                    key={`${label}${icon}${href}`}
                >
                    <a href={(!disabled && !loading && href) || undefined} target={target}
                        rel={rel}
                    >
                        {buttonIcon}
                        <span className="button-label">
                            {label}
                        </span>
                    </a>
                    {this.renderCount()}
                </div>
            </Tooltip>
        );
    }

}


// Validate properties for current Component
IButton.propTypes = {
    label          : PropTypes.string,
    className      : PropTypes.string,
    icon           : PropTypes.string,
    iconTheme      : PropTypes.string,
    iconSize       : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iconColor      : PropTypes.string,
    labelColor     : PropTypes.string,
    bgColor        : PropTypes.string,
    href           : PropTypes.string,
    target         : PropTypes.string,
    rel            : PropTypes.string,
    count          : PropTypes.number,
    fontSize       : PropTypes.number,
    loading        : PropTypes.bool,
    disabled       : PropTypes.bool,
    active         : PropTypes.bool,
    reverse        : PropTypes.bool,
    onClick        : PropTypes.func,
    onMouseEnter   : PropTypes.func,
    onMouseLeave   : PropTypes.func,
    hoverLabelColor: PropTypes.string,
    hoverBgColor   : PropTypes.string,
    tooltip        : PropTypes.string,
    tooltipOffset  : PropTypes.array,
    tooltipArrow   : PropTypes.bool,
    tooltipTheme   : PropTypes.oneOf(['dark', 'light']),
};


// Declare default properties
IButton.defaultProps = {
    labelColor     : null,
    bgColor        : null,
    icon           : null,
    iconTheme      : null,
    disabled       : false,
    loading        : false,
    active         : false,
    reverse        : false,
    onClick        : () => {},
    onMouseEnter   : () => {},
    onMouseLeave   : () => {},
    hoverLabelColor: null,
    hoverBgColor   : null,
    tooltip        : null,
    tooltipArrow   : true,
    tooltipOffset  : [0, -2],
    tooltipTheme   : 'dark',
    fontSize       : 14,
};


export default IButton;
