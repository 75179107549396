import React, { PureComponent }      from 'react';
import PropTypes                     from 'prop-types';
import { connect }                   from 'react-redux';
import { learn }                     from 'store/actions/knowledge';

import _                             from 'lodash';
import { Skeleton, Tooltip }         from 'antd';

import {
    htmlize, stripTags, isUrl, str2DomFormat
}                                    from 'utils/text';
import { Icon, Flag }                from 'helpers';

import './assets/orgunitNetworkNode.less';

/**
* Render Orgunit entities
*
*/
class OrgunitNetworkNode extends PureComponent {

    /**
    * Prepare the component to be used.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'openCollaboration');

        this.state = {};
    }

    /**
    * Load the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['orgClasses', 'countries', 'tags'])
            .then(this.setState.bind(this));
    }

    /**
    * Get the name of a country given its ISO2 code
    *
    * @return string
    */
    getCountryName(isoCode) {
        const { countries } = this.state;

        if (!countries) {
            return null;
        }

        const countryMdl = countries.find((country) => country.id === isoCode);

        if (!countryMdl) {
            return 'Unknown';
        }

        return countryMdl.label;
    }

    /**
    * Returns whether the entity is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Get the name of a country given its ISO2 code
    *
    * @return string
    */
    renderOrgClass(orgClassId) {
        const { orgClasses } = this.state;

        if (!orgClasses) {
            return false;
        }

        const orgClassMdl = orgClasses.find((orgClass) => orgClass.id === orgClassId);

        if (!orgClassMdl) {
            return false;
        }

        return orgClassMdl.label;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const { showExpandedContent, columns } = this.props;

        if (showExpandedContent) {
            return null;
        }

        // Real row display
        return (
            <>
                {_.map(columns, column => {
                    const renderFuncName = `render${_.upperFirst(_.camelCase(column.id))}`;

                    if (this[renderFuncName]) {
                        return this[renderFuncName]();
                    }

                    return null;
                })}
            </>
        );
    }

    /**
    * Render Name
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeName() {
        const {
                entity, renderActiveStatus,
                renderModelAction, onClick, renderOpenableIcon,
            }                        = this.props,
            { clickable, label, id } = entity,
            classNames               = ['label'];

        if (clickable) { classNames.push('clickable'); }

        return (
            <td key="name" className={classNames.join(' ')}
                onClick={clickable ? onClick : null}
            >

                <div className="left">
                    <Icon
                        id="orgunit"
                        folder="/entities/"
                        width={18}
                        height={18}
                        discShaped
                        orgunitLogo={id}
                        borderSize={1}
                        borderColor="#888"
                        style={{top: '0px'}}
                    />
                    <span className="ellipsis" title={label}>{label}</span>
                    {clickable && renderOpenableIcon()}
                </div>
                {renderActiveStatus()}
                {clickable && renderModelAction()}
            </td>
        );
    }

    /**
    * Render Aka
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeAka() {
        const { entity } = this.props;

        return (
            <td key="aka" className="ellipsis">
                {entity.org_aka}
            </td>
        );
    }

    /**
    * Render Aka
    *
    * @return JSX
    */
    renderAkaContent() {
        const { entity } = this.props;

        if (!entity.org_aka) {
            return null;
        }

        return (
            <>
                <div className="label">A K A</div>
                <div className="content content-ellipsis" style={{ marginBottom: 10}}>
                    {entity.org_aka}
                </div>
            </>
        );
    }

    /**
    * Render tags for current bookmark
    *
    * @param object entity
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeClassification() {
        const {
                bookmark,
                entity, compiledTags,
            }                = this.props,
            { tags }          = this.state,
            { id }           = entity,
            currentTags      = tags?.filter(tag => bookmark && bookmark.tags.includes(tag.id));

        if (!bookmark || !compiledTags || !currentTags) {
            return <td key="classification" />;
        }

        return (
            <td key="classification" className="tags">
                {currentTags.map(tag => {
                    const size      = 20,
                        tagsInScope = !compiledTags || compiledTags[tag.id] && compiledTags[tag.id].includes(id),
                        style       = {
                            color   : tag.color,
                            height  : size,
                            width   : size,
                            fontSize: 20
                        };

                    return (
                        <Icon
                            key={tag.id}
                            type={tag.icon}
                            color={tag.color}
                            theme={tagsInScope ? 'filled' : 'outlined'}
                            style={style}
                            height={size}
                            width={size}
                            title={tag.label}
                            className="icon"
                        />
                    );
                })}
            </td>
        );
    }

    /**
    * Render Type
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeType() {
        const { entity } = this.props;

        return (
            <td key="type" className="ellipsis">
                {this.renderOrgClass(entity.org_class)}
            </td>
        );
    }

    /**
    * Render Acronym
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeAcronym() {
        const { entity } = this.props;

        return (
            <td key="acronym" className="ellipsis">
                {entity.org_acronym}
            </td>
        );
    }

    /**
    * Render Country
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeCountry() {
        const { entity } = this.props;

        return (
            <td key="country" className="ellipsis">
                {entity.country && (
                    <>
                        <Flag iso={entity.country} height={15}
                            className="flag" borderColor="#dddddd"
                            borderSize={1}
                        />
                        <span className="country-label">{this.getCountryName(entity.country)}</span>
                    </>
                )}
            </td>
        );
    }

    /**
    * Render Collaborations number or collaboration open button
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeRelation() {
        const { entity, sourceModel }       = this.props,
            { type: sourceModelType }       = sourceModel,
            { type: entityType, clickable } = entity,
            { label }                       = entity,
            isCollaborationInOrgunits       = sourceModelType === 'orgunit' && entityType === 'orgunit',
            canClickOnRelation              = clickable && isCollaborationInOrgunits,
            classNames                      = ['ellipsis'];

        if (canClickOnRelation) {
            classNames.push('clickable');
        }

        return (
            <td key="relation" className={classNames.join(' ')}
                onClick={canClickOnRelation ? this.openCollaboration : undefined}
                data-qa-key={str2DomFormat(`${label} relations`)}
            >
                {canClickOnRelation ? (
                    <Icon id="collection-open" width={12}
                        height={12}
                    />
                ) : entity.collaborators}
            </td>
        );
    }

    /**
    * Render Nb Doc
    *
    * @return JSX
    */
    renderNetworkOrgunitNodeDocuments() {
        const { entity } = this.props;

        return (
            <td key="documents" className="ellipsis">
                {entity.coworks}
            </td>
        );
    }


    /**
    * Render concepts / topics
    *
    * @return JSX
    */
    renderTopics() {
        const { entity } = this.props,
            topics       = entity.topics || false,
            joinTopics   = topics ? topics.join(', ') : false;

        if (!topics) {
            return false;
        }

        return (
            <div key="topics" className="topics">
                <span className="label">
                    Mainly works on
                </span><br />
                <span className="content">
                    <Tooltip
                        key={joinTopics}
                        mouseEnterDelay={2}
                        placement="left"
                        title={htmlize(joinTopics)}
                    >
                        {joinTopics}
                    </Tooltip>
                </span>
            </div>
        );
    }

    /**
    * Render the description
    *
    * @return JSX
    */
    renderDescription() {
        const { entity } = this.props,
            description  = _.get(entity, 'descriptions.presentation.description', false);

        if (!description) {
            return false;
        }

        return (
            <>
                <div className="label">Presentation</div>
                <div className="content content-ellipsis">
                    {stripTags(description)}
                </div>
            </>
        );
    }


    /**
     * Open collaboration entity
     */
    openCollaboration() {
        const {onClick, entity, sourceModel } = this.props,
            relationId               = sourceModel && entity && `${sourceModel.id}&${entity.id}`,
            relationLabel            = `${sourceModel.label} & ${entity.label}`,
            collabEntity             = {
                id       : relationId,
                type     : 'orgunit_collaboration',
                label    : relationLabel,
                model    : sourceModel,
                withModel: entity,
            };

        if (onClick && relationId) {
            onClick(collabEntity);
        }
    }

    /**
     * Open Network and Influence page of the entity
     */
    openNetworkAndInfluence() {
        // TODO: On collaboration network row, click on the number of relation open the network and influence page of the entity
    }

    /**
    * Render the expanded content for the list
    *
    * @return JSX
    */
    renderExpandedContent() { // eslint-disable-line  max-lines-per-function
        const {
                entity,
                showExpandedContent,
            }                         = this.props,
            webUrl                    = _.get(entity, 'eAddresses.website', false);

        if (!showExpandedContent) { return false; }
        return (
            <>
                <td />
                <td className="logo" colSpan="2">
                    <Icon
                        id="orgunit"
                        folder="/entities/"
                        width={80}
                        height={80}
                        discShaped
                        orgunitLogo={entity.id}
                        borderSize={2}
                        borderColor="#888"
                    />
                </td>
                <td className="abstract" colSpan="3">
                    {this.renderAkaContent()}
                    {this.renderDescription()}
                </td>
                <td className="other-info" colSpan="3">
                    {webUrl && isUrl(webUrl) && (
                        <div className="web">
                            <span className="label">Web</span><br />
                            <span className="content">
                                <a href={webUrl} target="_blank"
                                    rel="noopener noreferrer"
                                >{webUrl}
                                </a>
                            </span>
                        </div>
                    )}
                    {this.renderTopics()}
                </td>
            </>
        );
    }

    /**
    * Render loading patent row
    *
    * @return JSX
    */
    renderLoading() {
        const {
                showExpandedContent,
                columns,
            }               = this.props,
            numberOfColumns = columns.length + (showExpandedContent ? 2 : 0);

        if (!this.isLoading()) {
            return false;
        }

        return (
            _.map(_.range(numberOfColumns), range => {
                return (
                    <td key={range}>
                        <Skeleton loading active
                            paragraph={false}
                        />
                    </td>
                );
            })
        );
    }

    /**
    * Render the patent row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render patent row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

OrgunitNetworkNode.propTypes = {
    bookmark: PropTypes.shape({
        tags: PropTypes.array,
    }),
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    entity : PropTypes.shape({
        clickable    : PropTypes.bool,
        collaborators: PropTypes.number,
        country      : PropTypes.any,
        coworks      : PropTypes.number,
        entity       : PropTypes.object,
        id           : PropTypes.string,
        isLoading    : PropTypes.bool,
        label        : PropTypes.string,
        org_acronym  : PropTypes.any,
        org_aka      : PropTypes.any,
        org_class    : PropTypes.any,
        topics       : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
        type         : PropTypes.string,
    }).isRequired,
    onClick            : PropTypes.func,
    renderActiveStatus : PropTypes.func,
    renderModelAction  : PropTypes.func,
    renderOpenableIcon : PropTypes.func,
    learnKnowledge     : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    sourceModel        : PropTypes.shape(),
    compiledTags       : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

OrgunitNetworkNode.defaultProps = {
    showExpandedContent: false,
    onClick            : () => {},
    renderOpenableIcon : () => {},
    renderActiveStatus : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(OrgunitNetworkNode);

