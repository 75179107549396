import Immutable  from 'immutable';
import * as types from '../../actions/types/navigation';

/**
* Manage the navigation/analysesFilters slice in the store
*
* @return navigation As a fresh Immutable Map
*/
export const NavigationNodesPreferences = (navigation, action) => {
    const { type, payload } = action;

    // Switch over actions
    switch (type) {
        case types.STORE_NODE_PREFERENCES:
            return storeNodePreferences(navigation, payload);
    }

    // No action found (The sub reducer must return null)
    return null;
};

/**
 * Store nodes preferences
 *
 * @param payload
 */
const storeNodePreferences = (navigation, payload) => {
    const { nodeId, preferences } = payload,
        nodesPreferences          = navigation.get('nodesPreferences') || new Immutable.Map(),
        newNodesPreferences       = nodesPreferences.set(nodeId, {...(nodesPreferences?.get(nodeId)), ...preferences});

    return navigation.set('nodesPreferences', newNodesPreferences);
};
