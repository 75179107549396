// Import libs
import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import Header                   from './List/Header';
import Entity                   from '../Entity';
import Icon                     from '../Icon';
import { elementIsOverflowing } from '../../core/utils/dom';
import { learn }                from 'store/actions/knowledge';

// Import display CSS
import './assets/list.less';

const expandableTypes = [
    'orgunit',
    'network_orgunit_node',
    'expert',
    'network_expert_node',
    'patent',
    'scidoc',
    'project',
    'clinicaltrial',
    'mixed',
];

/**
* List: display a list of entities
*
*/
class List extends PureComponent {

    /**
    * Prepare the component to be used.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onToggleRow', 'renderEntity');

        this.state = {
            expandedRows: [],
            columns     : false,
        };
    }


    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['model-columns']).then(({ modelColumns }) => {
            this.setColumns(modelColumns);
        });
    }

    /**
    * Commit phase
    *
    */
    componentDidUpdate() {
        const { showAllEntities } = this.props;

        if (!showAllEntities) {
            this.cutOverflowingRows();
        }
    }

    /**
    * Callback function to pass at the children component
    *
    * @return bool
    */
    isExpandable = (typeFromEntity) => {
        const {
                type, oneClickOpen, showAllEntities
            }          = this.props,
            trueType   = type || typeFromEntity,
            expandable = showAllEntities && expandableTypes.includes(trueType) && !oneClickOpen;

        return expandable;
    }

    /**
    * On toggle row
    *
    * @return void
    */
    onToggleRow(entityId) {
        const {
                maxExpandedRows,  oneClickOpen, onClick,
                entities,
            }                  = this.props,
            { expandedRows }   = this.state,
            entity             = entities.find((e) => e.id === entityId),
            entityIsOpen       = expandedRows.indexOf(entityId) !== -1,
            cumuledExpandedRow = entityIsOpen
                ? expandedRows.filter((value) => value !== entityId) : expandedRows.concat([entityId]),
            singleExpandedRow  = entityIsOpen ? [] : [entityId];

        if (oneClickOpen && entity) {
            onClick(entity);
            return;
        }

        this.setState({
            expandedRows: maxExpandedRows === 1 ? singleExpandedRow : cumuledExpandedRow
        });
    }

    /**
    * Cut overflowing rows
    *
    */
    cutOverflowingRows() {
        const {  reference }  = this.props,
            domNode           = reference.current,
            testingParentNode = domNode ? domNode.parentNode.parentNode : null,
            rowsNodes         = domNode ? domNode.querySelectorAll('tbody > tr.row, tbody > tr.entity-row-separator') : [],
            headerNode        = domNode ? domNode.parentNode.parentNode.querySelector('thead > tr') : null,
            offsetHeight      = headerNode ? headerNode.clientHeight : 0,
            rowsVisibility    = {};

        if (testingParentNode && testingParentNode.clientHeight <= offsetHeight) { return; }

        // Put all visible
        _.each(rowsNodes, (rowNode, index) => {
            _.set(rowNode, 'style.display', null);
            rowsVisibility[index] = !elementIsOverflowing(
                rowNode,
                { parentElement: testingParentNode, offsetHeight }
            );
        });

        // Hide overflowing elements
        _.each(rowsNodes, (rowNode, index) => {
            _.set(
                rowNode,
                'style.display',
                rowsVisibility[index]
                    ? null
                    : 'none'
            );
        });

        this.nbEntitiesVisible =  _.filter(rowsVisibility, (visible) => visible).length;
    }

    /**
    * Return columns definition from entities knowledge
    *
    * @param {array} columns
    *
    * @return array
    */
    getEntityColumns(columns) {
        const { type }       = this.props,
            modelColumns     = columns?.toArray().filter(column => column.model_type === type),
            sortModelColumns = _.sortBy(modelColumns, 'rank');

        // Can't find columns from non existent knowledge
        if (!sortModelColumns) {
            return false;
        }

        // TODO : Remove this exception in order to show gauges !
        if (type === 'orgunit') {
            const columnsToRemove = ['scidoc', 'patent', 'clinicaltrial', 'project'];

            return sortModelColumns.filter(
                column => !columnsToRemove.includes(column.id) ?? column
            ) || false;
        }

        return sortModelColumns || false;
    }

    /**
    * Set columns
    *
    * @param {array} columnFromKnowledge
    *
    * @returns {array}
    */
    setColumns(columnFromKnowledge) {
        const entityColumns = this.getEntityColumns(columnFromKnowledge),
            composeColumns  = this.composeColumns(entityColumns);

        this.setState({
            columns: composeColumns,
        });
    }

    /**
    * Get filtered columns
    *
    * For filter columns, set it in database field "configuration" as below:
    * {"columns":{"use":["Scientific work","Invention","Clinical study","Project"]}}
    * {"columns":{"remove":["Name", "Project", "Acronym"]}}
    * {"columns":{"remove":["Name", "Project"], "resize": ["Country"]}}
    *
    * If "resize" is defined, columns inside will be resize otherwise all columns will be
    *
    * @param {array} baseColumns
    * @param {object} columnsAction
    *
    * @returns {array}
    */
    composeColumns(baseColumns) {
        const {
                columns: columnsActionsFromProps
            }              = this.props,
            columnsActions = columnsActionsFromProps || {};

        let columns = baseColumns;

        // To always trigger a resize
        if (!columnsActions?.resize) {
            columnsActions.resize = [];
        }

        /**
        * Reorder actions
        * Resizing columns with defined column(s) should always be done last
        * You must first filter column(s) to use or remove
        */
        const orderedActions      = _.orderBy(_.keys(columnsActions), action => action === 'resize' ? 1 : 0),
            orderedColumnsActions = _.zipObject(orderedActions, _.at(columnsActions, orderedActions));

        // Perform magic actions
        for (const action in orderedColumnsActions) {
            const actionFuncName = `columnsTo${_.upperFirst(action)}`;

            columns = this[actionFuncName](columns, orderedColumnsActions[action]);
        }

        return columns;
    }

    /**
    * Perform columns to use
    *
    * @param {array} columns
    * @param {array} columnsAction
    *
    * @returns {array}
    */
    columnsToUse(columns, columnsAction) {
        const hasColumns = columnsAction?.length > 0;

        if (!hasColumns) { return columns; }

        // TODO: add column application date in element configuration (remove)
        return _.filter(columns,
            column => columnsAction?.includes(column.label)
        );
    }

    /**
    * Perform columns to remove
    *
    * @param {array} columns
    * @param {array} columnsAction
    *
    * @returns {array}
    */
    columnsToRemove(columns, columnsAction) {
        const hasColumns = columnsAction?.length > 0;

        if (!hasColumns) { return columns; }

        return _.filter(columns,
            column => !columnsAction?.includes(column.label)
        );
    }

    /**
    * Calculate column's exact width
    *
    * If columns are filtered, they can be resize equally if their widths are set in percentage
    * Otherwise a list of columns can be resized with a resize array call as below:
    * {"columns":{"remove":["Scientific work","Invention","Clinical study","Project"]}, "resize": ["Name", "Type"]}
    *
    * @param {array} headerColumns
    * @param {array} columnsToResize
    *
    * @returns {array}
    */
    columnsToResize(headerColumns, columnsToResize) {
        const totalWidth           = _.reduce(headerColumns, (accumulator, current) => {
                return accumulator + parseFloat(_.replace(current.style?.width, '%', ''));
            }, 0),
            remainingWidth         = totalWidth < 100 ? 100 - totalWidth : 0,
            columnsResizable       = _.filter(headerColumns, col => {
                return columnsToResize.length >= 0 && _.includes(columnsToResize, col.label)
                    || columnsToResize.length === 0;
            }),
            columnsResizableLength = columnsResizable.length,
            widthPerColumns        = columnsResizableLength > 0 ? (remainingWidth / columnsResizableLength) : 0,
            columnsResizableLabel  = _.map(columnsResizable, 'label'),
            resizedColumns         = this.resizeColumns(columnsResizableLabel, headerColumns, widthPerColumns);

        return resizedColumns;
    }

    /**
    * Resize eligible columns
    *
    * @param {array} columnsResizable
    * @param {array} headerColumns
    * @param {number} headerColumns
    *
    * @returns {array}
    */
    resizeColumns(columnsResizable, headerColumns, widthPerColumns) {
        const resizedColumns = _.map(headerColumns, col => {
            const { style }  = col,
                { width }    = style || {},
                isPercent    = _.includes(width, '%'),
                widthNumber  = _.replace(width, '%', '') || 0,
                newWidth     = `${parseFloat(widthNumber) + widthPerColumns}%`,
                resizable    = columnsResizable.includes(col.label);

            if (!isPercent || widthNumber === 0 || !resizable) {
                return col;
            }

            return {
                ...col,
                style: {
                    ...style,
                    width: newWidth,
                }
            };
        });

        return resizedColumns;
    }

    /**
    * Render the list.
    *
    * @return JSX
    */
    renderNoData() {
        return (
            <div className="list noData">
                <div>
                    <Icon id="hand-holding-up-a-magnifier" height={30}
                        color="var(--insight-color)"
                    />
                    <div style={{ color: 'var(--insight-color)' }}>
                        We did not found anything
                    </div>
                </div>
            </div>
        );
    }

    /**
    * Render the list.
    *
    * @return JSX
    */
    renderFooter() {
        const { totalIsReached } = this.props;

        return !totalIsReached ? null : (
            <tfoot>
                <tr><td /></tr>
            </tfoot>
        );
    }

    /**
    * Render the entity component
    *
    * @param {object} object
    *
    * @returns {JSX}
    */
    renderEntity(entity, index) {
        const {
                tags, color, model,
                type, onClick, allowExport,
                onSelectEntity, selectedEntities,
                context, disabledEntities, compiledTags,
            }                             = this.props,
            {
                expandedRows, columns,
            }                             = this.state,
            { isLoading, label, id, url } = entity,
            key                           = id || label || url || `skeleton ${index}`,
            rowIsExpanded                 = !isLoading && expandedRows.includes(entity.id),
            disabled                      = disabledEntities.includes(entity.id),
            isSelected                    = selectedEntities.includes(entity);

        // Using url for news in homepage
        return (
            <Entity
                key={key}
                tags={tags}
                columns={columns}
                compiledTags={compiledTags}
                color={color}
                render="row"
                entity={entity}
                entityType={type}
                expandRow={rowIsExpanded}
                toggleRow={this.onToggleRow}
                onClick={onClick}
                allowExport={allowExport}
                onSelectEntity={onSelectEntity}
                isSelected={isSelected}
                disabled={disabled}
                sourceModel={model}
                context={context}
                isExpandable={this.isExpandable}
            />
        );
    }

    /**
    * Render the list.
    *
    * @return JSX
    */
    render() {
        const {
                type, entities,
                allowExport, reference,
                selectedEntities, toggleAllSelection,
            }                      = this.props,
            { columns }            = this.state;

        if (!entities.length) { return this.renderNoData(); }

        return (
            <table className={`list loaded ${type}`} ref={reference}>
                {/* Render table header */}
                <thead>
                    <Header
                        type={type}
                        columns={columns}
                        allowExport={allowExport}
                        toggleAllSelection={toggleAllSelection}
                        entities={entities}
                        selectedEntities={selectedEntities}
                        isExpandable={this.isExpandable}
                    />
                </thead>
                {/* Render table body */}
                <tbody>
                    {entities.length > 0 && _.map(
                        entities,
                        (entity, index) => this.renderEntity(entity, index)
                    )}
                </tbody>
                {this.renderFooter()}
            </table>
        );
    }

}

List.propTypes = {
    entities          : PropTypes.arrayOf(PropTypes.object).isRequired,
    maxExpandedRows   : PropTypes.number,
    type              : PropTypes.string.isRequired,
    totalIsReached    : PropTypes.bool.isRequired,
    columns           : PropTypes.object,
    learnKnowledge    : PropTypes.func.isRequired,
    oneClickOpen      : PropTypes.bool,
    showAllEntities   : PropTypes.bool,
    allowExport       : PropTypes.any,
    color             : PropTypes.any,
    onClick           : PropTypes.func,
    onSelectEntity    : PropTypes.any,
    disabledEntities  : PropTypes.array,
    selectedEntities  : PropTypes.any,
    tags              : PropTypes.any,
    toggleAllSelection: PropTypes.any,
    model             : PropTypes.object,
    context           : PropTypes.object,
    compiledTags      : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    reference         : PropTypes.shape({
        current: PropTypes.shape({
            parentNode: PropTypes.shape({
                parentNode: PropTypes.shape({
                    querySelector: PropTypes.func
                })
            }),
            querySelectorAll: PropTypes.func
        })
    }),
};

List.defaultProps = {
    maxExpandedRows: 1,
    columns        : null,
    oneClickOpen   : false,
    showAllEntities: true,
    entities       : [],
};

export default connect(null, {
    learnKnowledge: learn,
})(List);
