/**
 * Display the dashboard of the App
 *
 * @return Component
 */

import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import {
    bindAll, last, first
}                           from 'lodash';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { emitEvent }        from 'store/actions/sockets';
import {  Icon, Row, Col }  from 'helpers';
// Import Renderer
import Search               from '../Views/Search';
import EntityModule         from '../Views/Entity';

const Renderer = {
    query                : Search,
    project              : EntityModule,
    patent               : EntityModule,
    clinicaltrial        : EntityModule,
    scidoc               : EntityModule,
    orgunit              : EntityModule,
    expert               : EntityModule,
    orgunit_collaboration: EntityModule,
    news_link            : EntityModule,
};

/**
* The search module
*
*/
class Item extends Component {

    /**
    * Initialize the Entity display
    */
    constructor(props) {
        super(props);

        bindAll(this, 'navigateTo', 'onClick', 'registerMetadata', 'triggerAction', 'close', 'emitElementAction');

        this.state = {
            hasBeenActivated   : props.active,
            currentPathname    : null,
            elements           : null,
            viewActions        : {},
            previousActiveState: false,
        };

        this.styles = null;

        this.headerRef = React.createRef();
    }

    /**
    * Get state for props and previous state
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { active, node }                              = nextProps,
            {
                previousActiveState, hasBeenActivated, previousModelId
            }     = prevState,
            model = Item.getItemModel(node);


        return {
            previousModelId      : model?.id,
            mustEmitElementAction: active && (previousActiveState !== active || previousModelId !== model.id),
            previousActiveState  : active && hasBeenActivated,
        };
    }


    /**
    * Component has just been rendered
    *
    * @return void
    */
    componentDidUpdate() {
        const { active }         = this.props,
            { hasBeenActivated } = this.state;

        if (!hasBeenActivated && active) {
            this.setState({ hasBeenActivated: true });
        }
    }

    /**
    * Trigger an action for the current view
    *
    * @param string action The action name to trigger
    *
    * @return mixed
    */
    triggerAction(action) {
        const { viewActions } = this.state;

        if (viewActions[action]) {
            return viewActions[action]();
        }

        return false;
    }


    /**
    * It's emitting an user-action event to the server
    *
    * @param objet The element element trigger
    */
    emitElementAction(element) {
        const { node }                = this.props,
            { mustEmitElementAction } = this.state,
            { category, key }         = element,
            model                     = Item.getItemModel(node),
            { type }                  = model,
            rawModule                 = category?.replace('Area/', '').toLowerCase() || type,
            module                    = rawModule === 'analyse' ? 'analysis' : rawModule;

        if (mustEmitElementAction) {
            this.emitEvent('open', module, key);
        }
    }


    /**
    * Return the renderer
    *
    * @return Component
    */
    getRenderer() {
        const { node } = this.props,
            itemModel  = Item.getItemModel(node),
            { type }   = itemModel || {},
            View       = Renderer[type] || false;

        if (!View) {
            throw new Error('Browser/Accordion/Item: View does not exists.');
        }

        return View;
    }

    /**
    * Navigate to tab
    *
    * @param mixed pathOrModel  The path to add/switch
    *
    * @return void
    */
    navigateTo(pathOrModel, collection) {
        const { index, navigateTo } = this.props;

        return navigateTo(pathOrModel, collection || null, index);
    }

    /**
    * Register view actions to the current state
    *
    * @param object actions A set of callback indexed by a name
    *
    * @return self
    */
    registerMetadata(metadatas) {
        const { registerMetadata, index } = this.props;

        if (registerMetadata) {
            registerMetadata(index, metadatas);
        }
    }


    /**
     * Make a collaboration model
     *
     * @return Object
     */
    static getItemModel(node) {
        const { model, withModel } = node;

        if (!withModel) {
            return model;
        }

        return {
            id   : `${model.id}&${withModel.id}`,
            type : `${model.type}_collaboration`,
            label: `${model.label} & ${withModel.label}`,
            model,
            withModel
        };
    }

    /**
    * Render the item view with components
    *
    * @return Component
    */
    renderActiveView() {
        const {
                node,
                context,
                module,
                active,
                entities,
                elements,
                bookmarks,
                tags,
                resources
            }         = this.props,
            {
                hasBeenActivated,
            }         = this.state,
            View      = this.getRenderer(),
            itemModel = Item.getItemModel(node),
            key       = `${module}${itemModel && itemModel.id}`;

        return (
            <View
                key={key}
                node={node}
                module={module}
                model={itemModel}
                context={context}
                active={active}
                hasBeenActivated={hasBeenActivated}
                emitElementAction={this.emitElementAction}
                elements={elements}
                entities={entities}
                resources={resources}
                bookmarks={bookmarks}
                tags={tags}
                navigateTo={this.navigateTo}
                registerMetadata={this.registerMetadata}
            />
        );
    }


    /**
    * Triggered when the user click on item
    *
    * @return void
    */
    onClick() {
        const { index, active, onClick } = this.props;

        // Disabled click on actived items
        if (active || !onClick) {
            return;
        }

        onClick(index);
    }

    /**
    * Render actions area of the header
    *
    * @return JSX
    */
    renderActions() {
        const { metadata } = this.props;

        return metadata && metadata.actions
            ? (
                <Col className="item-actions">
                    {metadata.actions}
                </Col>
            ) : false;
    }

    /**
    * Is there a next element in collection ?
    *
    * @param object node The node to get entity
    *
    * @return Immutable.List
    */
    hasNextEntity() {
        const { node }            = this.props,
            { model, collection } = node,
            lastEntity            = node.collection ? last(collection) : null;

        return lastEntity && model.id !== lastEntity.id;
    }

    /**
    * Is there a next element in collection ?
    *
    * @param object node The node to get entity
    *
    * @return Immutable.List
    */
    hasPreviousEntity() {
        const { node }            = this.props,
            { model, collection } = node,
            firstEntity           = node.collection ? first(collection) : null;

        return firstEntity && model.id !== firstEntity.id;
    }


    /**
     * Send a Saas emit event
     *
     * @param {string} name
     */
    emitEvent(name, moduleFromParam, key) {
        const {
                emitEvent, context,
                node, module
            }     = this.props,
            model = Item.getItemModel(node),
            data  = {
                model,
                context,
            };

        if (key) {
            data.key = key;
        }

        emitEvent({
            name,
            module          : moduleFromParam || module,
            data,
            attributesFilter: ['model.id', 'model.type', 'context.id', 'context.type', 'key']
        });
    }


    /**
    * Close the current item
    *
    * @return void
    */
    close() {
        const { close, index } = this.props;

        this.emitEvent('close');
        close(index);
    }


    /**
    * Render the navigation part
    *
    * @return JSX
    */
    renderNavigation() {
        const { nextFromCollection, previousFromCollection } = this.props,
            iconSize = 20;

        return (
            <Col className="navigation">
                <Row>
                    {
                        this.hasPreviousEntity()
                        && (
                            <Icon
                                onClick={previousFromCollection} className="previous"
                                width={iconSize} type="caret-left"
                            />

                        )
                    }
                    {this.hasNextEntity()
                        && (
                            <Icon
                                onClick={nextFromCollection} className="next"
                                width={iconSize} type="caret-right"
                            />
                        )}
                    <Icon
                        width={iconSize} className="close"
                        onClick={this.close} type="close-circle"
                    />
                </Row>
            </Col>
        );
    }

    /**
    * Render the folded view
    *
    * @return Component
    */
    renderHeader() {
        const { index, renderBreadcrumbs, metadata } = this.props;

        if (!metadata) {
            return false;
        }

        return (
            <Row
                TagName="header"
                className="navigation-bar sticky"
                forwardedRef={this.headerRef}
            >
                {renderBreadcrumbs(index)}
                {this.renderActions()}
                {this.renderNavigation()}
            </Row>
        );
    }

    /**
    * Render the search
    *
    * @return self
    */
    render() {
        const { active } = this.props,
            classNames   = ['item', active ? 'active' : 'inactive'];

        return (
            <div
                className={classNames.join(' ')}
                onClick={this.onClick}
            >
                {this.renderHeader()}
                {this.renderActiveView()}
            </div>
        );
    }

}

Item.propTypes = {
    active                : PropTypes.any,
    bookmarks             : PropTypes.oneOfType([ImmutablePropTypes.map, ImmutablePropTypes.list, PropTypes.bool]),
    close                 : PropTypes.func,
    context               : PropTypes.any,
    elements              : PropTypes.any,
    entities              : PropTypes.any,
    index                 : PropTypes.any,
    module                : PropTypes.any,
    navigateTo            : PropTypes.func,
    nextFromCollection    : PropTypes.any,
    onClick               : PropTypes.func,
    previousFromCollection: PropTypes.any,
    registerMetadata      : PropTypes.func,
    renderBreadcrumbs     : PropTypes.func,
    resources             : PropTypes.any,
    emitEvent             : PropTypes.func,
    model                 : PropTypes.object,
    tags                  : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    metadata              : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    node                  : PropTypes.shape({
        collection: PropTypes.any,
        modules   : PropTypes.array,
        model     : PropTypes.shape({
            id   : PropTypes.any,
            type : PropTypes.any,
            label: PropTypes.string,
        }),
        withModel: PropTypes.shape({
            id   : PropTypes.any,
            type : PropTypes.any,
            label: PropTypes.string,
        }),
    }),
};

/**
* Bind the store to to component
*/
const mapStateToProps = (state) => ({});    // eslint-disable-line no-unused-vars

export default connect(mapStateToProps, {
    emitEvent
})(Item);
