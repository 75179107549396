// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* TAg cloud graph
*
*/
.TagCloud .background {
  position: absolute;
  overflow: hidden;
  user-select: none;
  /* background: #fff; */
}
.TagCloud .background .watermark {
  position: absolute;
}
.TagCloud > svg {
  position: absolute;
}
.TagCloud .label {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: bold;
  word-break: break-word;
  hyphens: auto;
  padding: 10px;
}
.TagCloud.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/TagCloud/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EAGQ,kBAAA;EACA,gBAAA;EACA,iBAAA;EAFN,sBAAsB;AACxB;AAJA;EAQY,kBAAA;AADZ;AAPA;EAaQ,kBAAA;AAHR;AAVA;EAgBQ,kBAAA;EACA,QAAA;EACA,6BAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;AAHR;AAMK;EACG,mDAAA;AAJR","sourcesContent":["/**\n* TAg cloud graph\n*\n*/\n\n.TagCloud {\n\n    .background {\n        position: absolute;\n        overflow: hidden;\n        user-select:none;\n        /* background: #fff; */\n        .watermark {\n            position: absolute;\n        }\n    }\n\n    > svg {\n        position: absolute;\n    }\n    .label{\n        position: absolute;\n        top: 50%;\n        transform: translate( 0, -50%);\n        font-weight: bold;\n        word-break: break-word;\n        hyphens: auto;\n        padding: 10px;\n    }\n\n     &.skeleton {\n        mask-image:  url('./skeleton.svg');\n    }\n\n}\n\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
