// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.span-overflow {
  display: flex;
  gap: 8px;
  white-space: nowrap;
}
.span-overflow .span-overflow-more {
  cursor: pointer;
}
.span-overflow .span-overflow-more .anticon-down {
  margin-left: 5px;
}
.span-overflow .span-overflow-more .anticon-down * {
  transition: color 200ms;
}
.span-overflow .span-overflow-collide-block {
  position: absolute;
  right: 0;
  height: 100%;
}
.more-custom {
  max-width: 800px;
}
.more-custom .more {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/span-overflow.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AAJA;EAMQ,eAAA;AACR;AAPA;EASY,gBAAA;AACZ;AAVA;EAYgB,uBAAA;AAChB;AAbA;EAkBQ,kBAAA;EACA,QAAA;EACA,YAAA;AAFR;AAMA;EACI,gBAAA;AAJJ;AAGA;EAIQ,aAAA;EACA,eAAA;EACA,QAAA;AAJR","sourcesContent":[".span-overflow {\n    display    : flex;\n    gap        : 8px;\n    white-space: nowrap;\n\n    .span-overflow-more {\n        cursor: pointer;\n\n        .anticon-down {\n            margin-left: 5px;\n\n            * {\n                transition: color 200ms;\n            }\n        }\n    }\n\n    .span-overflow-collide-block {\n        position: absolute;\n        right   : 0;\n        height  : 100%;\n    }\n}\n\n.more-custom {\n    max-width: 800px;\n\n    .more {\n        display  : flex;\n        flex-wrap: wrap;\n        gap      : 8px;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
