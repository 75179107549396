// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entity-inline {
  color: inherit;
}
.entity-inline .clicker {
  color: inherit;
}
.entity-inline .clicker .icon {
  margin-right: 5px;
}
.entity-inline .clicker .label {
  color: inherit;
}
.entity-inline .clicker.show-link:hover {
  cursor: pointer;
}
.entity-inline .clicker.show-link:hover .label {
  text-decoration: underline;
}
.entity-inline.not-reliable .clicker {
  cursor: default;
  color: #838383;
  text-decoration: none !important;
}
.entity-inline.not-reliable .clicker .label {
  color: #838383;
}
.entity-inline.not-reliable .clicker:hover {
  cursor: initial;
}
.entity-inline.not-reliable .clicker:hover .label {
  text-decoration: none;
}
.entity-inline .icon {
  position: relative;
  vertical-align: middle;
}
.entity-inline .loading {
  width: 100px;
  display: inline-block;
  line-height: 17px;
  height: 17px;
  vertical-align: middle;
}
.entity-inline .loading .ant-skeleton-title {
  margin: 0;
  position: relative;
  left: 5px;
}
.separator {
  align-self: center;
  color: #838383;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Entity/assets/inline.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAFA;EAOQ,cAAA;AAFR;AALA;EAMgB,iBAAA;AAEhB;AARA;EAUY,cAAA;AACZ;AAEQ;EACI,eAAA;AAAZ;AADQ;EAEa,0BAAA;AAErB;AAEI;EACI,eAAA;EACA,cAAA;EACA,gCAAA;AAAR;AAHI;EAKQ,cAAA;AACZ;AAEQ;EACI,eAAA;AAAZ;AADQ;EAGQ,qBAAA;AAChB;AA/BA;EAoCQ,kBAAA;EACA,sBAAA;AAFR;AAnCA;EAyCQ,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;AAHR;AA1CA;EAgDY,SAAA;EACA,kBAAA;EACA,SAAA;AAHZ;AAQA;EACI,kBAAA;EACA,cAAA;AANJ","sourcesContent":[".entity-inline {\n\n    color: inherit;\n\n    .clicker {\n\n        .icon { margin-right  : 5px }\n        color: inherit;\n\n        .label{\n            color: inherit;\n        }\n\n        &.show-link:hover {\n            cursor: pointer;\n            .label { text-decoration: underline; }\n        }\n    }\n\n    &.not-reliable .clicker{\n        cursor         : default;\n        color          : #838383;\n        text-decoration: none !important;\n        .label {\n            color: #838383;\n        }\n\n        &:hover{\n            cursor: initial;\n            .label {\n                text-decoration: none;\n            }\n        }\n    }\n\n    .icon {\n        position      : relative;\n        vertical-align: middle;\n    }\n\n    .loading {\n        width: 100px;\n        display: inline-block;\n        line-height: 17px;\n        height: 17px;\n        vertical-align: middle;\n\n        .ant-skeleton-title {\n            margin: 0;\n            position: relative;\n            left: 5px;\n        }\n    }\n}\n\n.separator {\n    align-self: center;\n    color     : #838383;\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
