import React, { useEffect, useMemo } from 'react';
import { connect }                   from 'react-redux';
import PropTypes                     from 'prop-types';
import { TreeSelect, Radio }         from 'antd';
import FilterBlock                   from 'helpers/Filters/Block';
import { getFolderBranch }           from 'store/actions/userView/bookmarksFolders';
import { fetchOuterModels }          from 'store/actions/userView';
import ImmutablePropTypes            from 'react-immutable-proptypes';
import _                             from 'lodash';
/**
* Render the smart filter render
* React Functional Components (Hooks)
*
* @return JSX
*/
const SmartFilter = ({ // eslint-disable-line max-lines-per-function
    className,
    filterKey,
    filterValue,
    onChangeFilter,
    getFolderBranch,
    bookmarkFolders,
    bookmarksFoldersOuter,
    options,
    fetchOuterModels,
}) => {
    useEffect(() => {
        const foldersTree     = getFolderBranch({ onlyFolders: true }),
            foldersTreeOfRoot = _.first(foldersTree)?.children,
            value             = _.find(foldersTreeOfRoot, ['value', filterValue]);

        if (!value) {
            fetchOuterModels('bookmark_folder', [filterValue]);
        }
    }, [bookmarkFolders]);

    /**
    * Get custom filter bookmark folder and boost
    *
    * @returns {JSX || void}
    */
    const getCustomFilter = () => {
        if(filterKey == 'bookmarkFolderForTags') {
            const foldersTree     = getFolderBranch({ onlyFolders: true }),
                foldersTreeOfRoot = _.first(foldersTree)?.children,
                folder            = _.find(foldersTreeOfRoot, ['value', filterValue]),
                modelFromOuter    = bookmarksFoldersOuter?.get(filterValue),
                memoizedFolder    = useMemo(() => {
                    return modelFromOuter
                        ? modelFromOuter?.label + ' (deleted)'
                        : folder?.fullPath;
                }, [folder, modelFromOuter]);

            return (
                <TreeSelect
                    key={filterKey}
                    className="bookmark-folder-selector"
                    style={{ width: '100%' }}
                    value={memoizedFolder}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={foldersTreeOfRoot}
                    placeholder="Please select"
                    treeDefaultExpandAll
                    onChange={(value) => onChangeFilter(filterKey, value)}
                    treeNodeLabelProp="fullPath"
                    treeNodeFilterProp="title"
                    showSearch
                    allowClear
                />
            );
        }

        if(filterKey == 'expand') {
            return (
                <Radio.Group onChange={(e) => onChangeFilter(filterKey, e.value)} value={filterValue}>
                    <Radio value={true}>Boost On</Radio>
                    <Radio value={false}>Boost Off</Radio>
                </Radio.Group>
            );
        }
    };

    /**
     *
     * @returns
     */
    const getHelpInfo = () => { // eslint-disable-line max-lines-per-function
        if(filterKey === 'countryFilter') {
            const helpTitle = ['Market ', <span>(BETA)</span>],
                helpContent = (
                    <span>
                        Hi! This is a beta version,
                        <br />
                        if you notice anything strange or lacking relevance
                        please share it with your Questel contact and we&apos;ll fix it!
                        <br />
                        NB:
                        <br />
                        “We don&apos;t filter randomly...
                        <br />
                        - For patents, we filter based on the &quot;countries of publication&quot;
                        and include both alive and dead patents.
                        <br />
                        - For NPL, we filter based on the &quot;countries of related organizations&quot;.
                        <br />
                        - For News, we consider the country of mentioned organizations (data provided by an external provider).
                        <br />
                        - For Organizations and People,
                        we consider documents with at least one organization in the selected country/countries.
                        <br />
                        To isolate organizations and people from a specific country,
                        use the &quot;Related organization countries&quot; facet filter in addition.”
                    </span>
                );
            return { title: helpTitle, content: helpContent };
        }

        if(filterKey === 'dateFilter') {
            const helpTitle = ['Application Year ', <span>(BETA)</span>],
                helpContent = (
                    <span>
                        Hi! This is a beta version,
                        <br />
                        if you notice anything strange or lacking relevance
                        <br />
                        {'please share it with your Questel contact and we\'ll fix it!'}
                        <br />
                        {'NB: for patents, the filter applies to \'Application date\''}
                    </span>
                );
            return { title: helpTitle, content: helpContent };
        }

        if(filterKey === 'bookmarkFolderForTags') {
            const helpTitle = ['Bookmark Folder ', <span>(BETA)</span>],
                helpContent = (
                    <span>
                        Hi! This is a beta version.
                        <br />
                        Use this option to limit the &apos;Competitive Landscape&apos; dashboard to organizations
                        classified as
                        <br />
                        &apos;Competitor&apos; and &apos;It&apos;s my company&apos; within the selected folder.
                        <br />
                        Please note that this option also limits the Classification filter
                        to the selected folder&apos;s organizations.
                        <br />
                    </span>
                );
            return { title: helpTitle, content: helpContent };
        }
        if(filterKey === 'expand') {
            const helpTitle = ['Boost On'],
                helpContent = (
                    <>
                        Including variation of concepts
                        <br />
                        (conjugated form, word family...)
                        <br />
                        and extending search to extra fields
                    </>
                );
            return { title: helpTitle, content: helpContent };
        }
    };


    /**
     * Get filter custom options
     */
    const getCustomOptions = () => {
        if(filterKey === 'expand') {
            return {showDelete: false};
        }
        return {};
    };

    /**
     *
     * @returns
     */
    const getFilterConfig = () => {
        if(filterKey === 'countryFilter') {
            return { showGeoMap: false, colors: {active: 'var(--primary-color)'} };
        }
        if(filterKey === 'dateFilter') {
            return { blockedEndDate: new Date(), minRange: 1 };
        }
    };

    const helpInfo  = getHelpInfo(),
        customLabel = helpInfo?.title;

    return (
        <FilterBlock
            key={filterKey}
            className={className}
            customLabel={customLabel}
            filterKey={filterKey}
            value={filterValue}
            onChange={onChangeFilter}
            filterConfig={getFilterConfig()}
            helpInfo={{content: helpInfo?.content}}
            options={{...options, ...getCustomOptions()}}
        >
            {getCustomFilter()}
        </FilterBlock>
    );
};

SmartFilter.propTypes = {
    className            : PropTypes.string,
    filterKey            : PropTypes.string.isRequired,
    filterValue          : PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChangeFilter       : PropTypes.func,
    getFolderBranch      : PropTypes.func,
    options              : PropTypes.object,
    bookmarkFolders      : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarksFoldersOuter: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    fetchOuterModels     : PropTypes.func,
};

SmartFilter.defaultProps = {
    className  : '',
    filterValue: null,
    options    : {}
};

/**
 *
 * @param {*} state
 * @returns
 */
const mapStateToProps  = (state) => {
    const bookmarkFolders     = state.getIn(['userView', 'bookmark_folder', 'list']),
        bookmarksFoldersOuter = state.getIn(['userView', 'bookmark_folder', 'outerModels']);

    return { bookmarkFolders, bookmarksFoldersOuter };
};

export default connect(mapStateToProps, {
    getFolderBranch,
    fetchOuterModels,
})(SmartFilter);
