// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* Venn graph
*
*/
.vertical-gauge {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.vertical-gauge:hover {
  opacity: 0.75;
}
.vertical-gauge .grade {
  width: 10px;
  height: 3px;
  background-color: #E6E6E6;
  margin: 1px;
}
.vertical-gauge .light-up {
  background-color: #E96F26;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/verticalGauge/main.less"],"names":[],"mappings":"AAAA;;;CAGC;AAED;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,aAAA;AAAR;AANA;EAUQ,WAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;AADR;AAZA;EAiBQ,yBAAA;AAFR","sourcesContent":["/**\n* Venn graph\n*\n*/\n\n.vertical-gauge {\n    display       : flex;\n    flex-direction: column;\n    width         : fit-content;\n\n    &:hover {\n        opacity: 0.75;\n    }\n\n    .grade {\n        width           : 10px;\n        height          : 3px;\n        background-color: #E6E6E6;\n        margin          : 1px;\n    }\n\n    .light-up {\n        background-color: #E96F26;\n    }\n}\n\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
