import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { Button }           from 'antd';
import stepsViewsByType     from './Steps';
import { Icon }             from 'helpers';

/**
 * Use this component to create a newsletter after its creation
 *
 */
class Create extends Component {

    /**
     * Construct the create view
     *
     * @param {object} props Some properties
     */
    constructor(props) {
        super(props);

        _.bindAll(this, 'previousStep', 'nextStep');

        this.state = {
            steps: {},
        };
    }

    /**
     * Get step views componants
     */
    getStepsViews() {
        return stepsViewsByType[this.getNewsletterKey()];
    }


    /**
    * Check whether the "next step" button is enabled.
    *
    * @return boolean
    */
    nextStepIsEnabled() {
        const { newsletter }  = this.props,
            currentDefinition = this.getActiveStepDefinition(),
            { key }             = currentDefinition;

        // Sections need to be filled before we allow to go to next step
        if (key === 'sections') {
            return newsletter.settings
                && newsletter.settings.sections
                && newsletter.settings.sections.length;
        }

        // Must have one entities at least
        if (key === 'entities') {
            return newsletter.entities
                && newsletter.entities.length;
        }

        return true;
    }

    /**
     * Get step for the actual newsletter
     *
     * @returns {integer}
     */
    getStep() {
        const { steps }   = this.state,
            newsletterKey = this.getNewsletterKey();

        return steps[newsletterKey] || 0;
    }


    /**
    * Get Active Step Definition
    *
    * @return object
    */
    getActiveStepDefinition() {
        const stepsViews = this.getStepsViews(),
            step         = this.getStep();

        return stepsViews[step].definition;
    }

    /**
     * Return newletter key
     */
    getNewsletterKey() {
        const { newsletter } = this.props;

        return newsletter.key;
    }


    /**
     * Go to the previous step
     *
     * @return void
     */
    previousStep() {
        const { steps }   = this.state,
            step          = this.getStep(),
            newsletterKey = this.getNewsletterKey();

        steps[newsletterKey] = step - 1;
        this.setState({ steps });
    }


    /**
     * Update the form, let's go to the next step.
     *
     * @return void
     */
    nextStep() {
        const { create }  = this.props,
            { steps }     = this.state,
            stepsViews    = this.getStepsViews(),
            newsletterKey = this.getNewsletterKey(),
            step          = this.getStep(),
            nextStep      = step + 1;

        steps[newsletterKey] = nextStep;
        this.setState({ steps });

        if(nextStep === stepsViews.length) {
            create();
        }
    }


    /**
    * Render buttons
    *
    * @return htlm
    */
    renderStepButtons() {
        const stepsViews = this.getStepsViews(),
            stepCount    = stepsViews.length,
            step         = this.getStep(),
            isLastStep   = step === stepCount - 1,
            nextLabel    = isLastStep ? 'Done' : 'Next',
            buttons      = [];

        // Push previous button
        if (step > 0) {
            buttons.push(
                <Button
                    onClick={this.previousStep}
                >
                    Previous
                </Button>
            );
        }

        // Push next button
        buttons.push(
            <Button
                type="primary"
                onClick={this.nextStep}
                disabled={!this.nextStepIsEnabled()}
                data-qa-key={nextLabel}
            >
                {nextLabel}
            </Button>
        );

        return (
            <div className="steps-buttons">
                {buttons}
            </div>
        );
    }


    /**
    * Render finished steps
    *
    */
    renderStepsFinished() {
        return (
            <>
                <div className="background">
                    <Icon id="innovation-newsletter" width={250}
                        height={360} color="#f5f5f5"
                    />
                </div>
                <div className="steps-finished">
                    <div className="YOURE-DONE">
                        <span className="c1">y</span>
                        <span className="c2">o</span>
                        <span className="c3">u</span>
                        <span className="c3">&apos;</span>
                        <span className="c4">r</span>
                        <span className="c5">e</span>
                        <span className="c5"> </span>
                        <span className="c6">d</span>
                        <span className="c7">o</span>
                        <span className="c8">n</span>
                        <span className="c9">e</span>
                        <span className="c9">!</span>
                    </div>
                    <div className="big-message">
                        You will soon receive your 1st Insight Feed
                    </div>
                    <div className="small-message">
                        Keep an eye on your mailbox.
                        <br />
                        Notice the Insight Feed will also be available right here.
                    </div>
                </div>
            </>
        );
    }


    /**
    * Render step title
    *
    * @return html
    */
    renderStepTitle() {
        return (
            <div className="step-title">
                Your tailored Insight Feed ready in 3 clicks
            </div>
        );
    }


    /**
    * Render steps
    *
    * @return html
    */
    renderStepLine() {
        const stepsViews = this.getStepsViews(),
            step         = this.getStep();

        return (
            <div className="steps">
                <div className="steps-line" />
                {stepsViews.map((Component, index) => {
                    const classNames   = ['step', `step-${index}`];

                    if (step === index) {
                        classNames.push('active');
                    }

                    if (step < index) {
                        classNames.push('todo');
                    }

                    return (
                        <div key={classNames.join(' ')} className={classNames.join(' ')}>
                            <span>
                                {index + 1}
                            </span>
                        </div>
                    );
                })}
                <div className={`step-nail step-${step}`} />
                <div className={`step-label step-${step}`}>
                    <span>
                        {stepsViews[step].definition.question}
                    </span>
                </div>
            </div>
        );
    }


    /**
    * Render the view for the active step
    *
    * @return html
    */
    renderStepView() {
        const {
                update, newsletter,
                close, activateOptions
            } = this.props,
            stepsViews                          = this.getStepsViews(),
            newsletterKey                       = this.getNewsletterKey(),
            step                                = this.getStep(),
            Component                           = stepsViews[step];

        return (
            <Component
                key={newsletterKey}
                newsletter={newsletter}
                canBeEmpty
                update={update}
                close={close}
                activateOptions={activateOptions}
            />
        );
    }


    /**
    * Render Step settings
    *
    * @return html
    */
    renderStep() {
        return [
            this.renderStepTitle(),
            this.renderStepLine(),
            this.renderStepView(),
        ];
    }


    /**
    * Render buttons
    *
    * @return html
    */
    renderButtons() {
        const stepsViews = this.getStepsViews(),
            step         = this.getStep();

        // Last step doesn't need a button
        if (step === stepsViews.length) {
            return false;
        }

        return  (
            <div className="footer-buttons">
                {this.renderStepButtons()}
            </div>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const stepsViews = this.getStepsViews(),
            step         = this.getStep(),
            finished     = step === stepsViews.length;

        return (
            <div className={finished ? 'newsletter-finished' : 'create-newsletter'}>
                <div className="content">
                    {finished ? this.renderStepsFinished()
                        :  this.renderStep()}
                </div>
                {this.renderButtons()}
            </div>
        );
    }

}

Create.propTypes = {
    newsletter     : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    activateOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    update         : PropTypes.func,
    close          : PropTypes.func,
    create         : PropTypes.func
};

Create.defaultProps = {
    newsletter     : false,
    activateOptions: false,
};

export default Create;

