/**
 * Insight Search block
 */

import React, { Component }  from 'react';
import { Icon }              from 'helpers';

import './assets/insight-search.less';

/**
 * The block to show the search is gerenated by insigth
 *
 */
class InsightSearch extends Component {

    state = {  };

    /**
     * General react componant render
     */
    render() {
        return (
            <div className="insight-stamp">
                Generated by
                <Icon id="newsletters" folder="/shortcuts/"
                    width={16} height={16}
                />
                Insight Feed
            </div>
        );
    }

}

export default InsightSearch;

