/**
 * Display the dashboard of the App
 *
 * @return Component
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _                    from 'lodash';
import { Element }          from 'helpers';
import { dataGet }          from 'utils/api';
import Dashboard            from './Search/Dashboard';

/**
* The search module
*
*/
class Search extends Component {

    /**
    * Initialize the Entity display
    */
    constructor(props) {
        super(props);

        const { active } = props;

        _.bindAll(this, 'getMetadata', 'getElement');

        this.state = {
            active,
            elementActions: {}
        };
    }

    /**
    * Triggered once when the component has been mount
    *
    * @return void
    */
    componentDidMount() {
        const {
                emitElementAction,
                registerMetadata,
                active
            }       = this.props,
            element = this.getElement();

        if (element) {
            registerMetadata(this.getMetadata());
            // Emit action to saas-event
            emitElementAction(element);
        }

        if (active) {
            this.fakeXhrToMonitorNavigation(); // Ugly, well, this time it's a NO-GO for heaven.
        }
    }

    /**
    * Send a XHR to follow user when he navigates on searches.
    *
    * @return void
    */
    fakeXhrToMonitorNavigation() {
        const { model }       = this.props,
            element           = this.getElement(),
            { category, key } = element;

        dataGet('/entities', { data: { ids: [model.id], area: category.split('/')[1].toLowerCase(), key  } });
    }

    /**
    * Component did update
    *
    * @return void
    */
    componentDidUpdate() {
        const { active }               = this.props,
            { active:activeFromState } = this.state;

        if (active !== activeFromState) {
            // Active this item
            if (active) {
                this.fakeXhrToMonitorNavigation(); // Ugly, well, this time it's a NO-GO for heaven.
            }

            this.setState({ active });
        }
    }

    /**
    * Render the title of the element
    *
    * @return JSX
    */
    getMetadata() {
        const element = this.getElement();

        return {
            actions: false,
            title  : element.label
        };
    }

    /**
    * Return the main element
    *
    * @return object
    */
    getElement() {
        const { module, elements, model } = this.props,
            dashboard                     = elements.find(
                (element) => element.category === 'Area/Dashboard' && element.key === module
            ),
            analyse                       = elements.find(
                (element) => element.category === 'Area/Analyse' && element.key === module
            ),
            dataset                       = elements.find(
                (element) => element.category === 'Area/Dataset' && element.key === module && element.target === model.type
            );

        return dashboard || analyse || dataset || false;
    }

    /**
    * Render the view element
    *
    * @return Component
    */
    renderElement() {
        const { hasBeenActivated } = this.props,
            element                = this.getElement(),
            { category, key }      = element,
            isADashboard           = category === 'Area/Dashboard';

        if (!hasBeenActivated || !element) {
            return false;
        }

        // It's a dashboard element
        if (isADashboard) {
            return <Dashboard key={key} {...this.props} />;
        }
        return (
            <Element key={`${category}${element.id}`} {...this.props}
                element={element}
            />
        );
    }

    /**
    * Render the search
    *
    * @return self
    */
    render() {
        return this.renderElement();
    }

}

Search.propTypes = {
    active           : PropTypes.bool,
    hasBeenActivated : PropTypes.any,
    module           : PropTypes.any,
    registerMetadata : PropTypes.func,
    emitElementAction: PropTypes.func,
    elements         : PropTypes.shape({
        find: PropTypes.func,
    }),
    model: PropTypes.shape({
        id  : PropTypes.any,
        type: PropTypes.any,
    }),
};

export default Search;
