// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./skeleton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiGraph .background {
  position: absolute;
  overflow: hidden;
  user-select: none;
  background: #fff;
}
.MultiGraph .background img {
  position: absolute;
  width: 100%;
}
.MultiGraph > .label {
  font-weight: bold;
  position: absolute;
  text-align: center;
}
.MultiGraph line {
  stroke-width: 1;
}
.MultiGraph.skeleton {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.MultiGraph > svg {
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Graph/types/MultiGraph/main.less"],"names":[],"mappings":"AAAA;EAGQ,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADR;AALA;EASY,kBAAA;EACA,WAAA;AADZ;AAKI;EACI,iBAAA;EACA,kBAAA;EACA,kBAAA;AAHR;AAdA;EAqBQ,eAAA;AAJR;AAOI;EACI,mDAAA;AALR;AApBA;EA6BQ,kBAAA;AANR","sourcesContent":[".MultiGraph {\n\n    .background {\n        position   : absolute;\n        overflow   : hidden;\n        user-select:none;\n        background : #fff;\n\n        img {\n            position: absolute;\n            width   : 100%;\n        }\n    }\n\n    & > .label{\n        font-weight: bold;\n        position   : absolute;\n        text-align : center;\n    }\n\n    line {\n        stroke-width: 1;\n    }\n\n    &.skeleton {\n        mask-image: url('./skeleton.svg');\n    }\n\n    > svg {\n        position: absolute;\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
