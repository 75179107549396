/**
 * Auth Actions Creator: create actions for the current member
 *
 */
import _                  from 'lodash';
import * as types         from './types/insightBot';
import * as socketTypes   from './types/sockets';
import { dataGet }        from 'utils/api';
import notification       from 'helpers/notification';


/**
 * Fetch assistant chatGPT
 *
 * @param {string} source
 */
export const fetchAssistant = source => dispatch => {
    const uri   = '/assistant',
        options = {
            data: {
                q: source,
            }
        };

    dispatch({ type: types.INSIGHT_BOT_ADD_LEXICON, payload: { key: source, data: {}, status: 'loading' } });

    const event = {
        module: 'insight-bot',
        name  : 'fetch',
        data  : {
            source,
        }
    };

    dispatch({ type: socketTypes.WEB_SOCKETS_EMIT_EVENT, payload: event });

    // Fetch openAI / ChatGPT
    dataGet(uri, options)
        .then(response => {
            const { body, status } = response,
                payload            = {
                    key   : source,
                    status: 'loaded',
                    data  : body || {},
                };

            if (status.code !== 200) {
                payload.status = 'inactive';
            }

            if (!_.includes([200, 206], status.code)) {
                notification({
                    key     : 'insight-bot-notification',
                    type    : 'error',
                    message : 'Error occurred, relaunch Insightbot call.',
                    maxCount: 1,
                });
            }

            return dispatch({ type: types.INSIGHT_BOT_ADD_LEXICON, payload });
        });
};

// Export default
export default {
    fetchAssistant,
};
