// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInfo {
  color: var(--primary-color);
  font-family: Helvetica;
  font-size: 12px;
  font-style: oblique;
  letter-spacing: 0.2px;
  line-height: 18px !important;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/Filters/assets/textInfo.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AACF","sourcesContent":[".textInfo{\n  color: var(--primary-color);\n  font-family: Helvetica;\n  font-size: 12px;\n  font-style: oblique;\n  letter-spacing: 0.2px;\n  line-height: 18px !important;\n  text-align: left;\n}\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
