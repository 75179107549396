import _                              from 'lodash';
import types                          from '../types/userView';
import { WEB_SOCKETS_EMIT_EVENT }     from '../types/sockets';
import { getSearchTitle }             from 'utils/search';
import { del, patch }                 from 'utils/api';
import { addItems, userViewItemIsDescendantOf } from '../userView';
import { getBookmarkFolder }            from 'store/actions/userView/bookmarksFolders';


let updatePool = new Map();

/**
* Get the label of the bookmark
*
* @returns string
*/
export const getBookmarkLabel = (bookmark) => {
    if(!bookmark) {
        return '';
    }
    const label = bookmark.label
    || bookmark.entity?.label
    || (bookmark.entity?.type === 'query' && getSearchTitle(bookmark.entity.entity.entity, ''))
    || bookmark.entity?.entity?.label
    || bookmark.entity?.entity?.entity?.label;

    return _.deburr(label?.toString().toLowerCase()) || '';
};

/**
 * Add a bookmark or an array of bookmarks
 *
 * @param {*} models
 * @param {*} params
 * @returns
 */
export const addBookmark = (models, params, onAddItem = null) => (dispatch, getState) => {
    const bookmarkModels = [];
    // Arrays of models are process individually
    _.isArray(models)
        ? models.forEach(model => bookmarkModels.push(createBookmarkModel(model, params)))
        : bookmarkModels.push(createBookmarkModel(models, params));

    addItems(bookmarkModels, onAddItem)(dispatch, getState);
};

/**
 * Create a temporal bookmark model and push it in pool
 *
 * @param {*} model
 * @param {*} params
 * @returns
 */
export const createBookmarkModel = (model, params) => {
    const {
            label,
            tags,
            parent_bookmark_folder
        }               = params,
        service         = 'data-api',
        dataEntity      = model.entity ? model.entity : model,  // Make sure is a data entity
        entity          = { entity: dataEntity };

    return {
        model: {
            id  : crypto.randomUUID(),
            type: 'bookmark',
            tags: tags || [],
            label,
            entity,
            parent_bookmark_folder,
            service,
        },
        parent_attachments: [
            {
                key        : `member/bookmark_folder/${parent_bookmark_folder}`,
                date_create: new Date(Date.now() + 86400000).toISOString(),
            }
        ]
    };
};


/**
* Remove a bookmark from api & remove it into userView slice
*
* @return {Promise}
*/
export const removeBookmarkFromFolder = (bookmark_id, parent_id) => (dispatch, getState) => {
    const state = getState(),
        bookmarkUserViewItems = state.get('userView').get('bookmark').get('map'),
        bookmarkItem          = bookmarkUserViewItems.find((userView) => {
            return bookmark_id === userView.model.id;
        });

    // Clean parent_attachments for the temporary items
    bookmarkItem.parent_attachments = bookmarkItem.parent_attachments.filter((parent_attachment) => {
        return parent_attachment.key !== `member/bookmark_folder/${parent_id}`;
    });

    dispatch({
        type   : types.USER_VIEW_UPDATE_ITEMS,
        payload: {
            items        : [bookmarkItem],
            userViewState: bookmarkItem.parent_attachments.length === 0 ? 'deleted' : 'updated'
        }
    });

    setTimeout(() => {
        del(`/bookmarks/${bookmark_id}`, {data: {from: parent_id}});
    }, 2000);

    // Emit socket event
    dispatch({
        type   : WEB_SOCKETS_EMIT_EVENT,
        payload: {
            module          : 'bookmark',
            name            : 'delete',
            data            : bookmarkItem.model,
            attributesFilter: ['id', 'type', 'entity.id', 'entity.type', 'entity.entity.id', 'entity.entity.type']
        }
    });

    // After the API finish the update of the userView,
    // The updated userView is sended by sockets
};


// TODO: check & refact tag folders
/**
* Update bookmark folder
*
* @return {promise}
*/
export const updateBookmark = (payload, content, cb) => (dispatch, getState) => {
    const state         = getState(),
        userViewItems   = state.get('userView').get('bookmark').get('map'),
        { id }          = payload.model || payload,  // Get id from bookmarks or stream entity
        attributes      = _.omitBy(content, _.isUndefined), // Remove undefined attributes
        attributesCount = _.keys(attributes).length,
        item            = userViewItems.find((userView) => {
            return id === userView.model?.id;
        }),
        newItem         = {
            ...item,
            ...{model: {...item.model, ...attributes}}
        };

    dispatch({ type: types.USER_VIEW_UPDATE_ITEMS, payload: { items: [newItem],  userViewState: 'updated' }});
    if(id && attributesCount) {
        updatePool.set(id, {id, attributes, cb});
        updateBookmarkPool();
    }
};

/** Send bulk update
* @return debounced function
*/
const updateBookmarkPool = _.debounce(() => {
    updatePool.forEach((model) => {
        const {id, attributes, cb } = model;
        patch(`/bookmarks/${id}`, attributes)
            .then(({ body }) => {
                if (cb) {
                    cb(body);
                }
            });
    });
    updatePool = new Map();
}, 2000);


/**
* Move a bookmark to a folder
*
* @return {promise}
*/
export const moveBookmark = (payload, content, cb) => (dispatch) => {
    const { model }  = payload,
        { id }       = model,  // Get id from bookmarks
        move         = _.pick(content, ['from', 'to']),
        { from, to } = move,
        params       = { move };

    if (!id || !from || !to) {
        return;
    }

    // Move in redux
    const item = _.cloneDeep(payload),
        { parent_attachments } = payload,
        newParentAttachments   = parent_attachments.filter(item => item.key != `member/bookmark_folder/${from}`);

    newParentAttachments.push({
        key        : `member/bookmark_folder/${to}`,
        date_create: new Date(Date.now() + 86400000).toISOString() // Tomorrow
    });

    item.parent_attachments = newParentAttachments;
    dispatch({ type: types.USER_VIEW_UPDATE_ITEMS, payload: { items: [item], userViewState: 'moved' } });

    // Move in API
    id && patch(`/bookmarks/${id}`, params)
        .then(({ body }) => {
            if (cb) {
                cb(body);
            }
        });
};


/**
 * Test if the bookmark is in the scope of the search
 *
 * @param {userViewItem} bookmarkUserViewItem
 * @param {string} bookmarkFolderId
 * @returns
 */
export const bookmarkIsInScopeOfSearch = (bookmarkUserViewItem, bookmarkFolderId) => (dispatch, getState) => {
    const isDescendant     = bookmarkFolderId
            && userViewItemIsDescendantOf(bookmarkUserViewItem, bookmarkFolderId)(dispatch, getState),
        folderUserViewItem = bookmarkFolderId && getBookmarkFolder(bookmarkFolderId)(dispatch, getState);

    return !folderUserViewItem || isDescendant;
};



export default {
    addBookmark,
    updateBookmark,
    moveBookmark,
    removeBookmarkFromFolder,
    bookmarkIsInScopeOfSearch
};
