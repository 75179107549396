// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty {
  text-align: center;
}
.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty .no-items {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: 330px;
  border-radius: 330px;
  background: #eee;
  margin: 30px auto;
  align-items: center;
  justify-content: center;
}
.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty .no-items .text {
  color: #999;
  font-size: 16px;
  line-height: 1.2;
}
.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty .no-items .text div,
.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty .no-items .text span {
  color: inherit;
}
.ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content .content.empty .no-items .text .options {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 15px;
  border: 1px solid #CCC;
  background: #FFF;
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/core/Ui/Header/Shortcut/Views/assets/empty.less"],"names":[],"mappings":"AAAA;EAEQ,kBAAA;AAAR;AAFA;EAKY,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;AAAZ;AAbA;EAgBgB,WAAA;EACA,eAAA;EACA,gBAAA;AAAhB;AAlBA;;EAqBoB,cAAA;AACpB;AAtBA;EAyBoB,qBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;AAApB","sourcesContent":[".ant-popover.shortcut-view .ant-popover-content .ant-popover-inner-content {\n    .content.empty {\n        text-align: center;\n\n        .no-items {\n            display        : flex;\n            flex-direction : column;\n            width          : 330px;\n            height         : 330px;\n            border-radius  : 330px;\n            background     : #eee;\n            margin         : 30px auto;\n            align-items    : center;\n            justify-content: center;\n\n            .text {\n                color      : #999;\n                font-size  : 16px;\n                line-height: 1.2;\n\n                div, span {\n                    color: inherit;\n                }\n\n                .options {\n                    display      : inline-block;\n                    height       : 20px;\n                    width        : 20px;\n                    border-radius: 15px;\n                    border       : 1px solid #CCC;\n                    background   : #FFF;\n                    margin       : 5px;\n                }\n            }\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
