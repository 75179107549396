import React, { useRef }    from 'react';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import _                    from 'lodash';
import OrgunitInformation   from '../Modal/IdCard/Informations/Orgunit';
import { Icon }             from 'helpers';
import { findParentClass }  from 'utils/dom';
import { makeStrClassName } from 'utils/text';

/**
* Display a detailed card orgunit
*/
const Orgunit = props => { // eslint-disable-line max-lines-per-function
    const ref = useRef();

    /**
    * Render tags for current bookmark
    *
    * @param object entity
    *
    * @return JSX
    */
    const renderClassification = () => {
        const {
                bookmark, tags,
                entity, compiledTags,
            }                     = props,
            { id }                = entity,
            currentTags           = tags.filter(tag => bookmark && bookmark.tags.includes(tag.id));

        if (!bookmark || !compiledTags || !currentTags) {
            return null;
        }

        return !_.isEmpty(currentTags.toArray()) && (
            <div key="tags" className="tags">
                {currentTags.map(tag => {
                    const size = 16,
                        tagsInScope = !compiledTags || compiledTags[tag.id] && compiledTags[tag.id].includes(id),
                        style  = {
                            color   : tag.color,
                            height  : size,
                            width   : size,
                            fontSize: 20
                        };

                    return (
                        <Icon
                            key={tag.id}
                            type={tag.icon}
                            color={tag.color}
                            theme={tagsInScope ? 'filled' : 'outlined'}
                            style={style}
                            height={size}
                            width={size}
                            title={tag.label}
                            className="icon"
                        />
                    );
                })}
            </div>
        );
    };

    /**
    * Render the document according to the props.
    *
    * @return JSX
    */
    const render = () => {
        const { entity, onClick }    = props,
            { renderModelAction }    = props,
            { featuredActionsRender } = props,
            { orgClasses }           = props,
            { id, label, clickable } = entity || {},
            isDashboard              = !!findParentClass(ref?.current, 'dashboard-list'),
            classNames               = [
                'information',
                { column: isDashboard },
            ];

        return (
            <>
                <div className="entity-logo">
                    <Icon
                        className="orgunit" id="orgunit"
                        folder="/entities/" orgunitLogo={id}
                        height={70} width={90}
                        discShaped borderSize={1}
                        borderColor="var(--insight-light-gray)"
                        onClick={clickable ? onClick : null}
                    />
                </div>

                <div className="information-container" ref={ref}>
                    <div className="header">
                        <div className="label" onClick={clickable ? onClick : null}
                            title={label}
                        >
                            {label}
                        </div>
                        {featuredActionsRender.map(renderAction => renderAction())}
                        {renderClassification()}
                        {renderModelAction()}
                    </div>
                    <div className={makeStrClassName(classNames)}>
                        <OrgunitInformation delay={300} light={false}
                            entity={entity} orgClasses={orgClasses}
                        />
                    </div>
                </div>
            </>
        );
    };

    return render();
};

Orgunit.propTypes = {
    bookmark: PropTypes.oneOfType([
        PropTypes.shape({tags: PropTypes.array}),
        PropTypes.bool,
    ]),
    compiledTags        : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    entity              : PropTypes.object.isRequired,
    onClick             : PropTypes.func,
    orgClasses          : ImmutablePropTypes.list,
    renderBookmarkAction: PropTypes.func,
    renderModelAction   : PropTypes.func,
    tags                : ImmutablePropTypes.list,
};

Orgunit.defaultProps = {
    renderBookmarkedIcon: () => {},
};

export default Orgunit;
