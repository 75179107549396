/**
 * Display a collection from a resource.
 * Mostly used in sidelists of Dashboards
 */

import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';
import _                    from 'lodash';
import { learn }            from 'store/actions/knowledge';
import { allEntityType }    from 'helpers/Entity/Stats';
import { Collection }       from 'helpers';

import './assets/resource-collection.less';

/**
 * ResourceCollection class
 */
class ResourceCollection extends Component {

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'storePagination');

        this.state = {
            total: null
        };

        this.collection = null;
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        // Fetch some entities to display
        learnKnowledge(['resources', 'entities'])
            .then(this.setState.bind(this));
    }

    /**
    * Store the pagination in the state
    */
    storePagination(pagination) {
        // No pagination
        if (_.isNull(pagination)) { return; }

        const { total } = pagination;

        this.setState({ total });
    }

    /**
    * Render the collection using singleton pattern.
    *
    * @return JSX
    */
    renderCollection() {
        if (this.collection) {
            return this.collection;
        }

        const { resources }                   = this.state,
            { resourceKey, searchId, extras } = this.props,
            { path, models }                  = resources.find((obj) => obj.id === resourceKey) || false,
            apiUri                            = `/query/${searchId}/${path}`,
            model                             = _.first(models),
            { limit }                         = extras;

        this.collection = (
            <Collection
                uri={apiUri}
                type={model.type}
                subtype={model.subtype}
                parameters={{ limit: { max: limit || 8 } }}
                storePagination={this.storePagination}
            />
        );

        return this.collection;
    }

    /**
    * Render the collection content
    *
    * @return JSX
    */
    render() {
        const { resources, entities, total } = this.state;

        if (!resources || !entities) {
            return null;
        }

        const { resourceKey, searchId, extras } = this.props,
            { dashboardItem }                   = this.props,
            { models }                          = resources.find((obj) => obj.id === resourceKey) || false,
            model                               = _.first(models),
            entity                              = entities.find((obj) => obj.id === model.type) || false,
            { label, dataset }                  = extras,
            entityDef                           = _.find(
                allEntityType,
                (entityType) => entityType.type === (dataset || entity.id)
            ) || {},
            datasetId                           = entityDef.label;

        return (
            <div className="dashboard-collection content-box" key={`${searchId}-${entity.id}-${resourceKey}`}>
                <h2>
                    {label || entity.label_plural}
                </h2>
                <div className="content-list">{this.renderCollection()}</div>
                <div className="footer">
                    <span className="more">
                        <Link to={datasetId ? `/search/${searchId}/dataset/${datasetId}/from/${dashboardItem.type}` : null}>
                            {
                                !total ? 'View all'
                                    : `View all (${total} ${label || entity.label_plural})`
                            }
                        </Link>
                    </span>
                </div>
            </div>
        );
    }

}

ResourceCollection.propTypes = {
    learnKnowledge: PropTypes.func.isRequired,
    resourceKey   : PropTypes.string.isRequired,
    searchId      : PropTypes.string.isRequired,
    extras        : PropTypes.shape({
        limit  : PropTypes.number,
        label  : PropTypes.string,
        dataset: PropTypes.string,
    }),
    dashboardItem: PropTypes.shape({
        type: PropTypes.any
    }).isRequired,
};

ResourceCollection.defaultProps = {
    extras: {}
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(ResourceCollection);

