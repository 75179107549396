/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';
import _                              from 'lodash';
import React, { Component }           from 'react';
import { connect }                    from 'react-redux';
import { activateShortcut }           from 'store/actions/navigation';
import {
    openSettings, closeSettings
}                                     from 'store/actions/newsletters';
import {
    getModelsOfType,
    saveNewsletter, addItems
}                                     from 'store/actions/userView';
import { Icon }                       from 'helpers';
import { Button }                     from 'antd';
import Newsletter                     from './Newsletters/Newsletter';

// Import the header stylesheet
import './assets/newsletters.less';

const newslettersType = [
    {
        key     : 'competitive-insights',
        tabLabel: 'Monitor Companies',
        label   : 'Company Monitor',
        subject : 'competition',
    },
    {
        key     : 'topic-insights',
        tabLabel: 'Monitor Searches',
        label   : 'Search Monitor',
        subject : 'topics',
    }
];

/**
 * Class Newsletters
 *
 */
class Newsletters extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'update', 'save', 'create', 'getNewsletter', 'renderEmptyList', 'renderNewsletter', 'initCreationProcess',
            'onNewsletterTabClick',
        );

        this.state = {
            selectedNewsletterKey: 'competitive-insights',
            hasBeenJustCreated   : false,
            newsletters          : {},
        };
    }

    /**
     * Trigger once
     */
    componentDidMount() {
    }

    /**
     * Update state from props
     *
     * @param {object} props Current properties
     * @param {object} state Current state
     */
    static getDerivedStateFromProps(props, state) {
        const { activateOptions, newsletterStream } = props,
            newState                                = Newsletters.getNewState(props, state),
            {
                selectedNewsletterKey, newsletters
            } = newState,
            { key, entityToAdd }                    = activateOptions || {},
            activatedNewsletter                     = newsletters[key],
            { entities }                            = activatedNewsletter || {};

        // Remove the previously created model to display the wait for view
        if(state.hasBeenJustCreated && props.active !== state.active) {
            delete newState.newsletters[selectedNewsletterKey];
            newState.hasBeenJustCreated = false;
        }

        // Keep the current active state
        newState.active = props.active;

        // Move on newsletter type (using activateOptions)
        if (key) {
            newState.selectedNewsletterKey = key;
        }

        // Add entityToAdd from activateOptions
        if (entityToAdd && entities && entities.indexOf(entityToAdd) === -1) {
            entities.push(entityToAdd);
        }

        newState.previousNewslettersList = newsletterStream;

        return newState;
    }


    /**
     * Get newState
     *   (Merge newsletters from props and state) when props.newsletters change.
     *
     * @param {object} props Current properties
     * @param {object} state Current state
     */
    static getNewState(props, state) {
        const { newsletterStream, getModelsOfType } = props,
            newState                                = { ...state },
            { previousNewslettersList}              = newState,
            newsletters                             = getModelsOfType('newsletter');

        // Iterate on newsletters in props to detect entities change
        newsletters?.forEach && newsletters.forEach(newsletter => {
            const newsletterFromState = state.newsletters[newsletter.key];

            // Must overwrite state.newsletters entities with updated newsletter in props
            if (
                newsletter && newsletterFromState
                && newsletterStream !== previousNewslettersList
            ) {
                newState.newsletters[newsletter.key] = newsletter;
            }
        });


        return newState;
    }


    /**
     * Update a newsletter model
     *
     * @param object model The updated newsletter model
     *
     * @returns void
     */
    update(model) {
        const  { newsletters } = this.state;

        // Update newsletters in this state
        if(this.shouldDisplaySettings() || !model.id) {
            newsletters[model.key] = model;
            this.setState({
                newsletters
            });
            return;
        }
    }


    /**
     * Save
     *
     * @param {*} model
     */
    save(model) {
        const { saveNewsletter } = this.props,
            { newsletters }      = this.state;

        // Reset this newletter in this state
        newsletters[model.key] = null;
        this.setState({
            newsletters
        });

        // Update newsletter in store
        saveNewsletter(model);
    }


    /**
     * Returns a boolean indicate whether the settings view should be diplayed or not
     *
     * @return {boolean}
     */
    shouldDisplaySettings() {
        const { newslettersState } = this.props       || {},
            { openedSettings }     = newslettersState || {},
            newsletter             = this.getNewsletterFromStore(),
            { key }                = newsletter || {};

        return openedSettings.indexOf(key) !== -1;
    }


    /**
     * Get newsletter from redux
     */
    getNewsletterFromStore() {
        const { newslettersState, getModelsOfType } = this.props,
            newsletters                             = getModelsOfType('newsletter'),
            { selectedNewsletterKey }               = this.state,
            newslettersOfTabs                       = newsletters?.filter
                ? newsletters.filter(newsletter => newsletter.key === selectedNewsletterKey)
                : null,
            { newsletter:openId }                   = newslettersState;

        if (newslettersOfTabs && newslettersOfTabs.find) {
            return _.cloneDeep(newslettersOfTabs.find(nl => nl.id === openId) || newslettersOfTabs[0]);
        }

        return false;
    }


    /**
     * Get newsletter from key
     *
     * @param {string} key
     */
    getNewsletter() {
        const{
                newsletters: newslettersInState,
                selectedNewsletterKey
            }                   = this.state,
            newsLetterFromStore = this.getNewsletterFromStore();

        if(newslettersInState[selectedNewsletterKey] && !newslettersInState[selectedNewsletterKey].date_delete) {
            return newslettersInState[selectedNewsletterKey];
        }

        if (newsLetterFromStore && !newsLetterFromStore.date_delete) {
            newslettersInState[newsLetterFromStore.key] = newsLetterFromStore;
            this.setState({ newsletters: newslettersInState });
            return newsLetterFromStore;
        }

        return false;
    }

    /**
     * Init the creation process
     *
     * @return self
     */
    initCreationProcess() {
        const { newsletters, selectedNewsletterKey } = this.state,
            newsletterType                           = this.getNewsletterType();

        newsletters[selectedNewsletterKey] = {
            type    : 'newsletter',
            label   : newsletterType.label,
            key     : newsletterType.key,
            period  : 'week',
            settings: {},
            entities: [],
        };

        // Affect a newsletter template to init the creation process
        this.setState({
            newsletters
        });
    }

    /**
     * Create a new newsletter base on the one stored in state
     *
     * @return void
     */
    create() {
        const { addItems, closeSettings } = this.props,
            newsletter                    = this.getNewsletter();

        // Post the new newsletter
        addItems(newsletter);

        // Ensure to have settings closed
        closeSettings(newsletter);

        // Wait, then, clean the current state
        this.setState({
            hasBeenJustCreated: true
        });
    }


    /**
     * On tab click
     *
     * @param {ClickEvent} e
     */
    onNewsletterTabClick(e) {
        const { activateShortcut } = this.props,
            { currentTarget }      = e,
            { dataset }            = currentTarget,
            { key }                = dataset;

        if (key) {
            activateShortcut('newsletters', { key });
        }
    }

    /**
     * Render newsletters tabs
     *
     * @returns JSX
     */
    renderNewsletterTabs() {
        const { selectedNewsletterKey } = this.state,
            otherNewslettersType        = _.clone(newslettersType),
            firstNewsletterType         = otherNewslettersType.shift(),
            firstIsSelected             = !selectedNewsletterKey || firstNewsletterType.key === selectedNewsletterKey;

        return (
            <div className="newsletters-tabs">
                <span
                    className={firstIsSelected ? 'selected' : 'selectable'}
                    onClick={firstIsSelected ? undefined : this.onNewsletterTabClick}
                    data-key={firstNewsletterType.key}
                    data-qa-key={firstNewsletterType.key}
                >
                    {firstNewsletterType.tabLabel}
                </span>
                {otherNewslettersType.map(newsletterType => {
                    const isSelected = newsletterType.key === selectedNewsletterKey;
                    return (
                        <React.Fragment key={newsletterType.key}>
                            <span className="separator" />
                            <span
                                className={isSelected ? 'selected' : 'selectable'}
                                onClick={isSelected ? undefined : this.onNewsletterTabClick}
                                data-key={newsletterType.key}
                                data-qa-key={newsletterType.key}
                            >
                                {newsletterType.tabLabel}
                            </span>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }

    /**
     * Render the module when there's no already created newsletters
     *
     * @return JSX
     */
    renderNewsletter() {
        const { openSettings, closeSettings } = this.props,
            newsletter                        = this.getNewsletter();

        if(!newsletter) {
            return false;
        }

        return (
            <>
                {this.renderNewsletterTabs()}
                <Newsletter
                    {...this.props}
                    newsletter={newsletter}
                    displaySettings={this.shouldDisplaySettings()}
                    update={this.update}
                    save={this.save}
                    create={this.create}
                    openSettings={openSettings}
                    closeSettings={closeSettings}
                />
            </>
        );
    }

    /**
     * Get actual newsletter type definition
     */
    getNewsletterType() {
        const { selectedNewsletterKey } = this.state;

        return newslettersType.find(n => n.key === selectedNewsletterKey);
    }

    /**
     * Return the view when there's no newsletter
     *
     * @return JSX
     */
    renderEmptyList() {
        const newsletterType = this.getNewsletterType();

        return (
            <div className="init-step">
                {this.renderNewsletterTabs()}
                <div className="content">
                    <div className="background">
                        <Icon id="innovation-newsletter" width={250}
                            height={360} color="#f5f5f5"
                        />
                    </div>
                    <div className="welcome-settings">
                        <div className="welcome-title">
                            Welcome to your personalized Insight Feed
                        </div>
                        <div className="welcome-text">
                            <div>Set up parameters in only 3 clicks and</div>
                            <div className="featured-text">get your insight feed on {newsletterType.subject}</div>
                            <div>tailored and prepared just for you.</div>
                        </div>
                    </div>
                    <Button
                        type="primary"
                        onClick={this.initCreationProcess}
                        title="Go!"
                    >
                        Go!
                    </Button>
                </div>
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
            newsletterStream,
        } = this.props;

        // Newsletters are not already loaded
        if (!newsletterStream) {
            return false;
        }

        return  this.renderNewsletter() || this.renderEmptyList();
    }

}

Newsletters.propTypes = {
    active          : PropTypes.bool,
    activateOptions : PropTypes.object,
    closeSettings   : PropTypes.func,
    addItems        : PropTypes.func,
    openSettings    : PropTypes.any,
    saveNewsletter  : PropTypes.func,
    activateShortcut: PropTypes.func,
    getModelsOfType : PropTypes.func,
    newsletterStream: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newslettersState: PropTypes.object
};

Newsletters.defaultProps = {
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const newsletterStream = state.getIn(['userView', 'newsletter', 'list']);

    return {
        newsletterStream
    };
};

export default connect(mapStateToProps, {
    addItems,
    saveNewsletter,
    openSettings,
    activateShortcut,
    closeSettings,
    getModelsOfType,
})(Newsletters);

