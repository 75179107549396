import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { Collection }       from 'helpers';

// Import './assets/related-to.less';

/**
* Draw Id cards reference
*
*/
class RelatedTo extends Component  {

    /**
    * Instanciate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'registerCallbacks');

        this.state = {
            callbacks: {}
        };
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { registerCallbacks } = this.props,
            { callbacks }           = this.state,
            /** Return a empty object */
            defaultCb               = () => {};

        callbacks[action] = cb || defaultCb;

        this.setState({ callbacks });

        // Register callback on Element base componant
        registerCallbacks(action, cb);
    }

    /**
    * Render all the references as a list
    *
    * @return JSX
    */
    render() {
        const {
                className, onClick,
                model, parameters,
            } = this.props,
            { attributePath } = parameters;

        return (
            <Collection
                mode="inline"
                allowExport={false}
                className={className}
                entities={model[attributePath]}
                onClick={onClick}
                logoSize={14}
                registerCallbacks={this.registerCallbacks}
            />
        );
    }

}

RelatedTo.propTypes = {
    element          : PropTypes.shape({}).isRequired,
    model            : PropTypes.shape({}),
    className        : PropTypes.string,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    parameters       : PropTypes.shape({
        attributePath: PropTypes.any
    }),
    
};

RelatedTo.defaultProps = {
    model: false,
};

export default RelatedTo;

