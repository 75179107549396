import React, { Component } from 'react';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import {
    hsl                 as d3Hsl,
    interpolateRgb      as d3InterpolateColor,
} from 'd3';

import './Foamtree/main.less';

/**
 * The Foamtree graph Component
 *
 */
class Foamtree extends Component {

    /**
    * Get colors
    *
    * @params options object { nextProps, state }
    *
    * @return object
    */
    static getColors(options) {
        const { nextProps } = options,
            { color } = nextProps,
            dc        = d3Hsl(color),
            colors    = {
                rainbow: [
                    dc.toString(),
                    dc.brighter(1.5).toString(),
                ],
            };

        colors.gradient = d3InterpolateColor(colors.rainbow[1], colors.rainbow[0]);

        return colors;
    }

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render', 'onItemClick', 'reset');

        this.state = {
            serie: null
        };

        this.graphNode   = React.createRef();
        this.activeGroup = null;

        // Make a uuid
        this.id = _.uniqueId('foamtree-chart');
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        this.startGraphProcess();
    }

    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidUpdate() {
        this.startGraphProcess();
    }

    /**
    * Launch graph process
    *  to drawn graph
    *
    */
    startGraphProcess() {
        const {
                content, noContent, contentChange, filterValues
            } = this.props,
            { serie }                                             = this.state;

        // NO data
        if (_.isNull(serie) || serie.length === 0) {
            return;
        }

        // First Draw
        if (!this.foamtree) {
            this.initializeFoamtree();
        }

        // Update foamtree
        if (!_.isUndefined(this.foamtree)) {
            // Set up new root data
            if (filterValues.length === 0) {
                this.foamtree.set({
                    dataObject: {
                        groups: serie
                    }
                });
                return;
            }

            if (contentChange && filterValues.length > 0) {
                clearTimeout(this.updateGroupTimeout);

                // This.foamtree.open({ all: true, open: false });
                this.foamtree.select(this.activeGroup);

                // Set up filterValues
                if (filterValues) {
                    this.foamtree.expose(filterValues[0]);
                }

                // NOT used =>
                // Use only first level
                if (!this.activeGroup || this.activeGroup.level === 1) {
                    return;
                }

                this.foamtree.expose(this.activeGroup).then(() => {
                    if (!noContent) {
                        this.updateGroupTimeout   = setTimeout(() => {
                            const groupToPopulate = this.activeGroup,
                                level             = (groupToPopulate.level || 0) + 1;

                            content.forEach((data) => {
                                data.filterValues = filterValues;
                                data.filterValue  = data.id;
                                data.id           = `${filterValues.join()}${data.id}`;
                                data.parent       = groupToPopulate;
                                data.level        = level;
                            });

                            // Disable populate ( No digg )
                            /* GroupToPopulate.groups = content;
                            this.foamtree.attach({ groups: groupToPopulate });
                            */

                            // This.foamtree.attach(groupToPopulate, 3);
                            // This.foamtree.update();
                            //  This.foamtree.update(groupToPopulate);

                            // This.foamtree.redraw(true, groupToPopulate);

                            this.foamtree.open({ groups: groupToPopulate, open: true });
                            // This.foamtree.open(true);
                            /* This.foamtree.set({
                                dataObject: {
                                    groups: content
                                }
                            });
                            */

                            // This.foamtree.expose(groupToPopulate).then(() => this.foamtree.open(groupToPopulate));
                            // This.foamtree.reset();
                        }, 500);
                    }
                });
            }
        }
    }

    /**
    * Reset the foamtree
    *
    * @return void
    */
    reset() {
        const { filterCb } = this.props;

        this.activeGroup = null;
        this.foamtree.expose([]);
        filterCb([]);
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data change
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { data, content, noContent } = nextProps,
            { filterValues }               = nextProps,
            { serie }                      = prevState,
            newserie                       = filterValues.length === 0
                ? content
                : (noContent ? null : serie || content);

        return {
            colors: Foamtree.getColors({ nextProps }),
            serie : newserie || null,
            data,
        };
    }

    /**
    * On item click
    *
    * @return boolean
    */
    onItemClick(carrotEvent) {
        const { filterCb } = this.props,
            itemId             = _.get(carrotEvent, 'group.filterValue') || _.get(carrotEvent, 'group.id'),
            // BottomGroup        = _.get(carrotEvent, 'bottommostOpenGroup'),
            clickType          = carrotEvent.type;

        // Add filter
        if (clickType === 'click' && filterCb && itemId) {
            // Const newFilterValues = _.xor(filterValues,[itemId]),
            const newFilterValues = [itemId],  // Just one item for now..
                group = carrotEvent.secondary ? carrotEvent.bottommostOpenGroup : carrotEvent.topmostClosedGroup;

            if (this.activeGroup && group && group.id === this.activeGroup.id) {
                this.reset();
                return;
            }

            filterCb(newFilterValues);
            this.activeGroup = group;
            this.foamtree.expose(carrotEvent.group);
        }

        return true;
    }

    /**
    * Get filter (key and values)
    *
    * @return void
    */
    getFilter() {
        const { filterValues } = this.props,
            filter              = filterValues.find((filter) => _.isUndefined(filter.bind));  // Filter not use specific bind

        return filter;
    }

    /**
    * Initialize the foamtree
    *
    * @return void
    */
    initializeFoamtree() {
        const { colors, serie } = this.state,
            animationIsDisabled = window.animationIsDisabled && window.animationIsDisabled();

        if (!_.isUndefined(this.foamtree) || !window.CarrotSearchFoamTree.supported) {
            return;
        }

        this.foamtree = new window.CarrotSearchFoamTree({
            element             : this.graphNode.current,
            zoomMouseWheelFactor: 1,
            groupColorDecorator : (opts, params, vars) => {
                const itemId     = _.get(params, 'group.id'),
                    filter       = this.getFilter(),
                    filterValues = _.get(filter, 'values', []);

                vars.groupColor = filterValues.includes(itemId) ?  colors.gradient(100) : colors.gradient(params.weightNormalized); // eslint-disable-line
                vars.labelColor = 'auto';   // eslint-disable-line
            },
            dataObject: {
                groups: serie
            },
            rolloutDuration           : 0,
            pullbackDuration          : 0,
            fadeDuration              : animationIsDisabled ? 0 : 500,
            exposeDuration            : animationIsDisabled ? 0 : 500,
            onGroupClick              : this.onItemClick,
            onGroupDoubleClick        : (e) => { e.preventDefault(); },
            groupExposureScale        : 1.0,
            groupExposureZoomMargin   : 0.03,
            groupExposureShadowSize   : 10,
            groupStrokeWidth          : 0.5,
            groupSelectionOutlineWidth: 1,
            groupBorderWidth          : 1.5, // Space inner cell
            groupInsetWidth           : 2,  // Margin of cell
        });

        this.foamtree.set({
            dataObject: {
                groups: serie
            }
        });
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                width, height, skeleton,
                skeletonGradient, dataIsLoaded,
            } = this.props,
            style = skeleton ? {
                backgroundImage: skeletonGradient,
            } : null;

        if (skeleton) { return (<div className="Foamtree skeleton" style={style} />); }
        return (
            <div
                className="Foamtree"
                ref={this.graphNode}
                style={{
                    width  : `${width}px`,
                    height : `${height}px`,
                    opacity: !dataIsLoaded ? 0.4 : 1,
                }}
            />
        );
    }

}

/**
 * Props type
 */
Foamtree.propTypes = {
    content         : PropTypes.array,
    contentChange   : PropTypes.any,
    data            : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    dataIsLoaded    : PropTypes.bool,
    filterCb        : PropTypes.func,
    filterValues    : PropTypes.array,
    height          : PropTypes.number.isRequired,
    noContent       : PropTypes.any,
    skeleton        : PropTypes.bool,
    skeletonGradient: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    width           : PropTypes.number.isRequired,
};

/**
 * Default props value
 */
Foamtree.defaultProps = {
    skeleton        : false,
    skeletonGradient: false,
    dataIsLoaded    : true,
};

export default Foamtree;

