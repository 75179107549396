import { Component } from 'react';
import PropTypes     from 'prop-types';
import { connect }   from 'react-redux';
import { post }      from '../core/utils/api';

/**
* Manage errors for the whole application
*
* @return self
*/
class ErrorBoundary extends Component {

    /**
    * Contruct the state
    */
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    /**
    * Use this to catch an error
    *
    * @return void
    */
    componentDidCatch(error/* , info */) {
        // Fallback to the UI
        this.setState({ hasError: true });

        // Log to the tracer
        post('/bug-report', {
            error: error.toString(),
            info : {
                build  : process.env.BUILD,
                stack  : error.stack,
                message: error.message,
                type   : error.name,
            }
        });
    }

    /**
    * Render fallbacks
    *
    */
    render() {
        const { children } = this.props,
            { hasError }   = this.state;

        if (hasError) {
            // You can render any custom fallback UI
            return false;
        }

        return children;
    }

}

/**
* Validate properties for current Component
*/
ErrorBoundary.propTypes = {
    children: PropTypes.shape({}).isRequired
};

/**
 * Bind the store to to component
 */
const mapStateToProps = state => ({}); // eslint-disable-line no-unused-vars

export default connect(mapStateToProps, {})(ErrorBoundary);

