import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                           from 'lodash';

import {
    scaleLinear      as D3ScaleLinear,
}                                    from 'd3';

import NodesLabels                 from './NodesLabels';
import Links                       from './Links';
import Nodes                       from './Nodes';
import Hulls                       from './Hulls';
import LinksValues                 from './LinksValues';


/**
 * Render networks nodes
 *
 */
class NetworkBase extends Component {

    /**
    * Initialize the component
    *
    * @params props
    *
    * @return void
    */
    constructor(props) {
        super(props);

        this.state = {
            oldGD3     : null,
            oldPlotData: null,
        };

        _.bindAll(this, 'getSizeScale', 'getLinksScaleLinear');
    }


    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidMount() {

    }

    /**
    * Triggered when the component can be modified (run side effects)
    *
    * @return void
    */
    componentDidUpdate() {
        const { onRenderBase } = this.props;

        onRenderBase();
    }


    /**
     * Render hulls
     *
     * @returns html
     */
    renderHulls() {
        return (
            <Hulls
                {...this.props}
            />
        );
    }


    /**
     * From links coworks domain return a lienar scale
     *
     * @returns d3linearScale
     */
    getLinksScaleLinear() {
        const { gD3 } = this.props,
            linksDomain= gD3.linksCoworksDomain;

        return D3ScaleLinear()
            .domain(linksDomain);
    }


    /**
     *
     * @returns
    */
    renderLinksValues() {
        const {
            zoomScale,
            linkValueClick, linkValueOver, linkValueOut,
            selectedNodesIds,
            highlightedLinks,
            linkValueOvered,
        } = this.props;

        return (
            <LinksValues
                zoomScale={zoomScale}
                onMouseUp={linkValueClick}
                onMouseOver={linkValueOver}
                onMouseOut={linkValueOut}
                selectedNodesIds={selectedNodesIds}
                linkValueOvered={linkValueOvered}
                highlightedLinks={highlightedLinks}
                getSizeScale={this.getSizeScale}
                getLinksScaleLinear={this.getLinksScaleLinear}
            />
        );
    }

    /**
    * Render the nodes
    *
    * @return html
    */
    renderNodesLabels() {
        const {
                zoomScale, nodeTitleClick,
                gD3, linkValueOvered, selectedSearchNode,
                highlightedNodes, overedNode
            } = this.props,
            nodes = linkValueOvered
                ? [linkValueOvered.source, linkValueOvered.target]
                : highlightedNodes,
            nodesToRender   = overedNode ? nodes.concat(overedNode) : nodes,
            nodesToRender2  = selectedSearchNode ? _.uniq(nodesToRender.concat(selectedSearchNode)) : nodesToRender;

        return (
            <NodesLabels
                gD3={gD3}
                zoomScale={zoomScale}
                nodeTitleClick={nodeTitleClick}
                getSizeScale={this.getSizeScale}
                nodes={nodesToRender2}
            />
        );
    }


    /**
     * Get box coordinate contains all nodes
     */
    getBoxOfNodes() {
        const { plotData } = this.props,
            { nodes }      = plotData,
            box            = {
                minX: null,
                maxX: null,
                minY: null,
                maxY: null,
            };

        nodes.forEach(node => {
            if (_.isNull(box.minX) || box.minX > node.x) { box.minX = node.x; }
            if (_.isNull(box.minY) || box.minY > node.y) { box.minY = node.y; }
            if (_.isNull(box.maxX) || box.maxX < node.x) { box.maxX = node.x; }
            if (_.isNull(box.maxY) || box.maxY < node.y) { box.maxY = node.y; }
        });

        return box;
    }


    /**
     * Render the all links at the background
     */
    renderAllLinks() {
        const {
                plotData, zoomScale,
                onMouseDown, onMouseUp,
                linkStyle, forceRenderLinks,
            }         = this.props,
            { links } = plotData;

        return (
            <g className="links all-links">
                <Links
                    zoomScale={zoomScale}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    linkStyle={linkStyle}
                    links={links}
                    linkColor="#DADADA"
                    getSizeScale={this.getSizeScale}
                    getLinksScaleLinear={this.getLinksScaleLinear}
                    forceRender={forceRenderLinks}
                />
            </g>
        );
    }


    /**
     * Render the all links at the background
     */
    renderCLusterLinks() {
        const {
                zoomScale, plotData,
                onMouseDown, onMouseUp,
                linkStyle, forceRenderLinks,
            }                 = this.props,
            { clustersLinks } = plotData;

        return (
            <g className="links clusters-links">
                <Links
                    zoomScale={zoomScale}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    linkStyle={linkStyle}
                    links={clustersLinks}
                    linkColor="red"
                    getSizeScale={this.getSizeScale}
                    getLinksScaleLinear={this.getLinksScaleLinear}
                    forceRender={forceRenderLinks}
                />
            </g>
        );
    }



    /**
     * Render white rectangle to mask unselected things
     */
    renderWhiteMask(box) {
        const { sizeRange, onMouseMove } = this.props,
            maxSize                      = sizeRange[1];

        return (
            <rect
                onMouseMove={onMouseMove}
                fill="white"
                opacity={0.7}
                x={box.minX - maxSize / 2}
                y={box.minY - maxSize / 2}
                width={box.maxX - box.minX + maxSize}
                height={box.maxY - box.minY + maxSize}
            />
        );
    }


    /**
     * Render highlighted links
     */
    renderHighlightedLinks() {
        const {
            zoomScale, selectedLinkColor,
            onMouseDown, onMouseUp, nodeDraggedId,
            linkStyle, highlightedLinks
        } = this.props;

        return (
            <g className="links highlighted-links">
                <Links
                    zoomScale={zoomScale}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    linkStyle={linkStyle}
                    links={highlightedLinks}
                    linkColor={selectedLinkColor}
                    getSizeScale={this.getSizeScale}
                    getLinksScaleLinear={this.getLinksScaleLinear}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }


    /**
     * Render overed links (links-value mouse over)
     */
    renderOveredLinks() {
        const {
            zoomScale, nodeDraggedId,
            onMouseDown, onMouseUp, linkValueOvered,
            linkStyle, selectedNodeColor
        } = this.props;

        return (
            <g className="links overed-links">
                <Links
                    zoomScale={zoomScale}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    linkStyle={linkStyle}
                    links={[linkValueOvered]}
                    linkColor={selectedNodeColor}
                    getSizeScale={this.getSizeScale}
                    getLinksScaleLinear={this.getLinksScaleLinear}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }

    /**
     * Get sizeScale
     *
     * @return function
     */
    getSizeScale() {
        const { gD3, sizeRange } = this.props,
            sizeDomain           = gD3.getSizeDomain();

        return D3ScaleLinear().domain(sizeDomain).range(sizeRange);
    }


    /**
     * Render unselected Nodes
     */
    renderAllNodes(options) {
        const {
                plotData,
                zoomScale,
                nodeOver,
                nodeColor,
                nodeDraggedId,
            } = this.props,
            { nodes } = plotData;

        return (
            <g className="nodes unselected-nodes">
                <Nodes
                    {...options}
                    nodes={nodes}
                    zoomScale={zoomScale}
                    nodeOver={nodeOver}
                    borderColor={nodeColor}
                    getSizeScale={this.getSizeScale}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }


    /**
     * Render highlight Nodes
     */
    renderHighlightNodes() {
        const {
            zoomScale,
            nodeColor,
            highlightedNodes,
            nodeOver,
            nodeDraggedId
        } = this.props;

        return (
            <g className="nodes highlight-nodes">
                <Nodes
                    nodes={highlightedNodes}
                    zoomScale={zoomScale}
                    nodeOver={nodeOver}
                    borderColor={nodeColor}
                    getSizeScale={this.getSizeScale}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }



    /**
     * Render highlight Node
     */
    renderSelectedNodes() {
        const {
                zoomScale,
                nodeOver,
                nodesByIds,
                selectedNodesIds,
                linkValueOvered,
                nodeDraggedId,
                selectedNodeColor
            } = this.props,
            selectedNodes = selectedNodesIds.map(nodeId => nodesByIds[nodeId])
                // Filter undefined node
                .filter(node => node),
            linkValueOveredNodes = linkValueOvered
                ? [linkValueOvered.source, linkValueOvered.target]
                : null;

        return selectedNodes.length && (
            <g className="nodes selected-node">
                <Nodes
                    nodes={linkValueOveredNodes || selectedNodes}
                    zoomScale={zoomScale}
                    nodeOver={nodeOver}
                    borderColor={selectedNodeColor}
                    borderSize={30 / zoomScale}
                    getSizeScale={this.getSizeScale}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }

    /**
    * Render highlight Node
    */
    renderOverNode() {
        const {
            zoomScale,
            nodeOut,
            overedNode,
            nodeDraggedId,
            selectedNodeColor
        } = this.props;

        return overedNode && (
            <g className="nodes overed-node">
                <Nodes
                    nodes={[overedNode]}
                    zoomScale={zoomScale}
                    nodeOut={nodeOut}
                    borderColor={selectedNodeColor}
                    borderSize={30 / zoomScale}
                    getSizeScale={this.getSizeScale}
                    scaleFactor={1.05}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }

    /**
    * Render searched Node
    */
    renderSelectedSearchNode() {
        const {
            zoomScale,
            nodeOut,
            nodeOver,
            selectedSearchNode,
            nodeDraggedId,
            selectedNodeColor
        } = this.props;

        return selectedSearchNode && (
            <g className="nodes searched-node">
                <Nodes
                    nodes={[selectedSearchNode]}
                    zoomScale={zoomScale}
                    nodeOut={nodeOut}
                    nodeOver={nodeOver}
                    borderColor={selectedNodeColor}
                    borderSize={40 / zoomScale}
                    getSizeScale={this.getSizeScale}
                    scaleFactor={1.05}
                    forceRender={!nodeDraggedId}
                />
            </g>
        );
    }

    /**
    * Render the network
    *
    * @return html
    */
    render() {
        const {
                forwardedRef, onMouseDown, onMouseUp, selectedSearchNode,
                linkValueOvered, highlightedLinks, nodeDraggedId
            }          = this.props,
            boxOfNodes = this.getBoxOfNodes();

        return (
            <g className="network"
                ref={forwardedRef}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                {/* Render All things at the background */}
                {this.renderAllLinks()}
                {/* A this.renderCLusterLinks() */}

                {this.renderAllNodes()}

                {/* Render the mask to change opacity of under layer */}
                {
                    (highlightedLinks.length || selectedSearchNode)
                    && this.renderWhiteMask(boxOfNodes)
                }

                {this.renderHulls()}

                {/* Render nodes just for handle mouse event over the mask */}
                {this.renderAllNodes({ noPaint: true})}

                {!linkValueOvered && this.renderHighlightedLinks()}

                {linkValueOvered && this.renderOveredLinks()}

                {this.renderNodesLabels()}

                {!linkValueOvered && this.renderHighlightNodes()}

                {this.renderSelectedNodes()}

                {!linkValueOvered && this.renderOverNode()}

                {this.renderSelectedSearchNode()}

                {!nodeDraggedId && this.renderLinksValues()}
            </g>
        );
    }

}

/**
 * Props type
 */
NetworkBase.propTypes = {
    gD3               : PropTypes.shape().isRequired,
    plotData          : PropTypes.shape().isRequired,
    gD3SizeScale      : PropTypes.func.isRequired,
    sizeRange         : PropTypes.array.isRequired,
    onRenderBase      : PropTypes.func.isRequired,
    forwardedRef      : PropTypes.shape({}).isRequired,
    sameData          : PropTypes.bool.isRequired,
    nodeTitleClick    : PropTypes.func,
    onMouseDown       : PropTypes.func.isRequired,
    onMouseUp         : PropTypes.func.isRequired,
    onMouseMove       : PropTypes.func.isRequired,
    nodeOver          : PropTypes.func.isRequired,
    nodeOut           : PropTypes.func.isRequired,
    linkValueClick    : PropTypes.func.isRequired,
    linkValueOver     : PropTypes.func.isRequired,
    linkValueOut      : PropTypes.func.isRequired,
    zoomScale         : PropTypes.number.isRequired,
    nodesByIds        : PropTypes.shape().isRequired,
    linksByIds        : PropTypes.shape().isRequired,
    selectedNodesIds  : PropTypes.array.isRequired,
    overedNode        : PropTypes.shape(),
    selectedSearchNode: PropTypes.shape(),
    linkValueOvered   : PropTypes.shape(),
    nodeDraggedId     : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    highlightedNodes  : PropTypes.array.isRequired,
    highlightedLinks  : PropTypes.array.isRequired,
    normalLinks       : PropTypes.array.isRequired,
    linkStyle         : PropTypes.string,
    linkWidth         : PropTypes.number,
    nodeColor         : PropTypes.string,
    selectedNodeColor : PropTypes.string,
    selectedLinkColor : PropTypes.string,
    clustersLinks     : PropTypes.any,
    forceRenderLinks  : PropTypes.bool,
};

/**
 * Default props value
 */
NetworkBase.defaultProps = {
    linkWidth        : 0.25,
    nodeColor        : '#555',
    selectedNodeColor: 'var(--secondary-color)',
    selectedLinkColor: '#10285D',
    nodeTitleClick   : null,
};

export default NetworkBase;

