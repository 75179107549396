import _                     from 'lodash';
import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { Dropdown }          from 'antd';
import { Icon }              from 'helpers';

// CSS
import './Select/assets/main.less';

/**
* Create a custom select
*/
class Select extends Component  {

    /**
    * Instanciate the modal
    */
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                range            : 0,
                search           : '',
                limit            : {  min: 0, max: 0 },
                order            : 'asc',
                orderFields      : ['label'],
                orderByPagination: false
            },
            selectedOption: null,
            inputIsFocused: false,
        };

        this.inputRef        = React.createRef();
        this.ref             = React.createRef();

        this.isLoading       = false;
        this.typingTimerWait = null;

        _.bindAll(this, 'onchangeOption', 'onSearch', 'reset', 'fetch', 'fetchNextPage',
            'onDataFetch', 'onInputFocus', 'onInputBlur', 'blurInput', 'focusInput',
            'getOptions', 'getSearch', 'getMenuItems', 'onDropDownScroll'
        );
    }

    /**
     * Call when the component mount
     *
     * @returns void
     */
    componentDidMount() {
        this.setState({
            pagination: this.getPaginationFromProps()
        });
    }

    /**
     * Get the dropdown html node
     *
     * @returns HTMLNode
     */
    getDropDownNode() {
        const currentSelect = this.ref?.current;

        return currentSelect?.parentNode.querySelector('.ant-dropdown');
    }


    /**
     * Componant did update
     *
     * @returns void
     */
    componentDidUpdate() {
        const { pagination } = this.state,
            { search }       = pagination,
            { current }      = this.inputRef,
            dropDownNode     = this.getDropDownNode();

        // Focus on input node
        if (search !== '') {
            current.focus();
        }

        // Attach scroll event to the dropdown
        if (dropDownNode) {
            dropDownNode.removeEventListener('scroll', this.onDropDownScroll);
            dropDownNode.addEventListener('scroll', this.onDropDownScroll);
        }
    }


    /**
     * Clean events before unmount the component
     *
     * @returns void
     */
    componentWillUnmount() {
        const dropDownNode = this.getDropDownNode();

        if (dropDownNode) {
            dropDownNode.removeEventListener('scroll', this.onDropDownScroll);
        }
    }


    /**
     * Get pagination object from props
     *
     * @returns object
     */
    getPaginationFromProps() {
        const { pagination } = this.state,
            {
                range, orderFields,
                orderByPagination
            } = this.props;

        if (range !== null && typeof range !== 'undefined') {
            pagination.range = range;
            pagination.limit.max = range;
        }
        if (orderFields) {
            pagination.orderFields = orderFields;
        }

        if (orderByPagination !== null && typeof orderByPagination !== 'undefined') {
            pagination.orderByPagination = orderByPagination;
        }

        return pagination;
    }


    /**
     * Get the search
     *
     * @returns string
     */
    getSearch() {
        const { pagination } = this.state,
            { search }       = pagination;

        return search;
    }

    /**
     * Reset the component: pagination to limit 0
     *
     * @returns void
     */
    reset(params) {
        const { keepSelectedOption } = params || {},
            { selectedOption }       = this.state,
            pagination               = this.getPaginationFromProps();

        this.setState(
            {
                pagination: {
                    ...pagination,
                    limit: {  min: 0, max: pagination.range },
                },
                selectedOption: keepSelectedOption ? selectedOption : null,
            },
            () => {
                this.fetch();
            }
        );
    }


    /**
     * Fetch the options
     *
     * @returns void
     */
    fetch() {
        const { updateData } = this.props,
            { pagination }   = this.state;

        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        updateData(pagination, this.onDataFetch);
    }


    /**
     * Get the next page options
     *
     * @returns void
     */
    fetchNextPage() {
        const { pagination } = this.state;

        if (this.isLoading) {
            return;
        }

        pagination.limit.min += pagination.range;
        pagination.limit.max += pagination.range;

        this.setState(
            { pagination },
            () => {
                const { updateData } = this.props;

                this.isLoading = true;
                updateData(pagination, this.onDataFetch);
            }
        );
    }

    /**
     * On Data fetched
     *
     * @returns void
     */
    onDataFetch(data) {
        const results = data?.data?.data,
            { pagination } = this.state;

        if (results?.length === 0) {
            // Stop fetching next page
            this.setState({ pagination: { ...pagination, range: 0 } });
        }
        this.isLoading = false;
    }


    /**
     * Trigger on select options changed
     *
     * @param AntdMenuEvent { item, key, keyPath, domEvent }
     *
     * @returns void
     */
    onchangeOption(e) {
        const { onChange } = this.props,
            { key }        = e,
            options        = this.getOptions(),
            option         = options.find(option => option.key === key);

        if (!option) {
            return;
        }

        this.setState(
            {
                selectedOption: option,
                inputIsFocused: false,
            }
        );

        onChange(option.value);
    }


    /**
     * On input focus
     *
     * @returns void
     */
    onInputFocus() {
        this.setState({ inputIsFocused: true });
        this.reset({ keepSelectedOption: true });
    }


    /**
     * On input blur
     *
     * @returns void
     */
    onInputBlur() {
        const { pagination } = this.state;

        window.setTimeout(() => {
            this.setState(
                {
                    inputIsFocused: false,
                    pagination    : { ...pagination, search: ''}
                }
            );
        }, 100);
    }

    /**
     * Blur the input
     */
    blurInput() {
        const { current } = this.inputRef;
        current.blur();
    }

    /**
     * Focus the input
     */
    focusInput() {
        const { current } = this.inputRef;
        current.focus();
    }



    /**
     * Just stop event propagation
     *
     * @params domEvent
     *
     */
    stopPropagation(e) {
        e.preventDefault();
    }


    /**
     * Trigger when the user search
     *
     * @returns void
     */
    onSearch(e) {
        const { pagination }      = this.state,
            { orderByPagination } = this.props,
            { target }            = e,
            { value }             = target;

        if (!orderByPagination) {
            this.setState({
                inputIsFocused: true,
                pagination    : { ...pagination, search: value}
            });
            return;
        }

        pagination.limit.min = 0;
        pagination.limit.max = pagination.range;

        this.setState(
            {
                inputIsFocused: true,
                pagination    : { ...pagination, search: value},

            },
            () => {
                if (this.typingTimerWait) {
                    clearTimeout(this.typingTimerWait);
                }

                this.typingTimerWait = setTimeout(() => {
                    this.reset({ keepSelectedOption: true });
                }, 500);
            }
        );
    }


    /**
     * Order and sort options
     *
     * @returns {array}
     */
    getOptions() {
        const { options } = this.props;

        if (!options) {
            return [];
        }

        const { pagination }         = this.state,
            { search, range, order } = pagination,
            searchUnaccent           = _.camelCase(search).toUpperCase(),
            filteredOptions          = search.length > 0 && range <= 0
                ? options.filter(element => _.camelCase(element.label).toUpperCase().indexOf(searchUnaccent)>=0)
                : options,
            // Force key as string ( Menu.onclick return a string )
            optionsForceKeyString    = filteredOptions.map(option => ({...option, key: String(option.key)}));

        return _.sortBy(
            optionsForceKeyString,
            [`${order === 'desc' ? '-' : ''}label`]
        );
    }

    /**
     * More options are available
     *
     * @returns boolean
     */
    nextPageIsAvailable() {
        const options      = this.getOptions(),
            { pagination } = this.state,
            { max }        = pagination.limit,
            optionsCount   = options.length;

        return (!this.isLoading || max === optionsCount) && pagination.range > 0;
    }


    /**
    * Trigger fetch when the scroll arrived at the bottom
    *
    * @param e UIEvent
    *
    * @returns void
    */
    onDropDownScroll(e) {
        const { target }          = e,
            { orderByPagination } = this.props;


        if (
            target.scrollTop + target.offsetHeight < target.scrollHeight
            || this.isLoading
            || !this.nextPageIsAvailable()
            || orderByPagination === false
        ) {
            return;
        }

        this.fetchNextPage();
    }


    /**
     * Menu item for show the data
     *
     * @returns HTML
     */
    getMenuItems() {
        const options           = this.getOptions(),
            nextPageIsAvailable = this.nextPageIsAvailable(),
            loadingOption       = nextPageIsAvailable ? [{
                key     : '_loading_',
                value   : '_loading_',
                label   : 'loading...',
                disabled: true,
            }] : [],
            mappedOptions     = options.map(option => ({
                key  : option.key,
                label: option.label,
                value: option.value,
            }));

        return [...mappedOptions, ...loadingOption];
    }

    /**
     * Render the input node
     *
     * @returns JSX
     */
    renderInput() {
        const {
                disabled, style, placeholder
            } = this.props,
            {
                selectedOption,
                pagination,
                inputIsFocused
            }                    = this.state,
            { key, label }       = selectedOption || {},
            { search }           = pagination,
            options              = this.getOptions(),
            newPlaceholder          = inputIsFocused && options.length > 0
                ? options[0].label
                : placeholder;

        return (
            <input
                ref={this.inputRef}
                type="text"
                disabled={disabled}
                key={key}
                value={
                    search.length > 0
                        ? search
                        : inputIsFocused ? '' : (label || '')
                }
                placeholder={newPlaceholder}
                style={style}
                onChange={this.onSearch}
                onFocus={this.onInputFocus}
                onBlur={this.onInputBlur}
            />
        );
    }


    /**
     * Render the arrow icon (expand / retract)
     *
     * @returns JSX
     */
    renderArrow() {
        const { inputIsFocused } = this.state,
            iconClass            = inputIsFocused
                ? 'retract-arrow'
                : 'expand-arrow';

        return (
            <Icon
                key={iconClass/* To force re-render on changing iconClass */}
                className={iconClass}
                type={iconClass}
                width={12}
                onClick={inputIsFocused ? this.blurInput : this.focusInput}
            />
        );
    }

    /**
    * Render the report
    *
    * @return Component
    */
    render() {
        const {disabled }      = this.props,
            { inputIsFocused } = this.state,
            classNames         = ['select-insight'];

        if (disabled) {
            classNames.push('disabled');
        }

        return(
            <div ref={this.ref} className="select-insight-container">
                <Dropdown
                    menu={{items: this.getMenuItems(), onClick: this.onchangeOption}}
                    trigger={[]}
                    open={inputIsFocused}
                    disabled={disabled}
                    className={classNames.join(' ')}
                    getPopupContainer={trigger => trigger.parentNode}
                >
                    <div>
                        {this.renderInput()}
                        {this.renderArrow()}
                    </div>
                </Dropdown>
            </div>

        );
    }

}

Select.propTypes = {
    range            : PropTypes.number,
    orderFields      : PropTypes.array,
    orderByPagination: PropTypes.bool,
    style            : PropTypes.object,
    disabled         : PropTypes.string,
    updateData       : PropTypes.func,
    placeholder      : PropTypes.string,
    onChange         : PropTypes.func,
    options          : PropTypes.arrayOf(PropTypes.shape({
        key  : PropTypes.string,
        value: PropTypes.any,
        label: PropTypes.string,
    })),
};

Select.defaultProps = {
    options          : [],
    range            : 0,
    orderFields      : ['label'],
    orderByPagination: false,
    style            : {},
    disabled         : false,
    updateData       : () => {},
    onChange         : () => {},
    placeholder      : '-'
};

export default Select;
