import * as types     from '../actions/types/yoomap';
import { initialMap } from '../initialStates';
import Immutable      from 'immutable';

/**
 * Add Lexicon
 *
 * @param {Immutable.map} state
 * @param {object}        payload
 *
 * @returns state
 */
const storeOrganizations = (state, payload) => {
    const { url, organizations } = payload,
        matchedOrgsByPlatform    = state.get('matchedOrgsByPlatform') || new Immutable.Map(),
        matchedOrgs              = matchedOrgsByPlatform.get(url) || new Immutable.Map();

    let newMatchedOrgs = matchedOrgs;

    // Add organizations from payload to the store
    organizations.forEach(organization => {
        newMatchedOrgs = newMatchedOrgs.set(
            organization.id,
            {
                id_yoomap: organization.id_yoomap,
                status   : organization.status,
                url      : organization.url,
            }
        );
    });

    return state.set('matchedOrgsByPlatform', matchedOrgsByPlatform.set(url, newMatchedOrgs));
};

/**
 * Manage the Yoomap searched organizations slice in the store
 *
 * @return state As a fresh Immutable Map
 */
const Yoomap = (state = initialMap, action) => {
    // Switch over actions
    switch (action.type) {

        // Update the state with lexicons
        case types.STORE_SEARCHED_ORGANIZATION:
            return storeOrganizations(state, action.payload);

        default:
            break;
    }

    return state;
};

export default Yoomap;
