import React, { PureComponent }           from 'react';
import PropTypes                          from 'prop-types';

// Import area renderers
import Layout                             from './Area/Layout';
import FilterableCollection               from './Area/Analyse';
import Dashboard                          from './Area/Dashboard';
import Section                            from './Area/Section';
import DashboardGroup                     from './Area/Dashboard/Group';
import DashboardList                      from './Area/Dashboard/List';
import DashboardItem                      from './Area/Dashboard/Item';
import EntityAuthority                    from './Area/Entity/Authority';
import EntityReferences                   from './Area/Entity/References';
import EntityFamily                       from './Area/Entity/Family';
import EntityWhy                          from './Area/Entity/Why';
import EntityAcquiredBy                   from './Area/Entity/AcquiredBy';
import EntityPartOf                       from './Area/Entity/PartOf';
import EntityIpc                          from './Area/Entity/Ipc';

const Renderers = {
    // Global elements
    Layout,
    // ID cards
    Section,
    // Dashboard elements
    Dashboard,
    Analyse            : FilterableCollection,
    Dataset            : FilterableCollection,
    'Dashboard/Group'  : DashboardGroup,
    'Dashboard/List'   : DashboardList,
    'Dashboard/Item'   : DashboardItem,
    'Entity/Authority' : EntityAuthority,
    'Entity/References': EntityReferences,
    'Entity/Family'    : EntityFamily,
    'Entity/Why'       : EntityWhy,
    'Entity/AcquiredBy': EntityAcquiredBy,
    'Entity/PartOf'    : EntityPartOf,
    'Entity/Ipc'       : EntityIpc,
};

/**
* Create a new Area for elements
*
*/
class Area extends PureComponent  {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
    * Get the graph type
    *
    * @return string
    */
    getType() {
        const { element } = this.props;

        return element.category.replace(/^Area\/?/, '');
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const Renderer = Renderers[this.getType()];

        return <Renderer {...this.props} />;
    }

}

Area.propTypes = {
    element: PropTypes.shape({
        category     : PropTypes.string,
        configuration: PropTypes.object
    }).isRequired,
};

Area.defaultProps = {
};

export default Area;
