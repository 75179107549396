import { useEffect, useContext } from 'react';
import PropTypes                 from 'prop-types';
import { LazyloadContext }       from './LazyLoad';

/**
 * CatchMount component
 */
const CatchMount = props => {
    const { onComponentMount } = useContext(LazyloadContext),
        { children }           = props;

    useEffect(() => {
        if (onComponentMount) {
            onComponentMount();
        }
    }, [children]);

    return children;
};

CatchMount.propTypes = {
    children: PropTypes.any,
};

export default CatchMount;
