/**
 * Instantiate the requestTimeout
 *
 * Cancel function in is the => return.cancel()
 *
 * @param {function} callback
 * @param {integer} delay
 * @returns object
 */
export function requestTimeout(callback, delay, onCancelCb) {
    let requestId = null;

    const timeoutId = setTimeout(() => {
        requestId = requestAnimationFrame(callback);
    }, delay);

    return {
        timeoutId,
        requestId,
        cancel: () => {
            cancelAnimationFrame(requestId);
            clearTimeout(timeoutId);
            onCancelCb && onCancelCb();
        }
    };
}
