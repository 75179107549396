import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import ImmutablePropTypes      from 'react-immutable-proptypes';
import _                       from 'lodash';
import { Skeleton }            from 'antd';
import { connect }             from 'react-redux';
import { learn }               from '../../../store/actions/knowledge';
import { Row, Col, Highlight } from 'helpers';
import DocumentHeader          from './DocumentHeader';

import {
    addBr, formatNumberWithMagnitude,
    renderUnit, renderDateInterval
}                              from 'utils/text';

import Infobox                 from '../utils/Infobox';
import Actors                  from '../utils/Actors';

import './assets/project.less';

/**
* Display a Project
*
*/
class Project extends Component {

    /**
    * Constructor : Initialize the state
    */
    constructor(props) {
        super(props);

        this.state = {
            projectTypes : null,
            novaSources  : null,
            novaLanguages: null,
        };
    }

    /**
    * Make sure the component has loaded the knowledge
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['entities', 'project-types', 'nova-sources', 'nova-languages'])
            .then(this.setState.bind(this));
    }


    /**
    * Get joined labels of a knowledge from ids
    *
    * @return string
    */
    getLabelsOfKnowledge(knowledgeName, ids, separator = ', ') {
        const { [knowledgeName]: values } = this.state;

        return values ? values
            .filter((value) => (ids ? ids.indexOf(value.id) !== -1 : false))
            .map((value) => value.label)
            .join(separator)
            : '';
    }

    /**
    * Render the Infobox
    *
    * @return JSX
    */
    renderInfobox() {
        const { entity } = this.props,
            { projectTypes, novaSources, novaLanguages } = this.state;

        if (!entity || !projectTypes || !novaSources) {
            return (
                <>
                    <Skeleton active paragraph={{ rows: 10 }} />
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                </>
            );
        }

        const currency = entity.currencySymbol,
            projectTypeItem = entity.subtype ? _.find(projectTypes.toArray(), { id: entity.subtype }).label : null,
            novaSourceItem = entity.source ? _.find(novaSources.toArray(), { id: entity.source }).label : null,
            novaLanguageItem = entity.lang ? _.find(novaLanguages.toArray(), { id: entity.lang }).label : null,
            elements = [
                { label: 'Type:', value: projectTypeItem },
                { label: 'Dates:', value: renderDateInterval(entity.start, entity.end) },
                {
                    label: 'Cost:',
                    value: entity.total_cost
                    && renderUnit(formatNumberWithMagnitude(entity.total_cost, 2), currency, true)
                },
                {
                    label: 'Funding:',
                    value: entity.total_funding
                        && renderUnit(formatNumberWithMagnitude(entity.total_funding, 2), currency, true)
                },
                { label: 'Base:', value: novaSourceItem },
                { label: 'Language:', value: novaLanguageItem },
                { label: 'Link:', value: entity.url, isUrl: true }
            ];

        return (
            <Infobox elements={elements} className="entity-information" />
        );
    }

    /**
    * Render the descriptions according to the project type
    *
    * @return JSX
    */
    renderDescriptions() {
        const { entity } = this.props,
            hasKeywords  = entity && entity.keywords,
            description  = entity && _.get(entity, 'abstract_description', false);

        if (!entity) {
            return [
                <Row
                    key="description"
                    label="Description"
                    className="textual"
                >
                    <Skeleton active paragraph={false} />
                </Row>
            ];
        }

        if (entity.sub_type_id === 3 || entity.sub_type_id === 4) {
            return this.renderAwards();
        }

        const descriptions = [
            <Row
                key="description"
                label="Description"
                className="description"
            >
                <Highlight>
                    {addBr(description) || 'No description to display'}
                </Highlight>
            </Row>
        ];

        if (hasKeywords) {
            descriptions.push(
                <Row
                    key="keywords"
                    label="Keywords"
                    className="keywords textual"
                >
                    {entity.keywords}
                </Row>
            );
        }

        return descriptions;
    }

    /**
    * Render the awards of a SBIR/STTR.
    *
    * @return JSX
    */
    renderAwards() {
        const { entity } = this.props;

        if (!entity) {
            return (
                <Row>
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                    <Skeleton active paragraph={false} />
                </Row>
            );
        }

        return (
            <Row>
                {_.map(entity.links_data.awards, (award) => (
                    <Row label={`Description - Phase ${award.phase}`} className="textual">
                        {award.description}
                    </Row>
                ))}
            </Row>
        );
    }

    /**
    * Render the other data of awards of a SBIR/STTR.
    *
    * @return JSX
    */
    renderAwardsOtherData() {
        const { entity } = this.props;

        return (
            <Row>
                {_.map(entity.links_data.awards, (award) => (
                    <Row label={`Data - Phase ${award.phase}`}>
                        <Infobox elements={award.other_data} className="other-data"
                            label={false}
                        />
                    </Row>
                ))}
            </Row>
        );
    }

    /**
    * Render the other data according to the project type.
    *
    * @return JSX
    */
    renderOtherData() {
        const { entity } = this.props;

        if (!entity) {
            return (
                <Row height={220} label="Other data">
                    <Skeleton active />
                </Row>
            );
        }

        if (entity.sub_type_id === 3 || entity.sub_type_id === 4) {
            return this.renderAwardsOtherData();
        }

        // For Cordis, making sure we have data to display in here.
        if (entity.project_data && _.keys(entity.project_data).length > 0) {
            const infoboxEls = _.map(entity.project_data, (value, label) => ({ value, label: _.startCase(label) }));

            return (
                <Row label="Other data">
                    <Infobox
                        className="other-data"
                        elements={infoboxEls}
                        label={false}
                    />
                </Row>
            );
        }

        return null;
    }

    /**
    * Render the leftbar
    *
    * @return JSX
    */
    renderLeftBar() {
        const { entity, onClick, registerCallbacks } = this.props;

        if (!entity) {
            return (
                <Row className="other-info">
                    <Skeleton active paragraph={false} />
                </Row>
            );
        }

        return (
            <Row className="other-info">
                <Actors
                    entity={entity}
                    onClick={onClick}
                    registerCallbacks={registerCallbacks}
                />
            </Row>
        );
    }

    /**
    * Render
    *
    * @return JSX
    */
    render() {
        const {
                width, entity, entities,
                bookmarks,
            }         = this.props,
            otherData = this.renderOtherData();

        return (
            <Col className="document project sheet rel" width={width}>
                <DocumentHeader entity={entity} entities={entities}
                    bookmarks={bookmarks}
                />
                <Row className="document-content">
                    <Col ratio="2/10"
                        className="sheet-col" alignement="center"
                    >
                        <Row className="rel">
                            {this.renderInfobox()}
                        </Row>
                        {this.renderLeftBar()}
                    </Col>
                    <Col ratio={otherData ? '5/10' : '8/10'} className="sheet-col">
                        {this.renderDescriptions()}
                    </Col>
                    {otherData && (
                        <Col ratio="3/10" className="sheet-col">
                            {otherData}
                        </Col>
                    )}
                </Row>
            </Col>
        );
    }

}

Project.propTypes ={
    entity           : PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    entities         : ImmutablePropTypes.list,
    learnKnowledge   : PropTypes.func.isRequired,
    onClick          : PropTypes.func,
    registerCallbacks: PropTypes.func,
    width            : PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    bookmarks        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
};

Project.defaultProps = {
    entity: false,
    width : false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Project);

