// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-children {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.toggle-children .title {
  display: flex;
  color: #10285d;
  border-bottom: 1px solid var(--insight-light-gray);
  line-height: 30px;
  font-size: 12px;
  font-weight: 700;
}
.toggle-children .title .left-part {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}
.toggle-children .title .boost {
  font-size: 14px;
  font-weight: 400;
}
.toggle-children .title .boost .ant-checkbox + span {
  padding-inline-end: 0;
}
.toggle-children .title .icon {
  transform: rotate(0);
  transition: transform 300ms;
}
.toggle-children .title .icon.open {
  transform: rotate(90deg);
}
.toggle-children .title:hover {
  cursor: pointer;
}
.toggle-children .children {
  display: none;
}
.toggle-children .children.show {
  display: initial;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/assets/toggle-children.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AAFJ;AAFA;EAOQ,aAAA;EACA,cAAA;EACA,kDAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAFR;AAVA;EAeY,aAAA;EACA,QAAA;EACA,mBAAA;EACA,YAAA;AAFZ;AAhBA;EAsBY,eAAA;EACA,gBAAA;AAHZ;AApBA;EA0BgB,qBAAA;AAHhB;AAvBA;EA+BY,oBAAA;EACA,2BAAA;AALZ;AAOY;EACI,wBAAA;AALhB;AASQ;EACI,eAAA;AAPZ;AAjCA;EA6CQ,aAAA;AATR;AAWQ;EACI,gBAAA;AATZ","sourcesContent":["@primary-color     : var(--primary-color);\n@insight-light-gray: var(--insight-light-gray);\n\n.toggle-children {\n    display       : flex;\n    flex-direction: column;\n    gap           : 8px;\n    width         : 100%;\n\n    .title {\n        display      : flex;\n        color        : @primary-color;\n        border-bottom: 1px solid @insight-light-gray;\n        line-height  : 30px;\n        font-size    : 12px;\n        font-weight  : 700;\n\n        .left-part {\n            display    : flex;\n            gap        : 6px;\n            align-items: center;\n            flex-grow  : 1;\n        }\n\n        .boost {\n            font-size  : 14px;\n            font-weight: 400;\n\n            .ant-checkbox + span {\n                padding-inline-end: 0;\n            }\n        }\n\n        .icon {\n            transform : rotate(0);\n            transition: transform 300ms;\n\n            &.open {\n                transform: rotate(90deg);\n            }\n        }\n\n        &:hover {\n            cursor: pointer;\n        }\n    }\n\n    .children {\n        display: none;\n\n        &.show {\n            display: initial;\n        }\n    }\n}\n\n@primary-color: #10285d;@secondary-color: #1f7fee;@link-color: #7d295e;@screen-md: 768px;@screen-lg: 1024px;@screen-xl: 1450px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
