/**
 * Display the list of dashboards, allowing the user to navigate.
 *
 * @return Component
 */

import _                    from 'lodash';
import PropTypes            from 'prop-types';
import React, { Component } from 'react';
import EntityStats          from 'helpers/Entity/Stats';
import { makeStrClassName } from 'utils/text';

import './assets/dashboard-bar.less';

/**
* DashboardBar class
*
*/
class DashboardBar extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this, 'getCurrentStats');

        this.state = {
            stats: null,
        };
    }

    /**
    * Return current dashboard stat
    *
    * @return self
    */
    getCurrentStats() {
        const { state } = this.props,
            stats       = state ? state.stats : {};

        return stats;
    }

    /**
     * Return availabe datasets
     *
     * @returns {Array}
     */
    getAvailableDatasets() {
        const { state }       = this.props,
            availableDatasets = state ? state.availableDatasets : [];

        return availableDatasets;
    }

    /**
    * Render the progress bar
    *
    * @return html
    */
    renderStats() {
        const {
                search, navigateTo, tableRender,
                close,
            }                       = this.props,
            stats                   = this.getCurrentStats(),
            availableDatasets       = this.getAvailableDatasets(),
            isLoading               = stats?.progress !== 100,
            classNames              = ['dashboard-stats', isLoading ? 'is-loading' : ''];

        return (
            <div className={classNames.join(' ')}>
                {!tableRender && (
                    <span>generated using:</span>
                )}
                <EntityStats availableDatasets={availableDatasets} close={close}
                    navigateTo={navigateTo} search={search.id}
                    showUnvailable stats={stats}
                    tableRender={tableRender}
                    inline
                />
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const { state }      = this.props,
            { dataIsLoaded } = this.props,
            stats            = this.getCurrentStats(),
            isLoading        = stats?.progress !== 100,
            classNames       = [
                'dashboard-bar',
                {
                    'is-loading'    : !_.isNull(state) && isLoading,
                    'data-is-loaded': dataIsLoaded,
                },
            ];

        return (
            <div className={makeStrClassName(classNames)}>
                <div className="bar-content visible">
                    {this.renderStats()}
                </div>
            </div>
        );
    }

}

/**
* Validate properties for current Component
*/
DashboardBar.propTypes = {
    dataIsLoaded: PropTypes.bool,
    tableRender : PropTypes.bool,
    navigateTo  : PropTypes.any,
    close       : PropTypes.func,
    search      : PropTypes.shape({
        id: PropTypes.any,
    }),
    state: PropTypes.shape({
        stats            : PropTypes.object,
        availableDatasets: PropTypes.array,
    }),
};

DashboardBar.defaultProps = {
    tableRender: false,
    close      : null,
};

export default DashboardBar;
