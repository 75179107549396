import React, { PureComponent }   from 'react';
import PropTypes                  from 'prop-types';
import ImmutablePropTypes         from 'react-immutable-proptypes';
import { emitEvent }              from 'store/actions/sockets';
import _                          from 'lodash';
import { connect }                from 'react-redux';
import { activateShortcut }       from 'store/actions/navigation';
import { updateMemberSettings }   from 'store/actions/auth';
import ShortcutView               from './Shortcut/ShortcutView';
import Buttons                    from './Shortcut/Buttons';
import { resetDownloadsList }     from 'store/actions/downloads';
// Import the header stylesheet
import './assets/shortcut.less';

/**
 * Shortcut link into quickAccess
 */
class Shortcut extends PureComponent {

    /**
    * Initialize the component
    *
    * @param {object} props The props of the Componant
    *
    * @returns void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'toggleActiveState', 'registerCallbacks', 'getActive', 'setActive', 'renderButtons');

        this.state = {
            active     : false,
            calling    : false,
            disabled   : false,
            callbacks  : {},
            loading    : false,
            nbUntouched: null,
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { registerCallbacks, resetDownloadsList, id } = this.props;

        if (registerCallbacks && id) {
            registerCallbacks('getActives', this.getActive, id);
            registerCallbacks('setActives', this.setActive, id);
        }

        // Recover download list
        resetDownloadsList();

        this.updateActiveState();
    }

    /** Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate(prevProps, prevState) {
        const { active: prevActive }   = prevState,
            { calling, active }        = this.state;

        this.updateActiveState();

        if (calling) {
            this.callingTimer = setTimeout(() => this.setState({ calling: false }), 1000);
        }

        if(prevActive && !active) {
            this.updateLastSeenDate();  // Store to the member
        }
    }

    /**
    * Triggered when the component is unmounting
    *
    * @return void
    */
    componentWillUnmount() {
        clearTimeout(this.callingTimer);
    }


    /**
    * Save lastSeenDate in member settings
    *
    * @return void
    */
    updateLastSeenDate() {
        const {
                updateMemberSettingsCb, downloads, lastSeenDate
            }               = this.props,
            downloadsArray  = downloads ? _.values(downloads.toJS()) : [],
            downloadsByDate = _.sortBy(downloadsArray.filter((download) => !!download.date_ready), 'date_ready'),
            date            = _.get(_.last(downloadsByDate), 'date_ready'),
            nbUntouched     = this.getUntouchedDownloads();

        if (nbUntouched > 0 && lastSeenDate !== date) {
            updateMemberSettingsCb({ file: { last_seen_date: date } });  // Store to the member
        }
    }

    /**
    * GetUntouched
    *
    */
    getUntouchedDownloads() {
        const { downloads, lastSeenDate } = this.props;
        return downloads && downloads?.filter(download => new Date(download.date_ready) > new Date(lastSeenDate))?.size || 0; // eslint-disable-line max-len
    }

    /**
    * Update active in state
    *
    * @return void
    */
    updateActiveState() {
        const { shortcutActivated, id } = this.props,
            { active, disabled }        = this.state,
            { shortcutId }              = shortcutActivated || {};

        if (disabled && active) {
            this.setState({ active: false });
        }

        if (shortcutId) {
            if (!active && shortcutId === id) {
                setTimeout(() => this.setState({ active: true }), 300);
            }

            if (active && shortcutId !== id) {
                this.setState({ active: false });
            }
        }
    }

    /** Get active state
    *
    * @return bool
    */
    getActive() {
        const { active } = this.state;

        return active;
    }

    /** Set active state
    *
    * @return void
    */
    setActive(value) {
        const { active } = this.state;

        if (active !== value) {
            this.setState({ active: value });
        }
    }

    /**
     *
     */
    getReducedCallback(callbackName) {
        const { callbacks } = this.state,
            getCallbacks  = _.values(callbacks[callbackName]);

        return getCallbacks ? getCallbacks.reduce(
            (accumulator, getCallback) => {
                const value = getCallback();
                return getCallbacks.length > 1
                        || (!_.isNull(value) && !_.isNull(accumulator))
                    ? accumulator + getCallback()
                    : null;
            }, 0
        ) : 'none';
    }


    /**
    * Change the active state
    *
    * @return void
    */
    toggleActiveState(activeByParams) {
        const {
                id, title, loading,
                activateShortcut, emitEvent
            }                                = this.props,
            { active, nbUntouched }          = this.state,
            activeByParamsIsBool             = _.isBoolean(activeByParams),
            newActiveState                   = activeByParamsIsBool ? activeByParams : !active;

        activateShortcut(null);
        this.setState({ active: newActiveState });

        // If shortcut actived by param then send emit event
        if (newActiveState) {
            emitEvent({
                module: 'shortcut',
                name  : 'open',
                data  : {
                    id,
                    label: title,
                    loading,
                    nbUntouched,
                }
            });
        }
    }


    /**
     *
     * @param {*} id
     * @returns
     */
    getStatus(id) {
        const { clipboard, downloads } = this.props;

        if(id === 'clipboard') {
            return {
                count  : clipboard && clipboard?.filter(item => item?.model?.userViewState !== 'deleted')?.size || 0,
                loading: false
            };
        }

        if(id === 'downloads') {
            const now           = new Date(),
                minutesTimeout  = 10;
            return {
                count  : this.getUntouchedDownloads(),
                loading: downloads && downloads?.filter(
                    download => download.date_ready == null
                    && new Date(download.date_create) > new Date(now.getTime() - (1000 * 60 * minutesTimeout))
                )?.size > 0 || false,
            };
        }

        return { count: 0, loading: false };
    }


    /**
    * Register callback from ShortcutView
    *
    * @return self
    */
    registerCallbacks(action, cb, viewId) {
        const { callbacks } = this.state;

        if (!callbacks[action]) {
            callbacks[action] = {};
        }

        callbacks[action][viewId] = cb;

        this.setState({ callbacks });
    }

    /**
    * Render the button
    *
    * @return html
    */
    renderButtons() {
        const {
                id, ids,
                progress, title
            } = this.props,
            {
                disabled,
                active,
                calling,
            } = this.state,
            { count, loading} = this.getStatus(id);

        return (
            <Buttons
                id={id}
                ids={ids}
                nbUntouched={count}
                disabled={disabled}
                active={active}
                calling={calling}
                loading={loading}
                progress={progress}
                title={title}
            />
        );
    }

    /**
    * Render the view
    *
    * @return JSX
    */
    render() {
        const {
                id, ids, title, downloads,
                tags, navigateTo, navigateToSearch,
                newsletters, reports, newslettersState,
                activateShortcut, shortcutActivated,
            }                    = this.props,
            { options }          = shortcutActivated || {},
            { active, disabled } = this.state;

        return (
            <ShortcutView
                id={id}
                ids={ids}
                title={title}
                toggleActive={this.toggleActiveState}
                active={active}
                disabled={disabled}
                registerCallbacks={this.registerCallbacks}
                downloads={downloads}
                newsletters={newsletters}
                reports={reports}
                newslettersState={newslettersState}
                tags={tags}
                navigateTo={navigateTo}
                navigateToSearch={navigateToSearch}
                renderButtons={this.renderButtons}
                activateOptions={options}
                activateShortcut={activateShortcut}
            />
        );
    }

}

/**
* Validate properties for current Component
*/
Shortcut.propTypes = {
    ids                   : PropTypes.arrayOf(PropTypes.string),
    title                 : PropTypes.string.isRequired,
    id                    : PropTypes.string,
    progress              : PropTypes.number,
    loading               : PropTypes.bool,
    registerCallbacks     : PropTypes.func,
    activateShortcut      : PropTypes.func,
    clipboard             : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    downloads             : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    tags                  : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newsletters           : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    lastSeenDate          : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    reports               : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    navigateTo            : PropTypes.func,
    navigateToSearch      : PropTypes.func,
    emitEvent             : PropTypes.func,
    resetDownloadsList    : PropTypes.func,
    updateMemberSettingsCb: PropTypes.func,
    newslettersState      : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    shortcutActivated     : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Shortcut.defaultProps = {
    progress          : 1,
    loading           : false,
    registerCallbacks : false,
    activateShortcut  : _.noop,
    clipboard         : false,
    downloads         : false,
    lastSeenDate      : false,
    tags              : false,
    newsletters       : false,
    reports           : [],
    navigateTo        : _.noop,
    navigateToSearch  : _.noop,
    resetDownloadsList: _.noop,
    newslettersState  : false,
    shortcutActivated : false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state, props) => {
    const { id }       = props,
        needClipboard  = id === 'clipboard',
        needDownloads  = id === 'downloads',
        memberSettings = needDownloads && state.getIn(['auth', 'member', 'settings']) || {},
        navigation     = state.get('navigation');

    // Use only necessary props for each ShortcutView
    return {
        clipboard        : needClipboard && state.getIn(['userView', 'clipboard_item', 'map']),
        downloads        : needDownloads && state.get('downloads').map,
        lastSeenDate     : needDownloads && memberSettings.file ? memberSettings.file.last_seen_date : null,
        shortcutActivated: navigation.get('shortcutActivated'),
    };
};

export default connect(mapStateToProps, {
    updateMemberSettingsCb: updateMemberSettings,
    resetDownloadsList,
    activateShortcut,
    emitEvent,
})(Shortcut);
