/**
 * Ovation helper router
 *
 * @use: import 'helpers/Router';
 *       <Router
 *
 * @return Component
 */
import  React            from 'react';
import { Route, Switch } from 'react-router';
import _                 from 'lodash';
import PropTypes         from 'prop-types';

/**
* Create the main route array
*
* @description: this should import and merge routes from modules
*/
const Router = (props) => {
    const { routes, module } = props,
        extendedRoutes       = routes.map((route) => {
            const modulePath = module ? `/${module}` : '';
            return {
                ...route,
                // Extend path with hashbang and module, or set the exact route
                path: !route.exact ? `${modulePath}${route.path}` : route.path
            };
        }),
        propsWithoutRoutes   = _.omit(props, 'routes');

    return (
        <Switch>
            {/* Iterate over configuration to add routes */}
            {extendedRoutes.map((route) => {
                const routeProps         = _.omit(route, 'component');

                return (
                    <Route
                        key={route.path}
                        {...routeProps}
                        render={(routeParameters) => {
                            const url = routeParameters.match.params;

                            return (
                                <route.component
                                    path={routeParameters.location.pathname}
                                    {...propsWithoutRoutes}
                                    {...url}
                                />
                            );
                        }}
                    />
                );
            })}

        </Switch>
    );
};

/**
 * Validate properties for current Component
 */
Router.propTypes = {
    module: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.func.isRequired,
        }).isRequired
    ).isRequired
};

Router.defaultProps = {
    module: false
};

export default Router;
